import React, { useMemo } from 'react'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import {
  FrequencyChartType,
  FrequencyComparisonType,
  FrequencyModule,
  FrequencyOrder,
  FrequencyQueryType,
} from '../frequencyModuleTypes'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import useMetaKeys from '../../../../../stores/useMetaKeys'
import ChartTypeDropdown from './ChartTypeDropdown'
import NumericKpiSelectionTreeData from '../../Line/settings/KpiSelectionTreeData'
import OpenKpiSelectionTreeData from './OpenKpiSelectionTreeData'
import TextInput from '../../_shared/ModuleSettings/components/TextInput'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoNumberOfDataPoints, infoSelectedComparisonTimeFrame } from '../FrequencyNotifications'
import SliderInput from '../../_shared/ModuleSettings/components/SliderInput'
import ChartDataFormatDropDown from './ChartDataFormatDropDown'
import LimitInputs from './LimitInputs'
import NumericCategoryLimitInputs from './NumericCategoryLimitInputs'
import ChartNumericKpiDataFormat from './ChartNumericKpiDataFormat'
import MetaKeyDropdown from './MetaKeyDropdown'

const FrequencySettings = () => {
  const { filteredMetaKeys: metaKeys } = useMetaKeys()
  const module = useSettingsTools()[0].module as FrequencyModule
  const selectedDataType = module.queryType
  const selectedChartType = module.chartType
  const selectedComparisonType = module.options?.comparisonType

  const comparisonTypes = useMemo(() => {
    if (selectedDataType === FrequencyQueryType.NUMERIC_KPI) {
      return Object.values(FrequencyComparisonType)
    } else {
      return [FrequencyComparisonType.DATE]
    }
  }, [selectedDataType])
  return (
    <>
      <Subtitle text='Data' />
      <Linebreak />
      <DropdownInput
        isRootProperty
        settingsName='queryType'
        options={Object.values(FrequencyQueryType)}
        title={'Data type for x-axis'}
        isRequired
        width='half'
        data_testid='queryType'
        id='queryType'
      />
      {selectedDataType === FrequencyQueryType.META && (
        <DropdownInput
          isRootProperty
          settingsName='grouping'
          options={metaKeys || []}
          title={'Metadata group'}
          isRequired
          width='half'
          data_testid='grouping'
          id='grouping'
        />
      )}
      {selectedDataType === FrequencyQueryType.OPEN && <OpenKpiSelectionTreeData />}
      {(selectedDataType === FrequencyQueryType.NUMERIC ||
        selectedDataType === FrequencyQueryType.NUMERIC_KPI) && <NumericKpiSelectionTreeData />}
      {selectedDataType !== FrequencyQueryType.NUMERIC_KPI && <ChartDataFormatDropDown />}
      {selectedDataType === FrequencyQueryType.NUMERIC_KPI && <ChartNumericKpiDataFormat />}
      {selectedDataType === FrequencyQueryType.NUMERIC && <NumericCategoryLimitInputs />}
      {selectedDataType !== FrequencyQueryType.NUMERIC && (
        <TextInput
          settingsName='dataPoints'
          data_testid='dataPoints'
          placeholder='displayed data points'
          type='number'
          width='half'
          id='dataPoints'
          isModuleProperty
          title={
            <TextWithInfo
              text={'Number of displayed data points'}
              info={infoNumberOfDataPoints}
              tooltipDirection='right'
            />
          }
        />
      )}
      {(selectedChartType === FrequencyChartType.BAR ||
        selectedChartType === FrequencyChartType.COLUMN) && (
        <DropdownInput
          settingsName='comparisonType'
          options={comparisonTypes}
          title={'Comparison type'}
          width='half'
          data_testid='comparisonType'
          id='comparisonType'
        />
      )}
      {selectedComparisonType === FrequencyComparisonType.DATE && (
        <TextInput
          settingsName='comparisonDays'
          data_testid='comparisonDays'
          placeholder='give time frame'
          type='number'
          width='half'
          id='comparisonDays'
          title={
            <TextWithInfo
              text={'Comparison time frame'}
              info={infoSelectedComparisonTimeFrame}
              tooltipDirection='right'
            />
          }
        />
      )}
      {selectedComparisonType === FrequencyComparisonType.META && <MetaKeyDropdown />}

      <Subtitle text='Chart styles' />
      <Linebreak />
      <LimitInputs />
      <ChartTypeDropdown />
      <DropdownInput
        settingsName='order'
        options={Object.entries(FrequencyOrder).map(([key, value]) => ({
          name: key.toLocaleLowerCase(),
          value,
        }))}
        title={'Order'}
        width='third'
        data_testid='order'
        id='order'
        defaultValue={FrequencyOrder.NONE.toLocaleLowerCase()}
        isRootProperty
      />
      <SliderInput
        title='Extension heights (px)'
        leftLabel='Height'
        settingsName='moduleHeight'
        width='full'
        min={400}
        max={800}
        defaultValue={450}
      />

      <Subtitle text='Chart details' />
      <Linebreak />
    </>
  )
}

export default FrequencySettings
