import { cloneDeep } from 'lodash'
import { Module, ModuleType, QueueStatus, RenderList, RenderQueueModule } from '../groupModuleTypes'

const MAX_NUMBER_FETCHING = 3
export enum ActionTypes {
  ADD = 'add',
  REMOVE = 'remove',
  UPDATE_TO_DONE = 'update_to_done',
  REQUEST_TO_FETCH = 'request_to_fetch',
  CHECK_QUEUE_STATUSES = 'check_queue_statuses',
}

export type Add = {
  type: ActionTypes.ADD
  data: {
    module: RenderQueueModule
  }
}

export type Remove = {
  type: ActionTypes.REMOVE
  data: {
    ids: string[]
  }
}

export type RequestToFetch = {
  type: ActionTypes.REQUEST_TO_FETCH
  data: {
    id: string
  }
}

export type UpdateToDone = {
  type: ActionTypes.UPDATE_TO_DONE
  data: {
    id: string
  }
}

export type CheckQueueStatuses = {
  type: ActionTypes.CHECK_QUEUE_STATUSES
}

type ReducerAction = Add | Remove | UpdateToDone | RequestToFetch | CheckQueueStatuses
export const initRenderList = {} as RenderList
export const renderListReducer = (state: RenderList, action: ReducerAction) => {
  switch (action.type) {
    case ActionTypes.ADD: {
      const newModule = cloneDeep(action.data.module)
      if (newModule.type === ModuleType.GROUP) return state
      if (!newModule.id) return state
      if (!state[newModule.id]) {
        const newState = cloneDeep(state)
        newState[newModule.id] = newModule
        return handleFetchingQueue(newState)
      }
      return state
    }

    case ActionTypes.REMOVE: {
      const ids = action.data.ids
      const newState = cloneDeep(state)
      ids.forEach((id) => delete newState[id])
      return handleFetchingQueue(newState)
    }

    case ActionTypes.REQUEST_TO_FETCH: {
      const id = action.data.id
      const currentModule = state[id]
      if (!currentModule) return state
      const currentStatus = currentModule.status
      if (currentStatus && isAllowedToFetch(currentStatus)) return state
      const newState = cloneDeep(state)
      newState[id] = { ...currentModule, status: QueueStatus.WAITING_TO_FETCH }
      return handleFetchingQueue(newState)
    }

    case ActionTypes.UPDATE_TO_DONE: {
      const id = action.data.id
      const currentModule = state[id]
      if (!currentModule) return state
      const currentStatus = currentModule.status
      if (currentStatus && isDoneFetching(currentStatus)) return state
      const newState = cloneDeep(state)
      newState[id] = { ...currentModule, status: QueueStatus.IDLE }
      return handleFetchingQueue(newState)
    }

    case ActionTypes.CHECK_QUEUE_STATUSES: {
      return handleFetchingQueue(state)
    }

    default:
      return state
  }
}

export const handleFetchingQueue = (modules: RenderList): RenderList => {
  const modulesList = Object.values(modules)
  const newModuleList = modulesList.reduce<RenderQueueModule[]>(
    (list, m, currentIndex, prevList) => {
      const numberOfModulesFetchingNewList = list.filter(
        (module) => module.status === QueueStatus.FETCHING,
      ).length
      const numberOfModulesFetchingOldList = prevList
        .slice(currentIndex + 1)
        .filter((module) => module.status === QueueStatus.FETCHING).length
      const hasMaxNumberFetching =
        numberOfModulesFetchingNewList + numberOfModulesFetchingOldList >= MAX_NUMBER_FETCHING
      if (hasMaxNumberFetching) return list.concat(m)
      const isWaitingFetching = m.status === QueueStatus.WAITING_TO_FETCH
      if (isWaitingFetching) return list.concat({ ...m, status: QueueStatus.FETCHING })
      return list.concat(m)
    },
    [] as RenderQueueModule[],
  )
  return newModuleList.reduce<RenderList>((acc, m) => {
    acc[m.id] = m
    return acc
  }, {})
}

export const isAllowedToFetch = (status: QueueStatus | undefined) => {
  return status === QueueStatus.NOT_IMPLEMENTED || status === QueueStatus.FETCHING
}

export const isFetchingData = (status: QueueStatus | undefined) => {
  return !!status && (status === QueueStatus.FETCHING || status === QueueStatus.NOT_IMPLEMENTED)
}

export const isWaitingForFetching = (status: QueueStatus | undefined) => {
  return !status || status === QueueStatus.WAITING_TO_FETCH
}

export const isDoneFetching = (status: QueueStatus | undefined) => {
  return !!status && (status === QueueStatus.IDLE || status === QueueStatus.NOT_IMPLEMENTED)
}

export const getInitStatus = (moduleType: Module['type']) => {
  if (moduleType === ModuleType.ALERT) return QueueStatus.NOT_IMPLEMENTED
  if (moduleType === ModuleType.BUBBLE) return QueueStatus.NOT_IMPLEMENTED
  if (moduleType === ModuleType.RADAR) return QueueStatus.NOT_IMPLEMENTED
  if (moduleType === ModuleType.SUMMARY) return QueueStatus.NOT_IMPLEMENTED
  const moduleTypes = Object.values(ModuleType) as Module['type'][]
  if (!moduleTypes.includes(moduleType)) return QueueStatus.NOT_IMPLEMENTED
  return QueueStatus.IDLE
}
