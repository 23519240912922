import React, { useEffect, useState } from 'react'
import Rodal from 'rodal'
import { Button, Stack } from '@mui/material'
import { Cancel, Check } from '@mui/icons-material'

import css from './ConfirmationModal.module.scss'

type ConfirmationModalProps = {
  title?: undefined | string
  info?: string
  confirm?: string
  cancel?: string
  handleConfirmation: () => void
  handleCancel: () => void
  enableEnterConfirmation?: boolean
  isWarning?: boolean
}

const ConfirmationModal = ({
  title = 'Please confirm',
  info = 'Please confirm the action to proceed.',
  confirm = 'Confirm',
  cancel = 'Cancel',
  handleConfirmation,
  handleCancel,
  isWarning,
  enableEnterConfirmation = false,
}: ConfirmationModalProps) => {
  const [confirmDisabled, setConfirmDisabled] = useState(false)

  useEffect(() => {
    if (!enableEnterConfirmation) return

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleEnterKeyPressed()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  const handleEnterKeyPressed = () => {
    if (!confirmDisabled) {
      setConfirmDisabled(true)
      handleConfirmation()
    }
  }

  return (
    <div data-testid='confirmation-modal'>
      <Rodal
        closeOnEsc
        animation='slideUp'
        visible={true}
        onClose={() => handleCancel()}
        className={css.rodal}
      >
        <div className={css.header}>
          <h5>{title}</h5>
        </div>
        <div className={css.infoCntr}>
          <hr />
          <div className={css.info}>{info}</div>
          <hr />
        </div>
        <div className={css.btns}>
          <Stack direction='row' spacing={2}>
            <Button
              variant='contained'
              className={isWarning ? css.warning : ''}
              startIcon={<Check />}
              size='large'
              onClick={handleConfirmation}
            >
              {confirm}
            </Button>
            <Button variant='outlined' startIcon={<Cancel />} size='large' onClick={handleCancel}>
              {cancel}
            </Button>
          </Stack>
        </div>
      </Rodal>
    </div>
  )
}

export default ConfirmationModal
