import React, { useState } from 'react'
import ModuleRenderer from './ModuleRenderer'
import { useGroupModule } from '../contexts/GroupContext'
import { Module } from '../groupModuleTypes'
import { useGroupKpis } from '../contexts/GroupKpiContext'
import useCalculatedKpis from '../../../../../stores/useCalculatedKpis'
import useNumericKpis from '../../../../../stores/useNumericKpis'
import useOpenKpis from '../../../../../stores/useOpenKpis'
import useConfigCustomerPath from '../../../../../stores/useConfigCustomerPath'
import useKpiExtractor from './useKpiExtractor'
import { useToastId } from '../../../../common/Notification/NotificationContext'
import { useDeepCompareEffect } from 'react-use'
import { useRenderActions } from '../contexts/RenderContext'
import { getInitStatus } from '../contexts/renderListReducer'
import { useRootActions } from '../contexts/RootContext'

import css from './GroupModulesList.module.scss'

const GroupModulesList = () => {
  const { addModuleToList } = useRenderActions()
  const { toastifyId } = useToastId()
  const [{ module, isNested }] = useGroupModule()
  const { saveModule, moveModuleDown, moveModuleUp, removeModule } = useRootActions()
  const setGroupKpis = useGroupKpis()[1]
  const { handleExtractingKpiIdsFromModules } = useKpiExtractor()
  const { isLoading: isLoadingCalculated } = useCalculatedKpis()
  const { numericKpis, isLoading: isLoadingNumeric } = useNumericKpis()
  const { openKpis, isLoading: isLoadingOpen } = useOpenKpis()
  const { isLoading: isLoadingCustomerPath } = useConfigCustomerPath()
  const [renderModules, setRenderModules] = useState<React.JSX.Element[]>([])
  const isLoading =
    isLoadingCalculated || isLoadingNumeric || isLoadingOpen || isLoadingCustomerPath
  const [modules, setModules] = useState<Module[]>([])

  useDeepCompareEffect(() => {
    if (!module?.modules) return
    setModules(module.modules)
  }, [module?.modules || {}])

  const extractorTimeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  const EXTRACTOR_TIMEOUT = 2000
  useDeepCompareEffect(() => {
    if (!modules || isLoading || !module) return
    if (!numericKpis || !openKpis) return
    if (extractorTimeoutRef.current) clearTimeout(extractorTimeoutRef.current)
    extractorTimeoutRef.current = setTimeout(() => {
      const kpiIds = handleExtractingKpiIdsFromModules(
        modules,
        !!module.options?.isConfigKpiExtractionEnabled,
        toastifyId,
      )
      setGroupKpis({ kpis: kpiIds })
    }, EXTRACTOR_TIMEOUT)
    return () => {
      if (extractorTimeoutRef.current) clearTimeout(extractorTimeoutRef.current)
    }
  }, [modules, isLoading, module?.options?.isConfigKpiExtractionEnabled, numericKpis, openKpis])

  useDeepCompareEffect(() => {
    if (!modules || !module?.id) return
    handleAddingModulesToRenderList(modules, module.id)
  }, [modules || []])

  const getCntrClasses = () => {
    let classes: string = css.cntr
    if (isNested) classes = classes.concat(' ', css.nested)
    return classes
  }

  const handleAddingModulesToRenderList = (modules: Module[], parentId: string) => {
    modules.forEach((m) => {
      if ((m.type === 'group' || m.type === 'group2') && m.modules && m.id)
        handleAddingModulesToRenderList(m.modules, m.id)
      else if (m.id)
        addModuleToList({
          id: m.id,
          status: getInitStatus(m.type),
          groupId: parentId,
          type: m.type,
        })
    })
  }

  const handleSaveNestedModule = (newModule: Module) => {
    saveModule(newModule)
  }

  const handleDeleteNestedModule = (module: Module) => {
    removeModule(module)
  }

  const renderTimeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  useDeepCompareEffect(() => {
    if (!modules || !module) return
    const newModuleElements = modules.map((m, i) => {
      return (
        <ModuleRenderer
          key={m.id || i}
          module={m}
          index={i}
          isNested={!!isNested}
          parentGroupModule={module}
          handleSaveNestedModule={handleSaveNestedModule}
          handleDeleteNestedModule={handleDeleteNestedModule}
          handleMoveNestedModuleUp={moveModuleUp}
          handleMoveNestedModuleDown={moveModuleDown}
        />
      )
    })
    if (renderTimeoutRef.current) clearTimeout(renderTimeoutRef.current)
    renderTimeoutRef.current = setTimeout(() => {
      setRenderModules(newModuleElements)
    }, 100)
    return () => {
      if (renderTimeoutRef.current) clearTimeout(renderTimeoutRef.current)
    }
  }, [modules || [], module || {}])

  return (
    <div data-testid='group-modules-list' className={getCntrClasses()}>
      {renderModules}
    </div>
  )
}

export default GroupModulesList
