import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTopBottomSettings } from './Context/TopBottomSettingsContext'
import TopBottomNotification from './TopBottomNotification'
import TopBottomTables from './Tables/TopBottomTables'
import useConfigCustomerPath from '../../../../stores/useConfigCustomerPath'
import useNumericKpiCounts from '../../../../stores/useNumericKpiCounts'
import { LimitValues, TopBottomColumnNames, TopBottomStructuredData } from './topBottomTypes'
import { useTopBottomData } from './Context/TopBottomDataContext'
import useTopBottomFunctions from './useTopBottomFunctions'
import useNumericKpis from '../../../../stores/useNumericKpis'
import { KpiData, Query } from '../../../../../types'
import { useToastId } from '../../../common/Notification/NotificationContext'
import { toast } from 'react-toastify'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import { isEmpty, isEqual, isNil } from 'lodash'
import { infoNoDataSource } from './TopBottomNotifications'
import NoData from '../../../_shared/Infos/NoData'
import { useRenderActions } from '../Group/contexts/RenderContext'
import {
  isAllowedToFetch,
  isFetchingData,
  isWaitingForFetching,
} from '../Group/contexts/renderListReducer'
import FadeIn from '../_shared/FadeIn'
import { CircularProgress } from '@mui/material'
import { QueueStatus } from '../Group/groupModuleTypes'

import css from './TopBottomModule.module.scss'

type TopBottomModuleProps = {
  moduleStatus: QueueStatus | undefined
}
const TopBottomModule = ({ moduleStatus }: TopBottomModuleProps) => {
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { updateModuleToIdle, requestToFetch } = useRenderActions()
  const [{ topBotFilters, id }, setTopBottomData] = useTopBottomData()
  const [
    { useTopBottomCustomerPath, selectedColumns, selectedKpis, topBottomDataDisplay, topBottomMax },
  ] = useTopBottomSettings()
  const isTrendsEnabled = selectedColumns.includes(TopBottomColumnNames.TREND)
  const { getTopBotTrendQuery, getLimitValues, buildCustomerPathData, buildRegularData } =
    useTopBottomFunctions()

  const [query, setQuery] = useState<Query.SingleNumeric | null>(null)
  const [trendQuery, setTrendQuery] = useState<Query.SingleNumeric | null>(null)
  const [ready, setReady] = useState<string>('')

  const { toastifyId } = useToastId()
  const toastCntr = { containerId: toastifyId }
  const toastError = (err: string) => {
    if (err) toast.error(err, toastCntr)
  }

  const {
    config: customerPathConf,
    isLoading: isCustomerPathConfLoading,
    error: customerPathConfError,
  } = useConfigCustomerPath()
  useEffect(() => toastError(customerPathConfError), [customerPathConfError])

  const {
    numericCounts: numCounts,
    isLoading: isNumCountsLoading,
    isRefetching: isRefetchingNumCounts,
    error: numCountsError,
  } = useNumericKpiCounts(query)
  useEffect(() => toastError(numCountsError), [numCountsError])

  const {
    numericCounts: trendNumCounts,
    isLoading: isTrendNumCountsLoading,
    isRefetching: isRefetchingTrendNumCounts,
    error: trendNumCountsError,
  } = useNumericKpiCounts(trendQuery)
  useEffect(() => toastError(trendNumCountsError), [trendNumCountsError])

  const {
    numericKpis: numKpis,
    isLoading: isNumericKpisLoading,
    error: numericKpisError,
  } = useNumericKpis()
  useEffect(() => toastError(numericKpisError), [numericKpisError])

  const isTopBotDataLoading = Boolean(
    isCustomerPathConfLoading ||
      isNumCountsLoading ||
      isTrendNumCountsLoading ||
      isNumericKpisLoading ||
      (isWaitingForFetching(moduleStatus) && !numCounts),
  )

  const DONE_STATUS_TIMEOUT = 200
  useEffect(() => {
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
    const isDoneFetching =
      !isNumCountsLoading &&
      !isRefetchingNumCounts &&
      !isTrendNumCountsLoading &&
      !isRefetchingTrendNumCounts
    if (isFetchingData(moduleStatus) && isDoneFetching) {
      doneStatusTimeoutRef.current = setTimeout(() => {
        updateModuleToIdle(id)
      }, DONE_STATUS_TIMEOUT)
    }
    return () => {
      doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
    }
  }, [
    moduleStatus,
    isNumCountsLoading,
    isRefetchingNumCounts,
    isTrendNumCountsLoading,
    isRefetchingTrendNumCounts,
  ])

  const showModule = Boolean(selectedColumns.length && topBottomDataDisplay && topBottomMax)

  const readyTimeout = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    if (readyTimeout.current) clearTimeout(readyTimeout.current)
    if (!isTopBotDataLoading && isFetchingData(moduleStatus)) {
      if (id) updateModuleToIdle(id)
      readyTimeout.current = setTimeout(() => {
        setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
      }, 4000)
    }
    return () => {
      if (readyTimeout.current) clearTimeout(readyTimeout.current)
    }
  }, [isTopBotDataLoading, moduleStatus])

  useEffect(() => {
    if (customerPathConf && customerPathConf.limitValues) {
      const newLimitValues = getLimitValues(customerPathConf.limitValues as LimitValues)
      setTopBottomData((prev) => ({
        ...prev,
        topBottomLimitValues: newLimitValues,
      }))
    }
  }, [customerPathConf?.limitValues])

  useEffect(() => {
    if (!numKpis) return

    const handleQueryKpis = (): Query.Kpi[] => {
      const customerPathKpis = numKpis.filter((kpi) => {
        const pathKpi = customerPathConf?.conf?.find((path) => path.kpiid === kpi.id)
        if (pathKpi) {
          return true
        }
      })
      if (useTopBottomCustomerPath) {
        return customerPathKpis as Query.Kpi[]
      } else {
        return selectedKpis as Query.Kpi[]
      }
    }

    const newQuery: Query.SingleNumeric = {
      start_date: topBotFilters.start_date,
      end_date: topBotFilters.end_date,
      where_meta: topBotFilters.where_meta,
      calculated_kpis: {},
      kpis: handleQueryKpis(),
      cache: true,
      trend_line: false,
    }

    if (isEqual(newQuery, query) && (!isTrendsEnabled || trendQuery)) return
    if (id) handleRequestingToFetch(id)
    if (isAllowedToFetch(moduleStatus)) {
      setQuery(newQuery)
      setTrendQuery(getTopBotTrendQuery(newQuery, isTrendsEnabled))
    }
  }, [
    id,
    isTrendsEnabled,
    moduleStatus,
    topBotFilters,
    selectedKpis,
    useTopBottomCustomerPath,
    customerPathConf?.conf,
    isTrendsEnabled,
    numKpis,
  ])

  useEffect(() => {
    if (!isTrendsEnabled) setTrendQuery(null)
  }, [isTrendsEnabled])

  const handleRequestingToFetch = (moduleId: string) => {
    requestToFetch(moduleId)
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
  }

  const isRefetching = useMemo(() => {
    return (
      !!numCounts &&
      (isWaitingForFetching(moduleStatus) || isRefetchingNumCounts || isRefetchingTrendNumCounts)
    )
  }, [isRefetchingNumCounts, numCounts, moduleStatus, isRefetchingTrendNumCounts])

  useEffect(() => {
    let newStructuredData: TopBottomStructuredData[] = []
    if (numCounts && numKpis && ((isTrendsEnabled && trendNumCounts) || !isTrendsEnabled)) {
      if (useTopBottomCustomerPath && customerPathConf && customerPathConf.conf) {
        newStructuredData = buildCustomerPathData(customerPathConf.conf, numCounts, trendNumCounts)
        setTopBottomData((prev) => ({
          ...prev,
          topBottomData: newStructuredData,
        }))
        return
      }

      if (!useTopBottomCustomerPath) {
        newStructuredData = buildRegularData(numKpis, numCounts, trendNumCounts)
        setTopBottomData((prev) => ({
          ...prev,
          topBottomData: newStructuredData,
        }))
      }
    } else {
      setTopBottomData((prev) => ({
        ...prev,
        topBottomData: [],
      }))
    }
  }, [numCounts, trendNumCounts])

  function isDataEmpty(): boolean {
    return hasNoData(numCounts)
    function hasNoData(counts: KpiData.NumericData | null) {
      return isNil(counts) || isEmpty(counts) || isSeriesEmpty(counts.series)
    }
    function isSeriesEmpty(series: KpiData.NumericKpiData[]) {
      return series.reduce((hasNoData, series) => {
        if (series.data && Array.isArray(series.data) && series.data.length) {
          return false
        } else {
          return hasNoData
        }
      }, true)
    }
  }
  if (isTopBotDataLoading)
    return (
      <div className={css.loading}>
        <CircularProgress thickness={1} size={'5rem'} style={{ opacity: 0.7 }} />
      </div>
    )

  if (
    query &&
    (!query.kpis || !query.kpis.length) &&
    (!query.calculated_kpis || !Object.keys(query.calculated_kpis).length)
  ) {
    return (
      <div className={css.noDataSource}>
        <div>{infoNoDataSource}</div>
        <div>
          <NoData />
        </div>
      </div>
    )
  }

  return (
    <FadeIn>
      <div className={`listnumeric-module-cntr ${ready} ${css.cntr}`} data-no-data={isDataEmpty()}>
        {isRefetching && (
          <div className={css.refetching}>
            <CircularProgress
              sx={{ color: '#fff' }}
              thickness={2}
              size={'1.7rem'}
              style={{ opacity: 0.7 }}
            />
          </div>
        )}
        {showModule ? <TopBottomTables /> : <TopBottomNotification />}
      </div>
    </FadeIn>
  )
}

export default TopBottomModule
