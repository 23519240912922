/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { COLLAB_BASE_URL } from '../../../../react-constants/urls'
import { SurveyInfoTool } from '../../../../../types'
import NoData from '../../../_shared/Infos/NoData'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable';
import trackingService from '../../../../react-services/trackingService';
import { TrackingEvent } from '../../../../react-constants';

type Props = {
  surveyList: SurveyInfoTool.SurveyListItem[]
  onOpenPreview: (id: any) => void
}

function SurveyInfoTable({ surveyList, onOpenPreview: onOpenRodal }: Props) {

  const buildHeaders = (): JSX.Element => {
    return (
      <tr>
        <th colSpan={4}>Survey</th>
        <th colSpan={1}>ID</th>
        <th colSpan={1}>Via</th>
        <th colSpan={1}>Type</th>
        <th colSpan={1}>Last sent</th>
        <th colSpan={1}>Latest answer</th>
        <th colSpan={1} onClick={() => { }}>Message preview</th>
        <th colSpan={1} onClick={() => { }}>Preview link</th>
      </tr>
    )
  }

  const createSurveyURL = (gizmoID: string): string => {
    return COLLAB_BASE_URL + gizmoID
  }

  const buildRows = (): JSX.Element[] => {
    const result: JSX.Element[] = []
    surveyList.forEach(n =>
      result.push(
        <tr key={n.surveyName} data-testid={`responseRateTableRow-${n.surveyName}`}>
          <td colSpan={4}>{n.surveyName}</td>
          <td className="center">{n.surveyGizmoId}</td>
          <td className="center">{createDeliveryMethod(n.deliveryMethod)}</td>
          <td className="center">{n.type ? createTypeTooltip(n.type, n.isActive) : 'Redirect'}</td>
          <td className="center">{n.lastSent || ''}</td>
          <td className="center">{n.lastReceived || ''}</td>
          <td className="center">{n.messages ? createMessageBtn(n) : ''}</td>
          <td className="center">{n.surveyGizmoId ? createCollabLink(n.surveyGizmoId) : ' '}</td>
        </tr>
      ))
    return result;
  }

  const createTypeTooltip = (type: string, isActive?: boolean): React.ReactNode => {
    return (
      <>
        {createSurveyTypeForTable(type)}
        {isActive === false && <span><br />(inactive)</span>}
        {createTooltip(createTooltipTextForType(type))}
      </>
    )
  }

  const createMessageBtn = (n: SurveyInfoTool.SurveyListItem): React.ReactNode => {
    return (
      <button className='btn-flat' onClick={() => onOpenRodal(n)}>
        <i className="material-icons">visibility</i>
      </button>
    )
  }

  const createCollabLink = (surveyGizmoId: string): React.ReactNode => {
    return (
      <a className='btn-flat' href={createSurveyURL(surveyGizmoId)} target="_blank" rel="noopener noreferrer" onClick={() => trackingService.track(TrackingEvent.ClickCollabLink)}>
        <i className="material-icons">link</i>
      </a>
    )
  }

  const createSurveyTypeForTable = (type: string): string => {
    switch (type) {
    case 'trigger': return 'Trigger'
    case 'automation': return 'Continuous'
    case 'manual': return 'Template'
    default: return ''
    }
  }

  const createTooltipTextForType = (type: string): string => {
    switch (type) {
    case 'trigger': return 'Trigger activated sending'
    case 'automation': return 'Scheduled sending'
    case 'manual': return 'Template-based sending, eg. manual sending'
    default: return ''
    }
  }

  const createDeliveryMethod = (deliveryMethod: SurveyInfoTool.DeliveryMethod): React.ReactNode => {
    const method = createDeliveyMethodText(deliveryMethod)
    return (
      <>
        <span>{method}</span>
        {createTooltipTextForDeliveyMethod(deliveryMethod)}
      </>
    )
  }

  const createTooltip = (tipText: string): React.ReactNode => {
    return (
      <span className="tool">
        <span className="tip">{tipText}</span>
        <i className="material-icons tiny">help</i>
      </span>
    )
  }

  const createTooltipTextForDeliveyMethod = (deliveryMethod: SurveyInfoTool.DeliveryMethod): React.ReactNode => {
    switch (deliveryMethod.second) {
    case 'email':
      return createTooltip('The survey is sent via SMS. If a phone number is unknown, the survey is sent via email.')
    case 'sms':
      return createTooltip('The survey is sent via email. If an email address is unknown, the survey is sent via SMS.')
    default: return <></>
    }
  }

  const createDeliveyMethodText = (deliveryMethod: SurveyInfoTool.DeliveryMethod): string => {
    let method = ''
    if (deliveryMethod.first === 'email') method = 'Email'
    else if (deliveryMethod.first === 'sms') method = 'SMS'
    if (deliveryMethod.second === 'email') method = method + ', then email'
    else if (deliveryMethod.second === 'sms') method = method + ', then SMS'
    return method
  }

  return (
    <div>
      <GenericTable
        headers={buildHeaders()}
        rows={buildRows()}
        useFixedLayout
      />
      {surveyList.length === 0 &&
        <NoData />
      }
    </div>
  )
}

export default SurveyInfoTable
