import React from 'react'

const AddNewCardIcon = () => {
  return (
    <i className={'material-icons material-symbols-outlined'} style={{ fontSize: '3.5em' }}>
      add
    </i>
  )
}

export default AddNewCardIcon
