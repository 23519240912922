import { createStateContext } from 'react-use'
import { Languages, SummaryFiltersContext } from '../summaryTypes'

export const [useSummaryFilters, SummaryFiltersProvider] =
  createStateContext<SummaryFiltersContext>({
    language: Languages.FI,
    summarySettings: {},
    filterInputs: [],
    filterMetas: { Tulosyksikkö: 'synnytysosasto' },
    filterSentiment: '',
    filterTopic: '',
    filterPath: '',
    filterSummaryTypes: [],
    filterFirstYear: '2021',
  })
