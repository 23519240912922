/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';


const makeApiHook = (apiRequests: any[]) => {
  return function useHook() {
    const [resolved, setResolved] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
      try {
        Promise.all(apiRequests)
          .then(res => {
            setResolved(res)
            setIsLoading(false)
          })

      } catch (error: any) {
        console.error(error)
        setIsLoading(false)
      }
    }, [])
    return {resolved, isLoading}
  }
}

export { makeApiHook }