import React from 'react'
import TextInput from '../_shared/ModuleSettings/components/TextInput'
import TextWithInfo from '../_shared/ModuleSettings/components/TextWithInfo'
import { infoPictureLink, infoPictureUrl } from './PictureNotifications'
import CheckBoxInput from '../_shared/ModuleSettings/components/CheckBoxInput'

const PictureSettings = () => {
  return (
    <>
      <TextInput
        settingsName='imageurl'
        data_testid='imageurl'
        id='imageurl'
        type='text'
        width='full'
        title={<TextWithInfo text='Picture url' info={infoPictureUrl} tooltipDirection='right' />}
        isModuleProperty
      />
      <TextInput
        settingsName='linktourl'
        data_testid='linktourl'
        id='linktourl'
        type='text'
        width='full'
        title={
          <TextWithInfo text='Picture link url' info={infoPictureLink} tooltipDirection='right' />
        }
        isModuleProperty
      />
      <CheckBoxInput
        settingsName='placeholder'
        text='Enabled'
        title='Module is placeholder only'
        data_testid='placeholder'
        id='placeholder'
        isRootProperty
        width='half'
      />
    </>
  )
}

export default PictureSettings
