import React from 'react';

const ManualSenderTemplateSelector = ({surveyTemplates, selectedSurveyTemplate, handleTemplateChange}) => {
  return (
    <div id="manualSurveyTop">
      <span className="manual-survey-span">Survey</span>
      {surveyTemplates && selectedSurveyTemplate ?
        <select data-jt="selectTemplate" name="template" id="templateSelection" value={selectedSurveyTemplate.id} onChange={(e) => handleTemplateChange(parseInt(e.target.value, 10))}>
          {surveyTemplates.map((t, i) => <option key={`${t}-${i}`} value={t.id}>{t.name}</option>)}
        </select>
      :
        <select disabled name="manual-sender-waiting-for-templates">
          <option>Please wait</option>
        </select>
      }
    </div>
  );
};

export default ManualSenderTemplateSelector;