import React from 'react'
import {
  Add,
  Check,
  Close,
  Delete,
  Download,
  Edit,
  Info,
  MoreHorizontal,
  MoreVertical,
  Remove,
  Reset,
  SVGProps,
  Save,
  Tune,
} from './icons'

export enum IconName {
  DOWNLOAD = 'download',
  TUNE = 'tune',
  DELETE = 'delete',
  MORE_VERT = 'more_vert',
  MORE_HORIZ = 'more_horiz',
  ADD = 'add',
  CHECK = 'check',
  REMOVE = 'remove',
  CLOSE = 'close',
  EDIT = 'edit',
  RESET = 'reset',
  INFO = 'info',
  SAVE = 'save',
}
export type IconProps = {
  name: IconName
}
const Icon = ({ name, ...props }: IconProps & SVGProps) => {
  return (
    <>
      {name === IconName.DOWNLOAD && <Download {...props} />}
      {name === IconName.TUNE && <Tune {...props} />}
      {name === IconName.DELETE && <Delete {...props} />}
      {name === IconName.MORE_VERT && <MoreVertical {...props} />}
      {name === IconName.MORE_HORIZ && <MoreHorizontal {...props} />}
      {name === IconName.ADD && <Add {...props} />}
      {name === IconName.CHECK && <Check {...props} />}
      {name === IconName.REMOVE && <Remove {...props} />}
      {name === IconName.CLOSE && <Close {...props} />}
      {name === IconName.EDIT && <Edit {...props} />}
      {name === IconName.RESET && <Reset {...props} />}
      {name === IconName.INFO && <Info {...props} />}
      {name === IconName.SAVE && <Save {...props} />}
    </>
  )
}

export default Icon
