import React, { useEffect, useRef, useState } from 'react'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { SummaryModule } from '../summaryTypes'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import { isEqual } from 'lodash'

const KpiSelectionTreeData = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const summaryModule = module as SummaryModule

  useEffect(() => {
    const kpiSelection = summaryModule?.options?.kpis || []
    setSelectedKpis(kpiSelection)
  }, [])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const handleSaveNewSelection = (newSelection: kpiIdAndName[]) => {
    try {
      if (!summaryModule) return
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        const newSummaryModule = { ...summaryModule }
        if (!newSummaryModule.options) newSummaryModule.options = {}
        newSummaryModule.options.kpis = newSelection
        if (isEqual(newSummaryModule, summaryModule)) return
        saveModule(newSummaryModule)
      }, 500)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.open}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
