import React from 'react'

type Props = {
  name: string
  kpiName: string | number
  kpiValue: string
  filter: Record<string, string>
  recipients: string[]
  edit: () => void
  show: () => void
}

function AlertRow({ name, kpiName, kpiValue, filter, recipients, edit, show }: Props) {
  const displayRecipients = recipients.length > 2 ? recipients.slice(0, 2).concat('...') : recipients
  const filterReducer = (acc: string, curr: string) => `${curr}: ${filter[curr]}${acc !== '' ? ', ' : ''}${acc}`;
  const recipientsReducer = (acc: string, curr: string) => `${acc}${acc !== '' ? ', ' : ''}${curr}`;
  let isMeta = false
  if (recipients.length > 0) {
    isMeta = recipients[0].startsWith('%') && recipients[0].endsWith('%')
  }
  return (
    <tr key={name} data-testid={`alert-table-row-${name}`}>
      <td colSpan={3}>{name}</td>
      <td colSpan={4}>{kpiName}</td>
      <td colSpan={1}>{kpiValue}</td>
      <td colSpan={3}>{Object.keys(filter).reduce(filterReducer, '')}</td>
      <td colSpan={4}>{displayRecipients.reduce(recipientsReducer, '')}</td>
      <td>
        <span data-testid="show-icon-span" className="pointer" onClick={() => { show() }}>
          <i className="material-icons module-icon-color waves-effect">visibility</i>
        </span>
      </td>
      <td>
        {!isMeta &&
          <span data-testid="edit-icon-span" className="pointer" onClick={() => { edit() }}>
            <i className="material-icons module-icon-color waves-effect">edit</i>
          </span>
        }
      </td>
    </tr>
  )
}

export default AlertRow