/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react'
import { Query, KpiData, ApiHook } from '../../../../types'
import { post } from '../../../react-services/apiService'
import { isQueryXYZValid } from '../../../react-services/queryService'
import { isEqual, isNil } from 'lodash'
import { deepCopy } from '../../../../utils'
import { useMountedState } from 'react-use'

function createUseXyzQuery() {
  return function useXyzQuery(query: Query.XYZ): ApiHook<KpiData.XYZData> {
    if (isNil(query)) {
      throw new Error("createUseXyzQuery can't do without a query.")
    }
    const isMounted = useMountedState()
    const [data, setData] = useState<KpiData.XYZData | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [userError, setUserError] = useState<string | null>(null)

    const queryStore = useRef<Query.XYZ>({} as Query.XYZ)
    const dataStore = useRef<KpiData.XYZData | null>(null)

    useEffect(() => {
      setIsLoading(true)
      setError(null)
      setUserError(null)

      if (!isQueryXYZValid(query)) {
        setUserError('Please select Y-axis KPI, Z-axis KPI and series by')
      } else if (allowQuerying(queryStore.current, query)) {
        queryStore.current = query
        let apiCalls: Promise<any>[] = []
        try {
          apiCalls = [post('POST_ANSWERS_XYZ', query)]
        } catch (e) {
          console.error('Error fetching XYZ data.', e)
          setErrorMessage(e)
        }
        Promise.all(apiCalls)
          .then((res) => {
            if (isMounted()) {
              const data: KpiData.XYZData = res[0] as KpiData.XYZData
              dataStore.current = deepCopy(data)
              setData(data)
            }
          })
          .catch((error: any) => {
            if (isMounted()) setErrorMessage(error)
          })
          .finally(() => {
            if (isMounted()) setIsLoading(false)
          })
      } else if (isMounted()) {
        const dataSet = deepCopy(dataStore.current)
        setData(dataSet)
        setIsLoading(false)
      }

      function setErrorMessage(error: any) {
        if (error instanceof Error) {
          setError(determineErrorMessage(error.message))
        } else {
          setError('An unknown error occured')
        }
      }
    }, [
      query.z,
      query.y,
      query.x,
      query,
      query.start_date,
      query.end_date,
      query.cache,
      query.grouping,
      query.category,
      query.where_meta,
      query.time_res,
      query.series_by,
    ])

    return { data, error, isLoading, userError }
  }
}

function allowQuerying(storedQuery: Query.XYZ, currentQuery: Query.XYZ): boolean {
  if (currentQuery.cache === false) {
    return true
  }

  if (!isEqual(storedQuery, currentQuery)) {
    return true
  }

  return false
}

function determineErrorMessage(errorMessage: string): string {
  let message = 'An error was encountered.'
  if (errorMessage.includes('40')) {
    message = message.concat(' There seems to be a problem with fetching the data.')
  } else if (errorMessage.includes('50')) {
    message = message.concat(' The server is experiencing difficulties.')
  } else if (errorMessage) {
    message = errorMessage
  }
  return message
}

export default createUseXyzQuery
