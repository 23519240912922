import React from 'react'
import Rodal from 'rodal'
import {Row, Col, Button} from 'react-materialize';

const DeleteGroup = ({ group, closeDeleteGroup, handleDelete }) => {

  return (
    <div>
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={true} 
        onClose={closeDeleteGroup}
      >
        <h5>Delete group "{group.name}"?</h5>
        <Row>
          <Col s={12}><hr/></Col>
          <Col s={12}></Col>
        </Row>
      
        <br /><br />
        <Row className="center">
          <Col s={5} offset="s1">
            <Button data-cy="deleteGroup" onClick={() => handleDelete()}>Delete</Button>
          </Col>
          <Col s={5}>
            <Button flat onClick={closeDeleteGroup}>Close</Button>
          </Col>
        </Row>
      </Rodal>
    </div>
  )
}

export default DeleteGroup