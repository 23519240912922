import React, { useEffect, useRef, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import { TabularModule, TabularSettings } from '../tabularTypes'
import useCalculatedKpis from '../../../../../stores/useCalculatedKpis'
import { cloneDeep, isEqual, isUndefined } from 'lodash'
import { PrunedKpi } from '../../Pietabular/PietabularModuleContext'
import { GenericConfig } from '../../../../../../types'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

const KpiSelectionTreeData = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const tabularModule = module as TabularModule
  const tabularOptions = module?.options as TabularSettings | undefined
  const { originalCalculatedKpis } = useCalculatedKpis()

  useEffect(() => {
    if (!originalCalculatedKpis) return
    const kpiSelection = [] as kpiIdAndName[]
    const numericKpis = tabularOptions?.kpis
    if (numericKpis) {
      numericKpis.forEach((kpi) => {
        if (!isUndefined(kpi.id)) {
          kpiSelection.push({ id: kpi.id, name: kpi.name })
        }
      })
    }
    const calculatedKpis = Object.keys(tabularOptions?.calculated_kpis || {})
    if (calculatedKpis.length) {
      calculatedKpis.forEach((kpi) => {
        kpiSelection.push({ id: undefined, name: kpi })
      })
    }
    if (!selectedKpis) setSelectedKpis(kpiSelection)
  }, [originalCalculatedKpis])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const handleSaveNewSelection = (newSelection: kpiIdAndName[]) => {
    const newNumericKpis = newSelection.filter((kpi) => !isUndefined(kpi.id)) as PrunedKpi[]
    const newCalculatedKpis = {} as GenericConfig.CalculatedKpis
    newSelection.forEach((kpi) => {
      const { id, name } = kpi
      if (isUndefined(id) && originalCalculatedKpis) {
        const foundKpiFromConfig = originalCalculatedKpis[name]
        if (foundKpiFromConfig) newCalculatedKpis[name] = foundKpiFromConfig
      }
    })

    try {
      if (!tabularModule) return
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      const newModule = cloneDeep(tabularModule)
      const newOptions = {
        ...(tabularOptions || {}),
        kpis: newNumericKpis,
        calculated_kpis: newCalculatedKpis,
      }
      newModule.options = newOptions
      if (isEqual(newModule, tabularModule)) return
      timeoutRef.current = setTimeout(() => {
        saveModule(newModule)
      }, 500)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.numeric}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
