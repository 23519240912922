import React, { Fragment, useMemo } from 'react'
import { useGroupModule } from '../contexts/GroupContext'
import { useRootActions } from '../contexts/RootContext'
import { CustomFilter } from '../groupModuleTypes'
import { Chip, List } from '@mui/material'
import { cloneDeep } from 'lodash'
import { primaryModalColor } from '../../../../../../styles/variableExport'
import {
  getFilterArrayWhereMeta,
  removeMetaValuesFromFilterArray,
} from '../../../../../react-services/filterService'

import css from './MetaChipsCntr.module.scss'

const MetaChipsCntr = () => {
  const { module, isNested } = useGroupModule()[0]
  const { saveModule } = useRootActions()

  const isChipsVisible = useMemo(() => {
    const showFilters = !module?.hidefilters
    const showChips = !!module?.options?.isChipsEnabled
    const filterType = module?.customfilters
    const hasOwnMetaFilters =
      filterType === CustomFilter.CUSTOM ||
      filterType === CustomFilter.MERGE ||
      (!isNested && (filterType === CustomFilter.NONE || !filterType))
    if (showChips) return true
    if (!isNested && hasOwnMetaFilters && showFilters) return true
    return false
  }, [module?.hidefilters, module?.customfilters, module?.options?.isChipsEnabled])

  const chips = useMemo(() => {
    const selectedMetas = getFilterArrayWhereMeta(module?.settings || [])
    if (!selectedMetas) return []
    const newChipLists = Object.entries(selectedMetas).map(([metakey, metaValues]) => {
      const newChipList = metaValues.map((metaValue) => {
        return (
          <Chip
            size='small'
            label={metaValue}
            key={metaValue}
            onDelete={() => handleClickDeteteChip(metakey, metaValue)}
            sx={{ color: primaryModalColor }}
            data-testid='metaValueChip'
          />
        )
      })
      if (!newChipList.length) return <Fragment key={metakey} />
      return (
        <List key={metakey} sx={{ padding: '5px 30px 5px 0', display: 'inline-block' }}>
          {newChipList}
        </List>
      )
    })
    return newChipLists
  }, [module?.settings])

  const handleClickDeteteChip = (key: string, value: string) => {
    if (!module) return
    const newModule = cloneDeep(module)
    if (!newModule.settings) return
    const newFilters = removeMetaValuesFromFilterArray(newModule.settings, key, [value])
    newModule.settings = newFilters
    saveModule(newModule)
  }
  if (!isChipsVisible) return <></>
  return <div className={css.cntr}>{chips}</div>
}

export default MetaChipsCntr
