import React from 'react'
import css from './ToolbarIcon.module.scss'

type ToolbarIconProps = {
  icon: string
  title: string
  onClick: (() => void) | (() => Promise<void>)
  [prop: string]: unknown
}

const ToolbarIcon = ({ icon, title, onClick, ...props }: ToolbarIconProps) => {
  return (
    <ToolbarIconWrapper>
      <i
        className={'material-icons material-symbols-outlined ' + css.icon}
        style={{}}
        title={title}
        onClick={onClick}
        {...props}
      >
        {icon}
      </i>
    </ToolbarIconWrapper>
  )
}

type ToolbarIconWrapperProps = {
  children: JSX.Element
}

const ToolbarIconWrapper = ({ children }: ToolbarIconWrapperProps) => (
  <div className={css.wrapper}>{children}</div>
)

export default ToolbarIcon
