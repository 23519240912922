import { useState } from 'react'
import { GenericConfig } from '../../types'
import { useDeepCompareEffect } from 'react-use'
import { makeNonResettingObservable } from './nonResettingObservable'
import { getCalculatedKpis } from '../react-services/kpiService'
import useStoreOwner from './useStoreOwner'
import useStoreNonResetting, { ActionState } from './useStoreNonResetting'

export type CalculatedKpi = { name: string; kpiIds?: number[]; function: string }

const calculatedKpiStorage = makeNonResettingObservable<GenericConfig.CalculatedKpis | null>({})

const useCalculatedKpis = () => {
  const key = 'calculatedKpis'
  const [calculatedKpis, setCalculatedKpis] = useState<CalculatedKpi[] | null>(null)
  const { tenant, user } = useStoreOwner(calculatedKpiStorage)
  const {
    dataState: { data, error, state },
  } = useStoreNonResetting(calculatedKpiStorage, key, user, tenant, true, getCalculatedKpis)

  useDeepCompareEffect(() => {
    if (!data) return
    const listedCalculatedKpis = Object.entries(data).map(([kpiName, details]) => {
      return {
        name: kpiName,
        ...(details || {}),
      }
    })
    setCalculatedKpis(listedCalculatedKpis)
  }, [data || {}])

  const isLoading = state !== ActionState.DONE && state !== ActionState.ERROR
  return { originalCalculatedKpis: data, calculatedKpis, error, isLoading }
}

export default useCalculatedKpis
