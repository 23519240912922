import React from 'react'
import Select from 'react-select'
import useReportingFilters from '../../../../../stores/useReportingFilters'
import { useRadarSettings } from '../RadarContext/RadarSettingsContext'
import { MetaSelect } from '../radarTypes'
import css from './RadarSettings.module.scss'

const MetaMode = () => {
  const { metas, error: metasError } = useReportingFilters()
  const [
    { radarSettings, radarMetaKey, radarMetaKeyValues, radarMetaKeyValuesSelected, saveSettings },
    setRadarSettings,
  ] = useRadarSettings()

  const handleMetaKeyChange = (metaKey: MetaSelect) => {
    if (!metas) return

    const newMetaKey = metaKey.value
    const newMetaValues = metas[newMetaKey]

    setRadarSettings((prev) => ({
      ...prev,
      radarMetaKey: newMetaKey,
      radarMetaKeyValues: newMetaValues,
      radarMetaKeyValuesSelected: [],
    }))

    if (saveSettings) {
      const newSettings = {
        ...radarSettings,
        radarMetaKey: newMetaKey,
        radarMetaKeyValues: newMetaValues,
        radarMetaKeyValuesSelected: [],
      }
      saveSettings(newSettings)
    }
  }

  const handleMetaValueChange = (metaValues: MetaSelect[]) => {
    if (metaValues) {
      setRadarSettings((prev) => ({
        ...prev,
        radarMetaKeyValuesSelected: metaValues.map((meta) => meta.value),
      }))
      if (saveSettings) {
        const newSettings = {
          ...radarSettings,
          radarMetaKeyValuesSelected: metaValues.map((meta) => meta.value),
        }
        saveSettings(newSettings)
      }
    }
  }

  return (
    <>
      <div className={css.setting_row}>
        <div className={css.option}>
          <i className='material-icons'>key</i>
          <span> Metakey</span>
        </div>
        <div className={css.select} data-testid='radar-select-metakey'>
          {metas && Object.keys(metas).length && !metasError ? (
            <Select
              className='react-select-default-styles'
              placeholder='Select a meta key'
              options={Object.keys(metas).map((key) => ({ label: key, value: key }))}
              value={{ label: radarMetaKey, value: radarMetaKey }}
              onChange={(metaKey) => handleMetaKeyChange(metaKey as MetaSelect)}
            />
          ) : (
            <span>No metadata was found...</span>
          )}
        </div>
      </div>

      {radarMetaKeyValues && radarMetaKeyValues.length ? (
        <div className={css.setting_row}>
          <div className={css.option}>
            <i className='material-icons'>vpn_key</i>
            <span> Meta values</span>
          </div>
          <div className={css.select} data-testid='radar-select-metavalues'>
            <Select
              className='react-select-default-styles'
              isMulti
              isClearable
              closeMenuOnSelect={false}
              placeholder='Select meta values'
              isOptionDisabled={() => radarMetaKeyValuesSelected.length >= 5}
              options={radarMetaKeyValues.map((value) => ({ label: value, value: value }))}
              value={radarMetaKeyValuesSelected.map((value) => ({ label: value, value: value }))}
              onChange={(metaValues) => handleMetaValueChange(metaValues as MetaSelect[])}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default MetaMode
