import React from 'react'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { topBotMaxResultInfo } from '../TopBottomNotifications'
import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { TopBottomModule, TopBottomModuleSettings } from '../topBottomTypes'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'

const SelectMaxResults = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const topbotModule = module as TopBottomModule
  const options = module?.options ? (module.options as TopBottomModuleSettings) : null
  const currentMax = options?.topBottomMax

  const handleMaxResult = (value: string) => {
    if (!module || Number(value) < 0) return
    const newMaxValue = value
    saveModule({
      ...topbotModule,
      options: { ...topbotModule.options, topBottomMax: newMaxValue },
    })
  }

  return (
    <div className={`${modal_css.third}`}>
      <TextWithInfo text='Max results' info={topBotMaxResultInfo} tooltipDirection='top' />
      <RequiredStar />
      <input
        data-testid='topBottomMax'
        type='number'
        value={currentMax}
        id='topBottomMax'
        onChange={(event) => handleMaxResult(event.target.value)}
        className={`${css.input}`}
      />
    </div>
  )
}

export default SelectMaxResults
