/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import AlertRow from './AlertRow'
import { AlertTool } from '../../../../types'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import NoData from '../../_shared/Infos/NoData'

type Props = {
  AlertData: AlertTool.AlertData | undefined
  edit: (alertName: string) => void
  show: (alertName: string) => void
}

function AlertTable({ AlertData, edit, show }: Props) {
  const parseOperator = (comparisonOp: string) => {
    const compOpList = [
      { value: 'EQ', label: '=' },
      { value: 'NE', label: '≠' },
      { value: 'GT', label: '>' },
      { value: 'GTE', label: '≥' },
      { value: 'LT', label: '<' },
      { value: 'LTE', label: '≤' }
    ];

    const result = compOpList.find(e => e.value === comparisonOp)
    return result ? result.label : ''
  }

  // sorting disabled for headers... sortings break everything for some reason
  const headers = (
    <tr>
      <th onClick={() => { }} colSpan={3}>Name</th>
      <th onClick={() => { }} colSpan={4}>KPI</th>
      <th onClick={() => { }} colSpan={1}>KPI value</th>
      <th onClick={() => { }} colSpan={3}>Filter</th>
      <th onClick={() => { }} colSpan={4}>Recipients</th>
      <th onClick={() => { }} className="tool-icon-column"></th>
      <th onClick={() => { }} className="tool-icon-column"></th>
    </tr>
  )

  const getRows = () => {
    if (!AlertData) return []
    return (
      AlertData.filteredAlerts.map((alert) => {
        const { questionId, name, filter, recipients, value, comparisonOp } = alert;
        const kpi = AlertData.kpis.find(({ id }) => id === questionId);
        return (
          <AlertRow
            name={name}
            key={name}
            kpiName={kpi ? kpi.name : questionId}
            kpiValue={`${parseOperator(comparisonOp)} ${value}`}
            filter={filter}
            recipients={recipients ? recipients : []}
            edit={() => edit(alert.name)}
            show={() => show(alert.name)}
          />
        )
      }))
  }


  return (
    <div>
      <GenericTable
        useFixedLayout
        headers={headers}
        rows={getRows()}
      />
      {!AlertData &&
        <NoData />
      }
    </div >
  )
}

export default AlertTable
