/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { get, put } from '../../../react-services/apiService'
import ReportTable from './ReportTable'
import { ReportTool } from '../../../../types'
import LoadingIndicator from '../../_shared/Infos/LoadingIndicator'
import { WEEKDAYS } from '../../../react-constants/weekdays'
import { toTimeString, formatDay, toFormatString } from '../../../react-services/reportsService'
import useDbTemplateConfig, { Dashboard, DbTemplate } from '../../../stores/useDbTemplateConfig'

const createDashboardsArr = (dbtemplate: DbTemplate | null): Dashboard[] => {
  if (!dbtemplate) return []
  const dashboards = []
  for (const templateKey in dbtemplate) {
    const templates = dbtemplate[templateKey]
    if (!templates) continue
    for (const template of templates) {
      const currentDashboards = template.dashboards
      if (currentDashboards) dashboards.push(...currentDashboards)
    }
  }
  return dashboards
}

function Report() {
  const [reports, setReports] = useState<Record<string, ReportTool.Report>>()
  // const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const { config: dbtemplate } = useDbTemplateConfig()
  const dashboards = createDashboardsArr(dbtemplate)

  useEffect(() => {
    setIsLoading(true)

    get('GET_REPORTS')
      .then((res) => {
        setReports(res)
      })
      .catch((err) => {
        console.error(err)
        // setError("Failed to get reports")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleChangeRecipients = (key: string, recipients: string[]) => {
    setIsLoading(true)
    // setError(undefined)
    put('PUT_REPORTS', { value: { [key]: { recipients } } })
      .then((res: any) => {
        setReports(res)
      })
      .catch((err: any) => {
        console.error(err)
        // setError("Failed to change recipients")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const createReportTableRows = (
    reports: { [name: string]: ReportTool.Report },
    dashboards: ReportTool.Dashboard[],
  ): ReportTool.ReportTableRow[] => {
    const keys = Object.keys(reports)
    const result = keys.map((key) => {
      const report = reports[key]
      return {
        sendingSchedule: formatSchedule(report.schedule),
        recipients: createRecipientsArr(report),
        format: toFormatString(report.format) as ReportTool.format | undefined,
        dashboardUuid: report.dashboard_uuid,
        dashboardName: createDashboardName(dashboards, report),
        hasContactTableMetakey:
          typeof report.contact_table_metakey !== 'undefined' &&
          report.contact_table_metakey !== '',
        dashboardFullName: key,
        id: key,
        lastRan: report.schedule?.lastRan,
      }
    })
    return result
  }

  const createDashboardName = (
    dashboards: ReportTool.Dashboard[],
    report: ReportTool.Report,
  ): string | undefined => {
    // eslint-disable-next-line eqeqeq
    if (report.dashboard_uuid == null) {
      return undefined
    }
    return dashboards.find((d) => d.uuid === report.dashboard_uuid)?.name
  }

  const createRecipientsArr = (report: ReportTool.Report): string[] => {
    const emails: string[] = report.recipients ?? []
    const contactTableMetakey = report.contact_table_metakey ? [report.contact_table_metakey] : []
    return [...contactTableMetakey, ...emails]
  }

  const formatSchedule = (schedule?: ReportTool.Schedule): string => {
    // eslint-disable-next-line eqeqeq
    if (schedule == null) return ''

    const dayOfWeek = schedule.dayOfWeek ? WEEKDAYS[schedule.dayOfWeek - 1] : undefined
    const dayOfMonth = schedule.dayOfMonth ? formatDay(schedule.dayOfMonth) : undefined
    const hour = schedule.hour ?? 0
    const minute = schedule.minute ?? 0
    const time = toTimeString(hour, minute)
    if (dayOfWeek?.length) {
      return `On ${dayOfWeek}s at ${time}`
    }
    if (dayOfMonth?.length) {
      return `${dayOfMonth} day monthly at ${time}`
    }
    return ''
  }

  const reportTableRows = reports ? createReportTableRows(reports, dashboards) : []

  return (
    <div>
      <br />
      <br />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <ReportTable reports={reportTableRows} onChangeRecipients={handleChangeRecipients} />
      )}
    </div>
  )
}

export default Report
