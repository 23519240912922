const MODULECONSTANTS = {
  AXIS_VALUES: [
    {
      value: 'avg',
      name: 'Average'
    },
    {
      value: 'sum',
      name: 'Sum'
    },
    {
      value: 'n',
      name: 'Count'
    }
  ],
  /*  Legacy frontend Highcharts colors; don't use
  */
  SERIES_COLORS: [
    '#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9',
    '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'
  ],
  /*  ..use these instead
  */
  // Green/red colors commented out
  SERIES_COLORS2: [
    //'rgb(100,140,50)', 'rgb(140,170,70)',
    'rgb(180,210,220)', 'rgb(140,150,140)', 'rgb(150,130,70)',
    'rgb(220,190,70)', 'rgb(250,130,0)', //'rgb(250,80,30)',
    'rgb(250,60,180)',
    'rgb(200,80,255)', 'rgb(110,110,255)', 'rgb(0,150,255)', 'rgb(0,200,200)',
    //'rgb(40,200,100)'
  ],
  SERIES_COLORS_REPORT: ['#B333FF','#FFA600','#55BBFF','#66E300','#5082A0'],
  SERIES_COLORS_TRAFFICLIGHTS: [
    'rgb(250,80,30)', 'rgb(40,200,100)', 'rgb(180,210,220)'
  ],
  BG_COLOR: 'transparent',
  PLOT_BORDER_COLOR: 'rgba(0,0,0,0.3)',
  TIME_SERIES_GROUPS: ['day', 'week', 'month', 'quarter', 'year'],
  SERIES_SYMBOLS: ['circle', 'square', 'diamond', 'triangle'],
  SERIES_SYMBOLS2: ['square']
}

const SENTIMENTS = [
  "Negative",
  "Neutral",
  "Positive"
]

export {
  MODULECONSTANTS,
  SENTIMENTS,
}
