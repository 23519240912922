import { Button, styled } from '@mui/material'
import React from 'react'
import { primaryModalColor } from '../../../../../../styles/variableExport'
import { pSBC } from '../../../../../react-services/colorService'

import css from './ChartButton.module.scss'

const StyledButton = styled(Button)`
  border-right: 1px solid #fff;
  border-radius: 0;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 7px;
  &:focus {
    background: ${pSBC(0.6, primaryModalColor)};
    color: ${pSBC(-0.3, primaryModalColor)};
  }
`

type ChartButtonProps = {
  text: string
  info?: string
  isLast?: boolean
  onClick?: (event: React.MouseEvent<unknown, MouseEvent>) => void
}
const ChartButton = ({ text, isLast = false, onClick }: ChartButtonProps) => {
  return (
    <div className={css.fadeIn}>
      <StyledButton
        color='primary'
        data-testid='chartButton'
        variant='contained'
        sx={{
          ...(isLast ? { borderRight: 'none' } : {}),
          opacity: 0.88,
          maxWidth: '220px',
          minWidth: '40px',
        }}
        onClick={(e) => {
          e.preventDefault()
          e.currentTarget.blur()
          onClick && onClick(e)
        }}
      >
        {text.slice(0, 30)}
      </StyledButton>
    </div>
  )
}

export default ChartButton
