/* eslint-disable @typescript-eslint/no-explicit-any */
import { MetaOptionType, WhereMeta } from '../../types'
import { BLACKLIST } from '../react-constants/metadata'
import { get, post } from './apiService'
import { isStringArray, isWhereMetaObject } from '../utilities'
import { DynamicFiltersQuery, DynamicFiltersResponse } from '../stores/useDynamicFilters'
import { reportingFilterStorage } from '../stores/useReportingFilters'

type Metadata = {
  [metaKey: string]: string[]
}

function getGroupByMetaSelections(): MetaOptionType[] {
  const allFilters = reportingFilterStorage.get('reportingFilters')

  const groupings: MetaOptionType[] =
    allFilters && allFilters.filters
      ? Object.keys(allFilters.filters)
          .filter((f) => !BLACKLIST.includes(f))
          .map((f) => ({ label: f, value: f }))
      : []
  groupings.sort((a, b) => (a.value > b.value ? 1 : -1))
  return groupings
}

const getMetaDataKeysAndValues = (): Promise<Metadata> => {
  try {
    return get('GET_METAS')
      .then((res) => res.metas)
      .catch(() => Promise.reject('metaDataService: There was an issue with getting the metadatas'))
  } catch (e: any) {
    return Promise.reject('metaDataService: There was an issue with getting the metadatas')
  }
}

const getMetaKeys = (): Promise<string[]> => {
  try {
    return get('GET_META_KEYS')
      .then((res: unknown) => {
        if (!res || typeof res !== 'object' || !('keys' in res))
          return Promise.reject('No meta key list found, something went wrong')
        const { keys } = res as { keys: unknown } // cast to get rid of error after type check
        if (!isStringArray(keys))
          return Promise.reject('Not a string array, data is in wrong format')
        if (keys.length === 0) return Promise.reject('No meta keys found, something went wrong')
        return keys
      })
      .catch((err) => (typeof err === 'string' ? Promise.reject(err) : Promise.reject(err.message)))
  } catch (error) {
    return Promise.reject('These was a problem fetching meta keys')
  }
}

const getReportingFiltersDynamic = (
  query: DynamicFiltersQuery,
  isExpanded: boolean,
): Promise<DynamicFiltersResponse> => {
  try {
    return post('POST_DYNAMICFILTERS', query, { 'is-expanded': isExpanded })
      .then((res: unknown) => {
        if (!res || typeof res !== 'object' || !('filters' in res))
          return Promise.reject('No dynamic filters found, something went wrong')
        const { filters } = res as { filters: unknown } // cast to get rid of error after type check
        if (!isWhereMetaObject(filters))
          return Promise.reject('Not a where meta object, data is in wrong format')
        const filteredFilters = {} as WhereMeta
        Object.entries(filters).forEach(([key, value]) => {
          if (!BLACKLIST.includes(key)) filteredFilters[key] = value
        })
        return { filters: filteredFilters }
      })
      .catch((err: any) =>
        typeof err === 'string' ? Promise.reject(err) : Promise.reject(err?.message),
      )
  } catch (error) {
    return Promise.reject('These was a problem when trying to fetch dynamic filters')
  }
}

export {
  getGroupByMetaSelections,
  getMetaDataKeysAndValues,
  getMetaKeys,
  getReportingFiltersDynamic,
}
