import { useMemo } from 'react'
import { DynamicTranslationNameSpace, TranslationNameSpace } from '../../../../../languages/i18n'
import useDashboardSections from '../../../../stores/useDashboardSections'
import useOpenCategories from '../../../../stores/useOpenCategories'
import useMetaKeys from '../../../../stores/useMetaKeys'
import { Module, ModuleType } from '../../../Dashboards/DashboardModules/Group/groupModuleTypes'
import useConfigCustomerPath from '../../../../stores/useConfigCustomerPath'
import useNumericKpis from '../../../../stores/useNumericKpis'
import useOpenKpis from '../../../../stores/useOpenKpis'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'

type useTranslatableListsProps = {
  namespace: DynamicTranslationNameSpace
}
const useTranslatableLists = ({ namespace }: useTranslatableListsProps) => {
  const { sections } = useDashboardSections()
  const { answerCategories } = useOpenCategories()
  const { metaKeys } = useMetaKeys()
  const { config: customerPath } = useConfigCustomerPath()
  const { numericKpis } = useNumericKpis()
  const { openKpis } = useOpenKpis()
  const { calculatedKpis } = useCalculatedKpis()

  const translatableList: string[] = useMemo(() => {
    switch (namespace) {
      case TranslationNameSpace.TEMPLATES:
        return getTemplateTranslatableList()
      case TranslationNameSpace.CATEGORIES:
        return getCategoriesTranslatableList()
      case TranslationNameSpace.DATA:
        return getMetaKeysTranslatableList()
      case TranslationNameSpace.MODULES:
        return getModulesTranslatableList()
      case TranslationNameSpace.KPIS:
        return getKpiTranslatableList()
      default:
        return []
    }
  }, [
    namespace,
    sections,
    answerCategories,
    customerPath,
    metaKeys,
    numericKpis,
    openKpis,
    calculatedKpis,
  ])

  function getTemplateTranslatableList() {
    const templateTranslatableList = [] as string[]
    if (!sections) return templateTranslatableList
    sections.forEach((section) => {
      if (section.name) templateTranslatableList.push(section.name)
      if (section.dashboards) {
        const dashboards = section.dashboards
        dashboards.forEach((dashboard) => {
          if (dashboard.name) templateTranslatableList.push(dashboard.name)
        })
      }
    })
    return handleLowerCaseAndUniqueAndSortToTranslatableList(templateTranslatableList)
  }

  function getCategoriesTranslatableList() {
    const categoriesTranslatableList = ['uncategorized', 'other'] as string[]
    if (!answerCategories) return categoriesTranslatableList
    Object.values(answerCategories || {})
      .flatMap((categories) => categories)
      .forEach((category) => {
        categoriesTranslatableList.push(category)
      })
    Object.keys(answerCategories).forEach((category) => {
      categoriesTranslatableList.push(category)
    })

    customerPath?.conf?.forEach((kpiCategory) => {
      if (kpiCategory.category) categoriesTranslatableList.push(kpiCategory.category)
    })
    customerPath?.open_conf?.forEach((openKpi) => {
      if (openKpi.name) categoriesTranslatableList.push(openKpi.name)
    })
    return handleLowerCaseAndUniqueAndSortToTranslatableList(categoriesTranslatableList)
  }

  function getMetaKeysTranslatableList() {
    const metaKeysTranslatableList = [] as string[]
    if (!metaKeys) return metaKeysTranslatableList
    metaKeys.forEach((metaKey) => {
      metaKeysTranslatableList.push(metaKey)
    })
    return handleLowerCaseAndUniqueAndSortToTranslatableList(metaKeysTranslatableList)
  }

  function getModulesTranslatableList() {
    const modulesTranslatableList = [] as string[]
    if (!sections) return modulesTranslatableList
    sections.forEach((section) => {
      if (section.dashboards) {
        const dashboards = section.dashboards
        dashboards.forEach((dashboard) => {
          if (dashboard.modules) {
            const modules = dashboard.modules
            modulesTranslatableList.push(...getNestedModuleTranslatables(modules))
          }
        })
      }
    })
    return handleLowerCaseAndUniqueAndSortToTranslatableList(modulesTranslatableList)
  }

  function getNestedModuleTranslatables(modules: Module[]) {
    const moduleTranslatables = [] as string[]
    modules.forEach((module) => {
      if (module.title) moduleTranslatables.push(module.title)
      if (module.type === ModuleType.TEXTBOX && module.data) moduleTranslatables.push(module.data)
      if (module.type === ModuleType.GROUP && module.modules) {
        moduleTranslatables.push(...getNestedModuleTranslatables(module.modules))
      }
    })
    return moduleTranslatables
  }

  function getKpiTranslatableList() {
    const kpiTranslatableList = [] as string[]
    numericKpis?.forEach((kpi) => {
      if (kpi.name) kpiTranslatableList.push(kpi.name)
    })
    openKpis?.forEach((kpi) => {
      if (kpi.name) kpiTranslatableList.push(kpi.name)
    })
    calculatedKpis?.forEach((kpi) => {
      if (kpi.name) kpiTranslatableList.push(kpi.name)
    })
    return handleLowerCaseAndUniqueAndSortToTranslatableList(kpiTranslatableList)
  }

  function handleLowerCaseAndUniqueAndSortToTranslatableList(translatableList: string[]): string[] {
    const lowerCasedTranslatableList = translatableList.map((translatable) =>
      translatable.toLowerCase(),
    )
    const uniqueTranslatableList = Array.from(new Set(lowerCasedTranslatableList))
    return uniqueTranslatableList.sort()
  }
  return translatableList
}

export default useTranslatableLists
