import { PietabularModuleSettings } from './PietabularModuleContext'

const usePietabularSettingsValidator = (settings: PietabularModuleSettings) => {
  const settingsValidator = (settings: PietabularModuleSettings) => {
    return (
      !!settings &&
      !!settings.defaultCountedKey &&
      !!settings.defaultMetaGroup &&
      !!settings.metaKeys &&
      !!settings.metaKeys.length &&
      !!settings.numberOfTopTopicsToShow &&
      !isNaN(settings.numberOfTopTopicsToShow) &&
      !!settings.openCategories &&
      !!settings.openCategories.length &&
      settings.tolerance !== null &&
      settings.tolerance !== undefined &&
      settings.tolerance !== '' &&
      typeof settings.showTotalCount === 'boolean' &&
      !!(settings.defaultSortColumn !== null && settings.defaultSortColumn !== undefined) &&
      !isNaN(settings.defaultSortColumn) &&
      settings.defaultSortColumn > 0 &&
      typeof settings.defaultSortIsAscending === 'boolean' &&
      typeof settings.showTotalCount === 'boolean'
    )
  }

  return { isValidSettings: settingsValidator(settings) }
}

export default usePietabularSettingsValidator
