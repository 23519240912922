// useFrequencyCounts

import { useEffect, useRef, useState } from 'react'
import { KpiData, Query, Response } from '../../types'
import { makeNonResettingObservable } from './nonResettingObservable'
import objectHash from 'object-hash'
import { getTenant, getUsername } from '../react-services/authService'
import { errorGettingMetaFrenquencies } from './notificationMessages'
import { subMinutes } from 'date-fns'
import { useMountedState, useUpdateEffect } from 'react-use'
import { getMetaFrequencies } from '../react-services/frequenciesService'

const OUTDATED_DATA_TIME_MINS = 5
type MetaFrequencyDataStorageType = {
  counts: Response.FrequenciesNumeric
  timestamp: Date
} | null

const metaFrequencyDataStorage = makeNonResettingObservable<MetaFrequencyDataStorageType>(
  {},
  OUTDATED_DATA_TIME_MINS,
)

const enum LoadingState {
  INIT = 'init',
  LOADING = 'loading',
  REFETCHING = 'refetching',
  DONE = 'done',
}

const useMetaFrequencyCounts = (
  query: Query.Query2Payload | null,
  FETCH_TIMEOUT = 100,
  resetWhenNewQuery = false,
) => {
  const isMounted = useMountedState()
  const fetchTimeoutRef = useRef<NodeJS.Timeout>()
  const tenant = getTenant() || ''
  const user = getUsername() || ''
  const key = objectHash(query, { unorderedArrays: true, unorderedObjects: true })
  const [data, setData] = useState<{ counts: KpiData.NumericData; timestamp: Date } | null>(null)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<LoadingState>(LoadingState.INIT)

  useEffect(() => {
    const owner = metaFrequencyDataStorage.getOwner()
    if (!owner.tenant && !owner.user) metaFrequencyDataStorage.reset()
    if (owner.tenant !== tenant || owner.user !== user) metaFrequencyDataStorage.reset()
    metaFrequencyDataStorage.setOwner(tenant, user)
  }, [tenant, user])

  useEffect(() => {
    if (!query || !query.start_date || !query.end_date || (!user && !tenant)) {
      setLoading(LoadingState.DONE)
      setData(null)
      return
    }
    if (!data) setLoading(LoadingState.LOADING)
    if (data) setLoading(LoadingState.REFETCHING)
    if (resetWhenNewQuery) setData(null)

    metaFrequencyDataStorage.removeOutDatedData()
    const hasListenersAlready = !!metaFrequencyDataStorage.getRelatedListeners(key)
    const storedData = metaFrequencyDataStorage.get(key)
    if (!hasListenersAlready && storedData) {
      const storeTime = storedData.timestamp
      const currentTime = new Date()
      const hasOutDatedData = storeTime < subMinutes(currentTime, OUTDATED_DATA_TIME_MINS)
      if (fetchTimeoutRef.current) clearTimeout(fetchTimeoutRef.current)
      fetchTimeoutRef.current = setTimeout(() => {
        hasOutDatedData && getMetaCounts(query, key)
      }, FETCH_TIMEOUT)
      !hasOutDatedData && setData(storedData)
      !hasOutDatedData && setLoading(LoadingState.DONE)
    }
    if (!hasListenersAlready && !storedData) {
      if (fetchTimeoutRef.current) clearTimeout(fetchTimeoutRef.current)
      fetchTimeoutRef.current = setTimeout(() => {
        getMetaCounts(query, key)
      }, FETCH_TIMEOUT)
    }
    if (hasListenersAlready && storedData) {
      setData(storedData)
      setLoading(LoadingState.DONE)
    }
    return metaFrequencyDataStorage.subscribe(setData, key)
  }, [key])

  useUpdateEffect(() => {
    if (data) setLoading(LoadingState.DONE)
  }, [data])

  useEffect(() => {
    return () => fetchTimeoutRef.current && clearTimeout(fetchTimeoutRef.current)
  }, [])

  const getMetaCounts = (query: Query.Query2Payload, key: string) => {
    return getMetaFrequencies(query)
      .then((data) => {
        if (!isMounted()) return
        const timestamp = new Date()
        const newDataWithTimeStamp = { timestamp, counts: data }
        setData(newDataWithTimeStamp)
        metaFrequencyDataStorage.set(newDataWithTimeStamp, key)
      })
      .catch((e: unknown) => {
        if (isMounted() && typeof e === 'string') setError(e)
        if (isMounted() && typeof e !== 'string') setError(errorGettingMetaFrenquencies)
      })
      .finally(() => isMounted() && setLoading(LoadingState.DONE))
  }

  return {
    metaFrequencyCounts: data ? data.counts : null,
    timestamp: data ? data.timestamp : null,
    isLoading: loading === LoadingState.INIT || loading === LoadingState.LOADING,
    isRefetching: loading === LoadingState.REFETCHING,
    error,
  }
}

export default useMetaFrequencyCounts
