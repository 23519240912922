import React from 'react'
import { useSummaryData } from '../Context/SummaryDataContext'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import useSummaryLanguage from '../useSummaryLanguage'
import useConfigCustomerPath, {
  CustomerPathConfig,
} from '../../../../../stores/useConfigCustomerPath'

import css from './SummaryPath.module.scss'
import { upperFirst } from 'lodash'

const SummaryCustomerPath = () => {
  const [{ saveSettings, isCustomerPathEnabled }] = useSummaryData()
  const [{ summarySettings, filterPath, language }, setSummaryFilters] = useSummaryFilters()
  const { t } = useSummaryLanguage(language)
  const { config } = useConfigCustomerPath()
  const openCustomerPath: CustomerPathConfig['open_conf'] = config?.open_conf

  const handlePathSelection = (path: string) => {
    if (!path) return
    setSummaryFilters((prev) => ({
      ...prev,
      filterPath: path,
    }))

    if (saveSettings) {
      const newSettings = {
        ...summarySettings,
        filterPath: path,
      }
      saveSettings(newSettings)
    }
  }

  return (
    <>
      {openCustomerPath && isCustomerPathEnabled !== false ? (
        <div className={css.summary_module_customer_path}>
          <div className={css.customer_path}></div>
          <div className={css.customer_path_items}>
            {openCustomerPath.map((path) => (
              <div
                key={path.name}
                className={`${css.path_item} ${
                  filterPath === path.name ? css.active : css.inactive
                }`}
                onClick={() => handlePathSelection(path.name)}
                data-testid={`path-${path.name}`}
              >
                <div className={css.path_image_cntr}>
                  <img className={css.path_image} src={path.iconUrl} />
                </div>
                <span className={css.path_title}>{upperFirst(t(path.name))}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={css.empty}></div>
      )}
    </>
  )
}

export default SummaryCustomerPath
