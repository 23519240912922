import React, { Component } from 'react';
import { Col, Row, Input } from 'react-materialize';

export default class MetadataRibbonButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      metaValue: props.metaValue,
    }
    this.inputBuffer = null;

    this.toggleButtonExpansion = this.toggleButtonExpansion.bind(this)
    this.handleMetaValueChange = this.handleMetaValueChange.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.expandedButtonIndex !== this.props.index && this.state.isExpanded) {
      this.setState({
        isExpanded: false,
      })
    }
  }

  toggleButtonExpansion() {
    this.setState({
      isExpanded: !this.state.isExpanded
    })
    this.props.onExpand(this.props.index)
  }

  handleMetaValueChange(e, value) {
    if (this.props.isImmutable) return;

    clearTimeout(this.inputBuffer);
    this.inputBuffer = setTimeout(() => {
      this.setState({
        metaValue: value
      })
      this.props.onChange({[this.props.metaKey]: value})
    }, 250)
  }

  render() {
    let {index, metaKey, metaValues, isImmutable} = this.props;

    const getMetadataInput = () => {
      if (metaValues && metaValues.length > 0) {
        return (
          <>
            <label>{metaKey}</label>
            <br/>
            <select className="browser-default" defaultValue={this.state.metaValue} onChange={(e) => {this.handleMetaValueChange(e, e.target.value)}}>
              {metaValues.map((mv, i) => <option key={`metaValue-${mv}-${i}`} value={mv}>{mv}</option>)}
            </select>
          </>
        )
      } else {
        return (
          <>
            <label>{metaKey}</label>
            <Input
              className="metadata-expansion-offset-label"
              disabled={isImmutable}
              s={11}
              type="text"
              placeholder={metaKey}
              defaultValue={this.state.metaValue}
              onChange={this.handleMetaValueChange}
            />
          </>
        )
      }
      
    }

    return (
      <>
        <Col
          key={`meta-${index}`} 
          s={4} 
          className={`center metadata-ribbon-button waves-effect ${isImmutable ? 'waves-cyan cyan' : 'waves-teal teal'}  ${this.state.isExpanded ? '' : 'lighten-2'}`}
          onClick={this.toggleButtonExpansion}
        >
          <span className="metadata-ribbon-button-text btn-flat">
            {this.state.metaValue ? this.state.metaValue : <i style={{fontSize: '14px'}}>empty</i>}
          </span><br/>
          <div className={`metadata-ribbon-button-expander ${this.state.isExpanded ? 'white-text' : isImmutable ? 'cyan-text' : 'teal-text'}`}><i className="material-icons">{`${this.state.isExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}`}</i></div>
        </Col>

        {this.state.isExpanded &&
          <div className={`metadata-button-expansion ${isImmutable ? 'cyan' : 'teal'}`} id={`metadata-button-expansion-${index + 1}`}>
            <div className="metadata-expansion-container">
              <Row>
                <Col className="metadata-button-expansion-overflow-container" s={10} offset="s1">
                  <Row className="metadata-button-expansion-input" onClick ={() => {isImmutable && window.Materialize.toast('This is contact information and cannot be modified.')}}>
                    {getMetadataInput()}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        }
      </>
    );
  }
}