export function isValidEmail(email: string): boolean {
  // eslint-disable-next-line
  const emailRegex: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
  const matches: RegExpMatchArray | null = (email || '').match(emailRegex);
  return (matches || ['']).includes(email);
}

export function validateEmail(recipient: string, recipients?: string[]): string | null {
  if (!isValidEmail(recipient)) return ' is an invalid email.'
  if (recipients && !isUnique<string>(recipient, recipients)) return ' is not unique email.'
  if (recipient.replace(/[^%]/g, '').length > 1 || recipient.startsWith('%')) return ' is an invalid email.'
  return null
}

function isUnique<T>(item: T, array: T[]) {
  return array.filter(e => e === item).length < 2
}

export function isValidNumber(value: string): string | undefined {
  if (isNaN(parseInt(value)))
    return 'Must\xA0be\xA0a\xA0valid\xA0number'
  return
}