import React, { useState } from 'react'
import { Row, Col, Button } from 'react-materialize'
import Rodal from 'rodal'
import SelectionTree, { VALIDKPITYPES, VALIDSELECTIONTYPES } from './SelectionTree'
import MainButton from '../Buttons/MainButton'
import { isEmpty } from 'lodash'
import useNumericKpis from '../../../stores/useNumericKpis'
import useOpenKpis from '../../../stores/useOpenKpis'
import { Kpi } from '../../../../types'
import { CalculatedKpi } from '../../../stores/useCalculatedKpis'

import './SelectKpi.css'
import { tCommon } from '../../../../languages/i18n'

type SelectKpiButtonProps = {
  float?: string
  selectedKpis: number[] | Kpi.Kpi[]
  onSelectKpi: (selectedList: Kpi.Kpi[]) => void
  kpiType?: VALIDKPITYPES
}

const SelectKpiButton = ({ onSelectKpi, selectedKpis, float, kpiType }: SelectKpiButtonProps) => {
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal((prev) => !prev)
  const { numericKpis } = useNumericKpis()
  const { openKpis } = useOpenKpis()

  function getCheckedKpis(kpis: number[] | Kpi.Kpi[]) {
    if (!isEmpty(kpis) && typeof kpis[0] === 'number') {
      let allKpis = [...(numericKpis || []), ...(openKpis || [])]
      const selectedKpiIds = kpis.map((kpi) => (typeof kpi === 'number' ? kpi : kpi.id))
      allKpis = allKpis.filter((kpi) => selectedKpiIds.includes(kpi.id))
      return allKpis
    } else {
      return []
    }
  }

  function handleSelectKpis(selectedList: (Kpi.Kpi | CalculatedKpi)[]) {
    const selectedNonCalculatedKpis = [] as Kpi.Kpi[]
    selectedList.forEach((kpi) => 'id' in kpi && selectedNonCalculatedKpis.push(kpi))
    onSelectKpi(selectedNonCalculatedKpis)
  }

  return (
    <React.Fragment>
      <MainButton
        data-testid='kpiSelectionButton'
        float={float}
        text={tCommon('button.selectQuestions')}
        icon={'insert_chart'}
        iconPosition={'left'}
        onClick={toggleModal}
      />

      {/* Restrict rendering to make KPI lazy loading possible */}
      {showModal && (
        <div className='treeViewRodal'>
          <Rodal closeOnEsc animation='slideUp' visible={showModal} onClose={toggleModal}>
            <h5>Select KPIs</h5>
            <Row>
              <Col s={6}>
                <hr />
              </Col>
              <Col s={6}></Col>
            </Row>

            <SelectionTree
              selectionType={VALIDSELECTIONTYPES.kpis}
              selectedKpis={getCheckedKpis(selectedKpis || [])}
              kpiType={kpiType ? kpiType : VALIDKPITYPES.all}
              excludeCalculatedKpis={true}
              onSelect={handleSelectKpis}
            />

            <br />
            <br />
            <Row className='center'>
              <Col s={5} offset='s1'>
                <Button data-testid='closeKpiSelectionButton' onClick={toggleModal}>
                  Ok
                </Button>
              </Col>
              <Col s={5}>
                <Button flat onClick={toggleModal}>
                  Close
                </Button>
              </Col>
            </Row>
          </Rodal>
        </div>
      )}
    </React.Fragment>
  )
}

export default SelectKpiButton
