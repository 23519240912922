import React from 'react'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import { post, get } from '../../../../react-services/apiService'
import { Button, Input, Row } from 'react-materialize'
import { deepCopy } from '../../../../../utils';
import { getDateNow } from '../../../../react-services/datesService';
import Rodal from 'rodal'
import { isNil } from 'lodash';


export default class SurveyCheckToolTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      whereMeta: {},
      startDate: "2010-01-01",
      endDate: getDateNow(),
      page: 0,
      surveys: [],
      pendingQueries: 0,
      email: '',
      phone: '',
      responseData: [],
      showTable: false,
      searchInput: '',
      surveyStats: [],
      modalVisibility: false,
      metaDataList: [],
      metaDataName: "",
    }
  }

  componentDidMount() {
    this.setState({ pendingQueries: this.state.pendingQueries + 1 })
    this.getSurveys()
    this.getSurveyStats()
  }

  buildRows() {
    const { responseData } = this.state
    let resultTable = []

    responseData.forEach(survey => {

      let surveyName = this.handleLinkSurveyToName(survey)
      resultTable.push(
        <tr key={survey.id}>

          <td colSpan={2}>{surveyName === null ? <i>No Name</i> : surveyName}</td>
          <td className="center" colSpan={2}>{survey.surveyId}</td>
          <td className="center" colSpan={1}>{survey.deliveryMethod}</td>
          <td className="center" colSpan={1}>{survey.status}</td>
          <td colSpan={2}>{survey.errorMessage}</td>
          <td className="center" colSpan={2}>{survey.createdAt}</td>
          <td className="center" colSpan={2} >
            <span data-testid="survey-check-metadata-button" onClick={() => { this.showMetadata(survey.metadataColumn, surveyName === null ? <i>No Name</i> : surveyName) }}>
              <i className="material-icons module-icon-color waves-effect">visibility</i>
            </span>
          </td>
        </tr>
      )
    })
    return resultTable;
  }

  handleLinkSurveyToName = (targetSurvey) => {
    for (let surveyStatsObject of this.state.surveyStats) {
      if (surveyStatsObject?.survey?.surveygizmo_id === targetSurvey.surveyId || surveyStatsObject?.survey?.surveypal_id === targetSurvey.surveyId || surveyStatsObject?.survey?.twowaysms_id === targetSurvey.surveyId) {
        return surveyStatsObject.survey.name
      }
    }
    return null;
  }

  handleCloseModal = () => {
    this.setState({
      modalVisibility: false
    })
  }

  showMetadata = (metadata, surveyName) => {

    if (!isNil(metadata)) {
      let mappedMetadata = Object.entries(metadata).map(([key, value]) =>
        <tr key={key}>

          <td colSpan={6}>{key}</td>
          <td colSpan={6}>{value}</td>
        </tr>
      )
      this.setState({
        modalVisibility: true,
        metaDataList: mappedMetadata,
        metaDataName: surveyName
      })
    }
  }

  handleSurveyCheck = (survey_ids, payload) => {
    survey_ids = this.state.surveys.map(survey => survey.id);

    if (this.state.email !== '') {
      payload = {
        survey_ids,
        email: this.state.email, // TODO remove, use only the list format
        emails: [this.state.email],
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        page: this.state.page
      }
      this.setState({ searchInput: this.state.email })
    }
    if (this.state.phone !== '') {
      payload = {
        survey_ids,
        phone: this.state.phone,  // TODO remove, use only the list format
        phones: [this.state.phone],
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        page: this.state.page
      }
      this.setState({ searchInput: this.state.phone })
    }

    post('POST_SURVEY_ROW_DATA', payload)
      .then(res => {
        this.setState({ showTable: true, responseData: res.content })
        this.buildRows()
      })
  }

  getSurveys = () => {
    get('GET_SURVEYS', { filter: 'active' })
      .then(res => {
        let surveys = res || [];
        this.attachStatisticsDataToSurveys(surveys)

      }).catch((error) => {
        console.error(error)
      });
  }

  getSurveyStats = () => {
    get('GET_SURVEY_STATS')
      .then(res => {
        this.setState({ surveyStats: res.surveyStatuses })

      })
  }

  attachStatisticsDataToSurveys(surveys) {
    let promises = surveys.map(survey => this.getResponseRatesOf(survey));

    Promise.all(promises).then(surveysWithStatistics => {
      surveysWithStatistics.forEach(s => {
        cleanUpPagination(s);
      });

      this.setState({
        surveys: surveysWithStatistics
      })
      this.setState({ pendingQueries: this.state.pendingQueries - 1 })
    });

    function cleanUpPagination(survey) {
      survey.latestPage = undefined;
      survey.totalPages = undefined;
    }
  }

  getResponseRatesOf(survey) {
    let resultSurvey = deepCopy(survey);
    resultSurvey.statistics = null;

    return post('POST_RESPONSE_RATES', { surveyid: survey.id, start_date: this.state.startDate, end_date: this.state.endDate, where_meta: this.state.whereMeta }).then(res => {
      if (res && res.responseRates && res.responseRates.length > 0) {
        let data = res.responseRates[0].data[0].data;
        resultSurvey.statistics = {
          sent: data.sent,
          answered: data.answered,
          responseRate: data.responseRate,
        }
      }
      return resultSurvey;
    }).catch(e => {
      console.error(e);
      return resultSurvey;
    })
  }

  handleEmailInputChange = (event) => {
    this.setState({ email: event.target.value })
  }

  handlePhoneInputChange = (e) => {
    this.setState({ phone: e.target.value })
  }


  render() {
    const { email, phone, searchInput, responseData, showTable, metaDataName, metaDataList } = this.state
    let isEmpty = (!email && !phone)

    return (
      <>
        <br />
        <div className="center title">Survey sending details</div>
        <Row>
          <Input
            s={8}
            label="Email"
            value={email}
            data-testid="survey-check-email-input"
            onChange={this.handleEmailInputChange}
          />
          <Input
            s={8}
            label="Phone"
            value={phone}
            data-testid="survey-check-phone-input"
            onChange={this.handlePhoneInputChange}
          />
        </Row>
        <Row>
          <Button data-testid="survey-check-search-button" disabled={isEmpty} onClick={this.handleSurveyCheck}>
            Search
          </Button>
        </Row>
        <Row>
          {searchInput !== '' &&
            <span>{responseData.length} results for <i>{searchInput}</i></span>
          }
        </Row>
        <Row>
          {responseData !== null && showTable !== false &&
            <GenericTable
              headers={<tr>
                <th colSpan={2}>Survey Name</th>
                <th colSpan={2} className="center">Survey ID</th>
                <th colSpan={1} className="center">Delivery Method</th>
                <th colSpan={1} className="center">Status</th>
                <th colSpan={2} className="center">Error Message</th>
                <th colSpan={2} className="center">Created At</th>
                <th colSpan={2} className="center">Metadata</th>
              </tr>}
              rows={this.buildRows()}

              useFixedLayout
            />
          }
        </Row>
        {this.state.modalVisibility &&
          <Rodal
            closeOnEsc
            animation="slideDown"
            visible
            className="sectioned-rodal"
            onClose={this.handleCloseModal}
          >
            <header>
              <h5>Metadata ( {metaDataName} )</h5>
            </header>

            <section>
              <table className='table striped z-depth-1'>
                <thead>
                  <tr>
                    <th colSpan={6}>Metadata Key</th>
                    <th colSpan={6}>Metadata Value</th>
                  </tr>
                </thead>
                <tbody>
                  {metaDataList}
                </tbody>
              </table>
            </section>
          </Rodal>
        }
      </>
    )
  }
}
