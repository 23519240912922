import React from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { PropTypes } from 'prop-types'
import { tCommon, tData } from '../../../../languages/i18n'
import { Delete } from '@mui/icons-material'

const MetadataBoardItem = ({
  index,
  metaKey,
  metaValues,
  possibleKeys,
  possibleValues,
  creatables,
  isMulti,
  valuePlaceholderText,
  keyPlaceholderText,
  onChange,
  onDelete,
}) => {
  function handleMetaKeyChange(selection) {
    let value = selection ? selection.value : null
    onChange(index, value, null)
  }

  function handleMetaValueChange(selections) {
    let values = []
    if (isMulti)
      values = selections && selections.length > 0 ? selections.map((s) => s.value) : null
    else values = [selections.value]
    onChange(index, metaKey, values)
  }

  const keySelectorProps = {
    className: 'react-select-default-styles',
    isMulti: false, // should never be anything else
    isSearchable: true,
    isClearable: true,
    options: possibleKeys ? possibleKeys.map((k) => ({ label: tData(k), value: k })) : [],
    value: metaKey ? { label: metaKey, value: metaKey } : null,
    placeholder: keyPlaceholderText ? keyPlaceholderText : tCommon('label.select') + '...',
    styles: {
      menuList: (styles) => ({ ...styles, maxHeight: '140px', overflowY: 'scroll' }),
    },
    onChange: handleMetaKeyChange,
  }

  const valueSelectorProps = {
    className: 'react-select-default-styles',
    isMulti: isMulti,
    isClearable: true,
    isDisabled: !metaKey,
    closeMenuOnSelect: !isMulti,
    options: possibleValues ? possibleValues.map((v) => ({ label: v, value: v })) : [],
    value: metaValues ? metaValues.map((mv) => ({ label: mv, value: mv })) : null,
    styles: {
      menuList: (styles) => ({ ...styles, maxHeight: '140px', overflowY: 'scroll' }),
    },
    placeholder: valuePlaceholderText ? valuePlaceholderText : tCommon('label.select') + '...',
    onChange: handleMetaValueChange,
  }

  return (
    <div className='row metadata-board-item'>
      {/* Key selection */}
      <div className='col s5' data-testid={`metadataBoardKeySelector-${index}`}>
        {creatables === 'both' ? (
          <Creatable data-jt='creatableKeySelector' {...keySelectorProps} />
        ) : (
          <Select {...keySelectorProps} />
        )}
      </div>

      {/* Value selection */}
      <div className='col s6' data-testid={`metadataBoardValuesSelector-${index}`}>
        {creatables === 'both' || creatables === 'values' ? (
          <Creatable data-jt='creatableValuesSelector' {...valueSelectorProps} />
        ) : (
          <Select {...valueSelectorProps} />
        )}
      </div>

      <div className='col s1 center'>
        <button
          data-testid='metadataBoardDeleteRow'
          onClick={() => {
            onDelete(index)
          }}
        >
          <div className='valign-wrapper'>
            <Delete />
          </div>
        </button>
      </div>
    </div>
  )
}

MetadataBoardItem.propTypes = {
  /* Note to self: if keys are creatable, values have to be creatable as well (so there is no lone "key" option)
   */
  creatables: PropTypes.oneOf(['values', 'both', 'neither', null]),
}

export default MetadataBoardItem
