/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useEffect, useState } from 'react'
import BubbleGraphHighcharts from '../Graphs/BubbleGraphHighcharts'
import { KpiData, KpiNamesXYZ, ApiHook, Dashboard } from '../../../../../types'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import createUseXyzQuery from '../../../_shared/hooks/createUseXyzQuery'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import HelpdeskInfo from '../../../_shared/Infos/HelpdeskInfo'
import './BubbleModule.scss'
import { isEmpty, isNil } from 'lodash'
import { getKpiNamesQueryXYZ } from '../../../../react-services/queryService'
import { useMountedState } from 'react-use'

type Props = {
  module: Dashboard.BubbleModule
  useApiHook?: Function
}

const EMPTY_DATA: KpiData.XYZData = {
  series: [],
}

const BubbleModuleContainer = memo(({ module, useApiHook = createUseXyzQuery }: Props) => {
  const { data, error, isLoading, userError }: ApiHook<KpiData.XYZData> = useApiHook()(module.query)
  const isMounted = useMountedState()
  const [kpiNamesXYZ, setKpiNamesXYZ] = useState<KpiNamesXYZ>()

  useEffect(() => {
    getKpiNames()
  }, [])

  const getKpiNames = async () => {
    try {
      const newKpiNames = await getKpiNamesQueryXYZ(module.query)
      if (!isMounted()) return
      setKpiNamesXYZ(newKpiNames)
    } catch (error) {
      console.error('error', error)
    }
  }

  if (userError) {
    return (
      <div data-no-data className='height-400 center'>
        <div className='grey-text'>No data. {userError}</div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='height-400 center'>
        <div className='red-text text-darken-3'>{error}</div>
        <br />
        <HelpdeskInfo subject={error} />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='height-400'>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div
      data-no-data={!hasData()}
      className={`module-content bubble-chart-module ${CSSCONSTANTS.CLASS_MODULE_DONE_LOADING}`}
    >
      <BubbleGraphHighcharts
        module={module}
        data={hasData() ? (data as KpiData.XYZData) : EMPTY_DATA}
        kpiNamesXYZ={kpiNamesXYZ}
      />
    </div>
  )

  function hasData(): boolean {
    return !isNil(data) && !isEmpty(data.series)
  }
})
BubbleModuleContainer.displayName = 'BubbleModuleContainer'
export default BubbleModuleContainer
