import React, { useState, useEffect } from 'react'
import Select, { MultiValue } from 'react-select'
import { UserManagementTool, ReactSelectOption } from '../../../../types'

const USER_ROLES = ['admin', 'API', 'salesforce', 'dynamics']
const USER_ROLE_OPTIONS = USER_ROLES.map(
  (role) => ({ label: role, value: role } as UserManagementTool.RoleOption),
)

type Props = {
  selectedRoles: UserManagementTool.Role[]
  handleRoleSelect: (roles: UserManagementTool.Role[]) => void
  hasStatsOption: boolean
}

function RoleSelect({ selectedRoles, handleRoleSelect, hasStatsOption }: Props) {
  const [roleOptions, setRoleOptions] = useState<UserManagementTool.RoleOption[]>([])

  useEffect(() => {
    const createOptions = (): UserManagementTool.RoleOption[] => {
      if (hasStatsOption) {
        return [...USER_ROLE_OPTIONS, { label: 'stats', value: 'stats' }]
      } else {
        return USER_ROLE_OPTIONS
      }
    }

    setRoleOptions(createOptions())
  }, [hasStatsOption])

  const handleChangeRoles = (
    selectedRoleOptions: MultiValue<ReactSelectOption<UserManagementTool.Role>>,
  ): void => {
    const selectedRoles = selectedRoleOptions as UserManagementTool.RoleOption[]
    const selectedRoleValues = selectedRoles.map((option) => option.value)
    handleRoleSelect(selectedRoleValues)
  }

  const createSelectedRoleValues = (): UserManagementTool.RoleOption[] => {
    return selectedRoles
      .map((role) => (role === 'api' ? 'API' : role))
      .map((role) => ({ value: role, label: role }))
  }

  return (
    <Select
      isMulti
      className='react-select-default-styles'
      value={createSelectedRoleValues()}
      options={roleOptions}
      onChange={(opts) => handleChangeRoles(opts as ReactSelectOption<UserManagementTool.Role>[])}
    />
  )
}

export default RoleSelect
