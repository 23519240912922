import { SeriesColumnOptions } from 'highcharts8'
import { cloneDeep } from 'lodash'
import { FreqFormat, FrequencyOrder, NumericKpiFormat } from '../frequencyModuleTypes'
import useFrequencyUnits from '../useFrequencyUnits'

// To force redraw
let idIncrement = 0

export type SetDataAction = {
  type: ActionTypes.SET_DATA
  data: {
    series: SeriesColumnOptions[]
  }
}

export type SetLanguageAction = {
  type: ActionTypes.SET_LANGUAGE
}

export type SetChartHeightAction = {
  type: ActionTypes.SET_HEIGHT
  data: {
    chartHeight: number | string
  }
}

export type SetChartOrderAction = {
  type: ActionTypes.SET_ORDER
  data: {
    chartOrder?: FrequencyOrder
  }
}

export type SetChartLimitsAction = {
  type: ActionTypes.SET_LIMITS
  data: {
    limitValues: {
      min?: string | number
      max?: string | number
    }
    isSoftMin: boolean
    isSoftMax: boolean
  }
}

export type SetChartFormatAction = {
  type: ActionTypes.SET_DATA_FORMAT
  data: {
    chartFormat: FreqFormat | NumericKpiFormat | null
  }
}

export type SetChartDataPointsAction = {
  type: ActionTypes.SET_DATA_POINTS
  data: {
    dataPoints?: number
  }
}

export enum ActionTypes {
  SET_DATA = 'SET_DATA',
  SET_HEIGHT = 'SET_HEIGHT',
  SET_ORDER = 'SET_ORDER',
  SET_LIMITS = 'SET_LIMITS',
  SET_DATA_FORMAT = 'SET_DATA_FORMAT',
  SET_DATA_POINTS = 'SET_DATA_POINTS',
  SET_LANGUAGE = 'SET_LANGUAGE',
}

type ReducerAction =
  | SetDataAction
  | SetChartHeightAction
  | SetChartOrderAction
  | SetChartLimitsAction
  | SetChartFormatAction
  | SetChartDataPointsAction
  | SetLanguageAction

export const columnReducer = (state: Highcharts.Options, action: ReducerAction) => {
  const newState = cloneDeep(state)
  switch (action.type) {
    case ActionTypes.SET_DATA: {
      newState.series = action.data.series
      const yAxis = newState.yAxis
      if (yAxis && !Array.isArray(yAxis)) {
        if (!yAxis.title) yAxis.title = {}
        yAxis.title.text = 'n'
        newState.yAxis = yAxis
      }
      return newState
    }
    case ActionTypes.SET_HEIGHT: {
      if (newState.chart) {
        newState.chart.height = action.data.chartHeight
      }
      return newState
    }
    case ActionTypes.SET_ORDER: {
      if (!newState.series) newState.series = []
      const numberOfSeries = newState.series.length
      const newOrder = action.data.chartOrder
      if (!newOrder || newOrder === FrequencyOrder.NONE) {
        newState.series = newState.series.map((s) => ({
          ...s,
          dataSorting: { enabled: true, sortKey: 'x' },
        }))
        if (newState.xAxis && !Array.isArray(newState.xAxis))
          newState.xAxis.reversed = numberOfSeries === 1
        if (!newState.legend) newState.legend = {}
        newState.legend.reversed = numberOfSeries === 1
      }
      if (newOrder === FrequencyOrder.INCREASING) {
        newState.series = newState.series.map((s) => ({
          ...s,
          dataSorting: { enabled: true, sortKey: numberOfSeries > 1 ? 'name' : 'y' },
        }))
        if (newState.xAxis && !Array.isArray(newState.xAxis))
          newState.xAxis.reversed = numberOfSeries === 1
        if (!newState.legend) newState.legend = {}
        newState.legend.reversed = true
      }
      if (newOrder === FrequencyOrder.DESCENDING) {
        newState.series = newState.series.map((s) => ({
          ...s,
          dataSorting: { enabled: true, sortKey: numberOfSeries > 1 ? 'name' : 'y' },
        }))
        if (newState.xAxis && !Array.isArray(newState.xAxis)) newState.xAxis.reversed = false
        if (!newState.legend) newState.legend = {}
        newState.legend.reversed = false
      }
      return newState
    }
    case ActionTypes.SET_LIMITS: {
      const { isSoftMax, isSoftMin } = action.data

      const newMin =
        action.data.limitValues?.min !== undefined && action.data.limitValues?.min !== ''
          ? +action.data.limitValues?.min
          : undefined
      const newMax =
        action.data.limitValues?.max !== undefined && action.data.limitValues?.max !== ''
          ? +action.data.limitValues?.max
          : undefined
      if (newState.yAxis && !Array.isArray(newState.yAxis)) {
        newState.yAxis = {
          ...(newState.yAxis || {}),
          ...(isSoftMin
            ? { softMin: newMin, min: null, floor: undefined }
            : { min: newMin, floor: newMin }),
          ...(isSoftMax
            ? { softMax: newMax, max: null, ceiling: undefined }
            : {
                max: newMax ? newMax : undefined,
                ceiling: newMax ? newMax : undefined,
              }),
        }
      }
      return newState
    }

    case ActionTypes.SET_LANGUAGE: {
      const newState = cloneDeep(state)
      const freqSeries = newState.series ? newState.series[0] : null
      if (!freqSeries || freqSeries.type !== 'column') return newState
      freqSeries.id = `freq-${idIncrement++}`
      if (newState.series) newState.series[0] = freqSeries
      return newState
    }

    case ActionTypes.SET_DATA_FORMAT: {
      const currentFormat = action.data.chartFormat
      const { getDataFormatButtonText } = useFrequencyUnits()
      if (newState.yAxis && !Array.isArray(newState.yAxis)) {
        newState.yAxis.title = {
          ...(newState.yAxis.title || {}),
          text: getDataFormatButtonText(currentFormat) || '',
        }
      }
      return newState
    }

    case ActionTypes.SET_DATA_POINTS: {
      const dataPoints = action.data.dataPoints
      const pointsNumber = Number(dataPoints)
      let floor: undefined | number
      let ceiling: undefined | number
      if (dataPoints && !isNaN(pointsNumber)) {
        floor = 0
        ceiling = pointsNumber - 1
      }
      if (newState.xAxis && !Array.isArray(newState.xAxis)) {
        newState.xAxis.floor = floor
        newState.xAxis.ceiling = ceiling
      }
      return newState
    }

    default: {
      return newState
    }
  }
}
