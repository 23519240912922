import React, { useEffect } from 'react'
import { Input } from 'react-materialize'
import { useOpenCategoryToolData } from './OpenCategoryToolProvider'
import { CategoriesAndValues } from './openCategoryToolTypes'
import { isEqual } from 'lodash'
import HotkeySetup from './Hotkeys/HotkeySetup'
import css from './OpenCategoryToolModal.module.scss'
import { tCategory, tCommon } from '../../../../../../languages/i18n'
import { AutoAwesomeMosaic, Mood, Topic, Warning } from '@mui/icons-material'

const OpenCategoryToolCategories = () => {
  const [
    { initialCategoryValues, filteredCategories, updatedCategoryValues, isCategoryChangingAllowed },
    setOpenCategoryToolData,
  ] = useOpenCategoryToolData()

  useEffect(() => {
    setOpenCategoryToolData((prev) => ({
      ...prev,
      updatedCategoryValues: initialCategoryValues,
    }))
  }, [initialCategoryValues])

  useEffect(() => {
    if (isEqual(initialCategoryValues, updatedCategoryValues)) {
      setOpenCategoryToolData((prev) => ({
        ...prev,
        isUnsavedCategoryChanges: false,
      }))
    }
    if (!isEqual(initialCategoryValues, updatedCategoryValues)) {
      setOpenCategoryToolData((prev) => ({
        ...prev,
        isUnsavedCategoryChanges: true,
      }))
    }
  }, [updatedCategoryValues])

  const handleChangeCategory = (
    event: React.ChangeEvent<HTMLInputElement>,
    categoryType: string,
  ) => {
    if (updatedCategoryValues) {
      const newCategoryValue = event.currentTarget.value
      const specificCategoryArr = updatedCategoryValues[categoryType]

      if (event.target.checked) {
        const newCheckedCategories: CategoriesAndValues = {
          ...updatedCategoryValues,
          [categoryType]: [...specificCategoryArr, newCategoryValue].sort(),
        }
        setOpenCategoryToolData((prev) => ({
          ...prev,
          updatedCategoryValues: newCheckedCategories,
        }))
      } else {
        const newRemovedCategories: CategoriesAndValues = {
          ...updatedCategoryValues,
          [categoryType]: specificCategoryArr
            .filter((category) => category !== newCategoryValue)
            .sort(),
        }
        setOpenCategoryToolData((prev) => ({
          ...prev,
          updatedCategoryValues: newRemovedCategories,
        }))
      }
    }
  }

  const getCategoryIcon = (categoryType: string) => {
    switch (categoryType) {
      case 'topic':
        return <Topic sx={{ fontSize: '30px' }} />
      case 'sentiment':
        return <Mood sx={{ fontSize: '30px' }} />
      default:
        return <AutoAwesomeMosaic sx={{ fontSize: '30px' }} />
    }
  }

  return (
    <>
      {!isCategoryChangingAllowed && (
        <div className={css.category_tool_readme}>
          <Warning />
          <span>{tCommon('info.categoryToolWarning')}</span>
        </div>
      )}
      <div className={css.category_tool_categories}>
        {filteredCategories &&
          Object.entries(filteredCategories).map((category, index) => {
            const categoryHeader = category[0]
            const categories = category[1]
            const directionForTooltip = index % 3 === 2 ? 'bottom' : 'right'

            return (
              <div
                key={index}
                className={`${css.categories_item} 
                 ${isCategoryChangingAllowed ? css.border : css.readonly_border}`}
              >
                <div
                  className={`${css.categories_header} ${
                    isCategoryChangingAllowed ? css.header : css.readonly_header
                  }`}
                >
                  <span key={index}>{tCategory(categoryHeader)}</span>
                  {getCategoryIcon(categoryHeader)}
                </div>
                <div
                  className={css.categories_list}
                  style={{
                    display: categories.length > 7 ? 'grid' : 'block',
                    gridTemplateColumns:
                      categoryHeader.toLocaleLowerCase() === 'topic'
                        ? 'auto auto auto'
                        : 'auto auto',
                  }}
                >
                  {categories.map((category, index) => (
                    <div className={css.categories_list_item} key={index}>
                      <HotkeySetup
                        categoryHeader={categoryHeader}
                        categoryValue={category}
                        tooltipDirection={directionForTooltip}
                      />
                      <Input
                        className={'filled-in alerts-list-checkbox'}
                        type='checkbox'
                        label={tCategory(category)}
                        value={category}
                        disabled={isCategoryChangingAllowed ? null : 'disabled'}
                        checked={updatedCategoryValues[categoryHeader].indexOf(category) !== -1}
                        onClick={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangeCategory(event, `${categoryHeader}`)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default OpenCategoryToolCategories
