import React from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { LabelPosition, LineChartModule, LineChartSettings, TargetColor } from '../lineChartTypes'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import TextInput from '../../_shared/ModuleSettings/components/TextInput'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import TargetDataFormatDropdown from './TargetDataFormatDropdown'
import { infoSoftMax, infoSoftMin, infoTargetFromTo } from '../LineNotifications'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'

const LimitInputs = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const lineModule = module as LineChartModule
  const currentMin = lineModule?.limitValues?.min || ''
  const currentMax = lineModule?.limitValues?.max || ''
  const lineOptions = lineModule?.options as LineChartSettings | undefined
  const currentKpiTargetFrom = lineOptions?.kpiTargetFrom
  const currentKpiTargetTo = lineOptions?.kpiTargetTo
  const currentKpiAreaColor = lineOptions?.kpiTargetAreaColor
  const isTableMode = !!lineModule?.isInTableMode
  const hasTargetFrom =
    currentKpiTargetFrom !== undefined &&
    currentKpiTargetFrom !== null &&
    currentKpiTargetFrom !== ''
  const hasTargetTo =
    currentKpiTargetTo !== undefined && currentKpiTargetTo !== null && currentKpiTargetTo !== ''

  const handleOnChangeLimits = (value: string, property: 'min' | 'max') => {
    if (!isNaN(Number(value)) && lineModule) {
      const newLimits = { ...(lineModule.limitValues || {}), [property]: value }
      const newModule = { ...(lineModule || {}), limitValues: newLimits }
      saveModule(newModule)
    }
  }

  if (isTableMode) {
    return (
      <>
        <div className={`${modal_css.half}`}>
          Kpi min
          <input
            data-testid='kpiMinInput'
            type='number'
            value={currentMin}
            id='kpiMinInput'
            onChange={(e) => handleOnChangeLimits(e.target.value, 'min')}
            className={`${css.input}`}
          />
        </div>
        <div className={`${modal_css.half}`}>
          Kpi max
          <input
            data-testid='kpiMaxInput'
            type='number'
            value={currentMax}
            id='kpiMaxInput'
            onChange={(e) => handleOnChangeLimits(e.target.value, 'max')}
            className={`${css.input}`}
          />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={`${modal_css.quarter}`}>
          <TextWithInfo text='Y-axis min' info={infoSoftMin} tooltipDirection='right' />
          <input
            data-testid='kpiMinInput'
            type='number'
            value={currentMin}
            id='kpiMinInput'
            onChange={(e) => handleOnChangeLimits(e.target.value, 'min')}
            className={`${css.input}`}
          />
        </div>
        <CheckBoxInput
          settingsName='isSoftMin'
          text='Enable'
          title='Use soft min'
          data_testid='isSoftMin'
          id='isSoftMin'
          width='quarter'
        />
        <div className={`${modal_css.quarter}`}>
          <TextWithInfo text='Y-axis max' info={infoSoftMax} tooltipDirection='left' />
          <input
            data-testid='kpiMaxInput'
            type='number'
            value={currentMax}
            id='kpiMinMaxTextInput'
            onChange={(e) => handleOnChangeLimits(e.target.value, 'max')}
            className={`${css.input}`}
          />
        </div>
        <CheckBoxInput
          settingsName='isSoftMax'
          text='Enable'
          title='Use soft max'
          data_testid='isSoftMax'
          id='isSoftMax'
          width='quarter'
        />
        <TextInput
          title={
            <TextWithInfo
              text='KPI target (from)'
              info={infoTargetFromTo}
              tooltipDirection='right'
            />
          }
          settingsName='kpiTargetFrom'
          width='third'
          data_testid='kpiTargetFrom'
          id='kpiTargetFrom'
          type='number'
        />
        <TextInput
          title={
            <TextWithInfo text='KPI target (to)' info={infoTargetFromTo} tooltipDirection='left' />
          }
          settingsName='kpiTargetTo'
          width='third'
          data_testid='kpiTargetTo'
          id='kpiTargetTo'
          type='number'
        />
        <TargetDataFormatDropdown />
        <TextInput
          title='KPI target label'
          settingsName='kpiTargetLabel'
          width='third'
          data_testid='kpiTargetLabel'
          id='kpiTargetLabel'
          type='text'
        />

        <DropdownInput
          title={'KPI target label position'}
          settingsName='kpiTargetLabelPosition'
          width='third'
          data_testid='kpiTargetLabelPosition'
          id='kpiTargetLabelPosition'
          options={Object.values(LabelPosition)}
        />
        {hasTargetTo && hasTargetFrom && (
          <DropdownInput
            title={'KPI target area color'}
            settingsName='kpiTargetAreaColor'
            width='third'
            data_testid='kpiTargetAreaColor'
            id='kpiTargetAreaColor'
            options={Object.values(TargetColor)}
          />
        )}
        {currentKpiAreaColor === TargetColor.CUSTOM && (
          <TextInput
            title='Area custom color'
            settingsName='kpiTargetCustomColor'
            width='third'
            data_testid='kpiTargetCustomColor'
            id='kpiTargetCustomColor'
            type='text'
          />
        )}
      </>
    )
  }
}

export default LimitInputs
