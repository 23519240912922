const COLORCONSTANTS = {
  TRAFFICLIGHT_COLORS: {
    max: '#CFE700', // '#90af3d',
    mid: '#FFE300', // '#ffd41b',
    min: '#FF5400', // '#e12a45',
    none: '#000000',
  },
  TRAFFICLIGHT_COLORS_2: {
    max: '#78aa00',
    mid: '#dce6f0',
    min: '#ff3c00',
    none: '#2d4150',
    text: '#333333',
  },
} as const

const CLASS_MODULE_DONE_LOADING: string = 'module-data-loaded' as const

const CSSCONSTANTS = {
  CLASS_MODULE_DONE_LOADING: CLASS_MODULE_DONE_LOADING,
  CLASS_MODULE_DONE_LOADING_SELECTOR: '.' + CLASS_MODULE_DONE_LOADING,
  CLASS_REPORTS_CONTAINER_SELECTOR: '.screen-view',
} as const

const DEFAULT_CURSOR_STYLE_TIMEOUT: number = 10000 as const
const CURSOR_STYLE_DEFAULT: string = 'default' as const
const CURSOR_STYLE_WAIT: string = 'wait' as const

export {
  COLORCONSTANTS,
  CSSCONSTANTS,
  DEFAULT_CURSOR_STYLE_TIMEOUT,
  CURSOR_STYLE_DEFAULT,
  CURSOR_STYLE_WAIT,
}
