import React, { useEffect } from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { Dashboard } from '../../../../../../types'
import { useUpdateEffect } from 'react-use'
import { cloneDeep, debounce, isEqual, set } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'

const YaxisPlotLineInput = () => {
  const [{ module, saveModule }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  if (module?.type === 'bubble') bubbleModule = module
  const plotLines = bubbleModule?.options?.y?.plotLines || []
  const savedPlotLine = plotLines[0]
  const [plotLine, setPlotLine] = React.useState<
    { value: number | undefined; label: string | undefined } | undefined
  >()

  useEffect(() => {
    if (!bubbleModule) return
    setPlotLine(savedPlotLine)
  }, [])

  const SAVE_TIMEOUT = 1000
  useUpdateEffect(() => {
    if (!bubbleModule) return
    if (isEqual(savedPlotLine, plotLine)) return
    const debouncer = debounce(() => {
      const newModule = cloneDeep(bubbleModule)
      if (!newModule) return
      const newPlotLines = [plotLine]
      set(newModule, 'options.y.plotLines', newPlotLines)
      saveModule(newModule)
    }, SAVE_TIMEOUT)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [plotLine])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'value' | 'label') => {
    const input = e.target.value
    if (type === 'value' && !isNaN(Number(input))) {
      setPlotLine((prev) => ({ ...(prev || { label: undefined }), value: Number(input) }))
    }
    if (type === 'label') {
      setPlotLine((prev) => ({ ...(prev || { value: undefined }), label: input }))
    }
  }

  return (
    <>
      <div className={modal_css.half}>
        Position, value
        <input
          data-testid={'value-input'}
          type='number'
          placeholder={'value'}
          value={plotLine?.value || ''}
          id={'value-input'}
          onChange={(e) => handleOnChange(e, 'value')}
          className={`${css.input}`}
        />
      </div>
      <div className={modal_css.half}>
        Label for plotline
        <input
          data-testid={'label-input'}
          type='text'
          placeholder={'label'}
          value={plotLine?.label || ''}
          id={'label-input'}
          onChange={(e) => handleOnChange(e, 'label')}
          className={`${css.input}`}
        />
      </div>
    </>
  )
}

export default YaxisPlotLineInput
