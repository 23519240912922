import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { tCommon } from '../../../../languages/i18n'

type CopyToClipboardIconProps = {
  onClick: () => void
}
const CopyToClipboardIcon = ({ onClick }: CopyToClipboardIconProps) => {
  return (
    <Tooltip title={tCommon('info.copyToClipboard')} data-testid='copy-to-clipboard-info'>
      <IconButton
        onClick={onClick}
        sx={{ color: '#fff', position: 'absolute', top: 0, right: 0 }}
        data-testid='copy-to-clipboard-icon-button'
      >
        <ContentCopy />
      </IconButton>
    </Tooltip>
  )
}

export default CopyToClipboardIcon
