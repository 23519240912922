import React from 'react'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { Dashboard } from '../../../../../../types'
import { ModuleType } from '../../Group/groupModuleTypes'
import KpiSelectionTreeData from './KpiSelectionTreeData'
import TodayPlotLineCheckBox from './TodayPlotLineCheckBox'
import YaxisLimitsInput from './YaxisLimitsInput'
import YaxisPlotLineInput from './YaxisPlotLineInput'
import ZaxisUnitInput from './ZaxisUnitInput'
import AxisUnitInput from './AxisUnitInput'
import SeriesByDropdown from './SeriesByDropdown'
import TooltipMetaSelection from './TooltipMetaSelection'

const BubbleSettings = () => {
  const [{ module }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  if (module?.type === ModuleType.BUBBLE) bubbleModule = module

  if (!bubbleModule) return <></>
  return (
    <>
      <Subtitle text='Data' />
      <Linebreak />
      <KpiSelectionTreeData axis='x' customTitle='X-axis KPI' />
      <TodayPlotLineCheckBox />
      <KpiSelectionTreeData axis='y' customTitle='Y-axis KPI' />
      <AxisUnitInput axis='y' />
      <YaxisLimitsInput />
      <YaxisPlotLineInput />
      <KpiSelectionTreeData axis='z' customTitle='Z-axis KPI' />
      <ZaxisUnitInput />
      <AxisUnitInput axis='z' />

      <Subtitle text='Bubbles' />
      <Linebreak />
      <SeriesByDropdown />
      <TooltipMetaSelection />
    </>
  )
}

export default BubbleSettings
