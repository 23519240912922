import React, { useEffect, useMemo, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'
import { cloneDeep, debounce } from 'lodash'
import {
  FilterArray,
  getFilterArrayEndDate,
  getFilterArrayStartDate,
  setFilterArrayEndDate,
  setFilterArrayStartDate,
} from '../../../../../react-services/filterService'
import { extractDateStringFromDayJs } from '../../../../../react-services/datesService'
import { useTranslation } from 'react-i18next'

import css from './TimeframeDisplay.module.scss'

type TimeframeDisplayProps = {
  filters: FilterArray
  saveFilters: (filters: FilterArray) => void
  isDisabled?: boolean
  keepStateUpdated?: boolean
}
const TimeframeDisplay = ({
  filters,
  saveFilters,
  isDisabled = false,
  keepStateUpdated = false,
}: TimeframeDisplayProps) => {
  const { t } = useTranslation()
  const currentStartDate = getFilterArrayStartDate(filters)
  const currentEndDate = getFilterArrayEndDate(filters)

  const startDateDayJs = useMemo(() => {
    const settingsDate = currentStartDate
    const defaultDate = new Date()
    try {
      return settingsDate ? dayjs(settingsDate) : dayjs(defaultDate)
    } catch (e) {
      console.error(e)
    }
  }, [currentStartDate])

  const endDateDayJs = useMemo(() => {
    const settingsDate = currentEndDate
    const defaultDate = new Date()
    try {
      return settingsDate ? dayjs(settingsDate) : dayjs(defaultDate)
    } catch (e) {
      console.error(e)
    }
  }, [currentEndDate])

  useEffect(() => {
    if (keepStateUpdated) {
      setStartDateState(startDateDayJs)
      setEndDateState(endDateDayJs)
    }
  }, [startDateDayJs, endDateDayJs])

  const [startDateState, setStartDateState] = useState(startDateDayJs)
  const [endDateState, setEndDateState] = useState(endDateDayJs)

  const lastYearToPick = useMemo(() => new Date().getFullYear() + 1, [])

  const handleSaveStartDate = (value: dayjs.Dayjs | null) => {
    if (!value || !module) return
    setStartDateState(value)
    const startDate = extractDateStringFromDayJs(value)
    if (!startDate) return
    let newFilters = cloneDeep(filters)
    newFilters = setFilterArrayStartDate(newFilters, startDate)
    debounce(() => saveFilters(newFilters), 500, { maxWait: 2000 })()
  }

  const handleSaveEndDate = (value: dayjs.Dayjs | null) => {
    if (!value || !module) return
    setEndDateState(value)
    const endDate = extractDateStringFromDayJs(value)
    if (!endDate) return
    let newFilters = cloneDeep(filters)
    newFilters = setFilterArrayEndDate(newFilters, endDate)
    debounce(() => saveFilters(newFilters), 500, { maxWait: 2000 })()
  }

  return (
    <div className={css.cntr}>
      <DatePicker
        label={t('common.label.startDate')}
        value={startDateState}
        onChange={handleSaveStartDate}
        minDate={dayjs('2015-01-01')}
        maxDate={dayjs(lastYearToPick + '-12-31')}
        className={`${css.date} startDate-picker`}
        format='YYYY-MM-DD'
        disabled={isDisabled}
        slotProps={{
          nextIconButton: { id: 'nextMonthButton' },
          previousIconButton: { id: 'previousMonthButton' },
          day: { id: 'dayButton' },
          actionBar: { actions: ['today'] },
        }}
      />
      <DatePicker
        label={t('common.label.endDate')}
        value={endDateState}
        onChange={handleSaveEndDate}
        minDate={dayjs('2015-01-01')}
        maxDate={dayjs(lastYearToPick + '-12-31')}
        className={`${css.date} endDate-picker`}
        format='YYYY-MM-DD'
        disabled={isDisabled}
        slotProps={{
          nextIconButton: { id: 'nextMonthButton' },
          previousIconButton: { id: 'previousMonthButton' },
          day: { id: 'dayButton' },
          actionBar: { actions: ['today'] },
        }}
      />
    </div>
  )
}

export default TimeframeDisplay
