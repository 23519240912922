import { ModuleType } from '../components/Dashboards/DashboardModules/Group/groupModuleTypes'
import { moduleTypes } from './moduleTypes'

const DASHBOARDCONSTANTS = (() => ({
  LOCKED_DASH_CHAR: '|',
  RESPONSE_RATE_START_DATE: '2017-09-26',
}))()

const EMPTY_MY_DASHBOARD = (() => [
  {
    name: 'My Dashboard',
    dashboards: [
      {
        name: 'Dashboard',
        modules: [
          {
            type: ModuleType.GROUP,
          },
        ],
      },
    ],
  },
])()

const EMPTY_DASHBOARD = (() => ({
  multilevel: true,
  dashboards: [
    {
      name: 'My Dashboard',
      autoTimeScale: true,
      showFilters: true,
      customerPathMax: 9,
      customerPathMid: 5,
      customerPathMin: 1,
      modules: [
        {
          type: moduleTypes.GROUP,
          modules: [],
        },
      ],
    },
    {
      name: 'Customer Excellence',
      modules: [
        {
          type: moduleTypes.GROUP,
          modules: [],
        },
      ],
    },
  ],
  sections: [
    {
      name: 'dashboard',
      dashboards: [
        {
          name: 'My Dashboard',
          modules: [
            {
              type: moduleTypes.GROUP,
              modules: [],
            },
          ],
        },
        {
          name: 'Customer Excellence',
          modules: [
            {
              type: moduleTypes.GROUP,
              modules: [],
            },
          ],
        },
      ],
    },
  ],
}))()

const DEFAULT_DECIMALS = 1

export { DASHBOARDCONSTANTS, EMPTY_MY_DASHBOARD, EMPTY_DASHBOARD, DEFAULT_DECIMALS }
