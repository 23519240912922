export const errorFetchSummaries = 'Could not fetch summaries...'
export const errorFetchSummaryConfig = 'Could not fetch summary generation configs...'

export const infoMetaSelection =
  'Meta keys selected here will\nbe used as filter options in the\nmodule for summaries'
export const infoEnableCustomerPath =
  'You need to create customer\npath in customerpath config file.\nCheck summary documentation for\nmore info if needed'
export const infoSummaryModuleAdminRightsRequired =
  'Summary module requires admin rights to be enabled on the user.'
export const infoSingleMetaSelect =
  'By enabling this, meta selection\nwill reset other meta selections when\na new one is clicked'
export const infoPerspectivesFI =
  'Vasen hiiren painallus valitsee\nyhden näkymän kerrallaan, oikea\nhiiren painallus valitsee useita näkymiä'
export const infoPerspectivesEN = 'Left click to select one,\nright click to select multiple'
export const infoDynamicMetas =
  'Enable to filter our meta values\nwithout open answers from the\nmeta selection'
export const infoNoPathFoundInConfig = 'No path found in customerpath config file'
export const infoShowEmptyMetaValues = 'Show empty meta values'
