import React, { Fragment } from 'react'
import { useRadarSettings } from '../RadarContext/RadarSettingsContext'
import { RadarDataDisplay, RadarDataMode, RadarDataSentiment } from '../radarTypes'
import MetaMode from './MetaMode'
import css from './RadarSettings.module.scss'

const RadarModeSettings = () => {
  const [
    {
      radarDataMode,
      radarDataSentiment,
      radarDataDisplay,
      radarSettings,
      saveSettings
    },
    setRadarSettings,
  ] = useRadarSettings()

  const isCompareDataMode = radarDataMode === RadarDataMode.COMPARE
  const isMetaDataMode = radarDataMode === RadarDataMode.META

  const handleSentimentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRadarSentiment = event.currentTarget.value as RadarDataSentiment
    setRadarSettings((prev) => ({
      ...prev,
      radarDataSentiment: newRadarSentiment,
    }))
    if (saveSettings) {
      const newSettings = {
        ...radarSettings,
        radarDataSentiment: newRadarSentiment
      }
      saveSettings(newSettings)
    }
  }

  const handleDisplayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRadarDisplay = event.currentTarget.value as RadarDataDisplay
    setRadarSettings((prev) => ({
      ...prev,
      radarDataDisplay: newRadarDisplay,
    }))
    if (saveSettings) {
      const newSettings = {
        ...radarSettings,
        radarDataDisplay: newRadarDisplay
      }
      saveSettings(newSettings)
    }
  }

  return (
    <>
      {!isCompareDataMode && (
        <>
            <div className={css.radar_mode_settings} data-testid='radar-mode-settings'>

              <div className={css.setting_row}>
                <div className={css.option}>
                  <i className='material-icons'>mood</i>
                  <span> Sentiment</span>
                </div>

                <div className={css.radio_buttons}>
                  {Object.values(RadarDataSentiment).map((sentiment) => {
                    const checkedSentiment = radarDataSentiment === sentiment
                    const uniqueRef = Math.random()
                    return (
                      <Fragment key={sentiment}>
                        <input
                          name={`${sentiment}${uniqueRef}`}
                          id={`${sentiment}${uniqueRef}`}
                          type='radio'
                          value={sentiment}
                          onChange={handleSentimentChange}
                          checked={checkedSentiment}
                        />
                        <label htmlFor={`${sentiment}${uniqueRef}`}>{sentiment}</label>
                      </Fragment>
                    )
                  })}
                </div>
              </div>

              <div className={css.setting_row}>
                <div className={css.option}>
                  <i className='material-icons'>analytics</i>
                  <span> Display</span>
                </div>

                <div className={css.radio_buttons}>
                  {Object.values(RadarDataDisplay).map((display) => {
                    const checkedDisplay = radarDataDisplay === display
                    const uniqueRef = Math.random()
                    return (
                      <Fragment key={display}>
                        <input
                          name={`${display}${uniqueRef}`}
                          id={`${display}${uniqueRef}`}
                          type='radio'
                          value={display}
                          onChange={handleDisplayChange}
                          checked={checkedDisplay}
                        />
                        <label htmlFor={`${display}${uniqueRef}`}>{display}</label>
                      </Fragment>
                    )
                  })}
                </div>
              </div>

              {isMetaDataMode && <MetaMode />}
            </div>
        </>
      )}
    </>
  )
}

export default RadarModeSettings
