// not typed because of testing imports
export const failedToFetchMetaKeys = 'Failed to fetch meta keys'
export const errorInvalidCommonDbSettingsObject = 'Did not receive a valid commondbsettings config'
export const errorUpdatingCommonDbSettingsConfig =
  'There was an error updating commondbsettings config'
export const errorInvalidCustomerPathObject = 'Customer path config object is invalid'
export const errorFetchingCustomerPathConfig = 'There was an error loading customer path config'
export const errorFetchingCommonDbSettingsConfig =
  'There was an error fetching commondbsettings config'
export const errorConflictWithConfigInStoreAndDb =
  'There are changes in the configuration, please refresh to get avoid overwriting them'
export const errorFetchingOpenCategories = 'There was an error loading open answer categories'
export const errorFetchingMetas = 'There was an error loading meta data'
export const errorGettingOpenKpis = 'There was an error getting open kpis'
export const errorGettingNumericKpis = 'There was an error getting numeric kpis'
export const errorInvalidNumericKpis = 'Numeric KPIs config is not valid'
export const errorGettingCalculatedKpis = 'There was an error getting calculated kpis'
export const errorGettingReportFilters = 'There was an error getting reporting filters'
export const errorFetchingNumericCounts = 'There was an error getting numeric counts'
export const errorGettingMetaFrenquencies = 'There was an error getting meta frequencies'
export const errorInvalidMetaFrenquencies = 'Meta frequency response is not valid'
export const errorGettingNumericFrenquencies = 'There was an error getting numeric frequencies'
export const errorInvalidNumericFrenquencies = 'Numeric frequency response is not valid'
export const errorGettingOpenFrenquencies = 'There was an error getting numeric frequencies'
export const errorInvalidOpenFrenquencies = 'Open frequency response is not valid'
export const errorInvalidDashboardConfig = 'Dashboard config is not valid'
export const errorGettingDashboardConfig = 'There was an error getting dashboard config'
export const errorGettingConversationsConfig = 'There was an error getting conversations config'
export const errorInvalidDbTemplateConfig = 'Db template config is not valid'
export const errorGettingDbTemplateConfig = 'There was an error getting db template config'
export const errorUpdatingDbTemplateConfig = 'There was an error updating db template config'
export const errorInvalidReportingFilters = 'Reporting filters config is not valid'
export const errorGettingReportingFilters = 'There was an error getting reporting filters'
export const errorFetchingDynamicFilters = 'There was an error fetching dynamic filters'
export const errorCalculatedKpisInvalidFormat = 'Calculated KPIs config is not valid'
export const errorInvalidDashboardSettingsConfig = 'Dashboard settings config is not valid'
export const errorGettingDashboardSettingsConfig =
  'There was an error getting dashboard settings config'
export const errorPostingDashboardSettingsConfig =
  'There was an error posting dashboard settings config'
export const errorInvalidTranslationsConfig = 'Translations config is not valid'
export const errorGettingTranslationsConfig = 'There was an error getting translations config'
export const errorUpdatingTranslationsConfig = 'There was an error updating translations config'
