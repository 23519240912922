/* eslint-disable eqeqeq */
import React from 'react'
import { post, put, remove } from '../../../../react-services/apiService'
import UserList from './UserList'
import EditUser from './EditUser'
import DeleteUser from './DeleteUser'
import InviteUserBasedOnUser from '../UserManagementGrouped/InviteUserBasedOnUser'
import { dedupe, spawnHelpdeskToast } from '../../../../../utils'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'
import { UserManagementTool } from '../../../../../types'
import { toast } from 'react-toastify'

type Props = {
  users: UserManagementTool.User[]
  currentUser: UserManagementTool.User
  filters: string[]
  templates: { key: string; name: string }[]
  onUpdateUsers: () => void
}
type State = {
  currentUser: UserManagementTool.User
  user: UserManagementTool.User | null
  roles: UserManagementTool.Role[] | null
  showEditUser: boolean
  showDeleteUser: boolean
  errors: string[]
  error: boolean
}

class UserManagement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentUser: props.currentUser,
      user: null,
      roles: null,
      showEditUser: false,
      showDeleteUser: false,
      errors: [],
      error: false,
    }
  }

  getUsers = () => {
    this.props.onUpdateUsers()
  }

  handleInviteUser = (emailInput: string, basedOnId: boolean | null) => {
    if (!emailInput) {
      return
    }

    this.state.errors.length > 0 && this.setState({ errors: [] })

    const errors = []
    let areEmailsOk = true
    const usernames = this.props.users.map((user: UserManagementTool.User) => user.username)
    let emails = emailInput.split('\n')

    for (const email of emails) {
      if (!email.includes('@')) {
        errors.push('"' + email + '" is not a valid email address')
        areEmailsOk = false
      }
      if (usernames.includes(email)) {
        errors.push('User "' + email + '" already exists')
        areEmailsOk = false
      }
    }
    if (!areEmailsOk) {
      this.setState({ errors: errors })
    } else {
      // eslint-disable-next-line eqeqeq
      trackingService.track(TrackingEvent.InviteUser, {
        basedOnUser: basedOnId != null ? 'true' : 'false',
      })
      emails = dedupe(emails)
      let count = 0
      emails.forEach((email: string) => {
        post(
          'POST_INVITEUSER',
          { dashboardUserId: basedOnId, email: email.trim() },
          { parseAsText: true },
        )
          .then(() => {
            count++
            if (count === emails.length) {
              this.getUsers()
              this.setState({ errors: [] })
              toast.success('User(s) invited')
            }
          })
          .catch((error: Error) => {
            console.error(error)
            this.getUsers()
            this.setState({
              errors: [],
              error: true,
            })
            setTimeout(() => {
              this.setState({ error: false })
            }, 5000)
          })
      })
    }
  }

  handleUpdate = (
    id: number,
    modules: string[],
    accessRights: { [name: string]: string[] },
    defaultFilters: { [name: string]: string[] },
    roles: UserManagementTool.Role[],
  ) => {
    if (!id) return
    trackingService.track(TrackingEvent.EditUser)

    this.cleanUpAccessRightsFromDefaultFilters(accessRights, defaultFilters)
    Promise.all([
      this.updateModules(id, modules),
      this.updateAccessRights(id, getFinalizedFilters(accessRights, this.state.user?.filters)),
      this.updateDefaultFilters(
        id,
        getFinalizedFilters(defaultFilters, this.state.user?.defaultfilters),
      ),
      this.updateRoles(id, roles),
    ]).finally(() => {
      this.getUsers()
      this.setStateAfterUpdate()
    })

    function getFinalizedFilters(
      upcomings: { [name: string]: string[] },
      currents?: { [name: string]: string[] },
    ): { [name: string]: string[] } {
      const result: { [name: string]: string[] } = { ...currents }
      nullifyEveryValue(result)
      return { ...result, ...upcomings }
    }

    function nullifyEveryValue(result: { [name: string]: string[] | null }) {
      for (const key in result) result[key] = null
    }
  }

  cleanUpAccessRightsFromDefaultFilters = (
    ars: { [name: string]: string[] },
    dfs: { [name: string]: string[] | null },
  ) => {
    // cannot set default filters that there is no access to
    ars &&
      Object.entries(ars).forEach(([key, accessibleValues]: [string, string[]]) => {
        if (key in dfs) {
          const defaultFilterValues = dfs[key]
          if (defaultFilterValues != null) {
            dfs[key] =
              accessibleValues.length === 0
                ? null
                : defaultFilterValues.filter((v: string) => accessibleValues.includes(v))
            if (dfs[key] && dfs[key]?.length === 0) dfs[key] = null
          }
        }
      })
  }

  updateModules = (id: number, modules: string[]) => {
    return put('PUT_USERDBSETTINGS', { value: { templates: modules } }, { 'user-id': id }).catch(
      (error: unknown) => {
        console.error(error)
        window.Materialize.toast(
          'An error occurred while saving user modules. Please refresh and try again.',
        )
      },
    )
  }

  updateRoles = (id: number, roles: UserManagementTool.Role[]) => {
    return post(
      'POST_EDITUSER',
      {
        userId: id,
        roles: roles,
        user_conf: this.state.user?.user_conf,
      },
      { parseAsText: true },
    ).catch((error: Error) => {
      this.handleUpdateError(error, 'roles')
    })
  }

  updateAccessRights = (id: number, accessrights: { [name: string]: string[] }) => {
    return put('PUT_ACCESSRIGHTS', { value: accessrights }, { 'user-id': id }).catch(
      (error: Error) => {
        this.handleUpdateError(error, 'access rights')
      },
    )
  }

  updateDefaultFilters = (id: number, defaultfilters: { [name: string]: string[] }) => {
    return put('PUT_USERDEFAULTFILTERS', { value: defaultfilters }, { 'user-id': id }).catch(
      (error: Error) => {
        this.handleUpdateError(error, 'default filters')
      },
    )
  }

  handleUpdateError = (
    error: Error,
    triedToUpdate: 'access rights' | 'roles' | 'default filters',
  ) => {
    console.error(error)
    spawnHelpdeskToast(
      'An error occurred while updating the user',
      'An error occurred while updating the user',
      `groupless user management could not update ${triedToUpdate}`,
    )
  }

  setStateAfterUpdate = () => {
    this.setState({
      showEditUser: false,
      user: null,
      roles: null,
    })
  }

  handleDelete = (id: number) => {
    if (id !== this.state.currentUser.id) {
      trackingService.track(TrackingEvent.DeleteUser)
      this.setState({
        showDeleteUser: false,
      })
      remove('DELETE_USER', undefined, { 'user-id': id })
        .then(() => {
          this.getUsers()
          toast.success('User deleted')
        })
        .catch((error: Error) => {
          console.error(error)
          spawnHelpdeskToast(
            'An error occurred while deleting the user',
            'groupless user management could not delete a user',
            null,
          )
        })
    }
  }

  showEditUser = (user: UserManagementTool.User) => {
    trackingService.track(TrackingEvent.OpenEditUser)
    if (!this.props.filters) return

    let roles: UserManagementTool.Role[] = []
    if (user.roles && user.roles.length > 0) {
      roles = user.roles
    }

    this.setState({
      showEditUser: true,
      user: user,
      roles: roles,
    })
  }

  closeEditUser = () => {
    trackingService.track(TrackingEvent.CloseEditUser)
    this.setState({
      showEditUser: false,
      user: null,
      roles: null,
    })
  }

  showDeleteUser = (user: UserManagementTool.User) => {
    trackingService.track(TrackingEvent.OpenDeleteUser)
    this.setState({
      showDeleteUser: true,
      user: user,
    })
  }

  closeDeleteUser = () => {
    trackingService.track(TrackingEvent.CloseDeleteUser)
    this.setState({
      showDeleteUser: false,
      user: null,
    })
  }

  render() {
    const errors = () => {
      const array = []
      for (const error of this.state.errors) {
        array.push(<div key={error}>{error}</div>)
      }
      return array
    }

    return (
      <div>
        <br />
        <br />
        {this.props.users && this.props.templates && (
          <UserList
            users={this.props.users}
            templates={this.props.templates}
            showEditUser={this.showEditUser}
            showDeleteUser={this.showDeleteUser}
          />
        )}

        {this.state.errors && <div>{errors()}</div>}
        {this.props.users && (
          <InviteUserBasedOnUser users={this.props.users} onInviteUser={this.handleInviteUser} />
        )}
        {this.state.error && <div className='card red'>Error</div>}

        {this.state.showEditUser && this.props.filters && this.state.user && (
          <EditUser
            user={this.state.user}
            templates={this.props.templates}
            filters={this.props.filters}
            onClose={this.closeEditUser}
            handleUpdate={this.handleUpdate}
          />
        )}

        {this.state.showDeleteUser && this.state.user && (
          <DeleteUser
            user={this.state.user}
            onClose={this.closeDeleteUser}
            handleDelete={this.handleDelete}
          />
        )}
      </div>
    )
  }
}

export default UserManagement
