import React from 'react'
import SummaryCustomerPath from './CustomerPath/SummaryCustomerPath'
import Summaries from './Summaries/Summaries'

import css from './SummaryModule.module.scss'

const SummaryContent = () => {
  return (
    <div className={css.summary_module_content}>
      <SummaryCustomerPath />
      <Summaries />
    </div>
  )
}

export default SummaryContent
