import React, { useEffect, useRef, useState } from 'react'
import trackingService from '../../../../../react-services/trackingService'
import { OpenModule } from '../openTypes'
import { TrackingEvent } from '../../../../../react-constants'

import css from './PhraseSearch.module.scss'

type PhraseSearchProps = {
  module: OpenModule
  saveModule: (module: OpenModule) => void
  handleClose: () => void
}

const SEARCH_TIMEOUT = 200

const PhraseSearch = ({ module, saveModule, handleClose }: PhraseSearchProps) => {
  const searchTimeoutRef = useRef<NodeJS.Timeout>()
  const { searchFilter: search } = module
  const [searchString, setSearchString] = useState<string>('')

  useEffect(() => {
    setSearchString(search || '')
  }, [])

  const handleSetSearchPhrase = (phrase: string) => {
    setSearchString(phrase)
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current)
    }
    searchTimeoutRef.current = setTimeout(() => {
      trackingService.track(TrackingEvent.FilterOpenAnswers)
      saveModule({ ...module, searchFilter: phrase })
    }, SEARCH_TIMEOUT)
  }

  return (
    <div className={css.cntr}>
      <div className={css.search}>
        <input
          autoFocus
          data-testid='textualSearchFilter'
          type='text'
          placeholder='Filter answers'
          value={searchString}
          onChange={(e) => handleSetSearchPhrase(e.currentTarget.value || '')}
        />
      </div>
      <div onClick={handleClose} className={css.close}>
        CLOSE
      </div>
    </div>
  )
}

export default PhraseSearch
