import { useEffect, useMemo, useState } from 'react'
import { GenericConfig, Kpi, Query } from '../../../../../types'
import { FrequencyModule, QueryBase } from './frequencyModuleTypes'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { idAndNameKpi } from '../../../../stores/useOpenKpis'
import { datePlusDays, daysInBetween } from '../../../../react-services/datesService'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { QueueStatus } from '../Group/groupModuleTypes'
import { isAllowedToFetch } from '../Group/contexts/renderListReducer'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { isEqual, isUndefined } from 'lodash'

const useFrequencyQueryFormat = (
  moduleQuery: FrequencyModule['query'] | undefined,
  sharedFilter: SharedFilter | undefined,
  numericKpis: Kpi.Kpi[] | null,
  calculatedKpis: GenericConfig.CalculatedKpis | null,
  openkpis: idAndNameKpi[] | null,
  comparisonDays: number | undefined,
  moduleStatus: QueueStatus | undefined,
  doneStatusTimeoutRef: React.MutableRefObject<NodeJS.Timeout | null>,
  id: string,
) => {
  const { requestToFetch } = useRenderActions()
  const [queryBase, setQueryBase] = useState<QueryBase | null>(null)
  useEffect(() => {
    const newQuery: QueryBase = {
      start_date: '',
      end_date: '',
      where_meta: {},
    }
    if (moduleQuery && moduleQuery.start_date && moduleQuery.end_date) {
      newQuery.start_date = moduleQuery.start_date
      newQuery.end_date = moduleQuery.end_date
      newQuery.where_meta = moduleQuery.filters || moduleQuery.where_meta || {}
    }
    if (sharedFilter) {
      const extractedDetails = transformFiltersToWhereMetaForm(sharedFilter.filters)
      newQuery.start_date = extractedDetails.start_date
      newQuery.end_date = extractedDetails.end_date
      newQuery.where_meta = extractedDetails.where_meta || {}
    }

    if (isEqual(newQuery, queryBase)) return
    if (id) handleRequestingToFetch(id)
    if (isAllowedToFetch(moduleStatus)) setQueryBase(newQuery)
  }, [
    moduleStatus,
    moduleQuery?.start_date,
    moduleQuery?.end_date,
    moduleQuery?.filters,
    sharedFilter?.filters,
    moduleQuery?.where_meta,
  ])

  const handleRequestingToFetch = (moduleId: string) => {
    requestToFetch(moduleId)
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
  }
  const comparisonQueryBase: QueryBase | null = useMemo(() => {
    if (queryBase) {
      const timeFrameInDays = comparisonDays
        ? comparisonDays
        : daysInBetween(queryBase.start_date, queryBase.end_date)
      const newComparisonQuery = { ...queryBase }
      newComparisonQuery.end_date = datePlusDays(queryBase.start_date, -1)
      newComparisonQuery.start_date = datePlusDays(newComparisonQuery.end_date, -timeFrameInDays)
      return newComparisonQuery
    }
    return null
  }, [queryBase, comparisonDays])

  const createMetaFrequencyQuery = (metaGrouping: string): Query.Query2Payload | null => {
    if (!queryBase) return null
    return { ...queryBase, grouping: metaGrouping }
  }

  const createMetaFrequencyDateComparisonQuery = (
    normalMetaQuery: Query.Query2Payload,
    comparisonDays?: number,
  ): Query.Query2Payload => {
    const timeFrameInDays = comparisonDays
      ? comparisonDays
      : daysInBetween(normalMetaQuery.start_date, normalMetaQuery.end_date)
    const newComparisonQuery = { ...normalMetaQuery }
    newComparisonQuery.end_date = datePlusDays(normalMetaQuery.start_date, -1)
    newComparisonQuery.start_date = datePlusDays(newComparisonQuery.end_date, -timeFrameInDays)
    return newComparisonQuery
  }

  const createNumericFrequencyQuery = (
    selections: FrequencyModule['selections'],
  ): Query.SingleNumeric | null => {
    if (!queryBase) return null
    const newCalculatedKpis = {} as GenericConfig.CalculatedKpis
    const newNumericKpis = [] as Kpi.Kpi[]

    if (selections && Object.keys(selections).length) {
      const kpiKeys = Object.keys(selections)
      for (let i = 0; i <= kpiKeys.length; i++) {
        const currentKey = kpiKeys[i]
        if (isNaN(+currentKey) && calculatedKpis) {
          const foundCalculatedKpi = calculatedKpis[currentKey]
          if (foundCalculatedKpi) newCalculatedKpis[currentKey] = foundCalculatedKpi
        } else {
          const foundNumericKpi = numericKpis?.find((kpi) => +kpi.id === +currentKey)
          if (foundNumericKpi)
            newNumericKpis.push({ id: foundNumericKpi.id, name: foundNumericKpi.name })
        }
      }
    }
    return {
      ...queryBase,
      calculated_kpis: newCalculatedKpis,
      kpis: newNumericKpis,
      trend_line: false,
    }
  }

  const createNumericFrequencyDateComparisonQuery = (
    normalNumericQuery: Query.SingleNumeric,
  ): Query.SingleNumeric => {
    return { ...normalNumericQuery, ...comparisonQueryBase }
  }

  const createOpenFrequencyQuery = (
    selectedOpenKpis: idAndNameKpi[] | undefined,
  ): Query.Textual | null => {
    if (!queryBase || !openkpis || !selectedOpenKpis) return null
    const filteredKpis = selectedOpenKpis.filter((kpi) => !isUndefined(kpi.id))
    return { ...queryBase, kpis: filteredKpis, time_res: 'year' }
  }

  const createOpenFrequencyDateComparisonQuery = (
    normalOpenQuery: Query.Textual,
  ): Query.Textual => {
    return { ...normalOpenQuery, ...comparisonQueryBase }
  }

  const createNumericKpiQuery = (
    selections: FrequencyModule['selections'],
    metaGrouping: string | undefined,
  ): Query.SingleNumeric | null => {
    if (!queryBase) return null
    const newCalculatedKpis = {} as GenericConfig.CalculatedKpis
    const newNumericKpis = [] as Kpi.Kpi[]

    if (selections && Object.keys(selections).length) {
      const kpiKeys = Object.keys(selections)
      for (let i = 0; i <= kpiKeys.length; i++) {
        const currentKey = kpiKeys[i]
        if (isNaN(+currentKey) && calculatedKpis) {
          const foundCalculatedKpi = calculatedKpis[currentKey]
          if (foundCalculatedKpi) newCalculatedKpis[currentKey] = foundCalculatedKpi
        } else {
          const foundNumericKpi = numericKpis?.find((kpi) => +kpi.id === +currentKey)
          if (foundNumericKpi)
            newNumericKpis.push({ id: foundNumericKpi.id, name: foundNumericKpi.name })
        }
      }
    }
    return {
      ...queryBase,
      calculated_kpis: newCalculatedKpis,
      kpis: newNumericKpis,
      trend_line: false,
      grouping: metaGrouping,
    }
  }

  const createNumericKpiDateComparisonQuery = (
    normalOpenQuery: Query.SingleNumeric,
  ): Query.SingleNumeric | null => {
    return { ...normalOpenQuery, ...comparisonQueryBase }
  }
  return {
    createMetaFrequencyQuery,
    createMetaFrequencyDateComparisonQuery,
    createNumericFrequencyQuery,
    createNumericFrequencyDateComparisonQuery,
    createOpenFrequencyQuery,
    createOpenFrequencyDateComparisonQuery,
    createNumericKpiQuery,
    createNumericKpiDateComparisonQuery,
    queryBase,
    comparisonQueryBase,
  }
}

export default useFrequencyQueryFormat
