import React, { Component } from 'react';
import MetadataRibbonButton from './MetadataRibbonButton';
import { Row, Col } from 'react-materialize';
import { deepCopy } from '../../../utils';

const MAX_NUMBER_OF_BUTTONS_ON_SCREEN = 3; 

export default class MetadataRibbon extends Component {
  constructor(props) {
    super(props);
    this.maximumOffset = props.metadata ? Object.keys(props.metadata).length - MAX_NUMBER_OF_BUTTONS_ON_SCREEN : 0;
    this.state = {
      isAbleToOffset: this.maximumOffset > 0,
      buttonOffset: 0,
      expandedButtonIndex: null,
    }

    this.adjustOffset = this.adjustOffset.bind(this)
    this.handleUpdateMetadata = this.handleUpdateMetadata.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (currentMetadataHasChanged(this.props.invitationMetadata, nextProps.invitationMetadata) && !this.contactHasChanged(this.props.contactId, nextProps.contactId)) {
      return false;
    }
    return true;

    function currentMetadataHasChanged(current, next) {
      if (current === null || next === null)
        return false;
      if (current && next && current.__sourceId !== next.__sourceId)
        return false

      return JSON.stringify(current) !== JSON.stringify(next)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.contactHasChanged(this.props.contactId, prevProps.contactId)) {
      this.setState({
        buttonOffset: 0
      })
    }
  }

  contactHasChanged(current, next) {
    return current !== next;
  }

  adjustOffset(side = 'left') {
    if (side !== 'left' && side !== 'right') throw new Error('Side has to be either left or right!')
    if (this.state.isAbleToOffset) {
      this.setState({
        buttonOffset: side === 'left' ? this.state.buttonOffset - 1 : this.state.buttonOffset + 1
      })
    }
  }

  handleUpdateMetadata(oneMeta) {
    let allMetas = deepCopy(this.props.invitationMetadata)
    let [[key, value]] = [...Object.entries(oneMeta)]
    allMetas.find(meta => meta.key === key).value = value;
    
    this.props.onModify(allMetas)
  }
  
  render() {
    let allMetadata = [].concat(this.props.invitationMetadata || []).concat(this.props.metadata || [])
    let leftArrowDisabled = !this.state.isAbleToOffset || this.state.buttonOffset === 0;
    let rightArrowDisabled = !this.state.isAbleToOffset || this.state.buttonOffset === this.maximumOffset;
    let start = this.state.buttonOffset;
    let end = 3 + this.state.buttonOffset;

    return (
      <Row id="manual-sender-metadata-ribbon-container">
        <div className="row metadata-ribbon valign-wrapper">
          {/* Button to scroll left */}
          <Col s={1}>
            <div className={`metadata-ribbon-scroller valign-wrapper left ${leftArrowDisabled ? 'disabled' : ''}`} onClick={() => {!leftArrowDisabled && this.adjustOffset('left')}}>
              <i className="material-icons">chevron_left</i>
            </div>
          </Col>

          <Col s={10} id="metadata-ribbon-buttons">
            <div>
              {allMetadata.slice(start, end).map((meta, i) =>
                <MetadataRibbonButton 
                  key={`${meta.key}-${meta.value}-${i}`}
                  index={i}
                  metaKey={meta.key}
                  metaValue={meta.value}
                  metaValues={meta.values}
                  isImmutable={meta.__isImmutable}
                  expandedButtonIndex={this.state.expandedButtonIndex}

                  onChange={this.handleUpdateMetadata}
                  onExpand={(index) => {this.setState({expandedButtonIndex: index})}}
                />
              )}
            </div>
          </Col>

          {/* Button to scroll right */}
          <Col s={1}>
            <div className={`metadata-ribbon-scroller valign-wrapper right ${rightArrowDisabled ? 'disabled' : ''}`} onClick={() => {!rightArrowDisabled && this.adjustOffset('right')}}>
              <i className="material-icons">chevron_right</i>
            </div>
          </Col>
        </div>
      </Row>
    );
  }
}