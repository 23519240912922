import React, { memo, useEffect, useRef, useState } from 'react'
import { PictureModule } from './pictureTypes'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import Placeholder from './Placeholder'
import { useMountedState } from 'react-use'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { isFetchingData } from '../Group/contexts/renderListReducer'

import css from './PictureModuleCntr.module.scss'
import { QueueStatus } from '../Group/groupModuleTypes'
import { Link } from 'react-router-dom'

type PictureModuleCntrProps = {
  module: PictureModule
  sharedFilter?: SharedFilter
  saveModule: (module: PictureModule) => void
  id: string
  isReportMode: boolean
  isScreenMode: boolean
  moduleStatus: QueueStatus | undefined
}

const PictureModuleCntr = memo(({ module, id, moduleStatus }: PictureModuleCntrProps) => {
  const { updateModuleToIdle } = useRenderActions()
  const isMounted = useMountedState()
  const [ready, setReady] = useState<string>('')

  const readyTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (module.imageurl && !module.placeholder) return
    handleReadyState()
    return () => readyTimeout.current && clearTimeout(readyTimeout.current)
  }, [module])

  useEffect(() => {
    if (isFetchingData(moduleStatus)) updateModuleToIdle(id)
  }, [moduleStatus])

  const handleReadyState = () => {
    if (readyTimeout.current) clearTimeout(readyTimeout.current)
    readyTimeout.current = setTimeout(
      () => isMounted() && setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING),
      4000,
    )
  }

  return (
    <div data-no-data='true' className={`picture-module-cntr ${ready} ${css.cntr}`}>
      {!module.imageurl && !module.placeholder && (
        <div className={css.placeholder}>
          <label>Please give url to an image</label>
          <Placeholder />
        </div>
      )}
      {module.imageurl && !module.placeholder && (
        <Link to={module.linktourl || '#'} className='picture-cntr'>
          <img
            data-testid='pictureLinkImg'
            src={module.imageurl}
            placeholder=''
            alt='Picture not found'
            className={css.picture}
            onLoad={handleReadyState}
          />
        </Link>
      )}
    </div>
  )
})

PictureModuleCntr.displayName = 'PictureModuleCntr'
export default PictureModuleCntr
