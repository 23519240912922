import React, { useMemo } from 'react'
import DoubleModalSide from '../../../../../_shared/Modals/DoubleModalSide'
import { List, Paper, Tooltip } from '@mui/material'
import Icon, { IconName } from '../../../../../../utilities/Icon'
import { allChartColors } from '../../../../../../../styles/variableExport'
import { pSBC } from '../../../../../../react-services/colorService'
import CurrentSelectedMetaValue from './components/CurrentSelectedMetaValue'
import { SavingStatus } from '../../groupModuleTypes'
import CurrentSelectedMetaKey from './components/CurrentSelectedMetaKey'
import { MODAL_ANIMATION_DURATION } from './FilterModal'
import {
  FilterArray,
  getFilterArrayWhereMeta,
} from '../../../../../../react-services/filterService'

import modal_css from './FilterModal.module.scss'
import { tCommon } from '../../../../../../../languages/i18n'

type ViewCurrentFiltersProps = {
  savingStatus: SavingStatus
  filters: FilterArray
  filtersSelectedAlready: FilterArray
  onClose: () => void
  handleResetFilters: () => void
  handleSaveNewMetaValues: (values: string[], key?: string) => void
  handleSelectActiveMeta: (key: string) => void
}
const ViewCurrentFilters = ({
  savingStatus,
  filters,
  filtersSelectedAlready,
  onClose,
  handleResetFilters,
  handleSaveNewMetaValues,
  handleSelectActiveMeta,
}: ViewCurrentFiltersProps) => {
  const where_meta = getFilterArrayWhereMeta(filters)
  const parentFilters = getFilterArrayWhereMeta(filtersSelectedAlready)

  const currentFilterValues = useMemo(() => {
    const parentValueElements = {} as { [key: string]: JSX.Element[] }
    if (Object.keys(parentFilters).length) {
      for (const [key, value] of Object.entries(parentFilters)) {
        parentValueElements[key] = value.map((v) => (
          <CurrentSelectedMetaValue
            metaValue={v}
            metaValues={value}
            metaKey={key}
            isDisabled
            key={v}
          />
        ))
      }
    }

    const selectedFilterElements = {} as { [key: string]: JSX.Element[] }
    if (where_meta) {
      for (const [key, value] of Object.entries(where_meta)) {
        selectedFilterElements[key] = value.map((v) => (
          <CurrentSelectedMetaValue
            metaValue={v}
            metaValues={value}
            metaKey={key}
            onClick={handleSaveNewMetaValues}
            key={v}
          />
        ))
      }
    }
    const mergedElements = {} as { [key: string]: JSX.Element[] }
    if (Object.keys(parentValueElements).length) {
      for (const key in parentValueElements) {
        mergedElements[key] = parentValueElements[key]
      }
    }
    for (const key in selectedFilterElements) {
      if (!mergedElements[key]) mergedElements[key] = selectedFilterElements[key]
      else mergedElements[key].push(...selectedFilterElements[key])
    }
    return mergedElements
  }, [where_meta, parentFilters, savingStatus])

  const currentFilterElements = useMemo(() => {
    return Object.entries(currentFilterValues).map(([key, value]) => {
      return (
        <List
          dense
          component='div'
          role='list'
          key={key}
          sx={{
            flexDirection: 'row',
            textAlign: 'left',
            paddingTop: '0px',
            backgroundColor: pSBC(0.9, allChartColors.GREY),
          }}
          data-testid={`currentFilter-${key}`}
        >
          <CurrentSelectedMetaKey
            metaKey={key}
            isDisabled={!(where_meta[key] || []).length}
            handleDeselectValues={handleSaveNewMetaValues}
            handleSelectActiveMeta={handleSelectActiveMeta}
          />
          {value}
        </List>
      )
    })
  }, [currentFilterValues])
  return (
    <DoubleModalSide
      onClose={onClose}
      closeOnEsc={false}
      animation='fade'
      duration={MODAL_ANIMATION_DURATION}
    >
      <div className={modal_css.header}>
        {tCommon('label.currentFilters').toUpperCase()}
        {where_meta &&
        Object.keys(where_meta).length > 0 &&
        savingStatus !== SavingStatus.SAVING ? (
          <Tooltip title={tCommon('info.resetAllMetaFilters')}>
            <div
              className={modal_css.modalExtraIcon}
              onClick={handleResetFilters}
              data-testid='clearAllMetaFilters'
            >
              <Icon name={IconName.RESET} color='#fff' width={24} height={24} />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={tCommon('info.resetAllMetaFilters')}>
            <div className={`${modal_css.modalExtraIcon}`}>
              <Icon name={IconName.RESET} color='#585858' width={24} height={24} />
            </div>
          </Tooltip>
        )}
      </div>
      <div className={modal_css.valuesCntr}>
        <Paper
          sx={{
            maxWidth: '500px',
            overflow: 'hidden',
            boxSizing: 'border-box',
            height: 'inherit',
            overflowY: 'auto',
            backgroundColor: pSBC(0.9, allChartColors.GREY),
          }}
          data-testid='currentFilters'
        >
          {currentFilterElements}
        </Paper>
      </div>
    </DoubleModalSide>
  )
}

export default ViewCurrentFilters
