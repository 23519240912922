import { Data, GenericConfig, Kpi, WhereMeta } from '../../../../../types'
import { FilterArray } from '../../../../react-services/filterService'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type ModuleQuery = {
  start_date: string
  end_date: string
  grouping: string
  where_meta: WhereMeta
  kpis: Array<Kpi.Kpi> | { id: number; name: string }[]
  calculated_kpis: GenericConfig.CalculatedKpis
}

export type DataQuery = {
  startDate: string
  endDate: string
  grouping: string
  filters: WhereMeta
  kpis: Array<Kpi.Kpi> | { id: number; name: string }[]
  calculatedKpis: GenericConfig.CalculatedKpis
}

export type TabularSettings = {
  show_total?: boolean
  trends?: boolean
  show_total_n?: boolean
  cellDataMode?: Data.FormatBasic
  xAxisScrolling?: boolean
  limitValues?: {
    min?: number
    max?: number
  }
  column_title?: string
  grouping?: string | null
  risks?: boolean
  tableclass?: string
  kpis?: {
    id: number
    name: string
    dataMode?: Data.FormatBasic
    limitValues?: {
      min?: number
      max?: number
    }
  }[]
  calculated_kpis?: GenericConfig.CalculatedKpis
  headers?: unknown[]
  isKpiSpecificSettings?: boolean
  isTableCollapsed?: boolean
}

export type TabularModule = {
  type: 'tabular'
  title: string
  /** @deprecated settings property not in use anymore, settings are in options like with other modules */
  settings?: (TabularSettings & ModuleDefaultSettings) | FilterArray
  options?: TabularSettings & ModuleDefaultSettings
  sharedFilter?: SharedFilter
  query?: ModuleQuery
  sorting?: {
    index: number
    isReversed: boolean
  }
} & ModuleBase

export type DeprecatedTableModule = {
  id?: string
  type: 'table'
  title: string
  small?: boolean
  filterGrouping?: string
  selections?: Record<string, boolean>
  limitValues?: {
    min?: number
    mid?: number
    max?: number
  }
  yAxis?: VALID_DATA_MODES
  sharedFilter?: SharedFilter
  query?: ModuleQuery
} & ModuleBase

export type TableContent = {
  rows: JSX.Element[] | null
  headers: JSX.Element | null
  footer: JSX.Element | null
}

export enum VALID_DATA_MODES {
  avg = 'avg',
  sum = 'sum',
  n = 'n',
}

export type GroupedData = {
  [metaValue: string]: {
    [kpiName: string]: number | string
  }
}

export type ValueInspectorDetails = {
  kpiName: string
  kpiValue: number | string
  kpiIds: number[]
  filters: WhereMeta
  startDate: string
  endDate: string
}

export type CellClickDetails = {
  value: string | number
  rowGroup: string
  columnGroup: string
  id: number | string
}

export type CheckedKpiSettings = {
  id: number | string
  name: string
  grouping?: string | null
  dataMode: Data.FormatBasic
  limitValues: {
    min: number | null
    max: number | null
  }
}

export type KpiSettings = {
  id?: number
  name: string
  grouping?: string | null
  dataMode?: Data.FormatBasic
  limitValues?: {
    min?: number | null
    max?: number | null
  }
}
