import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Query, Data, KpiData } from '../../../../../types'
import Tabular from '../Tabular/Tabular'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import { cloneDeep, isEmpty, isEqual, isNil } from 'lodash'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingModule, infoNoKpisSelected } from './LineNotifications'
import useLineChartQueryBuilding from './useLineChartQueryBuilding'
import { LineChartDataFormats, LineChartModule } from './lineChartTypes'
import useNumericKpiCounts from '../../../../stores/useNumericKpiCounts'
import Line from './Line'
import { addRollingAverageToNumericData } from '../../../../react-services/rollingAverageService'
import { toast } from 'react-toastify'
import HighchartsReact from 'highcharts-react-official'
import { Breakpoint } from '../Pietabular/pietabularTypes'
import useNumericKpis from '../../../../stores/useNumericKpis'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'
import NoData from '../../../_shared/Infos/NoData'
import { useRenderActions } from '../Group/contexts/RenderContext'
import {
  isAllowedToFetch,
  isFetchingData,
  isWaitingForFetching,
} from '../Group/contexts/renderListReducer'
import { CircularProgress } from '@mui/material'

import css from './LineModuleContainer.module.scss'
import { QueueStatus } from '../Group/groupModuleTypes'

type LineModuleCntrUnwrappedProps = {
  module: LineChartModule
  query?: Query.Numeric | null
  dataFormat: Data.Format
  chartThemeKey: string
  isInTableMode: boolean
  isReportMode: boolean
  isScreenMode: boolean
  id: string
  saveModule: (module: LineChartModule) => void
  doneStatusTimeoutRef: React.MutableRefObject<NodeJS.Timeout | null>
  moduleStatus: QueueStatus | undefined
}

const LineModuleContainerUnwrapped = memo(
  ({
    moduleStatus,
    module,
    query,
    dataFormat,
    chartThemeKey = '',
    isInTableMode = false,
    isReportMode = false,
    isScreenMode = false,
    saveModule,
    doneStatusTimeoutRef,
  }: LineModuleCntrUnwrappedProps) => {
    const { updateModuleToIdle } = useRenderActions()
    const limitValuesDataFormat = useRef<Data.Format>()
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
    const { getYearBefore } = useLineChartQueryBuilding()
    const wasInTableMode = useRef<boolean>(isInTableMode)
    const [ready, setReady] = useState<string>('')
    let isBuffered = false

    const {
      numericCounts: counts,
      isLoading: isLoadingCounts,
      error: countsError,
      isRefetching: isRefetchingCounts,
    } = useNumericKpiCounts(query || null)

    let rollingAvgQuery: null | Query.Numeric = null
    if (dataFormat === LineChartDataFormats.ROLLING_AVG && query) {
      rollingAvgQuery = { ...query, start_date: getYearBefore(query.start_date) }
    }
    const {
      numericCounts: rollingAvgCounts,
      isLoading: isLoadingRollingAvgCounts,
      error: rollingAvgCountsError,
      isRefetching: isRefetchingRollingAvgCounts,
    } = useNumericKpiCounts(rollingAvgQuery)

    useEffect(() => toastError(countsError), [countsError])
    useEffect(() => toastError(rollingAvgCountsError), [rollingAvgCountsError])

    const DONE_STATUS_TIMEOUT = 200
    useEffect(() => {
      if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
      const isDoneFetching =
        !isLoadingCounts &&
        !isRefetchingCounts &&
        !isLoadingRollingAvgCounts &&
        !isRefetchingRollingAvgCounts
      if (isFetchingData(moduleStatus) && isDoneFetching) {
        doneStatusTimeoutRef.current = setTimeout(() => {
          if (module.id) updateModuleToIdle(module.id)
        }, DONE_STATUS_TIMEOUT)
      }
      return () => {
        doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
      }
    }, [
      moduleStatus,
      counts,
      isLoadingCounts,
      isRefetchingCounts,
      isLoadingRollingAvgCounts,
      isRefetchingRollingAvgCounts,
    ])

    const READY_TIMEOUT = 4000
    const readyTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
      if (!isLoadingCounts && !isLoadingRollingAvgCounts) {
        if (readyTimeoutRef.current) clearTimeout(readyTimeoutRef.current)
        readyTimeoutRef.current = setTimeout(() => {
          setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
        }, READY_TIMEOUT)
      }
      return () => readyTimeoutRef.current && clearTimeout(readyTimeoutRef.current)
    }, [isLoadingRollingAvgCounts, isLoadingCounts, counts])

    useEffect(() => {
      limitValuesDataFormat.current = dataFormat || 'avg'
    }, [module.limitValues?.max, module.limitValues?.min])
    if (wasInTableMode.current !== isInTableMode && counts) {
      if (counts?.isFilledWithEmptyValues) {
        isBuffered = true
      }
    } else {
      isBuffered = false
    }

    wasInTableMode.current = isInTableMode

    const toastError = (e: string) => {
      if (e) toast.error(e)
    }

    const handleSaveBreakpoints = useCallback(
      (newBreakpoints: Breakpoint[]) => {
        const currentOptions = module.options || {}
        const newOptions = { ...currentOptions, breakpoints: newBreakpoints }
        saveModule({ ...module, options: newOptions })
      },
      [module],
    )

    const handleSaveDateGroup = useCallback(
      (newDateGroup: Query.TimeRes) => {
        const newModule = cloneDeep(module)
        newModule.filterGrouping = newDateGroup
        if (newModule.query) newModule.query.time_res = newDateGroup
        saveModule(newModule)
      },
      [module],
    )

    const handleSaveDataFormat = useCallback(
      (dataFormat: LineChartDataFormats) => {
        saveModule({ ...(module || {}), yAxis: dataFormat })
      },
      [module],
    )

    const handleSaveShowTotals = useCallback(() => {
      const currentMode = !!module.options?.showTotals
      const currentOptions = { ...(module.options || {}) }
      saveModule({ ...(module || {}), options: { ...currentOptions, showTotals: !currentMode } })
    }, [module])

    const kpiNamesAndIds = useMemo(() => {
      return [
        ...(query?.kpis || []).map((kpi) => ({ name: kpi.name, id: kpi.id })),
        ...Object.entries(query?.calculated_kpis || {}).map(([key]) => ({
          name: key,
          id: undefined,
        })),
      ]
    }, [query?.kpis, query?.calculated_kpis])

    const breakpoints = useMemo(() => {
      return module?.options?.breakpoints || []
    }, [module?.options?.breakpoints])

    const isRefetching = useMemo(() => {
      return (
        (!!counts || !!rollingAvgCounts) &&
        (isRefetchingCounts || isRefetchingRollingAvgCounts || isWaitingForFetching(moduleStatus))
      )
    }, [
      isLoadingCounts,
      isLoadingRollingAvgCounts,
      isRefetchingCounts,
      isRefetchingRollingAvgCounts,
    ])

    if (
      !query ||
      isLoadingCounts ||
      isBuffered ||
      isWaitingForFetching(moduleStatus) ||
      (!chartComponentRef.current &&
        dataFormat === LineChartDataFormats.ROLLING_AVG &&
        isLoadingRollingAvgCounts)
    ) {
      return (
        <div className={`module-content line-chart-module ${css.loading} ${css.cntr}`}>
          <CircularProgress thickness={1} size={'6rem'} style={{ opacity: 0.7 }} />
        </div>
      )
    }

    return (
      <div
        className={`${ready} module-content line-chart-module ${css.cntr}`}
        style={{
          height: (isReportMode || isScreenMode) && isInTableMode ? 'fit-content' : 'inherit',
        }}
        data-no-data={isDataEmpty()}
      >
        {(!query?.kpis || !query.kpis.length) &&
          (!query?.calculated_kpis || !Object.keys(query.calculated_kpis).length) && (
            <div>
              <div>{infoNoKpisSelected}</div>{' '}
              <div>
                <NoData />
              </div>
            </div>
          )}
        {!isInTableMode ? (
          <>
            {!!counts && (
              <>
                <Line
                  id={module.id || ''}
                  chartComponentRef={chartComponentRef}
                  chartThemeKey={chartThemeKey}
                  isRefetching={isRefetching}
                  isScreenMode={isScreenMode}
                  isReportMode={isReportMode}
                  data={counts}
                  query={query}
                  rollingAvgCounts={rollingAvgCounts}
                  filterGrouping={query.time_res || module.filterGrouping || 'month'}
                  limitValuesDataFormat={limitValuesDataFormat.current}
                  isSoftMin={module.options?.isSoftMin}
                  isSoftMax={module.options?.isSoftMax}
                  breakpoints={breakpoints}
                  kpiTargetTo={module.options?.kpiTargetTo}
                  kpiTargetFrom={module.options?.kpiTargetFrom}
                  showTotals={!!module.options?.showTotals}
                  kpiTargetAreaColor={module.options?.kpiTargetAreaColor}
                  dataFormat={dataFormat as LineChartDataFormats}
                  limitValues={module.limitValues}
                  kpiTargetCustomColor={module.options?.kpiTargetCustomColor}
                  kpiTargetLabel={module.options?.kpiTargetLabel}
                  kpiTargetLabelPosition={module.options?.kpiTargetLabelPosition}
                  kpiTargetDataFormat={module.options?.kpiTargetDataFormat}
                  handleSaveBreakpoints={handleSaveBreakpoints}
                  handleSaveDateGroup={handleSaveDateGroup}
                  handleSaveDataFormat={handleSaveDataFormat}
                  handleSaveShowTotals={handleSaveShowTotals}
                />
              </>
            )}
          </>
        ) : (
          <Tabular
            selectedGrouping={query.time_res}
            showN={false}
            showTrends={false}
            showTotal={false}
            data={
              dataFormat === LineChartDataFormats.ROLLING_AVG
                ? addRollingAverageToNumericData(counts, rollingAvgCounts, query.time_res)
                : counts
            }
            trendData={null}
            settingsKpis={kpiNamesAndIds}
            dataMode={dataFormat as Data.FormatBasic}
            minLimit={
              module.limitValues?.min !== undefined && module.limitValues?.min !== ''
                ? +module.limitValues.min
                : undefined
            }
            maxLimit={
              module.limitValues?.max !== undefined && module.limitValues?.max !== ''
                ? +module.limitValues.max
                : undefined
            }
            isScreenMode={isReportMode || isScreenMode}
            xAxisScrollingEnabled={false}
            whereMeta={query.where_meta}
            startDate={query.start_date}
            endDate={query.end_date}
            isTitleVisible={false}
            isGroupingByTimeRes
            calculatedKpis={query.calculated_kpis}
          />
        )}
      </div>
    )

    function isDataEmpty(): boolean {
      return isNil(counts) || isEmpty(counts) || isSeriesEmpty(counts.series)
    }

    function isSeriesEmpty(series: KpiData.NumericKpiData[]) {
      return series.reduce((hasNoData, series) => {
        if (series.data && Array.isArray(series.data) && series.data.length) {
          return false
        } else {
          return hasNoData
        }
      }, true)
    }
  },
  isPropsEqual,
)

function isPropsEqual(
  prevProps: LineModuleCntrUnwrappedProps,
  newProps: LineModuleCntrUnwrappedProps,
) {
  return isEqual(prevProps, newProps)
}
LineModuleContainerUnwrapped.displayName = 'LineModuleContainerUnwrapped'

type LineModuleContainerProps = {
  module: LineChartModule
  isReportMode: boolean
  isScreenMode: boolean
  sharedFilter?: {
    filters: { name: string; value: string }[]
  }
  id: string
  saveModule: (module: LineChartModule) => void
  moduleStatus: QueueStatus | undefined
}
const LineModuleContainer = memo((props: LineModuleContainerProps) => {
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { requestToFetch } = useRenderActions()
  const { createQueryObjectToModuleSettings, migrateLegacyTableModeConfiguration } =
    useLineChartQueryBuilding()
  const [query, setQuery] = useState<Query.Numeric | null>(null)
  const { numericKpis } = useNumericKpis()
  const { originalCalculatedKpis } = useCalculatedKpis()

  useEffect(() => {
    const newModule = migrateLegacyTableModeConfiguration(props.module)
    if (!isEqual(newModule, props.module)) props.saveModule(newModule)
  }, [])

  useEffect(() => {
    const newQuery = createQueryObjectToModuleSettings(props.module, props.sharedFilter)
    if (isEqual(newQuery, query)) return
    if (props.module.id) handleRequestingToFetch(props.module.id)
    if (isAllowedToFetch(props.moduleStatus)) setQuery(newQuery)
  }, [
    props.moduleStatus,
    props.module.settings,
    props.module.selections,
    props.module.yAxis,
    props.module.query?.start_date,
    props.module.query?.end_date,
    props.module.query?.time_res,
    props.module.query?.kpis,
    props.module.query?.calculated_kpis,
    props.module.query?.where_meta,
    props.sharedFilter?.filters,
    props.module,
    numericKpis,
    originalCalculatedKpis,
  ])

  const handleRequestingToFetch = (moduleId: string) => {
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
    requestToFetch(moduleId)
  }

  const dataFormat = props.module?.yAxis
  return (
    <ErrorBoundary
      message={errorLoadingModule}
      fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
    >
      <LineModuleContainerUnwrapped
        {...props}
        doneStatusTimeoutRef={doneStatusTimeoutRef}
        query={query}
        isInTableMode={!!props.module.isInTableMode}
        chartThemeKey={''}
        dataFormat={dataFormat && typeof dataFormat === 'string' ? dataFormat : 'avg'}
      />
    </ErrorBoundary>
  )
})

LineModuleContainer.displayName = 'LineModuleContainer'
export default LineModuleContainer
