import { isPlainObject } from 'lodash'
import { APPROOT } from '../react-constants/endpoints'
import { get } from './apiService'
import { currentStoresInUse } from '../stores/useStoreNonResetting'
import { refreshTokenInterval } from './loginService'

const TEMPLATE_USER_NAME = 'templateuser'

function setToken(tokenParam) {
  if (tokenParam) window.sessionStorage.setItem('token', tokenParam)
}

const getToken = () => window.sessionStorage.getItem('token')

function getClaims() {
  let claims = {}
  try {
    const token = getToken()
    if (token) {
      claims = JSON.parse(atob(token.split('.')[1]))
    }
  } catch (e) {
    console.error(e)
  } finally {
    return claims
  }
}

function hasRole(role) {
  return getRoles().includes(role)
}

function hasToken() {
  return Boolean(getToken())
}
function getRoles() {
  let claims = getClaims()
  return claims && claims.role ? claims.role : []
}

/**
 *
 * @returns {string | null}
 */
function getTenant() {
  let claims = getClaims()
  return claims.tenant ? claims.tenant : null
}

/**
 * @type {() => string | undefined}
 */
function getUsername() {
  return getClaims().username
}

function getBxxSettings() {
  return JSON.parse(window.sessionStorage.getItem('bxxSettings'))
}

function setBxxSettings(settings) {
  let stored = settings
  if (isPlainObject(stored)) {
    stored = JSON.stringify(settings)
  }
  window.sessionStorage.setItem('bxxSettings', stored)
}

function isInSessionStorage(item) {
  return Boolean(window.sessionStorage.getItem(item))
}

function isTestRunner() {
  return (
    window.sessionStorage.tenant === 'test_frontend' &&
    (window.sessionStorage.username === 'cypress' ||
      window.sessionStorage.username === 'kypress' ||
      window.sessionStorage.username === 'cypress_local')
  )
}

function isMultilevel() {
  return Boolean(window.sessionStorage.getItem('multilevel') === 'true')
}

function isTestMode() {
  return Boolean(window.sessionStorage.getItem('testmode') === 'true')
}

function logout() {
  windowStop()
  clearSession()
  clearStores()
  navigateToLanding()
  if (refreshTokenInterval) clearInterval(refreshTokenInterval)
}

function clearStores() {
  currentStoresInUse.forEach((store) => store.reset())
  window.sessionStorage.clear()
}
function clearSession() {
  clearStores()
}

function isTemplateUser() {
  return getUsername() === TEMPLATE_USER_NAME
}

function isLoggedIn() {
  return hasToken() && !isLimitedAccess()
}

function isLimitedAccess() {
  if (!hasToken()) return false
  return getClaims().limited_access
}

function navigateToLanding() {
  // TODO: cannot use location.pathname since Angular's usage of # in the URL messes things up - change this later
  let href = window.location.href
  let searchIndex = href.indexOf('?')
  if (searchIndex >= 0) {
    // Omit search part of URL
    href = href.substring(0, searchIndex)
  }
  // Redirect to landing if currently somewhere else
  if (href !== APPROOT) window.location.replace(APPROOT)
}

function saveUsernameToSessionStorage(username) {
  window.sessionStorage.setItem('username', username)
}

function getUsernameFromSessionStorage() {
  return window.sessionStorage.getItem('username')
}

function saveTenantToSessionStorage(tenant) {
  window.sessionStorage.setItem('tenant', tenant)
}

function getTenantFromSessionStorage() {
  return window.sessionStorage.getItem('tenant')
}

function refreshToken() {
  if (isLimitedAccess()) return
  var tenant = getTenant()
  var isSys = hasRole('sys_admin') && tenant === null
  return getRefreshToken(isSys)
    .then((res) => {
      setToken(res)
      return res
    })
    .catch(() => clearToken())
}

function getRefreshToken(isSys) {
  const url = isSys ? 'GET_REFRESHED_SYSTOKEN' : 'GET_REFRESHED_TOKEN'
  return get(url)
}

function clearToken() {
  if (logToConsole) console.log('authservice: clearToken')
  const params = new URLSearchParams(location.search)
  params.set('token', null)
  window.sessionStorage.removeItem('token')
}

function windowStop() {
  try {
    window.stop()
    return
  } catch (e) {
    console.error('Error running command window.stop()')
  }
  try {
    document.execCommand('stop')
  } catch (e) {
    console.error("Error running command document.execCommand('stop')")
  }
}

export {
  setToken,
  getToken,
  getClaims,
  hasRole,
  getUsername,
  getBxxSettings,
  setBxxSettings,
  isInSessionStorage,
  isTestRunner,
  isMultilevel,
  isTestMode,
  logout,
  isTemplateUser,
  clearSession,
  isLoggedIn,
  isLimitedAccess,
  navigateToLanding,
  getTenant,
  saveUsernameToSessionStorage,
  getUsernameFromSessionStorage,
  saveTenantToSessionStorage,
  getTenantFromSessionStorage,
  refreshToken,
  windowStop,
}
