/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { post } from './scripts/react-services/apiService'
import { toast } from 'react-toastify'
import { looksLikeEmail } from './scripts/react-services/inputService'
import {
  Credentials,
  login,
  loginFederatedUser,
  loginSuccess,
  loginWithToken,
} from './scripts/react-services/loginService'
import { getClaims, isLimitedAccess } from './scripts/react-services/authService'
import { validateEmail } from './scripts/react-services/validators'
import { InteractionStatus, PopupRequest } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { CodeResponse, useGoogleLogin } from '@react-oauth/google'
import { APIROOT } from './scripts/react-constants/endpoints'
import { googleClientId } from '.'
import CookieConsent from './scripts/components/CookieConsentDialog/CookieConsentDialog'
import { getConsentStatus, isReportMode } from './scripts/utilities'
import LanguageSelect from './languages/LanguageSelect'
import { useTranslation } from 'react-i18next'
import { Language } from './languages/languages'

/**
 * Login component is a direct copy paste from old code to react. Many utilities have been converted
 * but the structure of the component is still the same.
 * @returns
 */
const Login = () => {
  const { t } = useTranslation()
  const { instance: msInstance, inProgress } = useMsal()
  const isResetLink = !!useParams().reset
  const [loginFailed, setLoginFailed] = useState(false)
  const googleLogin = useGoogleLogin({
    onSuccess: oauthSignIn,
    onNonOAuthError: () => {
      toast.error('Login failed with google authentication', { autoClose: 5000 })
    },

    flow: 'auth-code',
    onError: () => {
      toast.error('Login failed with google authentication', { autoClose: 5000 })
    },
  })
  const [requestPasswordReset, setRequestPasswordReset] = useState(isResetLink ? true : false)
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const sysAdminLogin = params.get('sysadmin') === ''
  const adminLogin = params.get('admin') === '' || sysAdminLogin
  const token = params.get('token')

  const [credentials, setCredentials] = useState<Credentials>(emptyCredentials())
  const [passwordreset, setPasswordreset] = useState(emptyPasswordResetRequest())
  const [userNameIsEmail, setUserNameIsEmail] = useState(false)
  const resetLinkSent = false
  const [isResetProhibited, setIsResetProhibited] = useState(false)

  useEffect(() => {
    if (!token) return
    loginWithToken(token)
    navigate('/section/0/dashboard/0')
  }, [token])

  const paramsTenant = params.get('tenant')
  useEffect(() => {
    if (paramsTenant) setCredentials((prev) => ({ ...prev, tenant: paramsTenant }))
  }, [paramsTenant])

  const paramsUsername = params.get('username')
  useEffect(() => {
    if (paramsUsername) setCredentials((prev) => ({ ...prev, username: paramsUsername }))
  }, [paramsUsername])

  const paramsLoginas = params.get('loginas')
  useEffect(() => {
    if (adminLogin && paramsLoginas) setCredentials((prev) => ({ ...prev, loginas: paramsLoginas }))
  }, [adminLogin, paramsLoginas])

  function sendReset(
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
    email: string,
  ) {
    e.preventDefault()
    const payload = { email: email }
    post('POST_RESET_PASSWORD', payload)
      .then(() => {
        passwordreset.username = ''
        passwordreset.sentto = email
        showResetLinkSentMessage(email)
        hideRequestPasswordReset()
      })
      .catch((res: any) => {
        if (res.status === 403) {
          setIsResetProhibited(true)
          showResetProhibitedMessage()
        } else {
          let message = ''
          try {
            message = JSON.stringify(res)
          } catch (e) {
            /* No can do */
          }
          toast.error('An unknown error occurred. Unable to reset password' + message, {
            autoClose: 10000,
          })
        }
      })
      .finally(() => {
        setLoginFailed(false)
      })
  }

  function credentialToLowerCase(credentials: Credentials) {
    if (sysAdminLogin && !credentials.loginas) {
      // do nothing if 'loginas' is not defined for sysAdminLogin
      return null
    }
    const result: Credentials = {
      tenant: credentials.tenant.toLowerCase(),
      username: credentials.username.toLowerCase(),
      password: credentials.password,
    }
    if (credentials.loginas) result.loginas = credentials.loginas.toLowerCase()
    if (sysAdminLogin) result.sysAdminLogin = true
    return result
  }

  async function loginUser(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    credentials: Credentials,
  ) {
    e.preventDefault()
    const isUsernameEmail = looksLikeEmail(credentials.username)
    setUserNameIsEmail(isUsernameEmail)
    const credentialsInLowerCase = credentialToLowerCase(credentials)
    if (credentialsInLowerCase) {
      await login(credentialsInLowerCase).then(loginSucceed, () => setLoginFailed(true))
    }
  }

  function loginSucceed() {
    const claims: any = getClaims()
    if (isLimitedAccess() && claims.limited_access.type === 'two_step_auth') {
      // proceed to step 2
      navigate('/loginstep2')
    } else {
      loginSuccess(credentials)
      navigate('/section/0/dashboard/0')
    }
  }

  /*
   * Create form to request access token from Google's OAuth 2.0 server.
   */
  function oauthSignIn(credentialResponse: CodeResponse) {
    if (!credentialResponse?.code)
      return toast.error('Login failed with google authentication', { autoClose: 5000 })
    /* We got the access token so now we can get our jwt */

    return fetch(APIROOT + '/auth/google', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientId: googleClientId,
        code: credentialResponse.code,
        redirectUri: window.location.origin,
        state: credentialResponse.state,
      }),
    })
      .then(function (res) {
        if (!res.ok) {
          throw Error('Logging failed, could not authenticate with the server')
        }
        try {
          return res.json()
        } catch (error) {
          throw Error('Logging failed, could not authenticate with the server')
        }
      })
      .then(function (json) {
        if (!json.token) throw Error('Logging failed, could not authenticate with the server')
        loginFederatedUser(json.token)
        window.sessionStorage.setItem('logAtStart', 'google-login')
        toast.success('Succesfully logged in with google', { autoClose: 5000 })
        window.sessionStorage.removeItem('google-error')
        setLoginFailed(false)
      })
      .then(() => navigate('/section/0/dashboard/0'))
      .catch((error) => {
        window.sessionStorage.setItem('google-error', error.message)
        toast.error(error.message, { autoClose: 5000 })
        setLoginFailed(true)
      })
  }

  function msadLogin(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    if (inProgress !== InteractionStatus.None) return
    const accessTokenRequest: PopupRequest = {
      scopes: ['user.read'],
      redirectUri: window.location.origin,
    }

    msInstance
      .acquireTokenPopup(accessTokenRequest)
      .then((response) => {
        if (!response) {
          throw Error('failed to get response from ms ad')
        } else if (response.accessToken) {
          /* We got the access token so now we can get our jwt */
          return fetch(APIROOT + '/auth/ms-ad', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token: response.accessToken,
            }),
          })
            .then(function (res) {
              if (!res.ok) {
                throw Error('Logging failed, could not authenticate with the server')
              }
              try {
                return res.json()
              } catch (error) {
                throw Error('Logging failed, could not authenticate with the server')
              }
            })
            .then(function (json) {
              if (!json.token) throw Error('Logging failed, could not authenticate with the server')
              loginFederatedUser(json.token)
              window.sessionStorage.setItem('logAtStart', 'msad-login')
              toast.success('Succesfully logged in with microsoft', { autoClose: 5000 })
              window.sessionStorage.removeItem('mslogin-error')
              setLoginFailed(false)
            })
            .then(() => navigate('/section/0/dashboard/0'))
            .catch((error) => {
              window.sessionStorage.setItem('mslogin-error', error.message)
              toast.error(error.message, { autoClose: 5000 })
              setLoginFailed(true)
            })
        }
      })
      .catch((error) => {
        window.sessionStorage.setItem('mslogin-error', error.message)
        toast.error(error.message, { autoClose: 5000 })
        setLoginFailed(true)
      })
  }

  function emptyCredentials(): Credentials {
    return {
      tenant: '',
      username: '',
      password: '',
      loginas: '',
    }
  }

  function emptyPasswordResetRequest() {
    return {
      username: '',
      sentto: '',
    }
  }

  function showRequestPasswordReset() {
    setLoginFailed(false)
    setRequestPasswordReset(true)
  }

  function hideRequestPasswordReset() {
    setRequestPasswordReset(false)
    setIsResetProhibited(false)
    setPasswordreset({ sentto: '', username: '' })
  }

  function showResetLinkSentMessage(email: string) {
    toast.info(`Password reset link sent to ${email}.`)
  }

  function showResetProhibitedMessage() {
    toast.info('Password reset is prohibited by your tenant.')
  }

  return (
    <>
      <div className='row login-form-container' id='login-container' style={{ height: '100%' }}>
        <div style={{ position: 'fixed', top: 10, left: 10 }}>
          <LanguageSelect tenantLanguages={[Language.EN, Language.FI]} />
        </div>

        <div className='col offset-l1 offset-m1 offset-xl2 xl8 l10 m12 s12 relative'>
          {!!requestPasswordReset && (
            <>
              <div className='login-form-password-reset-mask'></div>
              <div className='login-form-password-reset'>
                <div className='row'>
                  <div className='col s12'>
                    <i
                      className='material-icons password-reset-close'
                      onClick={hideRequestPasswordReset}
                    >
                      {t('common.button.close')}
                    </i>
                    <div className='left' style={{ paddingLeft: '11px', height: 'inherit' }}>
                      <h4
                        data-cy='loginText'
                        className='wq-orange-text'
                        style={{ fontWeight: '300' }}
                      >
                        {t('common.button.resetPassword')}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col s12'>
                    <form name='resetPasswordForm' noValidate autoComplete='off'>
                      <div className='row left-align'>
                        <div className='col s12'>{t('common.label.provideEmail')}</div>
                      </div>

                      <div className='row marginless-row'>
                        <div className='input-field col s12'>
                          <span className='login-screen-input-label'>
                            {t('common.label.email')}
                          </span>
                          <input
                            type='email'
                            name='useremail'
                            value={passwordreset.username}
                            onChange={(e) =>
                              setPasswordreset((prev) => ({ ...prev, username: e.target.value }))
                            }
                            required
                            autoComplete='off'
                            autoCorrect='off'
                            autoCapitalize='none'
                            className='browser-default'
                          />
                          {!!validateEmail(passwordreset.username) ||
                            (isResetProhibited && (
                              <div className='error-form-corner'>
                                {isResetProhibited
                                  ? t('common.info.unableToReset')
                                  : t('common.info.required')}
                              </div>
                            ))}
                        </div>
                      </div>

                      <br />

                      <div className='row'>
                        <div className='col s12'>
                          <button
                            className='login-form-button waves-effect waves-light'
                            onClick={(e) => sendReset(e, passwordreset.username)}
                            disabled={!!validateEmail(passwordreset.username) || isResetProhibited}
                          >
                            {t('common.button.reset')}
                          </button>
                        </div>
                        <br />
                        <br />
                        <div className='col s12'>
                          <button
                            className='login-form-button login-secondary-button waves-effect waves-light'
                            onClick={hideRequestPasswordReset}
                          >
                            {t('common.button.backToLogin')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='row marginless-row' style={{ height: '60px' }}>
            <div className='left' style={{ paddingLeft: '11px', height: 'inherit' }}>
              <h4 data-cy='loginText' className='wq-orange-text' style={{ fontWeight: '300' }}>
                {t('common.button.login')}
              </h4>
            </div>
            <div className='right' style={{ paddingRight: '11px' }}>
              <a href='https://www.wheelq.com/' target='_blank' rel='noreferrer'>
                <img
                  style={{ maxHeight: '40px' }}
                  src='https://s3-eu-west-1.amazonaws.com/wheelq/Wheel+logo.png'
                  alt='WheelQ logo'
                />
              </a>
            </div>
          </div>

          <form className='login-form col s12' name='loginForm' noValidate autoComplete='off'>
            <div className='row marginless-row'>
              <div className='input-field col s12'>
                <span className='login-screen-input-label'>{t('common.label.tenant')}</span>
                <input
                  data-cy='tenant'
                  id='tenant'
                  name='tenant'
                  type='text'
                  value={credentials.tenant}
                  onChange={(e) => setCredentials((prev) => ({ ...prev, tenant: e.target.value }))}
                  required
                  autoComplete='off'
                  autoCorrect='off'
                  autoCapitalize='none'
                  className='browser-default'
                />
                {loginFailed && !credentials.tenant && (
                  <div className='error-form-corner'>{t('common.info.required')}</div>
                )}
              </div>
            </div>
            {adminLogin && (
              <div className='row marginless-row'>
                <div className='input-field col s12'>
                  <span className='login-screen-input-label'>{t('common.label.loginas')}</span>
                  <input
                    data-cy='loginAs'
                    id='admn'
                    name='loginas'
                    type='text'
                    value={credentials.loginas}
                    onChange={(e) =>
                      setCredentials((prev) => ({ ...prev, loginas: e.target.value }))
                    }
                    required
                    autoComplete='off'
                    autoCorrect='off'
                    autoCapitalize='none'
                    className='browser-default'
                  />
                  {loginFailed && !credentials.loginas && (
                    <div className='error-form-corner'>{t('common.info.required')}</div>
                  )}
                </div>
              </div>
            )}
            <div className='row marginless-row'>
              <div className='input-field col s12'>
                <span className='login-screen-input-label'>
                  {adminLogin ? t('common.label.adminUsername') : t('common.label.username')}
                </span>
                <input
                  data-cy='username'
                  id='usr'
                  name='username'
                  type='text'
                  value={credentials.username}
                  onChange={(e) =>
                    setCredentials((prev) => ({ ...prev, username: e.target.value }))
                  }
                  required
                  autoComplete='off'
                  autoCorrect='off'
                  autoCapitalize='none'
                  className='browser-default'
                />
                {loginFailed && !credentials.username && (
                  <div className='error-form-corner'>{t('common.info.required')}</div>
                )}
              </div>
            </div>
            <div className='row marginless-row'>
              <div className='input-field col s12'>
                <span className='login-screen-input-label'>{t('common.label.password')}</span>
                <input
                  data-cy='password'
                  id='pwd'
                  name='password'
                  type='password'
                  value={credentials.password}
                  onChange={(e) =>
                    setCredentials((prev) => ({ ...prev, password: e.target.value }))
                  }
                  required
                  autoComplete='off'
                  className='browser-default'
                />
                {loginFailed && !credentials.password && (
                  <div className='error-form-corner'>{t('common.info.required')}</div>
                )}
                {!adminLogin && (
                  <span className='left login-forget-password' onClick={showRequestPasswordReset}>
                    {t('common.button.forgotPassword')}
                  </span>
                )}
              </div>
            </div>

            <br />

            <div className='row'>
              <div className='col s12'>
                <button
                  id='login-submit'
                  className='login-form-button'
                  data-cy='loginButton'
                  onClick={(e) => loginUser(e, credentials)}
                  name='loginButton'
                >
                  {t('common.button.login')}
                </button>
              </div>
            </div>

            <hr />

            <br />
            {/* <div className='row ' style={{ marginBottom: 7 }}>
              <div style={{ width: 'fit-content', margin: 'auto' }}>
                <GoogleLogin
                  locale='en'
                  width={'300px'}
                  onSuccess={() => {}}
                  onError={() => {
                    toast.error('Login failed with google authentication', { autoClose: 5000 })
                  }}
                  cancel_on_tap_outside
                  type='standard'
                  context='signin'
                />
              </div>
            </div> */}

            <div className='row' style={{ marginBottom: 2 }}>
              <div style={{ width: '300px', margin: 'auto' }}>
                <button
                  className='satellizer-login valign-wrapper'
                  onClick={(e) => {
                    e.preventDefault()
                    googleLogin()
                  }}
                  style={{
                    backgroundColor: '#fff',
                    width: '100%',
                    height: 38,
                    border: '1px solid #dadce0',
                    borderRadius: 4,
                    padding: '0 12px',
                  }}
                >
                  <svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 48 48'
                    width='21'
                    height='21'
                  >
                    <g>
                      <path
                        fill='#EA4335'
                        d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'
                      ></path>
                      <path
                        fill='#4285F4'
                        d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'
                      ></path>
                      <path
                        fill='#FBBC05'
                        d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'
                      ></path>
                      <path
                        fill='#34A853'
                        d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'
                      ></path>
                      <path fill='none' d='M0 0h48v48H0z'></path>
                    </g>
                  </svg>
                  <span
                    style={{
                      width: '100%',
                      color: '#3c4043',
                      fontFamily: '"Google Sans", arial, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      letterSpacing: '0.25px',
                    }}
                    className='center'
                  >
                    {t('common.button.loginWithGoogle')}
                  </span>
                </button>
              </div>
            </div>

            <div className='row' style={{ marginBottom: 2 }}>
              <div style={{ width: '300px', margin: 'auto' }}>
                <button
                  className='satellizer-login valign-wrapper'
                  onClick={(e) => msadLogin(e)}
                  style={{
                    backgroundColor: '#fff',
                    width: '100%',
                    height: 38,
                    border: '1px solid #dadce0',
                    borderRadius: 4,
                    padding: '0 12px',
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='21'
                    viewBox='0 0 21 21'
                  >
                    <title>MS-SymbolLockup</title>
                    <rect x='1' y='1' width='9' height='9' fill='#f25022' />
                    <rect x='1' y='11' width='9' height='9' fill='#00a4ef' />
                    <rect x='11' y='1' width='9' height='9' fill='#7fba00' />
                    <rect x='11' y='11' width='9' height='9' fill='#ffb900' />
                  </svg>
                  <span
                    style={{
                      width: '100%',
                      color: '#3c4043',
                      fontFamily: '"Google Sans", arial, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      letterSpacing: '0.25px',
                    }}
                    className='center'
                  >
                    {t('common.button.loginWithMicrosoft')}
                  </span>
                </button>
              </div>
            </div>
          </form>

          {!!loginFailed && (
            <div className='row'>
              <div className='col s12' data-login-error>
                <div className='card-panel orange'>
                  <span
                    data-cy='loginErrorMessage'
                    className='red-text text-darken-4'
                    id='loginErrorMessage'
                  >
                    {t('common.info.loginFailed')}
                  </span>
                  <br />
                  {userNameIsEmail && (
                    <span className='black-text'>
                      {t('common.info.resetPassword1')}
                      <a href='' onClick={(e) => sendReset(e, credentials.username)}>
                        {t('common.info.resetPassword2')}
                      </a>
                      {t('common.info.resetPassword3')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {getConsentStatus() === 'Unset' && !isReportMode() && <CookieConsent />}
    </>
  )
}

export default Login
