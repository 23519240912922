/* eslint-disable eqeqeq */
import React from 'react'
import { post, put, remove } from '../../../../react-services/apiService'
import UserList from './UserList'
import InviteUserBasedOnUser from './InviteUserBasedOnUser'
import EditUser from './EditUser'
import DeleteUser from './DeleteUser'
import './UserManagement.scss'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'
import { dedupe } from '../../../../../utils'
import { UserManagementTool } from '../../../../../types'
import { toast } from 'react-toastify'

type State = {
  currentUser: UserManagementTool.User
  user: UserManagementTool.User | null
  filtervalues: string[][]
  showEditUser: boolean
  showDeleteUser: boolean
  errors: string[] | null
  error: boolean
  showError: boolean
}
type Props = {
  currentUser: UserManagementTool.User
  users: UserManagementTool.User[]
  filtervalues: string[][]
  filters: string[]
  groups: { [id: string]: UserManagementTool.UserGroup }
  setUsersActive: () => void
  getUsers: () => void
}

class UserManagement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentUser: props.currentUser,
      user: null,
      filtervalues: props.filtervalues,
      showEditUser: false,
      showDeleteUser: false,
      errors: null,
      error: false,
      showError: false,
    }
  }

  handleInviteUserBasedOnUser = (emailInput: string, basedOnId: boolean | null) => {
    const errors = []
    let emailsOK = true
    const usernames = this.props.users.map((user) => user.username)
    let emails = emailInput.split('\n')

    for (const email of emails) {
      if (!email.includes('@')) {
        errors.push('"' + email + '" is not a valid email address')
        emailsOK = false
      }
      if (usernames.includes(email)) {
        errors.push('User "' + email + '" already exists')
        emailsOK = false
      }
    }
    if (!emailsOK) {
      this.setState({ errors: errors })
    } else {
      trackingService.track(TrackingEvent.InviteUser, {
        basedOnUser: basedOnId != null ? 'true' : 'false',
      })
      emails = dedupe(emails)
      let count = 0
      emails.forEach((email) => {
        post(
          'POST_INVITEUSER',
          { dashboardUserId: basedOnId, email: email.trim() },
          { parseAsText: true },
        )
          .then(() => {
            count++
            if (count === emails.length) {
              this.props.setUsersActive()
              this.props.getUsers()
              this.setState({ errors: null })
              toast.success('User(s) invited')
            }
          })
          .catch((error: Error) => {
            console.error(error)
            this.props.setUsersActive()
            this.props.getUsers()
            this.setState({ error: true })
            setTimeout(() => {
              this.setState({ error: false })
            }, 5000)
          })
      })
    }
  }

  handleUpdate = (
    id: number,
    groups: UserManagementTool.GroupOption[],
    accessrights: { [name: string]: string[] },
    defaultfilters: { [name: string]: string[] },
  ) => {
    trackingService.track(TrackingEvent.EditUser)
    const groupIds: string[] = []
    groups.forEach((group: UserManagementTool.GroupOption) => {
      groupIds.push(group.value)
    })
    const deletes = [
      remove('DELETE_ACCESSRIGHTS', { value: accessrights }, { 'user-id': this.state.user?.id }),
      remove(
        'DELETE_USERDEFAULTFILTERS',
        { value: defaultfilters },
        { 'user-id': this.state.user?.id },
      ),
    ]
    Promise.all(deletes).then(() => {
      const updates = [
        put('PUT_ACCESSRIGHTS', { value: accessrights }, { 'user-id': this.state.user?.id }),
        put(
          'PUT_USERDEFAULTFILTERS',
          { value: defaultfilters },
          { 'user-id': this.state.user?.id },
        ),
        put(
          'PUT_USERDBSETTINGS',
          { value: { templates: this.mapGroupsToTemplates(groupIds, this.props.groups) } },
          { 'user-id': this.state.user?.id },
        ),
        post(
          'POST_EDITUSER',
          {
            userId: this.state.user?.id,
            roles: [this.determineRoleFromGroupConfigs(groups)],
            user_conf: { group: groupIds },
          },
          { parseAsText: true },
        ),
      ]
      Promise.all(updates).then((responses) => {
        let errorOccurred = false
        responses.forEach((res) => {
          if (res?.errors) {
            console.error(res.errors)
            errorOccurred = true
          }
        })
        errorOccurred && this.setState({ showError: true })
        this.userUpdatedItself(id) &&
          window.Materialize.toast(
            'You just updated your own settings.<br/>Please refresh the page to activate those edits.',
          )
        this.props.setUsersActive()
        this.props.getUsers()
      })
    })

    this.setState({
      showEditUser: false,
    })
  }

  userUpdatedItself(userId: number) {
    return userId === this.state.currentUser.id
  }

  determineRoleFromGroupConfigs(groups: UserManagementTool.GroupOption[]) {
    // handles only admins and regular users (i.e. "empty" role)
    let result = ''
    if (groups && groups.length > 0) {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].group.roles.includes('admin')) {
          result = 'admin'
          break
        }
      }
    }
    return result
  }

  mapGroupsToTemplates(
    userGroups: string[],
    allGroups: { [name: string]: UserManagementTool.UserGroup },
  ) {
    const result: string[] = []
    userGroups.forEach((groupId: string) => {
      allGroups[groupId].modules.forEach((template: UserManagementTool.Module) => {
        if (!result.includes(template.key)) {
          result.push(template.key)
        }
      })
    })
    if (result.length === 0) return null
    return result
  }

  handleDelete = (id: number) => {
    if (id !== this.state.currentUser.id) {
      trackingService.track(TrackingEvent.DeleteUser)
      this.setState({
        showDeleteUser: false,
      })
      remove('DELETE_USER', undefined, { 'user-id': id }).then(
        () => {
          toast.success('User deleted')
          this.props.setUsersActive()
          this.props.getUsers()
        },
        (error: unknown) => {
          console.error(error)
        },
      )
    }
  }

  showEditUser = (user: UserManagementTool.User) => {
    if (!this.props.filters) {
      return
    }
    trackingService.track(TrackingEvent.OpenEditUser)
    this.setState({
      showEditUser: true,
      user: user,
    })
  }

  closeEditUser = (): void => {
    trackingService.track(TrackingEvent.CloseEditUser)
    this.setState({
      showEditUser: false,
      user: null,
    })
  }

  showDeleteUser = (user: UserManagementTool.User) => {
    trackingService.track(TrackingEvent.OpenDeleteUser)
    this.setState({
      showDeleteUser: true,
      user: user,
    })
  }

  closeDeleteUser = () => {
    trackingService.track(TrackingEvent.CloseDeleteUser)
    this.setState({
      showDeleteUser: false,
      user: null,
    })
  }

  render() {
    const errors = () => {
      const array = []
      if (this.state.errors) {
        for (const error of this.state.errors) {
          array.push(<div key={error}>{error}</div>)
        }
      }
      return array
    }

    return (
      <div>
        <br />
        <br />
        {this.state.showError &&
          window.Materialize.toast(
            `An error occurred while saving the user ${
              this.state.user?.name ?? ''
            }!<br/><br/>Please refresh and contact helpdesk@wheelq.com if the problem persists.`,
          ) &&
          null}
        {this.props.users && (
          <UserList
            users={this.props.users}
            showEditUser={this.showEditUser}
            showDeleteUser={this.showDeleteUser}
            editorWasToggled={this.state.showEditUser}
            deletorWasToggled={this.state.showDeleteUser}
          />
        )}
        <br />
        <br />

        {this.state.errors && <div>{errors()}</div>}
        {this.props.users && (
          <InviteUserBasedOnUser
            users={this.props.users}
            onInviteUser={this.handleInviteUserBasedOnUser}
          />
        )}
        {this.state.error && <div className='card red data-margin'>Error</div>}

        {this.state.showEditUser && this.props.filters && this.state.user && (
          <EditUser
            user={this.state.user}
            allGroups={this.props.groups}
            filters={this.props.filters}
            filtervalues={this.state.filtervalues}
            closeEditUser={this.closeEditUser}
            handleUpdate={this.handleUpdate}
          />
        )}

        {this.state.showDeleteUser && this.state.user && (
          <DeleteUser
            user={this.state.user}
            closeDeleteUser={this.closeDeleteUser}
            handleDelete={this.handleDelete}
          />
        )}
      </div>
    )
  }
}

export default UserManagement
