import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { cloneDeep, isEqual, isUndefined } from 'lodash'
import { toast } from 'react-toastify'
import PietabularContainer from './PietabularContainer'
import {
  OpenAnswersQuery,
  PieTabularSettingsProvider,
  PietabularModuleSettings,
  usePieTabularSettings,
} from './PietabularModuleContext'
import useOpenCategories from '../../../../stores/useOpenCategories'
import usePietabularQueryFormat from './usePietabularQueryFormat'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import { errorFetchingCommonDbSettings, errorLoadingModule } from './PietabularNotifications'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { DateGroups, PietabularModule } from './pietabularTypes'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { CircularProgress } from '@mui/material'
import { QueueStatus } from '../Group/groupModuleTypes'

type PietabularModuleContainerNoWrappersProps = {
  moduleQuery?: OpenAnswersQuery
  filters: unknown
  id: string
  settings?: PietabularModuleSettings
  saveSettings: (settings?: PietabularModuleSettings) => void
  isScreenMode: boolean
  isReportMode: boolean
  moduleStatus: QueueStatus | undefined
}

const PietabularModuleContainerNoWrappers = ({
  id,
  moduleQuery,
  filters,
  settings,
  saveSettings,
  isScreenMode,
  isReportMode,
  moduleStatus,
}: PietabularModuleContainerNoWrappersProps) => {
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const savingConvertedSettingsRef = useRef<boolean>(false)
  const setPieTabularSettings = usePieTabularSettings()[1]

  const {
    error: categoriesError,
    loading: isLoadingCategories,
    answerCategories,
  } = useOpenCategories()

  const {
    error: configError,
    isLoading: isLoadingConfig,
    config: commondbConfig,
  } = useCommonDbSettingsConfig()

  const moduleQueryWithKpis = useMemo(() => {
    return {
      ...(moduleQuery || {}),
      kpis: settings?.kpis || [],
    } as OpenAnswersQuery
  }, [moduleQuery?.end_date, moduleQuery?.start_date, moduleQuery?.where_meta, settings?.kpis])

  const { error: queryError, pietabularQuery } = usePietabularQueryFormat(
    moduleQueryWithKpis,
    filters,
    id,
    doneStatusTimeoutRef,
    moduleStatus,
  )

  const isLoading = isLoadingCategories || isLoadingConfig

  useEffect(() => {
    if (categoriesError) toast.error(categoriesError)
  }, [categoriesError])

  useEffect(() => {
    if (queryError) toast.error(queryError)
  }, [queryError])

  useEffect(() => {
    if (configError) toast.error(errorFetchingCommonDbSettings)
  }, [configError])

  useEffect(() => {
    if (isLoading || !settings) return
    const prevSettings = settings
    const newSettings = { ...(settings || {}) }

    if (
      pietabularQuery &&
      settings &&
      pietabularQuery.kpis &&
      !isEqual(settings.kpis, pietabularQuery.kpis)
    ) {
      newSettings.kpis = pietabularQuery.kpis
    }
    if (!isEqual(prevSettings, newSettings)) {
      saveSettings(newSettings)
    }
  }, [isLoading, pietabularQuery?.kpis])

  // Force default settings when module exists already but doesn't have new default settings
  useEffect(() => {
    if (isLoading || !settings) return
    savingConvertedSettingsRef.current = false
    const newSettings = cloneDeep(settings || {})
    if (!newSettings.openCategories && answerCategories?.topic)
      newSettings.openCategories = answerCategories.topic
    if (typeof newSettings.showPie !== 'boolean') newSettings.showPie = true
    if (typeof newSettings.showTabular !== 'boolean') newSettings.showTabular = true
    if (typeof newSettings.showTrends !== 'boolean') newSettings.showTrends = false
    if (typeof newSettings.showBreakpoints !== 'boolean') newSettings.showBreakpoints = true
    if (typeof newSettings.showRegressionLines !== 'boolean') newSettings.showRegressionLines = true
    if (typeof newSettings.showRollingAverage !== 'boolean') newSettings.showRollingAverage = true
    if (typeof newSettings.showAreaShareCharts !== 'boolean') newSettings.showAreaShareCharts = true
    if (typeof newSettings.showTotalCount !== 'boolean' && newSettings.showTotalCount === 'false')
      newSettings.showTotalCount = false
    if (typeof newSettings.showTotalCount !== 'boolean' && newSettings.showTotalCount === 'true')
      newSettings.showTotalCount = true
    if (typeof newSettings.numerator !== 'string') newSettings.numerator = 'Positive'
    if (typeof newSettings.denominator !== 'string') newSettings.denominator = 'Negative'
    if (typeof newSettings.showTooltip !== 'boolean' && newSettings.showTooltip === 'false')
      newSettings.showTooltip = false
    if (typeof newSettings.showTooltip !== 'boolean' && newSettings.showTooltip === 'true')
      newSettings.showTooltip = true
    if (
      typeof newSettings.defaultSortIsAscending !== 'boolean' &&
      newSettings.defaultSortIsAscending === 'false'
    )
      newSettings.defaultSortIsAscending = false
    if (
      typeof newSettings.defaultSortIsAscending !== 'boolean' &&
      newSettings.defaultSortIsAscending === 'true'
    )
      newSettings.defaultSortIsAscending = true

    if (newSettings.breakpoints && newSettings.breakpoints.length) {
      const settingsBreakpoints = newSettings.breakpoints
      const configBreakpoints = commondbConfig?.breakpoints || []
      const settingsBreakPointsNotFoundInConfig = settingsBreakpoints.filter(
        (settingsBp) => !configBreakpoints.map((configBp) => configBp.id).includes(settingsBp.id),
      )
      if (settingsBreakPointsNotFoundInConfig.length) {
        newSettings.breakpoints = settingsBreakpoints.filter((settingsBp) =>
          configBreakpoints.map((configBp) => configBp.id).includes(settingsBp.id),
        )
      }
    }
    if (answerCategories?.topic && answerCategories?.topic.length && settings.openCategories) {
      const allTopics = answerCategories?.topic.concat('uncategorized')
      const filteredSelectedTopics = settings.openCategories.filter((t) => allTopics.includes(t))
      if (!isEqual(filteredSelectedTopics, settings.openCategories)) {
        newSettings.openCategories = filteredSelectedTopics
      }
    }
    if (
      !newSettings.defaultDateGroup ||
      !Object.values(DateGroups).includes(newSettings.defaultDateGroup)
    ) {
      newSettings.defaultDateGroup = DateGroups.MONTH
    }
    if (newSettings && isEqual(settings, newSettings)) return
    savingConvertedSettingsRef.current = true
    saveSettings(newSettings)
  }, [
    settings?.showPie,
    settings?.breakpoints,
    settings?.showTabular,
    settings?.showTrends,
    settings?.showBreakpoints,
    settings?.showTotalCount,
    settings?.openCategories,
    settings?.numerator,
    settings?.denominator,
    settings?.defaultSortIsAscending,
    settings?.defaultDateGroup,
    settings?.showTooltip,
    isLoading,
  ])

  const saveSettingsProperty = useCallback(
    (newPropery: Record<string, unknown> | undefined) => {
      if (isUndefined(newPropery)) return
      const oldSettings = { ...(settings || {}) }
      saveSettings({ ...oldSettings, ...newPropery })
    },
    [settings],
  )

  useEffect(() => {
    if (isLoading) return
    if (settings && pietabularQuery) {
      setPieTabularSettings({
        isReportMode: isReportMode,
        query: pietabularQuery || ({} as OpenAnswersQuery),
        isScreenMode: isScreenMode,
        settings: cloneDeep(settings),
        saveSettingsProperty,
        id,
      })
    }
  }, [
    settings?.breakpoints,
    settings?.defaultCountedKey,
    settings?.defaultDateGroup,
    settings?.defaultMetaGroup,
    settings?.defaultSortColumn,
    settings?.defaultSortIsAscending,
    settings?.denominator,
    settings?.hideNoCategory,
    settings?.metaKeys,
    settings?.modulewidth,
    settings?.numberOfTopTopicsToShow,
    settings?.numerator,
    settings?.openCategories,
    settings?.pieTitle,
    settings?.selectedPieTopic,
    settings?.showAreaShareCharts,
    settings?.showBreakpoints,
    settings?.showPie,
    settings?.showRegressionLines,
    settings?.showRollingAverage,
    settings?.showTabular,
    settings?.showTooltip,
    settings?.showTotalCount,
    settings?.showTrends,
    settings?.showNotSelectedTopicsAsOther,
    settings?.pieOtherReplaceText,
    settings?.pieUncategorizedReplaceText,
    saveSettingsProperty,
    pietabularQuery,
    isLoading,
    id,
  ])

  if (isLoading) {
    return (
      <div style={{ minHeight: '300px', alignContent: 'center', textAlign: 'center' }}>
        <CircularProgress thickness={1} size={'5rem'} style={{ opacity: 0.7 }} />
      </div>
    )
  }
  return (
    <>
      {settings && pietabularQuery ? (
        <PietabularContainer
          doneStatusTimeoutRef={doneStatusTimeoutRef}
          moduleStatus={moduleStatus}
        />
      ) : (
        <div style={{ minHeight: '300px', alignContent: 'center', textAlign: 'center' }}>
          <CircularProgress thickness={1} size={'5rem'} style={{ opacity: 0.7 }} />
        </div>
      )}
    </>
  )
}

type PietabularContainerProps = {
  id: string
  module: PietabularModule
  saveModule: (module: PietabularModule) => void
  isScreenMode: boolean
  isReportMode: boolean
  sharedFilter?: SharedFilter
  moduleStatus: QueueStatus | undefined
}
const PietabularModuleContainer = memo(
  ({
    module,
    id,
    isReportMode,
    isScreenMode,
    saveModule,
    sharedFilter,
    moduleStatus,
  }: PietabularContainerProps) => {
    const saveModuleSettings = useCallback(
      (options?: PietabularModule['options']) => {
        if (options && !isEqual(options || {}, module.options || {})) {
          const newModule = cloneDeep(module)
          newModule.options = options
          saveModule(newModule)
        }
      },
      [module],
    )
    const groupQuery = sharedFilter ? transformFiltersToWhereMetaForm(sharedFilter) : {}
    return (
      <ErrorBoundary
        message={errorLoadingModule}
        containerId={id}
        fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
      >
        <PieTabularSettingsProvider>
          <PietabularModuleContainerNoWrappers
            id={id}
            isReportMode={isReportMode}
            isScreenMode={isScreenMode}
            saveSettings={saveModuleSettings}
            filters={sharedFilter?.filters}
            moduleStatus={moduleStatus}
            moduleQuery={
              module.query
                ? { ...groupQuery, ...module.query, where_meta: module.query.filters || {} }
                : undefined
            }
            settings={module.options}
          />
        </PieTabularSettingsProvider>
      </ErrorBoundary>
    )
  },
)

PietabularModuleContainer.displayName = 'PietabularModuleContainer'
export default PietabularModuleContainer
