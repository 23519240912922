import React, { useEffect } from 'react'
import { isLoggedIn } from './scripts/react-services/authService'
import { useNavigate } from 'react-router-dom'
import Login from './Login'
import { debounce } from 'lodash'

const Main = () => {
  const navigate = useNavigate()

  const DEBOUNCE_TIME = 1000
  useEffect(() => {
    const debouncer = debounce(() => {
      if (isLoggedIn()) navigate('/section/0/dashboard/0')
    }, DEBOUNCE_TIME)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [])

  if (isLoggedIn()) return <></>
  return (
    <div className='row marginless-row white'>
      <div className='col s12 m10 l6 xl6 login-top-margin'>
        <Login />
      </div>
      <div className='col s0 m0 l6 xl6'>
        <img
          className='login-screen-image'
          src='https://s3-eu-west-1.amazonaws.com/wheelq/ui/Login_image_light.jpg'
          alt='wheelq-login-decor'
        />
      </div>
    </div>
  )
}

export default Main
