/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  fetchModuleSettings,
  getModuleSettings,
} from '../../../../../react-services/moduleSettingsService'
import { cloneDeep } from 'lodash'

type TitleSubtitleProps = {
  titletext?: string
  subtitle?: string
  titlesize?: any
  subtitlesize?: any
  module?: any
  isReportMode?: any
  isScreenMode?: any
}
const TitleSubtitle = ({
  subtitlesize,
  isReportMode,
  isScreenMode,
  module,
  subtitle,
  titlesize,
  titletext,
}: TitleSubtitleProps) => {
  const [settings, setSettings] = useState<any>(null)
  const [modifiedModule, setModifiedModule] = useState<any>(null)
  useEffect(() => {
    if (!module) return
    const newSettings = fetchModuleSettings(module.type)
    setSettings(newSettings)
    const newModule = cloneDeep(module)
    if (!newModule.yAxis && isModuleWithSuffix()) {
      newModule.yAxis = 'avg'
    } else if (!isModuleWithSuffix() && newModule.yAxis) {
      delete newModule.yAxis
      setModifiedModule(newModule)
    }
  }, [module])

  function hasTitleText() {
    return titletext !== ' ' && (titletext || (settings && settings.defaultTitle))
  }

  function isModuleWithSuffix() {
    return getModuleSettings(module?.type)?.titleShowAxisUnit ?? false
  }

  if (!hasTitleText()) return <></>
  else
    return (
      <div className='scalable-title hide-overflow' style={{ textAlign: 'left', width: '100%' }}>
        <div
          data-cy='moduleTitleText'
          className={`pre module-title ${
            !isScreenMode ? 'module-content module-title-left-aligned' : ''
          }`}
          style={{ fontSize: titlesize + 'px' }}
        >
          {titletext || settings.defaultTitle}
          {!!module.yAxis && !isReportMode && !isScreenMode && (
            <span data-testid='dataFormatSuffix' className='module-title-suffix'>
              {' '}
              ({modifiedModule?.yAxis})
            </span>
          )}
          <br />
        </div>
        {!!subtitle && (
          <div
            className='scalable-title-subtitle module-title-subtitle'
            style={{ fontSize: subtitlesize + 'px' }}
          >
            {subtitle}
          </div>
        )}
      </div>
    )
}

export default TitleSubtitle
