import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { alertSave, generatePayload } from '../../../../react-services/alertService'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import { successAlertClose, successAlertOpen } from './FeedBackAlertNotifications'
import { useAlertData, useAlertModalControl, useAlertTools } from './FeedbackAlertsTableCntr'
import { FeedbackAlertPruned } from './alertTypes'
import { tCommon } from '../../../../../languages/i18n'
import { IconButton } from '@mui/material'
import { Close, ListAlt, Mail, QuestionAnswer, RotateRight, Tune } from '@mui/icons-material'
import { primaryModalColor } from '../../../../../styles/variableExport'

import css from './FeedbackAlertsTableRow.module.scss'

type FeedbackAlertsTableRowProps = {
  alert: FeedbackAlertPruned
}

const FeedbackAlertsTableRow = ({ alert }: FeedbackAlertsTableRowProps) => {
  const [alertTools] = useAlertTools()
  const [{ alertConfig, initAlerts, conversationsList }, setAlertData] = useAlertData()
  const [alertModalControl, setAlertModalControl] = useAlertModalControl()
  const [unreadMessages, setUnreadMessages] = useState<number>(0)

  const [closeConfirmationModalOpen, setCloseConfirmationModalOpen] = useState<boolean>(false)
  const [openConfirmationModalOpen, setOpenConfirmationModalOpen] = useState<boolean>(false)

  const open = alert.state?.toLocaleLowerCase() === 'open' ? css.open : ''
  const closed = alert.state?.toLocaleLowerCase() === 'closed' ? css.closed : ''
  const inProgress = alert.state?.toLocaleLowerCase() === 'in_progress' ? css.in_progress : ''

  useEffect(() => {
    let count = 0
    conversationsList.forEach((conversation) => {
      if (conversation.completedSurveyId === alert.completed_survey_id) {
        count += conversation.unreadMessagesCount
      }
    })
    setUnreadMessages(count)
  }, [conversationsList])

  const handleChangeStateConfirmation = (newState: string) => {
    const changes = { state: newState }
    const initAlert = initAlerts?.find((row) => row.id === alert.id)
    if (!initAlert) return
    const payload = generatePayload(initAlert, changes)

    alertTools.updateAlerts && alertTools.updateAlerts(alert.id, changes)
    alertSave(payload, alert.id)
      .then(() => {
        if (newState === 'OPEN') toast.success(successAlertOpen)
        if (newState === 'CLOSED') toast.success(successAlertClose)
      })
      .catch((err: unknown) => {
        toast.error(typeof err === 'string' ? err : '')
      })
      .finally(() => {
        alertTools.getAlerts && alertTools.getAlerts()
        setCloseConfirmationModalOpen(false)
        setOpenConfirmationModalOpen(false)
      })
  }

  const handleRowClick = () => {
    setAlertModalControl((prev) => ({ ...prev, isAlertModalOpen: true }))
    setAlertData((prev) => ({ ...prev, currentAlertId: alert.id }))
  }

  const handleConversationIconsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setAlertModalControl((prev) => ({
      ...prev,
      isAlertModalOpen: true,
      isConversationModalOpen: true,
    }))
    setAlertData((prev) => ({ ...prev, currentAlertId: alert.id }))
  }

  const handleSettingIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setAlertModalControl((prev) => ({ ...prev, isAlertModalOpen: true, isSettingsModalOpen: true }))
    setAlertData((prev) => ({ ...prev, currentAlertId: alert.id }))
  }

  const handleRelatedAlertsIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setAlertModalControl((prev) => ({
      ...prev,
      isAlertModalOpen: true,
      isRelatedAlertsModalOpen: true,
    }))
    setAlertData((prev) => ({ ...prev, currentAlertId: alert.id }))
  }

  const handleCloseAlertIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setCloseConfirmationModalOpen(true)
  }

  const handleOpenAlertIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setOpenConfirmationModalOpen(true)
  }

  return (
    <>
      {(closeConfirmationModalOpen || openConfirmationModalOpen) && (
        <tr style={{ height: 0, padding: 0, margin: 0 }}>
          <td style={{ height: 0, padding: 0, margin: 0 }}>
            <ConfirmationModal
              info={`Are you sure you want to ${
                closeConfirmationModalOpen ? 'close' : 'open'
              } this alert? Click Confirm or press Enter to continue.`}
              confirm={'Confirm'}
              cancel={'Cancel'}
              handleCancel={() => {
                setCloseConfirmationModalOpen(false)
                setOpenConfirmationModalOpen(false)
              }}
              handleConfirmation={() =>
                handleChangeStateConfirmation(closeConfirmationModalOpen ? 'CLOSED' : 'OPEN')
              }
              enableEnterConfirmation={true}
            />
          </td>
        </tr>
      )}

      <tr
        className={`${css.alert_row} ${
          alertModalControl.isAlertModalOpen ? '' : css.row_clickable
        }`}
        data-cy='alert-list-data-row'
        onClick={alertModalControl.isAlertModalOpen ? () => ({}) : () => handleRowClick()}
      >
        <td colSpan={1.5}>{alert.time}</td>
        <td colSpan={1.5} className={`${open} ${closed} ${inProgress}`} data-cy='state-column-data'>
          <b>
            {alert.state?.toLocaleLowerCase() === 'in_progress'
              ? tCommon('label.inProgress').toLocaleUpperCase()
              : tCommon('label.' + alert.state?.toLocaleLowerCase()).toLocaleUpperCase()}
          </b>
        </td>
        <td colSpan={2}>
          <div>
            {alert.recipients &&
              alert.recipients.map((person) => (
                <div
                  key={person}
                  style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {person}
                </div>
              ))}
          </div>
        </td>
        <td colSpan={5}>
          <p>
            <b>{alert.subject}:</b> {alert.comment}
          </p>
        </td>
        <td colSpan={2} className={css.btns_td}>
          <div className={css.btns_td_flex}>
            <IconButton
              onClick={handleSettingIconClick}
              sx={{ padding: '2px' }}
              data-testid='settings-icon-button'
            >
              <Tune sx={{ fontSize: '28px', color: primaryModalColor }} />
            </IconButton>
            {alert.state?.toLocaleLowerCase() !== 'closed' && (
              <IconButton
                onClick={handleCloseAlertIconClick}
                sx={{ padding: '2px' }}
                data-testid='close-alert-icon-button'
              >
                <Close sx={{ fontSize: '30px', color: primaryModalColor }} />
              </IconButton>
            )}
            {alert.state?.toLocaleLowerCase() === 'closed' && (
              <IconButton
                onClick={handleOpenAlertIconClick}
                sx={{ padding: '2px' }}
                data-testid='open-alert-icon-button'
              >
                <RotateRight sx={{ fontSize: '30px', color: primaryModalColor }} />
              </IconButton>
            )}
            {alertConfig && alertConfig.show_conversation && Boolean(alert.question_sent) && (
              <IconButton onClick={handleConversationIconsClick} sx={{ padding: '2px' }}>
                <QuestionAnswer sx={{ fontSize: '28px', color: primaryModalColor }} />
              </IconButton>
            )}
            {alert.related_alerts && (
              <IconButton onClick={handleRelatedAlertsIconClick} sx={{ padding: '2px' }}>
                <ListAlt sx={{ fontSize: '30px', color: primaryModalColor }} />
              </IconButton>
            )}
            {alertConfig && alertConfig.show_conversation && unreadMessages > 0 && (
              <div className={css.unread_messages}>
                <IconButton onClick={handleConversationIconsClick} sx={{ padding: '2px' }}>
                  <Mail sx={{ fontSize: '28px', color: primaryModalColor }} />
                </IconButton>
                <span className={css.unread_messages_count}>{unreadMessages}</span>
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  )
}

export default FeedbackAlertsTableRow
