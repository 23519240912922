import {
  FreqFormat,
  FrequencyComparisonType,
  FrequencyQueryType,
  FrequencyUnit,
  NumericKpiFormat,
} from './frequencyModuleTypes'

const useFrequencyUnits = () => {
  const getDataFormatButtonText = (chartFormat: FreqFormat | NumericKpiFormat | null) => {
    if (
      chartFormat === FreqFormat.PERCENTAGE_ALL ||
      chartFormat === FreqFormat.PERCENTAGE_RESPONDENT ||
      chartFormat === NumericKpiFormat.PERCENT
    ) {
      return '%'
    }
    if (chartFormat === FreqFormat.N || chartFormat === NumericKpiFormat.N) {
      return 'n'
    }
    if (chartFormat === NumericKpiFormat.AVG) {
      return 'avg'
    }
    if (chartFormat === NumericKpiFormat.SUM) {
      return 'sum'
    }

    return null
  }

  const getComparisonText = (
    comparisonType: FrequencyComparisonType | undefined | null,
    cntrWidth: number,
  ) => {
    if (comparisonType === FrequencyComparisonType.DATE) {
      return cntrWidth < 400 ? 'date' : 'comparison: date'
    }
    if (comparisonType === FrequencyComparisonType.META) {
      return cntrWidth < 400 ? 'meta' : 'comparison: meta'
    }
    return cntrWidth < 400 ? 'comparison' : 'comparison: off'
  }

  const getComparisonDetailsText = (
    comparisonType: FrequencyComparisonType | undefined | null,
    comparisonDays: number | undefined,
    comparisonMeta: string | undefined,
    cntrWidth: number,
  ) => {
    if (comparisonType === FrequencyComparisonType.DATE) {
      const days = comparisonDays ? comparisonDays.toString() : ''
      return cntrWidth < 400 ? days : days + ' days'
    }
    if (comparisonType === FrequencyComparisonType.META) {
      let metaText = ''
      if (!comparisonMeta) metaText = 'No meta'
      if (comparisonMeta) metaText = comparisonMeta.toLocaleLowerCase()
      if (metaText.length > 10) metaText = metaText.slice(0, 10) + '...'
      return metaText
    }
    return ''
  }

  const getChartFormat = (
    queryType: FrequencyQueryType | undefined,
    numericKpiDataFormat: NumericKpiFormat | undefined,
    freq_format: FreqFormat | undefined,
  ) => {
    if (queryType === FrequencyQueryType.NUMERIC_KPI) {
      return numericKpiDataFormat || NumericKpiFormat.AVG
    }
    if (
      queryType === FrequencyQueryType.META ||
      queryType === FrequencyQueryType.NUMERIC ||
      queryType === FrequencyQueryType.OPEN
    ) {
      return freq_format || FreqFormat.N
    }
    return null
  }

  const getChartUnit = (
    queryType: FrequencyQueryType | undefined,
    numericKpiDataFormat: NumericKpiFormat | undefined,
    freq_format: FreqFormat | undefined,
  ) => {
    if (
      queryType === FrequencyQueryType.OPEN ||
      queryType === FrequencyQueryType.NUMERIC ||
      queryType === FrequencyQueryType.META
    ) {
      if (
        freq_format === FreqFormat.PERCENTAGE_ALL ||
        freq_format === FreqFormat.PERCENTAGE_RESPONDENT
      )
        return FrequencyUnit.PERCENT
      return FrequencyUnit.N
    }
    if (queryType === FrequencyQueryType.NUMERIC_KPI) {
      if (numericKpiDataFormat === NumericKpiFormat.PERCENT) return FrequencyUnit.PERCENT
      if (numericKpiDataFormat === NumericKpiFormat.N) return FrequencyUnit.N
      if (numericKpiDataFormat === NumericKpiFormat.SUM) return FrequencyUnit.SUM
      return FrequencyUnit.AVG
    }

    return FrequencyUnit.NONE
  }
  return {
    getDataFormatButtonText,
    getComparisonText,
    getComparisonDetailsText,
    getChartFormat,
    getChartUnit,
  }
}

export default useFrequencyUnits
