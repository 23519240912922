import { useEffect, useState } from 'react'
import { ConversationsConfig, getConversationsConfig } from '../react-services/conversationService'
import { useMountedState } from 'react-use'
import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'

const conversationsConfigStorage = makeNonResettingObservable<ConversationsConfig>({})

const useConversationsConfig = () => {
  const isMounted = useMountedState()
  const [config, setConfig] = useState<ConversationsConfig>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const key = 'conversations'

  const { tenant, user } = useStoreOwner(conversationsConfigStorage)

  useEffect(() => {
    if (!user && !tenant) return
    if (!conversationsConfigStorage.getRelatedListeners(key)) {
      getConfig()
    } else {
      const storeValue = conversationsConfigStorage.get(key)
      if (storeValue) setConfig(storeValue)
      setIsLoading(false)
    }
    return conversationsConfigStorage.subscribe(setConfig, key)
  }, [])

  const getConfig = () => {
    getConversationsConfig()
      .then((res) => {
        if (!isMounted()) return
        conversationsConfigStorage.set(res, key)
        const storeValue = conversationsConfigStorage.get(key)
        if (storeValue) setConfig(storeValue)
        setIsLoading(false)
      })
      .catch((e) => {
        if (!isMounted()) return
        setError(typeof e === 'string' ? e : e.message)
        conversationsConfigStorage.set({} as ConversationsConfig, key)
        setIsLoading(false)
      })
  }

  return {
    config,
    isLoading,
    error,
  }
}

export default useConversationsConfig
