import { moduleTypes } from '../react-constants/moduleTypes'
import { EXPORT_V3_PAYLOAD, ROW_DATA_PAYLOAD } from '../react-constants/emptyPayloads'
import { CUSTOMFILTERSELECTIONS } from '../react-constants/filters'
import { isPlainObject, isNil, cloneDeep } from 'lodash'
import { TimeFrame } from '../react-constants/timeFrame'
import _ from 'underscore'
import { numericKpiStorage } from '../stores/useNumericKpis'
import { openKpiStorage } from '../stores/useOpenKpis'
import {
  moduleIsTypeOpenFreq,
  updateQueryToModuleTimeframeSelection,
} from '../components/Dashboards/DashboardModules/SingleGraph/HelperFunctions'

function getModuleSeriesTitle(module) {
  if (module && module.series_title) return module.series_title
}

function moduleHasComparisonQueries(module) {
  let comparisonQueries = getModuleComparisonQueries(module)
  if (comparisonQueries && Object.keys(comparisonQueries).length) return true
}

function getModuleComparisonQueries(module) {
  if (module && module.comparison_queries) {
    return module.comparison_queries
  }
}

function setModuleComparisonQueries(module, comparisonQueries) {
  if (module) {
    module.comparison_queries = comparisonQueries
    return comparisonQueries
  }
}

function getModuleMetaColumns(module) {
  if (module && module.metacolumns) return module.metacolumns
}

function setModuleMetaColumns(module, metacolumns) {
  if (module) {
    module.metacolumns = metacolumns
  }
}

function getModuleQuery(module) {
  if (!isPlainObject(module)) throw new Error(`Provide the entire module object as a parameter.`)
  if (!module.type) throw new Error(`Empty module type provided: ${JSON.stringify(module)}`)

  switch (module.type) {
    case moduleTypes.EXPORT:
      return getExportModuleQuery(module)
    default:
      console.warn(
        `Unknown module type provided: ${module.type}. Implement this or check if something is wrong.`,
      )
      return {}
  }
}

function getExportModuleQuery(exportModule) {
  if (!exportModule.query) {
    console.log('Empty export module query detected, opting to use the default empty one.')
    return exportModule.dataSelectionType === 'survey' ? ROW_DATA_PAYLOAD : EXPORT_V3_PAYLOAD
  }

  return cloneDeep(exportModule.query)
}

function getModuleCustomFilters(module) {
  if (module) return module.customfilters
}

function moduleHasCustomTimeFilters(module) {
  return module && module.customfilters === CUSTOMFILTERSELECTIONS.custom_timeframe.id
}

function createKpisFromSelectionsObject(module) {
  let numericKpis = numericKpiStorage.get('numericKpis')
  let selectedKpis = numericKpis.kpis.filter((kpi) => module.selections[kpi.id] === true)
  let cleanedUpSelectedKpis = selectedKpis
    .filter((kpi) => isPlainObject(kpi))
    .map((kpi) => ({ id: kpi.id, name: kpi.name }))

  return cleanedUpSelectedKpis
}

function isModuleTypeValid(type) {
  let isValid = false
  if (type && moduleTypes) {
    let values = Object.values(moduleTypes)
    if (values) isValid = values.includes(type)
  }
  return isValid
}

function getModuleFilterGrouping(module) {
  if (module) return module.filterGrouping
}

function setModuleTimeframePreviousDays(module, timeframePreviousDays) {
  if (module) module.timeframedays = timeframePreviousDays
}

function setModuleTimeframeFutureDays(module, timeframeFutureDays) {
  if (module) module.timeframedays_future = timeframeFutureDays
}

function setModuleQuickTimeframeButton(module, quickTimeframeButton) {
  if (module) module.quicktimeframebutton = quickTimeframeButton
}

function setModuleAutoTimeScale(module, autoTimeScale) {
  if (module) module.autoTimeScale = autoTimeScale
}

function moduleIsGroup(module) {
  return module && module.type === moduleTypes.GROUP
}

/**
 * Determine which type of timeframe module is setup with.
 * Value is used by backend through POST_DYNAMICFILTERS endpoint.
 */
function getModuleTimeFrame(module) {
  if (module) {
    if (
      !isNil(module.timeframedays) ||
      module.quicktimeframebutton === 'd' ||
      module.quicktimeframebutton === 'w'
    ) {
      return TimeFrame.FIXED_WINDOW
    } else if (module.quicktimeframebutton) {
      switch (module.quicktimeframebutton) {
        case 'm':
          return TimeFrame.M
        case 'q':
          return TimeFrame.Q
        case 'y':
          return TimeFrame.Y
        case 'wtd':
          return TimeFrame.WTD
        case 'mtd':
          return TimeFrame.MTD
        case 'qtd':
          return TimeFrame.QTD
        case 'ytd':
          return TimeFrame.YTD
        case 'lm':
          return TimeFrame.LM
        case 'lq':
          return TimeFrame.LQ
        case 'ly':
          return TimeFrame.LY
        default:
      }
    }
    if (isNil(module.timeframedays_future) && module.autoTimeScale === false) {
      return TimeFrame.STATIC
    }
  }
  return null
}

const widths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
function getModuleWidth(width, type) {
  const defaultWidth = type === moduleTypes.NUMBERTREND || type === moduleTypes.PICTURE ? 3 : 6
  if (!isValidModuleWidth(width)) return defaultWidth
  else return +width
}

const isValidModuleWidth = (width) => width || !isNaN(Number(width)) || widths.includes(+width)

function useCustomFiltersOld(module) {
  return module && module.customfilters === true
}

function useCustomFilters(module) {
  return (
    module &&
    (useCustomFiltersOld(module) || module.customfilters === CUSTOMFILTERSELECTIONS.custom.id)
  )
}

function useMergeFilters(module) {
  return module && module.customfilters === CUSTOMFILTERSELECTIONS.merge.id
}

function createQuery2Filters(filters, grouping, groupFilter) {
  var queryFilters = {}
  for (var i = 0; i < filters.length; i++) {
    var filter = filters[i]
    if (Array.isArray(filter.value) && filter.value.length > 0) {
      queryFilters[filter.name] = filter.value
    }
  }
  if (
    grouping !== undefined &&
    grouping !== null &&
    groupFilter !== undefined &&
    groupFilter !== null
  ) {
    queryFilters[grouping] = groupFilter
  }
  return {
    where_meta: queryFilters,
  }
}

function createQuery2FromFilters(f) {
  var filters = cloneDeep(f)
  var grouping = filters.grouping
  var groupFilter = filters.groupFilter
  filters = filters.filters ? filters.filters : filters
  var query = createQuery2Filters(filters, grouping, groupFilter)
  // If dates already in query form, use them directly. Otherwise, find startDate/endDate filter object values
  var startDate =
    filters.start_date ||
    _.findWhere(filters, {
      name: 'startDate',
    })
  if (startDate && startDate.value) startDate = startDate.value
  var endDate =
    filters.end_date ||
    _.findWhere(filters, {
      name: 'endDate',
    })
  if (endDate && endDate.value) endDate = endDate.value
  if (startDate) {
    query.start_date = startDate
  }
  if (endDate) {
    query.end_date = endDate
  }
  if (grouping && grouping !== 'none') {
    query.grouping = grouping
  }
  return query
}

// LEGACY STUFF, COPY PASTED FROM ANGULARJS
async function createTextualQuery2FromModuleSettings(settings, openKpis) {
  console.log('moduleService: createTextualQuery2FromModuleSettings')
  console.log(cloneDeep(settings))
  var filters = {}
  filters.filters = settings.settings
  var query2 = createQuery2FromFilters(filters)
  updateQueryToModuleTimeframeSelection(settings, query2)
  var openkpis
  var freqOpenKpis = settings.KPI ? [settings.KPI] : null
  var kpiSelections = settings.query
    ? settings.query.kpis || []
    : settings.selections && !_.isEmpty(settings.selections)
    ? settings.selections
    : freqOpenKpis
    ? freqOpenKpis
    : []
  var selections
  if (moduleIsTypeOpenFreq(settings)) {
    selections = freqOpenKpis
    console.log('moduleIsTypeOpenFreq - set selections to:')
    console.log(cloneDeep(selections))
  } else {
    selections = kpiSelections
    console.log('Set selections to:')
    console.log(cloneDeep(selections))
  }
  if (openKpis) {
    openkpis = openKpis
    var kpis = []
    _.each(selections, function (id) {
      // Get id from kpi object
      if (id.id) id = id.id
      var kpi = _.findWhere(openkpis, {
        id: id,
      })
      if (kpi) kpis.push(kpi)
    })
    query2.kpis = kpis
  } else {
    const tenantKpis = openKpiStorage.get('openKpis')
    openkpis = tenantKpis ? tenantKpis : null
    const kpis = []
    _.each(selections, function (id) {
      // Get id from kpi object
      if (id.id) id = id.id
      var kpi = _.findWhere(openkpis, {
        id: id,
      })
      if (kpi) kpis.push(kpi)
    })
    query2.kpis = kpis
  }
  return Promise.resolve(query2)
}

function useWindowedTimeframe(module) {
  return module && module.timeframedays !== undefined && module.timeframedays !== null
}
function useWindowedTimeframeFuture(module) {
  return module && module.timeframedays_future !== undefined && module.timeframedays_future !== null
}

function useQuickTimeframeButton(module) {
  return (
    module &&
    module.quicktimeframebutton !== undefined &&
    module.quicktimeframebutton !== null &&
    module.quicktimeframebutton !== 'none'
  )
}

function calculateMidValueForColorAxis(limitValues) {
  var scaledMidValue = 0.5
  if (
    limitValues &&
    limitValues.mid !== undefined &&
    limitValues.mid !== null &&
    limitValues.min !== null &&
    limitValues.min !== undefined &&
    limitValues.max !== null &&
    limitValues.max !== undefined
  ) {
    var range = limitValues.max - limitValues.min
    var mid = limitValues.mid - limitValues.min
    scaledMidValue = mid / range
    // round floating point to 2 decimals
    scaledMidValue *= 100
    scaledMidValue = Math.round(scaledMidValue)
    scaledMidValue /= 100
  }
  return scaledMidValue
}

function listDataPointValuesUnderAxisLabels(module) {
  if (module && module.axislabels_listdatavalues) return true
}
export {
  getModuleSeriesTitle,
  getModuleWidth,
  isValidModuleWidth,
  moduleHasComparisonQueries,
  getModuleComparisonQueries,
  setModuleComparisonQueries,
  getModuleMetaColumns,
  setModuleMetaColumns,
  getModuleQuery,
  getModuleCustomFilters,
  moduleHasCustomTimeFilters,
  createKpisFromSelectionsObject,
  isModuleTypeValid,
  getModuleFilterGrouping,
  setModuleTimeframePreviousDays,
  setModuleTimeframeFutureDays,
  setModuleQuickTimeframeButton,
  moduleIsGroup,
  getModuleTimeFrame,
  setModuleAutoTimeScale,
  useCustomFilters,
  useMergeFilters,
  createQuery2FromFilters,
  createTextualQuery2FromModuleSettings,
  useWindowedTimeframe,
  useWindowedTimeframeFuture,
  useQuickTimeframeButton,
  calculateMidValueForColorAxis,
  listDataPointValuesUnderAxisLabels,
}
