import { GenericConfig } from '../../../../../../types'
import useCalculatedKpis from '../../../../../stores/useCalculatedKpis'
import useConfigCustomerPath from '../../../../../stores/useConfigCustomerPath'
import { FrequencyQueryType } from '../../Frequency/frequencyModuleTypes'
import { Module, ModuleType } from '../groupModuleTypes'
import { GenericQuery } from '../../_shared/ModuleSettings/settingsTypes'
import { isUndefined } from 'lodash'
import { toast } from 'react-toastify'
import { isTemplateUser } from '../../../../../react-services/authService'
import { isReportMode } from '../../../../../utilities'

const useKpiExtractor = () => {
  const { originalCalculatedKpis: calculated } = useCalculatedKpis()
  const { config: customerPath } = useConfigCustomerPath()

  function extractIdsFromQuery(
    query: GenericQuery | undefined,
    tenantCalculatedKpis: GenericConfig.CalculatedKpis | null,
  ) {
    const foundKpiIds: number[] = []
    if (!query) return foundKpiIds
    if (query.kpis) foundKpiIds.push(...query.kpis.map((kpi) => kpi.id))
    if (query.calculated_kpis && tenantCalculatedKpis) {
      for (const kpiName in query.calculated_kpis) {
        const kpi = tenantCalculatedKpis[kpiName]
        foundKpiIds.push(...(kpi?.kpiIds || []))
      }
    }
    return foundKpiIds
  }

  function extraIdsFromSelection(
    selection: { [key: string | number]: boolean },
    tenantCalculatedKpis: GenericConfig.CalculatedKpis | null,
  ) {
    const foundKpiIds: number[] = []
    for (const kpi in selection) {
      if (isNaN(Number(kpi))) {
        if (!tenantCalculatedKpis) continue
        const calculatedKpi = tenantCalculatedKpis[kpi]
        if (calculatedKpi) foundKpiIds.push(...(calculatedKpi.kpiIds || []))
      } else {
        foundKpiIds.push(Number(kpi))
      }
    }
    return foundKpiIds
  }

  function handleExtractingKpiIdsFromModules(
    modules: Module[],
    isConfigExtractionEnabled: boolean,
    toastCntrId?: string,
  ) {
    const foundKpiIds: number[] = []
    for (const m of modules) {
      const kpiIds = [] as number[]
      switch (m.type) {
        case ModuleType.ALERT: {
          break
        }
        case ModuleType.BUBBLE: {
          break
        }
        case ModuleType.FREQ: {
          const hasOpenKpis = !!(m.queryType === FrequencyQueryType.OPEN && m.options?.openKpis)
          const isNumeric =
            m.queryType === FrequencyQueryType.NUMERIC ||
            m.queryType === FrequencyQueryType.NUMERIC_KPI

          const hasNumericKpis = !!(isNumeric && m.selections)
          if (hasOpenKpis) kpiIds.push(...(m.options?.openKpis?.map((kpi) => kpi.id) || []))
          if (hasNumericKpis) kpiIds.push(...extraIdsFromSelection(m.selections || {}, calculated))
          break
        }
        case ModuleType.GROUP2:
          kpiIds.push(
            ...handleExtractingKpiIdsFromModules(
              m.modules || [],
              isConfigExtractionEnabled,
              toastCntrId,
            ),
          )
          break
        case ModuleType.GROUP:
          kpiIds.push(
            ...handleExtractingKpiIdsFromModules(
              m.modules || [],
              isConfigExtractionEnabled,
              toastCntrId,
            ),
          )
          break
        case ModuleType.LINE: {
          const hasQueryKpis = !!(m.query?.kpis || m.query?.calculated_kpis)
          const hasSelectionKpis = !!m.selections
          if (hasQueryKpis) kpiIds.push(...extractIdsFromQuery(m.query, calculated))
          else if (hasSelectionKpis) kpiIds.push(...extraIdsFromSelection(m.selections, calculated))
          break
        }
        case ModuleType.LISTNUMERIC: {
          const isConfigEnabled = !!m.options?.useTopBottomCustomerPath && isConfigExtractionEnabled
          const tenantPathIds = customerPath?.open_conf?.flatMap((kpi) => kpi.kpis) || []
          const moduleKpis = m.options?.selectedKpis || []
          if (isConfigEnabled) kpiIds.push(...tenantPathIds)
          else if (moduleKpis)
            moduleKpis.forEach((kpi) => !isUndefined(kpi.id) && kpiIds.push(kpi.id))
          break
        }
        case ModuleType.NUMBERTREND: {
          const hasQueryKpis = !!(m.query?.kpis || m.query?.calculated_kpis)
          if (hasQueryKpis) kpiIds.push(...extractIdsFromQuery(m.query, calculated))
          else if (m.selections) kpiIds.push(...extraIdsFromSelection(m.selections, calculated))
          break
        }
        case ModuleType.OPEN: {
          const hasQueryKpis = !!(m.query?.kpis || m.query?.calculated_kpis)
          if (hasQueryKpis) kpiIds.push(...extractIdsFromQuery(m.query, calculated))
          break
        }
        case ModuleType.PICTURE: {
          break
        }
        case ModuleType.PIETABULAR: {
          const hasQueryKpis = !!(m.query?.kpis || m.query?.calculated_kpis)
          if (hasQueryKpis) kpiIds.push(...extractIdsFromQuery(m.query, calculated))
          break
        }
        case ModuleType.RADAR: {
          const hasQueryKpis = !!(m.query?.kpis || m.query?.calculated_kpis)
          if (hasQueryKpis) kpiIds.push(...extractIdsFromQuery(m.query, calculated))
          break
        }
        case ModuleType.SUMMARY: {
          const isConfigEnabled = !!m.options?.isCustomerPathEnabled && isConfigExtractionEnabled
          const tenantPathIds = customerPath?.open_conf?.flatMap((kpi) => kpi.kpis) || []
          const moduleKpis = m.options?.kpis?.map((kpi) => kpi.id) || []
          if (isConfigEnabled) kpiIds.push(...tenantPathIds)
          else moduleKpis.forEach((kpi) => !isUndefined(kpi) && kpiIds.push(kpi))
          break
        }
        case ModuleType.TABULAR: {
          const hasQueryKpis = !!(m.query?.kpis || m.query?.calculated_kpis)
          if (hasQueryKpis) kpiIds.push(...extractIdsFromQuery(m.query, calculated))
          break
        }
        case ModuleType.TEXTBOX: {
          const hasSelectionKpis = !!m.options?.selections
          if (hasSelectionKpis)
            kpiIds.push(...extraIdsFromSelection(m.options?.selections || {}, calculated))
          break
        }
        case ModuleType.WHEEL: {
          const isConfigEnabled =
            m.options?.isCustomerPathConfigSelected && isConfigExtractionEnabled
          const tenantPathIds = customerPath?.open_conf?.flatMap((kpi) => kpi.kpis) || []
          const modulePath = m.options?.moduleWheelConfig?.conf
          if (isConfigEnabled) kpiIds.push(...tenantPathIds)
          else if (modulePath)
            modulePath.forEach((kpi) => !isUndefined(kpi.kpiid) && kpiIds.push(kpi.kpiid))
          break
        }
        default:
          if (isTemplateUser() && !isReportMode()) {
            toast.info(
              `Module of type "${m.type} has not been added to extractor, module has title "${m.title}"`,
              {
                toastId: toastCntrId,
              },
            )
          }
          break
      }
      foundKpiIds.push(...kpiIds)
    }
    return foundKpiIds
  }

  return { handleExtractingKpiIdsFromModules }
}

export default useKpiExtractor
