import React, { memo, useMemo } from 'react'
import ReportItem from './ReportItem'
import { Card, Icon, Row, Col } from 'react-materialize'
import { hasRole } from '../../../../react-services/authService'
import { Report, ReportsOverViewModule } from './reportsOverViewTypes'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { cloneDeep } from 'lodash'

import './ReportsOverview.scss'
import useDbTemplateConfig from '../../../../stores/useDbTemplateConfig'

type ReportsOverviewProps = {
  module: ReportsOverViewModule
  id: string
  sharedFilter?: SharedFilter
  saveModule: (module: ReportsOverViewModule) => void
  isReportMode?: boolean
  isScreenMode?: boolean
}
const ReportsOverview = memo(({ module, saveModule }: ReportsOverviewProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { config: dbTemplates } = useDbTemplateConfig()
  const reportTemplateKey = useMemo(() => {
    for (const template in dbTemplates) {
      if (dbTemplates[template][0].is_report_template) return template
    }
    return null
  }, [dbTemplates])
  const reportTemplate =
    reportTemplateKey && dbTemplates?.[reportTemplateKey] ? dbTemplates[reportTemplateKey][0] : null
  const isAdmin = hasRole('admin')

  const saveReport = (newReport: Report) => {
    const allReports = module.reports ? module.reports : []
    allReports.push(newReport)
    const newModule = cloneDeep(module)
    newModule.reports = allReports
    saveModule(newModule)
  }

  const modifyReport = (newReport: Report) => {
    const newModule = cloneDeep(module)
    if (!newModule.reports) return
    const reportToModify = newModule.reports.find((rep) => rep.id === newReport.id)
    if (!reportToModify) return
    const indexOfModified = newModule.reports.indexOf(reportToModify)
    if (indexOfModified >= 0) {
      newModule.reports[indexOfModified] = newReport
      saveModule(newModule)
    } else {
      console.error('Failed to modify a report because it cant be found')
    }
  }

  const deleteReport = (report: Report) => {
    const newModule = cloneDeep(module)
    if (!newModule.reports) return
    const remainingReports = newModule.reports.filter((rep) => rep.id !== report.id)
    newModule.reports = remainingReports
    saveModule(newModule)
  }

  return (
    <div className='summary-module reports-overview'>
      <div className='infograph'>
        {!reportTemplate && (
          <>
            <Card className='orange white-text'>
              <Row className='marginless-row'>
                <Col>
                  <Icon>warning</Icon>
                </Col>
                <Col>
                  No report templates. Please contact helpdesk@wheelq.com to resolve this issue.
                </Col>
              </Row>
            </Card>
            <div className='reports-overview-disable-overlay'></div>
          </>
        )}

        {!isAdmin && (
          <>
            <Card className='red white-text'>
              <Row className='marginless-row'>
                <Col>
                  <Icon>warning</Icon>
                </Col>
                <Col>Administrator rights are required to use this module.</Col>
              </Row>
            </Card>
            <div className='reports-overview-disable-overlay'></div>
          </>
        )}

        {module.reports &&
          module.reports.length > 0 &&
          module.reports.map((rep, index) => (
            <ReportItem
              key={`report-${rep.title}-${index}`}
              index={index}
              reportConf={rep}
              reportTemplate={reportTemplate}
              onSave={saveReport}
              onModify={modifyReport}
              onDelete={deleteReport}
            />
          ))}
        <ReportItem isEmpty reportTemplate={reportTemplate} onSave={saveReport} />
      </div>
    </div>
  )
})

ReportsOverview.displayName = 'ReportsOverview'
export default ReportsOverview
