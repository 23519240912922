import React, { useMemo } from 'react'
import { QuickTimeframe } from '../groupModuleTypes'
import { Tooltip } from '@mui/material'
import { tCommon } from '../../../../../../languages/i18n'

import css from './QuickTimeFrameButton.module.scss'

type QuickTimeFrameButtonProps = {
  label: string
  isActive: boolean
  isSmall: boolean
  onSelectQuickTimeFrame: (quickTimeFrame: QuickTimeframe) => void
}
const QuickTimeFrameButton = ({
  label,
  isActive,
  isSmall,
  onSelectQuickTimeFrame,
}: QuickTimeFrameButtonProps) => {
  const classes = useMemo(() => {
    let newClasses = css.cntr || ''
    if (isActive) newClasses = newClasses.concat(' ', css.active)
    if (isSmall) newClasses = newClasses.concat(' ', css.nested)
    return newClasses
  }, [isActive])

  const handleClick = () => {
    if (!Object.keys(QuickTimeframe).includes(label)) return
    const tf = label.toLocaleLowerCase() as QuickTimeframe
    onSelectQuickTimeFrame(tf)
  }

  return (
    <Tooltip title={tCommon('info.' + label.toLocaleLowerCase())}>
      <div
        className={classes}
        onClick={handleClick}
        data-testid={`qtf-${label.toLocaleLowerCase()}`}
      >
        {label}
      </div>
    </Tooltip>
  )
}

export default QuickTimeFrameButton
