import React from 'react'
import css from './RadarModule.module.scss'
import {
  dataModeNotification,
  lessTopicsNotification,
  metaKeyNotification,
  metaValueNotification,
  moreTopicsNotification,
} from './RadarNotifications'
import NoData from '../../../_shared/Infos/NoData'

type RadarNotificationProps = {
  moreTopicsNote: boolean
  lessTopicsNote: boolean
  dataModeNote: boolean
  metaKeyNote: boolean
  metaValueNote: boolean
}

const RadarNotification = ({
  moreTopicsNote,
  lessTopicsNote,
  dataModeNote,
  metaKeyNote,
  metaValueNote,
}: RadarNotificationProps) => {
  const showNotification =
    moreTopicsNote || lessTopicsNote || dataModeNote || metaKeyNote || metaValueNote

  return (
    <>
      {showNotification && (
        <div className={css.radar_notifications} data-testid='radar-notifications'>
          <>
            <div className={css.title}>
              <i className='material-icons'>radar</i>
              <span> Radar Notification</span>
            </div>
            <div className={css.content}>
              <ul>
                {moreTopicsNote && <li>{moreTopicsNotification}</li>}
                {lessTopicsNote && <li>{lessTopicsNotification}</li>}
                {dataModeNote && <li>{dataModeNotification}</li>}
                {metaKeyNote && <li>{metaKeyNotification}</li>}
                {metaValueNote && <li>{metaValueNotification}</li>}
              </ul>
            </div>
          </>
          <div>
            <NoData />
          </div>
        </div>
      )}
    </>
  )
}

export default RadarNotification
