import React, { useEffect, useMemo, useState } from 'react'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import css from './TopBottomTables.module.scss'
import { useTopBottomData } from '../Context/TopBottomDataContext'
import {
  DataDisplayOptions,
  TopBottomColumnNames,
  TopBottomStructuredData,
} from '../topBottomTypes'
import { useTopBottomSettings } from '../Context/TopBottomSettingsContext'
import useTopBottomFunctions from '../useTopBottomFunctions'
import useCommonDbSettingsConfig from '../../../../../stores/useCommonDbSettingsConfig'
import NoData from '../../../../_shared/Infos/NoData'
import { upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'

const TopBottomTables = () => {
  const { t } = useTranslation()
  const [{ topBottomData, topBottomLimitValues }] = useTopBottomData()
  const [{ selectedColumns, showDataOfInterest, topBottomDataDisplay, topBottomMax }] =
    useTopBottomSettings()
  const { config: commonDbSettings } = useCommonDbSettingsConfig()
  const { getTrendIcon, getTrendColor } = useTopBottomFunctions()
  const [topItems, setTopItems] = useState<TopBottomStructuredData[]>([])
  const [bottomItems, setBottomItems] = useState<TopBottomStructuredData[]>([])

  const decimals = useMemo(() => {
    return typeof commonDbSettings?.decimals === 'number' ? commonDbSettings.decimals : 1
  }, [commonDbSettings])

  useEffect(() => {
    if (!topBottomData.length) return

    const sortedTopItems = [...topBottomData].sort((a, b) => Number(b.average) - Number(a.average))
    const sortedBottomItems = [...topBottomData].sort(
      (a, b) => Number(a.average) - Number(b.average),
    )

    if (showDataOfInterest) {
      const newTopItemsWithInterest = sortedTopItems.filter((dataItem) => {
        if (Number(dataItem.average) > topBottomLimitValues.max) return dataItem
      })
      const newBottomItemsWithInterest = sortedBottomItems.filter((dataItem) => {
        if (Number(dataItem.average) < topBottomLimitValues.min) return dataItem
      })
      setTopItems(newTopItemsWithInterest)
      setBottomItems(newBottomItemsWithInterest)
      return
    }

    setTopItems(sortedTopItems)
    setBottomItems(sortedBottomItems)
  }, [topBottomData, showDataOfInterest])

  const headers = (
    <tr>
      {selectedColumns.includes(TopBottomColumnNames.CATEGORY) && (
        <th colSpan={4}>{t('common.label.category')}</th>
      )}
      {selectedColumns.includes(TopBottomColumnNames.KPI) && (
        <th colSpan={4}>{TopBottomColumnNames.KPI}</th>
      )}
      {selectedColumns.includes(TopBottomColumnNames.PERFORMANCE) && (
        <th colSpan={2}>{t('common.label.performance')}</th>
      )}
      {selectedColumns.includes(TopBottomColumnNames.TREND) && (
        <th colSpan={2}>{t('common.label.trend')}</th>
      )}
    </tr>
  )

  const tableRowsTopFive = () => {
    return topItems.slice(0, Number(topBottomMax)).map((value) => {
      const average = Number(value.average)
      let trendNum = 'N/A'
      let trendIcon = ''
      if (value.trend_change) {
        trendNum = ` ${value.trend_change.toFixed(decimals)}`
        trendIcon = getTrendIcon(value.trend_change.toFixed(decimals))
      }

      return (
        <tr key={value.kpiid}>
          {selectedColumns.includes(TopBottomColumnNames.CATEGORY) && (
            <td colSpan={4}>
              {upperFirst(t(value.category.toLocaleLowerCase(), { ns: 'categories' }))}
            </td>
          )}
          {selectedColumns.includes(TopBottomColumnNames.KPI) && (
            <td colSpan={4}>{upperFirst(t(value.kpiname.toLocaleLowerCase(), { ns: 'kpis' }))}</td>
          )}
          {selectedColumns.includes(TopBottomColumnNames.PERFORMANCE) && (
            <td colSpan={2} className={css.table_performance}>
              {value.average.toFixed(decimals)}
              <span
                className={`${css.performance_icon} ${
                  average > topBottomLimitValues.max
                    ? css.max
                    : average < topBottomLimitValues.min
                    ? css.min
                    : ''
                }`}
              ></span>
            </td>
          )}
          {selectedColumns.includes(TopBottomColumnNames.TREND) && (
            <td colSpan={2} className={css.table_trend}>
              {Number(trendNum) >= 0 ? (
                <>
                  <i className='material-icons'>add</i>
                  {' ' + Math.abs(Number(trendNum)).toFixed(decimals)}
                </>
              ) : (
                <>
                  <i className='material-icons'>remove</i>
                  {' ' + Math.abs(Number(trendNum)).toFixed(decimals)}
                </>
              )}{' '}
              <i className='material-icons' style={{ color: getTrendColor(trendIcon) }}>
                {' '}
                {trendIcon}
              </i>
            </td>
          )}
        </tr>
      )
    })
  }

  const tableRowsBottomFive = () => {
    return bottomItems.slice(0, Number(topBottomMax)).map((value) => {
      const average = Number(value.average)
      let trendNum = 'N/A'
      let trendIcon = ''
      if (value.trend_change) {
        trendNum = ` ${value.trend_change.toFixed(decimals)}`
        trendIcon = getTrendIcon(value.trend_change.toFixed(decimals))
      }

      return (
        <tr key={value.kpiid}>
          {selectedColumns.includes(TopBottomColumnNames.CATEGORY) && (
            <td colSpan={4}>
              {upperFirst(t(value.category.toLocaleLowerCase(), { ns: 'categories' }))}
            </td>
          )}
          {selectedColumns.includes(TopBottomColumnNames.KPI) && (
            <td colSpan={4}>{upperFirst(t(value.kpiname.toLocaleLowerCase(), { ns: 'kpis' }))}</td>
          )}
          {selectedColumns.includes(TopBottomColumnNames.PERFORMANCE) && (
            <td colSpan={2} className={css.table_performance}>
              {value.average.toFixed(decimals)}
              <span
                className={`${css.performance_icon} ${
                  average > topBottomLimitValues.max
                    ? css.max
                    : average < topBottomLimitValues.min
                    ? css.min
                    : ''
                }`}
              ></span>
            </td>
          )}
          {selectedColumns.includes(TopBottomColumnNames.TREND) && (
            <td colSpan={2} className={css.table_trend}>
              {Number(trendNum) >= 0 ? (
                <>
                  <i className='material-icons'>add</i>
                  {' ' + Math.abs(Number(trendNum)).toFixed(decimals)}
                </>
              ) : (
                <>
                  <i className='material-icons'>remove</i>
                  {' ' + Math.abs(Number(trendNum)).toFixed(decimals)}
                </>
              )}{' '}
              <i className='material-icons' style={{ color: getTrendColor(trendIcon) }}>
                {trendIcon}
              </i>
            </td>
          )}
        </tr>
      )
    })
  }

  return (
    <div className={css.top_bottom_tables}>
      {topBottomData && topBottomData.length ? (
        <>
          {(topBottomDataDisplay === DataDisplayOptions.TOP_BOTTOM ||
            topBottomDataDisplay === DataDisplayOptions.TOP) && (
            <div className={`${css.table_item} top-table`}>
              <GenericTable headers={headers} rows={tableRowsTopFive()} useFixedLayout={true} />
            </div>
          )}
          {(topBottomDataDisplay === DataDisplayOptions.TOP_BOTTOM ||
            topBottomDataDisplay === DataDisplayOptions.BOTTOM) && (
            <div className={`${css.table_item} bottom-table`}>
              <GenericTable headers={headers} rows={tableRowsBottomFive()} useFixedLayout={true} />
            </div>
          )}
        </>
      ) : (
        <div className={css.noData}>
          <NoData />
        </div>
      )}
    </div>
  )
}

export default TopBottomTables
