import React from 'react';
import { Icon, Button } from 'react-materialize';
import ManualSenderRecipientsTop from './ManualSenderRecipientsTop';
import ManualSenderRecipientTemplate from './ManualSenderRecipientTemplate';

const ManualSenderRecipients = ({useContacts, contacts, recipients, surveyTemplate, onAdd, onModify, onRemove}) => {
  // for contacts mode
  let selectedContacts = recipients ? recipients.filter(rec => rec.contactId !== null) : [];
  let disableAddButton = !surveyTemplate || (useContacts && (contacts.length === recipients.length))

  return (
    <div id="manual-survey-middle">
      <ManualSenderRecipientsTop
        isEmpty={!recipients || recipients.length === 0}
      />
      
      {recipients && recipients.map(rec => 
        <ManualSenderRecipientTemplate
          key={rec.index}
          index={rec.index}
          metadata={rec.metadata}
          invitationMetadata={rec.invitationMetadata}
          selectedContacts={selectedContacts.map(r => r.contactId)}

          onModify={onModify}
          onRemove={onRemove}
        />
      )}

      <div id="add-recipient-block" className="center">
        <Button data-jt="addRecipient" disabled={disableAddButton} className="waves-effect waves-teal btn-flat" onClick={onAdd}>
          <Icon>add_circle_outline</Icon>
        </Button>
      </div>
    </div>
  )
}

export default ManualSenderRecipients;