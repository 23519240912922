import React, { memo, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import * as Highcharts from 'highcharts8'
import { cloneDeep } from 'lodash'
import { createChart } from './pieChart'
import { ActionTypes, pieReducer } from './pieReducer'
import { FreqFormat, FrequencyPieType, NumericKpiFormat } from '../frequencyModuleTypes'
import useCntrWidthObserver from '../../../../../utilities/hooks/useCntrWidthObserver'
import FadeIn from '../../_shared/FadeIn'
import { useDeepCompareEffect, useUpdateEffect } from 'react-use'
import { CircularProgress } from '@mui/material'

import css from '../FrequencyModuleCntr.module.scss'

type PieProps = {
  isReportMode: boolean
  isScreenMode: boolean
  series: Highcharts.SeriesPieOptions[]
  categories: string[]
  pieType?: FrequencyPieType
  chartFormat: FreqFormat | NumericKpiFormat | null
  handleClickChartPoint: (e: Highcharts.PointClickEventObject) => void
  isLoading: boolean
  moduleCntrRef: React.MutableRefObject<HTMLDivElement | undefined>
  isRefetching: boolean
}
const Pie = memo(
  ({
    isReportMode,
    isScreenMode,
    series,
    categories,
    pieType,
    chartFormat,
    handleClickChartPoint,
    moduleCntrRef,
    isRefetching,
  }: PieProps) => {
    const isReportOrScreen = isReportMode || isScreenMode
    const initOptionsState = useMemo(() => createChart(isReportOrScreen, handleClickChartPoint), [])
    const { cntrWidth: moduleWidth } = useCntrWidthObserver(moduleCntrRef)
    const CHART_RENDER_TIMEOUT = 1000
    const chartUpdateTimeoutRef = useRef<NodeJS.Timeout>()
    const chartComponentRef = useRef<HighchartsReactRefObject>(null)
    const chartCntrRef = useRef<HTMLDivElement | null>(null)
    const [chartOptions, setChartOptions] = useReducer(pieReducer, initOptionsState)
    const [chartCopy, setChartCopy] = useState<Highcharts.Options | null>(null)

    const reflowTimeoutRef = useRef<NodeJS.Timeout>()
    useUpdateEffect(() => {
      if (isReportOrScreen) return
      if (reflowTimeoutRef.current) clearTimeout(reflowTimeoutRef.current)
      reflowTimeoutRef.current = setTimeout(() => {
        if (chartComponentRef.current) {
          chartComponentRef.current.chart.reflow()
        }
      }, 100)
      return () => reflowTimeoutRef.current && clearTimeout(reflowTimeoutRef.current)
    }, [moduleWidth])

    useDeepCompareEffect(() => {
      if (chartUpdateTimeoutRef.current) clearTimeout(chartUpdateTimeoutRef.current)
      if (reflowTimeoutRef.current) clearTimeout(reflowTimeoutRef.current)
      chartUpdateTimeoutRef.current = setTimeout(() => {
        setChartCopy(chartOptions)
      }, CHART_RENDER_TIMEOUT)
      return () => chartUpdateTimeoutRef.current && clearTimeout(chartUpdateTimeoutRef.current)
    }, [chartOptions])

    useDeepCompareEffect(() => {
      if (series) {
        setChartOptions({
          type: ActionTypes.SET_DATA,
          data: {
            series,
            categories,
          },
        })
      }
    }, [series, categories])

    useEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_PIE_TYPE,
        data: {
          type: pieType,
        },
      })
    }, [pieType])

    useDeepCompareEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_DATA_FORMAT,
        data: {
          chartFormat,
        },
      })
    }, [series, chartFormat])

    const chart = useMemo(() => {
      if (chartCopy) {
        return (
          <HighchartsReact
            highcharts={Highcharts}
            options={cloneDeep(chartCopy)}
            containerProps={{ id: 'wheel-container' }}
            ref={chartComponentRef}
            updateArgs={[true, true, true]}
          />
        )
      }
      return null
    }, [chartCopy])

    return (
      <div
        className={css.cntr}
        ref={(newRef) => {
          if (newRef) chartCntrRef.current = newRef
        }}
      >
        {isRefetching && (
          <div className={css.loadingRefetch}>
            <CircularProgress thickness={2} size={'1.7rem'} style={{ opacity: 0.6 }} />
          </div>
        )}
        {chart ? <FadeIn durationMs={2000}>{chart}</FadeIn> : <div></div>}
      </div>
    )
  },
)

Pie.displayName = 'Pie'
export default Pie
