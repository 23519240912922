import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreNonResetting, { ActionState } from './useStoreNonResetting'
import { getDbTemplateConfig, putDbTemplateConfig } from '../react-services/configService2'
import { Module } from '../components/Dashboards/DashboardModules/Group/groupModuleTypes'

export type Dashboard = {
  export_button?: boolean
  name: string
  modules: Module[]
  module_id?: string
  uuid?: string
  autoTimeScale?: boolean
}

export type DashboardSettings = {
  dashboards?: Dashboard[]
  is_report_template?: boolean
  name?: string
  templatekey?: string
  id?: string
  use_dynamic_filters?: boolean | 'timeframe'
}

export type DbTemplate = {
  [SECTIONKEY: string]: DashboardSettings[]
}

export const dashboardTemplateStorage = makeNonResettingObservable<DbTemplate>({})

const useDbTemplateConfig = () => {
  const key = 'dbtemplate'
  const { tenant, user } = useStoreOwner(dashboardTemplateStorage)
  const {
    dataState: { data, error, state },
    put: putDbTemplate,
  } = useStoreNonResetting(
    dashboardTemplateStorage,
    key,
    user,
    tenant,
    true,
    getDbTemplateConfig,
    () => Promise.resolve(),
    putDbTemplateConfig,
  )

  const isLoading = state !== ActionState.DONE && state !== ActionState.ERROR
  return { config: data, error, isLoading, putDbTemplate }
}

export default useDbTemplateConfig
