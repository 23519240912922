import { useEffect, useRef, useState } from 'react'
import { getOpenAnswersNew } from '../react-services/openAnswersService'
import { isGroupedAndPaginatedOpenAnswersObject } from '../utilities'
import { errorFetchOpenAnswers } from '../components/Dashboards/DashboardModules/Pietabular/PietabularNotifications'
import { OpenAnswer } from '../components/Dashboards/DashboardModules/Pietabular/pietabularTypes'
import objectHash from 'object-hash'
import { useMountedState } from 'react-use'

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OpenAnswersResponse = {
  series: {
    name: string
    data: OpenAnswer[]
  }[]
  pageMetadata: {
    page_size: number
    total_elements: number
    total_pages: number
    page_number: number
  }
}

export type OpenAnswersRequest = {
  start_date: string
  end_date: string
  answers?: string[]
  pagination?: {
    page_size?: number
    page_number?: number
  }
  kpis: {
    id: number
    name: string
  }[]
  where_meta: {
    [metaKey: string]: (string | null)[]
  }
  category_grouping?: {
    sentiment?: (null | string)[] | undefined
    topic?: (null | string)[] | undefined
    custom?: {
      [categoryKey: string]: (null | string)[] | undefined
    }
  }
  sort_columns_with_order?: {
    order: SortDirection
    columnName: string
  }[]
}

const useNewOpenAnswers = (query: OpenAnswersRequest | null) => {
  const fetchTimeout = useRef<NodeJS.Timeout>()
  const refetchTimeOut = useRef<NodeJS.Timeout>()
  const isMounted = useMountedState()
  const key = objectHash(query, { unorderedArrays: true, unorderedObjects: true })
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRefetching, setIsRefetching] = useState<boolean>(false)
  const [pagination, setPagination] = useState<OpenAnswersResponse['pageMetadata'] | null>(null)

  const [answers, setAnswers] = useState<OpenAnswer[] | null>(null)

  useEffect(() => {
    if (query) {
      if (!isMounted()) return
      setIsLoading(true)
      if (fetchTimeout.current) clearTimeout(fetchTimeout.current)
      if (refetchTimeOut.current) {
        setIsRefetching(false)
        clearTimeout(refetchTimeOut.current)
      }
      fetchTimeout.current = setTimeout(() => {
        fetchAnswers(query)
      }, 100)
    } else {
      setIsLoading(false)
    }
    return () => fetchTimeout.current && clearTimeout(fetchTimeout.current)
  }, [key])

  useEffect(() => {
    if (isRefetching) {
      if (refetchTimeOut.current) clearTimeout(refetchTimeOut.current)
      refetchTimeOut.current = setTimeout(() => {
        fetchMoreAnswers()
      }, 300)
    }
    return () => refetchTimeOut.current && clearTimeout(refetchTimeOut.current)
  }, [isRefetching])

  const fetchAnswers = (query: OpenAnswersRequest) => {
    setIsLoading(true)
    getOpenAnswersNew(query)
      .then((res: unknown) => {
        if (isGroupedAndPaginatedOpenAnswersObject(res)) {
          if (!isMounted()) return
          handleSettingDataToStates(res, true)
        }
      })
      .catch(() => {
        if (!isMounted()) return
        setError(errorFetchOpenAnswers)
        setAnswers([])
      })
      .finally(() => isMounted() && setIsLoading(false))
  }

  const fetchMoreAnswers = () => {
    if (!query || !pagination || pagination.page_number >= pagination.total_pages) {
      setIsRefetching(false)
      setIsLoading(false)
      return Promise.resolve(false)
    }

    return getOpenAnswersNew({
      ...query,
      pagination: {
        ...(query.pagination ? query.pagination : {}),
        page_number: pagination.page_number + 1,
      },
    })
      .then((res: unknown) => {
        if (isGroupedAndPaginatedOpenAnswersObject(res)) {
          if (!isMounted()) return
          handleSettingDataToStates(res, false)
          return res.pageMetadata.page_number >= res.pageMetadata.total_elements
        } else {
          throw new Error('')
        }
      })
      .catch(() => {
        if (!isMounted()) return
        setError(errorFetchOpenAnswers)
        setAnswers([])
        setPagination(null)
        return false
      })
      .finally(() => {
        if (!isMounted()) return
        setIsRefetching(false)
        setIsLoading(false)
      })
  }

  const createUngroupedAnswersList = (data: OpenAnswersResponse): OpenAnswer[] => {
    if (data && data.series) {
      return data.series.flatMap((group) => {
        if (group.data) {
          return group.data.map((answer) => ({ ...answer, question: group.name }))
        }
        return []
      })
    }
    return []
  }

  const createUniqueAnswersList = (answers: OpenAnswer[]) => {
    const uniqueAnswers = [] as OpenAnswer[]
    answers.forEach((answer) => {
      if (!uniqueAnswers.map((answer) => answer.answer_id).includes(answer.answer_id)) {
        uniqueAnswers.push(answer)
      }
    })
    return uniqueAnswers
  }

  const handleSettingDataToStates = (res: OpenAnswersResponse, resetData: boolean) => {
    const ungroupedAnswers = createUngroupedAnswersList(res)
    setPagination(res.pageMetadata)
    setAnswers((prev) =>
      prev && !resetData
        ? createUniqueAnswersList([...prev, ...ungroupedAnswers])
        : createUniqueAnswersList(ungroupedAnswers),
    )
  }

  const fetchMore = () => {
    const totalPages = pagination?.total_pages || 0
    const currentPage = pagination?.page_number || 0
    const totalAnswers = pagination?.total_elements || 0
    if (totalPages - currentPage > 0 && totalAnswers > 0) {
      setIsRefetching(true)
      return Promise.resolve(true)
    } else {
      setIsRefetching(false)
      return Promise.resolve(false)
    }
  }

  return {
    error,
    isLoading,
    isRefetching,
    answers,
    pagination,
    fetchMoreAnswers: fetchMore,
    totalAnswers: pagination?.total_elements,
  }
}

export default useNewOpenAnswers
