import React from 'react'
import Rodal from 'rodal'
import { Row, Col } from 'react-materialize'
import MainButton from '../../_shared/Buttons/MainButton'

type Props = {
  errorMessages: ErrorMessage[]
  savedRecipients: string[]
  closeSaveSummary: () => void
}

type ErrorMessage = {
  email: string
  errMessage: string
}

const SaveSummaryRodal = ({ errorMessages, savedRecipients, closeSaveSummary }: Props) => {

  return (
    <div>
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={true}
        onClose={closeSaveSummary}
      >
        <h5>Summary!</h5>
        <Row>
          <Col s={12}><hr /></Col>
          <Col s={12}></Col>
        </Row>
        {savedRecipients.length > 0 &&
          <>
            <p className="green-text">Added recipients:</p>
            <ul>{savedRecipients.map((recipient, i: number) =>
              <li key={i}>{recipient}</li>)}
            </ul>
          </>
        }
        {errorMessages.length > 0 &&
          <>
            <p className="red-text">These emails where ignored due to errors.</p>
            <ul>{errorMessages.map((err: ErrorMessage, i: number) =>
              <li key={i}>{err.email + err.errMessage}</li>)}
            </ul>
          </>
        }
        <br /><br />
        <footer>
          <MainButton isFlat text="Close" onClick={closeSaveSummary} />
        </footer>
      </Rodal>
    </div>
  )
}

export default SaveSummaryRodal