import React from 'react'
import SelectMetas from './SelectMetas'
import SelectTimeframe from './SelectTimeframe'
import SelectView from './SelectView'

import css from './SummaryFilters.module.scss'

const Filters = () => {
  return (
    <div className={css.content_filters}>
      <SelectView />
      <SelectMetas />
      <SelectTimeframe />
    </div>
  )
}

export default Filters
