import React from 'react'

const Linebreak = () => {
  return (
    <div
      style={{
        borderTop: '1px solid #5a5a5a',
        width: '100%',
        opacity: 0.6,
        marginBottom: '-1.2vh',
      }}
    ></div>
  )
}

export default Linebreak
