import React, { useEffect } from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { Dashboard } from '../../../../../../types'
import { cloneDeep, debounce, isEqual } from 'lodash'
import { useUpdateEffect } from 'react-use'
import useReportingFilters from '../../../../../stores/useReportingFilters'

import modal_css from './../../../DashboardModules/_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../../DashboardModules/_shared/ModuleSettings/components/CheckBoxSelectionList.module.scss'

const TooltipMetaSelection = () => {
  const [{ module, saveModule }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  if (module?.type === 'bubble') bubbleModule = module
  const savedValues = bubbleModule?.tooltipMetas || []
  const { metas } = useReportingFilters()
  const [selectedMetaKeys, setSelectedMetaKeys] = React.useState<string[]>([])

  useEffect(() => {
    if (!bubbleModule) return
    setSelectedMetaKeys(savedValues)
  }, [])

  const SAVE_TIMEOUT = 1000
  useUpdateEffect(() => {
    if (!bubbleModule) return
    if (isEqual(savedValues, selectedMetaKeys)) return
    const debouncer = debounce(() => {
      const newModule = cloneDeep(bubbleModule)
      if (!newModule) return
      newModule.tooltipMetas = selectedMetaKeys
      saveModule(newModule)
    }, SAVE_TIMEOUT)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [selectedMetaKeys])

  const handleClickMetaKey = (metaKey: string) => {
    if (!bubbleModule) return
    setSelectedMetaKeys((prev) => {
      const newValues = prev.includes(metaKey)
        ? prev.filter((value) => value !== metaKey)
        : [...prev, metaKey]
      return newValues
    })
  }

  return (
    <div className={`${modal_css.max}`}>
      Select extensions to show
      <div className={css.list}>
        {Object.keys(metas || {}).map((metaKey) => (
          <div className={modal_css.third} key={metaKey}>
            <input
              type='checkbox'
              id={metaKey + 'checkbox'}
              checked={selectedMetaKeys.includes(metaKey)}
              onChange={(e) => e.currentTarget.blur()}
              data-testid={metaKey + 'checkbox'}
              name={metaKey}
            />
            <label
              data-testid={metaKey + 'checkbox-label'}
              htmlFor={metaKey + 'checkbox'}
              id={metaKey + 'checkbox-label'}
              onClick={() => handleClickMetaKey(metaKey)}
            >
              {metaKey}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TooltipMetaSelection
