import { isArray } from 'lodash'
import { WhereMeta } from '../../types'

export type FilterArray = (
  | { name: 'startDate'; value: string }
  | { name: 'endDate'; value: string }
  | { name: string; value: string[] }
)[]

export type FilterObject = {
  start_date: string
  end_date: string
  where_meta?: WhereMeta
  filters?: WhereMeta
}

export const convertFilterArrayToFilterObject = (filterArray = [] as FilterArray) => {
  const filterObject = {
    start_date: '',
    end_date: '',
    where_meta: {} as WhereMeta,
  }
  filterArray.forEach((filter) => {
    if (filter.name === 'startDate' && typeof filter.value === 'string') {
      filterObject.start_date = filter.value
    } else if (filter.name === 'endDate' && typeof filter.value === 'string') {
      filterObject.end_date = filter.value
    } else if (filter.name !== 'startDate' && filter.name !== 'endDate') {
      const metaKey = filter.name
      const metaValues = filter.value
      if (!isArray(metaValues)) return
      if (!filterObject.where_meta) filterObject.where_meta = {}
      filterObject.where_meta[metaKey] = metaValues
    }
  })
  return filterObject
}

export const convertFilterObjectToFilterArray = (filterObject: FilterObject): FilterArray => {
  const filterArray: FilterArray = []
  if (filterObject.start_date)
    filterArray.push({ name: 'startDate', value: filterObject.start_date })
  if (filterObject.end_date) filterArray.push({ name: 'endDate', value: filterObject.end_date })
  if (Object.keys(filterObject.where_meta || {}).length) {
    for (const [metaKey, metaValues] of Object.entries(filterObject.where_meta || {})) {
      filterArray.push({ name: metaKey, value: metaValues })
    }
  }
  if (Object.keys(filterObject.filters || {}).length) {
    for (const [metaKey, metaValues] of Object.entries(filterObject.filters || {})) {
      filterArray.push({ name: metaKey, value: metaValues })
    }
  }
  return filterArray
}

export const getFilterArrayStartDate = (filterArray: FilterArray): string => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  return filterObject.start_date
}

export const setFilterArrayStartDate = (
  filterArray: FilterArray,
  startDate: string,
): FilterArray => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  filterObject.start_date = startDate
  return convertFilterObjectToFilterArray(filterObject)
}

export const getFilterArrayEndDate = (filterArray: FilterArray): string => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  return filterObject.end_date
}

export const setFilterArrayEndDate = (filterArray: FilterArray, endDate: string): FilterArray => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  filterObject.end_date = endDate
  return convertFilterObjectToFilterArray(filterObject)
}

export const getFilterArrayWhereMeta = (filterArray: FilterArray): WhereMeta => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  return filterObject.where_meta || {}
}

export const setFilterArrayWhereMeta = (
  filterArray: FilterArray,
  whereMeta: WhereMeta,
): FilterArray => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  filterObject.where_meta = whereMeta
  return convertFilterObjectToFilterArray(filterObject)
}

export const addMetaValuesToFilterArray = (
  filterArray: FilterArray,
  metaKey: string,
  metaValues: string[],
): FilterArray => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  if (!filterObject.where_meta) filterObject.where_meta = {}
  if (!filterObject.where_meta[metaKey]) filterObject.where_meta[metaKey] = []
  filterObject.where_meta[metaKey] = [...filterObject.where_meta[metaKey], ...metaValues]
  filterObject.where_meta[metaKey] = Array.from(new Set(filterObject.where_meta[metaKey]))
  return convertFilterObjectToFilterArray(filterObject)
}

export const removeMetaValuesFromFilterArray = (
  filterArray: FilterArray,
  metaKey: string,
  metaValues: string[],
): FilterArray => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  if (!filterObject.where_meta) filterObject.where_meta = {}
  if (!filterObject.where_meta[metaKey]) filterObject.where_meta[metaKey] = []
  filterObject.where_meta[metaKey] = filterObject.where_meta[metaKey].filter(
    (metaValue) => !metaValues.includes(metaValue),
  )
  if (!filterObject.where_meta[metaKey].length) delete filterObject.where_meta[metaKey]
  return convertFilterObjectToFilterArray(filterObject)
}

export const removeMetaKeyFromFilterArray = (
  filterArray: FilterArray,
  metaKey: string,
): FilterArray => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  if (!filterObject.where_meta) filterObject.where_meta = {}
  delete filterObject.where_meta[metaKey]
  return convertFilterObjectToFilterArray(filterObject)
}

export const resetMetaValuesInFilterArray = (filterArray: FilterArray): FilterArray => {
  const filterObject = convertFilterArrayToFilterObject(filterArray)
  filterObject.where_meta = {}
  return convertFilterObjectToFilterArray(filterObject)
}
