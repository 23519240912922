import React from 'react'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import TopicsForDataFilterSelectionList from './TopicsForDataFilterSelectionList'
import TextInput from '../../_shared/ModuleSettings/components/TextInput'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import {
  infoMetaKeysToShow,
  infoNumberOfTopicToShow,
  infoShowNotSelectedTopicsAsOther,
  infoTolerance,
} from '../PietabularNotifications'
import SelectExtensions from './SelectExtensions'
import MetaKeysSelectionList from '../../_shared/ModuleSettings/components/MetaKeysSelectionList'
import GroupingMetaKeySelect from './GroupingMetaKeySelect'
import CountedCategorySelect from './CountedCategorySelect'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import NumeratorAndDenominatorSelect from './NumeratorAndDenominatorSelect'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import KpiSelectionTreeData from './KpiSelectionTreeData'

const PietabularSettings = () => {
  return (
    <>
      <Subtitle text='Extensions' />
      <Linebreak />
      <SelectExtensions />

      <Subtitle text='Data' />
      <Linebreak />
      <KpiSelectionTreeData />
      <TextInput
        settingsName='numberOfTopTopicsToShow'
        placeholder='add limit how many top topics shown'
        data_testid='moduleDefaulNumberOfTopTopics'
        type='number'
        id='numberOfTopTopicsToShow'
        min={1}
        isRequired
        title={
          <TextWithInfo
            text='Number of topics to show'
            tooltipDirection='left'
            info={infoNumberOfTopicToShow}
          />
        }
      />
      <TopicsForDataFilterSelectionList />

      <Subtitle text='Tabular' />
      <Linebreak />
      <GroupingMetaKeySelect />
      <TextInput
        settingsName='tolerance'
        data_testid='tolerance'
        placeholder='add colouring tolerance %'
        id='tolerance'
        type='number'
        min={0}
        isRequired
        title={
          <TextWithInfo
            text='Colour indicator tolerance (%)'
            tooltipDirection='left'
            info={infoTolerance}
          />
        }
      />
      <CountedCategorySelect />
      <TextInput
        settingsName='defaultSortColumn'
        data_testid='defaultSortColumn'
        placeholder='give sort column'
        id='defaultSortColumn'
        type='number'
        title='Sort column (1/2/3/4)'
        isRequired
      />
      <div style={{ width: '100%' }}>
        <DropdownInput
          options={['share', 'count']}
          settingsName='tabularDataFormat'
          title='Tabular data format'
          data_testid='tabularDataFormat'
          id='tabularDataFormat'
          isRequired
        />
      </div>
      <CheckBoxInput
        settingsName='defaultSortIsAscending'
        text='Ascending'
        title='Sort order'
        data_testid='defaultSortIsAscending'
        id='settings-defaultSortIsAscending'
        width='third'
      />
      <CheckBoxInput
        settingsName='showTotalCount'
        text='Total count'
        title='Show totals'
        data_testid='showTotalCount'
        id='showTotalCount'
        width='third'
      />
      <CheckBoxInput
        settingsName='showTooltip'
        text='Tooltip'
        title='Show tooltip'
        data_testid='showTooltip'
        id='showTooltip'
        width='third'
      />
      <MetaKeysSelectionList
        propertyName='metaKeys'
        isRequired
        text='Meta keys to show in dropdown menu'
        info={infoMetaKeysToShow}
      />

      <Subtitle text='Pie' />
      <Linebreak />
      <TextInput
        settingsName='pieTitle'
        data_testid='pieTitle'
        placeholder='add pie title'
        id='pieTitle'
        title='Pie chart title'
      />
      <CheckBoxInput
        title={
          <TextWithInfo
            text='Show not selected topics'
            info={infoShowNotSelectedTopicsAsOther}
            tooltipDirection='left'
          />
        }
        settingsName='showNotSelectedTopicsAsOther'
        data_testid='showNotSelectedTopicsAsOther'
        id='showNotSelectedTopicsAsOther'
        text='Enable'
      />
      <TextInput
        settingsName='pieOtherReplaceText'
        data_testid='pieOtherReplaceText'
        placeholder='new topic text'
        id='pieOtherReplaceText'
        title='Replace "Other" name'
      />
      <TextInput
        settingsName='pieUncategorizedReplaceText'
        data_testid='pieUncategorizedReplaceText'
        placeholder='new topic text'
        id='pieUncategorizedReplaceText'
        title='Replace "Uncategorized" name'
      />

      <Subtitle text='Trends' />
      <Linebreak />
      <TextInput
        settingsName='trendsTitle'
        data_testid='trendsTitle'
        placeholder='add trends title'
        id='trendsTitle'
        title='Trends chart title'
      />
      <TextInput
        settingsName='trendsSubTitle'
        data_testid='trendsSubTitle'
        placeholder='add trends subtitle'
        id='trendsSubTitle'
        title='Trends chart subtitle'
      />
      <NumeratorAndDenominatorSelect />
      <div style={{ width: '100%' }}>
        <DropdownInput
          options={['week', 'month', 'quarter', 'year']}
          settingsName='defaultDateGroup'
          title='Date group:'
          data_testid='defaultDateGroup'
          id='defaultDateGroup'
          isRequired
        />
      </div>
      <CheckBoxInput
        settingsName='showBreakpoints'
        text='Breakpoints'
        title='Show breakpoints'
        data_testid='showBreakpoints'
        id='showBreakpoints'
        width='third'
      />
      <CheckBoxInput
        settingsName='showRollingAverage'
        text='Rolling average'
        title='Show rolling average'
        data_testid='showRollingAverage'
        id='showRollingAverage'
        width='third'
      />
      <CheckBoxInput
        settingsName='showRegressionLines'
        text='Trend'
        title='Show regression trend'
        data_testid='showRegressionLines'
        id='showRegressionLines'
        width='third'
      />
      <CheckBoxInput
        settingsName='showAreaShareCharts'
        text='Areas'
        title='Show area shares'
        data_testid='showAreaShareCharts'
        id='showAreaShareCharts'
        width='third'
      />
    </>
  )
}

export default PietabularSettings
