import React from 'react'

const BrandLogo = () => {
  return (
    <a href='#/' className='brand-logo' style={{ height: '100%' }}>
      <img
        className='brand-img'
        alt='WheelQ Logo'
        src='https://s3-eu-west-1.amazonaws.com/wheelq/ui/logo64.png'
      />
    </a>
  )
}

export default BrandLogo
