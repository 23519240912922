import { SeriesOptionsType } from 'highcharts8'
import { Counts, GroupedDataWithCounts, RegressionTableRow } from '../pietabularTypes'
import { allChartColors } from '../../../../../../styles/variableExport'
import { pSBC } from '../../../../../react-services/colorService'
import { cloneDeep, isArray } from 'lodash'
import { tCategory, tCommon } from '../../../../../../languages/i18n'

// To force redraw
let idIncrement = 0

const colors = Object.entries(allChartColors)
  .filter((c) => c[0] !== 'GREY')
  .map((c) => c[1])

export type ChartBreakpoint = {
  x: number
  title: string
  description: string
}
export enum ActionTypes {
  SET_CHART_DATA = 'SET_CHART_DATA',
  SET_BREAKPOINT = 'SET_BREAKPOINT',
  SET_TITLE = 'SET_TITLE',
  SET_LANGUAGE = 'SET_LANGUAGE',
}

interface SetLanguageAction {
  type: ActionTypes.SET_LANGUAGE
}

interface SetDataAction {
  type: ActionTypes.SET_CHART_DATA
  data: {
    counts: GroupedDataWithCounts | null
    numerator?: string
    denominator?: string
    rollingAvgDelta?: number
    areas: string[] | null
    settings?: SetChartDataSettings | null
    breakpoints?: ChartBreakpoint[] | null
    clickDateColumn?: (category: string) => void
  }
}

interface SetTitleAction {
  type: ActionTypes.SET_TITLE
  data: {
    title: string
    subtitle: string
  }
}

interface SetBreakpoint {
  type: ActionTypes.SET_BREAKPOINT
  data: {
    counts: GroupedDataWithCounts | null
    numerator: string
    denominator: string
    areas: string[]
    settings: SetChartDataSettings | null
    breakpoints: ChartBreakpoint[]
    isReportMode: boolean
  }
}

type SetChartDataSettings = {
  showRegressionLine?: boolean
  showRollingAvgLine?: boolean
  showAreaShareCharts?: boolean
  showBreakingPoint?: boolean
}

type ReducerAction = SetDataAction | SetBreakpoint | SetTitleAction | SetLanguageAction

type SlopesAndValuesObject = {
  values: number[]
  slope: number
}

export const trendsReducer = (
  state: Highcharts.Options,
  action: ReducerAction,
): Highcharts.Options => {
  const newState = { ...state }
  switch (action.type) {
    case ActionTypes.SET_LANGUAGE: {
      const newState = cloneDeep(state)
      const trendSeries = newState.series ? newState.series[0] : null
      if (!trendSeries) return newState
      trendSeries.id = `trend-${idIncrement++}`
      if (newState.series) newState.series[0] = trendSeries
      const newLeftYaxisText = tCommon('label.share') + ' %'
      const previousYaxis = newState.yAxis
      if (isArray(previousYaxis)) {
        const newLeftYaxisTitle = { ...(previousYaxis[0]?.title || {}), text: newLeftYaxisText }
        previousYaxis[0].title = newLeftYaxisTitle

        newState.yAxis = previousYaxis
      }
      return newState
    }

    case ActionTypes.SET_CHART_DATA: {
      if (action.data && action.data.counts) {
        const {
          counts,
          numerator,
          denominator,
          rollingAvgDelta,
          settings,
          areas,
          breakpoints,
          clickDateColumn,
        } = action.data
        const series = [] as SeriesOptionsType[]

        if (settings && settings.showAreaShareCharts && areas && areas.length > 0) {
          const areaShareCountsByDate = createAreaShareValues(counts, areas)
          const areaShareSeries = createAreaShareSeries(areaShareCountsByDate, areas)
          series.push(...areaShareSeries)
        }

        if (
          settings &&
          settings.showRegressionLine &&
          numerator &&
          denominator &&
          areas &&
          areas.concat('n').includes(numerator) &&
          areas.concat('n').includes(denominator) &&
          Object.keys(counts).length > 3
        ) {
          const regressionTables = createRegressionTables(
            counts,
            numerator,
            denominator,
            breakpoints,
          )

          const regressionValuesAndSlopes = createRegressionLineValues(regressionTables)
          const regressionLineSeries = createRegressionLineSeries(
            regressionValuesAndSlopes,
            breakpoints,
          )

          const regressionLineAxisMax = getMaxValue(
            regressionValuesAndSlopes.flatMap((valuesAndSlopes) =>
              valuesAndSlopes.values.filter((v) => !isNaN(v)),
            ),
          )
          const regressionLineAxisinterval = parseFloat((regressionLineAxisMax / 4).toFixed(1))
          const regressionLineAxisMaxFittedForInterval = regressionLineAxisinterval * 4
          const newregressionLineAxisLabelLimits = {
            max: regressionLineAxisMaxFittedForInterval,
            tickInterval: regressionLineAxisinterval,
          }
          series.push(...regressionLineSeries)

          if (newState.yAxis && !Array.isArray(newState.yAxis)) {
            newState.yAxis = { ...newState.yAxis, ...newregressionLineAxisLabelLimits }
            newState.yAxis = {
              ...newState.yAxis,
              title: {
                text: `${numerator === 'n' ? tCommon('label.total') : tCategory(numerator)}/${
                  denominator === 'n' ? tCommon('label.total') : tCategory(denominator)
                } ratio`,
              },
              visible: true,
            }
          }
          if (newState.yAxis && Array.isArray(newState.yAxis)) {
            newState.yAxis[1] = { ...newState.yAxis[1], ...newregressionLineAxisLabelLimits }
            newState.yAxis[1] = {
              ...newState.yAxis[1],
              title: {
                text: `${numerator === 'n' ? tCommon('label.total') : tCategory(numerator)}/${
                  denominator === 'n' ? tCommon('label.total') : tCategory(denominator)
                } ratio`,
              },
              visible: true,
            }
          }
        }

        if (
          settings &&
          !settings.showRegressionLine &&
          newState.yAxis &&
          Array.isArray(newState.yAxis)
        ) {
          newState.yAxis[1] = { ...newState.yAxis[1], visible: false }
        }

        if (
          settings &&
          settings.showRollingAvgLine &&
          rollingAvgDelta !== undefined &&
          rollingAvgDelta !== null &&
          !isNaN(rollingAvgDelta) &&
          numerator &&
          areas &&
          areas.concat('n').includes(numerator) &&
          denominator &&
          areas &&
          areas.concat('n').includes(denominator) &&
          Object.keys(counts).length > 3
        ) {
          const rollingAvgValues = createRollingAvgValues(
            counts,
            rollingAvgDelta,
            numerator,
            denominator,
          )
          const rollingAvgLineSeries = createRollingAvgLineSeries(rollingAvgValues, rollingAvgDelta)
          series.push(rollingAvgLineSeries)
        }

        const xAxisPlotLines = createXAxisPlotLines(Object.keys(counts).sort(), clickDateColumn)
        const xAxisCategories = Object.keys(counts)
        if (newState.xAxis && !Array.isArray(newState.xAxis)) {
          newState.xAxis.categories = xAxisCategories
          newState.xAxis.plotLines = xAxisPlotLines
        }
        if (newState.xAxis && Array.isArray(newState.xAxis)) {
          newState.xAxis[0].categories = xAxisCategories
          newState.xAxis[0].plotLines = xAxisPlotLines
        }
        newState.series = series
      } else {
        delete newState.series
      }
      return newState
    }

    case ActionTypes.SET_BREAKPOINT: {
      if (action.data && action.data.counts && action.data.breakpoints) {
        const xAxisPlotlineBreakpoints = createXAxisPlotlinesForBreakpoints(
          action.data.breakpoints,
          action.data.isReportMode,
          action.data.counts,
        )
        if (newState.xAxis && !Array.isArray(newState.xAxis))
          newState.xAxis.plotBands = xAxisPlotlineBreakpoints
        if (newState.xAxis && Array.isArray(newState.xAxis))
          newState.xAxis[0].plotBands = xAxisPlotlineBreakpoints
      } else {
        if (newState.xAxis && !Array.isArray(newState.xAxis)) delete newState.xAxis.plotBands
        if (newState.xAxis && Array.isArray(newState.xAxis)) delete newState.xAxis[0].plotBands
      }
      return newState
    }

    case ActionTypes.SET_TITLE: {
      const newTitle = action.data.title
      const newSubTitle = action.data.subtitle

      if (newState.title) newState.title.text = newTitle
      if (newState.subtitle) newState.subtitle.text = newSubTitle
      if ((newTitle || newSubTitle) && newState.chart) newState.chart.marginTop = 75
      if (newTitle && newSubTitle && newState.chart) newState.chart.marginTop = 80
      return newState
    }

    default:
      return state
  }
}

const createRegressionLineSeries = (
  valuesAndSlopes: SlopesAndValuesObject[],
  breakpoints?: ChartBreakpoint[] | null,
): SeriesOptionsType[] => {
  if (breakpoints && breakpoints.length > 0) {
    return valuesAndSlopes.map((valuesAndSlope, i) => {
      return {
        name: tCommon('label.trendLineRatio', { n: (i + 1).toString() }),
        type: 'line',
        events: {
          click: () => ({}),
        },
        data: valuesAndSlope.values.map((value) => ({
          y: value,
          custom: { extraInformation: valuesAndSlope.slope },
        })),
        yAxis: 1,
        pointPlacement: 'on',
        dashStyle: 'ShortDash',
        className: 'regressionLine',
        animation: {
          defer: 200,
          duration: 1000,
        },
        animationLimit: 500,
        lineWidth: 2,
        pointStart: breakpoints[i - 1]?.x || 0,
        marker: {
          enabled: false,
        },
      }
    })
  } else {
    return [
      {
        name: tCommon('label.trendLineRatio', { n: '' }),
        type: 'line',
        data: valuesAndSlopes[0].values.map((value) => ({
          y: value,
          custom: { extraInformation: valuesAndSlopes[0].slope },
        })),
        yAxis: 1,
        events: {
          click: () => ({}),
        },
        dashStyle: 'ShortDash',
        pointPlacement: 'on',
        pointStart: 0,
        className: 'regressionLine',
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        animation: {
          defer: 200,
          duration: 1000,
        },
        animationLimit: 500,
      },
    ]
  }
}

export const createRegressionTables = (
  data: GroupedDataWithCounts,
  numerator: string,
  denominator: string,
  breakpoints?: ChartBreakpoint[] | null,
): RegressionTableRow[][] => {
  const regressionTables = [] as RegressionTableRow[][]
  const countsByDates = createCountsByDate(data)
  const averagedCountsByDates = createAveragedCountsByDate(
    countsByDates,
    numerator,
    denominator,
  ).slice(1, countsByDates.length - 1)

  if (breakpoints && breakpoints.length > 0) {
    const intervals = [] as { start: number; end: number }[]
    breakpoints
      .sort((a, b) => a.x - b.x)
      .forEach((br, i, array) => {
        if (i === 0) intervals.push({ start: 0, end: br.x - 1 >= 0 ? br.x - 1 : 0 })
        if (i + 1 === array.length)
          intervals.push({ start: br.x - 1, end: averagedCountsByDates.length - 1 })
        if (array[i + 1]) intervals.push({ start: br.x - 1, end: array[i + 1].x - 1 })
      })

    for (let index = 0; index < intervals.length; index++) {
      if (intervals[index].end - intervals[index].start < 1) {
        regressionTables.push([])
        continue
      }
      const regressionTable = [] as RegressionTableRow[]
      const startIndex = intervals[index].start
      const endIndex = intervals[index].end
      const slicedAveragedCountsByDates = averagedCountsByDates.slice(startIndex, endIndex + 1)

      slicedAveragedCountsByDates.forEach((groupedCounts, i) => {
        const numeratorValue = groupedCounts[numerator as keyof unknown] || 0
        const denominatorValue =
          groupedCounts[denominator as keyof unknown] || numeratorValue || null
        regressionTable[i] = createRegressionTableRow(i, numeratorValue, denominatorValue)
      })

      regressionTables.push(regressionTable.filter((row) => row))
    }
  } else {
    const regressionTable = [] as RegressionTableRow[]
    averagedCountsByDates.forEach((groupedCounts, i) => {
      const numeratorValue = groupedCounts[numerator as keyof unknown] || 0
      const denominatorValue = groupedCounts[denominator as keyof unknown] || numeratorValue || null
      regressionTable[i] = createRegressionTableRow(i, numeratorValue, denominatorValue)
    })

    regressionTables.push(regressionTable)
  }
  return regressionTables
}

const createRegressionTableRow = (
  i: number,
  numeratorValue: number,
  denominatorValue: number | null,
): RegressionTableRow => {
  const dateIndex = i
  if (!numeratorValue) numeratorValue = 0
  if (!denominatorValue) denominatorValue = 1
  return {
    x: dateIndex,
    y: numeratorValue / denominatorValue,
    x2: dateIndex * dateIndex,
    y2: (numeratorValue / denominatorValue) * (numeratorValue / denominatorValue),
    xy: (dateIndex * numeratorValue) / denominatorValue,
  }
}

export const createRegressionLineValues = (
  regressionTables: RegressionTableRow[][],
): { values: number[]; slope: number }[] => {
  return regressionTables.map((regressionTable, tableIndex) => {
    const tableLength = regressionTable.length
    const sums = regressionTable.reduce<{
      x: number
      y: number
      x2: number
      y2: number
      xy: number
    }>(
      (sumObject, row) => {
        sumObject['x'] = sumObject['x'] + row['x']
        sumObject['y'] = sumObject['y'] + row['y']
        sumObject['x2'] = sumObject['x2'] + row['x2']
        sumObject['y2'] = sumObject['y2'] + row['y2']
        sumObject['xy'] = sumObject['xy'] + row['xy']
        return sumObject
      },
      { x: 0, y: 0, x2: 0, y2: 0, xy: 0 },
    )

    const cuttingPoint =
      (sums['y'] * sums['x2'] - sums['x'] * sums['xy']) /
      (tableLength * sums['x2'] - sums['x'] * sums['x'])
    const slope =
      (tableLength * sums['xy'] - sums['x'] * sums['y']) /
      (tableLength * sums['x2'] - sums['x'] * sums['x'])
    const lineValues = [] as number[]
    let valueArrayLength = 0

    if (regressionTables.length === 1) valueArrayLength = tableLength + 2
    if (regressionTables.length > 1 && tableIndex === 0) valueArrayLength = tableLength + 1
    if (regressionTables.length > 1 && tableIndex < regressionTables.length - 1 && tableIndex !== 0)
      valueArrayLength = tableLength
    if (regressionTables.length > 1 && tableIndex === regressionTables.length - 1)
      valueArrayLength = tableLength + 1
    //  = regressionTables.length === 1 ? tableLength + 2 : tableLength + 1
    for (let i = 0; i < valueArrayLength; i++) {
      lineValues.push(cuttingPoint + slope * i)
    }
    return { values: lineValues, slope: slope }
  })
}

const createRollingAvgLineSeries = (values: number[], delta: number): SeriesOptionsType => {
  return {
    name: tCommon('label.rollingPointAvg', { delta: delta.toString() }),
    type: 'spline',
    pointPlacement: 'on',
    data: values,
    yAxis: 0,
    dashStyle: 'ShortDot',
    className: 'rollingAvgSpline',
    animation: {
      defer: 200,
      duration: 1000,
    },
    animationLimit: 500,
    marker: {
      enabled: false,
    },
    events: {
      click: () => ({}),
    },
    lineWidth: 3,
    pointStart: 2,
  }
}

const createRollingAvgValues = (
  data: GroupedDataWithCounts,
  rollingAvgDelta: number,
  numerator: string,
  denominator: string,
): number[] => {
  const countsByDateObject = createCountsByDate(data)
  const rollingAvgList = countsByDateObject
    .map((countByDateObject) => Object.values(countByDateObject)[0])
    .map((_counts, countIndex, array) => {
      if (countIndex >= rollingAvgDelta - 1) {
        let numeratorValueSum = 0
        let denominatorValueSum = 0
        for (let index = countIndex - rollingAvgDelta + 1; index < countIndex + 1; index++) {
          numeratorValueSum = numeratorValueSum + (array[index][numerator] || 0)
          denominatorValueSum = denominatorValueSum + (array[index][denominator] || 0)
        }

        if (!numeratorValueSum) return 0
        if (!denominatorValueSum) return 100
        return (numeratorValueSum / (numeratorValueSum + denominatorValueSum)) * 100
      } else {
        return 0
      }
    })
    .splice(rollingAvgDelta - 1)
  return rollingAvgList
}

const getMaxValue = (values: number[]): number => {
  return Math.ceil(Math.max(...values))
}

const createAreaShareSeries = (
  values: { [area: string]: number[] },
  areas: string[],
): SeriesOptionsType[] => {
  const series = {} as { [area: string]: SeriesOptionsType }
  areas.forEach((area, i) => {
    const areaColor = colors[i]
    series[area] = {
      name: tCategory(area) + ' (%)',
      data: values[area],
      type: 'area',
      pointPlacement: 'on',
      color:
        area.toLocaleLowerCase() === 'positive'
          ? allChartColors.BLUE_GREEN
          : area.toLocaleLowerCase() === 'negative'
          ? allChartColors.RED
          : area.toLocaleLowerCase() === 'neutral'
          ? allChartColors.BLUE
          : area.toLocaleLowerCase() === 'uncategorized'
          ? pSBC(0.5, allChartColors.GREY) || allChartColors.GREY
          : areaColor,
      lineColor:
        area.toLocaleLowerCase() === 'positive'
          ? allChartColors.BLUE_GREEN
          : area.toLocaleLowerCase() === 'negative'
          ? allChartColors.RED
          : area.toLocaleLowerCase() === 'neutral'
          ? allChartColors.BLUE
          : area.toLocaleLowerCase() === 'uncategorized'
          ? pSBC(0.5, allChartColors.GREY) || allChartColors.GREY
          : areaColor,
      fillOpacity: 0.98,
      opacity: 1,
      lineWidth: 2,
      yAxis: 0,
      events: {
        click: () => ({}),
      },
      marker: {
        symbol: 'circle',
        radius: 3,
      },
      animation: {
        defer: 0,
        duration: 1000,
      },
      animationLimit: 1000,
    }
  })
  return Object.values(series)
}

const createAreaShareValues = (
  values: GroupedDataWithCounts,
  areas: string[],
): { [area: string]: number[] } => {
  const countsByDate = createCountsByDate(values)
  const areaSharesByDate = {} as { [area: string]: number[] }
  areas.forEach((area) => {
    if (area === 'n') return
    const areaShareValues = countsByDate
      .map((countsByDateObject) => Object.values(countsByDateObject)[0])
      .map((counts) => {
        const newCounts = { ...counts }
        delete newCounts['n']
        const totalCounts = Object.values(newCounts).reduce((acc, curr) => acc + curr, 0)
        const currentCount =
          Math.floor(((counts[area as keyof unknown] || 0) / (totalCounts || 1)) * 100 * 10) / 10
        return currentCount
      })
    areaSharesByDate[area] = areaShareValues
  })
  return areaSharesByDate
}

const createCountsByDate = (data: GroupedDataWithCounts): { [date: string]: Counts }[] => {
  return Object.entries(data).map(([date, counts]) => ({ [date]: counts }))
}

const createAveragedCountsByDate = (
  countsByDates: { [date: string]: Counts }[],
  numerator: string,
  denominator: string,
): { [numeratorOrDenominator: string]: number }[] => {
  return countsByDates
    .map((countByDates) => Object.values(countByDates)[0])
    .map((counts, i, array) => {
      const averagedCounts = { [numerator]: counts[numerator], [denominator]: counts[denominator] }
      if (i !== 0 && i !== array.length - 1 && array[i + 1] && array[i - 1]) {
        averagedCounts[numerator] =
          (averagedCounts[numerator] + array[i + 1][numerator] + array[i - 1][numerator]) / 3
        averagedCounts[denominator] =
          (averagedCounts[denominator] + array[i + 1][denominator] + array[i - 1][denominator]) / 3
      }
      return averagedCounts
    })
}

const createXAxisPlotlinesForBreakpoints = (
  breakpoints: ChartBreakpoint[],
  isReportMode: boolean,
  data: GroupedDataWithCounts,
): Array<Highcharts.XAxisPlotBandsOptions> => {
  const categoriesLength = Object.keys(data).length
  const delta = 0.003 + categoriesLength * 0.004
  return breakpoints.map((breakpoint) => ({
    color: 'rgba(88, 88, 88, .7)',
    className: 'open-answers-trends-breakpoint',
    from: breakpoint.x - delta,
    to: breakpoint.x + delta,
    zIndex: 11,
    dashStyle: 'Solid',

    label: {
      rotation: 0,
      useHTML: true,
      y: isReportMode ? -18 : -16,
      x: -4,
      text:
        `<span class="trends-highcharts-plotbands-label" data-content="${breakpoint.description}">` +
        `${breakpoint.title}` +
        '</span>',
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#585858',
        backgroundColor: 'rgba(88, 88, 88, .01)',
      },
    },
  }))
}

const createXAxisPlotLines = (
  categories: string[],
  clickDateColumn?: (category: string) => void,
): Array<Highcharts.XAxisPlotLinesOptions> => {
  const categoriesLength = categories.length
  const delta = 90 - categoriesLength > 10 ? 90 - categoriesLength : 10

  return categories.map<Highcharts.XAxisPlotLinesOptions>((category, i) => ({
    color: 'rgba(0, 0, 0, 0)',
    className: 'open-answers-trends-plotline',
    value: i,
    id: category,
    zIndex: 12,
    dashStyle: 'Solid',
    width: delta,
    events: {
      mouseover: function () {
        this.svgElem.css({
          stroke: 'rgba(0, 0, 0, .09)',
          zIndex: 10,
        })
      },
      mouseout: function () {
        this.svgElem.css({
          stroke: 'rgba(0, 0, 0, 0)',
          zIndex: 12,
        })
      },
      click: function () {
        if (clickDateColumn) {
          clickDateColumn(category)
        }
      },
    },
  }))
}
