import React, { useEffect, useRef, useState } from 'react'
import DoubleModalMain from '../../../../_shared/Modals/DoubleModalMain'
import { Settings } from './settingsTypes'
import { useSettingsTools } from './SettingsContext'
import TextInput from './components/TextInput'
import RadioButtonsInput from './components/RadioButtonsInput'
import ModuleMoveButtons from './components/ModuleMoveButtons'
import { useEvent } from 'react-use'
import Linebreak from './components/Linebreak'
import Subtitle from './components/Subtitle'
import { moduleTypes } from '../../../../../react-constants/moduleTypes'
import { cloneDeep } from 'lodash'

import PietabularSettings from '../../Pietabular/settings/PietabularSettings'
import OpenSettings from '../../Open/settings/OpenSettings'
import NumberTrendSettings from '../../NumberTrend/settings/NumberTrendSettings'
import LineChartSettings from '../../Line/settings/LineChartSettings'
import TabularSettings from '../../Tabular/settings/TabularSettings'
import TopBottomSettings from '../../TopBottom/Settings/TopBottomSettings'
import RadarSettings from '../../Radar/RadarSettings/RadarSettings'
import WheelSettings from '../../Wheel/settings/WheelSettings'
import FrequencySettings from '../../Frequency/settings/FrequencySettings'
import SummarySettings from '../../Summary/settings/SummarySettings'
import TextBoxSettings from '../../TextBox/settings/TextBoxSettings'
import PictureSettings from '../../Picture/PictureSettings'
import { Module } from '../../Group/groupModuleTypes'
import CheckBoxInput from './components/CheckBoxInput'
import SliderInput from './components/SliderInput'
import BubbleSettings from '../../Bubble/settings/BubbleSettings'

import css from './SettingsModal.module.scss'
import GroupSettings from '../../Group/settings/GroupSettings'

type SettingsModalProps = {
  onClose: () => void
  options?: Settings
  module: Module
  index: string | number | null
  handleDeleteClick: () => void
  moveModuleUp?: (m: Module) => void
  moveModuleDown?: (m: Module) => void
  saveModule?: (module: Module) => void
  settingsWrapperRef: React.MutableRefObject<HTMLDivElement | undefined>
  scrollIntoPosition?: ScrollLogicalPosition
}
const SettingsModal = ({
  onClose,
  options,
  module,
  index,
  handleDeleteClick,
  moveModuleUp,
  moveModuleDown,
  saveModule,
  settingsWrapperRef,
  scrollIntoPosition = 'center',
}: SettingsModalProps) => {
  const [{ moduleType, inGroup }, setSettingsTools] = useSettingsTools()
  const [modalPosition, setModalPosition] = useState<string>(css.center)

  useEvent('keyup', (e: KeyboardEvent) => e.key === 'Escape' && onClose())
  useEvent('keyup', (e: KeyboardEvent) => e.key === 'ArrowLeft' && handleMoveModal('left'))
  useEvent('keyup', (e: KeyboardEvent) => e.key === 'ArrowRight' && handleMoveModal('right'))

  useEffect(() => {
    setSettingsTools((prev) => ({
      ...prev,
      options,
      module,
      index,
      moveModuleUp: moveModuleUp || (() => {}),
      moveModuleDown: moveModuleDown || (() => {}),
      saveModule: saveModule || (() => {}),
    }))
  }, [options, module, index, handleDeleteClick, moveModuleUp, moveModuleDown, saveModule])

  const scrollTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (scrollTimeout.current) clearTimeout(scrollTimeout.current)
    scrollTimeout.current = setTimeout(() => {
      if (settingsWrapperRef.current && settingsWrapperRef.current.scrollIntoView) {
        settingsWrapperRef.current.scrollIntoView({
          behavior: 'smooth',
          block: inGroup ? scrollIntoPosition : 'start',
        })
      }
    }, 500)

    return () => scrollTimeout.current && clearTimeout(scrollTimeout.current)
  }, [index])

  const handleMoveModal = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      if (modalPosition === css.right) setModalPosition(css.center)
      if (modalPosition === css.center) setModalPosition(css.left)
    }
    if (direction === 'right') {
      if (modalPosition === css.left) setModalPosition(css.center)
      if (modalPosition === css.center) setModalPosition(css.right)
    }
  }

  const handleDisableManualHeight = (newValue: boolean) => {
    if (!module) return
    const newModule = cloneDeep(module)
    if (!newModule.options) newModule.options = {}
    if (newModule.options) newModule.options.isManualHeightDisabled = newValue
    delete newModule.options.moduleheight
    if (saveModule) saveModule(newModule)
  }

  return (
    <div className={`${css.cntr} ${modalPosition}`}>
      <DoubleModalMain double={false} closeOnEsc={false} onClose={onClose} duration={0}>
        <div className={css.title}>
          <h5>MODULE SETTINGS&nbsp;&nbsp;&#8212;&nbsp;&nbsp;{moduleType?.toUpperCase()}</h5>
          <div className={css.position_btns}>
            <span
              className='material-symbols-outlined'
              onClick={handleDeleteClick}
              title='Click to delete the module'
            >
              delete
            </span>
            <span
              className='material-symbols-outlined'
              onClick={() => handleMoveModal('left')}
              title='Move modal to left, you can also press ArrowLeft'
            >
              format_image_left
            </span>
            <span
              className='material-symbols-outlined'
              onClick={() => handleMoveModal('right')}
              title='Move modal to right, you can also press ArrowRight'
            >
              format_image_right
            </span>
          </div>
        </div>

        <div
          className={css.settings}
          onKeyDown={(e) => {
            e.stopPropagation()
            e.key === 'Escape' && onClose()
          }}
          onKeyUp={(e) => {
            e.stopPropagation()
            e.key === 'Escape' && onClose()
          }}
        >
          <Subtitle text='General' />
          <Linebreak />
          <TextInput
            settingsName='title'
            title='Module title'
            data_testid='moduleTitleChanger'
            id='moduleTitleChanger'
            type='text'
            placeholder='give title text'
            isModuleProperty
          />
          <ModuleMoveButtons classNames={css.half} />
          <RadioButtonsInput
            buttons={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            settingsName='modulewidth'
            title='Module width'
            reflowCharts
            width='full'
            data_testid='moduleWidth'
            isRequired
          />
          <SliderInput
            settingsName='moduleheight'
            min={200}
            max={1000}
            title='Module height'
            leftLabel='Pixels'
            width='twoThirds'
            defaultValue={450}
            isDisabled={!!module?.options?.isManualHeightDisabled}
          />
          <CheckBoxInput
            settingsName='isManualHeightDisabled'
            text='Disable'
            title='Disable manual height'
            data_testid='isManualHeightDisabled'
            id='isManualHeightDisabled'
            width='third'
            customSave={handleDisableManualHeight}
          />
          <CheckBoxInput
            settingsName='isTittleCollapsed'
            text='Enable'
            title='Collapse title area'
            data_testid='isTittleCollapsed'
            id='isTittleCollapsed'
            width='third'
          />
          {moduleType === moduleTypes.PIETABULAR && <PietabularSettings />}
          {moduleType === moduleTypes.NUMBERTREND && <NumberTrendSettings />}
          {moduleType === moduleTypes.OPEN && <OpenSettings />}
          {moduleType === moduleTypes.LINE && <LineChartSettings />}
          {moduleType === moduleTypes.TABULAR && <TabularSettings />}
          {moduleType === moduleTypes.LISTNUMERIC && <TopBottomSettings />}
          {moduleType === moduleTypes.RADAR && <RadarSettings />}
          {moduleType === moduleTypes.WHEEL && <WheelSettings />}
          {moduleType === moduleTypes.FREQUENCY && <FrequencySettings />}
          {moduleType === moduleTypes.SUMMARY && <SummarySettings />}
          {moduleType === moduleTypes.TEXTBOX && <TextBoxSettings />}
          {moduleType === moduleTypes.PICTURE && <PictureSettings />}
          {moduleType === moduleTypes.BUBBLE && <BubbleSettings />}
          {moduleType === moduleTypes.GROUP && <GroupSettings />}
        </div>
      </DoubleModalMain>
    </div>
  )
}

export default SettingsModal
