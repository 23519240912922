import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const ExpandMoreIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'expand_more'}
    text=""
    customClasses={[css.btn,...(props.customClasses ? props.customClasses : [])]}
    iconPosition={'center'}
    title="Show more information"
    data_testid="expand-more-icon-button"
    style={{ marginLeft: '3px' }}
  />
)

export default ExpandMoreIcon;