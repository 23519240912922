import React, { useEffect, useState } from 'react'
import SingleGraphCntr from './SingleGraphCntr'
import useDbSettingsConfig from '../../../../stores/useDbSettingsConfig'
import { Dashboard, DashboardSettings } from '../../../../stores/useDbTemplateConfig'
import { getAutoTimeScaleFromDbConfig } from '../../../../react-services/moduleFiltersService'
import { Module } from '../Group/groupModuleTypes'
import { decodeURIComponentSafe } from '../../../../react-services/helperFunctionService'
import useDashboardSections from '../../../../stores/useDashboardSections'
import { WhereMeta } from '../../../../../types'

declare global {
  interface Window {
    sectionsConfig: DashboardSettings[]
  }
}

export type SingleGraphDashboardParserProps = {
  token: string | null
  dateString: string | null
  dashboardIdx: number | null
  moduleIdx: number | null
  sectionIdx: number | null
  pastDays: number | null
  futureDays: number | null
  scrollPage: boolean | null
  forcedFilters: unknown | null
  moduleFromUrlString: unknown | null
  moduleIndicesFromUrl: unknown | null
  filterUpdateFromUrl: unknown | null
  tenantReportingFilters?: WhereMeta | null
}

const SingleGraphDashboardParser = (props: SingleGraphDashboardParserProps) => {
  const { moduleFromUrlString, moduleIndicesFromUrl, sectionIdx } = props
  const [autoTimeScale, setAutoTimeScale] = useState(true)
  const [currentTemplate, setCurrentTemplate] = useState<DashboardSettings | null>(null)
  const moduleFromUrl = getModuleFromUrl() || null
  const moduleIndices = getModuleIndicesFromUrl()
  const { sections } = useDashboardSections()
  const { config: dbSettings } = useDbSettingsConfig()

  if (!window.sectionsConfig && sections) window.sectionsConfig = sections

  useEffect(() => {
    if (!dbSettings) return
    const newAutoTimeScale = !!getAutoTimeScaleFromDbConfig(dbSettings)
    setAutoTimeScale(newAutoTimeScale)
  }, [dbSettings])

  useEffect(() => {
    if (moduleFromUrlString) return
    if (!sections) return
    const newCurrentTemplate = sections[sectionIdx || 0]
    if (newCurrentTemplate) setCurrentTemplate(newCurrentTemplate)
    else alert('No section available at index ' + sectionIdx)
  }, [sections])

  useEffect(() => {
    if (!moduleFromUrl) return
    const newDashboard: Dashboard = {
      name: '',
      modules: [moduleFromUrl],
    }
    const newDashboardSettings: DashboardSettings = {
      dashboards: [newDashboard],
    }
    window.sectionsConfig = [newDashboardSettings]
  }, [moduleFromUrl])

  function getModuleFromUrl(): Module | undefined {
    if (!moduleFromUrlString) return
    try {
      const jsonModuleFromUrl = JSON.parse(decodeURIComponentSafe(moduleFromUrlString))
      if (jsonModuleFromUrl) {
        console.log('Use module data from URL param:\n')
        console.log(JSON.stringify(jsonModuleFromUrl, null, 2))
        return jsonModuleFromUrl
      }
    } catch (e) {
      alert('Error parsing URL param module')
      console.log(e)
    }
  }

  function getModuleIndicesFromUrl() {
    if (!moduleIndicesFromUrl) return
    try {
      const jsonModuleIndicesFromUrl = JSON.parse(decodeURIComponentSafe(moduleIndicesFromUrl))
      if (jsonModuleIndicesFromUrl) {
        console.log('Include submodules from indices:', JSON.stringify(jsonModuleIndicesFromUrl))
        return jsonModuleIndicesFromUrl
      }
    } catch (e) {
      alert('Error parsing URL param module_indices')
      console.log(e)
    }
  }

  const handleSettingsAutoTimeScale = (isAutoTimeScale: boolean) => {
    setAutoTimeScale(isAutoTimeScale)
  }

  if (!currentTemplate && !props.moduleFromUrlString) return <div>Parsing template...</div>
  return (
    <SingleGraphCntr
      {...props}
      currentTemplate={currentTemplate}
      moduleFromUrl={moduleFromUrl}
      moduleIndices={moduleIndices}
      handleSettingsAutoTimeScale={handleSettingsAutoTimeScale}
      autoTimeScale={autoTimeScale}
    />
  )
}

export default SingleGraphDashboardParser
