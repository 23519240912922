// For testing stability
export const errorLoadingModule = 'There was an error when loading the pietabular module'
export const errorLoadingPie = 'There was an error when loading the pie extension'
export const errorLoadingTabular = 'There was an error when loading the tabular extension'
export const errorLoadingTrends = 'There was an error when loading the trends extension'
export const errorLoadingInspector = 'There was an error when loading the inspector'
export const errorLoadingBreakpoints = 'There was an error when loading the breakpoints'
export const errorFetchOpenAnswers = 'openAnswersService: Error fetching open answers!'
export const errorFetchOpenAnswersCounts = 'openAnswersService: Error fetching open answer counts'
export const errorFetchKpis = 'kpiService: Error fetching kpis needed for open answers!'
export const errorNoMetaKeySettings =
  'No meta keys to group data, select more from settings. If dataset does not include selected meta keys, no data can be rendered.'
export const errorGenericModuleErroTitle = 'PIE TABULAR SETTINGS ERROR(s):'
export const errorNoDefaultCountKey =
  'No default counted key value given to use for tabular counts, e.g. "sentiment"'
export const errorNoDefaultMetaKey = 'No default meta key value given to use for tabular grouping'
export const errorNoMetaKeys = 'No meta keys selected for tabular, atleast 1 required'
export const errorNoTopics = 'No topics selected for pie chart, atleast 1 required'
export const errorNoTolerance = 'No tolerance value given, give value "0" if no tolerance is wanted'
export const errorInvalidTolerance = 'Invalid tolerance value, must be a number'
export const errorInvalidNumberOfTopicsToShow =
  'Invalid "Number of topics to show", must be a number'
export const errorInvalidShowTotalCount =
  'Invalid "Enable total count footer" value, must be true or false, choose from settings'
export const errorInvalidSortIndex =
  'Invalid sorting column number, use a number, e.g. "1" or "2" or "3"'
export const errorInvalidSortingDirection =
  'Make sure "Ascending order" is either "true" or "false", required when index is given'
export const errorInvalidShowTooltip = 'Invalid "Show cell tooltip" value, must be true or false'
export const errorLoadingBreakpointsFromConfig = 'Failed to load breakpoints from commondbsettings'
export const errorSavingNewBreakpoint = 'There was an error when saving the new breakpoint'
export const errorFetchingCommonDbSettings = 'There was an error when loading the commondbsettings configuration'
export const errorDeletingBreakpoint = 'There was an error when deleting the breakpoint'
export const errorConvertingBreakpointToDateGroups = 'There was an error when matching and converting breakpoints to date groups'
export const errorMatchingMetaValues = 'There was an error matching meta values when opening inspector'
export const errorCreatingInspectorDateRange = 'There was an error when creating date range for inspector'
export const infoDefaultCountCategory =
  'Default count category is used as the value\nwhat is counted for tabular and trends. This\nmeans in the case of "sentiment", module\ncounts positives, negatives and neutrals.'
export const infoTrendsNumerator =
  'Numerator is used in the trends calculations.\nFor example regression and rolling average\nlines need this value. This is selected from\nthe same category as default count category.'
export const infoTrendsDenominator =
  'Denominator is used in the trends calculations.\nFor example regression and rolling average\nlines need this value. This is selected from\nthe same category as default count category.'
export const infoTrendsDefaultMetaGroup =
  'Default meta group is used as the grouping\nvalue for tabular when module loads. For\nexample if "country" is selected, the tabular\nrows will be "finland", "sweden", "norway".'
export const infoTopicSelection =
  'Topics are picked to filter out answers that\ndo not include the chosen topics. This helps\nto visualize only certain sections of the data\nwhen non related answers are filtered out.'
export const infoMetaKeysToShow =
  'Meta keys selected here will be shown in the\ntabular dropdown where you can select grouping\nmeta. You should ideally select atleast 5 but\nmax 10 to keep selecting easy and still have\noptions as the data may not include all the\nmetas selected here.'
export const infoNumberOfTopicToShow =
  'You can limit the number of slices/topics\nyou want to be included in the data. It will\nselect the top topics with most answers.\nThis data is filtered in all extensions. Using\nthis option helps to focus on the most\nimportant topics.'
export const infoTolerance =
  "Tolerance is the value used to determine\nwhat calculated numbers in tabular cells are\noutliers. These outliers are then coloured\nif the tolerance given here, is crossed. The\ncomparison where tolerance is used, always\nuses the current tabular average (plus\ntolerance) agains't the row average."
export const infoSuccesfulBreakpointSave = 'New breakpoint was saved succesfully'
export const infoSuccesfulBreakpointAdd = 'Breakpoint was succesfully added to the module'
export const infoSuccesfulBreakpointRemove = 'Breakpoint was succesfully removed from the module'
export const infoSuccesfulBreakpointDelete = 'Breakpoint was succesfully deleted'
export const infoShowNotSelectedTopicsAsOther = 'Enabling this allows not selected\ntopics to be displayed as one\nslice called "other"'
