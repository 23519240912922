import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreNonResetting, { ActionState } from './useStoreNonResetting'
import { getDbSettingsConfig } from '../react-services/configService2'

export type DbSettings = {
  templates: string[]
}
const dashboardSettingsStorage = makeNonResettingObservable<DbSettings>({})

const useDbSettingsConfig = () => {
  const key = 'dbsettings'
  const { tenant, user } = useStoreOwner(dashboardSettingsStorage)
  const {
    dataState: { data, error, state },
  } = useStoreNonResetting(dashboardSettingsStorage, key, user, tenant, true, getDbSettingsConfig)

  const isLoading = state !== ActionState.DONE && state !== ActionState.ERROR
  return { config: data, error, isLoading }
}

export default useDbSettingsConfig
