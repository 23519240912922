import { useMemo } from 'react'
import { ExtendedPointOptionsObject, SunburstDataWithSums, WheelTheme } from '../wheelModuleTypes'
import { CustomerPathKpi } from '../../../../../stores/useConfigCustomerPath'
import { isNull, isUndefined } from 'lodash'
import { Kpi, KpiData } from '../../../../../../types'
import { pSBC } from '../../../../../react-services/colorService'
import {
  allChartColors,
  darkerChartColors,
  oldTrafficLights,
} from '../../../../../../styles/variableExport'
import { TargetColor } from '../../Line/lineChartTypes'
import { CaptionOptions } from 'highcharts8'

import css from './Wheel.module.scss'

const COLOR_BRIGHTNESS_ABS = 0.8
const useWheelSeriesBuilders = (
  categories: CustomerPathKpi[] | undefined,
  numericKpis: Kpi.Kpi[] | null,
  wheelCounts: KpiData.NumericData | null,
  kpiTargetAreaColor: TargetColor | undefined,
  decimals: number,
  defaultMin: number | undefined,
  defaultMax: number | undefined,
  wheelTheme: WheelTheme | undefined,
) => {
  const colors = useMemo(() => {
    return Object.values(darkerChartColors).filter(
      (color) =>
        !(
          kpiTargetAreaColor === TargetColor.GREEN &&
          (color === darkerChartColors.BLUE_GREEN || color === darkerChartColors.LIGHT_BLUE_GREEN)
        ) &&
        !(
          kpiTargetAreaColor === TargetColor.RED &&
          (color === darkerChartColors.RED || color === darkerChartColors.LIGHT_RED)
        ) &&
        !(kpiTargetAreaColor === TargetColor.GREY && color === darkerChartColors.GREY),
    )
  }, [kpiTargetAreaColor])

  const createChartCaption = (
    theme: WheelTheme,
    innerCategories: ExtendedPointOptionsObject[],
    defaultMin: number | undefined,
    defaultMax: number | undefined,
  ): CaptionOptions => {
    let upperLimitCaption = ''
    let lowerLimitCaption = ''
    if (!isUndefined(defaultMax)) {
      upperLimitCaption = `<div class='${css.captionRow}'>`
      if (theme === WheelTheme.STANDARD) {
        upperLimitCaption += createColoredDivSquare('rgb(120, 170, 0)')
      }
      if (theme === WheelTheme.STANDARD_COLORED || theme === WheelTheme.HEAT) {
        upperLimitCaption += createColoredDivSquare(allChartColors.BLUE_GREEN)
      }
      if (theme === WheelTheme.SHADES) {
        for (let i = 0; i < innerCategories.length; i++) {
          const currentColor = pSBC((-1 * COLOR_BRIGHTNESS_ABS) / 2, colors[i]) || colors[i]
          upperLimitCaption += createColoredDivSquare(currentColor)
        }
      }
      upperLimitCaption += `<div class='${css.captionNumber} wheelCaptionMax'>${defaultMax}</div>`
      upperLimitCaption += '</div>'
    }
    if (!isUndefined(defaultMin)) {
      lowerLimitCaption = `<div class='${css.captionRow}'>`
      if (theme === WheelTheme.STANDARD) {
        lowerLimitCaption += createColoredDivSquare('rgb(255, 60, 0)')
      }
      if (theme === WheelTheme.STANDARD_COLORED || theme === WheelTheme.HEAT) {
        lowerLimitCaption += createColoredDivSquare(allChartColors.RED)
      }
      if (theme === WheelTheme.SHADES) {
        for (let i = 0; i < innerCategories.length; i++) {
          const currentColor = pSBC(COLOR_BRIGHTNESS_ABS / 2, colors[i]) || colors[i]
          lowerLimitCaption += createColoredDivSquare(currentColor)
        }
      }
      lowerLimitCaption += `<div class='${css.captionNumber} wheelCaptionMin'>${defaultMin}</div>`
      lowerLimitCaption += '</div>'
    }
    return {
      useHTML: true,
      text: `<div class='${css.caption} wheelCaptionCntr'>${upperLimitCaption}${lowerLimitCaption}</div>`,
      style: { fontWeight: 'bold', fontSize: '14px', color: allChartColors.GREY },
      floating: true,
      verticalAlign: 'bottom',
      align: 'left',
      margin: 0,
      y: 30,
    }
  }

  const createColoredDivSquare = (color: string) =>
    `<div class='${css.captionSquare}' style='background-color: ${color};'></div>`
  const createInnerCategorySeriesWithSums = (): SunburstDataWithSums[] | null => {
    if (!categories || !numericKpis || !wheelCounts) return null
    return categories.reduce((series, categoryKpi) => {
      const { category, kpiid, limitValues } = categoryKpi
      const currentMin = limitValues?.min || defaultMin
      const currentMax = limitValues?.max || defaultMax
      if (isUndefined(category) || isUndefined(kpiid)) return series
      const kpiName = numericKpis.find((k) => k.id === kpiid)?.name
      if (!kpiName) return series
      const foundKpiSeries = wheelCounts.series.find((s) => s.name === kpiName)
      if (!foundKpiSeries) return series
      const dataGroup = foundKpiSeries.data[0]
      if (!dataGroup) return series
      const data = dataGroup.data
      if (!data) return series
      const { n, sum } = data

      const newSeries = [...series]
      const foundSunBurstDataPoint = newSeries.find((p) => p.id === category)
      if (!foundSunBurstDataPoint) {
        const newSunBurstDataPoint: SunburstDataWithSums = {
          id: category,
          n,
          sum,
          name: category,
          parent: 'All',
          kpisN: 1,
          min: currentMin,
          minN: isUndefined(currentMin) ? 0 : 1,
          minSum: currentMin,
          max: currentMax,
          maxN: isUndefined(currentMax) ? 0 : 1,
          maxSum: currentMax,
        }
        return newSeries.concat(newSunBurstDataPoint)
      } else {
        const {
          minSum: prevMinSum,
          minN: prevMinN,
          min: prevMin,
          maxSum: prevMaxSum,
          maxN: prevMaxN,
          max: prevMax,
        } = foundSunBurstDataPoint

        const newMinN =
          prevMinN && !isUndefined(currentMin)
            ? prevMinN + 1
            : !prevMinN && !isUndefined(currentMin)
            ? 1
            : prevMinN
        const newMinSum =
          !isUndefined(prevMinSum) && !isUndefined(currentMin)
            ? prevMinSum + currentMin
            : isUndefined(prevMinSum) && !isUndefined(currentMin)
            ? currentMin
            : prevMinSum

        const newMaxN =
          prevMaxN && !isUndefined(currentMax)
            ? prevMaxN + 1
            : !prevMaxN && !isUndefined(currentMax)
            ? 1
            : prevMaxN
        const newMaxSum =
          !isUndefined(prevMaxSum) && !isUndefined(currentMax)
            ? prevMaxSum + currentMax
            : isUndefined(prevMaxSum) && !isUndefined(currentMax)
            ? currentMax
            : prevMaxSum

        const newSunBurstDataPoint = {
          ...foundSunBurstDataPoint,
          n: (foundSunBurstDataPoint.n || 0) + (n || 0),
          sum: (foundSunBurstDataPoint.sum || 0) + (sum || 0),
          kpisN: foundSunBurstDataPoint.kpisN + 1,
          min: !isUndefined(newMinSum) && newMinN ? newMinSum / newMinN : prevMin,
          minN: newMinN,
          minSum: newMinSum,
          max: !isUndefined(newMaxSum) && newMaxN ? newMaxSum / newMaxN : prevMax,
          maxN: newMaxN,
          maxSum: newMaxSum,
        }
        return newSeries.map((p) => (p.id === category ? newSunBurstDataPoint : p))
      }
    }, [] as SunburstDataWithSums[])
  }

  const createInnerCategoryPointOptions = (
    circleInnerSeries: SunburstDataWithSums[] | null,
  ): ExtendedPointOptionsObject[] | null => {
    if (!circleInnerSeries || !categories) return null
    return circleInnerSeries
      .filter((series) => series.n)
      .map<ExtendedPointOptionsObject>((dataPoint, i, a) => {
        const newValue = dataPoint.n === 0 ? '' : dataPoint.sum / dataPoint.n
        let newColor = pSBC(0.07, darkerChartColors.BLUE)
        if (isNull(newColor)) newColor = darkerChartColors.BLUE
        const chartTheme = wheelTheme || WheelTheme.STANDARD
        if (
          typeof newValue === 'number' &&
          !isUndefined(dataPoint.min) &&
          !isUndefined(dataPoint.max) &&
          chartTheme === WheelTheme.SHADES
        ) {
          newColor = colors[i]
          newColor = createColorShade({
            min: dataPoint.min,
            max: dataPoint.max,
            colorFromIndex: newColor,
            newValue,
          })
        }

        if (
          typeof newValue === 'number' &&
          !isUndefined(dataPoint.min) &&
          !isUndefined(dataPoint.max) &&
          chartTheme === WheelTheme.STANDARD
        ) {
          newColor =
            newValue <= dataPoint.min
              ? oldTrafficLights.RED
              : newValue >= dataPoint.max
              ? oldTrafficLights.GREEN
              : newColor
        }
        if (
          typeof newValue === 'number' &&
          !isUndefined(dataPoint.min) &&
          !isUndefined(dataPoint.max) &&
          chartTheme === WheelTheme.STANDARD_COLORED
        ) {
          newColor =
            newValue <= dataPoint.min
              ? darkerChartColors.RED
              : newValue >= dataPoint.max
              ? darkerChartColors.BLUE_GREEN
              : newColor
        }
        if (
          typeof newValue === 'number' &&
          !isUndefined(dataPoint.min) &&
          !isUndefined(dataPoint.max) &&
          chartTheme === WheelTheme.HEAT
        ) {
          newColor =
            createBinaryColor({
              min: dataPoint.min,
              max: dataPoint.max,
              newValue: newValue,
            }) || newColor
        }

        return {
          realValue: +(+newValue).toFixed(decimals),
          value: dataPoint.n === 0 ? 0 : dataPoint.kpisN / categories.length,
          id: dataPoint.id,
          parent: dataPoint.parent,
          name: dataPoint.name,
          n: dataPoint.n,
          kpiId: null,
          limitValues: {
            min: isUndefined(dataPoint.min) ? dataPoint.min : +dataPoint.min.toFixed(decimals),
            max: isUndefined(dataPoint.max) ? dataPoint.max : +dataPoint.max.toFixed(decimals),
          },
          dataLabels: {
            style: {
              textOverflow:
                dataPoint.name.length > 36 || (a.length > 6 && dataPoint.name.length > 29)
                  ? 'ellipsis'
                  : 'clip',
            },
            className: 'wheelElementLabel-1-' + i,
          },
          level: 1,
          color: newColor,
          className: 'wheelElement-1-' + i,
        } as ExtendedPointOptionsObject
      })
  }

  const createOuterCategoryPointOptions = (
    innerCategorySeries: ExtendedPointOptionsObject[],
  ): ExtendedPointOptionsObject[] | null => {
    if (!categories || !numericKpis || !wheelCounts) return null
    return categories.map<ExtendedPointOptionsObject>((kpiCategory, index, a) => {
      const uniqueCategories = innerCategorySeries.map((s) => s.name)
      const { category, kpiid, limitValues } = kpiCategory
      const currentMin = limitValues?.min || defaultMin
      const currentMax = limitValues?.max || defaultMax
      const kpiName = numericKpis.find((kpi) => kpi.id === kpiid)?.name
      const newCategory: ExtendedPointOptionsObject = {
        level: 2,
        kpiId: isUndefined(kpiCategory.kpiid) ? null : kpiCategory.kpiid,
        realValue: '',
        value: 0,
        n: 0,
        id: kpiid ? (category || '') + kpiid.toString() : 'nodata',
        parent: category,
        name: kpiName ? kpiName : '',
      }

      if (isUndefined(category) || isUndefined(kpiid)) return newCategory
      const foundKpiSeries = wheelCounts.series.find((s) => s.name === kpiName)
      if (!foundKpiSeries) return newCategory
      const dataGroup = foundKpiSeries.data[0]
      if (!dataGroup) return newCategory
      const data = dataGroup.data
      if (!data) return newCategory

      let newColor = pSBC(0.07, darkerChartColors.BLUE)
      if (isNull(newColor)) newColor = darkerChartColors.BLUE
      const chartTheme = wheelTheme || WheelTheme.STANDARD
      if (
        !isUndefined(currentMin) &&
        !isUndefined(currentMax) &&
        typeof data.avg === 'number' &&
        chartTheme === WheelTheme.SHADES
      ) {
        const i = uniqueCategories.findIndex((c) => c === kpiCategory.category)
        newColor = colors[i]
        newColor = createColorShade({
          min: currentMin,
          max: currentMax,
          colorFromIndex: newColor,
          newValue: data.avg,
        })
      }

      if (
        !isUndefined(currentMin) &&
        !isUndefined(currentMax) &&
        typeof data.avg === 'number' &&
        chartTheme === WheelTheme.STANDARD
      ) {
        newColor =
          data.avg <= currentMin
            ? oldTrafficLights.RED
            : data.avg >= currentMax
            ? oldTrafficLights.GREEN
            : newColor
      }
      if (
        !isUndefined(currentMin) &&
        !isUndefined(currentMax) &&
        typeof data.avg === 'number' &&
        chartTheme === WheelTheme.STANDARD_COLORED
      ) {
        newColor =
          data.avg <= currentMin
            ? darkerChartColors.RED
            : data.avg >= currentMax
            ? darkerChartColors.BLUE_GREEN
            : newColor
      }
      if (
        !isUndefined(currentMin) &&
        !isUndefined(currentMax) &&
        typeof data.avg === 'number' &&
        chartTheme === WheelTheme.HEAT
      ) {
        newColor =
          createBinaryColor({
            min: currentMin,
            max: currentMax,
            newValue: data.avg,
          }) || newColor
      }

      return {
        ...newCategory,
        realValue: typeof data.avg === 'string' ? data.avg : +data.avg.toFixed(decimals),
        n: data.n,
        value: 1 / a.length,
        color: newColor,
        className: 'wheelElement-2-' + index,
        limitValues: {
          min: isUndefined(currentMin) ? currentMin : +currentMin.toFixed(decimals),
          max: isUndefined(currentMax) ? currentMax : +currentMax.toFixed(decimals),
        },
        dataLabels: {
          style: {
            textOverflow:
              (newCategory.name?.toString() || '').length > 26 ||
              (a.length > 35 && (newCategory.name?.toString() || '').length > 18)
                ? 'ellipsis'
                : 'clip',
          },
          className: 'wheelElementLabel-2-' + index,
        },
      }
    })
  }
  function createColorShade({
    min,
    max,
    colorFromIndex,
    newValue,
  }: {
    min: number
    max: number
    colorFromIndex: string
    newValue: number
  }) {
    let newColor = colorFromIndex
    const fullRange =
      min < 0 && max < 0
        ? Math.abs(min - max)
        : min < 0 && max >= 0
        ? Math.abs(min) + Math.abs(max)
        : max - min
    if (newValue <= min) newColor = pSBC(COLOR_BRIGHTNESS_ABS / 2, newColor) || newColor
    if (newValue > min && newValue < max && newValue < 0) {
      const valueDistanceInsideRange = Math.abs(min - newValue)
      const adjustedColorBrightness =
        COLOR_BRIGHTNESS_ABS / 2 - COLOR_BRIGHTNESS_ABS * (valueDistanceInsideRange / fullRange)
      newColor = pSBC(adjustedColorBrightness, newColor) || newColor
    }
    if (newValue > min && newValue < max && newValue >= 0) {
      const valueDistanceInsideRange = Math.abs(newValue - min)
      const adjustedColorBrightness =
        COLOR_BRIGHTNESS_ABS / 2 - COLOR_BRIGHTNESS_ABS * (valueDistanceInsideRange / fullRange)
      newColor = pSBC(adjustedColorBrightness, newColor) || newColor
    }
    if (newValue >= max) {
      newColor = pSBC(-(COLOR_BRIGHTNESS_ABS / 2), newColor) || newColor
    }
    return newColor
  }

  function createBinaryColor({
    min,
    max,
    newValue,
  }: {
    min: number
    max: number
    newValue: number
  }) {
    const green = pSBC(-0.15, darkerChartColors.BLUE_GREEN)
    const red = pSBC(-0.15, darkerChartColors.RED)
    const fullRange =
      min < 0 && max < 0
        ? Math.abs(min - max)
        : min < 0 && max >= 0
        ? Math.abs(min) + Math.abs(max)
        : max - min
    const COLOR_BRIGHTNESS_ABS = 1
    let newColor: string | null = null
    if (newValue <= min) {
      newColor = pSBC(0, red, green, true) || newColor
    }
    if (newValue > min && newValue < max && newValue < 0) {
      const valueDistanceInsideRange = Math.abs(min - newValue)
      const adjustedColorBrightness = COLOR_BRIGHTNESS_ABS * (valueDistanceInsideRange / fullRange)
      newColor = pSBC(adjustedColorBrightness, red, green, true) || newColor
    }
    if (newValue > min && newValue < max && newValue >= 0) {
      const valueDistanceInsideRange = Math.abs(newValue - min)
      const adjustedColorBrightness = COLOR_BRIGHTNESS_ABS * (valueDistanceInsideRange / fullRange)
      newColor = pSBC(adjustedColorBrightness, red, green, true) || newColor
    }
    if (newValue >= max) {
      newColor = pSBC(COLOR_BRIGHTNESS_ABS, red, green, true) || newColor
    }
    return newColor
  }

  return {
    createChartCaption,
    createInnerCategorySeriesWithSums,
    createInnerCategoryPointOptions,
    createOuterCategoryPointOptions,
  }
}

export default useWheelSeriesBuilders
