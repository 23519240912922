import React from 'react'
import { Button, Icon } from 'react-materialize'
import AddEditReportItem from './AddEditReportItem'
import ReportSenderModal from './ReportSenderModal'
import DeleteModal from '../../../_shared/Modals/DeleteModal'
import withReportingFilters from '../../../_shared/Hoc/withReportingFilters'

class ReportItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddEdit: false,
      showSenderModal: false,
      showDeleteModal: false,
    }
    this.reportingFilters = this.props.reportingFilters

    this.handleDelete = this.handleDelete.bind(this)
  }

  handleDelete() {
    this.setState({
      showDeleteModal: false,
    })
    this.props.onDelete(this.props.reportConf)
  }

  render() {
    const getPlaceholderImage = () => {
      let seed = this.props.index % 3
      let result =
        seed === 0
          ? 'https://s3-eu-west-1.amazonaws.com/wheelq/ui/Reporttikuva_placeholder_photo.png'
          : seed === 1
          ? 'https://s3-eu-west-1.amazonaws.com/wheelq/ui/Reporttikuva_placeholder2.png'
          : 'https://s3-eu-west-1.amazonaws.com/wheelq/ui/Reporttikuva_placeholder.png'
      return result
    }

    return (
      <div className='cx_kpi_box flexible-column reports-overview-report'>
        {!this.props.isEmpty ? (
          <>
            {/* Title rows */}
            <div className='infograph-box-title'>
              {this.props.reportConf.title}
              <span
                data-cy='reportItemEditorToggle'
                onClick={() => {
                  this.setState({ showAddEdit: true })
                }}
              >
                <Icon right className='pointer' tiny>
                  more_vert
                </Icon>
              </span>
              <span
                data-cy='reportItemDeletorToggle'
                onClick={() => {
                  this.setState({ showDeleteModal: true })
                }}
              >
                <Icon right className='pointer' tiny>
                  delete
                </Icon>
              </span>
            </div>
            <div className='infograph-box-subtitle'>
              {this.props.reportConf.subtitle ? this.props.reportConf.subtitle : '\xa0'}
            </div>

            {/* Report image */}
            <div
              data-cy='reportItemSenderToggler'
              className='flexible-column-stretch-item pointer'
              onClick={() => {
                this.setState({ showSenderModal: true })
              }}
            >
              <img src={getPlaceholderImage()} alt={this.props.title} />
            </div>
          </>
        ) : (
          <>
            <br />
            <br />
            <div className='flexible-column-stretch-item valign-wrapper'>
              <div className='centered-add-icon'>
                <Button
                  data-cy='addReportItemButton'
                  floating
                  large
                  className='transparent'
                  waves='light'
                  icon='add'
                  onClick={() => {
                    this.setState({ showAddEdit: true })
                  }}
                />
              </div>
            </div>
          </>
        )}

        {this.state.showAddEdit && (
          <AddEditReportItem
            reportTemplate={this.props.reportTemplate}
            reportConf={this.props.reportConf}
            onSave={this.props.onSave}
            onModify={this.props.onModify}
            onClose={() => {
              this.setState({ showAddEdit: false })
            }}
          />
        )}

        {this.state.showSenderModal && (
          <ReportSenderModal
            reportConf={this.props.reportConf}
            reportTemplate={this.props.reportTemplate}
            onSend={this.props.onModify}
            onClose={() => {
              this.setState({ showSenderModal: false })
            }}
          />
        )}

        {this.state.showDeleteModal && (
          <DeleteModal
            onConfirm={this.handleDelete}
            onCancel={() => {
              this.setState({ showDeleteModal: false })
            }}
          />
        )}
      </div>
    )
  }
}

export default withReportingFilters(ReportItem)
