import React, { useState, useEffect } from 'react'
import './CookieConsentDialog.scss'
import trackingService from '../../react-services/trackingService'
import MainButton from '../_shared/Buttons/MainButton'
import { isReportMode, getConsentStatus } from '../../utilities'

function CookieConsent() {
  const [, setState] = useState<null>()

  /**
   * Add an event listener in case the location changes without refresh
   */
  useEffect(() => {
    window.addEventListener('popstate', popStateListener)
  }, [])

  if (getConsentStatus() !== 'Unset' || isReportMode()) {
    return null
  }

  function handleAccept() {
    trackingService.acceptTracking()
    setState(null)
  }

  function handleDecline() {
    trackingService.declineTracking()
    setState(null)
  }

  /**
   * Rerender if in report mode
   */
  function popStateListener() {
    if (isReportMode()) {
      setState(null)
    }
  }

  return (
    <div className="cookie-consent-dialog">
      <div>
        <div>
          By clicking the “Agree” -button, you agree to our use of cookies. We use cookies to provide you with a great experience, to help our website run effectively and to collect information about the site usage.
        </div>
        <div>
          <MainButton data-testid="accept-cookies" onClick={handleAccept} text="Agree"></MainButton>
          <MainButton isFlat data-testid="decline-cookies" onClick={handleDecline} text="Decline"></MainButton>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent