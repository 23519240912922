import React from 'react'
import { post } from '../../../react-services/apiService'
import { Input, Row, Button } from 'react-materialize'
import ModuleFilterBar from '../../Dashboards/DashboardModules/_shared/Filter/ModuleFilterBar'
import trackingService from '../../../react-services/trackingService'
import { TrackingEvent } from '../../../react-constants'
import withNumericKpis from '../../_shared/Hoc/withNumericKpis'

class InsertDataManually extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      questions: this.props.numericKpis,
      filters: {},
      startDate: '',
      endDate: '',
      questionId: '',
      value: '',
      success: false,
      error: false,
    }
  }

  componentDidMount() {
    this.getDate()
  }

  getDate = () => {
    let startDate = new Date()
    startDate.setFullYear(startDate.getFullYear() - 1)
    startDate = startDate.toISOString().slice(0, 10)
    const endDate = new Date().toISOString().slice(0, 10)
    this.setState({
      startDate: startDate,
      endDate: endDate,
    })
  }

  handleChangeQuestion = (event) => {
    this.setState({ questionId: event.target.value })
  }

  handleChangeValue = (event) => {
    this.setState({ value: event.target.value })
  }

  changeDate = (date, isStartDate) => {
    if (isStartDate) {
      this.setState({ startDate: date })
    } else {
      this.setState({ endDate: date })
    }
  }

  changeFilters = (filters) => {
    this.setState({ filters: filters })
  }

  submit = () => {
    const filters = JSON.parse(JSON.stringify(this.state.filters))
    for (let filter in filters) {
      if (filters[filter].length === 1) {
        filters[filter] = filters[filter].toString()
      } else {
        alert('Only one value per filter can be selected')
        return
      }
    }
    if (this.state.questionId === '') {
      alert('Please choose a question')
      return
    }
    if (this.state.value === '') {
      alert('Please specify a numeric value')
      return
    }
    if (this.state.endDate === '') {
      alert('Date is missing')
      return
    }
    const newimportvalue = {
      data: [
        {
          value: parseFloat(this.state.value),
          text: '',
          numericquestionid: parseInt(this.state.questionId, 10),
          type: 'number',
        },
      ],
      date: this.state.endDate,
      meta: [filters],
    }
    const question = this.state.questions.find((q) => q.id === parseInt(this.state.questionId, 10))
    let filtersString = JSON.stringify(filters)

    filtersString = filtersString.replace(/{/g, '')
    filtersString = filtersString.replace(/}/g, '')
    filtersString = filtersString.replace(/":/g, ': ')
    filtersString = filtersString.replace(/"/g, '')
    filtersString = filtersString.replace(/,/g, '\n')

    if (
      window.confirm(
        'Are you sure you want to send:\n\nQuestion: ' +
          question.name +
          '\nValue: ' +
          newimportvalue.data[0].value +
          '\nDate: ' +
          newimportvalue.date +
          '\nFilters: ' +
          filtersString,
      )
    ) {
      trackingService.track(TrackingEvent.InsertDataManually)
      post('POST_IMPORTDATA', [newimportvalue], { parseAsText: true }).then(
        (res) => {
          this.setState({
            questionId: '',
            value: '',
            success: true,
          })
          setTimeout(() => {
            this.setState({ success: false })
          }, 5000)
        },
        (error) => {
          console.error(error)
          this.setState({
            questionId: '',
            value: '',
            error: true,
          })
          setTimeout(() => {
            this.setState({ error: false })
          }, 5000)
        },
      )
    }
  }

  render() {
    return (
      <div data-cy='dataEntry'>
        <br />
        <br />
        <div>
          <ModuleFilterBar
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            selections={this.state.filters}
            hideIcons={true}
            onChangeDate={this.changeDate}
            onChangeMetadata={this.changeFilters}
          />
        </div>

        <div>
          Edit the filters to change metadata. StartDate is not used. Use EndDate to set date for
          the value.
        </div>

        {this.state.questions && (
          <div>
            <Row>
              <br />
              <div data-testid='selectQuestionManualDataInsertion'>
                <Input
                  s={6}
                  type='select'
                  value={this.state.questionId}
                  onChange={this.handleChangeQuestion}
                  name='questionId'
                >
                  <option value=''>Choose a question</option>
                  {this.state.questions.map((question, id) => (
                    <option
                      data-testid={`selectQuestion-${question.id}`}
                      key={id + ':' + question.id}
                      value={question.id}
                    >
                      {question.name}
                    </option>
                  ))}
                </Input>
              </div>
            </Row>
            <div data-cy='chooseValue'>
              <Row>
                <Input
                  s={6}
                  type='number'
                  label='Choose value'
                  value={this.state.value}
                  onChange={this.handleChangeValue}
                  name='value'
                />
              </Row>
              <Button data-cy='sendData' onClick={this.submit}>
                Submit
              </Button>
            </div>
          </div>
        )}

        {this.state.success && <div className='green red data-margin'>Data sent!</div>}
        {this.state.error && <div className='card red data-margin'>Error</div>}
      </div>
    )
  }
}

export default withNumericKpis(InsertDataManually)
