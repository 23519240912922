import { DateGroups } from '../../Pietabular/pietabularTypes'
import { TabularModule } from '../../Tabular/tabularTypes'
import { Module } from '../groupModuleTypes'

export const initModuleConfig: Record<string, Module> = {
  pietabular: {
    type: 'pietabular',
    options: {
      modulewidth: '6',
      tolerance: '10',
      showTotalCount: true,
      defaultSortIsAscending: true,
      showTooltip: true,
      defaultCountedKey: 'sentiment',
      defaultDateGroup: DateGroups.MONTH,
      defaultSortColumn: 1,
      numberOfTopTopicsToShow: 10,
      cellDataMode: 'n',
      tabularDataFormat: 'n',
    },
  },
  tabular: {
    type: 'tabular',
    title: 'Tabular module',
    options: {
      show_total: true,
      trends: true,
      show_total_n: true,
      cellDataMode: 'avg',
    },
  } as TabularModule,
}
