import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const InProgressIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'pending'}
    text=""
    customClasses={[css.btn]}
    iconPosition={'center'}
    title="Change alert state to 'IN PROGRESS'"
    data_testid="in-progress-icon-button"
  />
)

export default InProgressIcon;