import React, { useEffect, useRef, useState } from 'react'
import * as Highcharts from 'highcharts8'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import css from './RadarModule.module.scss'
import RadarModeSettings from './RadarSettings/RadarModeSettings'
import RadarNotification from './RadarNotification'
import { RadarDataMode } from './radarTypes'
import { DateGroups, GroupBy, OpenAnswersCountsQuery } from '../Pietabular/pietabularTypes'
import BuildChartData from './RadarChartOptions/BuildChartData'
import NewChartOptions from './RadarChartOptions/NewChartOptions'
import { useRadarData } from './RadarContext/RadarDataContext'
import { useRadarSettings } from './RadarContext/RadarSettingsContext'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import NoData from '../../../_shared/Infos/NoData'
import useOpenCategories from '../../../../stores/useOpenCategories'
import useOpenAnswerCounts from '../../../../stores/useOpenAnswerCounts'
import HighchartsReact from 'highcharts-react-official'
import { useToastId } from '../../../common/Notification/NotificationContext'
import { toast } from 'react-toastify'

// TODO: Use these for notification, when implementing industry averages
// const allowedTimeframes = ['lm', 'lq', 'ly']

const RadarModuleComponent = () => {
  const [{ radarOptions, isLoading, radarCountQuery }, setRadarData] = useRadarData()
  const [
    {
      selectedTopics,
      radarDataMode,
      radarDataDisplay,
      radarMetaKey,
      radarMetaKeyValuesSelected,
      radarFilters,
    },
  ] = useRadarSettings()

  const timeoutRef = useRef<NodeJS.Timeout>()
  const radarChartRef = useRef<HighchartsReact.RefObject>(null)
  const [ready, setReady] = useState('')
  const { toastifyId } = useToastId()

  const { answerCategories, loading: topicsLoading, error: topicsError } = useOpenCategories()
  const {
    counts: radarCounts,
    isLoading: countsLoading,
    error: countsError,
  } = useOpenAnswerCounts(radarCountQuery)
  const allTopics = answerCategories?.topic

  const hasData = Boolean(radarCounts && allTopics && allTopics.length > 0)
  const isDataLoading = Boolean(isLoading || countsLoading || topicsLoading)

  const moreTopicsNote = Boolean(selectedTopics.length < 5)
  const lessTopicsNote = Boolean(selectedTopics.length > 10)
  const dataModeNote = Boolean(!radarDataMode)
  const metaKeyNote = Boolean(radarDataMode === RadarDataMode.META && !radarMetaKey)
  const metaValueNote = Boolean(
    radarDataMode === RadarDataMode.META && radarMetaKeyValuesSelected.length === 0,
  )

  /* const metaFiltersNote = Boolean(
    radarDataMode === RadarDataMode.AMOUNT && Object.keys(radarFilters.where_meta).length > 0,
  ) */

  const showChart = Boolean(
    hasData &&
      !isDataLoading &&
      !moreTopicsNote &&
      !lessTopicsNote &&
      !dataModeNote &&
      !metaKeyNote &&
      !metaValueNote,
  )

  useEffect(() => {
    if (topicsError) {
      toast.error(topicsError, { containerId: toastifyId })
    }
  }, [topicsError])

  useEffect(() => {
    if (countsError) {
      toast.error(countsError, { containerId: toastifyId })
    }
  }, [countsError])

  useEffect(() => {
    if (!radarFilters) return

    let countsQuery: OpenAnswersCountsQuery = {
      date_grouping: DateGroups.YEAR,
      group_by: [GroupBy.SENTIMENT, GroupBy.TOPIC],
      start_date: radarFilters.start_date,
      end_date: radarFilters.end_date,
      where_meta: radarFilters.where_meta,
    }

    if (radarDataMode === RadarDataMode.META) {
      if (!radarMetaKey) return
      countsQuery = {
        ...countsQuery,
        group_by: [GroupBy.META_KEY, GroupBy.SENTIMENT, GroupBy.TOPIC],
        meta_key: radarMetaKey,
      }
    }

    setRadarData((prev) => ({
      ...prev,
      radarCountQuery: countsQuery,
    }))
  }, [radarFilters, radarDataMode, radarMetaKey])

  useEffect(() => {
    // Try to force a proper update
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    setRadarData((prev) => ({
      ...prev,
      isLoading: true,
    }))
    timeoutRef.current = setTimeout(() => {
      setRadarData((prev) => ({
        ...prev,
        isLoading: false,
      }))
      setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
    }, 400)
    return () => timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [radarDataMode, radarDataDisplay])

  useEffect(() => {
    if (radarChartRef.current?.chart?.reflow) radarChartRef.current.chart.reflow()
  }, [radarOptions])

  return (
    <>
      <div className={`${css.radar_module} ${ready}`} data-testid='radar-module'>
        <RadarModeSettings />
        <BuildChartData radarCounts={radarCounts} allTopics={allTopics} />
        <NewChartOptions />
        <div className={css.radar_module_chart} data-testid='radar-chart'>
          {isLoading && <LoadingIndicator />}
          {showChart ? (
            <HighchartsReact highcharts={Highcharts} options={radarOptions} ref={radarChartRef} />
          ) : (
            <RadarNotification
              moreTopicsNote={moreTopicsNote}
              lessTopicsNote={lessTopicsNote}
              dataModeNote={dataModeNote}
              metaKeyNote={metaKeyNote}
              metaValueNote={metaValueNote}
            />
          )}
        </div>
        {!hasData && <NoData />}
      </div>
    </>
  )
}

export default RadarModuleComponent
