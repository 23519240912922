import React from 'react'
import {
  errorGenericModuleErroTitle,
  errorInvalidNumberOfTopicsToShow,
  errorInvalidShowTooltip,
  errorInvalidShowTotalCount,
  errorInvalidSortIndex,
  errorInvalidSortingDirection,
  errorInvalidTolerance,
  errorNoDefaultCountKey,
  errorNoDefaultMetaKey,
  errorNoMetaKeys,
  errorNoTolerance,
  errorNoTopics,
} from '../PietabularNotifications'
import { usePieTabularSettings } from '../PietabularModuleContext'
import css from './SettingsMissingError.module.scss'

const SettingsMissingError = () => {
  const { settings } = usePieTabularSettings()[0]
  if (!settings) return <div className={css.cntr}>There was an issue loading settings</div>
  return (
    <div className={css.cntr}>
      <label>{errorGenericModuleErroTitle}</label>
      {(!settings.openCategories || !settings.openCategories.length) && (
        <label>{errorNoTopics}</label>
      )}
      {(!settings.metaKeys || !settings.metaKeys.length) && <label>{errorNoMetaKeys}</label>}
      {!settings.defaultMetaGroup && <label>{errorNoDefaultMetaKey}</label>}
      {!settings.defaultCountedKey && <label>{errorNoDefaultCountKey}</label>}
      {settings.numberOfTopTopicsToShow && isNaN(settings.numberOfTopTopicsToShow) && (
        <label>{errorInvalidNumberOfTopicsToShow}</label>
      )}
      {(settings.tolerance === null || settings.tolerance === undefined) && (
        <label>{errorNoTolerance}</label>
      )}
      {settings.tolerance === null ||
        settings.tolerance === undefined ||
        (settings.tolerance === '' && <label>{errorInvalidTolerance}</label>)}
      {typeof settings.showTotalCount !== 'boolean' && <label>{errorInvalidShowTotalCount}</label>}
      {!!(settings.defaultSortColumn === null || settings.defaultSortColumn === undefined) ||
        ((isNaN(settings.defaultSortColumn) || settings.defaultSortColumn <= 0) && (
          <label>{errorInvalidSortIndex}</label>
        ))}
      {typeof settings.defaultSortIsAscending !== 'boolean' && (
        <label>{errorInvalidSortingDirection}</label>
      )}
      {typeof settings.showTooltip !== 'boolean' && <label>{errorInvalidShowTooltip}</label>}
    </div>
  )
}

export default SettingsMissingError
