import { useMountedState } from 'react-use'
import { WhereMeta } from '../../types'
import { useEffect, useRef, useState } from 'react'
import { getTenant, getUsername } from '../react-services/authService'
import objectHash from 'object-hash'
import { makeNonResettingObservable } from './nonResettingObservable'
import { subMinutes } from 'date-fns'
import { getReportingFiltersDynamic } from '../react-services/metadataService'
import { errorFetchingDynamicFilters } from './notificationMessages'

const OUTDATED_DATA_TIME_MINS = 5
type DynamicFilterStorageType = {
  filters: WhereMeta | null
  timestamp: Date
} | null
const dynamicFilterStorage = makeNonResettingObservable<DynamicFilterStorageType>(
  {},
  OUTDATED_DATA_TIME_MINS,
)

export type DynamicFiltersQuery = {
  kpis: number[]
  query: {
    start_date?: string
    end_date?: string
    where_meta?: WhereMeta
  }
}

export type DynamicFiltersResponse = {
  filters: WhereMeta
}

const enum LoadingState {
  INIT = 'init',
  LOADING = 'loading',
  REFETCHING = 'refetching',
  DONE = 'done',
}
const useDynamicFilters = (
  query: DynamicFiltersQuery | null,
  FETCH_TIMEOUT = 200,
  resetWhenNewQuery = false,
  isExpanded = true,
) => {
  const isMounted = useMountedState()
  const fetchTimeoutRef = useRef<NodeJS.Timeout>()
  const tenant = getTenant() || ''
  const user = getUsername() || ''
  const key = objectHash(query || {}, { unorderedArrays: true, unorderedObjects: true })
  const [data, setData] = useState<DynamicFilterStorageType | null>(null)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<LoadingState>(LoadingState.INIT)

  useEffect(() => {
    const owner = dynamicFilterStorage.getOwner()
    if (!owner.tenant && !owner.user) dynamicFilterStorage.reset()
    if (owner.tenant !== tenant || owner.user !== user) dynamicFilterStorage.reset()
    dynamicFilterStorage.setOwner(tenant, user)
  }, [tenant, user])

  const hasKpis = query?.kpis && query.kpis.length
  const hasQuery = query && hasKpis && query.query.start_date && query.query.end_date
  useEffect(() => {
    if (!hasQuery || (!user && !tenant)) {
      setLoading(LoadingState.DONE)
      setData(null)
      return
    }
    if (!data) setLoading(LoadingState.LOADING)
    if (data) setLoading(LoadingState.REFETCHING)
    if (resetWhenNewQuery) setData(null)

    dynamicFilterStorage.removeOutDatedData()
    const hasListenersAlready = !!dynamicFilterStorage.getRelatedListeners(key)
    const storedData = dynamicFilterStorage.get(key)
    if (!hasListenersAlready && storedData) {
      const storeTime = storedData.timestamp
      const currentTime = new Date()
      const hasOutDatedData = storeTime < subMinutes(currentTime, OUTDATED_DATA_TIME_MINS)
      if (fetchTimeoutRef.current) clearTimeout(fetchTimeoutRef.current)
      fetchTimeoutRef.current = setTimeout(() => {
        hasOutDatedData && getDynamicFilter(query, key, isExpanded)
      }, FETCH_TIMEOUT)
      !hasOutDatedData && setData(storedData)
      !hasOutDatedData && setLoading(LoadingState.DONE)
    }
    if (!hasListenersAlready && !storedData) {
      if (fetchTimeoutRef.current) clearTimeout(fetchTimeoutRef.current)
      fetchTimeoutRef.current = setTimeout(() => {
        getDynamicFilter(query, key, isExpanded)
      }, FETCH_TIMEOUT)
    }
    if (hasListenersAlready && storedData) {
      setData(storedData)
      setLoading(LoadingState.DONE)
    }
    return dynamicFilterStorage.subscribe(setData, key)
  }, [key])

  useEffect(() => {
    if (data) setLoading(LoadingState.DONE)
  }, [data])

  useEffect(() => {
    return () => fetchTimeoutRef.current && clearTimeout(fetchTimeoutRef.current)
  }, [])

  const getDynamicFilter = (query: DynamicFiltersQuery, key: string, isExpanded: boolean) => {
    return getReportingFiltersDynamic(query, isExpanded)
      .then((filters) => {
        if (!isMounted()) return
        const timestamp = new Date()
        const newDataWithTimeStamp = { timestamp, ...filters }
        setData(newDataWithTimeStamp)
        dynamicFilterStorage.set(newDataWithTimeStamp, key)
      })
      .catch(() => isMounted() && setError(errorFetchingDynamicFilters))
      .finally(() => isMounted() && setLoading(LoadingState.DONE))
  }

  return {
    dynamicFilter: data ? data.filters : null,
    timestamp: data ? data.timestamp : null,
    isLoading: loading === LoadingState.INIT || loading === LoadingState.LOADING,
    isRefetching: loading === LoadingState.REFETCHING,
    error,
  }
}

export default useDynamicFilters
