export const errorLoadingModule = 'There was an error loading the frequency module'

export const infoSelectChartType = 'Column type is vertical,\nbar is horizontal'
export const infoSelectedComparisonTimeFrame =
  'Number of days before the current\nselected filter time frame. If no number\nis given, the timeframe equals to current\ntimeframe size. Showing one week of\ndata in normal filter means comparison\nis without input 7 days before that.'
export const infoSelectedComparisonMetaValue = ''
export const infoSelectChartFormat =
  'Data format can be percentage\nor total n count for all\nanswers in each group'
export const infoSoftMin =
  'Enable soft mode if you want\nthe data that overflows to be\nshown and min to strech according\nto the data'
export const infoSoftMax =
  'Enable soft mode if you want\nthe data that overflows to be\nshown and max to strech according\nto the data'
export const infoSelectDataType = 'Please select data type and sources first for frequency module'
export const infoNumberOfDataPoints =
  'Number of data points visible on x-axis.\nRemoving this will remove any limitations.'
