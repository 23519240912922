export const errorLoadingModule = 'Error loading module group module'
export const errorParsingDates = 'Error parsing dates'
export const errorConvertingModuleSettings = 'Error converting module settings'

export const infoModuleNotPartOfList =
  'Module that was picked, is not part of the group modules list'
export const infoKpiContext =
  'Kpi context provides the found/selected\nkpis in other modules to all modules inside\nthe group. This can be then used in kpi\nselections inside settings or as a data filter\nwith dynamic filters or textbox meta list'
export const infoConfigKpiExtraction =
  'Some modules use customer path kpis as\nfilter. Kpis from the config are ignored by\ndefault. If you want to include customer\npath kpis used in modules inside the group,\nclick to enable'
export const infoPastDays = 'Number of past days to use\nas time frame in filter'
export const infoFutureDays = 'Number of future days to use\nas time frame in filter'
export const infoFilterSelections = 'Disabled/greyed out values are inherited from parent filters'
