import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useMountedState } from 'react-use'

export enum MoreToLoad {
  START = 'START',
  MORE = 'MORE',
  END = 'END',
  RESET = 'RESET',
}

const useInfiniteScroll = (
  callback: () => Promise<boolean>,
  minPixelsLeftUntillLoadMore = 600,
  scrollElement: HTMLTableElement | HTMLDivElement | undefined,
  needMoreToLoad?: boolean,
) => {
  const isMounted = useMountedState()
  const [hasMoreToLoad, setHasMoreToLoad] = useState<MoreToLoad>(MoreToLoad.START)
  const [scrolled, setScrolled] = useState(0)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  if (hasMoreToLoad !== MoreToLoad.END && scrollElement && callback) {
    scrollElement.onscroll = debounce(
      () => {
        if (!isMounted()) return
        const scrollToBottom =
          scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop
        if (scrollToBottom < minPixelsLeftUntillLoadMore) {
          callback().then((hasMore) => setHasMoreToLoad(hasMore ? MoreToLoad.MORE : MoreToLoad.END))
        }
        setScrolled(scrollElement.scrollTop)
      },
      200,
      { maxWait: 300 },
    )
  }
  if (scrollElement && (hasMoreToLoad === MoreToLoad.END || !callback)) {
    scrollElement.onscroll = debounce(() => setScrolled(scrollElement.scrollTop), 200, {
      maxWait: 300,
    })
  }

  useEffect(() => {
    debounce(
      () => {
        if (!isMounted()) return
        if (scrollElement && needMoreToLoad && hasMoreToLoad !== MoreToLoad.END && !isLoadingMore) {
          const scrollToBottom =
            scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop
          if (scrollToBottom < minPixelsLeftUntillLoadMore) {
            setIsLoadingMore(true)
            callback()
              .then((hasMore) => setHasMoreToLoad(hasMore ? MoreToLoad.MORE : MoreToLoad.END))
              .finally(() => {
                setIsLoadingMore(false)
                setScrolled(scrollElement.scrollTop)
              })
          }
        }
      },
      1000,
      { maxWait: 1000 },
    )()
  }, [
    scrollElement?.scrollTop,
    scrollElement?.scrollHeight,
    scrollElement?.offsetHeight,
    needMoreToLoad,
    hasMoreToLoad,
    isLoadingMore,
  ])

  const reset = () => {
    if (!isMounted()) return
    setHasMoreToLoad(MoreToLoad.RESET)
    if (scrollElement && needMoreToLoad && hasMoreToLoad !== MoreToLoad.END && !isLoadingMore) {
      const scrollToBottom =
        scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop
      if (scrollToBottom < minPixelsLeftUntillLoadMore) {
        setIsLoadingMore(true)
        callback()
          .then((hasMore) => {
            if (!isMounted()) return
            setHasMoreToLoad(hasMore ? MoreToLoad.MORE : MoreToLoad.END)
          })
          .finally(() => {
            if (!isMounted()) return
            setIsLoadingMore(false)
            setScrolled(scrollElement.scrollTop)
          })
      }
    }
  }
  return { hasMoreToLoad, reset, scrolled }
}

export default useInfiniteScroll
