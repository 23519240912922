import { get, post } from './apiService'
import { isInSessionStorage, getBxxSettings, setBxxSettings } from './authService'
import {
  EMPTY_DASHBOARD,
  DASHBOARDCONSTANTS,
  DEFAULT_DECIMALS,
} from '../react-constants/dashboards'
import { isPlainObject, isEmpty, isUndefined, cloneDeep } from 'lodash'
import { commondbsettingStorage } from '../stores/useCommonDbSettingsConfig'

function getGenericConfiguration(name) {
  if (!name) throw new Error('Config name empty/undefined')
  return get('GET_GENERIC_CONFIG', { name })
    .then((conf) => {
      return conf
    })
    .catch((error) => {
      console.error(`Failed to fetch a config called ${name}:`)
      console.error(error)
      return null
    })
}

function getPreBuiltConfig(name) {
  return getGenericConfiguration(name).then((conf) => attachLimitValues(conf, name))
}

function attachLimitValues(config, name) {
  if (!config || !isPlainObject(config)) return null

  let defaultLimitValues = config.limitValues
  let kpis = config.conf
  if (defaultLimitValues && kpis) {
    kpis.forEach((kpi) => {
      kpi.limitValues = kpi.limitValues ? kpi.limitValues : defaultLimitValues
    })
  } else {
    console.warn(`Config ${name} doesnt need prebuilding.`)
  }
  return config
}

function getDashboardSettings(partOfSettings = null) {
  if (isInSessionStorage('bxxSettings'))
    return new Promise((resolve) =>
      resolve(returnSettingsInPartOrWhole(getBxxSettings(), partOfSettings)),
    )

  return getDashboardSettingsFromApi().then((settings) => {
    if (typeof settings === 'undefined' || !settings) {
      settings = EMPTY_DASHBOARD
    } else {
      settings = unWrapConfFromArray(settings)
    }
    setBxxSettings(settings)
    return returnSettingsInPartOrWhole(settings, partOfSettings)
  })

  function unWrapConfFromArray(config) {
    if (Array.isArray(config)) {
      return {
        dashboards: config,
      }
    } else return config
  }

  function returnSettingsInPartOrWhole(config, part) {
    if (!part) return config

    const VALID_PARTS = Object.freeze(['dashboards', 'mydashboards', 'sections'])

    if (!VALID_PARTS.includes(part)) {
      console.warn(
        `${part} can't be obtained dashboard settings. Allowed items are ${VALID_PARTS.join(
          ', ',
        )}. The entire config is returned.`,
      )
      return config
    } else return config[part]
  }
}

/** Preserve old dashboard if dashboard name contains a | character
    Recursively traverse child dashboards if iterationKey is defined
*/
function preserveLockedDashboard(oldDash, newDash = [], iterationKey = null) {
  if (newDash.length) {
    for (let i = 0; i < newDash.length; i++) {
      let newName = newDash[i].name
      let oldName = i < oldDash.length ? oldDash[i].name : ''
      if (dashboardIsLocked(newName, oldName)) {
        let isChanged = JSON.stringify(newDash[i]) !== JSON.stringify(oldDash[i])
        if (isChanged)
          console.log('Revert changes to locked dashboard! (' + newName.split('|')[0] + ')')
        newDash[i] = oldDash[i]
      } else if (iterationKey) {
        let newChildDash = newDash[i][iterationKey]
        let oldChildDash = oldDash[i][iterationKey]
        if (newChildDash && newChildDash.length) {
          newChildDash = preserveLockedDashboard(oldChildDash, newChildDash)
        }
      }
    }
  }
  return newDash

  function dashboardIsLocked(newName, oldName) {
    return (
      newName.indexOf(DASHBOARDCONSTANTS.LOCKED_DASH_CHAR) !== -1 &&
      oldName.indexOf(DASHBOARDCONSTANTS.LOCKED_DASH_CHAR) !== -1
    )
  }
}

function saveMyDashboards(newSettings) {
  return getDashboardSettings().then((settings) => {
    settings.mydashboards = newSettings

    return saveSettings(settings).then(() => newSettings)
  })
}

function saveSettings(settings) {
  let newSettings = removeDeprecatedModules(omitHashKey(settings))

  return checkForLockedSections(newSettings).then(function (settings) {
    cleanUpSettings(settings)
    return post('POST_DASHBOARD_SETTINGS', settings, { parseAsText: true })
      .then(() => {
        setBxxSettings(settings)
        return settings
      })
      .catch((e) => {
        console.error('Error while saving dashboard settings: ', e)
        return settings
      })
  })
}

/** In case you are wondering: this thing relates to AngularJS and how it adds hashKeys to keep track of its scopes. Can be removed when AngularJS is no more.
 */
function omitHashKey(obj) {
  let result = cloneDeep(obj)

  for (let prop in result) {
    if (prop === '$$hashKey') {
      delete result[prop]
    } else if (typeof result[prop] === 'object') {
      omitHashKey(result[prop])
    }
  }
  return result
}

/** If it's 2020 and this function still exists, you can remove it
 */
function removeDeprecatedModules(settings) {
  try {
    let s = cloneDeep(settings)
    if (s.dashboards && Array.isArray(s.dashboards)) {
      s.dashboards.forEach((d) => {
        d.modules = eliminateDeprecatedModules(d.modules)
      })
    }

    if (s.mydashboards && Array.isArray(s.mydashboards)) {
      s.mydashboards.forEach((sec) => {
        sec.dashboards.forEach((d) => {
          d.modules = eliminateDeprecatedModules(d.modules)
        })
        try {
          delete sec.modules
        } catch (e) {
          /* Whatever */
        }
      })
    }

    if (s.sections && Array.isArray(s.sections)) {
      s.sections.forEach((sec) => {
        if (sec.dashboards && Array.isArray(sec.dashboards)) {
          sec.dashboards.forEach((d) => {
            d.modules = eliminateDeprecatedModules(d.modules)
          })
        }
      })
    }
    return s
  } catch (e) {
    console.error('Encountered a problem while removing deprecated modules. Mission was aborted.')
    return settings
  }

  function eliminateDeprecatedModules(mods) {
    const DEPRECATED = [
      'cx_overview',
      'cx_overview2',
      'oc_overview',
      'os_overview',
      'segment_overview',
    ]

    let result = []
    if (mods && Array.isArray(mods)) {
      mods.forEach((m) => {
        if (m && !DEPRECATED.includes(m.type)) {
          result.push(m)
        }
      })
    }
    return result
  }
}

function cleanUpSettings(settings) {
  try {
    // old conf when there was an additional Insights-mode
    delete settings.insight
  } catch (e) {
    /* ignore */
  }

  try {
    // old conf for when old module versions were updated
    delete settings.moduleVersions
  } catch (e) {
    /* ignore */
  }
}

// Check for locked dashboards in multilevel dashboard
function checkForLockedSections(newSettings) {
  return new Promise((resolve) => {
    if (!newSettings) {
      return resolve(newSettings)
    }
    // Return if there are no multilevel dashboards
    if (!newSettings.sections || !newSettings.sections.length) {
      return resolve(newSettings)
    }

    return getDashboardSettings().then((settings) => {
      //eslint-disable-next-line
      let newDash = newSettings.sections
      let oldDash = settings.sections
      let iterationKey = 'dashboards'
      if (oldDash) newDash = preserveLockedDashboard(oldDash, newDash, iterationKey)
      return resolve(newSettings)
    })
  })
}

function getDashboardSettingsFromApi() {
  return get('GET_DASHBOARD_SETTINGS').catch((e) => {
    console.error(e)
    window.Materialize.toast('Could not load dashboards.')
    return null
  })
}

function getCalculatedKpis() {
  return getGenericConfiguration('calculated_kpis')
}

function getCommonDbSettings() {
  return Promise.resolve(commondbsettingStorage.get('commondbsettings'))
}

function getCommonDbSettingsSync() {
  return commondbsettingStorage.get('commondbsettings')
}

function getCustomerPathConfig() {
  return getGenericConfiguration('customerpath').then((conf) => {
    if (!conf || isEmpty(conf)) {
      return null
    } else if (isEmpty(conf.conf) || isEmpty(conf.limitValues)) {
      console.warn('Customer path conf is not correctly configured.')
      return null
    }
    return attachLimitValues(conf)
  })
}

function getDecimalsConfigSync() {
  let result = parseInt(getCommonDbSettingsSync()?.decimals ?? DEFAULT_DECIMALS)

  if (!isUndefined(result) && !isNaN(result)) {
    return result
  } else {
    return DEFAULT_DECIMALS
  }
}

function getAvailableModules() {
  return getGenericConfiguration('dashboardmodules').then((res) => {
    if (!res || isEmpty(res) || !res.enabled || isEmpty(res.enabled)) {
      return null
    }

    return res
  })
}

function enableChartThemes() {
  let setup = getCommonDbSettingsSync()
  return setup && setup.chart_themes_enabled === true
}

function isUsingDynamicFilters() {
  try {
    let cdbs = getCommonDbSettingsSync()
    return cdbs.use_dynamic_filters === true || cdbs.use_dynamic_filters === 'timeframe'
  } catch (e) {
    console.warn("Couldn't determine if dynamic filters are used; will assume they are")
    return true
  }
}

export {
  getGenericConfiguration,
  getPreBuiltConfig,
  getCalculatedKpis,
  getCommonDbSettings,
  getCommonDbSettingsSync,
  getDashboardSettings,
  saveSettings,
  saveMyDashboards,
  attachLimitValues,
  getCustomerPathConfig,
  getDecimalsConfigSync,
  enableChartThemes,
  getAvailableModules,
  isUsingDynamicFilters,
}
