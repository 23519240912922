import React from 'react'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoSelectChartFormat } from '../FrequencyNotifications'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { FrequencyModule, NumericKpiFormat } from '../frequencyModuleTypes'
import { cloneDeep, isUndefined } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'

const ChartNumericKpiDataFormat = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const freqModule = module as FrequencyModule
  const freqOptions = module?.options
  const numericKpiFormat = freqModule.options?.numericKpiDataFormat

  // any for typeguard
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isNumericKpiFormat = (value: any): value is NumericKpiFormat => {
    if (!value) return false
    return Object.values(NumericKpiFormat).includes(value)
  }

  const handleOnChangeChartFormat = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!freqModule) return
    const moduleCopy = cloneDeep(freqModule)
    if (!isUndefined(moduleCopy.horizontalBars)) delete moduleCopy.horizontalBars
    const newChartFormat = e.currentTarget.value
    if (!isNumericKpiFormat(newChartFormat)) return
    saveModule({
      ...moduleCopy,
      options: {
        ...(freqOptions || {}),
        numericKpiDataFormat: newChartFormat,
        limitTarget: newChartFormat,
      },
    })
  }

  return (
    <>
      <div className={`${modal_css.half}`}>
        <TextWithInfo
          text='Chart format for y-axis'
          info={infoSelectChartFormat}
          tooltipDirection='right'
        />
        <RequiredStar />
        <select
          className={`${css.input} browser-default ${numericKpiFormat ? '' : modal_css.required}`}
          onChange={handleOnChangeChartFormat}
          placeholder={'select type'}
          data-testid={'numericKpiDataFormat'}
          id={'numericKpiDataFormat'}
          value={numericKpiFormat}
        >
          <option key={'emptyOption'} value={''}></option>
          {Object.values(NumericKpiFormat).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export default ChartNumericKpiDataFormat
