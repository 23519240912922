import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const ExpandLessIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'expand_less'}
    text=""
    customClasses={[css.btn,...(props.customClasses ? props.customClasses : [])]}
    iconPosition={'center'}
    title="Show less information"
    data_testid="expand-less-icon-button"
    style={{ marginLeft: '3px' }}
  />
)

export default ExpandLessIcon;