import React from 'react'
import Selection from './Selection'
import useSelectionDetails from './useSelectionDetails'
import useSummaryLanguage from '../useSummaryLanguage'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import { isNull, isUndefined } from 'lodash'

import css from './SummaryFilters.module.scss'

type NonDynamicMetaSelectionProps = {
  metaKey: string
  metaValues: string[]
  onSelectMeta: (key: string, value: string) => void
}

const NonDynamicMetaSelection = ({
  metaKey,
  metaValues,
  onSelectMeta,
}: NonDynamicMetaSelectionProps) => {
  const { getNumberOfColumns, getSelectionWidth, metaSortPredicate } = useSelectionDetails()
  const [{ language, filterMetas, summarySettings }] = useSummaryFilters()
  const isEmptyMetaValuesEnabled = !!summarySettings.isEmptyMetaValuesEnabled
  const { t } = useSummaryLanguage(language)
  const filteredMetaValues = metaValues.filter(
    (metaValue) =>
      !isNull(metaValue) && !isUndefined(metaValue) && !(isEmptyMetaValuesEnabled && !metaValue),
  )
  return (
    <Selection
      title={t(metaKey)}
      key={metaKey}
      value={
        filterMetas[metaKey] === '' ? '' : filterMetas[metaKey] ? filterMetas[metaKey] : 'Kaikki'
      }
      onSelect={(value: string) => onSelectMeta(metaKey, value)}
      selection={filteredMetaValues.concat(['Kaikki']).sort(metaSortPredicate)}
      classNames={css.timeGroupSelect}
      columns={getNumberOfColumns(filteredMetaValues)}
      width={getSelectionWidth(filteredMetaValues)}
      position='bottomRight'
      testid={metaKey + 'Selection'}
    />
  )
}

export default NonDynamicMetaSelection
