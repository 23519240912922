/* eslint-disable react/display-name */
import React, { memo, useEffect, useState, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official'
import { Options } from 'highcharts8'
import { isEmpty, isEqual, isNil } from 'lodash'

type Props = {
  chartConfiguration: Options
}

// eslint-disable-next-line react/prop-types
const HighchartsGraph = memo<Props>(({ chartConfiguration }) => {
  const [isReady, setIsReady] = useState(true)
  const storedChartConfiguration = useRef(chartConfiguration)

  /*  Needed to make the Highchart context menu events behave correctly.
      Otherwise they wouldn't be update on re-renders.
  */
  useEffect(() => {
    if (!isEqual(storedChartConfiguration.current, chartConfiguration)) {
      setIsReady(false)
      setTimeout(() => {
        setIsReady(true)
      })
    }
    storedChartConfiguration.current = chartConfiguration
  }, [chartConfiguration])

  if (!chartConfiguration || isEmpty(chartConfiguration)) {
    throw new Error(
      'Provide a chart configuration for HighchartsGraph. See "chartService.ts" file.',
    )
  }

  if (!isReady) return <div className='height-400'></div>

  return (
    <HighchartsReact
      highcharts={window.Highcharts8}
      options={chartConfiguration}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(r: any) => {
        setTimeout(() => {
          !!r && !isNil(r.chart) && r.chart.reflow()
        })
      }}
    />
  )
})

export default HighchartsGraph
