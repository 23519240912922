import { SeriesPieOptions } from 'highcharts8'
import { cloneDeep } from 'lodash'
import { FreqFormat, FrequencyPieType, NumericKpiFormat } from '../frequencyModuleTypes'
import useFrequencyUnits from '../useFrequencyUnits'

export type SetDataAction = {
  type: ActionTypes.SET_DATA
  data: {
    series: SeriesPieOptions[]
    categories: string[]
  }
}

export type SetChartHeightAction = {
  type: ActionTypes.SET_HEIGHT
  data: {
    chartHeight: number | string
  }
}

export type SetPieType = {
  type: ActionTypes.SET_PIE_TYPE
  data: {
    type?: FrequencyPieType
  }
}

export type SetChartFormatAction = {
  type: ActionTypes.SET_DATA_FORMAT
  data: {
    chartFormat: FreqFormat | NumericKpiFormat | null
  }
}
export enum ActionTypes {
  SET_DATA = 'SET_DATA',
  SET_HEIGHT = 'SET_HEIGHT',
  SET_PIE_TYPE = 'SET_PIE_TYPE',
  SET_DATA_FORMAT = 'SET_DATA_FORMAT',
}
type ReducerAction = SetDataAction | SetChartHeightAction | SetPieType | SetChartFormatAction

export const pieReducer = (state: Highcharts.Options, action: ReducerAction) => {
  const newState = cloneDeep(state)
  switch (action.type) {
    case ActionTypes.SET_DATA: {
      newState.series = action.data.series
      const yAxis = newState.yAxis
      if (yAxis && !Array.isArray(yAxis)) {
        yAxis.categories = action.data.categories
        newState.yAxis = yAxis
      }
      return newState
    }
    case ActionTypes.SET_PIE_TYPE: {
      const newPieType = action.data.type
      if (!newState.plotOptions) newState.plotOptions = {}
      if (!newState.plotOptions.pie) newState.plotOptions.pie = {}
      if (newPieType === FrequencyPieType.FULL) newState.plotOptions.pie.innerSize = '10%'
      if (newPieType === FrequencyPieType.HOLLOW) newState.plotOptions.pie.innerSize = '74%'
      return newState
    }

    case ActionTypes.SET_HEIGHT: {
      const newState = { ...state }
      if (newState.chart) {
        newState.chart.height = action.data.chartHeight
      }
      return newState
    }

    case ActionTypes.SET_DATA_FORMAT: {
      const currentFormat = action.data.chartFormat
      const { getDataFormatButtonText } = useFrequencyUnits()
      const formatText = getDataFormatButtonText(currentFormat)
      let unit = ''
      if (formatText === '%') unit = '%'
      if (!newState.plotOptions) newState.plotOptions = {}
      if (!newState.plotOptions.pie) newState.plotOptions.pie = {}
      if (!newState.plotOptions.pie.dataLabels) newState.plotOptions.pie.dataLabels = {}
      if (!Array.isArray(newState.plotOptions.pie.dataLabels)) {
        newState.plotOptions.pie.dataLabels.format = `{point.y}${unit}`
      }
      return newState
    }

    default: {
      return newState
    }
  }
}
