import React, { useState, memo, useContext, useEffect, useRef } from 'react'
import CategoryColumn from './OpenModuleCategoryColumn'
import { isEmpty } from 'lodash'
import OpenCategoryToolContext from './OpenCategoryTool/OpenCategoryToolProvider'
import { OpenModuleContext } from './_OpenModuleContext'
import { ToastProvider } from '../../../common/Notification/NotificationContext'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'
import OpenInspectorCntr from '../_shared/ValueInspector/Open/OpenInspectorCntr'
import { TextualKpiData } from './openTypes'

type OpenModuleAnswersTableRowProps = {
  kpiName: string
  answersForThisKpi: TextualKpiData[]
  index: number
  answer: TextualKpiData
  metadataColumns: string[]
  answerMetas: string[] | null
  topics: string[] | null
  doShowSentiments: boolean
  doShowTopics: boolean
  doShowCustomCategories: boolean
  customCategories: { [name: string]: string[] } | null
  onChangeCategories: (isCategorySelectorOpen: boolean) => void
  showTools: boolean
}

type MetadataListColumnProps = {
  answerMetas: string[] | null
  answer: TextualKpiData
}

const OpenModuleAnswersTableRow = memo(function OpenModuleAnswersTableRow({
  answersForThisKpi,
  kpiName,
  index,
  answer,
  metadataColumns,
  answerMetas,
  topics,
  doShowSentiments,
  doShowTopics,
  doShowCustomCategories,
  customCategories,
  onChangeCategories,
  showTools,
}: OpenModuleAnswersTableRowProps) {
  const [isInspectorOpen, setInspectorOpenness] = useState(false)
  const [conversationFromOpenModule, setConversationFromOpenModule] = useState(false)
  const [isOpenCategoryToolOpen, setIsOpenCategoryToolOpen] = useState<boolean>(false)
  const { showInspectorConversation, showCategoryTool, conversationsList } =
    useContext(OpenModuleContext)
  const [unreadMessages, setUnreadMessages] = useState<number>(0)

  const unreadMessagesTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    unreadMessagesTimeout.current = setTimeout(() => {
      let count = 0
      conversationsList.forEach((conversation) => {
        if (conversation.completedSurveyId === answer.completed_survey_id) {
          count += conversation.unreadMessagesCount
        }
      })
      setUnreadMessages(count)
    }, 1)
    return () => unreadMessagesTimeout.current && clearTimeout(unreadMessagesTimeout.current)
  }, [conversationsList])

  const handleInspectorOpenness = (inspectorOpen: boolean) => {
    setInspectorOpenness(inspectorOpen)
    setConversationFromOpenModule(inspectorOpen)
  }

  const handleOpenCategoryToolOpenness = (categoryToolOpen: boolean) => {
    trackingService.track(
      categoryToolOpen ? TrackingEvent.OpenCategoryPopup : TrackingEvent.CloseCategoryPopup,
    )
    setIsOpenCategoryToolOpen(categoryToolOpen)
  }

  function getTranslation(answer: TextualKpiData) {
    return answer && answer.translation && answer.translation.translation
  }

  return (
    <div data-testid={`openModuleAnswerTableRow-${answer.answer_id}`} className='tr answerRow'>
      <div className='tc nowrap-hide-overflow'>{answer.date || 'N/A'}</div>
      <div
        data-testid={`openAnswerText-${answer.answer_id}`}
        className='tc open-answer-text'
        onClick={() => setInspectorOpenness(true)}
      >
        {answer.answer}
        {getTranslation(answer) && (
          <div
            data-testid={`openAnswerTranslation-${answer.answer_id}`}
            className='open-answer-translation'
          >
            <div className='translation-header'>Translation</div>
            <div className='translation-text'>{getTranslation(answer)}</div>
          </div>
        )}
      </div>
      {showTools && (
        <div className='tc tools'>
          {showCategoryTool && (
            <i
              data-testid={`openModuleCategoyTool-${answer.answer_id}`}
              className='material-icons'
              onClick={() => handleOpenCategoryToolOpenness(true)}
            >
              dashboard_customize
            </i>
          )}
          {showInspectorConversation && (
            <div className='conversation-tool'>
              <i className='material-icons' onClick={() => handleInspectorOpenness(true)}>
                chat
              </i>
              {unreadMessages ? (
                <div className='unread-messages'>
                  <i className='material-icons'>email</i>
                  <div className='unread-messages-count'>{unreadMessages}</div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      )}
      {metadataColumns.map((metaKey) => (
        <div key={metaKey} className='tc'>
          {(answer.metadata && answer.metadata[metaKey]) || ''}
        </div>
      ))}
      {doShowCustomCategories &&
        customCategories &&
        Object.entries(customCategories).map(([key, values]) => (
          <CategoryColumn
            key={`${answer.answer_id}-cstm-${key}`}
            answer={answer}
            type='custom'
            customCategory={key}
            customCategoryValues={values}
            onChangeCategories={onChangeCategories}
          />
        ))}
      {doShowSentiments && (
        <CategoryColumn
          key={`${answer.answer_id}-snt`}
          answer={answer}
          type='sentiment'
          onChangeCategories={onChangeCategories}
        />
      )}
      {doShowTopics && (
        <CategoryColumn
          key={`${answer.answer_id}-tpc`}
          answer={answer}
          type='topic'
          topics={topics}
          onChangeCategories={onChangeCategories}
        />
      )}
      {!isEmpty(answerMetas) && <MetadataListColumn answerMetas={answerMetas} answer={answer} />}
      {isOpenCategoryToolOpen && (
        <ToastProvider id={`${answer.id}`}>
          <NotificationContainer id={`${answer.id}`} />
          <OpenCategoryToolContext
            onClose={handleOpenCategoryToolOpenness}
            kpiName={kpiName}
            answersForThisKpi={answersForThisKpi}
            index={index}
            openAnswer={answer}
            topics={topics}
            customCategories={customCategories}
          />
        </ToastProvider>
      )}

      {isInspectorOpen && (
        <OpenInspectorCntr
          answer={answer}
          inspected={answer.answer}
          onClose={() => handleInspectorOpenness(false)}
          conversationFromOpenModule={conversationFromOpenModule}
        />
      )}
    </div>
  )
})

const MetadataListColumn = memo(function MetadataListColumn({
  answerMetas = [],
  answer,
}: MetadataListColumnProps) {
  return (
    <div className='tc'>
      {answerMetas &&
        answerMetas.length > 0 &&
        answerMetas.map(
          (key) =>
            answer.metadata &&
            answer.metadata[key] && (
              <div key={key} className='nowrap-hide-overflow open-metadata-list-column'>
                {answer.metadata[key]}
              </div>
            ),
        )}
    </div>
  )
})

export default OpenModuleAnswersTableRow
