import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
  Tooltip,
} from '@mui/material'
import React, { useState } from 'react'
import Rodal from 'rodal'
import { dedupe } from '../../../../../utils'
import { allChartColors } from '../../../../../styles/variableExport'
import { post } from '../../../../react-services/apiService'
import { toast } from 'react-toastify'
import { Dashboard } from '../../../../stores/useDbTemplateConfig'
import { cloneDeep, isUndefined } from 'lodash'
import { ModuleType } from '../Group/groupModuleTypes'
import { useParams } from 'react-router-dom'
import { FilterArray } from '../../../../react-services/filterService'

import css from './SendReportModal.module.scss'
import { useMountedState } from 'react-use'
import { tCommon } from '../../../../../languages/i18n'
import { Close } from '@mui/icons-material'

enum ReportFormat {
  PNG = 'png',
  PPT = 'ppt',
  PDF = 'pdf',
}
type ReportSendPayload = {
  dashboard_idx: number
  section_idx: number
  dashboard_settings: unknown
  emails: string[]
  format: ReportFormat
}

type SendReportModalProps = {
  onClose: () => void
  currentDashboard: Dashboard
}

const SendReportModal = ({ onClose, currentDashboard }: SendReportModalProps) => {
  const isMounted = useMountedState()
  const [format, setFormat] = useState<ReportFormat>(ReportFormat.PDF)
  const [additionalRecipients, setAdditionalRecipients] = useState<string>('')
  const [invalidEmail, setInvalidEmail] = useState<string>('')
  const [isSendingReport, setIsSendingReport] = useState<boolean>(false)

  const { sectionIdx, dashboardIdx } = useParams()
  const sectionNumber =
    isNaN(Number(sectionIdx)) || isUndefined(sectionIdx) ? undefined : Number(sectionIdx)
  const dashboardNumber =
    isNaN(Number(dashboardIdx)) || isUndefined(dashboardIdx) ? undefined : Number(dashboardIdx)

  function doExportDash(payload: ReportSendPayload | undefined) {
    if (!payload) return
    setIsSendingReport(true)
    const path = 'POST_DASHEXPORT'
    post(path, payload)
      .then(() => {
        if (!isMounted()) return
        toast.success(tCommon('info.reportSent'))
        onClose()
      })
      .catch(() => {
        if (!isMounted()) return
        toast.error('Error generating report.')
      })
      .finally(() => isMounted() && setIsSendingReport(false))
  }

  function getExportDashPayload() {
    const dbSettings = cloneDeep(currentDashboard)
    const groupedTextBoxes = findGroupedTextBoxes(dbSettings)
    if (groupedTextBoxes.length > 0) tryToReplaceDynamicMetas(dbSettings, groupedTextBoxes)

    if (isUndefined(dashboardNumber) || isUndefined(sectionNumber)) {
      toast.error('Error parsing dashboard indexes.')
      return
    }
    const validatedEmails = validateExportEmails(additionalRecipients) || []
    if (invalidEmail !== '') {
      toast.info('Invalid email address.')
      return
    }
    return {
      dashboard_idx: dashboardNumber,
      section_idx: sectionNumber,
      dashboard_settings: cleanUpDashboardSettings(dbSettings),
      emails: validatedEmails,
      format: format,
    }
  }

  function cleanUpDashboardSettings(dbSettings: Dashboard) {
    for (let i = 0; i < dbSettings.modules.length; i++) {
      const module = dbSettings.modules[i]
      if (module.type !== ModuleType.GROUP) continue
      // remove dynamic filters
      if (module.dynamicFilters) delete module.dynamicFilters
      // remove focused filter
      if (module.focusedFilter) delete module.focusedFilter
    }

    return dbSettings
  }

  function findGroupedTextBoxes(dbSettings: Dashboard) {
    const result = []
    for (let i = 0; i < dbSettings.modules.length; i++) {
      const module = dbSettings.modules[i]
      if (module.type === ModuleType.GROUP) {
        if (!module.modules) continue
        for (let j = 0; j < module.modules.length; j++) {
          if (module.modules[j].type === ModuleType.TEXTBOX) {
            result.push({ x: i, y: j })
          }
        }
      }
    }
    return result
  }

  function tryToReplaceDynamicMetas(
    dbSettings: Dashboard,
    textBoxes: {
      x: number
      y: number
    }[],
  ) {
    textBoxes.forEach((indexPair) => {
      const module = dbSettings.modules[indexPair.x]
      if (module.type !== ModuleType.GROUP || !module.modules) return
      const nestedModule = module.modules[indexPair.y]
      if (nestedModule.type !== ModuleType.TEXTBOX) return
      const textBoxText = nestedModule.data
      if (!textBoxText) return
      if (textBoxText && textBoxText.includes('{') && textBoxText.includes('}')) {
        // do a mutation blaaargh
        const filters = module.settings
        if (!filters) return
        nestedModule.data = replaceDynamicMetas(textBoxText, filters)
      }
    })
  }

  function replaceDynamicMetas(text: string, filters: FilterArray) {
    let values = []
    let result = text
    for (let i = 0; i < filters.length; i++) {
      if (text.includes('{' + filters[i].name + '}')) {
        for (let j = 0; j < filters[i].value.length; j++) {
          values.push(filters[i].value[j])
        }
        if (values.length === 1) {
          result = result.replace('{' + filters[i].name + '}', values.toString())
        } else {
          result = result.replace(
            '{' + filters[i].name + '}',
            values.slice(0, -1).join(', ') + ' & ' + values.slice(-1),
          )
        }
        values = []
      }
    }
    return result
  }

  const validateExportEmails = (input: string) => {
    if (!input) return setInvalidEmail('')
    let emails = input.replace(/([,;])/g, '\n').split('\n')
    emails = dedupe(emails.filter((value) => value !== '').map((value) => value.trim()))
    for (let i = 0; i < emails.length; i++) {
      const currentEmail = emails[i]
      if (!validateEmail(currentEmail)) {
        setInvalidEmail(tCommon('error.invalidEmail', { email: currentEmail }))
        break
      } else {
        setInvalidEmail('')
      }
    }
    return emails
  }

  const validateEmail = (email: string) => {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  const handleFormatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormat = e.target.value
    if (
      newFormat !== ReportFormat.PDF &&
      newFormat !== ReportFormat.PNG &&
      newFormat !== ReportFormat.PPT
    )
      return
    setFormat(newFormat)
  }

  const handleAdditionalRecipientsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newAdditionalRecipients = e.currentTarget.value
    validateExportEmails(newAdditionalRecipients)
    setAdditionalRecipients(newAdditionalRecipients)
  }
  return (
    <div className={css.cntr}>
      <Rodal onClose={onClose} closeOnEsc animation='slideUp' visible>
        <div className={css.header}>
          <h5>{tCommon('button.sendReport')}</h5>
          <IconButton onClick={onClose}>
            <Close sx={{ fontSize: '26px', color: '#fff' }} />
          </IconButton>
        </div>
        <div className={css.content}>
          <p>{tCommon('info.sendReport1')}</p>
          <div className='col s12'>
            <FormControl>
              <FormLabel id='file-format-buttons-group-label'>
                {tCommon('label.reportFormat')}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby='file-format-buttons-group-label'
                value={format}
                onChange={handleFormatChange}
                name='file-format-buttons-group'
              >
                <FormControlLabel value={ReportFormat.PDF} control={<Radio />} label='pdf' />
                <Tooltip title={tCommon('info.underMaintenance')}>
                  <FormControlLabel
                    value={ReportFormat.PPT}
                    control={<Radio />}
                    label='powerpoint'
                    disabled
                  />
                </Tooltip>
                <Tooltip title={tCommon('info.underMaintenance')}>
                  <FormControlLabel
                    value={ReportFormat.PNG}
                    control={<Radio />}
                    label='image'
                    disabled
                  />
                </Tooltip>
              </RadioGroup>
            </FormControl>
          </div>
          <p>{tCommon('info.sendReport1')}</p>
          <FormLabel id='recipients-label'>{tCommon('label.additionalRecipients')}</FormLabel>
          <TextField
            id='recipients'
            sx={{ width: '100%', maxHeight: '50vh', overflowY: 'auto' }}
            multiline
            error={!!invalidEmail}
            helperText={invalidEmail}
            minRows={2}
            maxRows={8}
            placeholder={tCommon('info.sendReport3')}
            value={additionalRecipients}
            onChange={handleAdditionalRecipientsChange}
            InputProps={{
              inputComponent: TextareaAutosize,
            }}
          />
          <br />
          <div className='row' style={{ margin: '20px 0' }}>
            <Button
              color='primary'
              variant='contained'
              sx={{ marginRight: '12px' }}
              onClick={() => doExportDash(getExportDashPayload())}
              disabled={isSendingReport || !!invalidEmail}
              endIcon={
                isSendingReport ? (
                  <CircularProgress
                    color='inherit'
                    sx={{ color: '#fff' }}
                    thickness={4}
                    size='1.2rem'
                  />
                ) : null
              }
            >
              {isSendingReport ? `${tCommon('button.sending')}...` : tCommon('button.send')}
            </Button>

            <Button
              color='inherit'
              variant='contained'
              sx={{ color: allChartColors.GREY }}
              onClick={onClose}
            >
              {tCommon('button.cancel')}
            </Button>
          </div>
        </div>
      </Rodal>
    </div>
  )
}

export default SendReportModal
