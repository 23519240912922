import React, { useRef, useState } from 'react'
import { useDeepCompareEffect, useMountedState, useShallowCompareEffect } from 'react-use'
import { Input } from 'react-materialize'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import NoData from '../../../_shared/Infos/NoData'
import FeedbackAlertsTableRow from './FeedbackAlertsTableRow'
import css from './FeedbackAlertsTable.module.scss'
import './FeedbackAlertsTable.scss'
import './CheckboxOverride.scss'
import CustomPagination from '../../../common/Pagination/CustomPagination'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import FeedbackAlertModalContainer from './AlertModal/FeedbackAlertModalContainer'
import { useAlertData, useAlertModalControl } from './FeedbackAlertsTableCntr'
import { AlertsTableFilters, FeedbackAlertPruned } from './alertTypes'
import { FormControl, InputLabel, TextField } from '@mui/material'
import { tCommon } from '../../../../../languages/i18n'

const FeedbackAlertsTable = () => {
  // TODO: better implementation for polling backend
  // const intervalRef = useRef<NodeJS.Timeout | undefined>();

  const initFilters = {
    search: '',
    open: true,
    closed: false,
    inProgress: true,
    newMessages: false,
    all: true,
  }

  const [{ alertConfig, alerts, currentAlertId, conversationsList }] = useAlertData()
  const [alertModalControl] = useAlertModalControl()
  const isMounted = useMountedState()
  const [filters, setFilters] = useState<AlertsTableFilters>(initFilters)
  const [filteredAlerts, setFilteredAlerts] = useState<FeedbackAlertPruned[]>([])
  const [sortedFilteredAlerts, setSortedFilteredAlerts] = useState<FeedbackAlertPruned[]>([])
  const [selectedAlertsSlice, setSelectedAlertsSlice] = useState<FeedbackAlertPruned[]>([])
  const [alertsSliceStart, setAlertsSliceStart] = useState<number | null>(null)
  const [alertsSliceEnd, setAlertsSliceEnd] = useState<number | null>(null)
  const [itemsPerPage, setItemsPerPage] = useState<number>(7)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [timeColumnSortingOrder, setTimeColumnSortingOrder] = useState<string>('descending')

  const filterTimeoutRef = useRef<NodeJS.Timeout>()
  useDeepCompareEffect(() => {
    if (filterTimeoutRef.current) clearTimeout(filterTimeoutRef.current)
    filterTimeoutRef.current = setTimeout(() => {
      if (!isMounted()) return
      if (alerts && alerts.length > 0) {
        const filteredAlertsArray = filterAlerts(alerts)
        setFilteredAlerts(filteredAlertsArray)
      } else {
        const emptyArray = [] as FeedbackAlertPruned[]
        setFilteredAlerts(emptyArray)
      }
    }, 400)
  }, [filters, alerts || {}])

  useDeepCompareEffect(() => {
    if (filteredAlerts.length > 0) {
      const sortedAlerts = sortFilteredAlertsByTime(timeColumnSortingOrder, filteredAlerts)
      setSortedFilteredAlerts(sortedAlerts)
    } else {
      const emptyArray = [] as FeedbackAlertPruned[]
      setSortedFilteredAlerts(emptyArray)
    }
  }, [filteredAlerts, timeColumnSortingOrder])

  useDeepCompareEffect(() => {
    createPageSliceDetails()
  }, [itemsPerPage, currentPage, sortedFilteredAlerts])

  useShallowCompareEffect(() => {
    setCurrentPage(1)
  }, [filters, itemsPerPage])

  const createPageSliceDetails = () => {
    const emptyArray = [] as FeedbackAlertPruned[]
    if (sortedFilteredAlerts.length === 0 || !(currentPage >= 0)) {
      setSelectedAlertsSlice(emptyArray)
      setAlertsSliceStart(null)
      setAlertsSliceEnd(null)
    } else {
      let sliceStartingIndex = 0
      let sliceEndingIndex = itemsPerPage - 1

      if (currentPage !== 1) {
        sliceStartingIndex = (currentPage - 1) * itemsPerPage
        sliceEndingIndex = sliceEndingIndex + itemsPerPage * (currentPage - 1)
      }

      if (sliceEndingIndex >= sortedFilteredAlerts.length) {
        sliceEndingIndex = sortedFilteredAlerts.length - 1
      }

      if (sliceStartingIndex >= sortedFilteredAlerts.length) {
        setSelectedAlertsSlice(emptyArray)
        setAlertsSliceStart(null)
        setAlertsSliceEnd(null)
      } else {
        const filteredAlertsSlice = sortedFilteredAlerts.slice(
          sliceStartingIndex,
          sliceEndingIndex + 1,
        )
        setSelectedAlertsSlice(filteredAlertsSlice)
        setAlertsSliceStart(sliceStartingIndex + 1)
        setAlertsSliceEnd(sliceEndingIndex + 1)
      }
    }
  }
  const filterAlerts = (alertsList: FeedbackAlertPruned[]): FeedbackAlertPruned[] => {
    if (!alertsList) return []
    return alertsList.filter((alert) => {
      const hasUnreadMessages = conversationsList.some(
        (obj) => obj.completedSurveyId === alert.completed_survey_id && obj.unreadMessagesCount > 0,
      )
      if (!filters.closed && alert.state?.toLocaleLowerCase() === 'closed') return false
      if (!filters.inProgress && alert.state?.toLocaleLowerCase() === 'in_progress') return false
      if (!filters.open && alert.state?.toLocaleLowerCase() === 'open') return false
      if (filters.newMessages && !hasUnreadMessages) return false
      if (
        !alert.time?.includes(filters.search) &&
        !alert.recipients?.some((e) => e.toLocaleLowerCase().includes(filters.search)) &&
        !alert.subject?.toLocaleLowerCase().includes(filters.search?.toLocaleLowerCase()) &&
        !alert.comment?.toLocaleLowerCase().includes(filters.search?.toLocaleLowerCase()) &&
        !alert.category?.toLocaleLowerCase().includes(filters.search?.toLocaleLowerCase()) &&
        !alert.subcategory?.toLocaleLowerCase().includes(filters.search?.toLocaleLowerCase())
      )
        return false
      return true
    })
  }

  const handleSortingOrderChange = () => {
    setTimeColumnSortingOrder((prev) => {
      if (prev === 'ascending') {
        return 'descending'
      } else {
        return 'ascending'
      }
    })
  }

  const sortFilteredAlertsByTime = (
    order: string,
    alerts: FeedbackAlertPruned[],
  ): FeedbackAlertPruned[] => {
    return [...alerts].sort((a, b) => {
      if (!a.time && !b.time) return 0
      if (!a.time) return -1
      if (!b.time) return 1

      if (order === 'ascending') {
        if (a.time < b.time) return -1
        if (a.time > b.time) return 1
        return 0
      } else {
        if (a.time < b.time) return 1
        if (a.time > b.time) return -1
        return 0
      }
    })
  }

  const getHeaders = () => (
    <tr>
      <th
        colSpan={1.5}
        className={`${css.col_title_time} ${
          timeColumnSortingOrder === 'descending' ? css.down : css.up
        }`}
        id='alerts-list-column-title-time'
        onClick={() => handleSortingOrderChange()}
      >
        {tCommon('label.date')}&nbsp;&nbsp;
      </th>
      <th colSpan={1.5} onClick={() => ({})}>
        {tCommon('label.state')}
      </th>
      <th colSpan={2} onClick={() => ({})}>
        {tCommon('label.responsible')}
      </th>
      <th colSpan={5} onClick={() => ({})}>
        {tCommon('label.subjectComment')}
      </th>
      <th colSpan={2} onClick={() => ({})}>
        {tCommon('label.actions')}
      </th>
    </tr>
  )

  const getRows = () => {
    const array = [1]
    if (alerts === null) {
      return array.map((val, i) => (
        <tr key={i}>
          <td colSpan={10.5}>
            <LoadingIndicator />
          </td>
        </tr>
      ))
    }
    if (alerts && alerts.length === 0) {
      return array.map((val, i) => (
        <tr key={i}>
          <td colSpan={10.5}>
            <NoData />
          </td>
        </tr>
      ))
    }
    return selectedAlertsSlice.map((alert) => (
      <FeedbackAlertsTableRow key={alert.id} alert={alert} />
    ))
  }

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setFilters((prev) => ({ ...prev, search: value }))
  }

  return (
    <>
      {alertModalControl.isAlertModalOpen && currentAlertId && <FeedbackAlertModalContainer />}
      <div className={css.module_cntr} data-testid='alertsTable'>
        <div className={`${css.filters} ${css.filter_col}`}>
          <FormControl sx={{ width: '300px', marginBottom: '5px' }}>
            <InputLabel id='search-label'>{tCommon('label.search')}</InputLabel>
            <TextField
              onChange={handleSearchInputChange}
              className={css.filter_input}
              id='alerts-list-filter-input'
              label={tCommon('label.search')}
              value={filters.search}
              sx={{ width: '300px' }}
            />
          </FormControl>
          <div className={css.checkboxes}>
            <Input
              className={`filled-in alerts-list-checkbox ${css.checkbox}`}
              type='checkbox'
              label={tCommon('label.open')}
              checked={filters.open}
              onClick={() => setFilters((prev) => ({ ...prev, open: !prev.open }))}
            />
            <Input
              data-cy='alerts-list-inprogress-filter'
              className={`filled-in alerts-list-checkbox ${css.checkbox}`}
              type='checkbox'
              value='green'
              label={tCommon('label.inProgress')}
              checked={filters.inProgress}
              onClick={() => setFilters((prev) => ({ ...prev, inProgress: !prev.inProgress }))}
            />
            <Input
              data-cy='alerts-list-closed-filter'
              className={`filled-in alerts-list-checkbox ${css.checkbox}`}
              id='alerts-list-closed-filter'
              type='checkbox'
              label={tCommon('label.closed')}
              checked={filters.closed}
              onChange={() => setFilters((prev) => ({ ...prev, closed: !prev.closed }))}
            />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            {alertConfig && alertConfig.show_conversation && (
              <>
                <Input
                  data-testid='alerts-list-new-messages-filter'
                  className={`filled-in alerts-list-radio ${css.checkbox}`}
                  type='checkbox'
                  label='New Messages'
                  checked={filters.newMessages}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      newMessages: !prev.newMessages,
                      all: prev.newMessages,
                    }))
                  }
                />
                <Input
                  data-testid='alerts-list-all-filter'
                  className={`filled-in alerts-list-radio  ${css.checkbox}`}
                  type='checkbox'
                  label='All'
                  checked={filters.all}
                  onChange={() =>
                    setFilters((prev) => ({ ...prev, newMessages: prev.all, all: !prev.all }))
                  }
                />
              </>
            )}
          </div>
        </div>

        <div className={css.tbl_cntr}>
          <GenericTable
            useFixedLayout
            hasFixedHeader
            headers={getHeaders()}
            rows={getRows()}
            tableClasses={[css.tbl, 'alert_table']}
          />
        </div>
        <div className={css.pagination_brdr}>
          <CustomPagination
            dataLength={filteredAlerts.length}
            sliceStart={alertsSliceStart}
            sliceEnd={alertsSliceEnd}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </>
  )
}

export default FeedbackAlertsTable
