import React, { useEffect, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import { cloneDeep, isEqual, isUndefined } from 'lodash'
import useNumericKpis from '../../../../../stores/useNumericKpis'
import { ModuleType } from '../../Group/groupModuleTypes'
import { Dashboard } from '../../../../../../types'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

type KpiSelectionTreeDataProps = {
  axis: 'x' | 'y' | 'z'
  customTitle?: string
}
const KpiSelectionTreeData = ({ axis, customTitle }: KpiSelectionTreeDataProps) => {
  const [{ saveModule, module }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  if (module?.type === ModuleType.BUBBLE) bubbleModule = module

  const { numericKpis } = useNumericKpis()
  const settingsName = axis as keyof unknown

  let value: number | undefined
  if (bubbleModule && bubbleModule.query && !isUndefined(bubbleModule.query[settingsName])) {
    value = Number(bubbleModule.query[settingsName])
  }

  useEffect(() => {
    if (!numericKpis) return
    const kpiSelection = [] as kpiIdAndName[]
    if (
      !isUndefined(value) &&
      !isNaN(value) &&
      numericKpis &&
      numericKpis.find((kpi) => kpi.id === value)
    ) {
      kpiSelection.push({
        id: value,
        name: numericKpis.find((kpi) => kpi.id === value)?.name || '',
      })
    }
    setSelectedKpis(kpiSelection)
  }, [numericKpis])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const handleSaveNewSelection = (newSelectionAll: kpiIdAndName[]) => {
    const newSelection = [] as kpiIdAndName[]
    if (typeof value === 'number') {
      const filteredSelectionWithoutPrevSelectedKpi = newSelectionAll.filter(
        (kpi) => kpi.id !== value,
      )
      newSelection.push(...filteredSelectionWithoutPrevSelectedKpi)
    }
    try {
      if (!bubbleModule) return
      const newSelectedKpi = newSelection[0]
      if (isUndefined(newSelectedKpi)) return
      const newValue = isUndefined(newSelectedKpi.id)
        ? newSelectedKpi.name
        : newSelectedKpi.id.toString()
      const newModule = cloneDeep(bubbleModule)
      if (!newModule.query) newModule.query = {} as Dashboard.BubbleModule['query']
      if (!newSelection.length) delete newModule.query[axis]
      else newModule.query[axis] = Number(newValue)
      if (isEqual(newModule, bubbleModule)) return
      saveModule(newModule)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.numeric}
          customTitle={customTitle}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
