import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const DownArrowIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'south'}
    text=""
    customClasses={[css.btn,...(props.customClasses ? props.customClasses : [])]}
    iconPosition={'center'}
    title="Move down"
    data_testid="down-arrow-icon-button"
    style={{ marginLeft: '3px' }}
  />
)

export default DownArrowIcon;