import React, { memo, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import * as Highcharts from 'highcharts8'
import { ExtendedPointOptionsObject, InspectorQuery, SelectedPoint } from '../wheelModuleTypes'
import SunBurst from 'highcharts8/modules/sunburst'
import { ActionTypes, wheelReducer } from './wheelReducer'
import { cloneDeep, isEqual, isNull } from 'lodash'
import { CustomerPathKpi } from '../../../../../stores/useConfigCustomerPath'
import { Query } from '../../../../../../types'
import NumericInspector from '../../_shared/ValueInspector/NumericInspector'
import NoDataToDisplay from 'highcharts8/modules/no-data-to-display'
import { createChart } from './wheelChart'
import FadeIn from '../../_shared/FadeIn'
import { CircularProgress } from '@mui/material'
NoDataToDisplay(Highcharts)
SunBurst(Highcharts)

import css from './Wheel.module.scss'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'
import { reportSVGWidth } from '../../../../../../styles/variableExport'

type WheelProps = {
  categories: CustomerPathKpi[]
  wheelQuery: Query.SingleNumeric
  center: Highcharts.PointOptionsObject[]
  innerCircle: ExtendedPointOptionsObject[]
  outerCircle: ExtendedPointOptionsObject[]
  caption: Highcharts.CaptionOptions | null
  extensionWidth?: number
  isReportOrScreen: boolean
  isRefetching?: boolean
  handleInnerSliceClick:(category: string) => void
}
const Wheel = memo(
  ({
    categories,
    wheelQuery,
    isRefetching,
    center,
    innerCircle,
    outerCircle,
    caption,
    extensionWidth = 50,
    isReportOrScreen,
    handleInnerSliceClick
  }: WheelProps) => {
    const [selectedPoint, setSelectedPoint] = useState<SelectedPoint | null>(null)
    const { i18n } = useTranslation()
    const initOptionsState = createChart(setSelectedPoint,handleInnerSliceClick)
    const CHART_RENDER_TIMEOUT = 500
    const chartUpdateTimeoutRef = useRef<NodeJS.Timeout>()
    const chartComponentRef = useRef<HighchartsReactRefObject>(null)
    const chartCntrRef = useRef<HTMLDivElement | null>(null)
    const [chartOptions, setChartOptions] = useReducer(wheelReducer, initOptionsState)
    const [chartCopy, setChartCopy] = useState<Highcharts.Options | null>(null)
    const [inspectorQuery, setInspectorQuery] = useState<InspectorQuery | null>(null)

    useEffect(() => {
      if (chartComponentRef.current) {
        if (isReportOrScreen) {
          chartComponentRef.current.chart.setSize(
            chartCntrRef.current?.offsetWidth,
            chartCntrRef.current?.offsetHeight,
            false,
          )
        } else {
          chartComponentRef.current.chart.reflow()
        }
      }
    }, [chartCntrRef.current?.offsetWidth, chartCntrRef.current?.offsetWidth])

    useUpdateEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_LANGUAGE,
      })
    }, [i18n.language])

    useEffect(() => {
      if (chartUpdateTimeoutRef.current) clearTimeout(chartUpdateTimeoutRef.current)
      chartUpdateTimeoutRef.current = setTimeout(() => {
        setChartCopy(chartOptions)
      }, CHART_RENDER_TIMEOUT)
      return () => chartUpdateTimeoutRef.current && clearTimeout(chartUpdateTimeoutRef.current)
    }, [chartOptions])

    useEffect(() => {
      if ((innerCircle.length, outerCircle.length)) {
        setChartOptions({
          type: ActionTypes.SET_DATA,
          data: {
            innerCircle: innerCircle.filter((cell) => cell.realValue !== ''),
            outerCircle: outerCircle.filter((cell) => cell.realValue !== ''),
            center,
          },
        })
      }
    }, [innerCircle, innerHeight, center])

    useEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_CAPTION,
        data: {
          caption,
        },
      })
    }, [caption])

    useEffect(() => {
      if (!selectedPoint || !selectedPoint.name || !selectedPoint.level) return
      if (selectedPoint.level === 1) {
        const relatedKpis = categories
          .filter((kpiCategory) => kpiCategory.category === selectedPoint.name)
          .map((kpiCategory) => kpiCategory.kpiid)
          .filter((kpiId): kpiId is number => typeof kpiId === 'number')
        setInspectorQuery({
          start_date: wheelQuery.start_date,
          end_date: wheelQuery.end_date,
          kpis: relatedKpis,
          filter: wheelQuery.where_meta,
        })
      }
      if (selectedPoint.level === 2 && !isNull(selectedPoint.id)) {
        setInspectorQuery({
          start_date: wheelQuery.start_date,
          end_date: wheelQuery.end_date,
          kpis: [selectedPoint.id],
          filter: wheelQuery.where_meta,
        })
      }
    }, [selectedPoint])

    const handleInspectorClose = () => {
      setSelectedPoint(null)
      setInspectorQuery(null)
    }
    const chart = useMemo(() => {
      if (chartCopy) {
        return (
          <HighchartsReact
            highcharts={Highcharts}
            options={cloneDeep(chartCopy)}
            containerProps={{ id: 'wheel-container', style: { height: 'inherit' } }}
            ref={chartComponentRef}
            updateArgs={[true, true, true]}
          />
        )
      }
      return null
    }, [chartCopy])

    return (
      <div
        className={`${css.cntr} wheel-extension`}
        style={{
          width: isReportOrScreen ? reportSVGWidth : `${extensionWidth}%`,
          borderRight:
            isReportOrScreen || extensionWidth === 100 ? 'none' : '1px solid rgb(225, 225, 225)',
        }}
        ref={(newRef) => (chartCntrRef.current = newRef)}
      >
        {inspectorQuery && (
          <NumericInspector
            query={inspectorQuery}
            isPreviewMode={false}
            onClose={handleInspectorClose}
            inspected={selectedPoint?.name || ''}
          />
        )}
        {isRefetching && (
          <div className={css.loadingRefetch}>
            <CircularProgress thickness={2} size={'1.7rem'} style={{ opacity: 0.6 }} />
          </div>
        )}
        {chart ? <FadeIn durationMs={2000}>{chart}</FadeIn> : <div></div>}
      </div>
    )
  },
  arePropsEqual,
)

function arePropsEqual(oldProps: WheelProps, newProps: WheelProps) {
  return isEqual(oldProps, newProps)
}
Wheel.displayName = 'Wheel'
export default Wheel
