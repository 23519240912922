/* eslint-disable @typescript-eslint/no-explicit-any */
import { deburr } from 'lodash';

/** Creates a downloadable CSV blob out of an array of strings.
 *  Always removes diacritics (` ^ ¨ etc) from letter in filenames
 */
function downloadCsv(csv: string[], filename: string): void {
  if (!csv) throw new Error('No CSV given to download');
  if (!Array.isArray(csv)) csv = [csv];

  const csvBlob: Blob = new Blob(csv, {type: 'text/csv'});

  // IE
  if (window.navigator && window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(csvBlob, deburr(filename));
  // Other browsers
  } else {
    createAndDownloadBlob(csvBlob, filename);
  } 
}

function createAndDownloadBlob(csvBlob: Blob, filename: string): void {
  const wUrl: any = window.URL || window.webkitURL;
  const blobUrl: string = wUrl.createObjectURL(csvBlob);
  const a: HTMLElement = document.createElement('a');
  a.setAttribute('href', blobUrl)
  a.setAttribute('download', deburr(filename))
  document.body.appendChild(a);
  a.click()
  // cleanup
  setTimeout(() => {
    document.body.removeChild(a);
    wUrl.revokeObjectURL(blobUrl);
  }, 1000);
}


export {
  downloadCsv,
  createAndDownloadBlob
}