import { KpiData, Query, Data, Dashboard, KpiNamesXYZ } from '../../types'
import { deepCopy } from '../../utils'
import { moduleTypes } from '../react-constants/moduleTypes'
import { isEmpty, isNil, merge } from 'lodash'
import {
  AxisLabelsFormatterContextObject,
  ExtendedPoint,
  PointXYZ,
  SeriesXYZ,
  ExtendedOptionsPointClickEventObject,
} from '../../types/highcharts'
import {
  startAndEndDatesOfWeekInYear,
  startAndEndDatesOfQuarterInYear,
  startAndEndDatesOfMonthInYear,
  startAndEndDatesOfYear,
  listOfDaysInBetween,
  toTruncatedFormat,
} from './datesService'
import ReactDOM from 'react-dom'
import React from 'react'
import ValueInspector from '../components/Dashboards/DashboardModules/_shared/ValueInspector/ValueInspector'
import { MODULECONSTANTS } from '../react-constants/modules'
import { isFirstDayOfMonth, isToday, isValid as isValidDate } from 'date-fns'

const TOOLTIP_FONT_SIZE = '1rem'
const IMAGE_REPORT_FONT_SIZE = '16px'
const IMAGE_REPORT_FONT_SIZE_BIG = '20px'

function getLineGraphConfiguration(
  module: Dashboard.LineModule,
  kpiData: KpiData.NumericData,
  dataFormat: Data.Format,
  chartThemeKey: string,
  query: Query.Numeric,
): Highcharts.Options {
  const result: Partial<Highcharts.Options> = {
    legend: {
      enabled: true,
      padding: 0,
      itemMarginTop: 2,
      itemMarginBottom: 6,
      maxHeight: 90,
      align: 'left',
      verticalAlign: 'top',
    },
    lang: {
      noData: 'Please, select data to display',
    },
    exporting: {
      sourceWidth: 1800,
      sourceHeight: 675,
      scale: 1.5,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
    },
    title: {
      align: 'left',
    },
    subtitle: undefined,
    yAxis: {
      gridLineWidth: 0,
      title: undefined,
      ...module.limitValues,
    },
    xAxis: {
      title: {
        text: null,
      },
      categories: kpiData.categories,
      showEmpty: true,
      gridLineWidth: 0,
    },
    credits: {
      enabled: false,
    },
    series: formatLineChartSeries(kpiData.series, dataFormat, query),
    tooltip: getTooltipFormat(moduleTypes.LINE, dataFormat),
  }

  if (hasCustomStyles(chartThemeKey)) {
    merge(result, getCustomLineChartStyle(chartThemeKey))
  }

  return result
}

function getBubbleGraphConfiguration(
  kpiData: KpiData.XYZData,
  kpiNamesXYZ?: KpiNamesXYZ,
  module?: Dashboard.BubbleModule,
): Highcharts.Options {
  const categories = getCategories(kpiData)
  const todayIndex = categories.findIndex((cat) => isToday(new Date(cat)))

  const result: Partial<Highcharts.Options> = {
    // TODO: remove hard coded coloring
    colors: MODULECONSTANTS.SERIES_COLORS_TRAFFICLIGHTS.concat(MODULECONSTANTS.SERIES_COLORS2),
    chart: {
      type: 'bubble',
      plotBorderWidth: 0,
      zooming: {
        type: 'xy',
      },
      spacingLeft: 1,
      spacingRight: 1,
    },
    legend: {
      maxHeight: 90,
      itemMarginTop: 4,
      itemMarginBottom: 0,
      bubbleLegend: {
        enabled: isEmpty(kpiData.series) ? true : false,
      },
    },
    lang: {
      noData: 'Please, select data to display',
    },
    exporting: {
      sourceWidth: 1800,
      sourceHeight: 675,
      scale: 1.5,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
    },
    title: {
      align: 'left',
    },
    subtitle: undefined,
    yAxis: {
      title: {
        text: '',
      },
      min: module?.options?.y?.limits?.min ?? 0,
      max: module?.options?.y?.limits?.max ?? 100,
      labels: {
        formatter: function () {
          return `${this.value.toString()} ${module?.options?.y?.unit ?? ''}`
        },
      },
      plotLines: module?.options?.y?.plotLines?.map((pl) => ({
        dashStyle: 'LongDash',
        value: pl.value,
        label: { x: -5, align: 'right', text: pl.label ?? '' },
      })),
    },
    xAxis: {
      title: {
        text: null,
      },
      max: categories.length - 1,
      min: 0,
      lineWidth: 0,
      gridLineWidth: 0,
      tickWidth: 0,
      categories,
      labels: {
        step: hasKpiXAxis() ? undefined : 1,
        rotation: 0,
        align: 'left',
        style: {
          fontSize: getScaledXAxisFontSize(categories?.length ?? 0),
        },
        formatter: function () {
          return formatBubbleXAxis(this)
        },
      },
      plotLines:
        todayIndex >= 0 && !isNil(module?.options)
          ? // X axis plot line for "today" doesn't display a label
            module?.options.x?.plotLines?.map((pl) =>
              pl.label
                ? { dashStyle: 'LongDash', value: todayIndex, label: { y: -5, text: '' } }
                : {},
            )
          : [],
    },
    plotOptions: {
      bubble: {
        maxSize: 25,
        stickyTracking: false,
      },
    },
    credits: {
      enabled: false,
    },
    series: formatBubbleChartSeries(kpiData, kpiNamesXYZ),
    tooltip: getTooltipFormat(moduleTypes.BUBBLE, undefined, module),
  }

  return result

  function formatBubbleXAxis(axisLabelContext: AxisLabelsFormatterContextObject): string {
    const value: string = axisLabelContext.value.toString()

    if (isInvalidAxisPosition(axisLabelContext.pos, categories?.length ?? null)) {
      return ''
    }

    if (hasKpiXAxis(kpiNamesXYZ)) {
      // this used to return value as date -> in the future this should return the corresponding x axis values
      return ''
    }

    if (!isValidDate(new Date(value))) {
      return toTruncatedFormat(value)
    }

    return getBubbleXAxisDate()

    function getBubbleXAxisDate() {
      const asDate: Date = new Date(value)

      if (axisLabelContext.isFirst) {
        return toTruncatedFormat(asDate)
      } else {
        if (isFirstDayOfMonth(asDate)) {
          return toTruncatedFormat(asDate)
        } else {
          return ''
        }
      }
    }
  }
}

/**
 * This is just a heuristic that seems to work well enough.
 * Calculations use integers so we can avoid hassle with floating point numbers.
 */
function getScaledXAxisFontSize(axisLabelCount: number) {
  const initialScale = 10
  let reduction: number = Math.floor(axisLabelCount / 300)
  if (reduction > 3) {
    reduction = 3
  }
  return `${(initialScale - reduction) / initialScale}em`
}

/**
 * There is something going on in Highcharts that causes "overflowing" axis points
 * to occur with large datasets. This is just a workaround for that.
 */
function isInvalidAxisPosition(position: number, axisLabelCount: number | null) {
  if (position < 0) {
    return true
  }

  // eslint-disable-next-line eqeqeq
  if (axisLabelCount != null && position >= axisLabelCount) {
    return true
  }

  return false
}

function hasKpiXAxis(kpiNamesXYZ?: KpiNamesXYZ) {
  return !isNil(kpiNamesXYZ?.x ?? null)
}

function hasCustomStyles(chartThemeKey: string) {
  return chartThemeKey && chartThemeKey !== 'default'
}

function formatLineChartSeries(
  seriesOptions: KpiData.NumericKpiData[],
  dataFormat: Data.Format,
  query: Query.Numeric,
): Highcharts.SeriesLineOptions[] {
  let result: Highcharts.SeriesLineOptions[] = []

  if (isEmpty(seriesOptions)) {
    result.push({
      name: 'No data to display',
      type: moduleTypes.LINE as 'line',
    })
  } else {
    result = constructLineChartSeriesOptions(seriesOptions, dataFormat, query)
  }

  return result
}

function getCategories(kpiData: KpiData.XYZData): string[] {
  let categories: string[] = []
  if (kpiData.range) {
    categories = listOfDaysInBetween(
      kpiData.range.startDate ?? kpiData.range.start_date,
      kpiData.range.endDate ?? kpiData.range.end_date,
    )
  }
  return categories
}

function formatBubbleChartSeries(
  kpiData: KpiData.XYZData,
  kpiNamesXYZ?: KpiNamesXYZ,
): Highcharts.SeriesBubbleOptions[] {
  let result: Highcharts.SeriesBubbleOptions[] = []
  const series: KpiData.XYZSeries[] = kpiData.series
  if (isEmpty(series)) {
    result.push({
      name: 'No data',
      type: 'bubble',
    })
  } else {
    result = series.map((series) => {
      return {
        name: series.seriesBy ? series.seriesBy : undefined,
        type: 'bubble',
        data: series.data,
        axisNames: kpiNamesXYZ,
      }
    })
  }
  return result
}

function constructLineChartSeriesOptions(
  seriesOptions: KpiData.NumericKpiData[],
  dataFormat: Data.Format,
  query: Query.Numeric,
) {
  return seriesOptions.map((series) => {
    return {
      ...deepCopy(series),
      dashStyle: series.is_trend ? 'ShortDot' : undefined,
      name: !series.data || isEmpty(series.data) ? 'No data' : series.name,
      type: 'line',
      data: series.data.map((dataSet) => {
        if (dataSet?.data) {
          const format = dataSet.data[dataFormat]
          // eslint-disable-next-line eqeqeq
          if (format != null) {
            dataSet.y = parseFloat(format.toString())
            dataSet.value = parseFloat(format.toString())
          }
        }
        return dataSet
      }),
      point: {
        events: !series.is_trend && {
          click: function (event: ExtendedOptionsPointClickEventObject) {
            prepareLineChartInspector(event, dataFormat, query)
          },
        },
      },
    } as unknown as Highcharts.SeriesLineOptions
  })
}

function prepareLineChartInspector(
  event: ExtendedOptionsPointClickEventObject,
  dataFormat: string,
  query: Query.Numeric,
) {
  const timeResCategory: string = event.point.category.toString()
  const value = event.point.options.data[dataFormat]
  const kpiId = event.point.options.data.id
  const kpiName: string = event.point.series.name
  const inspectorQueryObject: Query.ExportV3 = {
    ...figureOutStartAndEndDatesForInspector(timeResCategory.toString(), query.time_res),
    filter: query.where_meta,
    kpis: [kpiId],
  }
  const inspectedValueText = `Value ${value} (${dataFormat}) on ${timeResCategory} for '${kpiName}'.`

  spawnInspectTooltip(event, inspectedValueText, inspectorQueryObject)
}

function getCustomLineChartStyle(chartThemeKey: string): Partial<Highcharts.Options> {
  switch (chartThemeKey) {
    case 'report':
      return {
        colors: MODULECONSTANTS.SERIES_COLORS_REPORT,
        exporting: {
          enabled: false,
        },
        plotOptions: {
          line: {
            lineWidth: 4,
            dataLabels: {
              enabled: false,
            },
            marker: {
              enabled: true,
              radius: 2.5,
            },
          },
          heatmap: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'Dot',
          title: {
            text: null,
          },
          labels: {
            style: {
              fontSize: IMAGE_REPORT_FONT_SIZE,
            },
          },
        },
        legend: {
          verticalAlign: 'top',
          align: 'left',
          itemStyle: {
            fontSize: IMAGE_REPORT_FONT_SIZE_BIG,
          },
        },
      }
    case 'screen':
      return {
        exporting: {
          enabled: false,
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: false,
            },
            marker: {
              enabled: true,
              radius: 2.5,
            },
          },
          heatmap: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'Dot',
          title: {
            text: null,
          },
        },
      }
    case 'bs_basic':
      return {
        colors: ['#FF0085', '#C0FF7F', '#00ADF3'],
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'Dot',
        },
      }
    case 'bs_scenario':
      return {
        colors: ['#eee'],
        yAxis: {
          lineWidth: 1.5,
          lineColor: 'rgba(10,10,10,0.35)',
          gridLineColor: 'rgba(20,20,20,0.35)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'Dot',
        },
        legend: {
          enabled: false,
        },
      }
    case 'bs_brand_strength':
      return {
        colors: [
          '#00FFE0',
          '#C0FF7F',
          '#FF0086',
          '#00ADF3',
          '#BCBEC0',
          '#FF8F00',
          '#7E54FF',
          '#B07B4A',
          '#E100FF',
        ],
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'Dot',
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              y: 30,
            },
            marker: {
              enabled: true,
              // Empty base64 symbol (space)
              symbol: 'url(data:image/png;base64,IA==)',
            },
          },
        },
      }
    case 'bs_area_spline':
      return {
        chart: {
          type: 'area',
        },
        colors: ['#FF0585', '#FF4400', '#FFAF22'],
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'Dot',
        },
        plotOptions: {
          series: {
            connectNulls: true,
            stacking: 'normal',
            marker: {
              radius: 1,
            },
          },
        },
      }
    default:
      return {}
  }
}

function figureOutStartAndEndDatesForInspector(
  timeResCategory: string,
  queryTimeRes: Query.TimeRes,
) {
  // eslint-disable-next-line prefer-const
  let [seriesByUnit, year] = timeResCategory.split('/')
  if (isNil(year)) {
    year = seriesByUnit
  }

  switch (queryTimeRes) {
    case 'day':
      return {
        start_date: seriesByUnit,
        end_date: seriesByUnit,
      }
    case 'week':
      return startAndEndDatesOfWeekInYear(year, seriesByUnit)
    case 'month':
      return startAndEndDatesOfMonthInYear(year, seriesByUnit)
    case 'quarter':
      return startAndEndDatesOfQuarterInYear(year, seriesByUnit)
    case 'year':
      return startAndEndDatesOfYear(year)
    default:
      throw new Error('Invalid time res given.')
  }
}

function spawnInspectTooltip(
  event: Highcharts.PointClickEventObject,
  inspectedValue: string,
  query: Query.ExportV3,
) {
  const inspectorId = 'highchartsInspectorTooltipContainer'
  let element = document.getElementById(inspectorId)
  if (!element) {
    element = document.createElement('DIV')
    element.style.position = 'absolute'
    element.id = inspectorId
  }

  element.style.left = event.pageX - 2 + 'px'
  element.style.top = event.pageY - 2 + 'px'
  element.style.visibility = 'visible'
  document.body.appendChild(element)
  renderInspectorTooltipComponent(element, inspectedValue, query)
}

function getTooltipFormat(
  type: string,
  dataFormat?: Data.Format,
  module?: Dashboard.DashboardModule<Query.Query2Payload>,
) {
  const tooltip: Highcharts.TooltipOptions = {
    useHTML: true,
    style: {
      padding: '0',
      fontSize: TOOLTIP_FONT_SIZE,
    },
    hideDelay: 100,
    formatter: function () {
      switch (type) {
        case moduleTypes.LINE:
          return surroundElementWithTooltipContainer(
            buildCustomHighchartsTooltipFormatter(
              this.point as ExtendedPoint,
              this.series,
              dataFormat,
            ),
          )
        case moduleTypes.BUBBLE:
          return surroundElementWithTooltipContainer(
            buildCustomHighchartsTooltipFormatterXYZ(
              this.point as PointXYZ,
              this.series as SeriesXYZ,
              module,
            ),
          )
        default:
          throw new Error(`Tooltip formatter not implemented for type: ${type}`)
      }
    },
  }

  return tooltip
}

function surroundElementWithTooltipContainer(element: string): string {
  return `<div class="highcharts-react-tooltip-container">${element}</div>`
}

/**
 *  Check chartoptions.js for the "legacy" version of this formatter.
 *  It contains formats for different types of modules.
 *  Implement those when they are relevant.
 */
function buildCustomHighchartsTooltipFormatter(
  highchartsDataPoint: ExtendedPoint,
  highchartsSeries: Highcharts.Series,
  dataFormatInput?: Data.Format,
): string {
  const dataFormat: Data.Format = dataFormatInput ? dataFormatInput : 'avg'
  const dataValue: string = highchartsDataPoint.data[dataFormat].toString()
  const nValue: number =
    dataFormat.toString() === 'rollingAvg'
      ? highchartsDataPoint.data['rollingAvgN']
      : highchartsDataPoint.data['n']
  const kpiName: string = highchartsSeries.name

  return `
      ${getTooltipHeader(highchartsDataPoint.category.toString())}
      ${getTooltipMiddle(highchartsDataPoint.color, kpiName)}
      ${getTooltipFooter(dataFormat, dataValue, nValue)}
  `
}

function buildCustomHighchartsTooltipFormatterXYZ(
  highchartsDataPoint: PointXYZ,
  highchartsSeries: SeriesXYZ,
  module?: Dashboard.DashboardModule<Query.Query2Payload>,
): string {
  return `
    ${
      highchartsSeries.xAxis.categories
        ? getTooltipHeader(highchartsDataPoint.category.toString())
        : ''
    }
    ${getTooltipMiddle(highchartsDataPoint.color, highchartsSeries.options.name)}

    <table>
      ${
        !highchartsSeries.xAxis.categories
          ? `<tr><th>${getAxisNameX(highchartsSeries)}</th><th>${highchartsDataPoint.x}</th></tr>`
          : ''
      }
      <tr><th>${getAxisNameY(highchartsSeries)}</th><th>${highchartsDataPoint.y} ${
    module?.options?.y?.unit ?? ''
  }</th></tr>
      <tr><th>${getAxisNameZ(highchartsSeries)}</th><th>${highchartsDataPoint.z} ${
    module?.options?.z?.unit ?? ''
  }</th></tr>

      ${buildMetadataTableRows(highchartsDataPoint, module)}
    </table>
`
}

function getAxisNameX(highchartsSeries: SeriesXYZ): string {
  let name = 'x'
  if (
    highchartsSeries &&
    highchartsSeries.userOptions &&
    highchartsSeries.userOptions.axisNames &&
    highchartsSeries.userOptions.axisNames.x
  ) {
    name = highchartsSeries.userOptions.axisNames.x
  }
  return name
}

function getAxisNameY(highchartsSeries: SeriesXYZ): string {
  let name = 'y'
  if (
    highchartsSeries &&
    highchartsSeries.userOptions &&
    highchartsSeries.userOptions.axisNames &&
    highchartsSeries.userOptions.axisNames.y
  ) {
    name = highchartsSeries.userOptions.axisNames.y
  }
  return name
}

function getAxisNameZ(highchartsSeries: SeriesXYZ): string {
  let name = 'z'
  if (
    highchartsSeries &&
    highchartsSeries.userOptions &&
    highchartsSeries.userOptions.axisNames &&
    highchartsSeries.userOptions.axisNames.z
  ) {
    name = highchartsSeries.userOptions.axisNames.z
  }
  return name
}

function buildMetadataTableRows(
  highchartsDataPoint: PointXYZ,
  module?: Dashboard.DashboardModule<Query.Query2Payload>,
): string {
  let metadataTableRows = ''
  if (highchartsDataPoint.metadata && module && module.tooltipMetas) {
    for (const key of module.tooltipMetas) {
      const value: string = highchartsDataPoint.metadata[key]
        ? highchartsDataPoint.metadata[key]
        : ''
      metadataTableRows += `<tr><td>${key}:</td><td>${value}</td></tr>`
    }
  }
  return metadataTableRows
}

function getTooltipHeader(headerText: string) {
  if (!headerText) return ''

  return `<span style="font-size: 10px">${headerText}</span></br>`
}

function getTooltipMiddle(
  color:
    | Highcharts.ColorString
    | Highcharts.GradientColorObject
    | Highcharts.PatternObject
    | undefined,
  kpiName: string | undefined,
) {
  return kpiName ? `<span style="color: ${color}">\u25CF</span> ${kpiName}<br />` : ''
}

function getTooltipFooter(dataFormat: Data.Format, dataValue: string, n: number) {
  const hasNSuffix: boolean = dataFormat !== 'n'

  return `
    <table>
      <tr>
        <td>${dataFormat.toString() === 'rollingAvg' ? 'rolling avg' : dataFormat}</td>
        <td><b>${dataValue}</b></td>
        ${hasNSuffix ? getNSuffix(n) : ''}
      </tr>
    </table>
  `
}

function getNSuffix(n: number) {
  return `
    <td>n</td>
    <td id="tooltip-n"><b>${n}</b></td>
  `
}

function renderInspectorTooltipComponent(
  inspectorElement: HTMLElement,
  valueToInspect: string,
  query: Query.ExportV3,
) {
  if (inspectorElement) {
    ReactDOM.render(
      <ValueInspector
        valueType={'numeric'}
        inspected={valueToInspect}
        kpis={query.kpis}
        startDate={query.start_date}
        endDate={query.end_date}
        filters={query.filter}
        onClose={() => {
          ReactDOM.unmountComponentAtNode(inspectorElement)
        }}
      />,
      inspectorElement,
    )
  }
}

export {
  getLineGraphConfiguration,
  getBubbleGraphConfiguration,
  figureOutStartAndEndDatesForInspector,
}
