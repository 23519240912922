import React, { useEffect, useRef, useState } from 'react'
import { useMountedState } from 'react-use'

const useCntrWidthObserver = (cntrRef: React.MutableRefObject<HTMLDivElement | undefined>) => {
  const moduleRef = cntrRef.current
  const isMounted = useMountedState()
  const [cntrWidth, setCntrWidth] = useState<number | null>(cntrRef.current?.clientWidth || null)
  const widthSetTimeout = useRef<NodeJS.Timeout>()
  const WIDTH_SET_TIMEOUT = 1000

  useEffect(() => {
    if (!moduleRef) return
    const observer = new ResizeObserver((entries) => {
      if (widthSetTimeout.current) clearTimeout(widthSetTimeout.current)
      widthSetTimeout.current = setTimeout(() => {
        if (!isMounted()) return
        setCntrWidth(Math.round(entries[0].contentRect.width))
      }, WIDTH_SET_TIMEOUT)
    })
    observer.observe(moduleRef)
    return () => {
      if (moduleRef) observer.unobserve(moduleRef)
      if (widthSetTimeout.current) clearTimeout(widthSetTimeout.current)
    }
  }, [cntrRef.current])

  return { cntrWidth }
}

export default useCntrWidthObserver
