/*  Lists all used endpoints

    Follows Swagger (https://testapi.wheelq.com/index.html) form with groupings and dynamic values.

    Adhere to this form when adding an endpoint (even if different methods use the same endpoint):
    >> METHOD_ANY_NAME_YOU_WANT: APIROOT + /path/to/endpoint

    Dynamic values are to be included inside curly brackets:
    >> /path/with/id/{some-id}

    Stick to the naming conventions for consistency.
*/

const LOCALHOST = 'http://localhost:5555/api'
const LOCAL_IP = `http://${process.env.REACT_APP_IP}:5555/api`
const TEST_API = 'https://testapi.wheelq.com/api'
const PROD_API = 'https://frontapi.wheelq.com/api'
const STAGING_API = 'https://stagingapi.wheelq.com/api'

const APIROOT: string = (function determineEnv(): string {
  return determineApiRoot()
})()

function determineApiRoot() {
  if (process.env.REACT_APP_IP) return LOCAL_IP
  else if (process.env.REACT_APP_LOCAL) return LOCALHOST
  else if (process.env.REACT_APP_SMOKE) return TEST_API
  else if (process.env.REACT_APP_STAGING) return STAGING_API
  else if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_START_PROD)
    return PROD_API
  else return TEST_API
}

const APPROOT: string | null = (function determineEnv(): string | null {
  try {
    return (window.location.origin || window.location.protocol + '//' + window.location.host) + '/'
  } catch (e) {
    if (e instanceof ReferenceError) {
      console.warn('Tried to check app root from a non-browser context:', e)
      return null
    } else {
      throw e
    }
  }
})()

/**
 * Note that some endpoints are also hardcoded into AngularJS implementations.
 * If you modify an endpoint here and nothing changes, that's probably the case.
 *
 * Those endpoints should still be listed here and thus are seemingly "not used".
 * DO NOT delete any endpoints from this list even if they seem unused unless you know what you are doing.
 */
const ENDPOINTS: { [name: string]: string } = {
  // Admin
  GET_USERLIST: `${APIROOT}/admin/user`,
  POST_INVITEUSER: `${APIROOT}/admin/user/invite`,
  DELETE_USER: `${APIROOT}/admin/user/{user-id}`,
  POST_EDITUSER: `${APIROOT}/admin/user/edit_configuration`,
  GET_USERLISTJAVA: `${APIROOT}/admin/userJava`,
  GET_USERSWITHCONFS: `${APIROOT}/admin/user/config`,
  GET_ALL_KPIS: `${APIROOT}/admin/kpi/list`,

  // Alert
  GET_LIMITED_ACCESS_TOKEN: `${APIROOT}/limited_access/verify?token={token}`,
  GET_LIMITED_ACCESS_ALERT: `${APIROOT}/limited_access/alert`,
  GET_ALERT_CONFIGS: `${APIROOT}/alert/configs`,
  GET_ALL_ALERTS: `${APIROOT}/alert/querylist`,
  POST_LIMITED_ACCESS_ALERT: `${APIROOT}/limited_access/alert`,
  POST_ALERTS: `${APIROOT}/alert/query`,
  POST_ALERT_CONFIGS: `${APIROOT}/alert/configs`,
  POST_CATEGORIZED_ALERTS: `${APIROOT}/alert/querycat`,
  POST_ALERT_AVGS: `${APIROOT}/alert/queryavg`,
  POST_ALERT_ASSIGNMENT: `${APIROOT}/alert/assign`,
  POST_SAVE_ALERT: `${APIROOT}/alert`,
  PUT_ALERT: `${APIROOT}/alert/{id}`,
  GET_ALERT: `${APIROOT}/alert/{id}`,
  POST_ALERT_MESSAGE: `${APIROOT}/alert/{id}/conversation`,

  // Config
  GET_GENERIC_CONFIG: `${APIROOT}/config/generic/{name}`,
  PUT_TENANT_CONFIG: `${APIROOT}/config/tenant/{name}`,
  GET_REPORTINGFILTERS: `${APIROOT}/config/reportingfilters`,
  POST_DYNAMICFILTERS: `${APIROOT}/config/reportingfiltersdynamic?expanded={is-expanded}`,
  GET_DASHBOARD_SETTINGS: `${APIROOT}/config/dashboardsettings`,
  POST_DASHBOARD_SETTINGS: `${APIROOT}/config/dashboardsettings`,
  GET_GROUPS: `${APIROOT}/config/tenant/user_groups`,
  PUT_GROUP: `${APIROOT}/config/tenant/user_groups`,
  DELETE_GROUP: `${APIROOT}/config/tenant/user_groups`,
  GET_TASKS_TENANT: `${APIROOT}/config/tenant/tasks`,
  PUT_TASKS_TENANT: `${APIROOT}/config/tenant/tasks`,
  PUT_REPORTS: `${APIROOT}/config/tenant/image_reports`,
  GET_USERDBSETTINGS: `${APIROOT}/config/user/{user-id}/dbsettings`,
  PUT_USERDBSETTINGS: `${APIROOT}/config/user/{user-id}/dbsettings`,
  DELETE_USERDBSETTINGS: `${APIROOT}/config/user/{user-id}/dbsettings`,
  GET_ACCESSRIGHTS: `${APIROOT}/config/user/{user-id}/filters`,
  PUT_ACCESSRIGHTS: `${APIROOT}/config/user/{user-id}/filters`,
  DELETE_ACCESSRIGHTS: `${APIROOT}/config/user/{user-id}/filters`,
  GET_USERDEFAULTFILTERS: `${APIROOT}/config/user/{user-id}/defaultfilters`,
  PUT_USERDEFAULTFILTERS: `${APIROOT}/config/user/{user-id}/defaultfilters`,
  DELETE_USERDEFAULTFILTERS: `${APIROOT}/config/user/{user-id}/defaultfilters`,
  GET_USERGROUPS: `${APIROOT}/config/user/{user-id}/usergroups`,
  GET_DBTEMPLATES: `${APIROOT}/config/generic/dbtemplate`,
  GET_SURVEYINVITATION: `${APIROOT}/config/generic/survey_invitation`,
  GET_COMMONDBSETTINGS: `${APIROOT}/config/generic/commondbsettings`,
  PUT_COMMONDBSETTINGS: `${APIROOT}/config/tenant/commondbsettings`,
  GET_DBTEMPLATE: `${APIROOT}/config/generic/dbtemplate`,
  PUT_DBTEMPLATE: `${APIROOT}/config/tenant/dbtemplate`,
  GET_CUSTOMERPATH: `${APIROOT}/config/generic/customerpath`,
  GET_ORGANIZATION: `${APIROOT}/config/generic/organization`,
  GET_TRANSLATIONS: `${APIROOT}/config/generic/translations`,
  PUT_TRANSLATIONS: `${APIROOT}/config/tenant/translations`,
  GET_DEFAULTFILTERS: `${APIROOT}/config/generic/defaultfilters`,
  GET_DBSETTINGS: `${APIROOT}/config/generic/dbsettings`,
  GET_TASKS: `${APIROOT}/config/generic/tasks`,
  GET_USERS: `${APIROOT}/config/users`,
  GET_REPORTS: `${APIROOT}/config/generic/image_reports`,

  // CRM
  GET_ACTIVEINTEGRATIONS: `${APIROOT}/crm/activated`,
  GET_CRMAUTHLINK: `${APIROOT}/crm/{crm}/authorization-url`,

  // Customer path
  POST_OPEN_CATEGORIES: `${APIROOT}/customerpath/openquestions/answers/{answer-id}/category`,

  // Contact
  GET_CONTACTLIST: `${APIROOT}/contact`,
  POST_NEWCONTACT: `${APIROOT}/contact`,
  DELETE_CONTACT: `${APIROOT}/contact/{contact-id}`,

  // Emailreports
  POST_DASHEXPORT: `${APIROOT}/emailreport/export_dash`,

  // Export
  POST_ADMINEXPORT: `${APIROOT}/export/v3`,
  POST_ADMINEXPORT_EXCEL: `${APIROOT}/export/v3?excel=true`,
  POST_ADMINEXPORWITHHIDDEN: `${APIROOT}/export/v3?useHiddenFilters=true`,

  // KPIs
  GET_OPEN_KPIS: `${APIROOT}/kpi/freq/open`,
  GET_NUMERIC_KPIS: `${APIROOT}/kpi/numeric/list`,
  GET_METAS: `${APIROOT}/kpi/meta`,
  GET_META_KEYS: `${APIROOT}/kpi/meta/key`,
  POST_NUMERIC_QUERY_OLD: `${APIROOT}/kpi/numeric/query`, // use kpi/query/numeric instead
  POST_NUMERIC_DIFFS: `${APIROOT}/kpi/numeric/querydiffs{version}`,

  // Log
  POST_LOG: `${APIROOT}/log`,

  // Login
  GET_REFRESHED_TOKEN: `${APIROOT}/refreshtoken`,
  GET_REFRESHED_SYSTOKEN: `${APIROOT}/refreshsystoken`,
  POST_MFA_PIN: `${APIROOT}/limited_access/verifypin`,
  POST_RESET_PASSWORD: `${APIROOT}/passwordreset/send`,
  POST_AD_LOGIN: `${APIROOT}/auth/ms-ad`,
  POST_LOGIN: `${APIROOT}/{tenant}/login`,
  POST_SYS_LOGIN: `${APIROOT}/{tenant}/sys-login`,

  // Password reset
  GET_VERIFYTOKEN: `${APIROOT}/passwordreset/verify?token={token}`,
  POST_RESETPASSWORD: `${APIROOT}/passwordreset/reset`,

  // Public
  GET_PING: `${APIROOT}/public/ping`,
  GET_UNSUBSCRIBE: `${APIROOT}/public/contactattempt/{hashId}/unsubscribe`,

  // QueryKPIs
  POST_TEXTUAL: `${APIROOT}/kpi/query/textual`,
  POST_NUMERIC: `${APIROOT}/kpi/query/numeric`,
  POST_NUMERIC_XYZ: `${APIROOT}/kpi/query/numeric/xyz`,
  POST_ANSWERS_XYZ: `${APIROOT}/kpi/query/answers/xyz`,
  POST_META_FREQS: `${APIROOT}/kpi/query/frequencies/meta`,
  POST_NUMERIC_FREQS: `${APIROOT}/kpi/query/frequencies/numeric`,
  POST_TEXTUAL_FREQS: `${APIROOT}/kpi/query/frequencies/textual`,
  GET_COMPLETED_SURVEY_ANSWERS: `${APIROOT}/kpi/query/answers/{completedSurveyId}`,
  GET_TEXTUAL_ANSWER_COUNT: `${APIROOT}/kpi/query/textual/count`,
  POST_TEXTUAL_COUNTS: `${APIROOT}/kpi/query/textual/pietabular/count`,
  POST_OPEN_ANSWERS: `${APIROOT}/kpi/query/textual/pietabular`,

  // Conversation
  GET_CONVERSATIONS: `${APIROOT}/conversation/{completedSurveyId}`,
  POST_FILTERED_MESSAGES: `${APIROOT}/conversation/messages/filter`,
  POST_CONVERSATION: `${APIROOT}/conversation/`,
  POST_CONVERSATION_MESSAGE: `${APIROOT}/conversation/messages`,
  POST_FILTERED_CONVERSATIONS: `${APIROOT}/conversation/filter`,
  PUT_UPDATED_MESSAGES: `${APIROOT}/conversation/messages/batchUpdateState`,

  // Summary
  GET_SUMMARY: `${APIROOT}/summary/filter`,

  // Survey
  GET_SURVEYS: `${APIROOT}/survey?filter={filter}`,
  POST_RESPONSE_RATES: `${APIROOT}/survey/stats/responserates`,
  POST_INVITATIONS_COUNT: `${APIROOT}/survey/stats/invitationsCount`,
  POST_COUNTBYMETA: `${APIROOT}/survey/stats/countbymeta`,
  POST_IMPORTDATA: `${APIROOT}/survey/importdata`,
  GET_SURVEY_STATS: `${APIROOT}/survey/stats/surveystats`,
  POST_SURVEY_ROW_DATA: `${APIROOT}/survey/stats/export`,
  POST_SURVEY_BATCH_EXPORT: `${APIROOT}/survey/stats/batchexport`,

  // Template
  POST_DIRECT_INVITE: `${APIROOT}/template/{id}/directinvite`,
  POST_INVITE: `${APIROOT}/template/invite`,
  GET_SURVEY_TEMPLATES: `${APIROOT}/template/list`,
  POST_SURVEYTEMPLATEINVITE: `${APIROOT}/template/invite`,

  // Sales Companion
  GET_ENABLERS_BARRIERS: `${APIROOT}/config/modelling/ebs`,

  // Completed survey
  PUT_SURVEY_META_DATA: `${APIROOT}/completedSurvey/{completedSurveyId}/metadata`,
}

const DISALLOWED_ENDPOINTS = [
  ENDPOINTS.DELETE_ACCESSRIGHTS,
  ENDPOINTS.DELETE_CONTACT,
  ENDPOINTS.DELETE_GROUP,
  ENDPOINTS.DELETE_USER,
  ENDPOINTS.DELETE_USERDBSETTINGS,
  ENDPOINTS.DELETE_USERDEFAULTFILTERS,

  ENDPOINTS.POST_INVITEUSER,
  ENDPOINTS.POST_EDITUSER,
  ENDPOINTS.POST_LIMITED_ACCESS_ALERT,
  ENDPOINTS.POST_ALERT_ASSIGNMENT,
  ENDPOINTS.POST_SAVE_ALERT,
  ENDPOINTS.POST_DASHBOARD_SETTINGS,
  ENDPOINTS.POST_OPEN_CATEGORIES,
  ENDPOINTS.POST_NEWCONTACT,
  ENDPOINTS.POST_DASHEXPORT,
  ENDPOINTS.POST_ADMINEXPORT,
  ENDPOINTS.POST_ADMINEXPORWITHHIDDEN,
  ENDPOINTS.POST_LOG,
  ENDPOINTS.POST_MFA_PIN,
  ENDPOINTS.POST_RESET_PASSWORD,
  ENDPOINTS.POST_RESETPASSWORD,
  ENDPOINTS.POST_AD_LOGIN,
  ENDPOINTS.POST_IMPORTDATA,
  ENDPOINTS.POST_INVITE,
  ENDPOINTS.POST_DIRECT_INVITE,
  ENDPOINTS.POST_SURVEYTEMPLATEINVITE,
] as const

const EXPENSIVE_ENDPOINTS = [
  ENDPOINTS.GET_REPORTINGFILTERS,
  ENDPOINTS.POST_DYNAMICFILTERS,
  ENDPOINTS.POST_RESPONSE_RATES,
  ENDPOINTS.POST_SURVEY_ROW_DATA,
] as const

export default ENDPOINTS

export {
  APIROOT,
  APPROOT,

  /**
   * These both relate to endpoint testing (03/2021)
   */
  DISALLOWED_ENDPOINTS,
  EXPENSIVE_ENDPOINTS,
}
