import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const HelpIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'help'}
    text=""
    customClasses={[css.btn,...(props.customClasses ? props.customClasses : [])]}
    iconPosition={'center'}
    title="Click here for more information"
    data_testid="info-icon-button"
    style={{ marginLeft: '3px' }}
  />
)

export default HelpIcon;