import React from 'react'

import modal_css from './../../../DashboardModules/_shared/ModuleSettings/SettingsModal.module.scss'
import css from './SelectNonMetaColumns.module.scss'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'

const SelectNonMetaColumns = () => {
  return (
    <div className={`${modal_css.max}`}>
      <TextWithInfo text={'Non meta columns to show'} tooltipDirection='top' info={'info'} />

      <div className={css.list}>
        <CheckBoxItem
          text='Show custom categories'
          data_testid='doShowCustomCategories'
          id='doShowCustomCategories'
          settingsName='doShowCustomCategories'
          width='third'
          defaultValue={false}
          isRootProperty
        />
        <CheckBoxItem
          text='Show sentiments'
          data_testid='doShowSentiments'
          id='doShowSentiments'
          settingsName='doShowSentiments'
          width='third'
          defaultValue={false}
          isRootProperty
        />
        <CheckBoxItem
          text='Show topics'
          data_testid='doShowTopics'
          id='doShowTopics'
          settingsName='doShowTopics'
          width='third'
          defaultValue={false}
          isRootProperty
        />
        <CheckBoxItem
          text='Show tools'
          data_testid='showTools'
          id='showTools'
          settingsName='showTools'
          width='third'
          defaultValue={false}
        />
      </div>
    </div>
  )
}

export default SelectNonMetaColumns
