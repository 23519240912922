import React, { useState } from 'react';
import ExpandLessIcon from '../../../common/Buttons/ExpandLessIcon';
import ExpandMoreIcon from '../../../common/Buttons/ExpandMoreIcon';
import HelpIcon from '../../../common/Buttons/HelpIcon';
import TabButtonBar from './TabButtonBar';
import css from './TabHeading.module.scss';

type TabHeadingProps = {
  title: string,
  info: string,
  onClickPreview: () => void,
  onClickSave:() => void,
}

const TabHeading = ({ title, info, onClickPreview, onClickSave }: TabHeadingProps) => {
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);

  const handleInfoIconClick = () => {
    setIsInfoOpen(prev => !prev)
  }

  return (
    <div className={css.tbl_heading}>
      <div className={css.tbl_header_and_btns}>
        <div className={css.tbl_header}>
          <h5>{title}</h5>
          <div className={css.heading_buttons}>
            <HelpIcon onClick={handleInfoIconClick} customClasses={[css.info_btn]} />
            {isInfoOpen
              ?
              <ExpandLessIcon onClick={handleInfoIconClick} customClasses={[css.expand_btn]}/>
              :
              <ExpandMoreIcon onClick={handleInfoIconClick} customClasses={[css.expand_btn]}/>
            }
          </div>
        </div>
        <TabButtonBar
          onClickPreview={onClickPreview}
          onClickSave={onClickSave}
        />

      </div>
      {isInfoOpen &&
        <p>
          {info}
        </p>
      }
    </div>
  )
}

export default TabHeading;