import React, { memo, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { ActionTypes, lineChartReducer } from './lineChartReducer'
import * as Highcharts from 'highcharts8'
import HighchartsReact from 'highcharts-react-official'
import {
  LabelPosition,
  LineChartDataFormats,
  LineChartDateGroups,
  LineChartModule,
  TargetColor,
} from './lineChartTypes'
import { Data, KpiData, Query } from '../../../../../types'
import useLineChartOptionsBuilding from './useLineChartOptionsBuilding'
import { cloneDeep, isEqual, isUndefined } from 'lodash'
import { AddOrRemove, Breakpoint } from '../Pietabular/pietabularTypes'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import BreakPointModal from '../Pietabular/trend/BreakpointModal/BreakPointModal'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingBreakpoints, errorLoadingInspector } from './LineNotifications'
import ValueInspector from '../_shared/ValueInspector/ValueInspector'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'
import { figureOutStartAndEndDatesForInspector } from '../../../../react-services/chartService'
import { addRollingAverageToNumericData } from '../../../../react-services/rollingAverageService'
import NoDataToDisplay from 'highcharts8/modules/no-data-to-display'
import { getTenant } from '../../../../react-services/authService'
NoDataToDisplay(Highcharts)
import { createChart } from './lineChart'
import FadeIn from '../_shared/FadeIn'
import { CircularProgress } from '@mui/material'
import ChartButton from '../_shared/ChartComponents/ChartButton'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'
import { reportSVGWidth } from '../../../../../styles/variableExport'

import css from './Line.module.scss'
import { CustomerPathKpi } from '../../../../stores/useConfigCustomerPath'
import { staleMadeUpId } from '../Wheel/line/useLineSeriesBuilders'

type LineProps = {
  id: string
  isRollingAvgDisabled?: boolean
  showAxisLines?: boolean
  query: Query.Numeric
  chartHeight?: number
  data: KpiData.NumericData | null
  dataFormat: LineChartDataFormats
  isReportMode?: boolean
  isScreenMode?: boolean
  filterGrouping: Query.TimeRes
  chartThemeKey: string
  rollingAvgCounts: KpiData.NumericData | null
  breakpoints: Breakpoint[]
  limitValuesDataFormat?: Data.Format
  kpiTargetFrom?: string
  kpiTargetTo?: string
  showTotals: boolean
  kpiTargetAreaColor?: TargetColor
  limitValues?: LineChartModule['limitValues']
  isSoftMin?: boolean
  isSoftMax?: boolean
  kpiTargetCustomColor?: string
  kpiTargetLabel?: string
  kpiTargetLabelPosition?: LabelPosition
  kpiTargetDataFormat?: Exclude<LineChartDataFormats, LineChartDataFormats.ROLLING_AVG>
  handleSaveBreakpoints: (breakpoints: Breakpoint[]) => void
  handleSaveDateGroup: (dateGroup: Query.TimeRes) => void
  handleSaveDataFormat: (dataFormat: LineChartDataFormats) => void
  handleSaveShowTotals: () => void
  // module: LineChartModule
  // saveModule?: (module: LineChartModule) => void
  isRefetching: boolean
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
  categories?: CustomerPathKpi[] | undefined
}

const Line = memo(
  ({
    id,
    isRollingAvgDisabled = false,
    query,
    data,
    isReportMode,
    isScreenMode,
    isRefetching,
    dataFormat,
    chartComponentRef,
    rollingAvgCounts,
    limitValues,
    isSoftMin,
    isSoftMax,
    limitValuesDataFormat,
    filterGrouping,
    kpiTargetTo,
    kpiTargetFrom,
    showTotals,
    kpiTargetAreaColor,
    kpiTargetCustomColor,
    kpiTargetLabel,
    kpiTargetLabelPosition,
    kpiTargetDataFormat,
    breakpoints,
    handleSaveBreakpoints,
    handleSaveDateGroup,
    handleSaveDataFormat,
    handleSaveShowTotals,
    categories,
  }: LineProps) => {
    const { t, i18n } = useTranslation()
    const {
      handleAddOrRemoveBreakpoint,
      formatLineChartSeries,
      formatBarChartSeries,
      convertBreakpointDatesToPointsInTheXAxis,
    } = useLineChartOptionsBuilding(
      (kpiId, seriesName, dateStamp) =>
        handleSeriesClick(kpiId, seriesName, dateStamp, filterGrouping),
      !!isReportMode,
    )

    const { config } = useCommonDbSettingsConfig()
    const { originalCalculatedKpis } = useCalculatedKpis()
    const [chartCopy, setChartCopy] = useState<Highcharts.Options | null>(null)
    const [isBreakpointModalOpen, setIsBreakpointModalOpen] = useState<boolean>(false)
    const [clickedSeriesNameAndDateGroup, setClickedSeriesNameAndDateGroup] = useState<{
      names: string[]
      kpiIds: number[] | undefined
      start_date: string
      end_date: string
    } | null>(null)
    const initOptionsState = createChart(!!isReportMode)
    const [chartOptions, setChartOptions] = useReducer(lineChartReducer, initOptionsState)

    const CHART_RENDER_TIMEOUT = getTenant() === 'test_frontend' ? 0 : 1000
    const chartUpdateTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
      if (chartUpdateTimeoutRef.current) clearTimeout(chartUpdateTimeoutRef.current)
      chartUpdateTimeoutRef.current = setTimeout(() => {
        setChartCopy(cloneDeep(chartOptions))
      }, CHART_RENDER_TIMEOUT)
      return () => chartUpdateTimeoutRef.current && clearTimeout(chartUpdateTimeoutRef.current)
    }, [chartOptions])

    const chart = useMemo(() => {
      if (!data) return
      if (chartCopy) {
        return (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartCopy}
            updateArgs={chartComponentRef.current ? [true, true, true] : undefined}
            immutable={chartComponentRef.current ? true : false}
            ref={chartComponentRef}
            containerProps={{ id: 'linechart-container', style: { height: 'inherit' } }}
            key={id}
          />
        )
      }
    }, [chartCopy])

    useUpdateEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_LANGUAGE,
      })
    }, [i18n.language])

    useEffect(() => {
      if (!data) return
      if (dataFormat === LineChartDataFormats.ROLLING_AVG && !rollingAvgCounts) return
      let newData = data
      if (dataFormat === LineChartDataFormats.ROLLING_AVG && rollingAvgCounts && data) {
        const newDataWithRollingAvg = addRollingAverageToNumericData(
          data,
          rollingAvgCounts,
          query.time_res,
        )
        if (newDataWithRollingAvg) newData = newDataWithRollingAvg
      }
      const isTargetAreaEnabled = kpiTargetTo !== undefined && kpiTargetFrom !== undefined
      const newSeries = formatLineChartSeries(
        newData.series,
        dataFormat,
        isTargetAreaEnabled,
        !!showTotals,
        kpiTargetAreaColor,
      )
      setChartOptions({
        type: ActionTypes.SET_CHART_DATA,
        data: { series: newSeries, categories: data.categories },
      })
    }, [
      data,
      dataFormat,
      rollingAvgCounts,
      showTotals,
      kpiTargetAreaColor,
      kpiTargetTo,
      kpiTargetFrom,
      query.time_res,
    ])

    useEffect(() => {
      if (!data) return
      const isSameDataFormat = limitValuesDataFormat === dataFormat
      setChartOptions({
        type: ActionTypes.SET_LIMITS,
        data: {
          limitValues: isSameDataFormat ? limitValues || {} : {},
          isSoftMin: !!isSoftMin,
          isSoftMax: !!isSoftMax,
        },
      })
    }, [
      data,
      limitValues?.max,
      limitValues?.mid,
      limitValues?.min,
      dataFormat,
      limitValuesDataFormat,
      isSoftMin,
      isSoftMax,
    ])

    useEffect(() => {
      if (!data) return
      setChartOptions({
        type: ActionTypes.SET_TARGET,
        data: {
          kpiTargetAreaColor,
          kpiTargetCustomColor,
          kpiTargetFrom,
          kpiTargetLabel,
          kpiTargetLabelPosition,
          kpiTargetTo,
          kpiTargetDataFormat,
          dataFormat:
            dataFormat === LineChartDataFormats.ROLLING_AVG ? LineChartDataFormats.AVG : dataFormat,
        },
      })
    }, [
      data,
      kpiTargetAreaColor,
      kpiTargetCustomColor,
      kpiTargetFrom,
      kpiTargetLabel,
      kpiTargetLabelPosition,
      kpiTargetTo,
      kpiTargetDataFormat,
      dataFormat,
    ])

    useEffect(() => {
      if (!data) return
      const convertedBps = convertBreakpointDatesToPointsInTheXAxis(
        breakpoints,
        data.categories,
        filterGrouping,
        config,
      )
      setChartOptions({
        type: ActionTypes.SET_BREAKPOINT,
        data: { breakpoints: convertedBps || [], categories: data.categories || [] },
      })
    }, [data, data?.categories, breakpoints, config])

    useEffect(() => {
      if (!data) return
      if (dataFormat === LineChartDataFormats.ROLLING_AVG && !rollingAvgCounts) return
      let newData = data
      if (dataFormat === LineChartDataFormats.ROLLING_AVG && rollingAvgCounts && data) {
        const newDataWithRollingAvg = addRollingAverageToNumericData(
          data,
          rollingAvgCounts,
          query.time_res,
        )
        if (newDataWithRollingAvg) newData = newDataWithRollingAvg
      }
      const newSeries = formatBarChartSeries(newData.series, kpiTargetAreaColor)
      setChartOptions({
        type: ActionTypes.SET_TOTALS,
        data: { series: newSeries, showTotals: showTotals, dataFormat },
      })
    }, [data, dataFormat, showTotals, kpiTargetAreaColor, rollingAvgCounts, query.time_res])

    useEffect(() => {
      if (!data) return
      setChartOptions({
        type: ActionTypes.SET_TOOLTIP,
        data: { dataFormat },
      })
    }, [data, dataFormat])

    const handleDateGroupClick = () => {
      const curretDateGroup: Query.TimeRes = filterGrouping
      let newDateGroup: Query.TimeRes = LineChartDateGroups.MONTH
      if (curretDateGroup === LineChartDateGroups.YEAR) newDateGroup = LineChartDateGroups.QUARTER
      if (curretDateGroup === LineChartDateGroups.QUARTER) newDateGroup = LineChartDateGroups.MONTH
      if (curretDateGroup === LineChartDateGroups.MONTH) newDateGroup = LineChartDateGroups.WEEK
      if (curretDateGroup === LineChartDateGroups.WEEK) newDateGroup = LineChartDateGroups.DAY
      if (curretDateGroup === LineChartDateGroups.DAY) newDateGroup = LineChartDateGroups.YEAR
      handleSaveDateGroup(newDateGroup)
    }

    const handleDataFormatClick = () => {
      const currentDataFormat: LineChartDataFormats = dataFormat
      let newDataFormat: LineChartDataFormats = LineChartDataFormats.AVG
      if (currentDataFormat === LineChartDataFormats.AVG) newDataFormat = LineChartDataFormats.N
      if (currentDataFormat === LineChartDataFormats.N) newDataFormat = LineChartDataFormats.SUM
      if (currentDataFormat === LineChartDataFormats.SUM && !isRollingAvgDisabled)
        newDataFormat = LineChartDataFormats.ROLLING_AVG
      if (currentDataFormat === LineChartDataFormats.SUM && isRollingAvgDisabled)
        newDataFormat = LineChartDataFormats.AVG
      if (currentDataFormat === LineChartDataFormats.ROLLING_AVG)
        newDataFormat = LineChartDataFormats.AVG
      handleSaveDataFormat(newDataFormat)
    }

    function handleSeriesClick(
      kpiId: number | undefined,
      name: string,
      dateStamp: string,
      dateGroup: Query.TimeRes,
    ) {
      const dates = figureOutStartAndEndDatesForInspector(dateStamp, dateGroup)
      if (isUndefined(kpiId)) return
      setClickedSeriesNameAndDateGroup({ kpiIds: [kpiId], names: [name], ...(dates || {}) })
    }

    let selectedKpis: number[] = []
    if (
      clickedSeriesNameAndDateGroup?.kpiIds &&
      clickedSeriesNameAndDateGroup?.kpiIds[0] === staleMadeUpId &&
      categories
    ) {
      const newSelectedKpis: number[] = []
      categories.forEach((category) => {
        if (category.category === clickedSeriesNameAndDateGroup.names[0] && category.kpiid) {
          newSelectedKpis.push(category.kpiid)
        }
      })
      selectedKpis = newSelectedKpis
    } else if (
      clickedSeriesNameAndDateGroup &&
      originalCalculatedKpis &&
      originalCalculatedKpis[clickedSeriesNameAndDateGroup.names[0]]
    ) {
      selectedKpis = originalCalculatedKpis[clickedSeriesNameAndDateGroup?.names[0]].kpiIds
    } else if (clickedSeriesNameAndDateGroup && clickedSeriesNameAndDateGroup.kpiIds) {
      selectedKpis = clickedSeriesNameAndDateGroup.kpiIds
    }
    return (
      <>
        {clickedSeriesNameAndDateGroup && (
          <ErrorBoundary
            message={errorLoadingInspector}
            fallback={<div style={{ color: 'red' }}>{errorLoadingInspector}</div>}
          >
            <ValueInspector
              startDate={clickedSeriesNameAndDateGroup.start_date}
              endDate={clickedSeriesNameAndDateGroup.end_date}
              filters={query.where_meta}
              valueType='numeric'
              onClose={() => setClickedSeriesNameAndDateGroup(null)}
              kpis={selectedKpis}
              inspected={`${clickedSeriesNameAndDateGroup.names[0]}, ${clickedSeriesNameAndDateGroup.start_date} - ${clickedSeriesNameAndDateGroup.end_date}`}
            />
          </ErrorBoundary>
        )}
        {isBreakpointModalOpen && (
          <ErrorBoundary
            message={errorLoadingBreakpoints}
            fallback={<div style={{ color: 'red' }}>{errorLoadingBreakpoints}</div>}
          >
            <BreakPointModal
              breakpoints={breakpoints || []}
              onClose={() => setIsBreakpointModalOpen(false)}
              handleAddOrRemoveBreakpoint={(breakpoint: Breakpoint, action: AddOrRemove) =>
                handleAddOrRemoveBreakpoint(breakpoint, action, breakpoints, handleSaveBreakpoints)
              }
            />
          </ErrorBoundary>
        )}

        <div className={css.cntr} style={{ ...(isReportMode ? { width: reportSVGWidth } : {}) }}>
          {chart && !isReportMode && !isScreenMode ? (
            <div className={css.actionBar}>
              <ChartButton
                text={t('common.button.breakpoints')}
                onClick={() => setIsBreakpointModalOpen(true)}
              />
              <ChartButton
                text={t('common.data.' + filterGrouping.toLocaleLowerCase())}
                onClick={handleDateGroupClick}
              />
              <ChartButton
                text={t('common.data.' + dataFormat.toLocaleLowerCase())}
                onClick={handleDataFormatClick}
              />
              <ChartButton
                text={showTotals ? t('common.button.nBarOn') : t('common.button.nBarOff')}
                isLast
                onClick={handleSaveShowTotals}
              />
            </div>
          ) : (
            <div></div>
          )}
          {isRefetching && (
            <div className={css.loadingRefetch}>
              <CircularProgress thickness={2} size={'1.7rem'} style={{ opacity: 0.6 }} />
            </div>
          )}
          {chart ? <FadeIn durationMs={2000}>{chart}</FadeIn> : <div></div>}
        </div>
      </>
    )
  },
  isPropsEqual,
)

function isPropsEqual(prevProps: LineProps, newProps: LineProps) {
  return isEqual(prevProps, newProps)
}
Line.displayName = 'Line'

export default Line
