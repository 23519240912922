/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  cloneDeep,
  each,
  extend,
  filter,
  intersection,
  isEmpty,
  isNil,
  isUndefined,
  min,
  reject,
  sortBy,
  union,
  without,
} from 'lodash'
import { getDatesQuickTimeframeButton } from '../../../../react-services/datesService'
import { isLoggedIn } from '../../../../react-services/authService'
import { post } from '../../../../react-services/apiService'
import { removeBlacklistedFilters } from '../../../../react-services/moduleFiltersService'
import { createQuery2FromFilters } from '../../../../react-services/moduleService'
import { getCalculatedKpis, getNumericKpis } from '../../../../react-services/kpiService'
import { getQueryMeta, setQueryMeta } from '../../../../react-services/queryService'
import { WhereMeta } from '../../../../../types'
// CODE IN THIS FILE IS PRETTY MUCH COPY PASTED JUNK, NO IDEA ABOUT TYPES OR ANYTHING ELSE

const dashFilterCache: any = {
  kpiReportingFilters: [],
}
const maxCacheSize = 10
let cacheIdx = 0

// LEGACY STUFF, COPY PASTED FROM OLD CODE
export function createQuery2FromModuleSettings(settings: any) {
  console.log('moduleService: createQuery2FromModuleSettings')
  console.log('settings:')
  console.log(cloneDeep(settings))
  let numericKpis: any = []
  let calculated: any = {}
  // Add filters to queries
  const query = createQuery2FiltersFromModuleSettings(settings, true)
  const query2 = createQuery2FiltersFromModuleSettings(settings)
  // Fetch numeric KPIs
  return (
    fetchNumericKpis()
      .then(function (res) {
        if (res && res.kpis) numericKpis = res.kpis
      })
      // Fetch calculated KPIs
      .then(fetchCalculatedKpis)
      .then(function (res) {
        if (res) calculated = res
      })
      // Add KPIs to query
      .then(function () {
        let kpis: any = []
        let calculatedKpis: any = {}
        // Add KPIs to FREQ module: meta data mode
        if (moduleIsTypeMetaFreq(settings)) {
          // don't add any KPIs
          kpis = undefined
          calculatedKpis = undefined
        }
        // Add KPIs to FREQ module: open answers mode
        else if (moduleIsTypeOpenFreq(settings)) {
          const q = cloneDeep(query2)
          setSelectionsToQuery(settings.KPI, q)
          kpis = q.kpis
        }
        // Add KPIs to all other modules
        else {
          setSelectionsToQuery(settings.selections, query)
          each(query.kpis, function (kpiId) {
            const kpi = numericKpis.find((k: any) => k.id === kpiId)
            if (kpi) {
              kpis.push({
                id: kpi.id,
                name: kpi.name,
              })
            }
          })
          if (calculated && calculated.kpis) {
            each(query.calculatedKpis, function (kpiName) {
              if (calculated.kpis[kpiName]) calculatedKpis[kpiName] = calculated.kpis[kpiName]
            })
          }
        }
        query2.kpis = kpis
        query2.calculated_kpis = calculatedKpis
        console.log('return query:')
        console.log(cloneDeep(query2))
        return query2
      })
  )
}

// Parameters:
// settings: module settings
// query1: true/false -> if true, return old query type
export function createQuery2FiltersFromModuleSettings(module: any, query1?: boolean) {
  console.log('moduleService: createQuery2FiltersFromModuleSettings, settings:')
  console.log(module)
  const filters = {} as any
  if (module.settings) filters.filters = module.settings
  let timeRes = null
  const grouping = getModuleGrouping(module)
  if (module.type === 'line') {
    timeRes = grouping
  } else {
    if (grouping) filters.grouping = grouping
  }
  const query: any = createQueryFromFilters(filters)
  const query2: any = {
    start_date: query.start_date,
    end_date: query.end_date,
    where_meta: query.filter,
  }
  updateQueryToModuleTimeframeSelection(module, query)
  updateQueryToModuleTimeframeSelection(module, query2)
  if (timeRes !== null) query2.time_res = timeRes
  else query2.grouping = query.grouping
  if (query1) {
    return query
  }
  return query2
}

function createQueryFromFilters(f: any) {
  let filters = cloneDeep(f)
  const grouping = filters?.grouping
  const groupFilter = filters?.groupFilter
  filters = filters?.filters ? filters.filters : filters
  // If dates already in query form, use them directly. Otherwise, find startDate/endDate filter object values
  let startDate = filters.start_date
  const startDateObj = filters?.find((filter: any) => filter.name === 'startDate')
  if (startDateObj) {
    startDate = startDateObj
    const idx = filters.indexOf(startDateObj)
    filters.splice(idx, 1)
  }
  if (startDate && startDate.value) startDate = startDate.value
  let endDate = filters.end_date
  const endDateObj = filters?.find((filter: any) => filter.name === 'endDate')
  if (endDateObj) {
    endDate = endDateObj
    const idx = filters.indexOf(endDateObj)
    filters.splice(idx, 1)
  }
  if (endDate && endDate.value) endDate = endDate.value
  const query: any = createQueryFilters(filters, grouping, groupFilter)
  if (startDate) {
    query.start_date = startDate
  }
  if (endDate) {
    query.end_date = endDate
  }
  if (grouping && grouping !== 'none') {
    query.grouping = grouping
  }
  return query
}

function getModuleGrouping(conf: any) {
  return conf && conf.filterGrouping && conf.filterGrouping.length && !moduleGroupingInactive(conf)
    ? conf.filterGrouping
    : null
}

function moduleGroupingInactive(conf: any) {
  return conf && (moduleIsTypeNumericFreq(conf) || moduleIsTypeOpenFreq(conf))
}

function moduleIsTypeNumericFreq(module: any) {
  return module && module.queryType === 'numeric'
}

export function moduleIsTypeOpenFreq(module: any) {
  return module && module.queryType === 'open'
}

export function updateQueryToModuleTimeframeSelection(
  module: any,
  query: any,
  endDateOverride: Date | null = null,
) {
  if (useWindowedTimeframe(module) && query) {
    const start_date = todayPlusDays(-module.timeframedays)
    const end_date = todayPlusDays(0)
    if (start_date) query.start_date = start_date
    if (end_date) query.end_date = end_date
  }
  if (useWindowedTimeframeFuture(module) && query) {
    const end_date = todayPlusDays(module.timeframedays_future)
    if (end_date) query.end_date = end_date
  }
  if (useQuickTimeframeButton(module) && query) {
    const end = endDateOverride ? endDateOverride : new Date()
    const dates = getDatesQuickTimeframeButton(module.quicktimeframebutton, end)
    const start_date = datePlusDays(dates.start, 0)
    const end_date = datePlusDays(dates.end, 0)
    if (start_date) query.start_date = start_date
    if (end_date) query.end_date = end_date
  }
}

function useWindowedTimeframe(module: any) {
  return module && module.timeframedays !== undefined && module.timeframedays !== null
}

// return today +- given days formated to yyyy-MM-dd
export function todayPlusDays(days: any) {
  return datePlusDays(new Date(), days)
}

// return date +- given days formated to yyyy-MM-dd
export function datePlusDays(d: any, days: any) {
  const date = typeof d === 'string' ? new Date(d) : d
  date.setDate(date.getDate() + days)
  const year = '' + (date.getYear() + 1900)
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()
  if (month.length === 1) month = '0' + month
  if (day.length === 1) day = '0' + day
  return year + '-' + month + '-' + day
}

function useWindowedTimeframeFuture(module: any) {
  return module && module.timeframedays_future !== undefined && module.timeframedays_future !== null
}

function useQuickTimeframeButton(module: any) {
  return (
    module &&
    module.quicktimeframebutton !== undefined &&
    module.quicktimeframebutton !== null &&
    module.quicktimeframebutton !== 'none'
  )
}

function createQueryFilters(filters: any, grouping: any, groupFilter: any) {
  const queryFilters: any = {}
  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]
    if (Array.isArray(filter.value) && filter.value.length > 0) {
      queryFilters[filter.name] = filter.value
    } else {
      queryFilters[filter.name] = [filter.value]
    }
  }
  if (
    grouping !== undefined &&
    grouping !== null &&
    groupFilter !== undefined &&
    groupFilter !== null
  ) {
    queryFilters[grouping] = queryFilters[grouping] ? queryFilters[grouping] : groupFilter
  }
  return {
    filter: queryFilters,
  }
}

export function convertQueryToFilterBarObject(q: any) {
  const filters: any = []
  if (!q) return filters
  if (q.start_date) {
    filters.push({
      name: 'startDate',
      value: q.start_date,
    })
  }
  if (q.end_date) {
    filters.push({
      name: 'endDate',
      value: q.end_date,
    })
  }
  if (q.where_meta) {
    for (const key in q.where_meta) {
      if (q.where_meta[key] && key) {
        filters.push({
          name: key,
          value: q.where_meta[key],
        })
      }
    }
  }
  return filters
}

export function fetchDynamicFilters(
  kpis: any,
  q: any,
  id: any,
  expanded: any,
  timeframe: any,
  useDynamicTimeFilters: boolean,
) {
  const promise = new Promise((resolve, reject) => {
    console.log('dashboardFiltersService: fetchDynamicFilters for id ' + id + '!')
    const query = q
    const cacheIdx = getCacheIndex(kpis, query, expanded === false ? false : true)
    const data = getKpiReportingFiltersForId(cacheIdx)
    if (data) {
      console.log(
        'dashboardFiltersService: fetch dynamicReportingFilters for KPIs, query ' +
          cacheIdx +
          ' from service cache!',
      )
      return resolve(data)
    } else {
      console.log(
        'dashboardFiltersService: fetch dynamicReportingFilters for KPIs, query ' +
          JSON.stringify(kpis) +
          ', ' +
          JSON.stringify(query) +
          ' from api!',
      )
      if (!kpis || !kpis.length) {
        console.log('Error fetching dynamic filters - no KPIs available. Return...')
        return reject('No KPIs available')
      } else {
        const payload = {
          kpis: kpis,
          query: query,
          timeframe: !isEmpty(timeframe) && useDynamicTimeFilters ? timeframe : undefined,
        }
        post('POST_DYNAMICFILTERS', payload, {
          'is-expanded': expanded === false ? false : true,
        }).then(
          function (res: any) {
            if (res.filters) {
              res.filters = removeBlacklistedFilters(res.filters)
              addDataToKpiReportingFilters(res.data, cacheIdx)
              console.log('dashboardFiltersService: return dynamicReportingFilters:')
              console.log(res)
              return resolve(res)
            } else {
              return reject()
            }
          },
          function (error: any) {
            console.log('Error loading dynamic filters', error)
            return reject()
          },
        )
      }
    }
  })
  return promise
}

function getCacheIndex(kpis: any, query: any, expanded: any) {
  let idx = ''
  if (kpis) idx += JSON.stringify(kpis)
  if (query && !isEmpty(query)) {
    const q = queryWithSortedMeta(query)
    idx += JSON.stringify(q)
  }
  if (idx.length && expanded) idx += 'EXPANDED'
  return idx
}

function getCachedFilters() {
  if (isLoggedIn()) return dashFilterCache.kpiReportingFilters
}

function getKpiReportingFiltersForId(id: any) {
  const kpiReportingFilters = getCachedFilters()
  if (!kpiReportingFilters) return
  const value = kpiReportingFilters
  let data = null
  for (let i = 0; i < value.length; i++) {
    const obj = value[i]
    if (obj && obj.ids) {
      const isInList = obj.ids.indexOf(id)
      if (isInList !== -1) {
        data = obj.value
        break
      }
    }
  }
  return data
}

function addDataToKpiReportingFilters(dataParametert: any, id: any) {
  if (!isLoggedIn()) {
    return console.log(
      'dashboardFiltersService: Not logged in - cancel kpi reporting filters update',
    )
  }
  // Convert id to string if still in Array format
  const idx = id && Array.isArray(id) ? JSON.stringify(id) : id
  console.log('dashboardFiltersService: store kpiReportingFilters for ID ' + idx + '!')
  if (!idx || !idx.length) {
    return console.error(
      'dashboardFiltersService: could not store kpiReportingFilters for empty ID ' + idx + '!',
    )
  }
  let data = dataParametert
  if (!data) {
    data = {}
  }
  let kpiReportingFilters = dashFilterCache.kpiReportingFilters || []
  // First remove current kpi reporting filters from previous settings, in case old data is stored there
  for (let i = 0; i < kpiReportingFilters.length; i++) {
    const obj = kpiReportingFilters[i]
    if (obj.ids) {
      obj.ids = without(obj.ids, idx)
    }
  }
  // Remove objects with empty kpi ids Array
  kpiReportingFilters = reject(kpiReportingFilters, function (obj) {
    return !obj.ids || !obj.ids.length
  })
  let usePreviousCachedData
  for (let j = 0; j < kpiReportingFilters.length; j++) {
    const object = kpiReportingFilters[j]
    if (object && object.value && JSON.stringify(object.value) === JSON.stringify(data)) {
      usePreviousCachedData = true
      if (object.ids) {
        object.ids.push(idx)
      }
      break
    }
  }
  if (usePreviousCachedData) console.log('Use previously stored kpi filters data!')
  if (!usePreviousCachedData) {
    console.log('Push new data to kpiReportingFilters')
    kpiReportingFilters.push({
      ids: [idx],
      value: data,
      idx: cacheIdx,
    })
    cacheIdx++
  }
  kpiReportingFilters = removeOldEntriesFromCache(kpiReportingFilters)
  // Sort all id holders
  each(kpiReportingFilters, function (obj) {
    obj.ids = sortBy(obj.ids, function (ids) {
      return ids
    })
  })
  // Sort Array objects by ids
  kpiReportingFilters = sortBy(kpiReportingFilters, function (obj) {
    return obj.ids
  })
  setCachedFilters(kpiReportingFilters)
  console.log('kpiReportingFilters to be saved:')
  console.log(cloneDeep(kpiReportingFilters))
  console.log('Dash filter cache after save:')
  console.log(cloneDeep(dashFilterCache))
}

function removeOldEntriesFromCache(cache: any) {
  if (cache && cache.length > maxCacheSize) {
    const oldest_cache_entry = min(cache.map((item: any) => item.idx))
    cache = without(cache, oldest_cache_entry)
    console.log('Removed cache entry:')
    console.log(oldest_cache_entry)
    cache = removeOldEntriesFromCache(cache)
  }
  return cache
}

function setCachedFilters(conf: any) {
  if (!isLoggedIn()) {
    return console.log('dashboardFiltersService: Not logged in - do not set kpi reporting filters')
  }
  if (conf) dashFilterCache.kpiReportingFilters = conf
}

export function filterSubmodulesByIndex(module: any, indices: any) {
  if (!module || !indices) return
  const submodules = module.modules || []
  const filteredSubmodules = [] as any[]
  if (submodules) {
    for (const i of indices) {
      if (submodules[i]) {
        filteredSubmodules.push(submodules[i])
      } else {
        console.error(
          'Module index out of bounds! Cannot add submodule at idx',
          i,
          'from module list',
          submodules,
        )
        return
      }
    }
    setModuleSubmodules(module, filteredSubmodules)
    return module
  }
}

function setModuleSubmodules(conf: any, modules: any) {
  if (conf) {
    if (modules) {
      conf.modules = modules
    } else {
      delete conf.modules
    }
  }
}

export function queryContainsFilterWithValue(query: any, filter: any) {
  console.log('queryContainsFilterWithValue', 'Query:', query, 'Filter', filter)
  if (query && filter) {
    const keys = Object.keys(filter)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (!query[key]) {
        return false
      }
      for (let j = 0; j < filter[keys[i]].length; j++) {
        const v = filter[key][j]
        if (!query[key].contains(v)) {
          return false
        }
      }
    }
    return true
  }
}

// Merge queries with combined meta
export function deepMergeQueries(q1: any, q2: any) {
  console.log('queryService: deepMergeQueries')
  console.log('q1', q1)
  console.log('q2', q2)
  const query1 = formatOldQuery(q1)
  const query2 = formatOldQuery(q2)
  const merged = extend({}, query1, query2)
  merged.where_meta = extend({}, query1.where_meta, query2.where_meta)
  each(merged.where_meta, function (value, key) {
    const q1Values =
      query1 && query1.where_meta && query1.where_meta[key] ? query1.where_meta[key] : []
    const q2Values =
      query2 && query2.where_meta && query2.where_meta[key] ? query2.where_meta[key] : []
    merged.where_meta[key] = union(q1Values, q2Values)
  })
  console.log('Deep merged query', merged)
  return merged
}

function formatOldQuery(q: any) {
  let query = q ? cloneDeep(q) : {}
  if (isOldQuery(query)) {
    query = createQuery2FromFilters(query)
  }
  return query
}

function isOldQuery(query: any) {
  if (!query) return false
  if (Array.isArray(query)) return true
  if (query.filters && Array.isArray(query.filters)) return true
  return false
}

export function removeUnusedFiltersFromQueryService(filters: any, query: any) {
  if (!filters || !query) return
  console.log('queryService: removeUnusedFiltersFromQuery')
  console.log('allowed filters:')
  console.log(filters)
  console.log('query before:')
  console.log(cloneDeep(query))
  // Remove missing meta keys
  each(query.where_meta, function (value, key) {
    const isUsed = !isUndefined(filters[key])
    if (!isUsed) delete query.where_meta[key]
  })
  // Remove any missing values under meta key
  each(query.where_meta, function (value, key) {
    if (value && value.length && filters[key] && filters[key].length) {
      query.where_meta[key] = intersection(value, filters[key])
    }
    if (query.where_meta[key] && !query.where_meta[key].length) {
      delete query.where_meta[key]
    }
  })
  console.log('query after:')
  console.log(cloneDeep(query))
}

function fetchNumericKpis() {
  return getNumericKpis().then(
    function (res: any) {
      return res
    },
    function (error: any) {
      console.log(error)
      return
    },
  )
}

function fetchCalculatedKpis() {
  return getCalculatedKpis()
    .then(function (res: any) {
      return res
    })
    .catch(function (error: any) {
      console.log(error)

      return
    })
}

function moduleIsTypeMetaFreq(module: any) {
  return module && module.queryType === 'meta'
}

function setSelectionsToQuery(selections: any, query: any) {
  let selectedIds: any
  let calculatedKpis: any
  if (Array.isArray(selections)) {
    selectedIds = reject(selections, function (id) {
      return isNaN(id)
    })
    calculatedKpis = filter(selections, function (id) {
      return isNaN(id)
    })
  } else if (typeof selections === 'string' || typeof selections === 'number') {
    selectedIds = [selections]
  } else {
    const selectedIdsWithoutCalculated: any = []
    const selectedCalculatedWithoutIds: any = []
    for (const key in selections) {
      const parsedKey = Number(key)
      if (isNaN(parsedKey)) {
        selectedCalculatedWithoutIds.push(key)
      } else {
        selectedIdsWithoutCalculated.push(parsedKey)
      }
    }
    selectedIds = selectedIdsWithoutCalculated
    calculatedKpis = selectedCalculatedWithoutIds
  }
  query.kpis = selectedIds
  query.calculatedKpis = calculatedKpis
}

export function getScreenRootClasses(conf: any, reportmode: any) {
  let classes = ''
  if (reportmode) {
    classes += 'screen-reports '
  } else {
    classes += 'screen-regular inheritsize '
  }
  classes += getModuleSummaryModeClass(conf)
  const moduleOptionsStyle = getModuleStyle(conf)
  if (moduleOptionsStyle) classes += moduleOptionsStyle + ' '
  return classes
}

function getModuleSummaryModeClass(conf: any) {
  let classes = ''
  if (conf && conf.summarymode) {
    classes += 'summary-modulegroup '
  }
  return classes
}

function getModuleStyle(conf: any) {
  return conf && conf.options && conf.options.style ? conf.options.style + ' ' : ''
}

export function getScreenModuleClasses(conf: any, reportmode: any) {
  let classes = ''
  if (reportmode) {
    classes += 'single-graph-scroll '
  } else {
    classes += 'single-graph '
  }
  if (conf && conf.type === 'group') {
    classes += 'no-padding '
  }
  if (conf && conf.high) classes += 'single-graph-high '
  // const width = reportmode ? 12 : getModuleWidth(conf) ? getModuleWidth(conf) : 6
  classes += 'single-graph-12 '
  classes += getModuleCssClasses(conf)
  classes += getModuleStyle(conf)
  classes += getModuleSummaryModeClass(conf)
  classes += getSubGroupModuleCssClasses(conf)
  return classes
}

function getModuleCssClasses(conf: any) {
  return conf && conf.options && conf.options.cssclasses ? conf.options.cssclasses + ' ' : ''
}

function getSubGroupModuleCssClasses(conf: any) {
  let css = ''
  if (conf) {
    if (conf.type !== 'group') css += 'leaf-module '
    if (conf.type) css += 'group-module-type-' + conf.type + ' '
    if (isResizable(conf.type)) {
      css += 'resizable '
    }
  }
  return css
}

function isResizable(type: any) {
  return !isNil(type) && type === 'bubble'
}

function queryWithSortedMeta(query: any) {
  const q = cloneDeep(query)
  const meta = getQueryMeta(q)
  if (meta) {
    const keys = Object.keys(meta)
    keys.sort()
    const m: WhereMeta = {}
    for (const key of keys) {
      m[key] = meta[key]
    }
    setQueryMeta(q, m)
  }
  return q
}
