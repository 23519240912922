import React, { useCallback, useEffect, useState } from 'react'
import { useSummaryData } from '../Context/SummaryDataContext'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import useMetaData from '../../../../../stores/useMetaData'
import useSummaryLanguage from '../useSummaryLanguage'
import DynamicMetaSelection from './DynamicMetaSelection'
import NonDynamicMetaSelection from './NonDynamicMetaSelection'

import css from './SummaryFilters.module.scss'

const SelectMetas = () => {
  const [{ saveSettings, metaKeysForFilters }] = useSummaryData()
  const [{ summarySettings, language }, setSummaryFilters] = useSummaryFilters()
  const { isMetaFilterDynamic, isSingleMetaSelectEnabled } = summarySettings
  const { t } = useSummaryLanguage(language)
  const { metas } = useMetaData()
  const [selectedMeta, setSelectedMeta] = useState<Record<string, string> | null>(null)

  const onSelectMeta = useCallback((key: string, value: string) => {
    setSelectedMeta({ [key]: value })
  }, [])

  useEffect(() => {
    if (!selectedMeta) return
    if (!metaKeysForFilters) return
    setSummaryFilters((prev) => {
      const prevMetas = prev.filterMetas
      Object.keys(prevMetas).forEach(
        (metaKey) => !metaKeysForFilters.includes(metaKey) && delete prevMetas[metaKey],
      )
      let newMetas: {
        [x: string]: string
      } = {}
      if (isSingleMetaSelectEnabled) newMetas = { ...selectedMeta }
      else newMetas = { ...prevMetas, ...selectedMeta }
      if (saveSettings) {
        const newSettings = {
          ...summarySettings,
          metaKeysForFilters: metaKeysForFilters,
          filterMetas: newMetas,
        }
        saveSettings(newSettings)
      }
      return {
        ...prev,
        filterMetas: newMetas,
      }
    })
  }, [selectedMeta, isSingleMetaSelectEnabled])

  if (!metaKeysForFilters) return <></>
  return (
    <div className={css.filter_metas} data-testid='filter-metas'>
      <div className={css.header}>
        <i className='material-icons'>analytics</i>&nbsp;
        {t('Taustatiedot')}
      </div>
      <div className={css.selections}>
        {isMetaFilterDynamic &&
          metaKeysForFilters &&
          metaKeysForFilters.map((metaKey) => (
            <DynamicMetaSelection metaKey={metaKey} key={metaKey} onSelectMeta={onSelectMeta} />
          ))}
        {!isMetaFilterDynamic &&
          !!metas &&
          !!metaKeysForFilters &&
          metaKeysForFilters.map((metaKey) => {
            const metaValues = metas[metaKey]
            return !metaValues ? (
              <React.Fragment key={metaKey}></React.Fragment>
            ) : (
              <NonDynamicMetaSelection
                metaKey={metaKey}
                key={metaKey}
                metaValues={metaValues}
                onSelectMeta={onSelectMeta}
              />
            )
          })}
      </div>
    </div>
  )
}

export default SelectMetas
