import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { createStateContext, useEffectOnce } from 'react-use'
import { getDashboardSettings } from '../../../../../react-services/configService'
import {
  isCategoryChangingAllowed,
  saveHotkeyConfig,
} from '../../../../../react-services/openAnswersService'
import { isHotkeyConfig } from '../../../../../utilities'
import { useToastId } from '../../../../common/Notification/NotificationContext'
import ErrorBoundary from '../../../../_shared/Infos/ErrorBoundary'
import OpenCategoryToolModal from './OpenCategoryToolModal'
import {
  CategoriesAndValues,
  CategoriesWithHotkeys,
  HotkeysDataContext,
  OpenCategoryToolContextProps,
  OpenCategoryToolDataContext,
  SentimentCategory,
} from './openCategoryToolTypes'
import { errorLoadingCategoryTool, errorLoadingHotkeyConfig } from './OpenCategoryToolNotifications'
import useCommonDbSettingsConfig from '../../../../../stores/useCommonDbSettingsConfig'

export const [useOpenCategoryToolData, OpenCategoryToolDataProvider] =
  createStateContext<OpenCategoryToolDataContext>({
    kpiName: '',
    answersForThisKpi: [],
    index: 0,
    openAnswer: null,
    customCategories: null,
    allCategories: {},
    filteredCategories: {},
    initialCategoryValues: {},
    updatedCategoryValues: {},
    isCategoryChangingAllowed: false,
    isUnsavedCategoryChanges: false,
    isFilterOnFocus: false,
    isInfoOpen: false,
  })

export const [useHotkeysData, HotkeysDataProvider] = createStateContext<HotkeysDataContext>({
  hotkeys: [],
  saveHotkeys: () => ({}),
  isHotkeySetupMaskOn: false,
  isHotkeysEnabled: true,
})

const OpenCategoryToolContext = ({ ...props }: OpenCategoryToolContextProps) => {
  const { toastifyId } = useToastId()
  return (
    <OpenCategoryToolDataProvider>
      <HotkeysDataProvider>
        <ErrorBoundary message={errorLoadingCategoryTool} containerId={toastifyId} fallback={null}>
          <OpenCategoryToolProvider {...props} />
        </ErrorBoundary>
      </HotkeysDataProvider>
    </OpenCategoryToolDataProvider>
  )
}

const OpenCategoryToolProvider = ({ ...props }: OpenCategoryToolContextProps) => {
  const [{ openAnswer, allCategories, index }, setOpenCategoryToolData] = useOpenCategoryToolData()
  const setHotkeysData = useHotkeysData()[1]
  const { toastifyId } = useToastId()
  const { config: cdbs } = useCommonDbSettingsConfig()
  useEffect(() => {
    setOpenCategoryToolData((prev) => ({
      ...prev,
      kpiName: props.kpiName,
      answersForThisKpi: props.answersForThisKpi,
      index: openAnswer ? index : props.index,
      openAnswer: props.answersForThisKpi[openAnswer ? index : props.index],
      customCategories: props.customCategories,
      allCategories: getAllCategories(),
      isCategoryChangingAllowed: isCategoryChangingAllowed(cdbs),
    }))
    setHotkeysData((prev) => ({
      ...prev,
      saveHotkeys: saveHotkeys,
    }))
  }, [props.answersForThisKpi, cdbs])

  useEffect(() => {
    setOpenCategoryToolData((prev) => ({
      ...prev,
      initialCategoryValues: getInitialCategoryValues(),
    }))
  }, [openAnswer])

  useEffect(() => {
    setOpenCategoryToolData((prev) => ({
      ...prev,
      filteredCategories: allCategories,
    }))
  }, [allCategories])

  useEffectOnce(() => {
    getDashboardSettings()
      .then((res) => {
        if (res && res.myhotkeys) {
          if (!isHotkeyConfig(res.myhotkeys)) {
            toast.error(errorLoadingHotkeyConfig, {
              containerId: toastifyId,
            })
            setHotkeysData((prev) => ({
              ...prev,
              isHotkeysEnabled: false,
            }))
          } else {
            setHotkeysData((prev) => ({
              ...prev,
              hotkeys: res.myhotkeys,
            }))
          }
        }
      })
      .catch(() => {
        toast.error(errorLoadingHotkeyConfig, {
          containerId: toastifyId,
        })
        setHotkeysData((prev) => ({
          ...prev,
          isHotkeysEnabled: false,
        }))
      })
  })

  const getAllCategories = (): CategoriesAndValues => {
    if (Object.entries(allCategories).length > 0) return allCategories
    const listForAllCategories: CategoriesAndValues = {
      sentiment: [
        SentimentCategory.Negative,
        SentimentCategory.Neutral,
        SentimentCategory.Positive,
      ],
      topic:
        props.topics && props.topics.length > 0
          ? props.topics.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
          : [],
    }
    if (props.customCategories) {
      Object.entries(props.customCategories).map((customCategory) => {
        const categoryType = customCategory[0]
        const categoryValue = customCategory[1]
        listForAllCategories[categoryType] = categoryValue.sort((a, b) =>
          a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
        )
      })
    }
    return listForAllCategories
  }

  const getInitialCategoryValues = (): CategoriesAndValues => {
    /* Sometimes category key value exists with an empty array and
    sometimes key value does not exist at all, so we have to set
    key values to avoid errors */
    let initVals: CategoriesAndValues = {
      sentiment: [],
    }

    if (openAnswer && openAnswer.category) {
      initVals = openAnswer.category

      if (!('sentiment' in initVals)) initVals.sentiment = []
      if (!('topic' in initVals) && props.topics && props.topics.length > 0) initVals.topic = []
      if (props.customCategories && Object.entries(props.customCategories).length > 0) {
        Object.keys(props.customCategories).map((categoryName) => {
          if (!(categoryName in initVals)) {
            initVals[categoryName] = []
          }
        })
      }
      Object.entries(initVals).map((category) => {
        const categoryHeader = category[0]
        const categories = category[1]
        initVals[categoryHeader] = categories.sort()
      })
    } else {
      if (props.topics && props.topics.length > 0) initVals.topic = []
      if (props.customCategories && Object.entries(props.customCategories).length > 0) {
        Object.keys(props.customCategories).map((categoryName) => {
          initVals[categoryName] = []
        })
      }
    }
    return initVals
  }

  const saveHotkeys = (newHotkeys: CategoriesWithHotkeys[]) => {
    saveHotkeyConfig(newHotkeys)
      .then((res) => {
        if (res) {
          setHotkeysData((prev) => ({
            ...prev,
            hotkeys: res,
          }))
        }
      })
      .catch((err) => toast.error(err, { containerId: toastifyId }))
  }

  return <OpenCategoryToolModal onClose={props.onClose} />
}

export default OpenCategoryToolContext
