import React, { useCallback, useState } from 'react'
import Icon, { IconName } from '../../../../../utilities/Icon'
import { useGroupModule } from '../contexts/GroupContext'
import ModulePicker from '../../ModulePicker/ModulePicker'
import { useRootActions } from '../contexts/RootContext'
import { Dashboard } from '../../../../../../types'

import css from './GroupAddModule.module.scss'
import { Tooltip } from '@mui/material'
import { tCommon } from '../../../../../../languages/i18n'

const GroupAddModule = () => {
  const { isNested, module: parentModule } = useGroupModule()[0]
  const [isModulePickerOpen, setIsModulePickerOpen] = useState(false)
  const { addModule } = useRootActions()

  const handleOpenPicker = useCallback(() => setIsModulePickerOpen(true), [])
  const handleClosePicker = useCallback(() => setIsModulePickerOpen(false), [])

  const handleAddModule = useCallback(
    (m: Dashboard.PickableModule) => {
      if (!parentModule?.id) return
      addModule(m, parentModule.id)
    },
    [parentModule?.id],
  )

  if (isNested) return <></>
  return (
    <div data-testid='group-add-module' className={css.cntr}>
      {isModulePickerOpen && (
        <ModulePicker isGrouped onSelect={handleAddModule} onClose={handleClosePicker} />
      )}
      <Tooltip title={tCommon('info.addModuleToGroup')}>
        <div className={css.btn} onClick={handleOpenPicker} data-testid='addModuleInGroup'>
          <Icon name={IconName.ADD} color='#fff' height={26} width={26} />
        </div>
      </Tooltip>
    </div>
  )
}

export default GroupAddModule
