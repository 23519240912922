import { isEqual } from 'lodash'

// Some tips how to implement observables and handle them in React
// https://stackoverflow.com/questions/53451584/is-it-possible-to-share-states-between-components-using-the-usestate-hook-in-r/62002044#62002044?newreg=106c3103b555468b9004c63bdac57f3f

type Listener<T> = (value: T) => void
export type ResettingStorage<T> = {
  get: (key: string) => T
  set: (newValue: T, key: string) => void
  reset: () => void
  getOwner: () => {
    tenant: string
    user: string
  }
  setOwner: (tenant: string, user: string) => void
  subscribe: (listenerFunc: Listener<T>, key: string) => () => void
  unsubscribe: (listenerFunc: Listener<T>, key: string) => void
  getRelatedListeners: (key: string) => Listener<T>[]
}

// This observable resets value keys if no subscribers are present for that specific key
export const makeResettingObservable = <T>(target: { [key: string]: T }): ResettingStorage<T> => {
  const listeners = {} as { [key: string]: Listener<T>[] }
  const value = target
  const owner = { tenant: '', user: '' } as { tenant: string; user: string }

  const get = (key: string) => {
    return value[key]
  }

  const set = (newValue: T, key: string) => {
    if (value && typeof value === 'object' && isEqual(value[key as keyof unknown], newValue)) return
    value[key as keyof unknown] = newValue

    if (listeners[key]) {
      listeners[key].forEach((l) => l(value[key as keyof unknown]))
    }
  }

  const getRelatedListeners = (key: string) => {
    return listeners[key]
  }

  const getOwner = () => {
    return owner
  }

  const setOwner = (tenant: string, user: string) => {
    if (!owner.tenant && !owner.user) {
      owner.tenant = tenant
      owner.user = user
    }
  }

  const subscribe = (listenerFunc: Listener<T>, key: string) => {
    if (listeners[key]) {
      listeners[key].push(listenerFunc)
    } else {
      listeners[key] = [listenerFunc]
    }
    return () => unsubscribe(listenerFunc, key)
  }

  const reset = () => {
    if (Object.keys(listeners).length !== 0) {
      Object.keys(listeners).forEach((key) => delete listeners[key])
    }
    if (Object.keys(value).length !== 0) {
      Object.keys(value).forEach((key) => delete value[key])
    }
    owner.user = ''
    owner.tenant = ''
  }

  const unsubscribe = (listenerFunc: Listener<T>, key: string) => {
    if (listeners[key]) {
      listeners[key] = listeners[key].filter((l) => l !== listenerFunc)
    }
    if (listeners[key] && listeners[key].length === 0) {
      delete listeners[key]
      if (value && typeof value === 'object' && value[key as keyof unknown]) {
        delete value[key as keyof unknown]
      }
    }
  }

  return {
    get,
    set,
    reset,
    getOwner,
    setOwner,
    subscribe,
    unsubscribe,
    getRelatedListeners,
  }
}
