import { Utilities, WebAnalytics } from '../../types';

/**
 * Returns true if node env is any of the parameters
 * @param envs node environments
 */
function isNodeEnv(...envs: Utilities.NodeEnvs[]): boolean {
  return envs.some(env => env === process.env.NODE_ENV)
}

/**
 * Checks localStorage to see if a specific logger is required
 * localStorage item key: loggerOverride
 */
function getLoggerOverride(): 'production' | 'development' | 'default' {
  const state = window.localStorage.getItem('loggerOverride')
  if (state === 'production') {
    return 'production'
  } else if (state === 'development') {
    return 'development'
  } else {
    return 'default'
  }
}

/**
 * Our image report generators are modified to add their name into user agent strings
 * so we can reliably detect that an image report is running.
 * Falls back to checking location.href.
 */
function isReportMode(): boolean {
  return isPdfReportMode()
    || doesUrlContainToken()
}

function isPdfReportMode(): boolean {
  return navigator.userAgent.toLowerCase().includes('puppeteer')
}

function doesUrlContainToken(): boolean {
  return /dashboard\/\d+\/module\/\d+\?.*token=/gm.test(window.location.href)
}

/**
 * Determine from location.hash if the app is displaying an alert
 */
function isEmailAlert(): boolean {
  return /#\/alert\//.test(window.location.hash)
}

/**
 * Get consent status from local storage
 */
function getConsentStatus(): WebAnalytics.ConsentStatus {
  if (window.localStorage.getItem('trackingActive') === 'false') {
    return 'Forbidden'
  }

  if (window.localStorage.getItem('trackingActive') === 'true') {
    return 'Consented'
  }

  return 'Unset'
}

/**
 * Get consent status from local storage
 */
function setConsentStatus(status: WebAnalytics.ConsentStatus) {
  switch (status) {
  case 'Forbidden':
    localStorage.setItem('trackingActive', 'false')
    break
  case 'Consented':
    localStorage.setItem('trackingActive', 'true')
    break
  case 'Unset':
    localStorage.removeItem('trackingActive')
    break
  default:
    break
  }
}


export {
  isNodeEnv,
  getLoggerOverride,
  isReportMode,
  isPdfReportMode,
  isEmailAlert,
  getConsentStatus,
  setConsentStatus,
}
