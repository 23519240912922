import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import { toast } from 'react-toastify'
import NoData from '../../../../_shared/Infos/NoData'
import GenericTableTitle from '../../_shared/GenericTableTitle'
import { OpenAnswersQuery } from '../PietabularModuleContext'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import { Counts, GroupedDataWithCounts } from '../pietabularTypes'
import {
  errorLoadingInspector,
  errorMatchingMetaValues,
  errorNoMetaKeySettings,
} from '../PietabularNotifications'
import CountedRowCell from './CountedRowCell'
import ErrorBoundary from '../../../../_shared/Infos/ErrorBoundary'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import {
  errorFetchingMetas,
  errorFetchingOpenCategories,
} from '../../../../../stores/notificationMessages'
import OpenInspectorCntr from '../../_shared/ValueInspector/Open/OpenInspectorCntr'
import useMetaData from '../../../../../stores/useMetaData'
import FadeIn from '../../_shared/FadeIn'
import { CircularProgress, SelectChangeEvent } from '@mui/material'
import { useMountedState } from 'react-use'
import { TranslationNameSpace, tCategory, tCommon, tData } from '../../../../../../languages/i18n'

import css from './Tabular.module.scss'
import objectHash from 'object-hash'

type TableContent = {
  rows: JSX.Element[] | null
  headers: JSX.Element | null
  footer: JSX.Element | null
}

type ClickFilter = {
  metaFilter: { [metaValue: string]: (string | null)[] }
  categoryFilter:
    | { topic?: (string | null)[] }
    | { sentiment?: (string | null)[] }
    | { custom?: { [customCategory: string]: (string | null)[] } }
}

type TabularProps = {
  groupedAndCountedData: GroupedDataWithCounts | null
  tableHeadingsWithoutN: string[]
  filteredTopics: (string | null)[] | undefined
  isLoading: boolean
  query: OpenAnswersQuery | null
  defaultSortColumn: number | undefined
  defaultSortIsAscending: boolean | undefined
  defaultMetaGroup?: string | undefined
  defaultCountedKey?: string | undefined
  tabularDataFormat: string | undefined
  showTotalCount: boolean
  isReportMode: boolean
  isScreenMode: boolean
  metaKeys?: string[]
  moduleCntrRef: React.MutableRefObject<HTMLDivElement | undefined>
  saveSettingsProperty: (newProperty: Record<string, unknown> | undefined) => void
  isRefetching: boolean
}

const Tabular = memo(
  ({
    isRefetching,
    groupedAndCountedData,
    tableHeadingsWithoutN,
    filteredTopics,
    isLoading,
    query,
    defaultSortColumn,
    defaultSortIsAscending,
    defaultMetaGroup,
    defaultCountedKey,
    tabularDataFormat,
    showTotalCount,
    isReportMode,
    isScreenMode,
    metaKeys,
    saveSettingsProperty,
  }: TabularProps) => {
    const animateTimeOutRef = useRef<NodeJS.Timeout>()
    const renderRowsTimeOutRef = useRef<NodeJS.Timeout>()
    const {
      answerCategories,
      error: errorLoadingCategories,
      loading: isLoadingCategories,
    } = useOpenCategories()

    const { metas, error: errorLoadingMetas, loading: isLoadingMetas } = useMetaData()
    const [isInspectorModalOpen, setIsInspectorModalOpen] = useState(false)
    const [clickedMetaAndCountValues, setClickedMetaAndCountValues] = useState<ClickFilter | null>(
      null,
    )
    const [changeAnimate, setChangeAnimate] = useState<boolean>(false)

    const [tableContent, setTableContent] = useState<TableContent>({
      rows: null,
      headers: null,
      footer: null,
    })

    const preSortedColumn = useMemo(() => {
      const currentSortColumn = defaultSortColumn || 1
      const sortColumn =
        currentSortColumn > tableHeadingsWithoutN.length
          ? tableHeadingsWithoutN.length
          : currentSortColumn
      if (tableContent.headers && tableContent.rows && defaultSortColumn) {
        return {
          index: sortColumn - 1,
          isReversed: defaultSortIsAscending === true ? false : true,
        }
      }
      return
    }, [
      defaultSortColumn,
      defaultSortIsAscending,
      tableHeadingsWithoutN,
      tableContent.headers,
      tableContent.rows,
    ])

    const isMounted = useMountedState()
    const onSort = useCallback(() => {
      if (animateTimeOutRef.current) clearTimeout(animateTimeOutRef.current)
      animateTimeOutRef.current = setTimeout(() => isMounted() && setChangeAnimate(false), 300)
    }, [])

    const table = useMemo(() => {
      if (animateTimeOutRef.current) clearTimeout(animateTimeOutRef.current)
      animateTimeOutRef.current = setTimeout(() => isMounted() && setChangeAnimate(false), 300)
      if (!tableContent.rows || !tableContent.headers) return <div></div>
      const sortIndex = preSortedColumn?.index || 0
      const headerHash = objectHash(tableHeadingsWithoutN)
      return (
        <GenericTable
          headers={tableContent.headers || <th></th>}
          rows={tableContent.rows || []}
          footer={
            groupedAndCountedData &&
            Object.keys(groupedAndCountedData).length &&
            (showTotalCount || isReportMode || isScreenMode) &&
            tableContent.footer ? (
              tableContent.footer
            ) : (
              <tr></tr>
            )
          }
          useFixedLayout
          onSort={onSort}
          onInvalidSorting={() => ({})}
          tableClasses={['pietabular']}
          preSortedColumn={
            sortIndex < tableHeadingsWithoutN.length - 1 ? preSortedColumn : undefined
          }
          key={headerHash}
        />
      )
    }, [
      tableContent.footer,
      tableContent.rows,
      tableContent.headers,
      onSort,
      preSortedColumn?.index,
      preSortedColumn?.isReversed,
      showTotalCount,
    ])

    useEffect(() => {
      if (errorLoadingCategories) {
        toast.error(errorFetchingOpenCategories)
      }
    }, [errorLoadingCategories])

    useEffect(() => {
      if (errorLoadingMetas) {
        toast.error(errorFetchingMetas)
      }
    }, [errorLoadingMetas])

    useEffect(() => {
      if (isLoading || !defaultMetaGroup || !metas || !defaultCountedKey) return
      setChangeAnimate(true)
      if (renderRowsTimeOutRef.current) clearTimeout(renderRowsTimeOutRef.current)
      renderRowsTimeOutRef.current = setTimeout(() => {
        const rows = createRows(
          groupedAndCountedData,
          tableHeadingsWithoutN,
          defaultMetaGroup,
          tabularDataFormat,
        )
        const headers = createHeaders()
        const footer = createFooter(defaultMetaGroup)
        setTableContent({ rows, headers, footer })
      }, 300)
      return () => {
        if (renderRowsTimeOutRef.current) clearTimeout(renderRowsTimeOutRef.current)
        if (animateTimeOutRef.current) clearTimeout(animateTimeOutRef.current)
      }
    }, [
      groupedAndCountedData,
      defaultCountedKey,
      tableHeadingsWithoutN,
      defaultMetaGroup,
      metas,
      tabularDataFormat,
      isLoadingMetas,
      isLoadingCategories,
    ])

    useEffect(() => {
      if (!isInspectorModalOpen) setClickedMetaAndCountValues(null)
    }, [isInspectorModalOpen])

    useEffect(() => {
      if (clickedMetaAndCountValues) setIsInspectorModalOpen(true)
    }, [clickedMetaAndCountValues])

    const createHeaders = () => {
      let dynamicHeaders: JSX.Element[] = []
      dynamicHeaders.push(
        <th key={'groupMetaKey'} colSpan={2}>
          {tData(defaultMetaGroup || '')}
        </th>,
      )
      let filteredHeadings = ['n', ...tableHeadingsWithoutN]
      if (tabularDataFormat && tabularDataFormat === 'share') {
        filteredHeadings = filteredHeadings.filter((heading) => heading !== 'n')
      }
      if (filteredHeadings.length > 0) {
        dynamicHeaders = dynamicHeaders.concat(
          filteredHeadings.map((heading) => (
            <th key={heading} colSpan={1} className={css.heading}>
              {tabularDataFormat === 'share' ? tCategory(heading) + ' %' : tCategory(heading)}
            </th>
          )),
        )
      }

      return <tr className={css.header}>{dynamicHeaders}</tr>
    }

    const createRows = (
      groupedAndCountedData: GroupedDataWithCounts | null,
      tableHeadingsWithoutN: string[],
      group: string,
      tabularDataFormat: string | undefined,
    ) => {
      const headings = ['n', ...tableHeadingsWithoutN]
      const totalSliceCounts = createTotalCountsForSlice(groupedAndCountedData || {})
      if (groupedAndCountedData && Object.keys(groupedAndCountedData).length) {
        const newRows = Object.entries(groupedAndCountedData)
          .map(([key, value]) => ({ [key]: value }))
          .map((metaCount) => Object.keys(metaCount)[0])
          .map((key) => {
            const cells = []
            cells.push(
              <td
                colSpan={2}
                className={css.meta_td}
                data-testid={'metaValueCell'}
                onClick={() => handleRowClick(group, key, undefined)}
                key={key}
              >
                {key}
              </td>,
            )
            headings.forEach((countedValue) => {
              if (countedValue.toLocaleLowerCase() === 'n' && tabularDataFormat === 'share') return
              let cellValue
              if (
                groupedAndCountedData[key][countedValue] ||
                !isNaN(groupedAndCountedData[key][countedValue])
              ) {
                cellValue = groupedAndCountedData[key][countedValue]
              } else {
                cellValue = 0
              }
              if (tabularDataFormat === 'share') {
                cellValue = Number(((cellValue / groupedAndCountedData[key]['n']) * 100).toFixed(0))
              }
              if (countedValue.toLocaleLowerCase() === 'n') {
                cells.push(
                  <td
                    key={key + countedValue}
                    colSpan={1}
                    className={css.count_td}
                    data-testid={countedValue}
                    onClick={() => handleRowClick(group, key, undefined)}
                  >
                    {cellValue}
                  </td>,
                )
              } else {
                cells.push(
                  <CountedRowCell
                    key={key + countedValue}
                    countedValue={countedValue}
                    totalSliceCounts={totalSliceCounts}
                    handleRowClick={handleRowClick}
                    groupedAndCountedData={groupedAndCountedData}
                    metaKey={key}
                    group={group}
                  >
                    {cellValue}
                  </CountedRowCell>,
                )
              }
            })
            return (
              <tr key={key} className={`${css.row} ${css.click}`}>
                {cells}
              </tr>
            )
          })
        return newRows
      }

      if (groupedAndCountedData && metaKeys && !metaKeys.length) {
        return [
          <tr key={errorNoMetaKeySettings}>
            <td colSpan={5}>{errorNoMetaKeySettings}</td>
          </tr>,
        ]
      }

      if (isLoading || isLoadingCategories || isLoadingMetas) {
        return [
          <tr key={'LOADING-INDICATOR'}>
            <td colSpan={5}>
              <LoadingIndicator />
            </td>
          </tr>,
        ]
      }

      return [
        <tr key={'NODATA-INDICATOR'}>
          <td colSpan={5}>
            <NoData />
          </td>
        </tr>,
      ]
    }

    const createFooter = (metaGroup: string) => {
      if (groupedAndCountedData) {
        const totals = Object.values(groupedAndCountedData).reduce<{ [key: string]: number }>(
          (totals, count) => {
            Object.entries(count).forEach(([key, value]) => {
              if (totals[key]) totals[key] = totals[key] + value
              if (!totals[key]) totals[key] = isNaN(value) ? 0 : value
            })
            return totals
          },
          {},
        )
        if (tabularDataFormat === 'share') {
          const total = totals['n']
          delete totals['n']
          return (
            <tr>
              <td colSpan={2}>Total</td>
              {Object.entries(totals).map(([key, value]) => (
                <td
                  key={key}
                  colSpan={1}
                  className={`${css.row_total_count} ${css.click}`}
                  data-testid='pietabular-totals-cell'
                  onClick={() => handleRowClick(metaGroup, 'all', key === 'n' ? undefined : key)}
                >
                  {parseFloat(((value / total) * 100).toFixed(0))}
                </td>
              ))}
            </tr>
          )
        }
        return (
          <tr>
            <td colSpan={2}>Total</td>
            {Object.entries(totals).map(([key, value]) => (
              <td
                key={key}
                colSpan={1}
                className={`${css.row_total_count} ${css.click}`}
                data-testid='pietabular-totals-cell'
                onClick={() => handleRowClick(metaGroup, 'all', key === 'n' ? undefined : key)}
              >
                {value}
              </td>
            ))}
          </tr>
        )
      }
      return <tr></tr>
    }

    const handleRowClick = (
      metaKey: string,
      metaValue: string,
      countKey: string | null | undefined,
    ) => {
      let categoryObject = {
        topic: !filteredTopics
          ? filteredTopics
          : filteredTopics.map((t) => (t === 'uncategorized' ? null : t)),
      } as ClickFilter['categoryFilter']
      let allCountedValues: (string | null)[] = answerCategories
        ? answerCategories[defaultCountedKey || ''] || []
        : []
      allCountedValues = [...allCountedValues, null]
      if (defaultCountedKey && defaultCountedKey === 'topic') {
        if (countKey !== undefined) {
          categoryObject = { ...categoryObject, topic: [countKey] }
        } else {
          categoryObject = { ...categoryObject, topic: allCountedValues }
        }
      }
      if (defaultCountedKey && defaultCountedKey === 'sentiment') {
        if (countKey !== undefined) {
          categoryObject = { ...categoryObject, sentiment: [countKey] }
        } else {
          categoryObject = { ...categoryObject, sentiment: allCountedValues }
        }
      }
      if (defaultCountedKey && defaultCountedKey !== 'sentiment' && defaultCountedKey !== 'topic') {
        if (countKey !== undefined) {
          categoryObject = { ...categoryObject, custom: { [defaultCountedKey]: [countKey] } }
        } else {
          categoryObject = { ...categoryObject, custom: { [defaultCountedKey]: allCountedValues } }
        }
      }
      let metaValuesToFind = [] as (string | null)[]
      const metaValuesForKey = (metas || {})[metaKey]
      if (metaValuesForKey) {
        try {
          if (metaValue === 'all') {
            metaValuesToFind = [...metaValuesForKey, null]
          } else {
            metaValuesToFind = metaValuesForKey.filter(
              (value) => value.toLocaleLowerCase() === metaValue.toLocaleLowerCase(),
            )
          }
        } catch (error) {
          toast.error(errorMatchingMetaValues)
        }
      }
      setClickedMetaAndCountValues({
        metaFilter: { [metaKey]: metaValue === 'no group' ? [null] : metaValuesToFind },
        categoryFilter: categoryObject,
      })
    }

    function createTotalCountsForSlice(groupedCounts: GroupedDataWithCounts | null): Counts {
      if (groupedCounts) {
        return Object.entries<Counts>(groupedCounts).reduce((acc, groupedCount) => {
          const newTotalCount: Counts = { ...acc } // casting for typescript random error
          Object.entries(groupedCount[1]).forEach((count) => {
            if (newTotalCount[count[0]]) {
              newTotalCount[count[0]] = newTotalCount[count[0]] + count[1]
            } else {
              newTotalCount[count[0]] = count[1]
            }
          })
          return newTotalCount
        }, {} as Counts)
      }
      return {}
    }

    const handleGroupingChange = useCallback(
      (e: SelectChangeEvent<string>) => {
        saveSettingsProperty({ defaultMetaGroup: e.target.value })
      },
      [saveSettingsProperty],
    )

    const handleCountingChange = useCallback(
      (e: SelectChangeEvent<string>) => {
        saveSettingsProperty({ defaultCountedKey: e.target.value })
      },
      [saveSettingsProperty],
    )

    const inspectorFallBack = useMemo(
      () => <div style={{ color: 'red' }}>{errorLoadingInspector}</div>,
      [],
    )
    if (isLoading) return <LoadingIndicator />
    return (
      <FadeIn>
        {isInspectorModalOpen && !!clickedMetaAndCountValues && !!query && (
          <ErrorBoundary message={errorLoadingInspector} fallback={inspectorFallBack}>
            <OpenInspectorCntr
              inspected={`${
                defaultMetaGroup && tCommon('label.metaGroup') + ': ' + tData(defaultMetaGroup)
              } ${
                defaultCountedKey &&
                ', ' + tCommon('label.countedCategory') + ': ' + tCategory(defaultCountedKey)
              }`}
              onClose={() => setIsInspectorModalOpen(false)}
              query={{
                kpis: query.kpis,
                start_date: query.start_date,
                end_date: query.end_date,
                where_meta: {
                  ...query.where_meta,
                  ...clickedMetaAndCountValues.metaFilter,
                },
                category_grouping: clickedMetaAndCountValues.categoryFilter,
              }}
            />
          </ErrorBoundary>
        )}
        {!isReportMode && !isScreenMode && (
          <div className={css.title}>
            <GenericTableTitle
              title=''
              isLoading={false}
              loadingText=''
              useGrouping={true}
              useCounting={true}
              selectedGrouping={defaultMetaGroup || ''}
              selectedCounting={defaultCountedKey || ''}
              groupings={metaKeys || []}
              groupingsNamespace={TranslationNameSpace.DATA}
              countings={answerCategories ? Object.keys(answerCategories) : undefined}
              onGroupingChange={handleGroupingChange}
              onCountingChange={handleCountingChange}
            />
            {isRefetching && (
              <div className={css.refetching}>
                <CircularProgress
                  sx={{ color: '#fff' }}
                  thickness={2}
                  size={'1.7rem'}
                  style={{ opacity: 0.7 }}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={`${css.tbl} ${changeAnimate ? css.animate : ''} ${
            isReportMode ? css.reportTbl : ''
          }`}
        >
          {table}
        </div>
      </FadeIn>
    )
  },
)

Tabular.displayName = 'Tabular'
export default Tabular
