import React, { Component } from 'react';
import { Row, Card } from 'react-materialize';
import { get } from '../../../react-services/apiService';
import { deepCopy } from '../../../../utils';
import trackingService from '../../../react-services/trackingService';
import { TrackingEvent } from '../../../react-constants';

export default class CrmIntegrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crms: null,
      error: false,
    }
    this.recentlyActivated = [];
    this.setCrmActive = this.setCrmActive.bind(this)
  }

  componentDidMount() {
    get('GET_ACTIVEINTEGRATIONS').then((response) => {
      this.setState({
        crms: response
      })
    }).catch(error => {
      console.error(error)
      this.setState({ error: true })
    })
  }

  setCrmActive(name) {
    trackingService.track(TrackingEvent.ClickCRMIntegrationActivationSwitch, { crmProvider: name })
    this.recentlyActivated.push(name)
    get('GET_CRMAUTHLINK', { crm: name }).then(res => {
      window.open(res.authorizationUrl, '_blank')
      this.storeCrmToState(name)
    }).catch(error => {
      this.recentlyActivated.pop();
      console.error(error)
      this.setState({ error: true })
    })
  }

  storeCrmToState(crm) {
    let copyOfState = deepCopy(this.state.crms)
    copyOfState[crm] = true
    this.setState({
      crms: copyOfState
    })
  }

  render() {
    const getAdditionalMessage = (name, isActive) => {
      if (this.recentlyActivated.includes(name))
        return <span>Finish the integration and refresh this page.</span>
      if (isActive && !this.recentlyActivated.includes(name))
        return <span>This integration is active.</span>
    }

    const renderSwitchElement = (name, isActive) => {
      return (
        <div key={`crmSwitch-${name}`} className="switch">
          <label>
            {name.charAt(0).toUpperCase() + name.slice(1)}
            <input
              type="checkbox"
              checked={isActive ? 'checked' : ''}
              disabled={isActive ? 'disabled' : ''}
              onChange={() => { this.setCrmActive(name) }}
            />
            <span className="lever"></span>
            {getAdditionalMessage(name, isActive)}
          </label>
          <br /> <br />
        </div>
      )
    }

    return (
      <div className="admin-tools-crm-integrations">
        <Row>
          {this.state.crms !== null ?
            Object.entries(this.state.crms).map(crm => {
              return renderSwitchElement(...crm)
            })
            :
            <div>Loading ..</div>
          }
          {this.state.error &&
            <Card className="red white-text">An error occurred while trying to retrieve information on integrations. Please refresh and try again.</Card>
          }
        </Row>
      </div>
    );
  }
}