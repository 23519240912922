/* eslint-disable eqeqeq */
import { Query, KpiData } from '../../types';
import { deepCopy } from '../../utils';

function addRollingAverageToNumericData(data: KpiData.NumericData | null, prevData: KpiData.NumericData | null, timeRes: Query.TimeRes): KpiData.NumericData | null {
  if (data == null || prevData == null) return data
  
  const maxDifferenceX = getMaxdifference(timeRes)
  if (maxDifferenceX == null) return data

  const numericData: KpiData.NumericData = deepCopy(data)
  const numericKpiDatas: KpiData.NumericKpiData[] = data.series.map((kpiData: KpiData.NumericKpiData) => {
    return addRollingAverageToKpiData(kpiData, prevData, maxDifferenceX)
  })

  numericData.series = numericKpiDatas
  return numericData
}

function addRollingAverageToKpiData(kpiData: KpiData.NumericKpiData, prevData: KpiData.NumericData, maxDifferenceX: number): KpiData.NumericKpiData {
  const kpiDataCopy = deepCopy(kpiData)
  const matchingSeries = findmatchingSeriesFromPrevData(prevData, kpiData)
  if (matchingSeries) {
    kpiDataCopy.data = addRollingAvgToNumericKpiDataPoint(kpiData, matchingSeries, maxDifferenceX)
  }
  return kpiDataCopy
}

function findmatchingSeriesFromPrevData(prevData: KpiData.NumericData, kpiData: KpiData.NumericKpiData): KpiData.NumericKpiData | undefined {
  return prevData.series.find(p => p.name === kpiData.name)
}

function getMaxdifference(timeRes: Query.TimeRes): number | undefined {
  switch (timeRes) {
  case 'day': return 365
  case 'week': return 52
  case 'month': return 12
  case 'quarter': return 4
  case 'year': return 1
  default: return
  }
}

function addRollingAvgToNumericKpiDataPoint(numericKpiData: KpiData.NumericKpiData, prevNumericKpiData: KpiData.NumericKpiData, maxDifferenceX: number): KpiData.NumericKpiDataPoint[] {
  const numericKpiDataWithRollingAvg = numericKpiData.data.map((numericKpiDataPoint: KpiData.NumericKpiDataPoint) => {
    const numericKpiDataPointCopied = deepCopy(numericKpiDataPoint)
    const prevNumericKpiDataPointX = findPrevNumericKpiDataPointX(numericKpiDataPoint, prevNumericKpiData)
    if (prevNumericKpiDataPointX == null) return numericKpiDataPointCopied

    const points = createDataPointArrayForRollingAverage(prevNumericKpiData.data, prevNumericKpiDataPointX, maxDifferenceX)
    if (numericKpiDataPoint.data) {
      const { rollingAvg, rollingAvgN } = calculateRollingAverage(points)
      if (numericKpiDataPointCopied.data != null) {
        numericKpiDataPointCopied.data.rollingAvgN = rollingAvgN
        numericKpiDataPointCopied.data.rollingAvg = rollingAvg
      }
    }
    return numericKpiDataPointCopied
  });
  
  return numericKpiDataWithRollingAvg
}

function findPrevNumericKpiDataPointX(numericKpiDataPoint: KpiData.NumericKpiDataPoint, prevNumericKpiData: KpiData.NumericKpiData): number | undefined {
  const matchingPrevNumericKpiDataPoint = findMatchingPrevNumericDataPoint(numericKpiDataPoint, prevNumericKpiData.data)
  if (matchingPrevNumericKpiDataPoint == null) return
  const prevNumericKpiDataPointX = matchingPrevNumericKpiDataPoint.x
  if (prevNumericKpiDataPointX == null) return 
  return prevNumericKpiDataPointX
}

function createDataPointArrayForRollingAverage(prevNumericKpiDataPoints: KpiData.NumericKpiDataPoint[], prevNumericKpiDataPointX: number, maxDifferenceX = 12): KpiData.NestedNumericKpiDataPoint[] {
  const points: KpiData.NestedNumericKpiDataPoint[] = []
  prevNumericKpiDataPoints.forEach(point => {
    if (point.data != null && isPointInsideRange(point, prevNumericKpiDataPointX, maxDifferenceX)){
      points.push(point.data)
    }
  })
  return points
}

function isPointInsideRange(point: KpiData.NumericKpiDataPoint, maxValue: number, maxDifferenceX: number): boolean {
  const minValue = maxValue - maxDifferenceX
  return point.x > minValue && point.x <= maxValue
}

function findMatchingPrevNumericDataPoint(numericKpiDataPoint: KpiData.NumericKpiDataPoint, prevNumericKpiDataPoints: KpiData.NumericKpiDataPoint[]): KpiData.NumericKpiDataPoint | undefined {
  return prevNumericKpiDataPoints.find(prevNumericKpiDataPoint => prevNumericKpiDataPoint.category === numericKpiDataPoint.category)
}

function calculateRollingAverage(points: KpiData.NestedNumericKpiDataPoint[]): { rollingAvg: number, rollingAvgN: number } {
  const a = points.reduce(calculateSumAndN, { sum: 0, n: 0 })
  const rollingAvg = a.sum / a.n
  return { rollingAvg, rollingAvgN: a.n }
}

function calculateSumAndN(accumulator: { sum: number, n: number }, currentDataPoint: KpiData.NestedNumericKpiDataPoint) {
  return ({
    sum: accumulator.sum + (currentDataPoint.avg as number) * currentDataPoint.n,
    n: accumulator.n + currentDataPoint.n
  })
}


export {
  addRollingAverageToNumericData,

  // export for testing:
  addRollingAvgToNumericKpiDataPoint,
  findPrevNumericKpiDataPointX,
  createDataPointArrayForRollingAverage,
  isPointInsideRange,
  findMatchingPrevNumericDataPoint,
  calculateRollingAverage
}