import React from 'react'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './CellCustomizationOptions.module.scss'

const CellCustomizationOptions = () => {
  return (
    <div className={`${modal_css.max}`}>
      Select what details to show
      <div className={`${css.list}`}>
        <CheckBoxItem
          text='Show trends'
          data_testid='showTrends'
          id='showTrends'
          settingsName='trends'
          width='third'
        />
        <CheckBoxItem
          text='Show total (avg)'
          data_testid='showTotal'
          id='showTotal'
          settingsName='show_total'
          width='third'
        />
        <CheckBoxItem
          text='Show n (count)'
          data_testid='showCount'
          id='showCount'
          settingsName='show_total_n'
          width='third'
        />
        <CheckBoxItem
          text='Horizontal scrolling'
          data_testid='xAxisScrolling'
          id='xAxisScrolling'
          settingsName='xAxisScrolling'
          width='third'
        />
        <CheckBoxItem
          text='Collapse height'
          data_testid='isTableCollapsed'
          id='isTableCollapsed'
          settingsName='isTableCollapsed'
          width='third'
        />
      </div>
    </div>
  )
}

export default CellCustomizationOptions
