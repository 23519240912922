import React from 'react';
import { HELPDESKEMAIL } from '../../../react-constants/emails';
import { PropTypes } from 'prop-types';

const HelpdeskInfo = ({subject}) => {
  function getMailtoLink() {
    return `mailto: ${HELPDESKEMAIL}?Subject=WheelQ Application Error: ${subject}`;
  }

  return (
    <a href={getMailtoLink()} data-testid="helpdeskInfo">
      Send an email to helpdesk.
    </a>
  );
}

HelpdeskInfo.propTypes = {
  subject: PropTypes.string.isRequired,
}

HelpdeskInfo.defaultProps = {
  subject: "No details given by the application."
}

export default HelpdeskInfo;