import React, { useState } from 'react'
import { Payload, WhereMeta } from '../../../../../../types'
import { post } from '../../../../../react-services/apiService'
import { datePlusDays, getDateNow } from '../../../../../react-services/datesService'
import { createAndDownloadBlob, downloadCsv } from '../../../../../react-services/exportService'
import { postDashboardExport } from '../../../../../react-services/queryService'
import MainButton from '../../../../_shared/Buttons/MainButton'
import DoubleModalMain from '../../../../_shared/Modals/DoubleModalMain'
import ExportModal from '../../../../_shared/Modals/ExportModal'
import ExportModule from '../../Export/ExportModule'
import css from './ValueInspector.module.scss'

type NumericInspectorProps = {
  query: {
    start_date: string
    end_date: string
    filter: WhereMeta
    kpis: number[]
    surveyid?: number
  }
  onClose: () => void
  inspected: string
  isPreviewMode: boolean
}

const NumericInspector = ({ query, onClose, inspected, isPreviewMode }: NumericInspectorProps) => {
  // const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false)
  const [isBatchExporting, setIsBatchExporting] = useState<boolean>(false)
  // const [isReady, setIsReady] = useState<boolean>(true);
  // const [header, setHeaders] = useState<string[] | null>(null);
  // const [data, setData] = useState<NumericRowObject[] | null>(null);

  // useEffectOnce(() => fetchNumericData())
  const hasExportFilter = !(
    query.filter &&
    Object.values(query.filter).length === 1 &&
    (JSON.stringify(Object.values(query.filter)[0]) === JSON.stringify(['']) ||
      JSON.stringify(Object.values(query.filter)[0]) === JSON.stringify([]))
  )

  const getDynamicColumnStyles = () => {
    return css.many_meta_columns
  }

  const handleExportData = (query: Payload, fileFormat?: string) => {
    if (fileFormat === 'xlsx') {
      post('POST_ADMINEXPORT_EXCEL', query, { responseType: 'blob' }).then((data: Blob) => {
        createAndDownloadBlob(data, 'export.xlsx')
      })
    } else {
      postDashboardExport(query, {}).then((data: string[]) => {
        downloadCsv(data, 'export.csv')
      })
    }
  }

  return (
    <div className={`open-value-inspector-container ${getDynamicColumnStyles()}`}>
      <DoubleModalMain onClose={onClose} double={false} showMask={true}>
        <div data-testid='inspector-header'>
          <div className={css.header}>
            <h4 className='value-inspector-title' data-testid='valueInspectorTitle'>
              Data Inspector
              {/* <InfoBar handleInfoIconClick={() => setIsInfoOpen(prev => !prev)} isInfoOpen={isInfoOpen} /> */}
            </h4>
          </div>
          {/* {isInfoOpen
            ?
            <p>{inspectorInfo}</p>
            :
            null
          } */}
          <p style={{ fontSize: '1.1em' }}>
            <b>Inspected value: </b>
            <span data-testid='openValueInspectorValue'>{inspected}</span>
          </p>
        </div>

        {/* {!isReady && <LoadingIndicator />} */}

        <ExportModule query={query} useHiddenFilters />

        <br />
        <div className='row'>
          {hasExportFilter && !isPreviewMode ? (
            <MainButton
              iconPosition='left'
              icon={'import_export'}
              text='Export data'
              style={{ marginRight: '15px' }}
              onClick={() => setIsExportModalOpen(true)}
            />
          ) : (
            <div></div>
          )}
        </div>
        {isExportModalOpen && !isPreviewMode && (
          <ExportModal
            dataSelectionType='kpis'
            onExport={(_somequery, fileFormat) => {
              handleExportData({ ...query, where_meta: query.filter }, fileFormat)
            }}
            onClose={() => {
              setIsExportModalOpen(false)
            }}
            hasExcelOption={true}
            query={{
              end_date: query.end_date ? query.end_date : getDateNow(),
              start_date: query.start_date ? query.start_date : datePlusDays(getDateNow(), -365),
              where_meta: query.filter,
              kpis: query.kpis,
            }}
            areKpisAndFiltersHidden={true}
            isBatchExporting={isBatchExporting}
            waitingForBatchResponse={false}
            missingData={false}
            wasLastPage={false}
            onCloseNotification={() => setIsBatchExporting(false)}
          />
        )}
      </DoubleModalMain>
    </div>
  )
}

export default NumericInspector
