import { WhereMeta } from '../../../../../types'
import { idAndNameKpi } from '../../../../stores/useOpenKpis'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import {
  GenericQuery,
  ModuleBase,
  ModuleDefaultSettings,
} from '../_shared/ModuleSettings/settingsTypes'

export type FrequencyModule = {
  type: 'freq'
  title: string
  sharedFilter?: SharedFilter
  options?: FrequencyModuleSettings & ModuleDefaultSettings
  selections?: {
    [kpiId: string]: boolean
  }
  numericquery?: {
    min?: number
    max?: number
  }
  filterGrouping?: string // e.g. metakey
  grouping?: string // e.g. metakey
  chartType?: FrequencyChartType
  queryType?: FrequencyQueryType
  dataPoints?: number
  order: FrequencyOrder
  settings: Record<string, unknown>[]
  horizontalBars?: boolean // deprecated, old settings property
  hasComparison: boolean
  KPI?: number // // deprecated, old settings property
  limitValues?: {
    max?: number | string
    min?: number | string
  }
} & ModuleBase

export type FrequencyModuleSettings = {
  freq_format?: FreqFormat
  pieType?: FrequencyPieType
  openKpis?: idAndNameKpi[]
  comparisonType?: FrequencyComparisonType | null
  comparisonDays?: number
  moduleHeight?: number
  limitTarget?: FreqFormat | NumericKpiFormat
  isSoftMin?: boolean
  isSoftMax?: boolean
  numericKpiMetaGrouping?: string
  numericKpiDataFormat?: NumericKpiFormat
}

export type FrequencyModuleQuery = {
  [key: string]: unknown
}

export enum FrequencyChartType {
  COLUMN = 'column',
  BAR = 'bar',
  PIE = 'pie',
}

export enum FrequencyQueryType {
  META = 'meta',
  NUMERIC = 'numeric',
  OPEN = 'open',
  NUMERIC_KPI = 'numeric kpi',
}

export enum FrequencyOrder {
  NONE = 'none',
  INCREASING = 'inc',
  DESCENDING = 'dec',
}

export enum FreqFormat {
  N = 'n',
  PERCENTAGE_ALL = 'percentage_all',
  PERCENTAGE_RESPONDENT = 'percentage_respondent',
}

export enum NumericKpiFormat {
  PERCENT = 'percent',
  N = 'n',
  AVG = 'avg',
  SUM = 'sum',
}

export enum FrequencyPieType {
  HOLLOW = 'hollow',
  FULL = 'full',
}

export enum FrequencyComparisonType {
  DATE = 'date',
  META = 'meta',
}

export enum FrequencyUnit {
  PERCENT = '%',
  N = 'n',
  SUM = 'sum',
  AVG = 'avg',
  NONE = '',
}

export type DeprecatedBarModule = {
  type: 'bar'
  title: string
  query?: GenericQuery
  sharedFilter?: SharedFilter
  small: true
  filterGrouping?: string
  selections: {
    [kpiId: string]: boolean
  }
  switchCategorySeries: true
  yAxis?: 'n' | 'avg' | 'sum'
  options: ModuleDefaultSettings
  horizontalBars: boolean
  settings?: SharedFilter['filters']
  limitValues?: {
    max?: number | string
    min?: number | string
  }
} & ModuleBase

export type QueryBase = {
  start_date: string
  end_date: string
  where_meta: WhereMeta
}
