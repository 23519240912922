import React from 'react'
import { useTopBottomSettings } from './Context/TopBottomSettingsContext'
import css from './TopBottomModule.module.scss'
import {
  selectTopBotColumnNote,
  selectTopBotDataDisplayNote,
  selectTopBotMaxNumberOfResultsNote,
  topBotNotification,
} from './TopBottomNotifications'

const TopBottomNotification = () => {
  const [{ selectedColumns, topBottomDataDisplay, topBottomMax }] = useTopBottomSettings()

  return (
    <div className={css.top_bottom_notification} data-testid='top-bottom-notifications'>
      <div className={css.note_title}>
        <i className='material-icons'>list</i>
        <span> Top Bottom Notification</span>
      </div>
      <div className={css.note_content}>
        <span>
          {topBotNotification} <i className='material-icons'>settings</i>{' '}
        </span>
        <ul>
          {!selectedColumns.length && <li>{selectTopBotColumnNote}</li>}
          {!topBottomDataDisplay && <li>{selectTopBotDataDisplayNote}</li>}
          {!topBottomMax && <li>{selectTopBotMaxNumberOfResultsNote}</li>}
        </ul>
      </div>
    </div>
  )
}

export default TopBottomNotification
