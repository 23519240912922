import React, { useState } from 'react'
import { useSummaryData } from './Context/SummaryDataContext'
import { useSummaryFilters } from './Context/SummaryFiltersContext'
import { isTemplateUser } from '../../../../react-services/authService'
import Filters from './Filters/Filters'

import css from './SummaryModule.module.scss'
import useSummaryLanguage from './useSummaryLanguage'

const SummaryHeader = () => {
  const [isEditTitleOpen, setIsEditTitleOpen] = useState<boolean>(false)
  const [{ summaryTitle, saveSettings }, setSummaryData] = useSummaryData()
  const [{ summarySettings, language }] = useSummaryFilters()
  const { t } = useSummaryLanguage(language)
  const handleSummaryTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSummaryTitle = event.currentTarget.value
    setSummaryData((prev) => ({
      ...prev,
      summaryTitle: newSummaryTitle,
    }))
  }

  const handleSummaryTitleSave = () => {
    if (saveSettings) {
      const newSettings = {
        ...summarySettings,
        summaryTitle: summaryTitle,
      }
      saveSettings(newSettings)
    }
    setIsEditTitleOpen(false)
  }

  return (
    <div className={css.summary_module_header}>
      {isEditTitleOpen ? (
        <>
          {isTemplateUser() && (
            <i
              className={`material-icons ${css.edit}`}
              data-testid='summary-title-done'
              onClick={handleSummaryTitleSave}
            >
              done
            </i>
          )}
          <input
            data-testid='summary-edit-title'
            type='text'
            value={summaryTitle}
            onChange={(event) => handleSummaryTitle(event)}
          />
        </>
      ) : (
        <>
          {isTemplateUser() && (
            <i
              className={`material-icons ${css.edit}`}
              data-testid='summary-title-edit'
              onClick={() => setIsEditTitleOpen(true)}
            >
              edit
            </i>
          )}
          <span className={css.title}> {summaryTitle}</span>
          {!!summarySettings?.subtitle && (
            <span className={css.title_second}>{t(summarySettings.subtitle)}</span>
          )}
        </>
      )}
      <Filters />
    </div>
  )
}

export default SummaryHeader
