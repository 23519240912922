import { getDateNow, datePlusDays } from "../react-services/datesService";
import { DASHBOARDCONSTANTS } from "./dashboards";

function getDefaultStartAndEndDates() {
  return {
    start_date: datePlusDays(getDateNow(), -365),
    end_date: getDateNow(),
  }
}

const EXPORT_V3_PAYLOAD = (() => ({
  ...getDefaultStartAndEndDates(),
  kpis: [
    // as numbers
  ],
  filter: {},
}))();

const ROW_DATA_PAYLOAD = (() => ({
  start_date: DASHBOARDCONSTANTS.RESPONSE_RATE_START_DATE,
  end_date: getDateNow(),
  surveyid: null,
  where_meta: {},
}))();

const QUERY_TEXTUAL_PAYLOAD = (() => ({
  ...getDefaultStartAndEndDates(),
  calculated_kpis: {},
  where_numeric: {},
  trend_line: false,
  metacolumns: [],
  kpis: [
    // as KPI objects
    // {
    //   id: 0,
    //   name: "Open KPI"
    // }
  ],
  grouping: "",
  where_meta: {},
  page: "",
  time_res: "",
  where_textual: {},
  cache: true,
}))();

const QUERY_NUMERIC_PAYLOAD = (() => ({
  ...getDefaultStartAndEndDates(),
  calculated_kpis: {},
  trend_line: false,
  kpis: [
    // as KPI objects
    // {
    //   id: 0,
    //   name: "Num KPI"
    // }
  ],
  where_numeric: {},
  time_res: "month",
  where_textual: {},
  cache: true,
}))();

const QUERY_XYZ_PAYLOAD = (() => ({
  ...getDefaultStartAndEndDates(),
  where_meta: {},
}))();

export {
  EXPORT_V3_PAYLOAD,
  QUERY_TEXTUAL_PAYLOAD,
  QUERY_NUMERIC_PAYLOAD,
  ROW_DATA_PAYLOAD,
  QUERY_XYZ_PAYLOAD
}
