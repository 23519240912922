import React from 'react'
import { Pagination, Icon } from 'react-materialize'
import css from './CustomPagination.module.scss'

type CustomPaginationProps = {
  dataLength: number
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  itemsPerPage: number
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>
  sliceStart: number | null
  sliceEnd: number | null
}

const CustomPagination = ({
  dataLength,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
  sliceStart,
  sliceEnd,
}: CustomPaginationProps) => {
  return (
    <div className={css.cntr}>
      <Pagination
        className={css.numbers}
        activePage={currentPage}
        maxButtons={10}
        items={Math.ceil(dataLength / itemsPerPage)}
        leftBtn={<Icon>chevron_left</Icon>}
        rightBtn={<Icon>chevron_right</Icon>}
        onSelect={(number: number) => setCurrentPage(number)}
      />
      <label className={css.page_input_cntr}>
        Page
        <input
          data-testid='pagination-page-input'
          id='pagination-page-input'
          className={css.input}
          value={currentPage}
          type='number'
          onChange={(e) =>
            !isNaN(parseInt(e.currentTarget.value)) &&
            setCurrentPage(parseInt(e.currentTarget.value))
          }
        />
      </label>
      <label className={css.items_input_cntr}>
        Rows
        <input
          data-testid='pagination-rows-input'
          className={css.input}
          id='pagination-page-input'
          value={itemsPerPage}
          type='number'
          onChange={(e) =>
            !isNaN(parseInt(e.currentTarget.value)) &&
            setItemsPerPage(parseInt(e.currentTarget.value))
          }
        />
      </label>
      <div className={css.current_items_cntr}>
        {(sliceStart || sliceEnd || dataLength > 0) && (
          <label className={css.current_items_label}>
            {`${sliceStart} - ${sliceEnd} from ${dataLength}`}
          </label>
        )}
      </div>
    </div>
  )
}

export default CustomPagination
