import React from 'react';
import { Table } from 'react-materialize';

const CsvTable = ({headers, content}) => {
  return (
    <Table striped responsive>
      <thead>
        <tr>
          {headers.map((header, index) => {
            return (<th key={index}>{header}</th>)
          })}
        </tr>
      </thead>

      <tbody>
        {content}
      </tbody>
    </Table>
    
  );
};

export default CsvTable;