import { GenericConfig, Kpi, Query, WhereMeta } from '../../../../../types'
import { CustomerPathConfig } from '../../../../stores/useConfigCustomerPath'
import { Breakpoint } from '../Pietabular/pietabularTypes'
import { LabelPosition, LineChartDataFormats, TargetColor } from '../Line/lineChartTypes'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export enum WheelTheme {
  STANDARD = 'standard',
  STANDARD_COLORED = 'standard colored',
  SHADES = 'shades',
  HEAT = 'heat',
}

export type ModuleQuery = {
  start_date: string
  end_date: string
  where_meta: WhereMeta
  kpis: Kpi.Kpi[]
  calculated_kpis: GenericConfig.CalculatedKpis
}

export type WheelModule = {
  type: 'wheel'
  query?: ModuleQuery
  options?: WheelModuleSettings & ModuleDefaultSettings
  limitValues?: {
    min?: string
    max?: string
  }
} & ModuleBase

export type WheelModuleSettings = {
  isCustomerPathConfigSelected?: boolean
  moduleWheelConfig?: CustomerPathConfig
  showWheel?: boolean
  showLine?: boolean
  showCaption?: boolean
  wheelWidthPercentage?: number
  showTotals?: boolean
  breakpoints?: Breakpoint[]
  dataFormat?: LineChartDataFormats
  dateGroup?: Query.TimeRes
  wheelTheme?: WheelTheme
  /** @deprecated  deprecated, use general moduleheight */
  moduleHeight?: number
  kpiTargetFrom?: string
  kpiTargetTo?: string
  kpiTargetLabel?: string
  kpiTargetLabelPosition?: LabelPosition
  kpiTargetAreaColor?: TargetColor
  kpiTargetCustomColor?: string
  kpiTargetDataFormat?: Exclude<LineChartDataFormats, LineChartDataFormats.ROLLING_AVG>
  isSoftMin?: boolean
  isSoftMax?: boolean
}

export type SunburstDataWithSums = {
  id: string
  parent: string
  name: string
  sum: number
  n: number
  kpisN: number
  min?: number
  minN?: number
  minSum?: number
  max?: number
  maxN?: number
  maxSum?: number
}

export type ExtendedPointOptionsObject = Highcharts.PointOptionsObject & {
  realValue: number | string
  n: number
  level: number
  kpiId: number | null
  limitValues?: {
    min?: number
    max?: number
  }
}

export type ExtendedPoint = Highcharts.Point & {
  realValue: number | string
}

export type LineSeriesData = {
  [date: string]:
    | {
        avg: number | null
        sum: number
        n: number
      }
    | undefined
}

export type InspectorQuery = {
  start_date: string
  end_date: string
  filter: WhereMeta
  kpis: number[]
  surveyid?: number
}

export type SelectedPoint = {
  id: number | null
  name: string
  level: number
}
