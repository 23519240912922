import { Dashboard } from '../../../../../types'
import { FilterArray } from '../../../../react-services/filterService'
import { AlertModule } from '../FeedbackAlerts/alertTypes'
import { DeprecatedBarModule, FrequencyModule } from '../Frequency/frequencyModuleTypes'
import { LineChartModule } from '../Line/lineChartTypes'
import { NumberTrendModule, SharedFilter } from '../NumberTrend/numbertrendTypes'
import { OpenModule } from '../Open/openTypes'
import { PictureModule } from '../Picture/pictureTypes'
import { PietabularModule } from '../Pietabular/pietabularTypes'
import { RadarModule } from '../Radar/radarTypes'
import { ReportsOverViewModule } from '../ReportsOverview/reportsOverViewTypes'
import { SummaryModule } from '../Summary/summaryTypes'
import { DeprecatedTableModule, TabularModule } from '../Tabular/tabularTypes'
import { TextBoxModule } from '../TextBox/textBoxTypes'
import { TopBottomModule } from '../TopBottom/topBottomTypes'
import { WheelModule } from '../Wheel/wheelModuleTypes'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type GroupModule = {
  type: 'group2' | 'group'
  modules?: Module[]
  options?: GroupModuleSettings & ModuleDefaultSettings
  settings?: FilterArray
  timeframedays?: number
  timeframedays_future?: number
  quicktimeframebutton?: QuickTimeframe
  hasOrganizationFilters?: boolean
  autoTimeScale?: boolean
  hidefilters?: boolean
  /** Don't use booleans, it is depcated, user proper type */
  customfilters?: CustomFilter
  sharedFilter?: SharedFilter
  livedata?: boolean
  isNested?: boolean
  dynamicFilters?: unknown
  focusedFilter?: unknown
} & ModuleBase

export type GroupModuleSettings = {
  isKpiContextEnabled?: boolean
  isConfigKpiExtractionEnabled?: boolean
  isQuickTimeButtonsEnabled?: boolean
  isTimeDisplayEnabled?: boolean
  isChipsEnabled?: boolean
  style?: GroupModuleThemes
}

export enum QuickTimeframe {
  D = 'd',
  W = 'w',
  M = 'm',
  Q = 'q',
  Y = 'y',
  WTD = 'wtd',
  MTD = 'mtd',
  QTD = 'qtd',
  YTD = 'ytd',
  LW = 'lw',
  LM = 'lm',
  LQ = 'lq',
  LY = 'ly',
  NONE = 'none',
}

export enum ModuleType {
  ALERT = 'alert',
  BAR = 'bar',
  BUBBLE = 'bubble',
  FREQ = 'freq',
  GROUP2 = 'group2',
  GROUP = 'group',
  LINE = 'line',
  LISTNUMERIC = 'listnumeric',
  NUMBERTREND = 'numbertrend',
  OPEN = 'open',
  PICTURE = 'picture',
  PIETABULAR = 'pietabular',
  RADAR = 'radar',
  REPORTS = 'reports_overview',
  SUMMARY = 'summary',
  TABULAR = 'tabular',
  TABLE = 'table', // DEPRECATED
  TEXTBOX = 'textbox',
  WHEEL = 'wheel',
}

export enum CustomFilter {
  NONE = 'none',
  MERGE = 'merge',
  CUSTOM = 'custom',
  CUSTOM_TIMEFRAME = 'custom_timeframe',
}

export enum RendererAction {
  MOVE_UP = 'move_up',
  MOVE_DOWN = 'move_down',
  DELETE = 'delete',
  SAVE = 'save',
  SAVING = 'saving',
}

export enum GroupModuleThemes {
  DEFAULT = 'default',
  STYLES2 = 'group-style2',
}
export type Module =
  | AlertModule
  | DeprecatedBarModule
  | Dashboard.BubbleModule
  | FrequencyModule
  | GroupModule
  | LineChartModule
  | NumberTrendModule
  | OpenModule
  | PictureModule
  | PietabularModule
  | RadarModule
  | ReportsOverViewModule
  | SummaryModule
  | DeprecatedTableModule
  | TabularModule
  | TopBottomModule
  | TextBoxModule
  | WheelModule

export enum SavingStatus {
  MANUAL = 'manual',
  NONE = 'none',
  INIT = 'init',
  WAITING = 'waiting',
  SAVING = 'saving',
  FINISHED = 'finished',
}

export enum QueueStatus {
  FETCHING = 'fetching',
  IDLE = 'idle',
  WAITING_TO_FETCH = 'waiting_to_fetch',
  NOT_IMPLEMENTED = 'not_implemented',
}

export type RenderQueueModule = {
  id: string
  groupId: string
  status: QueueStatus
  type: Module['type']
}

export type RenderList = {
  [id: string]: RenderQueueModule
}
