import React, { useCallback, useEffect, useRef, useState } from 'react'
import useNewOpenAnswers, { OpenAnswersRequest } from '../../../../../../stores/useNewOpenAnswers'
import FileSaver from 'file-saver'
import useOpenKpis from '../../../../../../stores/useOpenKpis'
import Sheetjs from 'sheetjs-style'
import useReportingFilters from '../../../../../../stores/useReportingFilters'

import { OpenAnswer } from '../../../Pietabular/pietabularTypes'
import { ExcelCompletedSurveyRow, ExcelRow } from '../inspectorTypes'
import {
  errorExportingAnswers,
  infoInspectorMetas,
  infoInspectorMetasDisabled,
  infoReportingFilter,
  infoReportingFilterDisabled,
} from '../notifications'
import useMetaKeys from '../../../../../../stores/useMetaKeys'
import useCommonDbSettingsConfig from '../../../../../../stores/useCommonDbSettingsConfig'
import LoadingIndicator from '../../../../../_shared/Infos/LoadingIndicator'
import { useEvent } from 'react-use'
import { useToastId } from '../../../../../common/Notification/NotificationContext'
import { toast } from 'react-toastify'

import css from './OpenInspectorExportView.module.scss'

const tableView = 'tableView'
const completedSurveysView = 'completedSurveysView'
const inspectorMetas = 'inspectorMetas'
const reportingMetas = 'reportingMetas'
const allMetas = 'allMetas'
const excel = 'excel'
const csv = 'csv'

type OpenInspectorSingleViewProps = {
  handleClosingExportView: () => void
  query: OpenAnswersRequest
  customFilterAnswersFunction?: (data: OpenAnswer[]) => OpenAnswer[]
}

type MetaNameAndSelected = {
  name: string
  selected: boolean
}

enum RowView {
  ANSWER = 'answer',
  COMPLETEDSURVEY = 'completedSurvey',
}

enum FileType {
  XLSX = 'xlsx',
  CSV = 'csv',
}

enum MetaSelection {
  INSPECTOR = 'inspector',
  REPORTING = 'reporting',
  ALL = 'all',
}

enum ExportStatus {
  NONE = 'none',
  STARTED = 'started',
  DOWNLOADING = 'downloading',
  FINISHED = 'finished',
}

const OpenInspectorExportView = ({
  handleClosingExportView,
  query,
  customFilterAnswersFunction,
}: OpenInspectorSingleViewProps) => {
  const exportingTimeoutRef = useRef<NodeJS.Timeout>()
  const [nextPage, setNextPage] = useState<number>(1)
  const [selectedRowType, setSelectedRowType] = useState<RowView>(RowView.ANSWER)
  const [metaSelection, setMetaSelection] = useState<MetaSelection>(MetaSelection.INSPECTOR)
  const [selectedMetas, setSelectedMetas] = useState<MetaNameAndSelected[] | null>(null)
  const [selectedFileType, setSelectedFileType] = useState<FileType>(FileType.XLSX)
  const [exportStatus, setExportStatus] = useState<ExportStatus>(ExportStatus.NONE)
  const [exportError, setExportError] = useState<string>('')

  let exportQuery: OpenAnswersRequest | null = null
  if (exportStatus !== ExportStatus.NONE && exportStatus !== ExportStatus.FINISHED) {
    exportQuery = { ...query, pagination: { page_size: 2000, page_number: nextPage } }
  }
  const { toastifyId } = useToastId()

  const {
    answers: fetchedAnswers,
    pagination,
    error: answersError,
  } = useNewOpenAnswers(exportQuery)

  let answers = null as OpenAnswer[] | null
  if (fetchedAnswers && customFilterAnswersFunction) {
    answers = customFilterAnswersFunction(fetchedAnswers)
  } else {
    answers = fetchedAnswers
  }

  const {
    metas: reportingMetaFilters,
    isLoading: isLoadingReportingFilters,
    error: reportingFiltersError,
  } = useReportingFilters()
  const { openKpis, isLoading: isLoadingOpenKpis, error: openKpisError } = useOpenKpis()
  const { filteredMetaKeys, loading: isLoadingMetaKeys, error: metaKeysError } = useMetaKeys()
  const {
    config,
    isLoading: isLoadingCommonDbSettings,
    error: errorCommonDbSettings,
  } = useCommonDbSettingsConfig()
  const inspectorMetaKeys = config?.inspector_open_multi?.meta_columns

  const isLoading =
    isLoadingMetaKeys || isLoadingOpenKpis || isLoadingReportingFilters || isLoadingCommonDbSettings

  const isReportingFilterDisabled =
    !reportingMetaFilters || !Object.keys(reportingMetaFilters).length
  const isInspectorFilterDisabled = !inspectorMetaKeys || !inspectorMetaKeys.length
  const isExportDisabled = exportStatus !== ExportStatus.NONE

  const onKeyDown = useCallback(({ key }) => {
    if (key === 'ArrowLeft' && handleClosingExportView) handleClosingExportView()
  }, [])

  useEvent('keydown', onKeyDown)

  useEffect(() => {
    if (exportError) toast.error(exportError, { containerId: toastifyId })
  }, [exportError])
  useEffect(() => {
    if (answersError) toast.error(answersError, { containerId: toastifyId })
  }, [answersError])
  useEffect(() => {
    if (reportingFiltersError) toast.error(reportingFiltersError, { containerId: toastifyId })
  }, [reportingFiltersError])
  useEffect(() => {
    if (openKpisError) toast.error(openKpisError, { containerId: toastifyId })
  }, [openKpisError])
  useEffect(() => {
    if (metaKeysError) toast.error(metaKeysError, { containerId: toastifyId })
  }, [metaKeysError])
  useEffect(() => {
    if (errorCommonDbSettings) toast.error(errorCommonDbSettings, { containerId: toastifyId })
  }, [errorCommonDbSettings])

  useEffect(() => {
    let newSelectedMetaKeys = [] as MetaNameAndSelected[]
    if (metaSelection === MetaSelection.ALL && filteredMetaKeys) {
      newSelectedMetaKeys = filteredMetaKeys.map<MetaNameAndSelected>((meta) => ({
        name: meta,
        selected: true,
      }))
    }
    if (metaSelection === MetaSelection.INSPECTOR && inspectorMetaKeys) {
      newSelectedMetaKeys = inspectorMetaKeys.map<MetaNameAndSelected>((meta) => ({
        name: meta.name,
        selected: true,
      }))
    }
    if (metaSelection === MetaSelection.REPORTING && reportingMetaFilters) {
      newSelectedMetaKeys = Object.keys(reportingMetaFilters).map<MetaNameAndSelected>((meta) => ({
        name: meta,
        selected: true,
      }))
    }
    setSelectedMetas(newSelectedMetaKeys)
  }, [metaSelection, isLoading])

  useEffect(() => {
    if (exportStatus === ExportStatus.NONE) {
      setNextPage(1)
    }
  }, [exportStatus])

  useEffect(() => {
    if (answers && pagination && exportStatus !== ExportStatus.FINISHED) {
      handleExportingAnswers()
    }
    return () => exportingTimeoutRef.current && clearTimeout(exportingTimeoutRef.current)
  }, [answers, pagination])

  useEffect(() => {
    if (inspectorMetaKeys && inspectorMetaKeys.length)
      return setMetaSelection(MetaSelection.INSPECTOR)
    if (
      (!inspectorMetaKeys || !inspectorMetaKeys.length) &&
      reportingMetaFilters &&
      Object.keys(reportingMetaFilters).length
    ) {
      setMetaSelection(MetaSelection.REPORTING)
    } else {
      setMetaSelection(MetaSelection.ALL)
    }
  }, [filteredMetaKeys, inspectorMetaKeys, reportingMetaFilters])

  const handleRadioButtonClick = (name: string) => {
    if (exportStatus === ExportStatus.STARTED || exportStatus === ExportStatus.DOWNLOADING) return
    if (name === tableView) setSelectedRowType(RowView.ANSWER)
    if (name === completedSurveysView) setSelectedRowType(RowView.COMPLETEDSURVEY)
    if (name === inspectorMetas) setMetaSelection(MetaSelection.INSPECTOR)
    if (name === reportingMetas) setMetaSelection(MetaSelection.REPORTING)
    if (name === allMetas) setMetaSelection(MetaSelection.ALL)
    if (name === excel) setSelectedFileType(FileType.XLSX)
    if (name === csv) setSelectedFileType(FileType.CSV)
    setExportStatus(ExportStatus.NONE)
  }

  const handleClickExport = () => {
    if (exportStatus === ExportStatus.STARTED || exportStatus === ExportStatus.DOWNLOADING) return
    setExportStatus(ExportStatus.STARTED)
  }

  const handleExportingAnswers = () => {
    if (!answers || !pagination || exportStatus === ExportStatus.FINISHED) return
    setExportStatus(ExportStatus.DOWNLOADING)
    if (exportingTimeoutRef.current) clearTimeout(exportingTimeoutRef.current)
    exportingTimeoutRef.current = setTimeout(() => {
      try {
        if (!answers) return
        const a = answers[0]
        if (!a) return

        let convertedAnswers = [] as ExcelRow[]
        if (selectedRowType === RowView.ANSWER) {
          convertedAnswers = answers.map((a) => handleConvertingOpenAnswerToAnswerRow(a))
        }
        if (selectedRowType === RowView.COMPLETEDSURVEY) {
          convertedAnswers = handleGroupingByCompletedSurvey(answers)
        }

        const ws = Sheetjs.utils.json_to_sheet(convertedAnswers)
        let dataToSave: string | Blob = ''
        if (selectedFileType === FileType.CSV) {
          const csvText = Sheetjs.utils.sheet_to_csv(ws, { FS: ';' })
          const fileType = 'text/plain;charset=UTF-8'
          dataToSave = new Blob([csvText], { type: fileType })
        }
        if (selectedFileType === FileType.XLSX) {
          const excelBuffer = Sheetjs.write(
            {
              Sheets: { data: ws },
              SheetNames: ['data'],
            },
            { bookType: selectedFileType, type: 'array' },
          )
          const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          dataToSave = new Blob([excelBuffer], { type: fileType })
        }

        FileSaver.saveAs(dataToSave, 'openanswers' + nextPage + '.' + selectedFileType)
        if (nextPage < pagination.total_pages) {
          setNextPage((prev) => prev + 1)
          setExportStatus(ExportStatus.STARTED)
        } else {
          setExportStatus(ExportStatus.FINISHED)
        }
      } catch (error) {
        setExportError(errorExportingAnswers)
      }
    }, 1000)
  }

  const handleConvertingOpenAnswerToAnswerRow = (answer: OpenAnswer) => {
    let excelRow = {} as ExcelRow
    if (openKpis) {
      excelRow.date = answer.date || ''
      excelRow.kpi_name = openKpis.find((kpi) => kpi.id === answer.id)?.name || ''
      excelRow.answer_id = answer.answer_id
      excelRow.completed_survey_id = answer.completed_survey_id
      excelRow.answer = answer.answer
    }
    excelRow = handleMetaFiltering(answer, excelRow)
    return excelRow
  }

  const handleGroupingByCompletedSurvey = (answers: OpenAnswer[]) => {
    const groupedAnswers = [] as ExcelCompletedSurveyRow[]
    if (!openKpis) return groupedAnswers
    const foundKpis = openKpis.filter((kpi) => answers.map((a) => a.id).find((id) => id === kpi.id))
    answers.forEach((answer, _i, array) => {
      if (groupedAnswers.find((a) => a.completed_survey_id === answer.completed_survey_id)) return
      const matchingAnswers = array.filter(
        (a) => a.completed_survey_id === answer.completed_survey_id,
      )
      if (matchingAnswers.length) {
        let excelRow = {} as ExcelCompletedSurveyRow
        excelRow.date = matchingAnswers[0].date || ''
        excelRow.completed_survey_id = matchingAnswers[0].completed_survey_id
        foundKpis.forEach((kpi) => (excelRow[kpi.name] = ''))
        matchingAnswers.forEach((a) => {
          const kpiName = openKpis.find((kpi) => kpi.id === a.id)?.name
          if (kpiName) {
            excelRow[kpiName] = a.answer
          }
        })
        if (answer.metadata) {
          excelRow = excelRow = handleMetaFiltering(answer, excelRow)
        }
        groupedAnswers.push(excelRow)
      }
    })
    return groupedAnswers
  }

  const handleMetaFiltering = (answer: OpenAnswer, row: ExcelRow) => {
    const newRow = { ...row }
    if (!selectedMetas) return newRow
    selectedMetas
      .filter((meta) => meta.selected)
      .map((meta) => meta.name)
      .forEach((k) => (newRow[k] = answer.metadata && answer.metadata[k] ? answer.metadata[k] : ''))
    return newRow
  }

  const handleMetaSelectionClick = (clickedKey: string) => {
    if (exportStatus === ExportStatus.STARTED || exportStatus === ExportStatus.DOWNLOADING) return
    setExportStatus(ExportStatus.NONE)
    setSelectedMetas((prev) => {
      if (!prev) return prev
      return prev.map<MetaNameAndSelected>((meta) =>
        meta.name === clickedKey ? { name: clickedKey, selected: !meta.selected } : meta,
      )
    })
  }

  return (
    <div>
      <div className={css.actionBtns}>
        <button
          className='btn blue'
          title='Click or press ArrowLeft to go back to table view'
          onClick={handleClosingExportView}
          data-testid='closeBtn'
        >
          Close export view
        </button>
        <div>
          <button
            className='btn blue'
            title='Click to export answers data'
            onClick={handleClickExport}
            disabled={isExportDisabled}
            data-testid='exportBtn'
          >
            {exportStatus === ExportStatus.NONE ? (
              'Export'
            ) : exportStatus === ExportStatus.STARTED ? (
              <div>
                Getting data
                {/* <div className={css.loadingFile}>
                  <LoadingIndicator />
                </div> */}
              </div>
            ) : exportStatus === ExportStatus.DOWNLOADING ? (
              <div>
                Downloading
                {/* <div className={css.loadingFile}>
                  <LoadingIndicator />
                </div> */}
              </div>
            ) : (
              'Done'
            )}
          </button>
          {pagination &&
            exportStatus !== ExportStatus.NONE &&
            exportStatus !== ExportStatus.FINISHED && (
              <p>
                {pagination?.page_number} of {pagination?.total_pages}
              </p>
            )}
        </div>
      </div>
      <div className={css.btnsCntr}>
        <div>
          <div className={css.btnsBoxLabel}>
            <b>Row grouping format</b>
          </div>

          <div className={css.btnsBox}>
            <input
              name={tableView}
              id={tableView}
              type='radio'
              onChange={() => handleRadioButtonClick(tableView)}
              checked={selectedRowType === RowView.ANSWER}
              data-testid={tableView + '-input'}
            />
            <label
              htmlFor={tableView}
              className={css.buttonLabel}
              data-testid={tableView + '-label'}
              aria-disabled={false}
            >
              Answer
            </label>
            <input
              name={completedSurveysView}
              id={completedSurveysView}
              type='radio'
              onChange={() => handleRadioButtonClick(completedSurveysView)}
              checked={selectedRowType === RowView.COMPLETEDSURVEY}
              data-testid={completedSurveysView + '-input'}
            />
            <label
              htmlFor={completedSurveysView}
              className={css.buttonLabel}
              data-testid={completedSurveysView + '-label'}
              aria-disabled={false}
            >
              Completed surveys
            </label>
          </div>
        </div>
        <div>
          <div className={css.btnsBoxLabel}>
            <b>Meta filtering configuration</b>
          </div>
          <div className={css.btnsBox}>
            <input
              name={inspectorMetas}
              id={inspectorMetas}
              type='radio'
              onChange={() => handleRadioButtonClick(inspectorMetas)}
              checked={metaSelection === MetaSelection.INSPECTOR}
              data-testid={inspectorMetas + '-input'}
              disabled={isInspectorFilterDisabled}
            />
            <label
              htmlFor={inspectorMetas}
              className={css.buttonLabel}
              data-testid={inspectorMetas + '-label'}
              title={isInspectorFilterDisabled ? infoInspectorMetasDisabled : infoInspectorMetas}
              aria-disabled={isInspectorFilterDisabled}
            >
              Inspector
            </label>
            <>
              <input
                name={reportingMetas}
                id={reportingMetas}
                type='radio'
                onChange={() => handleRadioButtonClick(reportingMetas)}
                checked={metaSelection === MetaSelection.REPORTING}
                data-testid={reportingMetas + '-input'}
                disabled={isReportingFilterDisabled}
              />
              <label
                htmlFor={reportingMetas}
                className={css.buttonLabel}
                data-testid={reportingMetas + '-label'}
                title={
                  isReportingFilterDisabled ? infoReportingFilterDisabled : infoReportingFilter
                }
                aria-disabled={isReportingFilterDisabled}
              >
                Reporting
              </label>
            </>
            <input
              name={allMetas}
              id={allMetas}
              type='radio'
              onChange={() => handleRadioButtonClick(allMetas)}
              checked={metaSelection === MetaSelection.ALL}
              data-testid={allMetas + '-input'}
            />
            <label
              htmlFor={allMetas}
              className={css.buttonLabel}
              data-testid={allMetas + '-label'}
              aria-disabled={false}
            >
              All
            </label>
          </div>
        </div>
      </div>
      {isLoading || !selectedMetas ? (
        <LoadingIndicator />
      ) : (
        <div className={css.metaListCntr}>
          <div className={css.btnsBoxLabel}>
            <b>Metas to be included</b>
          </div>
          <div className={css.metaList}>
            {selectedMetas.map((meta) => (
              <div key={meta.name}>
                <input
                  type='checkbox'
                  id={`${meta.name}-input`}
                  checked={meta.selected}
                  onChange={(e) => e.currentTarget.blur()}
                  data-testid={`${meta.name}-input`}
                  name={meta.name}
                />
                <label
                  data-testid={`${meta.name}-label`}
                  htmlFor={`${meta.name}-input`}
                  id={`${meta.name}-label`}
                  onClick={() => handleMetaSelectionClick(meta.name)}
                  className={css.metaSelectionLabel}
                >
                  {meta.name}
                </label>
              </div>
            ))}
          </div>
          <div className={css.btnsCntr} style={{ marginTop: 0 }}>
            <div>
              <div className={css.btnsBoxLabel}>
                <b>File type</b>
              </div>

              <div className={css.btnsBox}>
                <input
                  name={excel}
                  id={excel}
                  type='radio'
                  onChange={() => handleRadioButtonClick(excel)}
                  checked={selectedFileType === FileType.XLSX}
                  data-testid={excel + '-input'}
                />
                <label
                  htmlFor={excel}
                  className={css.buttonLabel}
                  data-testid={excel + '-label'}
                  aria-disabled={false}
                >
                  {FileType.XLSX}
                </label>
                <input
                  name={csv}
                  id={csv}
                  type='radio'
                  onChange={() => handleRadioButtonClick(csv)}
                  checked={selectedFileType === FileType.CSV}
                  data-testid={csv + '-input'}
                />
                <label
                  htmlFor={csv}
                  className={css.buttonLabel}
                  data-testid={csv + '-label'}
                  aria-disabled={false}
                >
                  {FileType.CSV}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OpenInspectorExportView
