import { GenericConfig } from '../../types'
import {
  getCommonDbSettingsConfig,
  putCommonDbSettingsConfig,
} from '../react-services/configService2'
import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreNonResetting, { ActionState } from './useStoreNonResetting'
import useStoreOwner from './useStoreOwner'

export const commondbsettingStorage = makeNonResettingObservable<GenericConfig.CommonDbSettings>({})

const useCommonDbSettingsConfig = () => {
  const key = 'commondbsettings'
  const { tenant, user } = useStoreOwner(commondbsettingStorage)
  const {
    dataState: { data, error, state },
    get,
    put,
  } = useStoreNonResetting(
    commondbsettingStorage,
    key,
    user,
    tenant,
    true,
    getCommonDbSettingsConfig,
    undefined,
    putCommonDbSettingsConfig,
  )

  const isLoading = state !== ActionState.DONE && state !== ActionState.ERROR
  return { config: data, error, isLoading, refetchConfig: get, updateConfig: put }
}

export default useCommonDbSettingsConfig
