/* eslint-disable eqeqeq */
/**
 * parses the section from a url string
 * @param url a url string
 * @return dashboard index or undefined
 */
function sectionFromUrl(url: string): string | undefined {
  const [section] = url.match(/section\/\d+/gm) ?? []
  return section != null ? section.replace('section/', '') : undefined
}

/**
 * parses the dashboard from a url string
 * @param url a url string
 * @return dashboard index or undefined
 */
function dashboardFromUrl(url: string): string | undefined {
  const [dashboard] = url.match(/dashboard\/\d+/gm) ?? []
  // eslint-disable-next-line eqeqeq
  return dashboard != null ? dashboard.replace('dashboard/', '') : undefined
}

export {
  sectionFromUrl,
  dashboardFromUrl
}