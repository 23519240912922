import React, { Component } from 'react'
import { Button } from 'react-materialize'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import NoData from '../../../_shared/Infos/NoData'
import { postDashboardExport } from '../../../../react-services/queryService'
import { BLACKLIST } from '../../../../react-constants/metadata'

import './ExportModule.css'
import CsvTable from '../_shared/CsvTable'
import { isEqual } from 'lodash'
import { PropTypes } from 'prop-types'
import { post } from '../../../../react-services/apiService'

// type Props = {
//   startDate: string,
//   endDate: string,
//   filters: {[string]: Array<string>},
//   kpis: any,
//   useHiddenFilters: boolean,
// }

// type State = {
//   ready: boolean,
//   noData: boolean,
//   data: any,
//   headers: Array<string>,
//   content: any,
//   disableRowAddition: boolean,
// }

// type ExportQuery = {
//   start_date: string,
//   end_date: string,
//   filter: any,
//   kpis: Array<number>,
// }

const DEFAULTROWINCREMENT = 500

export default class ExportModule extends Component {
  static propTypes = {
    useHiddenFilters: PropTypes.bool,
    query: PropTypes.shape({
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string.isRequired,
      filter: PropTypes.object,
      where_meta: PropTypes.object,
      kpis: PropTypes.arrayOf(PropTypes.number),
      surveyid: PropTypes.number,
    }),
    module: PropTypes.shape({
      type: PropTypes.string.isRequired,
      dataSelectionType: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      ready: false,
      noData: false,
      data: {},
      headers: [],
      content: [],
      disableRowAddition: false,
    }
  }

  componentDidMount() {
    if (this.props.module && this.props.module.dataSelectionType === 'survey') {
      this.fetchResponseRateData()
    } else {
      this.fetchExportData()
    }
  }

  fetchResponseRateData() {
    post('POST_SURVEY_ROW_DATA_CSV', this.props.query, { parseAsText: true })
      .then((csv) => {
        let dataArray = csv.split('\n').reverse()
        let headers = dataArray.pop().split(';')

        let rows = []
        let rowsInInitialLoad =
          dataArray.length < DEFAULTROWINCREMENT ? dataArray.length : DEFAULTROWINCREMENT
        for (let i = 1; i < rowsInInitialLoad; i++) {
          let contentsOfRow = dataArray[i].split(';')
          rows.push(this.constructRows(contentsOfRow, i))
        }
        this.setState({
          ready: true,
          noData: false,
          data: dataArray,
          headers: headers,
          content: rows,
          disableRowAddition: rowsInInitialLoad < DEFAULTROWINCREMENT ? true : false,
        })
      })
      .catch((e) => {
        console.error(e)
        window.Materialize.toast(`Could not load export module response data.`)
        this.setState({
          ready: true,
          noData: true,
        })
      })
  }

  fetchExportData() {
    postDashboardExport(this.props.query, { useHiddenFilters: this.props.useHiddenFilters })
      .then((data) => {
        if (!(data.length === 0 || !data.trim())) {
          let dataArray = data.split('\n').reverse()
          let headers = dataArray.pop().split(';')
          let indicesOfCrapMetas = this.gatherIndicesOfBlacklistedMetas(headers)
          // omit every value that appear on indices collected by the crap meta collector; this is also done to contentsOfRow later
          headers = headers.filter((element, index) => {
            return !indicesOfCrapMetas.includes(index)
          })
          let rows = []
          let rowsInInitialLoad =
            dataArray.length < DEFAULTROWINCREMENT ? dataArray.length : DEFAULTROWINCREMENT
          for (let i = 1; i < rowsInInitialLoad; i++) {
            let contentsOfRow = dataArray[i].split(';')
            contentsOfRow = contentsOfRow.filter((element, index) => {
              return !indicesOfCrapMetas.includes(index)
            })
            rows.push(this.constructRows(contentsOfRow, i))
          }

          this.setState({
            ready: true,
            noData: false,
            data: dataArray,
            headers: headers,
            content: rows,
            disableRowAddition: rowsInInitialLoad < DEFAULTROWINCREMENT ? true : false,
          })
        } else {
          this.setState({
            ready: true,
            noData: true,
          })
        }
      })
      .catch((e) => {
        console.error(e)
        window.Materialize.toast(`Could not load export module data.`)
        this.setState({
          ready: true,
          noData: true,
        })
      })
  }

  gatherIndicesOfBlacklistedMetas = (headers) => {
    let result = []
    headers.forEach((str, index) => {
      if (BLACKLIST.includes(str)) result.push(index)
    })
    return result
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.payloadHasChanged(prevProps.query, this.props.query)) {
      this.emptyContentsAndUpdate()
    }
  }

  payloadHasChanged(oldFilters, newFilters) {
    if (newFilters) {
      return !isEqual(oldFilters, newFilters)
    }

    return false
  }

  emptyContentsAndUpdate() {
    this.setState(
      {
        ready: false,
        noData: false,
        headers: [],
        content: [],
      },
      () => {
        this.componentDidMount()
      },
    )
  }

  kpiInputToNumber(kpi) {
    if (typeof kpi === 'number') return kpi
    else if (typeof kpi === 'string') return parseInt(kpi, 10)
    else if (kpi.id) return kpi.id
    else
      throw new Error(
        'Export module tries to parse your KPI input into a number but cannot determine its form: ' +
          kpi,
      )
  }

  constructRows(contents, index) {
    return (
      <tr key={index}>
        {contents.map((data, subIndex) => {
          return <td key={subIndex}>{data}</td>
        })}
      </tr>
    )
  }

  loadMoreRows = () => {
    let rowNumber = this.state.content.length
    let upcomingRowNumber = rowNumber + DEFAULTROWINCREMENT
    if (upcomingRowNumber >= this.state.data.length) {
      upcomingRowNumber = this.state.data.length
      this.setState({ disableRowAddition: true })
    }

    let rows = []
    for (let i = rowNumber; i < upcomingRowNumber; i++) {
      let contentsOfRow = this.state.data[i].split(';')
      rows.push(this.constructRows(contentsOfRow, i))
    }

    this.setState({
      content: this.state.content.concat(rows),
    })
  }

  render() {
    return (
      <div className='export-module-container'>
        {this.state.ready && !this.state.noData ? (
          <React.Fragment>
            <CsvTable headers={this.state.headers} content={this.state.content} />
            {this.state.disableRowAddition ? null : (
              <React.Fragment>
                <br />
                <Button onClick={this.loadMoreRows}>Load {DEFAULTROWINCREMENT} more rows</Button>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : this.state.noData ? (
          <NoData />
        ) : (
          <LoadingIndicator />
        )}
      </div>
    )
  }
}
