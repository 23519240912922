import React from 'react'
import TimeframeDisplay from '../../Group/filter/TimeframeDisplay'
import {
  FilterArray,
  getFilterArrayEndDate,
  getFilterArrayStartDate,
} from '../../../../../react-services/filterService'

type ModuleFiltersTimeSelectionProps = {
  startDate: string
  endDate: string
  onChangeDate: (value: string, isStartDate: boolean) => void
  customDatePickerOptions?: object
  isChangedAfterClose?: boolean
  isDisabled?: boolean
}
const ModuleFiltersTimeSelection = ({
  startDate,
  endDate,
  onChangeDate,
  isDisabled = false,
}: ModuleFiltersTimeSelectionProps) => {
  function handleDateChange(filters: FilterArray) {
    const newStartDate = getFilterArrayStartDate(filters)
    const newEndDate = getFilterArrayEndDate(filters)
    const oldStartDate = startDate
    const oldEndDate = endDate
    if (newStartDate !== oldStartDate) {
      onChangeDate(newStartDate, true)
    }
    if (newEndDate !== oldEndDate) {
      onChangeDate(newEndDate, false)
    }
  }

  return (
    // PATCH CHROME BUG
    <div className='row marginless-row date-filter-picker-container'>
      <div style={{ display: 'inline-block' }}>
        <TimeframeDisplay
          filters={[
            { name: 'startDate', value: startDate },
            { name: 'endDate', value: endDate },
          ]}
          saveFilters={handleDateChange}
          isDisabled={isDisabled}
        />
      </div>

      {/* <Input disabled={isDisabled} data-testid="startDatePickADate" className="filter-bar-date-picker" name='startDate' type='date' value={startDate} options={datePickerOptions} onChange={(e, value) => {handleDateChange(value, true)}} />
      <Input disabled={isDisabled} data-testid="endDatePickADate" className="filter-bar-date-picker" name='endDate' type='date' value={endDate} options={datePickerOptions} onChange={(e, value) => {handleDateChange(value, false)}} /> */}
    </div>
  )
}

export default ModuleFiltersTimeSelection
