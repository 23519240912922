import React from 'react'
import { getEndpoint } from '../../react-services/apiService'
import './Unsubscribe.scss'
import { useParams } from 'react-router-dom'
import LanguageSelect from '../../../languages/LanguageSelect'
import { tCommon } from '../../../languages/i18n'
import { Button } from '@mui/material'

function Unsubscribe(): JSX.Element {
  const { hashId } = useParams()

  return (
    <div className='unsubscribe'>
      <LanguageSelect />
      <div>{tCommon('info.unsubscribe')}</div>
      <div className='unsubscribe-link-container'>
        <Button
          href={getEndpoint('GET_UNSUBSCRIBE', { hashId: hashId })}
          data-testid='unsubscribe-link'
          variant='contained'
        >
          {tCommon('button.confirm')}
        </Button>
      </div>
    </div>
  )
}

export default Unsubscribe
