import { cloneDeep } from 'lodash'
import { post } from './apiService'
import _ from 'underscore'
import { numericKpiStorage } from '../stores/useNumericKpis'

let logToConsole = false

// type Kpi = {
//   id: number,
//   name: string,
// }

// type ExportQuery = {
//   start_date: string,
//   end_date: string,
//   filter?: {[string]: Array<string>},
//   kpis: Array<number>,
// }

// type TextualQuery = {
//   calculated_kpis: ?any,
//   where_numeric: ?any,
//   trend_line: ?boolean,
//   kpis: ?Array<Kpi>,
//   grouping: ?string,
//   where_meta: {[string]: Array<string>},
//   page: ?string,
//   time_res: ?string,
//   where_textual: ?any,
//   cache: ?boolean,
//   end_date: string,
//   start_date: string
// }

// type InvitationsCountQuery = {
//   surveyid?: number,
//   id: Array<number>,
//   start_date: string,
//   end_date: string,
//   sent?: string,
//   answered?: string,
//   where_meta?: {[string]: Array<string>},
// }

// type Survey = {
//   attributes: {[string]: string},
//   id: number,
//   name: string,
//   status: string,
// }

function postDashboardExport(body, options) {
  validateQueryOptions(options)
  let endpoint =
    options.useHiddenFilters === true ? 'POST_ADMINEXPORWITHHIDDEN' : 'POST_ADMINEXPORT'
  return post(endpoint, body, { parseAsText: true })
}

function postTextual(body) {
  let endpoint = 'POST_TEXTUAL'

  return post(endpoint, body)
}

function postNumeric(body) {
  let endpoint = 'POST_NUMERIC'

  return post(endpoint, body)
}

function validateQueryOptions(input) {
  if (typeof input !== 'object')
    throw new Error('Expect an object type as input, instead got ' + typeof input)
}

// Attach key value pairs from 'meta' to 'query.where_meta'
function attachMetaToQuery(meta, query) {
  if (query && meta) {
    if (logToConsole) console.log('queryService: attachMetaToQuery')
    if (logToConsole) console.log('queryService: query before:')
    if (logToConsole) console.log(JSON.stringify(query))
    _.each(meta, function (value, key) {
      if (!query.where_meta) query.where_meta = {}
      query.where_meta[key] = value
    })
    if (logToConsole) console.log('queryService: query after:')
    if (logToConsole) console.log(JSON.stringify(query))
  }
}

function getQueryMeta(query) {
  return query && query.where_meta && !_.isEmpty(query.where_meta) ? query.where_meta : null
}

function setQueryStartDate(query, date) {
  if (query) {
    if (date && date.length > 0) {
      query.start_date = date
    } else {
      removeQueryStartDate(query)
    }
  }
}

function setQueryEndDate(query, date) {
  if (query) {
    if (date && date.length > 0) {
      query.end_date = date
    } else {
      removeQueryEndDate(query)
    }
  }
}

function setQueryMeta(query, meta) {
  if (query) {
    if (meta && !_.isEmpty(meta)) {
      query.where_meta = meta
    } else {
      removeQueryMeta(query)
    }
  }
}

function getNewQuery(meta, startDate, endDate) {
  var query = {}
  if (meta && !_.isEmpty(meta)) query.where_meta = meta
  if (startDate) query.start_date = startDate
  if (endDate) query.end_date = endDate
  return query
}

function getQueryStartDate(query) {
  return query && query.start_date
}

function getQueryEndDate(query) {
  return query && query.end_date
}

function removeQueryStartDate(query) {
  if (query) delete query.start_date
}

function removeQueryEndDate(query) {
  if (query) delete query.end_date
}

function removeQueryMeta(query) {
  if (query) delete query.where_meta
}

function getKpiNamesQueryXYZ(queryXYZ) {
  let kpiNamesXYZ = {}
  if (queryXYZ) {
    const numericKpis = numericKpiStorage.get('numericKpis')
    if (numericKpis && numericKpis.kpis) {
      let kpiX = numericKpis.kpis.find((kpi) => {
        return kpi && kpi.id === queryXYZ.x
      })
      if (kpiX) kpiNamesXYZ.x = kpiX.name

      let kpiY = numericKpis.kpis.find((kpi) => {
        return kpi && kpi.id === queryXYZ.y
      })
      if (kpiY) kpiNamesXYZ.y = kpiY.name

      let kpiZ = numericKpis.kpis.find((kpi) => {
        return kpi && kpi.id === queryXYZ.z
      })
      if (kpiZ) kpiNamesXYZ.z = kpiZ.name
    }
  }
  return kpiNamesXYZ
}

function isQueryXYZValid(queryXYZ) {
  return !!(queryXYZ && queryXYZ.y && queryXYZ.z && queryXYZ.series_by)
}

function overwriteWithPartialQuery(
  base /*: Query.Query2Payload */,
  overwrite /*: Partial<Query.Query2Payload> */,
) {
  return { ...base, ...overwrite }
}

// Attach all values from 'filters' for selected 'keys' into 'query.where_meta'
function attachMetaValuesToQuery(keys, filters, query) {
  if (query && filters && keys) {
    if (logToConsole) console.log('queryService: attachMetaValuesToQuery')
    if (logToConsole) console.log('queryService: query before:')
    if (logToConsole) console.log(angular.copy(query))
    var keysArray = []
    if (!Array.isArray(keys)) {
      keysArray.push(keys)
    } else {
      keysArray = keys
    }
    _.each(keysArray, function (key) {
      if (filters[key]) {
        if (!query.where_meta) query.where_meta = {}
        query.where_meta[key] = filters[key]
      }
    })
    if (logToConsole) console.log('queryService: query after:')
    if (logToConsole) console.log(cloneDeep(query))
  }
}

function removeMetaKeysFromQuery(query, meta) {
  if (query && meta) {
    if (logToConsole) console.log('queryService: removeMetaKeysFromQuery', meta)
    if (logToConsole) console.log('queryService: query before:')
    if (logToConsole) console.log(cloneDeep(query))
    let m = getQueryMeta(query)
    if (m) {
      // meta is an Array
      if (Array.isArray(meta)) {
        for (let key of meta) {
          delete m[key]
        }
        // meta is an Object
      } else {
        for (let key in meta) {
          delete m[key]
        }
      }
    }
    if (logToConsole) console.log('queryService: query after:')
    if (logToConsole) console.log(cloneDeep(query))
  }
}

// Get query timeframe and meta selections as strings in an Array
function getQueryStringArray(query) {
  var array = []
  var time = getQueryDatesString(query)
  if (time) array.push(time)
  var metas = getQueryMetasStringArray(query)
  if (metas) array = array.concat(metas)
  return array
}

function getQueryDatesString(query) {
  if (query && query.start_date && query.end_date) {
    return query.start_date + ' - ' + query.end_date
  }
}

function getQueryMetasStringArray(query) {
  if (query && query.where_meta) {
    var array = []
    _.each(query.where_meta, function (values, key) {
      if (values && values.length) {
        var metastr = ''
        metastr += key + ': '
        _.each(values, function (value, idx) {
          metastr += value
          if (idx < values.length - 1) {
            metastr += ', '
          }
        })
        array.push(metastr)
      }
    })
    return array
  }
}

// LEGACY FUNCTIONS FROM ANGULARJS SERVICES
function attachKpiObjectToQuery(kpi, query) {
  if (!kpi || !query) return
  _.each(kpi.kpis, function (obj) {
    if (!query.kpis) query.kpis = []
    if (cloneDeep(obj.id)) {
      var listedObj = _.findWhere(query.kpis, {
        id: obj.id,
      })
    }
    if (listedObj) {
      if (logToConsole)
        console.log('queryService: attachKpiObjectToQuery: query already has this kpi, skip!')
    } else {
      query.kpis.push(obj)
    }
  })
  _.each(kpi.calculated_kpis, function (value, key) {
    query.calculated_kpis = query.calculated_kpis ? query.calculated_kpis : {}
    query.calculated_kpis[key] = value
  })
}

export {
  postDashboardExport,
  postTextual,
  postNumeric,
  attachMetaToQuery,
  getQueryMeta,
  setQueryStartDate,
  setQueryEndDate,
  setQueryMeta,
  getNewQuery,
  getQueryStartDate,
  getQueryEndDate,
  getKpiNamesQueryXYZ,
  isQueryXYZValid,
  overwriteWithPartialQuery,
  attachMetaValuesToQuery,
  removeMetaKeysFromQuery,
  getQueryStringArray,
  attachKpiObjectToQuery,
}
