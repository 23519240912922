/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import mixpanel from 'mixpanel-browser'
import { TrackingEvent, MIXPANEL_TOKEN } from '../react-constants'
import { getClaims, hasRole, getToken } from './authService'
import { sectionFromUrl, dashboardFromUrl } from '../utilities/string'
import {
  isReportMode,
  isEmailAlert,
  getConsentStatus,
  isStringTuple2,
  setConsentStatus,
} from '../utilities'
import LoggingService, { BackendLoggingAPI } from './loggingService'
import { v4 as uuid } from 'uuid'

interface Tracker {
  isEnabled: boolean

  track(event: TrackingEvent, metadata?: Record<string, string>): void
  /**
   * A debounced tracking function that waits before calling track
   * @param event Tracking event string
   * @param wait waiting time in ms, defaults to 1000
   * @param properties metadata
   * @param options mixpanel options
   * @param callback
   */
  debounceTrack(event: TrackingEvent, wait?: number, metadata?: Record<string, string>): void
  identify(): void
}

class AthenaTracker implements Tracker {
  private logger: LoggingService
  isEnabled = true
  private sessionId: string = uuid()

  constructor() {
    this.logger = LoggingService.GetLoggerFor('AthenaTracker')
  }

  track(event: TrackingEvent, metadata?: Record<string, string>): void {
    try {
      this.isEnabled &&
        BackendLoggingAPI.INSTANCE.track(event, { ...metadata, sessionId: this.sessionId })
    } catch (error: any) {
      this.logger.error('Failed to track', error)
    }
  }

  debounceTrack(): void {
    // for lint
  }

  identify(): void {
    // for lint
  }

  /**
   * Does nothing
   * Can't disable athena tracker
   */
  setEnabled(): void {
    // for lint
  }
}

class MixpanelTracker implements Tracker {
  private hasIdentified: boolean
  private logger: LoggingService
  private _isEnabled: boolean

  constructor() {
    this._isEnabled = false
    this.hasIdentified = false
    mixpanel.init(MIXPANEL_TOKEN, {
      opt_out_tracking_by_default: true,
    })
    this.isEnabled = false
    this.logger = LoggingService.GetLoggerFor('MixpanelTracker')
  }

  track(event: TrackingEvent, metadata?: Record<string, string>): void {
    try {
      if (this.isEnabled) {
        mixpanel.track(event, { ...metadata })
      }
    } catch (error: any) {
      this.logger.error('Failed to track', error)
    }
  }

  debounceTrack(): void {
    // for lint
  }

  /**
   * Identifies the user and sets some properties to be tracked with every event
   */
  identify(): void {
    if (!this.hasIdentified) {
      try {
        if (isEmailAlert()) {
          this.identifyFromEmailToken()
        } else {
          this.identifyFromToken()
        }
      } catch (error: any) {
        this.logger.error(error.message, error)
      }
    }
  }

  private identifyFromToken(): void {
    if (!getToken()) {
      this.logger.debug('Tried to identify but token is not set')
      return
    }

    const { username, id, tenant } = getClaims() as Record<string, string>

    mixpanel.identify(id)

    mixpanel.register({
      tenant,
      isAdmin: hasRole('admin'),
    })

    mixpanel.people.set({
      username,
      tenant,
      $last_login: new Date(),
    })

    this.hasIdentified = true

    this.logger.debug('Identified')
  }

  private identifyFromEmailToken(): void {
    if (!getToken()) {
      this.logger.debug('Tried to identify but token is not set')
      return
    }

    const { username, tenant, limited_access } = getClaims() as Record<string, any>

    mixpanel.register({
      tenant,
      username,
      alertId: limited_access?.params?.alert_id,
    })

    this.hasIdentified = true
    this.logger.debug('Identified Email Alert User')
  }

  set isEnabled(enabled: boolean) {
    if (enabled) {
      mixpanel.opt_in_tracking()
    } else {
      mixpanel.opt_out_tracking()
      mixpanel.disable()
    }

    this._isEnabled = enabled
  }

  get isEnabled(): boolean {
    return this._isEnabled
  }
}

class DisabledTracker implements Tracker {
  static INSTANCE: DisabledTracker = new DisabledTracker()

  private constructor() {
    // for lint
  }

  track(): void {
    // for lint
  }
  debounceTrack(): void {
    // for lint
  }
  identify(): void {
    // for lint
  }
  get isEnabled(): boolean {
    return false
  }

  set enabled(enabled: boolean) {
    // for lint
  }
}

class EventListenerContainer {
  static INSTANCE = new EventListenerContainer()
  private logger: LoggingService
  private tracker: Tracker

  private constructor() {
    this.tracker = DisabledTracker.INSTANCE
    this.logger = LoggingService.GetLoggerFor('EventListenerContainer')
  }
  /*
   * Add event listeners on the window object
   */
  enableEventTracking(tracker: Tracker) {
    this.tracker = tracker
    if (!isReportMode()) {
      window.addEventListener('hashchange', this.handleHashChange.bind(this))
      window.addEventListener('focus', this.handleFocusChange.bind(this))
      window.addEventListener('blur', this.handleFocusChange.bind(this))
    }
  }

  disableEventTracking() {
    this.tracker = DisabledTracker.INSTANCE
    window.removeEventListener('hashchange', this.handleHashChange.bind(this))
    window.removeEventListener('focus', this.handleFocusChange.bind(this))
    window.removeEventListener('blur', this.handleFocusChange.bind(this))
  }

  /**
   * Tracks changes in sections and dashboards
   */
  private handleHashChange(event: HashChangeEvent): void {
    try {
      /** IE doesn't have these parameters*/
      if (event.oldURL == null || event.newURL == null) {
        this.tracker.track(TrackingEvent.LocationChange, this.determineLocationChangeFromHref())
      } else {
        this.tracker.track(TrackingEvent.LocationChange, this.determineLocationChange(event))
      }
    } catch (error: any) {
      this.logger.error('Failed to track hash change', error)
    }
  }

  private determineLocationChange({ oldURL, newURL }: HashChangeEvent): Record<string, string> {
    const result: {
      from?: string
      to?: string
      oldSection?: string
      oldDashboard?: string
      newSection?: string
      newDashboard?: string
    } = {}

    if (oldURL.includes('admin')) {
      result.from = 'tools'
    } else {
      result.from = 'dashboard'
      result.oldSection = sectionFromUrl(oldURL)
      result.oldDashboard = dashboardFromUrl(oldURL)
    }

    if (newURL.includes('admin')) {
      result.to = 'tools'
    } else {
      result.to = 'dashboard'
      result.newSection = sectionFromUrl(newURL)
      result.newDashboard = dashboardFromUrl(newURL)
    }

    return Object.entries(result)
      .filter(isStringTuple2)
      .reduce((result, [key, val]) => {
        result[key] = val
        return result
      }, {} as Record<string, string>)
  }

  private determineLocationChangeFromHref(): Record<string, string> {
    const result: {
      to?: string
      newSection?: string
      newDashboard?: string
    } = {}

    if (window.location.href.includes('admin')) {
      result.to = 'tools'
    } else {
      result.to = 'dashboard'
      result.newSection = sectionFromUrl(window.location.href)
      result.newDashboard = dashboardFromUrl(window.location.href)
    }

    return Object.entries(result)
      .filter(isStringTuple2)
      .reduce((result, [key, val]) => {
        result[key] = val
        return result
      }, {} as Record<string, string>)
  }

  /**
   * Tracks when window receives or loses focus to determine when the user
   * leaves and enters the tab (the window might lose focus even though it's still visible)
   */
  private handleFocusChange({ type }: FocusEvent): void {
    try {
      this.tracker.track(type === 'focus' ? TrackingEvent.WindowFocus : TrackingEvent.WindowBlur)
    } catch (error: any) {
      this.logger.error('Failed to track focus change', error)
    }
  }
}

class TrackingContext implements Tracker {
  static INSTANCE: TrackingContext = new TrackingContext()
  private trackingTimeouts: Record<string, NodeJS.Timeout> = {}
  private trackers: Tracker[]
  isSysadmin = false

  constructor() {
    this.trackers = [new AthenaTracker(), new MixpanelTracker()]
    this.isEnabled = getConsentStatus() === 'Consented' ? true : false
  }

  track(event: TrackingEvent, metadata?: Record<string, string>): void {
    try {
      if (this.isSysadmin) {
        console.debug('Tried to track for sysadmin', event, metadata)
      } else {
        this.trackers.forEach((tracker) => tracker.track(event, metadata))
      }
    } catch (error) {
      console.log('Failed to track', error)
    }
  }

  debounceTrack(event: TrackingEvent, wait = 1000, metadata?: Record<string, string>): void {
    if (this.trackingTimeouts[event] != null) {
      clearTimeout(this.trackingTimeouts[event])
    }

    this.trackingTimeouts[event] = setTimeout(() => {
      this.track(event, metadata)
    }, wait)
  }

  identify(): void {
    this.trackers.forEach((tracker) => tracker.identify())
  }

  set isEnabled(enabled: boolean) {
    if (!enabled) {
      clearCookie()
    }
    this.trackers.forEach((tracker) => (tracker.isEnabled = enabled))
  }

  get isEnabled(): boolean {
    return this.trackers.some((tracker) => tracker.isEnabled)
  }

  acceptTracking() {
    setConsentStatus('Consented')
    this.isEnabled = true
  }

  declineTracking() {
    setConsentStatus('Forbidden')
    this.isEnabled = false
  }
}

function clearCookie(): void {
  document.cookie.split(';').forEach((cookie) => {
    document.cookie = `${cookie}=;expires=${new Date(0).toUTCString()}`
  })
}

const trackingService = TrackingContext.INSTANCE
EventListenerContainer.INSTANCE.enableEventTracking(trackingService)

export { TrackingContext as _TrackingContext, EventListenerContainer as _EventListenerContainer }

export default trackingService
