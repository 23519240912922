import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  FreqFormat,
  FrequencyChartType,
  FrequencyComparisonType,
  FrequencyModule,
  FrequencyQueryType,
  FrequencyUnit,
  NumericKpiFormat,
} from './frequencyModuleTypes'
import { infoSelectDataType } from './FrequencyNotifications'
import useFrequencyQueryFormat from './useFrequencyQueryFormat'
import { KpiData, Query } from '../../../../../types'
import useMetaFrequencyCounts from '../../../../stores/useMetaFrequencyCounts'
import Bar from './bar/Bar'
import Column from './column/Column'
import Pie from './pie/Pie'
import {
  Point,
  PointClickEventObject,
  PointOptionsObject,
  SeriesOptions,
  SeriesPieOptions,
} from 'highcharts8'
import useNumericFrequencyCounts from '../../../../stores/useNumericFrequencyCounts'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'
import useNumericKpis from '../../../../stores/useNumericKpis'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import useOpenFrequencyCounts from '../../../../stores/useOpenFrequencyCounts'
import useOpenKpis from '../../../../stores/useOpenKpis'
import useFrequencySeriesBuilder from './useFrequencySeriesBuilder'
import useNumericKpiCounts from '../../../../stores/useNumericKpiCounts'
import { daysInBetween } from '../../../../react-services/datesService'
import useFrequencyUnits from './useFrequencyUnits'
import FrequencyModuleInspectorCntr from './FrequencyModuleInspectorCntr'
import { isEmpty, isNil } from 'lodash'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import { toast } from 'react-toastify'
import { useToastId } from '../../../common/Notification/NotificationContext'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import NoData from '../../../_shared/Infos/NoData'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { isFetchingData, isWaitingForFetching } from '../Group/contexts/renderListReducer'
import { CircularProgress } from '@mui/material'
import { QueueStatus } from '../Group/groupModuleTypes'

import css from './FrequencyModuleCntr.module.scss'
import { reportSVGWidth } from '../../../../../styles/variableExport'
import { useTranslation } from 'react-i18next'

export type FrequencyModuleCntrProps = {
  id: string
  isNested?: boolean
  module: FrequencyModule
  saveModule: (module: FrequencyModule) => void
  isReportMode: boolean
  isScreenMode: boolean
  settingsWrapperRef?: React.MutableRefObject<HTMLDivElement | undefined>
  sharedFilter?: SharedFilter
  moduleStatus: QueueStatus | undefined
}

const FrequencyModuleCntr = ({
  id,
  isReportMode,
  isScreenMode,
  saveModule,
  module,
  sharedFilter: groupQuery,
  moduleStatus,
}: FrequencyModuleCntrProps) => {
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { i18n } = useTranslation()
  const { updateModuleToIdle } = useRenderActions()
  const {
    queryType,
    grouping: metaGrouping,
    query: moduleQuery,
    chartType,
    dataPoints,
    options: {
      freq_format,
      pieType,
      openKpis: selectedOpenKpis,
      comparisonType,
      comparisonDays,
      limitTarget,
      isSoftMin,
      isSoftMax,
      numericKpiMetaGrouping,
      numericKpiDataFormat,
    } = {},
    selections,
    order: chartOrder,
    limitValues,
    numericquery: { min: numericMin, max: numericMax } = {},
  } = module
  const {
    originalCalculatedKpis,
    isLoading: isLoadingCalculatedKpis,
    error: errorCalculatedKpis,
  } = useCalculatedKpis()
  useEffect(() => toastError(errorCalculatedKpis), [errorCalculatedKpis])

  const {
    config,
    isLoading: isLoadingCommonDbSettings,
    error: errorCommonDbSettings,
  } = useCommonDbSettingsConfig()
  useEffect(() => toastError(errorCommonDbSettings), [errorCommonDbSettings])

  const { numericKpis, isLoading: isLoadingNumericKpis, error: errorNumericKpis } = useNumericKpis()

  useEffect(() => toastError(errorNumericKpis), [errorNumericKpis])
  const {
    openKpis: tenantOpenKpis,
    isLoading: isLoadingOpenKpis,
    error: errorOpenKpis,
  } = useOpenKpis()
  useEffect(() => toastError(errorOpenKpis), [errorOpenKpis])

  const moduleCntrRef = useRef<HTMLDivElement>()
  const [clickedPoint, setClickedPoint] = useState<Point | null>(null)
  const [ready, setReady] = useState<string>('')
  const { toastifyId } = useToastId()
  const toastCntr = { containerId: toastifyId }

  const limitTargetRef = useRef<FreqFormat | NumericKpiFormat>()
  useEffect(() => {
    if (limitTarget) limitTargetRef.current = limitTarget
    else limitTargetRef.current = freq_format
  }, [limitTarget])

  const {
    createMetaFrequencyQuery,
    createMetaFrequencyDateComparisonQuery,
    createNumericFrequencyQuery,
    createNumericFrequencyDateComparisonQuery,
    createOpenFrequencyQuery,
    createOpenFrequencyDateComparisonQuery,
    createNumericKpiQuery,
    createNumericKpiDateComparisonQuery,
    queryBase,
    comparisonQueryBase,
  } = useFrequencyQueryFormat(
    moduleQuery,
    groupQuery,
    numericKpis,
    originalCalculatedKpis,
    tenantOpenKpis,
    comparisonDays,
    moduleStatus,
    doneStatusTimeoutRef,
    id,
  )

  const { getChartFormat, getChartUnit } = useFrequencyUnits()
  const chartUnit: FrequencyUnit = useMemo(
    () => getChartUnit(queryType, numericKpiDataFormat, freq_format),
    [queryType, numericKpiDataFormat, freq_format],
  )
  const chartFormat: FreqFormat | NumericKpiFormat | null = useMemo(
    () => getChartFormat(queryType, numericKpiDataFormat, freq_format),
    [queryType, numericKpiDataFormat, freq_format],
  )
  const decimals = config?.decimals ?? 1

  const metaFrequencyQuery: Query.Query2Payload | null = useMemo(() => {
    if (queryType === FrequencyQueryType.META && metaGrouping)
      return createMetaFrequencyQuery(metaGrouping)
    else return null
  }, [metaGrouping, queryType, queryBase])

  const {
    metaFrequencyCounts,
    isLoading: isLoadingMetaFrequencyCounts,
    isRefetching: isRefetchingMetaFrequencyCounts,
    error: errorMetaFrequencyCounts,
  } = useMetaFrequencyCounts(metaFrequencyQuery)
  useEffect(() => toastError(errorMetaFrequencyCounts), [errorMetaFrequencyCounts])

  const metaFrequencyComparisonQuery: Query.Query2Payload | null = useMemo(() => {
    if (metaFrequencyQuery && comparisonType === FrequencyComparisonType.DATE)
      return createMetaFrequencyDateComparisonQuery(metaFrequencyQuery, comparisonDays)
    return null
  }, [metaFrequencyQuery, comparisonType, comparisonDays])
  const {
    metaFrequencyCounts: metaFrequencyComparisonCounts,
    isLoading: isLoadingMetaFrequencyComparisonCounts,
    isRefetching: isRefetchingMetaFrequencyComparisonCounts,
    error: errorMetaFrequencyComparisonCounts,
  } = useMetaFrequencyCounts(metaFrequencyComparisonQuery)
  useEffect(
    () => toastError(errorMetaFrequencyComparisonCounts),
    [errorMetaFrequencyComparisonCounts],
  )

  const numericFrequencyQuery: Query.SingleNumeric | null = useMemo(() => {
    if (queryType === FrequencyQueryType.NUMERIC && selections)
      return createNumericFrequencyQuery(selections)
    else return null
  }, [queryType, selections, numericKpis, queryBase])
  const {
    numericFrequencyCounts,
    isLoading: isLoadingNumericFrequencyCounts,
    isRefetching: isRefetchingNumericFrequencyCounts,
    error: errorNumericFrequencyCounts,
  } = useNumericFrequencyCounts(numericFrequencyQuery)
  useEffect(() => toastError(errorNumericFrequencyCounts), [errorNumericFrequencyCounts])

  const numericComparisonFrequencyQuery: Query.SingleNumeric | null = useMemo(() => {
    if (numericFrequencyQuery && comparisonType === FrequencyComparisonType.DATE)
      return createNumericFrequencyDateComparisonQuery(numericFrequencyQuery)
    return null
  }, [numericFrequencyQuery, comparisonType, comparisonDays])
  const {
    numericFrequencyCounts: numericFrequencyComparisonCounts,
    isLoading: isLoadingNumericFrequencyComparisonCounts,
    isRefetching: isRefetchingNumericFrequencyComparisonCounts,
    error: errorNumericFrequencyComparisonCounts,
  } = useNumericFrequencyCounts(numericComparisonFrequencyQuery)
  useEffect(
    () => toastError(errorNumericFrequencyComparisonCounts),
    [errorNumericFrequencyComparisonCounts],
  )

  const openFrequencyQuery: Query.Textual | null = useMemo(() => {
    if (queryType === FrequencyQueryType.OPEN && selectedOpenKpis)
      return createOpenFrequencyQuery(selectedOpenKpis)
    else return null
  }, [queryType, selectedOpenKpis, tenantOpenKpis, queryBase])
  const {
    openFrequencyCounts,
    isLoading: isLoadingOpenFrequencyCounts,
    isRefetching: isRefetchingOpenFrequencyCounts,
    error: errorOpenFrequencyCounts,
  } = useOpenFrequencyCounts(openFrequencyQuery)
  useEffect(() => toastError(errorOpenFrequencyCounts), [errorOpenFrequencyCounts])

  const openComparisonFrequencyQuery: Query.Textual | null = useMemo(() => {
    if (openFrequencyQuery && comparisonType === FrequencyComparisonType.DATE)
      return createOpenFrequencyDateComparisonQuery(openFrequencyQuery)
    return null
  }, [openFrequencyQuery, comparisonType, comparisonDays])
  const {
    openFrequencyCounts: openComparisonFrequencyCounts,
    isLoading: isLoadingOpenComparisonFrequencyCounts,
    isRefetching: isRefetchingOpenComparisonFrequencyCounts,
    error: errorOpenComparisonFrequencyCounts,
  } = useOpenFrequencyCounts(openComparisonFrequencyQuery)
  useEffect(
    () => toastError(errorOpenComparisonFrequencyCounts),
    [errorOpenComparisonFrequencyCounts],
  )

  const numericKpiQuery: Query.SingleNumeric | null = useMemo(() => {
    const meta = comparisonType === FrequencyComparisonType.META ? numericKpiMetaGrouping : ''
    if (queryType === FrequencyQueryType.NUMERIC_KPI) return createNumericKpiQuery(selections, meta)
    return null
  }, [queryType, selections, numericKpiMetaGrouping, comparisonType, numericKpis, queryBase])

  const {
    numericCounts: numericKpiCounts,
    isLoading: isLoadingNumericKpiCounts,
    isRefetching: isRefetchingNumericKpiCounts,
    error: errorNumericKpiCounts,
  } = useNumericKpiCounts(numericKpiQuery)
  useEffect(() => toastError(errorNumericKpiCounts), [errorNumericKpiCounts])

  const numericKpiComparisonQuery: Query.SingleNumeric | null = useMemo(() => {
    if (numericKpiQuery && comparisonType === FrequencyComparisonType.DATE)
      return createNumericKpiDateComparisonQuery(numericKpiQuery)
    return null
  }, [numericKpiQuery, comparisonType, comparisonDays])
  const {
    numericCounts: numericKpiComparisonCounts,
    isLoading: isLoadingNumericKpiComparisonCounts,
    isRefetching: isRefetchingNumericKpiComparisonCounts,
    error: errorNumericKpiComparisonCounts,
  } = useNumericKpiCounts(numericKpiComparisonQuery)
  useEffect(() => toastError(errorNumericKpiComparisonCounts), [errorNumericKpiComparisonCounts])

  const {
    createPointOptionsArrayFromSingleSeries,
    createPointOptionsArrayFromMultipleSeries,
    isWithinNumericLimits,
    createBaOrColumnSeries,
    createCategoriesFromSeries,
  } = useFrequencySeriesBuilder(
    decimals,
    chartUnit,
    comparisonType === FrequencyComparisonType.META,
  )

  const seriesCategories: string[] = useMemo(() => {
    return createCategoriesFromSeries(
      queryType,
      metaFrequencyCounts || numericFrequencyCounts || openFrequencyCounts || numericKpiCounts,
    )
  }, [metaFrequencyCounts, numericFrequencyCounts, openFrequencyCounts, numericKpiCounts])

  const handleClickChartPoint = useCallback(
    function (e: PointClickEventObject) {
      if (queryType === FrequencyQueryType.META || queryType === FrequencyQueryType.NUMERIC) return
      setClickedPoint(e.point)
    },
    [queryType],
  )

  const metaFrequencyPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!metaFrequencyQuery || !metaFrequencyCounts) return null
    if (queryType !== FrequencyQueryType.META || !queryBase) return null
    return createPointOptionsArrayFromSingleSeries(metaFrequencyCounts, seriesCategories, queryBase)
  }, [metaFrequencyCounts, metaFrequencyQuery, chartUnit, decimals])

  const metaComparisonFrequencyPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!metaFrequencyComparisonCounts || !metaFrequencyCounts) return null
    if (queryType !== FrequencyQueryType.META || !comparisonQueryBase) return null
    return createPointOptionsArrayFromSingleSeries(
      metaFrequencyComparisonCounts,
      seriesCategories,
      comparisonQueryBase,
      true,
    )
  }, [
    metaFrequencyComparisonCounts,
    metaFrequencyComparisonQuery,
    metaFrequencyCounts,
    chartUnit,
    decimals,
  ])

  const numericFrequencyPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!numericFrequencyQuery || !numericFrequencyCounts) return null
    if (queryType !== FrequencyQueryType.NUMERIC || !queryBase) return null
    const points = createPointOptionsArrayFromSingleSeries(
      numericFrequencyCounts,
      seriesCategories,
      queryBase,
    )
    if (!points) return points
    return points.filter((point) => isWithinNumericLimits(point, numericMin, numericMax))
  }, [numericFrequencyCounts, numericFrequencyQuery, chartUnit, decimals, numericMin, numericMax])

  const numericComparisonFrequencyPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!numericComparisonFrequencyQuery || !numericFrequencyComparisonCounts) return null
    if (queryType !== FrequencyQueryType.NUMERIC || !comparisonQueryBase) return null
    const points = createPointOptionsArrayFromSingleSeries(
      numericFrequencyComparisonCounts,
      seriesCategories,
      comparisonQueryBase,
      true,
    )
    if (!points) return points
    return points.filter((point) => isWithinNumericLimits(point, numericMin, numericMax))
  }, [
    numericFrequencyComparisonCounts,
    numericComparisonFrequencyQuery,
    chartUnit,
    decimals,
    numericMin,
    numericMax,
  ])

  const openFrequencyPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!openFrequencyQuery || !openFrequencyCounts) return null
    if (queryType !== FrequencyQueryType.OPEN || !queryBase) return null
    return createPointOptionsArrayFromSingleSeries(openFrequencyCounts, seriesCategories, queryBase)
  }, [openFrequencyCounts, openFrequencyQuery, chartUnit, decimals, queryType])

  const openComparisonFrequencyPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!openComparisonFrequencyQuery || !openComparisonFrequencyCounts) return null
    if (queryType !== FrequencyQueryType.OPEN || !comparisonQueryBase) return null
    return createPointOptionsArrayFromSingleSeries(
      openComparisonFrequencyCounts,
      seriesCategories,
      comparisonQueryBase,
      true,
    )
  }, [openComparisonFrequencyCounts, openComparisonFrequencyQuery, chartUnit, decimals])

  const numericKpiPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!numericKpiQuery || !numericKpiCounts) return null
    if (queryType !== FrequencyQueryType.NUMERIC_KPI || !queryBase) return null
    return createPointOptionsArrayFromMultipleSeries(numericKpiCounts, queryBase)
  }, [numericKpiCounts, numericKpiQuery, chartUnit, decimals, queryType, numericKpiDataFormat])

  const numericKpiDateComparisonPoints: PointOptionsObject[] | null = useMemo(() => {
    if (!numericKpiComparisonQuery || !numericKpiComparisonCounts) return null
    if (queryType !== FrequencyQueryType.NUMERIC_KPI || !comparisonQueryBase) return null
    return createPointOptionsArrayFromMultipleSeries(
      numericKpiComparisonCounts,
      comparisonQueryBase,
    )
  }, [
    numericKpiComparisonCounts,
    numericKpiComparisonQuery,
    chartUnit,
    decimals,
    queryType,
    numericKpiDataFormat,
  ])

  const barSeries: SeriesOptions[] | null = useMemo(() => {
    if (chartType !== FrequencyChartType.BAR) return null
    else
      return createBaOrColumnSeries(
        'bar',
        metaFrequencyPoints,
        metaComparisonFrequencyPoints,
        numericFrequencyPoints,
        numericComparisonFrequencyPoints,
        openFrequencyPoints,
        openComparisonFrequencyPoints,
        numericKpiPoints,
        numericKpiDateComparisonPoints,
        queryBase,
        comparisonQueryBase,
        metaGrouping || '',
      )
  }, [
    metaFrequencyPoints,
    metaComparisonFrequencyPoints,
    numericFrequencyPoints,
    numericComparisonFrequencyPoints,
    openFrequencyPoints,
    openComparisonFrequencyPoints,
    numericKpiPoints,
    numericKpiDateComparisonPoints,
    queryBase,
    comparisonQueryBase,
    metaGrouping,
    chartType,
    comparisonType,
    i18n.language,
  ])

  const columnSeries: SeriesOptions[] | null = useMemo(() => {
    if (chartType !== FrequencyChartType.COLUMN) return null
    else
      return createBaOrColumnSeries(
        'column',
        metaFrequencyPoints,
        metaComparisonFrequencyPoints,
        numericFrequencyPoints,
        numericComparisonFrequencyPoints,
        openFrequencyPoints,
        openComparisonFrequencyPoints,
        numericKpiPoints,
        numericKpiDateComparisonPoints,
        queryBase,
        comparisonQueryBase,
        metaGrouping || '',
      )
  }, [
    metaFrequencyPoints,
    metaComparisonFrequencyPoints,
    numericFrequencyPoints,
    numericComparisonFrequencyPoints,
    openFrequencyPoints,
    openComparisonFrequencyPoints,
    numericKpiPoints,
    numericKpiDateComparisonPoints,
    metaGrouping,
    chartType,
    comparisonType,
    i18n.language,
  ])

  const pieSeries: SeriesPieOptions[] | null = useMemo(() => {
    if (chartType !== FrequencyChartType.PIE) return null
    const seriesBase: SeriesPieOptions = { type: 'pie' }
    const normalSeriesName = `${queryBase?.start_date} - ${queryBase?.end_date}`
    if (metaFrequencyPoints) {
      return [{ ...seriesBase, data: metaFrequencyPoints, name: metaGrouping }]
    }
    if (numericFrequencyPoints) {
      return [{ ...seriesBase, data: numericFrequencyPoints, name: normalSeriesName }]
    }
    if (openFrequencyPoints) {
      return [{ ...seriesBase, data: openFrequencyPoints, name: normalSeriesName }]
    }
    if (numericKpiPoints) {
      return [{ ...seriesBase, data: numericKpiPoints, name: normalSeriesName }]
    }
    return null
  }, [
    metaFrequencyPoints,
    numericFrequencyPoints,
    openFrequencyPoints,
    numericKpiPoints,
    chartType,
  ])

  const isLoading = useMemo(() => {
    const hasData =
      !!metaFrequencyCounts ||
      !!openFrequencyCounts ||
      !!numericFrequencyCounts ||
      !!numericKpiCounts
    return (
      (!hasData && isWaitingForFetching(moduleStatus)) ||
      isLoadingCalculatedKpis ||
      isLoadingCommonDbSettings ||
      isLoadingNumericKpis ||
      isLoadingOpenKpis ||
      isLoadingNumericKpiCounts ||
      isLoadingMetaFrequencyCounts ||
      isLoadingNumericFrequencyCounts ||
      isLoadingOpenFrequencyCounts ||
      isLoadingNumericKpiComparisonCounts ||
      isLoadingMetaFrequencyComparisonCounts ||
      isLoadingNumericFrequencyComparisonCounts ||
      isLoadingOpenComparisonFrequencyCounts ||
      (!columnSeries && !barSeries && !pieSeries)
    )
  }, [
    moduleStatus,
    isLoadingCalculatedKpis,
    isLoadingCommonDbSettings,
    isLoadingNumericKpis,
    isLoadingOpenKpis,
    isLoadingNumericKpiCounts,
    isLoadingMetaFrequencyCounts,
    isLoadingNumericFrequencyCounts,
    isLoadingOpenFrequencyCounts,
    isLoadingNumericKpiComparisonCounts,
    isLoadingMetaFrequencyComparisonCounts,
    isLoadingNumericFrequencyComparisonCounts,
    isLoadingOpenComparisonFrequencyCounts,
    numericFrequencyCounts,
    metaFrequencyCounts,
    openFrequencyCounts,
    numericKpiCounts,
    columnSeries,
    barSeries,
    pieSeries,
  ])

  const isRefetching = useMemo(() => {
    const hasData =
      !!metaFrequencyCounts ||
      !!openFrequencyCounts ||
      !!numericFrequencyCounts ||
      !!numericKpiCounts
    return (
      (hasData && isWaitingForFetching(moduleStatus)) ||
      isRefetchingMetaFrequencyCounts ||
      isRefetchingNumericFrequencyCounts ||
      isRefetchingOpenFrequencyCounts ||
      isRefetchingNumericKpiCounts ||
      isRefetchingMetaFrequencyComparisonCounts ||
      isRefetchingNumericFrequencyComparisonCounts ||
      isRefetchingOpenComparisonFrequencyCounts ||
      isRefetchingNumericKpiComparisonCounts
    )
  }, [
    moduleStatus,
    isRefetchingMetaFrequencyCounts,
    isRefetchingNumericFrequencyCounts,
    isRefetchingOpenFrequencyCounts,
    isRefetchingNumericKpiCounts,
    isRefetchingMetaFrequencyComparisonCounts,
    isRefetchingNumericFrequencyComparisonCounts,
    isRefetchingOpenComparisonFrequencyCounts,
    isRefetchingNumericKpiComparisonCounts,
    metaFrequencyCounts,
    numericFrequencyCounts,
    openFrequencyCounts,
    numericKpiCounts,
  ])

  const DONE_STATUS_TIMEOUT = 200
  useEffect(() => {
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
    const isDoneFetching = !isLoading && !isRefetching
    if (isFetchingData(moduleStatus) && isDoneFetching) {
      doneStatusTimeoutRef.current = setTimeout(() => {
        updateModuleToIdle(id)
      }, DONE_STATUS_TIMEOUT)
    }
    return () => {
      doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
    }
  }, [moduleStatus, isLoading, isRefetching])

  const READY_TIMEOUT = 4000
  const readyTimeoutRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (!isLoading && isFetchingData(moduleStatus)) {
      if (readyTimeoutRef.current) clearTimeout(readyTimeoutRef.current)
      readyTimeoutRef.current = setTimeout(() => {
        setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
      }, READY_TIMEOUT)
    }
    return () => readyTimeoutRef.current && clearTimeout(readyTimeoutRef.current)
  }, [isLoading])

  const numberOfComparisonDays = useMemo(() => {
    if (comparisonDays) return comparisonDays
    if (queryBase?.start_date && queryBase?.end_date)
      return daysInBetween(queryBase?.start_date, queryBase?.end_date) + 1
  }, [comparisonDays, queryBase?.start_date, queryBase?.end_date])

  const toastError = (e: string) => {
    if (e) toast.error(e, toastCntr)
  }

  const handleOptionsPropertySave = useCallback(
    (properties: Partial<FrequencyModule['options']>) => {
      if (!module || !saveModule) return
      saveModule({ ...module, options: { ...(module.options || {}), ...properties } })
    },
    [module],
  )

  if (!queryType) {
    return (
      <div className={`${css.cntr} frequency-module-cntr`} data-no-data={true}>
        <div>{infoSelectDataType}</div>
        <div>
          <NoData />
        </div>
      </div>
    )
  }

  function isDataEmpty(): boolean {
    if (isLoading) return false
    if (queryType === FrequencyQueryType.META) return hasNoData(metaFrequencyCounts)
    if (queryType === FrequencyQueryType.NUMERIC) return hasNoData(numericFrequencyCounts)
    if (queryType === FrequencyQueryType.OPEN) return hasNoData(openFrequencyCounts)
    if (queryType === FrequencyQueryType.NUMERIC_KPI) return hasNoData(numericKpiCounts)
    return true
    function hasNoData(counts: KpiData.NumericData | null) {
      return isNil(counts) || isEmpty(counts) || isSeriesEmpty(counts.series)
    }
    function isSeriesEmpty(series: KpiData.NumericKpiData[]) {
      return series.reduce((hasNoData, series) => {
        if (series.data && Array.isArray(series.data) && series.data.length) {
          return false
        } else {
          return hasNoData
        }
      }, true)
    }
  }
  return (
    <div
      className={`${css.cntr} frequency-module-cntr ${ready}`}
      style={{
        ...(isReportMode ? { width: reportSVGWidth } : {}),
      }}
      ref={(element) => (moduleCntrRef.current = element ? element : undefined)}
      data-no-data={isDataEmpty()}
    >
      {isLoading && !pieSeries && !columnSeries && !barSeries && (
        <div className={css.loadingBig}>
          <CircularProgress thickness={1} size={'6rem'} style={{ opacity: 0.7 }} />
        </div>
      )}

      {clickedPoint && queryBase && (
        <FrequencyModuleInspectorCntr
          clickedPoint={clickedPoint}
          setClickedPoint={setClickedPoint}
          queryType={queryType}
          where_meta={queryBase.where_meta}
          selectedOpenKpis={selectedOpenKpis}
          grouping={metaGrouping}
          comparisonType={comparisonType}
        />
      )}
      {chartType === FrequencyChartType.BAR && !!barSeries && moduleCntrRef.current && (
        <Bar
          isReportMode={isReportMode}
          isScreenMode={isScreenMode}
          series={barSeries}
          chartOrder={chartOrder}
          limitValues={limitValues}
          isFreqFormatLimitTarget={freq_format === limitTargetRef.current}
          isSoftMin={!!isSoftMin}
          isSoftMax={!!isSoftMax}
          chartFormat={chartFormat}
          comparisonType={comparisonType}
          comparisonDays={numberOfComparisonDays}
          comparisonMeta={numericKpiMetaGrouping}
          queryType={queryType}
          handleOptionsPropertySave={handleOptionsPropertySave}
          handleClickChartPoint={handleClickChartPoint}
          dataPoints={dataPoints}
          isLoading={isLoading || isRefetching}
          moduleCntrRef={moduleCntrRef}
        />
      )}
      {chartType === FrequencyChartType.COLUMN && !!columnSeries && moduleCntrRef.current && (
        <Column
          id={id}
          isReportMode={isReportMode}
          isScreenMode={isScreenMode}
          series={columnSeries}
          dataPoints={dataPoints}
          chartOrder={chartOrder}
          limitValues={limitValues}
          isFreqFormatLimitTarget={freq_format === limitTargetRef.current}
          isSoftMin={!!isSoftMin}
          isSoftMax={!!isSoftMax}
          chartFormat={chartFormat}
          comparisonType={comparisonType}
          comparisonDays={numberOfComparisonDays}
          comparisonMeta={numericKpiMetaGrouping}
          queryType={queryType}
          handleOptionsPropertySave={handleOptionsPropertySave}
          handleClickChartPoint={handleClickChartPoint}
          isLoading={isLoading || isRefetching}
          moduleCntrRef={moduleCntrRef}
        />
      )}
      {chartType === FrequencyChartType.PIE && !!pieSeries && moduleCntrRef.current && (
        <Pie
          isReportMode={isReportMode}
          isScreenMode={isScreenMode}
          series={pieSeries}
          categories={seriesCategories}
          pieType={pieType}
          chartFormat={chartFormat}
          handleClickChartPoint={handleClickChartPoint}
          isLoading={isLoading || isRefetching}
          moduleCntrRef={moduleCntrRef}
          isRefetching={isRefetching}
        />
      )}
    </div>
  )
}

export default FrequencyModuleCntr
