import React from 'react'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import RadioButtonsInput from '../../_shared/ModuleSettings/components/RadioButtonsInput'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { topBotInterestInfo, topBotPathInfo } from '../TopBottomNotifications'
import { DataDisplayOptions, TopBottomModuleSettings } from '../topBottomTypes'
import KpiSelectionTreeData from './KpiSelectionTreeData'
import SelectMaxResults from './SelectMaxResults'
import SelectTopBottomColumns from './SelectTopBottomColumns'

const TopBottomSettings = () => {
  const [{ module }] = useSettingsTools()
  const options = (module?.options || {}) as TopBottomModuleSettings
  const isCustomerPathEnabled = Boolean(options.useTopBottomCustomerPath)
  return (
    <>
      <Subtitle text='Configuration' />
      <Linebreak />
      <CheckBoxInput
        title={
          <TextWithInfo
            text={'Use customer path configuration'}
            tooltipDirection='top'
            info={topBotPathInfo}
          />
        }
        settingsName='useTopBottomCustomerPath'
        text='Enable'
        defaultValue={false}
        data_testid='useTopBottomCustomerPath'
        id='useTopBottomCustomerPath'
        width='half'
      />
      {!isCustomerPathEnabled && <KpiSelectionTreeData />}
      <Subtitle text='Display Options' />
      <Linebreak />
      <SelectTopBottomColumns />
      <RadioButtonsInput
        title='Data display'
        width='full'
        buttons={Object.values(DataDisplayOptions).map((display) => display)}
        settingsName='topBottomDataDisplay'
        data_testid='topBottomDataDisplay'
        isRequired
      />
      <SelectMaxResults />
      <CheckBoxInput
        title={
          <TextWithInfo
            text={'Show data of interest'}
            tooltipDirection='top'
            info={topBotInterestInfo}
          />
        }
        settingsName='showDataOfInterest'
        text='Enable'
        defaultValue={false}
        data_testid='showDataOfInterest'
        id='showDataOfInterest'
        width='third'
      />
    </>
  )
}

export default TopBottomSettings
