import React, { useState } from 'react'
import { KPIAnswer, MetadataKpis } from '../alertTypes'
import { tCommon, tData, tKpi } from '../../../../../../languages/i18n'
import { Button } from '@mui/material'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'

type FeedbackAlertModalTableProps = {
  tableData: MetadataKpis[]
  triggerKPI?: KPIAnswer
}

const FeedbackAlertModalTable = ({ tableData, triggerKPI }: FeedbackAlertModalTableProps) => {
  const [showAllTable, setShowAllTable] = useState<boolean>(false)

  return (
    <>
      <table>
        <tbody>
          {!showAllTable &&
            tableData.slice(0, 4).map((object: MetadataKpis, index) => (
              <tr
                key={index}
                style={{
                  fontWeight: triggerKPI?.kpi === object.key ? 'bold' : 'normal',
                  fontSize: '0.9em',
                }}
              >
                <td>{tKpi(object.key, { defaultValue: '' }) || tData(object.key)}</td>
                <td>{object.value}</td>
              </tr>
            ))}
          {showAllTable &&
            tableData.map((object: MetadataKpis, index) => (
              <tr
                key={index}
                style={{
                  fontWeight: triggerKPI?.kpi === object.key ? 'bold' : 'normal',
                  fontSize: '0.9em',
                }}
              >
                <td>{tKpi(object.key, { defaultValue: '' }) || tData(object.key)}</td>
                <td>{object.value}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {tableData.length > 4 && (
        <Button
          onClick={() => setShowAllTable(!showAllTable)}
          endIcon={showAllTable ? <ArrowUpward /> : <ArrowDownward />}
        >
          {showAllTable ? tCommon('button.showLess') : tCommon('button.showMore')}
        </Button>
      )}
    </>
  )
}

export default FeedbackAlertModalTable
