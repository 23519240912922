import React, { createContext, useEffect, useMemo, useReducer, useRef } from 'react'
import { useMountedState } from 'react-use'
import { v4 as uuid } from 'uuid'
import { ActionTypes, findModuleWithinRootGroup, rootReducer } from './rootReducer'
import { CustomFilter, GroupModule, Module, ModuleType, QuickTimeframe } from '../groupModuleTypes'
import { cloneDeep, isUndefined } from 'lodash'
import { format, subYears } from 'date-fns'
import {
  DEFAULT_DATE_FORMAT,
  getDatesQuickTimeframeButton,
} from '../../../../../react-services/datesService'
import useGroupModuleDates from '../useGroupModuleDates'
import { toast } from 'react-toastify'
import {
  errorConvertingModuleSettings,
  errorParsingDates,
  infoModuleNotPartOfList,
} from '../GroupNotifications'
import { Dashboard, GenericConfig, Kpi } from '../../../../../../types'
import { NumericKpi } from '../../../../../stores/useNumericKpis'
import { NumberTrendModule } from '../../NumberTrend/numbertrendTypes'
import { TopBottomModule } from '../../TopBottom/topBottomTypes'
import { handleConvertingSettingsFromOldModuleVersion } from '../../TopBottom/TopBottomModuleContainer'
import { WheelModule } from '../../Wheel/wheelModuleTypes'
import { DeprecatedTableModule, TabularModule } from '../../Tabular/tabularTypes'
import { handleConvertingTableToTabular } from '../../Tabular/TabularModuleConverter'
import {
  FilterArray,
  getFilterArrayEndDate,
  getFilterArrayStartDate,
  getFilterArrayWhereMeta,
  setFilterArrayEndDate,
  setFilterArrayStartDate,
  setFilterArrayWhereMeta,
} from '../../../../../react-services/filterService'
import { AlertModule } from '../../FeedbackAlerts/alertTypes'

type ActionFunctionsContext = {
  saveModule: (module: Module) => void
  addModule: (m: Dashboard.PickableModule, parentGroupId: string) => void
  moveModuleUp: (module: Module) => void
  moveModuleDown: (module: Module) => void
  removeModule: (module: Module) => void
}
export const RootModuleContext = createContext<GroupModule | null>(null)
export const RootActionsContext = createContext<ActionFunctionsContext>({
  saveModule: () => ({}),
  addModule: () => ({}),
  moveModuleUp: () => ({}),
  moveModuleDown: () => ({}),
  removeModule: () => ({}),
})

type RootProviderProps = {
  initRootModule: GroupModule
  saveRootModule: (module: GroupModule) => void
  children: React.ReactNode
  toastifyId: string
  calculatedKpis: GenericConfig.CalculatedKpis
  numericKpis: NumericKpi[]
}

const SAVE_TIMEOUT = 300

export const RootProvider = ({
  initRootModule,
  saveRootModule,
  toastifyId,
  children,
  calculatedKpis,
  numericKpis,
}: RootProviderProps) => {
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const isMounted = useMountedState()
  const toastCntr = { containerId: toastifyId }

  const convertedRootModule = useMemo(
    () =>
      convertAllModulesInsideRoot(
        initRootModule,
        calculatedKpis,
        numericKpis,
        toastifyId,
        false,
        [],
      ),
    [],
  )
  const [rootModule, dispatch] = useReducer(rootReducer, convertedRootModule)

  useEffect(() => {
    if (!isMounted()) return
    saveCall()
    return () => {
      if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    }
  }, [rootModule])

  const saveCall = () => {
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    saveTimeoutRef.current = setTimeout(() => {
      if (!isMounted()) return
      saveRootModule(
        convertAllModulesInsideRoot(rootModule, calculatedKpis, numericKpis, toastifyId, false, []),
      )
    }, SAVE_TIMEOUT)
  }

  const saveModule = (module: Module) => {
    dispatch({ type: ActionTypes.SAVE, data: { module } })
  }

  const addModule = (m: Dashboard.PickableModule, parentGroupId: string) => {
    const modulesTypes: string[] = Object.values(ModuleType)
    if (!modulesTypes.includes(m.type)) return toast.info(infoModuleNotPartOfList, toastCntr)
    dispatch({ type: ActionTypes.ADD, data: { newModule: m, parentGroupId } })
  }

  const moveModuleUp = (module: Module) => {
    dispatch({ type: ActionTypes.MOVE_UP, data: { module } })
  }

  const moveModuleDown = (module: Module) => {
    dispatch({ type: ActionTypes.MOVE_DOWN, data: { module } })
  }

  const removeModule = (module: Module) => {
    dispatch({ type: ActionTypes.REMOVE, data: { module } })
  }

  return (
    <RootModuleContext.Provider value={rootModule}>
      <RootActionsContext.Provider
        value={{ saveModule, addModule, moveModuleUp, moveModuleDown, removeModule }}
      >
        {children}
      </RootActionsContext.Provider>
    </RootModuleContext.Provider>
  )
}

export const useRootModule = () => {
  const rootModule = React.useContext(RootModuleContext)
  if (rootModule === undefined) {
    throw new Error('useRootModule must be used within a RootProvider')
  }
  return rootModule
}

export const useModule = (id?: string) => {
  const rootModule = useRootModule()
  if (!id || !rootModule) return null
  return findModuleWithinRootGroup(rootModule, id) || null
}

export const useRootActions = () => {
  const rootActions = React.useContext(RootActionsContext)
  if (rootActions === undefined) {
    throw new Error('useRootActions must be used within a RootProvider')
  }
  return rootActions
}

const convertAllModulesInsideRoot = (
  root: GroupModule,
  calculatedKpis: GenericConfig.CalculatedKpis,
  numericKpis: NumericKpi[],
  toastifyId: string,
  isNested: boolean,
  parentFilters: FilterArray,
): GroupModule => {
  try {
    let newRoot = cloneDeep(root)
    if (!isNested) newRoot = handleAddingIds(newRoot)
    newRoot = handleGroupModuleInit(newRoot, isNested, parentFilters, toastifyId)
    const nestedParentFilters = newRoot.settings || []
    const modules = newRoot.modules
    if (!modules) return newRoot
    newRoot.modules = convertNestedModules(
      modules,
      nestedParentFilters,
      calculatedKpis,
      numericKpis,
      toastifyId,
    )
    return newRoot
  } catch (error) {
    toast.error(errorConvertingModuleSettings, { toastId: toastifyId })
    return root
  }
}

const convertNestedModules = (
  modules: Module[],
  parentFilters: FilterArray,
  calculatedKpis: GenericConfig.CalculatedKpis,
  numericKpis: NumericKpi[],
  toastifyId: string,
): Module[] => {
  const newModules = modules.map((m) => {
    switch (m.type) {
      case ModuleType.ALERT: {
        let newM = cloneDeep(m)
        newM = initAlertsModule(newM)
        return newM
      }

      case ModuleType.GROUP: {
        let newM = cloneDeep(m)
        newM = handleGroupModuleInit(newM, true, parentFilters, toastifyId)
        const nestedParentFilters = newM.settings || []
        const modules = newM.modules
        if (!modules) return newM
        newM.modules = convertNestedModules(
          modules,
          nestedParentFilters,
          calculatedKpis,
          numericKpis,
          toastifyId,
        )
        return newM
      }
      case ModuleType.GROUP2: {
        let newM = cloneDeep(m)
        newM = handleGroupModuleInit(newM, true, parentFilters, toastifyId)
        const nestedParentFilters = newM.settings || []
        const modules = newM.modules
        if (!modules) return newM
        newM.modules = convertNestedModules(
          modules,
          nestedParentFilters,
          calculatedKpis,
          numericKpis,
          toastifyId,
        )
        return newM
      }

      case ModuleType.LISTNUMERIC: {
        let newM = cloneDeep(m)
        newM = initListNumericModule(newM)
        return newM
      }

      case ModuleType.NUMBERTREND: {
        let newM = cloneDeep(m)
        newM = initNumberTrendModule(newM, calculatedKpis, numericKpis)
        return newM
      }

      case ModuleType.TABLE: {
        let newM: TabularModule | DeprecatedTableModule = cloneDeep(m)
        newM = initTableModule(newM, calculatedKpis, numericKpis)
        return newM
      }

      case ModuleType.TABULAR: {
        let newM = cloneDeep(m)
        newM = initTabularModule(newM)
        return newM
      }

      case ModuleType.WHEEL: {
        let newM = cloneDeep(m)
        newM = initWheelModule(newM)
        return newM
      }

      default:
        return m
    }
  })
  return newModules
}

const handleGroupModuleInit = (
  group: GroupModule,
  isNested: boolean,
  parentFilters: FilterArray,
  toastifyId: string,
) => {
  let newGroup = cloneDeep(group)
  newGroup = convertGroupModuleSettings(newGroup)
  newGroup = initGroupModuleDates(newGroup, isNested, parentFilters, toastifyId)
  newGroup = initGroupModuleWhereMeta(newGroup, parentFilters)
  return newGroup
}

const convertGroupModuleSettings = (module: GroupModule): GroupModule => {
  const newModule = cloneDeep(module)
  if (!newModule.options) newModule.options = {}
  if (!newModule.options.modulewidth) newModule.options.modulewidth = '12'
  if (isUndefined(newModule.autoTimeScale)) newModule.autoTimeScale = true
  if (newModule.type === ModuleType.GROUP2) newModule.type = ModuleType.GROUP
  return newModule
}

const handleAddingIds = (root: GroupModule) => {
  const newModule = cloneDeep(root)
  if (!newModule.id) newModule.id = uuid()
  const modules = newModule.modules || []
  const modulesWithIds = modules.map((m) => {
    if (m.type === ModuleType.GROUP) {
      if (!m.id) m.id = uuid()
      if (m.modules) m = handleAddingIds(m)
      return m
    } else {
      if (m.id) return m
      return { ...m, id: uuid() }
    }
  })
  newModule.modules = modulesWithIds
  return newModule
}

const initGroupModuleDates = (
  module: GroupModule,
  isNested: boolean,
  parentFilters: FilterArray,
  toastifyId: string,
) => {
  const { getFutureTimeFrame, getPastTimeFrame } = useGroupModuleDates()
  const newModule = cloneDeep(module)
  const currentDate = new Date()
  const isResetDateEnabled = newModule.autoTimeScale !== false
  const defaultDates = {
    startDate: format(subYears(currentDate, 1), DEFAULT_DATE_FORMAT),
    endDate: format(currentDate, DEFAULT_DATE_FORMAT),
  }

  const newDates = {
    startDate:
      getFilterArrayStartDate(newModule.settings || []) ||
      getFilterArrayStartDate(parentFilters || []),
    endDate:
      getFilterArrayEndDate(newModule.settings || []) || getFilterArrayEndDate(parentFilters || []),
  }

  try {
    if (newModule.quicktimeframebutton && newModule.quicktimeframebutton !== QuickTimeframe.NONE) {
      const tf = newModule.quicktimeframebutton
      const dates = getDatesQuickTimeframeButton(tf, new Date())
      newDates.startDate = format(dates.start, DEFAULT_DATE_FORMAT)
      newDates.endDate = format(dates.end, DEFAULT_DATE_FORMAT)
    } else if (newModule.timeframedays) {
      const dates = getPastTimeFrame(
        newModule.timeframedays,
        isResetDateEnabled,
        getFilterArrayEndDate(module.settings || []),
      )
      newDates.startDate = dates.startDate
      newDates.endDate = dates.endDate
    } else if (newModule.timeframedays_future) {
      const dates = getFutureTimeFrame(
        newModule.timeframedays_future,
        isResetDateEnabled,
        getFilterArrayStartDate(module.settings || []),
      )
      newDates.startDate = dates.startDate
      newDates.endDate = dates.endDate
    } else {
      newDates.startDate = getFilterArrayStartDate(module.settings || []) || defaultDates.startDate
      newDates.endDate =
        isResetDateEnabled || !getFilterArrayEndDate(module.settings || [])
          ? defaultDates.endDate
          : getFilterArrayEndDate(module.settings || [])
    }
  } catch (e) {
    toast.error(errorParsingDates, { toastId: toastifyId })
    console.error(e)
  }

  if (
    isNested &&
    (!module.customfilters ||
      module.customfilters === CustomFilter.NONE ||
      module.customfilters === CustomFilter.MERGE)
  ) {
    newDates.startDate = getFilterArrayStartDate(parentFilters) || defaultDates.startDate
    newDates.endDate = getFilterArrayEndDate(parentFilters) || defaultDates.endDate
  }
  if (
    isNested &&
    (module.customfilters === CustomFilter.CUSTOM ||
      module.customfilters === CustomFilter.CUSTOM_TIMEFRAME)
  ) {
    newDates.startDate = newDates.startDate || defaultDates.startDate
    newDates.endDate = newDates.endDate || defaultDates.endDate
  }

  newModule.settings = setFilterArrayStartDate(newModule.settings || [], newDates.startDate)
  newModule.settings = setFilterArrayEndDate(newModule.settings || [], newDates.endDate)
  const newStartDate = getFilterArrayStartDate(newModule.settings || [])
  const newEndDate = getFilterArrayEndDate(newModule.settings || [])
  if (newModule.query) {
    newModule.query.start_date = newStartDate
    newModule.query.end_date = newEndDate
  }
  return newModule
}

const initGroupModuleWhereMeta = (module: GroupModule, parentFilters: FilterArray) => {
  if (module.customfilters === CustomFilter.CUSTOM) return module
  const newModule = cloneDeep(module)
  if (!newModule.options) newModule.options = {}
  if (
    newModule.customfilters === CustomFilter.CUSTOM_TIMEFRAME ||
    newModule.customfilters === CustomFilter.NONE
  ) {
    newModule.settings = setFilterArrayWhereMeta(
      newModule.settings || [],
      getFilterArrayWhereMeta(parentFilters),
    )
  }
  if (!newModule.settings) newModule.settings = []
  const newWhereMeta = getFilterArrayWhereMeta(newModule.settings)
  if (newModule.query) newModule.query.where_meta = newWhereMeta
  return newModule
}

const initAlertsModule = (module: AlertModule) => {
  const newModule = cloneDeep(module)
  if (!newModule.options) newModule.options = {}
  if (!newModule.options.modulewidth) newModule.options.modulewidth = '12'
  if (!module.title) newModule.title = 'Alerts'
  return newModule
}

const initNumberTrendModule = (
  module: NumberTrendModule,
  calculatedKpis: GenericConfig.CalculatedKpis,
  numericKpis: NumericKpi[],
) => {
  if (module.title || !module.selections) return module
  let selectedKpi: Kpi.Kpi | null = null
  let selectedCalculatedKpi: GenericConfig.CalculatedKpis | null = null
  const kpiIdentifier = Object.keys(module.selections)[0]
  if (!kpiIdentifier) return module
  const parsedKpiIndentifier = Number(kpiIdentifier)
  if (isNaN(parsedKpiIndentifier)) {
    selectedKpi = null
    selectedCalculatedKpi = {
      [kpiIdentifier]: calculatedKpis[kpiIdentifier],
    }
  }
  if (!isNaN(parsedKpiIndentifier)) {
    selectedKpi = numericKpis.find((kpi) => kpi.id === parsedKpiIndentifier) || null
    selectedCalculatedKpi = null
  }
  const newModule = cloneDeep(module)
  if (selectedKpi) newModule.title = selectedKpi.name
  if (selectedCalculatedKpi) newModule.title = Object.keys(selectedCalculatedKpi)[0]
  return newModule
}

const initListNumericModule = (module: TopBottomModule) => {
  if (!module.topBotConf) return module
  const newModule = handleConvertingSettingsFromOldModuleVersion(module)
  return newModule
}

const initTabularModule = (module: TabularModule) => {
  if (!module.settings) return module
  const newModule = cloneDeep(module)
  if (!newModule.options) newModule.options = {}
  newModule.options = { ...(module.settings || {}), ...newModule.options }
  delete newModule.settings
  return newModule
}

const initWheelModule = (module: WheelModule) => {
  const oldModuleHeight = module.options?.moduleHeight
  if (!oldModuleHeight) return module
  const newModule = cloneDeep(module)
  if (!newModule.options) newModule.options = {}
  newModule.options.moduleheight = oldModuleHeight.toString()
  return newModule
}

const initTableModule = (
  module: DeprecatedTableModule,
  calculatedKpis: GenericConfig.CalculatedKpis,
  numericKpis: NumericKpi[],
) => {
  const newTabularModule = handleConvertingTableToTabular(module, numericKpis, calculatedKpis)
  return newTabularModule || module
}
