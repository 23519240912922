import React from 'react'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoConfigKpiExtraction, infoKpiContext } from '../GroupNotifications'
import { CustomFilter, GroupModule, GroupModuleThemes, ModuleType } from '../groupModuleTypes'
import DaysPastInput from './DaysPastInput'
import DaysFutureInput from './DaysFutureInput'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'

const GroupSettings = () => {
  const [{ module, inGroup }] = useSettingsTools()
  // const {parentFilters} = useGroupModule()[0]
  let groupModule: GroupModule | null = null
  if (module?.type === ModuleType.GROUP) groupModule = module
  return (
    <>
      <Subtitle text='Context data' />
      <Linebreak />
      <CheckBoxInput
        settingsName='isKpiContextEnabled'
        data_testid='isKpiContextEnabled'
        id='isKpiContextEnabled'
        width='third'
        isDisabled
        defaultValue={false}
        text='Enabled'
        title={
          <TextWithInfo text='Group kpi context' info={infoKpiContext} tooltipDirection='right' />
        }
      />
      {!!groupModule?.options?.isKpiContextEnabled && (
        <CheckBoxInput
          settingsName='isConfigKpiExtractionEnabled'
          data_testid='isConfigKpiExtractionEnabled'
          id='isConfigKpiExtractionEnabled'
          width='third'
          defaultValue={false}
          text='Enabled'
          title={
            <TextWithInfo
              text='Config kpis'
              info={infoConfigKpiExtraction}
              tooltipDirection='right'
            />
          }
        />
      )}
      <Subtitle text='Filters' />
      <Linebreak />
      {!!inGroup && (
        <DropdownInput
          options={Object.values(CustomFilter)}
          settingsName='customfilters'
          title={'Custom filter type'}
          data_testid='customfilters'
          id='customfilters'
          isRootProperty
          width='half'
        />
      )}
      <DaysPastInput />
      <DaysFutureInput />

      <Subtitle text='Display' />
      <Linebreak />
      <CheckBoxInput
        settingsName='isQuickTimeButtonsEnabled'
        data_testid='isQuickTimeButtonsEnabled'
        id='isQuickTimeButtonsEnabled'
        width='third'
        defaultValue={inGroup ? false : true}
        text='Enabled'
        title='Quick time buttons'
      />
      <CheckBoxInput
        settingsName='hidefilters'
        data_testid='hidefilters'
        id='hidefilters'
        width='third'
        isRootProperty
        defaultValue={false}
        text='Hide filters'
        title='Hide filters'
      />
      <CheckBoxInput
        settingsName='isTimeDisplayEnabled'
        data_testid='isTimeDisplayEnabled'
        id='isTimeDisplayEnabled'
        width='third'
        defaultValue={false}
        text='Enabled'
        title='Time display'
      />
      <CheckBoxInput
        settingsName='autoTimeScale'
        data_testid='autoTimeScale'
        id='autoTimeScale'
        width='third'
        defaultValue={true}
        text='Enabled'
        title='Reset end date to today'
      />
      {!inGroup && (
        <DropdownInput
          options={Object.values(GroupModuleThemes)}
          settingsName='style'
          title={'Style theme'}
          defaultValue={GroupModuleThemes.DEFAULT}
          data_testid='style'
          id='style'
          width='half'
        />
      )}
    </>
  )
}

export default GroupSettings
