import { CaptionOptions } from 'highcharts8'
import { ExtendedPointOptionsObject } from '../wheelModuleTypes'
import { cloneDeep } from 'lodash'

// To force redraw
let idIncrement = 0

export type SetDataAction = {
  type: ActionTypes.SET_DATA
  data: {
    center: Highcharts.PointOptionsObject[]
    innerCircle: ExtendedPointOptionsObject[]
    outerCircle: ExtendedPointOptionsObject[]
  }
}

export type SetChartHeightAction = {
  type: ActionTypes.SET_HEIGHT
  data: {
    chartHeight: number | string
  }
}

export type SetChartCaptionAction = {
  type: ActionTypes.SET_CAPTION
  data: {
    caption: CaptionOptions | null
  }
}

export type SetLanguageAction = {
  type: ActionTypes.SET_LANGUAGE
}

export enum ActionTypes {
  SET_DATA = 'SET_DATA',
  SET_HEIGHT = 'SET_HEIGHT',
  SET_CAPTION = 'SET_CAPTION',
  SET_LANGUAGE = 'SET_LANGUAGE',
}
type ReducerAction =
  | SetDataAction
  | SetChartHeightAction
  | SetChartCaptionAction
  | SetLanguageAction

export const wheelReducer = (state: Highcharts.Options, action: ReducerAction) => {
  switch (action.type) {
    case ActionTypes.SET_DATA: {
      const newState = { ...state }
      const { center, innerCircle, outerCircle } = action.data
      if (!newState.series) return newState
      const currentSeries = newState.series[0]
      if (currentSeries && currentSeries.type === 'sunburst') {
        currentSeries.data = [...(center || []), ...(innerCircle || []), ...(outerCircle || [])]
        newState.series[0] = currentSeries
      }
      return newState
    }

    case ActionTypes.SET_HEIGHT: {
      const newState = { ...state }
      if (newState.chart) {
        newState.chart.height = action.data.chartHeight
      }
      return newState
    }

    case ActionTypes.SET_CAPTION: {
      const newState = { ...state }
      if (action.data.caption === null && newState.caption) {
        newState.caption.text = ''
      }
      if (action.data.caption) {
        newState.caption = action.data.caption
      }
      return newState
    }

    case ActionTypes.SET_LANGUAGE: {
      const newState = cloneDeep(state)
      const sunburstSeries = newState.series ? newState.series[0] : null
      if (!sunburstSeries || sunburstSeries.type !== 'sunburst') return newState
      sunburstSeries.id = `wheel-${idIncrement++}`
      if (newState.series) newState.series[0] = sunburstSeries
      return newState
    }

    default:
      return state
  }
}
