/* eslint-disable @typescript-eslint/no-empty-function */
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import React, { useState } from 'react'
import ReportRecipients from './ReportRecipients'
import NoData from '../../_shared/Infos/NoData'
import { ReportTool } from '../../../../types'
import { validateEmail } from '../../../react-services/validators'
import SaveSummaryRodal from './SaveSummaryRodal'

type Props = {
  reports: ReportTool.ReportTableRow[]
  onChangeRecipients: (key: string, recipients: string[]) => void
}

type ErrorMessage = {
  email: string
  errMessage: string
}

function ReportTable({ reports, onChangeRecipients }: Props) {

  const [recipients, setRecipients] = useState<string[]>([])
  const [reportName, setReportName] = useState<string | undefined>('')
  const [selectedReportId, setSelectedReportId] = useState<string>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [hasMetakey, setHasMetakey] = useState<boolean>(true)
  const [showSaveSummary, setShowSaveSummary] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])
  const [savedRecipients, setSavedRecipients] = useState<string[]>([])

  const headers = (
    <tr>
      <th>Name</th>
      <th onClick={() => { }}>Schedule (UTC)</th>
      <th>Last Sent</th>
      <th>Format</th>
      <th onClick={() => { }}>Recipients</th>
      <th onClick={() => { }} className="tool-icon-column"></th>
      <th onClick={() => { }} className="tool-icon-column"></th>
    </tr>
  )

  const createRows = () => {
    if (reports) {
      return reports.map((report) => {
        return (
          <tr key={report.id} data-testid={`report-table-row-${report.id}`}>
            <td>{report.dashboardName ?? ''}</td>
            <td>{report.sendingSchedule ?? ''}</td>
            <td>{report.lastRan ?? '-'}</td>
            <td>{report.format ?? ''}</td>
            <td className="pointer" onClick={() => { handleOpenRecipientsPreview(report) }}>
              <span className="tool" >
                <span className="pointer">{createRecipientsPreview(report.recipients)}</span>
                <span className="tip">Click to see more</span>
              </span>
            </td>
            <td>
              <span className="pointer" onClick={() => { handleOpenRecipientsPreview(report) }}>
                <i className="material-icons module-icon-color waves-effect">visibility</i>
              </span>
            </td>
            <td>
              {!report.hasContactTableMetakey &&
                <span data-testid={'edit-icon-span'} className="pointer" onClick={() => { handleOpenRecipientsEdit(report) }}>
                  <i className="material-icons module-icon-color waves-effect">edit</i>
                </span>
              }
            </td>
          </tr>
        )
      }
      )
    }
    else return []
  }

  const createRecipientsPreview = (recipients: string[]): string => {
    if (recipients.length === 0 || recipients.length === undefined) {
      return ''
    }
    else if (recipients.length === 1) {
      return recipients[0]
    }
    else if (recipients.length === 2) {
      return `${recipients[0]}, ${recipients[1]}`
    }
    return `${recipients[0]}, ${recipients[1]},...`
  }

  const handleCloseRecipientsPreview = () => {
    if (!selectedReportId) {
      return
    }
    if (isEditMode) onChangeRecipients(selectedReportId, recipients)
    setIsModalOpen(false)
    setRecipients([])
    setReportName('')
  }

  const handleCloseSaveSummary = () => {
    if (!selectedReportId) {
      return
    }
    setErrorMessages([])
    setSavedRecipients([])
    setShowSaveSummary(false)
    onChangeRecipients(selectedReportId, recipients)
    setSelectedReportId(undefined)
  }

  const handleOpenRecipientsPreview = (report: ReportTool.ReportTableRow) => {
    setRecipients(report.recipients)
    setReportName(report.dashboardName)
    setSelectedReportId(report.id)
    setHasMetakey(report.hasContactTableMetakey)
    setIsModalOpen(true)
    setIsEditMode(false)
  }

  const handleOpenRecipientsEdit = (report: ReportTool.ReportTableRow) => {
    setRecipients(report.recipients)
    setReportName(report.dashboardName)
    setSelectedReportId(report.id)
    setHasMetakey(report.hasContactTableMetakey)
    setIsModalOpen(true)
    setIsEditMode(true)
  }

  const handleSaveRecipients = (textField: string) => {
    if (!selectedReportId) {
      return
    }
    setShowSaveSummary(true)

    const { newRecipients, errors } = formatRecipients(textField)
    const oldAndNew = recipients.concat(newRecipients)

    setSavedRecipients(newRecipients)
    setErrorMessages(errors)
    setRecipients(oldAndNew)
  }

  const makeList = (textRecipients: string) => {
    const formatted = textRecipients.replace(/ |;|\n/g, ',')
    const splitted = formatted.split(',').filter(email => email !== '')
    return splitted
  }

  const formatRecipients = (textField: string): { newRecipients: string[], errors: ErrorMessage[] } => {
    const errors: ErrorMessage[] = []
    const newRecipients: string[] = makeList(textField)
    const oldAndNew: string[] = recipients.concat(newRecipients)
    const validated: string[] = []

    for (const email of newRecipients) {
      const validationError = validateEmail(email, oldAndNew)
      if (validationError === null) {
        validated.push(email)
      } else {
        errors.push({ email: '"' + email + '"', errMessage: validationError })
      }
    }

    return { errors, newRecipients: validated }
  }

  const handleRemove = (userIndex: number) => {
    if (!selectedReportId) {
      return
    }
    if (typeof userIndex !== 'undefined') {
      const newRecipients = [...recipients]
      newRecipients.splice(userIndex, 1)
      setRecipients(newRecipients)
    }
  }

  return (
    <div>
      {isModalOpen &&
        <ReportRecipients
          recipients={recipients}
          reportName={reportName}
          isEditMode={isEditMode}
          hasMetakey={hasMetakey}
          handleSaveRecipients={handleSaveRecipients}
          handleRemove={handleRemove}
          onClose={handleCloseRecipientsPreview} />
      }
      <GenericTable
        useFixedLayout
        headers={headers}
        rows={createRows()}
      />
      {reports.length === 0 &&
        <NoData />
      }
      {showSaveSummary &&
        <SaveSummaryRodal
          errorMessages={errorMessages}
          savedRecipients={savedRecipients}
          closeSaveSummary={handleCloseSaveSummary}
        />
      }
    </div>
  )
}

export default ReportTable