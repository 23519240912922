export const successUpdatingConfigInDb = 'Configuration changes were saved succesfully';
export const openTabTitle = 'Open answer inspector modal, meta columns';
export const openTabInfoPart1 = 'You can change the order here for open answer inspector modal. All possible metakeys can be seen listed on the left side and selected on the';
export const openTabInfoPart2 = 'right. After you have selected all the meta keys that you want to be displayed on open answers inspector, you can change their order. For '; 
export const openTabInfoPart3 = 'example if you want meta data \'country\' to show before \'city\';, simply hover over the country on the selected list and';
export const openTabInfoPart4 = 'use arrows to change the position. Lists below can be be sorted by order or by it&apos;s name by clicking on the column header. \n\n After ';
export const openTabInfoPart5 = 'all the changes are done, preview the inspector modal from icon on the right. If you want to keep these settings, make sure to click'
export const openTabInfoPart6 = '\'Save\';. If you want to start over, simply click the reset button to load current saved configuration.';
export const previewModalInfo = 'Preview for admin tool, data here is for testing purposes only';
export const confirmationModalInfo = 'These changes are done to global settings. Changes will affect all users. Please confirm that you want to continue and click "Save."';
export const confirmationModalTitle = 'WARNING! Please read and confirm.';
export const selectableMetasTitle = 'Selectable metakeys';
export const selectedMetasTitle = 'Selected metakeys';
export const placeholderStringForPreview = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
export const parentTabText = 'View customization';
export const subTabText = 'Open answers Inspector';