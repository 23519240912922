import React from 'react'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import DataFormatSelect from './DataFormatSelect'
import ImageUrlTextInput from './ImageUrlTextInput'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import NumberDisplaySelect from './NumberDisplaySelect'
import ComparisonCheckBox from './ComparisonCheckBox'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import { infoSecondaryNumber } from '../NumberTrendNotifications'
import KpiSelectionTreeData from './KpiSelectionTreeData'
import { NumberTrendModule } from '../numbertrendTypes'

const NumberTrendSettings = () => {
  const [{ module }] = useSettingsTools()
  const numberTrendModule = module as NumberTrendModule
  return (
    <>
      <Subtitle text='Data' />
      <Linebreak />
      <KpiSelectionTreeData />
      <DataFormatSelect />
      <Subtitle text='Customization' />
      <Linebreak />
      <ComparisonCheckBox />
      <NumberDisplaySelect
        title='Secondary number'
        info={infoSecondaryNumber}
        settingsName={'secondaryNumber'}
        isDisabled={numberTrendModule?.comparisonmode === true}
      />
      <CheckBoxInput
        settingsName='hideSecondaryNumber'
        title='Visibility of second line'
        text='Hide text'
        data_testid='hideSecondaryNumberInput'
        defaultValue={false}
      />
      <CheckBoxInput
        settingsName='hideTrendArrow'
        title='Visibility of arrow'
        text='Hide arrow'
        data_testid='hideTrendArrow'
        defaultValue={false}
      />
      <ImageUrlTextInput />
    </>
  )
}

export default NumberTrendSettings
