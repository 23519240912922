import React from 'react';
import { post, put, remove } from '../../../../react-services/apiService'
import GroupList from './GroupList'
import Info from './Info'
import AddGroup from './AddGroup'
import EditGroup from './EditGroup'
import DeleteGroup from './DeleteGroup'
import trackingService from '../../../../react-services/trackingService';
import { TrackingEvent } from '../../../../react-constants';
import PromisePool from '@supercharge/promise-pool'
import { cloneDeep } from 'lodash';

class GroupManagement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      group: null,
      showAddGroup: false,
      showEditGroup: false,
      showDeleteGroup: false
    }
  }

  toggleShowAddGroup = () => {
    this.setState({
      showAddGroup: !this.state.showAddGroup
    })
  }

  showEditGroup = (group) => {
    trackingService.track(TrackingEvent.OpenEditGroup)
    this.setState({
      showEditGroup: true,
      group: group
    })
  }

  closeEditGroup = () => {
    trackingService.track(TrackingEvent.CloseEditGroup)
    this.setState({
      showEditGroup: false,
      group: null
    })
  }

  showDeleteGroup = (group) => {
    trackingService.track(TrackingEvent.OpenDeleteGroup)
    this.setState({
      showDeleteGroup: true,
      group: group
    })
  }

  closeDeleteGroup = () => {
    trackingService.track(TrackingEvent.CloseDeleteGroup)
    this.setState({
      showDeleteGroup: false,
      group: null
    })
  }

  handleAdd = (name, modules, accessrights, defaultfilters, roles) => {
    trackingService.track(TrackingEvent.AddGroup)
    let maxId = 0
    if (this.props.groups && Object.keys(this.props.groups).length > 0) {
      for (let groupId in this.props.groups) {
        if (parseInt(groupId, 10) > maxId) {
          maxId = parseInt(groupId, 10)
        }
      }
    }
    const id = maxId + 1
    const newGroup = {
      [id]: {
        name: name,
        modules: modules,
        accessrights: accessrights,
        defaultfilters: defaultfilters,
        roles: roles
      }
    }
    put('PUT_GROUP', { value: newGroup })
      .then(res => {
        this.props.setGroupsActive()
        this.props.getUsers()
        this.toggleShowAddGroup()
      }).catch((error) => {
        console.error(error)
      })
  }

  handleUpdate = async (name, modules, accessrights, defaultfilters, roles) => {
    trackingService.track(TrackingEvent.EditGroup)
    this.setState({ isSaving: true });

    const editedGroup = {
      [this.state.group.id]: {
        name: name,
        modules: modules,
        accessrights: accessrights,
        defaultfilters: defaultfilters,
        roles: roles
      }
    }

    let groups = cloneDeep(this.props.groups)

    groups[this.state.group.id] = {
      name: name,
      modules: modules,
      accessrights: accessrights,
      defaultfilters: defaultfilters,
      roles: roles,
      id: this.state.group.id
    }

    let wasGroupSaveSuccessful = true;

    try {
      await put('PUT_GROUP', { value: editedGroup })
    } catch (error) {
      console.error(error)
      this.setState({ isSaving: false });
      wasGroupSaveSuccessful = false
    }

    if (wasGroupSaveSuccessful) {
      let affectedUsers = getAffectedUsers(this.props.users, this.state.group.id);
      if (affectedUsers.length === 0) {
        this.props.setGroupsActive()
        this.props.getUsers()
      }

      await PromisePool
        .for(affectedUsers)
        .withConcurrency(50)
        .process(async (user) => {
          await this.updateUserAfterGroupEdit(user, groups, roles)
        })

      this.props.setGroupsActive()
      this.props.getUsers()
      this.setState({ isSaving: false });
    }

    function getAffectedUsers(users, groupId) {
      return users.filter(user => user.usergroups && user.usergroups.length > 0 && typeof user.usergroups.find(g => g.id === groupId.toString()) !== "undefined");
    }
  }

  updateUserAfterGroupEdit = async (user, groups, roles) => {
    let groupIds = []
    for (let g of user.usergroups) {
      g && groupIds.push(g.id.toString())
    }

    if (!groupIds.includes(this.state.group.id)) {
      return;
    }

    let newAccessRights = []
    for (let g of groupIds) {
      for (let a of groups[g].accessrights) {
        newAccessRights.push(a)
      }
    }

    let newDefaultFilters = []
    for (let g of groupIds) {
      for (let d of groups[g].defaultfilters) {
        newDefaultFilters.push(d)
      }
    }

    let modules = []
    for (let g of groupIds) {
      for (let d of groups[g].modules) {
        modules.push(d.key)
      }
    }

    for (let a of newAccessRights) {
      if (user.filters && !Object.keys(user.filters).includes(a)) {
        user.filters[a] = []
      }
    }
    for (let d of newDefaultFilters) {
      if (user.defaultfilters && !Object.keys(user.defaultfilters).includes(d)) {
        user.defaultfilters[d] = []
      }
    }
    for (let a in user.filters) {
      if (!newAccessRights.includes(a)) {
        user.filters[a] = null;
      }
    }
    for (let d in user.defaultfilters) {
      if (!newDefaultFilters.includes(d)) {
        user.defaultfilters[d] = null;
      }
    }

    return this.updateAccessRightsAndDefaultFiltersAfterEdit(user.id, user.filters, user.defaultfilters, modules, roles)
  }

  updateAccessRightsAndDefaultFiltersAfterEdit = async (userId, accessrights, defaultfilters, modules, roles) => {
    let updates = [
      put('PUT_ACCESSRIGHTS', { value: accessrights ? accessrights : {} }, { 'user-id': userId }),
      put('PUT_USERDEFAULTFILTERS', { value: defaultfilters ? defaultfilters : {} }, { 'user-id': userId }),
      put('PUT_USERDBSETTINGS', { value: { templates: modules ? modules : [] } }, { 'user-id': userId }),
      post('POST_EDITUSER', { userId: userId, roles: roles ?? [], user_conf: { group: [this.state.group.id] } }, { parseAsText: true })
    ]
    return Promise.all(updates);
  }

  handleDelete = () => {
    trackingService.track(TrackingEvent.DeleteGroup)
    const groupId = this.state.group.id
    let groupsToSave = cloneDeep(this.props.groups);
    groupsToSave[groupId] = null;

    // modify users first
    let proceedWithGroupDelete = false;
    try {
      this.removeGroupFromUsers(groupId)
      proceedWithGroupDelete = true;
    } catch (e) {
      window.Materialize.toast('There was an error while removing this group from affected users. The group was not removed.<br/>Please refresh to try again and contact helpdesk@wheelq.com if this problem persists.')
    }

    if (proceedWithGroupDelete) {
      put('PUT_GROUP', { value: groupsToSave })
        .then(res => {
          this.props.setGroupsActive()
          this.props.getUsers()
        }).catch((error) => {
          console.error(error)
        })
    }
  }

  removeGroupFromUsers = (groupId) => {
    let users = cloneDeep(this.props.users)
    users.forEach((user) => {
      this.removeGroupFromUser(user, groupId)
    })
  }

  removeGroupFromUser = (user, groupId) => {
    let groupIds = []
    for (let g of user.usergroups) {
      groupIds.push(g.id.toString())
    }

    if (groupIds.includes(groupId)) {
      this.updateAccessRightsAndDefaultFiltersAfterRemove(user.id)
    }
  }

  updateAccessRightsAndDefaultFiltersAfterRemove = (userId) => {
    let promises = [
      remove('DELETE_ACCESSRIGHTS', { value: {} }, { 'user-id': userId }),
      remove('DELETE_USERDEFAULTFILTERS', { value: {} }, { 'user-id': userId }),
      put('PUT_USERDBSETTINGS', { value: { templates: [] } }, { 'user-id': userId }),
      post('POST_EDITUSER', { userId: userId, roles: [], user_conf: { group: [] } }, { parseAsText: true })
    ]
    Promise.all(promises).catch(error => {
      throw new Error(error)
    })
  }

  render() {
    return (
      <div data-cy="groupManagement">
        {this.props.groups ?
          <>
            <GroupList
              groups={this.props.groups}
              showEditGroup={this.showEditGroup}
              showDeleteGroup={this.showDeleteGroup}
            />
            {Object.keys(this.props.groups).length === 0 && <div className="user-group-editor-no-groups">No groups have yet been made. Click the button below to add the first one.</div>}
            <Info />
          </>
          :
          <div className="user-group-editor-no-groups">No groups have yet been made. Click the button below to add the first one.</div>
        }

        <button data-cy="addNewGroup" className='waves-effect waves-light btn orange lighten-1' onClick={this.toggleShowAddGroup}>Add a new group</button>
        {this.state.showAddGroup && this.props.filters &&
          <AddGroup
            templates={this.props.templates}
            filters={this.props.filters}
            toggleShowAddGroup={this.toggleShowAddGroup}
            handleAdd={this.handleAdd}
          />}

        {this.state.showEditGroup && this.props.filters &&
          <EditGroup
            group={this.state.group}
            templates={this.props.templates}
            filters={this.props.filters}
            closeEditGroup={this.closeEditGroup}
            handleUpdate={this.handleUpdate}
            isSaving={this.state.isSaving}
          />}

        {this.state.showDeleteGroup &&
          <DeleteGroup
            group={this.state.group}
            closeDeleteGroup={this.closeDeleteGroup}
            handleDelete={this.handleDelete}
          />}
      </div>
    )
  }
}

export default GroupManagement
