/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This is a logging service following the state design pattern.
 * 
 * Instead of using boolean flags for differentiating behaviour between
 * production and development, we have a context class that holds a reference to
 * class implementating the logging interface. Depending on the context the reference
 * points to either a development logger or a production logger and behaviour that
 * depends on that state is encapsulated within those classes.
 * 
 * The hope is to make it clearer how the service behaves based on context 
 * and that we wouldn't have to use debugging flags elsewhere.
 * 
 * TODO: this should propably be moved to the commons library at some point
 * 
 * USAGE: 
 *  import LoggingService from 'loggingServiceLocation'
 *  var logger = LoggingService.GetLoggerFor("module name") // var för höisting
 *  logger.log("message", {optional logdata}, {options})
 * 
 * NOTES:
 *  - Normally calls to console show up with filenames and line numbers but using this 
 *    causes everything to look like they come from here. To mitigate this try to use 
 *    descriptive messages and event names.
 *  - To use development logger in production see isDevelopmentLoggerForced function under utils/env
 */

import { post } from './apiService';
import { TrackingEvent, DEFAULT_METAS } from '../react-constants';
import { Logging } from '../../types'
import { isNodeEnv, getLoggerOverride } from '../utilities';
import { getUsername, getToken } from './authService';
import { removeQueryParams } from '../../utils';

/**
 * A singleton class encapsulating communication with 
 * the logging endpoint.
 * 
 * THIS IS ONLY EXPORTED FOR USE BY THE TRACKING SERVICE
 */
class BackendLoggingAPI {
  static INSTANCE: BackendLoggingAPI = new BackendLoggingAPI()

  private constructor() {
    // for lint
  }

  /**
   * Private method for logging events to athena. Includes default metadata.
   */
  private _doLog(eventName: string, message: string, error: boolean, moduleName: string, metadata: Record<string, string> = {}, elapsedTime?: number, errorMessage?: string): void {
    // eslint-disable-next-line eqeqeq
    if (getToken() == null) {
      console.debug('Tried to log without logging in')
      return
    }

    const sanitizedMetadata = Object.entries(metadata).reduce((result, [key, val]): Record<string, string> => {
      result[key] = `${val}`
      return result
    }, {} as Record<string, string>)

    const logData: Logging.LogData = {
      feStatistics: {
        ...DEFAULT_METAS,
        metadata: sanitizedMetadata, // make sure every value is a string
        moduleName,
        username: getUsername(),
        url: removeQueryParams(window.location.href),
        elapsedTime,
        errorMessage
      }
    }

    post('POST_LOG', {
      eventName: `fe_${eventName}`,
      message,
      error,
      logData
    }).catch((error: any) => {
      console.error(error)
    })
  }

  /**
   * Module private method for logging events to athena
   */
  _log(eventName: string, message: string, moduleName: string, metadata?: Record<string, string>, elapsedTime?: number) {
    this._doLog(eventName, message, false, moduleName, metadata, elapsedTime)
  }

  /**
   * Module private method for logging error to athena
   */
  _error(eventName: string, message: string, error: Error, moduleName: string, metadata?: Record<string, string>, elapsedTime?: number): void {
    this._doLog(eventName, message, true, moduleName, metadata, elapsedTime, error.stack)
  }

  /**
   * Sends a log entry to athena. Automatically prefixed by 
   * fe_web_analytics, lowercased and whitespace converted to underscores. 
   * The event name is sent as a message without formatting.
   */
  track(event: TrackingEvent, metadata?: Record<string, string>): void {
    this._doLog(`web_analytics_${event.toLowerCase().replace(/\s/, '_')}`, event, false, 'web analytics', metadata)
  }
}

interface Logger {
  log(message: string, moduleName: string, metadata?: Record<string, string>): void
  error(message: string, error: Error, moduleName: string, metadata?: Record<string, string>): void
  info(message: string, moduleName: string, metadata?: Record<string, string>): void
  warn(message: string, moduleName: string, metadata?: Record<string, string>): void
  debug(message: string, moduleName: string, metadata?: Record<string, string>): void
  performance(message: string, moduleName: string, elapsedTime: number, metadata?: Record<string, string>): void
}

/**
 * The development logger is a simple wrapper around the console object.
 * Follows the singleton pattern
 */
class DevelopmentLogger implements Logger {
  static INSTANCE: DevelopmentLogger = new DevelopmentLogger()

  private constructor() {
    // for lint
  }

  log(message: string, moduleName: string, metadata?: Record<string, string>): void {
    console.log('message:', message, 'moduleName:', moduleName, 'metadata:', metadata)
  }

  error(message: string, error: Error, moduleName: string, metadata?: Record<string, string>): void {
    console.error('message:', message, 'error:', error, 'moduleName:', moduleName, 'metadata:', metadata)
  }

  info(message: string, moduleName: string, metadata?: Record<string, string>): void {
    console.info('message:', message, 'moduleName:', moduleName, 'metadata:', metadata)
  }

  warn(message: string, moduleName: string, metadata?: Record<string, string>): void {
    console.warn('message:', message, 'moduleName:', moduleName, 'metadata:', metadata)
  }

  debug(message: string, moduleName: string, metadata?: Record<string, string>): void {
    console.debug('message:', message, 'moduleName:', moduleName, 'metadata:', metadata)
  }

  performance(message: string, moduleName: string, elapsedTime: number, metadata?: Record<string, string>): void {
    console.log('message:', message, 'moduleName:', 'elapsedTime:', elapsedTime, moduleName, 'metadata:', metadata)
  }
}

/**
 * The production logger sends calls to log and error to athena. Calls to other methods
 * have no effect to limit end user access to logging.
 * Follows the singleton pattern.
 */
class ProductionLogger implements Logger {
  static INSTANCE: ProductionLogger = new ProductionLogger()
  private backendLogger: BackendLoggingAPI = BackendLoggingAPI.INSTANCE

  private constructor() {
    // for lint
  }

  log(message: string, moduleName: string, metadata?: Record<string, string>): void {
    this.backendLogger._log('log', message, moduleName, metadata)
  }

  error(message: string, error: Error, moduleName: string, metadata?: Record<string, string>): void {
    this.backendLogger._error('error', message, error, moduleName, metadata)
  }

  performance(message: string, moduleName: string, elapsedTime: number, metadata?: Record<string, string>): void {
    this.backendLogger._log('log', message, moduleName, metadata, elapsedTime)
  }

  info() {
    // for lint
  }

  warn() {
    // for lint
  }

  debug() {
    // for lint
  }
}

/**
 * The context class that stores which logger class is used.
 * When instantiating the logger will be:
 *  Development Logger if
 *    - override points to development logger
 *    - node env is test or development
 *  Production Logger in any other case
 */
class LoggingService {
  /**
   * Reference to logger is static so that every instance of
   * this class has the same logger in use.
   * Uses IIFE to choose which logger to use during initialization.
   */
  private static logger: Logger = ((): Logger => {
    const overrideState = getLoggerOverride()
    if (overrideState === 'development') {
      return DevelopmentLogger.INSTANCE
    } else if (overrideState === 'production') {
      return ProductionLogger.INSTANCE
    } else if (isNodeEnv('test', 'development')) {
      return DevelopmentLogger.INSTANCE
    } else {
      return ProductionLogger.INSTANCE
    }
  })()
  private readonly moduleName: string

  private constructor(moduleName: string) {
    this.moduleName = moduleName
  }

  /**
   * Factory method for getting an instance of the logger.
   * @param moduleName name of the module
   */
  static GetLoggerFor(moduleName: string) {
    return new LoggingService(moduleName)
  }

  /**
   * For testing
   */
  private _useDevelopmentLogger() {
    LoggingService.logger = DevelopmentLogger.INSTANCE
  }

  /**
   * For testing
   */
  private _useProductionLogger() {
    LoggingService.logger = ProductionLogger.INSTANCE
  }

  /**
   * Logging based on context
   * In production data goes to athena
   * In development data goes to console.log
   */
  log(message: string, metadata?: Record<string, string>) {
    LoggingService.logger.log(message, this.moduleName, metadata)
  }

  /**
   * Logging based on context
   * In production data goes to athena with an error parameter
   * In development data goes to console.error
   */
  error(message: string, error: Error, metadata?: Record<string, string>) {
    LoggingService.logger.error(message, error, this.moduleName, metadata)
  }

  /**
   * Logging based on context
   * In production method is noop
   * In development data goes to console.info
   */
  info(message: string, metadata?: Record<string, string>) {
    LoggingService.logger.info(message, this.moduleName, metadata)
  }

  /**
   * Logging based on context
   * In production method is noop
   * In development data goes to console.warn
  */
  warn(message: string, metadata?: Record<string, string>) {
    LoggingService.logger.warn(message, this.moduleName, metadata)
  }

  /**
   * Logging based on context
   * In production method is noop
   * In development data goes to console.debug
   */
  debug(message: string, metadata?: Record<string, string>) {
    LoggingService.logger.debug(message, this.moduleName, metadata)
  }

  /**
   * Logging based on context
   * In production data goes to athena
   * In development data goes to console.log
   */
  performance(message: string, elapsedTime: number, metadata?: Record<string, string>) {
    LoggingService.logger.performance(message, this.moduleName, elapsedTime, metadata)
  }
}

export { BackendLoggingAPI }

export default LoggingService