/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Rodal from 'rodal'
import {Row, Col, Button} from 'react-materialize';

type Props = {
  user: User
  closeRemoveUser: () => void
  handleRemove: (i: number) => void
}

type User = {
  index: number
  email: string
}

const RemoveContact = ({ user, closeRemoveUser, handleRemove }: Props) => {

  return (
    <div>
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={true} 
        onClose={closeRemoveUser}
      >
        <h5>Remove recipient "{user.email}"</h5>
        <Row>
          <Col s={12}><hr/></Col>
          <Col s={12}></Col>
        </Row>
      
        <br /><br />
        <Row className="center">
          <Col s={5} offset="s1">
            <Button data-testid="remove" onClick={() => handleRemove(user.index)}>Remove</Button>
          </Col>
          <Col s={5}>
            <Button flat onClick={closeRemoveUser}>Cancel</Button>
          </Col>
        </Row>
      </Rodal>
    </div>
  )
}

export default RemoveContact