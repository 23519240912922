import React, { useContext, useEffect, useState } from 'react'
import { OpenModuleContext } from '../../../Open/_OpenModuleContext'

import { TrackingEvent } from '../../../../../../react-constants'
import trackingService from '../../../../../../react-services/trackingService'
import OpenInspector from './OpenInspector'
import { ToastProvider } from '../../../../../common/Notification/NotificationContext'
import NotificationContainer from '../../../../../common/Notification/NotificationContainer'
import ConversationModalContext from '../../ConversationModal/ConversationModalProvider'
import ConfirmationModal from '../../../../../common/ConfirmationModal/ConfirmationModal'
import useNewOpenAnswers, {
  OpenAnswersRequest,
  SortDirection,
} from '../../../../../../stores/useNewOpenAnswers'
import { Sorting } from '../inspectorTypes'
import useOpenKpis from '../../../../../../stores/useOpenKpis'
import { WhereMeta } from '../../../../../../../types'
import ErrorBoundary from '../../../../../_shared/Infos/ErrorBoundary'
import { errorLoadingInspector } from '../notifications'
import { TextualKpiData } from '../../../Open/openTypes'
import { OpenAnswer } from '../../../Pietabular/pietabularTypes'
import useGeneralConversation from '../../../../../../stores/useGeneralConversation'

type PropsBase = {
  conversationFromOpenModule?: boolean
  onClose: () => void
  inspected?: string
  customFilterAnswersFunction?: (data: OpenAnswer[]) => OpenAnswer[]
}

type Preview =
  | {
      isPreviewMode: true
      previewData: TextualKpiData[]
    }
  | {
      isPreviewMode?: false | null | undefined
      previewData?: undefined
    }

type QueryOrAnswer =
  | {
      query: OpenAnswersRequest
      answer?: undefined
    }
  | {
      query?: undefined
      answer: TextualKpiData
    }
type ValueInspectorProps = PropsBase & Preview & QueryOrAnswer

const OpenInspectorCntr = ({
  conversationFromOpenModule,
  previewData,
  answer,
  query,
  onClose,
  inspected = '',
  customFilterAnswersFunction,
}: ValueInspectorProps) => {
  const [sorting, setSorting] = useState<Sorting>({ column: 'date', order: SortDirection.DESC })
  const [singleAnswer, setSingleAnswer] = useState<TextualKpiData | null>(null)

  const [isConversationModalOpen, setIsConversationModalOpen] = useState<boolean>(false)
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false)
  const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(false)
  const [emailForConversation, setEmailForConversation] = useState<string>('')
  const { conversationsList, updateConversationsList, updateMessageCount } =
    useContext(OpenModuleContext)
  const { config: generalConversationConfig } = useGeneralConversation()

  const handleUnsavedChangesToggle = (isUnsaved: boolean) => {
    setIsUnsavedChanges(isUnsaved)
  }

  const { openKpis } = useOpenKpis()

  let openAnswerQuery = null
  if (query) {
    openAnswerQuery = {
      ...query,
      pagination: { page_number: 1, page_size: 50 },
      sort_columns_with_order: [
        { columnName: sorting.column, order: sorting.order },
        { columnName: 'date', order: SortDirection.ASC },
      ],
    }
  }

  const {
    answers: fetcheOpendAnswers,
    isLoading,
    fetchMoreAnswers,
    isRefetching: isLoadingMore,
    pagination,
  } = useNewOpenAnswers(openAnswerQuery)

  const answersLoaded = fetcheOpendAnswers?.length || 0
  const filteredAnswers =
    !!customFilterAnswersFunction && !!fetcheOpendAnswers
      ? customFilterAnswersFunction(fetcheOpendAnswers)
      : fetcheOpendAnswers

  let answers = null
  if (filteredAnswers) {
    answers = filteredAnswers
  }
  if (answer) {
    answers = [answer]
  }
  if (previewData) {
    answers = previewData
  }

  useEffect(() => {
    if (conversationFromOpenModule) {
      setIsConversationModalOpen(true)
    } else {
      setIsConversationModalOpen(false)
    }
  }, [conversationFromOpenModule])

  useEffect(() => {
    if (
      singleAnswer &&
      singleAnswer.metadata &&
      generalConversationConfig &&
      generalConversationConfig.conversation_emails &&
      generalConversationConfig.conversation_emails.length
    ) {
      for (const [key, value] of Object.entries(singleAnswer.metadata)) {
        const lowerCaseEmails = generalConversationConfig.conversation_emails.map((email) =>
          email.toLocaleLowerCase(),
        )
        const hasEmail = lowerCaseEmails.find((email) => email === key.toLocaleLowerCase())
        if (hasEmail) {
          setEmailForConversation(value)
          break
        }
      }
    }
  }, [singleAnswer, generalConversationConfig])

  useEffect(() => {
    trackingService.track(TrackingEvent.OpenValueInspector, {
      type: 'open',
    })
  }, [])

  const handleCloseConversation = () => {
    setIsConversationModalOpen(false)
  }

  function onCloseOpenInspector() {
    if (isUnsavedChanges) {
      setIsCancelConfirmationModalOpen(true)
    } else {
      trackingService.track(TrackingEvent.CloseValueInspector, {
        type: 'open',
      })
      onClose()
    }
  }

  const createQueryFromSingleAnswer = (
    answer?: TextualKpiData | null,
  ): OpenAnswersRequest | null => {
    if (!answer || !answer.date || !openKpis || !answer.metadata) return null
    const kpi = openKpis?.find((k) => k.id === answer.id)
    const metas = Object.entries(answer.metadata).reduce((a, [key, value]) => {
      if (value) a[key] = [value]
      return a
    }, {} as WhereMeta)
    if (!kpi) return null
    return {
      start_date: answer.date,
      end_date: answer.date,
      kpis: [kpi],
      where_meta: metas,
      answers: [answer.answer],
    }
  }

  return (
    <>
      <OpenInspector
        fetchMore={fetchMoreAnswers}
        isLoadingMore={isLoadingMore}
        isConversationModalOpen={isConversationModalOpen}
        setIsConversationModalOpen={setIsConversationModalOpen}
        singleAnswer={singleAnswer}
        setSingleAnswer={setSingleAnswer}
        answersData={answers}
        onClose={onCloseOpenInspector}
        inspected={inspected}
        isLoading={isLoading}
        setSorting={setSorting}
        sorting={sorting}
        query={query ? query : createQueryFromSingleAnswer(singleAnswer)}
        pagination={pagination}
        answersLoaded={answersLoaded}
        customFilterAnswersFunction={customFilterAnswersFunction}
      />

      {isConversationModalOpen && (
        <ToastProvider id={'20586'}>
          <NotificationContainer id={'20586'} />
          <ConversationModalContext
            completedSurveyId={answers ? answers[0].completed_survey_id : null}
            closeConversation={handleCloseConversation}
            unsavedChangesToggle={handleUnsavedChangesToggle}
            isUnsavedChanges={isUnsavedChanges}
            conversationsList={conversationsList}
            updateConversationsList={updateConversationsList}
            updateMessageCount={updateMessageCount}
            emailForConversation={emailForConversation}
          />
        </ToastProvider>
      )}
      {isCancelConfirmationModalOpen && (
        <ConfirmationModal
          handleConfirmation={onClose}
          handleCancel={() => setIsCancelConfirmationModalOpen(false)}
          title='Unsaved changes'
          info='Please confirm to exit without saving. To confirm, press enter or click confirm button. To cancel, press esc or click cancel.'
          enableEnterConfirmation={true}
        />
      )}
    </>
  )
}

const inspectorId = 'openInspectorToastId'
const OpenInspectorCntrWrapped = (props: ValueInspectorProps) => (
  <ToastProvider id={inspectorId}>
    <NotificationContainer id={inspectorId} />
    <ErrorBoundary
      message={errorLoadingInspector}
      containerId={inspectorId}
      fallback={<div style={{ color: 'red' }}>{errorLoadingInspector}</div>}
    >
      <OpenInspectorCntr {...props} />
    </ErrorBoundary>
  </ToastProvider>
)
export default OpenInspectorCntrWrapped
