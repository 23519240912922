import React, { memo, useEffect } from 'react'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import { ToastProvider } from '../../../common/Notification/NotificationContext'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { TopBottomDataProvider, useTopBottomData } from './Context/TopBottomDataContext'
import { TopBottomSettingsProvider, useTopBottomSettings } from './Context/TopBottomSettingsContext'
import TopBottomModule from './TopBottomModule'
import {
  DeprecatedTopBottomModuleSettings,
  TopBottomModule as TopBottomModuleType,
  DataDisplayOptions,
  TopBottomColumnNames,
} from './topBottomTypes'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import { cloneDeep, isEqual } from 'lodash'
import { isAllowedToFetch } from '../Group/contexts/renderListReducer'
import { QueueStatus } from '../Group/groupModuleTypes'

export type TopBottomModuleContainerProps = {
  id: string
  sharedFilter?: {
    filters: { name: string; value: string }[]
  }
  isScreenMode: boolean
  isReportMode: boolean
  module: TopBottomModuleType
  saveModule: (module: TopBottomModuleType) => void
  moduleStatus: QueueStatus | undefined
}

const TopBottomModuleContainer = memo((props: TopBottomModuleContainerProps) => {
  const moduleStatus = props.moduleStatus
  if (props.module.topBotConf) {
    if (!isAllowedToFetch(moduleStatus)) return <div></div>
    const newModule = handleConvertingSettingsFromOldModuleVersion(props.module)
    if (!isEqual(module, newModule)) {
      props.saveModule(newModule)
    }
    return (
      <div
        className={`topbot-module-converter ${CSSCONSTANTS.CLASS_MODULE_DONE_LOADING}`}
        data-no-data={true}
      ></div>
    )
  }

  return (
    <ToastProvider id={String(props.id)}>
      <NotificationContainer id={String(props.id)} />
      <ErrorBoundary
        message={'Error loading top bottom module'}
        containerId={props.id}
        fallback={<div style={{ color: 'red' }}>{'Error loading top bottom module'}</div>}
      >
        <TopBottomDataProvider>
          <TopBottomSettingsProvider>
            <TopBottomModuleProvider {...props} />
          </TopBottomSettingsProvider>
        </TopBottomDataProvider>
      </ErrorBoundary>
    </ToastProvider>
  )
})

const TopBottomModuleProvider = (props: TopBottomModuleContainerProps) => {
  const setTopBottomData = useTopBottomData()[1]
  const setTopBottomSettings = useTopBottomSettings()[1]

  useEffect(() => {
    const options = props.module.options
    if (options) {
      setTopBottomSettings(() => ({
        selectedColumns:
          options.selectedColumns && options.selectedColumns.length
            ? options.selectedColumns
            : [
                TopBottomColumnNames.CATEGORY,
                TopBottomColumnNames.KPI,
                TopBottomColumnNames.PERFORMANCE,
              ],
        selectedKpis: options.selectedKpis ? options.selectedKpis : [],
        useTopBottomCustomerPath: options.useTopBottomCustomerPath
          ? options.useTopBottomCustomerPath
          : false,
        topBottomDataDisplay: options.topBottomDataDisplay
          ? options.topBottomDataDisplay
          : DataDisplayOptions.TOP,
        topBottomMax: options.topBottomMax ? options.topBottomMax : '5',
        showDataOfInterest: options.showDataOfInterest ? options.showDataOfInterest : false,
      }))
    }
  }, [props.module.options])

  useEffect(() => {
    const query = props.module.query
    if (query && query.start_date && query.end_date) {
      const dashFilters = {
        start_date: query.start_date,
        end_date: query.end_date,
        where_meta: query.filters || query.where_meta || {},
      }
      setTopBottomData((prev) => ({
        ...prev,
        topBotFilters: dashFilters,
      }))
      return
    }

    const filters = props.sharedFilter?.filters
    if (filters) {
      const dashFilters = transformFiltersToWhereMetaForm(filters)
      setTopBottomData((prev) => ({
        ...prev,
        topBotFilters: dashFilters,
      }))
    }
  }, [props.sharedFilter, props.module.query])

  useEffect(() => {
    setTopBottomData((prev) => ({
      ...prev,
      id: props.id,
    }))
  }, [props.id])

  return <TopBottomModule moduleStatus={props.moduleStatus} />
}

export const handleConvertingSettingsFromOldModuleVersion = (module: TopBottomModuleType) => {
  const options = cloneDeep(module.options || ({} as TopBottomModuleType['options']))
  const oldOptions = module.topBotConf
  const newOptions = { ...options }
  if (!newOptions?.modulewidth) newOptions.modulewidth = '6'
  newOptions.topBottomDataDisplay = handleConvertingModuleType(oldOptions?.topBottom)
  newOptions.showDataOfInterest = !!oldOptions?.filterInteresting
  newOptions.topBottomMax = oldOptions?.numberOfResults?.toString() || '5'
  newOptions.useTopBottomCustomerPath = true
  newOptions.selectedColumns = handleConvertingModuleColumns(oldOptions?.columnNames)
  const newModule = cloneDeep(module)
  newModule.options = newOptions
  delete newModule.topBotConf
  return newModule
}

type ModuleType = DeprecatedTopBottomModuleSettings['topBottom']
export const handleConvertingModuleType = (type: ModuleType) => {
  switch (type) {
    case 'top':
      return DataDisplayOptions.TOP
    case 'bot':
      return DataDisplayOptions.BOTTOM
    case 'topbot':
      return DataDisplayOptions.TOP_BOTTOM
    default:
      return DataDisplayOptions.TOP
  }
}

type Columns = DeprecatedTopBottomModuleSettings['columnNames']
export const handleConvertingModuleColumns = (columns: Columns) => {
  if (!columns)
    return [
      TopBottomColumnNames.CATEGORY,
      TopBottomColumnNames.KPI,
      TopBottomColumnNames.PERFORMANCE,
    ]
  const newColumns = [] as TopBottomColumnNames[]
  const oldColumns = columns.map((c) => c.toLowerCase())
  if (oldColumns.includes(TopBottomColumnNames.CATEGORY.toLocaleLowerCase()))
    newColumns.push(TopBottomColumnNames.CATEGORY)
  if (oldColumns.includes(TopBottomColumnNames.KPI.toLocaleLowerCase()))
    newColumns.push(TopBottomColumnNames.KPI)
  if (oldColumns.includes(TopBottomColumnNames.PERFORMANCE.toLocaleLowerCase()))
    newColumns.push(TopBottomColumnNames.PERFORMANCE)
  if (oldColumns.includes(TopBottomColumnNames.TREND.toLocaleLowerCase()))
    newColumns.push(TopBottomColumnNames.TREND)

  if (!newColumns.length)
    return [
      TopBottomColumnNames.CATEGORY,
      TopBottomColumnNames.KPI,
      TopBottomColumnNames.PERFORMANCE,
    ]
  else return newColumns
}

TopBottomModuleContainer.displayName = 'TopBottomModuleContainer'
export default TopBottomModuleContainer
