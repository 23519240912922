import React from 'react'
import { Conversation } from '../_shared/ConversationModal/conversationTypes'
import { TextualKpiData } from './openTypes'

type OpenModuleContextType = {
  showInspectorConversation: boolean
  showCategoryTool: boolean
  conversationsList: Conversation[]
  updateConversationsList: (conversations: Conversation[]) => void
  updateMessageCount: (conversations: Conversation[]) => void
  onChangeCategoriesCompleted: (affectedAnswer: TextualKpiData) => void
  isReportMode: boolean
  isScreenMode: boolean
}

const OpenModuleContextDefault = {
  showInspectorConversation: false,
  showCategoryTool: false,
  conversationsList: [],
  updateConversationsList: () => ({}),
  updateMessageCount: () => ({}),
  onChangeCategoriesCompleted: () => ({}),
  isReportMode: false,
  isScreenMode: false,
}

export const OpenModuleContext =
  React.createContext<OpenModuleContextType>(OpenModuleContextDefault)

  
