import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import { ChartTypes } from './pie/pieReducer'
import { usePieTabularSettings } from './PietabularModuleContext'
import SettingsMissingError from './settings/SettingsMissingError'
import Tabular from './tabular/Tabular'
import Trends from './trend/Trends'
import usePietabularSettingsValidator from './usePietabularSettingsValidator'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingPie, errorLoadingTabular, errorLoadingTrends } from './PietabularNotifications'
import { GroupBy, GroupedDataWithCounts, OpenAnswersCountsQuery } from './pietabularTypes'
import useOpenAnswerCounts from '../../../../stores/useOpenAnswerCounts'
import { toast } from 'react-toastify'
import usePietabularFunctions from './usePietabularFunctions'
import { isEqual } from 'lodash'
import useOpenCategories from '../../../../stores/useOpenCategories'
import PiePropsContainer from './pie/Pie'
import usePietabularExtensionQuery from './usePietabularExtensionQuery'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { isFetchingData, isWaitingForFetching } from '../Group/contexts/renderListReducer'
import { QueueStatus } from '../Group/groupModuleTypes'

import css from './PietabularContainer.module.scss'

type PietabularContainerProps = {
  doneStatusTimeoutRef: React.MutableRefObject<NodeJS.Timeout | null>
  moduleStatus: QueueStatus | undefined
}
const PietabularContainer = memo(
  ({ doneStatusTimeoutRef, moduleStatus }: PietabularContainerProps) => {
    const {
      handleCountingTopicsCounts,
      handleCountingGroupedCountsByMeta,
      handleFilteringPossibleValuesToCount,
      handleCountingGroupedCountsByDate,
    } = usePietabularFunctions()

    const timeoutRef = useRef<NodeJS.Timeout>()
    const trendCntrRef = useRef<HTMLDivElement>()
    const moduleCntrRef = useRef<HTMLDivElement>()
    const [newFilteredTopics, setNewFilteredTopics] = useState<(string | null)[] | null>(null)
    const [pieData, setPieData] = useState<[string, number][] | null>(null)
    const [tabularData, setTabularData] = useState<GroupedDataWithCounts | null>(null)
    const [trendsData, setTrendsData] = useState<GroupedDataWithCounts | null>(null)
    const [valuesPossibleToCount, setValuesPossibleToCount] = useState<string[] | null>(null)
    const [ready, setReady] = useState('')
    const {
      answerCategories: allCategories,
      loading: isLoadingAllCategories,
      error: errorAllCategories,
    } = useOpenCategories()
    useEffect(() => toastError(errorAllCategories), [errorAllCategories])

    const tableHeadingsWithoutN = useMemo(
      () => valuesPossibleToCount || [],
      [valuesPossibleToCount],
    )
    const [
      {
        settings: {
          defaultCountedKey,
          defaultMetaGroup,
          defaultSortColumn,
          defaultSortIsAscending,
          kpis,
          breakpoints,
          denominator,
          modulewidth,
          numerator,
          showAreaShareCharts,
          showBreakpoints,
          showRegressionLines,
          showRollingAverage,
          trendsSubTitle,
          trendsTitle,
          metaKeys,
          numberOfTopTopicsToShow,
          openCategories,
          showTooltip,
          showPie,
          showTabular,
          showTrends,
          showTotalCount,
          tabularDataFormat,
          tolerance,
          selectedPieTopic,
          defaultDateGroup,
          showNotSelectedTopicsAsOther,
        },
        isReportMode,
        isScreenMode,
        query,
        id: moduleId,
        saveSettingsProperty,
      },
    ] = usePieTabularSettings()
    const { updateModuleToIdle } = useRenderActions()

    const { isValidSettings } = usePietabularSettingsValidator({
      defaultCountedKey,
      defaultMetaGroup,
      defaultSortColumn,
      defaultSortIsAscending,
      numberOfTopTopicsToShow,
      kpis,
      metaKeys,
      openCategories,
      showTooltip,
      showTotalCount,
      tabularDataFormat,
      tolerance,
      selectedPieTopic,
    })

    const { createPieExtensionQuery, createTabularExtensionQuery, createTrendsExtensionQuery } =
      usePietabularExtensionQuery(allCategories?.topic, query, !!showNotSelectedTopicsAsOther, kpis)

    const pieQuery = useMemo(
      () => createPieExtensionQuery(openCategories),
      [allCategories, query, !!showNotSelectedTopicsAsOther, kpis, openCategories],
    )
    const {
      counts: pieCounts,
      error: pieError,
      isLoading: isLoadingPieCounts,
      isRefetching: isRefetchingPieCounts,
    } = useOpenAnswerCounts(pieQuery)
    useEffect(() => toastError(pieError), [pieError])

    const tabularQuery = useMemo(
      () =>
        createTabularExtensionQuery(
          selectedPieTopic,
          newFilteredTopics,
          !!showTabular,
          defaultMetaGroup,
          defaultCountedKey,
        ),
      [
        allCategories,
        query,
        !!showNotSelectedTopicsAsOther,
        kpis,
        selectedPieTopic,
        newFilteredTopics,
        !!showTabular,
        defaultMetaGroup,
        defaultCountedKey,
      ],
    )
    const {
      counts: tabularCounts,
      error: tabularError,
      isLoading: isLoadingTabularCounts,
      isRefetching: isRefetchingTabularCounts,
    } = useOpenAnswerCounts(tabularQuery)
    useEffect(() => toastError(tabularError), [tabularError])

    const tabularNQuery = useMemo(() => {
      if (!tabularQuery) {
        return null
      } else {
        const newTabularNQuery = {
          ...tabularQuery,
          group_by: [GroupBy.META_KEY],
        } as OpenAnswersCountsQuery
        return newTabularNQuery
      }
    }, [tabularQuery])
    const {
      counts: tabularNCounts,
      error: tabularNError,
      isLoading: isLoadingTabularNCounts,
      isRefetching: isRefetchingTabularNCounts,
    } = useOpenAnswerCounts(tabularNQuery)
    useEffect(() => toastError(tabularNError), [tabularNError])

    const trendsQuery = useMemo(
      () =>
        createTrendsExtensionQuery(
          selectedPieTopic,
          newFilteredTopics,
          !!showTrends,
          defaultCountedKey,
          defaultDateGroup,
        ),
      [
        allCategories,
        query,
        !!showNotSelectedTopicsAsOther,
        kpis,
        selectedPieTopic,
        newFilteredTopics,
        !!showTrends,
        defaultCountedKey,
        defaultDateGroup,
      ],
    )
    const {
      counts: trendsCounts,
      error: trendsError,
      isRefetching: isRefetchingTrendsCounts,
      isLoading: isLoadingTrendsCounts,
    } = useOpenAnswerCounts(trendsQuery)
    useEffect(() => toastError(trendsError), [trendsError])

    const trendsNQuery = useMemo(() => {
      if (!trendsQuery) {
        return null
      } else {
        const newTabularNQuery = {
          ...trendsQuery,
          group_by: [GroupBy.DATE],
        } as OpenAnswersCountsQuery
        return newTabularNQuery
      }
    }, [trendsQuery])
    const {
      counts: trendsNCounts,
      error: trendsNError,
      isLoading: isLoadingTrendsNCounts,
      isRefetching: isRefetchingTrendsNCounts,
    } = useOpenAnswerCounts(trendsNQuery)
    useEffect(() => toastError(trendsNError), [trendsNError])

    useEffect(() => {
      if (pieCounts && openCategories && allCategories?.topic) {
        const { results: data } = pieCounts
        const topicsWithCount = handleCountingTopicsCounts(
          data,
          openCategories,
          !!showNotSelectedTopicsAsOther,
          numberOfTopTopicsToShow || 50,
          allCategories?.topic,
        )
        if (!topicsWithCount) return

        const newTopics = topicsWithCount.map((c) => c[0])
        setNewFilteredTopics(newTopics.map((t) => (t === 'uncategorized' ? null : t)))
        setPieData(topicsWithCount)
      }
    }, [pieCounts, numberOfTopTopicsToShow, openCategories, allCategories])

    useEffect(() => {
      if (!showTabular) return setTabularData(null)
      if (isLoadingTabularCounts || isLoadingTabularNCounts) return
      if (tabularCounts && tabularNCounts && defaultCountedKey && openCategories && pieData) {
        const { results: data } = tabularCounts
        const { results: nData } = tabularNCounts
        const possibleCountValues = handleFilteringPossibleValuesToCount(
          data,
          defaultCountedKey,
          pieData,
        )
        const metaValuesWithCounts = handleCountingGroupedCountsByMeta(
          data,
          nData,
          defaultCountedKey,
          possibleCountValues,
        )
        if (!isEqual(possibleCountValues, valuesPossibleToCount)) {
          setValuesPossibleToCount(possibleCountValues)
        }
        setTabularData(metaValuesWithCounts)
      }
    }, [
      tabularCounts,
      tabularNCounts,
      defaultCountedKey,
      openCategories,
      pieData,
      isLoadingTabularCounts,
      isLoadingTabularNCounts,
    ])

    useEffect(() => {
      if (!showTrends) return setTrendsData(null)
      if (isLoadingTrendsCounts || isLoadingTrendsNCounts) return
      if (trendsCounts && trendsNCounts && defaultCountedKey && openCategories && pieData) {
        const { results: data } = trendsCounts
        const { results: nData } = trendsNCounts
        const possibleCountValues = handleFilteringPossibleValuesToCount(
          data,
          defaultCountedKey,
          pieData,
        )
        const dateValuesWithCounts = handleCountingGroupedCountsByDate(
          data,
          nData,
          defaultCountedKey,
          possibleCountValues,
        )
        if (!showTabular && !isEqual(possibleCountValues, valuesPossibleToCount)) {
          setValuesPossibleToCount(possibleCountValues)
        }
        setTrendsData(dateValuesWithCounts)
      }
    }, [
      trendsCounts,
      trendsNCounts,
      defaultCountedKey,
      openCategories,
      pieData,
      isLoadingTrendsCounts,
      isLoadingTrendsNCounts,
      showTrends,
    ])

    const isLoading =
      isLoadingPieCounts ||
      isLoadingTabularCounts ||
      isLoadingTrendsCounts ||
      isLoadingAllCategories ||
      isLoadingTabularNCounts ||
      isLoadingTrendsNCounts ||
      isWaitingForFetching(moduleStatus)

    useEffect(() => {
      if (!isLoading && isFetchingData(moduleStatus)) {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
          setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
        }, 4000) // To make sure everything has rendered
      }
      return () => timeoutRef.current && clearTimeout(timeoutRef.current)
    }, [isLoading, moduleStatus])

    const DONE_STATUS_TIMEOUT = 100
    useEffect(() => {
      if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
      const isDoneFetching =
        !isLoadingPieCounts ||
        !isLoadingTabularCounts ||
        !isLoadingTrendsCounts ||
        !isLoadingAllCategories ||
        !isLoadingTabularNCounts ||
        !isLoadingTrendsNCounts ||
        !isRefetchingPieCounts ||
        !isRefetchingTabularCounts ||
        !isRefetchingTrendsCounts
      if (isFetchingData(moduleStatus) && isDoneFetching) {
        doneStatusTimeoutRef.current = setTimeout(() => {
          if (moduleId) updateModuleToIdle(moduleId)
        }, DONE_STATUS_TIMEOUT)
      }
      return () => {
        if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
      }
    }, [
      moduleStatus,
      moduleId,
      isLoadingPieCounts,
      isLoadingTabularCounts,
      isLoadingTrendsCounts,
      isLoadingAllCategories,
      isLoadingTabularNCounts,
      isLoadingTrendsNCounts,
      isRefetchingPieCounts,
      isRefetchingTabularCounts,
      isRefetchingTrendsCounts,
    ])

    const tabularFallBack = useMemo(
      () => <div style={{ color: 'red' }}>{errorLoadingTabular}</div>,
      [],
    )
    const trendsFallBack = useMemo(
      () => <div style={{ color: 'red' }}>{errorLoadingTrends}</div>,
      [],
    )

    const toastError = (e: string) => {
      if (e) toast.error(e)
    }

    const isRefetchingTrends = useMemo(() => {
      return (
        !!trendsCounts &&
        (isWaitingForFetching(moduleStatus) ||
          isRefetchingTrendsCounts ||
          isRefetchingTrendsNCounts)
      )
    }, [trendsCounts, moduleStatus, isRefetchingTrendsCounts, isRefetchingTrendsNCounts])

    const isRefetchingTabular = useMemo(() => {
      return (
        !!trendsCounts &&
        (isWaitingForFetching(moduleStatus) ||
          isRefetchingTabularCounts ||
          isRefetchingTabularNCounts)
      )
    }, [trendsCounts, moduleStatus, isRefetchingTabularCounts, isRefetchingTabularNCounts])

    const isRefetchingPie = useMemo(() => {
      return !!pieCounts && (isWaitingForFetching(moduleStatus) || isRefetchingPieCounts)
    }, [pieCounts, moduleStatus, isRefetchingPieCounts])

    return (
      <div
        ref={(ref) => {
          if (ref) moduleCntrRef.current = ref
        }}
        className={`${css.cntr} ${ready} pietabular-module-cntr`}
        style={{
          ...(isReportMode || isScreenMode
            ? {
                borderBottom: 'none',
                maxHeight: 'none',
                height: 'auto',
                width: '100%',
              }
            : {}),
        }}
      >
        {isValidSettings ? (
          <>
            {showPie && (
              <div
                className={`${
                  isReportMode || isScreenMode ? css.freq_cntr_reportMode : css.freq_cntr
                }`}
                style={{
                  ...((!showTabular && showTrends) || (showTabular && !showTrends)
                    ? { width: '50%' }
                    : {}),
                  ...(!showTabular && !showTrends ? { width: '100%' } : {}),
                  height:
                    !isReportMode &&
                    !isScreenMode &&
                    moduleCntrRef.current?.offsetHeight &&
                    showTabular &&
                    showTrends &&
                    showPie
                      ? moduleCntrRef.current.offsetHeight / 2
                      : 'inherit',
                }}
              >
                <ErrorBoundary
                  message={errorLoadingPie}
                  fallback={<div style={{ color: 'red' }}>{errorLoadingPie}</div>}
                >
                  <PiePropsContainer
                    chartType={ChartTypes.PIE}
                    limitedTopicPlusCount={pieData}
                    newFilteredTopics={newFilteredTopics}
                    isRefetchingPie={isRefetchingPie}
                  />
                </ErrorBoundary>
              </div>
            )}

            {(isReportMode || isScreenMode) && showTrends && (
              <div
                ref={(ref) => {
                  if (ref) trendCntrRef.current = ref
                }}
                className={css.trend_cntr_reportMode}
              >
                <ErrorBoundary message={errorLoadingTrends} fallback={trendsFallBack}>
                  <Trends
                    groupedAndCountedData={trendsData}
                    areas={valuesPossibleToCount}
                    trendsCntrRef={trendCntrRef.current}
                    filteredTopics={trendsQuery?.category_grouping?.topic}
                    breakpoints={breakpoints}
                    defaultCountedKey={defaultCountedKey}
                    defaultDateGroup={defaultDateGroup}
                    defaultMetaGroup={defaultMetaGroup}
                    isReportMode={isReportMode}
                    isScreenMode={isScreenMode}
                    modulewidth={modulewidth}
                    numberOfTopTopicsToShow={numberOfTopTopicsToShow}
                    query={query}
                    saveSettingsProperty={saveSettingsProperty}
                    showAreaShareCharts={showAreaShareCharts !== false}
                    showBreakpoints={!!showBreakpoints}
                    showRollingAverage={showRollingAverage !== false}
                    showPie={!!showPie}
                    showRegressionLines={showRegressionLines !== false}
                    showTabular={!!showTabular}
                    showTrends={!!showTrends}
                    denominator={denominator}
                    numerator={numerator}
                    isRefetching={isRefetchingTrends}
                    trendsTitle={trendsTitle}
                    trendsSubTitle={trendsSubTitle}
                  />
                </ErrorBoundary>
              </div>
            )}

            {showTabular && (
              <div
                className={`${
                  isReportMode || isScreenMode ? css.tbl_cntr_reportMode : css.tbl_cntr
                }`}
                style={{
                  ...((!showPie && showTrends) || (showPie && !showTrends) ? { width: '50%' } : {}),
                  ...(!showPie && !showTrends ? { width: '100%' } : {}),

                  height:
                    !isReportMode &&
                    !isScreenMode &&
                    moduleCntrRef.current?.offsetHeight &&
                    showTabular &&
                    showTrends &&
                    showPie
                      ? moduleCntrRef.current.offsetHeight / 2 - 5
                      : !isReportMode && !isScreenMode && moduleCntrRef.current?.offsetHeight
                      ? moduleCntrRef.current.offsetHeight - 5
                      : '',
                  maxHeight:
                    !isReportMode &&
                    !isScreenMode &&
                    moduleCntrRef.current?.offsetHeight &&
                    showTabular &&
                    showTrends &&
                    showPie
                      ? moduleCntrRef.current.offsetHeight / 2 - 5
                      : !isReportMode && !isScreenMode && moduleCntrRef.current?.offsetHeight
                      ? moduleCntrRef.current.offsetHeight - 5
                      : '',
                }}
              >
                <ErrorBoundary message={errorLoadingTabular} fallback={tabularFallBack}>
                  <Tabular
                    moduleCntrRef={moduleCntrRef}
                    tableHeadingsWithoutN={tableHeadingsWithoutN}
                    groupedAndCountedData={tabularData}
                    isLoading={isLoadingTabularCounts}
                    filteredTopics={tabularQuery?.category_grouping?.topic}
                    defaultSortColumn={defaultSortColumn}
                    defaultSortIsAscending={defaultSortIsAscending}
                    isReportMode={isReportMode}
                    isScreenMode={isScreenMode}
                    query={query}
                    showTotalCount={!!showTotalCount}
                    tabularDataFormat={tabularDataFormat}
                    defaultCountedKey={defaultCountedKey}
                    defaultMetaGroup={defaultMetaGroup}
                    metaKeys={metaKeys}
                    saveSettingsProperty={saveSettingsProperty}
                    isRefetching={isRefetchingTabular}
                  />
                </ErrorBoundary>
              </div>
            )}

            {!isReportMode && !isScreenMode && showTrends && (
              <div
                ref={(ref) => {
                  if (ref) trendCntrRef.current = ref
                }}
                className={css.trend_cntr}
                style={{
                  ...((!showPie && showTabular) || (!showTabular && showPie)
                    ? { width: '50%', minWidth: '50%' }
                    : {}),
                  ...(!showPie && !showTabular ? { width: '100%', minWidth: '100%' } : {}),
                  height:
                    !isReportMode &&
                    !isScreenMode &&
                    moduleCntrRef.current?.offsetHeight &&
                    showTabular &&
                    showTrends &&
                    showPie
                      ? moduleCntrRef.current.offsetHeight / 2 - 5
                      : 'inherit',
                }}
              >
                <ErrorBoundary message={errorLoadingTrends} fallback={trendsFallBack}>
                  {trendCntrRef.current ? (
                    <Trends
                      groupedAndCountedData={trendsData}
                      areas={valuesPossibleToCount}
                      trendsCntrRef={trendCntrRef.current}
                      isRefetching={isRefetchingTrends}
                      filteredTopics={trendsQuery?.category_grouping?.topic}
                      breakpoints={breakpoints}
                      defaultCountedKey={defaultCountedKey}
                      defaultDateGroup={defaultDateGroup}
                      defaultMetaGroup={defaultMetaGroup}
                      isReportMode={isReportMode}
                      isScreenMode={isScreenMode}
                      modulewidth={modulewidth}
                      numberOfTopTopicsToShow={numberOfTopTopicsToShow}
                      query={query}
                      saveSettingsProperty={saveSettingsProperty}
                      showAreaShareCharts={showAreaShareCharts !== false}
                      showBreakpoints={!!showBreakpoints}
                      showRollingAverage={showRollingAverage !== false}
                      showPie={!!showPie}
                      showRegressionLines={showRegressionLines !== false}
                      showTabular={!!showTabular}
                      showTrends={!!showTrends}
                      denominator={denominator}
                      numerator={numerator}
                      trendsTitle={trendsTitle}
                      trendsSubTitle={trendsSubTitle}
                    />
                  ) : (
                    <div></div>
                  )}
                </ErrorBoundary>
              </div>
            )}
          </>
        ) : (
          <SettingsMissingError />
        )}
      </div>
    )
  },
)

PietabularContainer.displayName = 'PietabularContainer'

export default PietabularContainer
