import { cloneDeep } from 'lodash'
import { darkerChartColors } from '../../../../../styles/variableExport'
import { Data } from '../../../../../types'
import { createRgbaFromColor } from '../../../../react-services/colorService'
import { LabelPosition, LineChartDataFormats, TargetColor } from './lineChartTypes'
import * as Highcharts from 'highcharts8'

// To force redraw
let idIncrement = 0

export type ChartBreakpoint = {
  x: number
  title: string
  description: string
}

interface SetDataAction {
  type: ActionTypes.SET_CHART_DATA
  data: {
    series: Highcharts.SeriesLineOptions[]
    categories: string[]
  }
}

interface SetLimitsAction {
  type: ActionTypes.SET_LIMITS
  data: {
    limitValues: {
      min?: string | number
      max?: string | number
    }
    isSoftMin: boolean
    isSoftMax: boolean
  }
}

interface SetTotalsAction {
  type: ActionTypes.SET_TOTALS
  data: {
    series: Highcharts.SeriesColumnOptions[]
    showTotals: boolean
    dataFormat: string
  }
}

interface SetTargetAction {
  type: ActionTypes.SET_TARGET
  data: {
    kpiTargetFrom?: string
    kpiTargetTo?: string
    kpiTargetLabel?: string
    kpiTargetLabelPosition?: LabelPosition
    kpiTargetAreaColor?: TargetColor
    kpiTargetCustomColor?: string
    kpiTargetDataFormat?: Exclude<LineChartDataFormats, LineChartDataFormats.ROLLING_AVG>
    dataFormat?: LineChartDataFormats
  }
}
interface SetBreakpointAction {
  type: ActionTypes.SET_BREAKPOINT
  data: {
    breakpoints: ChartBreakpoint[]
    categories: string[]
  }
}

interface SetToolTipAction {
  type: ActionTypes.SET_TOOLTIP
  data: {
    dataFormat: Data.Format
  }
}

export type SetChartHeightAction = {
  type: ActionTypes.SET_HEIGHT
  data: {
    chartHeight: number
  }
}

export type SetLanguageAction = {
  type: ActionTypes.SET_LANGUAGE
}

export enum ActionTypes {
  SET_CHART_DATA = 'SET_CHART_DATA',
  SET_LIMITS = 'SET_LIMITS',
  SET_TARGET = 'SET_TARGET',
  SET_BREAKPOINT = 'SET_BREAKPOINT',
  SET_TOTALS = 'SET_TOTALS',
  SET_TOOLTIP = 'SET_TOOLTIP',
  SET_HEIGHT = 'SET_HEIGHT',
  SET_LANGUAGE = 'SET_LANGUAGE',
}

type ReducerAction =
  | SetDataAction
  | SetLimitsAction
  | SetTargetAction
  | SetBreakpointAction
  | SetTotalsAction
  | SetToolTipAction
  | SetChartHeightAction
  | SetLanguageAction

export const lineChartReducer = (
  state: Highcharts.Options,
  action: ReducerAction,
): Highcharts.Options => {
  switch (action.type) {
    case ActionTypes.SET_CHART_DATA: {
      const newState = cloneDeep(state)
      newState.series = action.data.series
      let newXAxis = newState.xAxis
      if (!Array.isArray(newXAxis)) {
        newXAxis = { ...(newXAxis || {}), categories: action.data.categories }
      }
      newState.xAxis = newXAxis
      return newState
    }

    case ActionTypes.SET_LIMITS: {
      const newState = cloneDeep(state)
      const { isSoftMax, isSoftMin } = action.data
      const newMin =
        action.data.limitValues?.min !== undefined && action.data.limitValues?.min !== ''
          ? +action.data.limitValues?.min
          : undefined
      const newMax =
        action.data.limitValues?.max !== undefined && action.data.limitValues?.max !== ''
          ? +action.data.limitValues?.max
          : undefined
      if (newState.yAxis && !Array.isArray(newState.yAxis)) {
        newState.yAxis = {
          ...(newState.yAxis || {}),
          ...(isSoftMin
            ? { softMin: newMin, min: null, floor: undefined }
            : { min: newMin, floor: newMin }),
          ...(isSoftMax
            ? { softMax: newMax, max: null, ceiling: undefined }
            : {
                max: newMax ? newMax + 0.2 : undefined,
                ceiling: newMax ? newMax + 0.2 : undefined,
              }),
        }
      }
      if (newState.yAxis && Array.isArray(newState.yAxis) && newState.yAxis[0]) {
        newState.yAxis[0] = {
          ...(newState.yAxis[0] || {}),
          ...(isSoftMin
            ? { softMin: newMin, min: null, floor: undefined }
            : { min: newMin, floor: newMin }),
          ...(isSoftMax
            ? { softMax: newMax, max: null, ceiling: undefined }
            : {
                max: newMax ? newMax + 0.2 : undefined,
                ceiling: newMax ? newMax + 0.2 : undefined,
              }),
        }
      }
      return newState
    }

    case ActionTypes.SET_TARGET: {
      const newState = cloneDeep(state)
      const {
        kpiTargetAreaColor,
        kpiTargetCustomColor,
        kpiTargetFrom,
        kpiTargetLabel,
        kpiTargetLabelPosition,
        kpiTargetTo,
        kpiTargetDataFormat,
        dataFormat,
      } = action.data
      if (kpiTargetDataFormat && kpiTargetDataFormat === dataFormat) {
        const hasFrom =
          kpiTargetFrom !== undefined && kpiTargetFrom !== null && kpiTargetFrom !== ''
        const hasTo = kpiTargetTo !== undefined && kpiTargetTo !== null && kpiTargetTo !== ''
        if ((hasFrom && !hasTo) || (!hasFrom && hasTo)) {
          const newPlotLine = createTargetLine(
            hasFrom ? kpiTargetFrom : hasTo ? kpiTargetTo : null,
            kpiTargetLabel || '',
            kpiTargetLabelPosition || LabelPosition.BOTTOM_RIGHT,
          )
          if (newState.yAxis && !Array.isArray(newState.yAxis)) {
            newState.yAxis = { ...newState.yAxis, plotLines: [newPlotLine], plotBands: [] }
          }
          if (newState.yAxis && Array.isArray(newState.yAxis) && newState.yAxis[0]) {
            newState.yAxis[0] = { ...newState.yAxis[0], plotLines: [newPlotLine], plotBands: [] }
          }
        }
        if (hasFrom && hasTo) {
          const newPlotBand = createTargetArea(
            kpiTargetFrom,
            kpiTargetTo,
            kpiTargetLabel || '',
            kpiTargetLabelPosition || LabelPosition.BOTTOM_RIGHT,
            kpiTargetAreaColor || TargetColor.GREY,
            kpiTargetCustomColor || '',
          )
          const selectedColor = getAreaColor(
            kpiTargetAreaColor || TargetColor.GREY,
            kpiTargetCustomColor || '',
          )
          const borderColor = createRgbaFromColor(selectedColor, 0.5)
          if (newState.yAxis && !Array.isArray(newState.yAxis)) {
            newState.yAxis = {
              ...newState.yAxis,
              plotLines: [createAreaBorder(kpiTargetFrom, borderColor)],
              plotBands: [newPlotBand],
            }
          }
          if (newState.yAxis && Array.isArray(newState.yAxis) && newState.yAxis[0]) {
            newState.yAxis[0] = {
              ...newState.yAxis[0],
              plotLines: [createAreaBorder(kpiTargetFrom, borderColor)],
              plotBands: [newPlotBand],
            }
          }
        }
      } else {
        if (newState.yAxis && !Array.isArray(newState.yAxis)) {
          newState.yAxis = {
            ...newState.yAxis,
            plotLines: [],
            plotBands: [],
          }
        }
        if (newState.yAxis && Array.isArray(newState.yAxis) && newState.yAxis[0]) {
          newState.yAxis[0] = {
            ...newState.yAxis[0],
            plotLines: [],
            plotBands: [],
          }
        }
      }
      return newState
    }

    case ActionTypes.SET_BREAKPOINT: {
      const newState = cloneDeep(state)
      if (action.data.breakpoints.length && action.data.categories.length) {
        const xAxisPlotlineBreakpoints = createXAxisPlotlinesForBreakpoints(
          action.data.breakpoints,
          action.data.categories,
        )
        if (newState.xAxis && !Array.isArray(newState.xAxis))
          newState.xAxis.plotBands = xAxisPlotlineBreakpoints
        if (newState.xAxis && !Array.isArray(newState.xAxis) && !action.data.breakpoints.length)
          delete newState.xAxis.plotBands
      }
      return newState
    }

    case ActionTypes.SET_TOTALS: {
      const newState = cloneDeep(state)
      if (action.data.showTotals) {
        newState.series = [...(newState.series || []), ...action.data.series]
        if (newState.plotOptions?.series?.pointPlacement)
          newState.plotOptions.series.pointPlacement = undefined
        if (newState.plotOptions?.series?.pointStart)
          newState.plotOptions.series.pointStart = undefined
        if (newState.chart) newState.chart.marginRight = 60
        if (Array.isArray(newState.yAxis)) {
          const primaryYAxisTitleText =
            action.data.dataFormat === LineChartDataFormats.ROLLING_AVG
              ? 'avg'
              : action.data.dataFormat || ''
          const newFirstYAxisTitle: Highcharts.YAxisOptions['title'] = {
            align: 'high',
            text: primaryYAxisTitleText,
            offset: 13,
            reserveSpace: true,
            y: -20,
            rotation: 0,
            textAlign: 'right',
            style: {
              fontSize: '12px',
              color: darkerChartColors.GREY,
              fontWeight: 'bold',
            },
          }
          const newSecondYAxisTitle: Highcharts.YAxisOptions['title'] = {
            align: 'high',
            text: 'n',
            offset: 25,
            reserveSpace: true,
            y: -20,
            rotation: 0,
            textAlign: 'right',
            style: {
              fontSize: '12px',
              color: darkerChartColors.GREY,
              fontWeight: 'bold',
            },
          }
          newState.yAxis[0] = { ...(newState.yAxis[0] || {}), title: newFirstYAxisTitle }
          newState.yAxis[1] = { ...(newState.yAxis[1] || {}), title: newSecondYAxisTitle }
        }
      } else {
        if (newState.plotOptions?.series) {
          newState.plotOptions.series.pointPlacement = 'on'
          newState.plotOptions.series.pointStart = 0
        }
        if (newState.chart) newState.chart.marginRight = 35
        if (Array.isArray(newState.yAxis)) {
          newState.yAxis[0] = { ...(newState.yAxis[0] || {}), title: undefined }
          newState.yAxis[1] = { ...(newState.yAxis[1] || {}), title: undefined }
        }
      }
      return newState
    }

    case ActionTypes.SET_TOOLTIP: {
      const newState = cloneDeep(state)
      newState.tooltip = getTooltipFormat(action.data.dataFormat)
      return newState
    }

    case ActionTypes.SET_HEIGHT: {
      const newState = cloneDeep(state)
      if (newState.chart) {
        const newHeight = action.data.chartHeight
        newState.chart.height = newHeight
      }
      return newState
    }

    case ActionTypes.SET_LANGUAGE: {
      const newState = cloneDeep(state)
      const sunburstSeries = newState.series ? newState.series[0] : null
      if (!sunburstSeries || sunburstSeries.type !== 'sunburst') return newState
      sunburstSeries.id = `line-${idIncrement++}`
      if (newState.series) newState.series[0] = sunburstSeries
      return newState
    }

    default:
      return state
  }
}

const createTargetLine = (
  target: string | null,
  label: string,
  position: LabelPosition,
): Highcharts.YAxisPlotLinesOptions => {
  const newLine = {} as Highcharts.YAxisPlotLinesOptions
  if (target === null) return newLine
  newLine.value = +target
  newLine.dashStyle = 'ShortDot'
  newLine.width = 3
  newLine.color = darkerChartColors.GREY
  newLine.zIndex = 4
  if (label) {
    newLine.label = {}
    newLine.label.text = label
    newLine.label.style = {}
    newLine.label.style.color = darkerChartColors.GREY
    newLine.label.style.fontSize = '12px'
    newLine.label.style.fontWeight = 'bold'
    const newPosition = getLabelPosition(position)
    newLine.label.align = newPosition?.align
    newLine.label.verticalAlign = newPosition?.verticalAlign
    newLine.label.x = newPosition?.x
    newLine.label.y = newPosition?.y
  }
  return newLine
}

const createAreaBorder = (
  target: string | null,
  color: string,
): Highcharts.YAxisPlotLinesOptions => {
  const newLine = {} as Highcharts.YAxisPlotLinesOptions
  if (target === null) return newLine
  newLine.value = +target
  newLine.color = color
  newLine.width = 2
  newLine.zIndex = 2
  return newLine
}

const createTargetArea = (
  from: string,
  to: string,
  label: string,
  labelPosition: LabelPosition,
  areaColor: TargetColor,
  customColor: string,
): Highcharts.YAxisPlotBandsOptions => {
  const newArea = {} as Highcharts.YAxisPlotBandsOptions
  newArea.from = +from
  newArea.to = +to
  newArea.zIndex = 1
  if (label) {
    newArea.label = {}
    newArea.label.text = label
    newArea.label.style = {}

    newArea.label.style.color = '#585858'
    newArea.label.style.fontSize = '12px'
    newArea.label.style.fontWeight = 'bold'
    const newPosition = getLabelPosition(labelPosition)
    newArea.label.align = newPosition?.align
    newArea.label.verticalAlign = newPosition?.verticalAlign
    newArea.label.x = newPosition?.x
    newArea.label.y = newPosition?.y
  }
  const selectedColor = getAreaColor(areaColor, customColor)
  // gradient config sample
  // const isInverted = +from <= +to
  // newArea.color = {
  //   linearGradient: { x1: 0, x2: 0, y1: isInverted ? 0 : 1, y2: isInverted ? 1 : 0 },
  //   stops: [
  //     [0, createRgbaFromColor(selectedColor, 0.05)],
  //     [1, createRgbaFromColor(selectedColor, 0.3)],
  //   ],
  // }
  newArea.color = createRgbaFromColor(selectedColor, 0.6)
  return newArea
}

const getLabelPosition = (
  position: LabelPosition,
): Partial<Highcharts.YAxisPlotLinesOptions['label']> => {
  switch (position) {
    case LabelPosition.BOTTOM_CENTER:
      return { verticalAlign: 'bottom', align: 'center', y: 13 }
    case LabelPosition.BOTTOM_LEFT:
      return { verticalAlign: 'bottom', align: 'left', y: 13 }
    case LabelPosition.BOTTOM_RIGHT:
      return { verticalAlign: 'bottom', align: 'right', y: 13 }
    case LabelPosition.TOP_CENTER:
      return { verticalAlign: 'top', align: 'center', y: -8 }
    case LabelPosition.TOP_LEFT:
      return { verticalAlign: 'top', align: 'left', y: -8 }
    case LabelPosition.TOP_RIGHT:
      return { verticalAlign: 'top', align: 'right', y: -8 }
    default:
      return { verticalAlign: 'bottom', align: 'right', y: 13 }
  }
}

const getAreaColor = (areaColor: TargetColor, customColor: string) => {
  switch (areaColor) {
    case TargetColor.CUSTOM: {
      return customColor
    }
    case TargetColor.GREY: {
      return 'rgb(88, 88, 88)'
    }
    case TargetColor.GREEN: {
      return '#64C2A6'
    }
    case TargetColor.RED: {
      return '#F66D44'
    }
    default:
      return 'rgb(88, 88, 88)'
  }
}

const createXAxisPlotlinesForBreakpoints = (
  breakpoints: ChartBreakpoint[],
  categories: string[],
): Array<Highcharts.XAxisPlotBandsOptions> => {
  const categoriesLength = categories.length
  const delta = 0.0025 + categoriesLength * 0.002
  return breakpoints.map((breakpoint) => ({
    color: 'rgba(88, 88, 88, .5)',
    className: 'line-chart-breakpoint',
    from: breakpoint.x - delta,
    to: breakpoint.x + delta,
    dashStyle: 'Solid',
    label: {
      rotation: 0,
      useHTML: true,
      y: -10,
      x: -4,
      text:
        `<span class="linemodule-highcharts-plotbands-label" data-content="${breakpoint.description}">` +
        `${breakpoint.title}` +
        '</span>',
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#585858',
        backgroundColor: 'rgba(88, 88, 88, .01)',
      },
    },
  }))
}

const TOOLTIP_FONT_SIZE = '1rem'
function getTooltipFormat(dataFormat?: Data.Format) {
  const tooltip: Highcharts.TooltipOptions = {
    useHTML: true,
    style: {
      padding: '0',
      fontSize: TOOLTIP_FONT_SIZE,
    },
    hideDelay: 100,
    formatter: function () {
      return surroundElementWithTooltipContainer(
        buildCustomHighchartsTooltipFormatter(
          this.point as Highcharts.ExtendedPoint,
          this.series,
          dataFormat,
        ),
      )
    },
  }
  return tooltip
}

function surroundElementWithTooltipContainer(element: string): string {
  return `<div class="highcharts-react-tooltip-container">${element}</div>`
}

/**
 *  Check chartoptions.js for the "legacy" version of this formatter.
 *  It contains formats for different types of modules.
 *  Implement those when they are relevant.
 */
function buildCustomHighchartsTooltipFormatter(
  highchartsDataPoint: Highcharts.ExtendedPoint,
  highchartsSeries: Highcharts.Series,
  dataFormatInput?: Data.Format,
): string {
  const dataFormat: Data.Format = dataFormatInput ? dataFormatInput : 'avg'
  const dataValue: string = highchartsDataPoint.data[dataFormat]?.toString()
  const nValue: number =
    dataFormat.toString() === 'rollingAvg'
      ? highchartsDataPoint.data['rollingAvgN']
      : highchartsDataPoint.data['n']
  const kpiName: string = highchartsSeries.name

  return `
    ${getTooltipHeader(highchartsDataPoint.category.toString())}
    ${getTooltipMiddle(highchartsDataPoint.color, kpiName)}
    ${getTooltipFooter(dataFormat, dataValue, nValue)}
`
}

function getTooltipHeader(headerText: string) {
  if (!headerText) return ''

  return `<span style="font-size: 10px">${headerText}</span></br>`
}

function getTooltipMiddle(
  color:
    | Highcharts.ColorString
    | Highcharts.GradientColorObject
    | Highcharts.PatternObject
    | undefined,
  kpiName: string | undefined,
) {
  return kpiName ? `<span style="color: ${color}">\u25CF</span> ${kpiName}<br />` : ''
}

function getTooltipFooter(dataFormat: Data.Format, dataValue: string, n: number) {
  const hasNSuffix: boolean = dataFormat !== 'n'

  return `
  <table>
    <tr>
      <td>${dataFormat.toString() === 'rollingAvg' ? 'rolling avg' : dataFormat}</td>
      <td><b>${dataValue}</b></td>
      ${hasNSuffix ? getNSuffix(n) : ''}
    </tr>
  </table>
`
}

function getNSuffix(n: number) {
  return `
  <td>n</td>
  <td id="tooltip-n"><b>${n}</b></td>
`
}
