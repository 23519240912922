// DO NOT CHANGE THE ORDER
export const darkerChartColors = {
  BLUE: '#2D87BB',
  RED: '#F66D44',
  BLUE_GREEN: '#64C2A6',
  GREY: '#585858', // Not part of the actual palette
  ORANGE: '#FEAE65',
  GREEN: '#AADEA7',

  LIGHT_RED: '#FAA78F',
  LIGHT_ORANGE: '#FECEA3',
  LIGHT_GREEN: '#CCEBCA',
  LIGHT_BLUE_GREEN: '#A2DACA',
  LIGHT_BLUE: '#81B7D6',
}

// DO NOT CHANGE THE ORDER
export const allChartColors = {
  BLUE: '#2D87BB',
  GREY: '#585858', // Not part of the actual palette
  BLUE_GREEN: '#64C2A6',
  RED: '#F66D44',
  ORANGE: '#FEAE65',
  GREEN: '#AADEA7',
  YELLOW: '#E6F69D',

  LIGHT_RED: '#FAA78F',
  LIGHT_ORANGE: '#FECEA3',
  LIGHT_GREEN: '#CCEBCA',
  LIGHT_BLUE_GREEN: '#A2DACA',
  LIGHT_BLUE: '#81B7D6',
  LIGHT_YELLOW: '#F0FAC4',
}

export const oldTrafficLights = {
  GREEN: 'rgb(120, 170, 0)',
  RED: 'rgb(255, 60, 0)',
}

export const timePickerColor = 'rgb(100, 150, 180)'
export const primaryModalColor = '#466480'
export const moduleDefaultHeight = 500
export const reportSVGWidth = '260mm'
