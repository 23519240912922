import { GenericConfig, Kpi, WhereMeta } from '../../../../../types'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type ModuleQuery = {
  start_date: string
  end_date: string
  where_meta: WhereMeta
  kpis: Kpi.Kpi[]
  calculated_kpis: GenericConfig.CalculatedKpis
}

export type NumberTrendModule = {
  type: 'numbertrend'
  selections?: { [key: number]: boolean }
  yAxis?: DataTypes
  imageurl?: string
  options: NumberTrendModuleSettings & ModuleDefaultSettings
  comparisonmode?: boolean
  query?: ModuleQuery
} & ModuleBase

export type NumberTrendModuleSettings = {
  width?: string
  secondaryNumber?: DataTypes | 'comparison' | ''
  hideSecondaryNumber?: boolean
  hideTrendArrow?: boolean
}

export type SharedFilter = {
  filters?: {
    name: string | string[]
    value: string | string[]
  }[]
  groupFilter?: unknown
  grouping?: unknown
}

export enum DataTypes {
  SUM = 'sum',
  AVERAGE = 'avg',
  COUNT = 'n',
}
