import React from 'react'
import { Table } from 'react-materialize'
import Select, { SingleValue } from 'react-select'
import { UserManagementTool, ReactSelectOption } from '../../../../../types'

type UserOption = ReactSelectOption<number | string>

type Props = {
  users: UserManagementTool.User[]
  onInviteUser: (emailInput: string, basedOnId: boolean | null) => void
}
type State = {
  emails: string
  userId: number | null
}

class InviteUserBasedOnUser extends React.Component<Props, State> {
  state = {
    emails: '',
    userId: null,
  }

  handleUserSelect = (user: SingleValue<UserOption>) => {
    const selectedValue = user ? (user as UserOption).value : null
    if (typeof selectedValue === 'string') {
      const selectedValueConvertedToNumber = parseInt(selectedValue, 10)
      this.setState({ userId: selectedValueConvertedToNumber })
    } else {
      this.setState({ userId: selectedValue })
    }
  }

  render() {
    return (
      <div className='invite-user-based-on'>
        <h5 className='title invite-margin'>Invite new user based on user</h5>
        <Table data-cy='inviteUser' className='table z-depth-1'>
          <thead>
            <tr>
              <th className='invite-th-first'>Username</th>
              <th className='invite-th'>Invite based on user</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='invite'>
                <textarea
                  data-cy='email'
                  name='username'
                  placeholder='Email (one per row)'
                  onChange={(event) => {
                    this.setState({ emails: event.target.value })
                  }}
                ></textarea>
              </td>
              <td className='invite'>
                <div data-cy='inviteBasedOnUserSelect'>
                  <Select
                    className='react-select-default-styles'
                    isClearable
                    isSearchable
                    options={this.props.users.map(
                      (user) => ({ label: user.username, value: user.id } as UserOption),
                    )}
                    onChange={this.handleUserSelect}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <button
          data-cy='invite'
          type='submit'
          className='btn'
          onClick={() => {
            this.props.onInviteUser(this.state.emails, this.state.userId)
          }}
        >
          Invite
        </button>
        {/* This solution is temporary */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    )
  }
}

export default InviteUserBasedOnUser
