import React, { useRef, useState } from 'react'
import DoubleModalMain from '../../../../_shared/Modals/DoubleModalMain'
import { useHotkeysData, useOpenCategoryToolData } from './OpenCategoryToolProvider'
import { CategoriesAndValues, OpenCategoryToolModalProps } from './openCategoryToolTypes'
import ConfirmationModal from '../../../../common/ConfirmationModal/ConfirmationModal'
import OpenCategoryToolCategories from './OpenCategoryToolCategories'
import OpenCategoryToolSave from './OpenCategoryToolSave'
import OpenCategoryToolHeader from './OpenCategoryToolHeader'
import OpenCategoryToolInfo from './OpenCategoryToolInfo'
import css from './OpenCategoryToolModal.module.scss'
import { useEvent } from 'react-use'

const OpenCategoryToolModal = ({ onClose }: OpenCategoryToolModalProps) => {
  const [
    {
      isUnsavedCategoryChanges,
      isInfoOpen,
      updatedCategoryValues,
      isCategoryChangingAllowed,
      isFilterOnFocus,
      filteredCategories,
    },
    setOpenCategoryToolData,
  ] = useOpenCategoryToolData()
  const [{ hotkeys, isHotkeySetupMaskOn, isHotkeysEnabled }] = useHotkeysData()
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false)
  const [isFadeInActive, setIsFadeInActive] = useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleCloseConversationModal = () => {
    if (isHotkeySetupMaskOn) return
    if (isUnsavedCategoryChanges) {
      setIsCancelConfirmationModalOpen(true)
    } else {
      // avoid memory leak warning
      clearTimeout(timeoutRef.current as NodeJS.Timeout)
      onClose(false)
    }
  }

  const handleCancelConfirmation = () => {
    setIsCancelConfirmationModalOpen(false)
    onClose(false)
  }

  const handleHotkeyCategoryChange = (event: KeyboardEvent) => {
    if (!isHotkeysEnabled || isFilterOnFocus || isHotkeySetupMaskOn || !isCategoryChangingAllowed)
      return

    const pressedKey = event.key
    const hotkeyExists = hotkeys.find((category) => category.hotkey === pressedKey)

    if (!hotkeyExists) return

    const allCategories: string[] = []
    Object.entries(filteredCategories).forEach((categoryArr) => {
      allCategories.push(...categoryArr[1])
    })
    if (!allCategories.some((category) => hotkeyExists.categoryValue === category)) return

    if (updatedCategoryValues && hotkeyExists) {
      const newCategoryValue = hotkeyExists.categoryValue
      const specificCategoryArr = updatedCategoryValues[hotkeyExists.category]

      if (!specificCategoryArr.includes(newCategoryValue)) {
        const newCheckedCategories: CategoriesAndValues = {
          ...updatedCategoryValues,
          [hotkeyExists.category]: [...specificCategoryArr, newCategoryValue].sort(),
        }
        setOpenCategoryToolData((prev) => ({
          ...prev,
          updatedCategoryValues: newCheckedCategories,
        }))
      } else {
        const newRemovedCategories: CategoriesAndValues = {
          ...updatedCategoryValues,
          [hotkeyExists.category]: specificCategoryArr
            .filter((category) => category !== newCategoryValue)
            .sort(),
        }
        setOpenCategoryToolData((prev) => ({
          ...prev,
          updatedCategoryValues: newRemovedCategories,
        }))
      }
    }
  }

  useEvent('keydown', handleHotkeyCategoryChange)

  return (
    <>
      {isCancelConfirmationModalOpen && (
        <ConfirmationModal
          handleConfirmation={handleCancelConfirmation}
          handleCancel={() => setIsCancelConfirmationModalOpen(false)}
          title='Unsaved changes'
          info='Please confirm to exit without saving. To confirm, press enter or click confirm button. To cancel, press esc or click cancel.'
          enableEnterConfirmation={true}
        />
      )}
      <div className={css.category_tool_cntr}>
        <DoubleModalMain onClose={handleCloseConversationModal} double={false} showMask={true}>
          <div className={`${isHotkeySetupMaskOn ? css.category_mask : ''}`}></div>
          <div
            data-testid='openCategoryToolModal'
            className={`${css.category_tool} ${isFadeInActive ? css.fade_in : ''}`}
            onAnimationEnd={() => setIsFadeInActive(false)}
          >
            <div
              className={css.category_tool_flex}
              style={{ display: isInfoOpen ? 'flex' : 'block' }}
            >
              <OpenCategoryToolInfo />
              <div className={css.category_tool_flex_right}>
                <OpenCategoryToolHeader isInfoOpen={isInfoOpen} />
              </div>
            </div>
            <OpenCategoryToolCategories />
            <OpenCategoryToolSave
              setFadeIn={() => setIsFadeInActive(true)}
              timeoutRef={timeoutRef}
            />
          </div>
        </DoubleModalMain>
      </div>
    </>
  )
}

export default OpenCategoryToolModal
