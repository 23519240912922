import React from 'react'
import { useSettingsTools } from './../SettingsContext'
import RequiredStar from './RequiredStar'

import modal_css from './../SettingsModal.module.scss'
import css from './DropdownInput.module.scss'
import { cloneDeep } from 'lodash'

type DropdownInputProps = {
  settingsName: string
  title: string | JSX.Element
  options: string[] | { name: string; value: string | number }[]
  isRootProperty?: boolean
  data_testid?: string
  placeholder?: string
  classNames?: string
  width?: 'half' | 'third' | 'full'
  id?: string
  isRequired?: boolean
  isDisabled?: boolean
  defaultValue?: string
  [prop: string]: unknown
}

const DropdownInput = ({
  settingsName: property,
  title,
  options,
  data_testid,
  placeholder = '',
  classNames = '',
  width = 'half',
  id,
  isRequired = false,
  isRootProperty,
  isDisabled,
  defaultValue,
  ...rest
}: DropdownInputProps) => {
  const [{ saveModule, module }] = useSettingsTools()
  const settingsName = property as keyof unknown

  let value = ''
  if (isRootProperty && module && module[settingsName]) {
    value = module[settingsName]
  }
  if (!isRootProperty && module && module.options && module.options[settingsName]) {
    value = module.options[settingsName]
  }
  if (!value && defaultValue) value = defaultValue

  let isPresent = true
  if (
    isRequired &&
    (value === null ||
      value === undefined ||
      value === '' ||
      (typeof value !== 'number' && typeof value !== 'string'))
  ) {
    isPresent = false
  }

  const getWidthClass = () => {
    if (width === 'half') {
      return modal_css.half
    }
    if (width === 'third') {
      return modal_css.third
    }
    if (width === 'full') {
      return modal_css.max
    }
    return modal_css.half
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!module) return
    const value = e.currentTarget.value

    if (isRootProperty) {
      saveModule({ ...module, [settingsName]: value })
    }
    if (!isRootProperty) {
      const newModule = cloneDeep(module)
      const currentOptions = module.options || {}
      newModule.options = { ...currentOptions, ...{ [settingsName]: value } }
      saveModule(newModule)
    }
    e.currentTarget.blur()
  }

  return (
    <div className={`${classNames} ${getWidthClass()} ${isDisabled ? css.disabled : ''}`}>
      {title}
      {isRequired && <RequiredStar />}
      <select
        className={`${css.input} browser-default ${isPresent ? '' : modal_css.required}`}
        onChange={handleOnChange}
        placeholder={placeholder}
        data-testid={data_testid}
        id={id}
        value={value}
        disabled={!!isDisabled}
        {...rest}
      >
        <option key={'emptyOption'} value={''}></option>
        {options.map((item) => (
          <option
            key={typeof item === 'string' ? item : item.name + item.value}
            value={typeof item === 'string' ? item : item.value}
          >
            {typeof item === 'string' ? item : item.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DropdownInput
