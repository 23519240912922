export enum ExportFileType {
  XLSX = 'xlsx',
  CSV = 'csv',
}

export enum ReportFileType {
  PDF = 'pdf',
  IMAGE = 'png',
  PPT = 'ppt',
}
