/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-const */
import React, { memo } from 'react';
import { Dashboard } from '../../../../../types';
import { Keyboard } from '../../../../react-constants/keyboard';

type Props = {
  selectableModule: Dashboard.PickableModule
  isDisabled: boolean
  reasonWhyDisabled: string | null
  onSelect: Function
}

function ModulePanel({ selectableModule, isDisabled, reasonWhyDisabled, onSelect }: Props) {
  let name = selectableModule.name,
    description = selectableModule.description,
    iconUrl = selectableModule.iconUrl,
    groupNumber = selectableModule.groupNumber;

  function mapModuleGroupNumberToColor(groupNo: number, isModuleDisabled: boolean = false): string {
    if (isModuleDisabled) {
      return 'grey';
    }

    switch (groupNo) {
    case 0:
      return 'orange';
    case 1:
      return 'blue';
    case 2:
      return 'purple';
    case 3:
      return 'green';
    case 10:
      return 'red';
    default:
      return 'default';
    }
  }

  return (
    <div
      aria-disabled={isDisabled}
      data-testid={`modulePickerModule-${selectableModule.type}`}
      title={!isDisabled ? selectableModule.name : reasonWhyDisabled as string}
      className={`module-picker-panel panel-color-${mapModuleGroupNumberToColor(groupNumber, isDisabled)}`}
      tabIndex={!isDisabled ? 0 : undefined}
      onKeyDown={(e) => {
        if (isDisabled || e.keyCode !== Keyboard.ENTER) return;

        onSelect(selectableModule) ;
      }}
      onClick={() => {
        if (isDisabled) return;

        onSelect(selectableModule) ;
      }}
      data-focus-id={selectableModule.hotKeyCode ?? null}
    >
      <div className="module-picker-panel-content">
        <div className="module-picker-panel-content-icon">
          <img alt={`${name} selection button`} src={iconUrl} />
        </div>
        <div className="module-picker-panel-content-description">
          <h6>{name}</h6>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

export default memo(ModulePanel);
