import React from 'react'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import SliceInputTable from './SliceInputTable'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { WheelModuleSettings, WheelTheme } from '../wheelModuleTypes'
import SliceLimitInputs from './SliceLimitInputs'
import SliderInput from '../../_shared/ModuleSettings/components/SliderInput'
import SelectExtensions from './SelectExtensions'
import RadioButtonsInput from '../../_shared/ModuleSettings/components/RadioButtonsInput'
import LimitInputs from '../../Line/settings/LimitInputs'

const WheelSettings = () => {
  const [{ module }] = useSettingsTools()
  const options = (module?.options || {}) as WheelModuleSettings
  const isConfigSelected = options.isCustomerPathConfigSelected !== false
  return (
    <>
      <Subtitle text='Extensions' />
      <Linebreak />
      <SelectExtensions />
      <SliderInput
        title='Wheel/Line width ratio (%)'
        leftLabel='Wheel'
        rightLabel='Line'
        settingsName='wheelWidthPercentage'
        min={1}
        max={100}
        defaultValue={50}
      />
      <SliderInput
        title='Extension heights (px)'
        leftLabel='Height'
        settingsName='moduleHeight'
        min={400}
        max={800}
        defaultValue={500}
      />
      <Subtitle text='Config' />
      <Linebreak />
      <CheckBoxInput
        settingsName='isCustomerPathConfigSelected'
        title='Use tenant wide config'
        text='Enabled'
        data_testid='isCustomerPathConfigSelected'
        defaultValue={true}
        width='third'
      />

      <Subtitle text='Wheel' />
      <Linebreak />
      <RadioButtonsInput
        buttons={Object.values(WheelTheme)}
        settingsName='wheelTheme'
        title='Wheel theme'
        reflowCharts={false}
        data_testid='wheelTheme'
        isRequired
        width='full'
      />
      <CheckBoxInput
        settingsName='showCaption'
        title='Show caption for colors'
        text='Enabled'
        data_testid='showCaption'
        width='third'
      />
      {!isConfigSelected && <SliceLimitInputs />}
      <SliceInputTable />

      <Subtitle text='Line' />
      <Linebreak />
      <LimitInputs />
    </>
  )
}

export default WheelSettings
