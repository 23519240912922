import { WhereMeta } from '../../../../../types'
import { kpiIdAndName } from '../_shared/ModuleSettings/components/SelectionTreeInput'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type TopBottomModule = {
  type: 'listnumeric'
  title?: string
  reactSettings: Record<string, unknown>
  small: boolean
  options?: TopBottomModuleSettings & ModuleDefaultSettings
  topBotConf?: DeprecatedTopBottomModuleSettings
  kpikeys?: string[] // eg ['kpiid', 'importanceid']
  configname?: string // eg 'customerpath'
} & ModuleBase

export type TopBottomModuleSettings = {
  selectedColumns?: string[]
  selectedKpis?: kpiIdAndName[]
  useTopBottomCustomerPath?: boolean
  topBottomDataDisplay?: DataDisplayOptions
  topBottomMax?: string
  showDataOfInterest?: boolean
  sortTopByKey?: string // not implemented atm
  sortBottomByKey?: string // not implemented atm
}

export type DeprecatedTopBottomModuleSettings = {
  columnKeys?: string[] // eg ['category', 'kpiid.name', 'kpiid']
  columnNames?: string[] // eg ['Category', 'Kpi', 'Performance']
  isSortable?: boolean
  numberOfResults?: number
  topBottom?: 'bot' | 'top' | 'topbot'
  filterInteresting?: boolean
  sortByKey?: string // eg 'kpiid.avg'
  sortByKeyBottom?: string // eg 'kpiid.avg'
  sortReversed?: boolean
  sortReversedBottom?: boolean
  showIndex?: boolean
}

export type TopBottomSettingsContext = {
  selectedColumns: string[]
  selectedKpis: kpiIdAndName[]
  useTopBottomCustomerPath: boolean
  topBottomDataDisplay: DataDisplayOptions | null
  topBottomMax: string | null
  showDataOfInterest: boolean
}

export type TopBottomDataContext = {
  id: string
  topBottomData: TopBottomStructuredData[]
  topBotFilters: {
    start_date: string
    end_date: string
    where_meta: WhereMeta
  }
  topBottomLimitValues: LimitValues
  isLoading: boolean
}

export type TopBottomViewConfiguration = {
  name: string
  columnKeys: string[]
  columnNames: string[]
  sortByKey: string
  isSortable: boolean
  numberOfResults: number
  topBottom: boolean
  showIndex?: boolean
}

export type TopBottomStructuredData = {
  kpiid: number
  kpiname: string
  category: string
  average: number
  trend_change: number | null
}

export type LimitValues = {
  max: number
  mid: number
  min: number
}

export enum TopBottomColumnNames {
  CATEGORY = 'Category',
  KPI = 'KPI',
  PERFORMANCE = 'Performance',
  TREND = 'Trend',
}

export enum DataDisplayOptions {
  TOP_BOTTOM = 'Top & Bottom',
  TOP = 'Top',
  BOTTOM = 'Bottom',
}
