import React, { useEffect, useRef, useState } from 'react'
import { FormMountState } from './BreakPointModal'
import { AddOrRemove, Breakpoint } from '../../pietabularTypes'
import { v4 as uuid } from 'uuid'
import ToolbarIcon from './ToolbarIcon'
import { useEvent } from 'react-use'
import useCommonDbSettingsConfig from '../../../../../../stores/useCommonDbSettingsConfig'
import { toast } from 'react-toastify'
import { useToastId } from '../../../../../common/Notification/NotificationContext'
import {
  errorDeletingBreakpoint,
  errorSavingNewBreakpoint,
  infoSuccesfulBreakpointAdd,
  infoSuccesfulBreakpointDelete,
  infoSuccesfulBreakpointRemove,
  infoSuccesfulBreakpointSave,
} from '../../PietabularNotifications'
import { DatePicker } from '@mui/x-date-pickers-pro'

import css from './BreakPointAddNewForm.module.scss'
import dayjs from 'dayjs'
import { extractDateStringFromDayJs } from '../../../../../../react-services/datesService'
import { format } from 'date-fns'

type TouchedFields = {
  title: boolean
  date: boolean
  description: boolean
}

type BreakPointAddNewFormProps = {
  formState: FormMountState
  breakpoint?: Breakpoint | null
  onClose: () => void
  selected: boolean
  handleAddOrRemoveBreakpoint: (breakpoint: Breakpoint, action: AddOrRemove) => void
}

const BreakPointAddNewForm = ({
  formState,
  breakpoint = null,
  onClose,
  selected,
  handleAddOrRemoveBreakpoint,
}: BreakPointAddNewFormProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const initBreakpoint = {
    id: uuid(),
    title: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    description: '',
  }

  const initTouched = {
    title: false,
    description: false,
    date: false,
  }

  const { updateConfig, refetchConfig } = useCommonDbSettingsConfig()
  const { toastifyId } = useToastId()
  const [newBreakpoint, setNewBreakpoint] = useState<Breakpoint>(initBreakpoint)
  const [touched, setTouched] = useState<TouchedFields>(initTouched)
  const isNewBreakpoint = breakpoint === null

  useEvent('keyup', (e: KeyboardEvent) => e.key === 'Escape' && onClose())
  useEvent('keydown', (e: KeyboardEvent) => e.key === 's' && handleBreakpointSave())
  useEvent('keydown', (e: KeyboardEvent) => e.key === 'a' && handleAddingNewBreakpointToModule())
  useEvent('keydown', (e: KeyboardEvent) => e.key === 'r' && handleRemovingBreakpointFromModule())
  useEvent('keydown', (e: KeyboardEvent) => e.key === 'd' && handleBreakpointDelete())

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      if (!breakpoint) {
        setNewBreakpoint(initBreakpoint)
      } else {
        setNewBreakpoint(breakpoint)
      }
      setTouched(initTouched)
    }, 200)
    return () => timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [breakpoint])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    setTouched((prev) => ({ ...prev, [id]: true }))
    setNewBreakpoint((prev) => ({ ...prev, [id]: value }))
  }

  const handleDateInputChange = (value: dayjs.Dayjs | null) => {
    if (!value || !module) return
    const newDate = extractDateStringFromDayJs(value)
    if (!newDate) return
    setTouched((prev) => ({ ...prev, date: true }))
    setNewBreakpoint((prev) => ({ ...prev, date: newDate }))
  }

  const handleBreakpointSave = async () => {
    const newBp = { ...newBreakpoint }
    if (validateTitle(newBp.title) || validateDescription(newBp.description)) {
      setTouched({ title: true, description: true, date: true })
      return
    }
    try {
      const upToDateConfig = await refetchConfig()
      const breakpoints =
        upToDateConfig && upToDateConfig.breakpoints ? upToDateConfig.breakpoints : []
      const newBreakpoints = isNewBreakpoint
        ? breakpoints.concat(newBp)
        : breakpoints.map((bp) => (bp.id === newBp.id ? newBp : bp))
      await updateConfig({ value: { breakpoints: newBreakpoints } })
      toast.success(infoSuccesfulBreakpointSave, { containerId: toastifyId })
      onClose()
    } catch (e) {
      toast.error(errorSavingNewBreakpoint, { containerId: toastifyId })
    }
  }

  const handleBreakpointDelete = async () => {
    const newBp = { ...newBreakpoint }
    try {
      const upToDateConfig = await refetchConfig()
      const breakpoints =
        upToDateConfig && upToDateConfig.breakpoints ? upToDateConfig.breakpoints : []
      const newBreakpoints = breakpoints.filter((bp) => bp.id !== newBp.id)

      await updateConfig({ value: { breakpoints: newBreakpoints } })
      toast.success(infoSuccesfulBreakpointDelete, { containerId: toastifyId })
      onClose()
    } catch (e) {
      toast.error(errorDeletingBreakpoint, { containerId: toastifyId })
    }
  }

  const handleRemovingBreakpointFromModule = () => {
    const breakpoint = { ...newBreakpoint }
    handleAddOrRemoveBreakpoint(breakpoint, AddOrRemove.REMOVE)
    toast.success(infoSuccesfulBreakpointRemove, { containerId: toastifyId })
    onClose()
  }

  const handleAddingNewBreakpointToModule = () => {
    const breakpoint = { ...newBreakpoint }
    if (validateTitle(breakpoint.title) || validateDescription(breakpoint.description)) {
      setTouched({ title: true, description: true, date: true })
      return
    }
    handleAddOrRemoveBreakpoint(breakpoint, AddOrRemove.ADD)
    toast.success(infoSuccesfulBreakpointAdd, { containerId: toastifyId })
    onClose()
  }

  const validateTitle = (title: string) => {
    if (title.length < 3) {
      return 'should be atleast 3 characters long'
    }
    if (title.length > 20) {
      return 'should be less then 20 characters long'
    }
    return ''
  }

  const validateDescription = (title: string) => {
    if (title.length < 1) {
      return 'should be atleast 1 character long'
    }
    if (title.length > 200) {
      return 'should be less then 200 characters long'
    }
    return ''
  }

  return (
    <div
      className={`${formState === FormMountState.OPEN && css.animate_in} ${
        formState === FormMountState.CLOSING && css.animate_out
      } ${css.cntr}`}
    >
      <div className={css.toolbar}>
        {!isNewBreakpoint && (
          <ToolbarIcon
            icon='delete'
            data-testid='breakpoints-form-delete-button'
            title='Remove breakpoint, you can also press "d" to delete'
            onClick={handleBreakpointDelete}
          />
        )}
        <ToolbarIcon
          icon='save'
          title='Save changes, you can also press "s" to save'
          data-testid='breakpoints-form-save-button'
          onClick={handleBreakpointSave}
        />
        {!isNewBreakpoint &&
          (!selected ? (
            <ToolbarIcon
              icon='add'
              title='Click to add the breakpoint to chart, you can also press "a" to add'
              onClick={handleAddingNewBreakpointToModule}
            />
          ) : (
            <ToolbarIcon
              icon='remove'
              title='Click to remove the breakpoint from chart, you can also press "r" to remove'
              onClick={handleRemovingBreakpointFromModule}
            />
          ))}
        <ToolbarIcon
          icon='close'
          title='Close the form, you can also press "esc" to leave'
          onClick={onClose}
        />
      </div>
      <label>
        <h6 className={css.input_title}>
          <b>Title&nbsp;&nbsp;</b>
          {touched.title && !!validateTitle(newBreakpoint.title) && (
            <div>{validateTitle(newBreakpoint.title)}</div>
          )}
        </h6>
        <input
          type='text'
          id='title'
          data-testid='breakpoints-form-title-input'
          value={newBreakpoint.title}
          onChange={handleInputChange}
          placeholder='Text shown in chart'
          onBlur={() => setTouched((prev) => ({ ...prev, title: true }))}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </label>
      <label>
        <h6>
          <b>Date</b>
        </h6>
        <div
        // onMouseDown={(e) => {
        //   e.preventDefault()
        //   e.stopPropagation()
        // }}
        // onKeyDown={(e) => {
        //   e.preventDefault()
        //   e.stopPropagation()
        // }}
        // onKeyUp={(e) => {
        //   e.preventDefault()
        //   e.stopPropagation()
        // }}
        >
          <DatePicker
            className={`breakpoints-datepicker ${css.date}`}
            name='breakpointDate'
            value={dayjs(newBreakpoint.date || new Date())}
            format='YYYY-MM-DD'
            onChange={handleDateInputChange}
          />
        </div>
      </label>
      <label>
        <h6 className={css.input_title}>
          <b>Description&nbsp;&nbsp;</b>
          {touched.description && !!validateDescription(newBreakpoint.description) && (
            <div>{validateDescription(newBreakpoint.description)}</div>
          )}
        </h6>
        <textarea
          className={css.description}
          id='description'
          data-testid='breakpoints-form-description-input'
          value={newBreakpoint.description}
          onChange={handleInputChange}
          placeholder='Text shown when hovering title'
          onBlur={() => setTouched((prev) => ({ ...prev, description: true }))}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </label>
    </div>
  )
}

export default BreakPointAddNewForm
