import React from 'react';
import DownArrowIcon from '../../../common/Buttons/DownArrowIcon';
import DownBottomArrowIcon from '../../../common/Buttons/DownBottomArrowIcon';
import UpArrowIcon from '../../../common/Buttons/UpArrowIcon';
import UpTopArrowIcon from '../../../common/Buttons/UpTopArrowIcon';
import { Direction, Position } from '../inspectorTypes';
import css from './TablePositionButtons.module.scss';

type TablePositionButtonsProps = {
  position: Position,
  handlePositionChange: (direction: Direction) => void,
  disabled: boolean
}

const TablePositionButtons = ({ position, handlePositionChange, disabled }: TablePositionButtonsProps) => {
  return (
    <div className={css.cntr}>
      <UpTopArrowIcon
        onClick={() => handlePositionChange(Direction.UP_TOP)}
        customClasses={[css.btn, ...(position === Position.TOP ? [css.hide] : [])]}
        isDisabled={disabled}
      />
      <UpArrowIcon
        onClick={() => handlePositionChange(Direction.UP)}
        customClasses={[css.btn, ...(position === Position.TOP ? [css.hide] : [])]}
        isDisabled={disabled}
      />
      <DownArrowIcon
        onClick={() => handlePositionChange(Direction.DOWN)}
        customClasses={[css.btn, ...(position === Position.BOTTOM ? [css.hide] : [])]}
        isDisabled={disabled}
      />
      <DownBottomArrowIcon
        onClick={() => handlePositionChange(Direction.DOWN_BOTTOM)}
        customClasses={[css.btn, ...(position === Position.BOTTOM ? [css.hide] : [])]}
        isDisabled={disabled}
      />
    </div>
  )
}

export default TablePositionButtons;