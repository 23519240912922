import { useEffect, useState } from 'react'
import { getGenericConfiguration } from '../react-services/configService'
import { isCustomerPathObject } from '../utilities'
import {
  errorFetchingCustomerPathConfig,
  errorInvalidCustomerPathObject,
} from './notificationMessages'
import { WhereMeta } from '../../types'
import { useMountedState } from 'react-use'

export type CustomerPathKpi = {
  kpiid?: number
  category?: string
  limitValues?: {
    max?: number
    mid?: number
    min?: number
  }
}

export type CustomerPathOpenKpi = {
  name: string
  iconUrl: string
  kpis: number[]
  metaFilter?: WhereMeta
}

export type CustomerPathConfig = {
  conf?: CustomerPathKpi[]
  categoryconf?: unknown
  limitValues?: {
    max?: number
    mid?: number
    min?: number
  }
  open_conf?: CustomerPathOpenKpi[]
  module_v2?: boolean
  comparativelimits?: {
    max?: number
    mid?: number
    min?: number
  }
}
const configName = 'customerpath'
const useConfigCustomerPath = () => {
  const isMounted = useMountedState()
  const [config, setConfig] = useState<CustomerPathConfig | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    getConfig()
      .then((customerPath) => isMounted() && setConfig(customerPath))
      .catch((e) => isMounted() && setError(e))
      .finally(() => isMounted() && setIsLoading(false))
  }, [])

  const getConfig = async (): Promise<CustomerPathConfig> => {
    try {
      const config = await getGenericConfiguration(configName)
      if (isCustomerPathObject(config)) {
        return Promise.resolve(config || {})
      } else {
        return Promise.reject(errorInvalidCustomerPathObject)
      }
    } catch (err) {
      if (typeof err === 'string') {
        return Promise.reject(errorInvalidCustomerPathObject)
      } else {
        return Promise.reject(errorFetchingCustomerPathConfig)
      }
    }
  }

  return {
    config,
    isLoading,
    error,
  }
}

export default useConfigCustomerPath
