import React, { Component } from 'react';
import Rodal from 'rodal';
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable';
import { post } from '../../../../react-services/apiService';
import { addFiltersToEveryModuleInDashboardConfiguration, getKpiIdsFromDashboardSettings } from '../../../../react-services/reportsService';
import { Icon, Card, Row, Col, Button } from 'react-materialize';
import { getDateNow, datePlusDays } from '../../../../react-services/datesService';
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator';
import { deepCopy, spawnHelpdeskToast } from '../../../../../utils';
import { emailsFromString } from '../../../../react-services/inputService';
import { TimeFrame } from '../../../../react-constants/timeFrame';


export default class ReportSenderModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
      tableRows: [],
      rowsThatWereJustSent: [],
      // report instance basically refers to a single row in this view; only if the row has been edited or sent will it be instantiated
      reportInstances: deepCopy(props.reportConf.reportInstances) || {},
    }
    this.rowsThatWereJustSent = [];
    this.reportDashboard = this.props.reportTemplate.dashboards.find(db => db.name === this.props.reportConf.reportDashboardKey);
    this.reportDashboardKpis = getKpiIdsFromDashboardSettings(this.reportDashboard);

    this.sendReport = this.sendReport.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(this.props.reportConf.reportInstances) !== JSON.stringify(prevProps.reportConf.reportInstances)) {
      this.setState({
        reportInstances: deepCopy(this.props.reportConf.reportInstances) || {},
      }, () => {
        this.setState({tableRows: this.formTableRows()})
      })
    }
  }

  componentDidMount() {
    let now = getDateNow();
    let dynamicFiltersPayload = {
      query: {
        start_date: datePlusDays(now, -this.props.reportConf.threshold),
        end_date: now,
      },
      kpis: this.reportDashboardKpis.map(kpi => parseInt(kpi, 10)),
      timeframe: TimeFrame.FIXED_WINDOW
    }
    post('POST_DYNAMICFILTERS', dynamicFiltersPayload, {'is-expanded': false}).then(res => {
      if (res && res.filters) {
        let metaKey = Object.keys(this.props.reportConf.where_meta)[0];
        this.setState({
          filterValuesWithRecentData: res.filters[metaKey] || [],
        }, () => {
          this.setState({tableRows: this.formTableRows()})
        })
      }
    }).catch(() => {
      window.Materialize.toast('Could not fetch data about latest report data updates. Please refresh and try again.')
    }).finally(() => {
      this.setState({ready: true})
    })
  }

  formHeaders() {
    let result = []
    if (this.props.reportConf.where_meta) {
      for (let meta in this.props.reportConf.where_meta) {
        result.push(<th colSpan={2} key={`key-${meta}`}>{`Filter: ${meta}`}</th>)
      }
    }
    result.push(<th colSpan={1} key="lastSent">Last sent</th>)
    result.push(<th colSpan={2} key="recipients">Recipients</th>)
    result.push(<th colSpan={1} key="send" onClick={() => {}}>Send</th>)
    return <tr>{result}</tr>
  }

  formTableRows() {
    let result = []
    // this only supports one meta whereas headers support several
    let metaKey = Object.keys(this.props.reportConf.where_meta)[0];

    if (this.state.filterValuesWithRecentData) {
      for (let i = 0; i < this.state.filterValuesWithRecentData.length; i++) {
        let value = this.state.filterValuesWithRecentData[i]
        let buttonIsDisabled = this.rowsThatWereJustSent.includes(value)

        let tds = [<td data-cy={`reportSenderRowFilterValue-${i}`} colSpan={2} key={`filter${i}value${value}`}>{value}</td>];
        tds.push(<td data-cy={`reportSenderRowFilterLastSent-${i}`} colSpan={1} className="center" key={`filter${i}value${value}lastSent`}>
          {this.rowsThatWereJustSent.includes(value) ? 'just now' : (this.state.reportInstances[value] && this.state.reportInstances[value].key === metaKey && this.state.reportInstances[value].lastSent ? this.state.reportInstances[value].lastSent : 'n/a')}
        </td>)
        tds.push(this.formRecipientsTd(i, value))
        tds.push(<td colSpan={1} key={`filter${i}value${value}send`} className="center"><Button data-cy={`reportSenderRowSenderButton-${i}`} disabled={buttonIsDisabled} onClick={() => {this.sendReport(metaKey, value)}}><Icon left>send</Icon>{buttonIsDisabled ? 'Sent!' : 'Send'}</Button></td>)
        result.push(<tr key={`filter${i}value${value}row`}>{tds}</tr>)
      }
    }

    return result;
  }

  formRecipientsTd(rowIndex, metaValue) {
    let text = this.getRecipientsAsString(metaValue);
    return (
      <td data-cy={`reportSenderRowRecipients-${rowIndex}`} colSpan={2} key={`filter${rowIndex}value${metaValue}recipients`} className="report-sender-recipients-row">
        {/* This thing makes sorting possible */}
        <span className="hide">{text}</span>
        <textarea
          data-cy={`reportSenderRowRecipientsInput-${rowIndex}`}
          placeholder="Use separate rows or separate by comma (,) or semicolon (;)"
          disabled={this.rowsThatWereJustSent.includes(metaValue)}
          value={text}
          className="browser-default"
          onChange={(e, value) => {this.handleRecipientChangeOnReportInstance(metaValue, e.target.value)}}
        ></textarea>
      </td>
    )
  }

  getRecipientsAsString(metaValue) {
    let reportInstance = this.state.reportInstances[metaValue]
    let recipients = !reportInstance ? this.props.reportConf.recipients : reportInstance.recipients
    if (Array.isArray(recipients)) recipients = recipients.join(', ')
    return recipients
  }

  handleRecipientChangeOnReportInstance(metaValue, input) {
    let allInstances = deepCopy(this.state.reportInstances);
    if (!allInstances[metaValue]) allInstances[metaValue] = {recipients: this.props.reportConf.recipients.join(', '), lastSent: ''}
    allInstances[metaValue].recipients = input;

    this.setState({
      reportInstances: allInstances,
    }, () => {
      this.setState({
        tableRows: this.formTableRows(),
      })
    })
  }

  sendReport(metaKey, metaValue) {
    let reportInstance = this.buildReportInstance(metaKey, metaValue)
    let reportCopy = deepCopy(this.props.reportConf)
    reportCopy.lastSent = getDateNow();
    reportCopy.reportInstances[metaValue] = reportInstance;

    this.rowsThatWereJustSent = [...this.rowsThatWereJustSent, metaValue];
    this.setState({
      tableRows: this.formTableRows(),
    })

    post('POST_DASHEXPORT', {
      dashboard_settings: addFiltersToEveryModuleInDashboardConfiguration(this.reportDashboard, datePlusDays(getDateNow(), -reportCopy.threshold), getDateNow(), {[metaKey]: [metaValue]}),
      emails: reportInstance.recipients,
      format: 'pdf',

      // back end doesn't actually care about these indices but they are mandatory
      dashboard_idx: 9999,
      section_idx: 9999,
    }).then(() => {
      this.props.onSend(reportCopy);
    }).catch(error => {
      console.error(error);
      this.rowsThatWereJustSent.pop();
      spawnHelpdeskToast(`A problem occurred and the report was not sent. Please refresh and try again.`, "Unable to send a report from Report Overview module", error.toString() )
    })
  }

  buildReportInstance(metaKey, metaValue) {
    let reportInstance = deepCopy(this.state.reportInstances[metaValue]);
    if (!reportInstance) {
      reportInstance = {recipients: [].concat(this.props.reportConf.recipients)}
    } else {
      if (!Array.isArray(reportInstance.recipients))
        reportInstance.recipients = emailsFromString(reportInstance.recipients)[0]
    }
    reportInstance.lastSent = getDateNow();
    reportInstance.key = metaKey;
    return reportInstance;
  }

  render() {
    return (
      <Rodal
        closeOnEsc
        visible
        animation="slideUp"
        onClose={this.props.onClose}
        customStyles={{'minWidth': '80%'}}
      >
        <h5>Send report <i>{this.props.reportConf.title} {this.props.reportConf.subtitle ? `- ${this.props.reportConf.subtitle}`: null}</i></h5>
        <hr /><br />
        <div><i>Please note: </i>any report sent here will always be sent to your email as well.</div>
        <br/>
        <GenericTable
          headers={this.formHeaders()}
          rows={this.state.tableRows}
          useFixedLayout
        />
        {!this.state.ready && <LoadingIndicator />}
        {this.state.ready && this.state.filterValuesWithRecentData && this.state.filterValuesWithRecentData.length === 0 &&
          <Row>
            <br/><br/>
            <Col s={12}>
              <Card className="blue white-text">
                There doesn't seem to be new data.
              </Card>
            </Col>
          </Row>
        }

        {this.state.ready && this.reportDashboardKpis && this.reportDashboardKpis.length === 0 &&
          <Row>
            <br/><br/>
            <Col s={12}>
              <Card className="orange white-text">
                The template you have selected doesn't seem to use data. At least one KPI needs to be present in the template!
              </Card>
            </Col>
          </Row>
        }
      </Rodal>
    );
  }
}
