import React, { useEffect, useState } from 'react'
import useConfigCustomerPath, {
  CustomerPathConfig,
  CustomerPathKpi,
} from '../../../../../stores/useConfigCustomerPath'
import useNumericKpis from '../../../../../stores/useNumericKpis'

import input_css from './../../_shared/ModuleSettings/components/TextInput.module.scss'
import select_css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'
import settings_css from './SliceInputTable.module.scss'
import css from './SliceKpiSelections.module.scss'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { WheelModule, WheelModuleSettings } from '../wheelModuleTypes'
import { Kpi } from '../../../../../../types'

type SliceKpiSelectionsProps = {
  category?: string | null
  kpiid?: number | null
  limitValues?: { min?: number; max?: number }
  handleClearEditerCategory: () => void
}
const SliceKpiSelections = ({
  category,
  kpiid,
  limitValues,
  handleClearEditerCategory,
}: SliceKpiSelectionsProps) => {
  const [{ module, saveModule }] = useSettingsTools()
  const wheelModule = module as WheelModule
  const { config: customerPathConfig } = useConfigCustomerPath()
  const { numericKpis } = useNumericKpis()
  const [categories, setCategories] = useState<string[]>([])
  const [selectableKpis, setSelectableKpis] = useState<Kpi.Kpi[]>([])
  const [newCategorySelection, setNewCategorySelection] = useState<CustomerPathKpi | null>(null)
  const options = (module?.options || {}) as WheelModuleSettings
  const useConfig = options.isCustomerPathConfigSelected !== false
  const moduleCustomerPathConfig = options.moduleWheelConfig

  useEffect(() => {
    setNewCategorySelection({
      category: category ? category : undefined,
      kpiid: kpiid !== null && kpiid !== undefined ? kpiid : undefined,
      limitValues,
    })
  }, [category, kpiid, limitValues])

  useEffect(() => {
    if (customerPathConfig && useConfig) {
      const configCategoryKpis = (customerPathConfig.conf || [])
        .map((category) => category.category)
        .filter<string>((c): c is string => typeof c === 'string')
      const uniqueCategories = Array.from(new Set(configCategoryKpis))
      setCategories(uniqueCategories)
    }
  }, [customerPathConfig])

  useEffect(() => {
    if (moduleCustomerPathConfig && !useConfig) {
      const configCategoryKpis = (moduleCustomerPathConfig.conf || [])
        .map((category) => category.category)
        .filter<string>((c): c is string => typeof c === 'string')
      const uniqueCategories = Array.from(new Set(configCategoryKpis))
      setCategories(uniqueCategories)
    }
  }, [moduleCustomerPathConfig])

  useEffect(() => {
    if (!useConfig && numericKpis) {
      const selectedKpis = (moduleCustomerPathConfig?.conf || [])
        .filter((category) => category.category === newCategorySelection?.category)
        .map((category) => category.kpiid)
      const newSelectableKpis = numericKpis?.filter(
        (kpi) => !(selectedKpis.includes(kpi.id) && kpi.id !== newCategorySelection?.kpiid),
      )
      setSelectableKpis(newSelectableKpis)
    }
  }, [newCategorySelection?.category, moduleCustomerPathConfig, numericKpis])

  const handleSaveNewCategorySelection = () => {
    if (!wheelModule || !newCategorySelection?.category || !newCategorySelection.kpiid) return
    const currentOptions = (wheelModule.options || {}) as WheelModuleSettings
    const currentWheelConfig = currentOptions.moduleWheelConfig || {}
    const currentWheelKpis = currentWheelConfig.conf || []
    let newWheelKpis = currentWheelKpis
    if ((category === undefined || category === null) && (kpiid === undefined || kpiid === null)) {
      newWheelKpis = newWheelKpis.concat(newCategorySelection)
    } else {
      newWheelKpis = newWheelKpis.map((kpi) => {
        if (kpi.kpiid === kpiid && kpi.category === category) {
          return newCategorySelection
        } else {
          return kpi
        }
      })
    }

    const newWheelConfig: CustomerPathConfig = { ...currentWheelConfig, conf: newWheelKpis }
    saveModule({
      ...wheelModule,
      options: { ...currentOptions, moduleWheelConfig: newWheelConfig },
    })
    handleClearEditerCategory()
  }

  const handleOnChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!newCategorySelection) return
    const newValue = e.currentTarget.value
    setNewCategorySelection((prev) => ({ ...prev, category: newValue }))
  }

  const handleOnChangeKpi = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!newCategorySelection) return
    const newValue = e.currentTarget.value
    setNewCategorySelection((prev) => ({ ...prev, kpiid: +newValue }))
  }

  const handleOnChangeLimits = (e: React.ChangeEvent<HTMLInputElement>, limit: 'min' | 'max') => {
    if (!newCategorySelection) return
    const newValue = +e.currentTarget.value
    if (isNaN(newValue)) return
    const limitValues = newCategorySelection.limitValues || { min: undefined, max: undefined }
    limitValues[limit] = newValue
    setNewCategorySelection((prev) => ({ ...prev, limitValues }))
  }

  if (!newCategorySelection) {
    return <tr></tr>
  }
  return (
    <tr>
      <td className={`${settings_css.category}`}>
        <input
          type='text'
          list='categories'
          className={`${input_css.input} ${newCategorySelection.category ? '' : css.required}`}
          value={newCategorySelection.category}
          onChange={(e) => handleOnChangeCategory(e)}
          data-testid='categoryNameInput'
        />
        <datalist className={`browser-default ${select_css.input}`} id='categories'>
          {categories.map((element) => (
            <option key={element} value={element}>
              {element}
            </option>
          ))}
        </datalist>
      </td>
      <td className={settings_css.kpiName}>
        <select
          className={`browser-default ${select_css.input} ${
            newCategorySelection.kpiid ? '' : css.required
          }`}
          disabled={!newCategorySelection.category}
          id='kpis'
          value={newCategorySelection.category ? newCategorySelection.kpiid : 'Category first'}
          onChange={(e) => handleOnChangeKpi(e)}
          data-testid='categoryKpiInput'
        >
          <option key='emptyOption' value={''}></option>
          {!newCategorySelection.category && (
            <option value={'Category first'}>Category first</option>
          )}
          {selectableKpis.map((kpi) => (
            <option key={kpi.id} value={kpi.id}>
              {`name: ${kpi.name} id: ${kpi.id}`}
            </option>
          ))}
        </select>
      </td>
      <td className={settings_css.kpiId}></td>
      <td className={settings_css.minAndMax}>
        <input
          className={input_css.input}
          type='number'
          onChange={(e) => handleOnChangeLimits(e, 'min')}
          value={newCategorySelection.limitValues?.min}
        />
      </td>
      <td className={settings_css.minAndMax}>
        <input
          className={input_css.input}
          type='number'
          onChange={(e) => handleOnChangeLimits(e, 'max')}
          value={newCategorySelection.limitValues?.max}
        />
      </td>
      <td className={settings_css.edit}>
        <div className={settings_css.editFlex}>
          <span
            className={`material-symbols-outlined ${css.icon}`}
            onClick={handleSaveNewCategorySelection}
            data-testid='saveCategory'
          >
            save
          </span>
          <span
            className={`material-symbols-outlined ${css.icon}`}
            onClick={handleClearEditerCategory}
          >
            cancel
          </span>
        </div>
      </td>
    </tr>
  )
}

export default SliceKpiSelections
