import { clone } from 'lodash'
import { GenericConfig, Kpi, Query } from '../../../../../types'
import { datePlusDays, daysInBetween } from '../../../../react-services/datesService'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { NumberTrendModule, SharedFilter } from './numbertrendTypes'

type NormalQueryProps = {
  query?: NumberTrendModule['query']
  kpis?: Kpi.Kpi[]
  calculatedKpis?: GenericConfig.CalculatedKpis
}
type SharedQueryProps = {
  sharedFilter?: SharedFilter
  kpis?: Kpi.Kpi[]
  calculatedKpis?: GenericConfig.CalculatedKpis
}

const useNumberTrendQueryFormat = () => {
  const createQueryFromNormalQuery = ({
    query,
    kpis,
    calculatedKpis,
  }: NormalQueryProps): Query.SingleNumeric => {
    let newQuery: Query.SingleNumeric = {
      start_date: '',
      end_date: '',
      where_meta: {},
      calculated_kpis: calculatedKpis || {},
      kpis: kpis || [],
      cache: true,
      trend_line: false,
      grouping: '',
    }
    try {
      if (query) {
        newQuery = {
          ...newQuery,
          ...query,
          kpis: kpis || [],
          calculated_kpis: calculatedKpis || {},
        }
      }
      return newQuery
    } catch (e) {
      return newQuery
    }
  }

  const createQueryFromSharedFilter = ({
    sharedFilter,
    kpis,
    calculatedKpis,
  }: SharedQueryProps): Query.SingleNumeric => {
    let newQuery: Query.SingleNumeric = {
      start_date: '',
      end_date: '',
      where_meta: {},
      calculated_kpis: calculatedKpis || {},
      kpis: kpis || [],
      cache: true,
      trend_line: false,
      grouping: '',
    }
    try {
      if (sharedFilter) {
        newQuery = { ...newQuery, ...transformFiltersToWhereMetaForm(sharedFilter.filters) }
      }
      return newQuery
    } catch (e) {
      return newQuery
    }
  }
  const createTrendQueryFromNormalQuery = function (
    query: Query.SingleNumeric,
  ): Query.SingleNumeric {
    if (query && query.start_date && query.end_date) {
      const trendquery = clone(query)
      const days = daysInBetween(query.start_date, query.end_date)
      trendquery.end_date = datePlusDays(query.start_date, -1)
      trendquery.start_date = datePlusDays(trendquery.end_date, -days)
      return trendquery
    } else {
      return query
    }
  }

  return {
    createQueryFromNormalQuery,
    createQueryFromSharedFilter,
    createTrendQueryFromNormalQuery,
  }
}

export default useNumberTrendQueryFormat
