import React, { useEffect, useMemo } from 'react'
import { useSummaryData } from '../Context/SummaryDataContext'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import Summary from './Summary'
import { WhereMetaSingle } from '../../../../../../types'
import useSummariesTimeFrame from '../useSummariesTimeFrame'
import { isNull, isUndefined } from 'lodash'

import css from './Summaries.module.scss'
import objectHash from 'object-hash'

const Summaries = () => {
  const [{ summaryConf, summaries }, setSummaryData] = useSummaryData()
  const [
    {
      summarySettings,
      filterPath,
      filterSummaryTypes,
      filterMetas,
      filterTimeFrame,
      filterTimePeriod,
    },
  ] = useSummaryFilters()

  const { getStartAndEndDates } = useSummariesTimeFrame(filterTimeFrame, filterTimePeriod)

  const startAndEndDates: { startDate: string; endDate: string } | null = useMemo(() => {
    return getStartAndEndDates() || null
  }, [filterTimeFrame, filterTimePeriod])

  useEffect(() => {
    if (!summaryConf || !summarySettings) return
    const filteredSummaries = summaryConf.configurations.filter((summary) => {
      return (
        areMetasEqual(filterMetas, summary.filters.meta || {}) &&
        (!filterPath || (summary.filters.topics && summary.filters.topics.includes(filterPath))) &&
        filterSummaryTypes.map((s) => s.angle).includes(summary.angle) &&
        startAndEndDates?.endDate === summary.end_date &&
        startAndEndDates?.startDate === summary.start_date
      )
    })
    setSummaryData((prev) => ({
      ...prev,
      summaries: filteredSummaries,
    }))
  }, [summaryConf, summarySettings, startAndEndDates])

  const areMetasEqual = (filters: WhereMetaSingle, target: WhereMetaSingle): boolean => {
    const filterArray = Object.entries(filters || {})
    const targetMetas = { ...(target || {}) }
    let areEqual = true
    filterArray.forEach(([key, value]) => {
      const loweredValue = value.toLocaleLowerCase()
      const target = targetMetas[key]
      const loweredTarget =
        !isUndefined(target) && !isNull(target) ? target.toLocaleLowerCase() : 'kaikki'
      if (loweredValue !== loweredTarget) areEqual = false
      delete targetMetas[key]
    })
    if (targetMetas && Object.keys(targetMetas).length > 0) areEqual = false
    return areEqual
  }

  return (
    <div className={summaries.length === 1 ? css.singleCard : css.content_summaries}>
      {summaries &&
        summaries.map((summary) => {
          const summaryHash = objectHash(summary)
          return <Summary key={summaryHash} summary={summary} />
        })}
    </div>
  )
}

export default Summaries
