import React, { useEffect, useRef, useState } from 'react'
import { useSettingsTools } from '../SettingsContext'
import { cloneDeep } from 'lodash'

const SAVE_TIMEOUT = 500
type SliderItemProps = {
  settingsName: string
  setValue: (value: number) => void
  defaultValue?: number
  min: number
  max: number
  isDisabled?: boolean
}

const SliderItem = ({
  settingsName: property,
  setValue,
  defaultValue,
  min,
  max,
  isDisabled,
}: SliderItemProps) => {
  const saveTimeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const settingsName = property as keyof unknown
  const [sliderValue, setSliderValue] = useState<number>(50)

  const currentOptions = module?.options || {}
  const value =
    currentOptions && currentOptions[settingsName] ? currentOptions[settingsName] : defaultValue

  useEffect(() => {
    setSliderValue(value || 0)
    return () => saveTimeoutRef.current && clearTimeout(saveTimeoutRef.current)
  }, [])

  useEffect(() => {
    handleSaveValue(sliderValue)
    if (setValue) setValue(sliderValue)
  }, [sliderValue])

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return
    const newValue = +e.currentTarget.value
    setSliderValue(newValue)
  }

  const handleSaveValue = (newValue: number) => {
    if (!module || isDisabled) return
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    saveTimeoutRef.current = setTimeout(() => {
      if (value === sliderValue) return
      const currentOptions = module.options || {}
      const newModule = cloneDeep(module)
      newModule.options = { ...currentOptions, ...{ [settingsName]: newValue } }
      saveModule(newModule)
    }, SAVE_TIMEOUT)
  }

  return (
    <>
      <input
        data-testid={`${settingsName}-slider`}
        type='range'
        min={min}
        max={max}
        value={sliderValue}
        onChange={handleOnChangeInput}
      />
    </>
  )
}

export default SliderItem
