import React from 'react'
import useMetaKeys from '../../../../../stores/useMetaKeys'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { SummaryModule, SummarySettings } from '../summaryTypes'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoMetaSelection } from '../SummaryNotifications'

import css from './../../_shared/ModuleSettings/components/CheckBoxSelectionList.module.scss'
import modal_css from './../../../DashboardModules/_shared/ModuleSettings/SettingsModal.module.scss'

const MetaFilterOptions = () => {
  const { filteredMetaKeys } = useMetaKeys()
  const [{ module, saveModule }] = useSettingsTools()
  const summaryModule = module as SummaryModule
  const options = module?.options ? (module.options as SummarySettings) : null
  const selectedMetas = options?.metaKeysForFilters || []

  const handleClick = (metaKey: string) => {
    if (!summaryModule) return
    let newMetas = [] as string[]
    if (selectedMetas.includes(metaKey)) {
      newMetas = selectedMetas.filter((meta) => meta !== metaKey)
    }
    if (!selectedMetas.includes(metaKey)) {
      newMetas = selectedMetas.concat(metaKey)
    }
    saveModule({
      ...summaryModule,
      options: { ...summaryModule.options, metaKeysForFilters: newMetas },
    })
  }

  return (
    <div className={modal_css.max} data-testid='metaKeysForFilters'>
      <TextWithInfo text='Meta filters' info={infoMetaSelection} tooltipDirection='top' />
      <div className={`${css.list}`}>
        {filteredMetaKeys &&
          filteredMetaKeys.map((meta, i) => (
            <CheckBoxItem
              settingsName=''
              key={meta}
              text={meta}
              data_testid={'meta-checkbox-item-' + i}
              id={meta}
              classNames={css.item}
              onClickLabel={handleClick}
              checked={selectedMetas.includes(meta)}
            />
          ))}
      </div>
    </div>
  )
}

export default MetaFilterOptions
