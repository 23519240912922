import { cloneDeep, isNil } from 'lodash'
import { Dashboard, Query, Response, Data } from '../../types'
import { post } from './apiService'
import { overwriteWithPartialQuery } from './queryService'
import { concatNumericKpiSeries, concatTextualKpiSeries } from './kpiService'
import {
  errorGettingMetaFrenquencies,
  errorGettingNumericFrenquencies,
  errorGettingOpenFrenquencies,
  errorInvalidMetaFrenquencies,
  errorInvalidNumericFrenquencies,
  errorInvalidOpenFrenquencies,
} from '../stores/notificationMessages'
import {
  isMetaFrenquencyResponseObject,
  isNumericFrenquencyResponseObject,
  isOpenFrenquencyResponseObject,
} from '../utilities'

/**
 * General handler for every type of frequency data fetches. Handles the request based on the module's type.
 * Note that many of the subroutines utilize function closures.
 *
 * @param module Frequency module
 * @param query query that contains top-level stuff such as group module filters
 * @returns the requested data
 */
function handleFrequencyModuleDataFetch(
  module: Dashboard.FrequencyModule,
  query: Query.Query2Payload,
): Promise<Response.FrequenciesNumeric | Response.FrequenciesTextual> {
  if (isNumeric(module)) {
    return handleFetchNumericFrequencies(
      module as Dashboard.FrequencyNumericModule,
      query as Query.Numeric,
    ).then((res: Response.FrequenciesNumeric[]) => concatNumericKpiSeries(res))
  }

  if (isOpen(module)) {
    return handleFetchOpenFrequencies(
      module as Dashboard.FrequencyOpenModule,
      query as Query.Textual,
    ).then((res: Response.FrequenciesTextual[]) => concatTextualKpiSeries(res))
  }

  if (isMeta(module)) {
    return handleFetchMetaFrequencies(module, query).then((res: Response.FrequenciesTextual[]) =>
      concatTextualKpiSeries(res),
    )
  }
  throw new Error('Invalid frequency module type.')
}

/**
 * Handles numeric KPI frequencies specifically.
 * Takes into account "comparison queries" and does an extra fetch for each of them.
 */
function handleFetchNumericFrequencies(
  module: Dashboard.FrequencyNumericModule,
  query: Query.Numeric,
): Promise<Response.FrequenciesNumeric[]> {
  const allQueries: Query.Numeric[] = [query]
  if (hasComparisonMode(module)) {
    Object.values(module.comparison_queries).forEach((cq) =>
      allQueries.push(overwriteWithPartialQuery(query, cq) as Query.Numeric),
    )
  }
  return Promise.all(allQueries.map((q) => fetchNumericFrequencies(q)))
}

/**
 * Handles open KPI frequencies specifically.
 * Takes into account "comparison queries" and does an extra fetch for each of them.
 * Is also able to handle the special case where the "count" info is fetched.
 */
function handleFetchOpenFrequencies(module: Dashboard.FrequencyOpenModule, query: Query.Textual) {
  const allQueries: Query.Textual[] = [query]
  if (hasComparisonMode(module)) {
    Object.values(module.comparison_queries).forEach((cq) =>
      allQueries.push(overwriteWithPartialQuery(query, cq) as Query.Textual),
    )
  }
  const isCountNeeded = isInRespondentPercentageMode(module)
  return Promise.all(allQueries.map((q) => fetchOpenFrequencies(q, isCountNeeded)))
}

/**
 * Handles metadata frequencies.
 * Takes into account "comparison queries" and does an extra fetch for each of them.
 */
function handleFetchMetaFrequencies(module: Dashboard.FrequencyModule, query: Query.Query2Payload) {
  const allQueries: Query.Query2Payload[] = [query]
  if (hasComparisonMode(module)) {
    Object.values(module.comparison_queries).forEach((cq) =>
      allQueries.push(overwriteWithPartialQuery(query, cq)),
    )
  }
  return Promise.all(allQueries.map((q) => fetchMetaFrequencies(q)))
}

/**
 * Fetch numeric freqs
 */
function fetchNumericFrequencies(query: Query.Numeric): Promise<Response.FrequenciesNumeric> {
  return post('POST_NUMERIC_FREQS', query).then((res: Response.FrequenciesNumeric) =>
    formatNumericFreqDataSeries(res),
  )
}

/**
 * For open KPI frequencies.
 * In some cases, you need to fetch the count separately.
 */
function fetchOpenFrequencies(
  query: Query.Query2Payload,
  hasCount: boolean,
): Promise<Response.FrequenciesNumeric> {
  const maybeCount = handeFetchFrequenciesCount(query, hasCount)

  return maybeCount.then((countResponse) => {
    return post('POST_TEXTUAL_FREQS', query).then((res: Response.FrequenciesTextual) => {
      mergeCountToFrequencySeries(res, countResponse)
      return res
    })
  })

  function mergeCountToFrequencySeries(
    res: Response.FrequenciesTextual,
    countResponse: Response.FrequenciesCount | null,
  ) {
    if (!isNil(countResponse) && !isNil(countResponse.count)) {
      res.series.forEach((series) => {
        series.total = countResponse.count
      })
    }
  }
}

/**
 * Fetch for metadata freqs.
 * TAKE NOTE that endpoint has a but that it returns 500 if it can't find the grouping from the data.
 */
function fetchMetaFrequencies(query: Query.Query2Payload): Promise<Response.FrequenciesNumeric> {
  return post('POST_META_FREQS', query).then((res: Response.FrequenciesTextual) => res)
}

/**
 * Fetch the count for open KPIs.
 * This is needed because the textual frequency endpoint is limited to returning some maximum number of items.
 */
function handeFetchFrequenciesCount(
  query: Query.Query2Payload,
  isNeeded: boolean,
): Promise<Response.FrequenciesCount | null> {
  return new Promise((resolve) => {
    if (isNeeded) {
      resolve(fetchFrequenciesCount(query))
    } else {
      resolve(null)
    }
  })

  function fetchFrequenciesCount(query: Query.Query2Payload): Promise<Response.FrequenciesCount> {
    return post('GET_TEXTUAL_ANSWER_COUNT', query)
  }
}

function isNumeric(module: Dashboard.FrequencyModule): boolean {
  return module.queryType === 'numeric'
}

function isOpen(module: Dashboard.FrequencyModule): boolean {
  return module.queryType === 'open'
}

function isMeta(module: Dashboard.FrequencyModule): boolean {
  return module.queryType === 'meta'
}

function isInRespondentPercentageMode(module: Dashboard.FrequencyModule): boolean {
  return module?.options?.freq_format === 'percentage_respondent'
}

function hasComparisonMode(module: Dashboard.FrequencyModule): boolean {
  return !isNil(module.comparison_queries) && module.chartType === 'column'
}

/**
 * The way the numeric frequency endpoint returns series data is not optimal for the chart.
 * Example: a numeric answer with value 10 is not X = 10.
 * Rather, it is probably X = 0, 1 or 2. This is because alphabetically 10 is at the beginning
 * of the separate "categories" list given by the endpoint.
 *
 * This is a workaround for that. So with the example case above, numeric answer 10 is mapped to X = 10
 * instead of being X = 0 because the value "10" is the first item in the categories.
 */
function formatNumericFreqDataSeries(data: Response.FrequenciesNumeric) {
  try {
    return mapCategoryLabelsToAxisPoints()
  } catch (e) {
    console.error(
      'Was not able to format numeric data set for freq module; fallback to using the API result format',
    )
    console.error(e)
    return data
  }

  function mapCategoryLabelsToAxisPoints(): Response.FrequenciesNumeric {
    const resultData = cloneDeep(data)
    resultData.series.forEach((series: Data.FrequenciesSeriesSingular) => {
      resultData.categories.forEach((cat: string, i: number) => {
        series.data[i].x = parseInt(cat)
      })
    })

    resultData.categories = []
    return resultData
  }
}

const getMetaFrequencies = (query: Query.Query2Payload): Promise<Response.FrequenciesNumeric> => {
  try {
    return post('POST_META_FREQS', query)
      .then((res: unknown) => {
        if (isMetaFrenquencyResponseObject(res)) {
          return res
        } else {
          throw Error(errorInvalidMetaFrenquencies)
        }
      })
      .catch(() => Promise.reject(errorGettingMetaFrenquencies))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingMetaFrenquencies)
  }
}

const getNumericFrequencies = (
  query: Query.SingleNumeric,
): Promise<Response.FrequenciesNumeric> => {
  try {
    return post('POST_NUMERIC_FREQS', query)
      .then((res: unknown) => {
        if (isNumericFrenquencyResponseObject(res)) {
          return res
        } else {
          throw Error(errorInvalidNumericFrenquencies)
        }
      })
      .catch(() => Promise.reject(errorGettingNumericFrenquencies))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingNumericFrenquencies)
  }
}

const getOpenFrequencies = (query: Query.Textual): Promise<Response.FrequenciesTextual> => {
  try {
    return post('POST_TEXTUAL_FREQS', query)
      .then((res: unknown) => {
        if (isOpenFrenquencyResponseObject(res)) {
          return res
        } else {
          throw Error(errorInvalidOpenFrenquencies)
        }
      })
      .catch(() => Promise.reject(errorGettingOpenFrenquencies))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingOpenFrenquencies)
  }
}

export {
  handleFrequencyModuleDataFetch,
  fetchMetaFrequencies,
  getMetaFrequencies,
  getNumericFrequencies,
  getOpenFrequencies,
}
