import React, { memo, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import * as Highcharts from 'highcharts8'
import { cloneDeep, isEqual } from 'lodash'
import { createChart } from './columnChart'
import { ActionTypes, columnReducer } from './columnReducer'
import {
  FreqFormat,
  FrequencyComparisonType,
  FrequencyModule,
  FrequencyOrder,
  FrequencyQueryType,
  NumericKpiFormat,
} from '../frequencyModuleTypes'
import useFrequencyChartButtons from '../useFrequencyChartButtons'
import { getTenant } from '../../../../../react-services/authService'
import useCntrWidthObserver from '../../../../../utilities/hooks/useCntrWidthObserver'
import FadeIn from '../../_shared/FadeIn'
import { useUpdateEffect } from 'react-use'
import { CircularProgress } from '@mui/material'
import ChartButton from '../../_shared/ChartComponents/ChartButton'

import css from '../FrequencyModuleCntr.module.scss'
import { useTranslation } from 'react-i18next'
import { tData } from '../../../../../../languages/i18n'

type ColumnProps = {
  id: string
  isReportMode: boolean
  isScreenMode: boolean
  series: Highcharts.SeriesOptions[]
  chartOrder?: FrequencyOrder
  isFreqFormatLimitTarget: boolean
  limitValues: FrequencyModule['limitValues']
  dataPoints?: number
  isSoftMin: boolean
  isSoftMax: boolean
  chartFormat: FreqFormat | NumericKpiFormat | null
  comparisonType?: FrequencyComparisonType | null
  comparisonDays: number | undefined
  comparisonMeta: string | undefined
  queryType: FrequencyQueryType
  handleOptionsPropertySave: (properties: Partial<FrequencyModule['options']>) => void
  handleClickChartPoint: (e: Highcharts.PointClickEventObject) => void
  isLoading: boolean
  moduleCntrRef: React.MutableRefObject<HTMLDivElement | undefined>
}

const Column = memo(
  ({
    id,
    isReportMode,
    isScreenMode,
    series,
    chartOrder,
    isFreqFormatLimitTarget,
    limitValues,
    dataPoints,
    isSoftMin,
    isSoftMax,
    chartFormat,
    comparisonType,
    comparisonDays,
    comparisonMeta,
    queryType,
    handleOptionsPropertySave,
    handleClickChartPoint,
    isLoading,
    moduleCntrRef,
  }: ColumnProps) => {
    const { cntrWidth: moduleWidth } = useCntrWidthObserver(moduleCntrRef)
    const isReportOrScreen = isReportMode || isScreenMode
    const noTimeout = isReportOrScreen || getTenant() === 'test_frontend'
    const CHART_RENDER_TIMEOUT = noTimeout ? 0 : 500
    const LOADING_TIMEOUT = noTimeout ? 0 : 500
    const labelRenderTimeout = useRef<NodeJS.Timeout>()
    const chartUpdateTimeoutRef = useRef<NodeJS.Timeout>()
    const chartComponentRef = useRef<HighchartsReactRefObject>(null)
    const chartCntrRef = useRef<HTMLDivElement | null>(null)
    const { t, i18n } = useTranslation()
    const initOptionsState = useMemo(
      () => createChart(isReportOrScreen, handleClickChartPoint, labelRenderTimeout),
      [],
    )
    const [chartOptions, setChartOptions] = useReducer(columnReducer, initOptionsState)
    const [chartCopy, setChartCopy] = useState<Highcharts.Options | null>(null)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

    const { handleDataFormatChange, handleComparisonModeChange } = useFrequencyChartButtons(
      handleOptionsPropertySave,
      queryType,
      comparisonMeta,
    )

    const loadingTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
      if (loadingTimeoutRef.current) clearTimeout(loadingTimeoutRef.current)
      if (isLoading) {
        setIsLoadingData(isLoading)
      } else {
        loadingTimeoutRef.current = setTimeout(() => {
          setIsLoadingData(isLoading)
        }, LOADING_TIMEOUT)
      }
      return () => {
        if (loadingTimeoutRef.current) clearTimeout(loadingTimeoutRef.current)
        if (labelRenderTimeout.current) clearTimeout(labelRenderTimeout.current)
      }
    }, [isLoading])

    const reflowTimeoutRef = useRef<NodeJS.Timeout>()
    useUpdateEffect(() => {
      if (isReportOrScreen) return
      if (reflowTimeoutRef.current) clearTimeout(reflowTimeoutRef.current)
      reflowTimeoutRef.current = setTimeout(() => {
        if (chartComponentRef.current) {
          chartComponentRef.current.chart.reflow()
        }
      }, 500)
      return () => reflowTimeoutRef.current && clearTimeout(reflowTimeoutRef.current)
    }, [moduleWidth])

    useEffect(() => {
      if (chartUpdateTimeoutRef.current) clearTimeout(chartUpdateTimeoutRef.current)
      if (!isEqual(chartOptions, chartCopy) && !isLoadingData) {
        chartUpdateTimeoutRef.current = setTimeout(() => {
          setChartCopy(chartOptions)
        }, CHART_RENDER_TIMEOUT)
      }
      return () => chartUpdateTimeoutRef.current && clearTimeout(chartUpdateTimeoutRef.current)
    }, [chartOptions, isLoadingData])

    useUpdateEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_LANGUAGE,
      })
    }, [i18n.language])

    useEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_DATA,
        data: {
          series: series as Highcharts.SeriesColumnOptions[],
        },
      })
    }, [series])

    useEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_ORDER,
        data: {
          chartOrder,
        },
      })
    }, [chartOrder, series])

    useEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_DATA_FORMAT,
        data: {
          chartFormat,
        },
      })
    }, [chartFormat, series])

    useEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_DATA_POINTS,
        data: {
          dataPoints,
        },
      })
    }, [series, dataPoints])

    useEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_LIMITS,
        data: {
          limitValues: isFreqFormatLimitTarget ? limitValues || {} : {},
          isSoftMin: !!isSoftMin,
          isSoftMax: !!isSoftMax,
        },
      })
    }, [
      series,
      limitValues?.max,
      limitValues?.min,
      isFreqFormatLimitTarget,
      isSoftMin,
      isSoftMax,
      chartFormat,
    ])

    const chart = useMemo(() => {
      if (chartCopy) {
        return (
          <HighchartsReact
            key={id}
            highcharts={Highcharts}
            options={cloneDeep(chartCopy)}
            containerProps={{ id: 'freq-container', style: { height: 'inherit' } }}
            ref={chartComponentRef}
            immutable // NEEDED TO AVOID CRASH ON CHART UPDATE
          />
        )
      }
      return null
    }, [chartCopy])

    const getComparisonText = () => {
      if (comparisonType === FrequencyComparisonType.DATE)
        return `${t('common.label.days')}: ${comparisonDays}`
      if (comparisonType === FrequencyComparisonType.META)
        return `${t('common.button.comparisonMeta')}: ${tData(comparisonMeta || '')}`
      return t('common.button.comparisonOff')
    }

    return (
      <div
        style={{ height: 'inherit' }}
        ref={(newRef) => {
          if (newRef) chartCntrRef.current = newRef
        }}
      >
        {chart && !isReportMode && !isScreenMode ? (
          <div className={css.actionBar}>
            {chartFormat && (
              <ChartButton
                text={t('common.data.' + chartFormat.toLocaleLowerCase())}
                onClick={() => handleDataFormatChange(chartFormat)}
              />
            )}
            {moduleWidth && moduleWidth > 350 && (
              <ChartButton
                text={getComparisonText()}
                onClick={() => handleComparisonModeChange(comparisonType)}
              />
            )}
          </div>
        ) : (
          <div></div>
        )}
        {isLoading && (
          <div className={css.loadingRefetch}>
            <CircularProgress thickness={2} size={'1.7rem'} style={{ opacity: 0.6 }} />
          </div>
        )}
        {chart ? <FadeIn durationMs={2000}>{chart}</FadeIn> : <div></div>}
      </div>
    )
  },
)

Column.displayName = 'Column'

export default Column
