import React from 'react'

type SaveTextProps = {
  text: string
}
const SaveText = ({ text }: SaveTextProps) => {
  return (
    <div style={{ fontSize: '12px', padding: '4px', color: '#fff', textTransform: 'uppercase' }}>
      {text}
    </div>
  )
}

export default SaveText
