import React, { useEffect, useRef, useState } from 'react'
import DoubleModalMain from '../../../../../_shared/Modals/DoubleModalMain'
import BreakPointCard from './BreakPointCard'
import BreakPointCardAddNewCard from './BreakPointAddNewCard'
import BreakPointAddNewForm from './BreakPointAddNewForm'
import BreakPointModalHeading from './BreakPointModalHeading'
import { AddOrRemove, Breakpoint } from '../../pietabularTypes'
import useCommonDbSettingsConfig from '../../../../../../stores/useCommonDbSettingsConfig'
import { errorLoadingBreakpointsFromConfig } from '../../PietabularNotifications'
import LoadingIndicator from '../../../../../_shared/Infos/LoadingIndicator'
import { useToastId } from '../../../../../common/Notification/NotificationContext'
import { toast } from 'react-toastify'
import { hasRole } from '../../../../../../react-services/authService'

import css from './BreakPointModal.module.scss'

export enum FormMountState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CLOSING = 'CLOSING',
}
type BreakPointFormModalProps = {
  onClose: () => void
  handleAddOrRemoveBreakpoint: (breakpoint: Breakpoint, action: AddOrRemove) => void
  breakpoints: Breakpoint[] | null
}

const BreakPointModal = ({
  onClose,
  handleAddOrRemoveBreakpoint,
  breakpoints,
}: BreakPointFormModalProps) => {
  const timeOutRef = useRef<NodeJS.Timeout>()
  const [formMountState, setFormMountState] = useState<FormMountState>(FormMountState.CLOSED)
  const [activeBreakpoint, setActiveBreakpoint] = useState<Breakpoint | null>(null)
  const [usableBreakpoints, setUsableBreakpoints] = useState<Breakpoint[]>([])
  const { toastifyId } = useToastId()
  const { isLoading: loading, error, config, refetchConfig } = useCommonDbSettingsConfig()
  const selectedBreakpoints = breakpoints || []

  useEffect(() => {
    if (formMountState === FormMountState.CLOSED) {
      setActiveBreakpoint(null)
    }
    if (formMountState !== FormMountState.CLOSING) return
    if (timeOutRef.current) clearTimeout(timeOutRef.current)

    timeOutRef.current = setTimeout(() => {
      setFormMountState(FormMountState.CLOSED)
    }, 950)
    return () => timeOutRef.current && clearTimeout(timeOutRef.current)
  }, [formMountState])

  useEffect(() => {
    if (config?.breakpoints) {
      const configBreakpoints = [...config.breakpoints]
      configBreakpoints.sort(sortByDatePredicate).sort(sortBySelelectedPredicate)
      setUsableBreakpoints(configBreakpoints)
    }
  }, [config?.breakpoints])

  useEffect(() => {
    if (error) toast.error(errorLoadingBreakpointsFromConfig, { containerId: toastifyId })
  }, [error])

  const handleCardClick = (breakpoint: Breakpoint | null = null) => {
    if (!hasRole('admin')) return
    setFormMountState(FormMountState.OPEN)
    setActiveBreakpoint(breakpoint)
  }

  const handleFormClose = () => {
    refetchConfig()
    setFormMountState(FormMountState.CLOSING)
  }

  const sortBySelelectedPredicate = (a: Breakpoint, b: Breakpoint) => {
    const selectedIds = selectedBreakpoints.map((bp) => bp.id)
    const hasA = selectedIds.includes(a.id)
    const hasB = selectedIds.includes(b.id)
    if (!hasA && hasB) return 1
    if (hasA && !hasB) return -1
    return 0
  }

  const sortByDatePredicate = (a: Breakpoint, b: Breakpoint) => {
    const aDate = a.date
    const bDate = b.date
    if (aDate > bDate) return -1
    if (aDate < bDate) return 1
    return 0
  }

  return (
    <div
      className={`${css.cntr} ${formMountState === FormMountState.OPEN ? css.wide_cntr : ''} ${
        formMountState === FormMountState.CLOSING ? css.normal_cntr : ''
      }`}
    >
      <DoubleModalMain
        closeOnEsc={formMountState === FormMountState.OPEN ? false : true}
        animation='fade'
        duration={300}
        visible={true}
        onClose={onClose}
        double={false}
        showMask={true}
      >
        <div>
          <BreakPointModalHeading
            formMountState={formMountState}
            hasActiveBp={!!activeBreakpoint}
          />
          <div className={css.list_and_form_cntr}>
            <div className={css.card_list}>
              {hasRole('admin') && (
                <BreakPointCardAddNewCard
                  onClick={() => handleCardClick()}
                  active={!!(formMountState === FormMountState.OPEN && !activeBreakpoint)}
                />
              )}
              {usableBreakpoints.map((bp) => (
                <BreakPointCard
                  selected={selectedBreakpoints.map((selectedBp) => selectedBp.id).includes(bp.id)}
                  key={bp.id}
                  breakpoint={bp}
                  onClick={() => handleCardClick(bp)}
                  handleAddOrRemoveBreakpoint={handleAddOrRemoveBreakpoint}
                  active={
                    !!(formMountState === FormMountState.OPEN && activeBreakpoint?.id === bp.id)
                  }
                />
              ))}
              {loading && <LoadingIndicator />}
            </div>
            {formMountState !== FormMountState.CLOSED && (
              <BreakPointAddNewForm
                formState={formMountState}
                selected={
                  !!(
                    activeBreakpoint &&
                    selectedBreakpoints
                      .map((selectedBp) => selectedBp.id)
                      .includes(activeBreakpoint.id)
                  )
                }
                breakpoint={activeBreakpoint}
                onClose={handleFormClose}
                handleAddOrRemoveBreakpoint={handleAddOrRemoveBreakpoint}
              />
            )}
          </div>
        </div>
      </DoubleModalMain>
    </div>
  )
}

export default BreakPointModal
