import { cloneDeep, isEqual } from 'lodash'
import { GroupModule, Module, ModuleType } from '../groupModuleTypes'
import { Dashboard } from '../../../../../../types'
import { v4 as uuid } from 'uuid'
import { initModuleConfig } from './initModuleConfig'

export enum ActionTypes {
  SAVE = 'save',
  ADD = 'add',
  REMOVE = 'remove',
  MOVE_UP = 'move_up',
  MOVE_DOWN = 'move_down',
}

export type SaveModule = {
  type: ActionTypes.SAVE
  data: {
    module: Module
  }
}

export type AddModule = {
  type: ActionTypes.ADD
  data: {
    newModule: Dashboard.PickableModule
    parentGroupId: string
  }
}

export type RemoveModule = {
  type: ActionTypes.REMOVE
  data: {
    module: Module
  }
}

export type MoveModuleUp = {
  type: ActionTypes.MOVE_UP
  data: {
    module: Module
  }
}

export type MoveModuleDown = {
  type: ActionTypes.MOVE_DOWN
  data: {
    module: Module
  }
}

type ReducerAction = SaveModule | AddModule | MoveModuleUp | MoveModuleDown | RemoveModule

export const rootReducer = (state: GroupModule, action: ReducerAction) => {
  switch (action.type) {
    case ActionTypes.SAVE: {
      try {
        let newState = cloneDeep(state)
        const newModule = action.data.module
        const moduleId = newModule.id
        if (!moduleId) return state
        newState = handleFindingAndSavingModule(newState, newModule)
        if (isEqual(newState, state)) return state
        return newState
      } catch (error) {
        console.error(error)
        return state
      }
    }

    case ActionTypes.ADD: {
      try {
        let newState = cloneDeep(state)
        const { newModule, parentGroupId } = action.data
        newState = handleAddModule(newState, newModule, parentGroupId)
        if (isEqual(newState, state)) return state
        return newState
      } catch (error) {
        console.error(error)
        return state
      }
    }

    case ActionTypes.MOVE_UP: {
      try {
        const { module } = action.data
        const newState = handleMoveModuleUp(state, module)
        return newState
      } catch (error) {
        console.error(error)
        return state
      }
    }

    case ActionTypes.MOVE_DOWN: {
      try {
        const { module } = action.data
        const newState = handleMoveModuleDown(state, module)
        return newState
      } catch (error) {
        console.error(error)
        return state
      }
    }

    case ActionTypes.REMOVE: {
      try {
        const { module } = action.data
        const newState = handleRemovingModule(state, module)
        return newState
      } catch (error) {
        console.error(error)
        return state
      }
    }

    default:
      return state
  }
}

const handleFindingAndSavingModule = (group: GroupModule, newModule: Module) => {
  if (!group.id) {
    console.error('No group root id found')
    return group
  }
  if (!newModule.id) {
    console.error('No module id found in saving module')
    return group
  }
  if (group.id === newModule.id && (newModule.type === 'group' || newModule.type === 'group2'))
    return newModule
  if (!group.modules) return group
  const newModules = group.modules.map((m) => {
    if (m.id === newModule.id) return newModule
    if (m.type === 'group') {
      return handleFindingAndSavingModule(m, newModule)
    }
    return m
  })
  const newGroup = cloneDeep(group)
  newGroup.modules = newModules
  return newGroup
}

export const findParentModuleWithinRootGroup = (
  group: GroupModule,
  id: string,
): GroupModule | null => {
  const { modules } = group
  let foundGroup: GroupModule | null = null
  if (modules) findParentModuleWithinModulesArray(modules, id, group)

  function findParentModuleWithinModulesArray(
    modules: Module[],
    id: string,
    parentGroup: GroupModule,
  ) {
    for (const m of modules) {
      if (m.id === id) foundGroup = parentGroup
      if (m.type === 'group' || m.type === 'group2') {
        const modules = m.modules
        if (modules) findParentModuleWithinModulesArray(modules, id, m)
      }
    }
  }
  return foundGroup
}

export const findModuleWithinRootGroup = (group: GroupModule, id: string) => {
  let foundModule: Module | null = null
  if (group.id === id) foundModule = group
  const modules = group.modules
  if (modules) findModuleWithinModulesArray(modules, id)

  function findModuleWithinModulesArray(modules: Module[], id: string) {
    for (const m of modules) {
      if (m.id === id) return (foundModule = m)
      if (m.type === 'group' || m.type === 'group2') {
        const modules = m.modules
        if (modules) findModuleWithinModulesArray(modules, id)
      }
    }
  }
  return foundModule
}

const handleAddModule = (root: GroupModule, m: Dashboard.PickableModule, parentGroupId: string) => {
  // checked at context function
  const checkedModule = { ...m, type: m.type as ModuleType }
  const newModuleId = uuid()
  const moduleDefaultConfig = initModuleConfig[m.type]
  const newModule = moduleDefaultConfig || ({} as Module)
  newModule.id = newModuleId
  const newType = checkedModule.type
  const newWidth = newType === ModuleType.NUMBERTREND || newType === ModuleType.PICTURE ? '3' : '6'
  newModule.options = { ...(newModule.options || {}), modulewidth: newWidth }
  newModule.type = newType
  const parentGroup = findModuleWithinRootGroup(root, parentGroupId)
  if (!parentGroup || parentGroup.type !== ModuleType.GROUP) return root
  const newParentGroup = cloneDeep(parentGroup)
  const currentModules = newParentGroup.modules || ([] as Module[])
  newParentGroup.modules = currentModules.concat(newModule)
  const newRoot = handleFindingAndSavingModule(root, newParentGroup)
  return newRoot
}

const handleMoveModuleUp = (root: GroupModule, module: Module) => {
  if (!module.id || root.id === module.id) return root
  const parentGroup = findParentModuleWithinRootGroup(root, module.id)
  const newParentGroup = cloneDeep(parentGroup)
  if (!newParentGroup || !newParentGroup.modules) return root
  const newModules = newParentGroup.modules
  const currentIndex = newModules.findIndex((m) => m.id === module.id)
  if (currentIndex === -1) return root
  const newIndex = currentIndex === 0 ? newModules.length - 1 : currentIndex - 1
  if (newIndex === newModules.length - 1) {
    newModules.shift()
    newModules.push(module)
  } else {
    const replacedModule = newModules[newIndex]
    newModules[newIndex] = module
    newModules[currentIndex] = replacedModule
  }
  newParentGroup.modules = newModules
  const newState = cloneDeep(root)
  const newRoot = handleFindingAndSavingModule(newState, newParentGroup)
  return newRoot
}

const handleMoveModuleDown = (root: GroupModule, module: Module) => {
  if (!module.id) return root
  const parentGroup = findParentModuleWithinRootGroup(root, module.id)
  const newParentGroup = cloneDeep(parentGroup)
  if (!newParentGroup || !newParentGroup.modules) return root
  const newModules = newParentGroup.modules
  const currentIndex = newModules.findIndex((m) => m.id === module.id)
  if (currentIndex === -1) return root
  const newIndex = currentIndex === newModules.length - 1 ? 0 : currentIndex + 1
  if (newIndex === 0) {
    newModules.pop()
    newModules.unshift(module)
  } else {
    const replacedModule = newModules[newIndex]
    newModules[newIndex] = module
    newModules[currentIndex] = replacedModule
  }
  newParentGroup.modules = newModules
  const newState = cloneDeep(root)
  const newRoot = handleFindingAndSavingModule(newState, newParentGroup)
  return newRoot
}

const handleRemovingModule = (root: GroupModule, module: Module) => {
  if (!module.id) return root
  if (root.id === module.id) return root
  const parentGroup = findParentModuleWithinRootGroup(root, module.id)
  if (!parentGroup || !parentGroup.modules) return root
  const newModules = parentGroup.modules.filter((m) => m.id !== module.id)
  const newParentGroup = cloneDeep(parentGroup)
  newParentGroup.modules = newModules
  const newState = handleFindingAndSavingModule(root, newParentGroup)
  return newState
}
