import React, { memo, useEffect, useRef, useState } from 'react'
import CustomTooltip from '../../../../_shared/Infos/CustomTooltip'
import { usePieTabularSettings } from '../PietabularModuleContext'
import { Counts, GroupedDataWithCounts } from '../pietabularTypes'
import css from './Tabular.module.scss'
import { isEqual } from 'lodash'

type CountedRowCellProps = {
  countedValue: string
  totalSliceCounts: Counts | null
  handleRowClick: (metaKey: string, metaValue: string, countKey: string) => void
  groupedAndCountedData: GroupedDataWithCounts
  metaKey: string
  group: string
  children: number
}

const CountedRowCellUnMemoized = ({
  countedValue,
  totalSliceCounts,
  handleRowClick,
  groupedAndCountedData,
  metaKey,
  group,
  children,
}: CountedRowCellProps) => {
  const ratioTimeoutRef = useRef<NodeJS.Timeout>()
  const {
    settings: { tolerance: toleranceString, showTooltip },
  } = usePieTabularSettings()[0]
  const [active, setActive] = useState(false)
  const [ratio, setRatio] = useState('')
  const [calculation, setCalculation] = useState('')
  let tolerance = 0
  if (!isNaN(Number(toleranceString))) tolerance = Number(toleranceString)

  useEffect(() => {
    if (active && ratio) {
      setCalculation(createCalculationInfo())
    } else {
      setCalculation('')
    }
  }, [active, ratio])

  useEffect(() => {
    if (ratioTimeoutRef.current) clearTimeout(ratioTimeoutRef.current)
    ratioTimeoutRef.current = setTimeout(() => {
      setRatio(getSentimentRatio(totalSliceCounts, groupedAndCountedData[metaKey], countedValue))
    }, 200)
    return () => ratioTimeoutRef.current && clearTimeout(ratioTimeoutRef.current)
  }, [totalSliceCounts, groupedAndCountedData, metaKey, countedValue, tolerance])

  const createCalculationInfo = () => {
    if (totalSliceCounts) {
      const totalValues = totalSliceCounts[countedValue]
      const totalAll = totalSliceCounts['n']
      const totalAvg = totalValues / totalAll
      const groupValues = groupedAndCountedData[metaKey][countedValue]
      const groupAll = groupedAndCountedData[metaKey]['n']
      const groupAvg = groupValues / groupAll
      if (tolerance === null || tolerance === undefined) return ''
      return (
        ` There are more ${countedValue.toLocaleLowerCase()}s than on average.\n` +
        ` Threshold for notifying is ${((totalAvg + tolerance / 100) * 100).toFixed(1)}%\n` +
        ` "${metaKey}" has ${(groupAvg * 100).toFixed(1)}% ${countedValue.toLocaleLowerCase()}s`
      )
    }
    return ''
  }

  const getSentimentRatio = (
    totalCountedSentiments: Counts | null,
    sentiment: Counts,
    countedValue: string,
  ) => {
    if (!totalCountedSentiments || (countedValue !== 'Negative' && countedValue !== 'Positive')) {
      return ''
    }

    const totalNegativeRatio = totalCountedSentiments['Negative'] / totalCountedSentiments['n']
    const totalPositiveRatio = totalCountedSentiments['Positive'] / totalCountedSentiments['n']

    if (
      !Object.keys(sentiment).includes('Positive') ||
      !Object.keys(sentiment).includes('Negative')
    ) {
      return ''
    }
    if (isNaN(sentiment['Positive']) || isNaN(sentiment['Negative'])) {
      return ''
    }
    if (
      countedValue === 'Positive' &&
      Number(sentiment['Positive']) / Number(sentiment['n']) >
        totalPositiveRatio + (tolerance || 0) / 100
    ) {
      return css.good_ratio
    }
    if (
      countedValue === 'Negative' &&
      Number(sentiment['Negative']) / Number(sentiment['n']) >
        totalNegativeRatio + (tolerance || 0) / 100
    ) {
      return css.bad_ratio
    }
    return ''
  }

  return (
    <>
      {ratio && active && showTooltip === true ? (
        <td
          data-testid={countedValue}
          colSpan={1}
          className={`${css.count_td} ${ratio}`}
          onClick={() => handleRowClick(group, metaKey, countedValue)}
          onMouseOver={() => setTimeout(() => setActive(true), 100)}
          onMouseOut={() => setTimeout(() => setActive(false), 100)}
        >
          {children}
          <div style={{ height: 0, position: 'relative', top: '-18px', pointerEvents: 'none' }}>
            <CustomTooltip content={calculation} direction={'left'} />
          </div>
        </td>
      ) : (
        <td
          data-testid={countedValue}
          colSpan={1}
          className={`${css.count_td} ${ratio}`}
          onClick={() => handleRowClick(group, metaKey, countedValue)}
          onMouseOver={() => setTimeout(() => setActive(true), 100)}
          onMouseOut={() => setTimeout(() => setActive(false), 100)}
        >
          {children}
        </td>
      )}
    </>
  )
}

function arePropsEqual(prevProps: CountedRowCellProps, newProps: CountedRowCellProps) {
  return isEqual(prevProps, newProps)
}
const CountedRowCell = memo(CountedRowCellUnMemoized, arePropsEqual)
CountedRowCell.displayName = 'CountedRowCell'

export default CountedRowCell
