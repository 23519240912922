import React, { memo, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts8'
import { ActionTypes, ChartTypes, frequencyChartReducer, TopicPlusCount } from './pieReducer'
import { PietabularSettingsProviderProps, usePieTabularSettings } from '../PietabularModuleContext'
import NoData from '../../../../_shared/Infos/NoData'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import { cloneDeep } from 'lodash'
import { createChart } from './pieChart'
import { getTenant } from '../../../../../react-services/authService'
import ErrorBoundary from '../../../../_shared/Infos/ErrorBoundary'
import { errorLoadingInspector } from '../PietabularNotifications'
import OpenInspectorCntrWrapped from '../../_shared/ValueInspector/Open/OpenInspectorCntr'
import { allChartColors } from '../../../../../../styles/variableExport'
import { pSBC } from '../../../../../react-services/colorService'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import { useUpdateEffect } from 'react-use'
import FadeIn from '../../_shared/FadeIn'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { tCategory } from '../../../../../../languages/i18n'

import css from './Pie.module.scss'

type PieProps = PieContainerProps & PietabularSettingsProviderProps

const Pie = memo(
  ({
    isRefetchingPie,
    chartType,
    limitedTopicPlusCount,
    query,
    isReportMode,
    isScreenMode,
    saveSettingsProperty,
    settings,
    newFilteredTopics,
  }: PieProps) => {
    const { i18n } = useTranslation()
    const timeoutRef = useRef<NodeJS.Timeout>()
    const initOptionsState = createChart(
      isReportMode,
      isScreenMode,
      handleRightClickSlice,
      handleChartSliceClick,
    )
    const [isInspectorModalOpen, setIsInspectorModalOpen] = useState<boolean>(false)
    const [rightClickedTopic, setRightClickedTopic] = useState<string>('')
    const chartRef = useRef<HighchartsReact.RefObject>(null)
    const [chartOptions, setChartOptions] = useReducer(frequencyChartReducer, initOptionsState)
    const [chartCopy, setChartCopy] = useState<Highcharts.Options | null>(null)
    const [reRender, setReRender] = useState<boolean>(false)
    const { answerCategories } = useOpenCategories()
    const allTopics = (answerCategories?.topic || ([] as (string | null)[])).concat(null)
    const CHART_RENDER_TIMEOUT = getTenant() === 'test_frontend' ? 0 : 800
    const chartUpdateTimeoutRef = useRef<NodeJS.Timeout>()
    const [selectedSlice, setSelectedSlice] = useState<string | null>(null)
    const isSavingRef = useRef<boolean>(false)

    useEffect(() => {
      if (chartUpdateTimeoutRef.current) clearTimeout(chartUpdateTimeoutRef.current)
      chartUpdateTimeoutRef.current = setTimeout(() => {
        setChartCopy(cloneDeep(chartOptions))
      }, CHART_RENDER_TIMEOUT)
      return () => chartUpdateTimeoutRef.current && clearTimeout(chartUpdateTimeoutRef.current)
    }, [chartOptions])

    useUpdateEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_LANGUAGE,
      })
    }, [i18n.language])

    const chart = useMemo(() => {
      if (!limitedTopicPlusCount) return
      if (chartCopy) {
        return (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartCopy}
            ref={chartRef}
            containerProps={{ id: 'pie-container', style: { height: 'inherit' } }}
          />
        )
      }
    }, [chartCopy])

    useEffect(() => {
      // Try to force a proper update
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      setReRender(true)
      timeoutRef.current = setTimeout(() => {
        setReRender(false)
        if (chartRef.current?.chart?.reflow) chartRef.current.chart.reflow()
      }, 400)
      return () => timeoutRef.current && clearTimeout(timeoutRef.current)
    }, [settings.modulewidth, settings.showPie, settings.showTabular, settings.showTrends])

    useEffect(() => {
      if (chartType) setChartOptions({ type: ActionTypes.SET_CHART_TYPE, chartType })
    }, [chartType])

    useEffect(() => {
      if (limitedTopicPlusCount && limitedTopicPlusCount.length > 0) {
        const newCounts = cloneDeep(limitedTopicPlusCount)
        const otherIndex = newCounts.findIndex((count) => count[0] === 'other')
        otherIndex > -1 && newCounts.push(newCounts.splice(otherIndex, 1)[0])
        setChartOptions({
          type: ActionTypes.SET_CHART_DATA,
          data: {
            pointOptions: newCounts.map<Highcharts.PointOptionsObject>((count) => {
              return {
                name: count[0],
                y: count[1],
                sliced: settings?.selectedPieTopic === count[0],
                color:
                  count[0] === 'other'
                    ? pSBC(0.3, allChartColors.GREY) || allChartColors.GREY
                    : undefined,
              }
            }),
            replaceTextForOther: settings?.pieOtherReplaceText,
            replaceTextForUncategorized: settings?.pieUncategorizedReplaceText,
          },
        })
      }
      if (!limitedTopicPlusCount || limitedTopicPlusCount.length === 0)
        setChartOptions({ type: ActionTypes.SET_CHART_DATA, data: { pointOptions: [] } })
    }, [
      limitedTopicPlusCount,
      settings?.selectedPieTopic,
      settings.pieOtherReplaceText,
      settings.pieUncategorizedReplaceText,
    ])

    useEffect(() => {
      if (rightClickedTopic === '') return
      setIsInspectorModalOpen(true)
    }, [rightClickedTopic])

    useUpdateEffect(() => {
      saveSettingsProperty({ selectedPieTopic: getRealTopicName(selectedSlice) })
    }, [selectedSlice])

    useEffect(() => {
      if (settings && settings.pieTitle)
        setChartOptions({ type: ActionTypes.SET_TITLE, data: settings.pieTitle })
    }, [settings.pieTitle])

    function handleChartSliceClick(e: Highcharts.PointClickEventObject) {
      if (isSavingRef.current) return
      isSavingRef.current = true
      if (e.point && e.point.name) {
        if (e.point.selected === false) {
          e.point.series.points.forEach((p) => (p.selected = false))
          e.point.select(true)
          setSelectedSlice(e.point.name)
        } else {
          e.point.series.points.forEach((p) => (p.selected = false))
          e.point.select(false)
          setSelectedSlice(null)
        }
        e.point.select(!e.point.selected)
      }
      setTimeout(() => (isSavingRef.current = false), 300)
    }

    const handleInspectorClosing = () => {
      setIsInspectorModalOpen(false)
      setRightClickedTopic('')
    }

    const getRealTopicName = (selectedTopic: string | null) => {
      let realTopicName = selectedTopic
      if (
        selectedTopic &&
        settings.pieOtherReplaceText &&
        selectedTopic === settings.pieOtherReplaceText
      ) {
        realTopicName = 'other'
      }
      if (
        selectedTopic &&
        settings.pieUncategorizedReplaceText &&
        selectedTopic === settings.pieUncategorizedReplaceText
      ) {
        realTopicName = 'uncategorized'
      }
      return realTopicName
    }

    const getRealTopicListValue = (selectedTopic: string): (string | null)[] => {
      let realTopicList = [selectedTopic] as (string | null)[]
      if (
        selectedTopic === 'other' ||
        (selectedTopic &&
          settings.pieOtherReplaceText &&
          selectedTopic === settings.pieOtherReplaceText)
      ) {
        realTopicList = allTopics.filter((t) => !newFilteredTopics?.includes(t))
      }
      if (
        selectedTopic === 'uncategorized' ||
        (selectedTopic &&
          settings.pieUncategorizedReplaceText &&
          selectedTopic === settings.pieUncategorizedReplaceText)
      ) {
        realTopicList = [null]
      }
      return realTopicList
    }

    function handleRightClickSlice(sliceName: string) {
      if (!sliceName || sliceName === rightClickedTopic) return
      setRightClickedTopic(sliceName)
    }

    if (reRender || !limitedTopicPlusCount)
      return (
        <div>
          <LoadingIndicator />
        </div>
      )
    if (!!limitedTopicPlusCount && limitedTopicPlusCount.length === 0)
      return (
        <div>
          <NoData />
        </div>
      )
    return (
      <FadeIn>
        {isInspectorModalOpen && !!query && (
          <ErrorBoundary
            message={errorLoadingInspector}
            fallback={<div style={{ color: 'red' }}>{errorLoadingInspector}</div>}
          >
            <OpenInspectorCntrWrapped
              inspected={`${tCategory('topic')}: ${tCategory(
                rightClickedTopic === null ? 'uncategorized' : rightClickedTopic,
              )}`}
              query={{
                ...query,
                category_grouping: {
                  topic: getRealTopicListValue(rightClickedTopic),
                },
              }}
              onClose={handleInspectorClosing}
            />
          </ErrorBoundary>
        )}
        {chart}
        {isRefetchingPie && (
          <div className={css.loadingRefetch}>
            <CircularProgress thickness={2} size={'1.7rem'} style={{ opacity: 0.6 }} />
          </div>
        )}
      </FadeIn>
    )
  },
)

Pie.displayName = 'Pie'

type PieContainerProps = {
  chartType: ChartTypes
  limitedTopicPlusCount: TopicPlusCount[] | null
  newFilteredTopics: (string | null)[] | null
  isRefetchingPie: boolean
}

const PiePropsContainer = (props: PieContainerProps) => {
  const data = usePieTabularSettings()[0]
  return <Pie {...props} {...data} />
}

export default PiePropsContainer
