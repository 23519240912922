import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  Button,
  TextareaAutosize as BaseTextareaAutosize,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Language } from '../../../../../languages/languages'
import {
  DynamicTranslationNameSpace,
  TranslationNameSpace,
  tCommon,
} from '../../../../../languages/i18n'
import useTranslationsConfig, { Translations } from '../../../../stores/useTranslationsConfig'
import useTranslatableLists from './useTranslatableLists'
import { allChartColors, primaryModalColor } from '../../../../../styles/variableExport'
import { pSBC } from '../../../../react-services/colorService'
import { cloneDeep, isEqual } from 'lodash'
import { useMountedState } from 'react-use'
import { toast } from 'react-toastify'

import css from './TranslationsTable.module.scss'

const nameSpaces = Object.values(TranslationNameSpace).filter(
  (ns) => ns !== TranslationNameSpace.COMMON,
)

type TranslationsTableProps = {
  language: Language
  selectedLanguage: '' | Language
  selectedNamespace: DynamicTranslationNameSpace
  tenantLanguages: Language[]
  namespace: DynamicTranslationNameSpace
  handleTenantLanguageChange: (event: SelectChangeEvent<Language>) => void
  handleNamespaceChange: (event: SelectChangeEvent<TranslationNameSpace>) => void
}

const TextareaAutosize = styled(BaseTextareaAutosize)({
  width: '100%',
  lineHeight: 1.5,
  padding: '8px 12px',
  borderRadius: '5px',
  color: allChartColors.GREY,
  border: `1px solid ${allChartColors.GREY}`,
})
const TranslationsTable = ({
  language,
  selectedLanguage,
  selectedNamespace,
  tenantLanguages,
  namespace,
  handleNamespaceChange,
  handleTenantLanguageChange,
}: TranslationsTableProps) => {
  const isMounted = useMountedState()
  const translatableList = useTranslatableLists({ namespace })

  const {
    config: translationsConfig,
    putTranslations,
    isLoading: isLoadingTranslations,
  } = useTranslationsConfig()
  const [searchTranslatable, setSearchTranslatable] = useState<string>('')
  const [searchTranslation, setSearchTranslation] = useState<string>('')
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true)
  const [modifiedTranslationsConfig, setModifiedTranslationsConfig] = useState<Translations | null>(
    null,
  )
  const [focusedInput, setFocusedInput] = useState<string>('')
  const currentLanguageConfig = useMemo(() => {
    if (!translationsConfig) return null
    if (modifiedTranslationsConfig) return modifiedTranslationsConfig[language]?.[namespace] || {}
    return translationsConfig[language]?.[namespace] || {}
  }, [translationsConfig, modifiedTranslationsConfig, language, namespace, isLoadingTranslations])

  const translatableListWithTranslations: Record<string, string>[] = useMemo(() => {
    if (!translatableList) return [] as Record<string, string>[]
    const newTranslatablesWithTranslations = [] as Record<string, string>[]
    translatableList.forEach((translatable) => {
      const translation = currentLanguageConfig?.[translatable] || ''
      newTranslatablesWithTranslations.push({ [translatable]: translation })
    })
    return newTranslatablesWithTranslations
  }, [translatableList, currentLanguageConfig])

  const filteredTranslatableListWithTranslations = useMemo(() => {
    if (!translatableListWithTranslations) return [] as Record<string, string>[]
    return translatableListWithTranslations.filter((translatable) => {
      let isPartOfList = true
      const translatableWithtranslation = Object.entries(translatable)[0]
      if (searchTranslatable) {
        const translatable = translatableWithtranslation[0]
        const searchRegex = new RegExp(searchTranslatable, 'i')
        isPartOfList = searchRegex.test(translatable)
      }
      if (isPartOfList && searchTranslation) {
        const translation = translatableWithtranslation[1]
        const searchRegex = new RegExp(searchTranslation, 'i')
        isPartOfList = searchRegex.test(translation)
      }
      return isPartOfList
    })
  }, [translatableListWithTranslations, searchTranslatable, searchTranslation])

  useEffect(() => {
    if (!modifiedTranslationsConfig) return setIsSaveDisabled(true)
    const hasUnsavedChanges = !isEqual(modifiedTranslationsConfig, translationsConfig)
    setIsSaveDisabled(!hasUnsavedChanges)
  }, [modifiedTranslationsConfig, translationsConfig])

  const handleChangeTranslation = (key: string, value: string) => {
    if (isLoadingTranslations) return
    const currentModifiedTranslationsConfig = modifiedTranslationsConfig || translationsConfig || {}
    const newTranslationsConfig: Translations = cloneDeep(currentModifiedTranslationsConfig)
    const currentNamespaceConfig = newTranslationsConfig[language]?.[namespace] || {}
    const newNamespaceConfig = { ...currentNamespaceConfig, [key]: value }
    const newLanguageConfig = {
      ...(newTranslationsConfig[language] || {}),
      [namespace]: newNamespaceConfig,
    }
    newTranslationsConfig[language] = newLanguageConfig
    setModifiedTranslationsConfig(newTranslationsConfig)
  }

  const handleSaveTranslations = () => {
    if (isLoadingTranslations || !modifiedTranslationsConfig) return
    setIsSaveDisabled(true)
    putTranslations({ value: modifiedTranslationsConfig })
      .then(() => {
        if (!isMounted()) return
        setModifiedTranslationsConfig(null)
        toast.success('Translations saved successfully')
      })
      .catch(() => {
        if (!isMounted()) return
        toast.error('Failed to save translations')
        setIsSaveDisabled(false)
      })
  }

  const handleClickReset = () => setModifiedTranslationsConfig(null)

  return (
    <Paper
      sx={{
        background: pSBC(0.95, allChartColors.GREY) || '#fff',
        padding: '.5em',
        marginTop: '1em',
      }}
      className={css.cntr}
    >
      <div className={css.actions}>
        <Button
          disabled={isSaveDisabled}
          onClick={handleSaveTranslations}
          variant={isSaveDisabled ? 'outlined' : 'contained'}
          sx={{ margin: '0 10px 10px 0' }}
        >
          {tCommon('button.save')}
        </Button>
        <Button
          disabled={isSaveDisabled}
          onClick={handleClickReset}
          variant={isSaveDisabled ? 'outlined' : 'contained'}
          sx={{ margin: '0 10px 10px 0' }}
        >
          {tCommon('button.reset')}
        </Button>
        <FormControl sx={{ width: '200px', marginRight: '1em', marginLeft: 'auto' }}>
          <InputLabel id='tenant-language-select-label'>
            {tCommon('label.tenantLanguage')}
          </InputLabel>
          <Select
            value={selectedLanguage}
            labelId='tenant-language-select-label'
            label={tCommon('label.tenantLanguage')}
            onChange={handleTenantLanguageChange}
            sx={{ padding: 0 }}
          >
            {tenantLanguages.map((lang) => (
              <MenuItem key={lang} value={lang}>
                {lang}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '200px', marginRight: '1em' }}>
          <InputLabel id='namespace-select-label'>{tCommon('label.namespace')}</InputLabel>
          <Select
            value={selectedNamespace}
            labelId='namespace-select-label'
            label={tCommon('label.namespace')}
            onChange={handleNamespaceChange}
            sx={{ padding: 0 }}
          >
            {nameSpaces.map((namespace) => (
              <MenuItem key={namespace} value={namespace}>
                {namespace}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TableContainer
        sx={{
          maxHeight: '68vh',
          overflowY: 'auto',
          border: `1px solid ${pSBC(0.55, allChartColors.GREY) || allChartColors.GREY}`,
          borderRadius: '5px',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: primaryModalColor,
                  color: '#fff',
                  fontWeight: 500,
                  fontSize: '1em',
                }}
              >
                {tCommon('label.stringKey')}
                <TextField
                  value={searchTranslatable}
                  onChange={(e) => setSearchTranslatable(e.target.value)}
                  sx={{
                    width: '180px',
                    padding: 0,
                    margin: '0 0 0 10px',
                    color: '#fff',
                    height: '26px',
                    fontSize: '13px',
                    border: '1px solid rgba(255, 255, 255, 0.5)',
                    borderRadius: '5px',
                  }}
                  placeholder={tCommon('label.searchTranslationKey')}
                  InputProps={{
                    sx: { color: '#fff', height: '26px', fontSize: '13px', border: 'none' },
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  background: primaryModalColor,
                  color: '#fff',
                  fontWeight: 500,
                  borderLeft: '1px solid #fff',
                  fontSize: '1em',
                }}
              >
                {tCommon('label.stringValue')} ({language})
                <TextField
                  value={searchTranslation}
                  onChange={(e) => setSearchTranslation(e.target.value)}
                  sx={{
                    width: '180px',
                    padding: 0,
                    margin: '0 0 0 10px',
                    color: '#fff',
                    height: '26px',
                    fontSize: '13px',
                    border: '1px solid rgba(255, 255, 255, 0.5)',
                    borderRadius: '5px',
                  }}
                  placeholder={tCommon('label.searchTranslationValue')}
                  InputProps={{
                    sx: { color: '#fff', height: '26px', fontSize: '13px', border: 'none' },
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!language &&
              !isLoadingTranslations &&
              filteredTranslatableListWithTranslations.map((translationObject) => {
                const translatableKey = Object.keys(translationObject)[0]
                const translationValue = Object.values(translationObject)[0]
                return (
                  <TableRow key={translatableKey}>
                    <TableCell
                      sx={{
                        color: pSBC(-0.2, allChartColors.GREY) || allChartColors.GREY,
                        padding: '3px 10px',
                        fontWeight: focusedInput === translatableKey ? 'bold' : 'normal',
                        ...(focusedInput === translatableKey
                          ? { borderLeft: `8px solid ${allChartColors.GREY}` }
                          : {}),
                      }}
                      width={'50%'}
                    >
                      {translatableKey}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '2px',
                        color: pSBC(-0.2, allChartColors.GREY) || allChartColors.GREY,
                        borderLeft: `1px solid ${
                          pSBC(0.8, allChartColors.GREY) || allChartColors.GREY
                        }`,
                      }}
                      width={'50%'}
                    >
                      <TextareaAutosize
                        minRows={1}
                        maxRows={5}
                        value={translationValue || ''}
                        onChange={(e) => handleChangeTranslation(translatableKey, e.target.value)}
                        onFocus={() => setFocusedInput(translatableKey)}
                        onBlur={() =>
                          setFocusedInput((prev) => (prev === translatableKey ? '' : prev))
                        }
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default TranslationsTable
