import { cloneDeep, isEqual } from 'lodash'
import { Kpi, Payload } from '../../../../../types'
import { OpenAnswersRequest, SortDirection } from '../../../../stores/useNewOpenAnswers'
import { OpenAnswerCategories } from '../../../../stores/useOpenCategories'
import { idAndNameKpi } from '../../../../stores/useOpenKpis'
import { OpenAnswer } from '../Pietabular/pietabularTypes'
import { Conversation } from '../_shared/ConversationModal/conversationTypes'
import { OpenModule } from './openTypes'
import { getTenant } from '../../../../react-services/authService'

const useOpenModuleTools = (isReportMode: boolean) => {
  const handleFilterByMessageState = (
    data: OpenAnswer[],
    conversations: Conversation[],
    messageStates: string[],
  ) => {
    return data.filter((answer) => {
      const hasUnreadMessages = conversations.some(
        (obj) =>
          obj.completedSurveyId === answer.completed_survey_id && obj.unreadMessagesCount > 0,
      )
      if (messageStates.includes('UNREAD') && hasUnreadMessages) {
        return true
      }
      if (messageStates.includes('READ') && !hasUnreadMessages) {
        return true
      }
      return false
    })
  }

  const createConvertedKpiList = (
    openKpis: idAndNameKpi[],
    settingsKpis: number[] | Kpi.Kpi[] | undefined,
  ): Kpi.Kpi[] => {
    let newConvertedSettingsKpis = settingsKpis ? settingsKpis : null
    if (newConvertedSettingsKpis) {
      newConvertedSettingsKpis = newConvertedSettingsKpis.map((kpi) => {
        let matchedKpi = {} as idAndNameKpi
        if (typeof kpi === 'number') {
          matchedKpi = openKpis.find((value) => value.id === kpi) || ({} as idAndNameKpi)
        } else {
          matchedKpi = openKpis.find((value) => value.id === kpi.id) || ({} as idAndNameKpi)
        }
        return matchedKpi
      })
    } else {
      newConvertedSettingsKpis = openKpis
    }
    return newConvertedSettingsKpis
  }

  const createOpenAnswersQuery = (
    query: Payload,
    convertedSettingsKpis: idAndNameKpi[],
    customCategoryNames: null | string[],
    options: OpenModule['options'],
    allAnswerCategories: OpenAnswerCategories | null,
  ): OpenAnswersRequest => {
    const newPaginatedQuery: OpenAnswersRequest = {
      ...cloneDeep(query || {}),
      kpis: convertedSettingsKpis,
      pagination: {
        page_number: 1,
        page_size: isReportMode ? 2000 : getTenant() === 'test_frontend' ? 100 : 40,
      },
      sort_columns_with_order: [
        {
          order: SortDirection.ASC,
          columnName: 'id',
        },
        {
          order: SortDirection.DESC,
          columnName: 'date',
        },
      ],
      category_grouping: {
        topic: createCategoryFilter(options, 'topic', (allAnswerCategories || {})['topic']),
        sentiment: createCategoryFilter(
          options,
          'sentiment',
          (allAnswerCategories || {})['sentiment'],
        ),
        custom: customCategoryNames
          ? customCategoryNames.reduce((filter, current) => {
              filter[current] = createCategoryFilter(
                options,
                current,
                (allAnswerCategories || {})[current as keyof unknown],
              )
              return filter
            }, {} as { [category: string]: (null | string)[] | undefined })
          : {},
      },
    }
    return newPaginatedQuery
  }

  const createCategoryFilter = (
    options: OpenModule['options'],
    category: string,
    allPossibleValues: string[] | undefined,
  ): (string | null)[] | undefined => {
    let filterList: undefined | (string | null)[]
    const selectedValues = options
      ? (options[`${category}CategoryFilter`] || []).map((v) => (v === 'uncategorized' ? null : v))
      : undefined
    if (selectedValues && selectedValues.length) {
      const selectedValuesWithoutNull = selectedValues.filter((v) => v !== null)
      const isAllValuesSelected = isEqual(
        (allPossibleValues || []).filter((v) => v !== 'uncategorized').sort(),
        selectedValuesWithoutNull.sort(),
      )
      filterList = isAllValuesSelected ? undefined : selectedValues
    }
    return filterList
  }
  const handleFilterByPhrase = (data: OpenAnswer[], searchFilter: string) => {
    if (!searchFilter) return [...data]
    const loweredSearchFilter = searchFilter.toLocaleLowerCase()
    return data.filter(
      (answer) =>
        (answer.date && dateContainsPhrase(answer.date, loweredSearchFilter)) ||
        (answer.answer && answerContainsPhrase(answer.answer, loweredSearchFilter)) ||
        (answer.translation &&
          translationContainsPhrase(answer.translation, loweredSearchFilter)) ||
        (answer.category && categoriesContainPhrase(answer.category, loweredSearchFilter)),
    )
  }

  const dateContainsPhrase = (date: string, phrase: string) => {
    return date.toLocaleLowerCase().includes(phrase)
  }

  const answerContainsPhrase = (answer: string, phrase: string) => {
    return answer.toLowerCase().includes(phrase)
  }

  const translationContainsPhrase = (translation: { [name: string]: string }, phrase: string) => {
    return translation?.translation?.toLowerCase().includes(phrase) ?? false
  }

  const categoriesContainPhrase = (cats: { [category: string]: string[] }, phrase: string) => {
    if (!cats) return false

    let allCategoryValuesInAnswer: string[] = []
    Object.values(cats).forEach((valueSet) => {
      allCategoryValuesInAnswer = allCategoryValuesInAnswer.concat(valueSet)
    })
    return Boolean(
      allCategoryValuesInAnswer.find((val) => val.trim().toLowerCase().includes(phrase)),
    )
  }

  return {
    handleFilterByMessageState,
    createConvertedKpiList,
    createOpenAnswersQuery,
    handleFilterByPhrase,
  }
}

export default useOpenModuleTools
