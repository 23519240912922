import React, { memo, useContext, useState } from 'react'
import OpenModuleAnswersTableRow from './OpenModuleAnswersTableRow'
import { isEmpty, isUndefined } from 'lodash'
import { OpenModuleContext } from './_OpenModuleContext'
import { HeaderPosition, TextualKpiData } from './openTypes'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import OpenModuleAnswersTableHeader from './OpenModuleAnswersTableHeader'
import NoData from '../../../_shared/Infos/NoData'
import useOpenKpis from '../../../../stores/useOpenKpis'

import css from './OpenModuleAnswersTable.module.scss'

type OpenModuleAnswersTableProps = {
  answers: TextualKpiData[]
  metadataColumns: string[]
  answerMetas: string[] | null
  topics: string[] | null
  doShowTopics: boolean
  doShowSentiments: boolean
  doShowCustomCategories: boolean
  customCategories: { [name: string]: string[] } | null
  onChangeCategories: (isCategorySelectorOpen: boolean) => void
  isFetchingMoreAnswers: boolean
  scrolled: number
  showToolsColumn?: boolean
}

const OpenModuleAnswersTable = memo(function OpenModuleAnswersTable({
  answers,
  metadataColumns,
  answerMetas,
  topics,
  doShowTopics,
  doShowSentiments,
  doShowCustomCategories,
  customCategories,
  onChangeCategories,
  isFetchingMoreAnswers,
  scrolled,
  showToolsColumn,
}: OpenModuleAnswersTableProps) {
  const [headerPositions, setHeaderPositions] = useState<HeaderPosition[]>([])
  const { openKpis } = useOpenKpis()
  const { isReportMode, isScreenMode, showInspectorConversation, showCategoryTool } =
    useContext(OpenModuleContext)

  const showTools = !!(
    !isReportMode &&
    !isScreenMode &&
    (showToolsColumn === true || (isUndefined(showToolsColumn) && showInspectorConversation)) &&
    (!!showInspectorConversation || !!showCategoryTool)
  )

  const setHeaderPosition = (index: number, offSet: number) => {
    setHeaderPositions((prev) => {
      if (prev.find((prevHeader) => prevHeader.index === index)) {
        return prev.map((header) => (header.index === index ? { index, offSet } : header))
      } else {
        return prev.concat({ index, offSet })
      }
    })
  }

  const isHeaderHidden = (index: number, positions: HeaderPosition[]) => {
    const dublicates = positions.filter((header, i, arr) => {
      const dublicateCandidates = arr.filter((position) => position.offSet === header.offSet)
      return dublicateCandidates.length > 1 ? true : false
    })
    if (!dublicates.length) return false
    if (!dublicates.find((header) => header.index === index)) return false
    const lastHeaderIndex = Math.max(...dublicates.map((h) => h.index))
    return index === lastHeaderIndex ? false : true
  }

  return (
    <div
      data-testid={'openModuleAnswerTable-0'}
      className={`open-answers-table ${isEmpty(answers) ? 'open-answers-table-empty' : ''}`}
    >
      <div className='row-group'>
        {!isEmpty(answers) ? (
          answers.map((answer, index, array) => {
            return (
              <React.Fragment key={answer.answer_id}>
                {(index === 0 || array[index].id !== array[index - 1].id) && (
                  <OpenModuleAnswersTableHeader
                    metadataColumns={metadataColumns}
                    showTools={showTools}
                    answerMetas={answerMetas}
                    customCategories={customCategories}
                    doShowCustomCategories={doShowCustomCategories}
                    doShowSentiments={doShowSentiments}
                    doShowTopics={doShowTopics}
                    kpiId={answer.id}
                    key={answer.id}
                    scrolled={scrolled}
                    setHeaderPosition={(offset: number) => setHeaderPosition(index, offset)}
                    isHeaderHidden={isHeaderHidden(index, headerPositions)}
                  />
                )}
                <OpenModuleAnswersTableRow
                  key={answer.answer_id}
                  kpiName={openKpis?.find((kpi) => kpi.id === answer.id)?.name || ''}
                  answersForThisKpi={answers}
                  index={index}
                  answer={answer}
                  metadataColumns={metadataColumns}
                  answerMetas={answerMetas}
                  topics={topics}
                  doShowTopics={doShowTopics}
                  doShowSentiments={doShowSentiments}
                  doShowCustomCategories={doShowCustomCategories}
                  customCategories={customCategories}
                  onChangeCategories={onChangeCategories}
                  showTools={showTools}
                />
              </React.Fragment>
            )
          })
        ) : (
          <>
            <OpenModuleAnswersTableHeader
              answerMetas={[]}
              customCategories={null}
              doShowCustomCategories={false}
              doShowSentiments={false}
              doShowTopics={false}
              isHeaderHidden={false}
              kpiId={9999}
              metadataColumns={[]}
              scrolled={0}
              setHeaderPosition={() => ({})}
              showTools={false}
              isBlank
            />
            <EmptyTableRow />
          </>
        )}
        {isFetchingMoreAnswers && (
          <div className={css.footerRow}>
            <LoadingIndicator />
          </div>
        )}
      </div>
    </div>
  )
})

const EmptyTableRow = memo(function EmptyTableRow() {
  return (
    <div className='tr open-module-table-filtered-empty'>
      <NoData />
    </div>
  )
})

export default OpenModuleAnswersTable
