import React from 'react'
import { FILTERCONSTANTS } from '../../../../../react-constants/filters'

// type Props = {
//   index: number,
//   filterKey: string,
//   filterValues: Array<string>,
//   selected: Array<string>,

//   onChange: Function,
// }

export default function ModuleFiltersItem({
  filterKey,
  selected,
  filterValues,
  isDisabled,
  onChange,
}) {
  function changeValue(event) {
    let options = event.currentTarget
    let values = []
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected && options[i].value !== FILTERCONSTANTS.SELECT_ALL_VALUE) {
        values.push(options[i].value)
      }
    }

    if (noActualValueIsSelected(values)) {
      values = undefined
    }

    onChange(filterKey, values)
  }

  function noActualValueIsSelected(values) {
    return (
      values.length === 0 || (values.length === 1 && values[0] === FILTERCONSTANTS.SELECT_ALL_VALUE)
    )
  }

  return (
    <div className='multi-select-filters'>
      <span className={`multi-select-filters-key ${isDisabled ? 'disabled-key' : ''}`}>
        {filterKey}
      </span>
      <select
        data-testid={`filterSelectionForKey-${filterKey}`}
        className='browser-default'
        multiple
        value={selected}
        onChange={changeValue}
        disabled={isDisabled}
      >
        <option value={FILTERCONSTANTS.SELECT_ALL_VALUE}>{FILTERCONSTANTS.SELECT_ALL_NAME}</option>
        {filterValues &&
          filterValues.map((filter, index) => (
            <option key={index} value={filter}>
              {filter}
            </option>
          ))}
      </select>
    </div>
  )
}
