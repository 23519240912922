import React from 'react'
import ResetStylesContainer from '../../../../styles/ResetStylesContainer'

import './main.scss'
type ToastifyStylesContainerProps = {
  children: JSX.Element | JSX.Element[]
}

const ToastifyStylesContainer = ({ children }: ToastifyStylesContainerProps) => (
  <ResetStylesContainer>
    <div id='toastManualColorImportCntr'>{children}</div>
  </ResetStylesContainer>
)

export default ToastifyStylesContainer
