import React, { useMemo } from 'react'
import DoubleModalMain from '../../../../../_shared/Modals/DoubleModalMain'
import useReportingFilters from '../../../../../../stores/useReportingFilters'
import { Button, FormControl, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material'
import Icon, { IconName } from '../../../../../../utilities/Icon'
import useDynamicFilters, { DynamicFiltersQuery } from '../../../../../../stores/useDynamicFilters'
import { MODAL_ANIMATION_DURATION } from './FilterModal'
import useMetaData from '../../../../../../stores/useMetaData'

import modal_css from './FilterModal.module.scss'
import { tCommon, tData } from '../../../../../../../languages/i18n'

export enum FilterListType {
  DYNAMIC = 'DYNAMIC',
  REPORTING = 'REPORTING',
  ALL = 'ALL',
}
type ViewMetaKeysListProps = {
  activeMeta: string | null
  dynamicFilterQuery: DynamicFiltersQuery | null
  filterListType: FilterListType
  onClose: () => void
  handleSelectActiveMeta: (key: string) => void
  handleListTypeToggle: (e: SelectChangeEvent<FilterListType>) => void
}
const ViewMetaKeysList = ({
  activeMeta,
  dynamicFilterQuery,
  filterListType,
  onClose,
  handleSelectActiveMeta,
  handleListTypeToggle,
}: ViewMetaKeysListProps) => {
  const { metas: reportingMetas } = useReportingFilters()
  const { metas: allMetas } = useMetaData()

  const { dynamicFilter } = useDynamicFilters(dynamicFilterQuery)
  const metaKeys = useMemo(() => {
    if (filterListType === FilterListType.REPORTING) {
      return reportingMetas ? Object.keys(reportingMetas) : []
    }
    if (filterListType === FilterListType.ALL) {
      return allMetas ? Object.keys(allMetas) : []
    }
    return dynamicFilter ? Object.keys(dynamicFilter) : []
  }, [dynamicFilter, reportingMetas, allMetas, filterListType])

  return (
    <DoubleModalMain
      onClose={onClose}
      double={true}
      closeOnEsc={false}
      animation='fade'
      duration={MODAL_ANIMATION_DURATION}
    >
      <div className={modal_css.metaKeyListCntr}>
        <div className={modal_css.header}>
          <div>{tCommon('label.filters').toUpperCase()}</div>
          <div className={modal_css.filterSelect}>
            <Tooltip
              placement='right-start'
              enterDelay={1000}
              title='Select filter list type, "dynamic" type removes the values not present in data, "reporting" filters are pre selected to be displayed on dashboard and reports, "all" displays every possible filter value'
            >
              <FormControl sx={{ borderColor: '#fff' }}>
                <Select
                  labelId='selectFilterType'
                  id='selectFilterType'
                  data-testid='selectFilterType'
                  value={filterListType}
                  style={{ padding: 0 }}
                  onChange={handleListTypeToggle}
                  sx={{
                    height: '24px',
                    padding: '8px 0',
                    fontSize: 12,
                  }}
                >
                  {Object.values(FilterListType).map((key) => (
                    <MenuItem
                      key={key}
                      value={key}
                      style={{ fontSize: '14px', padding: '4px 8px' }}
                      data-testid={`selectFilterOption-${key.toLocaleLowerCase()}`}
                    >
                      {tCommon('label.' + key.toLowerCase())}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </div>
        </div>
        <div className={modal_css.cntr}>
          {metaKeys.map((metaKey) => (
            <div
              key={metaKey}
              className={`${modal_css.btn} ${activeMeta === metaKey ? modal_css.activeBtn : ''}`}
            >
              {activeMeta === metaKey ? (
                <Button
                  variant='contained'
                  endIcon={<Icon name={IconName.REMOVE} color='#fff' />}
                  color='inherit'
                  style={{
                    backgroundColor: 'grey',
                    justifyContent: 'space-around',
                    alignItems: 'unset',
                    padding: '5px 12px',
                  }}
                  onClick={() => handleSelectActiveMeta(metaKey)}
                  fullWidth
                  className='selected'
                  data-testid={`selectMetaKeyButton-${metaKey.toLocaleLowerCase()}`}
                >
                  <label className={`${modal_css.label} ${modal_css.active}`}>
                    {tData(metaKey)}
                  </label>
                </Button>
              ) : (
                <Button
                  variant='contained'
                  endIcon={<Icon name={IconName.ADD} color='#fff' />}
                  color='inherit'
                  style={{
                    backgroundColor: 'white',
                    justifyContent: 'space-around',
                    alignItems: 'unset',
                    padding: '5px 12px',
                  }}
                  onClick={() => handleSelectActiveMeta(metaKey)}
                  fullWidth
                  data-testid={`selectMetaKeyButton-${metaKey.toLocaleLowerCase()}`}
                >
                  <label className={modal_css.label}>{tData(metaKey)}</label>
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </DoubleModalMain>
  )
}

export default ViewMetaKeysList
