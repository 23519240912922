import { useMemo } from 'react'
import useConfigCustomerPath from '../../../../stores/useConfigCustomerPath'
import useOpenKpis from '../../../../stores/useOpenKpis'
import { useToastId } from '../../../common/Notification/NotificationContext'
import { useSummaryFilters } from './Context/SummaryFiltersContext'
import useSummariesTimeFrame from './useSummariesTimeFrame'
import { toast } from 'react-toastify'
import { DateGroups, OpenAnswersCountsQuery } from '../Pietabular/pietabularTypes'
import { isUndefined } from 'lodash'
import { WhereMeta } from '../../../../../types'
import useMetaData from '../../../../stores/useMetaData'

const useAnswerCountQuery = (useAllFilterMetas = false) => {
  const [{ filterMetas, filterTimeFrame, filterTimePeriod, summarySettings, filterPath }] =
    useSummaryFilters()
  const { metas } = useMetaData()
  const { toastifyId } = useToastId()
  const { isCustomerPathEnabled, isSingleMetaSelectEnabled, kpis: moduleKpis } = summarySettings
  const { config: tenantCustomerPath } = useConfigCustomerPath()
  const openPath = tenantCustomerPath?.open_conf
  const { getStartAndEndDates } = useSummariesTimeFrame(filterTimeFrame, filterTimePeriod)
  const { openKpis: tenantKpis } = useOpenKpis()

  const queryKpis: number[] | null = useMemo(() => {
    if (isCustomerPathEnabled) {
      if (!tenantKpis || !filterPath || !openPath) return null
      if (!filterPath) {
        toast.info('No path selected', { containerId: toastifyId })
        return null
      }
      const path = openPath?.find(
        (path) => path.name.toLocaleLowerCase() === filterPath.toLocaleLowerCase(),
      )
      if (!path) {
        toast.info('Path not found', { containerId: toastifyId })
        return null
      }
      return path.kpis || moduleKpis
    }
    if (!isCustomerPathEnabled && moduleKpis) {
      const selectedModuleKpis: number[] = []
      moduleKpis.forEach((kpi) => {
        if (!isUndefined(kpi.id)) {
          selectedModuleKpis.push(kpi.id)
        }
      })
      return selectedModuleKpis
    }
    if (!isCustomerPathEnabled && tenantKpis) {
      return tenantKpis.map((kpi) => kpi.id)
    }
    return null
  }, [tenantKpis, filterPath, openPath, isCustomerPathEnabled, moduleKpis])

  const queryMetas: WhereMeta = useMemo(() => {
    const newMetas = {} as WhereMeta
    for (const key in filterMetas) {
      const filterValue = filterMetas[key]
      if (
        !!metas &&
        !!Object.keys(metas || {}).length &&
        !isUndefined(filterValue) &&
        filterValue !== 'Kaikki' &&
        (!isSingleMetaSelectEnabled || useAllFilterMetas)
      ) {
        const allTenantValues = metas[key]
        const filteredMetaValues = allTenantValues?.filter(
          (meta) => meta?.toLocaleUpperCase() === filterValue?.toLocaleUpperCase(),
        )
        if (allTenantValues) newMetas[key] = filteredMetaValues
      }
    }

    if (isCustomerPathEnabled && filterPath) {
      const path = openPath?.find((path) => path.name === filterPath)
      let pathMetas: WhereMeta | null = null
      if (path && path.metaFilter) pathMetas = path.metaFilter
      if (pathMetas) {
        for (const key in pathMetas) {
          const pathMetaValues = pathMetas[key]
          const queryMetaValues = newMetas[key]
          newMetas[key] = [...(queryMetaValues || []), ...(pathMetaValues || [])]
        }
      }
    }
    return newMetas
  }, [isCustomerPathEnabled, filterMetas, isSingleMetaSelectEnabled, metas, queryKpis])

  const countsQuery: OpenAnswersCountsQuery | null = useMemo(() => {
    const dates = getStartAndEndDates()
    if (!dates || !queryKpis) return null
    const newQuery: OpenAnswersCountsQuery = {
      start_date: dates.startDate,
      end_date: dates.endDate,
      group_by: [],
      where_meta: queryMetas,
      kpis: queryKpis,
      date_grouping: DateGroups.YEAR,
    }
    return newQuery
  }, [filterTimeFrame, filterTimePeriod, queryKpis, queryMetas])

  return { countsQuery }
}

export default useAnswerCountQuery
