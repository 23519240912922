import { isUndefined } from 'lodash'
import { OpenAnswerCategories } from '../../../../stores/useOpenCategories'
import { Breakpoint, GroupedDataWithCounts, OpenAnswersCountsResponse } from './pietabularTypes'
import { ChartBreakpoint } from './trend/trendsReducer'

const usePietabularFunctions = () => {
  const handleMatchingBreakpointsWithWeekGroups = (
    configBpsFilteredBySettingsBpsIds: Breakpoint[],
    possibleDateGroupsInData: string[],
  ) => {
    const bpWeeks = configBpsFilteredBySettingsBpsIds.map((bp) =>
      covertDateStringToWeekGroup(bp.date),
    )

    const matchedBreakpoints = [] as ChartBreakpoint[]
    for (let weekIndex = 0; weekIndex < bpWeeks.length; weekIndex++) {
      const bpWeek = bpWeeks[weekIndex]
      const bpDate = new Date(configBpsFilteredBySettingsBpsIds[weekIndex].date)
      let movedWeek = bpWeek
      const firstWeek = possibleDateGroupsInData.sort()[0]
      const lastWeek = possibleDateGroupsInData.sort()[possibleDateGroupsInData.length - 1]

      if (bpWeek > lastWeek || bpWeek < firstWeek) {
        continue
      }

      let step = 0
      while (movedWeek < lastWeek && movedWeek > firstWeek) {
        const matchedIndex = possibleDateGroupsInData.findIndex((quarter) => quarter === movedWeek)
        if (matchedIndex >= 0) {
          const oldBp = configBpsFilteredBySettingsBpsIds[weekIndex]
          matchedBreakpoints.push({
            ...oldBp,
            description: `${oldBp.date}
${oldBp.description}
            `,
            x: matchedIndex,
          })
          break
        }

        step++
        const copiedNextBpDate = new Date(bpDate.getTime())
        const copiedPreviousBpDate = new Date(bpDate.getTime())
        copiedNextBpDate.setDate(bpDate.getDate() + Math.ceil(step / 2) * 7)
        copiedPreviousBpDate.setDate(bpDate.getDate() - Math.ceil((step / 2) * 7))
        const nextWeek = covertDateStringToWeekGroup(copiedNextBpDate.toISOString())
        const previousWeek = covertDateStringToWeekGroup(copiedPreviousBpDate.toISOString())

        if (step % 2 === 0 && previousWeek >= firstWeek) {
          movedWeek = previousWeek
        }
        if (step % 2 === 0 && previousWeek < firstWeek) {
          movedWeek = nextWeek
        }

        if (step % 2 !== 0 && nextWeek <= lastWeek) {
          movedWeek = nextWeek
        }

        if (step % 2 !== 0 && nextWeek > lastWeek) {
          movedWeek = previousWeek
        }
      }
    }
    return matchedBreakpoints
  }

  const covertDateStringToWeekGroup = (date: string) => {
    const currentDate = new Date(date)
    const startDate = new Date(currentDate.getFullYear(), 0, 1)
    const days = Math.floor((currentDate.valueOf() - startDate.valueOf()) / (24 * 60 * 60 * 1000))
    const weekNumber = Math.ceil(days / 7)
    if (weekNumber < 10) {
      return currentDate.getFullYear() + '/W0' + weekNumber
    } else {
      return currentDate.getFullYear() + '/W' + weekNumber
    }
  }

  const handleMatchingBreakpointsWithMonthGroups = (
    configBpsFilteredBySettingsBpsIds: Breakpoint[],
    possibleDateGroupsInData: string[],
  ) => {
    const bpMonths = configBpsFilteredBySettingsBpsIds.map((bp) =>
      covertDateStringToMonthGroup(bp.date),
    )
    const matchedBreakpoints = [] as ChartBreakpoint[]
    for (let monthIndex = 0; monthIndex < bpMonths.length; monthIndex++) {
      const bpMonth = bpMonths[monthIndex]
      const bpDate = new Date(configBpsFilteredBySettingsBpsIds[monthIndex].date)
      let movedMonth = bpMonth
      const firstMonth = possibleDateGroupsInData.sort()[0]
      const lastMonth = possibleDateGroupsInData.sort()[possibleDateGroupsInData.length - 1]

      if (bpMonth > lastMonth || bpMonth < firstMonth) {
        continue
      }

      let step = 0
      while (movedMonth < lastMonth && movedMonth > firstMonth) {
        const matchedIndex = possibleDateGroupsInData.findIndex((quarter) => quarter === movedMonth)
        if (matchedIndex >= 0) {
          const oldBp = configBpsFilteredBySettingsBpsIds[monthIndex]
          matchedBreakpoints.push({
            ...oldBp,
            description: `${oldBp.date}
${oldBp.description}`,
            x: matchedIndex,
          })
          break
        }

        step++
        const copiedNextBpDate = new Date(bpDate.getTime())
        const copiedPreviousBpDate = new Date(bpDate.getTime())
        copiedNextBpDate.setMonth(bpDate.getMonth() + Math.ceil(step / 2))
        copiedPreviousBpDate.setMonth(bpDate.getMonth() - Math.ceil(step / 2))
        const nextMonth = covertDateStringToMonthGroup(copiedNextBpDate.toISOString())
        const previousMonth = covertDateStringToMonthGroup(copiedPreviousBpDate.toISOString())

        if (step % 2 === 0 && previousMonth >= firstMonth) {
          movedMonth = previousMonth
        }
        if (step % 2 === 0 && previousMonth < firstMonth) {
          movedMonth = nextMonth
        }

        if (step % 2 !== 0 && nextMonth <= lastMonth) {
          movedMonth = nextMonth
        }

        if (step % 2 !== 0 && nextMonth > lastMonth) {
          movedMonth = previousMonth
        }
      }
    }

    return matchedBreakpoints
  }

  const covertDateStringToMonthGroup = (date: string) => {
    const bpYear = date.substring(0, 4)
    const bpMonth = date.substring(5, 7)
    return bpYear + '/M' + bpMonth
  }

  const handleMatchingBreakpointsWithQuarterGroups = (
    configBpsFilteredBySettingsBpsIds: Breakpoint[],
    possibleDateGroupsInData: string[],
  ) => {
    const bpQuarters = configBpsFilteredBySettingsBpsIds.map((bp) => {
      return covertDateStringToQuarterGroup(bp.date)
    })

    const matchedBreakpoints = [] as ChartBreakpoint[]
    for (let bpIndex = 0; bpIndex < bpQuarters.length; bpIndex++) {
      const bpQuarter = bpQuarters[bpIndex]
      const bpDate = new Date(configBpsFilteredBySettingsBpsIds[bpIndex].date)
      let movedQuarter = bpQuarter
      const firstQuarter = possibleDateGroupsInData.sort()[0]
      const lastQuarter = possibleDateGroupsInData.sort()[possibleDateGroupsInData.length - 1]
      if (bpQuarter > lastQuarter || bpQuarter < firstQuarter) {
        continue
      }

      let step = 0
      while (movedQuarter < lastQuarter && movedQuarter > firstQuarter) {
        const matchedIndex = possibleDateGroupsInData.findIndex(
          (quarter) => quarter === movedQuarter,
        )
        if (matchedIndex >= 0) {
          const oldBp = configBpsFilteredBySettingsBpsIds[bpIndex]
          matchedBreakpoints.push({
            ...oldBp,
            description: `${oldBp.date}
${oldBp.description}`,
            x: matchedIndex,
          })
          break
        }

        step++
        const copiedNextBpDate = new Date(bpDate.getTime())
        const copiedPreviousBpDate = new Date(bpDate.getTime())
        copiedNextBpDate.setMonth(bpDate.getMonth() + Math.ceil(step / 2) * 3)
        copiedPreviousBpDate.setMonth(bpDate.getMonth() - Math.ceil(step / 2) * 3)
        const nextQuarter = covertDateStringToQuarterGroup(copiedNextBpDate.toISOString())
        const previousQuarter = covertDateStringToQuarterGroup(copiedPreviousBpDate.toISOString())

        if (step % 2 === 0 && previousQuarter >= firstQuarter) {
          movedQuarter = previousQuarter
        }
        if (step % 2 === 0 && previousQuarter < firstQuarter) {
          movedQuarter = nextQuarter
        }

        if (step % 2 !== 0 && nextQuarter <= lastQuarter) {
          movedQuarter = nextQuarter
        }

        if (step % 2 !== 0 && nextQuarter > lastQuarter) {
          movedQuarter = previousQuarter
        }
      }
    }
    return matchedBreakpoints
  }

  const covertDateStringToQuarterGroup = (date: string) => {
    const bpyear = Number(date.substring(0, 4))
    const bpQuarter = Math.ceil(Number(date.substring(5, 7)) / 3)
    return bpyear + '/Q0' + bpQuarter
  }

  const handleMatchingBreakpointsWithYearGroups = (
    configBpsFilteredBySettingsBpsIds: Breakpoint[],
    possibleDateGroupsInData: string[],
  ) => {
    const possibleYears = Array.from(
      new Set(possibleDateGroupsInData.map((d) => Number(d.substring(0, 4)))),
    )
    const bpYears = configBpsFilteredBySettingsBpsIds.map((bp) => Number(bp.date.substring(0, 4)))
    const matchedBreakpoints = [] as ChartBreakpoint[]
    for (let bpIndex = 0; bpIndex < bpYears.length; bpIndex++) {
      const bpYear = bpYears[bpIndex]
      let movedYear = bpYear
      const firstYear = Math.min(...possibleYears)
      const lastYear = Math.max(...possibleYears)
      if (bpYear > lastYear || bpYear < firstYear) {
        break
      }

      let step = 0
      while (movedYear < lastYear && movedYear > firstYear) {
        const matchedIndex = possibleYears.findIndex((year) => year === movedYear)
        if (matchedIndex >= 0) {
          const oldBp = configBpsFilteredBySettingsBpsIds[bpIndex]
          matchedBreakpoints.push({
            ...oldBp,
            description: `${oldBp.date}
${oldBp.description}`,
            x: matchedIndex,
          })
          break
        }
        step++
        if (step % 2 === 0 && bpYear - Math.ceil(step / 2) >= firstYear) {
          movedYear = bpYear - Math.ceil(step / 2)
        } else if (step % 2 === 0 && bpYear - Math.ceil(step / 2) < firstYear) {
          movedYear = bpYear + Math.ceil(step / 2)
        } else if (step % 2 !== 0 && bpYear + Math.ceil(step / 2) <= lastYear) {
          movedYear = bpYear + Math.ceil(step / 2)
        } else if (step % 2 !== 0 && bpYear + Math.ceil(step / 2) > lastYear) {
          movedYear = bpYear - Math.ceil(step / 2)
        }
      }
    }
    return matchedBreakpoints
  }
  const handleCountingGroupedCountsByDate = (
    data: OpenAnswersCountsResponse['results'],
    nData: OpenAnswersCountsResponse['results'],
    defaultCountedKey: string,
    possibleCountValues: string[],
  ) => {
    const dateValuesWithCounts = {} as GroupedDataWithCounts
    const allPossibleCountValues = possibleCountValues.concat('uncategorized')
    for (let i = 0; i < nData.length; i++) {
      const point = nData[i]
      const pointDate = point.date_period
      if (!pointDate) continue
      const pointCount = point.count || 0
      const possibleCountObjects = allPossibleCountValues.reduce(
        (acc, curr) => ({ ...acc, [curr]: 0 }),
        {} as Record<string, number>,
      )
      dateValuesWithCounts[pointDate] = { n: pointCount, ...possibleCountObjects }
    }
    data.sort((a, b) => {
      const aPeriod = a.date_period
      const bPeriod = b.date_period
      if (!aPeriod && !bPeriod) return 0
      if (aPeriod && !bPeriod) return 1
      if (!aPeriod && bPeriod) return -1
      if (!aPeriod || !bPeriod) return 0
      if (aPeriod > bPeriod) return 1
      if (aPeriod < bPeriod) return -1
      return 0
    })

    for (let i = 0; i < data.length; i++) {
      const dateWithCounts = data[i]
      const { count, date_period } = dateWithCounts
      if (!count || !date_period) continue

      let dateValueCounts = dateValuesWithCounts[date_period]
      if (!dateValueCounts) {
        dateValueCounts = {}
        dateValueCounts['n'] = 0
        allPossibleCountValues.forEach((v) => (dateValueCounts[v] = 0))
      }
      let categoryValues: string[] | null = null
      if (defaultCountedKey === 'sentiment' || defaultCountedKey === 'topic') {
        categoryValues = dateWithCounts[defaultCountedKey]
      } else {
        categoryValues = dateWithCounts.custom_categories[defaultCountedKey]
      }
      let hasPossibleCountValues = false
      if (categoryValues && categoryValues.length) {
        categoryValues.forEach((v) => {
          if (allPossibleCountValues.includes(v)) hasPossibleCountValues = true
        })
        if (!hasPossibleCountValues) continue
        categoryValues = categoryValues.filter((v) => allPossibleCountValues.includes(v))
      }

      if ((!categoryValues || !categoryValues.length) && count) {
        dateValueCounts['uncategorized'] = (dateValueCounts['uncategorized'] || 0) + count
      }
      if (categoryValues && categoryValues.length && count) {
        categoryValues.forEach((value) => (dateValueCounts[value] = dateValueCounts[value] + count))
      }
      dateValuesWithCounts[date_period] = dateValueCounts
    }
    return dateValuesWithCounts
  }

  const handleFilteringPossibleValuesToCount = (
    data: OpenAnswersCountsResponse['results'],
    defaultCountedKey: string,
    pieData: [string, number][],
  ) => {
    let possibleCountValues: string[] = []
    if (defaultCountedKey === 'sentiment' || defaultCountedKey === 'topic') {
      possibleCountValues = data.flatMap((c) => {
        const values = c[defaultCountedKey]
        if (!values || !values.length) {
          return ['uncategorized']
        }
        return values
      })
    } else {
      possibleCountValues = data.flatMap((c) => {
        const values = c.custom_categories[defaultCountedKey]
        if (!values || !values.length) {
          return ['uncategorized']
        }
        return values
      })
    }
    possibleCountValues = Array.from(new Set(possibleCountValues)).sort()
    if (defaultCountedKey.toLocaleLowerCase() === 'topic') {
      possibleCountValues = pieData.map((c) => c[0]).filter((t) => t !== 'other')
    }
    if (defaultCountedKey.toLocaleLowerCase() === 'sentiment') {
      possibleCountValues = possibleCountValues.filter((v) => v !== 'uncategorized')
    }
    return possibleCountValues
  }

  const handleCountingGroupedCountsByMeta = (
    data: OpenAnswersCountsResponse['results'],
    nData: OpenAnswersCountsResponse['results'],
    defaultCountedKey: string,
    possibleCountValues: string[],
  ) => {
    const metaValuesWithCounts = {} as GroupedDataWithCounts
    for (let i = 0; i < nData.length; i++) {
      const point = nData[i]
      const pointMeta = point.meta_value || 'no group'
      const pointCount = point.count || 0
      const filteredPossibleCountValues =
        defaultCountedKey === 'sentiment'
          ? possibleCountValues.filter((v) => v !== 'uncategorized')
          : possibleCountValues
      const possibleCountObjects = filteredPossibleCountValues.reduce(
        (acc, curr) => ({ ...acc, [curr]: 0 }),
        {} as Record<string, number>,
      )
      metaValuesWithCounts[pointMeta] = { n: pointCount, ...possibleCountObjects }
    }

    for (let i = 0; i < data.length; i++) {
      const metaWithCounts = data[i]
      const count = metaWithCounts.count || 0
      const metaValue = metaWithCounts.meta_value || 'no group'
      const metaValueCounts = metaValuesWithCounts[metaValue]
      if (!metaValueCounts) continue
      metaValuesWithCounts[metaValue] = metaValueCounts
      let categoryValues: string[] | null = null
      if (defaultCountedKey === 'sentiment' || defaultCountedKey === 'topic') {
        categoryValues = metaWithCounts[defaultCountedKey]
      } else {
        categoryValues = metaWithCounts.custom_categories[defaultCountedKey]
      }
      if (!categoryValues) continue
      let hasPossibleCountValues = false
      categoryValues.forEach((v) => {
        if (possibleCountValues.includes(v)) hasPossibleCountValues = true
      })
      if (!hasPossibleCountValues) continue
      if (defaultCountedKey === 'sentiment') {
        categoryValues = categoryValues.filter((v) => v !== 'uncategorized')
      }
      categoryValues = categoryValues.filter((v) => possibleCountValues.includes(v))

      categoryValues.forEach((value) => (metaValueCounts[value] = metaValueCounts[value] + count))
      metaValuesWithCounts[metaValue] = metaValueCounts
    }
    return metaValuesWithCounts
  }

  const handleCountingTopicsCounts = (
    counts: OpenAnswersCountsResponse['results'],
    selectedCategories: string[],
    showNotSelectedTopicsAsOther: boolean,
    numberOfTopTopicsToShow: number,
    allTopicsWithoutUncategorized: OpenAnswerCategories['topic'],
  ) => {
    const allTopics = allTopicsWithoutUncategorized.concat('uncategorized')
    const countedTopics = {} as { [topic: string]: number }
    const targetTopics = showNotSelectedTopicsAsOther ? allTopics : selectedCategories
    targetTopics.forEach((t) => (countedTopics[t] = 0))
    for (let i = 0; i < counts.length; i++) {
      const topicsWithCounts = counts[i]
      if (topicsWithCounts.topic) {
        topicsWithCounts.topic.forEach((topic) => {
          const currentCount = countedTopics[topic]
          if (!isUndefined(currentCount)) {
            countedTopics[topic] = currentCount + (topicsWithCounts.count || 0)
          }
        })
      }

      if (!topicsWithCounts.topic) {
        const currentCount = countedTopics['uncategorized']
        if (!currentCount) {
          countedTopics['uncategorized'] = topicsWithCounts.count || 0
        } else {
          countedTopics['uncategorized'] = currentCount + (topicsWithCounts.count || 0)
        }
      }
    }

    if (showNotSelectedTopicsAsOther) {
      const filteredCountedTopicsWithOther = Object.entries(countedTopics)
        .reduce((allCounts, currentCount) => {
          const topic = currentCount[0]
          if (selectedCategories.includes(topic)) {
            return allCounts.concat([currentCount])
          } else {
            const otherCount = allCounts.find((count) => count && count[0] === 'other')
            if (!otherCount) return allCounts.concat([['other', currentCount[1]]])
            else {
              const newOtherCount = ['other', otherCount[1] + currentCount[1]] as [string, number]
              return allCounts.map((c) => (c[0] === 'other' ? newOtherCount : c))
            }
          }
        }, [] as [string, number][])
        .sort((cA, cB) => cB[1] - cA[1])
        .reduce((allCounts, currentCount, i) => {
          if (i < numberOfTopTopicsToShow) {
            return allCounts.concat([currentCount])
          } else {
            const otherCount = allCounts.find((count) => count && count[0] === 'other')
            if (!otherCount) return allCounts.concat([['other', currentCount[1]]])
            else {
              const newOtherCount = ['other', otherCount[1] + currentCount[1]] as [string, number]
              return allCounts.map((c) => (c[0] === 'other' ? newOtherCount : c))
            }
          }
        }, [] as [string, number][])
      return filteredCountedTopicsWithOther
    } else {
      const sortedHighestCountFirstTopics = Object.entries(countedTopics).sort(
        (cA, cB) => cB[1] - cA[1],
      )
      const filteredCountedTopics = sortedHighestCountFirstTopics.filter(
        (_v, i) => i < numberOfTopTopicsToShow,
      )
      return filteredCountedTopics
    }
  }

  return {
    handleMatchingBreakpointsWithWeekGroups,
    handleMatchingBreakpointsWithMonthGroups,
    handleMatchingBreakpointsWithQuarterGroups,
    handleMatchingBreakpointsWithYearGroups,
    handleCountingGroupedCountsByDate,
    handleFilteringPossibleValuesToCount,
    handleCountingGroupedCountsByMeta,
    handleCountingTopicsCounts,
  }
}

export default usePietabularFunctions
