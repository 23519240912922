/**
 * Event names should have every word start with a capital letter.
 * Events are grouped to categories and the categories are (hopefully) in an aplhabetical order
 */

enum TrackingEvent {
  Login = 'Login',
  LoginFederated = 'Login Federated',
  SysadminLogin = 'Sysadmin Login',
  Logout = 'Logout',
  PageLoadingTime = 'Page loading time', // legacy naming
  InitialLoadingTime = 'Initial load loading time', // legacy naming
  Error = 'Error',
  StartLoading = 'Start Loading',
  FinishLoading = 'Finish Loading',

  // Filters
  OpenFilters = 'Open Filters',
  CloseFilters = 'Close Filters',
  ChangeFilters = 'Change Filters',

  // #region Modals

  // Modals/Add Contact
  OpenAddContactModal = 'Open Add Contact Modal',
  CloseAddContactModal = 'Close Add Contact Modal',

  // Modals/Delete Contact
  OpenDeleteContactModal = 'Open Delete Contact Modal',
  CloseDeleteContactModal = 'Close Delete Contact Modal',
  DeleteContactInContactManagement = 'Delete Contact in Contact Management',

  // Modals/Export
  OpenExportModal = 'Open Export Modal',
  CloseExportModal = 'Close Export Modal',
  ExportResponseRate = 'Export Response Rate',
  ExportData = 'Export Data',

  // #endregion

  // #region Modules

  // Modules/Alerts
  StartLoadingAlerts = 'Start Loading Alerts',
  FinishLoadingAlerts = 'Finish Loading Alerts',
  OpenAlertDialog = 'Open Alert Dialog',
  CloseAlertDialog = 'Close Alert Dialog',
  SaveAlert = 'Save Alert',
  OpenEmailAlert = 'Open Email Alert',
  SaveEmailAlert = 'Save Email Alert',

  // Modules/Open answers
  LoadAllAnswers = 'Load All Answers',
  OpenCategoryPopup = 'Open Category Pop-up',
  CloseCategoryPopup = 'Close Category Pop-up',
  ChangeCategories = 'Change Categories',
  FilterOpenAnswers = 'Filter Open Answers',
  ToggleOpenAnswerTableVisibility = 'Toggle Open Answer Table Visibility',

  // Modules/Surveys
  ShowBlockedSurveys = 'Show Blocked Surveys',
  HideBlockedSurveys = 'Hide Blocked Surveys',
  ResendSurvey = 'Resend Survey',
  OpenStartNewSurveyModal = 'Open Start New Survey Modal',

  // Modules/Tabular
  ChangeTabularGrouping = 'Change Tabular Grouping',

  // #endregion

  // Navigation
  LocationChange = 'Location Change',
  WindowFocus = 'Window Focus',
  WindowBlur = 'Window Blur',

  // #region Tools
  OpenTools = 'Open Tools',

  // Tools/Contact management
  OpenContactManagement = 'Open Contact Management',
  ChangePageInContactManagement = 'Change Page in Contact Management',
  FilterContactsInContactManagement = 'Filter Contacts in Contact Management',

  // Tools/Data
  OpenData = 'Open Data',

  // Tools/Data/Insert Data Manually
  ChooseQuestionForManualDataInsertion = 'Choose Question For Manual Data Insertion',
  InsertDataManually = 'Insert Data Manually',

  // Tools/CRM Integration
  OpenCRMIntegration = 'Open CRM Integration',
  ClickCRMIntegrationActivationSwitch = 'Click CRM Integration Activation Switch',

  // Tools/Group Management
  OpenGroupManagement = 'Open Group Management',
  OpenEditGroup = 'Open Edit Group',
  CloseEditGroup = 'Close Edit Group',
  EditGroup = 'Edit Group',
  OpenDeleteGroup = 'Open Delete Group',
  CloseDeleteGroup = 'Close Delete Group',
  DeleteGroup = 'Delete Group',
  AddGroup = 'Add Group',

  // Tools/Surveys
  OpenSurveys = 'Open Surveys',

  // Tools/Surveys/Manual sending of surveys
  OpenManualSendingOfSurveys = 'Open Manual Sending of Surveys',
  SelectTemplateForSurvey = 'Select Template For Survey',
  ChangeDeliveryMethod = 'Change Delivery Method',
  OpenManualSendingContactList = 'Open Manual Sending Contact List',
  CloseManualSendingContactList = 'Close Manual Sending Contact List',
  OpenManualSendingAddNewContact = 'Open Manual Sending Add New Contact',
  CloseManualSendingAddNewContact = 'Close Manual Sending Add New Contact',
  ResetTemplate = 'Reset Template',
  StartSurvey = 'Start Survey',

  // Tools/Surveys/Response Rate
  OpenResponseRate = 'Open Response Rate',
  ToggleEmptyDataVisibility = 'Toggle Empty Data Visibility',
  ExportSingleSurvey = 'Export Single Survey',

  // Tools/Surveys/Survey info
  OpenSurveyInfo = 'Open Survey Info',
  ToggleShowRedirectsAndInactiveSurveys = 'Toggle Show Redirects And Inactive Surveys',
  OpenSurveyPreview = 'Open Survey Preview',
  CloseSurveyPreview = 'Close Survey Preview',
  ClickCollabLink = 'Click Collab Link',

  // Tools/User Management
  OpenUserManagement = 'Open User Management',
  InviteUser = 'Invite User',
  OpenDeleteUser = 'Open Delete User',
  CloseDeleteUser = 'Close Delete User',
  DeleteUser = 'Delete User',
  OpenEditUser = 'Open Edit User',
  CloseEditUser = 'Close Edit User',
  EditUser = 'Edit User',
  // #endregion

  // Value Inspector
  OpenValueInspector = 'Open Value Inspector',
  CloseValueInspector = 'Close Value Inspector',
}

export default TrackingEvent
