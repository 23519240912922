import React, { useEffect, useRef, useState } from 'react'
import './Tabular.scss'
import { datePlusDays, daysInBetween } from '../../../../react-services/datesService'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'
import { DataQuery, KpiSettings, TabularSettings } from './tabularTypes'
import { Query } from '../../../../../types'
import useNumericKpiCounts from '../../../../stores/useNumericKpiCounts'
import { infoSelectAtleastOneKpi } from './TabularNotifications'
import { toast } from 'react-toastify'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import Tabular from './Tabular'
import NoData from '../../../_shared/Infos/NoData'
import { useRenderActions } from '../Group/contexts/RenderContext'
import {
  isAllowedToFetch,
  isFetchingData,
  isWaitingForFetching,
} from '../Group/contexts/renderListReducer'
import { isEqual } from 'lodash'
import { CircularProgress, SelectChangeEvent } from '@mui/material'
import { QueueStatus } from '../Group/groupModuleTypes'

import css from './TabularModuleCntr.module.scss'

type TabularModuleCntrProps = {
  id: string
  groupings?: string[]
  query: DataQuery
  settings: TabularSettings
  isTrends?: boolean
  isScreenMode?: boolean
  isReportMode?: boolean
  settingsKpis?: KpiSettings[]
  isKpiSpecificSettings?: boolean
  isTableCollapsed?: boolean
  isTitleLoading?: boolean
  sorting?: {
    index: number
    isReversed: boolean
  }
  saveModuleProperty: (newProperty: Record<string, unknown>) => void
  moduleStatus: QueueStatus | undefined
}

const TabularModuleCntr = ({
  id,
  query,
  settings,
  groupings,
  isKpiSpecificSettings,
  isTableCollapsed,
  isScreenMode,
  isReportMode,
  isTitleLoading,
  isTrends,
  saveModuleProperty,
  settingsKpis,
  sorting,
  moduleStatus,
}: TabularModuleCntrProps) => {
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { requestToFetch, updateModuleToIdle } = useRenderActions()
  const { isLoading: isLoadingCommonDbSettings } = useCommonDbSettingsConfig()
  const saveTimeout = useRef<NodeJS.Timeout>()
  const [ready, setReady] = useState<boolean>(false)
  const [normalQuery, setNormalQuery] = useState<Query.SingleNumeric | null>(null)
  const [trendsQuery, setTrendsQuery] = useState<Query.SingleNumeric | null>(null)

  useEffect(() => {
    const newQuery = {
      start_date: query?.startDate,
      end_date: query?.endDate,
      where_meta: query?.filters,
      kpis: query?.kpis || [],
      calculated_kpis: query?.calculatedKpis || {},
      grouping: query?.grouping,
    }

    if (
      !newQuery.start_date ||
      !newQuery.end_date ||
      (!Object.keys(newQuery.calculated_kpis).length && !newQuery.kpis.length) ||
      isEqual(newQuery, normalQuery)
    )
      return
    if (id) handleRequestingToFetch(id)
    if (isAllowedToFetch(moduleStatus)) setNormalQuery(newQuery)
  }, [
    query.startDate,
    query.endDate,
    query.filters,
    query.calculatedKpis,
    query.kpis,
    moduleStatus,
  ])

  const handleRequestingToFetch = (moduleId: string) => {
    requestToFetch(moduleId)
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
  }

  useEffect(() => {
    if (normalQuery && isTrends) {
      const normalPeriodLength = daysInBetween(normalQuery.start_date, normalQuery.end_date)
      const trendsStartDate = datePlusDays(normalQuery.start_date, normalPeriodLength * -1 - 1)
      const trendsEndDate = datePlusDays(normalQuery.start_date, -1)
      setTrendsQuery({
        ...normalQuery,
        start_date: trendsStartDate,
        end_date: trendsEndDate,
      })
    }
  }, [normalQuery, isTrends])

  const {
    numericCounts: counts,
    isLoading: isLoadingCounts,
    error: errorCounts,
    isRefetching: isRefetchingCounts,
  } = useNumericKpiCounts(normalQuery)
  useEffect(() => toastError(errorCounts), [errorCounts])

  const {
    numericCounts: trends,
    isLoading: isLoadingTrends,
    isRefetching: isRefetchingTrends,
    error: errorTrends,
  } = useNumericKpiCounts(trendsQuery, counts ? 1500 : 4000, true)
  useEffect(() => toastError(errorTrends), [errorCounts])

  const DONE_STATUS_TIMEOUT = 200
  useEffect(() => {
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
    const isDoneFetching = !isLoadingCounts && !isRefetchingCounts
    if (isFetchingData(moduleStatus) && isDoneFetching) {
      doneStatusTimeoutRef.current = setTimeout(() => {
        updateModuleToIdle(id)
      }, DONE_STATUS_TIMEOUT)
    }
    return () => {
      doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
    }
  }, [moduleStatus, isLoadingCounts, isRefetchingCounts])

  const readyTimeoutref = useRef<NodeJS.Timeout>()
  const READY_TIMEOUT = 4000
  useEffect(() => {
    if (readyTimeoutref.current) clearTimeout(readyTimeoutref.current)
    if (
      !isLoadingCounts &&
      (!isLoadingTrends || !isTrends) &&
      !isLoadingCommonDbSettings &&
      isFetchingData(moduleStatus)
    ) {
      readyTimeoutref.current = setTimeout(() => {
        setReady(true)
      }, READY_TIMEOUT)
    }
    return () => readyTimeoutref.current && clearTimeout(readyTimeoutref.current)
  }, [isLoadingCounts, isLoadingTrends, isLoadingCommonDbSettings, moduleStatus])

  useEffect(() => {
    return () => saveTimeout.current && clearTimeout(saveTimeout.current)
  }, [])

  const changeGroupingFromTitlebar = (e: SelectChangeEvent<string>) => {
    trackingService.track(TrackingEvent.ChangeTabularGrouping)
    const newValue = e.target.value
    if (saveTimeout.current) clearTimeout(saveTimeout.current)
    if (newValue !== query.grouping) {
      setTimeout(() => {
        saveModuleProperty({ options: { ...settings, grouping: newValue } })
      }, 100)
    }
  }

  const handleSortChange = (index?: number, isReversed?: boolean) => {
    if (saveTimeout.current) clearTimeout(saveTimeout.current)
    if (sorting?.index !== index || sorting?.isReversed !== isReversed) {
      setTimeout(() => {
        saveModuleProperty({ sorting: { index, isReversed } })
      }, 1000)
    }
  }

  const toastError = (e: string) => {
    if (e) toast.error(e)
  }

  const minValue = settings?.limitValues ? settings.limitValues.min : null
  const maxValue = settings?.limitValues ? settings.limitValues.max : null

  if (
    isLoadingCounts ||
    isLoadingCommonDbSettings ||
    (!counts && isWaitingForFetching(moduleStatus))
  ) {
    return (
      <div className={css.loading}>
        <CircularProgress thickness={1} size={'5rem'} style={{ opacity: 0.7 }} />
      </div>
    )
  }

  if (
    normalQuery &&
    (!normalQuery?.kpis || !normalQuery.kpis.length) &&
    (!normalQuery?.calculated_kpis || !Object.keys(normalQuery.calculated_kpis).length)
  ) {
    return (
      <div className={css.noKpis}>
        <div>{infoSelectAtleastOneKpi}</div>
        <div>
          <NoData />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`tabular-container ${ready ? CSSCONSTANTS.CLASS_MODULE_DONE_LOADING : ''} ${
        css.cntr
      }`}
    >
      <Tabular
        isTitleVisible={!isReportMode && !isScreenMode}
        isGroupingByTimeRes={false}
        sorting={sorting}
        groupings={groupings}
        isRefetchingCounts={
          isRefetchingCounts ||
          isRefetchingTrends ||
          (!!counts && isWaitingForFetching(moduleStatus))
        }
        selectedGrouping={query.grouping}
        showN={settings?.show_total_n ?? false}
        showTrends={settings?.trends ?? false}
        showTotal={settings?.show_total ?? false}
        data={counts}
        trendData={isTrends ? trends : null}
        dataMode={settings?.cellDataMode ?? undefined}
        minLimit={minValue}
        maxLimit={maxValue}
        isScreenMode={!!isScreenMode}
        xAxisScrollingEnabled={settings?.xAxisScrolling ?? false}
        whereMeta={query.filters}
        startDate={query.startDate}
        endDate={query.endDate}
        settingsKpis={settingsKpis || []}
        calculatedKpis={query.calculatedKpis}
        isKpiSpecificSettings={isKpiSpecificSettings}
        isTableCollapsed={isTableCollapsed}
        isTitleLoading={isTitleLoading}
        valueInspectorInfo={{
          startDate: query.startDate,
          endDate: query.endDate,
          filters: query.filters,
        }}
        onGroupingChange={changeGroupingFromTitlebar}
        onSort={handleSortChange}
      />
    </div>
  )
}

export default TabularModuleCntr
