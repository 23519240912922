import React, { useEffect, useMemo, useRef, useState } from 'react'
import LineChart from '../../Line/Line'
import HighchartsReact from 'highcharts-react-official'
import { LineChartDataFormats, LineChartModule } from '../../Line/lineChartTypes'
import { KpiData, Query } from '../../../../../../types'
import useNumericKpis from '../../../../../stores/useNumericKpis'
import { CustomerPathKpi } from '../../../../../stores/useConfigCustomerPath'
import { isNull } from 'lodash'
import { WheelModuleSettings } from '../wheelModuleTypes'
import useLineSeriesBuilders from './useLineSeriesBuilders'
import { Breakpoint } from '../../Pietabular/pietabularTypes'
import { reportSVGWidth } from '../../../../../../styles/variableExport'

type LineProps = {
  id: string
  limitValues: LineChartModule['limitValues']
  lineQuery: Query.Numeric
  isRefetching: boolean
  lineCounts: KpiData.NumericData | null
  categories?: CustomerPathKpi[]
  selectedCategory: string
  saveModuleOptionsValue: (value: Record<string, unknown>) => void
  options?: WheelModuleSettings
  extensionWidth: number
  isReportOrScreen: boolean
}

const Line = ({
  id,
  limitValues,
  lineQuery,
  isRefetching,
  lineCounts,
  categories,
  selectedCategory,
  saveModuleOptionsValue,
  options,
  extensionWidth,
  isReportOrScreen,
}: LineProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const { numericKpis } = useNumericKpis()
  const [limitValuesDataFormat, setLimitValuesDataFormat] = useState<LineChartDataFormats>()

  const {
    createLineSeriesFromCircleState,
    createInnerCircleLineChartData,
    createOuterCircleLineChartData,
  } = useLineSeriesBuilders(
    numericKpis,
    categories,
    lineCounts,
    options?.dataFormat,
    options?.dateGroup,
  )

  const innerLines: KpiData.NumericData | null = useMemo(() => {
    if (lineCounts && categories && numericKpis) {
      const innerLineSeries = createLineSeriesFromCircleState()
      const lineChartData = createInnerCircleLineChartData(innerLineSeries)
      return lineChartData
    }
    return null
  }, [lineCounts, categories, numericKpis])

  const outerLines: KpiData.NumericData | null = useMemo(() => {
    if (selectedCategory && lineCounts && categories && numericKpis) {
      const lineChartData = createOuterCircleLineChartData(selectedCategory)
      return lineChartData
    } else {
      return null
    }
  }, [lineCounts, categories, numericKpis, selectedCategory])

  useEffect(() => {
    setLimitValuesDataFormat(options?.dataFormat || LineChartDataFormats.AVG)
  }, [limitValues])

  const breakpoints = useMemo(() => {
    return options?.breakpoints || []
  }, [options?.breakpoints])

  const dataFormat = useMemo(() => {
    return options?.dataFormat ? options.dataFormat : LineChartDataFormats.AVG
  }, [options?.dataFormat])

  const dateGroup = useMemo(() => {
    return options?.dateGroup ? options.dateGroup : 'month'
  }, [options?.dateGroup])

  const query = useMemo(() => {
    return {
      calculated_kpis: {},
      end_date: '',
      start_date: '',
      kpis: [],
      time_res: options?.dateGroup ? options.dateGroup : 'month',
      trend_line: false,
      where_meta: lineQuery.where_meta,
    }
  }, [options?.dateGroup, lineQuery.where_meta])

  const handleSaveBreakpoints = (breakpoints: Breakpoint[]) =>
    saveModuleOptionsValue({ breakpoints })

  const handleSaveLineChartDataFormat = (dataFormat: LineChartDataFormats) =>
    saveModuleOptionsValue({ dataFormat })

  const handleSaveLineChartDateGroup = (dateGroup: Query.TimeRes) =>
    saveModuleOptionsValue({ dateGroup })

  const handleSaveLineChartShowTotals = () =>
    saveModuleOptionsValue({ showTotals: !options?.showTotals })

  return (
    <div
      style={{
        width: isReportOrScreen ? reportSVGWidth : `${extensionWidth}%`,
        borderTop: isReportOrScreen ? '1px solid rgb(225, 225, 225)' : 'none',
        height: 'inherit',
      }}
      className='line-extension'
    >
      <LineChart
        id={id}
        isReportMode={isReportOrScreen}
        isScreenMode={isReportOrScreen}
        limitValuesDataFormat={limitValuesDataFormat}
        isSoftMin={options?.isSoftMin}
        isSoftMax={options?.isSoftMax}
        kpiTargetTo={options?.kpiTargetTo}
        kpiTargetFrom={options?.kpiTargetFrom}
        kpiTargetAreaColor={options?.kpiTargetAreaColor}
        kpiTargetCustomColor={options?.kpiTargetCustomColor}
        kpiTargetDataFormat={options?.kpiTargetDataFormat}
        kpiTargetLabel={options?.kpiTargetLabel}
        kpiTargetLabelPosition={options?.kpiTargetLabelPosition}
        limitValues={limitValues}
        isRollingAvgDisabled={true}
        showAxisLines={false}
        rollingAvgCounts={null}
        showTotals={!!options?.showTotals}
        breakpoints={breakpoints}
        data={isNull(outerLines) ? innerLines : outerLines}
        chartComponentRef={chartComponentRef}
        chartThemeKey=''
        dataFormat={dataFormat}
        filterGrouping={dateGroup}
        handleSaveBreakpoints={handleSaveBreakpoints}
        handleSaveDataFormat={handleSaveLineChartDataFormat}
        handleSaveDateGroup={handleSaveLineChartDateGroup}
        handleSaveShowTotals={handleSaveLineChartShowTotals}
        isRefetching={isRefetching}
        query={query}
        categories={categories}
      />
    </div>
  )
}

export default Line
