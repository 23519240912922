import { TextualKpiData } from '../openTypes'

export type OpenCategoryToolDataContext = {
  kpiName: string
  answersForThisKpi: TextualKpiData[]
  index: number
  openAnswer: TextualKpiData | null
  customCategories: CategoriesAndValues | null
  allCategories: CategoriesAndValues
  filteredCategories: CategoriesAndValues
  initialCategoryValues: CategoriesAndValues
  updatedCategoryValues: CategoriesAndValues
  isCategoryChangingAllowed: boolean
  isUnsavedCategoryChanges: boolean
  isFilterOnFocus: boolean
  isInfoOpen: boolean
}

export type HotkeysDataContext = {
  hotkeys: CategoriesWithHotkeys[]
  saveHotkeys: (newHotkeys: CategoriesWithHotkeys[]) => void
  isHotkeySetupMaskOn: boolean
  isHotkeysEnabled: boolean
}

export type OpenCategoryToolContextProps = {
  onClose: (categoryToolOpen: boolean) => void
  kpiName: string
  answersForThisKpi: TextualKpiData[]
  index: number
  openAnswer: TextualKpiData
  topics: string[] | null
  customCategories: CategoriesAndValues | null
}

export type OpenCategoryToolModalProps = {
  onClose: (categoryToolOpen: boolean) => void
}

export type CategoriesAndValues = {
  [category: string]: string[]
}

export type CategoriesWithHotkeys = {
  category: string
  categoryValue: string
  hotkey: string
}

export enum SentimentCategory {
  Positive = 'Positive',
  Neutral = 'Neutral',
  Negative = 'Negative',
}
