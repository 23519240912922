import { isUndefined } from 'lodash'
import { Kpi, KpiData, Query } from '../../../../../../types'
import { LineSeriesData } from '../wheelModuleTypes'
import { CustomerPathKpi } from '../../../../../stores/useConfigCustomerPath'
import { LineChartDataFormats } from '../../Line/lineChartTypes'

export const staleMadeUpId = 999999999
const useLineSeriesBuilders = (
  numericKpis: Kpi.Kpi[] | null,
  categories: CustomerPathKpi[] | undefined,
  lineCounts: KpiData.NumericData | null,
  dataFormat: LineChartDataFormats | undefined,
  dateGroup: Query.TimeRes | undefined,
) => {
  const createLineSeriesFromCircleState = () => {
    if (!numericKpis || !categories || !lineCounts) return null
    const { categories: timeCategories, series } = lineCounts
    return series.reduce((series, timeSeries) => {
      if (!timeCategories || !timeCategories.length) return series
      const kpiName = timeSeries.name
      if (!kpiName) return series
      const kpiData = timeSeries.data
      if (!kpiData || !kpiData.length) return series
      const kpiId = numericKpis.find((k) => k.name === kpiName)?.id
      if (isUndefined(kpiId)) return series
      const matchingCategories = categories.filter((c) => c.kpiid === kpiId)
      if (!matchingCategories.length) return series
      const matchingCategoryNames = matchingCategories.map((c) => c.category || '').filter((n) => n)
      const newSeries = { ...series }

      for (let i = 0; i < matchingCategoryNames.length; i++) {
        const currentCategory = matchingCategoryNames[i]
        let currentCategorySeries = newSeries[currentCategory]
        if (currentCategorySeries) continue
        if (!currentCategorySeries) currentCategorySeries = {}
        for (let j = 0; j < timeCategories.length; j++) {
          const currentDate = timeCategories[j]
          let currentDateSeries = currentCategorySeries[currentDate]
          if (!currentDateSeries) currentDateSeries = { avg: null, n: 0, sum: 0 }
          currentCategorySeries[currentDate] = currentDateSeries
          newSeries[currentCategory] = currentCategorySeries
        }
      }

      for (let i = 0; i < kpiData.length; i++) {
        const currentDateSeries = kpiData[i]
        if (!currentDateSeries) continue
        const currentDateData = currentDateSeries.data
        if (!currentDateData) continue
        const currentDate = currentDateSeries.category
        if (!currentDate) continue
        const { n, sum } = currentDateData
        if (!n) continue

        for (let j = 0; j < matchingCategoryNames.length; j++) {
          const currentCategory = matchingCategoryNames[j]
          const currentCategorySeries = newSeries[currentCategory]
          if (!currentCategorySeries) continue
          const currentDateSeries = currentCategorySeries[currentDate]
          if (!currentDateSeries) continue
          currentDateSeries.n += n
          currentDateSeries.sum += sum
          currentDateSeries.avg =
            currentDateSeries.n > 0 ? currentDateSeries.sum / currentDateSeries.n : null
          currentCategorySeries[currentDate] = currentDateSeries
          newSeries[currentCategory] = currentCategorySeries
        }
      }
      return newSeries
    }, {} as { [category: string]: LineSeriesData | undefined })
  }

  const createInnerCircleLineChartData = (
    innerLineSeries: {
      [category: string]: LineSeriesData | undefined
    } | null,
  ): KpiData.NumericData | null => {
    if (!innerLineSeries || !lineCounts) return null
    const innerLinePointOptions = Object.entries(innerLineSeries).map<KpiData.NumericKpiData>(
      ([category, counts]) => {
        return {
          type: 'line',
          data: Object.entries(counts || {})
            .map<KpiData.NumericKpiDataPoint>(([date, count], i) => {
              return {
                category: date,
                data: {
                  avg: count?.avg || 0,
                  category: date,
                  id: staleMadeUpId,
                  n: count?.n || 0,
                  sum: count?.sum || 0,
                  time_res: dateGroup || 'month',
                },
                format: dataFormat || LineChartDataFormats.AVG,
                value: LineChartDataFormats.AVG
                  ? count?.avg || 0
                  : LineChartDataFormats.N
                  ? count?.n || 0
                  : LineChartDataFormats.SUM
                  ? count?.sum || 0
                  : 0,
                x: i,
                y: count?.avg || 0,
              }
            })
            .filter((point) => point.data?.n),
          name: category,
          is_trend: false,
        }
      },
    )
    return {
      categories: lineCounts.categories,
      series: innerLinePointOptions,
    }
  }

  const createOuterCircleLineChartData = (selectedCategory: string): KpiData.NumericData | null => {
    if (!categories || !numericKpis || !lineCounts) return null
    const matchedCategories = categories.filter((c) => c.category === selectedCategory)
    const matchedKpiNames = matchedCategories
      .map((c) => numericKpis.find((kpi) => kpi.id === c.kpiid)?.name)
      .filter((n) => n)
    const matchedSeries = lineCounts.series.filter((s) => matchedKpiNames.includes(s.name))
    return {
      categories: lineCounts.categories,
      series: matchedSeries,
    }
  }
  return {
    createLineSeriesFromCircleState,
    createInnerCircleLineChartData,
    createOuterCircleLineChartData,
  }
}

export default useLineSeriesBuilders
