export const errorLoadingModule = 'There was an error loading the module'
export const errorLoadingBreakpoints = 'There was an error loading linechart breakpoint modal'
export const errorLoadingInspector = 'There was an error loading inspector'

export const infoTargetFromTo =
  'If both "to" and "from" targets\nare given, a target area is\ndisplayed, if only either is given,\na target line is displayed'
export const infoTargetDataFormat = 'Target is only to appear with this\ndata format'
export const infoSoftMin =
  'Enable soft mode if you want\nthe data that overflows to be\nshown and min to strech according\nto the data'
export const infoSoftMax =
  'Enable soft mode if you want\nthe data that overflows to be\nshown and max to strech according\nto the data'
export const infoNoKpisSelected = 'Select atleast one kpi for line chart'
