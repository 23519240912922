import React, { useEffect } from 'react'
import { useToggle } from 'react-use'
import { useOpenCategoryToolData } from './OpenCategoryToolProvider'
import css from './OpenCategoryToolModal.module.scss'
import { Info } from '@mui/icons-material'
import { tCommon } from '../../../../../../languages/i18n'

const OpenCategoryToolInfo = () => {
  const setOpenCategoryToolData = useOpenCategoryToolData()[1]
  const [showInfo, toggleShowInfo] = useToggle(false)

  useEffect(() => {
    setOpenCategoryToolData((prev) => ({
      ...prev,
      isInfoOpen: showInfo,
    }))
  }, [showInfo])

  return (
    <div className={css.category_tool_info}>
      <div className={css.info_button} onClick={toggleShowInfo}>
        <Info sx={{ fontSize: '22px' }} />
        <span>{showInfo ? tCommon('button.hideInfo') : tCommon('button.showInfo')}</span>
      </div>

      {showInfo && (
        <div className={css.info_content}>
          <p>
            <b>{tCommon('info.categoryTool1')}</b>
            {tCommon('info.categoryTool2')}
          </p>
          <p>{tCommon('info.categoryTool3')}</p>

          <p>
            <b>{tCommon('info.categoryTool4')}</b>
            {tCommon('info.categoryTool5')}
          </p>
          <p>{tCommon('info.categoryTool6')}</p>
        </div>
      )}
    </div>
  )
}

export default OpenCategoryToolInfo
