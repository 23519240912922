import { tCategory, tCommon } from '../../../../../../languages/i18n'
import { allChartColors } from '../../../../../../styles/variableExport'
import { pSBC } from '../../../../../react-services/colorService'

export interface PointExtension {
  custom: { extraInformation: number }
}
const splineColor = pSBC(-0.4, allChartColors.GREY) || 'black'
const lineColor = pSBC(-0.37, allChartColors.GREY) || 'black'
type PointExtended = Highcharts.Point & PointExtension
export const createChart = (
  isChartWithSmall: boolean,
  isReportMode: boolean,
  isScreenMode: boolean,
  chartWidth: number,
): Highcharts.Options => {
  return {
    chart: {
      marginRight: isChartWithSmall ? 60 : 80,
      marginLeft: isChartWithSmall ? 60 : 80,
      marginTop: 80,
      backgroundColor: 'rgba(148, 191, 214, 0.09)',
      reflow: true,
      width: chartWidth && (isReportMode || isScreenMode) ? chartWidth : undefined,
      animation: { duration: 1200 },
      zooming: {
        type: 'xy',
      },
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      visible: true,
      labels: {
        style: {
          color: pSBC(-0.4, allChartColors.GREY) || 'black',
        },
      },
    },
    exporting: {
      sourceWidth: 1800,
      sourceHeight: 675,
      scale: 1.5,
      buttons: {
        contextButton: {
          y: 10,
          x: -10,
        },
      },
    },
    title: {
      text: '',
      verticalAlign: 'top',
      align: 'left',
      floating: false,
      x: 40,
      style: {
        color: pSBC(-0.4, allChartColors.GREY) || 'black',
      },
    },
    subtitle: {
      text: '',
      align: 'left',
      verticalAlign: 'top',
      floating: false,
      x: 40,
      style: {
        color: pSBC(-0.4, allChartColors.GREY) || 'black',
      },
    },

    yAxis: [
      {
        endOnTick: false,
        title: {
          text: tCommon('label.share') + ' %',
          margin: 5,
          style: {
            color: pSBC(-0.4, allChartColors.GREY) || 'black',
          },
        },
        labels: {
          x: isChartWithSmall ? -8 : -15,
          style: {
            color: pSBC(-0.4, allChartColors.GREY) || 'black',
          },
        },
        tickAmount: 11,
        min: 0,
        max: 100,
        gridZIndex: 10,
        gridLineColor: 'rgba(0, 0, 0, 0.1)',
      },
      {
        endOnTick: false,
        title: {
          text: '',
          margin: 5,
          style: {
            color: pSBC(-0.4, allChartColors.GREY) || 'black',
          },
        },
        labels: {
          x: isChartWithSmall ? 8 : 15,
          style: {
            color: pSBC(-0.4, allChartColors.GREY) || 'black',
          },
        },
        opposite: true,
        gridLineColor: 'rgba(0, 0, 0, 0.1)',
        min: 0,
        gridLineWidth: 0,
        visible: true,
      },
    ],
    tooltip: {
      shared: true,
      distance: 100,
      headerFormat: '<span style="font-size:16px"><b>{point.key}</b></span><br>',
      pointFormatter: function () {
        if (this.series.type === 'line') {
          const point = this as PointExtended
          return `<span style="color:${point.color}">●</span> ${
            point.series.name
          } slope: <b>${point.custom.extraInformation.toFixed(4)}</b><br/>`
        } else {
          return `<span style="color:${this.color}">●</span> ${
            this.series.name
          }: <b>${this.y?.toFixed(1)}</b><br/>`
        }
      },
      padding: 20,
      style: {
        padding: '20px',
      },
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#666666',
        },
      },
      spline: {
        color: splineColor,
      },
      line: {
        color: lineColor,
      },
    },
    legend: {
      itemStyle: {
        color: pSBC(-0.4, allChartColors.GREY) || 'black',
        fontSize: '13px',
      },
      labelFormatter: function () {
        return tCategory(this.name)
      },
    },
  }
}
