import { addDays, format, subDays } from 'date-fns'
import { DEFAULT_DATE_FORMAT } from '../../../../react-services/datesService'

const useGroupModuleDates = () => {
  const getPastTimeFrame = (
    days: number,
    isResetEndDateEnabled: boolean,
    savedEndDate?: string,
  ) => {
    const endDateObject =
      isResetEndDateEnabled || !savedEndDate ? new Date() : new Date(savedEndDate)
    return {
      startDate: format(subDays(endDateObject, days), DEFAULT_DATE_FORMAT),
      endDate: format(endDateObject, DEFAULT_DATE_FORMAT),
    }
  }

  const getFutureTimeFrame = (
    days: number,
    isResetEndDateEnabled: boolean,
    savedStartDate?: string,
  ) => {
    const startDateObject =
      isResetEndDateEnabled || !savedStartDate ? new Date() : new Date(savedStartDate)
    return {
      startDate: format(startDateObject, DEFAULT_DATE_FORMAT),
      endDate: format(addDays(startDateObject, days), DEFAULT_DATE_FORMAT),
    }
  }

  return {
    getPastTimeFrame,
    getFutureTimeFrame,
  }
}

export default useGroupModuleDates
