import { createStateContext } from 'react-use'
import { GroupModule } from '../groupModuleTypes'
import { FilterArray } from '../../../../../react-services/filterService'

export type GroupModuleContext = {
  module: GroupModule | null
  index: number | null
  isNested?: boolean
  parentFilters?: FilterArray
}

export const [useGroupModule, GroupModuleProvider] = createStateContext<GroupModuleContext>({
  index: null,
  module: null,
})
