import React, { memo, useEffect, useMemo } from 'react'
import FrequencyModuleCntr, { FrequencyModuleCntrProps } from './FrequencyModuleCntr'
import {
  DeprecatedBarModule,
  FrequencyChartType,
  FrequencyComparisonType,
  FrequencyModule,
  FrequencyOrder,
  FrequencyQueryType,
  NumericKpiFormat,
} from './frequencyModuleTypes'
import { cloneDeep, isEqual, isNull, isUndefined } from 'lodash'
import { ToastProvider } from '../../../common/Notification/NotificationContext'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingModule } from './FrequencyNotifications'
import useOpenKpis, { idAndNameKpi } from '../../../../stores/useOpenKpis'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { isAllowedToFetch } from '../Group/contexts/renderListReducer'
import { QueueStatus } from '../Group/groupModuleTypes'

const arePropsEqual = (
  prevProps: FrequencyModuleCntrProps,
  currentProps: FrequencyModuleCntrProps,
) => {
  return isEqual(prevProps, currentProps)
}
const MemoizedFrequencyModuleCntr = memo(FrequencyModuleCntr, arePropsEqual)

type FrequencyModuleConverterProps = {
  module: FrequencyModule | DeprecatedBarModule
  sharedFilter?: SharedFilter
  saveModule: (module: FrequencyModule) => void
  id: string
  isNested?: boolean
  isReportMode: boolean
  isScreenMode: boolean
  settingsWrapperRef?: React.MutableRefObject<HTMLDivElement | undefined>
  moduleStatus: QueueStatus | undefined
}

const FrequencyModuleConverter = memo((props: FrequencyModuleConverterProps) => {
  const moduleStatus = props.moduleStatus
  let module: FrequencyModule | null = null
  if (props.module.type === 'bar') {
    module = handleConvertingBarChartToFrequencyChart(props.module)
  } else {
    module = cloneDeep(props.module)
  }

  const { openKpis: tenantOpenKpis } = useOpenKpis()
  const grouping = useMemo(() => {
    let newGrouping = ''
    if (!module) return newGrouping
    if (module.filterGrouping) {
      newGrouping = module.filterGrouping
      delete module.filterGrouping
    } else {
      newGrouping = module.grouping || ''
    }
    return newGrouping
  }, [module?.filterGrouping, module?.grouping])

  const realChartType = useMemo(() => {
    const isBarType =
      module?.horizontalBars === true && module?.chartType === FrequencyChartType.BAR
    if (isBarType) return FrequencyChartType.BAR
    const isColumnType =
      module?.horizontalBars === false && module?.chartType === FrequencyChartType.BAR
    if (isColumnType) return FrequencyChartType.COLUMN
    if (!isUndefined(module?.horizontalBars)) delete module?.horizontalBars
    if (!module?.chartType) return FrequencyChartType.COLUMN
    return module?.chartType
  }, [module?.chartType, module?.horizontalBars])

  const openKpis = useMemo(() => {
    const deprecatedOpenKpiIdValue = module?.KPI
    if (
      isUndefined(deprecatedOpenKpiIdValue) ||
      isNull(deprecatedOpenKpiIdValue) ||
      !tenantOpenKpis ||
      module?.options?.openKpis?.length
    )
      return module?.options?.openKpis || []
    const selectedKpis: idAndNameKpi[] = []
    let matchingOpenKpi = tenantOpenKpis.find((kpi) => kpi.id === deprecatedOpenKpiIdValue)
    if (!matchingOpenKpi) matchingOpenKpi = { id: deprecatedOpenKpiIdValue, name: '' }
    selectedKpis.push(matchingOpenKpi)
    if (!isUndefined(module?.KPI)) delete module?.KPI
    return selectedKpis
  }, [tenantOpenKpis, module?.KPI, module?.options?.openKpis])

  const convertedModule: FrequencyModule | null = useMemo(() => {
    const oldOptions = module?.options
    if (!module) return null
    return {
      ...module,
      type: 'freq' as const,
      chartType: realChartType,
      grouping: grouping,
      options: { ...(oldOptions || {}), openKpis },
    }
  }, [realChartType, grouping, openKpis, module])

  useEffect(() => {
    if (isEqual(props.module, convertedModule) || !convertedModule) return
    if (isAllowedToFetch(moduleStatus)) props.saveModule(convertedModule)
  }, [convertedModule, moduleStatus])

  if (!convertedModule) return <div></div>
  return (
    <ToastProvider id={props.id}>
      <NotificationContainer id={props.id} />
      <ErrorBoundary
        message={errorLoadingModule}
        containerId={props.id}
        fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
      >
        <MemoizedFrequencyModuleCntr {...props} module={convertedModule} />
      </ErrorBoundary>
    </ToastProvider>
  )
})

const handleConvertingBarChartToFrequencyChart = (module: DeprecatedBarModule) => {
  const newFrequencyModule: FrequencyModule = {
    id: module.id,
    type: 'freq',
    title: module.title,
    query: module.query,
    chartType: module.horizontalBars === true ? FrequencyChartType.BAR : FrequencyChartType.COLUMN,
    options: {
      comparisonType: module.filterGrouping ? FrequencyComparisonType.META : undefined,
      modulewidth: module.options?.modulewidth,
      numericKpiMetaGrouping: module.filterGrouping,
      numericKpiDataFormat:
        module.yAxis === 'avg'
          ? NumericKpiFormat.AVG
          : module.yAxis === 'sum'
          ? NumericKpiFormat.SUM
          : NumericKpiFormat.N,
    },
    queryType: FrequencyQueryType.NUMERIC_KPI,
    selections: module.selections,
    limitValues: module.limitValues,
    grouping: module.filterGrouping,
    filterGrouping: module.filterGrouping,
    order: FrequencyOrder.NONE,
    settings: [],
    hasComparison: module.filterGrouping ? true : false,
  }
  return newFrequencyModule
}
FrequencyModuleConverter.displayName = 'FrequencyModuleConverter'
export default FrequencyModuleConverter
