import React from 'react';
import { Row, Col } from 'react-materialize';
import { ManualSenderContext } from './_ManualSenderContext';

const ManualSenderRecipientsTop = ({isEmpty}) => {
  return (
    <>
      <span className="manual-survey-span">Recipients</span>
      <Row>
        {isEmpty ?
          <h5 className="center grey-text">Add a recipient</h5>
        :
        <ManualSenderContext.Consumer>
          {({useContacts}) =>
            <div className="grey-text">
              {useContacts ?
                <>
                  <Col s={10} className="offset-s1">
                    <span data-jt="recipientsEmailTopLabel">contact</span>
                  </Col>
                  <Col s={1} style={{paddingLeft: '0px'}}>
                    <span>via</span>
                  </Col>
                </>
              :
                <>
                  <Col s={6} className="offset-s1">
                    <span data-jt="recipientsEmailTopLabel">email or phone</span>
                  </Col>
                  <Col s={5}>
                    <span>full name</span>
                  </Col>
                </>
              }
            </div>
          }
          </ManualSenderContext.Consumer>
        }
      </Row>
    </>
  );
};

export default ManualSenderRecipientsTop;