/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import NumberTrendModuleCntr from '../../NumberTrend/NumberTrendModuleCntr'
import TitleSubtitle from './TitleSubtitle'
import PictureModuleCntr from '../../Picture/PictureModuleCntr'
import WheelModuleCntr from '../../Wheel/WheelModuleCntr'
import FrequencyModuleConverter from '../../Frequency/FrequencyModuleConverter'
import LineModuleContainer from '../../Line/LineModuleContainer'
import RadarModuleContainer from '../../Radar/RadarModuleContainer'
import TextBoxPropsConverter from '../../TextBox/TextBoxPropsConverter'
import TabularModuleConverter from '../../Tabular/TabularModuleConverter'
import BubbleModuleContainer from '../../Bubble/BubbleModuleContainer'
import PietabularModuleContainer from '../../Pietabular/PietabularModuleContainer'
import TopBottomModuleContainer from '../../TopBottom/TopBottomModuleContainer'
import WrappedOpenModuleContainer from '../../Open/OpenModuleContainer'
import ScreenRepeater from './ScreenRepeater'
import ErrorBoundary from '../../../../_shared/Infos/ErrorBoundary'
import { RenderProvider } from '../../Group/contexts/RenderContext'
import { cloneDeep } from 'lodash'
import { QueueStatus } from '../../Group/groupModuleTypes'
import { moduleDefaultHeight } from '../../../../../../styles/variableExport'

type ScreenListProps = {
  settings: any
  scrollPage: any
  readyModules: number
}
const ScreenList = ({ scrollPage, settings, readyModules }: ScreenListProps) => {
  const [previousModuleLoaded, setPreviousModuleLoaded] = useState(false)
  const moduleOrderIdx = getModuleOrderIndex(settings)

  function getModuleOrderIndex(module: any) {
    if (module) return module.module_order_idx
  }

  function generateSubtitleFromTimeframe(module: any) {
    try {
      return `${module.query.start_date} - ${module.query.end_date}`
    } catch (e) {
      return ''
    }
  }

  const fallBackId = useMemo(() => uuid(), [])

  const renderTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    initRenderTimeout()
    return () => {
      if (renderTimeout.current) clearTimeout(renderTimeout.current)
    }
  }, [])
  // Render after a timeout as fallback
  function initRenderTimeout() {
    if (!moduleOrderIdx) return
    renderTimeout.current = setTimeout(() => {
      setPreviousModuleLoaded(true)
    }, 20000)
  }

  function doRenderModule() {
    if (!moduleOrderIdx || previousModuleLoaded) {
      return true
    }
    if (readyModules < moduleOrderIdx + 2) {
      console.log('Start rendering ', moduleOrderIdx)
      setPreviousModuleLoaded(true)
      return true
    }
  }

  const getModule = (module: any) => {
    const id = module.id || fallBackId
    const newModule = cloneDeep(module)
    if (!newModule.id) newModule.id = id
    const saveModule = () => ({})
    const baseProps = {
      id,
      saveModule,
      module: newModule,
      moduleStatus: QueueStatus.NOT_IMPLEMENTED,
    }
    const reportOrScreen = {
      isReportMode: scrollPage ? true : false,
      isScreenMode: scrollPage ? false : true,
    }
    const titleProps = {
      titletext: settings.title,
      module: newModule,
      ...reportOrScreen,
    }
    switch (newModule.type) {
      case 'numbertrend':
        return (
          <div className='fullsize screen-module-container'>
            <TitleSubtitle {...titleProps} />
            <NumberTrendModuleCntr {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'picture':
        return (
          <div className='fullsize screen-module-container'>
            <TitleSubtitle {...titleProps} />
            <PictureModuleCntr {...baseProps} {...reportOrScreen} />
          </div>
        )

      case 'wheel': {
        const moduleSettingsHeight = Number(module.options?.moduleheight)
        const hasSetHeight = moduleSettingsHeight && !isNaN(moduleSettingsHeight)
        const hasLineEnabled = !!module.options?.showLine
        const hasWheelEnabled = module.options?.showWheel !== false
        const hasBothExtensionsEnabled = hasLineEnabled && hasWheelEnabled
        const heightMultiplier = hasBothExtensionsEnabled ? 2 : 1
        const moduleReportHeight = hasSetHeight
          ? moduleSettingsHeight * heightMultiplier
          : moduleDefaultHeight * heightMultiplier
        return (
          <div
            className='screen-module-container fullsize'
            style={{ height: moduleReportHeight, display: 'flex', flexDirection: 'column' }}
          >
            <TitleSubtitle {...titleProps} />
            <WheelModuleCntr {...baseProps} {...reportOrScreen} />
          </div>
        )
      }
      case 'bar':
        return (
          <div className='screen-module-container fullsize'>
            <TitleSubtitle {...titleProps} subtitle={settings.subtitle} />
            <FrequencyModuleConverter {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'bubble':
        return (
          <div className='screen-module-container'>
            <TitleSubtitle {...titleProps} />
            <BubbleModuleContainer module={newModule} />
          </div>
        )
      case 'freq':
        return (
          <div className='screen-module-container fullsize'>
            <TitleSubtitle {...titleProps} subtitle={settings.subtitle} />
            <FrequencyModuleConverter {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'group':
        return (
          <ScreenRepeater
            modulesettingslist={newModule.submodulesettings}
            scrollPage={scrollPage}
            readyModules={readyModules}
          />
        )
      case 'line': {
        const subtitle = settings.subtitle || generateSubtitleFromTimeframe(settings)
        return (
          <div className='screen-module-container fullsize'>
            <TitleSubtitle {...titleProps} subtitle={subtitle} />
            <LineModuleContainer {...baseProps} {...reportOrScreen} />
          </div>
        )
      }
      case 'listnumeric':
        return (
          <div className='screen-module-container fullsize' style={{ height: 'fit-content' }}>
            <TitleSubtitle {...titleProps} />
            <TopBottomModuleContainer {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'open':
        return (
          <div className='vertical-scroll fullsize screen-module-container module-flex-container'>
            <TitleSubtitle {...titleProps} />
            <WrappedOpenModuleContainer {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'pietabular': {
        return (
          <div className='screen-module-container' style={{ height: 'fit-content' }}>
            <TitleSubtitle {...titleProps} />
            <PietabularModuleContainer {...baseProps} {...reportOrScreen} />
          </div>
        )
      }
      case 'radar':
        return (
          <div className='screen-module-container'>
            <TitleSubtitle {...titleProps} />
            <RadarModuleContainer {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'reports_overview':
        return <></>
      case 'table':
        return (
          <div className='vertical-scroll screen-module-container'>
            <TitleSubtitle {...titleProps} />
            <TabularModuleConverter {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'tabular':
        return (
          <div className='vertical-scroll screen-module-container'>
            <TitleSubtitle {...titleProps} />
            <TabularModuleConverter {...baseProps} {...reportOrScreen} />
          </div>
        )
      case 'textbox':
        return (
          <div className='vertical-scroll screen-module-container'>
            <div className='module-flex-container fullsize'>
              <TitleSubtitle {...titleProps} />
              <TextBoxPropsConverter {...baseProps} {...reportOrScreen} />
            </div>
          </div>
        )
      default:
        return (
          <div style={{ width: 'inherit', height: 'inherit' }} className='screen-module-container'>
            Unsupported module type.
          </div>
        )
    }
  }

  if (!doRenderModule()) return <div className='waiting-to-render'></div>
  return (
    <ErrorBoundary
      message={'Error loading module: ' + settings.type}
      containerId={settings.id || fallBackId}
      fallback={<div style={{ color: 'red' }}>{'Error loading module: ' + settings.type}</div>}
    >
      <RenderProvider
        customInitList={{
          root: {
            groupId: 'root',
            id: settings.id || fallBackId,
            status: QueueStatus.NOT_IMPLEMENTED,
            type: settings.type,
          },
        }}
      >
        {getModule(settings)}
      </RenderProvider>
    </ErrorBoundary>
  )
}

export default ScreenList
