import React from 'react'
import { OpenModule } from '../openTypes'
import { Input } from 'react-materialize'
import useOpenKpis from '../../../../../stores/useOpenKpis'
import ClearOrSelectAll from './ClearOrSelectAll'

import css from './KpiSelection.module.scss'
import { tKpi } from '../../../../../../languages/i18n'
import { cloneDeep } from 'lodash'

type KpiSelectionProps = {
  module: OpenModule
  saveModule: (module: OpenModule) => void
  settingsKpis: { name: string; id: number }[] | null
  handleClose: () => void
}
const KpiSelection = ({ module, saveModule, settingsKpis, handleClose }: KpiSelectionProps) => {
  const { openKpis } = useOpenKpis()

  let selectedKpis = [] as { name: string; id: number }[]
  if (settingsKpis) {
    selectedKpis = settingsKpis
  } else {
    selectedKpis = openKpis || []
  }

  const handleClick = (kpi: { name: string; id: number }) => {
    let newSelectedKpis = [] as { name: string; id: number }[]
    if (selectedKpis.map((k) => k.id).includes(kpi.id)) {
      newSelectedKpis = selectedKpis.filter((c) => c.id !== kpi.id)
    } else {
      newSelectedKpis = selectedKpis.concat(kpi)
    }
    const newModule = cloneDeep(module)
    if (newModule.query) {
      newModule.query.kpis = newSelectedKpis
    }
    saveModule(newModule)
  }

  const handleClearAll = () => {
    const newModule = cloneDeep(module)
    if (newModule.query) {
      newModule.query.kpis = []
    }
    saveModule(newModule)
  }

  const handleSelectAll = () => {
    if (!openKpis) return
    const newModule = cloneDeep(module)
    if (newModule.query) {
      newModule.query.kpis = openKpis
    }
    saveModule(newModule)
  }

  return (
    <div className={css.flexCntr} data-testid='kpiSelectionCntr'>
      <div className={css.cntr}>
        {!!openKpis &&
          openKpis.map((kpi) => (
            <Input
              key={kpi.id}
              className={'filled-in alerts-list-checkbox'}
              data-testid='kpiInput'
              type='checkbox'
              label={tKpi(kpi.name)}
              value={kpi.name}
              checked={selectedKpis.map((k) => k.id).includes(kpi.id) || !selectedKpis.length}
              onClick={() => handleClick(kpi)}
            />
          ))}
      </div>
      <ClearOrSelectAll
        handleClear={handleClearAll}
        handleSelect={handleSelectAll}
        handleClose={handleClose}
      />
    </div>
  )
}

export default KpiSelection
