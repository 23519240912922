import React, { Fragment, useRef } from 'react'
import { useSettingsTools } from './../SettingsContext'
import * as Highcharts from 'highcharts8'
import RequiredStar from './RequiredStar'

import modal_css from './../SettingsModal.module.scss'
import css from './RadioButtonsInput.module.scss'

type RadioButtonsInputProps = {
  buttons: (string | number)[]
  title: string | JSX.Element
  settingsName: string
  reflowCharts?: boolean
  classNames?: string
  type?: 'text' | 'number'
  data_testid?: string
  isRequired?: boolean
  width?: 'half' | 'third' | 'full'
}

const RadioButtonsInput = ({
  buttons,
  title,
  type = 'text',
  settingsName,
  reflowCharts = false,
  classNames,
  data_testid,
  isRequired = false,
  width = 'half',
}: RadioButtonsInputProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ module, saveModule }] = useSettingsTools()
  const options = module && module.options
  const value = (options && options[settingsName as keyof unknown]) || ''

  let isPresent = true
  if (
    isRequired &&
    (value === null ||
      value === undefined ||
      value === '' ||
      (typeof value !== 'number' && typeof value !== 'string'))
  ) {
    isPresent = false
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!module) return
    try {
      const value = e.currentTarget.value
      const parsedValue = type === 'number' ? Number(value) : value
      const newModule = { ...module, options: { ...options, [settingsName]: parsedValue } }
      saveModule(newModule)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        reflowCharts && Highcharts.charts.forEach((chart) => chart?.reflow())
      }, 200)
      e.currentTarget.blur()
    } catch (error) {
      // TODO: toast
    }
  }

  const addSelectedClass = (selected: boolean) => {
    if (!selected) return ''
    return css.selected
  }

  const getWidthClass = () => {
    if (width === 'half') {
      return modal_css.half
    }
    if (width === 'third') {
      return modal_css.third
    }
    if (width === 'full') {
      return modal_css.max
    }
    return modal_css.half
  }

  return (
    <div className={`${classNames || ''} ${css.cntr} ${getWidthClass()}`}>
      {title}
      {isRequired && <RequiredStar />}
      <div className={`${css.btns_cntr} ${isPresent ? '' : modal_css.required}`}>
        {buttons.map((btn, i) => {
          const selected = value?.toString() === btn.toString()
          return (
            <Fragment key={btn}>
              <input
                name={`${btn}${i}`}
                id={`${btn}${i}`}
                type='radio'
                value={btn}
                onChange={handleOnChange}
                checked={selected}
                data-testid={data_testid}
              />
              <label
                htmlFor={`${btn}${i}`}
                className={`${addSelectedClass(selected)} ${css.label}`}
                data-cy={`${data_testid}${btn}`}
              >
                {btn}
              </label>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default RadioButtonsInput
