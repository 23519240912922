import { KpiData, Query } from '../../types'
import { errorFetchingNumericCounts } from '../stores/notificationMessages'
import { isNumericDataResponseObject } from '../utilities'
import { post } from './apiService'

const getNumericKpiCounts = (
  query: Query.Numeric | Query.SingleNumeric,
): Promise<KpiData.NumericData> => {
  try {
    return post('POST_NUMERIC', query)
      .then((res: unknown) => {
        if (isNumericDataResponseObject(res)) {
          return Promise.resolve(res)
        } else {
          throw Error(errorFetchingNumericCounts)
        }
      })
      .catch(() => Promise.reject(errorFetchingNumericCounts))
  } catch (e) {
    return Promise.reject(errorFetchingNumericCounts)
  }
}

export { getNumericKpiCounts }
