"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.determineTextValueForJsxElement = determineTextValueForJsxElement;
exports.sortByIndexReference = sortByIndexReference;
exports.sortByKey = sortByKey;

var _lodash = require("lodash");

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

/*  Sort an array in place according to a reference of indices
*/
function sortByIndexReference(sorted, ref) {
  if (ref.length !== sorted.length) throw new Error('Arrays are not equal in length');
  var copy = [].concat(sorted);
  sorted.forEach(function (el, i) {
    var item = copy[ref[i]];
    sorted[i] = item;
  });
}
/* Generic table helper function: find text/number value inside JSX element
 <span> element takes precedence over other elements */


function determineTextValueForJsxElement(element) {
  if (!element) return '';
  if (typeof element === 'string') return element;
  if (typeof element === 'number') return element.toString();

  if (Array.isArray(element)) {
    try {
      // @ts-ignore
      return determineTextValueForJsxElement(element.find(function (el) {
        return el.type === "span";
      }).props.children);
    } catch (e) {//
    } // no span elements, try to find a string


    for (var i = 0; i < element.length; i++) {
      if (typeof element[i] === 'string') {
        return element[i];
      }
    } // no string element found, iterate through children


    for (var _i = 0; _i < element.length; _i++) {
      try {
        return determineTextValueForJsxElement(element[_i].props.children);
      } catch (e) {//
      }
    }

    console.error("Oy mate, all of your table's TD's need to have a regular string (or a string in <span>) as a child element! Falling back to using an empty string.");
    return '';
  }

  if (_typeof(element) === 'object') return determineTextValueForJsxElement(element.props.children);
  throw new Error("Unknown input received: ".concat(element, " which seems to be of type ").concat(_typeof(element)));
}

function sortByKey(target, key) {
  var isReversed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var result = (0, _lodash.cloneDeep)(target);
  result.sort(function (a, b) {
    return a[key].toString().localeCompare(b[key].toString(), undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });

  if (isReversed) {
    return result.reverse();
  } else {
    return result;
  }
}