import React, { memo, useEffect } from 'react'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import { ToastProvider } from '../../../common/Notification/NotificationContext'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import {
  RadarDataDisplay,
  RadarDataSentiment,
  RadarFilters,
  RadarModule,
  RadarModuleSettings,
} from './radarTypes'
import { initChartOptions } from './RadarChartOptions/InitChartOptions'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { RadarDataProvider, useRadarData } from './RadarContext/RadarDataContext'
import { RadarSettingsProvider, useRadarSettings } from './RadarContext/RadarSettingsContext'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import RadarModuleComponent from './RadarModuleComponent'
import { cloneDeep, isEqual } from 'lodash'

type RadarModuleContainerProps = {
  module: RadarModule
  sharedFilter?: SharedFilter
  id: string
  isScreenMode: boolean
  isReportMode: boolean
  saveModule: (module: RadarModule) => void
}
const RadarModuleContainer = memo(
  ({
    id,
    isReportMode,
    isScreenMode,
    module,
    saveModule,
    sharedFilter,
  }: RadarModuleContainerProps) => {
    const saveModuleSettings = (options?: RadarModule['options']) => {
      if (options && !isEqual(options, module.options || {})) {
        const newModule = cloneDeep(module)
        newModule.options = options
        saveModule(newModule)
      }
    }
    return (
      <ToastProvider id={String(id)}>
        <NotificationContainer id={String(id)} />
        <ErrorBoundary
          message={'Error loading radar'}
          containerId={id}
          fallback={<div style={{ color: 'red' }}>{'Error loading radar'}</div>}
        >
          <RadarDataProvider>
            <RadarSettingsProvider>
              <RadarModuleProvider
                filters={sharedFilter?.filters}
                isReportMode={isReportMode}
                isScreenMode={isScreenMode}
                id={id}
                moduleQuery={module.query}
                saveSettings={saveModuleSettings}
                settings={module.options}
              />
            </RadarSettingsProvider>
          </RadarDataProvider>
        </ErrorBoundary>
      </ToastProvider>
    )
  },
)

type RadarModuleProviderProps = {
  moduleQuery: RadarModule['query']
  filters: SharedFilter['filters']
  id: string
  isScreenMode: boolean
  isReportMode: boolean
  saveSettings: (settings?: RadarModuleSettings) => void
  settings?: RadarModuleSettings
}
const RadarModuleProvider = ({ ...props }: RadarModuleProviderProps) => {
  const setRadarData = useRadarData()[1]
  const setRadarSettings = useRadarSettings()[1]

  useEffect(() => {
    setRadarData((prev) => ({
      ...prev,
      radarOptions: initChartOptions,
    }))
  }, [props.id])

  useEffect(() => {
    if (props.saveSettings) {
      setRadarSettings((prev) => ({
        ...prev,
        saveSettings: props.saveSettings,
      }))
    }
  }, [props.saveSettings])

  useEffect(() => {
    let dashboardFilters: RadarFilters = {
      start_date: '',
      end_date: '',
      where_meta: {},
    }
    if (props.filters) dashboardFilters = transformFiltersToWhereMetaForm(props.filters)
    setRadarSettings((prev) => ({
      ...prev,
      radarFilters: dashboardFilters,
    }))
  }, [props.filters])

  useEffect(() => {
    if (props.settings) {
      const settings = props.settings
      setRadarSettings((prev) => ({
        ...prev,
        radarSettings: settings ? settings : {},
        selectedTopics: settings.selectedTopics ? settings.selectedTopics : [],
        radarDataMode: settings.radarDataMode ? settings.radarDataMode : null,
        radarDataSentiment: settings.radarDataSentiment
          ? settings.radarDataSentiment
          : RadarDataSentiment.POSITIVE,
        radarDataDisplay: settings.radarDataDisplay
          ? settings.radarDataDisplay
          : RadarDataDisplay.COUNT,
        isAlphabetical: settings.isAlphabetical ? settings.isAlphabetical : false,
        radarMetaKey: settings.radarMetaKey ? settings.radarMetaKey : '',
        radarMetaKeyValues: settings.radarMetaKeyValues ? settings.radarMetaKeyValues : [],
        radarMetaKeyValuesSelected: settings.radarMetaKeyValuesSelected
          ? settings.radarMetaKeyValuesSelected
          : [],
      }))
    }
  }, [props.settings])

  return <RadarModuleComponent />
}

RadarModuleContainer.displayName = 'RadarModuleContainer'
export default RadarModuleContainer
