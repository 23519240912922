import React, { useEffect, useMemo, useState } from 'react'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import { SummarySettings, SummaryTimePeriod, TimeFrame } from '../summaryTypes'
import { months as allMonths } from '../summaryConstants'
import Selection from './Selection'
import { getQuarter, subMonths } from 'date-fns'
import { isEqual, isUndefined } from 'lodash'
import { useSummaryData } from '../Context/SummaryDataContext'
import useSummaryLanguage from '../useSummaryLanguage'
import useSummariesTimeFrame from '../useSummariesTimeFrame'

import css from './SummaryFilters.module.scss'

const SelectTimeframe = () => {
  const [
    {
      language,
      filterTimeFrame,
      filterTimePeriod: selectedTimePeriod,
      filterFirstYear,
      summarySettings,
    },
    setSummaryFilters,
  ] = useSummaryFilters()
  const { getTimeGroupLastIndex, getTimeGroupLastValue } = useSummariesTimeFrame(
    filterTimeFrame,
    selectedTimePeriod,
  )
  const { t } = useSummaryLanguage(language)
  const [{ saveSettings }] = useSummaryData()
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame | null>(null)

  const months: string[] = useMemo(() => {
    const monthStrings = allMonths[language] || []
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const isSelectedYearCurrentYear = currentYear === selectedTimeFrame?.year
    let lastMonth = 11
    if (isSelectedYearCurrentYear) lastMonth = currentDate.getMonth()
    return monthStrings.filter((_, i) => i <= lastMonth)
  }, [language, selectedTimeFrame])

  const quarters: string[] = useMemo(() => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const isSelectedYearCurrentYear = currentYear === selectedTimeFrame?.year
    let lastQuarter = 4
    if (isSelectedYearCurrentYear) lastQuarter = getQuarter(currentDate)
    const newQuarters: string[] = []
    for (let i = 1; i <= lastQuarter; i++) {
      newQuarters.push(`Q${i}`)
    }
    return newQuarters
  }, [selectedTimeFrame])

  const yearHalfs: string[] = useMemo(() => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()
    const isSelectedYearCurrentYear = currentYear === selectedTimeFrame?.year
    let lastHalf = 2
    if (isSelectedYearCurrentYear) lastHalf = currentMonth > 5 ? 2 : 1
    const newHalfs: string[] = []
    for (let i = 1; i <= lastHalf; i++) {
      newHalfs.push(`H${i}`)
    }
    return newHalfs
  }, [selectedTimeFrame])

  const years: number[] = useMemo(() => {
    const firstYear = filterFirstYear && !isNaN(Number(filterFirstYear)) ? +filterFirstYear : 2021
    const currentYear = new Date().getFullYear()
    const newYears = [] as number[]
    for (let i = firstYear; i <= currentYear; i++) {
      newYears.push(i)
    }
    return newYears
  }, [filterFirstYear])

  // const weeks: string[] = useMemo(() => {
  //   const newWeeks: string[] = []
  //   if (selectedTimeFrame?.year) {
  //     const weekPrefix = language === Languages.EN ? 'W' : 'V'
  //     const numberOfWeeks = 52
  //     for (let i = 1; i <= numberOfWeeks; i++) {
  //       if (i < 10) newWeeks.push(weekPrefix + '0' + i)
  //       else newWeeks.push(weekPrefix + i)
  //     }
  //   }
  //   return newWeeks
  // }, [filterTimePeriod, selectedTimeFrame?.year, language])

  useEffect(() => {
    const currentDate = new Date()
    let newDefaultDate = currentDate
    if (selectedTimePeriod === SummaryTimePeriod.MONTH) {
      newDefaultDate = subMonths(newDefaultDate, 1)
    }
    const selectedTimeGroupIndex = filterTimeFrame?.timeGroupIndex ?? newDefaultDate.getMonth()
    const selectedYear = filterTimeFrame?.year ?? newDefaultDate.getFullYear()
    setSelectedTimeFrame({ timeGroupIndex: selectedTimeGroupIndex, year: selectedYear })
  }, [])

  useEffect(() => {
    if (!selectedTimeFrame || isEqual(selectedTimeFrame, filterTimeFrame)) return
    setSummaryFilters((prev) => ({ ...prev, filterTimeFrame: selectedTimeFrame }))
    handleSaveSettings({ filterTimeFrame: selectedTimeFrame })
  }, [selectedTimeFrame])

  const handleSaveSettings = (newProperties: Partial<SummarySettings>) => {
    const newSettings = { ...summarySettings, ...newProperties }
    if (saveSettings && !isEqual(newSettings, summarySettings)) saveSettings(newSettings)
  }
  const handleSelectTimeGroup = (value: string, period: SummaryTimePeriod) => {
    let timeGroupIndex = -1
    if (period === SummaryTimePeriod.MONTH) {
      timeGroupIndex = months.findIndex((month) => month === value)
    }
    if (period === SummaryTimePeriod.QUARTER) {
      timeGroupIndex = quarters.findIndex((month) => month === value)
    }
    if (period === SummaryTimePeriod.HALF_YEAR) {
      timeGroupIndex = yearHalfs.findIndex((month) => month === value)
    }
    // if (period === SummaryTimePeriod.WEEK) {
    //   timeGroupIndex = weeks.findIndex((month) => month === value)
    // }
    if (timeGroupIndex < 0) return
    setSelectedTimeFrame((prev) => ({ ...prev, timeGroupIndex }))
    const filterTimeFrame = { year: selectedTimeFrame?.year, timeGroupIndex }
    handleSaveSettings({ filterTimeFrame })
  }

  const handleSelectYear = (value: string) => {
    if (isNaN(Number(value))) return
    setSelectedTimeFrame((prev) => ({ ...prev, year: +value }))
  }

  const handleSelectingDefaultTimeFrame = (
    timeGroup: string[],
    currentIndex: number | undefined,
    selectedTimePeriod: SummaryTimePeriod,
  ) => {
    const lastIndex = timeGroup.length - 1
    const lastYear = new Date().getFullYear() - 1
    const isFirstIndex = lastIndex === 0
    if (isFirstIndex) {
      const newLastIndex = getTimeGroupLastIndex(selectedTimePeriod)
      setSelectedTimeFrame(() => ({
        year: lastYear,
        timeGroupIndex: newLastIndex,
      }))
      return getTimeGroupLastValue(selectedTimePeriod, language)
    }

    const isGroupIndexOutOfRange = typeof currentIndex !== 'number' || !timeGroup[currentIndex]
    if (isGroupIndexOutOfRange) {
      setSelectedTimeFrame((prev) => ({ ...prev, timeGroupIndex: lastIndex - 1 }))
      return timeGroup[lastIndex]
    }
    setSelectedTimeFrame((prev) => ({ ...prev, timeGroupIndex: currentIndex - 1 }))
    return timeGroup[currentIndex - 1]
  }

  const defaultTimeframeGroupValue = useMemo(() => {
    if (selectedTimePeriod === SummaryTimePeriod.MONTH)
      return handleSelectingDefaultTimeFrame(months, new Date().getMonth(), selectedTimePeriod)
    if (selectedTimePeriod === SummaryTimePeriod.QUARTER)
      return handleSelectingDefaultTimeFrame(quarters, getQuarter(new Date()), selectedTimePeriod)

    if (selectedTimePeriod === SummaryTimePeriod.HALF_YEAR)
      return handleSelectingDefaultTimeFrame(
        yearHalfs,
        new Date().getMonth() > 5 ? 1 : 0,
        selectedTimePeriod,
      )
    return ''
  }, [selectedTimePeriod])

  const selectedTimeGroupIndex =
    selectedTimeFrame && !isUndefined(selectedTimeFrame.timeGroupIndex)
      ? selectedTimeFrame.timeGroupIndex
      : 100
  return (
    <div className={css.filter_timeframe}>
      <div className={css.header}>
        <i className='material-icons'>schedule</i>&nbsp;
        {t('Aikaikkuna')}
      </div>
      <div className={css.timeframe}>
        {/* {selectedTimeFrame && selectedTimePeriod === SummaryTimePeriod.WEEK && (
          <Selection
            title={t('Viikko')}
            value={weeks[selectedTimeFrame.timeGroupIndex ?? 0]}
            onSelect={(value: string) => handleSelectTimeGroup(value, selectedTimePeriod)}
            selection={weeks}
            classNames={css.timeGroupSelect}
            columns={6}
            width={340}
            position='bottomRight'
          />
        )} */}
        {selectedTimeFrame && selectedTimePeriod === SummaryTimePeriod.MONTH && (
          <Selection
            title={t('Kuukausi')}
            value={months[selectedTimeGroupIndex] || defaultTimeframeGroupValue}
            onSelect={(value: string) => handleSelectTimeGroup(value, selectedTimePeriod)}
            selection={months}
            classNames={css.timeGroupSelect}
            columns={months.length > 4 ? 4 : months.length}
            width={months.length > 4 ? 360 : months.length * 90}
            position='bottomRight'
            testid='monthSelection'
          />
        )}
        {selectedTimeFrame && selectedTimePeriod === SummaryTimePeriod.QUARTER && (
          <Selection
            title={t('Kvartaali')}
            value={quarters[selectedTimeGroupIndex] || defaultTimeframeGroupValue}
            onSelect={(value: string) => handleSelectTimeGroup(value, selectedTimePeriod)}
            selection={quarters}
            classNames={css.timeGroupSelect}
            columns={quarters.length}
            width={80 * quarters.length}
            position='bottomRight'
            testid='quarterSelection'
          />
        )}
        {selectedTimeFrame && selectedTimePeriod === SummaryTimePeriod.HALF_YEAR && (
          <Selection
            title={t('Puolivuosi')}
            value={yearHalfs[selectedTimeGroupIndex] || defaultTimeframeGroupValue}
            onSelect={(value: string) => handleSelectTimeGroup(value, selectedTimePeriod)}
            selection={yearHalfs}
            classNames={css.timeGroupSelect}
            columns={yearHalfs.length}
            width={80 * yearHalfs.length}
            position='bottomRight'
            testid='halfYearSelection'
          />
        )}
        {selectedTimeFrame && (
          <Selection
            title={t('Vuosi')}
            value={selectedTimeFrame.year?.toString() || ''}
            onSelect={handleSelectYear}
            selection={years.map((y) => y.toString())}
            classNames={css.timeGroupSelect}
            columns={years.length > 4 ? 4 : years.length}
            width={years.length > 4 ? 200 : years.length * 80}
            position='bottomRight'
            testid='yearSelection'
          />
        )}
      </div>
    </div>
  )
}

export default SelectTimeframe
