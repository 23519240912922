import React, { useEffect, useMemo, useState } from 'react'
import { get, getUserFromToken } from '../../react-services/apiService'
import GroupManagement from './UserManagement/GroupManagement/GroupManagement'
import UserManagementGrouped from './UserManagement/UserManagementGrouped/UserManagementGrouped'
import UserManagementGroupless from './UserManagement/UserManagementGroupless/UserManagementGroupless'
import LoadingIndicator from '../_shared/Infos/LoadingIndicator'
import useCommonDbSettingsConfig from '../../stores/useCommonDbSettingsConfig'
import { cloneDeep, debounce } from 'lodash'
import useReportingFilters from '../../stores/useReportingFilters'
import { Tab as OriginalTab, Tabs } from '@mui/material'
import styled from '@emotion/styled'
import useDbTemplateConfig from '../../stores/useDbTemplateConfig'

const Tab = styled(OriginalTab)(() => ({
  '&:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  '&:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))

const TabUserManagement = () => {
  const { config: commonDbSettings } = useCommonDbSettingsConfig()
  const { metas: reportingFilters, isLoading: isLoadingReportingFilters } = useReportingFilters()
  const useGroups = commonDbSettings && commonDbSettings.use_user_groups ? true : false
  const [users, setUsers] = useState(null)
  const [groups, setGroups] = useState(null)
  const [templates, setTemplates] = useState(null)
  const [filters, setFilters] = useState(null)
  const [filterValues, setFilterValues] = useState(null)
  const [showUsers, setShowUsers] = useState(true)
  const [tab, setTab] = useState(showUsers || !useGroups ? 'userManagement' : 'groupManagement')
  const currentUser = getUserFromToken()

  useEffect(() => {
    fetchUsersAndGroups()
    getTemplates()
  }, [])

  useEffect(() => {
    if (isLoadingReportingFilters || !reportingFilters) return
    const debouncer = debounce(() => {
      let filterKeys = []
      let filterValues = []
      for (const metaKey in reportingFilters) {
        const metaValues = reportingFilters[metaKey]
        filterKeys.push(metaKey)
        filterValues.push(metaValues)
      }
      setFilters(filterKeys)
      setFilterValues(filterValues)
    }, 1000)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [reportingFilters, isLoadingReportingFilters])

  const isSysAdmin =
    currentUser &&
    (currentUser.username.includes('@wheelq.com') ||
      currentUser.username === 'templateuser' ||
      currentUser.username === 'admin')

  const fetchUsersAndGroups = () => {
    let promises = [get('GET_USERSWITHCONFS'), get('GET_GROUPS')]
    Promise.all(promises).then((responses) => {
      let groups = responses[1]
      let users = mapGroupsToUsers(...responses)
      users = filterNonEmailUsers(users)
      setUsers(users)
      setGroups(groups)
    })
  }

  const mapGroupsToUsers = (users, groups) => {
    let newUsers = cloneDeep(users && users.users ? users.users : users)
    let result = []
    for (let i = 0; i < newUsers.length; i++) {
      let userItem = newUsers[i]
      userItem.usergroups = []
      if (
        groups &&
        userItem.user_conf &&
        userItem.user_conf.group &&
        userItem.user_conf.group.length > 0
      ) {
        userItem.user_conf.group.forEach((groupId) => {
          groups[groupId] && userItem.usergroups.push(groups[groupId])
        })
      }
      result.push(userItem)
    }
    return result
  }

  const filterNonEmailUsers = (users) => {
    if (isSysAdmin) return users
    let result = []
    users.forEach((user) => {
      if (user.username.includes('@')) result.push(user)
    })
    return result
  }

  const getTemplates = () => {
    let templates = []
    get('GET_DBTEMPLATES').then(
      (res) => {
        templates = res
        let templateKeysNames = []
        for (let template in templates) {
          if (templates[template][0]?.name) {
            let t = { key: template, name: templates[template][0].name }
            templateKeysNames.push(t)
          } else {
            console.error(
              'Cannot add template under key:',
              template,
              'Template name not found. Template JSON was:',
              templates[template],
            )
          }
        }
        setTemplates(templateKeysNames)
      },
      (error) => {
        console.error(error)
      },
    )
  }

  const setGroupsActive = () => {
    setShowUsers(false)
  }

  const setUsersActive = () => {
    setShowUsers(true)
  }

  const handleTabChange = (e, newValue) => setTab(newValue)
  return (
    <div data-cy='userManagement' className='admin-tools-user-management'>
      {!filters && !users && <LoadingIndicator />}

      {useGroups && filters && users && (
        <>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab value='userManagement' label='User management' />
            <Tab value='groupManagement' label='Group management' />
          </Tabs>

          {tab === 'userManagement' && filterValues && (
            <UserManagementGrouped
              currentUser={currentUser}
              users={users}
              groups={groups}
              templates={templates}
              filters={filters}
              filtervalues={filterValues}
              getUsers={fetchUsersAndGroups}
              setUsersActive={setUsersActive}
            />
          )}
          {tab === 'groupManagement' && (
            <GroupManagement
              currentUser={currentUser}
              users={users}
              groups={groups}
              templates={templates}
              filters={filters}
              getUsers={fetchUsersAndGroups}
              setGroupsActive={setGroupsActive}
            />
          )}
        </>
      )}

      {!useGroups && filters && (
        <>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab value='userManagement' label='User management' />
          </Tabs>

          {tab === 'userManagement' && (
            <UserManagementGroupless
              currentUser={currentUser}
              users={users}
              templates={templates}
              filters={filters}
              filtervalues={filterValues}
              onUpdateUsers={fetchUsersAndGroups}
            />
          )}
        </>
      )}
    </div>
  )
}

export default TabUserManagement
