import React, { useEffect, useRef, useState } from 'react'
import { DataTypes, NumberTrendModule } from './numbertrendTypes'

import css from './NumberTrend.module.scss'
type NumberTrendReportProps = {
  isComparison: boolean
  displayedNormalNumber: number | null
  displayedTrendNumber: number | null
  displayedComparisonNumber: string
  displayedSecondNumber: number | null
  options?: NumberTrendModule['options']
  secondaryFormat: DataTypes | 'comparison'
  decimals: number
}
const NumberTrendReport = ({
  isComparison,
  displayedNormalNumber,
  displayedTrendNumber,
  displayedComparisonNumber,
  displayedSecondNumber,
  options,
  secondaryFormat,
  decimals,
}: NumberTrendReportProps) => {
  const containerElementRef = useRef<HTMLDivElement>()
  const numberElementRef = useRef<HTMLSpanElement>()
  const [isArrowVisible, setIsNormalArrowVisible] = useState<boolean>(true)

  useEffect(() => {
    if (containerElementRef.current && numberElementRef.current)
      setIsNormalArrowVisible(
        containerElementRef.current.clientWidth - numberElementRef.current.clientWidth > 58 &&
          !options?.hideTrendArrow,
      )
  }, [
    displayedNormalNumber,
    displayedComparisonNumber,
    containerElementRef.current?.clientWidth,
    numberElementRef.current,
    options?.hideTrendArrow,
  ])

  function getTrendClass(current: number, previous: number) {
    if (current > previous) return `${css.reportArrowUp} material-icons text-trafficlight-max`
    if (current < previous) return `${css.reportArrowDown} material-icons text-trafficlight-min`
    if (current === previous) return `${css.reportArrowFlat} material-icons text-trafficlight-mid`
    return ''
  }

  return (
    <div
      className={css.reportCardCntr}
      ref={(newRef) => {
        if (newRef) containerElementRef.current = newRef
      }}
    >
      <span
        data-cy='numberTrendValue'
        data-testid='numberTrendValue'
        className={`${isComparison ? 'blue-grey-text' : ''} numbertrend-value ${
          css.primaryReport
        } ${isArrowVisible ? css.primaryReportArrowPadding : ''}`}
        ref={(newRef) => {
          if (newRef) numberElementRef.current = newRef
        }}
      >
        {isComparison ? displayedComparisonNumber : displayedNormalNumber}
        {displayedNormalNumber !== null && displayedTrendNumber !== null && isArrowVisible && (
          <i className={getTrendClass(displayedNormalNumber, displayedTrendNumber)}>call_made</i>
        )}
      </span>
      {options?.hideSecondaryNumber !== true && (
        <div className={`numbertrend-subtitle text-shadow ${css.secondary}`}>
          {typeof displayedSecondNumber === 'number' ? (
            <>
              {secondaryFormat === DataTypes.COUNT && `(n: ${displayedSecondNumber?.toFixed(0)})`}
              {secondaryFormat === DataTypes.AVERAGE &&
                `(avg: ${displayedSecondNumber?.toFixed(decimals)})`}
              {secondaryFormat === DataTypes.SUM && `(sum: ${displayedSecondNumber?.toFixed(0)})`}
            </>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  )
}

export default NumberTrendReport
