import { allChartColors } from '../../../../../../styles/variableExport'
import { getTenant } from '../../../../../react-services/authService'

export const createChart = (
  isReportOrScreen: boolean,
  handleClickChartPoint: (e: Highcharts.PointClickEventObject) => void,
): Highcharts.Options => {
  const FONT_SIZE = isReportOrScreen ? '15px' : '12px'
  const isAnimationDisabled = getTenant() === 'test_frontend' || isReportOrScreen
  const ANIMATION_DURATION = isAnimationDisabled ? 0 : 1200
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      animation: { duration: ANIMATION_DURATION },
      margin: [10, 10, 10, 10],
      spacing: [10, 10, 10, 10],
      type: 'pie',
    },
    exporting: {
      sourceWidth: 1800,
      sourceHeight: 675,
      scale: 1.5,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      buttons: {
        contextButton: {
          y: 0,
          x: -10,
        },
      },
    },
    series: [],
    plotOptions: {
      pie: {
        dataGrouping: {
          enabled: true,
        },
        colors: Object.values(allChartColors).filter((color) => color !== allChartColors.GREY),
        borderWidth: 2,
        center: ['66%', '50%'],
        innerSize: '10%',
        dataLabels: {
          overflow: 'allow',
          enabled: true,
          format: '{point.y}',
          distance: 0,
          style: {
            textDecoration: 'none',
            fontSize: FONT_SIZE,
          },
        },
        animation: {
          duration: ANIMATION_DURATION,
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{series.name}</span></br>',
          pointFormat:
            '<div style="margin-top:10px">' +
            '<div style="height:10px;width:10px;border-radius:5px;background-color:{point.color};display:inline-block"></div>' +
            ' ' +
            '<span style="font-size:12px">{point.name}</span>' +
            '</br>' +
            '<span style="margin-top:8px;font-size:12px;font-weight:bold">' +
            'count: {point.custom.total}' +
            '</span>' +
            '</br>' +
            '<span style="font-size:12px;font-weight:bold">' +
            'share: {point.custom.percent}%' +
            '</span>' +
            '</div>',
        },
        point: {
          events: {
            click: handleClickChartPoint,
          },
        },
      },
    },
    legend: {
      align: 'left',
      x: isReportOrScreen ? 90 : undefined,
      verticalAlign: 'middle',
      layout: 'vertical',
      width: !isReportOrScreen ? '30%' : '40%',
      enabled: true,
      floating: true,
      itemStyle: {
        fontSize: FONT_SIZE,
      },
    },
  }
}
