import React, { SetStateAction, memo, useEffect, useRef, useState } from 'react'
import OpenModuleAnswersTable from './OpenModuleAnswersTable'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import { OpenAnswer } from '../Pietabular/pietabularTypes'
import useInfiniteScroll from '../../../../stores/useInfiniteScroll'
import { FILTERBAR_TIMEOUT } from './OpenModuleContainer'
import { useDeepCompareEffect } from 'react-use'
import { OpenAnswersRequest, OpenAnswersResponse } from '../../../../stores/useNewOpenAnswers'
import FadeIn from '../_shared/FadeIn'

import css from './OpenModule.module.scss'

type OpenProps = {
  data: OpenAnswer[]
  answerMetas: string[] | null
  topics: string[] | null
  onChangeCategories: (isCategorySelectorOpen: boolean) => void
  metadataColumns: string[]
  doShowTopics: boolean
  doShowSentiments: boolean
  doShowCustomCategories: boolean
  customCategories: { [name: string]: string[] } | null
  fetchMoreAnswers: () => Promise<boolean>
  isFetchingMoreAnswers: boolean
  isReportMode: boolean
  showToolsColumn?: boolean
  hideFilterBarTimeout: React.MutableRefObject<NodeJS.Timeout | undefined>
  setShowFilterBar: React.Dispatch<SetStateAction<boolean>>
  showFilterBar: boolean
  paginatedQuery: OpenAnswersRequest
  pagination: OpenAnswersResponse['pageMetadata'] | null
}

const Open = memo(
  ({
    data,
    answerMetas,
    topics,
    onChangeCategories,
    metadataColumns,
    doShowTopics,
    doShowSentiments,
    doShowCustomCategories,
    customCategories,
    fetchMoreAnswers,
    isFetchingMoreAnswers,
    isReportMode,
    showToolsColumn,
    hideFilterBarTimeout,
    setShowFilterBar,
    showFilterBar,
    paginatedQuery,
    pagination,
  }: OpenProps) => {
    const tableCntrRef = useRef<HTMLDivElement>()
    const needMoreTimeout = useRef<NodeJS.Timeout>()
    const doneLoadingTimeout = useRef<NodeJS.Timeout>()
    const [needMoreToLoad, setNeedMoreToLoad] = useState<boolean>(false)
    const [table, setTable] = useState<HTMLDivElement>()
    const [isDoneLoading, setIsDoneLoading] = useState<boolean>(false)
    const { reset, scrolled } = useInfiniteScroll(fetchMoreAnswers, 1600, table, needMoreToLoad)

    useEffect(() => {
      if (doneLoadingTimeout.current) clearTimeout(doneLoadingTimeout.current)
      doneLoadingTimeout.current = setTimeout(() => {
        if (!isFetchingMoreAnswers) setIsDoneLoading(true)
        else setIsDoneLoading(false)
      }, 3000)
      return () => doneLoadingTimeout.current && clearTimeout(doneLoadingTimeout.current)
    }, [isFetchingMoreAnswers])

    useEffect(() => {
      needMoreTimeout.current = setTimeout(() => {
        if ((pagination && data.length < pagination.total_elements) || data.length < 40)
          setNeedMoreToLoad(true)
        else setNeedMoreToLoad(false)
      }, 1)
      return () => needMoreTimeout.current && clearTimeout(needMoreTimeout.current)
    }, [data, pagination])

    const setScrollTimeout = useRef<NodeJS.Timeout>()
    useDeepCompareEffect(() => {
      setScrollTimeout.current = setTimeout(() => {
        if (paginatedQuery && tableCntrRef.current) {
          tableCntrRef.current.scroll({ top: 0 })
        }
      }, 1)
      return () => needMoreTimeout.current && clearTimeout(needMoreTimeout.current)
    }, [paginatedQuery || {}])

    useEffect(() => {
      reset()
    }, [data, paginatedQuery])

    useEffect(() => {
      setTable(tableCntrRef.current)
    }, [tableCntrRef.current])

    useEffect(() => {
      if (!scrolled) return
      if (hideFilterBarTimeout.current) clearTimeout(hideFilterBarTimeout.current)
      setShowFilterBar(true)
      hideFilterBarTimeout.current = setTimeout(() => {
        setShowFilterBar(false)
      }, FILTERBAR_TIMEOUT)
      return () => hideFilterBarTimeout.current && clearTimeout(hideFilterBarTimeout.current)
    }, [scrolled])

    const getHeightClass = () => {
      if (isReportMode) {
        return css.report
      }
      if (showFilterBar) {
        return css.filterActive
      }
      return css.cntr
    }

    const [ready, setReady] = useState('')
    useEffect(() => {
      if (!isDoneLoading) return
      setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
    }, [isDoneLoading])

    return (
      <div
        className={`open-module-answers-container ${ready} ${getHeightClass()}`}
        ref={(ref) => {
          if (ref) tableCntrRef.current = ref
        }}
        data-testid='openModuleTableCntr'
      >
        {!!data && (
          <FadeIn>
            <OpenModuleAnswersTable
              answers={data}
              metadataColumns={metadataColumns}
              doShowTopics={doShowTopics}
              doShowSentiments={doShowSentiments}
              doShowCustomCategories={doShowCustomCategories}
              customCategories={customCategories}
              answerMetas={answerMetas}
              topics={topics}
              onChangeCategories={onChangeCategories}
              isFetchingMoreAnswers={isFetchingMoreAnswers}
              scrolled={scrolled}
              showToolsColumn={showToolsColumn}
            />
          </FadeIn>
        )}
      </div>
    )
  },
)

Open.displayName = 'Open'
export default Open
