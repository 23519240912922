/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react'
import { Dashboard } from '../../../../stores/useDbTemplateConfig'
import { hasRole, isLoggedIn } from '../../../../react-services/authService'
import { ExportFileType, ReportFileType } from './dashboardTypes'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import ExportModal from '../../../_shared/Modals/ExportModal'
import ModulePicker from '../ModulePicker/ModulePicker'
import { Dashboard as DashboardNameSpace, Payload } from '../../../../../types'
import { cloneDeep, pick } from 'lodash'
import useDashboardSections from '../../../../stores/useDashboardSections'
import { v4 as uuid } from 'uuid'
import { Module, QuickTimeframe } from '../Group/groupModuleTypes'
import { useParams } from 'react-router-dom'
import { format, subYears } from 'date-fns'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { post } from '../../../../react-services/apiService'
import { toast } from 'react-toastify'
import SendReportModal from './SendReportModal'
import { tCommon } from '../../../../../languages/i18n'
import { Add } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

const defaultConfig = {
  title: '',
  settings: [],
  query: {
    start_date: '',
    end_date: '',
    where_meta: {},
    kpis: [],
    calculated_kpis: {},
  },
}
type DashboardActionsProps = {
  currentDashboard: Dashboard
}
const DashboardActions = ({ currentDashboard }: DashboardActionsProps) => {
  const [isExportDisabled, setIsExportDisabled] = useState(false)
  const { saveSection, sections } = useDashboardSections()
  const { sectionIdx, dashboardIdx } = useParams()
  const sectionNumber = isNaN(Number(sectionIdx)) ? 0 : Number(sectionIdx)
  const dashboardNumber = isNaN(Number(dashboardIdx)) ? 0 : Number(dashboardIdx)
  const { config: commonDbSettings, isLoading: isLoadingCommonDbSettings } =
    useCommonDbSettingsConfig()
  const isDataExportVisible = currentDashboard.export_button !== false
  const isReportExportVisible =
    commonDbSettings?.show_send_report_button !== false && !isLoadingCommonDbSettings

  const isAdmin = hasRole('admin')
  const [exportFormat, setExportFormat] = useState<ExportFileType>(ExportFileType.XLSX)
  const [reportFormat, setReportFormat] = useState<ReportFileType>(ReportFileType.PDF)
  const [isModulePickerOpen, setIsModulePickerOpen] = useState(false)
  const [isDataExportOpen, setIsDataExportOpen] = useState(false)
  const [isReportExportOpen, setIsReportExportOpen] = useState(false)

  const handleOpenModulePicker = useCallback(() => setIsModulePickerOpen(true), [])
  const handleCloseModulePicker = useCallback(() => setIsModulePickerOpen(false), [])
  const handleOpenDataExport = useCallback(() => setIsDataExportOpen(true), [])
  const handleCloseDataExport = useCallback(() => setIsDataExportOpen(false), [])
  const handleOpenReportExport = useCallback(() => setIsReportExportOpen(true), [])
  const handleCloseReportExport = useCallback(() => setIsReportExportOpen(false), [])

  function addModuleToDashboard(m: DashboardNameSpace.PickableModule) {
    if (!currentDashboard || !sections) return
    const newDashBoard = cloneDeep(currentDashboard)
    newDashBoard.modules.push({
      type: m.type,
      timeframe: QuickTimeframe.LY,
      id: uuid(),
      ...defaultConfig,
    } as Module)
    const currentSection = sections[sectionNumber]
    if (!currentSection) return
    const newSection = cloneDeep(currentSection)
    if (!newSection.dashboards) newSection.dashboards = []
    newSection.dashboards[dashboardNumber] = newDashBoard
    saveSection(newSection)
  }

  function adminDownloadDataFile(query: Payload, fileFormat: 'csv' | 'xlsx' = 'csv') {
    setIsExportDisabled(true)
    const formatted_query = formatQuery(query)
    const path = fileFormat === 'xlsx' ? 'POST_ADMINEXPORT_EXCEL' : 'POST_ADMINEXPORT'
    downloadBlob(path, formatted_query, undefined, fileFormat)
  }

  function formatQuery(query: Payload) {
    const newQuery = { ...query, filter: query.where_meta || {} }
    const allowedQueryKeys = ['kpis', 'start_date', 'end_date', 'filter']
    const formatted_query = pick(newQuery, allowedQueryKeys)
    return formatted_query
  }

  function downloadBlob(
    path: string,
    query: Partial<Payload>,
    fileName: string | undefined,
    fileFormat: 'csv' | 'xlsx',
  ) {
    return (
      post(path, query, { responseType: 'blob' })
        .then((res: unknown) => {
          if (!res) return
          const blob = res
          return resolveBlob(blob, fileName, fileFormat)
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.error(error)
          return rejectBlob()
        })
        .finally(() => setIsExportDisabled(false))
    )
  }

  function resolveBlob(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    blob: any,
    fileName: string | undefined,
    fileFormat: 'csv' | 'xlsx',
  ) {
    const filename = fileName ? fileName : `export.${fileFormat}`
    const wUrl = window.URL || window.webkitURL
    const blobUrl = wUrl.createObjectURL(blob)
    // Saving in IE
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename)
      // Other browsers
    } else {
      const tmpLink = document.createElement('a')
      tmpLink.href = blobUrl
      tmpLink.download = filename
      document.body.appendChild(tmpLink)
      tmpLink.click()
      setTimeout(function () {
        document.body.removeChild(tmpLink)
      }, 1000)
    }
    setTimeout(function () {
      wUrl.revokeObjectURL(blobUrl)
    }, 1000)
    toast.success('Export file downloaded')
  }
  function rejectBlob() {
    toast.error('Error downloading export file')
  }

  const defaultQuery = useMemo(() => {
    const currentDate = new Date()
    const startDate = format(subYears(currentDate, 1), 'yyyy-MM-dd')
    const endDate = format(currentDate, 'yyyy-MM-dd')
    return {
      start_date: startDate,
      end_date: endDate,
      where_meta: {},
    }
  }, [])

  return (
    <>
      {isDataExportOpen && (
        <ErrorBoundary fallback={<></>}>
          <ExportModal
            areKpisAndFiltersHidden={false}
            query={defaultQuery}
            onClose={handleCloseDataExport}
            onExport={adminDownloadDataFile}
            wasLastPage={false}
            isBatchExporting={false}
            missingData={false}
            onCloseNotification={() => {}}
            waitingForBatchResponse={false}
            dataSelectionType='kpis'
            hasExcelOption={true}
            isDownloading={isExportDisabled}
          />
        </ErrorBoundary>
      )}
      {isReportExportOpen && (
        <SendReportModal onClose={handleCloseReportExport} currentDashboard={currentDashboard} />
      )}
      {isModulePickerOpen && (
        <ModulePicker
          isGrouped={false}
          onClose={handleCloseModulePicker}
          onSelect={addModuleToDashboard}
        />
      )}
      <div>
        <div className='row row-indent indent' style={{ marginTop: '1rem' }}>
          <div className='col s1'>
            <Tooltip title={tCommon('info.addModuleToRoot')}>
              <a
                className='btn-floating btn-large waves-effect waves-light orange lighten-1 tooltipped'
                data-position='top'
                data-tooltip='Add module'
                data-delay='100'
                onClick={handleOpenModulePicker}
                aria-label='Add module'
                data-testid='addModuleButton'
              >
                <Add sx={{ fontSize: '56px', padding: '12px' }} />
              </a>
            </Tooltip>
          </div>
        </div>

        {isDataExportVisible && (
          <div className='row row-indent indent' style={{ marginTop: '1rem' }}>
            <div className='col s12 m6'>
              <button
                data-cy='dashboardCsvExport'
                className='btn orange waves-effect'
                onClick={handleOpenDataExport}
              >
                {tCommon('button.exportData')}
              </button>
            </div>
          </div>
        )}

        {isReportExportVisible && (
          <div className='row row-indent indent' style={{ marginTop: '1rem' }}>
            <div className='col s12'>
              <button className='btn orange' onClick={handleOpenReportExport}>
                {tCommon('button.sendReport')}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DashboardActions
