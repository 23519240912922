import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreNonResetting, { ActionState } from './useStoreNonResetting'
import { Dashboard, DashboardSettings } from './useDbTemplateConfig'
import {
  getDashboardSettingsConfig,
  postDashboardSettingsConfig,
} from '../react-services/configService2'

export type DashboardSettingsConfig = {
  multilevel?: boolean
  dashboards?: Dashboard[]
  sections?: DashboardSettings[]
  mydashboards?: DashboardSettings[]
}

const dashboardSettingsStorage = makeNonResettingObservable<DashboardSettingsConfig>({})

const useDashboardSettingsConfig = () => {
  const key = 'dashboardsettings'
  const { tenant, user } = useStoreOwner(dashboardSettingsStorage)
  const {
    dataState: { data, error, state },
    post: postDashboardSettings,
  } = useStoreNonResetting(
    dashboardSettingsStorage,
    key,
    user,
    tenant,
    true,
    getDashboardSettingsConfig,
    postDashboardSettingsConfig,
  )

  const isLoading = state !== ActionState.DONE && state !== ActionState.ERROR
  return { config: data, error, isLoading, postDashboardSettings }
}

export default useDashboardSettingsConfig
