/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CategoriesAndValues,
  CategoriesWithHotkeys,
} from '../components/Dashboards/DashboardModules/Open/OpenCategoryTool/openCategoryToolTypes'
import {
  errorFetchOpenAnswers,
  errorFetchOpenAnswersCounts,
} from '../components/Dashboards/DashboardModules/Pietabular/PietabularNotifications'
import { OpenAnswersCountsQuery } from '../components/Dashboards/DashboardModules/Pietabular/pietabularTypes'
import { TrackingEvent } from '../react-constants'
import { isHotkeyConfig } from '../utilities'
import { OpenAnswersRequest } from '../stores/useNewOpenAnswers'
import { hasRole, post } from './apiService'
import { getDashboardSettings, saveSettings } from './configService'
import trackingService from './trackingService'
import { GenericConfig } from '../../types'

const getOpenAnswersNew = (query: OpenAnswersRequest) => {
  try {
    return post('POST_OPEN_ANSWERS', query)
      .then((res: any) => res)
      .catch(() => Promise.reject(errorFetchOpenAnswers))
  } catch (e) {
    return Promise.reject(errorFetchOpenAnswers)
  }
}

// DEPRECATED
const getOpenAnswers = (query: any) => {
  try {
    return post('POST_TEXTUAL', query)
      .then((res: any) => res)
      .catch(() => Promise.reject(errorFetchOpenAnswers))
  } catch (e) {
    return Promise.reject(errorFetchOpenAnswers)
  }
}

const getOpenAnswersCounts = (query: OpenAnswersCountsQuery) => {
  try {
    return post('POST_TEXTUAL_COUNTS', query)
      .then((res: any) => res)
      .catch(() => Promise.reject(errorFetchOpenAnswersCounts))
  } catch (e) {
    return Promise.reject(errorFetchOpenAnswersCounts)
  }
}

const saveOpenAnswerCategories = (
  openAnswerId: number,
  payload: CategoriesAndValues,
  isAllowedToChangeCategories: boolean,
) => {
  if (isAllowedToChangeCategories) {
    trackingService.track(TrackingEvent.ChangeCategories)
    try {
      return post('POST_OPEN_CATEGORIES', payload, { 'answer-id': openAnswerId })
        .then((res: any) => res)
        .catch(() => Promise.reject('Something went wrong while updating categories...'))
    } catch (e) {
      return Promise.reject('Something went wrong while updating categories...')
    }
  } else {
    return Promise.reject('Only admins are allowed to change categories!')
  }
}

function isCategoryChangingAllowed(cdbs: GenericConfig.CommonDbSettings | null) {
  const DEFAULT = false
  return (
    hasRole('admin') || (cdbs?.dashboard_modules?.open?.is_changing_categories_allowed ?? DEFAULT)
  )
}

const saveHotkeyConfig = (hotkeyConf: CategoriesWithHotkeys[]) => {
  if (!isHotkeyConfig(hotkeyConf))
    return Promise.reject('Something went wrong with hotkey validation. Aborting...')
  try {
    return getDashboardSettings().then((settings) => {
      settings.myhotkeys = hotkeyConf
      return saveSettings(settings).then(() => hotkeyConf)
    })
  } catch (e) {
    return Promise.reject('Could not save hotkeys...')
  }
}

export {
  getOpenAnswers,
  saveOpenAnswerCategories,
  getOpenAnswersCounts,
  isCategoryChangingAllowed,
  saveHotkeyConfig,
  getOpenAnswersNew,
}
