import React from 'react'
import Icon, { IconName } from '../../../../../../../utilities/Icon'
import SaveText from './SaveText'

import css from './Finished.module.scss'
import { tCommon } from '../../../../../../../../languages/i18n'

const Finished = () => {
  return (
    <div className={css.doneIcon}>
      <div className={css.done}>
        <Icon name={IconName.CHECK} color='#fff' width={24} height={24} />
      </div>
      <SaveText text={tCommon('label.done')} />
    </div>
  )
}

export default Finished
