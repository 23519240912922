import React from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { FrequencyModule } from '../frequencyModuleTypes'
import { cloneDeep, isNull, isUndefined } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'

const NumericCategoryLimitInputs = () => {
  const [{ saveModule, module }] = useSettingsTools()
  const freqModule = module as FrequencyModule
  const { numericquery: { min, max } = {} } = freqModule
  const currentMin = isNull(min) || isUndefined(min) ? '' : min
  const currentMax = isNull(max) || isUndefined(max) ? '' : max

  const handleOnChangeLimits = (newLimit: string, limit: 'min' | 'max') => {
    if (!freqModule) return
    const moduleCopy = cloneDeep(freqModule)
    const prevLimits = freqModule.numericquery || {}
    const newModule = {
      ...moduleCopy,
      numericquery: { ...prevLimits, [limit]: !isNaN(+newLimit) ? newLimit : null },
    }
    saveModule(newModule)
  }
  return (
    <>
      <div className={`${modal_css.quarter}`}>
        Numeric min
        <input
          data-testid='numericMinInput'
          type='number'
          value={currentMin}
          id='numericMinInput'
          onChange={(e) => handleOnChangeLimits(e.target.value, 'min')}
          className={`${css.input}`}
        />
      </div>
      <div className={`${modal_css.quarter}`}>
        Numeric max
        <input
          data-testid='numericMaxInput'
          type='number'
          value={currentMax}
          id='numericMaxInput'
          onChange={(e) => handleOnChangeLimits(e.target.value, 'max')}
          className={`${css.input}`}
        />
      </div>
    </>
  )
}

export default NumericCategoryLimitInputs
