/* eslint-disable @typescript-eslint/ban-types */
import React, { memo } from 'react'
import ModulePanel from './ModulePanel'
import { Dashboard } from '../../../../../types'

type Props = {
  pickableModules: Dashboard.PickableModule[]
  isGrouped: boolean
  onSelect: Function
}

function ModulePanelList({ isGrouped, pickableModules, onSelect }: Props) {
  const splitPoint = pickableModules.findIndex((m) => m.groupNumber !== 0)
  const topModules = pickableModules.slice(0, splitPoint)
  const restOfModules = pickableModules.slice(splitPoint, pickableModules.length)

  function hasToBeGrouped(pickableModule: Dashboard.PickableModule) {
    return Boolean(pickableModule.isGroupingForced && !isGrouped)
  }

  function hasToNotBeGrouped(pickableModule: Dashboard.PickableModule) {
    return Boolean(pickableModule.isGroupingProhibited && isGrouped)
  }

  return (
    <div className='module-picker-body'>
      <div className='row module-selection-panel-container'>
        {topModules.map((m) => {
          const isDisabled: boolean = hasToNotBeGrouped(m)

          return (
            <div key={m.name} className='col s12 m6 l4'>
              <ModulePanel
                selectableModule={m}
                isDisabled={isDisabled}
                reasonWhyDisabled={isDisabled ? `${m.name} cannot be grouped.` : null}
                onSelect={onSelect}
              />
            </div>
          )
        })}
      </div>

      <div className='row module-selection-panel-container'>
        {restOfModules.map((m) => {
          const reasonWhyDisabled: string | null = hasToBeGrouped(m)
            ? `${m.name} has to be grouped`
            : hasToNotBeGrouped(m)
            ? `${m.name} cannot be grouped`
            : null

          return (
            <div key={m.name} className='col s12 m6 l4'>
              <ModulePanel
                selectableModule={m}
                isDisabled={reasonWhyDisabled !== null}
                reasonWhyDisabled={reasonWhyDisabled}
                onSelect={onSelect}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(ModulePanelList)
