import React from 'react'
import useReportingFilters from '../../../stores/useReportingFilters'

const withReportingFilters = (Component: React.ComponentType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function wrappedComponent(props: any) {
    const { metas, isLoading } = useReportingFilters()
    if (isLoading) return <></>
    return <Component reportingFilters={metas || {}} {...props} />
  }
}

export default withReportingFilters
