import React from 'react'
import SummaryContent from './SummaryContent'
import SummaryHeader from './SummaryHeader'

import css from './SummaryModule.module.scss'

const SummaryModuleComponent = () => {
  return (
    <div className={`${css.summary_module} summary-module-cntr`} data-testid='summary-module'>
      <SummaryHeader />
      <SummaryContent />
    </div>
  )
}

export default SummaryModuleComponent
