import React, { useEffect, useState } from 'react'
import ConfirmationModal from '../../../../common/ConfirmationModal/ConfirmationModal'
import DoubleModalSide from '../../../../_shared/Modals/DoubleModalSide'
import css from './ConversationModal.module.scss'
import { useConversationFunctions, useConversationTools } from './ConversationModalProvider'
import ConversationList from './ConversationList'
import SingleConversation from './SingleConversation'

const ConversationModal = () => {
  const [
    { messageInput, currentConversationId, isUnsavedChanges, conversationNav },
    setConversationTools,
  ] = useConversationTools()
  const [{ closeConversation, unsavedChangesToggle }] = useConversationFunctions()
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (messageInput === '') {
      unsavedChangesToggle(false)
    } else {
      unsavedChangesToggle(true)
    }
  }, [messageInput])

  const handleCloseConversationModal = () => {
    if (isUnsavedChanges) {
      setIsCancelConfirmationModalOpen(true)
    } else {
      closeConversation()
    }
  }

  const handleCancelConfirmation = () => {
    unsavedChangesToggle(false)
    setIsCancelConfirmationModalOpen(false)
    closeConversation()
  }

  const handleConversationListButton = () => {
    setConversationTools((prev) => ({
      ...prev,
      conversationNav: { isConversationListOpen: true, isSingleConversationOpen: false },
    }))
  }

  const handleSingleConversationButton = () => {
    setConversationTools((prev) => ({
      ...prev,
      conversationNav: { isConversationListOpen: false, isSingleConversationOpen: true },
    }))
  }

  return (
    <>
      {isCancelConfirmationModalOpen && (
        <ConfirmationModal
          handleConfirmation={handleCancelConfirmation}
          handleCancel={() => setIsCancelConfirmationModalOpen(false)}
          title='Unsaved changes'
          info='Please confirm to exit without saving. To confirm, press enter or click confirm button. To cancel, press esc or click cancel.'
          enableEnterConfirmation={true}
        />
      )}
      <DoubleModalSide
        onClose={handleCloseConversationModal}
        animation='slideLeft'
        data_testid='conversation-modal'
      >
        <br />
        <div className={css.conversation_modal}>
          <span className={css.messages_header}>
            <b>{conversationNav.isConversationListOpen ? 'Conversations' : 'Messages'}</b>
            {currentConversationId && (
              <i
                className={
                  conversationNav.isSingleConversationOpen
                    ? `material-icons ${css.active_tab}`
                    : 'material-icons'
                }
                onClick={handleSingleConversationButton}
              >
                forum
              </i>
            )}
            <i
              className={
                conversationNav.isConversationListOpen
                  ? `material-icons ${css.active_tab}`
                  : 'material-icons'
              }
              onClick={handleConversationListButton}
            >
              list_alt
            </i>
          </span>
          {conversationNav.isConversationListOpen && <ConversationList />}
          {conversationNav.isSingleConversationOpen && <SingleConversation />}
        </div>
      </DoubleModalSide>
    </>
  )
}

export default ConversationModal
