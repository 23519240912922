import React, { useEffect, useState } from 'react'
import Rodal from 'rodal'
import { WhereMeta, Payload, Survey, Kpi } from '../../../../types'
import SelectSurveyButton from '../TreeView/SelectSurveyButton'
import MainButton from '../Buttons/MainButton'
import { transformFiltersToWhereMetaForm } from '../../../react-services/moduleFiltersService'
import { getDateNow, datePlusDays } from '../../../react-services/datesService'
import MetadataBoard from '../MetadataBoard/MetadataBoard'
import ModuleFiltersTimeSelection from '../../Dashboards/DashboardModules/_shared/Filter/ModuleFiltersTimeSelection'
import { cloneDeep, isEmpty, isNil, isNull, isUndefined } from 'lodash'
import NotificationModal from './NotificationModal'
import SelectKpiButton from '../TreeView/SelectKpiButton'
import trackingService from '../../../react-services/trackingService'
import { TrackingEvent } from '../../../react-constants'
import BatchExportModal from '../../AdminTools/Surveys/ResponseRate/BatchExportModal'
import useReportingFilters from '../../../stores/useReportingFilters'
import useCommonDbSettingsConfig from '../../../stores/useCommonDbSettingsConfig'

import './Modals.scss'
import { VALIDKPITYPES } from '../TreeView/SelectionTree'
import { tCommon } from '../../../../languages/i18n'

type Props = {
  dataSelectionType: DataSelectionType
  hasLimitedFilterSet?: boolean
  query: Payload
  surveys?: Survey[]
  isDownloading?: boolean
  isBatchExporting: boolean
  waitingForBatchResponse: boolean
  hasPagination?: boolean
  totalPages?: number | null
  missingData: boolean
  onClose: () => void
  onExport: (q: Payload, fileFormat?: 'csv' | 'xlsx') => void
  onBatchExport?: (q: Payload) => void
  onCloseNotification: () => void
  hasExcelOption: boolean
  areKpisAndFiltersHidden: boolean
  wasLastPage: boolean
}

type DataSelectionType = 'surveys' | 'kpis'

const ExportModal = ({
  areKpisAndFiltersHidden,
  dataSelectionType = 'kpis',
  hasExcelOption,
  isBatchExporting,
  missingData,
  onClose,
  onCloseNotification,
  onExport,
  surveys,
  waitingForBatchResponse,
  wasLastPage,
  hasLimitedFilterSet = false,
  hasPagination,
  isDownloading,
  onBatchExport,
  ...rest
}: Props) => {
  const [query, setQuery] = useState<Payload>({
    end_date: getDateNow(),
    start_date: datePlusDays(getDateNow(), -365),
    where_meta: {},
    page: 0,
    as_csv: true,
  })

  const { metas: reportingFilters } = useReportingFilters()
  const { config: cdbs } = useCommonDbSettingsConfig()

  const isSurveys = dataSelectionType === 'surveys'
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isBatchExportModalOpen, setIsBatchExportModalOpen] = useState<boolean>(false)
  const [fileFormat, setFileFormat] = useState<'csv' | 'xlsx'>('csv')
  useEffect(() => {
    trackingService.track(TrackingEvent.OpenExportModal)
    buildQueryFromProps(rest.query, isSurveys)
  }, [])

  const buildQueryFromProps = (q: Payload, isSurveys: boolean): Payload => {
    const result: Payload = cloneDeep(q)
    if (isSurveys && (!result.page || result.page < 0)) {
      result.page = 0
    }
    return result
  }

  const getReportingFilters = () => {
    if (!hasLimitedFilterSet) {
      return reportingFilters
    } else {
      return getLimitedFilterSet()
    }
  }

  const getLimitedFilterSet = () => {
    const allFilters = reportingFilters
    const allowedFilterKeys: string[] =
      cdbs && cdbs.response_rate_filters ? cdbs.response_rate_filters : []
    const result: WhereMeta = {}
    if (!allFilters) return result
    allowedFilterKeys.forEach((key: string) => {
      result[key] = allFilters[key]
    })

    return result
  }

  const updateQuery = (key: keyof Payload, value: string | WhereMeta | number[]): void => {
    const copyOfQuery = { ...cloneDeep(query), [key]: value }
    copyOfQuery.page = 0
    setQuery(copyOfQuery)
  }

  const handleBatchExport = (doExport: boolean) => {
    // TODO tracking for batchexport
    setIsBatchExportModalOpen(false)
    if (doExport && onBatchExport) onBatchExport(query)
  }

  const handleExport = (isSurveys: boolean): void => {
    onExport(query, fileFormat)
    if (!isSurveys) {
      trackingService.track(TrackingEvent.ExportData)
      closeModal()
    } else {
      trackingService.track(TrackingEvent.ExportResponseRate)
      updateQueriedPage()
    }
  }

  const closeModal = () => {
    trackingService.track(TrackingEvent.CloseExportModal)
    setIsOpen(false)
  }

  const updateQueriedPage = () => {
    const queryCopy = cloneDeep(query)
    if (!isNull(queryCopy.page) && !isUndefined(queryCopy.page)) queryCopy.page++
    setQuery(queryCopy)
  }

  const getShowBatchExport = () => {
    if (!cdbs) return false
    if (cdbs.batch_response_rate_export !== undefined) {
      return cdbs.batch_response_rate_export
    } else return false
  }

  const filters: WhereMeta = getReportingFilters() || {}
  const noOfFilters: number = Object.keys(filters).length
  const hasNoPagesExportedYet: boolean = !isNil(query) && query.page === 0

  let showBatchExport = false
  showBatchExport = getShowBatchExport()

  return (
    <div className='export-modal'>
      <Rodal
        closeOnEsc
        animation='slideUp'
        visible={isOpen}
        onClose={() => closeModal()}
        onAnimationEnd={() => {
          if (!isOpen) {
            onClose()
          }
        }}
      >
        <div className='container'>
          <h5>
            {tCommon('label.export')}{' '}
            {isSurveys
              ? tCommon('label.responseRates').toLocaleLowerCase()
              : tCommon('label.data').toLocaleLowerCase()}
          </h5>
          {missingData && (
            <p className='red-text'>Failed to fetch some data: too much data and filters.</p>
          )}

          {!areKpisAndFiltersHidden && (
            <>
              <div className='divider' />

              <br />
              <h6>{tCommon('label.dates')}</h6>
              <div className='section'>
                <ModuleFiltersTimeSelection
                  startDate={query.start_date}
                  endDate={query.end_date}
                  onChangeDate={(value: string, isStartDate: boolean): void => {
                    if (isStartDate) {
                      updateQuery('start_date', value)
                    } else {
                      updateQuery('end_date', value)
                    }
                  }}
                  isChangedAfterClose={false}
                />
              </div>

              <h6>{isSurveys ? tCommon('label.surveys') : tCommon('label.questions')}</h6>
              <div className='section'>
                {isSurveys ? (
                  <SelectSurveyButton
                    surveys={surveys}
                    selectedSurveys={
                      query.survey_ids
                        ? (surveys || []).filter((s) => query.survey_ids!.includes(s.id))
                        : []
                    }
                    onSelect={(surveys: Survey[]) => {
                      updateQuery(
                        'survey_ids',
                        surveys.map((s) => s.id),
                      )
                    }}
                  />
                ) : (
                  <SelectKpiButton
                    kpiType={VALIDKPITYPES.all}
                    selectedKpis={query.kpis || []}
                    onSelectKpi={(kpis: Kpi.Kpi[]) => {
                      updateQuery(
                        'kpis',
                        kpis.map((kpi) => kpi.id),
                      )
                    }}
                  />
                )}
                <br />
                <p>
                  {tCommon('info.exportModal', {
                    type: tCommon(
                      'label.' + (isSurveys ? 'survey' : 'question'),
                    ).toLocaleLowerCase(),
                  })}
                </p>
              </div>

              {noOfFilters > 0 && (
                <>
                  <h6>{tCommon('button.filter')}</h6>
                  <div className='section'>
                    <MetadataBoard
                      customFilterSet={filters}
                      selections={Object.entries(query.where_meta).map(([key, values]) => ({
                        key,
                        values,
                      }))}
                      isMulti
                      isUsedForFiltering
                      onChange={(filters: unknown[]): void => {
                        if (filters && !isEmpty(filters)) {
                          updateQuery(
                            'where_meta',
                            transformFiltersToWhereMetaForm(filters).where_meta,
                          )
                        }
                      }}
                    />
                  </div>
                </>
              )}

              {(hasPagination || isSurveys) && (
                <div className='section'>
                  <div className='row' data-testid='exportModalPagingIndicator'>
                    {hasNoPagesExportedYet ? (
                      <span>
                        No pages exported. Press <i>Export</i> to export the first one.
                      </span>
                    ) : query.page === 1 && wasLastPage ? (
                      <span>No data for the selected filters.</span>
                    ) : (
                      <span>
                        You have exported {query.page} page(s).{' '}
                        {wasLastPage ? ' There are no more pages' : ''}
                      </span>
                    )}
                    {showBatchExport && (
                      <>
                        <br></br>
                        <span>For big exports you can use the Batch Export.</span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {hasExcelOption && (
            <>
              <br />
              <h6>{tCommon('label.fileFormat')}</h6>
              <div className='radio'>
                <div>
                  <input
                    type='radio'
                    id={'excel'}
                    value={'xlsx'}
                    checked={fileFormat === 'xlsx'}
                    onChange={() => setFileFormat('xlsx')}
                  />
                  <label htmlFor={'excel'}>Excel</label>
                </div>
              </div>
              <div className='radio'>
                <input
                  type='radio'
                  id={'csv'}
                  value={'csv'}
                  checked={fileFormat === 'csv'}
                  onChange={() => setFileFormat}
                />
                <label htmlFor={'csv'}>CSV</label>
              </div>
              <br />
            </>
          )}

          {showBatchExport && isSurveys && (
            <div className='row'>
              <div className='col s5 offset-s1'>
                <MainButton
                  text='Batch Export..'
                  isDisabled={waitingForBatchResponse}
                  onClick={() => setIsBatchExportModalOpen(true)}
                />
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col s5 offset-s1'>
              <MainButton
                text={
                  isDownloading ? tCommon('button.exporting') + '...' : tCommon('button.export')
                }
                isDisabled={isDownloading || (wasLastPage && !hasNoPagesExportedYet)}
                onClick={() => handleExport(isSurveys)}
              />
            </div>

            <div className='col s2 offset-s2'>
              <MainButton text={tCommon('button.cancel')} isFlat onClick={() => closeModal()} />
            </div>
          </div>
        </div>
      </Rodal>

      {isBatchExportModalOpen && <BatchExportModal onClose={handleBatchExport} />}

      {isBatchExporting && (
        <NotificationModal
          title='Already Prosessing!'
          notification='Your previous data export is still running for this tenant. You will recieve an email when it is done. Please wait.'
          onClose={onCloseNotification}
        />
      )}
    </div>
  )
}

export default ExportModal
