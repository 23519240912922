import { cloneDeep, each, isUndefined, omit } from 'lodash'
import { moduleTypes } from '../react-constants/moduleTypes'
import { isMultilevel, isTestMode } from './authService'
import { MODULECONSTANTS } from '../react-constants/modules'

const styles = {}
const fetchModuleSettings = function (name) {
  var tmpsetting = getModuleSettings(name)
  var setting = cloneDeep(tmpsetting)
  return setting
}

// Remove unnecessary or unwanted data from module (e.g. hashkey, title etc.)
function cleanUpModuleData(module) {
  module = removeHashFromModule(module)
  module = removeTitle(module)
  module = removeSelections(module)
  module = setFiltergrouping(module)
  if (module.modules) {
    var cleanedUpModules = []
    each(module.modules, function (childModule) {
      childModule = cleanUpModuleData(childModule)
      cleanedUpModules.push(childModule)
    })
    module.modules = cleanedUpModules
  }
  return module
}

function removeHashFromModule(module) {
  module = omit(module, '$$hashKey')
  return module
}

// Remove module title if you need the title to be fixed
function removeTitle(module) {
  if (getModuleSettings[module.type] && getModuleSettings[module.type].fixedTitle) {
    module = omit(module, 'title')
  }
  return module
}

// Remove module selections if you need selections to be fixed
function removeSelections(module) {
  if (getModuleSettings[module.type] && getModuleSettings[module.type].fixedSelections) {
    module = omit(module, 'selections')
  }
  return module
}

// Delete filterGrouping parameter if it's set to undefined
function setFiltergrouping(module) {
  if (isUndefined(module.filterGrouping)) delete module.filterGrouping
  return module
}

function getModuleStyles(type) {
  if (type && styles[type]) return styles[type]
}

styles[moduleTypes.NUMBERTREND] = [
  {
    id: 'numbertrend-style-rightalign',
    name: 'Right align',
  },
  {
    id: 'numbertrend-style-rightalign-small',
    name: 'Right align small',
  },
]

styles[moduleTypes.GROUP] = [
  {
    id: 'group-style2',
    name: 'Style 2',
  },
]

export function getModuleSettings(name) {
  switch (name) {
    case moduleTypes.BAR:
      return {
        multiSelect: true,
        defaultTitle: 'Bar Chart',
        treeViewSelection: true,
        editAxisValues: true,
        okCancelButtons: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleShowAxisUnit: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
        horizontalBarSelection: true,
      }
    case moduleTypes.TABLE:
      return {
        multiSelect: true,
        defaultTitle: 'Table Chart',
        treeViewSelection: true,
        isColorAxis: true,
        editAxisValues: true,
        okCancelButtons: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleShowAxisUnit: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
        liveDataSelection: true,
      }
    case moduleTypes.FREQUENCY:
      return {
        multiSelect: true,
        chartTypeEditable: true,
        defaultTitle: 'Freq Chart',
        noGroupingInFilterBar: true,
        editAxisValues: true,
        okCancelButtons: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleShowAxisUnit: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
        percentageSelection: true,
        horizontalBarSelection: true,
        hasComparison: true,
      }
    case moduleTypes.LINE:
      return {
        multiSelect: true,
        filterGroupings: MODULECONSTANTS.TIME_SERIES_GROUPS,
        defaultTitle: 'Line Chart',
        treeViewSelection: true,
        tableToggle: true,
        editAxisValues: true,
        okCancelButtons: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleShowAxisUnit: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
      }
    case moduleTypes.PATHLINE:
      return {
        multiSelect: true,
        filterGroupings: MODULECONSTANTS.TIME_SERIES_GROUPS,
        fixedTitle: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleShowAxisUnit: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
        defaultTitle: 'Individual touch points',
        editAxisValues: true,
        okCancelButtons: true,
      }
    case moduleTypes.FOURFIELDER:
      return {
        multiSelect: true,
        defaultTitle: '4 Fielder Chart',
        okCancelButtons: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleShowAxisUnit: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
        hideYAxisSelection: true,
      }
    case moduleTypes.MAP:
      return {
        multiSelect: true,
        isColorAxis: true,
        dropDownDataSelection: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleShowAxisUnit: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
        okCancelButtons: true,
      }
    case moduleTypes.NUMBERTREND:
      return {
        multiSelect: true,
        titleModule: true,
        okCancelButtons: true,
        noGroupingInFilterBar: true,
        dropDownDataSelection: true,
        imageSelection: true,
        liveDataSelection: true,
        comparisonDataSelection: true,
        moduleStyles: styles[moduleTypes.NUMBERTREND],
        countsOfSurveys: true,
      }
    case moduleTypes.LISTNUMERIC:
      return {
        multiSelect: true,
        noGroupingInFilterBar: true,
        titleModule: true,
        titleHideDates: isMultilevel() ? true : false,
        titleLeftAlign: isMultilevel() ? true : false,
        topBotSelection: true,
        analyzeConfSelection: true,
        companySelect: true,
        editTopBotConf: true,
      }
    case moduleTypes.PICTURE:
      return {
        multiSelect: true,
        titleModule: true,
        noGroupingInFilterBar: true,
        imageSelection: true,
        placeholderSelection: isTestMode() ? true : false,
        linkToUrl: true,
        okCancelButtons: true,
      }
    case moduleTypes.PIETABULAR:
      return {
        defaultTitle: 'Pie Tabular',
      }
    case moduleTypes.ACTIONS:
      return {
        multiSelect: true,
        filterGroupings: MODULECONSTANTS.TIME_SERIES_GROUPS,
      }
    case moduleTypes.OPEN:
      return {
        hideOldFiltersAndSettings: true, // Use module.html, but hide old filter bar and settings/delete buttons
        multiSelect: true,
        noGroupingInFilterBar: true,
        defaultTitle: 'Open answers',
        titleLeftAlign: isMultilevel() ? true : false,
        searchFilterBar: true,
        liveDataSelection: true,
        hideYAxisSelection: true,
      }
    case moduleTypes.ALERT:
      return {
        defaultTitle: 'Alerts',
      }
    case moduleTypes.WHEEL:
      return {
        defaultTitle: 'Wheel Chart',
      }
    default:
      return {}
  }
}

export { fetchModuleSettings, cleanUpModuleData, getModuleStyles }
