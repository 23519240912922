/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  errorAssignAlert,
  errorFetchMetadataKeys,
  errorGetAlertConfig,
  errorGetAllAlerts,
  errorGetAllUsers,
  errorSaveAlert,
  errorUpdateMeta,
} from '../components/Dashboards/DashboardModules/FeedbackAlerts/FeedBackAlertNotifications'
import {
  AlertChanges,
  FeedbackAlert,
} from '../components/Dashboards/DashboardModules/FeedbackAlerts/alertTypes'
import { TrackingEvent } from '../react-constants'
import { post, get, put } from './apiService'
import { getUsername } from './authService'
import { getGenericConfiguration } from './configService'
import trackingService from './trackingService'

function generatePayload(alert: FeedbackAlert, changes: AlertChanges) {
  if (changes.state === 'OPEN') {
    alert.started_time = null
    alert.closed_time = null
  }
  if (changes.state === 'IN_PROGRESS') {
    alert.started_time = alert.started_time ? alert.started_time : new Date().toISOString()
    alert.started_by = alert.started_by ? alert.started_by : getUsername() || ''
    alert.closed_time = null
    alert.closed_by = null
  }
  if (changes.state === 'CLOSED') {
    alert.started_time = alert.started_time ? alert.started_time : new Date().toISOString()
    alert.started_by = alert.started_by ? alert.started_by : getUsername() || ''
    alert.closed_time = alert.closed_time ? alert.closed_time : new Date().toISOString()
    alert.closed_by = alert.closed_by ? alert.closed_by : getUsername() || ''
  }

  changes.category === '' ? null : changes.category
  changes.subcategory === '' ? null : changes.subcategory
  changes.comment === '' ? null : changes.category
  changes.state === '' ? null : changes.subcategory
  Object.keys(changes).forEach(
    (k) =>
      changes[k as keyof AlertChanges] === undefined && delete changes[k as keyof AlertChanges],
  )
  const payload = { ...alert, ...changes }
  return payload
}

function alertSave(payload: FeedbackAlert, id: number) {
  try {
    trackingService.track(TrackingEvent.SaveAlert)
    return put('PUT_ALERT', payload, { id })
      .then((res: any) => res)
      .catch(() => {
        return Promise.reject(errorSaveAlert)
      })
  } catch (e) {
    return Promise.reject(errorSaveAlert)
  }
}

function assignAlert(alertId: number, assignToUserId: number) {
  try {
    const payload = {
      alert_id: alertId,
      to_user_id: assignToUserId,
    }
    return post('POST_ALERT_ASSIGNMENT', payload)
      .then((res: any) => res)
      .catch(() => Promise.reject(errorAssignAlert))
  } catch (e) {
    return Promise.reject(errorAssignAlert)
  }
}

function fetchAllowedMetadataKeys() {
  try {
    const allowed_metas = getGenericConfiguration('alert_meta')
    if (allowed_metas) {
      return allowed_metas
        .then((res) => {
          if (res) {
            const metas = res && res.meta_keys ? res.meta_keys : null
            return metas
          }
        })
        .catch(() => Promise.reject(errorFetchMetadataKeys))
    } else {
      return Promise.reject(errorFetchMetadataKeys)
    }
  } catch (e) {
    return Promise.reject(errorFetchMetadataKeys)
  }
}

function getAlertConfig() {
  try {
    const alert_conf = getGenericConfiguration('alert')
    if (alert_conf) {
      return alert_conf.then((res) => res).catch(() => Promise.reject(errorGetAlertConfig))
    } else {
      return Promise.reject(errorGetAlertConfig)
    }
  } catch (e) {
    return Promise.reject(errorGetAlertConfig)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAllAlerts = (query: any) => {
  try {
    return post('GET_ALL_ALERTS', query)
      .then((res: any) => res)
      .catch(() => Promise.reject(errorGetAllAlerts))
  } catch (e) {
    return Promise.reject(errorGetAllAlerts)
  }
}

const getUsers = () => {
  try {
    return get('GET_USERS')
      .then((res) => res)
      .catch(() => Promise.reject(errorGetAllUsers))
  } catch (e) {
    return Promise.reject(errorGetAllUsers)
  }
}

const getOneAlert = (id: number) => {
  try {
    return get('GET_ALERT', { id })
      .then((res) => res)
      .catch(() => Promise.reject(errorGetAllUsers))
  } catch (e) {
    return Promise.reject(errorGetAllUsers)
  }
}

const getMetas = () => {
  return get('GET_METAS').then((res) => res)
}

const updateMetaValue = (key: string, value: string | number, completedSurveyId: number) => {
  try {
    return put('PUT_SURVEY_META_DATA', { key, value }, { completedSurveyId })
      .then((res: any) => res)
      .catch(() => Promise.reject(errorUpdateMeta))
  } catch (e) {
    return Promise.reject(errorUpdateMeta)
  }
}

export {
  generatePayload,
  alertSave,
  assignAlert,
  fetchAllowedMetadataKeys,
  getAllAlerts,
  getUsers,
  getAlertConfig,
  getOneAlert,
  getMetas,
  updateMetaValue,
}
