import { getNumericKpis } from '../react-services/kpiService'
import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreCRUD, { ActionState } from './useStoreNonResetting'

export type NumericKpi = { id: number; name: string; grouping?: string }

export const numericKpiStorage = makeNonResettingObservable<NumericKpi[] | null>({})

const useNumericKpis = (doFetch = true) => {
  const { tenant, user } = useStoreOwner(numericKpiStorage)
  const storageKey = 'numericKpis'
  const {
    dataState: { data, error, state },
  } = useStoreCRUD(numericKpiStorage, storageKey, user, tenant, doFetch, getNumericKpis)

  return {
    numericKpis: data,
    isLoading: state === ActionState.LOADING,
    error,
  }
}

export default useNumericKpis
