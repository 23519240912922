import React from 'react';
import Rodal from 'rodal';
import MainButton from '../Buttons/MainButton';

const DEFAULTTITLE = "Confirm deletion";

const DeleteModal = ({onConfirm, onCancel, customTitle}) => {
  return (
    <Rodal
      closeOnEsc
      animation="slideUp"
      visible
      onClose={onCancel}
    >
      <h5>{customTitle ? customTitle : DEFAULTTITLE}</h5>
      <hr/>
      <div className="row">&nbsp;</div>
      <div className="row">
        <div className="col"><MainButton data-cy="deleteModalConfirm" onClick={onConfirm} text="OK" /></div>
        <div className="col"><MainButton data-cy="deleteModalCancel" isFlat onClick={onCancel} text="Cancel" /></div>
      </div>
    </Rodal>
  );
};

export default DeleteModal;