import React from 'react'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import { infoTrendsDefaultMetaGroup } from '../PietabularNotifications'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import useReportingFilters from '../../../../../stores/useReportingFilters'

const GroupingMetaKeySelect = () => {
  const { metas, isLoading: loading, error } = useReportingFilters()
  const hasError = !!error
  const filteredMetaKeys = Object.keys(metas || {})
  return (
    <>
      {loading && <LoadingIndicator />}
      {hasError && <h5>{error}</h5>}
      {!loading && !hasError && !!filteredMetaKeys && (
        <DropdownInput
          options={filteredMetaKeys}
          settingsName='defaultMetaGroup'
          data_testid='moduleDefaultMetaGroup'
          isRequired
          title={
            <TextWithInfo
              text='Default grouping meta key'
              info={infoTrendsDefaultMetaGroup}
              tooltipDirection='left'
            />
          }
        />
      )}
    </>
  )
}

export default GroupingMetaKeySelect
