import React, { useMemo } from 'react'
import { useGroupModule } from '../contexts/GroupContext'
import FilterBar from './FilterBar'
import { CustomFilter, QuickTimeframe } from '../groupModuleTypes'
import { useRootActions } from '../contexts/RootContext'
import { cloneDeep } from 'lodash'
import {
  FilterArray,
  setFilterArrayEndDate,
  setFilterArrayStartDate,
} from '../../../../../react-services/filterService'
import { getDatesQuickTimeframeButton } from '../../../../../react-services/datesService'
import { formatISO } from 'date-fns'
import { useGroupKpis } from '../contexts/GroupKpiContext'

type FilterBarCntrProps = {
  handleOpenSettingsModal: () => void
  handleOpenDeleteModal: () => void
  isCntrHovered?: boolean
}

const FilterBarCntr = ({
  handleOpenDeleteModal,
  handleOpenSettingsModal,
  isCntrHovered,
}: FilterBarCntrProps) => {
  const { module, isNested, parentFilters } = useGroupModule()[0]
  const [{ kpis }] = useGroupKpis()
  const { saveModule } = useRootActions()

  const showFilterToggle = useMemo(() => {
    if (module?.hidefilters) return false
    if (!isNested) return true
    if (module?.customfilters === CustomFilter.NONE) return false
    if (module?.customfilters === CustomFilter.CUSTOM_TIMEFRAME) return false
    if (!module?.customfilters) return false
    return true
  }, [module?.customfilters, module?.hidefilters])

  const showQuickTimeButtons = useMemo(() => {
    if (module?.options?.isQuickTimeButtonsEnabled === false) return false
    if (module?.hidefilters) return false
    if (!isNested) return true
    if (isNested && module?.options?.isQuickTimeButtonsEnabled !== true) return false
    if (module?.customfilters === CustomFilter.NONE) return false
    if (module?.customfilters === CustomFilter.MERGE) return false
    if (!module?.customfilters) return false
    return true
  }, [module?.options?.isQuickTimeButtonsEnabled, module?.customfilters])

  const showTimeDisplay = useMemo(() => {
    if (module?.options?.isTimeDisplayEnabled === false) return false
    if (!isNested) return true
    if (module?.hidefilters) return false
    if (module?.customfilters === CustomFilter.NONE) return false
    if (module?.customfilters === CustomFilter.MERGE) return false
    if (!module?.customfilters) return false
    return true
  }, [module?.options?.isTimeDisplayEnabled, module?.customfilters])

  const isBarCollapsed =
    !!isNested && !showQuickTimeButtons && !showTimeDisplay && module?.hidefilters !== false

  const saveFilters = (filters: FilterArray) => {
    if (!module) return
    const newModule = cloneDeep(module)
    newModule.settings = filters
    delete newModule.quicktimeframebutton
    delete newModule.timeframedays
    delete newModule.timeframedays_future
    saveModule(newModule)
  }

  const handleSelectQuickTimeFrame = (tf: QuickTimeframe) => {
    if (!module) return
    const dates = getDatesQuickTimeframeButton(tf, new Date())
    const newModule = cloneDeep(module)
    newModule.quicktimeframebutton = tf
    if (!newModule.options) newModule.options = {}
    const newStartDate = formatISO(dates.start).substring(0, 10)
    const newEndDate = formatISO(dates.end).substring(0, 10)
    newModule.settings = setFilterArrayStartDate(newModule.settings || [], newStartDate)
    newModule.settings = setFilterArrayEndDate(newModule.settings || [], newEndDate)
    delete newModule.timeframedays
    delete newModule.timeframedays_future
    saveModule(newModule)
  }

  const isMergeFiltersEnabled = module?.customfilters === CustomFilter.MERGE

  return (
    <FilterBar
      handleOpenDeleteModal={handleOpenDeleteModal}
      handleOpenSettingsModal={handleOpenSettingsModal}
      onSelectQuickTimeFrame={handleSelectQuickTimeFrame}
      selectedQuickTimeFrame={module?.quicktimeframebutton}
      isCntrHovered={isCntrHovered}
      isBarCollapsed={isBarCollapsed}
      isSmall={isNested}
      showFilterToggle={showFilterToggle}
      showQuickTimeButtons={showQuickTimeButtons}
      showTimeDisplay={showTimeDisplay}
      filters={module?.settings}
      filtersSelectedAlready={isMergeFiltersEnabled ? parentFilters : []}
      isModuleActionsVisible={true}
      saveFilters={saveFilters}
      dynamicFiltersKpis={kpis}
      keepStateUpdated={
        !!module?.timeframedays || !!module?.timeframedays_future || !!module?.quicktimeframebutton
      }
    />
  )
}

export default FilterBarCntr
