import { useEffect, useState } from 'react'
import { makeNonResettingObservable } from './nonResettingObservable'
import useDashboardSettingsConfig, { DashboardSettingsConfig } from './useDashboardSettingsConfig'
import useDbSettingsConfig from './useDbSettingsConfig'
import useDbTemplateConfig, { DashboardSettings, DbTemplate } from './useDbTemplateConfig'
import useStoreOwner from './useStoreOwner'
import { cloneDeep, debounce } from 'lodash'
import { isTemplateUser, isTestRunner } from '../react-services/authService'
import { ModuleType } from '../components/Dashboards/DashboardModules/Group/groupModuleTypes'
import { v4 as uuid } from 'uuid'
import useStoreNonResetting from './useStoreNonResetting'
import { useEffectOnce } from 'react-use'
import { isReportMode } from '../utilities'

declare global {
  interface Window {
    sectionsConfig: DashboardSettings[]
  }
}

const EMPTY_MY_DASHBOARD: DashboardSettings[] = (() => [
  {
    name: 'My Dashboard',
    id: uuid(),
    dashboards: [
      {
        name: 'Dashboard',
        modules: [
          {
            type: ModuleType.GROUP,
          },
        ],
      },
    ],
  },
])()

const dashboardSectionsStorage = makeNonResettingObservable<DashboardSettings[]>({})
const modifiedDashboardSectionsStorage = makeNonResettingObservable<DashboardSettings[]>({})

const useDashboardSections = () => {
  const { user, tenant } = useStoreOwner(dashboardSectionsStorage)
  useStoreOwner(modifiedDashboardSectionsStorage)

  const key = 'sections'
  const modifiedKey = 'modifiedSections'

  useStoreNonResetting(dashboardSectionsStorage, key, user, tenant, true)
  const { dataState: modifiedSectionsState } = useStoreNonResetting(
    modifiedDashboardSectionsStorage,
    modifiedKey,
    user,
    tenant,
    true,
  )

  const STORAGE_DEBOUNCE_TIME = 1000
  const {
    config: dbTemplate,
    isLoading: isLoadingDbTemplate,
    error: errorLoadingDbTemplate,
    putDbTemplate,
  } = useDbTemplateConfig()
  const {
    config: dbSettings,
    isLoading: isLoadingDbSettings,
    error: errorLoadingDbSettings,
  } = useDbSettingsConfig()
  const {
    config: dashboardSettings,
    isLoading: isLoadingDashboardSettings,
    error: errorLoadingDashboardSettings,
    postDashboardSettings,
  } = useDashboardSettingsConfig()

  const isLoading = isLoadingDbTemplate || isLoadingDbSettings || isLoadingDashboardSettings
  const error = errorLoadingDbTemplate || errorLoadingDbSettings || errorLoadingDashboardSettings

  const unmodifiedSections = dashboardSectionsStorage.get(key)
  const modifiedSections = modifiedDashboardSectionsStorage.get(modifiedKey)
  const [sections, setSections] = useState<null | DashboardSettings[]>(
    modifiedSections || unmodifiedSections || null,
  )

  useEffectOnce(() => {
    const unsubscribe = modifiedDashboardSectionsStorage.subscribe(setSections, 'modifiedSections')
    return () => {
      unsubscribe()
    }
  })

  useEffect(() => {
    if (isLoading) return
    if (error) return
    if (modifiedSections) return
    const newSections = [] as DashboardSettings[]
    const allowedTemplateKeys = dbSettings?.templates || ([] as string[])

    if (isTemplateUser()) {
      const allDashboards = getAllCombinedDashboards(dbTemplate)
      newSections.push(...allDashboards)
    } else {
      const userDashboards =
        dbTemplate && !isTestRunner()
          ? getUserCombinedDashboardsFromTemplates(dbTemplate, allowedTemplateKeys)
          : dashboardSettings
          ? getUserDashboardsFromDashboardSettings(dashboardSettings)
          : []

      if (dashboardSettings?.mydashboards?.length) {
        const myDashboardsWithIds = cloneDeep(dashboardSettings.mydashboards)
        myDashboardsWithIds.forEach((dashboard) => {
          if (!dashboard.id) dashboard.id = uuid()
        })
        userDashboards.push(...myDashboardsWithIds)
      } else {
        !!dbTemplate && userDashboards.push(...EMPTY_MY_DASHBOARD)
      }
      newSections.push(...userDashboards)
    }

    const storedSections = localStorage.getItem('sections')
    if (isTestRunner() && storedSections) {
      newSections.length = 0
      newSections.push(...JSON.parse(storedSections))
    }
    if (isReportMode()) window.sectionsConfig = newSections
    if (isTestRunner()) localStorage.setItem('sections', JSON.stringify(newSections))
    const debouncer = debounce(() => {
      if (unmodifiedSections) return
      dashboardSectionsStorage.set(newSections, key)
      !modifiedSections && setSections(newSections)
    }, STORAGE_DEBOUNCE_TIME)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [isLoading, error, dbTemplate, dbSettings, dashboardSettings])

  function getAllCombinedDashboards(templates: DbTemplate | null) {
    const newCombinedDashboards = [] as DashboardSettings[]
    if (templates) {
      for (const templateKey in templates) {
        const template = templates[templateKey]
        if (!template) continue
        for (const dashboard of template) {
          const newDashBoard = cloneDeep(dashboard)
          newDashBoard.templatekey = templateKey
          newCombinedDashboards.push(newDashBoard)
        }
      }
    }
    return newCombinedDashboards
  }

  /**
   * Use templates config and template keys config to filter out what templates the user
   * is allowed to see and create a multilevel dashboard from the allowed templates.
   * @param templates
   * @param allowedTemplateKeys
   * @returns DashboardSettings[]
   */
  function getUserCombinedDashboardsFromTemplates(
    templates: DbTemplate,
    allowedTemplateKeys: string[],
  ) {
    const newCombinedDashboards = [] as DashboardSettings[]
    const templateKeys = allowedTemplateKeys.length ? allowedTemplateKeys : Object.keys(templates)

    for (const templateKey of templateKeys) {
      const template = templates[templateKey]
      if (!template) continue
      for (const dashboard of template) {
        const newDashBoard = cloneDeep(dashboard)
        newDashBoard.templatekey = templateKey
        if (!newDashBoard.id) newDashBoard.id = uuid()
        newCombinedDashboards.push(newDashBoard)
      }
    }
    return newCombinedDashboards
  }

  /**
   * If no templates config exist and there are dashboard settings, create multilevel
   * dashboard from dashboard settings by creating artificial section called "dashboard".
   * This is to maintain backward compatibility with old dashboard settings when there was
   * no templates or template config plus the dashboard was not multilevel.
   * @param dashboardSettings
   * @returns DashboardSettings[]
   */
  function getUserDashboardsFromDashboardSettings(dashboardSettings: DashboardSettingsConfig) {
    if (!dashboardSettings) return []
    if (!dashboardSettings.dashboards) return []
    const sections = [] as DashboardSettings[]
    if (!dashboardSettings.sections && dashboardSettings.dashboards) {
      const newSection = {
        name: 'DASHBOARD',
        dashboards: dashboardSettings.dashboards,
        id: uuid(),
      }
      sections.push(newSection)
    }
    if (dashboardSettings.sections) {
      sections.push(...dashboardSettings.sections)
    }

    return sections
  }

  /**
   * If user is not templateuser and templtes are active, we use modified sections storage to save
   * the current filters etc to allow session to keep the changes. Usable then in report requests.
   * If user is templateuser and templates are active, we save the changes to the template config.
   * If user is not templateuser and templates are not active, we save the changes to the dashboard settings
   * which is the old way of saving the dashboard settings when there was no templates.
   * @param newSection
   * @returns
   */
  const saveSection = (newSection: DashboardSettings) => {
    const isTemplateUserAndTenantHasTemplatesImplemented = isTemplateUser() && dbTemplate
    const isNormalUserAndHasTemplatesImplementedAndSavesMyDashboard =
      !isTemplateUser() &&
      dbTemplate &&
      newSection.name?.toLocaleLowerCase() === 'my dashboard' &&
      !isTestRunner()
    const isNormalUserAndTenantDoesNotHaveTemplatesImplemented =
      (!isTemplateUser() && !dbTemplate) || isTestRunner()
    const isNormalUserAndTenantHasTemplatesImplemented =
      !isTemplateUser() && !!dbTemplate && !isTestRunner()

    if (isTemplateUserAndTenantHasTemplatesImplemented) {
      if (!newSection.templatekey) return
      const currentDbTemplateConfig = cloneDeep(dbTemplate)
      let newSections = cloneDeep(currentDbTemplateConfig[newSection.templatekey])
      if (!newSections || !newSections.length) return
      newSections = newSections.map((section) =>
        section.id === newSection.id ? newSection : section,
      )

      const payload = { value: { [newSection.templatekey]: newSections } }
      const newSectionsConfig = Object.entries(currentDbTemplateConfig).flatMap(([key, value]) => {
        if (key === newSection.templatekey) return newSections
        else return value
      })
      modifiedDashboardSectionsStorage.set(newSectionsConfig, modifiedKey)
      putDbTemplate(payload)
      return
    }

    if (isNormalUserAndTenantDoesNotHaveTemplatesImplemented) {
      const currentDashboardSettingsConfig = cloneDeep(dashboardSettings)
      if (!currentDashboardSettingsConfig) return
      currentDashboardSettingsConfig.sections = currentDashboardSettingsConfig.sections?.map(
        (section) => (section.id === newSection.id ? newSection : section),
      )
      modifiedDashboardSectionsStorage.set(
        currentDashboardSettingsConfig?.sections || [],
        modifiedKey,
      )
      if (isTestRunner())
        localStorage.setItem(
          'sections',
          JSON.stringify(currentDashboardSettingsConfig?.sections || []),
        )
      postDashboardSettings(currentDashboardSettingsConfig)
      return
    }

    if (isNormalUserAndHasTemplatesImplementedAndSavesMyDashboard) {
      let currentDashboardSettingsConfig = cloneDeep(dashboardSettings)
      if (!currentDashboardSettingsConfig) {
        currentDashboardSettingsConfig = {}
        currentDashboardSettingsConfig.mydashboards = [newSection]
      } else if (
        !currentDashboardSettingsConfig.mydashboards ||
        !currentDashboardSettingsConfig.mydashboards.length
      ) {
        currentDashboardSettingsConfig.mydashboards = [newSection]
      } else {
        currentDashboardSettingsConfig.mydashboards =
          currentDashboardSettingsConfig.mydashboards?.map((section) =>
            section.name === newSection.name ? newSection : section,
          )
      }

      let newModifiedSections = cloneDeep(sections)
      if (!newModifiedSections) return
      newModifiedSections = newModifiedSections.map((section) =>
        section.id === newSection.id ? newSection : section,
      )
      modifiedDashboardSectionsStorage.set(newModifiedSections, modifiedKey)
      postDashboardSettings(currentDashboardSettingsConfig)
      return
    }

    if (isNormalUserAndTenantHasTemplatesImplemented) {
      let newModifiedSections = cloneDeep(sections)
      if (!newModifiedSections) return
      newModifiedSections = newModifiedSections.map((s) =>
        s.id === newSection.id ? newSection : s,
      )
      modifiedDashboardSectionsStorage.set(newModifiedSections, modifiedKey)
      return
    }
  }

  return {
    sections: modifiedSectionsState?.data || sections,
    isLoading: isLoading && !sections,
    error,
    saveSection,
  }
}

export default useDashboardSections
