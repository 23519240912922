import React from 'react'
import { useOpenCategoryToolData } from './OpenCategoryToolProvider'
import { CategoriesAndValues } from './openCategoryToolTypes'
import css from './OpenCategoryToolModal.module.scss'
import { tCommon, tKpi } from '../../../../../../languages/i18n'
import { Search } from '@mui/icons-material'

const OpenCategoryToolFilter = () => {
  const [{ allCategories }, setOpenCategoryToolData] = useOpenCategoryToolData()

  const handleCategoryFilter = (searchWord: string) => {
    const newFilters: CategoriesAndValues = {}

    Object.entries(allCategories).map((category) => {
      const categoryHeader = category[0]
      const categories = category[1]
      const updatedCategories = categories.filter((category) => {
        return tKpi(category).toLocaleLowerCase().includes(searchWord.toLocaleLowerCase())
      })
      newFilters[categoryHeader] = updatedCategories
    })

    setOpenCategoryToolData((prev) => ({
      ...prev,
      filteredCategories: newFilters,
    }))
  }

  const handleFilterOnFocusToggle = (filterOnFocus: boolean) => {
    setOpenCategoryToolData((prev) => ({
      ...prev,
      isFilterOnFocus: filterOnFocus,
    }))
  }

  return (
    <div className={css.category_tool_filter}>
      <Search sx={{ padding: '5px', fontSize: '36px', marginTop: '10px' }} />
      <input
        data-testid='openCategoryToolFilter'
        className={css.filter_input}
        type='text'
        placeholder={`${tCommon('info.filterCategories')}...`}
        onFocus={() => handleFilterOnFocusToggle(true)}
        onBlur={() => handleFilterOnFocusToggle(false)}
        onChange={(event) => handleCategoryFilter(event.currentTarget.value)}
      ></input>
    </div>
  )
}

export default OpenCategoryToolFilter
