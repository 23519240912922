import { getTenantFromSessionStorage } from '../../../../../react-services/authService'
import { OpenAnswersCountByTopic } from '../radarTypes'
import { compareDataOptions } from './DataChangeOptions'

export const handleNewChartForCompare = (
  isAlphabetical: boolean,
  countsByTopic: OpenAnswersCountByTopic[],
  selectedTopics: string[],
) => {
  const tenantName = getTenantFromSessionStorage()
  const radarCategories = isAlphabetical
    ? selectedTopics.sort().map((topic) => topic[0].toUpperCase() + topic.slice(1))
    : selectedTopics.map((topic) => topic[0].toUpperCase() + topic.slice(1))

  const newSettings: Highcharts.Options = {
    series: [
      {
        type: 'column',
        name: tenantName ? tenantName : 'Company',
        data: handleHighchartsSeriesData(isAlphabetical, countsByTopic, selectedTopics),
        pointPlacement: 'on',
        zones: [
          {
            value: 50,
            color: '#ff595e',
          },
          {
            color: '#8ac926',
          },
        ],
      },
    ],
    xAxis: {
      categories: radarCategories,
      tickmarkPlacement: 'between',
    },
    yAxis: compareDataOptions.yAxis,
    pane: {
      size: '100%',
    },
    tooltip: compareDataOptions.tooltip,
    colors: ['#8ac926'],
  }

  return newSettings
}

const handleHighchartsSeriesData = (
  isAlphabetical: boolean,
  countsByTopic: OpenAnswersCountByTopic[],
  selectedTopics: string[],
): number[] => {
  const numberData: number[] = []
  const topics = isAlphabetical ? selectedTopics.sort() : selectedTopics

  topics &&
    topics.forEach((topic) => {
      const matchTopic = countsByTopic.find((countTopic) => countTopic.topic === topic)

      if (!matchTopic) return

      const allNegativePositive = matchTopic.positiveCount + matchTopic.negativeCount
      const positiveAmount = (matchTopic.positiveCount / allNegativePositive) * 100
      numberData.push(isNaN(positiveAmount) ? 50 : Math.trunc(positiveAmount))
    })

  return numberData
}
