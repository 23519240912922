import { Chart } from 'highcharts8'
import { allChartColors, darkerChartColors } from '../../../../../../styles/variableExport'
import { pSBC } from '../../../../../react-services/colorService'
import { getTenant } from '../../../../../react-services/authService'

let timeoutRef: NodeJS.Timeout | null = null
export const createChart = (
  isReportOrScreen: boolean,
  handleClickChartPoint: (e: Highcharts.PointClickEventObject) => void,
): Highcharts.Options => {
  const isAnimationDisabled = getTenant() === 'test_frontend' || isReportOrScreen
  const ANIMATION_DURATION = isAnimationDisabled ? 0 : 1200
  return {
    accessibility: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: isReportOrScreen ? false : true,
      },
    },
    subtitle: {
      style: {
        width: 0,
      },
    },
    title: {
      text: '',
      floating: true,
      useHTML: true,
      style: {
        width: 0,
        zIndex: -1,
      },
    },
    chart: {
      animation: { duration: ANIMATION_DURATION },
      marginTop: 50,
      marginRight: 45,
      spacingLeft: 10,
      events: {
        load: resetLabelPositions,
        redraw: resetLabelPositions,
      },
      type: 'bar',
    },
    exporting: {
      sourceWidth: 1800,
      sourceHeight: 675,
      scale: 1.5,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      buttons: {
        contextButton: {
          y: 0,
          x: -10,
        },
      },
    },
    xAxis: {
      labels: {
        style: { color: allChartColors.GREY, fontSize: !isReportOrScreen ? '12px' : '15px' },
      },
      type: 'category',
    },
    yAxis: {
      labels: {
        style: { color: allChartColors.GREY, fontSize: !isReportOrScreen ? '12px' : '15px' },
      },
      title: {
        style: {
          color: allChartColors.GREY,
          fontSize: !isReportOrScreen ? '12px' : '15px',
          fontWeight: 'bold',
        },
        rotation: 0,
        align: 'low',
        offset: 0,
        textAlign: 'left',
        y: 6,
        x: -35,
      },
      lineColor: pSBC(0.6, darkerChartColors.GREY) || darkerChartColors.GREY,
      gridLineColor: pSBC(0.6, darkerChartColors.GREY) || darkerChartColors.GREY,
    },
    series: [],
    plotOptions: {
      series: {
        dataSorting: {
          enabled: true,
          matchByName: true,
        },
        animation: {
          duration: ANIMATION_DURATION,
        },
        animationLimit: 50,
        dataLabels: {
          allowOverlap: false,
          padding: 10,
          style: {
            padding: '5px',
          },
        },
      },
      bar: {
        dataLabels: {
          enabled: true,
          style: {
            color: allChartColors.GREY,
            fontSize: !isReportOrScreen ? '12px' : '15px',
          },
        },
        color: allChartColors.BLUE,
        pointPadding: 0.04,
        maxPointWidth: 80,
        groupPadding: 0.11,
        tooltip: {
          headerFormat: '<span style="font-size:10px">{series.name}</span></br>',
          pointFormat:
            '<div style="margin-top:10px">' +
            '<div style="height:10px;width:10px;border-radius:5px;background-color:{point.color};display:inline-block"></div>' +
            ' ' +
            '<span style="font-size:12px">{point.name}</span>' +
            '</br>' +
            '<span style="margin-top:8px;font-size:12px;font-weight:bold">' +
            'count: {point.custom.total}' +
            '</span>' +
            '</br>' +
            '<span style="font-size:12px;font-weight:bold">' +
            'share: {point.custom.percent}%' +
            '</span>' +
            '</div>',
        },
        animation: {
          duration: 1200,
        },
        animationLimit: 200,
        colorAxis: true,
        colors: [...Object.values(allChartColors), ...Object.values(allChartColors)],
        point: {
          events: {
            click: handleClickChartPoint,
          },
        },
      },
    },
    colors: Object.values(allChartColors),
    legend: {
      itemStyle: {
        color: darkerChartColors.GREY,
        fontSize: '13px',
      },
      maxHeight: isReportOrScreen ? 200 : undefined,
    },
  }
}

function resetLabelPositions(this: Chart) {
  try {
    if (timeoutRef) clearTimeout(timeoutRef)
    timeoutRef = setTimeout(
      () =>
        this.setTitle(
          {
            text: 'Frequency chart',

            style: { opacity: 0, fontSize: '12px' },
          },
          {
            text: 'Frequency chart',
            style: { opacity: 0, fontSize: '12px' },
          },
          true,
        ),
      3000,
    )
  } catch (error) {
    // no need to handle, fails silently
  }
}
