import { Query } from '../../../../../types'
import { Breakpoint } from '../Pietabular/pietabularTypes'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type LineChartSettings = {
  kpiTargetFrom?: string
  kpiTargetTo?: string
  kpiTargetLabel?: string
  kpiTargetLabelPosition?: LabelPosition
  kpiTargetAreaColor?: TargetColor
  kpiTargetCustomColor?: string
  kpiTargetDataFormat?: Exclude<LineChartDataFormats, LineChartDataFormats.ROLLING_AVG>
  breakpoints?: Breakpoint[] | null
  showTotals?: boolean
  isSoftMin?: boolean
  isSoftMax?: boolean
}

export enum TargetColor {
  GREEN = 'green',
  RED = 'red',
  GREY = 'grey',
  CUSTOM = 'custom',
}

export enum LabelPosition {
  TOP_LEFT = 'top left',
  TOP_CENTER = 'top center',
  TOP_RIGHT = 'top right',
  BOTTOM_LEFT = 'bottom left',
  BOTTOM_CENTER = 'bottom center',
  BOTTOM_RIGHT = 'bottom right',
}

export enum LineChartDataFormats {
  AVG = 'avg',
  N = 'n',
  SUM = 'sum',
  ROLLING_AVG = 'rollingAvg',
}

export enum LineChartDateGroups {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export type LineChartModule = {
  type: 'line'
  filterGrouping: Query.TimeRes
  selections: { [kpiName: string | number]: boolean }
  yAxis: LineChartDataFormats
  options?: LineChartSettings & ModuleDefaultSettings
  limitValues?: {
    min?: number | string
    max?: number | string
    mid?: number | string
  }
  isInTableMode?: boolean
  linetable?: boolean
  settings: unknown
} & ModuleBase
