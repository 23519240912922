import React from 'react'
import SingleGraphConfigs from './SingleGraphConfigs'
import { useParams, useSearchParams } from 'react-router-dom'
import { getToken } from '../../../../react-services/authService'
import { isNull } from 'lodash'

const SingleGraphParamsCntr = () => {
  const { sectionIdx, dashboardIdx, moduleIdx } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || getToken()
  const dashboardIdxString = searchParams.get('dashboardIdx') || dashboardIdx
  const dashboard = dashboardIdxString ? parseInt(dashboardIdxString, 10) : null
  const moduleIdxString = searchParams.get('moduleIdx') || moduleIdx
  const module = moduleIdxString ? parseInt(moduleIdxString, 10) : null
  const scrollPage = !isNull(searchParams.get('report'))
  const sectionIdxString = searchParams.get('sectionIdx') || sectionIdx
  const section = sectionIdxString ? parseInt(sectionIdxString, 10) : null
  const pastDaysString = searchParams.get('pastDays')
  const pastDays = pastDaysString ? parseInt(pastDaysString, 10) : null
  const futureDaysString = searchParams.get('futureDays')
  const futureDays = futureDaysString ? parseInt(futureDaysString, 10) : null
  const forcedFilters = searchParams.get('forced_filters')
  const moduleFromUrlString = searchParams.get('module')
  const moduleIndicesFromUrl = searchParams.get('module_indices')
  const filterUpdateFromUrl = searchParams.get('filter_update')
  const dateString = searchParams.get('override_end_date')

  const props = {
    token,
    dashboardIdx: dashboard,
    moduleIdx: module,
    scrollPage,
    sectionIdx: section,
    pastDays,
    futureDays,
    forcedFilters,
    moduleFromUrlString,
    moduleIndicesFromUrl,
    filterUpdateFromUrl,
    dateString,
  }
  return <SingleGraphConfigs {...props} />
}

export default SingleGraphParamsCntr
