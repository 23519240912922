/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-duplicate-imports */
import { get } from './apiService'
import { GenericConfig } from '../../types'
import { cloneDeep, flatten, isEmpty, isNil } from 'lodash'
import { Response, Data, KpiData, Direction } from '../../types'
import { dedupe, sort } from '../../utils'
import { TextualData } from '../components/Dashboards/DashboardModules/Open/openTypes'
import {
  errorCalculatedKpisInvalidFormat,
  errorGettingCalculatedKpis,
  errorGettingNumericKpis,
  errorGettingOpenKpis,
  errorInvalidNumericKpis,
} from '../stores/notificationMessages'
import { isCalculatedKpis, isIdAndNameObjectArray, isNumericKpisResponseObject } from '../utilities'
import { NumericKpi } from '../stores/useNumericKpis'

function dropEmptyKpiData(data: KpiData.Generic) {
  const result: { series: KpiData.Series<Data.TwoD>[] } = { series: [] }

  data.series.forEach((series) => {
    !hasEmptyDataSet(series) && result.series.push(series)
  })

  return result
}

function sortOpenModuleTextualData(data: KpiData.TextualData) {
  const copyOfData = cloneDeep(data)

  const datesOfMostRecentAnswers: { kpiId: number; answerDate: string }[] = []
  const kpisWithEmptyData: KpiData.Series<KpiData.TextualKpiDataPoint>[] = []
  copyOfData.series.forEach((series) => {
    // we are relying on backend having the answer arrays in order from oldest to newest
    series.data.reverse()
    !hasEmptyDataSet(series)
      ? datesOfMostRecentAnswers.push({ kpiId: series.data[0].id, answerDate: series.data[0].date })
      : kpisWithEmptyData.push(series)
  })

  const orderOfKpis =
    datesOfMostRecentAnswers.length > 0
      ? datesOfMostRecentAnswers.sortByKey('answerDate', true).map((a) => a.kpiId)
      : []
  const kpisWithDataInOrder = new Array(datesOfMostRecentAnswers.length)

  orderOfKpis.forEach((kpiId: number, index: number) => {
    kpisWithDataInOrder[index] = copyOfData.series.find(
      (series) => series.data && series.data[0] && series.data[0].id === kpiId,
    )
  })

  return { series: [...kpisWithDataInOrder, ...kpisWithEmptyData] }
}

function countAnswers(kpiData: TextualData) {
  let result = 0
  kpiData.series.forEach((series) => {
    if (!hasEmptyDataSet(series)) {
      result += series.data.length
    }
  })

  return result
}

function isEmptyKpiData(kpiData: TextualData) {
  if (hasMissingKpiData(kpiData)) throw new Error("Supplied data doesn't have the correct format.")

  return kpiData.series.every(hasEmptyDataSet)
}

function hasMissingKpiData(data: TextualData) {
  return !data || !Array.isArray(data.series)
}

function hasEmptyDataSet(kpi: KpiData.Series<any>) {
  return kpi && kpi.data && isEmpty(kpi.data)
}

/** Concat all series from multiple queried results into one KPI
 */
function concatTextualKpiSeries(data: Response.FrequenciesTextual[]): Response.FrequenciesTextual {
  if (isEmpty(data) || isNil(data)) {
    throw new Error("Won't handle empty KPI data sets")
  }

  if (data.length === 1) {
    return data[0]
  }

  const modifiedData = attachCategoriesToDataPoints(data)
  const combinedCategories = dedupe(
    flatten(modifiedData.map((dataSeries) => dataSeries.categories)),
  )
  const sortedCategories = sort(combinedCategories, false, 'discard') as string[]

  const result: KpiData.Generic = {
    categories: sortedCategories,
    series: shiftXOfCategorizedDataPoints(sortedCategories, modifiedData),
  }

  return result

  function attachCategoriesToDataPoints(data: KpiData.Generic[]): KpiData.Generic[] {
    const result = cloneDeep(data)
    result.forEach((dataSeries) => {
      if (dataSeries.series) {
        dataSeries.series.forEach((series) => {
          series.data.forEach((dataPoint) => {
            if (
              isNil(dataPoint.x) ||
              !dataSeries.categories ||
              isNil(dataSeries.categories[dataPoint.x])
            ) {
              throw new Error("Can't concatenate given KPI data")
            }
            dataPoint.category = dataSeries.categories[dataPoint.x]
          })
        })
      }
    })
    return result
  }

  function shiftXOfCategorizedDataPoints(categories: string[], data: KpiData.Generic[]) {
    const resultingSeries: KpiData.Series<Data.TwoD>[] = []

    data.forEach((dataSeries) => {
      dataSeries.series.forEach((series) => {
        const oneResultingSeries = { ...series }
        oneResultingSeries.data.forEach((dataPoint) => {
          if (isNil(dataPoint.category)) {
            throw new Error("Series data points must have a 'category' key")
          }

          if (isNil(dataPoint.x)) {
            throw new Error("Series data points must have an 'x' key")
          }

          if (categories.indexOf(dataPoint.category) === -1) {
            throw new Error('Data contains extra categories')
          }

          dataPoint.x = categories.indexOf(dataPoint.category)
        })
        oneResultingSeries.data.sortByKey('x', false)
        resultingSeries.push(oneResultingSeries)
      })
    })
    return resultingSeries
  }
}

/**
 * Assumes you have already formatted the data in whichever way you see fit.
 */
function concatNumericKpiSeries(data: KpiData.Generic[]) {
  if (isEmpty(data) || isNil(data)) {
    throw new Error("Won't handle empty KPI data sets")
  }

  let resultingSeries: KpiData.Generic['series'] = []
  if (data.length > 1) {
    data.forEach((kpiData) =>
      kpiData.series.forEach((kpiSeries) => resultingSeries.push(kpiSeries)),
    )
  } else {
    resultingSeries = data[0].series
  }

  const result: KpiData.Generic = {
    categories: [],
    series: resultingSeries,
  }

  return result
}

/**
 * Note: this implementation prioritizes the sorting by the given order of the series.
 * I.e. the first series is sorted without regard to the second, then the second without regard to the third and so on.
 * Therefore, you need to pass the series here in the correct order.
 *
 * @param data data with categories and series
 * @param direction either "inc" or "dec"
 * @param categoriesType if "numeric", then the existing categories are ignored and the X axis values are set as categories. If "textual" then existing categories have to be matched with the X axis values first
 * @returns
 */
function sortSeriesByY(
  data: KpiData.Generic,
  direction: Direction,
  categoriesType?: 'numeric' | 'textual',
) {
  if (!isValidDirection(direction)) {
    console.error('ERROR: "direction" must be set to "inc" or "dec". Data is returned as-is.')
    return data
  }

  const result = cloneDeep(data)
  const sortedCategories: string[] = []
  if (!result || !result.series || !result.categories) {
    return result
  }

  result.series.forEach((series) => {
    series.data.sortByKey('y', isSortReversed(direction))
  })

  result.series.forEach((series) => {
    series.data.forEach((dataPoint) => {
      if (!categoriesType || categoriesType === 'numeric') {
        sortedCategories.push(`${dataPoint.x}`)
      } else if (categoriesType === 'textual') {
        const cat = result.categories[dataPoint.x]
        sortedCategories.push(cat)
        dataPoint.category = cat
      }
    })
  })

  result.categories = dedupe<string>(sortedCategories)

  result.series.forEach((series) => {
    series.data.forEach((dataPoint) => {
      const oldX = dataPoint?.x ?? -1
      if (oldX >= 0) {
        if (!categoriesType || categoriesType === 'numeric') {
          dataPoint.x = result.categories.indexOf(`${oldX}`)
        } else if (categoriesType === 'textual') {
          dataPoint.x = result.categories.indexOf(`${dataPoint.category}`)
        }
      }
    })
    series.data.sortByKey('x', false)
  })

  return result
}

function isValidDirection(dir: Direction) {
  return dir === 'inc' || dir === 'dec'
}

function isSortReversed(dir: Direction) {
  return dir === 'dec'
}

const getCalculatedKpis = (): Promise<GenericConfig.CalculatedKpis | null> => {
  try {
    return get('GET_GENERIC_CONFIG', { name: 'calculated_kpis' })
      .then((res: unknown) => {
        if (!res) return Promise.resolve({} as GenericConfig.CalculatedKpis)
        if (isCalculatedKpis(res)) {
          return Promise.resolve(res.kpis)
        } else {
          throw Error(errorCalculatedKpisInvalidFormat)
        }
      })
      .catch(() => Promise.reject(errorGettingCalculatedKpis))
  } catch (e) {
    return Promise.reject(errorGettingCalculatedKpis)
  }
}

const getNumericKpis = (): Promise<NumericKpi[] | null> => {
  try {
    return get('GET_NUMERIC_KPIS')
      .then((res: unknown) => {
        if (isNumericKpisResponseObject(res)) {
          return Promise.resolve(res?.kpis || null)
        } else {
          throw Error(errorInvalidNumericKpis)
        }
      })
      .catch(() => Promise.reject(errorGettingNumericKpis))
  } catch (e) {
    return Promise.reject(errorGettingNumericKpis)
  }
}

const getOpenKpis = (): Promise<NumericKpi[] | null> => {
  try {
    return get('GET_OPEN_KPIS')
      .then((kpis) => {
        if (!kpis) throw new Error('')
        const openKpis: unknown = kpis.kpis
        if (isIdAndNameObjectArray(openKpis)) {
          return Promise.resolve(openKpis || null)
        } else {
          throw Error(errorGettingOpenKpis)
        }
      })
      .catch(() => Promise.reject(errorGettingOpenKpis))
  } catch (e) {
    return Promise.reject(errorGettingOpenKpis)
  }
}

export {
  sortOpenModuleTextualData,
  isEmptyKpiData,
  dropEmptyKpiData,
  countAnswers,
  concatTextualKpiSeries,
  concatNumericKpiSeries,
  sortSeriesByY,
  getCalculatedKpis,
  getNumericKpis,
  getOpenKpis,
}
