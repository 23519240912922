import { createStateContext } from 'react-use'
import { TopBottomSettingsContext } from '../topBottomTypes'

export const [useTopBottomSettings, TopBottomSettingsProvider] =
  createStateContext<TopBottomSettingsContext>({
    selectedColumns: [],
    selectedKpis: [],
    useTopBottomCustomerPath: false,
    topBottomDataDisplay: null,
    topBottomMax: null,
    showDataOfInterest: false,
  })
