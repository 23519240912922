/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import { get, post, remove, hasRole } from '../../../react-services/apiService'
import ContactList from './ContactList'
import AddContact from './AddContact'
import DeleteContact from './DeleteContact'
import trackingService from '../../../react-services/trackingService'
import { TrackingEvent } from '../../../react-constants'
import { Contacts, WhereMetaSingle, WhereMeta, GenericConfig } from '../../../../types'
import LoadingIndicator from '../../_shared/Infos/LoadingIndicator'

type Props = {
  cdbs: GenericConfig.CommonDbSettings | null
}
type State = {
  contacts: Contacts.Contact[] | null
  contact: Contacts.Contact | null
  contactMetadata: WhereMeta | null
  showAddContact: boolean
  showDeleteContact: boolean
  errorWhileAddingContact: boolean
  ready: boolean
  loadingContacts: boolean
  errorWhileLoadingContacts: boolean
}

class ManageContacts extends React.Component<Props, State> {
  private addNewContactIsAllowed: boolean

  constructor(props: Props) {
    super(props)
    this.state = {
      contacts: null,
      contact: null,
      contactMetadata: null,
      showAddContact: false,
      showDeleteContact: false,
      errorWhileAddingContact: false,
      ready: false,
      loadingContacts: false,
      errorWhileLoadingContacts: false,
    }

    this.addNewContactIsAllowed = (() => {
      if (hasRole('admin')) return true

      if (this.props.cdbs && this.props.cdbs.allow_add_contact) return true
      else return false
    })()
  }

  componentDidMount() {
    this.setState({
      ready: false,
    })
  }

  loadContacts() {
    this.setState({
      loadingContacts: true,
      errorWhileLoadingContacts: false,
    })
    get('GET_CONTACTLIST')
      .then((res) => {
        this.setState({
          contacts: res,
          contactMetadata: this.getContactMetadata(res),
          ready: true,
        })
      })
      .catch((error) => {
        console.error(error)
        this.setState({
          errorWhileLoadingContacts: true,
        })
      })
      .finally(() => {
        this.setState({
          loadingContacts: false,
        })
      })
  }

  getContactMetadata(contacts: Contacts.Contact[]): WhereMeta {
    const map: WhereMeta = {}
    contacts.forEach((contact: Contacts.Contact) => {
      if (contact.meta) {
        Object.entries(contact.meta).forEach(([key, value]) => {
          if (!map[key]) {
            map[key] = []
          }
          !map[key].includes(value) && map[key].push(value)
        })
      }
    })

    return map
  }

  toggleAddContact = () => {
    if (this.state.showAddContact) {
      trackingService.track(TrackingEvent.CloseAddContactModal, { context: 'Admin Tools' })
    } else {
      trackingService.track(TrackingEvent.OpenAddContactModal, { context: 'Admin Tools' })
    }

    this.setState({
      showAddContact: !this.state.showAddContact,
    })
  }

  showDeleteContact = (contact: Contacts.Contact) => {
    trackingService.track(TrackingEvent.OpenDeleteContactModal)
    this.setState({
      showDeleteContact: true,
      contact: contact,
    })
  }

  closeDeleteContact = () => {
    trackingService.track(TrackingEvent.CloseDeleteContactModal)
    this.setState({
      showDeleteContact: false,
      contact: null,
    })
  }

  handleAddNewContact = (name: string, email: string, phone: string, metadata: WhereMetaSingle) => {
    if (!this.addNewContactIsAllowed) return
    if (name.trim() === '') return

    const newContact = {
      name: name,
      email: email,
      phone: phone,
      meta: getMetadata(),
    }

    post('POST_NEWCONTACT', newContact)
      .then((res: any) => {
        this.setState({
          contacts: this.state.contacts?.concat(res) ?? [],
          showAddContact: false,
          errorWhileAddingContact: false,
        })
      })
      .catch((e: Error) => {
        console.error(e)
        this.setState({
          errorWhileAddingContact: true,
        })
      })

    function getMetadata() {
      const result: WhereMetaSingle[] = [
        { 'customer name': name },
        { 'customer email': email },
        { 'customer phone': phone },
      ]
      if (metadata && Object.keys(metadata).length > 0) {
        for (const m in metadata) {
          result.push({ [m]: metadata[m] as string })
        }
      }

      return result
    }
  }

  handleDelete = (id: number) => {
    trackingService.track(TrackingEvent.DeleteContactInContactManagement)
    remove('DELETE_CONTACT', undefined, { 'contact-id': id })
      .then(() => {
        this.setState({
          contacts:
            this.state.contacts?.filter((contact: Contacts.Contact) => contact.id !== id) ?? [],
          contact: null,
          showDeleteContact: false,
        })
      })
      .catch((error: unknown) => {
        console.error(error)
      })
  }

  render() {
    return (
      <>
        {!this.state.ready && !this.state.loadingContacts && (
          <div className='center'>
            <button
              data-testid='loadContactsButton'
              className='waves-effect waves-light btn orange lighten-1'
              onClick={() => this.loadContacts()}
            >
              Load contacts
            </button>
          </div>
        )}
        {this.state.loadingContacts && <LoadingIndicator />}
        {this.state.errorWhileLoadingContacts && (
          <div className='card-panel red white-text'>
            Error loading contacts
            <br />
            If this problem persists, please refresh the page or contact helpdesk@wheelq.com.
          </div>
        )}
        {this.state.ready && (
          <div>
            {this.addNewContactIsAllowed && (
              <div className='center'>
                <button
                  data-cy='addNewContact'
                  className='waves-effect waves-light btn orange lighten-1'
                  onClick={this.toggleAddContact}
                >
                  Add new contact
                </button>
              </div>
            )}

            {this.state.showAddContact && (
              <AddContact
                error={this.state.errorWhileAddingContact}
                toggleAddContact={this.toggleAddContact}
                handleAddNewContact={this.handleAddNewContact}
                contactMetadata={this.state.contactMetadata}
                onDismissError={() => {
                  this.setState({ errorWhileAddingContact: false })
                }}
              />
            )}

            {this.state.contacts && (
              <ContactList
                contacts={this.state.contacts}
                showDeleteContact={this.showDeleteContact}
              />
            )}

            {this.state.showDeleteContact && this.state.contact !== null && (
              <DeleteContact
                contact={this.state.contact}
                closeDeleteContact={this.closeDeleteContact}
                handleDelete={this.handleDelete}
              />
            )}
          </div>
        )}
      </>
    )
  }
}

export default ManageContacts
