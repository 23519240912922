import { Point } from 'highcharts8'
import React, { useMemo } from 'react'
import { FrequencyComparisonType, FrequencyQueryType } from './frequencyModuleTypes'
import OpenInspectorCntr from '../_shared/ValueInspector/Open/OpenInspectorCntr'
import { WhereMeta } from '../../../../../types'
import { idAndNameKpi } from '../../../../stores/useOpenKpis'
import { OpenAnswer } from '../Pietabular/pietabularTypes'
import ValueInspector from '../_shared/ValueInspector/ValueInspector'

type FrequencyModuleInspectorCntrProps = {
  clickedPoint: Point | null
  setClickedPoint: React.Dispatch<React.SetStateAction<Point | null>>
  queryType: FrequencyQueryType | undefined
  where_meta: WhereMeta
  selectedOpenKpis: idAndNameKpi[] | undefined
  grouping: string | undefined
  comparisonType: FrequencyComparisonType | null | undefined
}
const FrequencyModuleInspectorCntr = ({
  clickedPoint,
  queryType,
  setClickedPoint,
  where_meta,
  selectedOpenKpis,
  grouping,
  comparisonType,
}: FrequencyModuleInspectorCntrProps) => {
  const openQuery = useMemo(() => {
    if (queryType === FrequencyQueryType.OPEN && selectedOpenKpis && clickedPoint) {
      return {
        where_meta,
        start_date: clickedPoint.options?.custom?.start_date || '',
        end_date: clickedPoint.options?.custom?.end_date || '',
        kpis: selectedOpenKpis,
        answer: clickedPoint.name || '',
      }
    }
    return null
  }, [clickedPoint])
  const numericQuery = useMemo(() => {
    if (queryType === FrequencyQueryType.NUMERIC_KPI && clickedPoint) {
      const metaFilter =
        comparisonType === FrequencyComparisonType.META && grouping
          ? { [grouping]: [clickedPoint.options.custom?.metaGroup || ''] }
          : {}
      return {
        filters: { ...where_meta, ...metaFilter },
        startDate: clickedPoint.options?.custom?.start_date || '',
        endDate: clickedPoint.options?.custom?.end_date || '',
        kpis: [clickedPoint.options.custom?.kpiId],
      }
    }
    return null
  }, [clickedPoint])

  const handleFilteringOpenAnswersByAnswerText = (answers: OpenAnswer[], targetAnswer: string) => {
    return answers.filter((answer) =>
      (answer.answer || '').toLocaleLowerCase().includes((targetAnswer || '').toLocaleLowerCase()),
    )
  }
  const onCloseInspector = () => setClickedPoint(null)

  if (!openQuery && !numericQuery) return <></>
  return (
    <>
      {queryType === FrequencyQueryType.OPEN && clickedPoint && openQuery && (
        <OpenInspectorCntr
          onClose={onCloseInspector}
          query={openQuery}
          customFilterAnswersFunction={(data) =>
            handleFilteringOpenAnswersByAnswerText(data, openQuery.answer)
          }
        />
      )}
      {queryType === FrequencyQueryType.NUMERIC_KPI && clickedPoint && numericQuery && (
        <ValueInspector
          onClose={onCloseInspector}
          valueType='numeric'
          inspected=''
          kpis={numericQuery.kpis}
          startDate={numericQuery.startDate}
          endDate={numericQuery.endDate}
          filters={numericQuery.filters}
        />
      )}
    </>
  )
}

export default FrequencyModuleInspectorCntr
