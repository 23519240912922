import React, { useEffect, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import { isEqual, isUndefined } from 'lodash'
import useCalculatedKpis from '../../../../../stores/useCalculatedKpis'
import useNumericKpis from '../../../../../stores/useNumericKpis'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

const KpiSelectionTreeData = () => {
  const [{ saveModule, module }] = useSettingsTools()
  const {
    numericKpis,
    isLoading: isLoadingNumericKpis,
    // error: errorNumericKpis
  } = useNumericKpis()
  const {
    originalCalculatedKpis,
    isLoading: isLoadingCalculatedKpis,
    // error: errorCalculatedKpis,
  } = useCalculatedKpis()

  const isLoading = isLoadingNumericKpis || isLoadingCalculatedKpis
  const settingsName = 'selections' as keyof unknown

  let value: string | number = ''
  if (module && module[settingsName]) {
    const kpiNameOrId = Object.keys(module[settingsName])[0]
    // Calculated kpi is string, others are numbers
    const kpiStringOrNumber = isNaN(Number(kpiNameOrId)) ? kpiNameOrId : Number(kpiNameOrId)
    value = kpiStringOrNumber
  }

  useEffect(() => {
    if (!numericKpis || isLoading) return
    const initSelection = extractKpisForSelection(value)
    setSelectedKpis(initSelection)
  }, [isLoading, numericKpis, originalCalculatedKpis])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const extractKpisForSelection = (newValue: string | number) => {
    const kpiSelection = [] as kpiIdAndName[]
    if (
      typeof newValue === 'string' &&
      originalCalculatedKpis &&
      originalCalculatedKpis[newValue]
    ) {
      kpiSelection.push({ name: newValue })
    }
    if (
      typeof newValue === 'number' &&
      numericKpis &&
      numericKpis.find((kpi) => kpi.id === newValue)
    ) {
      kpiSelection.push({
        id: newValue,
        name: numericKpis.find((kpi) => kpi.id === value)?.name || '',
      })
    }
    return kpiSelection
  }
  const handleSaveNewSelection = (newSelectionAll: kpiIdAndName[]) => {
    const newSelection = [] as kpiIdAndName[]
    if (typeof value === 'string') {
      const filteredSelectionWithoutPrevSelectedKpi = newSelectionAll.filter(
        (kpi) => kpi.name !== value,
      )
      newSelection.push(...filteredSelectionWithoutPrevSelectedKpi)
    }
    if (typeof value === 'number') {
      const filteredSelectionWithoutPrevSelectedKpi = newSelectionAll.filter(
        (kpi) => kpi.id !== value,
      )
      newSelection.push(...filteredSelectionWithoutPrevSelectedKpi)
    }
    try {
      if (!module) return
      const newSelectedKpi = newSelection[0]
      if (isUndefined(newSelectedKpi)) return
      const newValue = isUndefined(newSelectedKpi.id)
        ? newSelectedKpi.name
        : newSelectedKpi.id.toString()
      const newTitle = isNaN(Number(newValue))
        ? newValue
        : numericKpis?.find((kpi) => kpi.id === Number(newValue))?.name || ''
      const newValueStringOrNumber = isNaN(Number(newValue)) ? newValue : Number(newValue)
      const newModule = { ...module, [settingsName]: { [newValue]: true }, title: newTitle }
      if (isEqual(newModule, module)) return
      extractKpisForSelection(newValueStringOrNumber)
      setSelectedKpis([newSelectedKpi])
      saveModule(newModule)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.numeric}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
