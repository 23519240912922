import React, { useMemo } from 'react'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoSelectChartFormat } from '../FrequencyNotifications'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { FreqFormat, FrequencyModule } from '../frequencyModuleTypes'
import { cloneDeep, isUndefined } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'

const ChartDataFormatDropDown = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const freqModule = module as FrequencyModule
  const freqOptions = module?.options
  const freqFormat = freqModule.options?.freq_format
  const realChartFormat = useMemo(() => {
    if (
      freqFormat === FreqFormat.PERCENTAGE_ALL ||
      freqFormat === FreqFormat.PERCENTAGE_RESPONDENT
    ) {
      return FreqFormat.PERCENTAGE_ALL
    }
    if (freqFormat === FreqFormat.N) {
      return FreqFormat.N
    }
    return ''
  }, [freqFormat])

  // any for typeguard
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFreqChartFormat = (value: any): value is FreqFormat => {
    if (!value) return false
    return Object.values(FreqFormat).includes(value)
  }

  const handleOnChangeChartFormat = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!freqModule) return
    const moduleCopy = cloneDeep(freqModule)
    if (!isUndefined(moduleCopy.horizontalBars)) delete moduleCopy.horizontalBars
    const newChartFormat = e.currentTarget.value
    if (!isFreqChartFormat(newChartFormat)) return
    saveModule({
      ...moduleCopy,
      options: { ...(freqOptions || {}), freq_format: newChartFormat, limitTarget: newChartFormat },
    })
  }

  return (
    <>
      <div className={`${modal_css.half}`}>
        <TextWithInfo
          text='Chart format for y-axis'
          info={infoSelectChartFormat}
          tooltipDirection='right'
        />
        <RequiredStar />
        <select
          className={`${css.input} browser-default ${realChartFormat ? '' : modal_css.required}`}
          onChange={handleOnChangeChartFormat}
          placeholder={'select type'}
          data-testid={'freq_format'}
          id={'freq_format'}
          value={realChartFormat}
        >
          <option key={'emptyOption'} value={''}></option>
          {[FreqFormat.N, FreqFormat.PERCENTAGE_ALL].map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export default ChartDataFormatDropDown
