import React from 'react'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoTrendsDenominator } from '../PietabularNotifications'
import { PietabularModuleSettings } from '../PietabularModuleContext'

const NUMERATOR = 'numerator'
const DENOMINATOR = 'denominator'

const NumeratorAndDenominatorSelect = () => {
  const { answerCategories, error, loading } = useOpenCategories()
  const [{ module }] = useSettingsTools()
  const hasError = !!error
  const options = module?.options ? (module.options as PietabularModuleSettings) : null
  const selectedCountCategory = options?.defaultCountedKey
  let selectedCategoryValues = [] as string[]
  if (selectedCountCategory && answerCategories && answerCategories[selectedCountCategory]) {
    selectedCategoryValues = answerCategories[selectedCountCategory].concat('n')
  }
  return (
    <>
      {loading && <LoadingIndicator />}
      {hasError && <h5>{error}</h5>}
      {!loading && !hasError && (
        <>
          <DropdownInput
            options={selectedCategoryValues}
            settingsName={NUMERATOR}
            data_testid={NUMERATOR}
            title={
              <TextWithInfo
                text={`Trends ${NUMERATOR}:`}
                tooltipDirection='right'
                info={infoTrendsDenominator}
              />
            }
          />
          <DropdownInput
            options={selectedCategoryValues}
            settingsName={DENOMINATOR}
            data_testid={DENOMINATOR}
            title={
              <TextWithInfo
                text={`Trends ${DENOMINATOR}:`}
                tooltipDirection='left'
                info={infoTrendsDenominator}
              />
            }
          />
        </>
      )}
    </>
  )
}

export default NumeratorAndDenominatorSelect
