import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const CloseIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'close'}
    text=""
    customClasses={[css.btn, css.close]}
    iconPosition={'center'}
    title="Change alert state to 'CLOSED'"
    data_testid="close-alert-icon-button"
    style={{ fontSize: '16px' }}
  />
)

export default CloseIcon;