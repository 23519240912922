import React from 'react'
import TextInput from '../../_shared/ModuleSettings/components/TextInput'

const ImageUrlTextInput = () => {
  return (
    <TextInput
      settingsName='imageurl'
      title={'Image URL'}
      isModuleProperty
      type='text'
      width='half'
      data_testid='imageurlInput'
      id='imageurlInput'
    />
  )
}

export default ImageUrlTextInput
