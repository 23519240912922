import _ from 'underscore'

var logToConsole = false

// Recursively replace all Object[key] key names with 'value'
// e.g. obj.name -> obj.firstname
function replaceKeyNames(array, key, value, iteration) {
  if (logToConsole) {
    // Only log first iteration
    if (!iteration) {
      console.log('helperFunctionService: replaceKeyNames (original data, key, newkey)')
      console.log(array)
      console.log('key: ' + key + ', newkey: ' + value)
    }
  }
  if (Array.isArray(array)) {
    _.each(array, function (obj) {
      obj = replaceKeyNames(obj, key, value, true)
    })
  }
  if (!Array.isArray(array) && _.isObject(array)) {
    if (array[key] !== undefined) {
      if (value === undefined) {
        delete array[key]
      } else {
        array[value] = array[key]
        delete array[key]
      }
    }
    _.each(array, function (item) {
      if (_.isObject(item)) {
        item = replaceKeyNames(item, key, value, true)
      }
    })
  }
  if (logToConsole) {
    // Only log final outcome
    if (!iteration) {
      if (logToConsole) {
        console.log('Data with replaced key names:')
        console.log(array)
      }
    }
  }
  return array
}

// Attach new key-value pairs in 'settings' to scope (don't override existing values)
function attachNewSettingsToScope(scope, settings) {
  _.each(settings, function (value, key) {
    if (scope[key] === undefined || scope[key] === null) {
      scope[key] = value
    }
  })
}

function parseKeyValue(item, key) {
  var keys = key.split('.')
  var value = item
  _.each(keys, function (k) {
    value = value ? value[k] : value
  })
  return value
}

// Recursively list all values under specific key
function listObjectValues(array, key, values) {
  if (!values) values = []
  if (Array.isArray(array)) {
    _.each(array, function (obj) {
      obj = listObjectValues(obj, key, values)
    })
  }
  if (!Array.isArray(array) && _.isObject(array)) {
    if (array[key] !== undefined) {
      values.push(array[key])
    }
    _.each(array, function (item) {
      if (_.isObject(item)) {
        values = listObjectValues(item, key, values)
      }
    })
  }
  return values
}

function uniqueObjectValues(array, key) {
  var values = listObjectValues(array, key)
  values = _.uniq(values)
  return values
}

function deleteKeysWithUndefinedValue(obj) {
  if (obj) {
    let keys = Object.keys(obj)
    if (keys && keys.length) {
      _.each(keys, function (key) {
        if (obj[key] === undefined) {
          delete obj[key]
        }
      })
    }
  }
}

/* Generic table helper function: find text/number value inside JSX element
 <span> element takes precedence over other elements */
function determineTextValueForJsxElement(element) {
  if (!element) return ''
  if (typeof element === 'string') return element
  if (typeof element === 'number') return element.toString()
  if (Array.isArray(element)) {
    try {
      return determineTextValueForJsxElement(
        element.find((el) => el.type === 'span').props.children,
      )
    } catch (e) {
      //
    }
    // no span elements, try to find a string
    for (let i = 0; i < element.length; i++) {
      if (typeof element[i] === 'string') {
        return element[i]
      }
    }
    // no string element found, iterate through children
    for (let i = 0; i < element.length; i++) {
      try {
        return determineTextValueForJsxElement(element[i].props.children)
      } catch (e) {
        //
      }
    }
    console.error(
      `Oy mate, all of your table's TD's need to have a regular string (or a string in <span>) as a child element! Falling back to using an empty string.`,
    )
    return ''
  }
  if (typeof element === 'object') return determineTextValueForJsxElement(element.props.children)

  throw new Error(`Unknown input received: ${element} which seems to be of type ${typeof element}`)
}

/**
 * Decode URI safely so percent signs can be used in configs.
 *
 * @param uri URI to be decoded safely
 * @returns safely decoded URI
 */
function decodeURIComponentSafe(uri) {
  if (!uri) {
    return uri
  }
  return decodeURIComponent(uri.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
}

export {
  replaceKeyNames,
  parseKeyValue,
  attachNewSettingsToScope,
  uniqueObjectValues,
  deleteKeysWithUndefinedValue,
  determineTextValueForJsxElement,
  decodeURIComponentSafe,
}
