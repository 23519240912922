import { dedupe } from '../../utils';

/** Accepts a string; will expect either comma, semicolon or a newline as separator
    Filters duplicates and empty values
    Will return two lists: valid and faulty emails
*/
function emailsFromString(emails: string): string[][] {
  if (!(typeof emails === 'string')) throw new Error('Give emails as a string');
  const valids: string[] = [], 
    invalids: string[] = [];

  let maybeEmails: string[] = emails.replace(/([,;])/g, '\n').split('\n')
  maybeEmails = dedupe(maybeEmails.filter((value) => value !== ''))
  for (let i = 0; i < maybeEmails.length; i++) {
    if (looksLikeEmail(maybeEmails[i])) {
      valids.push(maybeEmails[i].trim());
    } else {
      invalids.push(maybeEmails[i].trim());
    }
  }
  
  return [valids, invalids];
}

function phoneNumbersFromString(phoneNumbers: string): string[][] {
  if (!(typeof phoneNumbers === 'string')) throw new Error('Give phone numbers as a string');
  const valids = [], invalids = [];

  let maybePhones = phoneNumbers.replace(/([,;])/g, '\n').split('\n')
  maybePhones = dedupe(maybePhones.filter((value) => value !== ''))
  for (let i = 0; i < maybePhones.length; i++) {
    if (isPhoneNumber(maybePhones[i])) {
      valids.push(maybePhones[i].trim());
    } else {
      invalids.push(maybePhones[i].trim());
    }
  }
  
  return [valids, invalids];
}

function looksLikeEmail(input: string, isNaive = false): boolean {
  if (isNaive) {
    return input.includes('@');
  } else {
    return input.indexOf('@') !== -1 && input.substring(input.indexOf('@'), input.length).includes('.')
  } 
}

function isEmail(input: string): boolean {
  // eslint-disable-next-line
  var emailRegex: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return emailRegex.test(input);
}

function looksLikePhoneNumber(input: string): boolean {
  return input.charAt(0) === '+' || isPhoneNumber(input)
}

function isPhoneNumber(input: string): boolean {
  // eslint-disable-next-line
  var phoneRegex: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
  return phoneRegex.test(input);
}

function isInternationalPhoneNumber(input: string): boolean {
  if (!input || input.length === 0) return false;
  const doesStartWithPlus: boolean = input.charAt(0) === '+';
  const doesStartWithDoubleZero: boolean = input.charAt(0) === '0' && input.charAt(1) === '0';
  
  return (doesStartWithPlus || doesStartWithDoubleZero) && isPhoneNumber(input);
}

export {
  emailsFromString,
  phoneNumbersFromString,
  looksLikeEmail,
  isEmail,
  looksLikePhoneNumber,
  isPhoneNumber,
  isInternationalPhoneNumber,
}