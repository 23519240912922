import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { CustomerPathConfig } from '../../../../stores/useConfigCustomerPath'
import {
  ExtendedPointOptionsObject,
  WheelModule,
  WheelModuleSettings,
  WheelTheme,
} from './wheelModuleTypes'
import { ToastProvider, useToastId } from '../../../common/Notification/NotificationContext'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingModule } from './WheelNotifications'
import Wheel from './wheel/Wheel'
import { Query } from '../../../../../types'
import useNumericKpiCounts from '../../../../stores/useNumericKpiCounts'
import WheelModuleQueryCntr from './WheelModuleQueryWrapper'
import { isEqual, isUndefined } from 'lodash'
import useNumericKpis from '../../../../stores/useNumericKpis'
import Line from './line/Line'
import { darkerChartColors } from '../../../../../styles/variableExport'
import { toast } from 'react-toastify'
import { TargetColor } from '../Line/lineChartTypes'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import useWheelSeriesBuilders from './wheel/useWheelSeriesBuilders'
import { CaptionOptions } from 'highcharts8'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { isFetchingData, isWaitingForFetching } from '../Group/contexts/renderListReducer'
import { CircularProgress } from '@mui/material'
import { QueueStatus } from '../Group/groupModuleTypes'

import css from './WheelModuleCntr.module.scss'

export type WheelModuleCntrUnwrappedProps = {
  id: string
  lineLimits: WheelModule['limitValues']
  wheelQuery: Query.SingleNumeric
  lineQuery: Query.Numeric
  customerPathConfig: CustomerPathConfig
  options?: WheelModuleSettings
  saveModuleOptionsValue: (value: Record<string, unknown>) => void
  doneStatusTimeoutRef: React.MutableRefObject<NodeJS.Timeout | null>
  isReportOrScreen: boolean
  moduleStatus: QueueStatus | undefined
}

const WheelModuleCntrUnwrapped = memo(
  ({
    id,
    lineLimits,
    wheelQuery,
    lineQuery,
    customerPathConfig,
    options,
    saveModuleOptionsValue,
    doneStatusTimeoutRef,
    isReportOrScreen,
    moduleStatus,
  }: WheelModuleCntrUnwrappedProps) => {
    const { updateModuleToIdle } = useRenderActions()
    const [selectedCategory, setSelectedCategory] = useState<string>('')

    const colors = useMemo(() => {
      return Object.values(darkerChartColors).filter(
        (color) =>
          !(
            options?.kpiTargetAreaColor === TargetColor.GREEN &&
            (color === darkerChartColors.BLUE_GREEN || color === darkerChartColors.LIGHT_BLUE_GREEN)
          ) &&
          !(
            options?.kpiTargetAreaColor === TargetColor.RED &&
            (color === darkerChartColors.RED || color === darkerChartColors.LIGHT_RED)
          ) &&
          !(options?.kpiTargetAreaColor === TargetColor.GREY && color === darkerChartColors.GREY),
      )
    }, [options?.kpiTargetAreaColor])

    const { toastifyId } = useToastId()
    const toastCntr = { containerId: toastifyId }

    const {
      numericCounts: wheelCounts,
      isLoading: isLoadingWheelCounts,
      isRefetching: isRefetchingWheelCounts,
      error: errorWheelCounts,
    } = useNumericKpiCounts(wheelQuery)
    useEffect(() => toastError(errorWheelCounts), [errorWheelCounts])

    const {
      numericCounts: lineCounts,
      isLoading: isLoadingLineCount,
      isRefetching: isRefetchingLineCounts,
      error: errorLineCounts,
    } = useNumericKpiCounts(lineQuery)
    useEffect(() => toastError(errorLineCounts), [errorLineCounts])

    const { numericKpis } = useNumericKpis()
    const categories = customerPathConfig.conf
    const { min: defaultMin, max: defaultMax } = customerPathConfig.limitValues || {}
    const [ready, setReady] = useState<string>('')
    const { config } = useCommonDbSettingsConfig()
    const decimals = config && !isUndefined(config?.decimals) ? config.decimals : 2

    const {
      createInnerCategorySeriesWithSums,
      createInnerCategoryPointOptions,
      createOuterCategoryPointOptions,
      createChartCaption,
    } = useWheelSeriesBuilders(
      categories,
      numericKpis,
      wheelCounts,
      options?.kpiTargetAreaColor,
      decimals,
      defaultMin,
      defaultMax,
      options?.wheelTheme,
    )

    const innerCategorySeries: ExtendedPointOptionsObject[] | null = useMemo(() => {
      if (categories && wheelCounts && numericKpis) {
        const circleInnerSeries = createInnerCategorySeriesWithSums()
        const circleInnerPointOptions = createInnerCategoryPointOptions(circleInnerSeries)
        return circleInnerPointOptions
      }
      return null
    }, [
      wheelCounts,
      categories,
      numericKpis,
      defaultMax,
      defaultMin,
      options?.wheelTheme,
      colors,
      decimals,
    ])

    const outerCategorySeries: ExtendedPointOptionsObject[] | null = useMemo(() => {
      if (innerCategorySeries && wheelCounts && numericKpis && categories) {
        const circleOuterSeries = createOuterCategoryPointOptions(innerCategorySeries)
        return circleOuterSeries
      }
      return null
    }, [
      wheelCounts,
      categories,
      numericKpis,
      defaultMax,
      defaultMin,
      options?.wheelTheme,
      innerCategorySeries,
      colors,
      decimals,
    ])

    const READY_TIMEOUT = 4000
    const readyTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
      if (!isLoadingLineCount && !isLoadingWheelCounts) {
        if (readyTimeoutRef.current) clearTimeout(readyTimeoutRef.current)
        readyTimeoutRef.current = setTimeout(() => {
          setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
        }, READY_TIMEOUT)
      }
      return () => readyTimeoutRef.current && clearTimeout(readyTimeoutRef.current)
    }, [isLoadingLineCount, isLoadingWheelCounts, moduleStatus])

    const DONE_STATUS_TIMEOUT = 200
    useEffect(() => {
      if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
      const isDoneFetching =
        !isLoadingLineCount &&
        !isLoadingWheelCounts &&
        !isRefetchingLineCounts &&
        !isRefetchingWheelCounts
      if (isFetchingData(moduleStatus) && isDoneFetching) {
        doneStatusTimeoutRef.current = setTimeout(() => {
          updateModuleToIdle(id)
        }, DONE_STATUS_TIMEOUT)
      }
      return () => {
        doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
      }
    }, [
      moduleStatus,
      isLoadingLineCount,
      isLoadingWheelCounts,
      isRefetchingLineCounts,
      isRefetchingWheelCounts,
    ])
    const toastError = (e: string) => {
      if (e) toast.error(e, toastCntr)
    }

    const isWheelVisible = options?.showWheel !== false
    const isLineVisible = !!options?.showLine

    const center = useMemo(() => {
      if (!innerCategorySeries) return null
      if (innerCategorySeries && innerCategorySeries.length) {
        return [
          {
            id: 'All',
            name: 'All',
            parent: '',
            realValue: null,
            n: null,
            label: 'All',
            dataLabels: {
              enabled: false,
            },
            color: '#585858',
          },
        ]
      }
      if (innerCategorySeries && !innerCategorySeries.length) {
        return []
      }
      return null
    }, [innerCategorySeries])

    const caption: CaptionOptions | null = useMemo(() => {
      if (
        !options?.showCaption ||
        !innerCategorySeries ||
        !innerCategorySeries.length ||
        (isUndefined(defaultMin) && isUndefined(defaultMax))
      )
        return null
      const caption = createChartCaption(
        options.wheelTheme || WheelTheme.STANDARD,
        innerCategorySeries,
        defaultMin,
        defaultMax,
      )
      return caption
    }, [innerCategorySeries, options?.showCaption, options?.wheelTheme])

    const isRefetchingLineData = useMemo(() => {
      return !!lineCounts && (isRefetchingLineCounts || isWaitingForFetching(moduleStatus))
    }, [isRefetchingLineCounts, moduleStatus, lineCounts])

    const isRefetchingWheelData = useMemo(() => {
      return !!wheelCounts && (isRefetchingWheelCounts || isWaitingForFetching(moduleStatus))
    }, [isRefetchingWheelCounts, moduleStatus, wheelCounts])

    const handleInnerSliceClick = (category: string) => {
      setSelectedCategory((prev) => {
        if (prev === category) {
          return ''
        } else return category
      })
    }

    if (
      (!wheelCounts && isWaitingForFetching(moduleStatus)) ||
      (isWheelVisible && isLoadingWheelCounts) ||
      (isLineVisible && isLoadingLineCount)
    ) {
      return (
        <div className={`${css.cntr} ${css.loading}`}>
          <CircularProgress thickness={1} size={'5rem'} style={{ opacity: 0.7 }} />
        </div>
      )
    }

    return (
      <>
        {innerCategorySeries && outerCategorySeries ? (
          <div
            className={`${ready} ${css.cntr} wheel-module-cntr`}
            style={{
              flexDirection: isReportOrScreen ? 'column' : 'row',
              maxHeight: isReportOrScreen ? '' : '600px',
              height: isReportOrScreen ? '100%' : '100%',
              flex: '1 1',
            }}
            data-no-data={!!innerCategorySeries && !innerCategorySeries.length}
          >
            {isWheelVisible && !!categories && (
              <Wheel
                isRefetching={isRefetchingWheelData}
                isReportOrScreen={isReportOrScreen}
                categories={categories}
                wheelQuery={wheelQuery}
                center={center || []}
                innerCircle={innerCategorySeries}
                outerCircle={outerCategorySeries}
                caption={caption}
                extensionWidth={
                  !isLineVisible || isReportOrScreen
                    ? 100
                    : options?.wheelWidthPercentage
                    ? options.wheelWidthPercentage
                    : 50
                }
                handleInnerSliceClick={handleInnerSliceClick}
              />
            )}
            {!!options?.showLine && (
              <Line
                id={id}
                isReportOrScreen={isReportOrScreen}
                limitValues={lineLimits}
                lineQuery={lineQuery}
                isRefetching={isRefetchingLineData}
                lineCounts={lineCounts}
                categories={categories}
                selectedCategory={selectedCategory}
                saveModuleOptionsValue={saveModuleOptionsValue}
                options={options}
                extensionWidth={
                  !isWheelVisible || isReportOrScreen
                    ? 100
                    : options.wheelWidthPercentage
                    ? 100 - options.wheelWidthPercentage
                    : 50
                }
              />
            )}
          </div>
        ) : (
          <div></div>
        )}
      </>
    )
  },
  isPropsEqual,
)

function isPropsEqual(
  prevProps: WheelModuleCntrUnwrappedProps,
  newProps: WheelModuleCntrUnwrappedProps,
) {
  return isEqual(prevProps, newProps)
}
WheelModuleCntrUnwrapped.displayName = 'WheelModuleCntrUnwrapped'

type WheelModuleCntrProps = {
  module: WheelModule
  saveModule: (module: WheelModule) => void
  id: string
  isReportMode: boolean
  isScreenMode: boolean
  sharedFilter?: SharedFilter
  moduleStatus: QueueStatus | undefined
}
const WheelModuleCntr = memo((props: WheelModuleCntrProps) => {
  return (
    <ToastProvider id={props.id}>
      <NotificationContainer id={props.id} />
      <ErrorBoundary
        message={errorLoadingModule}
        containerId={props.id}
        fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
      >
        <WheelModuleQueryCntr
          {...props}
          isReportOrScreen={!!props.isReportMode || !!props.isScreenMode}
          Wrapped={WheelModuleCntrUnwrapped}
        />
      </ErrorBoundary>
    </ToastProvider>
  )
})

WheelModuleCntr.displayName = 'WheelModuleCntr'
export default WheelModuleCntr
