import React, { useState } from 'react'
import css from './ConversationModal.module.scss'
import { useConversationFunctions, useConversationTools } from './ConversationModalProvider'
import { format } from 'date-fns'
import { useToastId } from '../../../../common/Notification/NotificationContext'
import { createNewConversation } from '../../../../../react-services/conversationService'
import { toast } from 'react-toastify'
import { NewConversation } from './conversationTypes'

const ConversationList = () => {
  const { toastifyId } = useToastId()
  const [
    { conversations, completedSurveyId, currentConversationId, initConversations },
    setConversationTools,
  ] = useConversationTools()
  const [{ getMessagesById, closeConversation, updateConversationsList }] =
    useConversationFunctions()
  const [createConversationConfirmation, setCreateConversationConfirmation] =
    useState<boolean>(false)
  const [newConversationSubject, setNewConversationSubject] = useState<string>('')

  const handleConversationSelect = (conversationId: string) => {
    getMessagesById(conversationId)
    setConversationTools((prev) => ({
      ...prev,
      currentConversationId: conversationId,
      conversationNav: { isConversationListOpen: false, isSingleConversationOpen: true },
    }))
  }

  const handleNewConversationSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (createConversationConfirmation) setCreateConversationConfirmation(false)
    const newSubject = event.currentTarget.value
    setNewConversationSubject(newSubject)
  }

  const handleCreateConversation = () => {
    if (completedSurveyId) {
      const newConversation: NewConversation = {
        completedSurveyId: completedSurveyId,
        subject: newConversationSubject,
      }
      createNewConversation(newConversation)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          setCreateConversationConfirmation(false)
          updateConversationsList([...initConversations, res])
          setNewConversationSubject('')
          toast.success('New conversation created succesfully!', { containerId: toastifyId })
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          setCreateConversationConfirmation(false)
          toast.error(err, { containerId: toastifyId })
        })
    } else {
      closeConversation()
      toast.error('No completed survey id provided. Closing...', { containerId: toastifyId })
    }
  }

  return (
    <div className={css.conversation_modal_list}>
      {conversations &&
        conversations.length > 0 &&
        conversations.map((conversation) => (
          <div
            key={conversation.id}
            className={css.conversation_modal_list_item}
            data-testid={`conversationModalListItem-${conversation.id}`}
            onClick={() => handleConversationSelect(conversation.id)}
          >
            {conversation.id === currentConversationId && <span>Selected</span>}
            <p>
              <b>Conversation Id:</b> {conversation.id}
            </p>
            <p>
              <b>Subject:</b> {conversation.subject ? conversation.subject : <i>N/A</i>}
            </p>
            <p>
              <b>Created At:</b> {format(new Date(conversation.created), 'Pp')}
            </p>
            <p style={{ display: 'inline' }}>
              <b>Last Message Received:</b>{' '}
              {conversation.lastMessageReceived ? (
                format(new Date(conversation.lastMessageReceived), 'Pp')
              ) : (
                <i>No messages received.</i>
              )}
            </p>
            {conversation.unreadMessagesCount > 0 && (
              <div className={css.unread_messages}>
                <i className='material-icons'>email</i>
                <div className={css.unread_messages_count}>{conversation.unreadMessagesCount}</div>
              </div>
            )}
          </div>
        ))}
      <div className={css.conversation_modal_create}>
        {conversations.length === 0 && <h3>No conversations yet.</h3>}

        <div className={css.conversation_modal_create_confirm}>
          <h5>New conversation</h5>
          <span>Subject:</span>
          <input
            data-testid='conversationNewSubject'
            type='text'
            value={newConversationSubject}
            onChange={(event) => handleNewConversationSubject(event)}
          />
          <br />
          {!createConversationConfirmation && (
            <button className='btn blue' onClick={() => setCreateConversationConfirmation(true)}>
              Create Conversation <i className='material-icons'>add_comment</i>
            </button>
          )}
          {createConversationConfirmation && (
            <>
              <p>
                {newConversationSubject === ''
                  ? 'Empty subject. Create anyway?'
                  : `Create conversation for ${newConversationSubject}?`}
              </p>
              <button className='btn grey' onClick={() => setCreateConversationConfirmation(false)}>
                Cancel <i className='material-icons'>cancel</i>
              </button>
              <button className='btn green' onClick={handleCreateConversation}>
                Create <i className='material-icons'>check</i>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConversationList
