/* eslint-disable @typescript-eslint/no-explicit-any */
// MISSING TYPES FROMN LIBRARY
import { PlotSeriesDataLabelsOptions } from 'highcharts8'
import { ExtendedPointOptionsObject, SelectedPoint } from '../wheelModuleTypes'
import { tCategory, tKpi } from '../../../../../../languages/i18n'

import css from './Wheel.module.scss'
interface PlotSunburstLevelsOptionsExtended extends Highcharts.PlotSunburstLevelsOptions {
  [x: string]: any
  levelIsConstant?: boolean
}

export const createChart = (
  setSelectedPoint: React.Dispatch<React.SetStateAction<SelectedPoint | null>>,
  handleInnerSliceClick: (category: string) => void,
): Highcharts.Options => {
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      type: 'sunburst',
      animation: {
        duration: 900,
        defer: 100,
      },
      style: {
        padding: '0',
      },
      margin: 0,
      spacing: [0, 0, 0, 0],
      events: {
        redraw: function () {
          const sunburst: any = this.series[0]
          if (sunburst && sunburst.center && sunburst.center[2]) {
            const wheelWidth = sunburst.center[2]
            const root = sunburst.rootNode
            const rootNodeName = !root || root === 'All' ? null : sunburst.rootNode
            const { numberOfOuterElements } = getElementCounts(sunburst, rootNodeName)

            const dataLabels = this.options.plotOptions?.series?.dataLabels as
              | PlotSeriesDataLabelsOptions
              | undefined
            const fontSize = dataLabels?.style?.fontSize

            const widthAndOutElementRatio = wheelWidth / numberOfOuterElements
            const newFontSize =
              widthAndOutElementRatio < 18
                ? '10px'
                : widthAndOutElementRatio < 21
                ? '11px'
                : widthAndOutElementRatio < 27
                ? '12px'
                : '12.5px'
            if (fontSize !== newFontSize) {
              this.update({
                plotOptions: {
                  series: {
                    dataLabels: {
                      style: {
                        fontSize: newFontSize,
                      },
                    },
                  },
                },
              })
            }
          }

          // ADD CLICK EVENTS TO CHART LABELS, UPDATE NEEDED OR WILL DISAPPEAR INTO THE VOID
          this.series[0].points.forEach((p: any) => {
            if (!p || !p.graphic) return
            if (p.dataLabel) {
              p.dataLabel.on('contextmenu', function (e: any) {
                e.preventDefault()
                setSelectedPoint({ id: p.kpiId, name: p.name, level: p.level })
              })
              p.dataLabel.on('click', function (e: any) {
                e.preventDefault()
                e.stopPropagation()
                if (p.series.rootNode && p.series.rootNode !== 'All') {
                  p.series.drillUp()
                  handleInnerSliceClick('')
                } else {
                  p.series.onClickDrillToNode({ point: p })
                  if (p.level === 1) handleInnerSliceClick(p.name)
                }
              })
            }
            p.graphic.on('contextmenu', function (e: any) {
              e.preventDefault()
              setSelectedPoint({ id: p.kpiId, name: p.name, level: p.level })
            })
            p.graphic.on('click', function (e: any) {
              e.preventDefault()
              e.stopPropagation()
              if (p.series.rootNode && p.series.rootNode !== 'All') {
                p.series.drillUp()
                handleInnerSliceClick('')
              } else {
                p.series.onClickDrillToNode({ point: p })
                if (p.level === 1) handleInnerSliceClick(p.name)
              }
            })
          })
        },

        load: function () {
          const sunburst: any = this.series[0]
          if (sunburst && sunburst.center && sunburst.center[2]) {
            const wheelWidth = sunburst.center[2]
            const root = sunburst.rootNode
            const rootNodeName = !root || root === 'All' ? null : sunburst.rootNode
            const { numberOfOuterElements } = getElementCounts(sunburst, rootNodeName)

            const dataLabels = this.options.plotOptions?.series?.dataLabels as
              | PlotSeriesDataLabelsOptions
              | undefined
            const fontSize = dataLabels?.style?.fontSize

            const widthAndOutElementRatio = wheelWidth / numberOfOuterElements
            const newFontSize =
              widthAndOutElementRatio < 18
                ? '10px'
                : widthAndOutElementRatio < 21
                ? '11px'
                : widthAndOutElementRatio < 27
                ? '12px'
                : '12.5px'
            if (fontSize !== newFontSize) {
              this.update({
                plotOptions: {
                  series: {
                    dataLabels: {
                      style: {
                        fontSize: newFontSize,
                      },
                    },
                  },
                },
              })
            }
          }

          // ADD CLICK EVENTS TO CHART LABELS
          this.series[0].points.forEach((p: any) => {
            if (!p || !p.graphic) return
            if (p.dataLabel) {
              p.dataLabel.on('contextmenu', function (e: any) {
                e.preventDefault()
                setSelectedPoint({ id: p.kpiId, name: p.name, level: p.level })
              })
              p.dataLabel.on('click', function (e: any) {
                e.preventDefault()
                e.stopPropagation()
                if (p.series.rootNode && p.series.rootNode !== 'All') {
                  p.series.drillUp()
                  handleInnerSliceClick('')
                } else {
                  p.series.onClickDrillToNode({ point: p })
                  if (p.level === 1) handleInnerSliceClick(p.name)
                }
              })
            }
            p.graphic.on('contextmenu', function (e: any) {
              e.preventDefault()
              setSelectedPoint({ id: p.kpiId, name: p.name, level: p.level })
            })
            p.graphic.on('click', function (e: any) {
              e.preventDefault()
              e.stopPropagation()
              if (p.series.rootNode && p.series.rootNode !== 'All') {
                p.series.drillUp()
                handleInnerSliceClick('')
              } else {
                p.series.onClickDrillToNode({ point: p })
                if (p.level === 1) handleInnerSliceClick(p.name)
              }
            })
          })
        },
      },
    },
    lang: {
      noData: 'No data to display',
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
      },
    },
    navigation: {
      breadcrumbs: {
        floating: true,
      },
    },
    xAxis: {
      grid: {
        enabled: false,
      },
      crosshair: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          y: 10,
        },
      },
    },
    series: [
      {
        type: 'sunburst',
        data: [] as ExtendedPointOptionsObject[],
        allowTraversingTree: true,
        cursor: 'pointer',
        name: '',
        animation: {
          duration: 900,
          defer: 100,
        },

        dataLabels: {
          rotationMode: 'perpendicular',
          style: {
            color: '#FFF',
            fontWeight: 'normal',
            textOutline: 'none',
            whiteSpace: 'normal',
          },
          formatter: function () {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const currentPoint: any = this.point
            const currentLevel = currentPoint.level
            const currentName = currentPoint.name || ''
            if (currentLevel === 1) return tCategory(currentName)
            else return tKpi(currentName)
          },
          defer: true,
          enabled: true,
          animation: {
            defer: 500,
          },
        },
        levels: [
          {
            level: 1,
            levelSize: {
              unit: 'weight',
              value: 1.5,
            },
            borderWidth: 4,
            dataLabels: {
              rotation: 0,
            },
            levelIsConstant: false,
          },
          {
            level: 2,
            levelSize: {
              unit: 'weight',
              value: 4,
            },
            levelIsConstant: true,
          },
          {
            level: 3,
            levelSize: {
              unit: 'weight',
              value: 5,
            },
            dataLabels: {
              filter: {
                property: 'innerArcLength',
                operator: '>',
                value: 0,
              },
            },
          },
        ] as Array<PlotSunburstLevelsOptionsExtended>,
      },
    ],
    tooltip: {
      formatter: function () {
        const options = this.point.options as ExtendedPointOptionsObject
        const n = options.n
        if (!n) return
        let value: number | string = +options.realValue
        if (!isNaN(value)) value = value.toFixed(2)
        const limitValues = options.limitValues
        const title =
          `<div style='margin-bottom: 1rem;' class='${css.tooltip}'><strong>` +
          (this.point.name || '') +
          '</strong></div>'
        const data =
          "<div style='margin-bottom: 1rem;'><table><tr>" +
          '<td>' +
          'avg' +
          ': <strong>' +
          value +
          '</strong></td>' +
          '<td>n: <strong>' +
          (n || '') +
          '</strong></td>' +
          '</tr></table></div>'
        const limits = limitValues
          ? "<div style='font-size: small'><table><tr>" +
            '<td>min: ' +
            limitValues.min +
            '</td>' +
            '<td>max: ' +
            limitValues.max +
            '</td>' +
            '</tr></table></div>'
          : ''
        return "<div class='wheel-tooltip'>" + title + data + limits + '</div>'
      },
      useHTML: true,
      followPointer: true,
      borderWidth: 0,
      borderColor: 'none',
      shadow: false,
      backgroundColor: 'none',
    },
    plotOptions: {
      sunburst: {
        color: '#2D87BB',
        states: {},
      },
    },
    caption: {
      useHTML: true,
      text: '',
    },
  }
}

const getElementCounts = (sunburst: any, rootNodeName: 'strinng') => {
  const numberOfInnerElements = sunburst.data.filter((p: any) => {
    if (rootNodeName) return p.level === 1 && rootNodeName === p.name
    return p.level === 1
  }).length
  const numberOfOuterElements = sunburst.data.filter((p: any) => {
    if (rootNodeName) return p.level === 2 && rootNodeName === p.parent
    return p.level === 2
  }).length
  return { numberOfInnerElements, numberOfOuterElements }
}
