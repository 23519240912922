import React, { useEffect, useRef, useState } from 'react'
import { MetaAndPosition } from '../../../../../AdminTools/ViewCustomization/inspectorTypes'
import useInfiniteScroll from '../../../../../../stores/useInfiniteScroll'
import LoadingIndicator from '../../../../../_shared/Infos/LoadingIndicator'
import { Sorting } from '../inspectorTypes'
import { SortDirection } from '../../../../../../stores/useNewOpenAnswers'

import css from './OpenInspectorMultiTable.module.scss'
import { TextualKpiData } from '../../../Open/openTypes'
import { tCommon, tData } from '../../../../../../../languages/i18n'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import useCommonDbSettingsConfig from '../../../../../../stores/useCommonDbSettingsConfig'

const idProperty = 'completed_survey_id'
const answerProperty = 'answer'
const dateProperty = 'date'
const metaPropertyPrefix = 'meta_'

type OpenInspectorMultiTableProps = {
  tableCntrRef: React.MutableRefObject<HTMLTableElement | undefined>
  answers: TextualKpiData[]
  isLoadingMore?: boolean
  handleAnswerClick: (answer: TextualKpiData) => void
  metaKeysInDataAndConfig: string[]
  fetchMore?: () => Promise<boolean>
  setSorting: React.Dispatch<React.SetStateAction<Sorting>>
  sorting: Sorting
}

const OpenInspectorMultiTable = ({
  tableCntrRef,
  answers,
  isLoadingMore,
  fetchMore = () => Promise.resolve(false),
  handleAnswerClick,
  metaKeysInDataAndConfig,
  setSorting,
  sorting,
}: OpenInspectorMultiTableProps) => {
  const tableFocusTimeoutRef = useRef<NodeJS.Timeout>()
  const { config } = useCommonDbSettingsConfig()
  const configMetas = config?.inspector_open_multi?.meta_columns
  const [needMoreToLoad, setNeedMoreToLoad] = useState<boolean>(false)
  const [configMetasFoundInData, setConfigMetasFoundInData] = useState<MetaAndPosition[] | null>(
    null,
  )
  const { reset } = useInfiniteScroll(fetchMore, 2000, tableCntrRef.current, needMoreToLoad)

  useEffect(() => {
    if (answers.length < 50) setNeedMoreToLoad(true)
    else setNeedMoreToLoad(false)
  }, [answers])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  useEffect(() => {
    if (tableFocusTimeoutRef.current) clearTimeout(tableFocusTimeoutRef.current)
    tableFocusTimeoutRef.current = setTimeout(() => {
      if (tableCntrRef.current) {
        tableCntrRef.current.focus()
      }
    }, 500)
    return () => tableFocusTimeoutRef.current && clearTimeout(tableFocusTimeoutRef.current)
  }, [tableCntrRef.current])

  useEffect(() => {
    let metaColumns = [] as MetaAndPosition[]
    if (configMetas && metaKeysInDataAndConfig) {
      metaColumns = configMetas.filter((metaAndPosition) =>
        metaKeysInDataAndConfig.includes(metaAndPosition.name),
      )
    }
    if (!metaColumns.length) {
      metaColumns = metaKeysInDataAndConfig.map<MetaAndPosition>((meta) => ({
        name: meta,
        position: 1,
      }))
    }
    setConfigMetasFoundInData(metaColumns)
  }, [configMetas, metaKeysInDataAndConfig])

  const getDynamicColumnStyles = () => {
    if (configMetasFoundInData && configMetasFoundInData.length) {
      const numberOfMetas = configMetasFoundInData.length
      switch (numberOfMetas) {
        case 0:
          return ''
        case 1:
          return css.one_meta_column
        case 2:
          return css.two_meta_columns
        case 3:
          return css.three_meta_columns
        case 4:
          return css.four_meta_columns
        case 5:
          return css.five_meta_columns
        case 6:
          return css.six_meta_columns
        default:
          return css.many_meta_columns
      }
    }
    return ''
  }

  const handleDateHeaderClick = () => {
    reset()
    if (sorting.column === dateProperty) {
      setSorting((prev) => ({
        ...prev,
        order: prev.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
      }))
    } else {
      setSorting(() => ({ column: dateProperty, order: SortDirection.ASC }))
    }
  }

  const handleAnswerHeaderClick = () => {
    reset()
    if (sorting.column === answerProperty) {
      setSorting((prev) => ({
        ...prev,
        order: prev.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
      }))
    } else {
      setSorting(() => ({ column: answerProperty, order: SortDirection.ASC }))
    }
  }

  const handleIdHeaderClick = () => {
    reset()
    if (sorting.column === idProperty) {
      setSorting((prev) => ({
        ...prev,
        order: prev.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
      }))
    } else {
      setSorting(() => ({ column: idProperty, order: SortDirection.ASC }))
    }
  }

  const handleMetaHeaderClick = (metaKey: string) => {
    reset()
    if (sorting.column === metaPropertyPrefix + metaKey) {
      setSorting((prev) => ({
        ...prev,
        order: prev.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
      }))
    } else {
      setSorting(() => ({ column: metaPropertyPrefix + metaKey, order: SortDirection.ASC }))
    }
  }

  const sortPredicate = (a: TextualKpiData, b: TextualKpiData) => {
    if (sorting.column === idProperty && sorting.order === SortDirection.ASC)
      return sortByAnswerRootProperty(a, b, 'completed_survey_id', true)
    if (sorting.column === idProperty && sorting.order === SortDirection.DESC)
      return sortByAnswerRootProperty(a, b, 'completed_survey_id', false)
    if (
      (sorting.column === answerProperty || sorting.column === dateProperty) &&
      sorting.order === SortDirection.ASC
    )
      return sortByAnswerRootProperty(a, b, sorting.column, true)
    if (
      (sorting.column === answerProperty || sorting.column === dateProperty) &&
      sorting.order === SortDirection.DESC
    )
      return sortByAnswerRootProperty(a, b, sorting.column, false)
    if (sorting.column && sorting.order === SortDirection.ASC)
      return sortByAnswerMetaProperty(a, b, sorting.column.substring(5), true)
    if (sorting.column && sorting.order === SortDirection.DESC)
      return sortByAnswerMetaProperty(a, b, sorting.column.substring(5), false)
    return 0
  }

  const sortByAnswerRootProperty = (
    a: TextualKpiData,
    b: TextualKpiData,
    property: string,
    ascending: boolean,
  ) => {
    const aProperty = a[property as keyof TextualKpiData]
    const bProperty = b[property as keyof TextualKpiData]
    if (!aProperty) return ascending ? -1 : 1
    if (!bProperty) return ascending ? 1 : -1
    if (aProperty > bProperty) return ascending ? 1 : -1
    if (aProperty < bProperty) return ascending ? -1 : 1
    return 0
  }

  const sortByAnswerMetaProperty = (
    a: TextualKpiData,
    b: TextualKpiData,
    metakey: string,
    ascending: boolean,
  ) => {
    const aProperty = a.metadata ? a.metadata[metakey] : undefined
    const bProperty = b.metadata ? b.metadata[metakey] : undefined
    if (!aProperty) return ascending ? -1 : 1
    if (!bProperty) return ascending ? 1 : -1
    if (!isNaN(Number(aProperty)) && !isNaN(Number(bProperty)))
      return ascending
        ? Number(aProperty) - Number(bProperty)
        : Number(bProperty) - Number(aProperty)
    return ascending ? aProperty.localeCompare(bProperty) : bProperty.localeCompare(aProperty)
  }

  const getSortingIcon = (currentColumn: string, sortColumn: string, sortOrder: SortDirection) => {
    if (currentColumn === sortColumn && sortOrder === SortDirection.ASC) {
      return <ArrowUpward sx={{ fontSize: '14px' }} />
    }
    if (currentColumn === sortColumn && sortOrder === SortDirection.DESC) {
      return <ArrowDownward sx={{ fontSize: '14px' }} />
    }
    return <></>
  }

  return (
    <div className={css.cntr}>
      <table
        className={`${css.multiple_answers_list} ${getDynamicColumnStyles()}`}
        tabIndex={0}
        ref={(ref) => {
          if (ref) tableCntrRef.current = ref
        }}
        data-testid='openAnswerInspectorTable'
      >
        <thead className={css.tableHeader} data-testid='valueInspectorTableHead'>
          <tr data-testid='table-header-row' className={css.tableHeaderRow}>
            <th
              key={'Date'}
              className={css.cell_date}
              onClick={handleDateHeaderClick}
              data-testid='dateColumnHeader'
            >
              <div className={css.headerCell}>
                {tCommon('label.date')}
                {getSortingIcon(dateProperty, sorting.column, sorting.order)}
              </div>
            </th>
            <th
              key={'Answer'}
              className={css.cell_answer}
              onClick={handleAnswerHeaderClick}
              data-testid='answerColumnHeader'
            >
              <div className={css.headerCell}>
                {tCommon('label.answer')}
                {getSortingIcon(answerProperty, sorting.column, sorting.order)}
              </div>
            </th>
            {configMetasFoundInData && configMetasFoundInData.length > 0
              ? configMetasFoundInData
                  .sort((a, b) => a.position - b.position)
                  .map((metaAndPosition) => (
                    <th
                      key={metaAndPosition.name + metaAndPosition.position}
                      className={css.cell_meta}
                      data-testid={`${metaAndPosition.name}MetaColumnHeader`}
                      onClick={() => handleMetaHeaderClick(metaAndPosition.name)}
                      title={metaAndPosition.name}
                    >
                      <div className={css.headerCell}>
                        {tData(metaAndPosition.name)}
                        {getSortingIcon(
                          metaPropertyPrefix + metaAndPosition.name,
                          sorting.column,
                          sorting.order,
                        )}
                      </div>
                    </th>
                  ))
              : null}
            <th
              key={'Completed Survey Id'}
              className={css.cell_id}
              title='Completed survey id'
              onClick={handleIdHeaderClick}
              data-testid='completedSurveyIdColumnHeader'
            >
              <div className={css.headerCell}>
                Id
                {getSortingIcon(idProperty, sorting.column, sorting.order)}
              </div>
            </th>
          </tr>
        </thead>
        <tbody data-cy='valueInspectorTableBody' data-testid='valueInspectorTableBody'>
          {answers.sort(sortPredicate).map((answer) => (
            <tr key={answer.answer_id} onClick={() => handleAnswerClick(answer)}>
              <td className={css.cell_date} title={answer.date}>
                {answer.date}
              </td>
              <td className={css.cell_answer} title={answer.answer}>
                {answer.answer}
              </td>
              {configMetasFoundInData && configMetasFoundInData.length > 0
                ? configMetasFoundInData
                    .sort((a, b) => a.position - b.position)
                    .map((metaAndPosition) =>
                      answer.metadata && answer.metadata[metaAndPosition.name] ? (
                        <td
                          key={metaAndPosition.name + metaAndPosition.position}
                          className={css.cell_meta}
                          data-testid='meta-cell'
                          title={answer.metadata[metaAndPosition.name]}
                        >
                          {answer.metadata[metaAndPosition.name]}
                        </td>
                      ) : (
                        <td
                          key={metaAndPosition.name + metaAndPosition.position}
                          className={css.cell_meta}
                        ></td>
                      ),
                    )
                : null}
              <td className={css.cell_id} title={answer.completed_survey_id.toString()}>
                {answer.completed_survey_id}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoadingMore && (
        <div className={css.footerRow}>
          <LoadingIndicator />
        </div>
      )}
    </div>
  )
}

export default OpenInspectorMultiTable
