import React, { useMemo, useState } from 'react'
import ModuleFiltersExpanded from './ModuleFiltersExpanded'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import MainButton from '../../../../_shared/Buttons/MainButton'
import { cloneDeep } from 'lodash'
import useReportingFilters from '../../../../../stores/useReportingFilters'
import useCommonDbSettingsConfig from '../../../../../stores/useCommonDbSettingsConfig'
import { WhereMeta } from '../../../../../../types'

type ModuleFiltersProps = {
  startDate: string
  endDate: string
  selections?: WhereMeta
  hasRestrictedFilters?: boolean
  customDatePickerOptions?: object
  areDatesChangedAfterClosingDatePicker?: boolean
  canChangeFilters?: boolean
  areChangesImmediate?: boolean
  onChangeDate: (newDate: string, isStartDate: boolean) => void
  onChangeMetadata: (newSelections: WhereMeta) => void
  onToggleExpansion: (isExpanded: boolean) => void
  onClearMetadataFilter: (newSelections: object, isExpanded: boolean) => void
  onApplyFilters: () => void
}
const ModuleFilters = ({
  areChangesImmediate,
  startDate,
  endDate,
  selections = {},
  hasRestrictedFilters = false,
  areDatesChangedAfterClosingDatePicker = false,
  canChangeFilters = false,
  onToggleExpansion,
  onChangeDate,
  onChangeMetadata,
  onClearMetadataFilter,
  customDatePickerOptions,
  onApplyFilters,
}: ModuleFiltersProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { metas: reportingFiltersConfig } = useReportingFilters()
  const { config: cdbs } = useCommonDbSettingsConfig()
  const reportingFilters = useMemo(() => {
    if (hasRestrictedFilters && reportingFiltersConfig) {
      const filters = {} as WhereMeta
      if (cdbs && cdbs.response_rate_filters) {
        for (const filter of cdbs.response_rate_filters) {
          if (reportingFiltersConfig[filter]) {
            filters[filter] = reportingFiltersConfig[filter]
          }
        }
      }
      return filters
    } else {
      return reportingFiltersConfig
    }
  }, [reportingFiltersConfig, cdbs])

  const toggleExpansion = () => {
    setIsExpanded((prev) => {
      onToggleExpansion && onToggleExpansion(!prev)
      return !prev
    })
  }

  const handleChangeMetadataFilters = (key: string, values: string[]) => {
    const newSelections = modifyMetadataFilters(selections, key, values)
    onChangeMetadata(cloneDeep(newSelections))
  }

  const handleClearMetadataFilter = (filterKey: string) => {
    if (canChangeFilters) {
      const newSelections = modifyMetadataFilters(selections, filterKey, null)
      onClearMetadataFilter(newSelections, isExpanded)
    }
  }

  const modifyMetadataFilters = (
    currentSelections: WhereMeta,
    key: string,
    values: string[] | null,
  ) => {
    const result = cloneDeep(currentSelections)
    if (!values) {
      delete result[key]
    } else {
      result[key] = values
    }

    return result
  }

  const handleDateChange = (newDate: string, isStartDate: boolean) => {
    onChangeDate(newDate, isStartDate)
  }

  const handleClearDates = () => {
    if (canChangeFilters) {
      onChangeDate('', true)
      onChangeDate('', false)
    }
  }

  const showClearableDates = startDate !== '' && endDate !== ''

  const clearingIcon = (
    <i className={`material-icons tiny right ${canChangeFilters ? 'pointer' : 'grey-text'}`}>
      clear
    </i>
  )

  const buildClearableMetadataFilters = () => {
    return Object.entries(selections).map(([metaKey, metaValues]) => (
      <div
        key={metaKey}
        data-testid={`selectedValuesForFilter-${metaKey}`}
        className={`module-bar-edit-filters valign-wrapper ${canChangeFilters ? '' : 'grey-text'}`}
        title={metaValues.join(', ')}
      >
        {metaValues[0]}
        {metaValues.length > 1 && ', ...'}
        <span onClick={() => handleClearMetadataFilter(metaKey)}>{clearingIcon}</span>
      </div>
    ))
  }

  return (
    <div className='module-filters'>
      <div className='row-marginless-row'>
        {/* Filter opener */}
        <div className='module-bar-edit-filters valign-wrapper'>
          <MainButton
            data-testid='filtersToggleButton'
            text={!isExpanded ? 'Open filters' : 'Close filters'}
            icon={!!isExpanded && !!reportingFilters ? 'expand_less' : 'expand_more'}
            iconPosition='right'
            customClasses={['black-text']}
            isFlat
            onClick={toggleExpansion}
          />
        </div>
        {/* Clearable filters */}
        {/* Date */}
        {showClearableDates && (
          <div
            className={`module-bar-selected-filters valign-wrapper hide-on-small-only ${
              canChangeFilters ? '' : 'grey-text'
            }`}
          >
            <span data-testid={'clearableDateFilters'}>
              {startDate} - {endDate}
              <span onClick={handleClearDates}>{clearingIcon}</span>
            </span>
          </div>
        )}

        {/* Meta filters */}
        {buildClearableMetadataFilters()}
      </div>

      {isExpanded &&
        (reportingFilters ? (
          <ModuleFiltersExpanded
            startDate={startDate}
            endDate={endDate}
            reportingFilters={reportingFilters}
            selections={selections}
            seriesBy='none'
            customDatePickerOptions={customDatePickerOptions}
            areDatesChangedAfterClosingDatePicker={
              areChangesImmediate || areDatesChangedAfterClosingDatePicker
            }
            onChangeDate={handleDateChange}
            onChangeMetadata={handleChangeMetadataFilters}
            onClose={toggleExpansion}
            onApplyFilters={onApplyFilters}
            canChangeFilters={canChangeFilters}
          />
        ) : (
          <LoadingIndicator />
        ))}
    </div>
  )
}

export default ModuleFilters
