import React, { useState } from 'react'
import SliderItem from './SliderItem'

import modal_css from './../SettingsModal.module.scss'
import css from './SliderInput.module.scss'

type SliderInputProps = {
  title: string
  leftLabel?: string
  rightLabel?: string
  settingsName: string
  width?: 'half' | 'third' | 'full' | 'twoThirds'
  defaultValue?: number
  min: number
  max: number
  isDisabled?: boolean
}

const SliderInput = ({
  title,
  leftLabel,
  rightLabel,
  width = 'half',
  isDisabled,
  ...rest
}: SliderInputProps) => {
  const [sliderValue, setSliderValue] = useState<number>(50)

  const getWidthClass = (): string => {
    if (width === 'half') {
      return modal_css.half
    }
    if (width === 'third') {
      return modal_css.third
    }
    if (width === 'full') {
      return modal_css.max
    }
    if (width === 'twoThirds') {
      return modal_css.twoThirds
    }
    return modal_css.half
  }

  const getCntrClasses = () => {
    let classes = getWidthClass() || ''
    if (isDisabled) classes = classes.concat(' ', css.disabled)
    return classes
  }
  return (
    <div className={getCntrClasses()}>
      {title}
      <div className={css.input}>
        <div className={css.labelsCntr}>
          <label>{`${leftLabel} - ${sliderValue}`}</label>
          {!!rightLabel && <label>{`${100 - sliderValue} - ${rightLabel}`}</label>}
        </div>
        <SliderItem isDisabled={isDisabled} setValue={(value) => setSliderValue(value)} {...rest} />
      </div>
    </div>
  )
}

export default SliderInput
