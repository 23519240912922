import React from 'react'
import { Table } from 'react-materialize'
import { UserManagementTool } from '../../../../../types'
import { isTemplateUser } from '../../../../react-services/authService'
import { nonEditableUsers } from '../UserManagementGrouped/UserList'

import css from './../UserManagementGrouped/UserList.module.scss'
type Props = {
  users: UserManagementTool.User[]
  showEditUser: (user: UserManagementTool.User) => void
  showDeleteUser: (user: UserManagementTool.User) => void
  templates: { key: string; name: string }[]
}

type State = {
  users: UserManagementTool.User[]
  sortUsernamesAsc: boolean
  sortRolesAsc: boolean
}

class UserList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      users: props.users.sort(this.compareIds),
      sortUsernamesAsc: false,
      sortRolesAsc: false,
    }
  }

  sortByNames = () => {
    if (!this.state.sortUsernamesAsc) {
      this.setState({
        users: this.state.users.sort(this.compareUsernames),
        sortUsernamesAsc: true,
      })
    } else {
      this.setState({
        users: this.state.users.sort(this.compareUsernames2),
        sortUsernamesAsc: false,
      })
    }
  }

  compareIds(a: UserManagementTool.User, b: UserManagementTool.User) {
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  }

  compareUsernames(a: UserManagementTool.User, b: UserManagementTool.User) {
    if (a.username < b.username) return -1
    if (a.username > b.username) return 1
    return 0
  }

  compareUsernames2(a: UserManagementTool.User, b: UserManagementTool.User) {
    if (b.username < a.username) return -1
    if (b.username > a.username) return 1
    return 0
  }

  componentDidUpdate(nextProps: Props) {
    if (nextProps.users !== this.props.users) {
      this.setState({
        users: nextProps.users.sort(this.compareIds),
      })
    }
  }

  render() {
    const { showEditUser, showDeleteUser, templates } = this.props
    const mapTemplateKeyToName = (key: string) => {
      if (!key || !templates) return ''
      const template = templates.find((t: { key: string; name: string }) => t.key === key)

      return template && template.name ? template.name : ''
    }

    const getTemplatesForTooltip = (modules: string[]) => {
      const array = [
        <div key='modules-title'>
          <strong>Modules:</strong>
        </div>,
      ]
      for (const a in modules) {
        array.push(<div key={a}>{mapTemplateKeyToName(modules[a])}</div>)
      }
      return array
    }

    const accessrights = (accessrights: { [name: string]: string[] }) => {
      const hidden = [
        <div key='hidden'>
          <strong>Hidden:</strong>
        </div>,
      ]
      const forced = [
        <div key='forced'>
          <strong>Access rights:</strong>
        </div>,
      ]
      for (const a in accessrights) {
        if (accessrights[a].length === 0) {
          hidden.push(<div key={a}>{a}</div>)
        } else {
          forced.push(
            <div key={a}>
              {a + ': '} <i>{accessrights[a].join(', ')}</i>
            </div>,
          )
        }
      }
      if (hidden.length > 1 && forced.length > 1) {
        return [...hidden, ...forced]
      } else if (hidden.length > 1) {
        return hidden
      } else if (forced.length > 1) {
        return forced
      } else {
        return []
      }
    }

    const defaultfilters = (defaultfilters: { [name: string]: string[] }) => {
      const array = [
        <div key='default-filter-title'>
          <strong>Default filter:</strong>
        </div>,
      ]
      for (const a in defaultfilters) {
        array.push(
          <div key={a}>
            {a + ': '} <i>{defaultfilters[a].join(', ')}</i>
          </div>,
        )
      }
      return array
    }

    return (
      <Table className='table striped z-depth-1 table-margin'>
        <thead data-testid='grouplessUserListHead'>
          <tr>
            <th onClick={this.sortByNames} className='pointer'>
              Username
            </th>
            <th>Modules</th>
            <th>Access rights</th>
            <th>Default filter</th>
            <th>Roles</th>
            <th></th>
          </tr>
        </thead>
        <tbody data-cy='userlist'>
          {this.state.users.map((user) => {
            const isUserUnEditable = nonEditableUsers.includes(
              (user.username || '').toLocaleLowerCase(),
            )
            const isRemoveAllowed = isTemplateUser() || !isUserUnEditable
            return (
              <tr key={user.id}>
                <td data-cy={`userId-${user.id}`}>{user.username}</td>
                <td>
                  {user.dbsettings &&
                    user.dbsettings.templates &&
                    user.dbsettings.templates.length === 1 && (
                      <span className='cursor'>
                        {mapTemplateKeyToName(user.dbsettings.templates[0])}
                      </span>
                    )}
                  {user.dbsettings &&
                    user.dbsettings.templates &&
                    user.dbsettings.templates.length > 1 && (
                      <span className='tool'>
                        {mapTemplateKeyToName(user.dbsettings.templates[0]) + ', ...'}
                        <span className='tip'>
                          {getTemplatesForTooltip(user.dbsettings.templates)}
                        </span>
                      </span>
                    )}
                </td>
                <td>
                  {user.filters && Object.keys(user.filters).length !== 0 && (
                    <span className='tool' data-cy='limitedText'>
                      limited
                      <span className='tip'>{accessrights(user.filters)}</span>
                    </span>
                  )}
                </td>
                <td>
                  {user.defaultfilters && Object.keys(user.defaultfilters).length !== 0 && (
                    <span className='tool'>
                      {Object.keys(user.defaultfilters)[0] + ', ...'}
                      <span className='tip'>{defaultfilters(user.defaultfilters)}</span>
                    </span>
                  )}
                </td>
                <td>{user.roles && user.roles.join(', ')}</td>
                <td>
                  <span data-testid='showEditUserModal' onClick={() => showEditUser(user)}>
                    <i className='material-icons module-icon-color waves-effect'>edit</i>
                  </span>
                  <span
                    data-testid='showDeleteUserModal'
                    onClick={() => isRemoveAllowed && showDeleteUser(user)}
                  >
                    <i
                      className={`material-icons module-icon-color waves-effect ${
                        !isRemoveAllowed ? css.deleteDisabled : ''
                      }`}
                    >
                      delete
                    </i>
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

export default UserList
