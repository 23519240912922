export enum Keyboard {
  ENTER = 13,

  num0 = 48,
  num4 = 52,
  num8 = 56,

  a = 65,
  b = 66,
  c = 67,
  d = 68,
  e = 69,
  f = 70,
  g = 71,
  h = 72,
  i = 73,
  j = 74,
  l = 76,
  m = 77,
  n = 78,
  o = 79,
  p = 80,
  r = 82,
  s = 83,
  t = 84,
  u = 85,
  w = 87,
  x = 88,
  y = 89,
  z = 90,
}
