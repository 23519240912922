import React, { Fragment, useCallback, useState } from 'react'
import FilterToggle from './FilterToggle'
import QuickTimeFrameButton from './QuickTimeFrameButton'
import { QuickTimeframe } from '../groupModuleTypes'
import ModuleSettings from './ModuleSettings'
import TimeframeDisplay from './TimeframeDisplay'
import { FilterArray } from '../../../../../react-services/filterService'
import FilterModal from './modal/FilterModal'
import ErrorBoundary from '../../../../_shared/Infos/ErrorBoundary'

import css from './GroupFilters.module.scss'

type FilterBarProps = {
  isCntrHovered?: boolean
  filters?: FilterArray
  showFilterToggle?: boolean
  showTimeDisplay?: boolean
  isSmall?: boolean
  isBarCollapsed?: boolean
  isModuleActionsVisible?: boolean
  filtersSelectedAlready?: FilterArray
  selectedQuickTimeFrame?: QuickTimeframe
  handleOpenSettingsModal?: () => void
  handleOpenDeleteModal?: () => void
  saveFilters: (filters: FilterArray) => void
  dynamicFiltersKpis?: number[]
  keepStateUpdated?: boolean
} & QuickTimeFrame

type QuickTimeFrame =
  | {
      showQuickTimeButtons: true
      onSelectQuickTimeFrame: (quickTimeFrame: QuickTimeframe) => void
    }
  | {
      showQuickTimeButtons: false
      onSelectQuickTimeFrame?: (quickTimeFrame: QuickTimeframe) => void
    }

const FilterBar = ({
  isCntrHovered,
  filters = [],
  showFilterToggle,
  showQuickTimeButtons,
  showTimeDisplay,
  isSmall,
  isBarCollapsed,
  isModuleActionsVisible,
  selectedQuickTimeFrame,
  filtersSelectedAlready = [],
  dynamicFiltersKpis = [] as number[],
  handleOpenSettingsModal,
  handleOpenDeleteModal,
  saveFilters,
  onSelectQuickTimeFrame,
  keepStateUpdated,
}: FilterBarProps) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const handleOpenFilters = useCallback(() => setIsFiltersOpen(true), [])
  const handleCloseFilters = useCallback(() => setIsFiltersOpen(false), [])

  const getCntrClasses = () => {
    let classes: string = css.cntr
    if (isSmall && showQuickTimeButtons) classes = classes.concat(' ', css.nested)

    if (isBarCollapsed) classes = classes.concat(' ', css.cntrAbsolute)
    return classes
  }
  return (
    <>
      <div data-testid='group-filters' className={getCntrClasses()}>
        <div className={css.barLeft}>
          {showFilterToggle && (
            <FilterToggle
              isSmall={!!isSmall}
              handleOpenFilters={handleOpenFilters}
              showQuickTimeButtons={!!showQuickTimeButtons}
            />
          )}
          {!!showQuickTimeButtons &&
            !!onSelectQuickTimeFrame &&
            Object.keys(QuickTimeframe).map((tf) =>
              tf.toLocaleLowerCase() !== 'none' ? (
                <QuickTimeFrameButton
                  isSmall={!!isSmall}
                  key={tf}
                  label={tf}
                  isActive={tf.toLocaleLowerCase() === selectedQuickTimeFrame}
                  onSelectQuickTimeFrame={onSelectQuickTimeFrame}
                />
              ) : (
                <Fragment key={tf}></Fragment>
              ),
            )}
        </div>
        <div className={css.barRight}>
          {showTimeDisplay && (
            <TimeframeDisplay
              filters={filters || []}
              saveFilters={saveFilters}
              keepStateUpdated={!!keepStateUpdated}
            />
          )}
          {isModuleActionsVisible && (
            <ModuleSettings
              isSmall={!!isSmall}
              isBarCollapsed={!!isBarCollapsed}
              handleOpenSettingsModal={handleOpenSettingsModal}
              handleOpenDeleteModal={handleOpenDeleteModal}
              isCntrHovered={isCntrHovered}
            />
          )}
        </div>
      </div>
      {isFiltersOpen && (
        <ErrorBoundary message='Filter failed to open' fallback={<></>}>
          <FilterModal
            handleCloseFilters={handleCloseFilters}
            filters={filters}
            filtersSelectedAlready={filtersSelectedAlready}
            saveFilters={saveFilters}
            kpis={dynamicFiltersKpis}
          />
        </ErrorBoundary>
      )}
    </>
  )
}

export default FilterBar
