import React, { memo, useMemo, useRef, useState } from 'react'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import GenericTableTitle from '../_shared/GenericTableTitle'
import ValueInspector from '../_shared/ValueInspector/ValueInspector'
import { DEFAULT_DECIMALS } from '../../../../react-constants/dashboards'
import { Data, GenericConfig, KpiData, WhereMeta } from '../../../../../types'
import {
  CellClickDetails,
  CheckedKpiSettings,
  KpiSettings,
  TableContent,
  VALID_DATA_MODES,
  ValueInspectorDetails,
} from './tabularTypes'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import useTabularTableBuildingTools from './useTabularTableBuildingTools'
import { isBasicDataFormat, isTimeRes } from '../../../../utilities'
import { figureOutStartAndEndDatesForInspector } from '../../../../react-services/chartService'
import { isEqual } from 'lodash'
import FadeIn from '../_shared/FadeIn'
import { useDeepCompareEffect } from 'react-use'
import { CircularProgress, SelectChangeEvent } from '@mui/material'

import css from './Tabular.module.scss'
import { useTranslation } from 'react-i18next'
import { TranslationNameSpace } from '../../../../../languages/i18n'

type TabularProps = {
  sorting?: {
    index: number
    isReversed: boolean
  }
  selectedGrouping: string
  calculatedKpis?: GenericConfig.CalculatedKpis
  groupings?: Array<string>
  data: KpiData.NumericData | null
  trendData: KpiData.NumericData | null
  showTotal: boolean
  showN: boolean
  showTrends: boolean
  dataMode?: Data.FormatBasic
  minLimit?: number | null
  maxLimit?: number | null
  xAxisScrollingEnabled: boolean
  isKpiSpecificSettings?: boolean
  isTableCollapsed?: boolean
  settingsKpis: KpiSettings[]
  isGroupingByTimeRes: boolean
  whereMeta: WhereMeta
  startDate: string
  endDate: string
  isScreenMode: boolean
  isTitleVisible: boolean
  isTitleLoading?: boolean
  isRefetchingCounts?: boolean
  valueInspectorInfo?: {
    filters: WhereMeta
    startDate: string
    endDate: string
  }
  onGroupingChange?: (e: SelectChangeEvent<string>) => void
  onSort?: (index?: number, isReversed?: boolean) => void
}

const Tabular = memo(
  ({
    selectedGrouping,
    showTotal,
    showN,
    data,
    dataMode,
    trendData,
    minLimit,
    maxLimit,
    isKpiSpecificSettings,
    sorting,
    isScreenMode,
    onSort,
    isTitleVisible,
    isRefetchingCounts,
    xAxisScrollingEnabled,
    groupings,
    isTitleLoading,
    onGroupingChange,
    isGroupingByTimeRes,
    settingsKpis,
    whereMeta,
    startDate,
    endDate,
    calculatedKpis = {},
  }: TabularProps) => {
    const { config } = useCommonDbSettingsConfig()
    const decimals = config?.decimals || DEFAULT_DECIMALS
    const { i18n } = useTranslation()
    const formattedKpiSettings = useMemo(() => {
      if (!isKpiSpecificSettings && settingsKpis) {
        const currentDataMode = dataMode ? dataMode : VALID_DATA_MODES.avg
        return settingsKpis.map<CheckedKpiSettings>((kpi) => {
          return {
            ...kpi,
            id: calculatedKpis[kpi.name] ? kpi.name : kpi.id !== undefined ? kpi.id : '',
            grouping: kpi.grouping || '',
            limitValues: {
              min: typeof minLimit === 'number' ? minLimit : null,
              max: typeof maxLimit === 'number' ? maxLimit : null,
            },
            dataMode: !isKpiSpecificSettings && kpi.dataMode ? kpi.dataMode : currentDataMode,
          }
        })
      }
      if (isKpiSpecificSettings && settingsKpis) {
        const defaultDataMode = VALID_DATA_MODES.avg
        return settingsKpis.map<CheckedKpiSettings>((kpi) => {
          return {
            ...kpi,
            id: calculatedKpis[kpi.name] ? kpi.name : kpi.id !== undefined ? kpi.id : '',
            grouping: kpi.grouping || '',
            limitValues: {
              min: typeof kpi.limitValues?.min === 'number' ? kpi.limitValues.min : null,
              max: typeof kpi.limitValues?.max === 'number' ? kpi.limitValues.max : null,
            },
            dataMode: isBasicDataFormat(kpi.dataMode) ? kpi.dataMode : defaultDataMode,
          }
        })
      }
      return [] as CheckedKpiSettings[]
    }, [isKpiSpecificSettings, dataMode, settingsKpis, minLimit, maxLimit])

    const {
      createHeaderWithoutGrouping,
      createHeaderWithGrouping,
      createRowsWithGrouping,
      createRowsWithoutGrouping,
      createFooterWithGrouping,
      createFooterWithoutGrouping,
    } = useTabularTableBuildingTools(
      selectedGrouping,
      showN,
      showTotal,
      formattedKpiSettings,
      data,
      trendData,
      decimals,
    )
    const [valueInspectorDetails, setValueInspectorDetails] =
      useState<ValueInspectorDetails | null>(null)
    const [tableContent, setTableContent] = useState<TableContent>({
      rows: null,
      headers: null,
      footer: null,
    })
    const [isRebuildingTable, setIsRebuildingTable] = useState<boolean>(false)

    const createHeaders = (grouping: string) => {
      if (grouping) return createHeaderWithGrouping()
      else return createHeaderWithoutGrouping()
    }

    const createRows = (grouping: string, handleCellClick: (details: CellClickDetails) => void) => {
      if (grouping) return createRowsWithGrouping(handleCellClick, grouping)
      else return createRowsWithoutGrouping(handleCellClick)
    }

    const createFooter = (grouping: string) => {
      if (grouping) return createFooterWithGrouping()
      else return createFooterWithoutGrouping()
    }

    const renderTimeOutRef = useRef<NodeJS.Timeout>()
    useDeepCompareEffect(() => {
      if (renderTimeOutRef.current) clearTimeout(renderTimeOutRef.current)
      setIsRebuildingTable(true)
      renderTimeOutRef.current = setTimeout(
        () => {
          const headers = createHeaders(selectedGrouping)
          const rows = createRows(selectedGrouping, handleCellClick)
          const footer = showTotal === true ? createFooter(selectedGrouping) : null
          setTableContent({ rows, headers, footer })
          setIsRebuildingTable(false)
        },
        tableContent.rows ? 1000 : 300,
      )
      return () => renderTimeOutRef.current && clearTimeout(renderTimeOutRef.current)
    }, [data, trendData, showN, showTotal, formattedKpiSettings, decimals, i18n.language])

    const table = useMemo(() => {
      if (tableContent.headers && tableContent.rows) {
        return (
          <GenericTable
            headers={tableContent.headers}
            rows={tableContent.rows}
            footer={tableContent.footer ?? undefined}
            tableClasses={['tabular']}
            hasFixedHeader
            useFixedLayout
            preSortedColumn={sorting}
            onSort={onSort}
            onInvalidSorting={() => {
              onSort && onSort(undefined, undefined)
            }}
          />
        )
      } else {
        return <div></div>
      }
    }, [tableContent.footer, tableContent.headers, tableContent.rows, sorting])

    const handleCellClick = ({ value, rowGroup, columnGroup, id }: CellClickDetails) => {
      let inspectedIds = [] as number[]
      if (typeof id === 'string') inspectedIds = calculatedKpis[id].kpiIds
      if (typeof id === 'number') inspectedIds = [id]
      if (isGroupingByTimeRes && rowGroup && isTimeRes(selectedGrouping)) {
        const dates = figureOutStartAndEndDatesForInspector(rowGroup, selectedGrouping)
        setValueInspectorDetails({
          startDate: dates.start_date,
          endDate: dates.end_date,
          filters: whereMeta,
          kpiName: columnGroup,
          kpiValue: value,
          kpiIds: inspectedIds,
        })
      }
      if (!isGroupingByTimeRes && selectedGrouping) {
        setValueInspectorDetails({
          startDate,
          endDate,
          filters: { ...whereMeta, [selectedGrouping]: [rowGroup] },
          kpiName: columnGroup,
          kpiValue: value,
          kpiIds: inspectedIds,
        })
      }
      if (!isGroupingByTimeRes && !selectedGrouping) {
        setValueInspectorDetails({
          startDate,
          endDate,
          filters: whereMeta,
          kpiName: rowGroup,
          kpiValue: value,
          kpiIds: inspectedIds,
        })
      }
    }

    const handleCloseInspector = () => setValueInspectorDetails(null)

    const classes = ['tabular', 'tabular-module-cntr']
    // 'wraptitles' class prevents the scrollbar from appearing
    if (isScreenMode) {
      classes.push('wraptitles')
    } else {
      if (!xAxisScrollingEnabled) {
        classes.push('wraptitles')
      }
    }

    return (
      <FadeIn>
        <div className={css.cntr} style={{ height: 'inherit' }} data-testid='tabularTable'>
          <div className={css.title}>
            {isTitleVisible && (
              <GenericTableTitle
                title=''
                useGrouping={true}
                groupingsNamespace={TranslationNameSpace.DATA}
                selectedGrouping={selectedGrouping}
                groupings={groupings || []}
                isLoading={!!isTitleLoading}
                loadingText={'Loading dynamic selections ..'}
                onGroupingChange={(e) => {
                  onGroupingChange && onGroupingChange(e)
                }}
              />
            )}
            <div className={css.loading} id='tabularLoadingIconCntr'>
              {(!!isRefetchingCounts || isRebuildingTable) && (
                <div className={css.refetching}>
                  <CircularProgress thickness={2} size={'1.7rem'} style={{ opacity: 0.7 }} />
                </div>
              )}
            </div>
          </div>
          {tableContent.headers && tableContent.rows && (
            <div
              className={css.tbl}
              data-testid='tabularModuleData'
              style={{
                ...(isScreenMode ? { maxHeight: '400px', overflowY: 'auto' } : {}),
              }}
            >
              {table}
            </div>
          )}

          <div id={'tabularInspectorContainer'}>
            {valueInspectorDetails && (
              <ValueInspector
                valueType={'numeric'}
                inspectorId={'tabularInspectorContainer'}
                inspected={`${valueInspectorDetails.kpiName}, ${valueInspectorDetails.kpiValue}`}
                kpis={valueInspectorDetails.kpiIds}
                filters={valueInspectorDetails.filters}
                startDate={valueInspectorDetails.startDate}
                endDate={valueInspectorDetails.endDate}
                onClose={handleCloseInspector}
              />
            )}
          </div>
        </div>
      </FadeIn>
    )
  },
  arePropsEqual,
)

function arePropsEqual(prevProps: TabularProps, newProps: TabularProps) {
  return isEqual(prevProps, newProps)
}
Tabular.displayName = 'Tabular'

export default Tabular
