const tenantsUsingNewBackend: string[] = [
  'test_frontend',
  'sender_tester',
  'bxx',
  'kalmar',
  'lindstrom',
  'neste_asiakaspalvelu',
  'secto_automotive',
  'technopolis',
  'technopolis_demo',
  'visma',
  'fortum_norway',

  // ~ Half of all tenants (alphabetical order)
  '3stepit',
  'aava',
  'accountor',
  'accountorhr',
  'accountorhr_automation',
  'adfinland',
  'aditro',
  'adven',
  'adven_api',
  'aktiv_ortopedteknik',
  'ald',
  'alehdet',
  'alfalaval',
  'alfalaval_benelux',
  'alfalaval_mideurope',
  'alfalaval_uk_ir',
  'andy_test',
  'aninkainen',
  'arosystems',
  'arosystems_henkilosto',
  'arosystems_surveydata',
  'asiakasdemo',
  'asiakastieto',
  'asokodit',
  'aspakoti',
  'aspasaatio',
  'asuntosaatio',
  'asuntosaatio_konserni',
  'auth_demo',
  'avara',
  'bauermedia',
  'brandday2017',
  'brandstrategy',
  'bxx',
  'bxxcommon',
  'caruna',
  'caruna_puhelin',
  'caverion',
  'caverion_api',
  'caverion_kam',
  'caverion_mcsm_test',
  'caverionpsm',
  'caverionpsm_vastausprosentti',
  'cheeky',
  'chipsters',
  'comforta',
  'consti',
  'cramo_demo',
  'crmquality',
  'demo',
  'demo_english',
  'demo_housing',
  'demo_housing_en',
  'demo_housing_fi',
  'demo_housing_se',
  'demo2018',
  'demob2b',
  'demob2c',
  'demobtoc',
  'diakonissalaitos',
  'diakonissalaitos_vamos',
  'digia_demo',
  'duunitori',
  'dynamics',
  'dynamics_test',
  'eckero_arvonta',
  'eckeroline_dashboard',
  'eckeroline_hintajousto',
  'eduhouse',
  'eezy',
  'efecte',
  'elo',
  'elo_asiakaspalvelu',
  'elo_elakepalvelut',
  'elo_sahkoinenelakepalvelu',
  'espoonasunnot',
  'etteplan',
  'etteplan_budgetkickoff',
  'etteplan_employees',
  'etteplan_kamday',
  'evac',
  'ework',
  'example',
  'fcg',
  'feon',
  'ferratum',
  'ferratum_apipl',
  'ferratum_customerservice',
  'ferratum_five9',
  'ferratum_group',
  'ferratum_lending',
  'ferratum_mobilebank',
  'ferratumapi',
  'forenom',
  'fortum',
  'fortum_norway',
  'fortum_sweden',
  'fredman',
  'fysioline',
  'gatest',
  'glaston',
  'googleanalytics',
  'gotaenergi',
  'hafslund_strom',
  'halton',
  'hartela',
  'hartela_surveydata',
  'hartwall',
  'heba',
  'helen',
  'hellon',
  'helvar',
  'hoas',
  'hoya',
  'hrk',
  'hsy',
  'huomentaketju',
  'idealkeittiot',
  'iloq',
  'iloq_demo',
  'iloq_hitrate_demo',
  'imagewear',
  'imagewear_demo',
  'inspecta',
  'inwido',
  'joakim_test',
  'jobmeal',
  'jokerit',
  'jukkatalo',
  'juvenes',
  'kalle_testitenantti',
  'kalmar',
  'koas',
  'koja',
  'kone',
  'kotimaan_energia',
  'kotisun',
  'kpa_unicon',
  'kruunuasunnot',
  'lahdenaluetaksi',
  'lahdentalot',
  'lahdentalot_api',
  'lahdentalot_lahetys',
  'lakea',
  'lappeenrannan_energia',
  'lappset',
  'lapua',
  'lassilatikanoja',
  'leaseplan',
  'lehto',
  'lehtoasunnot',
  'liidikone',
  'lindstrom',
  'lindstrom_churn_demo',
  'logging',
  'lonneberga',
  'lviturva',
  'lyreco',
  'm2kodit',
  'managementevents',
  'manpower',
  'maria_test',
  'martela',
  'martela_demo',
  'meconet_automation',
  'meconetsales',
  'metos',
  'metos_demo'
]

export default tenantsUsingNewBackend
