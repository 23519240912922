import React, { useContext, useState } from 'react'
import { saveOpenAnswerCategories } from '../../../../../react-services/openAnswersService'
import { OpenModuleContext } from '../_OpenModuleContext'
import { useHotkeysData, useOpenCategoryToolData } from './OpenCategoryToolProvider'
import { Input } from 'react-materialize'
import { useKeyPressEvent, useToggle } from 'react-use'
import { useToastId } from '../../../../common/Notification/NotificationContext'
import { toast } from 'react-toastify'
import trackingService from '../../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../../react-constants'
import css from './OpenCategoryToolModal.module.scss'
import { tCommon } from '../../../../../../languages/i18n'
import { CheckCircle, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { pSBC } from '../../../../../react-services/colorService'
import { allChartColors } from '../../../../../../styles/variableExport'

type Props = {
  setFadeIn: () => void
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | null>
}

const OpenCategoryToolSave = ({ setFadeIn, timeoutRef }: Props) => {
  const [
    {
      answersForThisKpi,
      index,
      updatedCategoryValues,
      openAnswer,
      isCategoryChangingAllowed,
      isUnsavedCategoryChanges,
      isFilterOnFocus,
    },
    setOpenCategoryToolData,
  ] = useOpenCategoryToolData()
  const [{ isHotkeySetupMaskOn }] = useHotkeysData()

  const [showSaveNotification, setShowSaveNotification] = useState<boolean>(false)
  const [onAutosave, toggleAutosave] = useToggle(false)
  const { toastifyId } = useToastId()
  const { onChangeCategoriesCompleted } = useContext(OpenModuleContext)

  const nextAnswer = () => nextOpenAnswer()
  const prevAnswer = () => prevOpenAnswer()
  const saveAnswer = () => handleCategoriesSave()

  useKeyPressEvent('ArrowRight', nextAnswer)
  useKeyPressEvent('ArrowLeft', prevAnswer)
  useKeyPressEvent('Enter', saveAnswer)

  const handleCategoriesSave = () => {
    if (isFilterOnFocus || !isCategoryChangingAllowed || isHotkeySetupMaskOn) return
    if (openAnswer && openAnswer.answer_id && updatedCategoryValues && isUnsavedCategoryChanges) {
      return saveOpenAnswerCategories(
        openAnswer.answer_id,
        updatedCategoryValues,
        isCategoryChangingAllowed,
      )
        .then(() => {
          trackingService.track(TrackingEvent.ChangeCategories)
          openAnswer.category = updatedCategoryValues
          onChangeCategoriesCompleted(openAnswer)
          setOpenCategoryToolData((prev) => ({
            ...prev,
            isUnsavedCategoryChanges: false,
            initialCategoryValues: updatedCategoryValues,
          }))
          setShowSaveNotification(true)
          clearTimeout(timeoutRef.current as NodeJS.Timeout)
          timeoutRef.current = setTimeout(() => {
            setShowSaveNotification(false)
          }, 1000)
          return Promise.resolve()
        })
        .catch((err: unknown) =>
          toast.error(typeof err === 'string' ? err : '', { containerId: toastifyId }),
        )
    }
  }

  const nextOpenAnswer = async () => {
    if (isFilterOnFocus || answersForThisKpi.length === index + 1 || isHotkeySetupMaskOn) return
    if (onAutosave && isUnsavedCategoryChanges) {
      await handleCategoriesSave()
    }
    setFadeIn()
    setOpenCategoryToolData((prev) => ({
      ...prev,
      index: index + 1,
      openAnswer: answersForThisKpi[index + 1],
    }))
  }

  const prevOpenAnswer = async () => {
    if (isFilterOnFocus || index === 0 || isHotkeySetupMaskOn) return
    if (onAutosave && isUnsavedCategoryChanges) {
      await handleCategoriesSave()
    }
    setFadeIn()
    setOpenCategoryToolData((prev) => ({
      ...prev,
      index: index - 1,
      openAnswer: answersForThisKpi[index - 1],
    }))
  }
  return (
    <div className={css.category_tool_save}>
      {showSaveNotification && (
        <span className={css.save_notification}>
          <div>{tCommon('info.cateriesUpdated')}</div>
          <CheckCircle />
        </span>
      )}

      <IconButton
        onClick={prevOpenAnswer}
        disabled={index === 0}
        sx={{ background: pSBC(0.8, allChartColors.GREY) }}
      >
        <ChevronLeft />
      </IconButton>
      <button
        className='btn blue waves-effect'
        onClick={handleCategoriesSave}
        disabled={!openAnswer || !isCategoryChangingAllowed || !isUnsavedCategoryChanges}
      >
        {tCommon('button.save')}
      </button>
      <IconButton
        onClick={nextOpenAnswer}
        disabled={answersForThisKpi.length === index + 1}
        sx={{ background: pSBC(0.8, allChartColors.GREY) }}
      >
        <ChevronRight />
      </IconButton>

      {isCategoryChangingAllowed && (
        <div data-testid='openCategoryToolModalAutosave' className={css.save_autosave}>
          <Input
            className={'filled-in alerts-list-checkbox'}
            type='checkbox'
            label={tCommon('label.autosave')}
            disabled={isCategoryChangingAllowed ? null : 'disabled'}
            checked={onAutosave}
            onClick={toggleAutosave}
          />
          <span className={`${onAutosave ? css.toggle_green : css.toggle_red}`}>
            {onAutosave ? tCommon('label.on') : tCommon('label.off')}
          </span>
        </div>
      )}
    </div>
  )
}

export default OpenCategoryToolSave
