import { getMetaDataKeysAndValues } from '../react-services/metadataService'
import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreCRUD, { ActionState } from './useStoreNonResetting'

type MetaData = {
  [metaKey: string]: string[]
}
const metasStorage = makeNonResettingObservable<MetaData>({})

const useMetaData = (doFetch = true) => {
  const storageKey = 'metas'
  const { tenant, user } = useStoreOwner(metasStorage)
  const {
    dataState: { data, error, state },
  } = useStoreCRUD(metasStorage, storageKey, user, tenant, doFetch, getMetaDataKeysAndValues)

  return {
    error,
    loading: state === ActionState.LOADING,
    metas: data,
  }
}

export default useMetaData
