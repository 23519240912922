import React from 'react'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'

type KpiMinMaxTextInputProps = {
  title: string
  handleOnChange: (newValue: string) => void
  value: string
}

const KpiMinMaxTextInput = ({ title, value, handleOnChange }: KpiMinMaxTextInputProps) => {
  return (
    <div className={`${modal_css.third}`}>
      {title}
      <input
        data-testid='kpiMinMaxTextInput'
        type='number'
        value={value}
        id='kpiMinMaxTextInput'
        onChange={(e) => handleOnChange(e.target.value)}
        className={`${css.input}`}
      />
    </div>
  )
}

export default KpiMinMaxTextInput
