import React from 'react'
import { useEffectOnce } from 'react-use'
import trackingService from './scripts/react-services/trackingService'
import { TrackingEvent } from './scripts/react-constants'
import {
  getTenantFromSessionStorage,
  getUsernameFromSessionStorage,
  logout,
} from './scripts/react-services/authService'
import { Navigate } from 'react-router-dom'

const Logout = () => {
  useEffectOnce(() => {
    trackingService.track(TrackingEvent.Logout, {
      Tenant: getTenantFromSessionStorage() || '',
      'User name': getUsernameFromSessionStorage() || '',
    })
  })

  useEffectOnce(() => {
    logout()
  })

  return <Navigate to='/' />
}

export default Logout
