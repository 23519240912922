import { createStateContext } from 'react-use'
import { Module, ModuleType } from '../../Group/groupModuleTypes'

type SettingsToolsProps = {
  module: Module | null
  moduleType: ModuleType | null
  saveModule: (module: Module) => void
  index: string | number | null
  inGroup: boolean
  moveModuleUp: (m: Module) => void
  moveModuleDown: (m: Module) => void
}
export const [useSettingsTools, SettingsToolsProvider] = createStateContext<SettingsToolsProps>({
  moduleType: null,
  module: null,
  saveModule: () => ({}),
  index: null,
  inGroup: true,
  moveModuleUp: () => ({}),
  moveModuleDown: () => ({}),
})
