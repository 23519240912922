import React, { useRef, useState } from 'react'
import { useClickAway, useEvent } from 'react-use'
import CustomTooltip, { Directions } from '../../../../../_shared/Infos/CustomTooltip'
import { useHotkeysData, useOpenCategoryToolData } from '../OpenCategoryToolProvider'
import { CategoriesWithHotkeys } from '../openCategoryToolTypes'
import css from './HotkeySetup.module.scss'

type Props = {
  categoryHeader: string
  categoryValue: string
  tooltipDirection: Directions
}

const HotkeySetup = ({ categoryHeader, categoryValue, tooltipDirection }: Props) => {
  const [{ hotkeys, saveHotkeys, isHotkeySetupMaskOn, isHotkeysEnabled }, setHotkeysData] =
    useHotkeysData()
  const [{ isCategoryChangingAllowed }] = useOpenCategoryToolData()
  const [categoryTooltipInfo, setCategoryTooltipInfo] = useState<string>(
    `Set a hotkey for ${categoryValue}.\nPress DEL to remove hotkey.`,
  )
  const [isHotkeySetupActive, setIsHotkeySetupActive] = useState<boolean>(false)
  const clickAwayRef = useRef(null)

  const resetHotkeySetup = () => {
    setCategoryTooltipInfo(`Set a hotkey for ${categoryValue}.\nPress DEL to remove hotkey.`)
    setIsHotkeySetupActive(false)
  }

  const handleHotkeySetup = (event: KeyboardEvent) => {
    if (
      !isHotkeySetupActive ||
      !isHotkeySetupMaskOn ||
      !isCategoryChangingAllowed ||
      !isHotkeysEnabled
    )
      return

    const keyForSetup = event.key
    const letterNumber = /^[0-9a-zA-ZäöåÄÖÅ]+$/
    const newHotkey: CategoriesWithHotkeys = {
      category: categoryHeader,
      categoryValue: categoryValue,
      hotkey: keyForSetup,
    }
    const keyExists = hotkeys.find((hotkey) => hotkey.hotkey === keyForSetup)
    const currentHotkey = hotkeys.find(
      (hotkey) => hotkey.category === categoryHeader && hotkey.categoryValue === categoryValue,
    )

    if (keyForSetup === 'Delete') {
      const newHotkeys = hotkeys.filter((hotkey) => hotkey !== currentHotkey)
      setIsHotkeySetupActive(false)
      setHotkeysData((prev) => ({
        ...prev,
        isHotkeySetupMaskOn: false,
      }))
      saveHotkeys(newHotkeys)
      return
    }

    if (!keyForSetup.match(letterNumber) || keyForSetup.length > 1) {
      setCategoryTooltipInfo('Only letters and numbers are allowed as hotkeys!')
      return
    }

    if (!keyExists) {
      const newHotkeys = hotkeys.filter((hotkey) => hotkey !== currentHotkey)
      setHotkeysData((prev) => ({
        ...prev,
        isHotkeySetupMaskOn: false,
      }))
      saveHotkeys([...newHotkeys, newHotkey])
      resetHotkeySetup()
      return
    }

    if (keyExists) {
      if (
        keyExists.category === newHotkey.category &&
        keyExists.categoryValue === newHotkey.categoryValue
      ) {
        const newHotkeys = hotkeys.filter((hotkey) => hotkey !== keyExists)
        setHotkeysData((prev) => ({
          ...prev,
          isHotkeySetupMaskOn: false,
        }))
        saveHotkeys([...newHotkeys, newHotkey])
        resetHotkeySetup()
        return
      } else {
        setCategoryTooltipInfo(
          `Key ${keyForSetup} already exists on another category!\nUse another key.`,
        )
        return
      }
    }
  }

  useEvent('keydown', handleHotkeySetup)

  useClickAway(clickAwayRef, () => {
    if (!isHotkeySetupActive) return
    resetHotkeySetup()
    setHotkeysData((prev) => ({
      ...prev,
      isHotkeySetupMaskOn: false,
    }))
  })

  const handleHotkeySetupStart = () => {
    if (!isCategoryChangingAllowed) return
    setIsHotkeySetupActive(true)
    setHotkeysData((prev) => ({
      ...prev,
      isHotkeySetupMaskOn: true,
    }))
  }

  return (
    <>
      {isHotkeysEnabled && (
        <div
          data-testid={`openCategoryToolHotkey-${categoryValue}`}
          ref={clickAwayRef}
          className={css.set_hotkey}
          onClick={handleHotkeySetupStart}
        >
          <div className={`${isHotkeySetupActive ? css.active : ''}`}>
            {hotkeys.length > 0 &&
              hotkeys.map((hotkey, index) => {
                if (hotkey.category === categoryHeader && hotkey.categoryValue === categoryValue)
                  return <kbd key={index}>{hotkey.hotkey}</kbd>
              })}
          </div>
          <div className={css.tooltip}>
            {isHotkeySetupActive && (
              <CustomTooltip content={categoryTooltipInfo} direction={tooltipDirection} />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default HotkeySetup
