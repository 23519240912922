import { cloneDeep, isUndefined } from 'lodash'
import _ from 'underscore'

export const TREEROOT = {
  value: 'allValues',
  label: 'All',
  children: [
    // {value: "", label: "", children: []}
  ],
}
const isKpisTheSame = (kpi1, kpi2) => {
  return kpi1.id === kpi2.id && kpi1.name === kpi2.name && kpi1.grouping === kpi2.grouping
}
export function treeViewGenerator(kpisParameter) {
  if (!kpisParameter) throw new Error('ModuleService.treeViewGenerator: input is undefined')
  const clonedKpis = cloneDeep(kpisParameter)
  let resultingTree = cloneDeep(TREEROOT)
  const uniqueKpis = []
  const allKpis = clonedKpis.kpis
  for (let i = 0; i < allKpis.length; i++) {
    const kpi1 = allKpis[i]
    const isDuplicate = uniqueKpis.some((kpi2) => isKpisTheSame(kpi1, kpi2))
    if (!isDuplicate) uniqueKpis.push(kpi1)
    if (isDuplicate) console.info('duplicate kpi detected', kpi1)
  }
  clonedKpis.kpis = uniqueKpis
  clonedKpis.kpis = nullifyUndefinedGroupings(clonedKpis.kpis)
  let doGroupings = existsNonNullGroupings(clonedKpis)
  let kpis = formatKpisForTreeView(clonedKpis)
  kpis = kpis.kpis // level down to array
  if (doGroupings) {
    // group KPIs into "Other" if they have a null grouping
    for (let index in kpis) {
      if (kpis[index].grouping == null) kpis[index].grouping = 'Other'
    }
    kpis = _.groupBy(kpis, 'grouping')
    for (let groupName in kpis) {
      resultingTree.children.push({
        value: groupName,
        label: groupName,
        children: formatSubTree(kpis[groupName]),
      })
    }
  } else {
    if (Array.isArray(kpis) && Array.isArray(kpis[0])) {
      resultingTree.children = formatSubTree(kpis[0])
    } else {
      resultingTree.children = formatSubTree(kpis)
    }
  }
  // treeview plugin expects an array
  return [resultingTree]
}

export function surveyTreeViewGenerator(surveysParameter) {
  if (!surveysParameter)
    throw new Error('ModuleService.surveyTreeViewGenerator: input is undefined')
  let resultingTree = cloneDeep(TREEROOT)
  resultingTree.children = formatSubTree(surveysParameter)

  return [resultingTree]
}

function existsNonNullGroupings(kpis) {
  let result = false
  let kpiArray = kpis.kpis
  for (let i = 0; i < kpiArray.length; i++) {
    if (kpiArray[i].grouping && kpiArray[i].grouping != null && kpiArray[i].grouping !== 'Other') {
      result = true
      break
    }
  }
  return result
}

function formatKpisForTreeView(kpis) {
  let result = cloneDeep(kpis)
  flattenKpis(result)
  result = JSON.parse(
    JSON.stringify(result)
      // change each 'text' key to 'name'
      .split('"text":')
      .join('"name":'),
  )
  return result
}

function nullifyUndefinedGroupings(kpis) {
  let result = cloneDeep(kpis)
  for (let index in result) if (!result[index].grouping) result[index].grouping = null

  return result
}

function flattenKpis(kpis) {
  _.each(kpis.calculatedKpis, function (calcKpi, key) {
    // Calculated KPIs with string IDs
    if (typeof calcKpi === 'string') {
      kpis.kpis.push({
        id: calcKpi,
        text: calcKpi,
        grouping: 'Combined',
      })
    }
    // Query2 type calculated KPIs (whole object)
    if (_.isObject(calcKpi)) {
      kpis.kpis.push({
        id: key,
        text: key,
        grouping: 'Combined',
        payload: calcKpi,
      })
    }
  })

  delete kpis.calculatedKpis
}

function formatSubTree(arrayNode) {
  let result = []
  for (let index in arrayNode) {
    result.push({
      label: `${arrayNode[index].name} ${
        isUndefined(arrayNode[index].id) ? '' : ', id:' + arrayNode[index].id
      }`,
      value: JSON.stringify(arrayNode[index]),
    })
  }
  return result
}
