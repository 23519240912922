import React from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { Dashboard } from '../../../../../../types'
import { cloneDeep, set } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/CheckBoxInput.module.scss'

const TodayPlotLineCheckBox = () => {
  const [{ module, saveModule }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  if (module?.type === 'bubble') bubbleModule = module

  const plotLines = bubbleModule?.options?.x?.plotLines
  let checkedValue = false
  if (plotLines && Array.isArray(plotLines)) {
    checkedValue = !!plotLines.find((plotLine) => (plotLine.label || '').toLowerCase() === 'today')
  }

  const handleClick = () => {
    if (!bubbleModule) return
    let newPlotLines = [] as { label: string | undefined; value: number | undefined }[]
    if (checkedValue) {
      newPlotLines =
        plotLines?.filter((plotLine) => (plotLine.label || '').toLowerCase() !== 'today') || []
    }
    if (!checkedValue) {
      newPlotLines = plotLines || []
      newPlotLines.push({ label: 'Today', value: undefined })
    }
    const newModule = cloneDeep(bubbleModule)
    set(newModule, 'options.x.plotLines', newPlotLines)
    saveModule(newModule)
  }

  return (
    <div className={`${modal_css.half}`}>
      Today plotline
      <div className={`${css.item}`}>
        <input
          type='checkbox'
          id={'today-input'}
          checked={checkedValue}
          onChange={(e) => e.currentTarget.blur()}
          data-testid={'today-input'}
          name={'Today plotline enabled'}
        />
        <label
          data-testid={'today-label'}
          htmlFor={'today-input'}
          id={'today-label'}
          onClick={handleClick}
        >
          Enabled
        </label>
      </div>
    </div>
  )
}

export default TodayPlotLineCheckBox
