import React, { Component } from 'react'
import { Row, Col, Button } from 'react-materialize'
import Rodal from 'rodal'
import SelectionTree from './SelectionTree'
import { get } from '../../../react-services/apiService'
import MainButton from '../Buttons/MainButton'
import { cloneDeep } from 'lodash'

// type Survey = {
//   attributes: {[string]: string},
//   id: number,
//   name: string,
//   status: string,
// }

// type Props = {
//   surveys: Array<Survey>,
//   selectedSurveys: Array<Survey>,
// }

// type State = {
//   showModal: boolean,
//   surveys: Array<Survey>,
//   ready: boolean,
// }

export default class SelectSurveyButton extends Component {
  static defaultProps = {
    float: 'left',
  }

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      surveys: cloneDeep(this.props.surveys),
      ready: false,
    }
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
    // fetch surveys now if they weren't given as props/haven't already been loaded
    if (!this.state.surveys) this.loadSurveys()
    else if (!this.state.ready) this.setState({ ready: true })
  }

  loadSurveys() {
    get('GET_SURVEYS', { filter: 'active' }).then((surveys) => {
      this.setState({
        surveys: surveys,
        ready: true,
      })
    })
  }

  handleSurveySelection = (surveys) => {
    this.props.onSelect(surveys)
  }

  render() {
    return (
      <React.Fragment>
        <MainButton
          data-testid='surveySelectionButton'
          float={this.props.float}
          text={'Select surveys'}
          icon={'format_list_numbered'}
          iconPosition={'left'}
          onClick={this.toggleModal}
        />

        {/* Restrict rendering to make lazy loading possible */}
        {this.state.showModal && this.state.ready && (
          <div className='treeViewRodal'>
            <Rodal
              closeOnEsc
              animation='slideUp'
              visible={this.state.showModal}
              onClose={this.toggleModal}
            >
              <h5>Surveys</h5>
              <Row>
                <Col s={6}>
                  <hr />
                </Col>
                <Col s={6}></Col>
              </Row>

              <SelectionTree
                selectionType='surveys'
                surveys={this.state.surveys}
                selectedSurveys={this.props.selectedSurveys}
                onSelect={this.handleSurveySelection}
              />

              <br />
              <br />
              <Row className='center'>
                <Col s={5} offset='s1'>
                  <Button data-cy='surveyTreeViewOkButton' onClick={this.toggleModal}>
                    Ok
                  </Button>
                </Col>
                <Col s={5}>
                  <Button flat onClick={this.toggleModal}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Rodal>
          </div>
        )}
      </React.Fragment>
    )
  }
}
