import React from 'react'
import { useSettingsTools } from './../SettingsContext'
import { cloneDeep, isUndefined } from 'lodash'

import modal_css from './../SettingsModal.module.scss'

type CheckBoxItemProps = {
  settingsName: string
  id?: string
  text: string
  type?: 'text' | 'number'
  classNames?: string
  data_testid?: string
  width?: 'half' | 'full' | 'third' | 'quarter' | 'none'
  onClickLabel?: (clickedLabel: string) => void
  checked?: boolean
  isBlackAndBold?: boolean
  defaultValue?: boolean
  isRootProperty?: boolean
  customSave?: (newValue: boolean) => void
  isDisabled?: boolean
  [prop: string]: unknown
}

const CheckBoxItem = ({
  settingsName,
  checked,
  onClickLabel,
  classNames,
  width = 'half',
  data_testid,
  id = '',
  text,
  defaultValue,
  isBlackAndBold = false,
  isRootProperty,
  customSave,
  isDisabled,
}: CheckBoxItemProps) => {
  const [{ module, saveModule }] = useSettingsTools()
  let checkedValue = isUndefined(defaultValue) ? false : defaultValue
  if (settingsName && module && module.options && !isRootProperty) {
    checkedValue =
      typeof module.options[settingsName as keyof unknown] === 'boolean'
        ? module.options[settingsName as keyof unknown]
        : !!defaultValue
  }

  if (settingsName && module && isRootProperty) {
    checkedValue =
      typeof module[settingsName as keyof unknown] === 'boolean'
        ? module[settingsName as keyof unknown]
        : !!defaultValue
  }
  if (checked !== undefined) {
    checkedValue = checked
  }

  const getWidthClass = () => {
    if (width === 'half') {
      return modal_css.half
    }
    if (width === 'full') {
      return modal_css.max
    }
    if (width === 'third') {
      return modal_css.third
    }
    if (width === 'quarter') {
      return modal_css.quarter
    }
    if (width === 'none') return ''
    return modal_css.half
  }

  const handleClick = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (isDisabled) return
    const labelName = e.currentTarget.innerHTML
    if (onClickLabel) {
      onClickLabel(labelName)
    } else {
      try {
        if (!module) return
        if (isRootProperty) {
          const currentValue = !!module[settingsName as keyof unknown]
          if (customSave) return customSave(!currentValue)
          const newModule = {
            ...module,
            [settingsName as keyof unknown]: !currentValue,
          }
          saveModule(newModule)
        }
        if (!isRootProperty) {
          const currentValue = !!(module.options && module.options[settingsName as keyof unknown])
          if (customSave) return customSave(!currentValue)
          const currentOptions = module.options || {}
          const newModule =cloneDeep(module)
          newModule.options = { ...currentOptions, ...{ [settingsName]: !currentValue } }
          saveModule(newModule)
        }
      } catch (error) {
        // TODO: add toast
      }
    }
  }
  return (
    <div
      className={`${classNames} ${getWidthClass()}`}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <input
        type='checkbox'
        id={`${id}-input`}
        checked={checkedValue}
        onChange={(e) => e.currentTarget.blur()}
        data-testid={`${data_testid}-input`}
        name={text}
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      />
      <label
        data-testid={`${data_testid}-label`}
        htmlFor={`${id}-input`}
        id={`${id}-label`}
        onClick={handleClick}
        style={{
          ...(isBlackAndBold ? { color: 'black', fontWeight: 'bold' } : {}),
          ...(isDisabled ? { cursor: 'not-allowed' } : {}),
        }}
      >
        {text}
      </label>
    </div>
  )
}

export default CheckBoxItem
