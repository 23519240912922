import React from 'react';
import GenericButton from '../../../../common/Buttons/GenericButton';
import css from './ButtonRelatedAlertsToggle.module.scss';

type ButtonRelatedAlertsToggleProps = {
  onClick: () => void,
}

const ButtonRelatedAlertsToggle = ({
  onClick
}:ButtonRelatedAlertsToggleProps) => {
  return (
    <GenericButton
      onClick={onClick}
      icon={'breaking_news_alt_1'}
      text=""
      customClasses={[css.btn]}
      iconPosition={'center'}
      data-testid="alert-related-button"
      title="Open related alerts dialog"
    />
  )
}

export default ButtonRelatedAlertsToggle;