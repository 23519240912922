// For testing stability
export const errorFetchMetadataKeys = 'alertService: Error fetching allowed metadata keys!'
export const errorAssignAlert = 'alertService: Something went wrong during assignment!'
export const errorSaveAlert = 'alertService: Error when trying to save alert data!'
export const errorGetAlertConfig = 'alertService: Error with alert configuration!'
export const errorGetAllAlerts = 'alertService: Error getting alerts list!'
export const errorGetAllUsers = 'alertService: Error getting users list!'
export const errorUpdateMeta = 'alertService: Error updating meta value'
export const errorParsingAssignId = 'Could not assign the user, there was a problem with id'
export const successAlertClose = 'Alert closed succesfully'
export const successAlertOpen = 'Alert opened succesfully'
export const successAlertInProgress = "Alert state changed to 'in progress' succesfully"
export const successMessageSent = 'Message saved succesfully'
