import React, { useMemo, useState } from 'react'
import { useSummaryData } from '../Context/SummaryDataContext'
import { SummaryItem } from '../summaryTypes'
import useSummaryLanguage from '../useSummaryLanguage'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import { allChartColors } from '../../../../../../styles/variableExport'
import useOpenAnswerCounts from '../../../../../stores/useOpenAnswerCounts'
import useAnswerCountQuery from '../useAnswerCountQuery'
import { OpenAnswersCountsQuery } from '../../Pietabular/pietabularTypes'
import { cloneDeep } from 'lodash'
import { OpenAnswersQuery } from '../../Pietabular/PietabularModuleContext'
import useOpenKpis from '../../../../../stores/useOpenKpis'
import OpenInspectorCntr from '../../_shared/ValueInspector/Open/OpenInspectorCntr'

import css from './Summaries.module.scss'
import objectHash from 'object-hash'

export type SummaryItemProps = {
  summary: SummaryItem
}

const Summary = ({ summary }: SummaryItemProps) => {
  const [{ summarySettings, language }] = useSummaryFilters()
  const [{ saveSettings }] = useSummaryData()
  const [inspectorQuery, setInspectorQuery] = useState<OpenAnswersQuery | null>(null)
  const { openKpis } = useOpenKpis()
  const [{ summaryTypes }] = useSummaryData()
  const { t } = useSummaryLanguage(language)

  const useAllMetaFilters = true
  const { countsQuery } = useAnswerCountQuery(useAllMetaFilters)

  const countsQueryHash = objectHash(countsQuery, { unorderedArrays: true, unorderedObjects: true })
  const answerCountsQuery: OpenAnswersCountsQuery | null = useMemo(() => {
    if (!countsQuery) return null
    const newQuery = cloneDeep(countsQuery) as OpenAnswersCountsQuery
    newQuery.group_by = []
    return newQuery
  }, [countsQueryHash])

  const { counts } = useOpenAnswerCounts(answerCountsQuery)
  const answerCount = counts?.results?.[0]?.count || 0

  const summaryType = useMemo(() => {
    return summaryTypes.find((type) => type.angle === summary.angle)
  }, [summaryTypes, summary.angle])

  const handleClickOpenInspector = () => {
    if (!answerCountsQuery || !openKpis) return
    const queryKpis = openKpis.filter((kpis) => answerCountsQuery.kpis?.includes(kpis.id))
    setInspectorQuery({
      start_date: answerCountsQuery.start_date,
      end_date: answerCountsQuery.end_date,
      where_meta: answerCountsQuery.where_meta || {},
      kpis: queryKpis,
    })
  }
  const handleCloseInspector = () => setInspectorQuery(null)

  const handleClickCloseView = () => {
    if (!summary.angle || !summarySettings.filterSummaryTypes || !saveSettings) return
    const newSettings = {
      ...summarySettings,
      filterSummaryTypes: summarySettings.filterSummaryTypes.filter(
        (filter) => filter.angle !== summary.angle,
      ),
    }
    saveSettings(newSettings)
  }

  const parsedSummary = summary.summary.split('\n').map((txt) => txt.replace('- ', ''))
  return (
    <>
      {inspectorQuery && (
        <OpenInspectorCntr
          onClose={handleCloseInspector}
          query={inspectorQuery}
          inspected={`${summary.angle}`}
        />
      )}
      <div
        className={css.sum_item}
        style={{
          backgroundColor: 'rgb(255, 255, 255)',
          borderBottom: `5px solid ${summaryType?.color || allChartColors.GREY}`,
          borderLeft: `1px solid ${summaryType?.color || allChartColors.GREY}`,
          borderRight: `1px solid ${summaryType?.color || allChartColors.GREY}`,
        }}
      >
        <h6
          style={{ backgroundColor: summaryType?.color || allChartColors.GREY }}
          className={css.header}
        >
          <div className={css.headerLeft}>
            <i className='material-icons'>{summaryType?.icon}</i>
            <div className={css.headerTexts}>
              <div className={css.summaryName}>{t(summaryType?.name || '')}</div>
              <div className={css.answerCount}>
                {!!answerCount && <>{` (${answerCount} ${t('vastausta')})`}</>}
              </div>
            </div>
          </div>
          <div className={css.headerRight}>
            <div className={css.actionBtn} onClick={handleClickOpenInspector}>
              <i className='material-icons'>list</i>
            </div>
            <div className={css.actionBtn} onClick={handleClickCloseView}>
              <i className='material-icons'>close</i>
            </div>
          </div>
        </h6>
        <ul className={css.text}>
          {parsedSummary.map((txt, index) => {
            if (txt && txt.trim()) {
              return <li key={index}>{txt}</li>
            } else {
              return <div key={index}></div>
            }
          })}
        </ul>
      </div>
    </>
  )
}

export default Summary
