import React from 'react'
import SelectViewItem from './SelectViewItem'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import useSummaryLanguage from '../useSummaryLanguage'
import { useSummaryData } from '../Context/SummaryDataContext'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoPerspectivesFI } from '../SummaryNotifications'

import css from './SummaryFilters.module.scss'

const SelectView = () => {
  const [{ language }] = useSummaryFilters()
  const [{ summaryTypes }] = useSummaryData()
  const { t } = useSummaryLanguage(language)
  return (
    <div className={css.filter_view} data-testid='filter-views'>
      <div className={`${css.header} ${css.perspectivesHeader}`}>
        <i className='material-icons'>visibility</i>&nbsp;
        {
          <TextWithInfo
            text={t('Näkökulmat')}
            tooltipDirection='right'
            info={t(infoPerspectivesFI)}
          />
        }
      </div>
      <div className={css.values}>
        {summaryTypes.map((view) => (
          <SelectViewItem key={view.angle} view={view} />
        ))}
      </div>
    </div>
  )
}

export default SelectView
