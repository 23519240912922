import {
  ConversationsFilter,
  ConversationType,
  MessagePayload,
  MessagesFilter,
  MessagesUpdate,
  NewConversation,
} from '../components/Dashboards/DashboardModules/_shared/ConversationModal/conversationTypes'
import { errorGettingConversationsConfig } from '../stores/notificationMessages'
import { isConversationObject } from '../utilities'
import { get, getUserFromToken, post, put } from './apiService'
import { getGenericConfiguration } from './configService'

export type ConversationsConfig = {
  is_conversations_enabled?: boolean
  conversation_emails?: string[]
  show_conversation_alert?: boolean
  show_conversation_open?: boolean
  is_live_alert_conversations_enabled?: boolean
} | null

const getConversationsConfig = () => {
  try {
    const conversation_conf = getGenericConfiguration('conversations')

    if (conversation_conf) {
      return conversation_conf
        .then((res) => {
          if (typeof res === 'object') return res as ConversationsConfig
          else throw new Error()
        })
        .catch(() => Promise.reject(errorGettingConversationsConfig))
    } else {
      return Promise.reject(errorGettingConversationsConfig)
    }
  } catch (e) {
    return Promise.reject(errorGettingConversationsConfig)
  }
}

const getConversations = (completedSurveyId: number) => {
  try {
    return get('GET_CONVERSATIONS', { completedSurveyId })
      .then((res) => res)
      .catch(() => Promise.reject('Could not fetch conversations...'))
  } catch (e) {
    return Promise.reject('Could not fetch conversations...')
  }
}

const createNewConversation = (newConversation: NewConversation) => {
  try {
    return (
      post('POST_CONVERSATION', newConversation)
        .then((res: unknown) => {
          if (!res) return Promise.reject('Response is empty, something went wrong...')
          if (!isConversationObject(res))
            return Promise.reject('Response is not a valid conversation object!')
          return res
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) =>
          typeof err === 'string'
            ? Promise.reject(err)
            : Promise.reject('Could not post a new conversation...'),
        )
    )
  } catch (e) {
    return Promise.reject('Could not post a new conversation...')
  }
}

const getFilteredConversations = (conversationsFilter: ConversationsFilter) => {
  try {
    return (
      post('POST_FILTERED_CONVERSATIONS', conversationsFilter)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => res)
        .catch(() => Promise.reject('Could not fetch conversations...'))
    )
  } catch (e) {
    return Promise.reject('Could not fetch conversations...')
  }
}

const getFilteredMessages = (messagesFilter: MessagesFilter) => {
  try {
    return (
      post('POST_FILTERED_MESSAGES', messagesFilter)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => res)
        .catch(() => Promise.reject('Could not fetch messages...'))
    )
  } catch (e) {
    return Promise.reject('Could not fetch messages...')
  }
}

const createMessagePayLoad = (
  message: string,
  sendTo: string,
  conversationId: string,
  type: ConversationType,
) => {
  const user = getUserFromToken()
  if (!user) {
    throw new Error('No user information found from storage, try logging in again')
  }
  // alert.metadata['email'].toString() ||
  // email is hardcoded for testing, do not make dynamic for testing or you might send emails to people
  const payload = {
    messageType: type,
    commentText: message,
    clientContactInformation: type === ConversationType.EMAIL ? sendTo : '',
    wheelqUserContactInformation: '',
    conversationId: conversationId,
    additionalRecipients: '',
    hiddenAdditionalRecipients: '',
  }
  return payload
}

const postOneMessage = (messagePayload: MessagePayload) => {
  try {
    return (
      post('POST_CONVERSATION_MESSAGE', messagePayload)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => res)
        .catch(() => Promise.reject('There was an error saving the message...'))
    )
  } catch (e) {
    return Promise.reject('There was an error saving the message...')
  }
}

const updateMessageStates = (messagesUpdate: MessagesUpdate) => {
  try {
    return (
      put('PUT_UPDATED_MESSAGES', messagesUpdate)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => res)
        .catch(() => Promise.reject('There was an error updating message states...'))
    )
  } catch (e) {
    return Promise.reject('There was an error updating message states...')
  }
}

export {
  getConversationsConfig,
  getConversations,
  createNewConversation,
  getFilteredConversations,
  getFilteredMessages,
  createMessagePayLoad,
  postOneMessage,
  updateMessageStates,
}
