import React, { useEffect } from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { useUpdateEffect } from 'react-use'
import { cloneDeep, debounce, set } from 'lodash'
import { Dashboard } from '../../../../../../types'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'

type AxisUnitInputProps = {
  axis: 'y' | 'z'
}
const AxisUnitInput = ({ axis }: AxisUnitInputProps) => {
  const [{ module, saveModule }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  if (module?.type === 'bubble') bubbleModule = module
  const savedValue = bubbleModule?.options?.[axis]?.unit || ''
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    if (!bubbleModule) return
    setInputValue(savedValue || '')
  }, [])

  const SAVE_TIMEOUT = 1000
  useUpdateEffect(() => {
    if (!bubbleModule) return
    if (savedValue === inputValue) return
    const debouncer = debounce(() => {
      const newModule = cloneDeep(bubbleModule)
      if (!newModule) return
      set(newModule, 'options.' + axis + '.unit', inputValue)
      saveModule(newModule)
    }, SAVE_TIMEOUT)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [inputValue])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }
  return (
    <div className={modal_css.half}>
      Y axis unit
      <input
        data-testid={'yaxis-unit-input'}
        type={'text'}
        placeholder={'Unit'}
        value={inputValue || ''}
        id={'yaxis-unit-input'}
        onChange={handleOnChange}
        className={`${css.input}`}
      />
    </div>
  )
}

export default AxisUnitInput
