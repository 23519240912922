import React, { useEffect, useState } from 'react'
import FilterTab from './FilterTab'
import { DefaultTabOptions, FilterTabOptions, OpenModule } from '../openTypes'
import OpenInspectorCntr from '../../_shared/ValueInspector/Open/OpenInspectorCntr'
import { OpenAnswersRequest, OpenAnswersResponse } from '../../../../../stores/useNewOpenAnswers'
import PhraseSearch from './PhraseSearch'
import CategorySelection from './CategorySelection'
import KpiSelection from './KpiSelection'
import useOpenCategories, { OpenAnswerCategories } from '../../../../../stores/useOpenCategories'
import useOpenKpis from '../../../../../stores/useOpenKpis'
import MessageStatusSelection from './MessageStatusSelection'
import { MessageState } from '../../_shared/ConversationModal/conversationTypes'
import { OpenAnswer } from '../../Pietabular/pietabularTypes'

import css from './FilterContainer.module.scss'
import { useTranslation } from 'react-i18next'
import { tCategory } from '../../../../../../languages/i18n'

type FilterContainerProps = {
  query: OpenAnswersRequest | null
  customCategoryNames: string[] | null
  module: OpenModule
  saveModule: (module: OpenModule) => void
  pagination: OpenAnswersResponse['pageMetadata'] | null
  loadedAnswers?: number
  showBar: boolean
  settingsKpis: { name: string; id: number }[] | null
  conversationEnabled: boolean
  filterAnswersBySelectedValues: (data: OpenAnswer[]) => OpenAnswer[]
}

const FilterContainer = ({
  query,
  customCategoryNames,
  module,
  saveModule,
  pagination,
  loadedAnswers,
  showBar,
  settingsKpis,
  conversationEnabled,
  filterAnswersBySelectedValues,
}: FilterContainerProps) => {
  const [selectedFilterTab, setSelectedFilterTab] = useState<FilterTabOptions | null>(null)
  const { answerCategories } = useOpenCategories()
  const { openKpis } = useOpenKpis()
  const { t } = useTranslation()

  useEffect(() => {
    if (!showBar && selectedFilterTab !== DefaultTabOptions.INSPECTOR) setSelectedFilterTab(null)
  }, [showBar])

  const handleClickRadioTab = (button: FilterTabOptions) => {
    if (button === DefaultTabOptions.TOOLS) {
      return saveModule({
        ...module,
        options: { ...(module.options || {}), showTools: !module.options?.showTools },
      })
    }
    setSelectedFilterTab((prev) => {
      if (prev === button) return null
      else return button
    })
  }

  const handleCloseExpancedSearch = () => setSelectedFilterTab(null)

  const isSearchExpanded =
    selectedFilterTab === DefaultTabOptions.SENTIMENT ||
    selectedFilterTab === DefaultTabOptions.TOPIC ||
    selectedFilterTab === DefaultTabOptions.SEARCH ||
    selectedFilterTab === DefaultTabOptions.MESSAGE ||
    selectedFilterTab === DefaultTabOptions.KPIS ||
    selectedFilterTab?.includes(DefaultTabOptions.CUSTOM)

  return (
    <>
      <div
        className={`${css.info} ${
          showBar || selectedFilterTab === DefaultTabOptions.INSPECTOR ? '' : css.hide
        }`}
        data-testid='filtersCntr'
      >
        <div className={`${css.radioTabs}`}>
          {!!answerCategories && !!answerCategories.sentiment?.length && (
            <FilterTab
              text={tCategory('Sentiment')}
              selected={selectedFilterTab === DefaultTabOptions.SENTIMENT}
              onClick={() => handleClickRadioTab(DefaultTabOptions.SENTIMENT)}
              isFilterActive={
                !!module &&
                !!module.options &&
                !!module.options.sentimentCategoryFilter &&
                module.options.sentimentCategoryFilter.length < 4
              }
            />
          )}
          {!!answerCategories && !!answerCategories.topic?.length && (
            <FilterTab
              text={tCategory('Topic')}
              selected={selectedFilterTab === DefaultTabOptions.TOPIC}
              onClick={() => handleClickRadioTab(DefaultTabOptions.TOPIC)}
              isFilterActive={
                !!module &&
                !!module.options &&
                !!module.options.topicCategoryFilter &&
                module.options.topicCategoryFilter.length < answerCategories.topic.length + 1
              }
            />
          )}
          {customCategoryNames &&
            customCategoryNames.map((category) => (
              <FilterTab
                key={category}
                text={category.substring(0, 1).toUpperCase().concat(category.slice(1))}
                selected={selectedFilterTab === `${DefaultTabOptions.CUSTOM}${category}`}
                onClick={() => handleClickRadioTab(`${DefaultTabOptions.CUSTOM}${category}`)}
                isFilterActive={
                  (((module || {}).options || {})[`${category}CategoryFilter`] || []).length <
                  ((answerCategories || ({} as OpenAnswerCategories))[category] || []).length + 1
                }
              />
            ))}

          <FilterTab
            text={t('common.button.question')}
            selected={selectedFilterTab === DefaultTabOptions.KPIS}
            onClick={() => handleClickRadioTab(DefaultTabOptions.KPIS)}
            isFilterActive={
              !!module &&
              !!module.query &&
              !!module.query.kpis &&
              !!module.query.kpis.length &&
              !!openKpis &&
              module.query.kpis.length < openKpis.length
            }
          />
          {conversationEnabled && (
            <FilterTab
              text='Message'
              selected={selectedFilterTab === DefaultTabOptions.MESSAGE}
              onClick={() => handleClickRadioTab(DefaultTabOptions.MESSAGE)}
              isFilterActive={
                !!module &&
                !!module.options &&
                !!module.options.messageFilter &&
                module.options.messageFilter.length < Object.keys(MessageState).length
              }
            />
          )}

          <FilterTab
            text={<i className='material-icons module-icon-color waves-effect'>list</i>}
            selected={selectedFilterTab === DefaultTabOptions.INSPECTOR}
            onClick={() => query && handleClickRadioTab(DefaultTabOptions.INSPECTOR)}
            isFilterActive={false}
          />
          <FilterTab
            text={<i className='material-icons module-icon-color waves-effect'>search</i>}
            selected={selectedFilterTab === DefaultTabOptions.SEARCH}
            onClick={() => handleClickRadioTab(DefaultTabOptions.SEARCH)}
            isFilterActive={!!module.searchFilter}
          />
          <FilterTab
            text={
              <i className='material-icons module-icon-color waves-effect'>dashboard_customize</i>
            }
            selected={!!module.options?.showTools}
            onClick={() => handleClickRadioTab(DefaultTabOptions.TOOLS)}
            isFilterActive={false}
          />
        </div>
        {pagination &&
          !isNaN(pagination.total_elements) &&
          !!pagination.total_elements &&
          loadedAnswers !== undefined &&
          !isNaN(loadedAnswers) && (
            <span className={css.totalAndLoaded} data-testid='loadedText'>
              {t('common.label.loaded')}:{' '}
              {((loadedAnswers / pagination.total_elements) * 100).toFixed(0)} %,{' '}
              {t('common.label.total')}: {loadedAnswers}/{pagination.total_elements}
            </span>
          )}
      </div>
      {selectedFilterTab === DefaultTabOptions.INSPECTOR && query && (
        <OpenInspectorCntr
          onClose={() => setSelectedFilterTab(null)}
          query={query}
          inspected={`${module.searchFilter ? `Search text: "${module.searchFilter}"` : ''}`}
          customFilterAnswersFunction={filterAnswersBySelectedValues}
        />
      )}
      <div className={isSearchExpanded ? css.filterBox : css.emptyBox}>
        {selectedFilterTab === DefaultTabOptions.SEARCH && (
          <PhraseSearch
            module={module}
            saveModule={saveModule}
            handleClose={handleCloseExpancedSearch}
          />
        )}
        {selectedFilterTab === DefaultTabOptions.SENTIMENT && (
          <CategorySelection
            category='sentiment'
            module={module}
            saveModule={saveModule}
            handleClose={handleCloseExpancedSearch}
          />
        )}
        {selectedFilterTab === DefaultTabOptions.TOPIC && (
          <CategorySelection
            category='topic'
            module={module}
            saveModule={saveModule}
            handleClose={handleCloseExpancedSearch}
          />
        )}
        {selectedFilterTab?.includes(DefaultTabOptions.CUSTOM) && (
          <CategorySelection
            category={selectedFilterTab.substring(7)}
            module={module}
            saveModule={saveModule}
            handleClose={handleCloseExpancedSearch}
          />
        )}
        {selectedFilterTab === DefaultTabOptions.KPIS && (
          <KpiSelection
            module={module}
            saveModule={saveModule}
            settingsKpis={settingsKpis}
            handleClose={handleCloseExpancedSearch}
          />
        )}
        {selectedFilterTab === DefaultTabOptions.MESSAGE && (
          <MessageStatusSelection saveModule={saveModule} module={module} />
        )}
      </div>
    </>
  )
}

export default FilterContainer
