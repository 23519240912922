import { isUndefined } from 'lodash'
import { Languages, SummaryTimePeriod, TimeFrame } from './summaryTypes'

const useSummariesTimeFrame = (
  filterTimeFrame: TimeFrame | undefined,
  filterTimePeriod: SummaryTimePeriod | undefined,
) => {
  const { year, timeGroupIndex } = filterTimeFrame || { year: undefined, timeGroupIndex: undefined }
  const hasTimeFrame = year !== undefined && timeGroupIndex !== undefined

  const getMonthFirstDay = () => {
    if (!hasTimeFrame) return null
    return new Date(Date.UTC(year, timeGroupIndex, 1))
  }
  const getMonthLastDay = () => {
    if (!hasTimeFrame) return null
    return new Date(Date.UTC(year, timeGroupIndex + 1, 0))
  }
  const getQuarterFirstDay = () => {
    if (!hasTimeFrame) return null
    const firstMonthOfQuarter = timeGroupIndex * 3
    return new Date(Date.UTC(year, firstMonthOfQuarter, 1))
  }
  const getQuarterLastDay = () => {
    if (!hasTimeFrame) return null
    const lastMonthOfQuarter = timeGroupIndex * 3 + 2
    return new Date(Date.UTC(year, lastMonthOfQuarter + 1, 0))
  }
  const getHalfYearFirstDay = () => {
    if (!hasTimeFrame) return null
    const firstMonthOfHalfYear = timeGroupIndex * 6
    return new Date(Date.UTC(year, firstMonthOfHalfYear, 1))
  }
  const getHalfYearLastDay = () => {
    if (!hasTimeFrame) return null
    const lastMonthOfHalfYear = timeGroupIndex * 6 + 5
    return new Date(Date.UTC(year, lastMonthOfHalfYear + 1, 0))
  }

  const getTimeGroupLastIndex = (selectedTimePeriod: SummaryTimePeriod) => {
    return selectedTimePeriod === SummaryTimePeriod.MONTH
      ? 11
      : selectedTimePeriod === SummaryTimePeriod.QUARTER
      ? 3
      : selectedTimePeriod === SummaryTimePeriod.HALF_YEAR
      ? 1
      : 51
  }

  const getTimeGroupLastValue = (selectedTimePeriod: SummaryTimePeriod, language: Languages) => {
    return selectedTimePeriod === SummaryTimePeriod.MONTH
      ? language === Languages.EN
        ? 'December'
        : 'Joulukuu'
      : selectedTimePeriod === SummaryTimePeriod.QUARTER
      ? 'Q4'
      : selectedTimePeriod === SummaryTimePeriod.HALF_YEAR
      ? 'H2'
      : 'W52'
  }

  const getStartAndEndDates = () => {
    if (
      !filterTimeFrame ||
      isUndefined(filterTimeFrame.timeGroupIndex) ||
      isUndefined(filterTimeFrame.year)
    )
      return null
    let startDate: Date | null = null
    let endDate: Date | null = null
    if (filterTimePeriod === SummaryTimePeriod.MONTH) {
      startDate = getMonthFirstDay()
      endDate = getMonthLastDay()
    }
    if (filterTimePeriod === SummaryTimePeriod.QUARTER) {
      startDate = getQuarterFirstDay()
      endDate = getQuarterLastDay()
    }
    if (filterTimePeriod === SummaryTimePeriod.HALF_YEAR) {
      startDate = getHalfYearFirstDay()
      endDate = getHalfYearLastDay()
    }
    if (startDate && endDate) {
      return {
        startDate: startDate.toISOString().substring(0, 10),
        endDate: endDate.toISOString().substring(0, 10),
      }
    }
  }
  return {
    getMonthFirstDay,
    getMonthLastDay,
    getQuarterFirstDay,
    getQuarterLastDay,
    getHalfYearFirstDay,
    getHalfYearLastDay,
    getTimeGroupLastIndex,
    getTimeGroupLastValue,
    getStartAndEndDates,
  }
}

export default useSummariesTimeFrame
