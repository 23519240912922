import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useMetaKeys from '../../../stores/useMetaKeys'
import LoadingIndicator from '../../_shared/Infos/LoadingIndicator'
import { ADMIN_TOOLS_TOAST_ID } from '../AdminTools'
import { MetaAndPosition } from './inspectorTypes'
import TabContainer from './SubTabComponents/Wrappers/TabContainer.'
import TabHeading from './SubTabComponents/TabHeading'
import SelectableTableCntr from './SubTabComponents/Wrappers/SelectableTableCntr'
import SelectedTableCntr from './SubTabComponents/Wrappers/SelectedTableCntr'
import TabTablesCntr from './SubTabComponents/Wrappers/TabTablesCntr'
import SelectableTable from './SubTabComponents/SelectableTable'
import SelectedTable from './SubTabComponents/SelectedTable'
import { useShallowCompareEffect } from 'react-use'
import {
  confirmationModalInfo,
  confirmationModalTitle,
  openTabInfoPart1,
  openTabInfoPart2,
  openTabInfoPart3,
  openTabInfoPart4,
  openTabInfoPart5,
  openTabInfoPart6,
  openTabTitle,
  placeholderStringForPreview,
  previewModalInfo,
  selectableMetasTitle,
  selectedMetasTitle,
  successUpdatingConfigInDb,
} from './notifications'
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal'
import OpenInspectorCntr from '../../Dashboards/DashboardModules/_shared/ValueInspector/Open/OpenInspectorCntr'
import { TextualKpiData } from '../../Dashboards/DashboardModules/Open/openTypes'
import useCommonDbSettingsConfig from '../../../stores/useCommonDbSettingsConfig'

const OpenInspector = () => {
  const { filteredMetaKeys, error: metaKeysError, loading: loadingMetakeys } = useMetaKeys()
  const {
    config,
    error: commonDbSettingsError,
    isLoading: loadingCommonDbSettings,
    updateConfig,
  } = useCommonDbSettingsConfig()
  const configMetas = config?.inspector_open_multi?.meta_columns

  const [selectableMetaKeys, setSelectableMetaKeys] = useState<string[] | null>(null)
  const [selectedMetaKeys, setSelectedMetaKeys] = useState<MetaAndPosition[]>([])
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false)
  const [previewData, setPreviewData] = useState<TextualKpiData[]>([] as TextualKpiData[])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  const isLoading = loadingMetakeys || loadingCommonDbSettings
  const emptyArray = [] as TextualKpiData[]

  useEffect(() => {
    if (metaKeysError) toast.error(metaKeysError, { containerId: ADMIN_TOOLS_TOAST_ID })
  }, [metaKeysError])

  useEffect(() => {
    if (commonDbSettingsError)
      toast.error(commonDbSettingsError, { containerId: ADMIN_TOOLS_TOAST_ID })
  }, [commonDbSettingsError])

  useEffect(() => {
    if (config && !config.inspector_open_multi)
      updateConfig({ value: { inspector_open_multi: { meta_columns: [] } } })
  }, [config])

  useEffect(() => {
    if (!configMetas) return
    const selectedKeysAndPositions = configMetas
    if (!selectedKeysAndPositions) return
    setPreviewData(createPreviewData(selectedKeysAndPositions))
    setSelectedMetaKeys(selectedKeysAndPositions)
  }, [configMetas])

  useEffect(() => {
    if (!filteredMetaKeys.length) return
    if (!selectedMetaKeys.length) {
      setSelectableMetaKeys(filteredMetaKeys.sort())
      setPreviewData(createPreviewData([]))
    } else {
      const newFilteredMetaKeys = filteredMetaKeys.filter(
        (metaKey) =>
          !selectedMetaKeys.map((keyAndPosition) => keyAndPosition.name).includes(metaKey),
      )
      setSelectableMetaKeys(newFilteredMetaKeys)
      setPreviewData(createPreviewData(selectedMetaKeys))
    }
  }, [filteredMetaKeys, selectedMetaKeys])

  useShallowCompareEffect(() => {
    if (selectedMetaKeys.length) setPreviewData(createPreviewData(selectedMetaKeys))
    if (selectableMetaKeys && !selectableMetaKeys.length) setPreviewData(emptyArray)
  }, [selectedMetaKeys || []])

  const createPreviewData = (metas: MetaAndPosition[]): TextualKpiData[] => {
    const shortString = placeholderStringForPreview.slice(0, 12)
    const mediumString = placeholderStringForPreview.slice(0, 26)
    let longString = placeholderStringForPreview.slice(0, 100)
    const previewData = [] as TextualKpiData[]
    for (let i = 0; i < 5; i++) {
      const answer = {} as TextualKpiData
      answer.id = i
      answer.answer_id = i
      answer.answer = longString
      longString += longString
      answer.completed_survey_id = i
      answer.date = shortString + i
      answer.metadata = metas
        .map((meta) => meta.name)
        .reduce((acc, curr) => ({ ...acc, [curr]: mediumString + ' ' + i }), {})
      previewData.push(answer)
    }
    return previewData
  }

  const handleOpenPreviewModal = () => setIsPreviewModalOpen(true)

  const handleClosePreviewModal = () => setIsPreviewModalOpen(false)

  const handleSaveButtonClick = () => setIsConfirmationModalOpen(true)

  const handleSave = () => {
    setIsConfirmationModalOpen(false)
    updateConfig({ value: config })
      .then(() => toast.success(successUpdatingConfigInDb, { containerId: ADMIN_TOOLS_TOAST_ID }))
      .catch(() => ({}))
  }

  return (
    <TabContainer>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          confirm='Save'
          cancel='Cancel'
          title={confirmationModalTitle}
          info={confirmationModalInfo}
          handleConfirmation={handleSave}
          handleCancel={() => setIsConfirmationModalOpen(false)}
        />
      )}
      {isPreviewModalOpen && (
        <OpenInspectorCntr
          onClose={handleClosePreviewModal}
          inspected={previewModalInfo}
          previewData={previewData}
          isPreviewMode={true}
          answer={previewData[0]}
        />
      )}
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <TabHeading
            title={openTabTitle}
            info={
              openTabInfoPart1 +
              openTabInfoPart2 +
              openTabInfoPart3 +
              openTabInfoPart4 +
              openTabInfoPart5 +
              openTabInfoPart6
            }
            onClickPreview={handleOpenPreviewModal}
            onClickSave={handleSaveButtonClick}
          />
          <TabTablesCntr>
            <SelectableTableCntr>
              <SelectableTable
                header={selectableMetasTitle}
                metas={selectableMetaKeys}
                setSelectableMetaKeys={setSelectableMetaKeys}
                setSelectedMetaKeys={setSelectedMetaKeys}
              />
            </SelectableTableCntr>
            <SelectedTableCntr>
              <SelectedTable
                header={selectedMetasTitle}
                metasWithPosition={selectedMetaKeys}
                setSelectableMetaKeys={setSelectableMetaKeys}
                setSelectedMetaKeys={setSelectedMetaKeys}
              />
            </SelectedTableCntr>
          </TabTablesCntr>
        </>
      )}
    </TabContainer>
  )
}

export default OpenInspector
