import React, { useEffect, useRef, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import useNumericKpis from '../../../../../stores/useNumericKpis'
import { isEqual, isUndefined } from 'lodash'
import { TextBoxModule } from '../textBoxTypes'
import useOpenKpis from '../../../../../stores/useOpenKpis'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

type Selections = { [id: string]: boolean }

const KpiSelectionTreeData = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const textboxModule = module as TextBoxModule
  const { numericKpis } = useNumericKpis()
  const { openKpis } = useOpenKpis()
  const allKpis = [...(numericKpis || []), ...(openKpis || [])]

  useEffect(() => {
    if (!numericKpis || !openKpis) return
    let selectionSettings = [] as (number | string)[]
    if (
      textboxModule &&
      textboxModule.options?.selections &&
      typeof textboxModule.options?.selections === 'object'
    ) {
      selectionSettings = Object.keys(textboxModule.options?.selections)
    }
    const kpiSelection = [] as kpiIdAndName[]
    if (allKpis) {
      selectionSettings.forEach((kpi) => {
        if (isNaN(+kpi)) {
          kpiSelection.push({ id: undefined, name: kpi.toString() })
        } else {
          const matchingKpi = allKpis.find((numericKpi) => +numericKpi.id === +kpi)
          if (matchingKpi) kpiSelection.push({ id: matchingKpi.id, name: matchingKpi.name })
        }
      })
    }
    setSelectedKpis(kpiSelection)
  }, [numericKpis, openKpis])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const handleSaveNewSelection = (newSelection: kpiIdAndName[]) => {
    try {
      if (!textboxModule) return
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        const selectionObject = {} as Selections
        newSelection.forEach((kpi) => {
          if (isUndefined(kpi.id)) selectionObject[kpi.name] = true
          else selectionObject[kpi.id] = true
        })
        const newModule = { ...textboxModule }
        if (!newModule.options) newModule.options = {}
        newModule.options.selections = selectionObject
        if (isEqual(newModule, textboxModule)) return
        saveModule(newModule)
      }, 500)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.all}
          excludeCalculatedKpis
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
