import React from 'react'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { topBotColumnsInfo } from '../TopBottomNotifications'
import { TopBottomColumnNames, TopBottomModule, TopBottomModuleSettings } from '../topBottomTypes'

import css from './TopBottomSettings.module.scss'

const SelectTopBottomColumns = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const topbotModule = module as TopBottomModule
  const options = module?.options ? (module.options as TopBottomModuleSettings) : null
  const columnNames = Object.entries(TopBottomColumnNames).map((column) => column[1])
  const selectedColumns = options?.selectedColumns || []

  const handleClick = (columnName: string) => {
    if (!module) return
    let newColumns = [] as string[]
    if (selectedColumns.includes(columnName)) {
      newColumns = selectedColumns.filter((column) => column !== columnName)
    }
    if (!selectedColumns.includes(columnName)) {
      newColumns = selectedColumns.concat(columnName)
    }
    saveModule({
      ...topbotModule,
      options: { ...topbotModule.options, selectedColumns: newColumns },
    })
  }

  return (
    <div style={{ width: '100%' }}>
      <TextWithInfo text={'Table columns'} tooltipDirection={'right'} info={topBotColumnsInfo} />
      <RequiredStar />
      <div className={css.topbot_column_list}>
        {columnNames.map((column) => (
          <CheckBoxItem
            settingsName=''
            key={column}
            text={column}
            data_testid={'topbot-column-checkbox-item'}
            id={column}
            width='quarter'
            onClickLabel={handleClick}
            checked={selectedColumns.includes(column)}
          />
        ))}
      </div>
    </div>
  )
}

export default SelectTopBottomColumns
