import React from 'react'

const Info = () => {

    return (
      <div>
        <br/>
        <span className="info"><strong>Info</strong>
          <span className="text">
            <strong>Name: </strong>Name of the user group<br/>
            <strong>Modules: </strong>Views that the user has access to.<br/>
            <strong>Access right: </strong>If the user access is limited. User can see only information of this filter.<br/>
            <strong>Default filter: </strong>Filter that is selected when user opens the app.<br/>
            <strong>Default module: </strong>The module that the user sees when opens the app.<br/>
            <strong>Role: </strong><u>admin</u> users can invite new users, add data & see detailed information of the data.<br/>
            {/* <u>Extended</u> user can add data & see detailed information of the data */}
          </span>
        </span>
      </div>
    )
  }

export default Info