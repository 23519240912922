import React from 'react'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import RadioButtonsInput from '../../_shared/ModuleSettings/components/RadioButtonsInput'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { radarDataModeInfo } from '../RadarNotifications'
import { RadarDataMode } from '../radarTypes'
import RadarTopicList from './RadarTopicList'

const RadarSettings = () => {
  return (
    <>
      <Subtitle text='Radar Data' />
      <Linebreak />
      Topics
      <RequiredStar />
      <RadarTopicList />
      <CheckBoxInput
        settingsName='isAlphabetical'
        title='Sort topics on radar alphabetically'
        text='Sort topics'
        data_testid='topicSortCheckbox'
        defaultValue={false}
      />
      <Subtitle text='Radar Data Modes' />
      <Linebreak />
      <RadioButtonsInput
        title={
          <TextWithInfo
            text={'Select data display mode'}
            tooltipDirection={'top'}
            info={radarDataModeInfo}
          />
        }
        settingsName='radarDataMode'
        data_testid='radarDataMode'
        buttons={Object.values(RadarDataMode)}
        isRequired
      />
    </>
  )
}

export default RadarSettings
