import React, { Component } from 'react';
import { Card, Col, Row, ProgressBar } from 'react-materialize';
import { get, post } from '../../react-services/apiService';
import './ManualSender.scss';
import ManualSenderButtons from './ManualSenderButtons';
import ManualSenderRecipients from './ManualSenderRecipients';
import { emailsFromString, phoneNumbersFromString, looksLikeEmail } from '../../react-services/inputService';
import { deepCopy } from '../../../utils';
import ManualSenderTemplateSelector from './ManualSenderTemplateSelector';
import { ManualSenderContext } from './_ManualSenderContext';
import { getUsername } from '../../react-services/authService';
import { getCommonDbSettings } from '../../react-services/configService';

export default class ManualSender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useAutocomplete: true,
      flipName: false,
      surveyTemplates: null,
      selectedSurveyTemplate: null,
      contacts: [],
      recipients: [],
      sendingInProgress: false,
      showSuccess: false,
      errorMessage: null,
      failedSends: [],
    }
    this.useContacts = false;
    this.recipientIndex = 0;

    this.addRecipient = this.addRecipient.bind(this)
    this.validateBeforeSend = this.validateBeforeSend.bind(this)
    this.updateOptions = this.updateOptions.bind(this)
    this.handleModifyRecipient = this.handleModifyRecipient.bind(this)
    this.removeRecipient = this.removeRecipient.bind(this)
    this.handleSurveyTemplateChange = this.handleSurveyTemplateChange.bind(this)
  }

  componentDidMount() {
    this.determineWhetherToUseContacts();
    this.fetchSurveyTemplates();
  }

  determineWhetherToUseContacts() {
    getCommonDbSettings().then((cdbs) => {
      if (cdbs) {
        this.useContacts = cdbs.survey_contacts && cdbs.survey_contacts === true ? true : false;
        this.contactLanguageMetaKey = cdbs.contacts && cdbs.contacts.language_meta_key ? cdbs.contacts.language_meta_key : null;
        this.fetchContacts();
      }
    }).catch(e => {
      /* missing commondbsettings, doesn't matter here */
    })
  }

  fetchSurveyTemplates() {
    get('GET_SURVEY_TEMPLATES').then((res) => {
      let upcomingState = {}
      if (res && res.templates && res.templates.length > 0) {
        res.templates.sortByKey('id', false)
        upcomingState = {
          surveyTemplates: res.templates,
          selectedSurveyTemplate: res.templates[0],
        }
      } else {
        upcomingState = {
          surveyTemplates: [],
          selectedSurveyTemplate: null,
        }
      }
      this.setState(upcomingState)
    }).catch(e => {
      console.error(e)
    })
  }

  fetchContacts() {
    get('GET_CONTACTLIST').then((contacts) => {
      if (contacts && contacts.length > 0) {
        this.modifyMetadataStructureOfContacts(contacts);
        this.setState({contacts})
      } else {
        throw new Error('This tenant forces the use of contacts yet doesnt have any of them.')
      }
    })
  }

  modifyMetadataStructureOfContacts(contacts) {
    contacts.forEach(c => {
      let metaArray = []
      for (let key in c.meta) {
        metaArray.push({key: key, value: c.meta[key], __isImmutable: true})
      }
      c.meta = metaArray
    })
  }

  updateOptions(options) {
    let [option, value] = [...Object.entries(options)[0]];
    
    this.setState({
      [option]: value
    })
  }

  addRecipient() {
    this.setState({
      recipients: [...this.state.recipients, {
        index: this.recipientIndex++,
        contact: null,
        contactId: null,
        name: null,
        metadata: [],
        invitationMetadata: this.getMetasFromSurveyTemplate(this.state.selectedSurveyTemplate)
      }]
    })
  }

  getMetasFromSurveyTemplate() {
    if (!this.state.selectedSurveyTemplate.extra_metadata || this.state.selectedSurveyTemplate.extra_metadata.length === 0) return null;

    return setInvitationMetas(this.state.selectedSurveyTemplate);

    function setInvitationMetas(template) {
      let result = deepCopy(template.extra_metadata);
      result.forEach(meta => {
        meta.__isImmutable = false;
        meta.__sourceId = template.id
        if (meta.values.length === 0)
          meta.value = "";
        else
          meta.value = meta.values[0];
      })

      return result;
    }
  }

  handleModifyRecipient(info) {
    let recipients = deepCopy(this.state.recipients)
    let i = recipients.findIndex(r => r.index === info.index)
    recipients[i] = info;
    this.setState({
      recipients
    })
  }

  removeRecipient(atIndex) {
    this.setState({
      recipients: this.state.recipients.filter(rec => rec.index !== atIndex)
    })
  }

  validateBeforeSend() {
    this.setState({
      sendingInProgress: true,
      errorMessage: null,
    })
    
    let failedRecipients = this.getInvalidRecipients()
    if (failedRecipients.length === 0) {
      this.proceedWithSending();
    } else {
      this.setErrorMessage(failedRecipients);
      this.setState({sendingInProgress: false})
    }
  }

  proceedWithSending() {
    let failedAtLeastOnce = false;
    let failedSends = [];

    let nonEmptyRecipients = this.state.recipients.filter(r => r.contact !== null)
    let payloads = nonEmptyRecipients.map(r => this.formPayload(r))
    let chain = new Promise((resolve) => resolve())
    payloads.forEach(payload => {
      chain = chainInvitationPromises(chain, payload)
    })

    chain.finally(() => {
      this.setState({sendingInProgress: false})
      if (failedAtLeastOnce) {
        this.setErrorMessage(failedSends)
      } else {
        this.setState({
          showSuccess: true,
        }, () => {
          setTimeout(() => {
            this.setState({showSuccess: false})
          }, 5000)
        })
      }
    })

    function chainInvitationPromises(chain, payload) {
      return chain.then(() => {
        return post('POST_INVITE', payload, {id: payload.templateid})
          .catch(error => {
            failedAtLeastOnce = true;
            let failedContactPoint = payload.email ? payload.email : payload.phone;
            let customerName = payload.meta[0]['customer name']
            failedSends.push(`${failedContactPoint} ${customerName ? `(${customerName})` : ''}`)
          })
      })
    }
  }

  formPayload(info) {
    let isEmail = looksLikeEmail(info.contact)
    let t = this.state.selectedSurveyTemplate;
    let metadata = this.getMetadataOutOfContact(info);

    return {
      id: t.id,
      min_contact_frequency: t.min_contact_frequency,
      delivery_method: isEmail ? "email" : "sms",
      sender_email: t.sender_email,
      reply_to: t.reply_to,
      contacts: [
        {
          email: isEmail ? info.contact : "",
          phone: !isEmail ? info.contact : "",
          metadata: {...metadata, ...{"sent by": getUsername()}}
        }
      ],
      ...this.getLanguageContents(metadata)
    }
  }

  getMetadataOutOfContact(info) {
    let allMetas = [].concat(info.invitationMetadata || []).concat(info.metadata || [])
    let result = {};
    
    allMetas.forEach(meta => {result[meta.key] = meta.value})
    if (info.name && info.name !== "") {
      result['customer name'] = capitalizeName(info.name)
    }
    return result

    function capitalizeName(name) {
      let result = name.charAt(0).toUpperCase() + name.slice(1);
      let capitalize = false;

      for (let i = 1; i < name.length; i++) {
        if (capitalize) {
          result = result.slice(0, i) + result.charAt(i).toUpperCase() + result.slice(i + 1);
          capitalize = false;
        }

        if (name.charAt(i) === '-' || name.charAt(i) === ' ')
          capitalize = true;
      }
      return result;
    }
  }

  getLanguageContents(meta) {
    let result = {}
    // language meta key exists in conf and contact and in template languages 
    if (this.contactLanguageMetaKey && meta[this.contactLanguageMetaKey] && this.state.selectedSurveyTemplate.content[meta[this.contactLanguageMetaKey]]) {
      result = deepCopy(this.state.selectedSurveyTemplate.content[meta[this.contactLanguageMetaKey]])
    } else {
      result = deepCopy(this.state.selectedSurveyTemplate.content['default']);
    }
    // not used
    result.message_email_text !== null && delete result.message_email_text
    return result;
  }

  getInvalidRecipients() {
    let [ , invalidMails] = emailsFromString(this.state.recipients.map(r => r.contact).join(','))
    if (invalidMails.length > 0) {
      let [ , invalidPhones] = phoneNumbersFromString(invalidMails.join(','))
      if (invalidPhones.length > 0) {
        return invalidPhones;
      }
    }
    return [];
  }

  setErrorMessage(failedRecipients, errorFromServer = null) {
    if (errorFromServer) {
      this.setState({
        errorMessage: `Server error: ${errorFromServer}`,
        failedSends: []
      })
    } else {
      this.setState({
        errorMessage: `Did not send invitations since invalid recipients were found:`,
        failedSends: failedRecipients
      })
    }
  }

  handleSurveyTemplateChange(id) {
    this.setState({
      selectedSurveyTemplate: this.state.surveyTemplates.find(t => t.id === id)
    }, (resetInvitationMetasOfEachRecipient) => {
      let newRecipients = [];
      this.state.recipients.forEach(rec => {
        let copyOfRecipient = {
          ...deepCopy(rec),
          invitationMetadata: this.getMetasFromSurveyTemplate(),
        }
        newRecipients.push(copyOfRecipient)
      })

      this.setState({
        recipients: newRecipients
      })
    })
  }

  render() {
    const context = {
      selectedSurveyTemplate: this.state.selectedSurveyTemplate,
      useContacts: this.useContacts,
      contacts: this.state.contacts.length > 0 ? this.state.contacts : null,
      useAutocomplete: this.state.useAutocomplete,
      flipName: this.state.flipName,
    }

    return (
      <>
      <div id="manual-survey">
        <ManualSenderTemplateSelector
          surveyTemplates={this.state.surveyTemplates}
          selectedSurveyTemplate={this.state.selectedSurveyTemplate}
          handleTemplateChange={this.handleSurveyTemplateChange}
        />

        <ManualSenderContext.Provider value={context}>
          <ManualSenderRecipients
            useContacts={this.useContacts}
            contacts={this.state.contacts}
            surveyTemplate={this.state.selectedSurveyTemplate}
            recipients={this.state.recipients}

            onAdd={this.addRecipient}
            onModify={this.handleModifyRecipient}
            onRemove={this.removeRecipient}
          />
        </ManualSenderContext.Provider>

        {this.state.showSuccess && 
          <Row>
            <Col s={10} className="offset-s1">
              <Card className="green white-text" onClick={() => this.setState({showSuccess: false})}>
                Survey was succesfully sent!
              </Card>
            </Col>
          </Row>
        }

        {this.state.errorMessage && 
          <Row>
            <Col s={10} className="offset-s1">
              <Card className="red white-text" onClick={() => this.setState({errorMessage: null})}>
                {this.state.errorMessage}
                <ul>
                  {this.state.failedSends.length > 0 && this.state.failedSends.map(rec => <li key={rec}>- {rec}</li>)}
                </ul>
              </Card>
            </Col>
          </Row>
        }

        <ManualSenderButtons
          useContacts={this.useContacts}
          useAutocomplete={this.state.useAutocomplete}
          noRecipients={!this.state.recipients || this.state.recipients.length === 0 || this.state.recipients.filter(r => !!r.contact).length === 0}
          flipName={this.state.flipName}
          isSending={this.state.sendingInProgress}

          onToggleOptions={this.updateOptions}
          onSend={this.validateBeforeSend}
        />
      </div>

      {this.state.sendingInProgress && 
        <ProgressBar />
      }
      </>
    );
  }
}