import React, { useEffect, useRef, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import { cloneDeep, isEqual, isUndefined } from 'lodash'
import { PrunedKpi } from '../../Pietabular/PietabularModuleContext'
import { OpenModule } from '../openTypes'
import { GenericQuery } from '../../_shared/ModuleSettings/settingsTypes'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

const KpiSelectionTreeData = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const openModule = module as OpenModule
  const openQuery = module?.query as OpenModule['query'] | undefined

  useEffect(() => {
    const kpiSelection = [] as kpiIdAndName[]
    const openKpis = openQuery?.kpis
    if (openKpis) {
      openKpis.forEach((kpi) => {
        if (!isUndefined(kpi.id)) {
          kpiSelection.push({ id: kpi.id, name: kpi.name })
        }
      })
    }
    setSelectedKpis(kpiSelection)
  }, [])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const handleSaveNewSelection = (newSelection: kpiIdAndName[]) => {
    const newOpenKpis = newSelection.filter((kpi) => !isUndefined(kpi.id)) as PrunedKpi[]
    try {
      if (!openModule) return
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        const newModule: OpenModule = cloneDeep(openModule)
        if (!newModule.query) {
          const newQuery: GenericQuery = {
            start_date: '',
            end_date: '',
            kpis: newOpenKpis,
            calculated_kpis: {},
            grouping: '',
            filters: {},
          }
          newModule.query = newQuery
        } else {
          newModule.query.kpis = newOpenKpis
        }
        if (isEqual(newModule, openModule)) return
        saveModule(newModule)
      }, 500)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.open}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
