import React, { Component } from 'react'
import Rodal from 'rodal'
import Select from 'react-select'
import { Row, Col, Input, Button } from 'react-materialize'
import { emailsFromString } from '../../../../react-services/inputService'
import { getDateNow } from '../../../../react-services/datesService'
import { cloneDeep } from 'lodash'
import withReportingFilters from '../../../_shared/Hoc/withReportingFilters'

const DEFAULTTHRESHOLD = '30'

class AddEditReportItem extends Component {
  constructor(props) {
    super(props)
    let { reportConf } = this.props
    this.isEditMode = reportConf ? true : false
    this.reportingFilters = this.props.reportingFilters
    this.originalSelectableFilterSet = Object.keys(this.reportingFilters).map((filter) => ({
      label: filter,
      value: filter,
    }))

    this.state = {
      reportTitle: this.isEditMode ? reportConf.title : '',
      reportSubtitle: this.isEditMode ? reportConf.subtitle : '',
      selectedReportDash: this.isEditMode ? reportConf.reportDashboardKey : '',
      queryThreshold: this.isEditMode ? reportConf.threshold : DEFAULTTHRESHOLD,
      recipients: this.isEditMode ? reportConf.recipients.join(', ') : '',
      selectedFilters:
        this.isEditMode && Object.keys(reportConf.where_meta).length > 0
          ? Object.keys(reportConf.where_meta).map((meta) => ({ label: meta, value: meta }))
          : [null],

      // built to support selecting multiple filters with arbitrary values; not actually used as of now
      selectedFilterValues: [],
      selectableFilterKeys: this.originalSelectableFilterSet,
    }

    this.addFilterKey = this.addFilterKey.bind(this)
    this.deleteFilter = this.deleteFilter.bind(this)
    this.saveReport = this.saveReport.bind(this)
  }

  addFilterKey(selection, index) {
    let copyOfSelections = cloneDeep(this.state.selectedFilters)
    let copyOfSelectionValues = cloneDeep(this.state.selectedFilterValues)
    copyOfSelections[index] = selection
    copyOfSelectionValues[index] = []

    this.setState({
      selectedFilters: copyOfSelections,
      selectedFilterValues: copyOfSelectionValues,
      selectableFilterKeys: this.getSelectableFilterKeys(copyOfSelections),
    })
  }

  addFilterValues(selections, index) {
    let copyOfSelections = cloneDeep(this.state.selectedFilterValues)
    copyOfSelections[index] = selections

    this.setState({
      selectedFilterValues: copyOfSelections,
    })
  }

  deleteFilter(index) {
    let copyOfFilters = cloneDeep(this.state.selectedFilters)
    let copyOfFilterValues = cloneDeep(this.state.selectedFilterValues)
    copyOfFilters.splice(index, 1)
    copyOfFilterValues.splice(index, 1)

    this.setState({
      selectedFilters: copyOfFilters,
      selectedFilterValues: copyOfFilterValues,
      selectableFilterKeys: this.getSelectableFilterKeys(copyOfFilters),
    })
  }

  getSelectableFilterKeys(thoseRemoved) {
    let result = []
    this.originalSelectableFilterSet.forEach((f) => {
      if (!thoseRemoved.find((item) => item && f && item.value === f.value)) {
        result.push({ label: f.label, value: f.value })
      }
    })
    return result
  }

  saveReport() {
    let thresholdInput = parseInt(this.state.queryThreshold, 10)
    let { reportConf } = this.props
    let newReport = {
      id: this.isEditMode ? reportConf.id : this.makeRandomId(),
      title: this.state.reportTitle,
      subtitle: this.state.reportSubtitle,
      reportDashboardKey: this.state.selectedReportDash,
      createdOn: this.isEditMode ? reportConf.createdOn : getDateNow(),
      where_meta: this.getMetaFilters(),
      recipients: emailsFromString(this.state.recipients)[0],
      img: this.isEditMode ? reportConf.img : '',
      threshold: !isNaN(thresholdInput) ? thresholdInput.toString() : DEFAULTTHRESHOLD,
      reportInstances:
        this.isEditMode &&
        this.props.reportConf.reportDashboardKey === this.state.selectedReportDash
          ? cloneDeep(reportConf.reportInstances)
          : {},
    }

    this.isEditMode ? this.props.onModify(newReport) : this.props.onSave(newReport)
    this.props.onClose()
  }

  getMetaFilters() {
    let result = {}
    this.state.selectedFilters.forEach((key, index) => {
      if (!key) return
      result[key.value] = [] // this.state.selectedFilterValues[index].map(item => item.value);
    })
    return result
  }

  makeRandomId() {
    const LENGTH = 40
    let result = 'r-id'
    let letterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < LENGTH; i++) {
      if (i % (LENGTH / 4) === 0) result += '-'
      else result += letterSet.charAt(Math.floor(Math.random() * letterSet.length))
    }

    return result
  }

  render() {
    let templateWasChanged =
      this.isEditMode && this.props.reportConf.reportDashboardKey !== this.state.selectedReportDash
    let thresholdError = isNaN(this.state.queryThreshold)
      ? 'Number required'
      : this.state.queryThreshold < 0
      ? 'Zero or greater'
      : ''
    let templateIsSelected = this.state.selectedReportDash ? true : false
    let formIsValid = this.state.reportTitle && templateIsSelected && !thresholdError

    return (
      <Rodal closeOnEsc animation='slideUp' visible onClose={this.props.onClose}>
        <h5>{this.isEditMode ? 'Edit' : 'Add'} a report</h5>
        <hr />
        <br />
        <div className='report-item-editor'>
          <Row>
            <Input
              data-cy='reportItemTitle'
              label='Report title *'
              s={6}
              type='text'
              placeholder=' '
              value={this.state.reportTitle}
              error={!this.state.reportTitle ? 'Required' : ''}
              onChange={(e) => {
                this.setState({ reportTitle: e.target.value })
              }}
            />
            <Input
              data-cy='reportItemSubtitle'
              s={6}
              label='Subtitle (optional)'
              type='text'
              placeholder='Optional'
              value={this.state.reportSubtitle}
              onChange={(e) => {
                this.setState({ reportSubtitle: e.target.value })
              }}
            />
          </Row>

          <Row>
            <Col offset='s2' s={8}>
              <hr />
            </Col>
          </Row>

          <h6>Configurations</h6>
          <Row>
            <Col
              data-cy='reportItemTemplateWrapper'
              className={!templateIsSelected ? 'template-selection-error' : ''}
              s={8}
            >
              <label>Report template *</label>
              <Select
                className='react-select-default-styles'
                placeholder='Select a template'
                options={this.props.reportTemplate.dashboards.map((db) => ({
                  label: db.name,
                  value: db.name,
                }))}
                defaultValue={
                  this.state.selectedReportDash
                    ? { label: this.state.selectedReportDash, value: this.state.selectedReportDash }
                    : null
                }
                onChange={(sel) => {
                  this.setState({ selectedReportDash: sel.value })
                }}
              />
              {!templateIsSelected && (
                <label
                  data-cy='reportItemMissingTemplate'
                  className='template-selection-error-hint'
                >
                  Required
                  <br />
                </label>
              )}
              {templateWasChanged && (
                <label data-cy='reportItemModifiedTemplate' className='orange-text'>
                  Warning: changing the template causes you to lose data on when reports were last
                  sent!
                </label>
              )}
            </Col>
            <Input
              data-cy='reportItemQueryThreshold'
              s={4}
              type='text'
              label='Query threshold'
              value={this.state.queryThreshold}
              error={thresholdError}
              onChange={(e, value) => {
                this.setState({ queryThreshold: value })
              }}
            />
          </Row>
          <Row className='report-editor-filters'>
            <Col>
              <label>Filtering</label>
            </Col>
            <br />
            {/* {this.state.selectedFilters.length === 0 && <Col className="grey-text" s={12}>Use the button below to add a filter.</Col>} */}
            {this.state.selectedFilters.map((filter, index) => (
              <React.Fragment key={index}>
                <Col data-cy='reportItemFilterSelectionWrapper' s={6}>
                  {index === 0 && <label>Key</label>}
                  <Select
                    className='react-select-default-styles'
                    placeholder='Select a filter'
                    options={this.state.selectableFilterKeys}
                    defaultValue={this.state.selectedFilters[index]}
                    onChange={(sel) => {
                      this.addFilterKey(sel, index)
                    }}
                  />
                </Col>
                {/* <Col s={6}>
                  {index === 0 && <label>Values</label>}
                  <Select
                    isMulti
                    isDisabled={!this.state.selectedFilters[index]}
                    closeMenuOnSelect={false}
                    placeholder="Select specific values (optional)"
                    options={this.state.selectedFilters[index] ? this.reportingFilters[this.state.selectedFilters[index].value].map(val => ({label: val, value: val})) : []}
                    value={this.state.selectedFilterValues[index] ? this.state.selectedFilterValues[index] : []}
                    styles={{input: () => ({'height': '35px'})}}
                    onChange={(sels) => {this.addFilterValues(sels, index)}}
                  />
                </Col> */}
                {/* <Col s={1} className="center">
                  {index === 0 && <label>Remove</label>}
                  <span onClick={() => {this.deleteFilter(index)}}><Icon small>delete</Icon></span>
                </Col> */}
              </React.Fragment>
            ))}
            <br />
            {/* <Col className="tool">
              <span className="tip">Add a new filter</span>
              <Button flat onClick={() => {this.setState({selectedFilters: [...this.state.selectedFilters, undefined]})}}><Icon>add_circle_outline</Icon></Button>
            </Col> */}
          </Row>

          <Row>
            <Col offset='s2' s={8}>
              <hr />
            </Col>
          </Row>

          <h6>Recipients</h6>
          <Row>
            <Col s={12}>
              <div>
                <label>Default recipients (optional)</label>
              </div>
              <textarea
                data-cy='reportItemRecipientsInput'
                defaultValue={this.state.recipients}
                placeholder='Use separate rows, commas (,) or semicolons (;)'
                onChange={(e) => {
                  this.setState({ recipients: e.target.value })
                }}
              ></textarea>
            </Col>
          </Row>

          <Row>
            <Col offset='s1' s={5}>
              <Button data-cy='reportItemSave' disabled={!formIsValid} onClick={this.saveReport}>
                OK
              </Button>
            </Col>
            <Col offset='s3' s={1}>
              <Button data-cy='reportItemCancel' flat onClick={this.props.onClose}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Rodal>
    )
  }
}

export default withReportingFilters(AddEditReportItem)
