import { MessageState } from '../_shared/ConversationModal/conversationTypes'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type TextualKpiData = {
  id: number
  answer_id: number
  category?: {
    [category: string]: string[]
  }
  answer: string
  completed_survey_id: number
  metadata?: {
    [name: string]: string
  }
  translation?: {
    [name: string]: string
  }
  date?: string
  topics?: string[]
  sentiments?: string[]
}

export type TextualDataSeries = {
  name: string
  is_trend?: boolean
  data: TextualKpiData[]
}

export type TextualData = {
  series: TextualDataSeries[]
}

export type HeaderPosition = { index: number; offSet: number }

export enum DefaultTabOptions {
  SENTIMENT = 'sentiment',
  TOPIC = 'topic',
  KPIS = 'kpis',
  CUSTOM = 'custom_',
  SEARCH = 'search',
  INSPECTOR = 'inspector',
  MESSAGE = 'message',
  TOOLS = 'tools',
}

export type FilterTabOptions = DefaultTabOptions | `${DefaultTabOptions.CUSTOM}${string}`

export type OpenModuleSettings = {
  showTools?: boolean
  messageFilter?: MessageState[]
  [category: `${string}CategoryFilter`]: string[] | undefined
}

export type OpenModule = {
  type: 'open'
  searchFilter?: string
  doShowSentiments?: boolean
  doShowTopics?: boolean
  doShowCustomCategories?: boolean
  metacolumns?: string[]
  options?: OpenModuleSettings & ModuleDefaultSettings
} & ModuleBase
