/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from 'lodash'
import React from 'react'
import { getScreenModuleClasses } from '../HelperFunctions'
import ScreenList from './ScreenList'

type ScreenRepeaterProps = {
  modulesettingslist: any[]
  scrollPage: any
  readyModules: number
}

const ScreenRepeater = ({ modulesettingslist, scrollPage, readyModules }: ScreenRepeaterProps) => {
  function getGraphClass(settings: any) {
    return getScreenModuleClasses(settings, scrollPage)
  }

  if (!isArray(modulesettingslist)) {
    return <div></div>
  }
  return (
    <>
      {modulesettingslist.map((settings, i) => (
        <div
          key={settings.id || i}
          className={`screen-module-settings ${getGraphClass(settings)}`}
          style={{
            height: scrollPage ? settings?.options?.moduleheight : '',
            backgroundPosition: settings?.options?.bgposition,
            backgroundSize: settings?.options?.bgsize,
            backgroundColor: settings?.options?.bgcolor,
            backgroundImage: `url(${settings?.options?.imageurl})`,
            display: 'block',
          }}
        >
          <ScreenList settings={settings} scrollPage={scrollPage} readyModules={readyModules} />
        </div>
      ))}
    </>
  )
}

export default ScreenRepeater
