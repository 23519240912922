import {
  FeedbackAlert,
  LimitedAccessClaims,
} from '../components/Dashboards/DashboardModules/FeedbackAlerts/alertTypes'
import { TrackingEvent } from '../react-constants'
import { get, post } from './apiService'
import { clearSession, saveUsernameToSessionStorage, setToken, windowStop } from './authService'
import trackingService from './trackingService'

function getLimitedAccessTokenAndClaims(token: string) {
  try {
    return get('GET_LIMITED_ACCESS_TOKEN', { token })
      .then((res) => {
        const token_claims = {
          token: res,
          claims: JSON.parse(atob(res.split('.')[1])),
        }
        return token_claims
      })
      .catch(() => Promise.reject('Promise rejected...'))
  } catch (e) {
    return Promise.reject('Promise rejected...')
  }
}

function getLimitedAccessAlert(limited_access_token: string, claims: LimitedAccessClaims) {
  try {
    if (claims.limited_access.type !== 'alert') {
      return Promise.reject('Limited access type is NOT alert!')
    }
    windowStop()
    clearSession()
    console.log('GETTING ACCESS ALERT')
    saveUsernameToSessionStorage(claims.limited_access.username)
    setToken(limited_access_token)
    try {
      trackingService.identify()
    } catch (error) {
      console.log('Logging failed')
    }
    return get('GET_LIMITED_ACCESS_ALERT')
      .then((res) => {
        const limited_access_alert = res
        try {
          trackingService.track(TrackingEvent.OpenEmailAlert)
        } catch (error) {
          console.log('Logging failed')
        }
        return limited_access_alert
      })
      .catch((e) => {
        console.log(e)

        return Promise.reject('Promise rejected...')
      })
  } catch (e) {
    console.log(e)

    return Promise.reject('Promise rejected...')
  }
}

function limitedAlertSave(payload: FeedbackAlert) {
  try {
    trackingService.track(TrackingEvent.SaveAlert)
    return (
      post('POST_LIMITED_ACCESS_ALERT', payload)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(() => {
          console.log('Alert saved')
        })
        .catch(() => Promise.reject('Promise rejected...'))
    )
  } catch (e) {
    return Promise.reject('Promise rejected...')
  }
}

export { getLimitedAccessTokenAndClaims, getLimitedAccessAlert, limitedAlertSave }
