import React, { useState } from 'react'
import { Table } from 'react-materialize'
import { AnswerData } from './ValueInspectorSingleView'
import { Button } from '@mui/material'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { tCommon } from '../../../../../../../languages/i18n'

type ValueInspectorSingleViewTableProps = {
  answerData: AnswerData[]
}

function ValueInspectorSingleViewTable({ answerData }: ValueInspectorSingleViewTableProps) {
  const [showAllTable, setShowAllTable] = useState<boolean>(false)

  return (
    <>
      <Table>
        <tbody>
          {!showAllTable &&
            answerData &&
            answerData.slice(0, 5).map((answer, index) => (
              <tr key={index}>
                <td>{answer.question}</td>
                <td>{answer.answer || answer.answer === 0 ? answer.answer : 'N/A'}</td>
              </tr>
            ))}
          {showAllTable &&
            answerData &&
            answerData.map((answer, index) => (
              <tr key={index}>
                <td>{answer.question}</td>
                <td>{answer.answer || answer.answer === 0 ? answer.answer : 'N/A'}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      {answerData && answerData.length > 5 && (
        <>
          {showAllTable ? (
            <Button endIcon={<ArrowUpward />} onClick={() => setShowAllTable(false)}>
              {tCommon('button.showMore')}
            </Button>
          ) : (
            <Button endIcon={<ArrowDownward />} onClick={() => setShowAllTable(true)}>
              {tCommon('button.showMore')}
            </Button>
          )}
        </>
      )}
    </>
  )
}

export default ValueInspectorSingleViewTable
