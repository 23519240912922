import { createStateContext } from 'react-use'
import { TopBottomDataContext } from '../topBottomTypes'

export const [useTopBottomData, TopBottomDataProvider] = createStateContext<TopBottomDataContext>({
  id: '',
  topBottomData: [],
  topBotFilters: {
    start_date: '',
    end_date: '',
    where_meta: {},
  },
  topBottomLimitValues: { max: 8, mid: 7, min: 6 },
  isLoading: false,
})
