import { useEffect, useState } from 'react'
import { getMetaKeys } from '../react-services/metadataService'
import { failedToFetchMetaKeys } from './notificationMessages'
import { BLACKLIST } from '../react-constants/metadata'
import { makeNonResettingObservable } from './nonResettingObservable'
import { useShallowCompareEffect } from 'react-use'
import useStoreOwner from './useStoreOwner'

const metaKeysStorage = makeNonResettingObservable<string[]>({})

const useMetaKeys = () => {
  const key = 'metaKeys'
  const [metaKeys, setMetaKeys] = useState<string[]>([] as string[])
  const [filteredMetaKeys, setFilteredMetaKeys] = useState<string[]>([] as string[])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const { tenant, user } = useStoreOwner(metaKeysStorage)

  useEffect(() => {
    if (!user && !tenant) return
    if (!metaKeysStorage.getRelatedListeners(key)) {
      getConfig()
    } else {
      const storeValue = metaKeysStorage.get(key)
      if (storeValue) setMetaKeys(storeValue)
      setLoading(false)
    }
    return metaKeysStorage.subscribe(setMetaKeys, key)
  }, [])

  useShallowCompareEffect(() => {
    if (metaKeys && metaKeys.length) {
      const filteredMetaKeyStrings = metaKeys.filter((key) => !BLACKLIST.includes(key))
      setFilteredMetaKeys(filteredMetaKeyStrings)
      setLoading(false)
    }
  }, [metaKeys || []])

  const getConfig = () => {
    getMetaKeys()
      .then((res) => {
        metaKeysStorage.set(res, key)
        const storeData = metaKeysStorage.get(key)
        if (storeData) setMetaKeys(storeData)
        setLoading(false)
      })
      .catch(() => {
        setError(failedToFetchMetaKeys)
        metaKeysStorage.set([], key)
        setLoading(false)
      })
  }

  return {
    error,
    loading,
    metaKeys,
    filteredMetaKeys,
    refetch: getConfig,
  }
}

export default useMetaKeys
