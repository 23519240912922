import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import {
  alertSave,
  assignAlert,
  fetchAllowedMetadataKeys,
  generatePayload,
  getUsers,
  updateMetaValue,
} from '../../../../../react-services/alertService'
import FeedbackAlertModal from './FeedbackAlertModal'
import { AlertChanges, FeedbackAlert } from '../alertTypes'
import { useAlertData, useAlertModalControl, useAlertTools } from '../FeedbackAlertsTableCntr'
import { tCommon } from '../../../../../../languages/i18n'

function FeedbackAlertModalContainer() {
  const [{ getAlert, updateAlerts, getAlerts }, setAlertTools] = useAlertTools()
  const [{ currentAlertId, alert, alertConfig }, setAlertData] = useAlertData()
  const setAlertModalControl = useAlertModalControl()[1]

  useEffect(() => {
    if (currentAlertId) getAlert(currentAlertId)
  }, [currentAlertId])

  useEffect(() => {
    if ((alertConfig && !alertConfig.metakeys) || !alertConfig?.users) {
      createConfiguration()
    }

    function createConfiguration() {
      getUsers()
        .then((res) => {
          if (res && res.users) {
            setAlertData((prev) => ({
              ...prev,
              alertConfig: { ...prev.alertConfig, users: res.users },
            }))
          } else {
            setAlertData((prev) => ({ ...prev, alertConfig: { ...prev.alertConfig, users: [] } }))
          }
        })
        .catch((err) => toast.error(err))

      fetchAllowedMetadataKeys()
        .then((res) => {
          if (res) {
            setAlertData((prev) => ({
              ...prev,
              alertConfig: { ...prev.alertConfig, metakeys: res },
            }))
          } else {
            setAlertData((prev) => ({
              ...prev,
              alertConfig: { ...prev.alertConfig, metakeys: [] },
            }))
          }
        })
        .catch((err) => toast.error(err))
    }
  }, [])

  useEffect(() => {
    setAlertTools((prev) => ({ ...prev, handleAlertSave }))
  }, [])

  function handleAlertSave(alert: FeedbackAlert, changes: AlertChanges) {
    const payload = generatePayload(alert, changes)
    alertSave(payload, alert.id)
      .then(() => {
        if (changes.assignToUserId) return assignAlert(alert.id, changes.assignToUserId)
      })
      .then(() => {
        if (changes.metaKey && changes.metaValue && alert.completed_survey_id)
          return updateMetaValue(changes.metaKey, changes.metaValue, alert.completed_survey_id)
      })
      .then(() => toast.success(tCommon('info.alertSaved')))
      .then(() => changes.assignToUserId && toast.success(tCommon('info.emailSent')))
      .then(() => updateAlerts(alert.id, changes))
      .then(() => currentAlertId && getAlert(currentAlertId))
      .then(() => getAlerts())
      .then(() => setAlertData((prev) => ({ ...prev, isUnsavedChanges: false })))
      .then(() =>
        setAlertModalControl((prev) => ({
          ...prev,
          isConversationModalOpen: false,
          isSettingsModalOpen: false,
          isRelatedAlertsModalOpen: false,
        })),
      )
      .catch((err: unknown) => toast.error(typeof err === 'string' ? err : ''))
  }

  return (
    <>
      {alert && alertConfig && alertConfig.metakeys !== undefined && alertConfig.users && (
        <>
          <FeedbackAlertModal
            onCancel={() =>
              setAlertModalControl({
                isAlertModalOpen: false,
                isConversationModalOpen: false,
                isSettingsModalOpen: false,
                isRelatedAlertsModalOpen: false,
              })
            }
          />
        </>
      )}
    </>
  )
}

export default FeedbackAlertModalContainer
