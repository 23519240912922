/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlainObject, isNil, cloneDeep, partition } from 'lodash'
import { MaybePrimitive, Primitive, Nil } from './types'
import { v4 as uuid } from 'uuid'

const DEFAULT_COMPARISON_FUNCTION = (a: string, b: string) => {
  try {
    return Intl.Collator(undefined, { sensitivity: 'base', numeric: true }).compare(a, b)
  } catch (e) {
    if (localeCompareSupportsLocales()) {
      return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
    } else {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    }
  }
}

/**
 * @deprecated use {@link cloneDeep} directly instead of this
 */
function deepCopy<T>(target: T): T {
  return cloneDeep(target)
}

/* Checks if array values are in the same order as values of key in objectArray
 */
function compareArrayToObjectArrayWithKey(
  array: Primitive[],
  objectArray: { [key: string]: Primitive }[],
  key: string,
): boolean {
  if (array.length !== objectArray.length) return false
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== objectArray[i][key]) return false
  }
  return true
}

/*  Sort an array in place according to a reference of indices
 */
function sortByIndexReference(sorted: any, ref: number[]) {
  if (ref.length !== sorted.length) throw new Error('Arrays are not equal in length')

  const copy: any[] = [].concat(sorted)
  sorted.forEach((el: any, i: number) => {
    const item: any = copy[ref[i]]
    sorted[i] = item
  })
}

/**
 * Sort an object by its keys
 */
const sortObject: (obj: any) => any = (() => {
  return function sortObject(obj: any) {
    return Object.keys(obj)
      .sort(DEFAULT_COMPARISON_FUNCTION)
      .reduce(function (acc: any, key: string) {
        acc[key] = deepCopy(obj[key])
        return acc
      }, {})
  }
})()

// eslint-disable-next-line @typescript-eslint/ban-types
function nullifyPropertyValues<T>(object: Object, maxDepth?: number): T | undefined {
  if (!object || !isPlainObject(object)) {
    return undefined
  }

  return recurse(deepCopy(object), 0)

  function recurse(obj: any, currentDepth: number) {
    const canRecurse = maxDepth === undefined || currentDepth < maxDepth
    for (const k in obj) {
      if (isPlainObject(obj[k]) && canRecurse) recurse(obj[k], ++currentDepth)
      else obj[k] = null
    }
    return obj
  }
}

function deletePropertiesFromObject(
  keys: string[],
  target: Record<string, any>,
): Record<string, any> {
  const result: Record<string, any> = deepCopy(target)

  keys.forEach((prop) => {
    try {
      delete result[prop]
    } catch (e) {
      // IE will complain if trying to delete a property that's missing; other browsers don't mind. So that's why this is here to just ignore the error.
    }
  })

  return result
}

function spawnHelpdeskToast(
  message: string,
  emailSubject: string | null = null,
  emailBody: string | null = null,
): void {
  if (isNil(emailSubject) || isNil(emailBody))
    throw new Error('Provide an email subject and a body.')
  const TIMEOUT = 10000

  const text = `<span>${message}<br/>Please contact ${buildMailToElement(
    emailSubject,
    emailBody,
  )} if this problem persists.</span>`
  const toast: any = window.Materialize.toast(text, TIMEOUT)

  removeToast(toast, TIMEOUT)

  function buildMailToElement(emailSubject: string, emailBody: string) {
    emailSubject = 'WheelQ application error: '.concat(emailSubject)
    emailBody = emailBody.replace(/"/g, "'")
    const mailTo = `<a href="mailto:helpdesk@wheelq.com?subject=${emailSubject}&body=%0D%0A%0D%0AInformation for our helpdesk:%0D%0A-----%0D%0A${emailBody}">helpdesk@wheelq.com</a>`

    return mailTo
  }

  function removeToast(toast: any, timeout: number): void {
    setTimeout(() => {
      try {
        toast.el.parentNode.removeChild(toast.el)
      } catch (e) {
        /* ignore */
      }
    }, timeout)
  }
}

function createInfoToast(message: string): void {
  const TIMEOUT = 10000
  const toastId = uuid()
  const toastChildren = `
    <div class="info-toast">
      <div class="info-toast-icon-message-container">
        <div class="info-toast-icon"><i class="material-icons md-36">info</i></div>
        <div class="info-toast-message">${message}</div>
      </div>
    <div>
    <div id=${toastId}></div>`

  const toast: any = window.Materialize.toast(toastChildren, TIMEOUT)

  const dissmissBtn = document.createElement('button')
  dissmissBtn.innerHTML = 'Ok'
  dissmissBtn.className = 'waves-effect waves-light btn right blue'
  dissmissBtn.onclick = function () {
    toast.el.parentNode.removeChild(toast.el)
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.getElementById(toastId).appendChild(dissmissBtn)

  removeToast(toast, TIMEOUT)

  function removeToast(toast: any, timeout: number): void {
    setTimeout(() => {
      try {
        toast.el.parentNode.removeChild(toast.el)
      } catch (e) {
        /* ignore */
      }
    }, timeout)
  }
}

function isUsingIE(): boolean {
  const ua: string = window.navigator.userAgent
  const isIE: boolean = /MSIE|Trident/.test(ua)

  return isIE
}

/* eslint-disable */
Object.defineProperty(Array.prototype, 'sortByKey', {
  enumerable: false,
  writable: true,
  value: function (key: any, isReversed: boolean = false) {
    let result = this.sort((a: any, b: any) =>
      DEFAULT_COMPARISON_FUNCTION(a[key].toString(), b[key].toString()),
    )
    if (isReversed) return result.reverse()
    else return result
  },
})

function removeQueryParams(url: string): string {
  if (url.indexOf('?') > -1) {
    return url.substr(0, url.indexOf('?'))
  } else {
    return url
  }
}

function sort(
  array: MaybePrimitive[],
  isReversed: boolean,
  nullsBehaviour?: 'start' | 'end' | 'discard',
) {
  let [valid, nils] = partition(array, (value) => !isNil(value))
  valid = valid as Primitive[]
  nils = nils as Nil[]

  valid.sort((a: any, b: any) => DEFAULT_COMPARISON_FUNCTION(a.toString(), b.toString()))
  nils.sort()

  if (isReversed) {
    valid.reverse()
    nils.reverse()
  }

  switch (nullsBehaviour) {
    case 'discard':
      return valid

    case 'start':
      return nils.concat(valid)

    case 'end':
    default:
      return valid.concat(nils)
  }
}

/**
 * Removes duplicates from an array.
 *
 * @param array target array
 * @returns de-duplicated version of array
 */
function dedupe<T>(array: T[]) {
  return array.filter((v, i) => array.indexOf(v) === i)
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#check_browser_support_for_extended_arguments
 */
function localeCompareSupportsLocales(): boolean {
  try {
    'foo'.localeCompare('bar', 'i')
  } catch (e: any) {
    return e?.name === 'RangeError'
  }
  return false
}

function canUseWorkers() {
  try {
    return Worker !== undefined && !doesUrlContainToken()
  } catch (e: any) {
    return false
  }
}

/**
 * Duplicated from "env" utilities because importing it caused issues with Jest.
 */
function doesUrlContainToken() {
  return /dashboard\/\d+\/module\/\d+\?.*token=/gm.test(window.location.href)
}

export {
  DEFAULT_COMPARISON_FUNCTION,
  deepCopy,
  compareArrayToObjectArrayWithKey,
  sortByIndexReference,
  sortObject,
  nullifyPropertyValues,
  deletePropertiesFromObject,
  spawnHelpdeskToast,
  createInfoToast,
  isUsingIE,
  removeQueryParams,
  sort,
  dedupe,
  canUseWorkers,
}
