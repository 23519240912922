/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import SurveyInfoTable from './SurveyInfoTable';
import SurveyInfoCoveringLetter from './SurveyInfoCoveringLetter'
import { SurveyInfoTool, Survey } from '../../../../../types'
import { each, flatMap, isObject } from 'lodash'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import trackingService from '../../../../react-services/trackingService';
import { TrackingEvent } from '../../../../react-constants';

type Props = {
  useApiHook: () => any
}

const SurveyInfo = (props: Props) => {
  const { resolved, isLoading } = props.useApiHook()
  const [surveyList, setSurveyList] = useState<SurveyInfoTool.SurveyListItem[]>([])
  const [isRodalOpen, setIsRodalOpen] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<SurveyInfoTool.SurveyListItem | undefined>(undefined)
  const [isShowingEverySurvey, setIsShowingEverySurvey] = useState<boolean>(false)
  const [isSurveyListCreated, setIsSurveyListCreated] = useState<boolean>(false)

  function createSurveyList(
    automationConfs: { [name: string]: Survey.AutomationConfig },
    triggerConfs: { [name: string]: Survey.TriggerConfig },
    surveyTemplates: { templates: Survey.SurveyTemplate[] },
    surveyStats: { surveyStatuses: SurveyInfoTool.SurveyStatus[] }
  ): SurveyInfoTool.SurveyListItem[] {
    const surveyStatsAsSurveyItems: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } = surveyStats.surveyStatuses ? createSurveyStatusesWithoutMatchingSurvey(surveyStats.surveyStatuses) : {}
    const matchingSurveysAsSurveyItems: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } = matchConfsAndStats(automationConfs, triggerConfs, surveyTemplates, surveyStats)
    each(matchingSurveysAsSurveyItems, function (value, key) {
      surveyStatsAsSurveyItems[key] = value
    })
    return flatMap(Object.keys(surveyStatsAsSurveyItems), (key => surveyStatsAsSurveyItems[key]))
  }

  function createSurveyStatusesWithoutMatchingSurvey(surveyStatuses: SurveyInfoTool.SurveyStatus[]): { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } {
    const emptyStatuses: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } = {}
    surveyStatuses.forEach((stat: SurveyInfoTool.SurveyStatus) => {
      if (stat.survey) {
        const id = stat.survey.surveygizmo_id // || stat.survey.surveypal_id
        if (id) emptyStatuses[id] = [createSurveyListItem(stat)]
      }
    })
    return emptyStatuses
  }

  function matchConfsAndStats(
    automationConfs: { [name: string]: Survey.AutomationConfig },
    triggerConfs: { [name: string]: Survey.TriggerConfig },
    surveyTemplates: { templates: Survey.SurveyTemplate[] },
    surveyStats: { surveyStatuses: SurveyInfoTool.SurveyStatus[] }
  ): { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } {
    const foundSurveys: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } = {}
    if (isObject(automationConfs)) {
      const automConfListItems = foundMatchingAutomationConfs(automationConfs as { [name: string]: Survey.AutomationConfig }, surveyStats.surveyStatuses as SurveyInfoTool.SurveyStatus[])
      addItemsToFoundSurveys(foundSurveys, automConfListItems)
    }
    if (isObject(triggerConfs)) {
      const TriggerConfListItems = foundMatchingTriggerConfs(triggerConfs as { [name: string]: Survey.TriggerConfig }, surveyStats.surveyStatuses as SurveyInfoTool.SurveyStatus[])
      addItemsToFoundSurveys(foundSurveys, TriggerConfListItems)
    }
    if (surveyTemplates && surveyTemplates.templates) {
      const surveyTemplateListItems = foundMatchingSurveyTemplates(surveyTemplates.templates, surveyStats.surveyStatuses)
      addItemsToFoundSurveys(foundSurveys, surveyTemplateListItems)
    }
    return foundSurveys
  }

  function addItemsToFoundSurveys(foundSurveys: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] }, toBeAdded: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] }): void {
    each(toBeAdded, function (value, key) {
      if (foundSurveys[key]) foundSurveys[key] = [...foundSurveys[key], ...value]
      else foundSurveys[key] = value
    })
  }

  function foundMatchingConfs(
    confs: { [name: string]: Survey.TriggerConfig | Survey.AutomationConfig },
    surveyStatuses: SurveyInfoTool.SurveyStatus[], type: 'trigger' | 'automation'
  ): { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } {
    const confsArray = Object.keys(confs).map(key => ({ [confs[key].survey_id]: confs[key] }))
    const matchingListItems: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } = {}
    surveyStatuses.forEach(({ survey, status }) => {
      if (survey) {
        const id = survey.surveygizmo_id // || survey.surveypal_id
        if (id) {
          const matchingConfs = confsArray.filter((conf) => {
            return (conf[id] !== undefined)
          })
          if (matchingConfs.length > 0) {
            matchingConfs.forEach(conf => {
              if (matchingListItems[id]) matchingListItems[id] = [...matchingListItems[id], (createSurveyListItem({ survey, status }, conf[id], type))]
              else matchingListItems[id] = [(createSurveyListItem({ survey, status }, conf[id], type))]
            })
          }
        }
      }
    })
    return matchingListItems
  }

  function foundMatchingTriggerConfs(
    triggerConfs: { [name: string]: Survey.TriggerConfig },
    surveyStatuses: SurveyInfoTool.SurveyStatus[]
  ): { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } {
    return foundMatchingConfs(triggerConfs, surveyStatuses, 'trigger')
  }

  const foundMatchingAutomationConfs = (
    automationConfs: { [name: string]: Survey.AutomationConfig },
    surveyStatuses: SurveyInfoTool.SurveyStatus[]
  ): { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } => {
    return foundMatchingConfs(automationConfs, surveyStatuses, 'automation')
  }

  function foundMatchingSurveyTemplates(
    surveyTemplates: Survey.SurveyTemplate[],
    surveyStatuses: SurveyInfoTool.SurveyStatus[]
  ): { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } {
    const listItems: SurveyInfoTool.SurveyListItem[] = []
    const matchigSurveyListItems: { [surveyId: string]: SurveyInfoTool.SurveyListItem[] } = {}
    surveyStatuses.forEach(({ survey, status }) => {
      if (survey?.surveygizmo_id) {
        const id = survey.surveygizmo_id
        const matchingConfs = surveyTemplates.filter((conf) => JSON.stringify(conf.survey_id) === JSON.stringify(id))
        matchingConfs.forEach(conf => {
          const listItem = createSurveyListItem({ survey, status }, conf, 'manual')
          listItems.push(listItem)
          if (matchigSurveyListItems[id]) matchigSurveyListItems[id] = [...matchigSurveyListItems[id], listItem]
          else matchigSurveyListItems[id] = [listItem]
        })
      }

    })
    return matchigSurveyListItems
  }

  function createSurveyListItem(
    surveyStatus: SurveyInfoTool.SurveyStatus,
    matchingSurvey: Survey.TriggerConfig | Survey.SurveyTemplate | Survey.AutomationConfig | null = null,
    type: 'trigger' | 'automation' | 'manual' | null = null
  ): SurveyInfoTool.SurveyListItem {
    return ({
      surveyName: surveyStatus.survey && surveyStatus.survey.name ? surveyStatus.survey.name : '',
      surveyGizmoId: (surveyStatus.survey && surveyStatus.survey.surveygizmo_id) || '',
      type: type || '',
      messages: matchingSurvey ? matchingSurvey.content : null,
      groupTimings: matchingSurvey ? matchingSurvey.groupTimings : null,
      deliveryMethod: createDeliveryMethod(matchingSurvey),
      lastSent: surveyStatus.status ? localizeDate(surveyStatus.status.lastSent) : '',
      lastReceived: surveyStatus.status ? localizeDate(surveyStatus.status.lastReceived) : '',
      isActive: surveyStatus.status ? isSurveyActive(surveyStatus.status.lastSent) : false
    })
  }

  function isSurveyActive(utcTime: string | null): boolean {
    if (utcTime === null) return false
    const yearAgo = new Date();
    yearAgo.setMonth(yearAgo.getMonth() - 12);
    yearAgo.setHours(0, 0, 0);
    yearAgo.setMilliseconds(0);
    return new Date(utcTime) > yearAgo
  }

  function createDeliveryMethod(survey: Survey.TriggerConfig | Survey.SurveyTemplate | Survey.AutomationConfig | null): SurveyInfoTool.DeliveryMethod {
    return ({
      first: survey && survey.delivery_method ? parseFirstDeliveryMethod(survey.delivery_method) : null,
      second: survey && survey.delivery_method ? parseSecondDeliveryMethod(survey.delivery_method) : null
    })
  }

  function parseFirstDeliveryMethod(deliveryMethod: string): 'sms' | 'email' | null {
    if (deliveryMethod.toLowerCase().startsWith('sms')) return 'sms'
    else if (deliveryMethod.toLowerCase().startsWith('email')) return 'email'
    return null
  }

  function parseSecondDeliveryMethod(deliveryMethod: string): 'sms' | 'email' | null {
    if (hasTwoDeliveryMethods(deliveryMethod)) {
      if (deliveryMethod.toLowerCase().endsWith('sms')) return 'sms'
      else if (deliveryMethod.toLowerCase().endsWith('email')) return 'email'
    }
    return null
  }

  function hasTwoDeliveryMethods(deliveryMethod: string): boolean {
    const lenghtOfLongestDeliveryMethod = 'email'.length
    return lenghtOfLongestDeliveryMethod < deliveryMethod.length
  }

  function localizeDate(utcTime: string | null): string {
    if (utcTime === null) return ''
    const formatOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }

    return new Date(utcTime + ' UTC').toLocaleTimeString(undefined, formatOptions)
  }

  const handleOpenRodal = (item: SurveyInfoTool.SurveyListItem): void => {
    trackingService.track(TrackingEvent.OpenSurveyPreview)
    setIsRodalOpen(true)
    setSelectedItem(item)
  }

  const handleCloseRodal = (): void => {
    trackingService.track(TrackingEvent.CloseSurveyPreview)
    setIsRodalOpen(false)
    setSelectedItem(undefined)
  }

  const handleCheckShowAll = (): void => {
    trackingService.track(TrackingEvent.ToggleShowRedirectsAndInactiveSurveys, { visible: `${isShowingEverySurvey}` })
    setIsShowingEverySurvey(!isShowingEverySurvey)
  }

  const filterSurveyList = (surveyList: SurveyInfoTool.SurveyListItem[]): SurveyInfoTool.SurveyListItem[] => {
    return surveyList.filter(listItem => listItem.isActive && listItem.type)
  }

  if (!isLoading && !isSurveyListCreated) {
    const automationConfs = resolved[0]
    const triggerConfs = resolved[1]
    const surveyTemplates = resolved[2]
    const surveyStats = resolved[3]
    if (surveyStats && surveyStats.surveyStatuses) {
      const surveyList = createSurveyList(automationConfs, triggerConfs, surveyTemplates, surveyStats)
      setSurveyList(surveyList)
    }
    setIsSurveyListCreated(true)
  }

  return (
    <div className='survey-info'>
      <br />
      {isLoading || !isSurveyListCreated ? <LoadingIndicator /> :
        <>
          <div className="row survey-info-show-all-checkbox">
            <input
              id="survey-info-show-all-checkbox"
              name='emptyData' type='checkbox'
              checked={isShowingEverySurvey}
              onChange={handleCheckShowAll}
            />
            <label htmlFor="survey-info-show-all-checkbox" data-testid="showEmptyData">Show redirects and inactive surveys</label>
          </div>
          <SurveyInfoTable
            onOpenPreview={handleOpenRodal}
            surveyList={isShowingEverySurvey ? surveyList : filterSurveyList(surveyList)}
          />
          {isRodalOpen &&
            <SurveyInfoCoveringLetter
              onClose={handleCloseRodal}
              item={selectedItem}
            />
          }
        </>
      }
    </div>
  )
}

export default SurveyInfo