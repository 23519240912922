import { REPORTMODULES, moduleTypes } from '../react-constants/moduleTypes'
import { dedupe, deepCopy } from '../../utils'
import { CSSCONSTANTS } from '../react-constants/styles'
import { dashboardTemplateStorage } from '../stores/useDbTemplateConfig'

function getKpiIdsFromDashboardSettings(conf) {
  if (!conf || !conf.modules || !Array.isArray(conf.modules))
    throw new Error('Invalid dashboard configuration')
  let result = []

  loopCurrentModuleListAndPopulateKpis(conf.modules, result)
  return dedupe(result.filter((val, i) => !isNaN(val)))
}

function loopCurrentModuleListAndPopulateKpis(modules, result) {
  for (let i = 0; i < modules.length; i++) {
    let currentModule = modules[i] || {}
    if (isSupportedModule(currentModule)) {
      getKpisFromModule(currentModule, result)
    }
  }
}

function getKpisFromModule(module, result) {
  if (
    module.type === moduleTypes.WHEEL ||
    module.type === moduleTypes.PATHLINE ||
    module.type === moduleTypes.LISTNUMERIC
  ) {
    addCustomerPathKpisToResult(result)
  } else if (module.type === moduleTypes.GROUP && module.modules && module.modules.length > 0) {
    loopCurrentModuleListAndPopulateKpis(module.modules, result)
  } else {
    if (module.selections) {
      // numerics
      if (module.type === moduleTypes.FOURFIELDER && module.selections.length === 1) {
        Object.values(module.selections[0]).forEach((kpiId) => {
          result.push(kpiId.toString())
        })
      } else {
        Object.keys(module.selections).forEach((kpiId) => {
          result.push(kpiId.toString())
        })
      }
    } else if (module.settings && module.settings.kpis) {
      // tabular
      module.settings.kpis.map((kpi) => result.push(kpi.id.toString()))
    } else if (module.KPI && !isNaN(module.KPI)) {
      // freq
      result.push(module.KPI.toString())
    } else if (module.query) {
      // this is the way every module should be
      // open module works by automatically selecting every KPI if none are selected
      if (
        module.type === moduleTypes.OPEN &&
        (!module.query.kpis || module.query.kpis.length === 0)
      ) {
        module.query.kpis = []
        addEveryOpenKpiToResult(result)
      }
      // some modules have KPIs as objects whereas some have them as numbers
      module.query.kpis &&
        module.query.kpis.map((kpi) => result.push(isNaN(kpi) ? kpi.id.toString() : kpi))
    }
  }
}

function addEveryOpenKpiToResult(result) {
  const openKpis = openKpiStorage.get('openKpis')
  if (!openKpis || !openKpis.kpis) return
  for (let i = 0; i < openKpis.kpis.length; i++) {
    result.push(openKpis.kpis[i].id.toString())
  }
}

function addFiltersToEveryModuleInDashboardConfiguration(conf, startDate, endDate, whereMeta) {
  if (!conf || !conf.modules || !Array.isArray(conf.modules))
    throw new Error('Invalid dashboard configuration')
  let copyOfConf = deepCopy(conf)

  let [modulesWithQueryAsKey, modulesWithQueryAsSettings] = [
    ...collectReferencesToModuleQuerySettings(copyOfConf),
  ]
  attachNewStyleFilters(modulesWithQueryAsKey, startDate, endDate, whereMeta)
  attachOldStyleFilters(modulesWithQueryAsSettings, startDate, endDate, whereMeta)

  return copyOfConf
}

function collectReferencesToModuleQuerySettings(conf) {
  let referencesToNewStyleQueries = [],
    referencesToOldStyleQueries = []

  for (let i = 0; i < conf.modules.length; i++) {
    let currentModule = conf.modules[i]
    if (isSupportedModule(currentModule)) {
      if (currentModule.query) {
        referencesToNewStyleQueries.push(currentModule.query)
      } else if (currentModule.settings && currentModule.settings.length > 0) {
        referencesToOldStyleQueries.push(currentModule.settings)
        if (
          currentModule.type === moduleTypes.GROUP &&
          currentModule.modules &&
          currentModule.modules.length > 0
        ) {
          // Not necessary yet? When is, enable
          // collectReferencesToModuleQuerySettings(currentModule)
        }
      } else {
        console.log(
          `I have no idea where to find the filters of this module: ${currentModule.type}`,
        )
      }
    }
  }

  return [referencesToNewStyleQueries, referencesToOldStyleQueries]
}

function attachNewStyleFilters(queryConfs, startDate, endDate, whereMeta) {
  for (let conf of queryConfs) {
    conf['start_date'] = startDate
    conf['end_date'] = endDate
    conf['where_meta'] = { ...conf['where_meta'], ...whereMeta }
  }
}

function attachOldStyleFilters(querySettings, startDate, endDate, whereMeta) {
  let separatedFilters = []
  for (let meta in whereMeta) {
    separatedFilters.push({ name: meta, value: whereMeta[meta] })
  }

  for (let i = 0; i < querySettings.length; i++) {
    separatedFilters.map((f) => querySettings[i].push(f))
    for (let j = 0; j < querySettings[i].length; j++) {
      if (querySettings[i][j].name === 'startDate') querySettings[i][j].value = startDate
      else if (querySettings[i][j].name === 'endDate') querySettings[i][j].value = endDate
    }
  }
}

function isSupportedModule(module) {
  let type = module && module.type ? module.type : ''

  if (type !== '') {
    return REPORTMODULES.includes(type)
  } else return false
}

function replaceDynamicMetasInTextBoxes(text, filters, dynamicFilters) {
  if (!filters || Object.keys(filters).length === 0) return text

  let result = text
  try {
    for (let key in filters) {
      let values = filters[key]
      if (text.includes('{' + key + '}')) {
        if (values.length === 1) {
          result = result.replace('{' + key + '}', values)
        } else {
          result = result.replace(
            '{' + key + '}',
            values.slice(0, -1).join(', ') + ' & ' + values.slice(-1),
          )
        }
      }
    }
  } catch (e) {
    // most likely empty filters; ignore
  } finally {
    // recurse with another set of filters
    if (dynamicFilters) result = replaceDynamicMetasInTextBoxes(result, dynamicFilters, null)
    return result
  }
}

function numberOfModulesDoneLoading() {
  let modulesDoneLoading = 0
  let readyModules = document.getElementsByClassName(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
  if (readyModules && readyModules.length) modulesDoneLoading = readyModules.length
  return modulesDoneLoading
}

function getReportTemplate() {
  const dbtemplate = dashboardTemplateStorage.get('dbtemplate')
  for (let i in dbtemplate) {
    if (!Array.isArray(dbtemplate[i])) continue

    let template = dbtemplate[i][0]
    if (template && template.is_report_template) return template
  }
  return null
}

function toTimeString(hour, minute) {
  const resHour = hour < 10 ? '0' + hour : hour.toString()
  const resMinute = minute < 10 ? '0' + minute : minute.toString()
  return `${resHour}:${resMinute}`
}

function formatDay(num) {
  return `${num}${getOrdinalIndicatorForDay(num)}`
}

function getOrdinalIndicatorForDay(num) {
  switch (num) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    case 21:
      return 'st'
    case 22:
      return 'nd'
    case 23:
      return 'rd'
    case 31:
      return 'st'
    default:
      return 'th'
  }
}

function toFormatString(format) {
  if (!format) return
  switch (format.toLowerCase()) {
    case 'png':
      return 'Image'
    case 'ppt':
      return 'PowerPoint'
    case 'pdf':
      return 'PDF'
    default:
      return
  }
}

export {
  getKpiIdsFromDashboardSettings,
  addFiltersToEveryModuleInDashboardConfiguration,
  isSupportedModule,
  replaceDynamicMetasInTextBoxes,
  numberOfModulesDoneLoading,
  getReportTemplate,
  toTimeString,
  formatDay,
  toFormatString,
}
