import React from 'react'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import useMetaKeys from '../../../../../stores/useMetaKeys'
import { infoSelectedComparisonMetaValue } from '../FrequencyNotifications'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'

const MetaKeyDropdown = () => {
  const { filteredMetaKeys: metaKeys } = useMetaKeys()
  return (
    <>
      {metaKeys && (
        <DropdownInput
          settingsName='numericKpiMetaGrouping'
          data_testid='numericKpiMetaGrouping'
          id='numericKpiMetaGrouping'
          options={metaKeys}
          title={
            <TextWithInfo
              text={'Comparison meta'}
              info={infoSelectedComparisonMetaValue}
              tooltipDirection='left'
            />
          }
        />
      )}
    </>
  )
}

export default MetaKeyDropdown
