import { useEffect } from 'react'
import { useRadarData } from '../RadarContext/RadarDataContext'
import { useRadarSettings } from '../RadarContext/RadarSettingsContext'
import {
  BuildChartDataProps,
  OpenAnswersCountByMeta,
  OpenAnswersCountByTopic,
  RadarDataMode,
  Sentiment,
} from '../radarTypes'

const BuildChartData = ({ radarCounts, allTopics }: BuildChartDataProps) => {
  const setRadarData = useRadarData()[1]
  const [{ radarDataMode, radarMetaKeyValues }] = useRadarSettings()

  useEffect(() => {
    if (!allTopics || allTopics?.length === 0 || !radarCounts) return

    const handleCountsByTopic = () => {
      const countsByTopic: OpenAnswersCountByTopic[] = [
        {
          topic: 'Uncategorized',
          positiveCount: 0,
          negativeCount: 0,
          neutralCount: 0,
          uncategorizedCount: 0,
        },
      ]

      // create counts for all topics
      allTopics.forEach((topic) => {
        countsByTopic.push({
          topic: topic,
          positiveCount: 0,
          negativeCount: 0,
          neutralCount: 0,
          uncategorizedCount: 0,
        })
      })

      // sentiment count calculations for each topic
      radarCounts.results.forEach((answerCount) => {
        const answerHasTopics =
          answerCount &&
          answerCount.topic &&
          answerCount.topic.length > 0 &&
          !answerCount.topic.includes('uncategorized')
        const answerHasSentiments =
          answerCount &&
          answerCount.sentiment &&
          answerCount.sentiment.length > 0 &&
          !answerCount.sentiment.includes('uncategorized')

        if (!answerHasTopics) answerCount.topic = ['Uncategorized']
        if (!answerHasSentiments) answerCount.sentiment = [Sentiment.UNCATEGORIZED]

        answerCount?.topic?.forEach((topic: string) => {
          const topicIndex = countsByTopic.findIndex((countByTopic) => countByTopic.topic === topic)

          if (topicIndex > -1 && answerCount.sentiment) {
            answerCount.sentiment.forEach((sentiment) => {
              const count = answerCount.count ? answerCount.count : 0
              switch (sentiment) {
                case Sentiment.POSITIVE:
                  countsByTopic[topicIndex].positiveCount += count
                  break
                case Sentiment.NEUTRAL:
                  countsByTopic[topicIndex].neutralCount += count
                  break
                case Sentiment.NEGATIVE:
                  countsByTopic[topicIndex].negativeCount += count
                  break
                case Sentiment.UNCATEGORIZED:
                  countsByTopic[topicIndex].uncategorizedCount += count
                  break
                default:
                  countsByTopic[topicIndex].uncategorizedCount += count
                  break
              }
            })
          }
        })
      })

      setRadarData((prev) => ({
        ...prev,
        countsByTopic: countsByTopic,
      }))
    }

    const handleCountsByMeta = () => {
      if (!radarMetaKeyValues) return

      const countsByMeta: OpenAnswersCountByMeta[] = [
        {
          topic: 'Uncategorized',
          countByMetaValue: radarMetaKeyValues.map((metaValue) => {
            return {
              metaValue: metaValue,
              positiveCount: 0,
              negativeCount: 0,
              neutralCount: 0,
              uncategorizedCount: 0,
            }
          }),
        },
      ]

      // create counts for all topics with meta values
      allTopics.forEach((topic) => {
        countsByMeta.push({
          topic: topic,
          countByMetaValue: radarMetaKeyValues.map((metaValue) => {
            return {
              metaValue: metaValue,
              positiveCount: 0,
              negativeCount: 0,
              neutralCount: 0,
              uncategorizedCount: 0,
            }
          }),
        })
      })

      // sentiment count calculations for each topic and meta value
      radarCounts.results.forEach((answerMetaCount) => {
        const answerHasTopics =
          answerMetaCount &&
          answerMetaCount.topic &&
          answerMetaCount.topic.length > 0 &&
          !answerMetaCount.topic.includes('uncategorized')
        const answerHasSentiments =
          answerMetaCount &&
          answerMetaCount.sentiment &&
          answerMetaCount.sentiment.length > 0 &&
          !answerMetaCount.sentiment.includes('uncategorized')

        if (!answerHasTopics) answerMetaCount.topic = ['Uncategorized']
        if (!answerHasSentiments) answerMetaCount.sentiment = [Sentiment.UNCATEGORIZED]

        answerMetaCount?.topic?.forEach((topic) => {
          const topicIndex = countsByMeta.findIndex((countByMeta) => countByMeta.topic === topic)

          if (topicIndex > -1) {
            const metaValueIndex = countsByMeta[topicIndex].countByMetaValue.findIndex(
              (metaValue) => metaValue.metaValue?.toLowerCase() === answerMetaCount.meta_value,
            )

            if (metaValueIndex > -1 && answerMetaCount.sentiment) {
              answerMetaCount.sentiment.forEach((sentiment) => {
                const count = answerMetaCount.count ? answerMetaCount.count : 0
                switch (sentiment) {
                  case Sentiment.POSITIVE:
                    countsByMeta[topicIndex].countByMetaValue[metaValueIndex].positiveCount += count
                    break
                  case Sentiment.NEUTRAL:
                    countsByMeta[topicIndex].countByMetaValue[metaValueIndex].neutralCount += count
                    break
                  case Sentiment.NEGATIVE:
                    countsByMeta[topicIndex].countByMetaValue[metaValueIndex].negativeCount += count
                    break
                  case Sentiment.UNCATEGORIZED:
                    countsByMeta[topicIndex].countByMetaValue[metaValueIndex].uncategorizedCount +=
                      count
                    break
                  default:
                    countsByMeta[topicIndex].countByMetaValue[metaValueIndex].uncategorizedCount +=
                      count
                    break
                }
              })
            }
          }
        })
      })
      setRadarData((prev) => ({
        ...prev,
        countsByMeta: countsByMeta,
      }))
    }

    if (radarDataMode === RadarDataMode.META) {
      handleCountsByMeta()
    } else {
      handleCountsByTopic()
    }
  }, [radarCounts])

  return null
}

export default BuildChartData
