import { cloneDeep } from 'lodash'
import {
  FilterArray,
  convertFilterArrayToFilterObject,
  convertFilterObjectToFilterArray,
  getFilterArrayEndDate,
  getFilterArrayStartDate,
} from '../../../../react-services/filterService'
import { useGroupModule } from './contexts/GroupContext'
import { CustomFilter } from './groupModuleTypes'
import useGroupModuleMetas from './useGroupModuleMetas'

const useNestedModuleActions = () => {
  const [{ module: parentGroupModule, parentFilters }] = useGroupModule()
  const { handleMergingMetas } = useGroupModuleMetas()

  const getFiltersAccordingToFilterType = (filterType?: CustomFilter): FilterArray => {
    const newParentFilters = cloneDeep(
      convertFilterArrayToFilterObject(parentGroupModule?.settings || []),
    )
    const prevParentFilters = parentFilters?.length
      ? convertFilterArrayToFilterObject(parentFilters)
      : undefined

    if (filterType === CustomFilter.MERGE) {
      newParentFilters.where_meta = handleMergingMetas(
        newParentFilters.where_meta || {},
        prevParentFilters?.where_meta || {},
      )
      newParentFilters.start_date = getFilterArrayStartDate(parentFilters || [])
      newParentFilters.end_date = getFilterArrayEndDate(parentFilters || [])
    }

    if (!filterType || filterType === CustomFilter.NONE) {
      newParentFilters.start_date = prevParentFilters?.start_date || newParentFilters.start_date
      newParentFilters.end_date = prevParentFilters?.end_date || newParentFilters.end_date
      newParentFilters.where_meta = prevParentFilters?.where_meta || newParentFilters.where_meta
    }

    if (filterType === CustomFilter.CUSTOM_TIMEFRAME) {
      newParentFilters.where_meta = prevParentFilters?.where_meta || {}
    }

    return convertFilterObjectToFilterArray(newParentFilters)
  }

  return {
    getFiltersAccordingToFilterType,
  }
}

export default useNestedModuleActions
