import { $filter } from 'ngimport'
import _ from 'underscore'
import { moduleTypes } from '../react-constants/moduleTypes'
import { COLORCONSTANTS } from '../react-constants/styles'
import { MODULECONSTANTS } from '../react-constants/modules'
import { isMultilevel } from '../react-services/authService'
import { calculateMidValueForColorAxis, listDataPointValuesUnderAxisLabels } from './moduleService'
import { cloneDeep, isUndefined } from 'lodash'

function chartOptionsService() {
  var imageReportFontSize = '16px'
  var imageReportFontSizeBig = '20px'

  function gettype(module) {
    return module.type ? module.type : module
  }

  function configs(module, type) {
    switch (gettype(type ? type : module)) {
      case moduleTypes.RADAR:
        return radarconf(module)

      case 'column':
      case moduleTypes.BAR:
        return columnconf(module)

      case moduleTypes.FREQUENCY:
        var conf = columnconf(module)
        if (conf.xAxis) conf.xAxis = {}
        if (module.chartType && module.chartType === 'pie') {
          conf.chart.type = 'pie'
          conf.xAxis.lineWidth = 0
        } else {
          conf.xAxis.lineWidth = 1
          conf.legend = {
            enabled: false,
          }
        }
        conf.percentage = module.percentage
        var datalabels = conf.plotOptions.column.dataLabels
        datalabels.formatter = freqDataLabelFormatter
        conf.series = [
          {
            data: [],
          },
        ]
        return conf

      case 'bubble':
      case moduleTypes.FOURFIELDER:
        return {
          chart: {
            type: 'bubble',
            plotBorderWidth: 0,
            zooming: {
              type: 'xy',
            },
            spacingLeft: 1,
            spacingRight: 1,
          },
          legend: {
            enabled: true,
            maxHeight: 90,
            itemMarginTop: 4,
            itemMarginBottom: 0,
          },
          lang: {
            noData: 'Please, select data to display',
          },
          title: null,
          subtitle: null,
          tooltip: tooltipFormat(gettype(module)),
          xAxis: {
            min:
              module && module.limitValues && !isNaN(module.limitValues.Xmin)
                ? module.limitValues.Xmin
                : null,
            max:
              module && module.limitValues && !isNaN(module.limitValues.Xmax)
                ? module.limitValues.Xmax
                : null,
            lineWidth: 0,
            gridLineWidth: 0,
            tickWidth: 0,
            title: {
              text: '',
            },
            plotLines: [
              {
                color: MODULECONSTANTS.PLOT_BORDER_COLOR,
                dashStyle: 'solid',
                width: 1,
                value: undefined,
                label: {
                  rotation: 0,
                  y: 15,
                  style: {
                    fontStyle: 'italic',
                  },
                  text: '',
                },
                zIndex: 3,
              },
            ],
          },
          yAxis: {
            min:
              module && module.limitValues && !isNaN(module.limitValues.Ymin)
                ? module.limitValues.Ymin
                : null,
            max:
              module && module.limitValues && !isNaN(module.limitValues.Ymax)
                ? module.limitValues.Ymax
                : null,
            lineWidth: 0,
            gridLineWidth: 0,
            tickWidth: 0,
            title: {
              text: '',
            },
            maxPadding: 0.2,
            x: -30,
            plotLines: [
              {
                color: MODULECONSTANTS.PLOT_BORDER_COLOR,
                dashStyle: 'solid',
                width: 1,
                value: undefined,
                label: {
                  align: 'right',
                  style: {
                    fontStyle: 'italic',
                  },
                  text: '',
                  x: -10,
                },
                zIndex: 3,
              },
            ],
          },
          plotOptions: {
            bubble: {
              minSize: 40,
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return dataPointIsGrouped(this.point) ? this.point.name : this.series.name
                },
              },
            },
          },
          credits: {
            enabled: false,
          },
          series: [],
        }

      case 'heatmap':
      case moduleTypes.TABLE:
        var coloraxis = getColorAxisSettings()
        if (module && module.limitValues) {
          coloraxis.min = module.limitValues.min
          coloraxis.max = module.limitValues.max
          if (coloraxis.stops)
            coloraxis.stops[1][0] = calculateMidValueForColorAxis(module.limitValues)
        }
        if (coloraxis.dataClasses && module.limitValues)
          coloraxis.dataClasses = attachLimitValuesToDataClasses(module.limitValues)
        return {
          chart: {
            type: 'heatmap',
            spacingLeft: 1,
            spacingRight: 1,
          },
          exporting: {
            csv: {
              columnHeaderFormatter: function (series, key, keyLength) {
                if (key === 'y') {
                  return 'Data'
                }
                if (key === 'value') {
                  return series.name
                }
              },
            },
            sourceWidth: 1800,
            sourceHeight: 675,
            scale: 1.5,
            chartOptions: {
              plotOptions: {
                heatmap: {
                  dataLabels: {
                    showN: true,
                    module: module,
                    formatter: tableDataLabelFormatter,
                  },
                },
              },
            },
          },
          lang: {
            noData: 'Please, select data to display',
          },
          title: null,
          subtitle: null,
          colorAxis: coloraxis,
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 34,
            symbolHeight: 230,
            itemMarginTop: 10, // prevents text from cutting off at the top of the legend
          },
          tooltip: tooltipFormat(module.type, module.yAxis),
          plotOptions: {
            series: {
              turboThreshold: 0,
            },
            heatmap: {
              dataLabels: {
                enabled: true,
                style: {
                  fontWeight: 'bold',
                  fontSize: '15px',
                  textShadow: 'none',
                  textOutline: 'none',
                },
                module: module,
                formatter: tableDataLabelFormatter,
              },
            },
          },
          yAxis: {
            title: null,
            categories: [],
          },
          xAxis: {
            title: {
              text: null,
            },
            categories: [],
            crosshair: true,
            labels: {
              formatter: function () {
                if (this.value.length > 50) {
                  return this.value.slice(0, 50) + '...'
                }
                return this.value
              },
            },
          },
          credits: {
            enabled: false,
          },
          series: [],
        }

      case moduleTypes.MAP:
        var colorAxis = getColorAxisSettings()
        if (colorAxis.stops)
          colorAxis.stops[1][0] = calculateMidValueForColorAxis(module.limitValues)
        if (colorAxis) {
          colorAxis.min = module && module.limitValues ? module.limitValues.min : undefined
          colorAxis.max = module && module.limitValues ? module.limitValues.max : undefined
        }
        if (colorAxis && colorAxis.dataClasses !== undefined)
          colorAxis.dataClasses = attachLimitValuesToDataClasses(module.limitValues)
        return {
          chart: {
            type: 'map',
            spacingLeft: 1,
            spacingRight: 1,
          },
          title: null,
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              verticalAlign: 'bottom',
            },
          },
          colorAxis: colorAxis,
          legend: {
            title: {
              text: '',
            },
            floating: true,
            backgroundColor: 'rgba(255,255,255,0.3)',
            symbolWidth: 300,
            y: -30,
          },
          plotOptions: {
            series: {
              states: {
                hover: {
                  color: '#BADA55',
                },
              },
              dataLabels: {
                formatter: function () {
                  var format = this.point.format
                  var value = this.point.value ? this.point.value : this.y
                  if (format === 'n' || format === 'sum') {
                    return $filter('numberDecimalInt')(value)
                  } else {
                    return $filter('numberDecimal')(value)
                  }
                },
              },
            },
          },
          tooltip: tooltipFormat(gettype(module), module.yAxis),
          credits: {
            enabled: false,
          },
          xAxis: {},
        }

      case moduleTypes.LINE:
      case moduleTypes.PATHLINE:
        return lineconf(module)

      default:
        return {}
    }
  }

  function multilevelconfigs(module, type) {
    var conftype = gettype(type ? type : module)
    switch (conftype) {
      case moduleTypes.LINE:
      case moduleTypes.PATHLINE:
        var options = configs(module, type)
        // char title
        if (options.title) options.title.align = 'left'
        // chart subtitle
        if (options.subtitle) options.subtitle = null
        // legend items
        options.legend = _.extend(options.legend, {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginBottom: 6,
        })
        options.xAxis = _.extend(configs(module, type).xAxis, {
          gridLineWidth: 0,
          tickWidth: 0,
        })
        options.yAxis = _.extend(configs(module, type).yAxis, {
          gridLineWidth: 0,
          title: null,
        })
        return options

      case 'column':
      case moduleTypes.BAR:
        var emptyaxistitle = true
        var optionsCol = configs(module, type)
        // legend items
        optionsCol.legend = _.extend(optionsCol.legend, {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginBottom: 6,
          symbolHeight: 16,
          symbolWidth: 16,
          symbolRadius: 0,
        })
        optionsCol.plotOptions.column = _.extend(optionsCol.plotOptions.column, {
          maxPointWidth: 140, // max column width
          groupPadding: 0.01, // spacing between column groups
          pointPadding: 0.01, // spacing between columns inside group
          dataLabels: _.extend(
            optionsCol.plotOptions.column.dataLabels
              ? optionsCol.plotOptions.column.dataLabels
              : {},
            {
              color: 'black',
            },
          ),
        })
        var yaxis = configs(module, type).yAxis
        yaxis.gridLineWidth = 0
        yaxis.title = emptyaxistitle ? null : yaxis.title
        var xaxis = _.extend(configs(module, type).xAxis, {
          gridLineWidth: 0,
          tickWidth: 0,
        })
        optionsCol.yAxis = yaxis
        optionsCol.xAxis = xaxis
        return optionsCol

      case moduleTypes.FREQUENCY:
        var optionsFreq = multilevelconfigs(module, 'column')
        if (optionsFreq.xAxis) optionsFreq.xAxis = {}
        if (module.chartType && module.chartType === 'pie') {
          optionsFreq.chart.type = 'pie'
          optionsFreq.xAxis.lineWidth = 0
        } else {
          optionsFreq.xAxis.lineWidth = 1
          optionsFreq.legend = {
            enabled: false,
          }
        }
        var datalabels = optionsFreq.plotOptions.column.dataLabels
        datalabels.formatter = freqDataLabelFormatter
        return optionsFreq

      case 'bubble':
      case moduleTypes.FOURFIELDER:
        var optionsBubble = configs(module, type)
        optionsBubble.chart.plotBorderWidth = 0 // plot area border width
        var xAxis = configs(module, type).xAxis
        xAxis.title.offset = 5
        xAxis.lineWidth = 0 // x-axis line width
        xAxis.tickWidth = 0
        xAxis.labels = {
          autoRotation: 0,
          padding: 0,
          formatter: function () {
            return this.isFirst || this.isLast ? this.value : ''
          },
        }
        xAxis.gridLineWidth = 0 // grid lines inside plot area
        xAxis.plotLines[0].width = 1 // plot line width
        xAxis.plotLines[0].dashStyle = 'solid'
        xAxis.plotLines[0].color = '#cccccc'
        var yAxis = configs(module, type).yAxis
        yAxis.title.offset = 10
        yAxis.lineWidth = 0 // y-axis line width
        yAxis.tickWidth = 0
        yAxis.labels = {
          padding: 0,
          formatter: function () {
            return this.isFirst || this.isLast ? this.value : ''
          },
        }
        yAxis.gridLineWidth = 0 // grid lines inside plot area
        yAxis.plotLines[0].width = 1 // plot line width
        yAxis.plotLines[0].dashStyle = 'solid'
        yAxis.plotLines[0].color = '#cccccc'
        return {
          options: optionsBubble,
          xAxis: xAxis,
          yAxis: yAxis,
        }

      case moduleTypes.MAP:
        var optionsMap = configs(module, type)
        return {
          legend: {
            floating: true,
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            margin: 0,
            padding: 4,
            valueDecimals: 1,
            symbolRadius: 0,
            symbolHeight: 18,
            symbolWidth: 18,
            itemMarginBottom: 0,
            itemMarginTop: 0,
          },
          colors: [COLORCONSTANTS.TRAFFICLIGHT_COLORS_2.mid],
          plotOptions: {
            series: _.extend(configs(module, type).plotOptions.series, {
              nullColor: '#4B5055',
            }),
          },
          mapNavigation: _.extend(optionsMap.mapNavigation ? optionsMap.mapNavigation : {}, {
            enableMouseWheelZoom: false,
          }),
        }

      case 'heatmap':
      case moduleTypes.TABLE:
        var optionsHeatmap = configs(module, type)
        optionsHeatmap.plotOptions.heatmap = _.extend(
          optionsHeatmap.plotOptions.heatmap ? optionsHeatmap.plotOptions.heatmap : {},
          {
            borderWidth: 1,
            borderColor: 'white',
            states: {
              hover: {
                enabled: false,
              },
            },
            dataLabels: _.extend(
              optionsHeatmap.plotOptions.heatmap.dataLabels
                ? optionsHeatmap.plotOptions.heatmap.dataLabels
                : {},
              {
                style: {
                  fontWeight: 'normal',
                  fontSize: '15px',
                },
              },
            ),
          },
        )
        optionsHeatmap.legend = {
          align: 'left',
          verticalAlign: 'bottom',
          valueDecimals: 1,
          symbolRadius: 0,
          symbolHeight: 18,
          symbolWidth: 18,
        }
        optionsHeatmap.colors = [COLORCONSTANTS.TRAFFICLIGHT_COLORS_2.mid]
        optionsHeatmap.xAxis = _.extend(configs(module, type).xAxis, {
          tickWidth: 0,
          gridLineWidth: 0,
        })
        optionsHeatmap.yAxis = _.extend(configs(module, type).yAxis, {
          gridLineWidth: 0,
        })
        return optionsHeatmap

      default:
        return {}
    }
  }

  function freqDataLabelFormatter() {
    var isPercentage =
      this.series &&
      this.series.chart &&
      this.series.chart.options &&
      this.series.chart.options.percentage
    var suffix = isPercentage ? '%' : ''
    var value = isPercentage ? $filter('numberDecimal')(this.y) : this.y
    return this.point && this.point.data && this.point.data.n === 0 ? '' : value + suffix
  }

  function tableDataLabelFormatter(input) {
    var module = input && input.module ? input.module : null
    var color = this.point.color
    var format = module && module.yAxis ? module.yAxis : 'avg'
    var showN = input && input.showN && format !== 'n' ? true : false
    var value =
      format === 'avg'
        ? $filter('numberDecimal')(this.point.value)
        : $filter('numberDecimalInt')(this.point.value)
    value = this.point.value !== undefined && this.point.value !== null ? value : ''
    var valueN = this.point.data && this.point.data.n ? this.point.data.n : ''
    var textcolor =
      color === COLORCONSTANTS.TRAFFICLIGHT_COLORS_2.min ||
      color === COLORCONSTANTS.TRAFFICLIGHT_COLORS_2.max
        ? 'white'
        : 'black'
    var whiteShadow = textcolor === 'black'
    if (this.point.data && this.point.data.inFuture) {
      textcolor = textcolor === 'black' ? 'rgb(100,100,100)' : textcolor
      var bgcolor = color
      var r, g, b
      if (bgcolor && bgcolor.indexOf('#') !== -1) {
        var hex = bgcolor.replace('#', '')
        r = parseInt(hex.substr(0, 2), 16)
        g = parseInt(hex.substr(2, 2), 16)
        b = parseInt(hex.substr(4, 2), 16)
      } else {
        bgcolor = bgcolor.replace(/[^0-9,]+/g, '')
        r = parseInt(bgcolor.split(',')[0], 10)
        g = parseInt(bgcolor.split(',')[1], 10)
        b = parseInt(bgcolor.split(',')[2], 10)
      }
      bgcolor = 'rgba(' + r + ',' + g + ',' + b + ', 0.7)'
      this.point.shapeArgs.fill = bgcolor
      this.point.options.color = bgcolor
      var cssClass = whiteShadow ? 'class="blacktext" ' : ''
      return (
        '<div style="color: ' +
        textcolor +
        ';text-align:center;"' +
        cssClass +
        '>' +
        value +
        '<br><span style="font-size:0.75em;">(trend)</span></div>'
      )
    }
    var valueHtml = '<div style="color: ' + textcolor + '"' + cssClass + '>' + value + '</div>'
    var valueNHtml = showN
      ? '<br><div style="color: ' + textcolor + '"' + cssClass + '>n:' + valueN + '</div>'
      : ''
    return valueHtml + valueNHtml
  }

  function tooltipFormat(type, dataFormat) {
    if (!dataFormat) {
      dataFormat = 'avg'
    }
    var tooltip = {
      useHTML: true,
      style: {
        padding: 0,
      },
      formatter: function () {
        var name = ''
        var header = ''
        var format = this.point.format ? this.point.format : dataFormat
        var dataPoint = !isUndefined(this.point.value)
          ? this.point.value
          : this.point.data === undefined
          ? ''
          : this.point.data[format]
        dataPoint =
          format === 'avg'
            ? $filter('numberDecimal')(dataPoint)
            : $filter('numberDecimalInt')(dataPoint)
        var dataToShow =
          '<td>' + format + '</td><td id="tooltip-value" width="100%"><b>' + dataPoint + '</b></td>'
        var nSuffix =
          format !== 'n' && this.point.data && this.point.data.n
            ? '<td>n</td><td id="tooltip-n"><b>' + this.point.data.n + '</b></td>'
            : ''
        var dataPointValues =
          '<table style="max-width:160px;"><tr><td>' +
          (this.point.data && this.point.inFuture ? '<b>trend</b> ' : '') +
          '</td>' +
          dataToShow +
          nSuffix +
          '</tr></table>'
        switch (type) {
          case 'heatmap':
          case moduleTypes.TABLE:
            header = this.series.yAxis.categories[this.point.y]
              ? this.series.yAxis.categories[this.point.y]
              : header
            name = this.series.xAxis.categories[this.point.x]
              ? this.series.xAxis.categories[this.point.x]
              : name
            break
          case moduleTypes.FOURFIELDER:
          case 'bubble':
            var isGrouped = dataPointIsGrouped(this.point)
            name = isGrouped
              ? this.point.name
                ? this.point.name
                : header
              : this.series.name
              ? this.series.name
              : name
            header = isGrouped && this.series.name ? this.series.name : ''
            var xAxis =
              this.series.xAxis.axisTitle && this.series.xAxis.axisTitle.textStr
                ? this.series.xAxis.axisTitle.textStr + ':'
                : 'x:'
            var yAxis =
              this.series.yAxis.axisTitle && this.series.yAxis.axisTitle.textStr
                ? this.series.yAxis.axisTitle.textStr + ':'
                : 'y:'
            var value_x =
              dataFormat === 'avg'
                ? $filter('numberDecimal')(this.point.x)
                : $filter('numberDecimalInt')(this.point.x)
            var value_y =
              dataFormat === 'avg'
                ? $filter('numberDecimal')(this.point.y)
                : $filter('numberDecimalInt')(this.point.y)
            dataPointValues =
              this.point.data === undefined
                ? ''
                : '<table style="min-width:100px; max-width:160px; margin-top:4px;"><tr><td>' +
                  xAxis +
                  '</td><td>' +
                  yAxis +
                  '</td></tr>' +
                  '<tr><td width="100%" style="font-weight:bold" data-cy="bubbleChartTooltipValueX">' +
                  value_x +
                  '</td>' +
                  '<td style="font-weight:bold" data-cy="bubbleChartTooltipValueY">' +
                  value_y +
                  '</td></tr></table>'
            break
          case moduleTypes.MAP:
            header = this.series.name ? this.series.name : header
            name = this.point.name ? this.point.name : name
            break
          case moduleTypes.FREQUENCY:
            name = this.key
            var showPercentages = this.series.chart.options.percentage
            var prefix
            var suffix
            var value
            if (showPercentages) {
              prefix = ''
              suffix = '%'
              value = $filter('numberDecimal')(this.point.y)
            } else {
              prefix = 'n: '
              suffix = ''
              value = this.point.y
            }
            dataPointValues = prefix + value + suffix
            break
          default:
            header = this.series.xAxis.categories[this.point.x]
              ? this.series.xAxis.categories[this.point.x]
              : header
            name = this.series.name ? this.series.name : name
        }
        var tooltipHeader =
          header === '' ? '' : '<span style="font-size: 10px">' + header + '</span></br>'
        return (
          '<div style="max-width: 400px; overflow: hidden; text-overflow: ellipsis">' +
          tooltipHeader +
          '<span style="color:' +
          this.point.color +
          '">\u25CF</span>' +
          name +
          '<br/>' +
          dataPointValues +
          '</div>'
        )
      },
    }
    return tooltip
  }

  function dataPointIsGrouped(point) {
    return point.name !== 'All'
  }

  var lineconf = function (module) {
    return {
      chart: {
        type: 'line',
        spacingLeft: 1,
        spacingRight: 1,
      },
      legend: {
        enabled: true,
        padding: 0,
        itemMarginTop: 2,
        itemMarginBottom: 0,
        maxHeight: 90,
      },
      plotOptions: {
        series: {
          dataLabels: {
            formatter: function () {
              var format = this.point.format
              var value = this.point.value ? this.point.value : this.y
              if (format === 'n' || format === 'sum') {
                return $filter('numberDecimalInt')(value)
              } else {
                return $filter('numberDecimal')(value)
              }
            },
          },
        },
      },
      lang: {
        noData: 'Please, select data to display',
      },
      exporting: {
        sourceWidth: 1800,
        sourceHeight: 675,
        scale: 1.5,
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
      },
      title: null,
      subtitle: null,
      tooltip: tooltipFormat(gettype(module), module.yAxis),
      yAxis: {
        title: {
          text: '',
        },
        plotLines: [
          {
            color: 'red',
            dashStyle: 'dash',
            width: 1,
            zIndex: 4,
            label: {
              style: {
                color: 'red',
              },
              text: 'Target',
            },
          },
        ],
      },
      xAxis: {
        title: {
          text: null,
        },
        categories: [],
        crosshair: true,
        showEmpty: true,
      },
      credits: {
        enabled: false,
      },
      series: [],
    }
  }

  var columnconf = function (module) {
    return {
      chart: {
        type: 'column',
        spacingLeft: 1,
        spacingRight: 1,
      },
      legend: {
        itemMarginTop: 2,
        itemMarginBottom: 0,
        maxHeight: 90,
        padding: 0,
        symbolRadius: 0,
      },
      plotOptions: {
        column: {
          minPointLength: 2,
          dataLabels: {
            enabled: true,
            formatter: defaultDataLabelFormatter(module),
          },
        },
        bar: {
          minPointLength: 2,
          dataLabels: {
            enabled: true,
            align: 'right',
            formatter: defaultDataLabelFormatter(module),
          },
        },
      },
      lang: {
        noData: 'Please, select data to display',
      },
      title: null,
      subtitle: null,
      tooltip: tooltipFormat(gettype(module), module.yAxis),
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          formatter: function () {
            var value = this.value
            var suffix =
              this.chart && this.chart.options && this.chart.options.percentage ? '%' : ''
            return value + suffix
          },
        },
      },
      xAxis: {
        categories: [],
        crosshair: true,
      },
      credits: {
        enabled: false,
      },
      series: [],
    }
  }

  var radarconf = function (module) {
    return {
      chart: {
        polar: true,
        type: 'area',
      },
      legend: {
        maxHeight: 90,
        symbolRadius: 0,
        margin: 50,
      },
      plotOptions: {
        series: {
          fillOpacity: 0.5,
          minPointLength: 2,
          dataLabels: {
            enabled: true,
            padding: 0,
            formatter: defaultDataLabelFormatter(module),
          },
        },
      },
      lang: {
        noData: 'Please, select data to display',
      },
      title: null,
      subtitle: null,
      tooltip: tooltipFormat(gettype(module), module.yAxis),
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
      },
      xAxis: {
        categories: [],
        crosshair: true,
        labels: {
          useHTML: false,
          reserveSpace: true,
          formatter: radarAxisLabelFormatter(module),
        },
      },
      credits: {
        enabled: false,
      },
      series: [],
    }
  }

  function radarAxisLabelFormatter(module) {
    return function () {
      let label = this.value
      if (listDataPointValuesUnderAxisLabels(module)) {
        let categoryValues = []
        if (this && this.chart && this.chart.series && this.chart.series.length) {
          for (let i = 0; i < this.chart.series.length; i++) {
            let series = this.chart.series[i]
            let name = series.name ? series.name : ''
            let value = 'n/a'
            if (series && series.options && series.options.data && series.options.data.length) {
              let category = _.findWhere(series.options.data, {
                category: this.value,
              })
              if (category && category.value !== null && category.value !== undefined) {
                value = $filter('numberDecimal')(category.value)
              }
            }
            if (value) {
              categoryValues.push({
                name: name,
                value: value,
              })
            }
          }
        }
        let categoryValuesTable = ''
        if (categoryValues.length) {
          for (let categoryValue of categoryValues) {
            categoryValuesTable +=
              '<br><span class="radar-chart-axis-label-value">' +
              categoryValue.name +
              ' ' +
              categoryValue.value +
              '</span>'
          }
        }
        label += categoryValuesTable
      }
      return label
    }
  }

  function defaultDataLabelFormatter(module) {
    return function () {
      var suffix =
        this.series &&
        this.series.chart &&
        this.series.chart.options &&
        this.series.chart.options.percentage
          ? '%'
          : ''
      var format = module.yAxis ? module.yAxis : 'avg'
      var value =
        format === 'avg' ? $filter('numberDecimal')(this.y) : $filter('numberDecimalInt')(this.y)
      return this.point.data && this.point.data.n === 0 ? '' : value + suffix
    }
  }

  function getColorAxisSettings() {
    var colors = isMultilevel()
      ? COLORCONSTANTS.TRAFFICLIGHT_COLORS_2
      : COLORCONSTANTS.TRAFFICLIGHT_COLORS
    if (!isMultilevel())
      return {
        stops: [
          [0.0, colors.min],
          [0.5, colors.mid],
          [1.0, colors.max],
        ],
        startOnTick: false,
        endOnTick: false,
        reversed: false,
      }
    if (isMultilevel())
      return {
        dataClassColor: 'category',
        dataClasses: [],
      }
  }

  function attachLimitValuesToDataClasses(limitValues) {
    var dataClasses = []
    if (!limitValues) return dataClasses
    if (validLimit(limitValues.min)) var minAsString = $filter('number')(limitValues.min)
    if (validLimit(limitValues.mid)) var midAsString = $filter('number')(limitValues.mid)
    if (validLimit(limitValues.max)) var maxAsString = $filter('number')(limitValues.max)
    if (validLimit(limitValues.mid)) {
      dataClasses.push({
        to: limitValues.mid,
        name: (validLimit(limitValues.min) ? minAsString : '') + ' ... ' + midAsString,
        color: COLORCONSTANTS.TRAFFICLIGHT_COLORS_2.min,
      })
    }
    if (validLimit(limitValues.mid) || validLimit(limitValues.max)) {
      dataClasses.push({
        from: validLimit(limitValues.mid) ? limitValues.mid : undefined,
        to: validLimit(limitValues.max) ? limitValues.max : undefined,
        name:
          (validLimit(limitValues.mid) ? midAsString : '') +
          ' ... ' +
          (validLimit(limitValues.max) ? maxAsString : ''),
        color: COLORCONSTANTS.TRAFFICLIGHT_COLORS_2.mid,
      })
    }
    if (validLimit(limitValues.max)) {
      dataClasses.push({
        from: limitValues.max,
        name: maxAsString + ' ... ',
        color: COLORCONSTANTS.TRAFFICLIGHT_COLORS_2.max,
      })
    }
    return dataClasses
  }

  function validLimit(limitvalue) {
    return limitvalue !== undefined && limitvalue !== null
  }

  // Default chart options by module type
  var getChartOptions = function (module, type) {
    // merge config if in multilevel mode
    var tmpconfig = _.extend(
      configs(module, type),
      isMultilevel() ? multilevelconfigs(module, type) : {},
    )
    var config = cloneDeep(tmpconfig)
    return config
  }

  // Custom chart options
  function getCustomChartOptions(name) {
    switch (name) {
      case 'npscomponents':
        return npsComponentsChartOptions()
      case 'npstimeseries':
        return npsTimeseriesChartOptions()
      case 'customerpathtimeseries':
        return customerpathTimeseriesChartOptions()
      case 'screen':
        return screenChartOptions()
      case 'screenline':
        return screenLineChartOptions()
      case 'imagereports':
        return imagereportsChartOptions()
      case 'imagereportsline':
        return imagereportsLineChartOptions()
      case 'imagereportsradar':
        return imagereportsRadarChartOptions()
      default:
        return {}
    }
  }

  // Chart options for NPS v2 FREQ chart (NPS components)
  var npsComponentsChartOptions = function () {
    return {
      legend: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 100,
        labels: {
          format: '{value} %',
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return $filter('numberDecimal')(this.y) + '%'
            },
          },
        },
      },
      tooltip: {
        useHTML: true,
        style: {
          padding: 0,
        },
        formatter: function () {
          var name = ''
          name = this.key ? this.key : name
          var value = $filter('numberDecimal')(this.point.y)
          var dataPointValues =
            '<table style="min-width:100px; max-width:160px; margin-top:4px;">' +
            '<tr><td width="100%"><b>' +
            value +
            '% </b></td>' +
            '</tr></table>'
          var tooltipName = name
            ? '<span style="color:' + this.point.color + '">\u25CF</span>' + name
            : ''
          return (
            '<div style="max-width: 400px; overflow: hidden; text-overflow: ellipsis">' +
            tooltipName +
            '<br/>' +
            dataPointValues +
            '</div>'
          )
        },
      },
    }
  }

  // Chart options for NPS v2 LINE chart (NPS timeseries)
  var npsTimeseriesChartOptions = function () {
    return {
      chart: {
        type: 'line',
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        min: -100,
        max: 100,
      },
    }
  }

  // Chart options for Customerpath v2 LINE chart
  var customerpathTimeseriesChartOptions = function () {
    return {
      chart: {
        type: 'line',
      },
      yAxis: {
        min: 0,
      },
    }
  }

  var screenChartOptions = function () {
    return {
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
          marker: {
            enabled: true,
            radius: 2.5,
          },
        },
      },
      mapNavigation: {
        enabled: false,
      },
    }
  }

  var screenLineChartOptions = function () {
    return {
      exporting: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false,
          },
          marker: {
            enabled: true,
            radius: 2.5,
          },
        },
        heatmap: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      yAxis: {
        gridLineColor: 'rgba(160,160,160,0.48)',
        gridLineWidth: 1.5,
        gridLineDashStyle: 'dot',
      },
    }
  }

  var imagereportsChartOptions = function () {
    return {
      colors: MODULECONSTANTS.SERIES_COLORS_REPORT,
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: imageReportFontSize,
            },
          },
          marker: {
            enabled: true,
            radius: 2.5,
          },
        },
      },
      mapNavigation: {
        enabled: false,
      },
      legend: {
        itemStyle: {
          fontSize: imageReportFontSizeBig,
        },
      },
      yAxis: {
        labels: {
          style: {
            fontSize: imageReportFontSize,
          },
        },
      },
      xAxis: {
        labels: {
          style: {
            fontSize: imageReportFontSize,
          },
        },
      },
    }
  }

  var imagereportsLineChartOptions = function () {
    return {
      colors: MODULECONSTANTS.SERIES_COLORS_REPORT,
      exporting: {
        enabled: false,
      },
      plotOptions: {
        line: {
          lineWidth: 4,
          dataLabels: {
            enabled: false,
          },
          marker: {
            enabled: true,
            radius: 2.5,
          },
        },
        heatmap: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      yAxis: {
        gridLineColor: 'rgba(160,160,160,0.48)',
        gridLineWidth: 1.5,
        gridLineDashStyle: 'dot',
        labels: {
          style: {
            fontSize: imageReportFontSize,
          },
        },
      },
      legend: {
        itemStyle: {
          fontSize: imageReportFontSizeBig,
        },
      },
      xAxis: {
        lineColor: '#000000',
        labels: {
          style: {
            fontSize: imageReportFontSize,
          },
        },
      },
    }
  }

  var imagereportsRadarChartOptions = function () {
    return {
      colors: MODULECONSTANTS.SERIES_COLORS_REPORT,
      exporting: {
        enabled: false,
      },
      plotOptions: {
        line: {
          lineWidth: 4,
          dataLabels: {
            enabled: false,
          },
          marker: {
            enabled: true,
            radius: 2.5,
          },
        },
        heatmap: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      yAxis: {
        gridLineColor: 'rgba(160,160,160,0.48)',
        gridLineWidth: 1.5,
        gridLineDashStyle: 'dot',
        labels: {
          style: {
            fontSize: imageReportFontSize,
          },
        },
      },
      legend: {
        itemStyle: {
          fontSize: imageReportFontSizeBig,
        },
      },
      xAxis: {
        labels: {
          style: {
            fontSize: imageReportFontSize,
          },
        },
      },
    }
  }

  return {
    getChartOptions: getChartOptions,
    getCustomChartOptions: getCustomChartOptions,
    freqDataLabelFormatter: freqDataLabelFormatter,
  }
}

export default chartOptionsService
