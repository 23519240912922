import React from 'react';

const LoadingIndicator = ({size = "big", hasMaxContainer = true}) => {
  return (
    /*
    This spinner positions itself horizontally and vertically in the middle of 
    the container that it's given by default.
    */
    <div data-testid="loadingIndicator" className="valign-wrapper loading-indicator-container" style={hasMaxContainer ? {height: '100%', width: '100%'} : {}}>
      <div className="center" style={{width: '100%'}}>
        <div className={`preloader-wrapper active ${size}`}>
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div><div className="gap-patch">
              <div className="circle"></div>
            </div><div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingIndicator;