/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import css from './GenericButton.module.scss';

export type GenericButtonProps = {
  [x: string]: any;
  text?: string | undefined;
  icon?: string | null | undefined;
  iconPosition?: string | null | undefined;
  isLight?: boolean | undefined;
  isDisabled?: boolean | undefined;
  isFlat?: boolean | undefined;
  onClick: any;
  float?: any;
  title?: string;
  customClasses?: any[] | undefined;
  data_testid?: string;
}

const GenericButton = ({
  text = 'Submit', 
  icon = null, 
  iconPosition = 'left', 
  isLight = false, 
  isDisabled = false, 
  isFlat = false, 
  onClick, 
  float = 'right',
  customClasses = [],
  title='',
  data_testid='mainButton',
  ...rest
}: GenericButtonProps) => {
  
  const DEFAULT_ICON_SIZE = '24px';

  function getClasses() {
    const result = customClasses
    isDisabled && result.push('disabled');
    !isDisabled && isLight && result.push('light');
    !isDisabled && isFlat && result.push('flat');
    // !isDisabled && result.push('waves-effect')

    return result.join(' ');
  }

  function handleClick(e:React.MouseEvent<HTMLButtonElement,MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    if (!isDisabled) {
      onClick(e);
    }
  }
  
  return (
    <>
      <button
        data-testid={data_testid}
        className={`${css.btn} ${getClasses()}`}
        onClick={(e) => handleClick(e)}
        style={{ float }}
        title={title}
        {...rest}
      >
        {icon && <i className={`material-icons material-symbols-outlined ${iconPosition}`}>{icon}</i>}
        {text && <span
          className={css.btn_text}
          style={{ lineHeight: DEFAULT_ICON_SIZE }}
        >
          {text}
        </span>}
      </button>
    </>
  )
}


export default GenericButton;