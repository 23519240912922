import React from 'react'
import PreviewIcon from '../../../common/Buttons/PreviewIcon'
import SaveIcon from '../../../common/Buttons/SaveIcon'
import css from './TabButtonBar.module.scss'

type TabButtonBarProps = {
  onClickPreview: () => void
  onClickSave: () => void
}

const TabButtonBar = ({ onClickPreview, onClickSave }: TabButtonBarProps) => {
  return (
    <div className={css.tbl_buttons}>
      <PreviewIcon onClick={onClickPreview} customClasses={[css.action_btn]} />
      <SaveIcon onClick={onClickSave} customClasses={[css.action_btn, css.action_btn_disabled]} />
    </div>
  )
}

export default TabButtonBar
