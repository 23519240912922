import React, { useEffect, useRef, useState } from 'react'
import { useSettingsTools } from '../SettingsContext'
import RequiredStar from './RequiredStar'

import css from './TextInput.module.scss'
import modal_css from './../SettingsModal.module.scss'

type TextInputProps = {
  settingsName: string
  id?: string
  title: string | JSX.Element
  type?: 'text' | 'number'
  classNames?: string
  data_testid?: string
  placeholder?: string
  isModuleProperty?: boolean
  width?: 'half' | 'third' | 'full'
  min?: number
  isRequired?: boolean
  defaultValue?: string | number
  isDisabled?: boolean
  [prop: string]: unknown
}

const SAVE_TIMEOUT = 400
const TextInput = ({
  settingsName,
  title,
  data_testid,
  placeholder,
  classNames = '',
  isModuleProperty = false,
  type,
  width = 'half',
  isRequired = false,
  id,
  min,
  defaultValue,
  isDisabled,
  ...rest
}: TextInputProps) => {
  const [inputValue, setInputValue] = useState<number | string>('')
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  let value =
    typeof defaultValue === 'string' || typeof defaultValue === 'number' ? defaultValue : ''

  if (
    !isModuleProperty &&
    module &&
    module.options &&
    module.options[settingsName as keyof unknown] !== null &&
    module.options[settingsName as keyof unknown] !== undefined
  )
    value = module.options[settingsName as keyof unknown]
  if (
    isModuleProperty &&
    module &&
    module[settingsName as keyof unknown] !== null &&
    module[settingsName as keyof unknown] !== undefined
  )
    value = module[settingsName as keyof unknown]

  let isPresent = true
  if (
    isRequired &&
    (value === null ||
      value === undefined ||
      value === '' ||
      (typeof value !== 'number' && typeof value !== 'string'))
  ) {
    isPresent = false
  }

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value)
    }
  }, [])

  const getWidthClass = () => {
    if (width === 'half') {
      return modal_css.half
    }
    if (width === 'third') {
      return modal_css.third
    }
    if (width === 'full') {
      return modal_css.max
    }
    return modal_css.half
  }

  const handleInputBlur = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.currentTarget.blur()
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!module) return
      let value: string | number = e.currentTarget.value
      if (min !== undefined && (value === undefined || value === null || value === '')) value = min
      setInputValue(value)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)

      timeoutRef.current = setTimeout(() => {
        if (isModuleProperty) {
          saveModule({ ...module, [settingsName]: value })
        } else {
          const newModule = {
            ...module,
            options: { ...module.options, [settingsName as keyof unknown]: value },
          }
          saveModule(newModule)
        }
      }, SAVE_TIMEOUT)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <div className={`${classNames} ${getWidthClass()} ${isDisabled ? css.disabled : ''}`}>
      {title}
      {isRequired && !isDisabled && <RequiredStar />}
      <input
        disabled={!!isDisabled}
        onKeyDown={handleInputBlur}
        data-testid={data_testid || ''}
        type={type || 'text'}
        placeholder={placeholder || ''}
        value={inputValue === undefined ? '' : inputValue}
        id={id || ''}
        onChange={handleOnChange}
        className={`${css.input} ${isPresent ? '' : modal_css.required}`}
        min={min}
        {...rest}
      />
    </div>
  )
}

export default TextInput
