import React from 'react'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { RadarModule, RadarModuleSettings } from '../radarTypes'

import css from './RadarSettings.module.scss'

const RadarTopicList = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const radarModule = module as RadarModule
  const options = module?.options ? (module.options as RadarModuleSettings) : null
  const { answerCategories, loading, error } = useOpenCategories()

  const allTopics =
    answerCategories && answerCategories.topic && answerCategories.topic.length > 0
      ? answerCategories.topic
      : []
  const selectedTopics = options?.selectedTopics || []
  const hasError = !!error

  const handleClick = (topicName: string) => {
    if (!radarModule) return
    let newTopics = [] as string[]
    if (selectedTopics.includes(topicName)) {
      newTopics = selectedTopics.filter((topic) => topic !== topicName)
    }
    if (!selectedTopics.includes(topicName)) {
      newTopics = selectedTopics.concat(topicName)
    }
    saveModule({
      ...radarModule,
      options: { ...radarModule.options, selectedTopics: newTopics },
    })
  }

  return (
    <div>
      {loading && <LoadingIndicator />}
      {hasError && <h5>{error}</h5>}
      {!loading && !hasError && (
        <div className={css.radar_topic_list}>
          {allTopics.sort().map((topic) => (
            <CheckBoxItem
              settingsName=''
              key={topic}
              text={topic}
              data_testid={'topic-checkbox-item'}
              id={topic}
              onClickLabel={handleClick}
              checked={selectedTopics.includes(topic)}
            />
          ))}
          <CheckBoxItem
            settingsName=''
            key={'Uncategorized'}
            text={'Uncategorized'}
            data_testid='topic-checkbox-item'
            id={'Uncategorized'}
            classNames={css.item}
            onClickLabel={handleClick}
            checked={selectedTopics.includes('Uncategorized')}
            isBlackAndBold
          />
        </div>
      )}
    </div>
  )
}

export default RadarTopicList
