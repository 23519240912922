import React from 'react'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { DataTypes, NumberTrendModule } from '../numbertrendTypes'

const ComparisonCheckBox = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const numberTrendModule = module as NumberTrendModule

  const handleSave = (newValue: boolean) => {
    if (!numberTrendModule) return
    const newModule: NumberTrendModule = {
      ...numberTrendModule,
      comparisonmode: newValue,
      options: {
        ...(numberTrendModule.options || {}),
        secondaryNumber: newValue ? '' : DataTypes.COUNT,
      },
    }
    saveModule(newModule)
  }
  return (
    <CheckBoxInput
      settingsName='comparisonmode'
      title='Card deck front'
      text='Comparison'
      data_testid='comparisonmodeInput'
      isRootProperty
      customSave={handleSave}
      defaultValue={false}
    />
  )
}

export default ComparisonCheckBox
