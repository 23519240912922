import React, { useEffect, useState } from 'react';
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable';
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator';
import css from './SelectableTable.module.scss';
import { MetaAndPosition } from '../inspectorTypes';

type SelectableTableProps = {
  metas: string[] | null,
  header: string,
  setSelectedMetaKeys: React.Dispatch<React.SetStateAction<MetaAndPosition[]>>,
  setSelectableMetaKeys: React.Dispatch<React.SetStateAction<string[] | null>>,
}

const SelectableTable = ({
  metas,
  header,
  setSelectedMetaKeys,
  setSelectableMetaKeys
}: SelectableTableProps) => {
  const [disabled, setDisabled] = useState(false);
  const [rowsList, setRowsList] = useState<JSX.Element[] | null>(null);
  const [rowMetas,setRowMetas] = useState<string[] | null>(null);
  const [animateTarget, setAnimateTarget] = useState<{ target: string, direction: 'out' | 'in' } | null>(null);
  
  useEffect(() => {
    if (metas && rowMetas && metas.length - rowMetas.length > 1 && animateTarget) {
      const newMeta = metas.filter(meta => !rowMetas.includes(meta) && meta !== animateTarget.target)
      if (newMeta.length) setAnimateTarget({ target: newMeta[0], direction: 'in'});
      return;
    }
    if (metas && rowMetas && metas.length > rowMetas.length && !animateTarget) {
      const newMeta = metas.filter(meta => !rowMetas.includes(meta))
      if (newMeta.length) setAnimateTarget({ target: newMeta[0], direction: 'in'});
      return;
    } 
  }, [metas]);

  useEffect(() => {
    createTableRows();
  },[metas,animateTarget])

  const getAnimationClassName = (meta:string) => {
    if (animateTarget && animateTarget.target === meta && animateTarget.direction === 'out') {
      return css.animate_out
    }
    if (animateTarget && animateTarget.target === meta && animateTarget.direction === 'in') {
      return css.animate_in
    }
    return ''
  }

  const handleAnimationEnd = (metaKey:string) => {
    setAnimateTarget(null);
    if (animateTarget && animateTarget.direction === 'out') {
      setSelectableMetaKeys(prev => prev || [].filter(item => item !== metaKey));
      setSelectedMetaKeys(prev => handleAddingSelectedMeta(prev, metaKey));
    }
    setDisabled(false);
  }

  const handleClick = async (metaKey: string) => {
    if (disabled) return;
    setDisabled(true);
    setAnimateTarget({ target: metaKey, direction: 'out' })
  }

  const handleAddingSelectedMeta = (metaObjects: MetaAndPosition[], addedMetaKey: string): MetaAndPosition[] => {
    if (metaObjects.map(metaObject => metaObject.name).includes(addedMetaKey)) return metaObjects;
    const currentMaxPosition = metaObjects.length ? Math.max(...metaObjects.map(metaObject => metaObject.position)) : 0;
    return metaObjects.concat({ name: addedMetaKey, position: currentMaxPosition + 1 });
  }

  const createTableRows = () => {  
    if (metas) {     
      const rows = [] as JSX.Element[];
      metas.forEach(meta => rows.push(
        <tr
          key={meta}
          onClick={() => handleClick(meta)}
          className={`${getAnimationClassName(meta)}`}
          onAnimationEnd={() => handleAnimationEnd(meta)}
        >
          <td className={css.cell}>{meta}</td>
        </tr>))
      setRowsList(rows);
      setRowMetas(metas);
    }
  }

  return (
    <>
      {rowsList
        ?
        <GenericTable
          headers={<tr key={header}><th>{header}</th></tr>}
          rows={rowsList}
          useFixedLayout
          preSortedColumn={{ index: 0, isReversed: false }}
          hasFixedHeader

        />
        :
        <LoadingIndicator />
      }
    </>
  )
}


export default SelectableTable;