import React from 'react';
import Rodal from 'rodal';

import css from './DoubleModalSide.module.scss';

type DoubleModalSideProps = {
  onClose: () => void,
  children: React.ReactNode,
  closeOnEsc?: boolean,
  animation?: string,
  duration?: number,
  visible?: boolean,
  showMask?: boolean,
  data_testid?: string
}

const DoubleModalSide = ({
  onClose,
  children,
  closeOnEsc = true,
  animation = 'slideUp',
  duration = 200,
  visible = true,
  showMask = false,
  data_testid = 'DoubleModalSideModalCntr'
}: DoubleModalSideProps) => (

  <div className={css.cntr} data-testid={data_testid}>
    <Rodal
      closeOnEsc={closeOnEsc}
      animation={animation}
      duration={duration}
      visible={visible}
      showMask={showMask}
      onClose={onClose}
    >
      {children}
    </Rodal>
  </div>

)

export default DoubleModalSide;