import React from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { login } from './scripts/react-services/loginService'

const ManualSenderRedirect = () => {
  const IDKEY = 'wq-id'
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get(IDKEY)
  if (!token) return <Navigate to={'/'} />
  const info = Array.isArray(token) ? JSON.parse(atob(token[0])) : JSON.parse(atob(token))
  const descriptor = Object.getOwnPropertyDescriptor(info, 'uuid')
  if (!descriptor) return <Navigate to={'/'} />
  Object.defineProperty(info, 'password', descriptor)
  delete info['uuid']

  login(info).then(
    function () {
      return navigate('/manual')
    },
    function () {
      return navigate('/')
    },
  )

  return <div></div>
}

export default ManualSenderRedirect
