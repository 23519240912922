import React from 'react';

type ResetStylesContainerProps = {
  children: JSX.Element | JSX.Element[];
}

const ResetStylesContainer = ({ children }: ResetStylesContainerProps) => (
  <div className="reset-this-root">
    <>
      {children}
    </>
  </div>
)

export default ResetStylesContainer;