import { useEffect, useMemo, useRef, useState } from 'react'
import { KpiData, Query } from '../../types'
import { makeNonResettingObservable } from './nonResettingObservable'
import objectHash from 'object-hash'
import { getTenant, getUsername } from '../react-services/authService'
import { getNumericKpiCounts } from '../react-services/numericAnswerService'
import { errorFetchingNumericCounts } from './notificationMessages'
import { subMinutes } from 'date-fns'
import { useMountedState } from 'react-use'
import { debounce } from 'lodash'

const OUTDATED_DATA_TIME_MINS = 5
type NumericKpiDataStorageType = {
  counts: KpiData.NumericData
  timestamp: Date
} | null
const numericKpiDataStorage = makeNonResettingObservable<NumericKpiDataStorageType>(
  {},
  OUTDATED_DATA_TIME_MINS,
)

const enum LoadingState {
  INIT = 'init',
  LOADING = 'loading',
  REFETCHING = 'refetching',
  DONE = 'done',
}
const useNumericKpiCounts = (
  query: Query.Numeric | Query.SingleNumeric | null,
  FETCH_TIMEOUT = 100,
  resetWhenNewQuery = false,
) => {
  const isMounted = useMountedState()
  const fetchTimeoutRef = useRef<NodeJS.Timeout>()
  const tenant = getTenant() || ''
  const user = getUsername() || ''
  const key = objectHash(query || {}, { unorderedArrays: true, unorderedObjects: true })
  const [data, setData] = useState<{ counts: KpiData.NumericData; timestamp: Date } | null>(null)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<LoadingState>(LoadingState.INIT)

  const hasValidQuery = useMemo(() => {
    if (!query) return false
    const hasKpis =
      (query?.kpis && query.kpis.length) ||
      (query?.calculated_kpis && Object.keys(query?.calculated_kpis).length)
    if (!hasKpis) return false
    if (!query.start_date || !query.end_date) return false
    return true
  }, [query?.kpis, query?.calculated_kpis, query?.start_date, query?.end_date, user, tenant])

  useEffect(() => {
    const hasStoredData = !!numericKpiDataStorage.get(key)
    const hasData = !!data
    if (hasStoredData || !hasValidQuery) setLoading(LoadingState.DONE)
    else setLoading(hasData ? LoadingState.REFETCHING : LoadingState.LOADING)
  }, [data, key, hasValidQuery])

  useEffect(() => {
    const owner = numericKpiDataStorage.getOwner()
    if (!owner.tenant && !owner.user) numericKpiDataStorage.reset()
    if (owner.tenant !== tenant || owner.user !== user) numericKpiDataStorage.reset()
    numericKpiDataStorage.setOwner(tenant, user)
  }, [tenant, user])

  const prevKeyRef = useRef<string | null>(null)
  useEffect(() => {
    if (!user || !tenant) return
    const prevKey = prevKeyRef.current
    if (prevKey) numericKpiDataStorage.unsubscribe(setData, prevKey)
    const unsubscribe = numericKpiDataStorage.subscribe(setData, key)
    prevKeyRef.current = key
    return () => {
      unsubscribe()
    }
  }, [user, tenant, key])

  useEffect(() => {
    if (!hasValidQuery || !query || !user || !tenant) return setData(null)
    if (resetWhenNewQuery) setData(null)
    numericKpiDataStorage.removeOutDatedData()
    const relatedListeners = numericKpiDataStorage.getRelatedListeners(key)
    const hasListenersAlready = relatedListeners && relatedListeners.length > 1
    const storedData = numericKpiDataStorage.get(key)

    if (!hasListenersAlready && storedData) {
      const storeTime = storedData.timestamp
      const currentTime = new Date()
      const hasOutDatedData = storeTime < subMinutes(currentTime, OUTDATED_DATA_TIME_MINS)
      if (fetchTimeoutRef.current) clearTimeout(fetchTimeoutRef.current)
      if (hasOutDatedData) getNumericCounts(query, key)
      !hasOutDatedData && setData(storedData)
    }
    if (!hasListenersAlready && !storedData) getNumericCounts(query, key)
    if (hasListenersAlready && storedData) setData(storedData)

    return () => {
      getNumericCounts.cancel()
    }
  }, [key])

  const getNumericCounts = debounce((query: Query.Numeric | Query.SingleNumeric, key: string) => {
    return getNumericKpiCounts(query)
      .then((data) => {
        if (!isMounted()) return
        const timestamp = new Date()
        const newDataWithTimeStamp = { timestamp, counts: data }
        numericKpiDataStorage.set(newDataWithTimeStamp, key)
      })
      .catch(() => isMounted() && setError(errorFetchingNumericCounts))
  }, FETCH_TIMEOUT)

  return {
    numericCounts: data ? data.counts : null,
    timestamp: data ? data.timestamp : null,
    isLoading: loading === LoadingState.INIT || loading === LoadingState.LOADING,
    isRefetching: loading === LoadingState.REFETCHING,
    error,
  }
}

export default useNumericKpiCounts
