/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-materialize'
import LoadingIndicator from '../_shared/Infos/LoadingIndicator'
import { get, post } from '../../react-services/apiService'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { tCommon } from '../../../languages/i18n'
import LanguageSelect from '../../../languages/LanguageSelect'
import { Button, TextField as MUITextField, styled } from '@mui/material'

import css from './ResetPassword.module.scss'

const TextField = styled(MUITextField)({
  input: {
    marginBottom: '0px !important',
    borderBottom: 'none !important',
    boxShadow: 'none !important',
  },
  'input:focus': {
    borderBottom: 'none !important',
    boxShadow: 'none !important',
  },
  'input:active': {
    borderBottom: 'none !important',
    boxShadow: 'none !important',
  },
})
const ResetPassword = () => {
  const { token } = useParams()
  const location = useLocation()
  const route = location.pathname.split('/')[1]
  const header =
    route === 'resetpassword' ? tCommon('label.passwordReset') : tCommon('label.verifyAccount')
  const [verified, setVerified] = useState(false)
  const [password, setPassword] = useState('')
  const [ready, setReady] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorText, setErrorText] = useState<string | null>(null)
  const [passwordWasSet, setPasswordWasSet] = useState(false)

  // const minLength = !!token && !!token.startsWith('$') ? 12 : 10
  const minLength = 12
  useEffect(() => {
    verifyToken()
  }, [])

  const verifyToken = () => {
    setReady(true)
    get('GET_VERIFYTOKEN', { token }).then(
      () => {
        setVerified(true)
      },
      () => {
        setVerified(false)
      },
    )
  }

  const handlePasswordChange = (e: any, isConfirm: boolean) => {
    if (!isConfirm) {
      setPassword(e.target.value)
    } else {
      setConfirmPassword(e.target.value)
    }
  }

  const handleSubmit = () => {
    let errorText = null
    if (password !== confirmPassword) {
      errorText = 'error.passwordMismatch'
    }
    if (confirmPassword === '') {
      errorText = 'error.passwordConfirmEmpty'
    }
    if (password === '') {
      errorText = 'error.passwordEmpty'
    }
    if (errorText === null && password.length < minLength) {
      errorText = 'error.passwordTooShort'
    }
    if (errorText === null && password.replace(/[^a-zA-Z]/g, '').length === 0) {
      errorText = 'error.passwordAlphabet'
    }
    if (errorText === null && password.replace(/[\D]/g, '').length === 0) {
      errorText = 'error.passwordNumber'
    }
    if (errorText === null && password.replace(/[a-zA-Z0-9]/g, '').length === 0) {
      errorText = 'error.passwordSpecial'
    }
    if (errorText !== null) {
      setErrorText(errorText)
    } else {
      setErrorText(null)
      submitPassword()
    }
  }

  const submitPassword = () => {
    const payload = {
      token,
      password,
    }
    post('POST_RESETPASSWORD', payload).then(
      () => {
        setPasswordWasSet(true)
        toast.success(tCommon('info.passwordSet'))
      },
      () => {
        setErrorText('error.passwordSet')
        toast.error(tCommon('error.passwordSet'))
      },
    )
  }

  return (
    <div className={`set-password-container ${css.cntr}`}>
      <LanguageSelect />
      <Row>
        {!passwordWasSet ? (
          <Col s={6} offset='s3'>
            <Row>
              <Col s={12}>
                <h4 data-jt='headerText'>{header}</h4>
                {ready ? (
                  <Row>
                    {verified ? (
                      <>
                        <Row>
                          {tCommon('info.passwordRequirements1')}

                          <ul data-jt='passwordRequirements'>
                            <li>{tCommon('info.passwordRequirements2')}</li>
                            <li>{tCommon('info.passwordRequirements3')}</li>
                            <li>{tCommon('info.passwordRequirements4')}</li>
                            <li>{tCommon('info.passwordRequirements5')}</li>
                          </ul>
                          {errorText !== null && (
                            <Card className='red white-text'>
                              <span data-jt='errorCard'>{tCommon(errorText)}</span>
                            </Card>
                          )}
                        </Row>
                        <Row>
                          <TextField
                            id='pwd'
                            type='password'
                            required
                            label={tCommon('label.password')}
                            onChange={(e: any) => {
                              handlePasswordChange(e, false)
                            }}
                            sx={{ width: '100%' }}
                          />
                        </Row>
                        <Row>
                          <TextField
                            id='pwd2'
                            type='password'
                            required
                            label={tCommon('label.confirmPassword')}
                            onChange={(e: any) => {
                              handlePasswordChange(e, true)
                            }}
                            sx={{ width: '100%' }}
                          />
                        </Row>

                        <Row>
                          <Button
                            onClick={handleSubmit}
                            type='submit'
                            variant='contained'
                            size='large'
                          >
                            {tCommon('button.submit')}
                          </Button>
                        </Row>
                      </>
                    ) : (
                      <Col s={12}>
                        {tCommon('info.passwordResetExpired1')}
                        <br />
                        <br />
                        {tCommon('info.passwordResetExpired2')}{' '}
                        <a href='/#/?reset'>
                          {tCommon('info.passwordResetExpired3').toLocaleLowerCase()}
                        </a>
                        .
                      </Col>
                    )}
                  </Row>
                ) : (
                  <LoadingIndicator />
                )}
              </Col>
            </Row>
          </Col>
        ) : (
          <Col s={12}>
            <h4 className='green-text text-darken-3'>Success</h4>
            Password was set successfully. You may now proceed to <a href='/#/'>Login</a>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default ResetPassword
