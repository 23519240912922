/* eslint-disable @typescript-eslint/no-explicit-any */
// todo: delete this after testing is done
import { detailedDiff } from 'deep-object-diff'
import ENDPOINTS, {
  APIROOT,
  DISALLOWED_ENDPOINTS,
  EXPENSIVE_ENDPOINTS,
} from './react-constants/endpoints'
import { getTenant, isLimitedAccess } from './react-services/authService'
import LoggingService from './react-services/loggingService'
import { isEqual } from 'lodash'
import { tenantsUsingNewBackend } from './react-constants'

const IS_ENABLED = window.localStorage.getItem('disable-duplicator') ? false : false
const ORIGINAL_ROOT = APIROOT
const DUPLICATION_ROOT = 'https://testapi2.wheelq.com/api'
const EVENT_NAME = process.env.NODE_ENV === 'production' ? 'api-duplicate' : 'test-api-duplicate'
const MAX_EXPENSIVE_DUPLICATIONS = 1

const logger = LoggingService.GetLoggerFor('api-duplicator')

let expensiveDuplications = 0
async function duplicateFetchRequest(
  endpointName: string,
  url: string,
  requestInit: RequestInit,
  response: unknown,
): Promise<void> {
  try {
    if (!(response instanceof Response)) {
      logger.debug('Response is not compatible')
      return
    }

    if (!shouldProceed()) {
      return
    }

    if (!shouldProceedFetch(endpointName)) {
      return
    }

    const originalResponse = response.clone()

    if (!shouldProceedWithDuplication(originalResponse.status)) {
      return
    }

    const originalBody = await originalResponse.text()

    const duplicateResponse = await fetch(swapRoot(url), requestInit)

    if (isExpensiveEndpoint(endpointName)) {
      expensiveDuplications++
    }

    const duplicateBody = await duplicateResponse.text()

    const originalHeaders = Object.fromEntries(Array.from(originalResponse.headers.entries()))
    const duplicateHeaders = Object.fromEntries(Array.from(duplicateResponse.headers.entries()))
    /**
     * Apparently according to http spec content length has a special meaning
     * and doesn't always get sent with the response so it would give a false
     * positive
     */
    delete originalHeaders['content-length']
    delete duplicateHeaders['content-length']

    const areHeadersEqual = isEqual(originalHeaders, duplicateHeaders)
    let areBodiesEqual = originalBody === duplicateBody
    const areStatusesEqual = originalResponse.status === duplicateResponse.status

    if (areHeadersEqual && areBodiesEqual && areStatusesEqual) {
      logger.debug('responses are equal, no duplication needed')
      return
    }

    /**
     * JSON parsing is done after string equality check to avoid unnecessary operations
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    const duplicateBodyJSON = tryToGet((): Object => JSON.parse(duplicateBody))
    // eslint-disable-next-line @typescript-eslint/ban-types
    const originalBodyJSON = tryToGet((): Object => JSON.parse(originalBody))

    areBodiesEqual = isEqual(originalBodyJSON, duplicateBodyJSON)

    if (areHeadersEqual && areBodiesEqual && areStatusesEqual) {
      logger.debug('responses are equal, including deep object equality for body')
      return
    }

    const bodyDiff =
      originalBodyJSON && duplicateBodyJSON
        ? detailedDiff(originalBodyJSON, duplicateBodyJSON)
        : undefined
    const headerDiff = detailedDiff(originalHeaders, duplicateHeaders)

    const metadata: Record<string, string> = {
      url,
      method: requestInit.method ?? '',
      type: 'fetch',
      areBodiesEqual: `${areBodiesEqual}`,
      areHeadersEqual: `${areHeadersEqual}`,
      areStatusesEqual: `${areStatusesEqual}`,
      originalHeaders: JSON.stringify(originalHeaders),
      duplicateHeaders: JSON.stringify(duplicateHeaders),
      headerDiff: JSON.stringify(headerDiff),
      originalStatus: originalResponse.status.toString(),
      duplicateStatus: duplicateResponse.status.toString(),
      originalBody,
      duplicateBody,
    }

    if (bodyDiff) {
      metadata.bodyDiff = JSON.stringify(bodyDiff)
    }

    logger.log(EVENT_NAME, metadata)
  } catch (error: any) {
    logger.debug(
      'duplication failed',
      error instanceof Error ? { error: error.message } : undefined,
    )
  }
}

function isExpensiveEndpoint(endpoint: string): boolean {
  return EXPENSIVE_ENDPOINTS.includes(endpoint)
}

function shouldProceed() {
  const tenant = getTenant()
  if (!tenant) {
    logger.debug('tenant is not available')
    return false
  }

  /**
   * Don't duplicate for tenants using new backend
   */
  if (tenantsUsingNewBackend.includes(tenant)) {
    return false
  }

  /**
   * Only duplicate about 1 in 20 requests for other tenants
   */
  if (tenant !== 'test_frontend' && Math.random() > 0.05) {
    return false
  }

  /**
   * Limit expensive duplications
   */
  if (tenant !== 'test_frontend' && expensiveDuplications > MAX_EXPENSIVE_DUPLICATIONS) {
    return false
  }

  return true
}

function shouldProceedFetch(endpointName: string): boolean {
  if (DISALLOWED_ENDPOINTS.includes(ENDPOINTS[endpointName])) {
    return false
  }

  return true
}
/**
 * I assume if it's a limited access thingy then we should check
 * whether the new backend responds the same way but if it's just
 * an invalid token then we don't have to duplicate
 */
function shouldProceedWithDuplication(status: number): boolean {
  if (status === 401 || status === 403) {
    return Boolean(isLimitedAccess())
  }

  return true
}

function tryToGet<T>(fn: () => T): T | undefined {
  try {
    return fn()
  } catch (error: any) {
    return undefined
  }
}

function swapRoot(url: string) {
  return url.replace(ORIGINAL_ROOT, DUPLICATION_ROOT)
}

/**
 * query duplication is done in a promise so that it wont affect the normal flow
 * of the operation
 */
function scheduleFetchDuplication(
  endpointName: string,
  url: string,
  requestInit: RequestInit,
  response: unknown,
): Promise<void> {
  if (!IS_ENABLED) {
    return Promise.resolve()
  }

  if (!DUPLICATION_ROOT) {
    logger.debug('missing duplication root')
    return Promise.resolve()
  }

  return new Promise<void>((resolve) => {
    duplicateFetchRequest(endpointName, url, requestInit, response)
      .catch(console.error)
      .finally(() => resolve())
  })
}

const requestDuplicator = {
  scheduleFetchDuplication,
}

export default requestDuplicator
