import { getOpenKpis } from '../react-services/kpiService'
import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreCRUD, { ActionState } from './useStoreNonResetting'

export type idAndNameKpi = {
  id: number
  name: string
  grouping?: string | null
}

export const openKpiStorage = makeNonResettingObservable<idAndNameKpi[] | null>({})

const useOpenKpis = (doFetch = true) => {
  const storageKey = 'openKpis'
  const { tenant, user } = useStoreOwner(openKpiStorage)
  const {
    dataState: { data, error, state },
  } = useStoreCRUD(openKpiStorage, storageKey, user, tenant, doFetch, getOpenKpis)

  return {
    openKpis: data,
    isLoading: state === ActionState.LOADING,
    error,
  }
}

export default useOpenKpis
