/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import React from 'react'
import Rodal from 'rodal'
import { Row, Col, Button, Card, Icon } from 'react-materialize'
import { dedupe, deepCopy } from '../../../../../utils'
import Select, { MultiValue, SingleValue } from 'react-select'
import Creatable from 'react-select/creatable'
import { UserManagementTool } from '../../../../../types'

type Props = {
  user: UserManagementTool.User
  allGroups: { [id: string]: UserManagementTool.UserGroup }
  filters: string[]
  filtervalues: string[][]
  closeEditUser: () => void
  handleUpdate: (
    id: number,
    groups: { label: string; value: string; group: UserManagementTool.UserGroup }[],
    accessrights: { [name: string]: string[] },
    defaultfilters: { [name: string]: string[] },
  ) => void
}

type State = {
  allGroups: UserManagementTool.GroupOption[]
  groups: UserManagementTool.GroupOption[]
  inaccessibleFilters: string[]
  accessrights: { [name: string]: string[] | null[] }
  defaultfilters: { [name: string]: string[] | null[] }
  potentialConflicts: string[]
  errorsOfAccess: string[]
}

class EditUser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      allGroups: Object.keys(props.allGroups).map((key) => ({
        label: props.allGroups[key].name,
        value: props.allGroups[key].id,
        group: props.allGroups[key],
      })),
      groups: props.user.usergroups.map((g) => ({ label: g.name, value: g.id, group: g })),
      inaccessibleFilters: this.createInaccessibleFilters(),
      accessrights: props.user.filters ? this.modifyAccessRightsToState(props.user.filters) : {},
      defaultfilters: props.user.defaultfilters
        ? this.addDefaultFiltersToState(props.user.defaultfilters)
        : {},
      potentialConflicts: [],
      errorsOfAccess: [],
    }
    this.cleanUpBeforeSave = this.cleanUpBeforeSave.bind(this)
  }

  componentDidMount() {
    this.determinePossibilityForConflictingRightsAndFilters()
    if (this.state.inaccessibleFilters.length > 0) {
      this.setState({
        errorsOfAccess: dedupe(this.state.inaccessibleFilters),
      })
    }
  }

  modifyAccessRightsToState(
    rights: { [name: string]: string[] | null[] },
    isInitializationPhase = true,
  ): { [name: string]: string[] | null[] } {
    const rightsForState: { [name: string]: string[] | null[] } = {}
    for (const filter in rights) {
      if (!this.props.filters.includes(filter)) {
        continue
      }
      if (rights[filter].length === 0) {
        isInitializationPhase ? (rightsForState[filter] = [null]) : (rightsForState[filter] = [])
      } else {
        rightsForState[filter] = rights[filter]
      }
    }
    return rightsForState
  }

  addDefaultFiltersToState(filters: { [name: string]: string[] | null[] }): {
    [name: string]: string[] | null[]
  } {
    const filtersForState: { [name: string]: string[] | null[] } = {}
    for (const filter in filters) {
      if (this.props.filters.includes(filter)) {
        filtersForState[filter] = filters[filter]
      }
    }
    return filtersForState
  }

  createInaccessibleFilters() {
    const meta = { ...this.props.user.defaultfilters, ...this.props.user.filters }
    const inaccessibleFilters: string[] = []
    Object.keys(meta).forEach((key) => {
      if (!this.props.filters.includes(key)) inaccessibleFilters.push(key)
    })
    return inaccessibleFilters
  }

  determinePossibilityForConflictingRightsAndFilters() {
    const rightsWithPossibleConflict = []
    for (const ar in this.state.accessrights) {
      if (ar in this.state.defaultfilters) {
        rightsWithPossibleConflict.push(ar)
      }
    }
    this.setState({
      potentialConflicts: rightsWithPossibleConflict,
    })
  }

  handleGroupChange = (values: SingleValue<UserManagementTool.GroupOption>) => {
    const selectedValues = values as UserManagementTool.GroupOption
    if (selectedValues == null) {
      this.emptyGroupSelection()
    } else {
      const selectedGroup = selectedValues
      const selectedGroupArray = [selectedGroup]
      const [accessRights, defaultFilters] = [
        ...this.combineGroupFilteringOptions(selectedGroupArray),
      ]

      this.setState(
        {
          groups: selectedGroupArray,
          accessrights: this.modifyAccessRightsToState(accessRights, false),
          defaultfilters: this.addDefaultFiltersToState(defaultFilters),
        },
        () => {
          this.determinePossibilityForConflictingRightsAndFilters()
        },
      )
    }
  }

  emptyGroupSelection() {
    this.setState({
      groups: [],
      accessrights: {},
      defaultfilters: {},
    })
  }

  combineGroupFilteringOptions(groupValues: UserManagementTool.GroupOption[]) {
    const resultingAccessRights: { [name: string]: string[] | null[] } = {},
      resultingDefaultFilters: { [name: string]: string[] | null[] } = {}

    groupValues.forEach((selection: UserManagementTool.GroupOption) => {
      selection.group.accessrights.forEach((ar: string) => {
        resultingAccessRights[ar] = this.state.accessrights[ar] || []
      })
      selection.group.defaultfilters.forEach((df: string) => {
        resultingDefaultFilters[df] = this.state.defaultfilters[df] || []
      })
    })
    return [resultingAccessRights, resultingDefaultFilters]
  }

  handleAccessRightsChange(values: MultiValue<UserManagementTool.AccessRightOption>, ar: string) {
    const selectedAccessRights = values as UserManagementTool.AccessRightOption[]
    let hideFilter = false
    let allowEveryValue = false
    const arrayOfValues: string[] = []
    for (let i = 0; i < selectedAccessRights.length; i++) {
      if (selectedAccessRights[i].value == null) {
        hideFilter = true
        break
      } else if (selectedAccessRights[i].value === true) {
        allowEveryValue = true
        break
      } else {
        arrayOfValues.push(selectedAccessRights[i].value as string)
      }
    }
    const copyOfState = deepCopy(this.state.accessrights)

    copyOfState[ar] = hideFilter
      ? [null]
      : allowEveryValue
      ? this.props.filtervalues[this.props.filters.indexOf(ar)]
      : arrayOfValues
    this.setState(
      {
        accessrights: copyOfState,
      },
      () => {
        this.accommodateDefaultFilters(ar)
      },
    )
  }

  accommodateDefaultFilters(ar: string) {
    const overlappingDefaults: string[] | null[] = this.state.defaultfilters[ar]
    if (overlappingDefaults && overlappingDefaults.length > 0 && !overlappingDefaults[0] === null) {
      const overlappingDefaultsStrings = overlappingDefaults as string[]
      const newDefaults: { value: string | boolean }[] = []
      for (let i = 0; i < overlappingDefaults.length; i++) {
        if ((this.state.accessrights[ar] as string[]).includes(overlappingDefaultsStrings[i])) {
          newDefaults.push({ value: overlappingDefaultsStrings[i] })
        }
      }
      this.handleDefaultFilterChange(newDefaults, ar)
    } else return
  }

  handleDefaultFilterChange(
    values: MultiValue<UserManagementTool.DefaultFilterOption> | { value: string | boolean }[],
    df: string,
  ) {
    const arrayOfValues = []
    let selectEveryValue = false
    const selectedFilters = values as { value: string | boolean }[]
    for (let i = 0; i < selectedFilters.length; i++) {
      if (selectedFilters[i].value === true) {
        selectEveryValue = true
        break
      } else {
        arrayOfValues.push(selectedFilters[i].value)
      }
    }
    const copyOfState = deepCopy(this.state.defaultfilters)
    // when selecting every value, if access rights are set to this default filter, use those; otherwise use all filters
    copyOfState[df] = (
      selectEveryValue
        ? this.state.accessrights[df] && this.state.accessrights[df].length > 0
          ? this.state.accessrights[df]
          : this.props.filtervalues[this.props.filters.indexOf(df)]
        : arrayOfValues
    ) as string[]
    this.setState({
      defaultfilters: copyOfState,
    })
  }

  cleanUpBeforeSave() {
    // Select element uses [null] to denote a filter that's restricted entirely; change it back to [] before saving it to BE
    const newAccessRights: { [name: string]: string[] } = {}
    for (const right in this.state.accessrights) {
      // empty selection defaults to selecting every value
      if (this.state.accessrights[right].length === 0) {
        newAccessRights[right] = this.props.filtervalues[this.props.filters.indexOf(right)]
      } else if (
        this.state.accessrights[right].length === 1 &&
        this.state.accessrights[right][0] == null
      ) {
        newAccessRights[right] = []
      } else {
        newAccessRights[right] = this.state.accessrights[right] as string[]
      }
    }
    this.props.handleUpdate(
      this.props.user.id,
      this.state.groups,
      newAccessRights,
      this.state.defaultfilters as { [name: string]: string[] },
    )
  }

  render() {
    const { user, closeEditUser } = this.props

    const accessRights = () => {
      const result = []
      for (const accessright in this.state.accessrights) {
        let disableOptions = false
        // format values stored in state for use in Select
        const selectOptions: UserManagementTool.AccessRightOption[] = [
            { value: null, label: 'Restrict every value' },
          ],
          selections: UserManagementTool.AccessRightOption[] = []
        this.state.accessrights[accessright].forEach((applied: string | null | true) => {
          if (applied == null) {
            disableOptions = true
            selections.push({
              label: 'Access to every existing value is restricted.',
              value: applied,
            })
          } else if (applied === true) {
            selections.push({ label: 'Every existing value is accessible.', value: applied })
          } else {
            selections.push({ label: applied, value: applied })
          }
        })
        selectOptions.push({ value: true, label: 'Allow every value', isDisabled: disableOptions })
        this.props.filtervalues[this.props.filters.indexOf(accessright)].forEach((value) => {
          selectOptions.push({ label: value, value: value, isDisabled: disableOptions })
        })

        result.push(
          <Row key={`editAccessRight-${accessright}`}>
            <Col data-cy={`editAccessRight-${accessright}`} s={12}>
              <label>{accessright}</label>
              <Creatable
                className='react-select-default-styles'
                isMulti
                isClearable
                isSearchable={disableOptions ? false : true}
                closeMenuOnSelect={false}
                placeholder='Select values to show'
                options={selectOptions}
                value={selections}
                styles={{
                  multiValue: (styles, { data }: any) =>
                    data.value == null ? { ...styles, backgroundColor: 'indianred' } : styles,
                }}
                onChange={(
                  values: MultiValue<{ label: string; value: string | boolean | null }>,
                ) => {
                  this.handleAccessRightsChange(values, accessright)
                }}
              />
            </Col>
          </Row>,
        )
      }
      return result
    }

    const defaultFilters = () => {
      const result = []
      for (const defaultfilter in this.state.defaultfilters) {
        let isDisabled = false
        const selectOptions: UserManagementTool.DefaultFilterOption[] = [
            { value: true, label: 'Select every existing value' },
          ],
          selections: UserManagementTool.DefaultFilterOption[] = []
        this.state.defaultfilters[defaultfilter].forEach((applied: string | null) => {
          selections.push({ label: applied, value: applied })
        })
        let filterSet: string[] | null[] =
          this.props.filtervalues[this.props.filters.indexOf(defaultfilter)]
        if (defaultfilter in this.state.accessrights) {
          if (this.state.accessrights[defaultfilter].length > 0) {
            if (this.state.accessrights[defaultfilter][0] == null) {
              // disable all values
              isDisabled = true
            } else {
              // show only the values that are as access rights
              filterSet = this.state.accessrights[defaultfilter]
            }
          }
        }
        filterSet.forEach((value: string | null) => {
          selectOptions.push({ label: value, value: value, isDisabled: isDisabled })
        })
        result.push(
          <Row key={`selectDefaultFilter-${defaultfilter}`}>
            <Col data-cy={`selectDefaultFilter-${defaultfilter}`} s={12}>
              <label>{defaultfilter}</label>
              <Creatable
                className='react-select-default-styles'
                isMulti
                isClearable
                closeMenuOnSelect={false}
                isDisabled={isDisabled}
                placeholder={
                  isDisabled ? 'No access right' : 'Select automatically applied filters'
                }
                options={selectOptions}
                value={selections}
                onChange={(values: MultiValue<UserManagementTool.DefaultFilterOption>) =>
                  this.handleDefaultFilterChange(values, defaultfilter)
                }
              />
            </Col>
          </Row>,
        )
      }
      return result
    }

    const everySelectionHasValues = () => {
      for (const ar in this.state.accessrights)
        if (this.state.accessrights[ar] && this.state.accessrights[ar].length === 0) return false

      for (const df in this.state.defaultfilters) {
        if (this.state.defaultfilters[df] && this.state.defaultfilters[df].length === 0) {
          // if access rights have null value for a default filter, it means the DF disabled and selections can't be made
          if (
            this.state.accessrights[df] &&
            this.state.accessrights[df].length === 1 &&
            this.state.accessrights[df][0] == null
          )
            continue
          else return false
        }
      }

      return true
    }

    return (
      <div id='admin-user-tools-edit' data-testid='userToolsGroupedEditor'>
        <Rodal closeOnEsc animation='slideUp' visible={true} onClose={closeEditUser}>
          <h5 data-testid='userToolsGroupedEditorTitle'>
            Edit user <i>{user.name}</i>
          </h5>
          <br />
          <div>
            <h6 className='user-editor-subtitle'>Group</h6>
            <div data-cy='selectGroups'>
              <Select
                className='react-select-default-styles'
                isClearable
                closeMenuOnSelect
                name='groups'
                placeholder='Select a group'
                options={this.state.allGroups}
                value={this.state.groups}
                onChange={this.handleGroupChange}
                maxMenuHeight={200}
              />
            </div>
          </div>
          <br />

          {this.state.groups.length > 0 ? (
            <>
              {this.state.potentialConflicts.length > 0 && (
                <Card className='blue lighten-2'>
                  <Icon className='blue-text' small>
                    info
                  </Icon>
                  <span className='user-editor-warning blue-text text-darken-4'>
                    This group has overlap in its access rights and default filters.
                  </span>
                </Card>
              )}

              {this.state.errorsOfAccess.length > 0 && (
                <Card className='orange lighten-2'>
                  <Icon className='orange-text' small>
                    warning
                  </Icon>
                  <span className='user-editor-warning red-text text-darken-4'>
                    Following filters are no longer accessible for this tenant or user and cannot be
                    set as access rights or default filters:
                    <ul>
                      {this.state.errorsOfAccess.map((err, i) => (
                        <li key={i}>{err}</li>
                      ))}
                    </ul>
                  </span>
                </Card>
              )}

              <h6 className='user-editor-subtitle'>Access rights</h6>
              <div className='padding'>
                {this.state.accessrights && Object.keys(this.state.accessrights).length > 0 ? (
                  accessRights()
                ) : (
                  <div className='grouping-selection-info-box-smaller'>
                    This group doesn&apos;t apply restrictions.
                  </div>
                )}
              </div>
              <hr />
              <br />
              <h6 className='user-editor-subtitle'>Default filters</h6>
              <div className='padding edit-user-grouped-select-default-filters'>
                {this.state.defaultfilters && Object.keys(this.state.defaultfilters).length > 0 ? (
                  defaultFilters()
                ) : (
                  <div className='grouping-selection-info-box-smaller'>
                    This group doesn&apos;t designate default filters.
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className='grouping-selection-info-box'>
              Begin by selecting at least one group.
            </div>
          )}

          <div className='margin'>
            <Row className='center'>
              <Col s={5} offset='s1'>
                <Button
                  disabled={!everySelectionHasValues()}
                  data-cy='saveUser'
                  onClick={this.cleanUpBeforeSave}
                >
                  Save
                </Button>
              </Col>
              <Col s={5}>
                <Button data-cy='cancelEditUser' flat onClick={closeEditUser}>
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </Rodal>
      </div>
    )
  }
}

export default EditUser
