import { createStateContext } from 'react-use'
import { SummaryDataContext } from '../summaryTypes'

export const [useSummaryData, SummaryDataProvider] = createStateContext<SummaryDataContext>({
  summaryTitle: '',
  summaryAngles: [],
  summaryConf: null,
  saveSettings: () => ({}),
  metaKeysForFilters: [],
  summaries: [],
  summaryTypes: [],
})
