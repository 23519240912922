import React from 'react'
import { useSettingsTools } from './../SettingsContext'

import css from './ModuleMoveButtons.module.scss'

type ModuleMoveButtonsProps = {
  classNames?: string
}

const ModuleMoveButtons = ({ classNames }: ModuleMoveButtonsProps) => {
  const [{ moveModuleDown: standAloneMoveDown, moveModuleUp: standAloneMoveUp, module }] =
    useSettingsTools()

  function moveModuleUp() {
    if (module) return standAloneMoveUp(module)
  }
  function moveModuleDown() {
    if (module) return standAloneMoveDown(module)
  }

  return (
    <div className={`${classNames} ${css.cntr}`}>
      Module position
      <div className={css.btns_cntr}>
        <span data-testid='moveModuleUp' className={`${css.btn}`} onClick={moveModuleUp}>
          <label className={css.label}>move up</label>
          <i className={`material-icons ${css.icon}`}>arrow_drop_up</i>
        </span>
        <span data-testid='moveModuleDown' className={`${css.btn}`} onClick={moveModuleDown}>
          <i className={`material-icons ${css.icon}`}>arrow_drop_down</i>
          <label className={css.label}>move down</label>
        </span>
      </div>
    </div>
  )
}

export default ModuleMoveButtons
