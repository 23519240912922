import React from 'react'
import { DataTypes, NumberTrendModule } from '../numbertrendTypes'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'

type CustomTypes = {
  name: string
  value: DataTypes | 'comparison'
}[]

type NumberDisplaySelectProps = {
  title: string
  info: string
  settingsName: string
  isDisabled?: boolean
}

const NumberDisplaySelect = ({
  info,
  title,
  settingsName,
  isDisabled,
}: NumberDisplaySelectProps) => {
  const [{ module }] = useSettingsTools()
  const numberTrendModule = module as NumberTrendModule
  const isComparisonEnabled = module && numberTrendModule['comparisonmode'] === true
  const dataTypes: CustomTypes = Object.entries(DataTypes).map(([key, value]) => ({
    name: key.toLocaleLowerCase(),
    value,
  }))

  if (isComparisonEnabled) dataTypes.push({ name: 'comparison', value: 'comparison' })
  return (
    <>
      <DropdownInput
        options={dataTypes}
        settingsName={settingsName}
        data_testid={`${settingsName}Select`}
        title={<TextWithInfo text={title} tooltipDirection='top' info={info} />}
        isDisabled={isDisabled}
      />
    </>
  )
}

export default NumberDisplaySelect
