import React from 'react'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import KpiFormatDropDown from './KpiFormatDropdown'
import DateGroupDowndown from './DateGroupDowndown'

import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import LimitInputs from './LimitInputs'
import KpiSelectionTreeData from './KpiSelectionTreeData'

const LineChartSettings = () => {
  return (
    <>
      <Subtitle text='Data' />
      <Linebreak />
      <KpiSelectionTreeData />
      <Subtitle text='Chart formats' />
      <Linebreak />
      <KpiFormatDropDown />
      <DateGroupDowndown />

      <Subtitle text='Table/Line chart details' />
      <Linebreak />
      <div style={{ width: '100%' }}>
        <CheckBoxInput
          settingsName='isInTableMode'
          text='Enable'
          title='Use table mode as chart'
          data_testid='isInTableMode'
          id='isInTableMode'
          width='half'
          isRootProperty
        />
      </div>
      <LimitInputs />
    </>
  )
}

export default LineChartSettings
