import React from 'react'
import useNumericKpis from '../../../stores/useNumericKpis'

const withNumericKpis = (Component: React.ComponentType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function wrappedComponent(props: any) {
    const { numericKpis, isLoading } = useNumericKpis()
    if (isLoading) return <></>
    return <Component numericKpis={numericKpis || []} {...props} />
  }
}

export default withNumericKpis
