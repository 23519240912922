import React from 'react'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import { infoDefaultCountCategory } from '../PietabularNotifications'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'

const CATEGORY = 'category'

const CountedCategorySelect = () => {
  const { answerCategories, error, loading } = useOpenCategories()
  const hasError = !!error
  const hasAnswerCategories = !!answerCategories

  return (
    <>
      {loading && <LoadingIndicator />}
      {hasError && <h5>{error}</h5>}
      {!loading && !hasError && hasAnswerCategories && (
        <DropdownInput
          options={Object.keys(answerCategories)}
          settingsName='defaultCountedKey'
          data_testid='moduleDefaulCountedKey'
          isRequired
          title={
            <TextWithInfo
              text={`Default count ${CATEGORY}`}
              tooltipDirection='top'
              info={infoDefaultCountCategory}
            />
          }
        />
      )}
    </>
  )
}

export default CountedCategorySelect
