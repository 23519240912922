import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const DownBottomArrowIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'vertical_align_bottom'}
    text=""
    customClasses={[css.btn,...(props.customClasses ? props.customClasses : [])]}
    iconPosition={'center'}
    title="Move to bottom"
    data_testid="down-bottom-arrow-icon-button"
    style={{ marginLeft: '3px' }}
  />
)

export default DownBottomArrowIcon;