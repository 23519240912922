import { errorFetchSummaryConfig } from '../components/Dashboards/DashboardModules/Summary/SummaryNotifications'
import { SummaryFilterQuery } from '../components/Dashboards/DashboardModules/Summary/summaryTypes'
import { post } from './apiService'
import { getGenericConfiguration } from './configService'

const getSummaries = (query: SummaryFilterQuery) => {
  try {
    return (
      post('GET_SUMMARY', query)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => res)
        .catch(() => Promise.reject('Could not fetch summaries...'))
    )
  } catch (err) {
    return Promise.reject('Could not fetch summaries...')
  }
}

const getSummaryGenerationConfig = () => {
  try {
    const alert_conf = getGenericConfiguration('summary_generation_configs')
    if (alert_conf) {
      // TODO: Typeguard
      return alert_conf.then((res) => res).catch(() => Promise.reject(errorFetchSummaryConfig))
    } else {
      return Promise.reject(errorFetchSummaryConfig)
    }
  } catch (e) {
    return Promise.reject(errorFetchSummaryConfig)
  }
}

export { getSummaries, getSummaryGenerationConfig }
