import React, { Component } from 'react';
import {Row, Col, Icon, Button} from 'react-materialize';
import Rodal from 'rodal';

// type Props = {
//   onDelete: Function,
// }

// type State = {
//   showModal: boolean,
// }

export default class ModuleDeleteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  toggleModal() {
    this.setState((state) => ({
      showModal: !state.showModal,
    }));
  }

  handleDelete() {
    this.toggleModal();
    this.props.onDelete();
  }

  render() {
    return (
      <React.Fragment>
      <div onClick={this.toggleModal}>
        <Icon data-cy="moduleDeleteIcon" className="module-icon-color waves-effect">
          delete
        </Icon>
      </div>

      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={this.state.showModal} 
        onClose={this.toggleModal}
      >
        <h5>Confirm deletion</h5>
        <Row>
          <Col s={6}><hr/></Col>
          <Col s={6}></Col>
        </Row>
        <h6>Delete this module?</h6>
        <br /><br />
        <Row className="center">
          <Col s={5} offset="s1">
            <Button onClick={this.handleDelete}>Ok</Button>
          </Col>
          <Col s={5}>
            <Button flat onClick={this.toggleModal}>Cancel</Button>
          </Col>
        </Row>
      </Rodal>
      </React.Fragment>
    );
  }
}