/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import Rodal from 'rodal'
import sanitizeHtml from 'sanitize-html'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import FadeIn from '../_shared/FadeIn'
import { Button } from '@mui/material'
import { primaryModalColor } from '../../../../../styles/variableExport'
import { isFetchingData } from '../Group/contexts/renderListReducer'

import css from './TextBox.module.scss'
import { QueueStatus } from '../Group/groupModuleTypes'

const SANITIZER_OPTIONS: sanitizeHtml.IOptions = {
  // https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'big', 'small', 'u']),
  allowedAttributes: {
    h1: ['class'],
    h2: ['class'],
    h3: ['class'],
    h4: ['class'],
    h5: ['class'],
    h6: ['class'],
    div: ['class'],
    span: ['class'],
    big: ['class'],
    small: ['class'],
    i: ['class'],
    table: ['class'],
    b: ['class'],
  },
}

type Props = {
  id: string
  rawText: string
  filters: { name: string; value: string[] }[]
  isEditingDisabled?: boolean
  onRawTextChange: (rawText: string) => void
  isLoading: boolean
  moduleStatus: QueueStatus | undefined
}

function TextBoxModuleContainer({
  rawText = '',
  isEditingDisabled,
  onRawTextChange,
  filters,
  isLoading,
  moduleStatus,
}: Props): JSX.Element {
  const [inputValue, setInputValue] = React.useState(rawText)
  const [isEditorOpen, setIsEditorOpen] = React.useState(false)
  const sanitizedText = sanitizeHtml(rawText, SANITIZER_OPTIONS)
  const displayText = formatText(sanitizedText, (metaKey) => formatMetaValues(metaKey, filters))
  const [ready, setReady] = useState('')

  const readyTimeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    if (readyTimeoutRef.current) clearTimeout(readyTimeoutRef.current)
    if (!isLoading && isFetchingData(moduleStatus)) {
      readyTimeoutRef.current = setTimeout(() => {
        setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
      }, 3000) // To make sure everything has rendered
    }
    return () => {
      if (readyTimeoutRef.current) clearTimeout(readyTimeoutRef.current)
    }
  }, [isLoading, moduleStatus])

  function onToggleEditor() {
    if (isEditorOpen) {
      onRawTextChange(inputValue)
      setIsEditorOpen(false)
    } else {
      setInputValue(rawText)
      setIsEditorOpen(true)
    }
  }

  return (
    <FadeIn>
      <div
        data-no-data={true}
        className={`text-box-container textbox-module-cntr ${
          isEditingDisabled ? 'disable-edit' : ''
        } ${css.cntr} ${ready}`}
      >
        <div
          data-testid='displayedText'
          className='scroll-box hide-overflow'
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {!isEditingDisabled && (
          <div className='button-box'>
            <Button
              variant='contained'
              sx={{ backgroundColor: primaryModalColor }}
              data-testid='updateText'
              onClick={onToggleEditor}
              size='large'
            >
              Update text
            </Button>
          </div>
        )}
        {isEditorOpen && (
          <Rodal closeOnEsc animation='slideUp' visible={isEditorOpen} onClose={onToggleEditor}>
            {isEditorOpen && (
              <>
                <h4>Insert text</h4>
                <hr />
                <textarea
                  data-testid='textbox'
                  className={css.editor}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <br />
                <Button
                  sx={{ backgroundColor: primaryModalColor }}
                  data-testid='textBoxEditorOkButton'
                  variant='contained'
                  onClick={onToggleEditor}
                  size='large'
                >
                  OK
                </Button>
              </>
            )}
          </Rodal>
        )}
      </div>
    </FadeIn>
  )
}

/**
 * Finds meta keys from the text and replaces them using the getValue function
 * e.g.
 * formatText("Hello {name}!", metaKey => "dude") -> "Hello dude!"
 */
function formatText(text: string, getValue: (metaKey: string) => string): string {
  const withoutEmptyMetas = text.replace(/{}/gm, '')
  // Note: all regex not compatible with PhantomJS, e.g. look ahead/look behind syntax
  const metaKeysInText = withoutEmptyMetas.match(/{[^{}]+}/gm)

  if (!metaKeysInText) {
    return withoutEmptyMetas
  }

  const cleanedMetaKeysInText = metaKeysInText.map((meta) => meta.replace(/{|}/g, ''))
  return cleanedMetaKeysInText.reduce(
    (result, metaKey) => result.replace(`{${metaKey}}`, getValue(metaKey)),
    withoutEmptyMetas,
  )
}

/**
 * If metakey is not found in filters it returns the
 * template string instead
 */
function formatMetaValues(metaKey: string, filters: { name: string; value: string[] }[]): string {
  const filter = filters.find(({ name }) => name === metaKey)

  if (!filter) {
    return ''
  }

  if (!Array.isArray(filter.value)) {
    return ''
  }

  if (filter.value.length > 20) {
    const head = filter.value.slice(0, 20)
    const tail = filter.value.slice(20)
    return `${head.join(', ')} & ${tail.length} more ${tail.length === 1 ? 'value' : 'values'}`
  } else if (filter.value.length > 1) {
    return `${filter.value.slice(0, -1).join(', ')} & ${filter.value.slice(-1)}`
  } else if (filter.value.length === 1) {
    return filter.value[0]
  } else {
    return ''
  }
}

export default TextBoxModuleContainer
