import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
} from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import chartThemeService from './scripts/react-services/chartThemeService'
import Main from './Main'
import { getToken, isLoggedIn, setToken } from './scripts/react-services/authService'
import NavigationBar from './scripts/components/Dashboards/DashboardModules/Dashboard/NavigationBar'
import FooterBar from './scripts/components/Dashboards/DashboardModules/Dashboard/FooterBar'
import DashboardContainer from './scripts/components/Dashboards/DashboardModules/Dashboard/DashboardContainer'
import ResetPassword from './scripts/components/ResetPassword/ResetPassword'
import AlertEmailContainer from './scripts/components/Dashboards/DashboardModules/FeedbackAlerts/AlertModal/AlertEmailContainer'
import SingleGraphParamsCntr from './scripts/components/Dashboards/DashboardModules/SingleGraph/SingleGraphParamsCntr'
import Logout from './Logout'
import AdminTools from './scripts/components/AdminTools/AdminTools'
import ErrorBoundary from './scripts/components/_shared/Infos/ErrorBoundary'
import ManualSender from './scripts/components/Manual/ManualSender'
import ManualSenderRedirect from './ManualSenderRedirect'
import { useTranslation } from 'react-i18next'
import useTranslationsConfig from './scripts/stores/useTranslationsConfig'
import { useDeepCompareEffect, useEffectOnce } from 'react-use'
import languages, { Language, getLocaleTextsForMuiLocaliozation } from './languages/languages'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import useCommonDbSettingsConfig from './scripts/stores/useCommonDbSettingsConfig'

// DATE LOCALIZATION FOR MUI
import 'dayjs/locale/fi'
import 'dayjs/locale/sv'
import 'dayjs/locale/en'

// HORRIBLE OLD CSS IMPORTS, DELETE SOME DAY, MESSES UP THE WHOLE STYLING
import './../node_modules/materialize-css/dist/css/materialize.css'
import 'rodal/lib/rodal.css'

import './styles/override.scss'
import './styles/multilevel.scss'
import './styles/main.scss'
import css from './App.module.scss'
import AppStores from './AppStores'
import Unsubscribe from './scripts/components/Unsubscribe/Unsubscribe'
import { refreshLogin } from './scripts/react-services/loginService'
import { debounce } from 'lodash'

const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { config: translationsConfig, getTranslations } = useTranslationsConfig()
  const isLogged = isLoggedIn()
  const { isLoading: isLoadingCdbs, config: cdbs } = useCommonDbSettingsConfig()

  document.documentElement.lang = i18n.language

  useEffect(() => {
    const Highcharts = window.Highcharts
    Highcharts.setOptions(chartThemeService().getDefaultTheme())
  }, [])

  useEffect(() => {
    const prevPathName = location.hash
    const newPathName = prevPathName.replace('#/', '/')
    if (prevPathName && prevPathName !== newPathName) {
      navigate(newPathName, { replace: true })
    }
  }, [location.hash])

  useEffect(() => {
    if (isLogged) getTranslations()
  }, [isLogged])

  useEffect(() => {
    if (isLoadingCdbs) return
    if (cdbs && !cdbs?.is_multi_language_enabled) i18n.changeLanguage(Language.EN)
  }, [cdbs])

  useEffect(() => {
    if (!i18n.language) return
    const currentLanguage = i18n.language as Language
    if (languages.includes(currentLanguage)) return
    // en-US -> en
    const languageCode = currentLanguage.split('-')[0] as Language
    if (languages.includes(languageCode)) i18n.changeLanguage(languageCode)
    else i18n.changeLanguage(Language.EN)
  }, [i18n.language])

  const muiLocalizationTexts = useMemo(() => {
    return getLocaleTextsForMuiLocaliozation(i18n.language as Language)
  }, [i18n.language])

  useDeepCompareEffect(() => {
    if (!translationsConfig) return
    Object.entries(translationsConfig).forEach(([lang, translations]) => {
      Object.entries(translations).forEach(([namespace, translation]) => {
        i18n.addResourceBundle(lang, namespace, translation, true, true)
      })
    })
    i18n.changeLanguage(i18n.language)
  }, [translationsConfig || {}])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={muiLocalizationTexts}
      adapterLocale={i18n.language}
    >
      {isLogged && <AppStores />}
      <Routes>
        <Route path='/alert/:token' element={<AlertEmailContainer />} />
        <Route path='/loginstep2' element={withErrorBoundary(<ResetPassword />)} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/manual' element={withErrorBoundary(<ManualSender />)} />
        <Route path='/redirect' element={withErrorBoundary(<ManualSenderRedirect />)} />
        <Route
          path='/resetpassword/:token'
          element={withErrorBoundary(
            <PrivateRoute>
              <ResetPassword />
            </PrivateRoute>,
          )}
        />
        <Route path='/unsubscribe/:hashId' element={withErrorBoundary(<Unsubscribe />)} />
        <Route path='/verify/:token' element={withErrorBoundary(<ResetPassword />)} />

        <Route element={<DashboardLayout />}>
          <Route
            path='/section/:sectionIdx/dashboard/:dashboardIdx'
            element={<DashboardContainer />}
          />
          <Route path='/section/:sectionIdx' element={<RedirectToDefaultDashboard />} />
          <Route path='/dashboard/:dashboardIdx' element={<RedirectToDefaultDashboard />} />
          <Route path='/admin/dashboard' element={withErrorBoundary(<AdminTools />)} />
        </Route>

        <Route element={<SingleGraphLayout />}>
          <Route
            path='/section/:sectionIdx/dashboard/:dashboardIdx/module/:moduleIdx'
            element={<SingleGraphParamsCntr />}
          />
          <Route
            path='/dashboard/:dashboardIdx/module/:moduleIdx'
            element={<SingleGraphParamsCntr />}
          />
        </Route>
        <Route path='/' element={<Main />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </LocalizationProvider>
  )
}

export default App

type PrivateRouteProps = {
  redirectPath?: string
  children: JSX.Element
}
export const PrivateRoute = ({ children, redirectPath = '/' }: PrivateRouteProps) => {
  const tokenCheckDebouncer = debounce(
    () => {
      setStoredToken(getToken())
      console.log('debounced')
    },
    1000,
    { maxWait: 5000 },
  )
  const [storedToken, setStoredToken] = useState<string | null>(null)
  useEffectOnce(() => {
    refreshLogin()
  })
  const token = new URLSearchParams(window.location.search).get('token')
  useEffect(() => {
    if (!token) return
    setToken(token)
    tokenCheckDebouncer()
    return () => {
      tokenCheckDebouncer.cancel()
    }
  }, [token])

  useEffect(() => {
    if (!storedToken) return
    tokenCheckDebouncer.cancel()
  }, [storedToken])

  if (token && !storedToken) return <></>
  if (token && storedToken) return children
  return isLoggedIn() ? children : <Navigate to={redirectPath} />
}

const DashboardLayout = forwardRef((_props, ref) => {
  const location = useLocation()
  const currentOutlet = useOutlet()
  return (
    <PrivateRoute>
      <div className={css.cntr}>
        <NavigationBar />
        {withErrorBoundary(
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              timeout={350}
              classNames='dashboard'
              unmountOnExit
              nodeRef={currentOutlet?.props.ref || ref}
            >
              {() => <>{currentOutlet}</>}
            </CSSTransition>
          </SwitchTransition>,
        )}
        <FooterBar />
      </div>
    </PrivateRoute>
  )
})

DashboardLayout.displayName = 'DashboardLayout'

const SingleGraphLayout = () => {
  return (
    <>
      <PrivateRoute>
        <Outlet />
      </PrivateRoute>
    </>
  )
}

const RedirectToDefaultDashboard = () => {
  const { sectionIdx, dashboardIdx } = useParams()
  return <Navigate to={`/section/${sectionIdx || 0}/dashboard/${dashboardIdx || 0}`} />
}

const withErrorBoundary = (children: JSX.Element) => {
  const location = useLocation()
  return (
    <ErrorBoundary
      message={`Failed to path "${location.pathname}"`}
      fallback={
        <div
          style={{ fontSize: '1.2em', color: 'red', fontWeight: '600' }}
        >{`Failed to load path: "${location.pathname}"`}</div>
      }
    >
      {children}
    </ErrorBoundary>
  )
}
