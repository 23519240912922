import React from 'react';
import { PropTypes } from 'prop-types';
import './Buttons.scss';

const MainButton = ({
  text = "Submit", 
  icon = "", 
  iconPosition = "", 
  isLight = false, 
  isDisabled = false, 
  isFlat = false, 
  onClick, 
  float = "initial",
  customClasses = [],
  ...rest
}) => {

  const DEFAULT_ICON_SIZE = "24px";

  function getClasses() {
    let result = customClasses
    isDisabled && result.push('disabled');
    !isDisabled && isLight && result.push('light');
    !isDisabled && isFlat && result.push('flat');
    !isDisabled && result.push('waves-effect')

    return result.join(' ');
  }

  function handleClick(e) {
    if (!isDisabled) {
      onClick(e);
    }
  }

  return (
    <button data-testid={`mainButton-${text.replace(/\s/g,'')}`} className={`general-main-button ${getClasses()}`} onClick={handleClick} style={{float}} {...rest}>
      {icon && <i className={`material-icons ${iconPosition}`}>{icon}</i>}
      {text && <span className={`general-main-button-text`} style={{lineHeight: DEFAULT_ICON_SIZE}}>{text}</span>}
    </button>
  )
}

MainButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right", "center"]),
  isLight: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFlat: PropTypes.bool,

  onClick: PropTypes.func.isRequired,
}

export default MainButton;