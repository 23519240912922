import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreNonResetting, { ActionState } from './useStoreNonResetting'
import { getReportingFilters } from '../react-services/configService2'

export type Metas = {
  [metaKey: string]: string[]
}

export const reportingFilterStorage = makeNonResettingObservable<Metas | null>({})

const useReportingFilters = () => {
  const key = 'reportingFilters'
  const { tenant, user } = useStoreOwner(reportingFilterStorage)
  const {
    dataState: { data, error, state },
  } = useStoreNonResetting(reportingFilterStorage, key, user, tenant, true, getReportingFilters)

  const isLoading = state !== ActionState.DONE && state !== ActionState.ERROR
  return {
    metas: data,
    isLoading,
    error,
  }
}

export default useReportingFilters
