const useSelectionDetails = () => {
  const getNumberOfColumns = (array: string[]) => {
    if (array.length < 8) return 1
    if (array.length < 17) return 2
    if (array.length < 25) return 3
    return 4
  }

  const getSelectionWidth = (array: string[]) => {
    if (array.length < 8) return 130
    if (array.length < 17) return 280
    if (array.length < 25) return 400
    return 500
  }
  const metaSortPredicate = (a: string, b: string) => {
    if ((a || '').toLocaleLowerCase() === '' && (b || '').toLocaleLowerCase() !== 'kaikki')
      return -1
    if ((b || '').toLocaleLowerCase() === '' && (a || '').toLocaleLowerCase() !== 'kaikki') return 1
    if ((a || '').toLocaleLowerCase() === 'kaikki') return -1
    if ((b || '').toLocaleLowerCase() === 'kaikki') return 1
    if (a < b) return -1
    if (a > b) return 1
    return 0
  }

  return {
    getNumberOfColumns,
    getSelectionWidth,
    metaSortPredicate,
  }
}

export default useSelectionDetails
