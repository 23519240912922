import React, { useEffect, useMemo, useRef } from 'react'
import useDbSettingsConfig from '../../../../stores/useDbSettingsConfig'
import useDbTemplateConfig from '../../../../stores/useDbTemplateConfig'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'
import useReportingFilters from '../../../../stores/useReportingFilters'
import useDashboardSettingsConfig from '../../../../stores/useDashboardSettingsConfig'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import useNumericKpis from '../../../../stores/useNumericKpis'
import { isEmpty } from 'lodash'
import { getToken } from '../../../../react-services/authService'
import SingleGraphDashboardParser, {
  SingleGraphDashboardParserProps,
} from './SingleGraphDashboardParser'
import { useEffectOnce } from 'react-use'

const SingleGraphConfigs = (props: SingleGraphDashboardParserProps) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [isTokenValid, setIsTokenValid] = React.useState(false)
  const token = props.token
  const { isLoading: isLoadingDbSettingConfig, error: errorLoadingDbSettingsConfig } =
    useDbSettingsConfig()
  const { isLoading: isLoadingDbTemplateConfig, error: errorLoadingDbTemplateConfig } =
    useDbTemplateConfig()
  const { isLoading: isLoadingCommonDbSettings, error: errorLoadingCommonDbSettings } =
    useCommonDbSettingsConfig()
  const { isLoading: isLoadingCalculatedKpis, error: errorLoadingCalculatedKpis } =
    useCalculatedKpis()
  const { isLoading: isLoadingNumericKpis, error: errorLoadingNumericKpis } = useNumericKpis()
  const { isLoading: isLoadingReportingFilters, error: errorLoadingReportingFilters,metas:tenantReportingFilters } =
    useReportingFilters()
  const { isLoading: isLoadingDashboardSettings, error: errorLoadingDashboardSettings } =
    useDashboardSettingsConfig()

  const loadingTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (loadingTimeout.current) clearTimeout(loadingTimeout.current)
    loadingTimeout.current = setTimeout(() => {
      setIsLoading(
        isLoadingDbSettingConfig ||
          isLoadingDbTemplateConfig ||
          isLoadingCommonDbSettings ||
          isLoadingCalculatedKpis ||
          isLoadingNumericKpis ||
          isLoadingReportingFilters ||
          isLoadingDashboardSettings,
      )
    }, 1000)

    return () => {
      if (loadingTimeout.current) clearTimeout(loadingTimeout.current)
    }
  }, [
    isLoadingDbSettingConfig,
    isLoadingDbTemplateConfig,
    isLoadingCommonDbSettings,
    isLoadingCalculatedKpis,
    isLoadingNumericKpis,
    isLoadingReportingFilters,
    isLoadingDashboardSettings,
  ])

  useEffectOnce(() => {
    const body = document.querySelector('body')
    if (body) {
      body.classList.add('hide-default-view')
    }
    const root = document.getElementById('root')
    if (root) {
      root.classList.add('screen-view-container')
      root.classList.add('inheritsize')
    }
  })

  const hasErrors = useMemo(() => {
    return Boolean(
      errorLoadingDbSettingsConfig ||
        errorLoadingDbTemplateConfig ||
        errorLoadingCommonDbSettings ||
        errorLoadingCalculatedKpis ||
        errorLoadingNumericKpis ||
        errorLoadingReportingFilters ||
        errorLoadingDashboardSettings,
    )
  }, [
    errorLoadingDbSettingsConfig,
    errorLoadingDbTemplateConfig,
    errorLoadingCommonDbSettings,
    errorLoadingCalculatedKpis,
    errorLoadingNumericKpis,
    errorLoadingReportingFilters,
    errorLoadingDashboardSettings,
  ])

  if (hasErrors) alert('Failed to load configurations. Reload to try again')
  useEffect(() => {
    if (!token) return alert('Failed to load configurations. Reload to try again')
    validateToken(token)
  }, [token])

  /**
   * Check that token from URL param matches token in Session storage (when URL param 'token' is present).
   * <br>
   * Alert if tokens do not match. Prevents backend from generating image report/screen with wrong tenant's data.
   */
  function validateToken(urlToken?: string) {
    if (isEmpty(urlToken)) return setIsTokenValid(true)
    const sessionStorageToken = getToken()
    if (urlToken !== sessionStorageToken) {
      console.error('Mismatch between URL param token/Session storage token.')
      console.log('URL param token', urlToken)
      console.log('Session storage token', sessionStorageToken)
      setIsTokenValid(false)
      window.alert('Token validation error')
    } else {
      setIsTokenValid(true)
    }
  }

  if (!isTokenValid) return <div>Token validation error</div>
  if (hasErrors)
    return (
      <div>
        <div>Error loading configs...</div>
        {!!errorLoadingDbSettingsConfig && (
          <div>errorLoadingDbSettingsConfig: {errorLoadingDbSettingsConfig}</div>
        )}
        {!!errorLoadingDbTemplateConfig && (
          <div>errorLoadingDbTemplateConfig: {errorLoadingDbTemplateConfig}</div>
        )}
        {!!errorLoadingCommonDbSettings && (
          <div>errorLoadingCommonDbSettings: {errorLoadingCommonDbSettings}</div>
        )}
        {!!errorLoadingCalculatedKpis && (
          <div>errorLoadingCalculatedKpis: {errorLoadingCalculatedKpis}</div>
        )}
        {!!errorLoadingNumericKpis && <div>errorLoadingNumericKpis: {errorLoadingNumericKpis}</div>}
        {!!errorLoadingReportingFilters && (
          <div>errorLoadingReportingFilters: {errorLoadingReportingFilters}</div>
        )}
        {!!errorLoadingDashboardSettings && (
          <div>errorLoadingDashboardSettings: {errorLoadingDashboardSettings}</div>
        )}
      </div>
    )
  if (isLoading) return <div>Loading configs...</div>
  if (token)
    return (
      <div className='dashboard-view-container'>
        <SingleGraphDashboardParser {...props} token={token} tenantReportingFilters={tenantReportingFilters} />
      </div>
    )
  return <div>Something went wrong, no token found</div>
}

export default SingleGraphConfigs
