import React, { useEffect, useRef, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import { cloneDeep, isEqual, isUndefined } from 'lodash'
import { FrequencyModule } from '../frequencyModuleTypes'
import useOpenKpis, { idAndNameKpi } from '../../../../../stores/useOpenKpis'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import { PrunedKpi } from '../../Pietabular/PietabularModuleContext'

const OpenKpiSelectionTreeData = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const freqModule = module as FrequencyModule
  const selectedDeprecatedOpenKpi = freqModule.KPI
  const selectedOpenKpis = freqModule.options?.openKpis
  const freqOptions = freqModule.options
  const { openKpis } = useOpenKpis()

  useEffect(() => {
    if (!openKpis) return
    let realSelectedOpenKpis = [] as idAndNameKpi[]
    if (!isUndefined(selectedDeprecatedOpenKpi) && !isNaN(+selectedDeprecatedOpenKpi)) {
      const kpiId = selectedDeprecatedOpenKpi
      const matchingOpenKpi = openKpis.find((kpi) => kpi.id === kpiId)
      if (!matchingOpenKpi) return setSelectedKpis(realSelectedOpenKpis)
      else realSelectedOpenKpis.push(matchingOpenKpi)
      return setSelectedKpis(realSelectedOpenKpis)
    }
    if (selectedOpenKpis) {
      realSelectedOpenKpis = selectedOpenKpis
    }
    setSelectedKpis(realSelectedOpenKpis)
  }, [openKpis])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const handleSaveNewSelection = (newSelection: kpiIdAndName[]) => {
    const newOpenKpis = newSelection.filter((kpi) => !isUndefined(kpi.id)) as PrunedKpi[]
    try {
      const freqModuleCopy = cloneDeep(freqModule)
      if (!freqModuleCopy) return
      if (!isUndefined(freqModuleCopy.KPI)) delete freqModuleCopy.KPI
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      const newModule = {
        ...freqModuleCopy,
        options: {
          ...(freqOptions || {}),
          openKpis: newOpenKpis,
        },
      }
      if (isEqual(newModule, freqModule)) return
      timeoutRef.current = setTimeout(() => {
        saveModule(newModule)
      }, 200)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.open}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default OpenKpiSelectionTreeData
