import { darkerChartColors } from '../../../../../styles/variableExport'
import {
  FreqFormat,
  FrequencyComparisonType,
  FrequencyModule,
  FrequencyQueryType,
  NumericKpiFormat,
} from './frequencyModuleTypes'

const useFrequencyChartButtons = (
  handleOptionsPropertySave: (properties: Partial<FrequencyModule['options']>) => void,
  queryType: FrequencyQueryType,
  comparisonMeta: string | undefined,
) => {
  const staticOptions = {
    'stroke-width': 1,
    stroke: darkerChartColors.GREY,
    fill: 'none',
    r: 1.5,
    padding: 7,
    style: {
      color: darkerChartColors.GREY,
      fontWeight: 'bold',
      width: '120px',
    },
  }
  const hoverOptions = {
    'stroke-width': 1,
    stroke: '#FFF',
    fill: darkerChartColors.GREY,
    r: 1.5,
    padding: 7,
    style: {
      color: '#FFF',
      fontWeight: 'bold',
    },
  }

  const getComparisonButtonLeftPosition = (formatText: string, cntrWidth: number) => {
    if (formatText === 'n') {
      return cntrWidth < 400 ? 26 : 35
    }
    if (formatText === '%') {
      return cntrWidth < 400 ? 27 : 35
    }
    if (formatText === 'avg') {
      return cntrWidth < 400 ? 40 : 48
    }
    if (formatText === 'sum') {
      return cntrWidth < 400 ? 44 : 51
    }
    return cntrWidth < 400 ? 22 : 30
  }

  const getComparisonDetailsButtonLeftPosition = (
    comparisonType: FrequencyComparisonType | undefined | null,
    cntrWidth: number,
  ) => {
    if (comparisonType === FrequencyComparisonType.DATE) {
      return cntrWidth < 400 ? 45 : 133
    }
    if (comparisonType === FrequencyComparisonType.META) {
      return cntrWidth < 400 ? 49 : 136
    }
    return 30
  }

  const handleDataFormatChange = (prevFormat: FreqFormat | NumericKpiFormat | null) => {
    if (
      queryType === FrequencyQueryType.OPEN ||
      queryType === FrequencyQueryType.NUMERIC ||
      queryType === FrequencyQueryType.META
    ) {
      let newFormat = FreqFormat.N
      if (prevFormat === FreqFormat.N) newFormat = FreqFormat.PERCENTAGE_ALL
      if (prevFormat === FreqFormat.PERCENTAGE_ALL) newFormat = FreqFormat.N
      handleOptionsPropertySave({ freq_format: newFormat })
    }
    if (queryType === FrequencyQueryType.NUMERIC_KPI) {
      let newFormat = NumericKpiFormat.N
      if (prevFormat === NumericKpiFormat.N) newFormat = NumericKpiFormat.AVG
      if (prevFormat === NumericKpiFormat.AVG) newFormat = NumericKpiFormat.SUM
      if (prevFormat === NumericKpiFormat.SUM) newFormat = NumericKpiFormat.PERCENT
      if (prevFormat === NumericKpiFormat.PERCENT) newFormat = NumericKpiFormat.N
      handleOptionsPropertySave({ numericKpiDataFormat: newFormat })
    }
  }

  const handleComparisonModeChange = (prevMode: FrequencyComparisonType | undefined | null) => {
    if (
      queryType === FrequencyQueryType.OPEN ||
      queryType === FrequencyQueryType.NUMERIC ||
      queryType === FrequencyQueryType.META
    ) {
      let newMode = null
      if (!prevMode) newMode = FrequencyComparisonType.DATE
      handleOptionsPropertySave({ comparisonType: newMode })
    }
    if (queryType === FrequencyQueryType.NUMERIC_KPI) {
      let newMode = null
      if (!prevMode) newMode = FrequencyComparisonType.DATE
      if (prevMode === FrequencyComparisonType.DATE && comparisonMeta)
        newMode = FrequencyComparisonType.META
      if (prevMode === FrequencyComparisonType.DATE && !comparisonMeta) newMode = null
      handleOptionsPropertySave({ comparisonType: newMode })
    }
  }

  return {
    staticOptions,
    hoverOptions,
    getComparisonButtonLeftPosition,
    getComparisonDetailsButtonLeftPosition,
    handleDataFormatChange,
    handleComparisonModeChange,
  }
}

export default useFrequencyChartButtons
