import React from 'react'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import { infoSoftMax, infoSoftMin } from '../FrequencyNotifications'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { FreqFormat, FrequencyModule } from '../frequencyModuleTypes'
import { cloneDeep, isNull, isUndefined } from 'lodash'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'

const LimitInputs = () => {
  const [{ saveModule, module }] = useSettingsTools()
  const freqModule = module as FrequencyModule
  const freqOptions = module?.options
  const { limitValues: { min, max } = {}, options: { freq_format } = {} } = freqModule
  const currentMin = isNull(min) || isUndefined(min) ? '' : min
  const currentMax = isNull(max) || isUndefined(max) ? '' : max
  const currentFormat = freq_format || FreqFormat.N

  const handleOnChangeLimits = (newLimit: string, limit: 'min' | 'max') => {
    if (!freqModule) return
    const moduleCopy = cloneDeep(freqModule)
    const prevLimits = freqModule.limitValues || {}
    const newModule = {
      ...moduleCopy,
      limitValues: { ...prevLimits, [limit]: !isNaN(+newLimit) ? newLimit : null },
      options: {
        ...(freqOptions || {}),
        freq_format: currentFormat,
        limitTarget: currentFormat,
      },
    }
    saveModule(newModule)
  }
  return (
    <>
      <div className={`${modal_css.quarter}`}>
        <TextWithInfo text='Y-axis min' info={infoSoftMin} tooltipDirection='right' />
        <input
          data-testid='kpiMinInput'
          type='number'
          value={currentMin}
          id='kpiMinInput'
          onChange={(e) => handleOnChangeLimits(e.target.value, 'min')}
          className={`${css.input}`}
        />
      </div>
      <CheckBoxInput
        settingsName='isSoftMin'
        text='Enable'
        title='Use soft min'
        data_testid='isSoftMin'
        id='isSoftMin'
        width='quarter'
      />
      <div className={`${modal_css.quarter}`}>
        <TextWithInfo text='Y-axis max' info={infoSoftMax} tooltipDirection='right' />
        <input
          data-testid='kpiMaxInput'
          type='number'
          value={currentMax}
          id='kpiMaxInput'
          onChange={(e) => handleOnChangeLimits(e.target.value, 'max')}
          className={`${css.input}`}
        />
      </div>
      <CheckBoxInput
        settingsName='isSoftMax'
        text='Enable'
        title='Use soft max'
        data_testid='isSoftMax'
        id='isSoftMax'
        width='quarter'
      />
      <DropdownInput
        settingsName='limitTarget'
        options={[FreqFormat.N, FreqFormat.PERCENTAGE_ALL]}
        title={'Limit min-max target'}
        width='third'
        data_testid='limitTarget'
        id='limitTarget'
      />
    </>
  )
}

export default LimitInputs
