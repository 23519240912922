import { enUS, fiFI, svSE } from '@mui/x-date-pickers/locales'

export enum Language {
  FI = 'fi',
  EN = 'en',
  SV = 'sv',
}

const languages = Object.values(Language)
export default languages

export const getLocaleTextsForMuiLocaliozation = (locale: Language) => {
  switch (locale) {
    case Language.FI:
      return fiFI.components.MuiLocalizationProvider.defaultProps.localeText
    case Language.SV:
      return svSE.components.MuiLocalizationProvider.defaultProps.localeText
    case Language.EN:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText
    default:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText
  }
}
