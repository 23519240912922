import React, { useEffect, useMemo, useRef, useState } from 'react'

import css from './Selection.module.scss'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import useSummaryLanguage from '../useSummaryLanguage'
import { getTenant } from '../../../../../react-services/authService'
import { isUndefined } from 'lodash'

type SelectionProps = {
  onSelect: (value: string) => void
  testid?: string
  selection: string[]
  value: string
  classNames?: string
  columns: number
  width: number
  position: 'bottomLeft' | 'bottomCenter' | 'bottomRight'
  title: string
}
const Selection = ({
  onSelect,
  testid,
  selection,
  value,
  classNames,
  columns,
  width,
  position,
  title,
}: SelectionProps) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false)
  const listToggleTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [{ language }] = useSummaryFilters()
  const { t } = useSummaryLanguage(language)
  const tenant = getTenant()
  useEffect(() => {
    return () => clearTimeoutRef()
  }, [])
  function clearTimeoutRef() {
    if (listToggleTimeoutRef.current) clearTimeout(listToggleTimeoutRef.current)
  }
  const selectionTable = useMemo(() => {
    const rows = [] as JSX.Element[]
    let i = 0
    while (i < selection.length) {
      const cells = [] as JSX.Element[]
      for (let j = 0; j < columns && i < selection.length; j++) {
        const item = selection[i]
        cells.push(
          <td
            key={item + 1}
            className={`${css.cell} ${item === value ? css.selected : ''}`}
            style={{ width: width / columns }}
            onClick={(e) => handleOnSelect(item, e)}
          >
            {t(item)}
          </td>,
        )
        i++
      }
      rows.push(<tr key={i}>{cells}</tr>)
    }
    return (
      <div
        className={`${css.tableBorder} ${css[position]}`}
        onClick={handleLabelClick}
        onContextMenu={handleLabelRightClick}
      >
        <table
          className={`${css.table}`}
          style={{ width: width + columns * 2 }}
          data-testid={testid + '-table'}
        >
          <tbody>{rows}</tbody>
        </table>
      </div>
    )
  }, [selection, columns, value, width])

  const handleOnSelect = (value: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    onSelect(value)
    setIsListOpen(false)
  }

  function handleLabelClick(e: React.MouseEvent<HTMLDivElement | HTMLLabelElement, MouseEvent>) {
    e.stopPropagation()
    e.preventDefault()
    if (!selection || !selection.length) return
    const fallBackValue = selection[0]
    if (typeof value === 'string') {
      const indexOfValue = selection.indexOf(value)
      const valueOfNextIndex = selection[indexOfValue + 1]
      const nextValue = isUndefined(valueOfNextIndex) ? fallBackValue : valueOfNextIndex
      onSelect(nextValue)
    } else {
      onSelect(fallBackValue)
    }
  }

  function handleLabelRightClick(
    e: React.MouseEvent<HTMLDivElement | HTMLLabelElement, MouseEvent>,
  ) {
    e.stopPropagation()
    e.preventDefault()
    if (!selection || !selection.length) return
    const fallBackValue = selection[selection.length - 1]
    if (typeof value === 'string') {
      const indexOfValue = selection.indexOf(value)
      const valueOfNextIndex = selection[indexOfValue - 1]
      const nextValue = isUndefined(valueOfNextIndex) ? fallBackValue : valueOfNextIndex
      onSelect(nextValue)
    } else {
      onSelect(fallBackValue)
    }
  }

  const LIST_IN_TIMEOUT = 800
  const LIST_OUT_TIMEOUT = 400
  const handleListToggle = (isOpen: boolean) => {
    if (listToggleTimeoutRef.current) clearTimeout(listToggleTimeoutRef.current)
    if (tenant === 'test_frontend') return setIsListOpen(isOpen)
    const TIMEOUT = isOpen ? LIST_IN_TIMEOUT : LIST_OUT_TIMEOUT
    listToggleTimeoutRef.current = setTimeout(() => {
      setIsListOpen(isOpen)
    }, TIMEOUT)
  }
  return (
    <div
      className={css.cntr}
      onMouseLeave={() => handleListToggle(false)}
      onMouseEnter={() => handleListToggle(true)}
      data-testid={testid}
    >
      <div className={css.title} title={title} data-testid={testid + '-title'}>
        {t(title)}
      </div>
      <div className={`${css.select} ${classNames} ${isListOpen ? css.open : ''}`}>
        <label
          className={css.label}
          onClick={handleLabelClick}
          onContextMenu={handleLabelRightClick}
          data-testid={testid + '-label'}
        >
          {t(value)}
        </label>
        {isListOpen && <>{selectionTable}</>}
      </div>
    </div>
  )
}

export default Selection
