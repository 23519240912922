/* eslint-disable @typescript-eslint/no-explicit-any */
// Typeguards should use any to assign values, you cannot assign types to unknown

import {
  Data,
  GenericConfig,
  KpiData,
  Query,
  Response,
  Task,
  TasksConfig,
  WhereMeta,
} from '../../types'
import { MetaAndPosition } from '../components/AdminTools/ViewCustomization/inspectorTypes'
import { Conversation } from '../components/Dashboards/DashboardModules/_shared/ConversationModal/conversationTypes'
import {
  Categories,
  OpenAnswerResData,
  OpenAnswersCountsResponse,
} from '../components/Dashboards/DashboardModules/Pietabular/pietabularTypes'
import { CategoriesWithHotkeys } from '../components/Dashboards/DashboardModules/Open/OpenCategoryTool/openCategoryToolTypes'
import { OpenAnswersResponse } from '../stores/useNewOpenAnswers'
import { CustomerPathConfig } from '../stores/useConfigCustomerPath'
import { isArray, isNull } from 'lodash'
import { NumericKpi } from '../stores/useNumericKpis'
import { Metas } from '../stores/useReportingFilters'

/**
 * Verifies that the parameter is a string tuple of length 2
 * @param obj anything
 */
function isStringTuple2(obj: unknown): obj is [string, string] {
  if (!Array.isArray(obj)) {
    return false
  }

  if (obj.length !== 2) {
    return false
  }

  const [first, second] = obj

  if (typeof first === 'string' && typeof second === 'string') {
    return true
  } else {
    return false
  }
}

function isTask(obj: any): obj is Task {
  if (obj) {
    if (typeof (obj as Task).id === 'string' && typeof (obj as Task).name === 'string') {
      return true
    }
  }
  return false
}

function isTaskArray(obj: any): obj is Task[] {
  if (Array.isArray(obj)) {
    for (const o of obj) {
      if (!isTask(o)) return false
    }
    return true
  }
  return false
}

function isTasksConfig(obj: any): obj is TasksConfig {
  if (obj) {
    if (typeof (obj as TasksConfig).name !== 'string') {
      return false
    }
    if (!(obj as TasksConfig).rows || !isTaskArray((obj as TasksConfig).rows)) {
      return false
    }
    if (obj.columns) {
      if (!Array.isArray(obj.columns)) return false
      for (const col of obj.columns) {
        if (col === null || col === undefined || typeof col !== 'string') return false
      }
    }
    return true
  }
  return false
}

const isStringArray = (value: any): value is string[] => {
  if (!value) return false
  if (!Array.isArray(value)) return false
  if (value.length === 0) return true
  let isStrings = true
  value.forEach((item) => {
    if (typeof item !== 'string') {
      isStrings = false
    }
  })
  if (isStrings) return true
  return false
}

const isNumberArray = (value: any): value is number[] => {
  if (!value) return false
  if (!Array.isArray(value)) return false
  if (value.length === 0) return true
  let isNumbers = true
  value.forEach((item) => {
    if (typeof item !== 'number') {
      isNumbers = false
    }
  })
  if (isNumbers) return true
  return false
}

const isIdAndNameObjectArray = (value: any): value is { id: number; name: string }[] => {
  if (!value) return false
  if (!Array.isArray(value)) return false
  if (value.length === 0) return true
  let isNumbers = true
  value.forEach((item) => {
    if (typeof item.id !== 'number') {
      isNumbers = false
    }
    if (typeof item.name !== 'string') {
      isNumbers = false
    }
  })
  if (isNumbers) return true
  return false
}

const isCommonDbSettingsObject = (value: any): value is GenericConfig.CommonDbSettings => {
  if (value !== undefined && !Array.isArray(value) && typeof value === 'object') {
    return true
  } else {
    return false
  }
}

const isMetaAndPosition = (value: any): value is MetaAndPosition => {
  if (!value) return false
  if (Array.isArray(value)) return false
  if (typeof value !== 'object') return false
  if (!('name' in value)) return false
  if (typeof value.name !== 'string') return false
  if (!('position' in value)) return false
  if (typeof value.position !== 'number') return false
  return true
}

const isMetaAndPositionArray = (value: any): value is MetaAndPosition[] => {
  if (!value) return false
  if (!Array.isArray(value)) return false
  if (!value.length) return true
  let hasOnlyMetaAndPositionObjects = true
  value.forEach((object) => {
    if (!isMetaAndPosition(object)) {
      hasOnlyMetaAndPositionObjects = false
    }
  })
  return hasOnlyMetaAndPositionObjects
}

const isConversationObject = (value: any): value is Conversation => {
  if (!value) return false
  if (
    typeof value.id === 'string' &&
    typeof value.created === 'number' &&
    typeof value.completedSurveyId === 'number' &&
    typeof value.unreadMessagesCount === 'number' &&
    value.lastMessageReceived === null &&
    typeof value.subject === 'string'
  ) {
    return true
  } else {
    return false
  }
}

const isCategoriesObject = (value: any): value is Categories => {
  if (!value) return false
  if (typeof value !== 'object') return false
  const propertyValues = Object.values(value)
  if (!propertyValues || !propertyValues.length) return false
  let isStringArrays = true
  propertyValues.forEach((propertyValue) => {
    if (!isStringArray(propertyValue)) isStringArrays = false
  })
  if (isStringArrays) {
    return true
  }
  return false
}

const isOpenAnswersCountsObject = (value: any): value is OpenAnswersCountsResponse => {
  if (!value) return false
  if (typeof value !== 'object') return false
  const hasResProperties = 'results' in value && 'page_metadata' in value
  if (!hasResProperties) return false
  const { results, page_metadata } = value
  if (!results || !Array.isArray(results)) return false
  if (!page_metadata || typeof page_metadata !== 'object') return false
  const hasPageMetas =
    'page_size' in page_metadata &&
    'total_elements' in page_metadata &&
    'total_pages' in page_metadata &&
    'page_number' in page_metadata
  if (!hasPageMetas) return false
  return true
}

const isGroupedOpenAnswersObject = (value: any): value is OpenAnswerResData => {
  if (!value) return false
  if (typeof value !== 'object') return false
  const hasSeries = 'series' in value
  if (!hasSeries) return false
  const series = value.series
  if (!Array.isArray(series)) return false
  return true
}

const isGroupedAndPaginatedOpenAnswersObject = (value: any): value is OpenAnswersResponse => {
  if (!value) return false
  if (typeof value !== 'object') return false
  const hasSeries = 'series' in value
  if (!hasSeries) return false
  const series = value.series
  if (!Array.isArray(series)) return false
  const hasPagination = 'pageMetadata' in value
  if (!hasPagination) return false
  if (!value.pageMetadata) return false
  if (typeof value.pageMetadata !== 'object') return false
  const hasPaginationDetails =
    'page_size' in value.pageMetadata &&
    'total_elements' in value.pageMetadata &&
    'total_pages' in value.pageMetadata &&
    'page_number' in value.pageMetadata
  if (!hasPaginationDetails) return false
  return true
}

const isHotkeyConfig = (value: any): value is CategoriesWithHotkeys[] => {
  if (!value) return false
  if (!Array.isArray(value)) return false
  if (value.length > 0) {
    for (const hotkey of value) {
      if (!isHotkeyObject(hotkey)) return false
    }
  }
  return true
}

const isHotkeyObject = (value: any): value is CategoriesWithHotkeys => {
  if (!value) return false
  if (typeof value !== 'object') return false
  if (
    typeof value.category !== 'string' ||
    typeof value.categoryValue !== 'string' ||
    typeof value.hotkey !== 'string'
  ) {
    return false
  }
  return true
}

const isNumericDataResponseObject = (value: any): value is KpiData.NumericData => {
  if (!value) return false
  if (typeof value !== 'object') return false
  const hasSeries = 'series' in value
  if (!hasSeries) return false
  const series = value.series
  if (!Array.isArray(series)) return false
  const hasCategories = 'categories' in value
  if (!hasCategories) return false
  const categories = value.categories
  if (!Array.isArray(categories)) return false
  return true
}

const isCalculatedKpiList = (value: any): value is GenericConfig.CalculatedKpis => {
  if (!value) return false
  if (typeof value !== 'object') return false
  const kpiNames = Object.keys(value)
  if (!kpiNames.length) return true
  let isCalculatedKpis = true
  kpiNames.forEach((kpiName) => {
    const idAndFunction = value[kpiName]
    const hasFunction = 'function' in idAndFunction
    if (!hasFunction) return (isCalculatedKpis = false)
    const functionProperty = idAndFunction.function
    if (typeof functionProperty !== 'string') return (isCalculatedKpis = false)
    const hasIds = 'kpiIds' in idAndFunction
    if (!hasIds) return (isCalculatedKpis = false)
    const kpiIdsProperty = idAndFunction.kpiIds
    if (!Array.isArray(kpiIdsProperty)) return (isCalculatedKpis = false)
    if (kpiIdsProperty.length && typeof kpiIdsProperty[0] !== 'number')
      return (isCalculatedKpis = false)
  })
  return isCalculatedKpis
}

const isBasicDataFormat = (value: any): value is Data.FormatBasic => {
  if (!value) return false
  if (typeof value !== 'string') return false
  const possibleValues = ['avg', 'n', 'sum'] as Data.FormatBasic[]
  return possibleValues.reduce<boolean>((isBasicFormat, currentBasicFormat) => {
    if (currentBasicFormat === value) return true
    else return isBasicFormat
  }, false)
}

const isTimeRes = (value: any): value is Query.TimeRes => {
  if (!value) return false
  if (typeof value !== 'string') return false
  const possibleValues = ['day', 'week', 'month', 'quarter', 'year']
  return possibleValues.reduce<boolean>((isTimeGroup, currentTimGroup) => {
    if (currentTimGroup === value) return true
    else return isTimeGroup
  }, false)
}

const isCustomerPathObject = (value: any): value is CustomerPathConfig => {
  return true
}

const isMetaFrenquencyResponseObject = (value: any): value is Response.FrequenciesTextual => {
  if (!value || typeof value !== 'object') return false
  if (!('categories' in value)) return false
  if (!Array.isArray(value.categories)) return false
  if (!('series' in value)) return false
  if (!Array.isArray(value.series)) return false
  const series = value.series[0]
  if (!series || typeof series !== 'object') return false
  if (!('name' in series)) return false
  if (series.name !== 'Frequencies') return false
  if (!('is_trend' in series)) return false
  if (series.is_trend !== false) return false
  if (!('data' in series)) return false
  if (!Array.isArray(series.data)) return false
  return true
}

const isNumericFrenquencyResponseObject = (value: any): value is Response.FrequenciesNumeric => {
  return isMetaFrenquencyResponseObject(value)
}

const isOpenFrenquencyResponseObject = (value: any): value is Response.FrequenciesTextual => {
  return isMetaFrenquencyResponseObject(value)
}

const isWhereMetaObject = (value: any): value is WhereMeta => {
  if (!value || typeof value !== 'object') return false
  const keys = Object.keys(value)
  if (!keys.length) return true
  let isWhereMeta = true
  keys.forEach((key) => {
    if (!Array.isArray(value[key])) isWhereMeta = false
  })
  return isWhereMeta
}

const isCalculatedKpis = (data: any): data is { kpis: GenericConfig.CalculatedKpis } => {
  const dataObject = data
  if (typeof dataObject !== 'object' || !dataObject) return false
  const kpis = dataObject.kpis
  if (!kpis) return true
  if (typeof kpis !== 'object') return false
  const keys = Object.keys(kpis)
  if (keys.length === 0) return true
  let areObjectsCorrect = true
  for (const key of keys) {
    const kpi = kpis[key]
    if (!kpi || typeof kpi !== 'object') areObjectsCorrect = false
    const ids = kpi.kpiIds
    if (!ids || !Array.isArray(ids) || ids.length === 0) areObjectsCorrect = false
    for (const id of ids) {
      if (isNaN(Number(id))) areObjectsCorrect = false
    }
  }
  return areObjectsCorrect
}

const hasIdAndName = (data: any): boolean => {
  if (!('id' in data) || !('name' in data)) return false
  if (data.id === null || data.name === null) return false
  if (data.id === undefined || data.name === undefined) return false
  if (data.id === '' || typeof data.name !== 'string') return false
  return true
}

const isNumericKpisResponseObject = (res: unknown): res is { kpis: NumericKpi[] } | null => {
  const response = res
  if (isNull(response)) return true
  if (!response) return false
  if (typeof response !== 'object') return false
  if (!('kpis' in response)) return false
  const kpis = response.kpis
  if (kpis === null) return true
  if (!isArray(kpis)) return false
  let isKpiArray = true
  kpis.forEach((kpi) => {
    if (!hasIdAndName(kpi)) isKpiArray = false
  })
  return isKpiArray
}

const isReportingFiltersObject = (value: any): value is { filters: Metas } | null => {
  if (isNull(value)) return true
  if (!value) return false
  if (typeof value !== 'object') return false
  if (!('filters' in value)) return false
  const filters = value.filters
  if (typeof filters !== 'object' || !filters) return false
  const keys = Object.keys(filters)
  if (!keys.length) return true
  let isMetaValue = true
  for (const key of keys) {
    const item = value[key]
    if (item && !Array.isArray(item)) isMetaValue = false
  }
  return isMetaValue
}

export {
  isStringTuple2,
  isTasksConfig,
  isStringArray,
  isCommonDbSettingsObject,
  isMetaAndPosition,
  isMetaAndPositionArray,
  isConversationObject,
  isNumberArray,
  isIdAndNameObjectArray,
  isCategoriesObject,
  isGroupedOpenAnswersObject,
  isGroupedAndPaginatedOpenAnswersObject,
  isHotkeyConfig,
  isHotkeyObject,
  isOpenAnswersCountsObject,
  isNumericDataResponseObject,
  isCalculatedKpiList,
  isBasicDataFormat,
  isTimeRes,
  isCustomerPathObject,
  isMetaFrenquencyResponseObject,
  isNumericFrenquencyResponseObject,
  isOpenFrenquencyResponseObject,
  isWhereMetaObject,
  isCalculatedKpis,
  isNumericKpisResponseObject,
  isReportingFiltersObject,
}
