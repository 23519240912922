import React, { useMemo, useState } from 'react'
import languages, { Language } from '../../../../../languages/languages'
import { Button, Divider, FormControl, SelectChangeEvent, Tooltip } from '@mui/material'
import useTranslationsConfig, { Translations } from '../../../../stores/useTranslationsConfig'
import {
  DynamicTranslationNameSpace,
  TranslationNameSpace,
  tCommon,
} from '../../../../../languages/i18n'
import { AddCircle, RemoveCircle } from '@mui/icons-material'
import TranslationsTable from './TranslationsTable'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect, useMountedState } from 'react-use'
import { toast } from 'react-toastify'

import css from './TranslationsToolCntr.module.scss'
import LanguagePicker from './LanguagePicker'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import { isTemplateUser } from '../../../../react-services/authService'

const TranslationsToolCntr = () => {
  const isMounted = useMountedState()
  const { i18n } = useTranslation()
  const allLanguages = languages
  const [isAddLanguageDialogOpen, setIsAddLanguageDialogOpen] = useState<boolean>(false)
  const [isRemoveLanguageDialogOpen, setIsRemoveLanguageDialogOpen] = useState<boolean>(false)
  const [isRemoveLanguageConfirmationOpen, setIsRemoveLanguageConfirmationOpen] =
    useState<boolean>(false)
  const [languageToRemove, setLanguageToRemove] = useState<Language | ''>('')
  const [selectedLanguage, setSelectedLanguage] = useState<Language | ''>(Language.EN)
  const [selectedNamespace, setSelectedNamespace] = useState<DynamicTranslationNameSpace>(
    TranslationNameSpace.TEMPLATES,
  )
  const { putTranslations, config: translationConfig } = useTranslationsConfig()

  const tenantLanguages = useMemo(() => {
    if (!translationConfig) return []
    const newTenantLanguages = [] as Language[]
    for (const lang in translationConfig) {
      newTenantLanguages.push(lang as keyof Translations)
    }
    return newTenantLanguages
  }, [translationConfig])

  useDeepCompareEffect(() => {
    if (!tenantLanguages.length) return
    const defaultLanguage = tenantLanguages[0]
    setSelectedLanguage(defaultLanguage || Language.EN)
  }, [tenantLanguages || []])

  const handleOpenAddLanguageDialog = () => setIsAddLanguageDialogOpen(true)
  const handleCloseAddLanguageDialog = () => setIsAddLanguageDialogOpen(false)
  const handleOpenRemoveLanguageDialog = () => setIsRemoveLanguageDialogOpen(true)
  const handleCloseRemoveLanguageDialog = () => setIsRemoveLanguageDialogOpen(false)

  const handleClickNewLanguage = (language: Language) => {
    if (translationConfig?.[language]) return handleCloseAddLanguageDialog()
    putTranslations({ value: { [language]: {} } })
      .then(() => {
        toast.success(tCommon('info.languageAdded'))
      })
      .catch(() => {
        toast.error(tCommon('error.somethingWentWrong'))
      })
      .finally(() => {
        if (isMounted()) handleCloseAddLanguageDialog()
      })
  }

  const handleClickRemoveLanguage = (language: Language) => {
    setLanguageToRemove(language)
    setIsRemoveLanguageConfirmationOpen(true)
    handleCloseRemoveLanguageDialog()
  }

  const handleConfirmRemoveLanguage = () => {
    putTranslations({ value: { [languageToRemove]: null } })
      .then(() => {
        toast.success(tCommon('info.languageRemoved'))
      })
      .catch(() => {
        toast.error(tCommon('error.somethingWentWrong'))
      })
      .finally(() => {
        if (isMounted()) {
          const defaultLanguage = tenantLanguages[0]
          setSelectedLanguage(defaultLanguage || '')
          setIsRemoveLanguageConfirmationOpen(false)
          setLanguageToRemove('')
        }
      })
  }

  const handleTenantLanguageChange = (event: SelectChangeEvent<Language>) => {
    const newLanguage = event.target.value as Language
    setSelectedLanguage(newLanguage)
    i18n.changeLanguage(newLanguage)
  }

  const handleNamespaceChange = (event: SelectChangeEvent<TranslationNameSpace>) => {
    const newNamespace = event.target.value as DynamicTranslationNameSpace
    setSelectedNamespace(newNamespace)
  }

  const handleCancelRemoveLanguage = () => {
    setLanguageToRemove('')
    setIsRemoveLanguageConfirmationOpen(false)
  }

  return (
    <div className={css.cntr}>
      <Tooltip title={tCommon('info.translationTool')}>
        <h5 style={{ width: 'fit-content' }}>{tCommon('label.translationToolTitle')}</h5>
      </Tooltip>
      <Divider sx={{ marginTop: '0.5em', marginBottom: '1em' }} />
      <div>
        <FormControl sx={{ width: '200px', marginRight: '1em' }}>
          <Tooltip title={tCommon('info.addLanguage')}>
            <Button
              variant='contained'
              color='primary'
              endIcon={<AddCircle />}
              onClick={handleOpenAddLanguageDialog}
            >
              {tCommon('button.addLanguage')}
            </Button>
          </Tooltip>
        </FormControl>
        <LanguagePicker
          info={tCommon('info.addLanguage')}
          allLanguages={allLanguages}
          selectableLanguages={allLanguages.filter((l) => !tenantLanguages.includes(l))}
          handleClickNewLanguage={handleClickNewLanguage}
          isAddLanguageDialogOpen={isAddLanguageDialogOpen}
          handleCloseAddLanguageDialog={handleCloseAddLanguageDialog}
        />
        {isTemplateUser() && (
          <FormControl sx={{ width: '200px', marginRight: '1em' }}>
            <Tooltip title={tCommon('info.removeLanguage')}>
              <Button
                variant='contained'
                color='primary'
                endIcon={<RemoveCircle />}
                onClick={handleOpenRemoveLanguageDialog}
              >
                {tCommon('label.removeLanguage')}
              </Button>
            </Tooltip>
          </FormControl>
        )}
        {!!selectedLanguage && (
          <TranslationsTable
            language={selectedLanguage}
            selectedLanguage={selectedLanguage}
            selectedNamespace={selectedNamespace}
            tenantLanguages={tenantLanguages}
            namespace={selectedNamespace}
            handleTenantLanguageChange={handleTenantLanguageChange}
            handleNamespaceChange={handleNamespaceChange}
          />
        )}
        <LanguagePicker
          info={tCommon('info.removeLanguage')}
          allLanguages={tenantLanguages}
          selectableLanguages={tenantLanguages}
          handleClickNewLanguage={handleClickRemoveLanguage}
          isAddLanguageDialogOpen={isRemoveLanguageDialogOpen}
          handleCloseAddLanguageDialog={handleCloseRemoveLanguageDialog}
        />
        {isRemoveLanguageConfirmationOpen && (
          <ConfirmationModal
            title={tCommon('label.removeLanguage')}
            info={tCommon('info.languageRemoveConfirmation', {
              languageToRemove: languageToRemove,
            })}
            handleConfirmation={handleConfirmRemoveLanguage}
            handleCancel={handleCancelRemoveLanguage}
          />
        )}
      </div>
    </div>
  )
}

export default TranslationsToolCntr
