import React from 'react'

const RequiredStar = () => {
  return (
    <div style={{ color: 'red', display: 'inline' }}>
      <b>*</b>
    </div>
  )
}

export default RequiredStar
