import { KpiData, Query } from '../../../../../types'
import { datePlusDays, daysInBetween } from '../../../../react-services/datesService'
import { CustomerPathKpi } from '../../../../stores/useConfigCustomerPath'
import { LimitValues, TopBottomStructuredData } from './topBottomTypes'

const useTopBottomFunctions = () => {
  const getTopBotTrendQuery = (
    query: Query.SingleNumeric | null,
    isTrendsEnabled: boolean,
  ): Query.SingleNumeric | null => {
    if (query && query.start_date && query.end_date && isTrendsEnabled) {
      const newTrendQuery = { ...query }
      const days = daysInBetween(query.start_date, query.end_date)
      newTrendQuery.end_date = datePlusDays(query.start_date, -1)
      newTrendQuery.start_date = datePlusDays(newTrendQuery.end_date, -days)
      return newTrendQuery
    }
    return null
  }

  const getLimitValues = (limitConf: LimitValues): LimitValues => {
    const limit = limitConf
    const confLimitValues = {
      max: limit.max ? limit.max : 8,
      mid: limit.mid ? limit.mid : 7,
      min: limit.min ? limit.min : 6,
    } as LimitValues

    return confLimitValues
  }

  const getTrendIcon = (trendValue: string): string => {
    const value = Number(trendValue)
    if (value > 0) return 'trending_up'
    if (value < 0) return 'trending_down'
    return 'trending_flat'
  }

  const getTrendColor = (iconName: string) => {
    if (iconName === 'trending_up') return 'green'
    if (iconName === 'trending_down') return 'red'
    return '#466480'
  }
  const buildCustomerPathData = (
    customerPath: CustomerPathKpi[],
    numCounts: KpiData.NumericData,
    trendNumCounts: KpiData.NumericData | null,
  ): TopBottomStructuredData[] => {
    const data: TopBottomStructuredData[] = []
    customerPath.map((pathConf) => {
      const kpiIdItem = numCounts.series.find((count) => count.data[0]?.data?.id === pathConf.kpiid)
      const kpiIdTrendItem = trendNumCounts?.series.find(
        (count) => count.data[0]?.data?.id === pathConf.kpiid,
      )

      if (
        kpiIdItem &&
        kpiIdItem.data[0] &&
        kpiIdItem.data[0].value &&
        pathConf &&
        pathConf.kpiid &&
        pathConf.category
      ) {
        data.push({
          kpiid: pathConf.kpiid,
          kpiname: kpiIdItem.name,
          category: pathConf.category,
          average: kpiIdItem.data[0].value,
          trend_change: kpiIdTrendItem?.data[0].value
            ? kpiIdItem.data[0].value - kpiIdTrendItem.data[0].value
            : null,
        })
      }
    })

    return data
  }

  const buildRegularData = (
    numKpis: {
      id: number
      name: string
      grouping?: string | undefined
    }[],
    numCounts: KpiData.NumericData,
    trendNumCounts: KpiData.NumericData | null,
  ) => {
    const data: TopBottomStructuredData[] = []

    numKpis.map((kpi) => {
      const kpiIdItem = numCounts.series.find((count) => count.data[0]?.data?.id === kpi.id)
      const kpiIdTrendItem = trendNumCounts?.series.find(
        (count) => count.data[0]?.data?.id === kpi.id,
      )

      if (kpiIdItem && kpiIdItem.data[0] && kpiIdItem.data[0].value) {
        data.push({
          kpiid: kpi.id,
          kpiname: kpiIdItem.name,
          category: kpi.grouping ? kpi.grouping : 'N/A',
          average: kpiIdItem.data[0].value,
          trend_change: kpiIdTrendItem?.data[0].value
            ? kpiIdItem.data[0].value - kpiIdTrendItem.data[0].value
            : null,
        })
      }
    })

    return data
  }

  return {
    getTopBotTrendQuery,
    getLimitValues,
    getTrendIcon,
    buildCustomerPathData,
    buildRegularData,
    getTrendColor,
  }
}

export default useTopBottomFunctions
