import React from 'react'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { LineChartDataFormats, LineChartModule } from '../lineChartTypes'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'

const options = [
  { value: 'avg', name: 'Averages' },
  { value: 'n', name: 'Counts' },
  { value: 'sum', name: 'Sums' },
  { value: 'rollingAvg', name: 'Rolling Averages' },
]

const KpiFormatDropDown = () => {
  const [{ saveModule, module }] = useSettingsTools()
  const lineModule = module as LineChartModule

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!lineModule) return
    const value = e.currentTarget.value
    const formats = Object.values(LineChartDataFormats) as string[]
    if (formats.includes(value)) {
      const newModule = { ...lineModule, yAxis: value as LineChartDataFormats }
      saveModule(newModule)
    }
  }
  const currentValue = lineModule?.yAxis || ''
  return (
    <div className={`${modal_css.half}`}>
      KPI data format
      <RequiredStar />
      <select
        className={`${css.input} browser-default ${currentValue ? '' : modal_css.required}`}
        onChange={handleOnChange}
        data-testid='kpiFormatDropdownSelect'
        id='kpiFormatDropdownSelect'
        value={currentValue}
      >
        <option key={'emptyOption'} value={''}></option>
        {options.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default KpiFormatDropDown
