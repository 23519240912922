import { cloneDeep } from 'lodash'
import { WhereMeta } from '../../../../../types'

const useGroupModuleMetas = () => {
  const handleMergingMetas = (metas1: WhereMeta, metas2: WhereMeta) => {
    const mergedMetas = cloneDeep(metas1)
    for (const key in metas2) {
      const currentValues = metas2[key]
      const mergedValues = mergedMetas[key]
      if (mergedValues) {
        currentValues.forEach((v) => !mergedValues.includes(v) && mergedValues.push(v))
      } else {
        mergedMetas[key] = currentValues
      }
    }
    return mergedMetas
  }

  return {
    handleMergingMetas
  }
}

export default useGroupModuleMetas
