import React from 'react'
import ModuleFiltersItem from './ModuleFiltersItem'
import ModuleFiltersTimeSelection from './ModuleFiltersTimeSelection'
import { sortObject } from '../../../../../../utils'
import { FILTERCONSTANTS } from '../../../../../react-constants/filters'
import { PropTypes } from 'prop-types'
import MainButton from '../../../../_shared/Buttons/MainButton'
import { isNil } from 'lodash'

function ModuleFiltersExpanded({
  reportingFilters,
  disabledFilters,
  selections,
  hasTimeSelections,
  startDate,
  endDate,
  customDatePickerOptions,
  onChangeMetadata,
  onChangeDate,
  areDatesChangedAfterClosingDatePicker,
  onClose,
  onApplyFilters,
  canChangeFilters,
}) {
  const FILTER_SET = disabledFilters
    ? sortObject(Object.assign({}, disabledFilters, reportingFilters))
    : reportingFilters

  function getSelectionsOfFilter(filter) {
    let result = [FILTERCONSTANTS.SELECT_ALL_VALUE]
    if (selections[filter] && filter in selections && selections[filter].length > 0)
      result = selections[filter]

    return result
  }

  return (
    <div className={`module-filters-expansion`}>
      {!canChangeFilters && (
        <div
          className={`module-filters-expansion-mask`}
          data-testid={`moduleFiltersExpansionMask`}
        ></div>
      )}

      <div data-testid={`expandedModuleFilters`} className='multi-select-filter-container'>
        {Object.keys(FILTER_SET).map((filterKey, index) => (
          <ModuleFiltersItem
            key={index}
            index={index}
            filterKey={filterKey}
            filterValues={reportingFilters[filterKey]}
            selected={getSelectionsOfFilter(filterKey)}
            isDisabled={!reportingFilters[filterKey] || !canChangeFilters}
            onChange={onChangeMetadata}
          />
        ))}
      </div>

      {hasTimeSelections && (
        <ModuleFiltersTimeSelection
          startDate={startDate}
          endDate={endDate}
          onChangeDate={onChangeDate}
          customDatePickerOptions={customDatePickerOptions}
          isChangedAfterClose={areDatesChangedAfterClosingDatePicker}
          isDisabled={!canChangeFilters}
        />
      )}
      {(!isNil(onClose) || !isNil(onApplyFilters)) && (
        <div>
          {onClose && (
            <MainButton
              text='Apply & Close'
              icon='expand_less'
              iconPosition={'right'}
              float='right'
              onClick={onClose}
            />
          )}
          {onApplyFilters && (
            <MainButton
              isDisabled={!canChangeFilters}
              text='Apply'
              float='right'
              onClick={onApplyFilters}
              data-testid='filtersApplyButton'
              customClasses={['module-filters-apply-button']}
            />
          )}
        </div>
      )}
    </div>
  )
}

ModuleFiltersExpanded.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  reportingFilters: PropTypes.object,
  selections: PropTypes.object,
  seriesBy: PropTypes.string,
  customDatePickerOptions: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeMetadata: PropTypes.func,
  areDatesChangedAfterClosingDatePicker: PropTypes.bool,
  onClose: PropTypes.func,
  onApplyFilters: PropTypes.func,
  canChangeFilters: PropTypes.bool,
}

ModuleFiltersExpanded.defaultProps = {
  hasTimeSelections: true,
  disabledFilters: null,
  customDatePickerOptions: {},
  areDatesChangedAfterClosingDatePicker: false,
  canChangeFilters: true,
}

export default ModuleFiltersExpanded
