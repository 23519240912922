import React, { useEffect, useState } from 'react'
import {
  getLimitedAccessAlert,
  getLimitedAccessTokenAndClaims,
  limitedAlertSave,
} from '../../../../../react-services/alertLimitedService'
import { generatePayload } from '../../../../../react-services/alertService'
import FeedbackAlertModal from './FeedbackAlertModal'
import './FeedbackAlertModal.scss'
import { useAlertData, useAlertTools, withAlertContext } from '../FeedbackAlertsTableCntr'
import { AlertChanges, FeedbackAlert, LimitedAccessClaims } from '../alertTypes'
import { useParams } from 'react-router-dom'

function AlertEmailContainer() {
  const { token } = useParams()
  const [alertData, setAlertData] = useAlertData()
  const setAlertTools = useAlertTools()[1]
  const [message, setMessage] = useState<string>('Modifying alert...')
  const [showModal, setShowModal] = useState<boolean>(true)
  const [accessClaims, setAccessClaims] = useState<LimitedAccessClaims>()
  const [alertToken, setAlertToken] = useState<string>()

  useEffect(() => {
    if (!token) return
    getLimitedAccessTokenAndClaims(token)
      .then((res) => {
        setAccessClaims(res.claims)
        setAlertToken(res.token)
      })
      .catch(() => setMessage('Something went wrong while fetching alert...'))
  }, [])

  useEffect(() => {
    if (alertToken && accessClaims) {
      getLimitedAccessAlert(alertToken, accessClaims)
        .then((res) => {
          const alert_configuration = {
            assign: false,
            categories: res?.alertCategories?.categories,
            hide_recipients: res?.alertCategories?.hide_recipients,
            metakeys: res?.whitelistedMetas?.meta_keys,
          }
          setAlertData((prev) => ({
            ...prev,
            alert: res.alertData,
            alertConfig: alert_configuration,
          }))
        })
        .catch(() => setMessage('Something went wrong while fetching alert...'))
    }
  }, [alertToken, accessClaims])

  useEffect(() => {
    setAlertTools((prev) => ({ ...prev, handleAlertSave }))
  }, [])

  function handleShowModal() {
    setShowModal(false)
    setMessage('No changes were saved. If you wish to modify, please reload/revisit this page.')
  }

  function handleAlertSave(alert: FeedbackAlert, changes: AlertChanges) {
    const payload = generatePayload(alert, changes)
    limitedAlertSave(payload)
      .then(() =>
        setMessage(
          'Thank you! Your changes were succesfully saved. You may now safely close this tab/window.',
        ),
      )
      .catch(() =>
        setMessage(
          'Unfortunately something went wrong when saving your changes. You may try to reload this page.',
        ),
      )
    setShowModal(false)
  }

  return (
    <>
      <h4 style={{ textAlign: 'center' }}>{message}</h4>
      {showModal && alertData && alertData.alert && alertData.alertConfig && (
        <FeedbackAlertModal onCancel={handleShowModal} isLimitedAlert={true} />
      )}
    </>
  )
}

export default withAlertContext(AlertEmailContainer)
