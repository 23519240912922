import React, { memo } from 'react'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DynamicTranslationNameSpace, TranslationNameSpace } from '../../../../../languages/i18n'
import { upperFirst } from 'lodash'

import css from './GenericTableTitle.module.scss'

type GenericTableTitleProps = {
  title: string
  isLoading: boolean
  loadingText: string
  useGrouping: boolean
  selectedGrouping: string
  selectedCounting?: string
  onGroupingChange: (event: SelectChangeEvent<string>) => void
  groupings: string[]
  groupingsNamespace: DynamicTranslationNameSpace
  countings?: string[]
  countingsNamespace?: DynamicTranslationNameSpace
  useCounting?: boolean
  onCountingChange?: (event: SelectChangeEvent<string>) => void
}
const GenericTableTitle = memo(
  ({
    isLoading,
    loadingText,
    useGrouping,
    selectedGrouping,
    selectedCounting = '',
    onGroupingChange,
    groupings,
    groupingsNamespace,
    countings = [],
    countingsNamespace = TranslationNameSpace.CATEGORIES,
    useCounting = false,
    onCountingChange = () => {},
  }: GenericTableTitleProps) => {
    const { t } = useTranslation()
    const getTitleClasses = function () {
      const result = ['generic-table-title', css.cntr]
      useGrouping && result.push('with-dropdown')
      return result.join(' ')
    }

    return (
      <div className={getTitleClasses()}>
        {useGrouping && (
          <FormControl sx={{ maxWidth: '45%' }}>
            <InputLabel id='grouping-label'>{t('common.label.grouping')}</InputLabel>
            <Select
              value={selectedGrouping || 'empty'}
              renderValue={(value) =>
                upperFirst(t(value.toLocaleLowerCase(), { ns: groupingsNamespace }))
              }
              onChange={(e) => onGroupingChange(e)}
              label={t('common.label.grouping')}
              labelId='grouping-label'
              data-testid='genericTableGroupBy'
            >
              <MenuItem value='empty' key={'emptyValue'} data-testid='genericTableGroupByOption'>
                {t('common.info.selectGrouping')}
              </MenuItem>
              {(groupings || []).map((grouping, i) => (
                <MenuItem
                  value={grouping}
                  key={grouping + i}
                  data-testid='genericTableGroupByOption'
                >
                  {upperFirst(t(grouping.toLocaleLowerCase(), { ns: groupingsNamespace }))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {useCounting && (
          <FormControl sx={{ maxWidth: '45%', marginLeft: '10px' }}>
            <InputLabel id='counting-label'>{t('common.label.counting')}</InputLabel>
            <Select
              value={selectedCounting || 'empty'}
              renderValue={(value) =>
                upperFirst(t(value.toLocaleLowerCase(), { ns: countingsNamespace }))
              }
              onChange={(e) => onCountingChange(e)}
              label={t('common.label.counting')}
              labelId='counting-label'
              data-testid='genericTableCountBy'
            >
              <MenuItem value='empty' key={'emptyValue'} data-testid='genericTableCountByOption'>
                {t('common.info.selectCounting')}
              </MenuItem>
              {(countings || []).map((counting, i) => (
                <MenuItem
                  value={counting}
                  key={counting + i}
                  data-testid='genericTableCountByOption'
                >
                  {upperFirst(t(counting.toLocaleLowerCase(), { ns: countingsNamespace }))}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {isLoading && (
          <>
            <LoadingIndicator hasMaxContainer={false} />
            <span className='generic-table-title-loading-indicator-text'>{loadingText}</span>
          </>
        )}
      </div>
    )
  },
)

GenericTableTitle.displayName = 'GenericTableTitle'

export default GenericTableTitle
