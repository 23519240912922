/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  cloneDeep,
  each,
  get,
  isArray,
  isDate,
  isEmpty,
  isEqual,
  isNil,
  isNull,
  isPlainObject,
  isUndefined,
} from 'lodash'
import { isTemplateUser } from '../../../../react-services/authService'
import { ReportingFilters } from './singleGraphTypes'
import { isPdfReportMode } from '../../../../utilities'
import { decodeURIComponentSafe } from '../../../../react-services/helperFunctionService'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { Dashboard, DashboardSettings } from '../../../../stores/useDbTemplateConfig'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import { GenericConfig, WhereMeta } from '../../../../../types'
import { CustomFilter, GroupModule, Module, ModuleType } from '../Group/groupModuleTypes'
import useKpiExtractor from '../Group/list/useKpiExtractor'
import { getRandomInt } from '../../../../react-services/mathService'
import {
  attachKpiObjectToQuery,
  attachMetaToQuery,
  attachMetaValuesToQuery,
  getNewQuery,
  getQueryEndDate,
  getQueryMeta,
  getQueryStartDate,
  getQueryStringArray,
  removeMetaKeysFromQuery,
} from '../../../../react-services/queryService'
import {
  convertQueryToFilterBarObject,
  createQuery2FiltersFromModuleSettings,
  createQuery2FromModuleSettings,
  datePlusDays,
  deepMergeQueries,
  fetchDynamicFilters,
  filterSubmodulesByIndex,
  getScreenRootClasses,
  queryContainsFilterWithValue,
  removeUnusedFiltersFromQueryService,
  todayPlusDays,
  updateQueryToModuleTimeframeSelection,
} from './HelperFunctions'
import {
  createTextualQuery2FromModuleSettings,
  getModuleTimeFrame,
  getModuleWidth,
  moduleHasCustomTimeFilters,
  useCustomFilters,
  useMergeFilters,
  useQuickTimeframeButton,
  useWindowedTimeframe,
  useWindowedTimeframeFuture,
} from '../../../../react-services/moduleService'
import {
  isSupportedModule,
  numberOfModulesDoneLoading,
} from '../../../../react-services/reportsService'
import { differenceInDays, sub } from 'date-fns'
import ScreenList from './List/ScreenList'
import ScreenRepeater from './List/ScreenRepeater'
import {
  convertFilterArrayToFilterObject,
  convertFilterObjectToFilterArray,
} from '../../../../react-services/filterService'

import './../../../../../styles/print.scss'

/** This object is/should be explicitly set by us in the image reports Puppeteer!
 */

/*
 *
 * This controller is synced with backend's image report generator
 * If an alert is given here, image report is not sent
 *
 */

/*
 * List of supported screen/report modules
 *
 * !! NOTE WHEN ADDING NEW MODULES !!
 *
 * For 'waitForModulesToLoad' function to work properly,
 * make sure the new module(s) have the correct class name to indicate when data is ready.
 * Use 'modulereadyclass' directive for this.
 *
 * Modules will also need to indicate that they have no data when that is the case.
 * To do this, add a conditional 'data-no-data' HTML attribute somewhere into their container element:
 * Example:
 * <div clas="foo" data-no-data="true"> ... </div>
 * Note that you MUST include the ="true" part so it is explicitly set and easy to query directly.
 *
 */
declare global {
  interface Window {
    currentReport?: {
      forced_filters?: unknown
    }
  }
}

export type SingleGraphCntrProps = {
  token: string | null
  dateString: string | null
  dashboardIdx: number | null
  moduleIdx: number | null
  sectionIdx: number | null
  pastDays: number | null
  futureDays: number | null
  scrollPage: boolean | null
  forcedFilters: unknown | null
  moduleFromUrlString: unknown | null
  moduleIndicesFromUrl: unknown | null
  filterUpdateFromUrl: unknown | null
  moduleFromUrl: Module | null
  autoTimeScale: boolean | null
  moduleIndices: unknown | null
  currentTemplate: DashboardSettings | null
  tenantReportingFilters?: WhereMeta | null
  handleSettingsAutoTimeScale: (autoTimeScale: boolean) => void
}
const SingleGraphCntr = ({
  dateString,
  dashboardIdx,
  futureDays,
  moduleIdx,
  autoTimeScale,
  pastDays,
  scrollPage,
  forcedFilters,
  moduleFromUrl,
  moduleIndices,
  currentTemplate,
  filterUpdateFromUrl,
  tenantReportingFilters,
  handleSettingsAutoTimeScale,
}: SingleGraphCntrProps) => {
  const initReportingFilters = { all: tenantReportingFilters || null, root: null }
  const IMAGE_REPORT_CONFIG_PPTR: any = window.currentReport
  const [reportingFilters, setReportingFiltersState] =
    useState<ReportingFilters>(initReportingFilters)
  const [displayedFilters, setDisplayedFilters] = useState<any>([])
  const [forcedFilterKeys, setForcedFilterKeys] = useState<string[] | null>(null)
  const [graphSettings, setGraphSettings] = useState<any>(null)
  const { handleExtractingKpiIdsFromModules } = useKpiExtractor()
  const [groupSettings, setGroupSettings] = useState<any>(null)
  const [reportingFiltersIdx, setReportingFiltersIdx] = useState<number>(1)
  const dynamicFiltersIdPrefix = 'singleGraphFilters_'
  const metaContactForcedFilters = getForcedFilters()
  const [useDynamicFilters, setUseDynamicFilters] = useState(false)
  const [useDynamicTimeFilters, setUseDynamicTimeFilters] = useState(false)
  const [isGrouped, setIsGrouped] = useState(false)

  /*
   * If autoUpdateFilters=false, use filters as is
   * (i.e. do not set end date to today, do not add preselected filters)
   * This is used for dashboard exports
   *
   * Also, if autoTimeScale in user's db settings is set to false, do not set end date to today
   */
  const autoUpdateFilters = getFilterUpdateFromUrl()
  const [conflictingFilters, setConflictingFilters] = useState<any>({})
  const [dashKpis, setDashKpis] = useState<number[]>([])
  const [loopFetchCount, setLoopFetchCount] = useState<number>(0)
  // Stop waiting for modules to load after 5 minutes
  const waitTimeoutMs = 300000
  let waitInterval: any = null
  let waitTimeout: any = null

  const error = false

  const { config: commonDbSettings } = useCommonDbSettingsConfig()

  useEffect(() => {
    setUseDynamicFilters(hasDynamicFilters(commonDbSettings))
    setUseDynamicTimeFilters(hasDynamicTimeFilters(commonDbSettings))
    if (!currentTemplate || isTemplateUser()) return
    const forcedFilters = commonDbSettings?.template_forced_filters
    if (forcedFilters) setForcedFilterKeys((prev) => [...(prev || []), ...forcedFilters])
  }, [commonDbSettings])

  function hasDynamicFilters(config?: GenericConfig.CommonDbSettings) {
    return (config && config.use_dynamic_filters === true) || hasDynamicTimeFilters(config)
      ? true
      : false
  }

  function hasDynamicTimeFilters(config?: GenericConfig.CommonDbSettings) {
    return config && config.use_dynamic_filters === 'timeframe' ? true : false
  }

  function getForcedFilters() {
    const forcedFilters =
      isPdfReportMode() || !!scrollPage ? getForcedFiltersFromWindow() : getForcedFiltersFromUrl()
    if (!isEmpty(forcedFilters)) console.log('Use forced filters: ', forcedFilters)
    return isEmpty(forcedFilters) ? null : forcedFilters
  }

  function getForcedFiltersFromWindow() {
    return IMAGE_REPORT_CONFIG_PPTR?.forced_filters
  }

  function getForcedFiltersFromUrl() {
    if (!forcedFilters) return
    try {
      const jsonFiltersFromUrl = JSON.parse(decodeURIComponentSafe(forcedFilters))
      if (jsonFiltersFromUrl) {
        console.log('Forced filters from URL param:', JSON.stringify(jsonFiltersFromUrl, null, 2))
        return jsonFiltersFromUrl
      }
    } catch (e) {
      alert('Error parsing URL param forced_filters')
      console.log(e)
    }
  }

  function isPuppeteerDashboardExport() {
    return !isNil(getForcedDashFromWindow())
  }

  function getForcedDashFromWindow() {
    return IMAGE_REPORT_CONFIG_PPTR?.forced_dash ?? null
  }

  function getFilterUpdateFromUrl() {
    if (!filterUpdateFromUrl) return
    try {
      const jsonFilterUpdateFromUrl =
        JSON.parse(decodeURIComponentSafe(filterUpdateFromUrl)) === false ? false : true
      console.log('Enable filter auto updates: ' + jsonFilterUpdateFromUrl)
      return jsonFilterUpdateFromUrl
    } catch (e) {
      alert('Error parsing URL param filter_update')
      console.log(e)
    }
  }

  function checkForcedFiltersFromUrl() {
    if (metaContactForcedFilters) {
      // const allowedFilters = useDynamicTimeFilters
      //   ? getReportingFilters('all')
      //   : getReportingFilters()
      const allowedFilters = getReportingFilters('all')
      const validForcedFilters = queryContainsFilterWithValue(
        allowedFilters,
        metaContactForcedFilters,
      )

      if (!validForcedFilters) {
        alert(
          'Invalid filters from URL param "forced_filters": ' +
            JSON.stringify(metaContactForcedFilters),
        )
      }
    }
  }

  /**
   *
   * @returns {Date | undefined}
   */
  function getOverrideEndDate() {
    try {
      if (!dateString && IMAGE_REPORT_CONFIG_PPTR) {
        dateString = IMAGE_REPORT_CONFIG_PPTR.overrideEndDate
      }
      if (!dateString) {
        return undefined
      }
      const dateReportWasDue = new Date(dateString)
      if (isDate(dateReportWasDue)) {
        return dateReportWasDue
      } else {
        console.log('Override end date is not a valid date format', dateString)
        return undefined
      }
    } catch (error) {
      console.log(error)
      return undefined
    }
  }

  useEffect(() => {
    if (moduleFromUrl) {
      parseDashboard()
    } else {
      initDashboard()
    }
  }, [currentTemplate])

  function initDashboard() {
    const dashboard = currentTemplate?.dashboards
      ? currentTemplate.dashboards[dashboardIdx || 0]
      : null
    if (!dashboard) return alert('No dashboard available at index ' + dashboardIdx)
    console.log('SingleGraphCtrl: successfully initialized dashboard:')
    parseDashboard(cloneDeep(dashboard))
  }

  function parseDashboard(dashboard?: Dashboard) {
    try {
      if (moduleFromUrl) {
        parseModule(moduleFromUrl as Module)
      } else if (dashboard) {
        const module = cloneDeep(dashboard.modules?.[moduleIdx || 0] || {})
        parseModule(module)
      } else {
        console.log('SingleGraphCtrl: ERROR - no dashboard available at index ' + dashboardIdx)
        alert('No dashboard available at index ' + dashboardIdx)
      }
    } catch (error) {
      console.error('Failed to parse the dashboard.')
      console.error(error)
    }
  }

  function attachPreselectionsToModuleSettings(module: any) {
    const newModule = cloneDeep(module)
    if (!newModule) return newModule
    const query = createQuery2FiltersFromModuleSettings(newModule)
    attachPreselectedFiltersToQuery(newModule, query)
    const newSettings = convertQueryToFilterBarObject(query)
    console.log('SingleGraphCtrl: converted settings', newSettings)
    newModule.settings = cloneDeep(newSettings)
    return newModule
  }

  function attachPreselectionsToModuleQuery(module: Module) {
    // Update module.settings as well if they exist
    const newModule = attachPreselectionsToModuleSettings(module)
    // Update module.query
    if (newModule && newModule.query && !isEmpty(newModule.query)) {
      attachPreselectedFiltersToQuery(newModule, newModule.query)
      console.log('SingleGraphCtrl: converted query', module.query)
    }
    return newModule
  }

  function attachPreselectedFiltersToQuery(module: any, query: any) {
    if (!module || !query) return
    attachTemplateForcedFiltersToQuery(module, query)
    // Attach forced filters from URL
    attachMetaToQuery(metaContactForcedFilters, query)
    console.log('Query after attaching preselected + forced filters', query)
  }

  function attachTemplateForcedFiltersToQuery(module: any, query: any) {
    if (
      !module ||
      !query ||
      isEmpty(query) ||
      autoUpdateFilters === false ||
      module.customFilters === true ||
      module.customFilters === 'custom'
    )
      return
    // Attach preselected template filters to query
    console.log('attachTemplateForcedFiltersToQuery')
    console.log('forcedfilterkeys', forcedFilterKeys, 'reportingfilters', getReportingFilters())
    attachMetaValuesToQuery(forcedFilterKeys, getReportingFilters(), query)
    if (!useDynamicTimeFilters) removeMetaKeysFromQuery(query, conflictingFilters)
  }

  function updateModuleQuerySettings(module: any, filters?: any) {
    if (!module) return
    let query
    if (module.settings) {
      query = createQuery2FiltersFromModuleSettings(module)
    }
    if (module.query && !isEmpty(module.query)) {
      query = module.query
      if (autoUpdateFilters && !isPuppeteerDashboardExport()) {
        updateQueryToModuleTimeframeSelection(module, query)
      }
    }
    updateFiltersInQuery(query, filters, module)
    if (module.settings) {
      module.settings = convertQueryToFilterBarObject(query)
    }
    if (module.query && !isEmpty(module.query)) {
      module.query = query
    }
    return query
  }

  async function parseModule(module: Module) {
    if (module) {
      if (module.type === ModuleType.GROUP && typeof module.autoTimeScale === 'boolean') {
        handleSettingsAutoTimeScale(module.autoTimeScale)
      }
      const newModule = removeForcedFiltersFromModuleSettings(module)
      const extractedKpis = handleExtractingKpiIdsFromModules([newModule], true)
      setDashKpis(extractedKpis)
      const isUsingDynamicFilters =
        commonDbSettings?.use_dynamic_filters === true ||
        commonDbSettings?.use_dynamic_filters === 'timeframe'
      if (extractedKpis && extractedKpis.length && !doUseAllReportingFilters(newModule)) {
        if (isUsingDynamicFilters) {
          await loopFetchDynamicFilters(newModule)
        }
      }
      parseModuleGraphSettings(newModule)
    } else {
      console.log('SingleGraphCtrl: ERROR - no module available at index ' + moduleIdx)
      alert('No module available at index ' + moduleIdx) // Do not alter! This exact string is used in Backend
    }
  }
  function removeForcedFiltersFromModuleSettings(module: Module) {
    if (!module || !forcedFilterKeys || !useDynamicFilters || !autoUpdateFilters) return module
    if (module.type === ModuleType.GROUP) {
      const filterType = module.customfilters as CustomFilter | boolean
      const hasCustomFilters = filterType === 'custom' || filterType === true
      if (hasCustomFilters) return module
      const newModule = cloneDeep(module)
      const groupModuleSettings = newModule.settings
      if (groupModuleSettings) {
        const filters = convertFilterArrayToFilterObject(groupModuleSettings)
        const newFilters = cloneDeep(filters)
        if (!newFilters.where_meta) newFilters.where_meta = {}
        for (const key in forcedFilterKeys) {
          delete newFilters.where_meta[key]
        }
        const newSettings = convertFilterObjectToFilterArray(newFilters)
        newModule.settings = newSettings
        return newModule
      }
    } else if (module.query) {
      const newModule = cloneDeep(module)
      const currentQuery = newModule.query
      if (currentQuery?.where_meta) {
        for (const key in forcedFilterKeys) {
          delete currentQuery.where_meta[key]
        }
        newModule.query = currentQuery
      }
    }
    return module
  }

  //   const query = createQuery2FiltersFromModuleSettings(module)
  //   queryService.removeMetaKeysFromQuery(query, forcedFilterKeys)
  //   let newSettings = moduleFiltersService.convertQueryToFilterBarObject(query)
  //   module.settings = newSettings
  // } else if (module.query && !isEmpty(module.query)) {
  //   queryService.removeMetaKeysFromQuery(module.query, forcedFilterKeys)
  // }

  async function parseModuleGraphSettings(module: any) {
    filterModules(module)
    const isGroup = module && (module.type === 'group' || module.type === 'groupnps')
    setIsGrouped(isGroup)
    updateTimeFrameDays(module)
    console.log('SingleGraphCtrl: parse module w/ settings, query', module.settings, module.query)
    const newModule = attachPreselectionsToModuleQuery(module)
    newModule.query = updateModuleQuerySettings(newModule)

    attachDisplayedFilters(newModule)
    if (isGroup) {
      const newGroupSettings = newModule
      console.log(newGroupSettings, 'CREATING GROUP SETTINGS')

      setGroupSettings(newGroupSettings)
      // Create graph settings for all group's submodules
      const modules = newModule.modules

      const graphSettings = [] as any[]
      for (let i = 0; i < modules.length; i++) {
        if (isSupportedModule(modules[i]))
          graphSettings.push(createGraphSettings(modules[i], newGroupSettings))
      }
      Promise.all(graphSettings).then(function (res: any) {
        setGraphHeights(res)
        attachGraphSettings(res)
      })
    } else {
      createGraphSettings(newModule).then(function (res: any) {
        attachGraphSettings(res)
      })
    }
    checkForcedFiltersFromUrl()
  }

  function filterModules(module: any) {
    if (moduleIndices && module.modules) {
      const filteredModule = filterSubmodulesByIndex(module, moduleIndices)
      if (!filteredModule) {
        alert(
          'Failed to filter submodules at indices: ' +
            moduleIndices +
            '. Submodule count was ' +
            module?.length,
        )
      }
    }
  }

  function attachGraphSettings(settings: any) {
    const moduleCount = getNumberOfModules(settings)
    if (moduleCount === 0) alert('No modules to display!')
    if (settings) {
      attachModuleOrderIndex(settings)
      const newGraphSettings = settings
      setGraphSettings(settings)
      console.log('graphSettings', newGraphSettings)
    }
    waitForModulesToLoad(settings)
  }

  function attachModuleOrderIndex(graphSettings: any, startIdx?: any) {
    let idx = startIdx >= 0 ? startIdx : 0
    if (graphSettings && idx !== null && idx !== undefined) {
      if (Array.isArray(graphSettings)) {
        each(graphSettings, function (settings) {
          if (settings.submodulesettings) {
            const currentIdx = attachModuleOrderIndex(settings.submodulesettings, idx)
            idx = currentIdx
          } else {
            setModuleOrderIdx(settings, idx)
            idx++
          }
        })
      }
    }
    return idx
  }

  function setModuleOrderIdx(settings: any, idx: any) {
    if (settings && idx >= 0) {
      setModuleOrderIndex(settings, idx)
    }
  }

  function setModuleOrderIndex(module: any, idx: any) {
    if (module) module.module_order_idx = idx
  }
  function waitForModulesToLoad(settings: any) {
    const moduleCount = getNumberOfModules(settings)
    // Check if modules are done loading every 1 second(s)
    if (waitInterval) clearInterval(waitInterval)
    waitInterval = setInterval(function () {
      const n = numberOfModulesDoneLoading()
      console.log('Checking if modules are done loading... ' + n + '/' + moduleCount + ' ready')
      if (n >= moduleCount) {
        modulesLoaded(moduleCount, n)
      }
    }, 1000)
    // Stop waiting after a while
    waitTimeout = setTimeout(function () {
      console.log('Give up waiting for modules after ' + waitTimeoutMs + 'ms')
      const n = numberOfModulesDoneLoading()
      modulesLoaded(moduleCount, n)
    }, waitTimeoutMs)
  }

  function modulesLoaded(moduleCount: any, readyCount: any) {
    console.log(readyCount + '/' + moduleCount + ' modules loaded successfully!')
    cancelWaitModuleTimers()
    // Phantomjs callback to indicate modules are done loading
    if (typeof (window as any).callPhantom === 'function') {
      ;(window as any).callPhantom({
        modulesReady: readyCount,
        modulesTotal: moduleCount,
      })
    }
  }

  function cancelWaitModuleTimers() {
    clearInterval(waitInterval)
    clearTimeout(waitTimeout)
  }

  function getNumberOfModules(graphSettings: any) {
    if (graphSettings) {
      if (Array.isArray(graphSettings)) {
        let count = 0
        each(graphSettings, function (settings) {
          if (settings.submodulesettings) {
            const subCount = getNumberOfModules(settings.submodulesettings)
            count += subCount
          } else {
            count++
          }
        })
        return count
      } else {
        return 1
      }
    } else {
      return 0
    }
  }

  function updateFiltersInQuery(query: any, filtersList: any, module: any) {
    if (query && !isEmpty(query)) {
      updateQueryDates(query, module)
      if (!useDynamicTimeFilters) removeUnusedFiltersFromQuery(filtersList, query)
    }
  }

  function removeUnusedFiltersFromQuery(filtersList: any, query: any) {
    if (query && !isEmpty(query)) {
      const filters = filtersList ? filtersList : getReportingFilters()
      removeUnusedFiltersFromQueryService(filters, query)
    }
  }

  function attachDisplayedFilters(module: any) {
    // Get filters as a string, to be displayed on top of the page in reports mode
    const query2 = module.query
    setDisplayedFilters(getQueryStringArray(query2))
  }

  function getUpdatedQueryFromSettings(module: Module) {
    const newModule = attachPreselectionsToModuleQuery(module)
    return updateModuleQuerySettings(newModule)
  }

  function createGraphSettings(settings: any, group_settings?: any, id?: any): Promise<any> {
    const promise = new Promise((resolve) => {
      console.log('SingleGraphCtrl: createGraphSettings')
      const graphSettings = cloneDeep(settings)
      const groupSettings = cloneDeep(group_settings)
      let useGlobalFilters = false
      const graphReportingFilters = id ? getReportingFilters(id) : null
      console.log('SingleGraphCtrl: groupSettings:')
      console.log(groupSettings)
      console.log('SingleGraphCtrl: graphSettings before:')
      console.log(graphSettings)
      // Remove livedata toggle from settings (don't allow live data in screen/reports view)
      if (graphSettings) {
        delete graphSettings.livedata
      }
      updateTimeFrameDays(graphSettings)
      // Copy timeframedays from parent module to a sub-module UNLESS custom filters/custom time frame is used
      if (
        groupSettings &&
        groupSettings.timeframedays !== undefined &&
        !useCustomFilters(graphSettings) &&
        !moduleHasCustomTimeFilters(graphSettings)
      ) {
        graphSettings.timeframedays = cloneDeep(groupSettings.timeframedays)
      }
      // Copy timeframedays_future from parent module to a sub-module UNLESS custom filters/custom time frame is used
      if (
        groupSettings &&
        groupSettings.timeframedays_future !== undefined &&
        !useCustomFilters(graphSettings) &&
        !moduleHasCustomTimeFilters(graphSettings)
      ) {
        graphSettings.timeframedays_future = cloneDeep(groupSettings.timeframedays_future)
      }
      // Copy quicktimeframebutton from parent module to a sub-module UNLESS custom filters/custom time frame is used
      if (
        groupSettings &&
        groupSettings.quicktimeframebutton !== undefined &&
        !useCustomFilters(graphSettings) &&
        !moduleHasCustomTimeFilters(graphSettings)
      ) {
        graphSettings.quicktimeframebutton = cloneDeep(groupSettings.quicktimeframebutton)
      }

      if (useCustomFilters(graphSettings)) {
        // do nothing
      } else if (useMergeFilters(graphSettings) || moduleHasCustomTimeFilters(graphSettings)) {
        if (useMergeFilters(graphSettings)) console.log('Merge filters mode')
        if (moduleHasCustomTimeFilters(graphSettings)) console.log('Custom time filters mode')
        // Merge parent & sub group filters
        useGlobalFilters = true
        graphSettings.settings = convertQueryToFilterBarObject(
          deepMergeQueries(groupSettings.settings, graphSettings.settings),
        )
        console.log(graphSettings, 'CUSTOM FILTERS MODE')
      } else if (groupSettings && groupSettings.settings) {
        console.log('Inherit filters mode')
        // Set group filter as settings
        useGlobalFilters = true
        // Store tabular settings in temp variable (filter settings will override graphSettings.settings)
        if (graphSettings.type === 'tabular') {
          graphSettings.tabularSettings = graphSettings.settings
        }
        graphSettings.settings = groupSettings.settings
      }
      // Create settings for submodules (group module inside group)
      if (graphSettings.modules) {
        // Fetch subgroup dynamic filters
        fetchSubGroupDynamicFilters(graphSettings).then(
          function (subGroupId: any) {
            if (subGroupId) {
              console.log('Fetched subgroup dynamic filters for id', subGroupId)
              console.log('Current reportingFilters: ', getReportingFilters(subGroupId))
              updateModuleQuerySettings(graphSettings, getReportingFilters(subGroupId))
            }
            const submodulesettings = [] as any[]
            const submodules = graphSettings.modules
            for (let j = 0; j < submodules.length; j++) {
              submodulesettings.push(
                createGraphSettings(submodules[j], graphSettings, subGroupId ? subGroupId : id),
              )
            }

            const promise = Promise.all(submodulesettings).then(function (res) {
              console.log('submodulesettings res:')
              console.log(res)
              setGraphHeights(res)
              graphSettings.submodulesettings = res
              return graphSettings
            })
            return resolve(promise)
          },
          function (error: any) {
            console.log('Error fetching dynamic filters for subgroup')
            console.error(error)
            console.log(graphSettings, 'SUB MODULES')
            return resolve(graphSettings)
          },
        )
      }

      // OPEN MODULE
      if (graphSettings.type === 'open') {
        graphSettings.isGraph = true
        graphSettings.searchfilter = settings.searchfilter
        if (useGlobalFilters) {
          createTextualQuery2FromModuleSettings(graphSettings).then(
            function (query) {
              updateFiltersInQuery(query, graphReportingFilters, graphSettings)
              graphSettings.query = query
              if (settings?.query?.kpis?.length && !graphSettings?.query?.kpis?.length)
                if (!graphSettings.query) graphSettings.query = {}
              graphSettings.query.kpis = settings.query.kpis

              console.log('SingleGraphCtrl: graphSettings after:')
              console.log(cloneDeep(graphSettings))
              return resolve(graphSettings)
            },
            function (e) {
              return resolve(graphSettings)
            },
          )
        } else {
          updateFiltersInQuery(graphSettings.query, graphReportingFilters, graphSettings)
          return resolve(graphSettings)
        }
        // ALL OTHER MODULES
      } else {
        let promiseOfQueryObject
        if (graphSettings.type === 'line' || graphSettings.type === 'bubble') {
          // handle special cases
          const query2 = transformFiltersToWhereMetaForm(graphSettings.settings)
          updateQueryToModuleTimeframeSelection(graphSettings, query2)
          promiseOfQueryObject = mergeObjectsToPromise(graphSettings.query, query2)
        } else {
          promiseOfQueryObject = createQuery2FromModuleSettings(graphSettings)
        }

        promiseOfQueryObject.then(function (res: any) {
          let query = res
          console.log('SingleGraphCtrl: query 2 created from module settings:')
          console.log(cloneDeep(query))
          // If not in group & module type is WHEEL or WORDCLOUD, use module.query as query
          if (!useGlobalFilters && graphSettings.type === 'wheel') {
            query = !isUndefined(graphSettings.query) ? graphSettings.query : query ? query : {}
          }
          if (query) {
            updateFiltersInQuery(query, graphReportingFilters, graphSettings)
            // If module type is TABULAR, use KPI selections from module.tabularSettings
            if (graphSettings.type === 'tabular') {
              attachKpiObjectToQuery(graphSettings.tabularSettings, query)
            }
            // If module type is RADAR, use KPI selections from module.query
            if (graphSettings.type === 'radar') {
              attachKpiObjectToQuery(graphSettings.query, query)
            }
            graphSettings.isGraph = true

            // Reset responsive sizes to fixed, if in 'report' mode (scrollPage)
            if (scrollPage) {
              if (graphSettings.type === 'picture') {
                graphSettings.fixedsize = {
                  height: true,
                }
              }
              if (graphSettings.type === 'numbertrend') {
                graphSettings.fixedsize = {
                  contentsize: true,
                  contentsubsize: true,
                }
              }
            }

            // Hide N, in 'report' mode in Single KPI module
            if (scrollPage) {
              if (graphSettings.type === 'numbertrend') {
                graphSettings.hide_n = true
              }
            }

            graphSettings.query = query
            restoreTabularSettings(graphSettings)
            console.log('SingleGraphCtrl: graphSettings after:')
            console.log(cloneDeep(graphSettings))
            return resolve(graphSettings)
          }
          return resolve(null)
        })
      }
    })
    return promise
  }

  function mergeObjectsToPromise(q1: any, q2: any) {
    return new Promise((resolve) => resolve({ ...q1, ...q2 }))
  }

  // Restore Tabular module settings from temp variable graph.tabularSettings to graph.settings
  function restoreTabularSettings(graph: any) {
    if (graph && graph.type === 'tabular' && graph.tabularSettings) {
      graph.settings = graph.tabularSettings
      delete graph.tabularSettings
    }
  }

  // If start/end date is defined in route parameters, use those
  function updateQueryDates(query: any, module: any) {
    if (!query) return
    if (!isNull(pastDays) && !isNaN(pastDays)) {
      query.start_date = todayPlusDays(-pastDays)
    }
    if (!isNull(futureDays) && !isNaN(futureDays)) {
      query.end_date = todayPlusDays(futureDays)
    }
    if (autoUpdateEnddate()) {
      const end = new Date(query.end_date)
      const today = new Date()
      if (end < today) {
        const diff = Math.floor((today.getTime() - end.getTime()) / (1000 * 3600 * 24))
        query.end_date = datePlusDays(new Date(query.end_date), diff)
      }
    }
    /**
     * Basically the idea is that this bit of code has the last word in saying
     * what the time frame for the report is
     * used for report resending
     */
    const endDateOverride = getOverrideEndDate()

    // if Enddate is not automaticly updated then the days are the same
    if (!autoUpdateEnddate()) {
      // do nothing
    }
    // if fixed time frame (e.g. last 30 days) or future time frame or both
    else if (
      endDateOverride &&
      (useWindowedTimeframe(module) || useWindowedTimeframeFuture(module))
    ) {
      const prevEndDate = new Date(query.end_date)
      const prevStartDate = new Date(query.start_date)
      const timeShift = differenceInDays(new Date(), endDateOverride)

      // if only future timeframe
      if (!useWindowedTimeframe(module) && useWindowedTimeframeFuture(module)) {
        const newEndDate = sub(prevEndDate, { days: timeShift })
        query.end_date = datePlusDays(newEndDate, 0)
      } else {
        const newStartDate = sub(prevStartDate, { days: timeShift })
        const newEndDate = sub(prevEndDate, { days: timeShift })
        query.start_date = datePlusDays(newStartDate, 0)
        query.end_date = datePlusDays(newEndDate, 0)
      }
    }
    // if quicktimeframebutton is on use, set according to the given end dates
    else if (endDateOverride && useQuickTimeframeButton(module)) {
      updateQueryToModuleTimeframeSelection(module, query, endDateOverride)
    }
    // if basic/fixed start_date, change only end_date
    else if (endDateOverride) {
      query.end_date = datePlusDays(endDateOverride, 0)
    }
  }

  function autoUpdateEnddate() {
    return autoUpdateFilters && autoTimeScale
  }

  // If all charts fit into one row, set heights to 100%
  function setGraphHeights(settings: any) {
    console.log('SingleGraphCtrl graph widths:')
    let total = 0
    for (let i = 0; i < settings.length; i++) {
      if (!settings[i]) {
        // cannot read at index -> do nothing
      } else {
        let size = getModuleWidth(settings[i])
        if (size) {
          size /= 12
          total += size
        }
      }
    }
    console.log('Total graph widths: ' + total)
    if (total <= 1) {
      for (let j = 0; j < settings.length; j++) {
        if (settings[j]) settings[j].high = true
      }
    }
  }

  function getGroupClasses() {
    return getScreenRootClasses(groupSettings, scrollPage)
  }

  function loopFetchDynamicFilters(module: Module): Promise<any> {
    setLoopFetchCount((prev) => {
      // Prevent infinite looping
      if (prev > 5) {
        alert('Infinite loop error while loading dynamic filters')
        Promise.reject('Infinite loop error while loading dynamic filters')
      }
      return prev + 1
    })

    const dynamicQuery = getUpdatedQueryFromSettings(module)
    const uniqueId = dynamicFiltersIdPrefix + getRandomInt(5)
    // const dynamicQuery = useDynamicFilters
    //   ? useDynamicTimeFilters
    //     ? getNewQuery(getQueryMeta(q), getQueryStartDate(q), getQueryEndDate(q))
    //     : getNewQuery(getQueryMeta(q))
    //   : q
    console.log('Fetch dynamic filters for KPIs, query', dashKpis, dynamicQuery)
    return fetchDynamicFilters(
      dashKpis,
      dynamicQuery,
      uniqueId,
      undefined,
      getModuleTimeFrame(module),
      useDynamicTimeFilters,
    ).then(
      function (res: any) {
        if (res) {
          checkConflictingFilters(dynamicQuery, res.filters)
          setReportingFilters(res.filters ? res.filters : isPlainObject(res) ? res : null)
          console.log('Updated dynamic filters:', reportingFilters)
        }
      },
      function (res) {
        console.log('failed: ' + res)
        console.log('query', dynamicQuery)
        alert('Failed to load dynamic filters. Reload to try again')
      },
    )
    // .then(function () {
    //   if (!useDynamicTimeFilters) {
    //     const queryAfter = getUpdatedQueryFromSettings(module)
    //     const newDynamicQuery = useDynamicFilters
    //       ? useDynamicTimeFilters
    //         ? getNewQuery(
    //             getQueryMeta(queryAfter),
    //             getQueryStartDate(queryAfter),
    //             getQueryEndDate(queryAfter),
    //           )
    //         : getNewQuery(getQueryMeta(queryAfter))
    //       : {}
    // Check if query has changed after fetching new dynamic filters
    // MITÄ VITTUA
    // if (!isEqual(dynamicQuery, newDynamicQuery)) {
    //   console.log(
    //     'Query changed after fetching dynamic filters! Refetch dynamic filters...',
    //     queryAfter,
    //   )
    //   return loopFetchDynamicFilters(module)
    // }
    // }
    // })
  }

  function fetchSubGroupDynamicFilters(module: any) {
    const promise = new Promise(function (resolve) {
      if (doUseAllReportingFilters(module)) {
        return resolve(undefined)
      } else if (useCustomFilters(module) || useMergeFilters(module)) {
        console.log('fetchSubGroupDynamicFilters for module', module)
        const query = updateModuleQuerySettings(module, getReportingFilters('all'))
        // Fetch KPI IDs
        let kpis: any = []
        try {
          if (module.modules) kpis = handleExtractingKpiIdsFromModules(module.modules, true)
        } catch (error) {
          alert('Failed to parse module KPIs for sub Group. Reload to try again')
        }
        if (!kpis || !kpis.length) {
          // Cancel dynamic filters fetch if KPI list is empty
          return resolve(kpis)
        } else {
          const id = getDynamicFilterId(module)
          const expandDynamicFilters = false
          fetchDynamicFilters(
            kpis,
            query,
            dynamicFiltersIdPrefix + id,
            expandDynamicFilters,
            getModuleTimeFrame(module),
            false,
          ).then(
            function (res: any) {
              if (res) {
                setReportingFilters(res.filters, id)
              }
              return resolve(id)
            },
            function (res) {
              console.log('failed: ' + res)
              alert('Failed to load dynamic filters for Group id ' + id + '. Reload to try again')
              return resolve(id)
            },
          )
        }
      } else {
        resolve(undefined)
      }
    })
    return promise
  }

  function updateTimeFrameDays(module: any) {
    // Remove timeframedays if autoUpdateFilters=false (use dates as is)
    if (module && autoUpdateFilters === false) {
      delete module.timeframedays
      delete module.timeframedays_future
      delete module.quicktimeframebutton
    }
  }

  // Ignore metas in query which are not returned in dynamic filters
  // Means it is a meta combination which yields no data (e.g. 'country': 'finland', 'city': 'stockholm')
  function checkConflictingFilters(query: any, filters: any) {
    console.log('checkConflictingFilters', query, filters)
    const newConflictingFilters = cloneDeep(conflictingFilters)
    if (query && !isEmpty(query) && filters) {
      const meta = getQueryMeta(query)
      if (meta) {
        for (const key in meta) {
          if (!filters[key]) newConflictingFilters[key] = true
        }
      }
    }
    setConflictingFilters(newConflictingFilters)
    console.log('conflictingFilters after check', newConflictingFilters)
  }

  function getReportingFilters(reportingKey?: any) {
    const k = reportingKey ? reportingKey : 'root'
    return reportingFilters[k]
  }

  function setReportingFilters(filters: any, key?: any) {
    const k = key ? key : 'root'
    const newFilters = cloneDeep(reportingFilters)
    newFilters[k] = filters
    setReportingFiltersState(newFilters)
  }

  function doUseAllReportingFilters(module: Module) {
    // If module contains ALERT module display all reporting filters
    const usedModuleTypes = [] as Module['type'][]
    if (module.type === ModuleType.GROUP) {
      usedModuleTypes.push(...getModuleTypesFromGroupModuleModules(module))
    } else {
      usedModuleTypes.push(module.type)
    }
    console.log('usedModuleTypes', usedModuleTypes)
    if (usedModuleTypes.includes(ModuleType.ALERT)) {
      return true
    }
  }

  function getModuleTypesFromGroupModuleModules(groupModule: GroupModule) {
    const moduleTypes = [] as Module['type'][]
    moduleTypes.push(groupModule.type)
    const modules = groupModule.modules
    if (!modules) return moduleTypes
    for (const module of modules) {
      if (module.type === ModuleType.GROUP) {
        moduleTypes.push(...getModuleTypesFromGroupModuleModules(module))
      } else {
        moduleTypes.push(module.type)
      }
    }
    return moduleTypes
  }

  function getDynamicFilterId(module: any) {
    console.log('getDynamicFilterId for module:', module)
    if (!module) return
    if (!module.dynamicFilterId) {
      const idx = reportingFiltersIdx
      module.dynamicFilterId = 'subGroup_' + idx
      setReportingFiltersIdx((prev) => prev + 1)
    }
    console.log('Return module.dynamicFilterId:', module.dynamicFilterId)
    return module.dynamicFilterId
  }

  if (!graphSettings) return <div>Loading graphSettings settings...</div>

  const readyModules = numberOfModulesDoneLoading()
  return (
    <div
      className={`screen-view modulegroup ${getGroupClasses()}`}
      style={{
        backgroundPosition: groupSettings?.options?.bgposition,
        backgroundSize: groupSettings?.options?.bgsize,
        backgroundColor: groupSettings?.options?.bgcolor,
        backgroundImage: `url(${groupSettings?.options?.imageurl})`,
      }}
    >
      <img
        src='https://s3-eu-west-1.amazonaws.com/wheelq/ui/textlogo.png'
        className={`screen-logo ${scrollPage ? 'fixed' : 'relative'}`}
        alt='WheelQ'
      />

      {!!error && (
        <h5
          className={`grey-text text-lighten-1 valign center-align ${
            scrollPage ? 'screen-fixed-padding' : 'fullsize screen-relative-padding'
          }`}
        >
          Error loading modules
        </h5>
      )}
      {!error && (
        <div className={scrollPage ? 'screen-fixed-padding' : 'fullsize screen-relative-padding'}>
          {!!scrollPage && !!displayedFilters && (
            <div className='single-graph-scroll-padding-left-right'>
              <div className='query-as-string flexible-left' data-testid='singleGraphFiltersBar'>
                {isArray(displayedFilters) &&
                  displayedFilters.map((filter, i, a) => (
                    <div
                      key={i}
                      className={`${i !== a.length - 1 ? 'item-right-padding' : ''} ${
                        i === 0 ? 'item-big-font' : ''
                      }`}
                    >
                      {filter}
                    </div>
                  ))}
              </div>
            </div>
          )}
          {!!graphSettings?.isGraph && (
            <div className={scrollPage ? 'single-graph-scroll' : 'single-graph-fullpage'}>
              <ScreenList
                settings={graphSettings}
                scrollPage={scrollPage}
                readyModules={readyModules || 0}
              />
            </div>
          )}
          {!!isGrouped && (
            <ScreenRepeater
              modulesettingslist={graphSettings}
              scrollPage={scrollPage}
              readyModules={readyModules || 0}
            />
          )}
        </div>
      )}
    </div>
  )
}

{
  /* <div class="screen-view modulegroup" ng-class="getGroupClasses()" ng-style="!nested ? {'background-position': groupSettings.options.bgposition, 'background-size': groupSettings.options.bgsize, 'background-color': groupSettings.options.bgcolor, 'background-image': 'url(' + groupSettings.options.imageurl + ')'} : {}">

<img src="https://s3-eu-west-1.amazonaws.com/wheelq/ui/textlogo.png" class="screen-logo" ng-class="scrollPage ? 'fixed' : 'relative'" alt="WheelQ">

<h5 ng-show="error" ng-class="scrollPage ? 'screen-fixed-padding' : 'fullsize screen-relative-padding'" class="grey-text text-lighten-1 valign center-align">
  Error loading modules
</h5>

<div ng-hide="error" ng-class="scrollPage ? 'screen-fixed-padding' : 'fullsize screen-relative-padding'">
  <!-- List filters if using 'reports' mode -->
  <div ng-if="scrollPage && filters" class="single-graph-scroll-padding-left-right">
    <div class="query-as-string flexible-left" data-testid="singleGraphFiltersBar">
      <div ng-repeat="filter in filters" ng-class="{'item-right-padding': !$last, 'item-big-font': $first}">{{filter}}</div>
    </div>
  </div>

  <div ng-if="graphSettings.isGraph" ng-class="scrollPage ? 'single-graph-scroll' : 'single-graph-fullpage'">
    <screenmodulelist settings="graphSettings" scroll-page="scrollPage" class="inheritsize"></screenmodulelist>
  </div>

  <screenmodulelistrepeater class="screen-module-list" ng-if="isGroup" modulesettingslist="graphSettings" scroll-page="scrollPage"></screenmodulelistrepeater>

</div>

</div> */
}

export default SingleGraphCntr
