import React from 'react'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { WheelModule, WheelModuleSettings } from '../wheelModuleTypes'
import { infoLimitMax, infoLimitMin } from '../WheelNotifications'

import input_css from './../../_shared/ModuleSettings/components/TextInput.module.scss'
import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

const SliceLimitInputs = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const wheelModule = module as WheelModule
  const options = (module?.options || {}) as WheelModuleSettings
  const currentLimits = (options.moduleWheelConfig?.limitValues || {}) as {
    min?: number
    mid?: number
    max?: number
  }
  const currentMin = currentLimits?.min
  const currentMax = currentLimits?.max

  const handleOnChangeLimit = (limit: 'min' | 'max', value: string) => {
    if (!wheelModule) return
    const newValue = value === '' ? undefined : !isNaN(+value) ? +value : undefined
    const newLimits = { ...currentLimits, [limit]: newValue }
    const newConfig = {
      ...(options.moduleWheelConfig || {}),
      limitValues: newLimits,
    } as WheelModuleSettings['moduleWheelConfig']
    saveModule({ ...wheelModule, options: { ...options, moduleWheelConfig: newConfig } })
  }

  return (
    <>
      <div className={`${modal_css.third}`}>
        <TextWithInfo info={infoLimitMin} text='Min limit' tooltipDirection='right' />
        <input
          data-testid='kpiMinLimit'
          id='kpiMinLimit'
          type='number'
          value={currentMin}
          onChange={(e) => handleOnChangeLimit('min', e.currentTarget.value)}
          className={`${input_css.input}`}
        />
      </div>

      <div className={`${modal_css.third}`}>
        <TextWithInfo info={infoLimitMax} text='Max limit' tooltipDirection='left' />
        <input
          data-testid='kpiMaxLimit'
          id='kpiMaxLimit'
          type='number'
          value={currentMax}
          onChange={(e) => handleOnChangeLimit('max', e.currentTarget.value)}
          className={`${input_css.input}`}
        />
      </div>
    </>
  )
}

export default SliceLimitInputs
