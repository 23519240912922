import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useDynamicFilters, { DynamicFiltersQuery } from '../../../../../../stores/useDynamicFilters'
import { cloneDeep, isEqual } from 'lodash'
import { useKeyPressEvent } from 'react-use'
import { SavingStatus } from '../../groupModuleTypes'
import ViewCurrentFilters from './ViewCurrentFilters'
import ViewSelectFilters from './ViewSelectFilters'
import ViewMetaKeysList, { FilterListType } from './ViewMetaKeysList'
import ConfirmationModal from '../../../../../common/ConfirmationModal/ConfirmationModal'
import Waiting from './components/Waiting'
import AutoSaveToggle from './components/AutoSaveToggle'
import Saving from './components/Saving'
import Finished from './components/Finished'
import {
  FilterArray,
  addMetaValuesToFilterArray,
  convertFilterArrayToFilterObject,
  removeMetaKeyFromFilterArray,
  resetMetaValuesInFilterArray,
} from '../../../../../../react-services/filterService'
import { SelectChangeEvent } from '@mui/material'
import { tCommon } from '../../../../../../../languages/i18n'

import css from './FilterModal.module.scss'

export const MODAL_ANIMATION_DURATION = 150
const EMPTY_STATE = <></>
const SAVE_TIMEOUT = 2000
const SAVE_REQ_WAIT = 800
const SAVE_FINISH_WAIT = 2000

type FilterModalProps = {
  kpis: number[] | undefined
  handleCloseFilters: () => void
  saveFilters: (filters: FilterArray) => void
  filters: FilterArray
  filtersSelectedAlready: FilterArray
}

const FilterModal = ({
  kpis,
  saveFilters,
  filters = [],
  filtersSelectedAlready = [],
  handleCloseFilters,
}: FilterModalProps) => {
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const saveStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [isAutoSaveDisabled, setIsAutoSaveDisabled] = useState(true)
  const [isConfirmSaveOpen, setIsConfirmSaveOpen] = useState(false)
  const [savingStatus, setSavingStatus] = useState<SavingStatus>(SavingStatus.NONE)
  const [savingElement, setSavingElement] = useState<JSX.Element>(EMPTY_STATE)
  const [filterListType, setFilterListType] = useState<FilterListType>(FilterListType.DYNAMIC)

  const [activeMeta, setActiveMeta] = useState<string | null>(null)
  const [nextActiveMeta, setNextActiveMeta] = useState<string | null>(null)

  const [selectedMetaValues, setSelectedMetaValues] = useState<string[] | null>(null)

  const { end_date, start_date, where_meta } = convertFilterArrayToFilterObject(filters)

  const currentSavedSelectedMetaValues = activeMeta ? where_meta[activeMeta] : null
  const dynamicFilterQuery: DynamicFiltersQuery | null = useMemo(() => {
    if (filterListType !== FilterListType.DYNAMIC) return null
    if (!start_date || !end_date || !kpis) return null
    const newQuery: DynamicFiltersQuery = {
      kpis,
      query: {
        start_date,
        end_date,
        where_meta,
      },
    }

    return newQuery
  }, [start_date, end_date, where_meta, kpis, filterListType])
  const { isRefetching: isLoadingDynamicFilters } = useDynamicFilters(dynamicFilterQuery)

  useEffect(() => {
    const allSelectedMetas = where_meta
    if (!activeMeta || !allSelectedMetas) return setSelectedMetaValues(null)
    return setSelectedMetaValues(allSelectedMetas[activeMeta] || null)
  }, [activeMeta, isLoadingDynamicFilters])

  useEffect(() => {
    if (
      (currentSavedSelectedMetaValues &&
        selectedMetaValues &&
        isEqual(currentSavedSelectedMetaValues, selectedMetaValues)) ||
      (!currentSavedSelectedMetaValues && (!selectedMetaValues || !selectedMetaValues.length))
    ) {
      if (savingStatus !== SavingStatus.INIT && savingStatus !== SavingStatus.WAITING) return
      clearSaveStatusTimeout()
      if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
      setSavingStatus(SavingStatus.NONE)
    }
  }, [currentSavedSelectedMetaValues, selectedMetaValues, savingStatus])

  useEffect(() => {
    clearSaveStatusTimeout()
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    if (isAutoSaveDisabled) {
      setSavingStatus(SavingStatus.MANUAL)
    } else {
      setSavingStatus(SavingStatus.NONE)
    }
  }, [isAutoSaveDisabled])

  useEffect(() => {
    if (savingStatus === SavingStatus.NONE || savingStatus === SavingStatus.MANUAL) {
      clearSaveStatusTimeout()
      if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
      setSavingElement(
        <AutoSaveToggle isDisabled={isAutoSaveDisabled} handleToggle={handleToggleAutoSave} />,
      )
    }

    if (savingStatus === SavingStatus.INIT) {
      clearSaveStatusTimeout()
      setSavingElement(<div></div>)
      setSavingStatus(SavingStatus.WAITING)
    }

    if (savingStatus === SavingStatus.WAITING) {
      setSavingElement(<Waiting />)
      clearSaveStatusTimeout()
      saveStatusTimeoutRef.current = setTimeout(
        () => setSavingStatus(SavingStatus.SAVING),
        SAVE_TIMEOUT,
      )
    }

    if (savingStatus === SavingStatus.SAVING) {
      setSavingElement(<Saving />)
      clearSaveStatusTimeout()
      if (!isLoadingDynamicFilters) {
        saveStatusTimeoutRef.current = setTimeout(() => {
          setSavingStatus(SavingStatus.FINISHED)
        }, SAVE_REQ_WAIT)
      }
    }

    if (savingStatus === SavingStatus.FINISHED) {
      setSavingElement(<Finished />)
      clearSaveStatusTimeout()
      saveStatusTimeoutRef.current = setTimeout(() => {
        if (isAutoSaveDisabled) setSavingStatus(SavingStatus.MANUAL)
        else setSavingStatus(SavingStatus.NONE)
      }, SAVE_FINISH_WAIT)
    }

    return () => clearSaveStatusTimeout()
  }, [savingStatus, isLoadingDynamicFilters])

  const handleSelectActiveMeta = useCallback(
    (key: string) => {
      const hasUnsavedChanges = !isEqual(
        selectedMetaValues || [],
        currentSavedSelectedMetaValues || [],
      )
      if (hasUnsavedChanges && activeMeta) {
        setNextActiveMeta(key)
        setIsConfirmSaveOpen(true)
      } else setActiveMeta(activeMeta === key ? null : key)
    },
    [currentSavedSelectedMetaValues, selectedMetaValues, activeMeta],
  )

  const handleToggleAutoSave = useCallback(
    () => setIsAutoSaveDisabled((prev) => !prev),
    [isAutoSaveDisabled],
  )

  const clearSaveStatusTimeout = () => {
    if (saveStatusTimeoutRef.current) clearTimeout(saveStatusTimeoutRef.current)
  }

  const handleSelectNewValues = (values: string[]) => {
    if (savingStatus === SavingStatus.SAVING) return
    if (!activeMeta) return
    if (!isAutoSaveDisabled) setSavingStatus(SavingStatus.INIT)
    const newUniqueMetaValues = Array.from(new Set([...(selectedMetaValues || []), ...values]))
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    setSelectedMetaValues(newUniqueMetaValues)
    if (savingStatus === SavingStatus.MANUAL) return
    saveTimeoutRef.current = setTimeout(
      () => handleSaveNewMetaValues(newUniqueMetaValues),
      SAVE_TIMEOUT,
    )
  }

  const handleSaveNewMetaValues = (values: string[], key?: string) => {
    setSavingStatus(SavingStatus.SAVING)
    if (!activeMeta && !key) return
    const metaKey = activeMeta || key || ''
    let newFilters = cloneDeep(filters)
    newFilters = removeMetaKeyFromFilterArray(newFilters, metaKey)
    if (values.length) newFilters = addMetaValuesToFilterArray(newFilters, metaKey, values)
    saveFilters(newFilters)
    setSelectedMetaValues(values)
  }

  const handleDeselectValues = (values: string[], key?: string) => {
    if (savingStatus === SavingStatus.SAVING) return
    const metaKey = activeMeta || key
    if (!metaKey) return
    if (!isAutoSaveDisabled) setSavingStatus(SavingStatus.INIT)
    const newMetaValues = [] as string[]
    if (selectedMetaValues) {
      newMetaValues.push(...(selectedMetaValues || []).filter((v) => !values.includes(v)))
    } else {
      const savedMetaValues = where_meta[metaKey]
      newMetaValues.push(...(savedMetaValues || []).filter((v) => !values.includes(v)))
    }
    if (newMetaValues.length) setSelectedMetaValues(newMetaValues)
    else setSelectedMetaValues(null)
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    if (isAutoSaveDisabled) return
    saveTimeoutRef.current = setTimeout(() => handleSaveNewMetaValues(newMetaValues), SAVE_TIMEOUT)
  }

  const handleResetFilters = () => {
    if (savingStatus === SavingStatus.SAVING) return
    let newFilters = cloneDeep(filters)
    newFilters = resetMetaValuesInFilterArray(newFilters)
    saveFilters(newFilters)
  }

  const handleCloseFilterSelect = useCallback(
    (e?: KeyboardEvent) => {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      const isSaving =
        savingStatus === SavingStatus.SAVING || savingStatus === SavingStatus.FINISHED
      const hasUnsavedChanges = !isEqual(
        selectedMetaValues || [],
        currentSavedSelectedMetaValues || [],
      )
      if (hasUnsavedChanges && !isSaving) setIsConfirmSaveOpen(true)
      else setActiveMeta(null)

      if (!isAutoSaveDisabled) setSavingStatus(SavingStatus.NONE)
      else setSavingStatus(SavingStatus.MANUAL)
    },
    [savingStatus, selectedMetaValues, currentSavedSelectedMetaValues],
  )

  useKeyPressEvent('Escape', activeMeta ? handleCloseFilterSelect : handleCloseFilters)

  const parentSelectedMetaValues = useMemo(() => {
    filtersSelectedAlready
    if (!activeMeta) return null
    const parentFilters = convertFilterArrayToFilterObject(filtersSelectedAlready)
    const parentSelectedMetaValues = parentFilters.where_meta[activeMeta] || []
    return parentSelectedMetaValues
  }, [filtersSelectedAlready, activeMeta])

  const handleCancelSaveConfirmation = () => {
    setIsConfirmSaveOpen(false)
    setSelectedMetaValues(null)
    setActiveMeta(null)
  }
  const handleConfirmSaveConfirmation = useCallback(() => {
    setIsConfirmSaveOpen(false)
    handleSaveNewMetaValues(selectedMetaValues || [])
    if (!nextActiveMeta) setActiveMeta(null)
    else setActiveMeta(activeMeta === nextActiveMeta ? null : nextActiveMeta)
  }, [activeMeta, selectedMetaValues, nextActiveMeta])

  const handleListTypeToggle = (e: SelectChangeEvent<FilterListType>) => {
    const newListType = e.target.value as FilterListType
    setFilterListType(newListType)
  }

  return (
    <>
      {isConfirmSaveOpen && (
        <ConfirmationModal
          enableEnterConfirmation
          title={tCommon('info.saveUnsaved')}
          confirm={tCommon('button.save')}
          cancel={tCommon('button.discard')}
          info={tCommon('info.filterModalSave')}
          handleCancel={handleCancelSaveConfirmation}
          handleConfirmation={handleConfirmSaveConfirmation}
        />
      )}
      <div className={css.modal}>
        <ViewMetaKeysList
          activeMeta={activeMeta}
          dynamicFilterQuery={dynamicFilterQuery}
          filterListType={filterListType}
          handleSelectActiveMeta={handleSelectActiveMeta}
          onClose={handleCloseFilters}
          handleListTypeToggle={handleListTypeToggle}
        />
      </div>
      <div className={css.modalValues}>
        {activeMeta ? (
          <ViewSelectFilters
            activeMeta={activeMeta}
            savingElement={savingElement}
            savingStatus={savingStatus}
            selectedMetaValues={selectedMetaValues}
            parentSelectedMetaValues={parentSelectedMetaValues}
            dynamicFilterQuery={dynamicFilterQuery}
            isAutoSaveDisabled={isAutoSaveDisabled}
            filterListType={filterListType}
            filters={filters}
            handleCloseFilterSelect={handleCloseFilterSelect}
            handleDeselectValues={handleDeselectValues}
            handleSelectNewValues={handleSelectNewValues}
            handleSaveNewMetaValues={handleSaveNewMetaValues}
            handleToggleAutoSave={handleToggleAutoSave}
          />
        ) : (
          <ViewCurrentFilters
            savingStatus={savingStatus}
            filters={filters}
            filtersSelectedAlready={filtersSelectedAlready}
            onClose={handleCloseFilters}
            handleResetFilters={handleResetFilters}
            handleSaveNewMetaValues={handleSaveNewMetaValues}
            handleSelectActiveMeta={handleSelectActiveMeta}
          />
        )}
      </div>
    </>
  )
}

export default FilterModal
