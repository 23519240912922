/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import css from './ConversationModal.module.scss'
import { toast } from 'react-toastify'
import { successMessageSent } from '../../FeedbackAlerts/FeedBackAlertNotifications'
import { useConversationFunctions, useConversationTools } from './ConversationModalProvider'
import { ConversationType, MessagePayload } from './conversationTypes'
import {
  createMessagePayLoad,
  postOneMessage,
} from '../../../../../react-services/conversationService'
import { useToastId } from '../../../../common/Notification/NotificationContext'
import { useEffectOnce } from 'react-use'
import { isEmail } from '../../../../../react-services/inputService'

const ConversationModalTextarea = () => {
  const [
    { isTextareaExpanded, messageInput, messageType, currentConversationId, emailForConversation },
    setConversationTools,
  ] = useConversationTools()
  const [{ getMessagesById }] = useConversationFunctions()

  const { toastifyId } = useToastId()
  const [isTextAreaDisabled, setIsTextAreaDisabled] = useState<boolean>(false)
  const [isTextButtonDisabled, setIsTextButtonDisabled] = useState<boolean>(false)
  const [saveMessageConfirmation, setSaveMessageConfirmation] = useState<boolean>(false)

  const [messageSendTo, setMessageSendTo] = useState<string>('')
  const [messageSendToIsValid, setMessageSendToIsValid] = useState<boolean>(false)
  const [showEmailValidate, setShowEmailValidate] = useState<boolean>(false)
  const [showEmailValidateError, setShowEmailValidateError] = useState<boolean>(false)
  const [textareaPlaceholder, setTextareaPlaceholder] = useState<string>()
  const [messageConfirmation, setMessageConfirmation] = useState<string>()

  useEffectOnce(() => {
    handleConversationType(messageType)
  })

  useEffect(() => {
    if (emailForConversation) setMessageSendTo(emailForConversation)
  }, [emailForConversation])

  useEffect(() => {
    if (!messageInput) setIsTextButtonDisabled(true)
    if (messageInput) setIsTextButtonDisabled(false)
  }, [messageInput])

  useEffect(() => {
    if (messageType === ConversationType.EMAIL) {
      if (isEmail(messageSendTo)) {
        setMessageSendToIsValid(true)
      } else {
        setMessageSendToIsValid(false)
        setSaveMessageConfirmation(false)
      }
    }
  }, [messageSendTo])

  useEffect(() => {
    if (
      messageSendToIsValid ||
      messageType === ConversationType.PHONE ||
      messageType === ConversationType.NOTE
    ) {
      setShowEmailValidateError(false)
    }
  }, [messageSendToIsValid, messageType])

  const handleNewMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMsg = event.currentTarget.value
    setConversationTools((prev) => ({ ...prev, messageInput: newMsg }))
  }

  const handleMessageSave = () => {
    setIsTextAreaDisabled(true)
    setIsTextButtonDisabled(true)

    let newMessagePayload = {} as MessagePayload
    if (currentConversationId) {
      try {
        newMessagePayload = createMessagePayLoad(
          messageInput,
          messageSendTo,
          currentConversationId,
          messageType || ConversationType.EMAIL,
        )
      } catch (e: any) {
        toast.error(e.message, { containerId: toastifyId })
        setIsTextAreaDisabled(false)
        setIsTextButtonDisabled(false)
      }

      postOneMessage(newMessagePayload)
        .then(() => {
          setConversationTools((prev) => ({ ...prev, messageInput: '' }))
          toast.success(successMessageSent, { containerId: toastifyId })
          getMessagesById(currentConversationId)
        })
        .catch((err: any) => {
          setIsTextButtonDisabled(false)
          toast.error(err, { containerId: toastifyId })
        })
        .finally(() => {
          setIsTextAreaDisabled(false)
          handleCloseTextarea()
        })
    }
  }

  const handleConversationType = (conversation_type: ConversationType) => {
    setSaveMessageConfirmation(false)
    setIsTextAreaDisabled(false)
    setShowEmailValidate(false)
    if (conversation_type === ConversationType.EMAIL) {
      setConversationTools((prev) => ({ ...prev, messageType: ConversationType.EMAIL }))
      setTextareaPlaceholder('Send an email...')
      setMessageConfirmation('Send this email?')
    }
    if (conversation_type === ConversationType.PHONE) {
      setConversationTools((prev) => ({ ...prev, messageType: ConversationType.PHONE }))
      setTextareaPlaceholder('Transcribe...')
      setMessageConfirmation('Transcribe this message?')
    }
    if (conversation_type === ConversationType.NOTE) {
      setConversationTools((prev) => ({ ...prev, messageType: ConversationType.NOTE }))
      setTextareaPlaceholder('Make a note...')
      setMessageConfirmation('Post this note?')
    }
  }

  const onFocusTextarea = () => {
    setConversationTools((prev) => ({ ...prev, isTextareaExpanded: true }))
  }

  const handleCloseTextarea = () => {
    setConversationTools((prev) => ({ ...prev, isTextareaExpanded: false }))
    setSaveMessageConfirmation(false)
    setIsTextAreaDisabled(false)
  }

  const handleSaveArea = (bool: boolean) => {
    if (messageType === ConversationType.EMAIL && !messageSendToIsValid) {
      setShowEmailValidate(true)
      setShowEmailValidateError(true)
      return
    }
    setSaveMessageConfirmation(bool)
    setIsTextAreaDisabled(bool)
  }

  const handleMessageSendTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageSendTo(event.currentTarget.value)
  }

  return (
    <div className={css.conversation_modal_textbox} data-testid='conversation-modal-textarea'>
      {isTextareaExpanded && (
        <>
          <div className={css.textarea_top_buttons}>
            <button
              data-testid='conversation-modal-email-button'
              className='btn light-blue waves-effect'
              onClick={() => handleConversationType(ConversationType.EMAIL)}
            >
              <i className='material-icons'>email</i>
            </button>
            <button
              data-testid='conversation-modal-phone-button'
              className='btn green waves-effect'
              onClick={() => handleConversationType(ConversationType.PHONE)}
            >
              <i className='material-icons'>phone_in_talk</i>
            </button>
            <button
              data-testid='conversation-modal-note-button'
              className='btn amber waves-effect'
              onClick={() => handleConversationType(ConversationType.NOTE)}
            >
              <i className='material-icons'>sticky_note_2</i>
            </button>
            {<span>Message type: {messageType && messageType.toLocaleLowerCase()}</span>}
            <button className={`btn-flat ${css.textarea_close}`} onClick={handleCloseTextarea}>
              Close
              <i className='material-icons'>close</i>
            </button>
          </div>
          {messageType === ConversationType.EMAIL && (
            <div className={css.textarea_send_inputs}>
              <span>Send to:</span>
              <input
                data-testid='textarea-send-to'
                type='text'
                value={messageSendTo}
                onChange={(event) => handleMessageSendTo(event)}
              />
              {showEmailValidate && (
                <span>
                  {messageSendToIsValid ? (
                    <i className={`material-icons ${css.valid_email}`}>done</i>
                  ) : (
                    <i className={`material-icons ${css.not_valid_email}`}>cancel</i>
                  )}
                </span>
              )}
              <br />
            </div>
          )}
        </>
      )}
      <textarea
        className={isTextareaExpanded ? css.textarea_expand : css.textarea_collapse}
        placeholder={textareaPlaceholder}
        onChange={(event) => handleNewMessage(event)}
        onFocus={onFocusTextarea}
        disabled={isTextAreaDisabled}
        value={messageInput}
      />
      {isTextareaExpanded && (
        <div className={css.conversation_modal_textbox_save}>
          {showEmailValidateError && (
            <>
              <span className={css.email_validator}>Give a valid email address.</span>
              <br />
            </>
          )}
          {!saveMessageConfirmation && (
            <button
              className='btn blue'
              onClick={() => handleSaveArea(true)}
              disabled={isTextButtonDisabled}
            >
              {messageType === ConversationType.EMAIL ? 'Send' : 'Post'}{' '}
              <i className='material-icons'>send</i>
            </button>
          )}
          {saveMessageConfirmation && (
            <>
              <p>{messageConfirmation}</p>
              <button className='btn grey' onClick={() => handleSaveArea(false)}>
                Cancel <i className='material-icons'>cancel</i>
              </button>
              <button className='btn green' onClick={() => handleMessageSave()}>
                Confirm <i className='material-icons'>check</i>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ConversationModalTextarea
