import React, { ChangeEvent, useState } from 'react'
import Rodal from 'rodal'
import MainButton from '../../_shared/Buttons/MainButton'
import NoData from '../../_shared/Infos/NoData'
import RemoveContact from './RemoveContact'
import { Row, Col } from 'react-materialize';

type Props = {
  recipients: string[]
  reportName?: string
  isEditMode: boolean
  hasMetakey: boolean
  handleSaveRecipients: (textField: string) => void
  handleRemove: (i: number) => void
  onClose: () => void
}

type User = {
  index: number
  email: string
}

function ReportRecipients({ recipients, reportName, isEditMode, hasMetakey, handleSaveRecipients, handleRemove, onClose }: Props) {

  const [userToRemove, setUserToRemove] = useState<User>()
  const [textFieldRecipients, setTextFieldRecipients] = useState<string>('')
  const [showRemoveUser, setShowRemoveUser] = useState<boolean>(false)


  const handleFieldChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextFieldRecipients(event.target.value)
  }

  const handleBinClick = (index: number, email: string) => {
    setUserToRemove({ index: index, email: email })
    setShowRemoveUser(true)
  }

  const closeRemoveUser = () => {
    setUserToRemove(undefined)
    setShowRemoveUser(false)
  }

  const thisHandleRemove = (id: number) => {
    handleRemove(id)
    setUserToRemove(undefined)
    setShowRemoveUser(false)
  }

  const handleSave = () => {
    handleSaveRecipients(textFieldRecipients)
  }

  return (
    <Rodal
      closeOnEsc
      animation="slideUp"
      visible
      onClose={onClose}
      className="sectioned-rodal"
    >
      <header>

        {isEditMode ?
          <h5 data-testid="recepients-heading">Edit Recipients
            {reportName && <span> for <i>{reportName}</i> </span>}
          </h5>
          :
          <h5 data-testid="recepients-heading">Recipients
            {reportName && <span> for <i>{reportName}</i> </span>}
          </h5>
        }

        <hr /><br />
      </header>

      <section>
        <table className='table striped z-depth-1'>
          <thead>
            <tr>
              <th>Recipient</th>
            </tr>
          </thead>
          <tbody>
            {recipients.map((r: string, i: number) =>
              <tr key={i}>

                {isEditMode ?
                  <td>
                    {r}
                    <span data-testid={`"bin-icon-${i}"`} className="float-right">
                      <i className="material-icons module-icon-color waves-effect" onClick={() => handleBinClick(i, r)}>delete</i>
                    </span>
                  </td>
                  :
                  <td>
                    {r}
                  </td>
                }

              </tr>)}
          </tbody>
        </table>

        {recipients.length === 0 &&
          <NoData />
        }
        <br />
        {isEditMode &&
        <Row>
          <h6>Add recipients</h6>
          <textarea data-cy="recipients" name="recipients" placeholder="Use (,) or (;) or (space) or (line break) to separate emails." onChange={handleFieldChange} value={textFieldRecipients}></textarea>
        </Row>
        }
      </section>
      {isEditMode ?
        <>
          <br /><br />
          <Row className="center">
            <Col s={6}>
              <MainButton isFlat text="Exit" onClick={onClose} />
            </Col>
            <Col s={6}>
              <MainButton text="Add" onClick={handleSave} />
            </Col>
          </Row>
        </>
        :
        <>
          {hasMetakey &&
            <p>This report uses contact meta table. Editing is not possible. Ask support for help if you need to change the recipients.</p>
          }
          <br />
          <footer>
            <MainButton isFlat text="Close" onClick={onClose} />
          </footer>
        </>
      }

      {showRemoveUser && typeof userToRemove !== 'undefined' &&
        <RemoveContact
          user={userToRemove}
          closeRemoveUser={closeRemoveUser}
          handleRemove={thisHandleRemove}
        />}

    </Rodal>
  )
}

export default ReportRecipients