import React, { useMemo } from 'react'
import { Dashboard } from '../../../../stores/useDbTemplateConfig'
import { Module, ModuleType, QueueStatus, QuickTimeframe } from '../Group/groupModuleTypes'
import { cloneDeep, isEqual, isArray } from 'lodash'
import { v4 as uuid } from 'uuid'

import GroupRootModule from '../Group/GroupRootModule'
import LineModuleContainer from '../Line/LineModuleContainer'
import { FeedbackAlertsTableConverter } from '../FeedbackAlerts/FeedbackAlertsTableCntr'
import FrequencyModuleConverter from '../Frequency/FrequencyModuleConverter'
import BubbleModuleContainer from '../Bubble/BubbleModuleContainer'
import TopBottomModuleContainer from '../TopBottom/TopBottomModuleContainer'
import NumberTrendModuleCntr from '../NumberTrend/NumberTrendModuleCntr'
import WrappedOpenModuleContainer from '../Open/OpenModuleContainer'
import PictureModuleCntr from '../Picture/PictureModuleCntr'
import PietabularModuleContainer from '../Pietabular/PietabularModuleContainer'
import RadarModuleContainer from '../Radar/RadarModuleContainer'
import ReportsOverview from '../ReportsOverview/ReportsOverview'
import SummaryModuleContainer from '../Summary/SummaryModuleContainer'
import TabularModuleConverter from '../Tabular/TabularModuleConverter'
import TextBoxPropsConverter from '../TextBox/TextBoxPropsConverter'
import WheelModuleCntr from '../Wheel/WheelModuleCntr'
import useDashboardSections from '../../../../stores/useDashboardSections'
import { useParams } from 'react-router-dom'
import SettingsWrapper from '../_shared/ModuleSettings/SettingsWrapper'
import { isTemplateUser } from '../../../../react-services/authService'
import {
  FilterArray,
  convertFilterArrayToFilterObject,
  getFilterArrayEndDate,
  getFilterArrayStartDate,
  setFilterArrayEndDate,
  setFilterArrayStartDate,
} from '../../../../react-services/filterService'
import { GenericQuery } from '../_shared/ModuleSettings/settingsTypes'
import {
  DEFAULT_DATE_FORMAT,
  getDatesQuickTimeframeButton,
} from '../../../../react-services/datesService'
import { format, subYears } from 'date-fns'

type DashboardModulesProps = {
  currentDashboard: Dashboard | null
}
const DashboardModules = ({ currentDashboard }: DashboardModulesProps) => {
  const { saveSection, sections } = useDashboardSections()

  const { sectionIdx, dashboardIdx } = useParams()
  const sectionNumber = isNaN(Number(sectionIdx)) ? 0 : Number(sectionIdx)
  const dashboardNumber = isNaN(Number(dashboardIdx)) ? 0 : Number(dashboardIdx)

  const modulesWithIds = useMemo(() => {
    const currentModules = currentDashboard?.modules || ([] as Module[])
    const newModules = cloneDeep(currentModules)
    const newModulesWithIds = newModules.map((m) => ({ ...m, id: m.id || uuid() }))
    return newModulesWithIds
  }, [currentDashboard])

  const getModule = (module: Module, index: number) => {
    const moduleId = module.id
    const getBaseProps = <T,>(module: T) => ({
      module,
      key: moduleId || index,
      id: moduleId || '',
      saveModule: handleSaveModule,
      isReportMode: false,
      isScreenMode: false,
      moduleStatus: QueueStatus.NOT_IMPLEMENTED,
    })

    switch (module.type) {
      case ModuleType.ALERT:
        return <FeedbackAlertsTableConverter {...getBaseProps(module)} />
      case ModuleType.BAR: // DEPRECATED
        return <FrequencyModuleConverter {...getBaseProps(module)} />
      case ModuleType.BUBBLE:
        return <BubbleModuleContainer {...getBaseProps(module)} />
      case ModuleType.FREQ:
        return <FrequencyModuleConverter {...getBaseProps(module)} isNested={true} />
      case ModuleType.LISTNUMERIC:
        return <TopBottomModuleContainer {...getBaseProps(module)} />
      case ModuleType.LINE:
        return <LineModuleContainer {...getBaseProps(module)} />
      case ModuleType.NUMBERTREND:
        return <NumberTrendModuleCntr {...getBaseProps(module)} />
      case ModuleType.OPEN:
        return <WrappedOpenModuleContainer {...getBaseProps(module)} />
      case ModuleType.PICTURE:
        return <PictureModuleCntr {...getBaseProps(module)} />
      case ModuleType.PIETABULAR:
        return <PietabularModuleContainer {...getBaseProps(module)} />
      case ModuleType.RADAR:
        return <RadarModuleContainer {...getBaseProps(module)} />
      case ModuleType.REPORTS:
        return <ReportsOverview {...getBaseProps(module)} />
      case ModuleType.SUMMARY:
        return <SummaryModuleContainer {...getBaseProps(module)} />
      case ModuleType.TABLE:
        return <TabularModuleConverter {...getBaseProps(module)} />
      case ModuleType.TABULAR:
        return <TabularModuleConverter {...getBaseProps(module)} />
      case ModuleType.TEXTBOX:
        return <TextBoxPropsConverter {...getBaseProps(module)} />
      case ModuleType.WHEEL:
        return <WheelModuleCntr {...getBaseProps(module)} />
      default:
        return isTemplateUser() ? <div>No module found with type: {module.type}</div> : <></>
    }
  }

  const convertDatesToCorrectFormat = (m: Module) => {
    const module = cloneDeep(m)
    if (module.type === ModuleType.TABULAR && module.settings && !isArray(module.settings)) {
      const oldSettings = typeof module.settings === 'object' ? module.settings : {}
      module.options = { ...oldSettings, ...(module.options || {}) }
      delete module.settings
    }
    const settingsStartDate = getFilterArrayStartDate(module.settings)
    const settingsEndDate = getFilterArrayEndDate(module.settings)
    if (!settingsStartDate && module.query?.start_date)
      module.settings = setFilterArrayStartDate(module.settings, module.query.start_date)
    if (!settingsEndDate && module.query?.end_date)
      module.settings = setFilterArrayEndDate(module.settings, module.query.end_date)

    const filterArray = ((module.settings as FilterArray) || []) as FilterArray
    const currentDate = new Date()
    if (!filterArray.length && !module.query?.start_date && !module.query?.end_date) {
      const timeframe = module.quicktimeframebutton as QuickTimeframe | undefined
      if (!timeframe) module.quicktimeframebutton = QuickTimeframe.Y
    }
    const tf = module.quicktimeframebutton
    if (tf && tf !== QuickTimeframe.NONE) {
      const dates = getDatesQuickTimeframeButton(tf, currentDate)
      const startDateString = format(dates.start, DEFAULT_DATE_FORMAT)
      const endDateString = format(dates.end, DEFAULT_DATE_FORMAT)
      let newFilterArray = setFilterArrayStartDate(filterArray, startDateString)
      newFilterArray = setFilterArrayEndDate(newFilterArray, endDateString)
      module.settings = newFilterArray
    }

    const startDate = getFilterArrayStartDate(module.settings || []) || module.query?.start_date

    if (!startDate) {
      const startDateString = format(subYears(currentDate, 1), DEFAULT_DATE_FORMAT)
      const newFilterArray = setFilterArrayStartDate(filterArray, startDateString)
      module.settings = newFilterArray
    }

    const endDate = getFilterArrayEndDate(module.settings || []) || module.query?.end_date

    if (!endDate) {
      const endDateString = format(currentDate, DEFAULT_DATE_FORMAT)
      const newFilterArray = setFilterArrayEndDate(filterArray, endDateString)
      module.settings = newFilterArray
    }

    // if (module.autoTimeScale !== false && !tf) {
    //   const endDateString = format(currentDate, DEFAULT_DATE_FORMAT)
    //   const newFilterArray = setFilterArrayEndDate(filterArray, endDateString)
    //   module.settings = newFilterArray
    // }

    const query = filterArray ? convertFilterArrayToFilterObject(module.settings) : {}
    if (!module.query) module.query = {} as GenericQuery
    module.query = { ...module.query, ...query }
    return module
  }

  const handleDeleteModule = (module: Module) => {
    if (!currentDashboard || !sections || !modulesWithIds) return
    const newDashBoard = cloneDeep(currentDashboard)
    newDashBoard.modules = modulesWithIds.filter((m) => m.id !== module.id)
    const currentSection = sections[sectionNumber]
    if (!currentSection) return
    const newSection = cloneDeep(currentSection)
    if (!newSection.dashboards) newSection.dashboards = []
    newSection.dashboards[dashboardNumber] = newDashBoard
    saveSection(newSection)
  }

  const handleMoveModuleDown = (module: Module) => {
    if (!currentDashboard || !sections || !modulesWithIds) return
    const newDashBoard = cloneDeep(currentDashboard)
    const moduleIndex = modulesWithIds.findIndex((m) => m.id === module.id)
    if (moduleIndex === -1) return
    const moduleWithId = modulesWithIds[moduleIndex]
    const newModules = cloneDeep(modulesWithIds)
    if (moduleIndex === modulesWithIds.length - 1) {
      newModules.pop()
      newModules.unshift(moduleWithId)
    } else {
      const nextModule = modulesWithIds[moduleIndex + 1]
      if (!nextModule) return
      newModules[moduleIndex] = nextModule
      newModules[moduleIndex + 1] = moduleWithId
    }
    newDashBoard.modules = newModules
    const currentSection = sections[sectionNumber]
    if (!currentSection) return
    const newSection = cloneDeep(currentSection)
    if (!newSection.dashboards) newSection.dashboards = []
    newSection.dashboards[dashboardNumber] = newDashBoard
    saveSection(newSection)
  }

  const handleMoveModuleUp = (module: Module) => {
    if (!currentDashboard || !sections || !modulesWithIds) return
    const newDashBoard = cloneDeep(currentDashboard)
    const moduleIndex = modulesWithIds.findIndex((m) => m.id === module.id)
    if (moduleIndex === -1) return
    const moduleWithId = modulesWithIds[moduleIndex]
    const newModules = cloneDeep(modulesWithIds)
    if (moduleIndex === 0) {
      newModules.shift()
      newModules.push(moduleWithId)
    } else {
      const prevModule = modulesWithIds[moduleIndex - 1]
      if (!prevModule) return
      newModules[moduleIndex] = prevModule
      newModules[moduleIndex - 1] = moduleWithId
    }
    newDashBoard.modules = newModules
    const currentSection = sections[sectionNumber]
    if (!currentSection) return
    const newSection = cloneDeep(currentSection)
    if (!newSection.dashboards) newSection.dashboards = []
    newSection.dashboards[dashboardNumber] = newDashBoard
    saveSection(newSection)
  }

  const handleSaveModule = (module: Module) => {
    if (!currentDashboard || !sections || !modulesWithIds) return
    const newDashBoard = cloneDeep(currentDashboard)
    newDashBoard.modules = modulesWithIds.map((m) => (m.id === module.id ? module : m))
    const currentSection = sections[sectionNumber]
    if (!currentSection) return
    const newSection = cloneDeep(currentSection)
    if (!newSection.dashboards) newSection.dashboards = []
    newSection.dashboards[dashboardNumber] = newDashBoard
    if (isEqual(newSection, currentSection)) return
    saveSection(newSection)
  }

  const getModuleHeight = (module: Module) => {
    if (module.type === ModuleType.GROUP) return 'fit-content'
    if (module.type === ModuleType.ALERT) return 'fit-content'
    if (module.type === ModuleType.SUMMARY) return 'fit-content'
    if (!module.options || !module.options.moduleheight) return '500px'
    return module.options.moduleheight
  }

  return (
    <>
      <div data-cy='dashboardContainer'>
        <div id='slickcarousel'>
          {!!modulesWithIds?.length && (
            <div
              data-cy='dashboardModules'
              className='z-depth-1 no-depth row-indent'
              id='dashboardModules'
            >
              {!!modulesWithIds &&
                modulesWithIds.map((m, i) => {
                  const module = convertDatesToCorrectFormat(m)
                  return (
                    <div
                      data-cy={`dashModule-${module.type}`}
                      key={module.id || i}
                      className='module dashboard-root-module'
                      style={{
                        height: getModuleHeight(module),
                        maxHeight: getModuleHeight(module),
                      }}
                    >
                      {module.type === 'group' ? (
                        <GroupRootModule
                          key={module.id || i}
                          module={module}
                          deleteModule={() => handleDeleteModule(module)}
                          id={module.id || i.toString()}
                          index={i}
                          moveModuleDown={() => handleMoveModuleDown(module)}
                          moveModuleUp={() => handleMoveModuleUp(module)}
                          saveModule={handleSaveModule}
                          isNested={false}
                        />
                      ) : (
                        <SettingsWrapper
                          module={module}
                          deleteModule={handleDeleteModule}
                          moveModuleDown={handleMoveModuleDown}
                          moveModuleUp={handleMoveModuleUp}
                          isReportMode={false}
                          isScreenMode={false}
                          inGroup={false}
                          saveModule={handleSaveModule}
                          moduleType={module.type as ModuleType}
                          index={i}
                        >
                          {getModule(module, i)}
                        </SettingsWrapper>
                      )}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DashboardModules
