import { OpenAnswersCountByMeta, RadarDataDisplay, RadarDataSentiment } from '../radarTypes'
import { sentimentDataCount, sentimentDataPercentage } from './DataChangeOptions'

export const handleNewChartForMeta = (
  radarDataSentiment: RadarDataSentiment,
  radarDataDisplay: RadarDataDisplay,
  isAlphabetical: boolean,
  countsByMeta: OpenAnswersCountByMeta[],
  radarMetaKeyValuesSelected: string[],
  selectedTopics: string[],
) => {
  if (!radarMetaKeyValuesSelected || !radarMetaKeyValuesSelected.length) return

  const radarCategories = isAlphabetical
    ? selectedTopics.sort().map((topic) => topic[0].toUpperCase() + topic.slice(1))
    : selectedTopics.map((topic) => topic[0].toUpperCase() + topic.slice(1))

  const newSettings: Highcharts.Options = {
    series: radarMetaKeyValuesSelected.map((metaValue) => ({
      type: 'area',
      name: metaValue,
      data: handleHighchartsSeriesData(
        radarDataSentiment,
        metaValue,
        radarDataDisplay,
        isAlphabetical,
        countsByMeta,
        selectedTopics,
      ),
      pointPlacement: 'on',
    })),
    xAxis: {
      categories: radarCategories,
      tickmarkPlacement: 'on',
    },
    yAxis:
      radarDataDisplay === RadarDataDisplay.COUNT
        ? sentimentDataCount.yAxis
        : sentimentDataPercentage.yAxis,
    pane: {
      size: '100%',
      innerSize: 30,
    },
    tooltip:
      radarDataDisplay === RadarDataDisplay.COUNT
        ? sentimentDataCount.tooltip
        : sentimentDataPercentage.tooltip,
    colors:
      radarDataSentiment === RadarDataSentiment.POSITIVE
        ? ['#10BA00', '#0A7A00', '#15FA00', '#053B00', '#13E000']
        : radarDataSentiment === RadarDataSentiment.NEGATIVE
        ? ['#BA1000', '#7A0A00', '#FA1500', '#3B0500', '#E01200']
        : ['#00A4BA', '#006C7A', '#00DDFA', '#00343B', '#00C6E0'],
  }

  return newSettings
}

const handleHighchartsSeriesData = (
  radarDataSentiment: RadarDataSentiment,
  metaValue: string,
  radarDataDisplay: RadarDataDisplay,
  isAlphabetical: boolean,
  countsByMeta: OpenAnswersCountByMeta[],
  selectedTopics: string[],
): number[] => {
  if (!radarDataSentiment) return []
  const numberData: number[] = []
  const topics = isAlphabetical ? selectedTopics.sort() : selectedTopics

  topics &&
    topics.forEach((topic) => {
      const matchTopic = countsByMeta.find((countMeta) => countMeta.topic === topic)

      if (!matchTopic) return

      const matchMetaValue = matchTopic.countByMetaValue.find(
        (meta) => meta.metaValue === metaValue,
      )

      if (!matchMetaValue) return

      if (radarDataDisplay === RadarDataDisplay.COUNT) {
        switch (radarDataSentiment) {
          case RadarDataSentiment.POSITIVE:
            numberData.push(matchMetaValue.positiveCount)
            break
          case RadarDataSentiment.NEGATIVE:
            numberData.push(matchMetaValue.negativeCount)
            break
          case RadarDataSentiment.NEUTRAL:
            numberData.push(matchMetaValue.neutralCount)
            break
          default:
            numberData.push(0)
            break
        }
      }

      if (radarDataDisplay === RadarDataDisplay.PERCENTAGE) {
        const allAnswersCount =
          matchMetaValue.positiveCount +
          matchMetaValue.neutralCount +
          matchMetaValue.negativeCount +
          matchMetaValue.uncategorizedCount
        let percentValue = 0

        switch (radarDataSentiment) {
          case RadarDataSentiment.POSITIVE:
            percentValue = (100 * matchMetaValue.positiveCount) / allAnswersCount
            numberData.push(isNaN(percentValue) ? 0 : Math.trunc(percentValue))
            break
          case RadarDataSentiment.NEGATIVE:
            percentValue = (100 * matchMetaValue.negativeCount) / allAnswersCount
            numberData.push(isNaN(percentValue) ? 0 : Math.trunc(percentValue))
            break
          case RadarDataSentiment.NEUTRAL:
            percentValue = (100 * matchMetaValue.neutralCount) / allAnswersCount
            numberData.push(isNaN(percentValue) ? 0 : Math.trunc(percentValue))
            break
          default:
            numberData.push(0)
            break
        }
      }
    })

  return numberData
}
