import React from 'react'
import useNumericKpis from './scripts/stores/useNumericKpis'
import useOpenKpis from './scripts/stores/useOpenKpis'
import useReportingFilters from './scripts/stores/useReportingFilters'
import useCommonDbSettingsConfig from './scripts/stores/useCommonDbSettingsConfig'
import useDbTemplateConfig from './scripts/stores/useDbTemplateConfig'

const AppStores = () => {
  useNumericKpis()
  useOpenKpis()
  useReportingFilters()
  useCommonDbSettingsConfig()
  useDbTemplateConfig()
  return <></>
}

export default AppStores
