/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Icon } from 'react-materialize'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import { UserManagementTool } from '../../../../../types'
import { isTemplateUser } from '../../../../react-services/authService'

import css from './UserList.module.scss'

export const nonEditableUsers = ['admin', 'templateuser', 'screen', 'api', 'report', 'category']
type Props = {
  users: UserManagementTool.User[]
  showEditUser: (user: UserManagementTool.User) => void
  showDeleteUser: (user: UserManagementTool.User) => void
  editorWasToggled: boolean
  deletorWasToggled: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default class UserList extends React.Component<Props, {}> {
  shouldComponentUpdate(nextProps: Props) {
    // Re-render due to editor toggling will mess up the sorting of table; so don't rerender then
    if (this.props.editorWasToggled !== nextProps.editorWasToggled) {
      return false
    } else if (this.props.deletorWasToggled !== nextProps.deletorWasToggled) {
      return false
    }
    return true
  }

  render() {
    const { users, showEditUser, showDeleteUser } = this.props
    const groups = (user: UserManagementTool.User): JSX.Element[] => {
      const array = []
      if (user.usergroups) {
        for (const g of user.usergroups) {
          g && array.push(<div key={g.id + ' ' + g.name}>{g.name}</div>)
        }
      }
      return array
    }

    const composeTooltip = (
      array: { [name: string]: string[] },
      isAccessRights: boolean,
    ): JSX.Element[] | undefined => {
      const result = []
      for (const key in array) {
        if (!array[key]) return

        const values =
          array[key].length > 0 ? (
            array[key].join(', ')
          ) : isAccessRights ? (
            <i className='white-text'>every value restricted</i>
          ) : (
            <i className='white-text'>not set</i>
          )
        result.push(
          <div key={key}>
            <b>{key}</b>:&nbsp;{values}
          </div>,
        )
      }
      return result
    }

    const generateRows = (): JSX.Element[] => {
      return users.map((user) => {
        const userGroups = groups(user)
        const isUserUnEditable = nonEditableUsers.includes(
          (user.username || '').toLocaleLowerCase(),
        )
        const isRemoveAllowed = isTemplateUser() || !isUserUnEditable
        return (
          <tr data-cy={`userId-${user.id}`} key={user.id}>
            <td colSpan={2}>{user.username}</td>
            <td colSpan={2}>
              {userGroups.length > 0 ? (
                <>
                  <span>{userGroups[0]}</span>
                  {userGroups.length > 1 && userGroups.slice(1, userGroups.length)}
                </>
              ) : (
                <span>&nbsp;</span>
              )}
            </td>
            <td colSpan={2}>
              {user.filters && Object.keys(user.filters).length !== 0 && (
                <span className='tool' data-cy='limitedText'>
                  limited
                  <div className='tip'>{composeTooltip(user.filters, true)}</div>
                </span>
              )}
            </td>
            <td colSpan={2}>
              {user.defaultfilters && Object.keys(user.defaultfilters).length > 0 && (
                <span className='tool'>
                  {Object.keys(user.defaultfilters).length > 1
                    ? `${Object.keys(user.defaultfilters)[0]}, ...`
                    : `${Object.keys(user.defaultfilters)[0]}`}
                  <div className='tip'>{composeTooltip(user.defaultfilters, false)}</div>
                </span>
              )}
            </td>
            <td className='center'>
              <span data-testid='showEditUserModal' onClick={() => showEditUser(user)}>
                <Icon className='material-icons module-icon-color waves-effect'>edit</Icon>
              </span>
              <span
                data-testid='showDeleteUserModal'
                onClick={() => isRemoveAllowed && showDeleteUser(user)}
              >
                <i
                  className={`material-icons module-icon-color waves-effect ${
                    !isRemoveAllowed ? css.deleteDisabled : ''
                  }`}
                >
                  delete
                </i>
              </span>
            </td>
          </tr>
        )
      })
    }

    return (
      <>
        <GenericTable
          headers={
            <tr>
              <th colSpan={2} data-cy='userListHeaderUsername'>
                Username
              </th>
              <th colSpan={2}>Group</th>
              <th colSpan={2}>Access rights</th>
              <th colSpan={2} data-cy='userListHeaderDfs'>
                Default filters
              </th>
              <th onClick={() => {}}>Actions</th>
            </tr>
          }
          rows={generateRows()}
          useFixedLayout
          tableClasses={['user-management-grouped-list-table']}
        />
      </>
    )
  }
}
