import React from 'react'
import LoadingIndicator from '../../../../../_shared/Infos/LoadingIndicator'
import TextWithInfo from './TextWithInfo'
import CheckBoxItem from './CheckBoxItem'
import { useSettingsTools } from '../SettingsContext'
import { isStringArray } from '../../../../../../utilities'
import RequiredStar from './RequiredStar'
import useReportingFilters from '../../../../../../stores/useReportingFilters'

import modal_css from './../SettingsModal.module.scss'
import css from './CheckBoxSelectionList.module.scss'

type MetaKeysSelectionListProps = {
  isRootProperty?: boolean
  propertyName: string
  isRequired?: boolean
  text: string
  info: string
}
const MetaKeysSelectionList = ({
  isRootProperty,
  propertyName,
  isRequired,
  text,
  info,
}: MetaKeysSelectionListProps) => {
  const [{ module, saveModule }] = useSettingsTools()
  let selectedMetaKeys = [] as string[]
  if (module?.options && module.options[propertyName as keyof unknown] && !isRootProperty) {
    selectedMetaKeys = module.options[propertyName as keyof unknown]
  }

  if (module && module[propertyName as keyof unknown] && isRootProperty) {
    selectedMetaKeys = module[propertyName as keyof unknown]
  }

  const { metas, isLoading: loading, error } = useReportingFilters()
  const allMetaKeys = Object.keys(metas || {})
  const hasError = !!error

  const hasSelectedMetakeys =
    selectedMetaKeys && isStringArray(selectedMetaKeys) && selectedMetaKeys.length

  const handleClick = (metaKey: string) => {
    if (!module) return
    let newMetaKeys = [] as string[]
    if (selectedMetaKeys.includes(metaKey)) {
      newMetaKeys = selectedMetaKeys.filter((meta) => meta !== metaKey)
    }
    if (!selectedMetaKeys.includes(metaKey)) {
      newMetaKeys = selectedMetaKeys.concat(metaKey)
    }
    if (!isRootProperty) {
      const newModule = { ...module, options: { ...module.options, [propertyName]: newMetaKeys } }
      saveModule(newModule)
    }
    if (isRootProperty) {
      saveModule({ ...module, [propertyName]: newMetaKeys })
    }
  }

  return (
    <div className={modal_css.max}>
      <TextWithInfo text={text} tooltipDirection='top' info={info} />
      {!!isRequired && <RequiredStar />}
      {loading && <LoadingIndicator />}
      {hasError && <h5>{error}</h5>}
      {!loading && !hasError && (
        <div
          className={`${css.list} ${!hasSelectedMetakeys && isRequired ? modal_css.required : ''}`}
        >
          {allMetaKeys &&
            Array.isArray(allMetaKeys) &&
            allMetaKeys.map((metaKey) => (
              <CheckBoxItem
                key={metaKey}
                text={metaKey}
                data_testid={'metakey-checkbox-item'}
                id={metaKey}
                settingsName=''
                classNames={css.item}
                onClickLabel={handleClick}
                checked={selectedMetaKeys.includes(metaKey)}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default MetaKeysSelectionList
