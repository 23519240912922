import { MODULECONSTANTS } from '../react-constants/modules'
import { enableChartThemes } from '../react-services/configService'
import { isPdfReportMode } from '../utilities'
import { cloneDeep } from 'lodash'
import chartOptionsService from './chartOptionsService'

function chartThemeService() {
  const chartOptions = chartOptionsService()
  var axisTitle_fontSize = '1rem'
  var legendTitle_fontSize = '1rem'
  var legendItem_fontSize = '1rem'
  var legendItem_fontWeight = 300
  var axisLabel_fontSize = '13px'
  var dataLabels_fontSize = '14px'
  var tooltip_fontSize = '1rem'
  var exportFontSize = '22px'

  // Chart themes
  // Selections in this list will show up in module settings popup
  var configs = function () {
    if (enableChartThemes()) {
      return {
        default: {
          name: 'Default',
          config: {},
        },
        bs_basic: bsBasicConf(),
        bs_scenario: bsScenarioConf(),
        bs_brand_strength: bsBrandStrengthConf(),
        bs_area_spline: bsAreaSplineConf(),
      }
    } else {
      return {}
    }
  }

  var defaultconf = function () {
    return {
      colors: MODULECONSTANTS.SERIES_COLORS,
      chart: {
        backgroundColor: MODULECONSTANTS.BG_COLOR,
        plotBorderColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
      },
      title: {
        text: '',
        style: {
          color: 'fontColor',
        },
      },
      subtitle: {
        style: {
          color: 'fontColor',
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        gridLineColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        lineColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        lineWidth: 1,
        minorGridLineColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        tickColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        title: {
          style: {
            color: 'fontColor',
            fontSize: axisTitle_fontSize,
          },
        },
        labels: {
          style: {
            color: 'fontColor',
            fontSize: axisLabel_fontSize,
          },
        },
      },
      yAxis: {
        allowDecimals: false,
        gridLineColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        lineColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        minorGridLineColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        tickColor: MODULECONSTANTS.PLOT_BORDER_COLOR,
        title: {
          style: {
            color: 'fontColor',
            fontSize: axisTitle_fontSize,
          },
        },
        labels: {
          style: {
            color: 'fontColor',
            fontSize: axisLabel_fontSize,
          },
        },
      },
      labels: {
        style: {
          fontSize: axisLabel_fontSize,
        },
      },
      legend: {
        title: {
          style: {
            fontSize: legendTitle_fontSize,
          },
        },
        itemStyle: {
          color: 'fontColor',
          fontSize: legendItem_fontSize,
          fontWeight: legendItem_fontWeight,
        },
      },
      plotOptions: {
        series: {
          // undefined is the default value in Highcharts
          animation: isPdfReportMode() ? false : undefined,
          dataLabels: {
            style: {
              fontSize: dataLabels_fontSize,
            },
          },
        },
        pie: {
          innerSize: '30%',
          showInLegend: true,
          dataLabels: {
            enabled: false,
            distance: -50,
            formatter: chartOptions.freqDataLabelFormatter,
          },
        },
        line: {
          connectNulls: true,
          marker: {
            enabled: true,
            radius: 1,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      tooltip: {
        style: {
          fontSize: tooltip_fontSize,
        },
      },
      exporting: {
        enabled: true,
        scale: 1,
        sourceWidth: 1200,
        sourceHeight: 800,
        buttons: {
          contextButton: {
            theme: {
              zIndex: 99999999,
            },
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadSVG',
              'downloadCSV',
              'downloadXLS',
            ],
          },
        },
        csv: {
          itemDelimiter: ';',
        },
        chartOptions: {
          chart: {
            backgroundColor: '#ffffff',
            style: {
              fontSize: exportFontSize,
            },
          },
          title: null,
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: exportFontSize,
                },
              },
            },
            pie: {
              innerSize: '30%',
              showInLegend: true,
              dataLabels: {
                enabled: false,
                distance: -50,
              },
            },
          },
          legend: {
            itemStyle: {
              fontSize: exportFontSize,
            },
          },
          yAxis: {
            labels: {
              style: {
                fontSize: exportFontSize,
              },
            },
          },
          xAxis: {
            labels: {
              style: {
                fontSize: exportFontSize,
              },
            },
          },
        },
      },
    }
  }

  var getDefaultTheme = function () {
    var tmpconfig = defaultconf()
    var config = cloneDeep(tmpconfig)
    return config
  }

  var getTheme = function (key) {
    var config = {}
    var tmpconfig = configs()[key]
    if (tmpconfig && tmpconfig.config) config = cloneDeep(tmpconfig.config)
    return config
  }

  var listThemes = function () {
    return configs()
  }

  // BS BASIC LINE
  var bsBasicConf = function () {
    return {
      name: 'BS Basic line',
      config: {
        colors: ['#FF0085', '#C0FF7F', '#00ADF3'],
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'dot',
        },
        xAxis: {
          lineWidth: 0,
        },
      },
    }
  }

  // BS SCENARIO LINE
  var bsScenarioConf = function () {
    return {
      name: 'BS Scenario line',
      config: {
        colors: ['#eee'],
        yAxis: {
          lineWidth: 1.5,
          lineColor: 'rgba(10,10,10,0.35)',
          gridLineColor: 'rgba(20,20,20,0.35)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'dot',
        },
        xAxis: {
          lineWidth: 1.5,
          lineColor: 'rgba(10,10,10,0.35)',
        },
        legend: {
          enabled: false,
        },
        // Non-highchart custom attributes
        trendcolors: ['#B0FF60', '#00FFFF', '#FF00FE'],
        customclass: 'bs_scenario',
      },
    }
  }

  // BS BRAND STRENGTH LINE
  var bsBrandStrengthConf = function () {
    return {
      name: 'BS Brand Strength line',
      config: {
        colors: [
          '#00FFE0',
          '#C0FF7F',
          '#FF0086',
          '#00ADF3',
          '#BCBEC0',
          '#FF8F00',
          '#7E54FF',
          '#B07B4A',
          '#E100FF',
        ],
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'dot',
        },
        xAxis: {
          lineWidth: 0,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              y: 30,
              formatter: function () {
                var format = this.point.format
                var value = this.point.value ? this.point.value : this.y
                if (format === 'n' || format === 'sum') {
                  return value.toFixed(0)
                } else {
                  return value
                }
              },
            },
            marker: {
              enabled: true,
              // Empty base64 symbol (space)
              symbol: 'url(data:image/png;base64,IA==)',
            },
          },
        },
        // Non-highchart custom attributes
        trafficlight_markers: true,
      },
    }
  }

  // BS AREA SPLINE
  var bsAreaSplineConf = function () {
    return {
      name: 'BS Area spline',
      config: {
        chart: {
          type: 'area',
        },
        colors: ['#FF0585', '#FF4400', '#FFAF22'],
        yAxis: {
          gridLineColor: 'rgba(160,160,160,0.48)',
          gridLineWidth: 1.5,
          gridLineDashStyle: 'dot',
        },
        xAxis: {
          lineWidth: 0,
        },
        plotOptions: {
          series: {
            connectNulls: true,
            stacking: 'normal',
            marker: {
              radius: 1,
            },
          },
        },
        // Non-highchart custom attributes
        fade_future_series_color: true,
        remove_overlapping_trend_data: true,
      },
    }
  }

  return {
    getDefaultTheme: getDefaultTheme,
    getTheme: getTheme,
    listThemes: listThemes,
  }
}

export default chartThemeService
