import React, { useMemo } from 'react'
import BrandLogo from './BrandLogo'
import { getTenant, getUsername } from '../../../../react-services/authService'
import useDashboardSections from '../../../../stores/useDashboardSections'
import { Link, useParams } from 'react-router-dom'
import { isNull } from 'lodash'
import LanguageSelect from '../../../../../languages/LanguageSelect'
import { useTranslation } from 'react-i18next'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import useTranslationsConfig, { Translations } from '../../../../stores/useTranslationsConfig'
import { Language } from '../../../../../languages/languages'
import { Tooltip } from '@mui/material'
import { tCommon } from '../../../../../languages/i18n'

const NavigationBar = () => {
  const { sections } = useDashboardSections()
  const { sectionIdx, dashboardIdx } = useParams()
  const { t } = useTranslation()
  const { config: cdbs } = useCommonDbSettingsConfig()
  const { config: translationsConfig } = useTranslationsConfig()
  const sectionNumber = isNaN(Number(sectionIdx)) ? null : Number(sectionIdx)
  const dashboardNumber = isNaN(Number(dashboardIdx)) ? null : Number(dashboardIdx)

  const tenantLanguages = useMemo(() => {
    if (!translationsConfig) return []
    const newTenantLanguages = [] as Language[]
    for (const lang in translationsConfig) {
      newTenantLanguages.push(lang as keyof Translations)
    }
    return newTenantLanguages
  }, [translationsConfig])

  const tooltipText = (
    <>
      {tCommon('label.tenant')}:{getTenant()}
      <br />
      {tCommon('label.username')}:{getUsername()}
    </>
  )
  return (
    <div className='navbar-fixed'>
      <nav role='navigation'>
        <div className='container'>
          <div className='nav-wrapper'>
            <BrandLogo />
            <div className='nav-topmenu hide-on-med-and-down'>
              <ul className='hide-on-med-and-down'>
                {!!sections &&
                  sections.map((section, i) => (
                    <li key={'template' + i} className={sectionNumber === i ? 'active' : ''}>
                      <Link to={`/section/${i}/dashboard/0`} className={`sectionIndex${i}`}>
                        {section.name
                          ? t(section.name.toLocaleLowerCase(), { ns: 'templates' })
                          : ''}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className='hide-on-med-and-down navbar-icons'>
              <ul className='flexible-center right'>
                <li>
                  <Link id='adminToolsTab' to='/admin/dashboard'>
                    {t('common.button.tools')}
                  </Link>
                </li>
                <li>
                  <Link id='logoutButton' to='/logout'>
                    {t('common.button.logout')}
                  </Link>
                </li>
                {cdbs?.is_multi_language_enabled && (
                  <li>
                    <div style={{ lineHeight: 'normal', margin: 'auto 0' }}>
                      <LanguageSelect
                        isFlagsOnly={true}
                        hasDarkBackground={true}
                        tenantLanguages={tenantLanguages}
                      />
                    </div>
                  </li>
                )}
                <li>
                  <Tooltip title={tooltipText}>
                    <img
                      data-cy='profileIcon'
                      src='https://s3-eu-west-1.amazonaws.com/wheelq/ui/icon-profile.png'
                      className='tooltipped'
                      data-position='left'
                      data-delay='50'
                    />
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='hide-on-med-and-down' id='navbarSubmenu'>
          <div className='container'>
            <div className='nav-submenu'>
              <ul data-testid='sectionDashboardList'>
                {!isNull(sectionNumber) &&
                  !!sections?.[sectionNumber]?.dashboards &&
                  sections?.[sectionNumber]?.dashboards?.map((dashboard, i) => (
                    <li key={'dashboard' + i} className={dashboardNumber === i ? 'active' : ''}>
                      <Link
                        to={`/section/${sectionIdx}/dashboard/${i}`}
                        className={`dashboardIndex${i}`}
                      >
                        {dashboard.name
                          ? t(dashboard.name.toLocaleLowerCase(), { ns: 'templates' })
                          : ''}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavigationBar
