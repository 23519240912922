import React, { ErrorInfo } from 'react'
import { toast } from 'react-toastify'

type ErrorBoundaryProps = {
  fallback: React.ReactNode
  children: React.ReactNode
  containerId?: number | string
  message?: string
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  // param error: Error if needed
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log('catched error')

    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App

    if (this.props.containerId) {
      try {
        console.log('toasting')
        toast.error(this.props.message)
      } catch (e) {
        // not handled
      }
    }
    console.log(error)
    console.log(info.componentStack)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary
