import React, { Component } from 'react'
import LiveDataIndicator from '../LiveDataIndicator'
import ModuleSettingsButton from './ModuleSettingsButton'
import ModuleDeleteButton from './ModuleDeleteButton'
import ModuleFilters from './ModuleFilters'
import { PropTypes } from 'prop-types'

export default class ModuleFilterBar extends Component {
  static propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    selections: PropTypes.object,
    hasRestrictedFilters: PropTypes.bool,
    doHideIcons: PropTypes.bool,
    customDatePickerOptions: PropTypes.object,
    areDatesChangedAfterClosingDatePicker: PropTypes.bool,
    areChangesImmediate: PropTypes.bool,
    canChangeFilters: PropTypes.bool,

    onChangeTitle: PropTypes.func,
    onDelete: PropTypes.func,
    onChangeDate: PropTypes.func,
    onChangeMetadata: PropTypes.func,
    onToggleExpansion: PropTypes.func,
    onClearMetadataFilter: PropTypes.func,
  }

  static defaultProps = {
    hasRestrictedFilters: false,
    doHideIcons: false,
    areDatesChangedAfterClosingDatePicker: false,
    areChangesImmediate: true,
    canChangeFilters: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      showLiveData: false,
    }
  }

  render() {
    const { startDate, endDate, selections } = this.props

    return (
      <div className='row module-bar'>
        {/* Live data icon */}
        {this.state.showLiveData && <LiveDataIndicator />}

        {/* Icons */}
        {!this.props.doHideIcons && (
          <div
            data-testid='moduleContainerSettingsIcons'
            className='col module-bar-fixed-height valign-wrapper right'
          >
            <ModuleSettingsButton icon='settings' onChangeTitle={this.props.onChangeTitle} />
            <ModuleDeleteButton onDelete={this.props.onDelete} />
          </div>
        )}

        {/* Filters */}
        <ModuleFilters
          startDate={startDate}
          endDate={endDate}
          selections={selections}
          hasRestrictedFilters={this.props.hasRestrictedFilters}
          customDatePickerOptions={this.props.customDatePickerOptions}
          areDatesChangedAfterClosingDatePicker={this.props.areDatesChangedAfterClosingDatePicker}
          areChangesImmediate={this.props.areChangesImmediate}
          canChangeFilters={this.props.canChangeFilters}
          onChangeDate={this.props.onChangeDate}
          onChangeMetadata={this.props.onChangeMetadata}
          onToggleExpansion={this.props.onToggleExpansion}
          onClearMetadataFilter={this.props.onClearMetadataFilter}
          onApplyFilters={this.props.onApplyFilters}
        />
      </div>
    )
  }
}
