import React, { useMemo } from 'react'
import DoubleModalSide from '../../../../../_shared/Modals/DoubleModalSide'

import modal_css from './FilterModal.module.scss'
import {
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
} from '@mui/material'
import { allChartColors, primaryModalColor } from '../../../../../../../styles/variableExport'
import { SavingStatus } from '../../groupModuleTypes'
import useDynamicFilters, { DynamicFiltersQuery } from '../../../../../../stores/useDynamicFilters'
import { infoFilterSelections } from '../../GroupNotifications'
import { pSBC } from '../../../../../../react-services/colorService'
import Icon, { IconName } from '../../../../../../utilities/Icon'
import { isEqual } from 'lodash'
import { useKeyPressEvent } from 'react-use'
import { MODAL_ANIMATION_DURATION } from './FilterModal'
import {
  FilterArray,
  getFilterArrayWhereMeta,
} from '../../../../../../react-services/filterService'
import { FilterListType } from './ViewMetaKeysList'
import useReportingFilters from '../../../../../../stores/useReportingFilters'
import useMetaData from '../../../../../../stores/useMetaData'
import { tCommon } from '../../../../../../../languages/i18n'

type ViewSelectFiltersProps = {
  filters: FilterArray
  activeMeta: string | null
  savingElement: JSX.Element
  savingStatus: SavingStatus
  selectedMetaValues: string[] | null
  parentSelectedMetaValues: string[] | null
  dynamicFilterQuery: DynamicFiltersQuery | null
  isAutoSaveDisabled: boolean
  filterListType: FilterListType
  handleCloseFilterSelect: () => void
  handleDeselectValues: (values: string[]) => void
  handleSelectNewValues: (values: string[]) => void
  handleSaveNewMetaValues: (values: string[]) => void
  handleToggleAutoSave: () => void
}
const ViewSelectFilters = ({
  filters,
  activeMeta,
  savingElement,
  savingStatus,
  selectedMetaValues,
  parentSelectedMetaValues,
  dynamicFilterQuery,
  isAutoSaveDisabled,
  filterListType,
  handleCloseFilterSelect,
  handleDeselectValues,
  handleSelectNewValues,
  handleSaveNewMetaValues,
  handleToggleAutoSave,
}: ViewSelectFiltersProps) => {
  const { metas: reportingMetas } = useReportingFilters()
  const { metas: allMetas } = useMetaData()
  const { dynamicFilter } = useDynamicFilters(dynamicFilterQuery)
  const isSaving = savingStatus === SavingStatus.SAVING
  const where_meta = getFilterArrayWhereMeta(filters)
  const savedMetaValues = activeMeta ? where_meta[activeMeta] || [] : []
  const hasUnsavedChanges = !!activeMeta && !isEqual(selectedMetaValues || [], savedMetaValues)

  useKeyPressEvent('s', () =>
    isAutoSaveDisabled ? handleSaveNewMetaValues(selectedMetaValues || []) : () => ({}),
  )
  const isSavingOrWaiting =
    savingStatus === SavingStatus.SAVING || savingStatus === SavingStatus.WAITING
  useKeyPressEvent('a', isSavingOrWaiting ? () => ({}) : handleToggleAutoSave)

  const getMetaValueList = (
    title: string,
    values: string[],
    onClick: (value: string[]) => void,
    matchingValues: string[],
    parentValues: string[],
    info?: string,
  ) => {
    const selectedNonParentValues = values.filter((v) => !parentValues.includes(v))
    const totalSizeOfArrays = selectedNonParentValues.length + parentValues.length
    const fontSize = totalSizeOfArrays > 30 ? '11px' : totalSizeOfArrays > 20 ? '12px' : '13px'
    return (
      <Paper
        sx={{
          width: 250,
          overflow: 'hidden',
          boxSizing: 'border-box',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <List dense component='div' role='list' sx={{ paddingTop: '0px' }}>
          <ListSubheader
            sx={{
              borderBottom: '1px solid grey',
              backgroundColor: pSBC(0.9, allChartColors.GREY),
              paddingLeft: '12px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div>{title}</div>
              {!!info && (
                <Tooltip title={info}>
                  <div style={{ marginLeft: '2px' }}>
                    <Icon name={IconName.INFO} color={allChartColors.GREY} width={14} height={14} />
                  </div>
                </Tooltip>
              )}
            </div>
          </ListSubheader>
          {parentValues.map((value) => {
            return (
              <ListItemButton
                key={value}
                id={value}
                role='listitem'
                disabled
                sx={{
                  padding: '2px 12px',
                  borderBottom: '1px solid #e0e0e0',
                  textWrap: 'wrap',
                  wordWrap: 'break-word',
                  minHeight: '30px',
                  backgroundColor: pSBC(0.9, allChartColors.GREY),
                }}
              >
                <ListItemText
                  id={value}
                  primary={
                    <div
                      style={{
                        fontSize: fontSize,
                      }}
                    >
                      {value}
                    </div>
                  }
                />
              </ListItemButton>
            )
          })}
          {selectedNonParentValues.map((value) => {
            return (
              <ListItemButton
                key={value}
                id={value}
                role='listitem'
                onClick={() => onClick([value])}
                disabled={matchingValues.includes(value) || savingStatus === SavingStatus.SAVING}
                sx={{
                  padding: '2px 12px',
                  borderBottom: '1px solid #e0e0e0',
                  textWrap: 'wrap',
                  wordWrap: 'break-word',
                  minHeight: '30px',
                  backgroundColor: matchingValues.includes(value) ? '#e0e0e0' : 'white',
                }}
              >
                <ListItemText
                  id={value}
                  primary={
                    <div
                      style={{
                        fontSize: fontSize,
                      }}
                    >
                      {value}
                    </div>
                  }
                />
              </ListItemButton>
            )
          })}
          {!totalSizeOfArrays && (
            <ListItemText
              id={'noValues'}
              primary={'None'}
              sx={{ padding: '4px 12px', borderBottom: '1px solid #e0e0e0', textAlign: 'center' }}
            />
          )}
        </List>
      </Paper>
    )
  }

  const selectedItemsElements = useMemo(() => {
    return getMetaValueList(
      tCommon('label.selected').toLocaleUpperCase(),
      selectedMetaValues || [],
      handleDeselectValues,
      [],
      parentSelectedMetaValues || [],
      parentSelectedMetaValues?.length ? infoFilterSelections : undefined,
    )
  }, [selectedMetaValues, isSaving, parentSelectedMetaValues])

  const allMetaValues: string[] | null = useMemo(() => {
    if (!activeMeta) return null
    let newUnSelectedMetaValues =
      filterListType === FilterListType.REPORTING
        ? (reportingMetas || {})[activeMeta]
        : filterListType === FilterListType.ALL
        ? (allMetas || {})[activeMeta]
        : (dynamicFilter || {})[activeMeta]

    if (!newUnSelectedMetaValues) return null
    if (selectedMetaValues)
      newUnSelectedMetaValues = newUnSelectedMetaValues.filter(
        (v) => !selectedMetaValues.includes(v),
      )
    if (parentSelectedMetaValues?.length)
      newUnSelectedMetaValues = newUnSelectedMetaValues.filter(
        (v) => !parentSelectedMetaValues.includes(v),
      )
    return newUnSelectedMetaValues
  }, [
    activeMeta,
    selectedMetaValues,
    savingStatus,
    parentSelectedMetaValues,
    filterListType,
    dynamicFilter,
    reportingMetas,
    allMetas,
  ])

  const allItemsElements = useMemo(() => {
    return getMetaValueList(
      tCommon('label.available').toLocaleUpperCase(),
      allMetaValues || [],
      handleSelectNewValues,
      selectedMetaValues || [],
      [],
    )
  }, [allMetaValues, selectedItemsElements, selectedMetaValues, savingStatus])

  return (
    <DoubleModalSide
      onClose={handleCloseFilterSelect}
      animation='fade'
      duration={MODAL_ANIMATION_DURATION}
    >
      <div className={modal_css.valueModalCntr}>
        <div className={modal_css.header}>
          <Tooltip title={activeMeta}>
            <div className={modal_css.headerMeta}>{(activeMeta || '').toLocaleUpperCase()}</div>
          </Tooltip>
          {savingElement}
        </div>
        {savingStatus === SavingStatus.MANUAL && hasUnsavedChanges && (
          <Tooltip title={tCommon('info.saveSelected')}>
            <div
              onClick={() => handleSaveNewMetaValues(selectedMetaValues || [])}
              style={{ right: '30px' }}
              className={modal_css.modalExtraIcon}
              data-testid='saveSelectedValues'
            >
              <Icon name={IconName.SAVE} color='#fff' width={24} height={24} />
            </div>
          </Tooltip>
        )}
        <div className={modal_css.valuesCntr}>
          <Grid
            container
            spacing={2}
            justifyContent='start'
            alignItems='center'
            width={'fit-content'}
            height={'100%'}
          >
            <Grid
              textAlign={'left'}
              overflow={'auto'}
              height={'inherit'}
              data-testid='selectableItemsList'
            >
              {allItemsElements}
            </Grid>
            <Grid>
              <Grid
                container
                direction='column'
                alignItems='center'
                width={'fit-content'}
                padding={'16px'}
              >
                <Button
                  sx={{
                    my: 0.5,
                    fontSize: '22px',
                    padding: '0 14px',
                    color: primaryModalColor,
                    borderColor: primaryModalColor,
                  }}
                  variant='outlined'
                  size='small'
                  onClick={() => handleDeselectValues(selectedMetaValues || [])}
                  disabled={
                    (selectedMetaValues || []).length === 0 || savingStatus === SavingStatus.SAVING
                  }
                  aria-label='remove all selected'
                  data-testid='removeAllSelected'
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid
              textAlign={'left'}
              overflow={'auto'}
              height={'inherit'}
              data-testid='selectedItemsList'
            >
              {selectedItemsElements}
            </Grid>
          </Grid>
        </div>
      </div>
    </DoubleModalSide>
  )
}

export default ViewSelectFilters
