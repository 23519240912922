// SOURCE: https://paladini.dev/posts/how-to-make-an-extremely-reusable-tooltip-component-with-react--and-nothing-else/

import React from 'react'
import css from './Tooltip.module.scss'

export type Directions = 'top' | 'bottom' | 'right' | 'left'

type TooltipProps = {
  content: string
  direction: Directions
}

const CustomTooltip = ({ content, direction }: TooltipProps) => {
  return (
    <div className={css.Tooltip_Wrapper} data-testid='custom-tooltip'>
      <div className={`${css.Tooltip_Tip} ${css[direction] || css['top']}`}>{content}</div>
    </div>
  )
}

export default CustomTooltip
