import React from 'react'
import { FormControlLabel, Switch, Tooltip } from '@mui/material'
import SaveText from './SaveText'
import { tCommon } from '../../../../../../../../languages/i18n'

type AutoSaveToggleProps = {
  isDisabled: boolean
  handleToggle: () => void
}
const AutoSaveToggle = ({ isDisabled, handleToggle }: AutoSaveToggleProps) => {
  return (
    <Tooltip title={tCommon('info.toggleAutosave')}>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={!isDisabled}
              onChange={handleToggle}
              name='autosaveToggle'
              color='default'
            />
          }
          label={
            <SaveText
              text={`${tCommon('label.autosave')}: ${
                isDisabled ? tCommon('label.off') : tCommon('label.on')
              }`}
            />
          }
          sx={{ height: '24px' }}
        />
      </div>
    </Tooltip>
  )
}

export default AutoSaveToggle
