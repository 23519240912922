import React, { useMemo } from 'react'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { LineChartDataFormats, LineChartModule, LineChartSettings } from '../lineChartTypes'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoTargetDataFormat } from '../LineNotifications'
import { isUndefined } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'

type FormatOptions = LineChartDataFormats.AVG | LineChartDataFormats.N | LineChartDataFormats.SUM

const options = [
  { value: 'avg', name: 'Averages' },
  { value: 'n', name: 'Counts' },
  { value: 'sum', name: 'Sums' },
]

const TargetDataFormatDropdown = () => {
  const [{ saveModule, module }] = useSettingsTools()
  const lineModule = module as LineChartModule
  const currentOptions = module?.options as LineChartSettings
  const currentValue = currentOptions?.kpiTargetDataFormat
  const currentTargetTo = currentOptions?.kpiTargetTo
  const currentValueTargetFrom = currentOptions?.kpiTargetFrom
  const hasTarget = useMemo(() => {
    return !isUndefined(currentTargetTo) || !isUndefined(currentValueTargetFrom)
  }, [currentTargetTo, currentValueTargetFrom])

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!module) return
    const value = e.currentTarget.value
    if (
      value === LineChartDataFormats.AVG ||
      value === LineChartDataFormats.N ||
      value === LineChartDataFormats.SUM
    ) {
      const newModule = {
        ...lineModule,
        options: { ...currentOptions, kpiTargetDataFormat: value as FormatOptions },
      }
      saveModule(newModule)
    }
  }

  return (
    <div className={`${modal_css.third}`}>
      <TextWithInfo text='Target data format' info={infoTargetDataFormat} tooltipDirection='left' />
      {hasTarget && <RequiredStar />}
      <select
        className={`${css.input} browser-default ${
          hasTarget && isUndefined(currentValue) ? modal_css.required : ''
        }`}
        onChange={handleOnChange}
        data-testid='kpiTargetDataFormat'
        id='kpiTargetDataFormat'
        value={isUndefined(currentValue) ? '' : currentValue}
      >
        <option key={'emptyOption'} value={''}></option>
        {options.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default TargetDataFormatDropdown
