import React, { memo, useEffect, useState } from 'react'
import { useEffectOnce, useMountedState } from 'react-use'
import { getSummaryGenerationConfig } from '../../../../react-services/summaryService'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import { ToastProvider, useToastId } from '../../../common/Notification/NotificationContext'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { SummaryDataProvider, useSummaryData } from './Context/SummaryDataContext'
import { SummaryFiltersProvider, useSummaryFilters } from './Context/SummaryFiltersContext'
import {
  Languages,
  SummaryGenerationConfig,
  SummaryModule,
  SummaryTimePeriod,
} from './summaryTypes'
import { hasRole } from '../../../../react-services/authService'
import {
  errorFetchSummaryConfig,
  infoSummaryModuleAdminRightsRequired,
} from './SummaryNotifications'
import { toast } from 'react-toastify'
import SummaryModuleComponent from './SummaryModuleComponent'
import { cloneDeep, isEqual } from 'lodash'

type SummaryContainerProps = {
  id: string
  isReportMode: boolean
  isScreenMode: boolean
  module: SummaryModule
  saveModule: (module: SummaryModule) => void
}

const SummaryModuleContainer = memo(
  ({ id, isReportMode, isScreenMode, module, saveModule }: SummaryContainerProps) => {
    const isAdminUser = hasRole('admin')
    if (!isAdminUser) return <div>{infoSummaryModuleAdminRightsRequired}</div>

    const saveModuleSettings = (options?: SummaryModule['options']) => {
      if (options && !isEqual(options || {}, module.options || {})) {
        const newModule = cloneDeep(module)
        newModule.options = options
        saveModule(newModule)
      }
    }
    return (
      <ToastProvider id={String(id)}>
        <NotificationContainer id={String(id)} />
        <ErrorBoundary
          message={'Error loading summary'}
          containerId={id}
          fallback={<div style={{ color: 'red' }}>{'Error loading summary'}</div>}
        >
          <SummaryDataProvider>
            <SummaryFiltersProvider>
              <SummaryModuleProvider
                id={id}
                isReportMode={isReportMode}
                isScreenMode={isScreenMode}
                saveSettings={saveModuleSettings}
                settings={module.options}
              />
            </SummaryFiltersProvider>
          </SummaryDataProvider>
        </ErrorBoundary>
      </ToastProvider>
    )
  },
)

type SummaryModuleProviderProps = {
  id: string
  isReportMode: boolean
  isScreenMode: boolean
  settings: SummaryModule['options']
  saveSettings: (options: SummaryModule['options']) => void
}
const SummaryModuleProvider = (props: SummaryModuleProviderProps) => {
  const setSummaryData = useSummaryData()[1]
  const setSummaryFilters = useSummaryFilters()[1]
  const [summaryConfig, setSummaryConfig] = useState<SummaryGenerationConfig | null>(null)
  const { toastifyId } = useToastId()
  const isMounted = useMountedState()

  useEffectOnce(() => {
    getSummaryGenerationConfig()
      .then((res) => isMounted() && setSummaryConfig(res))
      .catch(() => toast.error(errorFetchSummaryConfig, { toastId: toastifyId }))
  })

  useEffect(() => {
    if (!summaryConfig) return
    const newSummaryConfig: SummaryGenerationConfig = { configurations: [], summaryTypes: [] }
    if (props.settings?.moduleLanguage === Languages.EN) {
      newSummaryConfig.configurations = summaryConfig.configurations.filter(
        (c) => c.locale === 'en_US',
      )
    } else {
      newSummaryConfig.configurations = summaryConfig.configurations.filter(
        (c) => c.locale === 'fi_FI',
      )
    }
    if (summaryConfig.summaryTypes) newSummaryConfig.summaryTypes = summaryConfig.summaryTypes
    setSummaryData((prev) => ({
      ...prev,
      summaryConf: newSummaryConfig,
      summaryTypes: newSummaryConfig.summaryTypes,
    }))
  }, [props.settings?.moduleLanguage, summaryConfig])

  useEffect(() => {
    setSummaryData((prev) => ({
      ...prev,
      saveSettings: props.saveSettings ? props.saveSettings : () => ({}),
    }))
  }, [props.saveSettings])

  useEffect(() => {
    setSummaryData((prev) => ({
      ...prev,
      summaryTitle: props.settings?.summaryTitle ? props.settings.summaryTitle : 'Yhteenveto',
      metaKeysForFilters: props.settings?.metaKeysForFilters,
      isCustomerPathEnabled: props.settings?.isCustomerPathEnabled,
    }))
    setSummaryFilters((prev) => ({
      ...prev,
      summarySettings: props.settings ? props.settings : {},
      filterMetas: props.settings?.filterMetas ? props.settings.filterMetas : {},
      filterSentiment: props.settings?.filterSentiment ? props.settings.filterSentiment : '',
      filterPath:
        props.settings?.filterPath && props.settings?.isCustomerPathEnabled !== false
          ? props.settings.filterPath
          : '',
      filterSummaryTypes: props.settings?.filterSummaryTypes
        ? props.settings.filterSummaryTypes
        : [],
      filterFirstYear: props.settings?.filterFirstYear ? props.settings.filterFirstYear : '2021',
      filterTimePeriod: props.settings?.filterTimePeriod || SummaryTimePeriod.MONTH,
      language: props.settings?.moduleLanguage ? props.settings?.moduleLanguage : Languages.FI,
      kpis: props.settings?.kpis,
      minAnswersForSummary: props.settings?.minAnswersForSummary,
    }))
  }, [props.settings])

  return <SummaryModuleComponent />
}

SummaryModuleContainer.displayName = 'SummaryModuleContainer'
export default SummaryModuleContainer
