import React from 'react'
import { AddOrRemove, Breakpoint } from '../../pietabularTypes'
import BreakpointCardCheckBox from './BreakpointCardCheckBox'

import css from './BreakPointCard.module.scss'

type BreakPointCardProps = {
  selected: boolean
  breakpoint: Breakpoint
  onClick: () => void
  handleAddOrRemoveBreakpoint: (breakpoint: Breakpoint, action: AddOrRemove) => void
  active: boolean
}

const BreakPointCard = ({
  selected,
  breakpoint,
  onClick,
  active,
  handleAddOrRemoveBreakpoint,
}: BreakPointCardProps) => {
  return (
    <div
      className={`${css.cntr} ${selected ? css.cntr_selected : css.cntr_unselected} ${
        active ? css.active : ''
      }`}
      onClick={onClick}
      data-testid='breakpoints-list-bp-card'
    >
      <div className={css.title_cntr}>
        <span>
          <b>Title: </b>
          {breakpoint.title}
        </span>
        <BreakpointCardCheckBox
          selected={selected}
          onClick={() =>
            handleAddOrRemoveBreakpoint(breakpoint, selected ? AddOrRemove.REMOVE : AddOrRemove.ADD)
          }
        />
      </div>
      <span>
        <b>Date: </b> {breakpoint.date}
      </span>
      <span>
        <b>Description: </b> {breakpoint.description}
      </span>
    </div>
  )
}

export default BreakPointCard
