import React from 'react';
import css from './SelectedTableCntr.module.scss';

const TabTablesCntr: React.FC = (props) => {
  return (
    <div className={css.tbl_selected} data-testid='tools-customization-selected-cntr'>
      {props.children}
    </div>
  )
}

export default TabTablesCntr;