import React, { useEffect, useRef, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import { cloneDeep, isEqual, isUndefined } from 'lodash'
import { TopBottomModule, TopBottomModuleSettings } from '../topBottomTypes'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

const KpiSelectionTreeData = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const topbotModule = module as TopBottomModule
  const topBottomOptions = module?.options as TopBottomModuleSettings | undefined

  useEffect(() => {
    const kpiSelection = [] as kpiIdAndName[]
    const numKpis = topBottomOptions?.selectedKpis
    if (numKpis) {
      numKpis.forEach((kpi) => {
        if (!isUndefined(kpi.id)) {
          kpiSelection.push({ id: kpi.id, name: kpi.name })
        }
      })
    }
    setSelectedKpis(kpiSelection)
  }, [])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis, topbotModule])

  const handleSaveNewSelection = (newSelection: kpiIdAndName[]) => {
    const newOpenKpis = newSelection.filter((kpi) => !isUndefined(kpi.id))
    try {
      if (!topbotModule) return
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      const newModule = cloneDeep(topbotModule)
      newModule.options = {
        ...(topBottomOptions || {}),
        selectedKpis: newOpenKpis,
      }
      if (isEqual(newModule, topbotModule)) return
      timeoutRef.current = setTimeout(() => {
        saveModule(newModule)
      }, 500)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.numeric}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
