import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const OpenIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'rotate_right'}
    text=""
    customClasses={[css.btn]}
    iconPosition={'center'}
    title="Change alert state to 'OPEN'"
    data_testid="open-alert-icon-button"
  />
)

export default OpenIcon;