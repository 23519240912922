import { Close } from '@mui/icons-material'
import { Button, Dialog, IconButton, List, Paper } from '@mui/material'
import React from 'react'
import { Language } from '../../../../../languages/languages'
import { primaryModalColor } from '../../../../../styles/variableExport'

type LanguagePickerProps = {
  isAddLanguageDialogOpen: boolean
  handleCloseAddLanguageDialog: () => void
  allLanguages: Language[]
  selectableLanguages: Language[]
  handleClickNewLanguage: (lang: Language) => void
  info: string
}
const LanguagePicker = ({
  allLanguages,
  handleClickNewLanguage,
  handleCloseAddLanguageDialog,
  isAddLanguageDialogOpen,
  selectableLanguages,
  info,
}: LanguagePickerProps) => {
  return (
    <Dialog onClose={handleCloseAddLanguageDialog} open={isAddLanguageDialogOpen}>
      <Paper sx={{ padding: '3em 1em 1em 1em', color: primaryModalColor }}>
        <IconButton
          sx={{ position: 'absolute', right: 5, top: 5 }}
          onClick={handleCloseAddLanguageDialog}
        >
          <Close />
        </IconButton>
        <h6 style={{ width: '300px' }}>{info}</h6>
        <List>
          {allLanguages.map((lang) => {
            return !selectableLanguages.includes(lang) ? (
              <Button
                key={lang}
                value={lang}
                variant='outlined'
                sx={{ margin: '3px' }}
                disabled={true}
              >
                {lang}
              </Button>
            ) : (
              <Button
                key={lang}
                value={lang}
                variant='contained'
                sx={{ margin: '3px' }}
                onClick={() => handleClickNewLanguage(lang)}
              >
                {lang}
              </Button>
            )
          })}
        </List>
      </Paper>
    </Dialog>
  )
}

export default LanguagePicker
