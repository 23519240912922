import React from 'react'
import { MessageState } from '../../_shared/ConversationModal/conversationTypes'
import { OpenModule } from '../openTypes'
import { Input } from 'react-materialize'

import css from './CategorySelection.module.scss'

type MessageStatusSelectionProps = {
  module: OpenModule
  saveModule: (module: OpenModule) => void
}

const MessageStatusSelection = ({ module, saveModule }: MessageStatusSelectionProps) => {
  const allStates = Object.keys(MessageState) as MessageState[]
  const settingsStates = module?.options?.messageFilter

  let selectedStates = [] as MessageState[]
  if (settingsStates) {
    selectedStates = settingsStates
  } else {
    selectedStates = allStates
  }

  const handleClick = (state: MessageState) => {
    let newSelectedStates = [] as MessageState[]
    if (selectedStates.includes(state)) {
      newSelectedStates = selectedStates.filter((c) => c !== state)
    } else {
      newSelectedStates = selectedStates.concat(state)
    }
    saveModule({
      ...module,
      options: { ...module.options, messageFilter: newSelectedStates },
    })
  }

  return (
    <div className={css.cntr}>
      {!!allStates &&
        allStates.map((state) => (
          <Input
            key={state}
            className={'filled-in alerts-list-checkbox'}
            type='checkbox'
            label={state}
            value={state}
            checked={selectedStates.includes(state)}
            onClick={() => handleClick(state)}
          />
        ))}
    </div>
  )
}

export default MessageStatusSelection
