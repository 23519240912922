import { TooltipFormatterContextObject } from 'highcharts8'

export const compareDataOptions: Highcharts.Options = {
  series: [
    {
      type: 'column',
      name: 'demo_hus',
      data: [],
      pointPlacement: 'on',
      zones: [
        {
          value: 50,
          color: '#ff595e',
        },
        {
          color: '#8ac926',
        },
      ],
    },
  ],
  yAxis: {
    gridLineInterpolation: 'circle',
    lineWidth: 0,
    tickPositions: [0, 25, 50, 75, 100, 101],
    labels: {
      format: '<b>{value} %</b>',
      y: 17,
      x: 8,
    },
    plotBands: [
      {
        from: 0,
        to: 50,
        color: 'white',
        borderColor: 'red',
        borderWidth: 2,
      },
    ],
  },
  tooltip: {
    shared: true,
    formatter: function (this: TooltipFormatterContextObject) {
      if (this.y) {
        return `<div> ${this.x} <br /> <span style="color:${
          this.y > 49 ? '#8ac926' : '#ff595e'
        }">Positives: ${this.y} %</span><br/></div>`
      }
    },
  },
}

export const sentimentDataCount: Highcharts.Options = {
  tooltip: {
    formatter: function (this: TooltipFormatterContextObject) {
      return `<div style="color:${this.color}"> <b>${this.series.name}</b> <br /> ${this.x}: ${this.y} <br/> </div>`
    },
  },
  yAxis: {
    gridLineInterpolation: 'circle',
  },
}

export const sentimentDataPercentage: Highcharts.Options = {
  tooltip: {
    formatter: function (this: TooltipFormatterContextObject) {
      if (this.y) {
        return `<div style="color:${this.color}"> <b>${this.series.name}</b> <br /> ${
          this.x
        }: ${Math.trunc(this.y)} % <br/> </div>`
      }
    },
  },
  yAxis: {
    max: 100,
    gridLineInterpolation: 'circle',
    tickPositions: [0, 25, 50, 75, 100, 125],
    labels: {
      format: '<b>{value}</b> %',
    },
  },
}
