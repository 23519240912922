import { WhereMeta, WhereMetaSingle } from '../../../../../types'
import { kpiIdAndName } from '../_shared/ModuleSettings/components/SelectionTreeInput'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type SummaryModule = {
  type: 'summary'
  title: string
  options: SummarySettings & ModuleDefaultSettings
} & ModuleBase

export type SummarySettings = {
  filterMetas?: WhereMetaSingle
  filterSentiment?: string
  filterPath?: string
  filterSummaryTypes?: SummaryType[]
  summaryTitle?: string
  moduleLanguage?: Languages
  metaKeysForFilters?: string[]
  filterTimePeriod?: SummaryTimePeriod
  filterFirstYear?: string
  filterTimeFrame?: TimeFrame
  isCustomerPathEnabled?: boolean
  isSingleMetaSelectEnabled?: boolean
  isEmptyMetaValuesEnabled?: boolean
  isMetaFilterDynamic?: boolean
  subtitle?: string
  kpis?: kpiIdAndName[]
  minAnswersForSummary?: number
}

export type SummaryFilterQuery = {
  kpis?: number[]
  start_date?: string
  end_date?: string
  time_period?: SummaryTimePeriod
  pagination?: {
    page_size: number
    page_number: number
  }
  sentiment?: string[]
  topic?: string[]
  where_meta?: WhereMeta
}

export type SummaryFilterResponse = {
  page_metadata: {
    page_size: number
    total_elements: number
    total_pages: number
    page_number: number
  }
  summaries: Summaries[]
}

export type Summaries = {
  id: number
  start_date: string
  end_date: string
  time_period: SummaryTimePeriod
  summary_text: string
  locale: string
}

export type SummaryStyle = {
  background: string
  icon: string
}

export type SummaryDataContext = {
  summaryTitle: string
  summaryAngles: Angles[]
  summaryConf: SummaryGenerationConfig | null
  metaKeysForFilters?: string[]
  isCustomerPathEnabled?: boolean
  saveSettings: (settings: SummarySettings) => void
  summaries: SummaryItem[]
  summaryTypes: SummaryType[]
}

export type SummaryFiltersContext = {
  language: Languages
  summarySettings: SummarySettings
  filterInputs: string[]
  filterMetas: WhereMetaSingle
  filterSentiment: string
  filterTopic: string
  filterPath: string
  filterTimePeriod?: SummaryTimePeriod
  filterTimeFrame?: TimeFrame
  filterFirstYear?: string
  filterSummaryTypes: SummaryType[]
}

export type TimeFrame = {
  timeGroupIndex?: number
  year?: number
}
export type Angles = {
  sentiment?: string
  metas?: WhereMetaSingle
}

export enum SummaryTimePeriod {
  MONTH = 'month',
  QUARTER = 'quarter',
  HALF_YEAR = 'half year',
  // WEEK = 'WEEK',
}

// TODO: These are used for demo version and will change
export type SummaryGenerationConfig = {
  configurations: SummaryItem[]
  summaryTypes: SummaryType[]
}

export type SummaryItem = {
  angle: string
  filters: {
    topics: string[]
    sentiments?: string[]
    meta?: WhereMetaSingle
  }
  locale: string
  name: string
  request_type: string
  summary: string
  time_period: string
  start_date: string
  end_date: string
}

export type SummaryType = {
  id: string
  name: string
  angle: string
  icon: string
  color: string
}

export enum Languages {
  EN = 'en',
  FI = 'fi',
}
