import React from 'react'
import GroupAddModule from './add/GroupAddModule'
import GroupModulesList from './list/GroupModulesList'
import MetaChipsCntr from './filter/MetaChipsCntr'
import FilterBarCntr from './filter/FilterBarCntr'

import css from './GroupDashboard.module.scss'

type GroupDashboardProps = {
  handleOpenSettingsModal: () => void
  handleOpenDeleteModal: () => void
  isCntrHovered?: boolean
}
const GroupDashboard = ({
  handleOpenSettingsModal,
  handleOpenDeleteModal,
  isCntrHovered,
}: GroupDashboardProps) => {
  return (
    <div data-testid='group-dashboard' className={css.cntr}>
      <FilterBarCntr
        isCntrHovered={isCntrHovered}
        handleOpenSettingsModal={handleOpenSettingsModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
      <MetaChipsCntr />
      <GroupModulesList />
      <GroupAddModule />
    </div>
  )
}

export default GroupDashboard
