import React from 'react'
import CheckBoxItem from './CheckBoxItem'

import modal_css from './../SettingsModal.module.scss'
import css from './CheckBoxInput.module.scss'

type CheckBoxInputProps = {
  title: string | JSX.Element
  settingsName: string
  id?: string
  text: string
  type?: 'text' | 'number'
  classNames?: string
  data_testid?: string
  width?: 'half' | 'full' | 'third' | 'quarter' | 'none'
  isRootProperty?: boolean
  customSave?: (newValue: boolean) => void
  defaultValue?: boolean
  isDisabled?: boolean
  [prop: string]: unknown
}

const CheckBoxInput = ({ width, classNames, title, isDisabled, ...rest }: CheckBoxInputProps) => {
  const getWidthClass = () => {
    if (width === 'half') {
      return modal_css.half
    }
    if (width === 'full') {
      return modal_css.max
    }
    if (width === 'third') {
      return modal_css.third
    }
    if (width === 'quarter') {
      return modal_css.quarter
    }
    if (width === 'none') return ''
    return modal_css.half
  }

  return (
    <div className={`${classNames} ${getWidthClass()} ${isDisabled ? css.disabled : ''}`}>
      {title}
      <CheckBoxItem width={'full'} {...rest} classNames={css.item} isDisabled={isDisabled} />
    </div>
  )
}

export default CheckBoxInput
