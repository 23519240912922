import React from 'react'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import CellDetailsSelection from './CellDetailsSelection'
import CellCustomizationOptions from './CellCustomizationOptions'
import KpiSelectionTreeData from './KpiSelectionTreeData'

const TabularSettings = () => {
  return (
    <>
      <Subtitle text='Data' />
      <Linebreak />
      <KpiSelectionTreeData />

      <Subtitle text='Table customization' />
      <Linebreak />
      <CellCustomizationOptions />

      <Subtitle text='Notification colouring and cell format' />
      <Linebreak />
      <div style={{ width: '100%' }}>
        <CheckBoxInput
          settingsName='isKpiSpecificSettings'
          text='Enable'
          title='Customize limits per KPI'
          data_testid='isKpiSpecificSettings'
          id='isKpiSpecificSettings'
          width='half'
        />
      </div>
      <div style={{ width: '100%' }}>
        <CellDetailsSelection />
      </div>
    </>
  )
}

export default TabularSettings
