import objectHash from 'object-hash'
import { useEffect, useRef, useState } from 'react'
import {
  OpenAnswersCountsQuery,
  OpenAnswersCountsResponse,
} from '../components/Dashboards/DashboardModules/Pietabular/pietabularTypes'
import { makeResettingObservable } from './resettingObservable'
import { getOpenAnswersCounts } from '../react-services/openAnswersService'
import { isOpenAnswersCountsObject } from '../utilities'
import { errorFetchOpenAnswersCounts } from '../components/Dashboards/DashboardModules/Pietabular/PietabularNotifications'
import { useMountedState } from 'react-use'
import { debounce } from 'lodash'

const openAnswersCountsStorage = makeResettingObservable<OpenAnswersCountsResponse | null>({})

const useOpenAnswerCounts = (query: OpenAnswersCountsQuery | null) => {
  const keyRef = useRef<string>()
  const isMounted = useMountedState()
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isRefetching, setIsRefetching] = useState<boolean>(false)
  const [data, setData] = useState<OpenAnswersCountsResponse | null>(null)
  const key = objectHash(query, { unorderedArrays: true, unorderedObjects: true })

  useEffect(() => {
    if (!query) return setIsLoading(false)
    const debouncer = debounce(() => fetchCounts(query, key), 200)
    if (!openAnswersCountsStorage.getRelatedListeners(key)) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      if (data) setIsRefetching(true)
      debouncer()
    }
    return () => {
      debouncer.cancel()
    }
  }, [key])

  useEffect(() => {
    if (!query) return
    if (keyRef.current && keyRef.current !== key)
      openAnswersCountsStorage.unsubscribe(setData, keyRef.current)
    keyRef.current = key
    const data = openAnswersCountsStorage.get(keyRef.current)
    if (data) {
      if (!isMounted()) return
      setData(data)
      setIsLoading(false)
    }
    return openAnswersCountsStorage.subscribe(setData, keyRef.current)
  }, [key])

  useEffect(() => {
    if (data || error) {
      if (!isMounted()) return
      setIsLoading(false)
    }
  }, [data, error])

  const fetchCounts = (query: OpenAnswersCountsQuery, key: string) => {
    getOpenAnswersCounts(query)
      .then((res: unknown) => {
        if (isOpenAnswersCountsObject(res)) {
          if (!isMounted()) return
          openAnswersCountsStorage.set(res, key)
          setData(res)
        } else {
          throw new Error('')
        }
      })
      .catch(() => {
        if (!isMounted()) return
        setError(errorFetchOpenAnswersCounts)
        const emptyRes = {
          results: [],
          page_metadata: {
            page_number: 0,
            page_size: 0,
            total_elements: 0,
            total_pages: 0,
          },
        }
        openAnswersCountsStorage.set(emptyRes, key)
        setData(emptyRes)
      })
      .finally(() => isMounted() && setIsRefetching(false))
  }
  return {
    error,
    isLoading,
    counts: data,
    isRefetching,
  }
}
export default useOpenAnswerCounts
