import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import TextBoxModuleContainer from './TextBoxModuleContainer'
import { TextBoxModule } from './textBoxTypes'
import { isArray, isEmpty, isEqual } from 'lodash'
import { ToastProvider } from '../../../common/Notification/NotificationContext'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingModule } from './textBoxNotifications'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import useDynamicFilters, { DynamicFiltersQuery } from '../../../../stores/useDynamicFilters'
import useNumericKpis from '../../../../stores/useNumericKpis'
import {
  isAllowedToFetch,
  isFetchingData,
  isWaitingForFetching,
} from '../Group/contexts/renderListReducer'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { QueueStatus } from '../Group/groupModuleTypes'

type TextBoxPropsConverterProps = {
  module: TextBoxModule
  saveModule: (module: TextBoxModule) => void
  isReportMode: boolean
  isScreenMode: boolean
  sharedFilter?: SharedFilter
  moduleStatus: QueueStatus | undefined
  id: string
}
const TextBoxPropsConverter = memo(
  ({
    module,
    saveModule,
    isReportMode,
    isScreenMode,
    sharedFilter,
    moduleStatus,
    id,
  }: TextBoxPropsConverterProps) => {
    const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const { numericKpis, isLoading: isLoadingNumericKpis } = useNumericKpis()
    const { requestToFetch, updateModuleToIdle } = useRenderActions()
    const [dynamicFilterQuery, setDynamicFilterQuery] = useState<DynamicFiltersQuery | null>(null)

    useEffect(() => {
      const selectedKpis = Object.keys(module.options?.selections || {})
      const selectedIds = [] as number[]
      selectedKpis.forEach((kpiId) => !isNaN(Number(kpiId)) && selectedIds.push(Number(kpiId)))
      const hasSelectedKpis = !!selectedIds.length
      const startDate = module.query?.start_date
      const endDate = module.query?.end_date
      let newQuery: DynamicFiltersQuery | null = null
      if (!hasSelectedKpis && !numericKpis) return
      if (!startDate || !endDate) return
      const queryBase = {
        start_date: startDate,
        end_date: endDate,
        where_meta: module.query?.where_meta || {},
      }
      if (hasSelectedKpis) newQuery = { query: queryBase, kpis: selectedIds }
      const allNumericIds = (numericKpis || []).map((kpi) => kpi.id)
      if (!hasSelectedKpis && numericKpis) newQuery = { query: queryBase, kpis: allNumericIds }

      if (isEqual(newQuery, dynamicFilterQuery)) return
      if (id) handleRequestingToFetch(id)
      if (isAllowedToFetch(moduleStatus)) setDynamicFilterQuery(newQuery)
    }, [
      moduleStatus,
      module.query?.start_date,
      module.query?.end_date,
      module.query?.where_meta,
      module.options?.selections,
      numericKpis,
    ])

    const handleRequestingToFetch = (moduleId: string) => {
      requestToFetch(moduleId)
      if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
    }

    const {
      dynamicFilter: selectableFilters,
      isLoading: isLoadingDynamicFilters,
      isRefetching: isRefetchingDynamicFilters,
    } = useDynamicFilters(dynamicFilterQuery)
    const updateRawText = useCallback(
      function (rawText) {
        const newModule = { ...module, data: rawText }
        saveModule(newModule)
      },
      [module],
    )

    const DONE_STATUS_TIMEOUT = 200
    useEffect(() => {
      if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
      const isDoneFetching = !isLoadingDynamicFilters && !isRefetchingDynamicFilters
      if (isFetchingData(moduleStatus) && isDoneFetching) {
        doneStatusTimeoutRef.current = setTimeout(() => {
          updateModuleToIdle(id)
        }, DONE_STATUS_TIMEOUT)
      }
      return () => {
        doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
      }
    }, [moduleStatus, isLoadingDynamicFilters, isRefetchingDynamicFilters])

    const filters: { name: string; value: string[] }[] = useMemo(() => {
      if (module.isAllValuesForMetadata) {
        if (!selectableFilters) return []
        return Object.entries(selectableFilters).map(([name, value]) => {
          return { name, value }
        })
      }
      if (sharedFilter && sharedFilter.filters) {
        const filters = [] as Array<{ name: string; value: string[] }>
        for (const filter of sharedFilter.filters) {
          const value = filter.value
          const name = filter.name
          if (typeof name === 'string' && isArray(value)) {
            filters.push({ name, value })
          }
        }
        return filters
      }
      if (module.settings) {
        const filters = [] as Array<{ name: string; value: string[] }>
        for (const filter of module.settings) {
          const value = filter.value
          const name = filter.name
          if (typeof name === 'string' && isArray(value)) {
            filters.push({ name, value })
          }
        }
        return filters
      }
      if (module.query && !isEmpty(module.query)) {
        return Object.entries(module.query.where_meta || {}).map(([name, value]) => ({
          name,
          value,
        }))
      }
      return []
    }, [module.query, sharedFilter, module.isAllValuesForMetadata, selectableFilters])

    const isLoading =
      isLoadingDynamicFilters || isLoadingNumericKpis || isWaitingForFetching(moduleStatus)

    return (
      <ToastProvider id={id}>
        <NotificationContainer id={id} />
        <ErrorBoundary
          message={errorLoadingModule}
          containerId={id}
          fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
        >
          <TextBoxModuleContainer
            id={id}
            isLoading={isLoading}
            rawText={module.data || ''}
            onRawTextChange={updateRawText}
            filters={filters}
            isEditingDisabled={module.disableEdit || isReportMode || isScreenMode}
            moduleStatus={moduleStatus}
          />
        </ErrorBoundary>
      </ToastProvider>
    )
  },
)

TextBoxPropsConverter.displayName = 'TextBoxPropsConverter'
export default TextBoxPropsConverter
