import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import Open from './Open'
import { OpenModuleContext } from './_OpenModuleContext'
import { Kpi, Payload } from '../../../../../types'
import { getFilteredConversations } from '../../../../react-services/conversationService'
import { Conversation, MessageState } from '../_shared/ConversationModal/conversationTypes'
import { OpenModule, TextualKpiData } from './openTypes'
import { toast } from 'react-toastify'
import {
  ErrorGettingConversation,
  ErrorUpdatingAnswer,
  ErrorLoadingModule,
  ErrorWithFilters,
} from './OpenNotifications'
import { ToastProvider, useToastId } from '../../../common/Notification/NotificationContext'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import useNewOpenAnswers, { OpenAnswersRequest } from '../../../../stores/useNewOpenAnswers'
import useOpenKpis from '../../../../stores/useOpenKpis'
import FilterContainer from './Filter/FilterContainer'
import useOpenCategories from '../../../../stores/useOpenCategories'
import { Categories, OpenAnswer } from '../Pietabular/pietabularTypes'
import { cloneDeep, isEmpty, isEqual } from 'lodash'
import useGeneralConversation from '../../../../stores/useGeneralConversation'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import useOpenModuleTools from './useOpenModuleTools'
import { getTenant } from '../../../../react-services/authService'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import {
  getModuleMetaColumns,
  setModuleMetaColumns,
} from '../../../../react-services/moduleService'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { GenericQuery } from '../_shared/ModuleSettings/settingsTypes'
import { useMountedState } from 'react-use'
import {
  isAllowedToFetch,
  isFetchingData,
  isWaitingForFetching,
} from '../Group/contexts/renderListReducer'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { QueueStatus } from '../Group/groupModuleTypes'

import './OpenModule.scss'
import css from './OpenModuleContainer.module.scss'

type OpenModuleContainerProps = {
  module: OpenModule
  saveModule: (module: OpenModule) => void
  sharedFilter?: SharedFilter
  isReportMode: boolean
  isScreenMode: boolean
  id: string
  moduleStatus: QueueStatus | undefined
}

export const FILTERBAR_TIMEOUT = 12000

const OpenModuleContainer = ({
  id,
  module,
  saveModule,
  isReportMode,
  isScreenMode,
  sharedFilter,
  moduleStatus,
}: OpenModuleContainerProps) => {
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { updateModuleToIdle, requestToFetch } = useRenderActions()
  const query: Payload | null = useMemo(() => {
    const defaultQuery: GenericQuery = {
      start_date: '',
      end_date: '',
      grouping: '',
      filters: {},
      kpis: [],
      calculated_kpis: {},
    }
    const newQuery =
      sharedFilter && sharedFilter.filters
        ? buildQueryForGroupedOpen(sharedFilter.filters, module.query)
        : { ...module.query, where_meta: module.query?.filters || module.query?.where_meta || {} }
    // Attach 'metacolumns' setting to query. This is required when generating Open answers CSV attachment in reports
    if (getModuleMetaColumns(module)) setModuleMetaColumns(query, getModuleMetaColumns(module))
    if (!newQuery) return null

    return { ...defaultQuery, ...newQuery }
  }, [
    module,
    sharedFilter,
    module.query?.filters,
    module.query?.grouping,
    module.query?.kpis,
    module.query?.start_date,
    module.query?.end_date,
    module.query?.where_meta,
    module.query,
  ])

  const { toastifyId } = useToastId()
  const {
    handleFilterByMessageState,
    createConvertedKpiList,
    createOpenAnswersQuery,
    handleFilterByPhrase,
  } = useOpenModuleTools(isReportMode)
  const hideFilterBarTimeout = useRef<NodeJS.Timeout>()
  const getConversationsTimeout = useRef<NodeJS.Timeout>()
  const {
    searchFilter,
    doShowSentiments,
    doShowTopics,
    doShowCustomCategories,
    metacolumns,
    options,
  } = module

  useEffect(() => {
    const newModule = cloneDeep(module)
    if (!newModule.options) newModule.options = {}
    if (!newModule.options.topicCategoryFilter && answerCategories) {
      newModule.options.topicCategoryFilter = answerCategories.topic.concat('uncategorized')
    }
    if (!newModule.options.sentimentCategoryFilter && answerCategories) {
      newModule.options.sentimentCategoryFilter = answerCategories.sentiment
      newModule.options.sentimentCategoryFilter.push('uncategorized')
    }
    if (!isEqual(module, newModule) && isAllowedToFetch(moduleStatus)) saveModule(newModule)
  }, [moduleStatus])
  const [textualData, setTextualData] = useState<OpenAnswer[] | null>(null)
  const [conversationsList, setConversationsList] = useState<Conversation[]>([])
  const [hasMask, setHasMask] = useState<boolean>(false)
  const [showFilterBar, setShowFilterBar] = useState<boolean>(false)
  const [paginatedQuery, setPaginatedQuery] = useState<OpenAnswersRequest | null>(null)
  const { openKpis, isLoading: isLoadingKpis, error: kpisError } = useOpenKpis()
  useEffect(() => toastError(kpisError), [kpisError])

  const {
    answerCategories,
    configCategories,
    loading: isLoadingCategories,
    error: categoriesError,
  } = useOpenCategories()
  useEffect(() => toastError(categoriesError), [categoriesError])

  let customCategoryNames: string[] | null = null
  if (answerCategories) {
    const newCustomCategoryNames = Object.keys(answerCategories).filter(
      (name) => name !== 'sentiment' && name !== 'topic',
    )
    customCategoryNames = newCustomCategoryNames.length ? newCustomCategoryNames : null
  }
  let customCategories: Categories | null = null
  if (customCategoryNames && answerCategories) {
    const newCustomCategories: Categories | null = {}
    customCategoryNames.forEach((name) => (newCustomCategories[name] = answerCategories[name]))
    customCategories = newCustomCategories
  }

  const convertedSettingsKpis: Kpi.Kpi[] | null = useMemo(() => {
    let newConvertedSettingsKpis: Kpi.Kpi[] | null = null
    if (query && module && openKpis) {
      newConvertedSettingsKpis = createConvertedKpiList(openKpis, query.kpis)
    }
    return newConvertedSettingsKpis
  }, [openKpis, query?.kpis, answerCategories])

  useEffect(() => {
    if (query && convertedSettingsKpis) {
      const newPaginatedQuery = createOpenAnswersQuery(
        query,
        convertedSettingsKpis,
        customCategoryNames,
        options,
        answerCategories,
      )

      if (isEqual(newPaginatedQuery, paginatedQuery)) return
      if (id) handleRequestingToFetch(id)
      if (isAllowedToFetch(moduleStatus)) setPaginatedQuery(newPaginatedQuery)
    }
  }, [
    convertedSettingsKpis,
    moduleStatus,
    query?.start_date,
    query?.end_date,
    query?.kpis,
    query?.where_meta,
    options,
    answerCategories,
  ])

  const handleRequestingToFetch = (moduleId: string) => {
    requestToFetch(moduleId)
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
  }

  const {
    answers,
    isLoading: isLoadingAnswers,
    isRefetching: isFetchingMoreAnswers,
    error: answersError,
    pagination,
    fetchMoreAnswers,
  } = useNewOpenAnswers(paginatedQuery)
  useEffect(() => toastError(answersError), [answersError])

  const DONE_STATUS_TIMEOUT = 200
  useEffect(() => {
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
    const isDoneFetching = !isLoadingAnswers
    if (isFetchingData(moduleStatus) && isDoneFetching) {
      doneStatusTimeoutRef.current = setTimeout(() => {
        updateModuleToIdle(id)
      }, DONE_STATUS_TIMEOUT)
    }
    return () => {
      doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
    }
  }, [moduleStatus, isLoadingAnswers])

  const {
    config: generalConversationConfig,
    isLoading: isLoadingGeneralConversationConfig,
    error: errorGeneralConversationConfig,
  } = useGeneralConversation()
  useEffect(() => toastError(errorGeneralConversationConfig), [errorGeneralConversationConfig])

  const {
    config: commonDbSettingsConfig,
    isLoading: isLoadingCommonDbSettingsConfig,
    error: errorCommonDbSettingsConfig,
  } = useCommonDbSettingsConfig()
  useEffect(() => toastError(errorCommonDbSettingsConfig), [errorCommonDbSettingsConfig])

  const isLoading =
    isLoadingCategories ||
    isLoadingKpis ||
    (!answers && isLoadingAnswers) ||
    isLoadingGeneralConversationConfig ||
    isLoadingCommonDbSettingsConfig ||
    (!answers && isWaitingForFetching(moduleStatus))

  let answerMetas: string[] | null = null
  if (
    commonDbSettingsConfig?.open_answers_meta &&
    Array.isArray(commonDbSettingsConfig.open_answers_meta) &&
    commonDbSettingsConfig.open_answers_meta.length > 0
  ) {
    answerMetas = commonDbSettingsConfig.open_answers_meta
  }
  let showInspectorConversation = false
  if (
    generalConversationConfig?.show_conversation_open &&
    generalConversationConfig?.is_conversations_enabled
  ) {
    showInspectorConversation = true
  }

  useEffect(() => {
    return () => hideFilterBarTimeout.current && clearTimeout(hideFilterBarTimeout.current)
  }, [])

  useEffect(() => {
    setTextualData(answers)
  }, [answers])

  useEffect(() => {
    if (getConversationsTimeout.current) clearTimeout(getConversationsTimeout.current)
    getConversationsTimeout.current = setTimeout(() => {
      if (showInspectorConversation) {
        getConversationsForMessageCounts()
      }
    }, 1)
    return () => getConversationsTimeout.current && clearTimeout(getConversationsTimeout.current)
  }, [textualData])

  const getCompletedSurveyIds = () => {
    const convoIds = [] as number[]
    if (textualData) {
      textualData.forEach((kpiData) => {
        const idExistsInConvoArr = convoIds.find((id) => id === kpiData.completed_survey_id)
        if (!idExistsInConvoArr) {
          convoIds.push(kpiData.completed_survey_id)
        }
      })
    }
    return convoIds
  }

  const filterAnswersBySelectedValues = (
    data: OpenAnswer[],
    search: string,
    isConversationEnabled: boolean,
    conversations?: Conversation[],
    messageFilter?: MessageState[],
  ) => {
    const filteredDataByPhrase = handleFilterByPhrase(data, search)
    if (isConversationEnabled && !!conversations && !!messageFilter)
      return handleFilterByMessageState(filteredDataByPhrase, conversations, messageFilter)
    else return filteredDataByPhrase
  }

  function buildQueryForGroupedOpen(
    groupFilters: SharedFilter['filters'],
    moduleFilters: OpenModule['query'],
  ) {
    const result: Payload = transformFiltersToWhereMetaForm(groupFilters)
    result.kpis = moduleFilters && moduleFilters.kpis ? moduleFilters.kpis : []
    return result
  }

  const isMounted = useMountedState()
  const getConversationsWithConvoIds = (convoIds: number[]) => {
    getFilteredConversations({ completedSurveyIds: convoIds })
      .then((res: Conversation[]) => {
        if (!isMounted()) return
        if (res) {
          setConversationsList(res)
        } else {
          setConversationsList([])
        }
      })
      .catch(() => {
        if (!isMounted()) return
        toast.error(ErrorGettingConversation, { containerId: toastifyId })
        setConversationsList([])
      })
  }

  const getConversationsForMessageCounts = () => {
    if (textualData) {
      const convoIds = getCompletedSurveyIds()
      if (convoIds.length > 0) getConversationsWithConvoIds(convoIds)
    }
  }

  const updateMessageCount = (conversations: Conversation[]) => {
    setConversationsList(conversations)
  }

  const updateConversationsList = (conversations: Conversation[]) => {
    setConversationsList(conversations)
  }

  const handleAddMask = (isCategorySelectorOpen: boolean) => {
    setHasMask(isCategorySelectorOpen)
  }

  const handleChangeCategoriesCompleted = (affectedAnswer: TextualKpiData) => {
    if (textualData) {
      try {
        const newTextualData = textualData.map((a) =>
          a.answer_id === affectedAnswer.answer_id ? affectedAnswer : a,
        )
        setTextualData(newTextualData)
      } catch (e) {
        toast.error(ErrorUpdatingAnswer, { containerId: toastifyId })
      }
    }
  }

  const handleShowingFilterBar = () => {
    if (hideFilterBarTimeout.current) clearTimeout(hideFilterBarTimeout.current)
    hideFilterBarTimeout.current = setTimeout(() => {
      setShowFilterBar(true)
    }, 1000)
  }

  const handleHidingFilterBar = () => {
    if (hideFilterBarTimeout.current) clearTimeout(hideFilterBarTimeout.current)
    hideFilterBarTimeout.current = setTimeout(
      () => {
        setShowFilterBar(false)
      },
      getTenant() === 'test_frontend' ? 2000 : FILTERBAR_TIMEOUT,
    )
  }

  const toastError = (e: string) => {
    if (e) {
      toast.error(e, { containerId: toastifyId })
    }
  }

  const CONTEXT = {
    showInspectorConversation,
    showCategoryTool: configCategories ? true : false,
    conversationsList,
    updateConversationsList,
    updateMessageCount,
    onChangeCategoriesCompleted: handleChangeCategoriesCompleted,
    isReportMode,
    isScreenMode,
  }

  const isRefetching = useMemo(() => {
    return (
      (!answers && isWaitingForFetching(moduleStatus)) ||
      isFetchingMoreAnswers ||
      (!!answers && isLoadingAnswers)
    )
  }, [isFetchingMoreAnswers, answers, moduleStatus, isLoadingAnswers])

  return (
    <OpenModuleContext.Provider value={CONTEXT}>
      <div
        className={`open-container ${css.cntr}`}
        data-testid='openModuleCntr'
        onMouseOver={handleShowingFilterBar}
        onMouseLeave={handleHidingFilterBar}
      >
        {!isReportMode && !isScreenMode && (
          <ErrorBoundary
            message={ErrorWithFilters}
            fallback={<div style={{ color: 'red' }}>{ErrorWithFilters}</div>}
          >
            <FilterContainer
              query={paginatedQuery}
              customCategoryNames={customCategoryNames}
              module={module}
              saveModule={saveModule}
              pagination={pagination}
              loadedAnswers={answers?.length}
              showBar={showFilterBar}
              settingsKpis={convertedSettingsKpis}
              conversationEnabled={showInspectorConversation}
              filterAnswersBySelectedValues={(answers) =>
                filterAnswersBySelectedValues(
                  answers,
                  searchFilter || '',
                  showInspectorConversation,
                  conversationsList,
                  options?.messageFilter,
                )
              }
            />
          </ErrorBoundary>
        )}
        {hasMask && <div data-testid='openModuleMask' className='mask'></div>}
        {isLoading && (
          <div className={css.loading}>
            <LoadingIndicator />
          </div>
        )}
        {!isLoading && textualData && !!paginatedQuery && (
          <Open
            data={filterAnswersBySelectedValues(
              textualData,
              searchFilter || '',
              showInspectorConversation,
              conversationsList,
              options?.messageFilter,
            )}
            topics={answerCategories?.topic || null}
            doShowSentiments={
              Array.isArray(answerCategories?.sentiment) &&
              !isEmpty(answerCategories?.sentiment) &&
              !!doShowSentiments
            }
            doShowTopics={
              Array.isArray(answerCategories?.topic) &&
              !isEmpty(answerCategories?.topic) &&
              !!doShowTopics
            }
            doShowCustomCategories={!!doShowCustomCategories}
            customCategories={customCategories}
            answerMetas={answerMetas}
            metadataColumns={metacolumns || []}
            onChangeCategories={handleAddMask}
            fetchMoreAnswers={fetchMoreAnswers}
            isFetchingMoreAnswers={isRefetching}
            isReportMode={isReportMode}
            showToolsColumn={module.options?.showTools}
            hideFilterBarTimeout={hideFilterBarTimeout}
            setShowFilterBar={setShowFilterBar}
            showFilterBar={showFilterBar}
            paginatedQuery={paginatedQuery}
            pagination={pagination}
          />
        )}
      </div>
    </OpenModuleContext.Provider>
  )
}

const WrappedOpenModuleContainer = memo((props: OpenModuleContainerProps) => (
  <ToastProvider id={props.id}>
    <NotificationContainer id={props.id} />
    <ErrorBoundary
      message={ErrorLoadingModule}
      containerId={props.id}
      fallback={<div style={{ color: 'red' }}>{ErrorLoadingModule}</div>}
    >
      <OpenModuleContainer {...props} />
    </ErrorBoundary>
  </ToastProvider>
))

WrappedOpenModuleContainer.displayName = 'OpenModuleContainer'
export default WrappedOpenModuleContainer
