import React from 'react'
import {Icon} from 'react-materialize'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable';

export default class GroupList extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    // Re-render due to editor toggling will mess up the sorting of table; so don't rerender then
    if (this.props.editorWasToggled !== nextProps.editorWasToggled) {
      return false
    } else if (this.props.deletorWasToggled !== nextProps.deletorWasToggled) {
      return false
    }
    return true;
  }

  render() {
    const { groups, showEditGroup, showDeleteGroup } = this.props;
    const generateRows = () => {
      let result = [];
      groupKeysValues().forEach(group => {
        result.push(<tr key={group.id}>
          <td data-cy={`userGroup-${group.id}`} colSpan={2}>{group.name}</td>
          <td data-cy={`userGroupModules-${group.id}`} colSpan={2}>
            {group.modules && group.modules.length > 0 ?
              group.modules.length === 1 ?
                <span>{group.modules[0].name}</span>
              :
                <span className="tool">{group.modules[0].name + ', ...'}
                  <div className="tip">{modules(group.modules)}</div>
                </span>
            :
              <span>&nbsp;</span>
            }
          </td>
          <td data-cy={`userGroupARs-${group.id}`} colSpan={2}>
            {group.accessrights && group.accessrights.length > 0 ?
              group.accessrights.length === 1 ?
                <span className="tool">{group.accessrights[0]}
                  <div className="tip"><h6><b>Limited:</b></h6>{composeTooltip(group.accessrights)}</div>
                </span>
              :
                <span className="tool">{group.accessrights[0] + ', ...'}
                  <div className="tip"><h6><b>Limited:</b></h6>{composeTooltip(group.accessrights)}</div>
                </span>
            :
              <span>&nbsp;</span>
            }
          </td>
          <td data-cy={`userGroupDFs-${group.id}`} colSpan={2}>
            {group.defaultfilters && group.defaultfilters.length > 0 ?
              group.defaultfilters.length === 1 ?
                <span>{group.defaultfilters[0]}</span>
              :
                <span className="tool">{group.defaultfilters[0] + ', ...'}
                  <div className="tip">{composeTooltip(group.defaultfilters)}</div>
                </span>
            :
              <span>&nbsp;</span>
            }
          </td>
          <td data-cy={`userGroupRoles-${group.id}`}>{group.roles.join(', ')}</td>
          <td className="center">
            <span data-testid="showEditGroupModal" onClick={() => showEditGroup(group)}><Icon className="material-icons module-icon-color waves-effect">edit</Icon></span>
            <span data-testid="showDeleteUserModalGroup" onClick={() => showDeleteGroup(group)}><Icon className="material-icons module-icon-color waves-effect">delete</Icon></span>
          </td>
        </tr>)
      })
      return result;
    }

    const modules = (modules) => {
      let array = []
      for (let a of modules) {
        array.push(<div key={a.key}>{a.name}</div>)
      }
      return array
    }

    const groupKeysValues = () => {
      let array = []
      for (let a in groups) {
        groups[a].id = a
        array.push(groups[a])
      }
      return array
    }

    const composeTooltip = (array) => {
      let result = []
      for (let i = 0; i < array.length; i++) {
        result.push(<div key={array[i]}>{array[i]}</div>)
      }
      return result
    }

    return (
      <div data-cy="userGroupManagement" id="user-group-management">
        <br/><br/>
        <GenericTable
          headers={<tr>
            <th colSpan={2}>Name</th>
            <th colSpan={2}>Modules</th>
            <th colSpan={2}>Access rights</th>
            <th colSpan={2}>Default filters</th>
            <th>Roles</th>
            <th onClick={() => {}}>Actions</th>
          </tr>}
          rows={generateRows()}
          useFixedLayout
        />
      </div>
    )
  }
}
