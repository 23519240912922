import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { createStateContext } from 'react-use'
import {
  getConversations,
  getFilteredMessages,
  updateMessageStates,
} from '../../../../../react-services/conversationService'
import { useToastId } from '../../../../common/Notification/NotificationContext'
import ConversationModal from './ConversationModal'
import {
  Conversation,
  ConversationFunctionsContext,
  ConversationModalContextProps,
  ConversationToolsContext,
  ConversationType,
  MessagesFilter,
  MessageState,
  MessagesUpdate,
  SingleConversationMessage,
} from './conversationTypes'

export const [useConversationTools, ConversationToolsProvider] =
  createStateContext<ConversationToolsContext>({
    completedSurveyId: null,
    currentConversationId: null,
    isTextareaExpanded: false,
    messageType: ConversationType.EMAIL,
    conversationNav: { isConversationListOpen: true, isSingleConversationOpen: false },
    isUnsavedChanges: false,
    messageInput: '',
    initConversations: [],
    conversations: [],
    messages: [],
    emailForConversation: '',
  })

export const [useConversationFunctions, ConversationFunctionsProvider] =
  createStateContext<ConversationFunctionsContext>({
    getMessagesById: () => ({}),
    getConversationsById: () => ({}),
    updateMessagesListState: () => ({}),
    updateConversationsList: () => ({}),
    updateMessageCount: () => ({}),
    closeConversation: () => ({}),
    unsavedChangesToggle: () => ({}),
  })

const ConversationModalContext = ({ ...props }: ConversationModalContextProps) => {
  return (
    <ConversationToolsProvider>
      <ConversationFunctionsProvider>
        <ConversationModalProvider {...props} />
      </ConversationFunctionsProvider>
    </ConversationToolsProvider>
  )
}

const ConversationModalProvider = ({ ...props }: ConversationModalContextProps) => {
  const [{ completedSurveyId }, setConversationTools] = useConversationTools()
  const setConversationFunctions = useConversationFunctions()[1]
  const { toastifyId } = useToastId()

  useEffect(() => {
    setConversationTools((prev) => ({
      ...prev,
      completedSurveyId: props.completedSurveyId,
    }))

    setConversationFunctions((prev) => ({
      ...prev,
      getMessagesById: getMessagesById,
      getConversationsById: getConversationsById,
      updateMessagesListState: updateMessagesListState,
      updateConversationsList: props.updateConversationsList,
      updateMessageCount: props.updateMessageCount,
      closeConversation: props.closeConversation,
      unsavedChangesToggle: props.unsavedChangesToggle,
    }))
  }, [props.completedSurveyId])

  useEffect(() => {
    setConversationTools((prev) => ({
      ...prev,
      emailForConversation: props.emailForConversation,
    }))
  }, [props.emailForConversation])

  useEffect(() => {
    setConversationTools((prev) => ({
      ...prev,
      isUnsavedChanges: props.isUnsavedChanges,
    }))
  }, [props.isUnsavedChanges])

  useEffect(() => {
    if (completedSurveyId && props.conversationsList && props.conversationsList.length > 0) {
      const conversationsListFiltered = props.conversationsList.filter((conversation) => {
        return conversation.completedSurveyId === completedSurveyId
      })
      const sortedConversations: Conversation[] = conversationsListFiltered.sort(function (a, b) {
        return Number(new Date(b.created)) - Number(new Date(a.created))
      })
      setConversationTools((prev) => ({
        ...prev,
        initConversations: props.conversationsList,
        conversations: sortedConversations,
      }))
    }
  }, [completedSurveyId, props.conversationsList])

  const getConversationsById = (completedSurveyId: number) => {
    if (completedSurveyId) {
      getConversations(completedSurveyId)
        .then((res: Conversation[]) => {
          const sortedConversations: Conversation[] = res.sort(function (a, b) {
            return Number(new Date(b.created)) - Number(new Date(a.created))
          })
          setConversationTools((prev) => ({ ...prev, conversations: sortedConversations }))
        })
        .catch((err) => {
          toast.error(err, { containerId: toastifyId })
        })
    }
  }

  const getMessagesById = (conversationId: string) => {
    const messagesFilter: MessagesFilter = {
      conversationIds: [conversationId],
      newerThan: '2023-01-01T10:03:12.086Z',
      messageStates: [MessageState.READ, MessageState.UNREAD],
    }
    getFilteredMessages(messagesFilter)
      .then((res: SingleConversationMessage[]) => {
        const sortedConversationtMessages: SingleConversationMessage[] = res.sort(function (a, b) {
          return Number(new Date(b.sentAt)) - Number(new Date(a.sentAt))
        })
        setConversationTools((prev) => ({ ...prev, messages: sortedConversationtMessages }))
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        toast.error(err, { containerId: toastifyId })
      })
  }

  const updateMessagesListState = (messagesUpdate: MessagesUpdate) => {
    updateMessageStates(messagesUpdate)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => res)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => {
        toast.error(err, { containerId: toastifyId })
      })
  }

  return <ConversationModal />
}

export default ConversationModalContext
