import React, { useCallback, useEffect, useRef, useState } from 'react'
import SingleAnswerInspectorTable from './ValueInspectorSingleViewTable'
import { get } from '../../../../../../react-services/apiService'
import CopyToClipboardIcon from '../../../../../common/Buttons/CopyToClipboardIcon'
import { useEvent } from 'react-use'
import { BLACKLIST } from '../../../../../../react-constants/metadata'
import { TextualKpiData } from '../../../Open/openTypes'
import { tCommon, tData, tKpi } from '../../../../../../../languages/i18n'
import { List } from '@mui/icons-material'

import css from './ValueInspectorSingleView.module.scss'
import { useTranslation } from 'react-i18next'

type ValueInspectorSingleViewProps = {
  singleAnswer: TextualKpiData
  navToTable?: () => void
  showNavButton?: boolean
  reportingMetas: string[] | null
  inspected?: string
}

export type AnswerData = {
  question: string
  answer: string | number
}

function ValueInspectorSingleView({
  singleAnswer,
  navToTable,
  showNavButton,
  reportingMetas,
  inspected,
}: ValueInspectorSingleViewProps) {
  const [answerDataMeta, setAnswerDataMeta] = useState<AnswerData[]>()
  const [answerDataNum, setAnswerDataNum] = useState<AnswerData[]>()
  const [answerDataOpen, setAnswerDataOpen] = useState<AnswerData[]>()
  const [noDataMsg, setNoDataMsg] = useState<string>(tCommon('info.noData'))
  const { i18n } = useTranslation()
  const hasAnswerMeta = !!(answerDataMeta && answerDataMeta.length > 0)
  const hasAnswerNumData = !!(answerDataNum && answerDataNum.length > 0)
  const hasAnswerOpenData = !!(answerDataOpen && answerDataOpen.length > 0)

  const onKeyDown = useCallback(({ key }) => {
    if (key === 'ArrowLeft' && navToTable) navToTable()
  }, [])

  useEvent('keydown', onKeyDown)

  useEffect(() => {
    let isMounted = true
    HandleCompletedSurveyData()
    function HandleCompletedSurveyData() {
      get('GET_COMPLETED_SURVEY_ANSWERS', {
        completedSurveyId: singleAnswer.completed_survey_id,
      })
        .then((res) => {
          if (!isMounted) return
          const arrNum: AnswerData[] = []
          const arrOpen: AnswerData[] = []
          if (res.surveyAnswers) {
            const surveyAnswers = res.surveyAnswers
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            surveyAnswers.forEach((surveyAnswer: any) => {
              if (surveyAnswer.type === 'OPEN') {
                arrOpen.push({
                  question: tKpi(surveyAnswer.questionText),
                  answer: surveyAnswer.answerText,
                })
              }
              if (surveyAnswer.type === 'NUMERIC') {
                arrNum.push({
                  question: tKpi(surveyAnswer.questionText),
                  answer:
                    Number(surveyAnswer.answerText) % 1 === 0
                      ? Number(surveyAnswer.answerText)
                      : Number(surveyAnswer.answerText).toFixed(2),
                })
              }
            })
            const sortedArrNum = arrNum.sort((a, b) => (a.answer < b.answer ? -1 : 1))
            setAnswerDataNum(sortedArrNum)
            setAnswerDataOpen(arrOpen)
          }
        })
        .catch(() => {
          if (!isMounted) return
          setNoDataMsg(tCommon('error.singleAnswerInspector'))
        })
    }
    return () => {
      isMounted = false
    }
  }, [i18n.language])

  const answerMetaTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    answerMetaTimeout.current = setTimeout(() => {
      if (singleAnswer.metadata) {
        const arrMeta: AnswerData[] = []
        for (const [key, value] of Object.entries(singleAnswer.metadata)) {
          if ((reportingMetas && reportingMetas.includes(key)) || !reportingMetas) {
            arrMeta.push({ question: tData(key), answer: value })
          }
        }
        setAnswerDataMeta(arrMeta)
      }
    }, 1)
    return () => answerMetaTimeout.current && clearTimeout(answerMetaTimeout.current)
  }, [singleAnswer, reportingMetas])

  const handleCopyMetadataToClipboard = async () => {
    if (hasAnswerMeta)
      await navigator.clipboard.writeText(
        answerDataMeta.reduce(
          (string, meta) => string + meta.question + ': ' + meta.answer + '\n',
          '',
        ),
      )
  }

  const handleCopyNumericKpisToClipboard = async () => {
    if (hasAnswerNumData)
      await navigator.clipboard.writeText(
        answerDataNum.reduce(
          (string, numKpi) => string + numKpi.question + ': ' + numKpi.answer + '\n',
          '',
        ),
      )
  }

  const handleCopyOpenKpisToClipboard = async () => {
    if (hasAnswerOpenData)
      await navigator.clipboard.writeText(
        answerDataOpen.reduce(
          (string, openKpi) => string + openKpi.question + ':\n' + openKpi.answer + '\n\n',
          '',
        ),
      )
  }

  return (
    <>
      <div className={css.single_answer_inspector} data-cy='singleAnswerInspectorTest'>
        {showNavButton && (
          <button
            className='btn blue'
            title='Click or press ArrowLeft to go back to table view'
            onClick={navToTable}
            data-testid='goBackToTableButton'
          >
            {tCommon('button.backToTable')}
          </button>
        )}
        {!!inspected && (
          <span className={css.open_value_date} data-cy='valueInspectorResultDate'>
            {inspected}
          </span>
        )}
        <div className={css.single_answer_flex}>
          <div key={singleAnswer.id} className={css.single_answer_metadata}>
            <div className='value-inspector-result' data-testid='valueInspectorResult'>
              <div className={css.single_answer_header}>
                <div className={css.header_text}>{tCommon('label.backgroundInformation')}</div>
                <div className={css.icon}>
                  <List fontSize='inherit' />
                </div>
                {hasAnswerMeta && <CopyToClipboardIcon onClick={handleCopyMetadataToClipboard} />}
              </div>
              {hasAnswerMeta ? (
                <SingleAnswerInspectorTable
                  answerData={answerDataMeta.filter((m) => !BLACKLIST.includes(m.question))}
                />
              ) : (
                <p>{noDataMsg}</p>
              )}
            </div>
          </div>

          <div className={css.single_answer_numeric}>
            <div className='value-inspector-result' data-testid='valueInspectorResult'>
              <div className={css.single_answer_header}>
                <div className={css.header_text}>{tCommon('label.numericAnswers')}</div>
                <div className={css.icon}>
                  <List fontSize='inherit' />
                </div>
                {hasAnswerNumData && (
                  <CopyToClipboardIcon onClick={handleCopyNumericKpisToClipboard} />
                )}
              </div>
              {hasAnswerNumData ? (
                <SingleAnswerInspectorTable answerData={answerDataNum} />
              ) : (
                <p>{noDataMsg}</p>
              )}
            </div>
          </div>
        </div>
        <div className={css.single_answer_open}>
          <div className='value-inspector-result' data-testid='valueInspectorResult'>
            <div className={css.single_answer_header}>
              <div className={css.header_text}>{tCommon('label.openAnswers')}</div>
              <div className={css.icon}>
                <List fontSize='inherit' />
              </div>
              {answerDataOpen && answerDataOpen.length > 0 && (
                <CopyToClipboardIcon onClick={handleCopyOpenKpisToClipboard} />
              )}
            </div>
            {answerDataOpen && answerDataOpen.length > 0 ? (
              <SingleAnswerInspectorTable answerData={answerDataOpen} />
            ) : (
              <p>{noDataMsg}</p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ValueInspectorSingleView
