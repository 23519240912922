import React, { useEffect, useMemo, useState } from 'react'
import { get, post, getUserFromToken, hasRole } from '../../../../react-services/apiService'
import { Row, Button, Card } from 'react-materialize'
import ContactList from './ContactList'
import Sheetjs from 'sheetjs-style'
import AddContact from '../../ContactManagement/AddContact'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'
import useReportingFilters from '../../../../stores/useReportingFilters'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import { cloneDeep, debounce } from 'lodash'
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Button as MUIButton,
  FormControl,
  InputLabel,
  Chip,
} from '@mui/material'

import css from './ManualSendingOfSurveys.module.scss'
import { toast } from 'react-toastify'

const ManualSendingOfSurveys = () => {
  const { metas: filters = {}, isLoading: isLoadingReportingMetas } = useReportingFilters()
  const { config: cdbs, isLoading: isLoadingCdbs } = useCommonDbSettingsConfig()

  const [templates, setTemplates] = useState(null)
  const [templateId, setTemplateId] = useState('')
  const [showContactList, setShowContactList] = useState(false)
  const [name, setName] = useState('')
  const [selectedRecipientsFileData, setSelectedRecipientsFileData] = useState(null)
  const [selectedRecipientsFileName, setSelectedRecipientsFileName] = useState('')
  const [sender_name_email, setSender_name_email] = useState('')
  const [sender_name_sms, setSender_name_sms] = useState('')
  const [min_contact_frequency, setMin_contact_frequency] = useState('')
  const [subject, setSubject] = useState('')
  const [message_sms, setMessage_sms] = useState('')
  const [message_email_html, setMessage_email_html] = useState('')
  const [delivery_method, setDelivery_method] = useState('email')
  const [reply_to, setReply_to] = useState('')
  const [sender_email, setSender_email] = useState('')
  const [recipients, setRecipients] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [sendingInProgress, setSendingInProgress] = useState(false)
  const [hideAddContact, setHideAddContact] = useState(false)
  const [showAddNewContact, setShowAddNewContact] = useState(false)
  const [invitationMetadataFields, setInvitationMetadataFields] = useState([])
  const [invitationMetadataSelections, setInvitationMetadataSelections] = useState({})
  const [contacts, setContacts] = useState([])
  const [useOnlyContacts, setUseOnlyContacts] = useState(false)
  const currentUser = getUserFromToken().username
  const [invitationMetadata, setInvitationMetadata] = useState([])
  const [contactsToUse, setContactsToUse] = useState([])
  const isLoading = isLoadingReportingMetas || isLoadingCdbs
  const [selectedColumn, setSelectedColumn] = useState('')
  const DEBOUNCE_TIME = 1000

  const selectedFileColumns = useMemo(() => {
    if (selectedRecipientsFileData) {
      const keys = Object.keys(selectedRecipientsFileData[0])
      if (keys.length > 0) {
        return keys
      }
    }
    return ['No columns found']
  }, [selectedRecipientsFileData])

  const selectedRecipientsFromColumn = useMemo(() => {
    if (selectedRecipientsFileData && selectedColumn) {
      return selectedRecipientsFileData.map((row) => row[selectedColumn]).join(', ')
    }
    return ''
  }, [selectedRecipientsFileData, selectedColumn])

  useEffect(() => {
    if (isLoading) return
    const debouncer = debounce(() => {
      setupContactModeIfNecessary()
      getSurveyTemplates()
      getInvitationMetadataConfiguration()
    }, DEBOUNCE_TIME)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [cdbs, filters, isLoading])

  useEffect(() => {
    if (!templateId) return
    handleTemplateChange(templateId)
  }, [templateId])
  const setupContactModeIfNecessary = () => {
    if (cdbs && cdbs.survey_contacts === true) {
      setUseOnlyContacts(true)
      getContactList()
    }
    if (cdbs && cdbs.allow_add_contact === false && !hasRole('admin')) {
      setHideAddContact(true)
    }
  }

  const getSurveyTemplates = () => {
    get('GET_SURVEY_TEMPLATES').then(
      (res) => {
        if (res && res.templates) {
          res.templates.sortByKey('id', false)
          setTemplates(res.templates)
        }
      },
      (error) => {
        console.error(error)
      },
    )
  }

  const getInvitationMetadataConfiguration = () => {
    get('GET_SURVEYINVITATION').then(
      (res) => {
        if (res && res.surveymetakey) {
          setInvitationMetadata(res.surveymetakey)
          setInvitationMetadataFields(res.surveymetakey)
        }
      },
      (error) => {
        console.error(error)
      },
    )
  }

  const getContactList = (event) => {
    get('GET_CONTACTLIST').then(
      (res) => {
        setContacts(res)
      },
      (error) => {
        console.error(error)
      },
    )
  }

  const handleTemplateChange = (id) => {
    trackingService.track(TrackingEvent.SelectTemplateForSurvey)
    if (id !== '') {
      const template = templates.find((t) => t.id === parseInt(id, 10))
      let invitationMetadatasToUse =
        template.extra_metadata && template.extra_metadata.length > 0
          ? template.extra_metadata
          : invitationMetadata
      setValues(template)
      setTemplateId(id)
      setInvitationMetadataFields(invitationMetadatasToUse)
      setInvitationMetadataSelections(setupDefaultMetaSelections(invitationMetadatasToUse))
    }
  }

  const setupDefaultMetaSelections = (metas) => {
    let result = {}
    metas.forEach((m) => {
      result[m.key] = m.values && m.values.length > 0 ? m.values[0] : ''
    })
    return result
  }

  const resetTemplate = (event = null) => {
    trackingService.track(TrackingEvent.ResetTemplate)
    if (event && event.preventDefault) event.preventDefault()
    const template = templates.find((t) => t.id === parseInt(templateId, 10))
    setValues(template)

    // old code, no idea what it is supposed to do, no metadataValues is ever used
    // let metadataValues = [].concat(metadataValues)
    // for (let i = 0; i < metadataValues.length; i++) {
    //   metadataValues[i] = ''
    // }
    // this.setState({ metadataValues: metadataValues })
  }

  const setValues = (template) => {
    setName(template.name)
    setSender_name_email(template.sender_name_email)
    setSender_name_sms(template.sender_name_sms)
    setMin_contact_frequency(template.min_contact_frequency)
    setSubject(template.subject)
    setMessage_sms(template.message_sms)
    setMessage_email_html(template.message_email_html)
    setDelivery_method(template.delivery_method)
    setReply_to(template.reply_to)
    setSender_email(template.sender_email)
    setRecipients('')
    setError(null)
  }

  // const handleFieldChange = (event) => {
  //   if (event.target.name === 'delivery_method') {
  //     trackingService.track(TrackingEvent.ChangeDeliveryMethod, {
  //       deliveryMethod: event.target.value,
  //     })
  //   }
  //   this.setState({ [event.target.name]: event.target.value })

  // }

  const handleMetaValueChange = (key, value) => {
    // autocomplete seems to have a bug where it gives a valid and also undefined value on autocomplete; ignore it
    if (typeof value === 'undefined') return
    let metas = cloneDeep(invitationMetadataSelections)
    metas[key] = value ? value : null
    setInvitationMetadataSelections(metas)
  }

  const handleOnChangeRecipientsFile = (e) => {
    try {
      const file = e.target.files[0]
      const reader = new FileReader()

      reader.readAsArrayBuffer(file)
      reader.onload = (e) => {
        // upload file
        const binarystr = new Uint8Array(e.target.result)
        const wb = Sheetjs.read(binarystr, { type: 'array', raw: true, cellFormula: false })

        const wsname = wb.SheetNames[0]
        const data = Sheetjs.utils.sheet_to_json(wb.Sheets[wsname])
        toast.success('File read successful')
        setSelectedRecipientsFileName(file.name)
        setSelectedRecipientsFileData(data)
      }
    } catch (_e) {
      toast.error('Could not read file')
    }
  }

  const handleFileRemove = () => {
    setSelectedRecipientsFileData(null)
    setSelectedColumn('')
    setSelectedRecipientsFileName('')
  }

  const toggleContactList = (event) => {
    trackingService.track(
      showContactList
        ? TrackingEvent.CloseManualSendingContactList
        : TrackingEvent.OpenManualSendingContactList,
    )
    setShowContactList((prev) => !prev)
  }

  const toggleAddContact = (event) => {
    trackingService.track(
      showContactList
        ? TrackingEvent.CloseManualSendingContactList
        : TrackingEvent.OpenManualSendingContactList,
    )
    setShowAddNewContact((prev) => !prev)
  }

  const handleCheckboxChange = (id, name, email, phone, meta, isAdd) => {
    let copyOfState = cloneDeep(contactsToUse)
    if (isAdd) {
      copyOfState.push({ id, name, email, phone, meta })
    } else {
      copyOfState = copyOfState.filter((contact) => contact.id !== id)
    }
    setContactsToUse(copyOfState)
  }

  const handleAddNewContact = (name, email, phone, metadata) => {
    if (name.trim() === '') return
    const newContact = {
      name: name,
      email: email,
      phone: phone,
      meta: getMetadata(),
    }
    post('POST_NEWCONTACT', newContact).then(
      (res) => {
        handleCheckboxChange(res.id, name, email, phone, {}, true)
        setContacts(contacts.concat(res))
        setShowAddNewContact(false)
      },
      (error) => {
        console.error(error)
      },
    )

    function getMetadata() {
      let result = [
        { 'customer name': name },
        { 'customer email': email },
        { 'customer phone': phone },
      ]
      if (metadata && Object.keys(metadata).length > 0) {
        for (let m in metadata) result.push({ [m]: metadata[m] })
      }
      return result
    }
  }

  const validateFormBeforeSend = () => {
    let errors = collectInputFieldErrors()
    if (errors.length === 0) startSurvey()
    else setError(errors[0])
  }

  const collectInputFieldErrors = () => {
    let result = []
    if (!useOnlyContacts && !recipients) result.push('There are no recipients')
    if (useOnlyContacts && contactsToUse.length === 0) result.push('There are no recipients')
    if (!subject) result.push('Missing subject')
    if (!message_email_html) result.push('Missing email message')
    if (!message_email_html.includes('_link'))
      result.push('Email message needs to have _link_ somewhere.')
    return result
  }

  const startSurvey = () => {
    trackingService.track(TrackingEvent.StartSurvey)
    setSendingInProgress(true)
    let recipients = useOnlyContacts ? collectSurveyContacts() : collectSurveyRecipients()
    attachMetadataFromForm(recipients)
    handleInvitations(recipients)
  }

  const collectSurveyContacts = () => {
    let result = cloneDeep(contactsToUse)
    result.forEach((contact) => {
      contact.meta = {
        ...contact.meta,
        'customer name': contact.name || '',
        'customer email': contact.email || '',
        'customer phone': contact.phone || '',
        'sent by': currentUser,
      }
    })
    return result
  }

  const collectSurveyRecipients = () => {
    let isEmailSurvey = delivery_method === 'email'
    return recipients.split(',').map((rec) => buildOneRecipient(rec, currentUser))

    function buildOneRecipient(recipient, currentUser) {
      if (isEmailSurvey)
        return {
          email: recipient,
          meta: { 'customer email': recipient, 'customer phone': '', 'sent by': currentUser },
          phone: '',
        }
      else
        return {
          phone: recipient,
          meta: { 'customer email': '', 'customer phone': recipient, 'sent by': currentUser },
          email: '',
        }
    }
  }

  const attachMetadataFromForm = (recipients) => {
    if (!invitationMetadataSelections) return
    else
      recipients.forEach((rec) => {
        rec.meta = {
          ...rec.meta,
          ...nonNullMetadataSelections(invitationMetadataSelections),
        }
      })

    function nonNullMetadataSelections(metaSelections) {
      let result = {}
      for (let meta in metaSelections) {
        if (metaSelections[meta] !== null) result[meta] = metaSelections[meta]
      }
      return result
    }
  }

  const handleInvitations = (recipients) => {
    let invitationPromises = []
    recipients.forEach((recipient) => {
      invitationPromises.push(post('POST_SURVEYTEMPLATEINVITE', formInvitationPayload(recipient)))
    })

    Promise.all(invitationPromises)
      .then(() => {
        resetTemplate()
        setSuccess(true)
        setTimeout(() => {
          setSendingInProgress(false)
          setSuccess(false)
        }, 5000)
      })
      .catch((e) => {
        console.error(e)
        setSendingInProgress(false)
        setError('Could not complete this request. Try again later.')
      })
  }

  const formInvitationPayload = (recipient) => {
    return {
      id: parseInt(templateId, 10),
      min_contact_frequency: parseInt(min_contact_frequency, 10),
      contacts: [
        {
          email: recipient.email,
          phone: recipient.phone,
          metadata: recipient.meta,
        },
      ],
      sender_name_email: sender_name_email,
      sender_name_sms: sender_name_sms,
      reply_to: reply_to,
      message_sms: message_sms,
      sender_email: sender_email,
      message_email_html: message_email_html,
      subject: subject,
      delivery_method: delivery_method,
    }
  }

  const constructMetadataInputs = () => {
    let metadataInputs = []
    for (let i = 0; i < invitationMetadataFields.length; i++) {
      let filter = invitationMetadataFields[i]
      if (filter.values && filter.values.length > 0) {
        metadataInputs.push(inputWithPredefinedOptions(filter))
      } else {
        metadataInputs.push(inputWithFreeText(filter))
      }
    }
    return metadataInputs
  }

  const inputWithPredefinedOptions = (filter) => {
    let selectOptions = filter.values.map((val) => ({ label: val, value: val })) || []
    let currentValue = invitationMetadataSelections[filter.key]
    return (
      <Grid item xs={4} key={filter.key}>
        <label>{filter.key}</label>
        <Select
          sx={{ padding: '0px', width: '100%' }}
          value={currentValue}
          onChange={() => {
            handleMetaValueChange(value, value)
          }}
          title={filter.key}
          placeholder={filter.key}
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    )
  }

  const inputWithFreeText = (filter) => {
    const autoCompleteData = []
    filters[filter.key] &&
      filters[filter.key].forEach((f) => {
        autoCompleteData.push({ label: f, value: f })
      })
    return (
      <Grid item xs={4} key={filter.key}>
        <label>{filter.key}</label>
        <Autocomplete
          disablePortal
          id={`autocomplete-${filter.key}`}
          options={autoCompleteData}
          sx={{ width: '100%', padding: '0px' }}
          value={invitationMetadataSelections[filter.key] || ''}
          onChange={(_e, value) => handleMetaValueChange(filter.key, value)}
          renderInput={(params) => (
            <TextField {...params} placeholder={filter.key} sx={{ height: '' }} />
          )}
        />
      </Grid>
    )
  }

  return (
    <div data-cy='surveyTemplate' className={css.cntr}>
      {templates && (
        <div
          data-cy='selectSurveyTemplate'
          id='adminUserToolsSurveyTemplate'
          style={{ paddingTop: '2em' }}
        >
          <label>Survey template</label>
          <br />
          <Select
            value={templateId}
            title='Select template for survey'
            sx={{ width: '400px', padding: '0px' }}
            onChange={(e) => setTemplateId(e.target.value)}
          >
            {templates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
          <Row />
        </div>
      )}

      {templateId !== '' && (
        <div>
          <div>
            <label>Method</label>
            <Row onChange={(e) => setDelivery_method(e.target.value)}>
              <RadioGroup
                value={delivery_method}
                defaultValue='email'
                name='delivery-method-buttons-group'
                onChange={(e) => setDelivery_method(e.target.value)}
                row
              >
                <FormControlLabel value='email' control={<Radio />} label='Email' />
                <FormControlLabel value='sms' control={<Radio />} label='SMS' />
              </RadioGroup>
            </Row>
          </div>

          {useOnlyContacts ? (
            <div>
              <label>Recipients *</label>
              <textarea
                data-cy='recipientsNames'
                name='recipientsNames'
                className='pointer'
                onClick={() => toggleContactList()}
                value={contactsToUse.map((c) => c.name).join(', ')}
                readOnly
              ></textarea>
              <div>
                {!hideAddContact && (
                  <button className='right left-margin' onClick={() => toggleAddContact()}>
                    Add new contact
                  </button>
                )}
                <button className='right' onClick={() => toggleContactList()}>
                  Add contact from list
                </button>
              </div>
              {contactsToUse.length === 0 && <label className='red-text'>Required</label>}
            </div>
          ) : (
            <div>
              <label style={{ marginRight: '4em' }}>Recipients *</label>
              <MUIButton component='label' variant='contained' sx={{ marginRight: '1em' }}>
                Import from file
                <input
                  type='file'
                  accept='.xlsx'
                  hidden
                  onChange={handleOnChangeRecipientsFile}
                  value={''}
                />
              </MUIButton>
              {selectedRecipientsFileData && (
                <FormControl className={css.columnSelect} sx={{ marginRight: '1em' }}>
                  <InputLabel id='column-select-label'>Column</InputLabel>
                  <Select
                    labelId='column-select-label'
                    label='Column'
                    value={selectedColumn}
                    onChange={(e) => setSelectedColumn(e.target.value)}
                    sx={{ minWidth: '120px', width: 'fit-content' }}
                    renderValue={(value) => value || 'Select column'}
                  >
                    <MenuItem value=''>Select column</MenuItem>
                    {selectedFileColumns.map((col) => (
                      <MenuItem key={col} value={col}>
                        {col}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedRecipientsFromColumn && (
                <MUIButton
                  onClick={() => setRecipients(selectedRecipientsFromColumn)}
                  variant='contained'
                  sx={{ marginRight: '1em' }}
                >
                  Create recipients
                </MUIButton>
              )}
              {selectedRecipientsFileName && (
                <Chip
                  label={selectedRecipientsFileName}
                  clickable
                  onDelete={handleFileRemove}
                  sx={{ maxWidth: '200px' }}
                />
              )}
              <textarea
                data-cy='recipients'
                name='recipients'
                placeholder='Use commas (,) to separate recipients'
                onChange={(e) => setRecipients(e.target.value)}
                value={recipients}
                style={{ marginTop: '.2em' }}
              ></textarea>
              {!recipients.trim() && <label className='red-text'>Required</label>}
            </div>
          )}

          {showContactList && (
            <ContactList
              contacts={contacts}
              toggleContactList={toggleContactList}
              handleCheckboxChange={handleCheckboxChange}
              checkedContacts={contactsToUse}
            />
          )}

          {showAddNewContact && (
            <AddContact
              toggleAddContact={toggleAddContact}
              handleAddNewContact={handleAddNewContact}
            />
          )}

          {invitationMetadataFields && Object.keys(invitationMetadataFields).length > 0 && (
            <div style={{ padding: '1.2em 0' }}>
              <label>Metadata</label>
              <Grid container spacing={1}>
                {constructMetadataInputs()}
              </Grid>
            </div>
          )}

          {delivery_method === 'email' && (
            <div>
              <div>
                <label>Sender name (cannot be changed)</label>
                <input
                  disabled
                  name='sender_name_email'
                  onChange={(e) => setSender_name_email(e.target.value)}
                  value={sender_name_email}
                ></input>
              </div>
              {/* <div>
              <label>Sender email</label>
              <input name="sender_email" onChange={this.handleFieldChange} value={this.state.sender_email}></input>
            </div>         
            <div>
              <label>Reply to</label>
              <input name="reply_to" onChange={this.handleFieldChange} value={this.state.reply_to}></input>
            </div> */}
              <div>
                <label>Subject *</label>
                <input
                  name='subject'
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                ></input>
              </div>
              <div>
                <label>Email message *</label>
                <textarea
                  data-cy='invitationEmailMessage'
                  name='message_email_html'
                  onChange={(e) => setMessage_email_html(e.target.value)}
                  value={message_email_html}
                ></textarea>
              </div>
            </div>
          )}
          {delivery_method === 'sms' && (
            <div>
              <div>
                <label>Sender name (cannot be changed)</label>
                <input
                  disabled
                  name='sender_name_sms'
                  onChange={(e) => setSender_name_sms(e.target.value)}
                  value={sender_name_sms}
                ></input>
              </div>
              <div>
                <label>Subject *</label>
                <input
                  name='subject'
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                ></input>
              </div>
              <div>
                <label>SMS message *</label>
                <textarea
                  name='message_sms'
                  onChange={(e) => setMessage_sms(e.target.value)}
                  value={message_sms}
                ></textarea>
              </div>
            </div>
          )}

          <div>
            <br />
            {error && <Card className='red white-text'>{error}</Card>}
            <Button
              data-cy='startSurvey'
              disabled={sendingInProgress}
              onClick={() => validateFormBeforeSend()}
            >
              Start survey
            </Button>
            <Button flat data-cy='reset' className='margin-left' onClick={resetTemplate}>
              Reset
            </Button>
          </div>
        </div>
      )}
      {success && <Card className='green white-text'>Survey sent!</Card>}
    </div>
  )
}

export default ManualSendingOfSurveys
