/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useRef } from 'react'
import {
  createStateContext,
  useDeepCompareEffect,
  useMountedState,
  useShallowCompareEffect,
} from 'react-use'
import { toast } from 'react-toastify'

import { getAlertConfig, getAllAlerts, getOneAlert } from '../../../../react-services/alertService'
import { ToastProvider } from '../../../common/Notification/NotificationContext'
import FeedbackAlertsTable from './FeedbackAlertsTable'
import {
  AlertDataContext,
  AlertModalControlContext,
  AlertModule,
  AlertToolsContext,
  FeedbackAlert,
  FeedbackAlertPruned,
  ModuleFilter,
} from './alertTypes'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import {
  getConversationsConfig,
  getFilteredConversations,
} from '../../../../react-services/conversationService'
import { Conversation } from '../_shared/ConversationModal/conversationTypes'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'

export const [useAlertData, AlertDataProvider] = createStateContext<AlertDataContext>({
  initAlerts: null,
  alerts: null,
  relatedAlerts: null,
  alertConfig: null,
  alert: null,
  currentAlertId: null,
  isUnsavedChanges: false,
  conversationsList: [],
})
export const [useAlertTools, AlertsToolsProvider] = createStateContext<AlertToolsContext>({
  getAlerts: () => Promise.resolve(),
  updateAlerts: () => ({}),
  getAlert: () => ({}),
  handleAlertSave: () => ({}),
})
export const [useAlertModalControl, ModalControlProvider] =
  createStateContext<AlertModalControlContext>({
    isAlertModalOpen: false,
    isConversationModalOpen: false,
    isSettingsModalOpen: false,
    isRelatedAlertsModalOpen: false,
  })

type FeedbackAlertsTableConverterProps = {
  module: AlertModule
  sharedFilter?: SharedFilter
  saveModule: (module: AlertModule) => void
  id: string
  isReportMode: boolean
  isScreenMode: boolean
}
const FeedbackAlertsTableConverter = memo(({ module, id }: FeedbackAlertsTableConverterProps) => {
  if (!module.query) return <div className='alert-query-converter'></div>
  return <FeedbackAlertsTableContext id={id} query={module.query} />
})
FeedbackAlertsTableConverter.displayName = 'FeedbackAlertsTableConverter'
export { FeedbackAlertsTableConverter }

const FeedbackAlertsTableContext = ({ id, query }: FeedbackAlertsTableCntrProps) => {
  return (
    <ModalControlProvider>
      <AlertDataProvider>
        <AlertsToolsProvider>
          <FeedbackAlertsTableCntr id={id} query={query} />
        </AlertsToolsProvider>
      </AlertDataProvider>
    </ModalControlProvider>
  )
}

export const withAlertContext = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<any> => {
  const AlertContext = (props: any) => {
    return (
      <ModalControlProvider>
        <AlertDataProvider>
          <AlertsToolsProvider>
            <Component {...(props as P)} />
          </AlertsToolsProvider>
        </AlertDataProvider>
      </ModalControlProvider>
    )
  }
  return AlertContext
}

type FeedbackAlertsTableCntrProps = {
  id: string
  query: ModuleFilter
}

const FeedbackAlertsTableCntr = ({ id, query }: FeedbackAlertsTableCntrProps) => {
  const [{ currentAlertId, initAlerts, alertConfig, alerts }, setAlertData] = useAlertData()
  const setAlertTools = useAlertTools()[1]
  const isMounted = useMountedState()
  useShallowCompareEffect(() => {
    getAlerts()
    getConfig()
  }, [query])

  useDeepCompareEffect(() => {
    if (!initAlerts) return
    if (currentAlertId === null) return setAlertData((prev) => ({ ...prev, relatedAlerts: null }))
    const prunedAlert = initAlerts.find((alert) => alert.id === currentAlertId)
    if (!prunedAlert) return
    const newRelatedAlerts = initAlerts.filter(
      (alert) => alert.completed_survey_id === prunedAlert.completed_survey_id,
    )
    if (newRelatedAlerts.length > 1)
      return setAlertData((prev) => ({ ...prev, relatedAlerts: newRelatedAlerts }))
  }, [initAlerts || {}, currentAlertId])

  useDeepCompareEffect(() => {
    if (initAlerts && initAlerts.length > 0) {
      const prunedData = initAlerts
        .filter((alert) => alert.id !== undefined && alert.id !== null)
        .map(
          (
            {
              id,
              subject,
              comment,
              recipients,
              question,
              state,
              time,
              category,
              subcategory,
              completed_survey_id,
            },
            index,
            array,
          ) => {
            if (!state) state = ''
            const related = { related_alerts: false }
            for (let i = 0; i < array.length; i++) {
              if (completed_survey_id === array[i].completed_survey_id && id !== array[i].id) {
                related.related_alerts = true
                break
              }
            }
            return {
              id,
              subject,
              comment,
              recipients,
              question,
              state,
              time,
              category,
              subcategory,
              completed_survey_id,
              ...related,
            }
          },
        )
      setAlertData((prev) => ({ ...prev, alerts: prunedData }))
    } else {
      const emptyArray = [] as FeedbackAlertPruned[]
      setAlertData((prev) => ({ ...prev, alerts: emptyArray }))
    }
  }, [initAlerts || {}])

  useEffect(() => {
    setAlertTools((prev) => ({
      ...prev,
      getAlerts: getAlerts,
      updateAlerts: updateAlerts,
      getAlert: getAlert,
    }))
  }, [query])

  const alertConversationIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const CONVERSATION_CHECK_INTERVAL = 10000
  useEffect(() => {
    if (!alertConfig || !alertConfig.show_conversation) return
    if (alertConfig.is_live_alert_conversations_enabled) {
      if (alertConversationIntervalRef.current) clearInterval(alertConversationIntervalRef.current)
      getConversationsForMessageCounts()
      alertConversationIntervalRef.current = setInterval(() => {
        getConversationsForMessageCounts()
      }, CONVERSATION_CHECK_INTERVAL)
    } else {
      getConversationsForMessageCounts()
    }

    return () => {
      if (alertConversationIntervalRef.current) clearInterval(alertConversationIntervalRef.current)
    }
  }, [alerts])

  const getAlerts = () => {
    return getAllAlerts(query)
      .then((res: any) => {
        if (!isMounted()) return
        setAlertData((prev) => ({ ...prev, initAlerts: res.alerts }))
      })
      .catch((err: unknown) => {
        if (!isMounted()) return
        toast.error(typeof err === 'string' ? err : '', { containerId: id })
        const emptyArray = [] as FeedbackAlert[]
        setAlertData((prev) => ({ ...prev, initAlerts: emptyArray }))
      })
  }

  const updateAlerts = (id: number, changes: Object) => {
    if (initAlerts) {
      const updatedAlerts = initAlerts.map((alert) => {
        if (alert.id === id) {
          return { ...alert, ...changes }
        } else {
          return alert
        }
      })
      setAlertData((prev) => ({ ...prev, initAlerts: updatedAlerts }))
    }
  }

  const getAlert = (alertId: number) => {
    getOneAlert(alertId)
      .then((res) => {
        if (!isMounted()) return
        setAlertData((prev) => ({ ...prev, alert: res }))
      })
      .catch((err) => isMounted() && toast.error(err, { containerId: id }))
  }

  const getConfig = () => {
    Promise.all([getAlertConfig(), getConversationsConfig()])
      .then((res) => {
        if (!isMounted()) return
        setAlertData((prev) => ({
          ...prev,
          alertConfig: {
            ...res[0],
            assign: res[0]?.assign?.enabled,
            show_conversation: res[1]?.show_conversation_alert && res[1]?.is_conversations_enabled,
            is_live_alert_conversations_enabled: res[1]?.is_live_alert_conversations_enabled,
          },
        }))
      })
      .catch((err) => isMounted() && toast.error(err, { containerId: id }))
  }

  const getConversationsForMessageCounts = () => {
    if (alerts) {
      const convoIds = [] as number[]

      const getCompletedSurveyIds = () => {
        alerts.forEach((alert) => {
          const idExistsInConvoArr = convoIds.find((id) => id === alert.completed_survey_id)
          if (!idExistsInConvoArr && alert.completed_survey_id) {
            convoIds.push(alert.completed_survey_id)
          }
        })
      }

      const getConversationsWithConvoIds = () => {
        getFilteredConversations({ completedSurveyIds: convoIds })
          .then((res: Conversation[]) => {
            if (!isMounted()) return
            if (res) {
              setAlertData((prev) => ({ ...prev, conversationsList: res }))
            } else {
              setAlertData((prev) => ({ ...prev, conversationsList: [] }))
            }
          })
          .catch((err: unknown) => {
            if (!isMounted()) return
            toast.error(typeof err === 'string' ? err : '', { containerId: id })
            setAlertData((prev) => ({ ...prev, conversationsList: [] }))
          })
      }

      getCompletedSurveyIds()
      if (convoIds.length > 0) getConversationsWithConvoIds()
    }
  }

  return (
    <ToastProvider id={id}>
      <NotificationContainer id={id} />
      <FeedbackAlertsTable />
    </ToastProvider>
  )
}

export default FeedbackAlertsTableContext
