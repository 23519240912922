import { cloneDeep } from 'lodash'
import { useEffect } from 'react'
import { useRadarData } from '../RadarContext/RadarDataContext'
import { useRadarSettings } from '../RadarContext/RadarSettingsContext'
import { RadarDataMode } from '../radarTypes'
import { handleNewChartForAmount } from './handleNewChartForAmount'
import { handleNewChartForCompare } from './handleNewChartForCompare'
import { handleNewChartForMeta } from './handleNewChartForMeta'
import { initChartOptions } from './InitChartOptions'

const NewChartOptions = () => {
  const [{ countsByTopic, countsByMeta }, setRadarData] = useRadarData()
  const [
    {
      selectedTopics,
      radarDataMode,
      radarDataSentiment,
      radarDataDisplay,
      radarMetaKeyValuesSelected,
      isAlphabetical,
    },
  ] = useRadarSettings()

  useEffect(() => {
    const initSettings = cloneDeep(initChartOptions)

    if (radarDataMode === RadarDataMode.AMOUNT) {
      const newChartSettings = {
        ...initSettings,
        ...handleNewChartForAmount(
          radarDataSentiment,
          radarDataDisplay,
          isAlphabetical,
          countsByTopic,
          selectedTopics,
        ),
      }

      setRadarData((prev) => ({
        ...prev,
        radarOptions: newChartSettings,
      }))

      return
    }

    if (radarDataMode === RadarDataMode.COMPARE) {
      const newChartSettings = {
        ...initSettings,
        ...handleNewChartForCompare(isAlphabetical, countsByTopic, selectedTopics),
      }

      setRadarData((prev) => ({
        ...prev,
        radarOptions: newChartSettings,
      }))

      return
    }

    if (radarDataMode === RadarDataMode.META) {
      const newChartSettings = {
        ...initSettings,
        ...handleNewChartForMeta(
          radarDataSentiment,
          radarDataDisplay,
          isAlphabetical,
          countsByMeta,
          radarMetaKeyValuesSelected,
          selectedTopics,
        ),
      }

      setRadarData((prev) => ({
        ...prev,
        radarOptions: newChartSettings,
      }))

      return
    }
  }, [
    countsByTopic,
    countsByMeta,
    selectedTopics,
    radarDataSentiment,
    radarDataDisplay,
    radarMetaKeyValuesSelected,
    isAlphabetical,
  ])

  return null
}

export default NewChartOptions
