import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable';
import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator';
import { Direction, MetaAndPosition, Position } from '../inspectorTypes';
import MovePositionButtons from './TablePositionButtons';
import css from './SelectedTable.module.scss';
import { isEqual } from 'lodash';

type SelectedTableProps = {
  header: string,
  metasWithPosition: MetaAndPosition[],
  setSelectableMetaKeys: React.Dispatch<React.SetStateAction<string[] | null>>
  setSelectedMetaKeys: React.Dispatch<React.SetStateAction<MetaAndPosition[]>>,
}

const SelectedTable = ({
  header,
  metasWithPosition,
  setSelectedMetaKeys,
  setSelectableMetaKeys,
}: SelectedTableProps) => {
  const [disabled, setDisabled] = useState(false);
  const [rowsList, setRowsList] = useState<JSX.Element[] | null>(null);
  const [rowMetas, setRowMetas] = useState<MetaAndPosition[] | null>(null);
  const [animateTarget, setAnimateTarget] = useState<{ target: string, direction: 'out' | 'in' | 'up' | 'down' } | null>(null);

  useEffect(() => {
    if (metasWithPosition && rowMetas && metasWithPosition.length - rowMetas.length > 1 && animateTarget) {
      const newMeta = metasWithPosition.filter(meta => !rowMetas.map(meta => meta.name).includes(meta.name) && meta.name !== animateTarget.target)
      if (newMeta.length) setAnimateTarget({ target: newMeta[0].name, direction: 'in' });
      return;
    }
    if (metasWithPosition && rowMetas && metasWithPosition.length > rowMetas.length && !animateTarget) {
      const newMeta = metasWithPosition.filter(meta => !rowMetas.map(meta => meta.name).includes(meta.name))
      if (newMeta.length) setAnimateTarget({ target: newMeta[0].name, direction: 'in' });
      return;
    }
  }, [metasWithPosition]);

  useEffect(() => {
    if ((isEqual(rowMetas,metasWithPosition) && animateTarget) || !isEqual(rowMetas,metasWithPosition) || !animateTarget)
      createTableRows();
  },[metasWithPosition,animateTarget])

  const createTableRows = () => {
    const rows = [] as JSX.Element[];
    if (metasWithPosition) {
      metasWithPosition.forEach((meta, _i, array) => rows.push(
        <tr
          key={meta.name}
          onAnimationEnd={() => handleAnimationEnd(meta.name)}
          className={`${css.row} ${getAnimationClassName(meta.name)}`}
        >
          <td className={`${css.cell} ${css.metakey}`} colSpan={4} onClick={() => handleMetaKeyClick(meta.name)}>{meta.name}</td>
          <td className={`${css.cell} ${css.cell_order}`} colSpan={1} data-testid='selected-order-cell'>{meta.position}</td>
          <td className={`${css.cell}`} colSpan={3}>
            <MovePositionButtons
              position={getPosition(meta, array)}
              handlePositionChange={(direction: Direction) => handlePositionChange(meta, direction)}
              disabled={disabled}
            />
          </td>
        </tr>
      ))
      setRowsList(rows)
      setRowMetas(metasWithPosition);
    }
  }

  const getAnimationClassName = (meta: string) => {
    if (animateTarget && animateTarget.target === meta && animateTarget.direction === 'out') {
      return css.animate_out
    }
    if (animateTarget && animateTarget.target === meta && animateTarget.direction === 'in') {
      return css.animate_in
    }
    if (animateTarget && animateTarget.target === meta && animateTarget.direction === 'up') {
      return css.animate_up
    }
    if (animateTarget && animateTarget.target === meta && animateTarget.direction === 'down') {
      return css.animate_down
    }
    return ''
  }

  const getPosition = (metaKey: MetaAndPosition, array: MetaAndPosition[]): Position => {
    if (metaKey.position === 1) return Position.TOP
    if (metaKey.position === Math.max(...array.map(meta => meta.position))) return Position.BOTTOM
    return Position.BETWEEN
  }

  const getHeaders = (header: string) => {
    return (
      <tr key={header}>
        <th className={css.row} colSpan={4}>{header}</th>
        <th className={`${css.row} ${css.cell_order}`} colSpan={1}>Order</th>
        <th className={css.row} colSpan={3} onClick={() => ({})}>Change order</th>
      </tr>
    )
  }

  const handleAnimationEnd = (metaKey: string) => {
    setAnimateTarget(null);
    if (animateTarget && animateTarget.direction === 'out') {
      setSelectedMetaKeys(prev => handleRemovingSelectedMeta(prev, metaKey));
      setSelectableMetaKeys(prev => {
        if (!prev) return [metaKey];
        if (prev.includes(metaKey)) return prev;
        return prev.concat(metaKey)
      });
    }
    setDisabled(false);
  }

  const handleMetaKeyClick = (metaKey: string) => {
    if (disabled) return;
    setDisabled(true);
    setAnimateTarget({ target: metaKey, direction: 'out' })
  }

  const handleRemovingSelectedMeta = (metaObjects: MetaAndPosition[], removedMetaKey: string): MetaAndPosition[] => {
    const currentMeta = metaObjects.find(metaObject => metaObject.name === removedMetaKey);
    if (!currentMeta) return metaObjects;
    const currentPosition = currentMeta.position;
    const filteredMetas = metaObjects.filter(metaObject => metaObject.name !== removedMetaKey);
    return filteredMetas.map(metaObject => (metaObject.position > currentPosition ? { ...metaObject, position: metaObject.position - 1 } : metaObject));
  }


  const handlePositionChange = (meta: MetaAndPosition, direction: Direction) => {
    setDisabled(true);
    setAnimateTarget({
      target: meta.name,
      direction: direction === Direction.UP || direction === Direction.UP_TOP ? 'up' : 'down'
    });
    setTimeout(() => {
      setAnimateTarget({
        target: meta.name,
        direction: 'in'
      });
      if (direction === Direction.UP)
        setSelectedMetaKeys(prev => handleMovingMetaUp(meta, prev));
      if (direction === Direction.UP_TOP)
        setSelectedMetaKeys(prev => handleMovingMetaUpTop(meta, prev));
      if (direction === Direction.DOWN)
        setSelectedMetaKeys(prev => handleMovingMetaDown(meta, prev));
      if (direction === Direction.DOWN_BOTTOM)
        setSelectedMetaKeys(prev => handleMovingMetaDownBottom(meta, prev));
      setDisabled(false);
    },400);
  }

  const handleMovingMetaUp = (meta: MetaAndPosition, prev: MetaAndPosition[]) => {
    return prev.map(metaItem => {
      if (metaItem.position === meta.position)
        return { ...metaItem, position: meta.position - 1 }
      if (metaItem.position === meta.position - 1)
        return { ...metaItem, position: meta.position }
      return metaItem;
    })
  }

  const handleMovingMetaUpTop = (meta: MetaAndPosition, prev: MetaAndPosition[]) => {
    return prev.map(metaItem => {
      if (metaItem.position === meta.position)
        return { ...metaItem, position: 1 }
      if (metaItem.position < meta.position)
        return { ...metaItem, position: metaItem.position + 1 }
      return metaItem;
    })
  }

  const handleMovingMetaDown = (meta: MetaAndPosition, prev: MetaAndPosition[]) => {
    return prev.map(metaItem => {
      if (metaItem.position === meta.position)
        return { ...metaItem, position: meta.position + 1 }
      if (metaItem.position === meta.position + 1)
        return { ...metaItem, position: meta.position }
      return metaItem;
    })
  }

  const handleMovingMetaDownBottom = (meta: MetaAndPosition, prev: MetaAndPosition[]) => {
    return prev.map(metaItem => {
      if (metaItem.position === meta.position)
        return { ...metaItem, position: prev.length }
      if (metaItem.position > meta.position)
        return { ...metaItem, position: metaItem.position - 1 }
      return metaItem;
    })
  }

  return (
    <>
      {rowsList
        ?
        <GenericTable
          headers={getHeaders(header)}
          rows={rowsList}
          useFixedLayout
          preSortedColumn={{ index: 1, isReversed: false }}
          hasFixedHeader
        />
        :
        <LoadingIndicator />
      }
    </>
  )
}


export default SelectedTable;