import React from 'react';
import css from './TabContainer.module.scss';

const TabContainer: React.FC = (props) => {
  return (
    <div className={css.cntr} data-testid='tools-customization-cntr'>
      {props.children}
    </div>
  )
}

export default TabContainer;