import React from 'react'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { DataTypes } from '../numbertrendTypes'
import { infoDataFormatSelect } from '../NumberTrendNotifications'

const DataFormatSelect = () => {
  const dataTypes = Object.entries(DataTypes).map(([key, value]) => ({
    name: key.toLocaleLowerCase(),
    value,
  }))
  return (
    <>
      <DropdownInput
        options={dataTypes}
        settingsName={'yAxis'}
        isRootProperty
        data_testid='dataFormatSelect'
        isRequired
        title={
          <TextWithInfo text={'Data format'} tooltipDirection='top' info={infoDataFormatSelect} />
        }
      />
    </>
  )
}

export default DataFormatSelect
