export const BLACKLIST = [
  "anonymousid",
  "anonymousId",
  "anonid",
  "anonId",
  "contactattemptid",
  "contactattemptId",
  "contactAttemptId",
  "link",
  "surveylink",
  "surveyLink",
  "surveyid",
  "surveyId",
  "xxid",
  "xxId",
  "smsServiceProvider",
  "smsserviceprovider",
  "messenteStatus",
  "messentestatus",

  /* set by manual survey sender */
  "customer email",
  "customer phone",

  /* Outlook's "Safelinks Protection" adds these */
  "data",
  "sdata",
  "reserved",
]
