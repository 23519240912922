import React from 'react';


type State = { toastifyId: string }
type ToastProviderProps = {
  children: React.ReactNode,
  id: string
}

export const NotificationContext = React.createContext<State | undefined>(undefined);

export const ToastProvider = ({ children, id }: ToastProviderProps) => {
  const value = { toastifyId: id };
  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export const  useToastId = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useToastId must be used within a ToastProvider');
  }
  return context
}
