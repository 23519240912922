import { createStateContext } from 'react-use'
import {
  RadarDataDisplay,
  RadarDataSentiment,
  RadarSettingsContext,
} from '../radarTypes'

export const [useRadarSettings, RadarSettingsProvider] = createStateContext<RadarSettingsContext>({
  radarSettings: {},
  selectedTopics: [],
  radarDataDisplay: RadarDataDisplay.COUNT,
  radarDataSentiment: RadarDataSentiment.POSITIVE,
  radarDataMode: null,
  radarMetaKey: '',
  radarMetaKeyValues: [],
  radarMetaKeyValuesSelected: [],
  radarFilters: {
    start_date: '',
    end_date: '',
    where_meta: {},
  },
  isAlphabetical: false,
  saveSettings: () => ({}),
})
