import React, { useEffect } from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'
import { OpenModule, OpenModuleSettings } from '../openTypes'

import modal_css from './../../../DashboardModules/_shared/ModuleSettings/SettingsModal.module.scss'
import css from './SelectNonMetaColumns.module.scss'

type SelectCategoriesProps = {
  title: string
  propertyName: string
  isRootProperty?: boolean
  allCategories: string[]
}

const SelectCategories = ({
  title,
  propertyName,
  isRootProperty,
  allCategories,
}: SelectCategoriesProps) => {
  const [{ module, saveModule }] = useSettingsTools()
  const openModule = module as OpenModule
  const options = openModule?.options as OpenModuleSettings
  let selectedCategories = [] as string[]
  if (options && options[propertyName as keyof unknown] && !isRootProperty) {
    const selected = options[propertyName as keyof unknown]
    selectedCategories = selected ? selected : []
  }

  useEffect(() => {
    if (options && !options[propertyName as keyof unknown] && !isRootProperty) {
      selectedCategories = allCategories.concat('uncategorized')
      saveModule({
        ...openModule,
        options: { ...options, [propertyName]: selectedCategories },
      })
    }
  }, [options])

  const handleClick = (category: string) => {
    if (!module) return
    let newSelectedCategories = [] as string[]
    if (selectedCategories.includes(category)) {
      newSelectedCategories = selectedCategories.filter((meta) => meta !== category)
    }
    if (!selectedCategories.includes(category)) {
      newSelectedCategories = selectedCategories.concat(category)
    }
    if (!isRootProperty) {
      saveModule({
        ...openModule,
        options: { ...module.options, [propertyName]: newSelectedCategories },
      })
    }
  }

  return (
    <div className={`${modal_css.max}`}>
      {title}

      <div className={css.list}>
        {allCategories &&
          Array.isArray(allCategories) &&
          allCategories.map((category) => (
            <CheckBoxItem
              settingsName=''
              key={category}
              text={category}
              data_testid={'metakey-checkbox-item'}
              id={category}
              classNames={css.item}
              onClickLabel={handleClick}
              checked={selectedCategories.includes(category)}
            />
          ))}
        <CheckBoxItem
          settingsName=''
          key={'uncategorized'}
          text={'uncategorized'}
          data_testid={'metakey-checkbox-item'}
          id={'uncategorized'}
          isBlackAndBold
          classNames={css.item}
          onClickLabel={handleClick}
          checked={selectedCategories.includes('uncategorized')}
        />
      </div>
    </div>
  )
}

export default SelectCategories
