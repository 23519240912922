let logToConsole = false;

// Get random int with length x
var getRandomInt = function (length) {
  var digits = length ? length : 1;
  var rnd = Math.random();
  var multiplier = Math.pow(10, digits);
  var float = rnd * multiplier;
  var minvalue = Math.pow(10, digits - 1);
  if (float < minvalue) {
    float += minvalue;
  }
  var int = Math.floor(float);
  if (logToConsole) console.log('getRandomInt w/ length ' + digits);
  if (logToConsole) console.log(int);
  return int;
}

export {
  getRandomInt
}