import React, { memo, useCallback, useMemo } from 'react'
import TabularModuleCntr from './TabularModuleCntr'
import { DeprecatedTableModule, TabularModule } from './tabularTypes'
import { cloneDeep, isArray, isEqual, isUndefined } from 'lodash'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingModule } from './TabularNotifications'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'
import useNumericKpis from '../../../../stores/useNumericKpis'
import { GenericConfig } from '../../../../../types'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import useDynamicFilters, { DynamicFiltersQuery } from '../../../../stores/useDynamicFilters'
import {
  isAllowedToFetch,
  isFetchingData,
  isWaitingForFetching,
} from '../Group/contexts/renderListReducer'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { QueueStatus } from '../Group/groupModuleTypes'

type TabularModuleQueryConverterProps = {
  id: string
  sharedFilter?: SharedFilter
  module: TabularModule
  saveModule: (module: TabularModule) => void
  isScreenMode: boolean
  isReportMode: boolean
  moduleStatus: QueueStatus | undefined
}
const TabularModuleQueryConverter = ({
  id,
  isScreenMode,
  isReportMode,
  module,
  sharedFilter,
  saveModule,
  moduleStatus,
}: TabularModuleQueryConverterProps) => {
  const { originalCalculatedKpis: tenantCalculatedKpis } = useCalculatedKpis()
  const kpis = [] as { name: string; id: number }[]
  if (module.options?.kpis) {
    module.options.kpis.forEach((kpi) => kpis.push({ id: kpi.id, name: kpi.name }))
  }
  const calculatedKpis = { ...(module.options?.calculated_kpis || {}) }
  for (const ck of Object.keys(calculatedKpis)) {
    calculatedKpis[ck].args = calculatedKpis[ck].args || {}
  }

  let startDate = ''
  let endDate = ''
  let filters = {}
  if (sharedFilter) {
    const startDateFilter = sharedFilter.filters?.find(
      (filter) => filter.name === 'startDate',
    )?.value
    startDate = typeof startDateFilter === 'string' ? startDateFilter : ''
    const endDateFilter = sharedFilter.filters?.find((filter) => filter.name === 'endDate')?.value
    endDate = typeof endDateFilter === 'string' ? endDateFilter : ''
    try {
      filters = transformFiltersToWhereMetaForm(sharedFilter?.filters).where_meta
    } catch (e) {
      filters = module.query?.where_meta || {}
    }
  } else {
    startDate = module.query?.start_date || ''
    endDate = module.query?.end_date || ''
    filters = module.query?.where_meta || {}
  }

  const query = {
    startDate,
    endDate,
    grouping: module.options?.grouping === 'none' ? '' : module.options?.grouping || '',
    filters: filters || {},
    kpis: module.options?.kpis || [],
    calculatedKpis: module.options?.calculated_kpis || {},
  }

  const dynamicFilterQuery: DynamicFiltersQuery | null = useMemo(() => {
    let newQuery: DynamicFiltersQuery | null = null
    if (isScreenMode || isReportMode) return newQuery
    const queryKpis = [] as number[]
    if (query.kpis) query.kpis.forEach((kpi) => queryKpis.push(kpi.id))
    if (query.calculatedKpis && tenantCalculatedKpis) {
      const calculatedKpiNames = Object.keys(query.calculatedKpis)
      const calculatedKpiIds = calculatedKpiNames.flatMap(
        (kpiName) => tenantCalculatedKpis[kpiName].kpiIds,
      )
      queryKpis.push(...calculatedKpiIds)
    }
    if (query.startDate && query.endDate)
      newQuery = {
        query: {
          start_date: query.startDate,
          end_date: query.endDate,
          where_meta: query.filters || {},
        },
        kpis: queryKpis,
      }
    return newQuery
  }, [
    query.calculatedKpis,
    query.endDate,
    query.filters,
    query.kpis,
    query.startDate,
    tenantCalculatedKpis,
  ])

  const { dynamicFilter } = useDynamicFilters(dynamicFilterQuery, 200, false, false)
  const groupings = Object.keys(dynamicFilter || {})
  const saveModuleProperty = useCallback(
    (newPropertyObject: Record<string, unknown>) => {
      const prevModule = module
      if (!prevModule) return
      let newModule = cloneDeep(prevModule)
      newModule = { ...(newModule || {}), ...(newPropertyObject || {}) }
      saveModule(newModule)
    },
    [module],
  )

  return (
    <ErrorBoundary
      message={errorLoadingModule}
      containerId={id}
      fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
    >
      <TabularModuleCntr
        moduleStatus={moduleStatus}
        query={query}
        groupings={groupings}
        id={id}
        isScreenMode={isScreenMode}
        isReportMode={isReportMode}
        sorting={module.sorting}
        settings={module.options || {}}
        isTrends={!!module.options?.trends}
        settingsKpis={cloneDeep([
          ...(module?.options?.kpis || []),
          ...Object.keys(module.options?.calculated_kpis || {}).map((k) => ({ name: k })),
        ])}
        isKpiSpecificSettings={module?.options?.isKpiSpecificSettings ?? false}
        isTableCollapsed={module?.options?.isTableCollapsed ?? false}
        isTitleLoading={false}
        saveModuleProperty={saveModuleProperty}
      />
    </ErrorBoundary>
  )
}

export function handleConvertingTableToTabular(
  module: DeprecatedTableModule,
  tenantNumericKpis:
    | {
        id: number
        name: string
        grouping?: string | undefined
      }[]
    | null,
  tenantCalculatedKpis: GenericConfig.CalculatedKpis | null,
) {
  const minValue = !isUndefined(module.limitValues) ? module.limitValues.mid : undefined
  const maxValue = !isUndefined(module.limitValues) ? module.limitValues.max : undefined
  const oldModule = cloneDeep(module)
  const numericKpiSelections = Object.keys(module.selections || {})
    .filter((kpi) => !isNaN(Number(kpi)))
    .map((kpi) => Number(kpi))
  if (numericKpiSelections.length && !tenantNumericKpis) return
  const numericKpis = [] as { id: number; name: string }[]
  if (tenantNumericKpis) {
    for (const kpi of tenantNumericKpis) {
      if (numericKpiSelections.includes(kpi.id)) {
        numericKpis.push({ id: kpi.id, name: kpi.name })
      }
    }
  }
  const calculatedKpiSelections = Object.keys(module.selections || {}).filter((kpi) =>
    isNaN(Number(kpi)),
  )
  if (calculatedKpiSelections.length && !tenantCalculatedKpis) return
  const calculatedKpis = {} as GenericConfig.CalculatedKpis
  if (tenantCalculatedKpis) {
    for (const kpi of calculatedKpiSelections) {
      calculatedKpis[kpi] = tenantCalculatedKpis[kpi]
    }
  }
  delete oldModule.limitValues
  delete oldModule.filterGrouping
  delete oldModule.selections
  const newModule: TabularModule = {
    ...oldModule,
    type: 'tabular',
    title: module.title,
    options: {
      limitValues: { min: minValue, max: maxValue },
      grouping: module.filterGrouping || '',
      headers: [],
      kpis: numericKpis,
      calculated_kpis: calculatedKpis,
      show_total: false,
      show_total_n: false,
    },
    query: module.query ? { ...module.query, filters: module.query.where_meta || {} } : undefined,
  }
  return newModule
}

type TabularModuleConverterProps = {
  id: string
  sharedFilter?: SharedFilter
  module: TabularModule | DeprecatedTableModule
  saveModule: (module: TabularModule) => void
  isScreenMode: boolean
  isReportMode: boolean
  moduleStatus: QueueStatus | undefined
}
const TabularModuleConverter = memo((props: TabularModuleConverterProps) => {
  const moduleStatus = props.moduleStatus
  const { requestToFetch } = useRenderActions()
  const module = props.module
  const { originalCalculatedKpis: tenantCalculatedKpis, isLoading: isLoadingCalculatedKpis } =
    useCalculatedKpis()
  const { numericKpis: tenantNumericKpis, isLoading: isLoadingNumericKpis } = useNumericKpis()
  if (module.type === 'table') {
    if (!isWaitingForFetching(moduleStatus) || isFetchingData(moduleStatus))
      requestToFetch(props.id)
    if (!isLoadingCalculatedKpis && !isLoadingNumericKpis && isAllowedToFetch(moduleStatus)) {
      const newModule = handleConvertingTableToTabular(
        module,
        tenantNumericKpis,
        tenantCalculatedKpis,
      )
      if (newModule && !isEqual(newModule, props.module)) props.saveModule(newModule)
    }
    return <div className='tabular-module-table-converter-cntr'></div>
  }

  if (!isArray(module.settings)) {
    module.options = { ...(module.settings || {}), ...(module.options || {}) }
    delete module.settings
  }
  return <TabularModuleQueryConverter {...props} module={module} />
})

TabularModuleConverter.displayName = 'TabularModuleConverter'
export default TabularModuleConverter
