import React from 'react'
import AddNewCardIcon from './AddNewCardIcon'

import css from './BreakPointAddNewCard.module.scss'

type BreakPointCardAddNewCardProps = {
  onClick: () => void
  active: boolean
}
const BreakPointCardAddNewCard = ({ onClick, active }: BreakPointCardAddNewCardProps) => {
  return (
    <div className={`${css.cntr} ${active ? css.active : ''}`} onClick={onClick} data-testid='breakpoints-list-new-bp-card'>
      <AddNewCardIcon />
      <h5>&nbsp;&nbsp;&nbsp;ADD NEW BREAKPOINT</h5>
    </div>
  )
}
export default BreakPointCardAddNewCard
