import React, { useState } from 'react'
import { useUpdateEffect } from 'react-use'
import Rodal from 'rodal'

import css from './DoubleModalMain.module.scss'
import { getTenant } from '../../../react-services/authService'

type DoubleModalMainProps = {
  double: boolean
  onClose: () => void
  children: React.ReactNode
  closeOnEsc?: boolean
  animation?: string
  duration?: number
  visible?: boolean
  showMask?: boolean
  data_testid?: string
  customMaskStyles?: unknown
  customStyles?: unknown
  showCloseButton?: boolean
}

const DoubleModalMain = ({
  double,
  onClose,
  children,
  closeOnEsc = true,
  animation = 'slideUp',
  duration = 200,
  visible = true,
  showMask = false,
  customMaskStyles,
  customStyles,
  showCloseButton = true,
  data_testid = 'DoubleModalMainModalCntr',
}: DoubleModalMainProps) => {
  const [initLoad, setInitLoad] = useState(true)

  useUpdateEffect(() => setInitLoad(false), [double])

  return (
    <div className={`${css.cntr} ${double ? css.double : css.single}`} data-testid={data_testid}>
      <Rodal
        {...(initLoad && !double ? { enterAnimation: 'fade' } : {})}
        closeOnEsc={closeOnEsc}
        animation={getTenant() === 'test_frontend' ? undefined : animation}
        duration={getTenant() === 'test_frontend' ? undefined : duration}
        visible={visible}
        showMask={showMask}
        onClose={onClose}
        showCloseButton={showCloseButton}
        customMaskStyles={customMaskStyles}
        customStyles={customStyles}
      >
        {children}
      </Rodal>
    </div>
  )
}

export default DoubleModalMain
