import React, { useState, useEffect } from 'react'
import { get, post } from '../../../react-services/apiService'
import AlertTable from './AlertTable'
import { AlertTool } from '../../../../types'
import LoadingIndicator from '../../_shared/Infos/LoadingIndicator'
import SaveSummaryRodal from './SaveSummaryRodal'
import AlertRecipients from './AlertRecipients'
import { validateEmail } from '../../../react-services/validators'
import { Input } from 'react-materialize'

type ErrorMessage = {
  email: string
  errMessage: string
}

function Alert() {

  const [alertData, setAlertData] = useState<AlertTool.AlertData>()
  const [alert, setAlert] = useState<AlertTool.Alert>()
  // const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showSaveSummary, setShowSaveSummary] = useState<boolean>(false)
  const [savedRecipients, setSavedRecipients] = useState<string[]>([])
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])

  useEffect(() => {
    apiCalls()
  }, [])

  const apiCalls = () => {
    setIsLoading(true)

    Promise.all([
      get('GET_ALERT_CONFIGS'), get('GET_ALL_KPIS')  // , get('GET_METAS')
    ])
      // metas are not used for now
      .then(responses => {
        const alerts: AlertTool.Alert[] = responses[0].alertConfigs
        const alertNames = alerts.map(({ name }) => name);
        const kpis: AlertTool.Kpi[] = responses[1].kpis
        // const metas: Record<string, string[]> = responses[2].metas

        setAlertData({
          alerts,
          filteredAlerts: alerts,
          alertNames,
          kpis: kpis.filter(({ type }) => type === 'numeric')
        });
      })
      .catch(error => {
        console.error(error)
        // setError("Failed to get alerts")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const postAlertChange= (editedAlert: AlertTool.Alert, doApiCalls: boolean) => {
    post('POST_ALERT_CONFIGS', { alertConfigs: [editedAlert] })
      .then(() => {
        if (doApiCalls) apiCalls()
      }).catch((error: Error) => {
        console.error(error)
      })
  }

  const editAlert = (alertName: string) => {
    setAlert(getAlert(alertName))
    setIsEditMode(true)
    setIsModalOpen(true)
  }

  const showRecipients = (alertName: string) => {
    setAlert(getAlert(alertName))
    setIsEditMode(false)
    setIsModalOpen(true)
  }

  const getAlert = (alertName: string) => {
    if (!alertData) return
    const index = alertData.alertNames.indexOf(alertName)
    return alertData.alerts[index]
  }

  const handleSaveRecipients = (textField: string) => {
    if (!alert) return
    setShowSaveSummary(true)

    const { newRecipients, errors } = formatRecipients(textField)
    const oldAndNew = alert.recipients.concat(newRecipients)

    setSavedRecipients(newRecipients)
    setErrorMessages(errors)
    const newAlert = { ...alert }
    newAlert.recipients = oldAndNew
    setAlert(newAlert)
  }

  const makeList = (textRecipients: string) => {
    const formatted = textRecipients.replace(/ |;|\n/g, ',')
    const splitted = formatted.split(',').filter(email => email !== '')
    return splitted
  }

  const formatRecipients = (textField: string): { newRecipients: string[], errors: ErrorMessage[] } => {
    const errors: ErrorMessage[] = []
    const newRecipients: string[] = makeList(textField)
    const oldAndNew: string[] = alert ? alert.recipients.concat(newRecipients) : []
    const validated: string[] = []

    for (const email of newRecipients) {
      const validationError = validateEmail(email, oldAndNew)
      if (validationError === null) {
        validated.push(email)
      } else {
        errors.push({ email: '"' + email + '"', errMessage: validationError })
      }
    }

    return { errors, newRecipients: validated }
  }

  const handleRemove = (userIndex: number) => {
    if (!alert) {
      return
    }
    if (userIndex !== undefined) {
      const newRecipients = alert.recipients.filter((_, i) => i !== userIndex)
      const newAlert = { ...alert }
      newAlert.recipients = newRecipients
      setAlert(newAlert)
      postAlertChange(newAlert, false)
    }
  }

  const handleCloseRecipientsPreview = () => {
    if (isEditMode) apiCalls();
    setIsModalOpen(false)
  }

  const handleCloseSaveSummary = () => {
    if (alert) postAlertChange(alert, true)
    setShowSaveSummary(false)
    setIsModalOpen(false)
  }

  const searchHandler = (search: string) => {
    if (!alertData) return
    const newAlertData = { ...alertData }
    if (search.length < 1) {
      newAlertData.filteredAlerts = newAlertData.alerts
      setAlertData(newAlertData)
      return;
    }
    const kpis = alertData.kpis.filter(e => e.name.toLowerCase().includes(search.toLowerCase()));
    let filteredAlerts: AlertTool.Alert[];
    // kpis.length < 100 to prevent performance issues with large amount of alerts
    if (kpis !== undefined && kpis.length < 100) {
      filteredAlerts = []
      kpis.forEach(kpi => {
        const combined = filteredAlerts.concat(alertData.alerts.filter(e => e.questionId === kpi.id));
        filteredAlerts = combined
      });  
    } else {
      filteredAlerts = [];
    }
    newAlertData.filteredAlerts = filteredAlerts
    setAlertData(newAlertData)
  }

  return (
    <div>
      <br /><br />
      {isLoading
        ? <LoadingIndicator />
        :
        <>
          <Input
            icon="search"
            s={12}
            label="Search by KPI name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchHandler(e.target.value)}
          />
          <AlertTable AlertData={alertData} edit={editAlert} show={showRecipients} />
        </>
      }
      {isModalOpen && alert &&
        <AlertRecipients
          recipients={alert.recipients}
          alertName={alert.name}
          isEditMode={isEditMode}
          handleSaveRecipients={handleSaveRecipients}
          handleRemove={handleRemove}
          onClose={handleCloseRecipientsPreview} />
      }
      {showSaveSummary &&
        <SaveSummaryRodal
          errorMessages={errorMessages}
          savedRecipients={savedRecipients}
          closeSaveSummary={handleCloseSaveSummary}
        />
      }
    </div>
  )
}

export default Alert
