import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import languages, { Language } from './languages'
import { FI, GB, SE } from 'country-flag-icons/react/3x2'
import { useTranslation } from 'react-i18next'

import css from './LanguageSelect.module.scss'

type LanguageSelectProps = {
  tenantLanguages?: Language[]
  isFlagsOnly?: boolean
  hasDarkBackground?: boolean
}
const LanguageSelect = ({
  tenantLanguages,
  isFlagsOnly,
  hasDarkBackground,
}: LanguageSelectProps) => {
  const { t, i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language || 'en')

  const handleChangeLanguage = (e: SelectChangeEvent<string>) => {
    const newLanguage = e.target.value as Language
    setCurrentLanguage(newLanguage)
    i18n.changeLanguage(newLanguage)
    localStorage.setItem('i18nextLng', newLanguage)
  }

  useEffect(() => {
    if (currentLanguage === i18n.language) return
    setCurrentLanguage(i18n.language)
  }, [i18n.language])

  const displayedLanguages = useMemo(() => {
    return tenantLanguages || languages
  }, [tenantLanguages])

  const languageLabels = {
    en: (
      <>
        English&nbsp;&nbsp;
        <GB width={15} height={10} />
      </>
    ),
    fi: (
      <>
        Suomi&nbsp;&nbsp;
        <FI width={15} height={10} />
      </>
    ),
    sv: (
      <>
        Svenska&nbsp;&nbsp;
        <SE width={15} height={10} />
      </>
    ),
  }

  const languageFlags = {
    en: <GB width={15} height={10} />,
    fi: <FI width={15} height={10} />,
    sv: <SE width={15} height={10} />,
  }

  return (
    <div
      className={`${css.cntr} ${isFlagsOnly ? css.small : ''} ${
        hasDarkBackground ? css.white : ''
      }`}
    >
      <FormControl fullWidth>
        {!isFlagsOnly && (
          <InputLabel
            id='language-select-label'
            sx={{
              width: 'fit-content',
              background: '#fff',
              paddingRight: '8px',
            }}
          >
            {t('common.label.language')}
          </InputLabel>
        )}
        <Select
          aria-label='Language'
          labelId='language-select-label'
          id='demo-simple-select'
          value={currentLanguage}
          label={isFlagsOnly ? '' : 'Language'}
          onChange={handleChangeLanguage}
          sx={{
            fontSize: '12px',
          }}
          renderValue={(value) =>
            isFlagsOnly ? languageFlags[value as Language] : languageLabels[value as Language]
          }
        >
          {displayedLanguages.map((l) => (
            <MenuItem
              key={l}
              value={l}
              sx={{ width: 130, ...(hasDarkBackground && { borderColor: '#fff' }) }}
              lang={l}
            >
              {languageLabels[l]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default LanguageSelect
