export const initChartOptions: Highcharts.Options = {
  chart: {
    polar: true,
    width: 630,
    height: 600,
  },

  accessibility: {
    description: 'Customer Feedback.',
  },

  legend: {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
  },

  plotOptions: {
    area: {
      fillOpacity: 0.1,
    },
    column: {
      pointPadding: 0,
      groupPadding: 0,
      opacity: 0.95,
    },
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 700,
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
          },
          pane: {
            size: '80%',
          },
        },
      },
    ],
  },
}
