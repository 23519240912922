/**
  * Types of timeframe setups a module may be using.
  *
  * M:              one month from current day
  * Q:              one quarter from current day
  * Y:              one year from current day
  * WTD:            week to date
  * MTD:            month to date
  * QTD:            quarter to date
  * YTD:            year to date
  * LM:             last/previous calendar month
  * LQ:             last/previous calendar quarter
  * LY:             last/previous calendar year
  
  * FIXED_WINDOW:   start + enddate move forward by one day every day
  * STATIC:         start + enddate are not updated
  * 
  * default (null): startdate is not updated, enddate moves forward by one every day
  */

export enum TimeFrame {
  M = 'm',
  Q = 'q',
  Y = 'y',
  WTD = 'wtd',
  MTD = 'mtd',
  QTD = 'qtd',
  YTD = 'ytd',
  LW = 'lw',
  LM = 'lm',
  LQ = 'lq',
  LY = 'ly',
  FIXED_WINDOW = 'fixed_window',
  STATIC = 'static',
}
