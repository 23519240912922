import React from 'react'
import { useOpenCategoryToolData } from './OpenCategoryToolProvider'
import css from './OpenCategoryToolModal.module.scss'
import OpenCategoryToolFilter from './OpenCategoryToolFilter'
import { tCommon, tKpi } from '../../../../../../languages/i18n'
import { DashboardCustomize } from '@mui/icons-material'

type Props = {
  isInfoOpen: boolean
}

const OpenCategoryToolHeader = ({ isInfoOpen }: Props) => {
  const [{ kpiName, openAnswer }] = useOpenCategoryToolData()
  return (
    <div className={`${css.category_tool_header} ${isInfoOpen ? '' : css.header_margin}`}>
      <h4>
        {tCommon('label.categoryTool')}
        <DashboardCustomize sx={{ fontSize: '34px', marginLeft: '5px' }} />
      </h4>
      <OpenCategoryToolFilter />
      {openAnswer && (
        <>
          <p>
            <b>KPI:</b> {tKpi(kpiName)}
          </p>
          <p>
            <b>{tCommon('label.openAnswer')}:</b> {openAnswer.answer}
          </p>
        </>
      )}
    </div>
  )
}

export default OpenCategoryToolHeader
