import { getTenantFromSessionStorage } from '../../../../../react-services/authService'
import { OpenAnswersCountByTopic, RadarDataDisplay, RadarDataSentiment } from '../radarTypes'
import { sentimentDataCount, sentimentDataPercentage } from './DataChangeOptions'

export const handleNewChartForAmount = (
  radarDataSentiment: RadarDataSentiment,
  radarDataDisplay: RadarDataDisplay,
  isAlphabetical: boolean,
  countsByTopic: OpenAnswersCountByTopic[],
  selectedTopics: string[],
): Highcharts.Options => {
  const tenantName = getTenantFromSessionStorage()
  const radarCategories = isAlphabetical
    ? selectedTopics.sort().map((topic) => topic[0].toUpperCase() + topic.slice(1))
    : selectedTopics.map((topic) => topic[0].toUpperCase() + topic.slice(1))

  const newSettings: Highcharts.Options = {
    series: [
      {
        type: 'area',
        name: tenantName ? tenantName : 'Company',
        data: handleHighchartsSeriesData(
          radarDataSentiment,
          radarDataDisplay,
          isAlphabetical,
          countsByTopic,
          selectedTopics,
        ),
        pointPlacement: 'on',
      },
    ],
    xAxis: {
      categories: radarCategories,
      tickmarkPlacement: 'on',
    },
    yAxis:
      radarDataDisplay === RadarDataDisplay.COUNT
        ? sentimentDataCount.yAxis
        : sentimentDataPercentage.yAxis,
    pane: {
      size: '100%',
      innerSize: 30,
    },
    tooltip:
      radarDataDisplay === RadarDataDisplay.COUNT
        ? sentimentDataCount.tooltip
        : sentimentDataPercentage.tooltip,
    colors:
      radarDataSentiment === RadarDataSentiment.POSITIVE
        ? ['#8ac926', '#b6b7ba']
        : radarDataSentiment === RadarDataSentiment.NEGATIVE
        ? ['#ff595e', '#b6b7ba']
        : ['#4287f5', '#b6b7ba'],
  }

  return newSettings
}

const handleHighchartsSeriesData = (
  radarDataSentiment: RadarDataSentiment,
  radarDataDisplay: RadarDataDisplay,
  isAlphabetical: boolean,
  countsByTopic: OpenAnswersCountByTopic[],
  selectedTopics: string[],
): number[] => {
  if (!radarDataSentiment) return []

  const numberData: number[] = []
  const topics = isAlphabetical ? selectedTopics.sort() : selectedTopics

  topics &&
    topics.forEach((topic) => {
      const matchTopic = countsByTopic.find((countTopic) => countTopic.topic === topic)

      if (!matchTopic) return

      if (radarDataDisplay === RadarDataDisplay.COUNT) {
        switch (radarDataSentiment) {
          case RadarDataSentiment.POSITIVE:
            numberData.push(matchTopic.positiveCount)
            break
          case RadarDataSentiment.NEGATIVE:
            numberData.push(matchTopic.negativeCount)
            break
          case RadarDataSentiment.NEUTRAL:
            numberData.push(matchTopic.neutralCount)
            break
          default:
            numberData.push(0)
            break
        }
      }

      if (radarDataDisplay === RadarDataDisplay.PERCENTAGE) {
        const allAnswersCount =
          matchTopic.negativeCount +
          matchTopic.neutralCount +
          matchTopic.positiveCount +
          matchTopic.uncategorizedCount
        let percentValue = 0

        switch (radarDataSentiment) {
          case RadarDataSentiment.POSITIVE:
            percentValue = (100 * matchTopic.positiveCount) / allAnswersCount
            numberData.push(isNaN(percentValue) ? 0 : Math.trunc(percentValue))
            break
          case RadarDataSentiment.NEGATIVE:
            percentValue = (100 * matchTopic.negativeCount) / allAnswersCount
            numberData.push(isNaN(percentValue) ? 0 : Math.trunc(percentValue))
            break
          case RadarDataSentiment.NEUTRAL:
            percentValue = (100 * matchTopic.neutralCount) / allAnswersCount
            numberData.push(isNaN(percentValue) ? 0 : Math.trunc(percentValue))
            break
          default:
            numberData.push(0)
            break
        }
      }
    })

  return numberData
}
