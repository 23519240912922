import React from 'react'
import { ListSubheader, Tooltip } from '@mui/material'
import { allChartColors } from '../../../../../../../../styles/variableExport'
import { pSBC } from '../../../../../../../react-services/colorService'
import Icon, { IconName } from '../../../../../../../utilities/Icon'
import { tCommon, tData } from '../../../../../../../../languages/i18n'

const muiHeaderStyles = {
  borderBottom: `1px solid ${allChartColors.GREY}`,
  backgroundColor: pSBC(0.9, allChartColors.GREY),
  paddingLeft: '12px',
  paddingTop: '12px',
  paddingBottom: '0px',
  lineHeight: '26px',
  marginBottom: '6px',
}

type CurrentSelectedMetaKeyProps = {
  metaKey: string
  handleDeselectValues: (values: string[], key: string) => void
  handleSelectActiveMeta: (key: string) => void
  isDisabled: boolean
}
const CurrentSelectedMetaKey = ({
  metaKey,
  handleDeselectValues,
  handleSelectActiveMeta,
  isDisabled,
}: CurrentSelectedMetaKeyProps) => {
  return (
    <ListSubheader sx={muiHeaderStyles}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: '4px' }}>{tData(metaKey)}</div>
        <div style={{ padding: '0 0 0 5px', display: 'flex', cursor: 'pointer' }}>
          <Tooltip title={tCommon('info.editMetaSelection')}>
            <div onClick={() => handleSelectActiveMeta(metaKey)}>
              <Icon name={IconName.EDIT} color={allChartColors.GREY} width={18} height={18} />
            </div>
          </Tooltip>
          {!isDisabled && (
            <Tooltip title={tCommon('info.removeAllSelected')}>
              <div
                onClick={() => handleDeselectValues([], metaKey)}
                data-testid='removeAllSelected'
              >
                <Icon name={IconName.CLOSE} color={allChartColors.GREY} width={18} height={18} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </ListSubheader>
  )
}

export default CurrentSelectedMetaKey
