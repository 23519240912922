import React, { useEffect, useState } from 'react'
import InfoIcon from './InfoIcon'
import CustomTooltip, { Directions } from '../../../../../_shared/Infos/CustomTooltip'

type TextWithInfoProps = {
  text: string
  tooltipDirection: Directions
  info: string
}

const TextWithInfo = ({ text, tooltipDirection, info }: TextWithInfoProps) => {
  const [active, setActive] = useState<boolean>(false)
  const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => setTimeoutHandler(null)
  }, [])

  const handleMouseEnterInfo = () => {
    setTimeoutHandler(
      setTimeout(() => {
        setActive(true)
      }, 500),
    )
  }
  const handleMouseLeaveInfo = () => {
    setActive(false)
    if (timeoutHandler) clearTimeout(timeoutHandler)
  }

  return (
    <div
      onMouseEnter={() => handleMouseEnterInfo()}
      onMouseLeave={handleMouseLeaveInfo}
      style={{ display: 'inline' }}
    >
      {text}
      <InfoIcon />
      {active && <CustomTooltip content={info} direction={tooltipDirection} />}
    </div>
  )
}

export default TextWithInfo
