import React, { useState, memo } from 'react'
import OpenCategorySelector from './OpenCategorySelector'
import { cloneDeep, isPlainObject, upperFirst } from 'lodash'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'
import { useDeepCompareEffect } from 'react-use'
import { TextualKpiData } from './openTypes'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import { isCategoryChangingAllowed } from '../../../../react-services/openAnswersService'
import { Dialog } from '@mui/material'
import { useTranslation } from 'react-i18next'

type CategoryColumnProps = {
  answer: TextualKpiData
  type: string
  topics?: string[] | null
  customCategory?: string | null
  customCategoryValues?: string[] | null
  onChangeCategories: (isCategorySelectorOpen: boolean) => void
}

const CategoryColumn = memo(function CategoryColumn({
  answer,
  type,
  topics = [],
  customCategory = null,
  customCategoryValues = null,
  onChangeCategories,
}: CategoryColumnProps) {
  const [isCategorySelectorOpen, setCategorySelectorOpenness] = useState(false)
  const [modifiableAnswer, updateAnswer] = useState<TextualKpiData>()
  const { config: cdbs } = useCommonDbSettingsConfig()
  const { t } = useTranslation()
  useDeepCompareEffect(() => {
    updateAnswer(answer)
  }, [answer])

  const CATEGORY_KEY = customCategory ? customCategory : type

  function hasCategoriesOfType(categories: { [category: string]: string[] }, type: string) {
    return categories && categories[type] && categories[type].length > 0
  }

  function handleUpdateAnswer(type: string, selections: string[]) {
    if (modifiableAnswer) {
      const modifiedAnswer = cloneDeep(modifiableAnswer)
      if (!modifiedAnswer.category || !isPlainObject(modifiedAnswer.category)) {
        modifiedAnswer.category = {}
      }
      modifiedAnswer.category[type] = selections
      updateAnswer(modifiedAnswer)
    }
  }

  function handleCategorySelectorOpenness(isOpen: boolean) {
    trackingService.track(
      isOpen ? TrackingEvent.OpenCategoryPopup : TrackingEvent.CloseCategoryPopup,
      { type },
    )
    setCategorySelectorOpenness(isOpen)
    onChangeCategories(isOpen)
  }

  function handleCategoryClick() {
    if (isCategoryChangingAllowed(cdbs)) {
      handleCategorySelectorOpenness(!isCategorySelectorOpen)
    }
  }

  const categories = (modifiableAnswer?.category?.[CATEGORY_KEY] || []).map((c) =>
    upperFirst(t(c.toLocaleLowerCase(), { ns: 'categories' })),
  )
  return (
    <>
      <div
        data-cy={`${CATEGORY_KEY}Category-${answer.answer_id}`}
        className='tc open-module-category-column'
        onClick={handleCategoryClick}
      >
        <div
          className={`open-module-categories-container ${
            isCategoryChangingAllowed(cdbs) ? 'open-module-categories-changeable' : ''
          }`}
        >
          <span data-testid={`${CATEGORY_KEY}CategoryText-${answer.answer_id}`}>
            {modifiableAnswer &&
            modifiableAnswer.category &&
            hasCategoriesOfType(modifiableAnswer.category, CATEGORY_KEY) ? (
              <span>{categories.map((c) => (!c ? 'empty' : c)).join(', ')}</span>
            ) : (
              <span className='grey-text'>
                <i>N/A</i>
              </span>
            )}
          </span>
        </div>
      </div>
      {isCategorySelectorOpen && !!modifiableAnswer && (
        <Dialog
          open={isCategorySelectorOpen}
          onClose={() => setCategorySelectorOpenness(false)}
          sx={{ top: '-35vh' }}
        >
          <OpenCategorySelector
            answer={modifiableAnswer}
            topics={topics || []}
            type={type}
            customCategory={CATEGORY_KEY}
            customCategoryValues={customCategoryValues || []}
            onClose={() => handleCategorySelectorOpenness(false)}
            onClickOutside={() => handleCategorySelectorOpenness(false)}
            onUpdateCategory={handleUpdateAnswer}
          />
        </Dialog>
      )}
    </>
  )
})

export default CategoryColumn
