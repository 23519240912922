import React from 'react'

type NoModuleFoundProps = {
  moduleType: string
  [key: string]: unknown
}
const NoModuleFound = ({ moduleType }: NoModuleFoundProps) => {
  return <div>No module found with type: {moduleType}</div>
}

export default NoModuleFound
