import React, { useEffect, useState } from 'react'
import trackingService from '../../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../../react-constants'
import './ValueInspector.scss'
import { WhereMeta } from '../../../../../../types'
import NumericInspector from './NumericInspector'
import { isIdAndNameObjectArray } from '../../../../../utilities'

type ValueInspectorProps = {
  valueType: 'numeric'
  inspectorId?: string
  inspected: string
  kpis: number[] | { id: number; name: string }[]
  filters?: WhereMeta
  startDate?: string
  endDate?: string
  onClose: () => void
  isPreviewMode?: boolean
}

const ValueInspector = ({
  valueType,
  inspected,
  kpis,
  filters,
  startDate,
  endDate,
  onClose,
  isPreviewMode = false,
}: ValueInspectorProps) => {
  const [isReady, setIsReady] = useState<boolean>(false)
  const [exportFilters, setExportFilters] = useState<WhereMeta | null>(null)

  const hasQuery = !!startDate && !!endDate && !!kpis && !!exportFilters

  useEffect(() => {
    if (valueType && valueType.toLocaleLowerCase() === 'numeric' && filters) {
      setExportFilters(filters)
    }
  }, [filters])

  useEffect(() => {
    trackingService.track(TrackingEvent.OpenValueInspector, {
      type: valueType,
    })
    if (valueType === 'numeric') setReadiness()
    else setReadiness()
  }, [])

  function setReadiness() {
    setIsReady(true)
  }

  // function searchForRelevantMetadata(data: TextualKpiData[]) {
  //   const allRows = []
  //   for (const index in data) {
  //     if (typeof inspected === 'string') {
  //       const strRegExPattern = '[a-zA-Z\\|]*' + inspected.toLocaleLowerCase() + '[\\|a-zA-Z]*'
  //       if (data[index].answer.toLowerCase().match(strRegExPattern)) {
  //         const answer = {
  //           ...data[index],
  //           metadata: cleanUpMetadata(data[index].metadata),
  //         }
  //         allRows.push(answer)
  //       }
  //     }
  //   }
  //   setAnswers(allRows)
  //   setOriginalAnswers(allRows)
  //   setIsReady(true)
  // }

  return (
    <>
      {isReady && valueType === 'numeric' && hasQuery && (
        <NumericInspector
          query={{
            start_date: startDate,
            end_date: endDate,
            kpis: isIdAndNameObjectArray(kpis) ? kpis.map((kpi) => kpi.id) : kpis,
            filter: exportFilters || {},
          }}
          onClose={onClose}
          inspected={inspected}
          isPreviewMode={isPreviewMode}
        />
      )}
    </>
  )
}

export default ValueInspector
