import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import NumberTrend from './NumberTrend'
import { DataTypes, NumberTrendModule, SharedFilter } from './numbertrendTypes'
import useNumberTrendQueryFormat from './useNumberTrendQueryFormat'
import useNumericKpiCounts from '../../../../stores/useNumericKpiCounts'
import { GenericConfig, KpiData, Query } from '../../../../../types'
import useNumericKpis, { NumericKpi } from '../../../../stores/useNumericKpis'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'
import NoData from '../../../_shared/Infos/NoData'
import { ToastProvider, useToastId } from '../../../common/Notification/NotificationContext'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import { errorLoadingModule } from './NumberTrendNotifications'
import { CSSCONSTANTS } from '../../../../react-constants/styles'
import { toast } from 'react-toastify'
import ValueInspector from '../_shared/ValueInspector/ValueInspector'
import { useRenderActions } from '../Group/contexts/RenderContext'
import {
  isAllowedToFetch,
  isFetchingData,
  isWaitingForFetching,
} from '../Group/contexts/renderListReducer'
import FadeIn from '../_shared/FadeIn'
import { CircularProgress } from '@mui/material'
import { isEqual, isNull } from 'lodash'
import { QueueStatus } from '../Group/groupModuleTypes'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'

import css from './NumberTrendModuleCntr.module.scss'

type Point = KpiData.NestedNumericKpiDataPoint

type NumberTrendModuleCntrProps = {
  id: string
  sharedFilter?: SharedFilter
  module: NumberTrendModule
  saveModule: (module: NumberTrendModule) => void
  isReportMode: boolean | undefined
  isScreenMode: boolean | undefined
  moduleStatus: QueueStatus | undefined
}

const NumberTrendModuleUnWrappedCntr = ({
  id,
  sharedFilter,
  module,
  saveModule,
  isReportMode,
  isScreenMode,
  moduleStatus,
}: NumberTrendModuleCntrProps) => {
  const isReportOrScreen = !!isReportMode || !!isScreenMode
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [normalQuery, setNormalQuery] = useState<Query.SingleNumeric | null>(null)
  const { updateModuleToIdle, requestToFetch } = useRenderActions()
  const { selections, comparisonmode, title, imageurl, options, query, yAxis } = module
  const readyTimeoutRef = useRef<NodeJS.Timeout>()
  const [ready, setReady] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInspectorOpen, setIsInspectorOpen] = useState<boolean>(false)
  const { toastifyId } = useToastId()
  const toastCntr = { containerId: toastifyId }

  const {
    createQueryFromNormalQuery,
    createQueryFromSharedFilter,
    createTrendQueryFromNormalQuery,
  } = useNumberTrendQueryFormat()

  const {
    originalCalculatedKpis,
    isLoading: isLoadingCalculatedKpis,
    error: errorCalculatedKpis,
  } = useCalculatedKpis()
  useEffect(() => toastError(errorCalculatedKpis), [errorCalculatedKpis])

  const {
    config: commonDbSettings,
    isLoading: isLoadingCommonDbSettings,
    error: errorCommonDbSettings,
  } = useCommonDbSettingsConfig()
  useEffect(() => toastError(errorCommonDbSettings), [errorCommonDbSettings])

  const { numericKpis, isLoading: isLoadingNumericKpis, error: errorNumericKpis } = useNumericKpis()
  useEffect(() => toastError(errorNumericKpis), [errorNumericKpis])

  const isLoadingConfigs =
    isLoadingCommonDbSettings || isLoadingNumericKpis || isLoadingCalculatedKpis
  const selectedKpi: NumericKpi | null = useMemo(() => {
    if (numericKpis && selections && Object.keys(selections).length) {
      const kpiIdentifier = Object.keys(selections)[0]
      const parsedKpiIndentifier = Number(kpiIdentifier)
      if (!isNaN(parsedKpiIndentifier) && numericKpis) {
        return numericKpis.find((kpi) => kpi.id === parsedKpiIndentifier) || null
      }
    }
    return null
  }, [selections, numericKpis])

  const selectedCalculatedKpi: GenericConfig.CalculatedKpis | null = useMemo(() => {
    if (originalCalculatedKpis && selections && Object.keys(selections).length) {
      const kpiIdentifier = Object.keys(selections)[0]
      const parsedKpiIndentifier = Number(kpiIdentifier)
      if (isNaN(parsedKpiIndentifier) && originalCalculatedKpis) {
        return {
          [kpiIdentifier]: originalCalculatedKpis[kpiIdentifier],
        }
      }
    }
    return null
  }, [selections, originalCalculatedKpis])

  useEffect(() => {
    let newNormalQuery: Query.SingleNumeric | null = null
    if (isLoadingConfigs) return
    if (
      sharedFilter &&
      sharedFilter.filters &&
      typeof sharedFilter.filters === 'object' &&
      (selectedKpi || selectedCalculatedKpi)
    ) {
      newNormalQuery = createQueryFromSharedFilter({
        sharedFilter,
        kpis: selectedKpi ? [selectedKpi] : [],
        calculatedKpis: selectedCalculatedKpi ? selectedCalculatedKpi : {},
      })
    }
    if (query && typeof query === 'object' && Object.keys(query).length) {
      newNormalQuery = createQueryFromNormalQuery({
        query,
        kpis: !isNull(selectedKpi) ? [selectedKpi] : [],
        calculatedKpis: !isNull(selectedCalculatedKpi) ? selectedCalculatedKpi : {},
      })
    }

    if (isEqual(normalQuery, newNormalQuery)) return
    if (id) handleRequestingToFetch(id)
    if (isAllowedToFetch(moduleStatus)) setNormalQuery(newNormalQuery)
  }, [
    moduleStatus,
    sharedFilter?.filters,
    selectedKpi,
    selectedCalculatedKpi,
    query?.where_meta,
    query?.start_date,
    query?.end_date,
    isLoadingConfigs,
    !!comparisonmode,
    !!options?.hideTrendArrow,
  ])

  const handleRequestingToFetch = (moduleId: string) => {
    requestToFetch(moduleId)
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
  }

  const trendQuery = useMemo(() => {
    let newTrendQuery: Query.SingleNumeric | null = null
    if (
      !commonDbSettings?.disable_trend_query &&
      !(options?.hideTrendArrow && isReportOrScreen) &&
      normalQuery
    ) {
      newTrendQuery = createTrendQueryFromNormalQuery(normalQuery)
    }
    return newTrendQuery
  }, [normalQuery])

  useEffect(() => {
    handleSettingTitleIfEmpty()
  }, [module.title, selectedCalculatedKpi, selectedKpi, moduleStatus])

  const {
    numericCounts: counts,
    isLoading: isLoadingCounts,
    isRefetching: isRefetchingCounts,
    error: countsError,
  } = useNumericKpiCounts(normalQuery, 0)
  useEffect(() => toastError(countsError), [countsError])

  const DONE_STATUS_TIMEOUT = 200
  useEffect(() => {
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
    const isDoneFetching = !isLoadingCounts && !isRefetchingCounts
    if (isFetchingData(moduleStatus) && isDoneFetching) {
      doneStatusTimeoutRef.current = setTimeout(() => {
        updateModuleToIdle(id)
      }, DONE_STATUS_TIMEOUT)
    }
    return () => {
      doneStatusTimeoutRef.current && clearTimeout(doneStatusTimeoutRef.current)
    }
  }, [moduleStatus, isLoadingCounts, isRefetchingCounts])

  const {
    numericCounts: trendCounts,
    isLoading: isLoadingTrends,
    isRefetching: isRefetchingTrendCounts,
    error: trendCountsError,
  } = useNumericKpiCounts(
    trendQuery,
    isReportOrScreen ? 0 : !comparisonmode && !counts ? 3000 : 500,
  )
  useEffect(() => toastError(trendCountsError), [trendCountsError])

  const selectedFormat = yAxis ? yAxis : DataTypes.AVERAGE
  const decimals = useMemo(() => {
    return typeof commonDbSettings?.decimals === 'number' ? commonDbSettings.decimals : 1
  }, [commonDbSettings])

  let displayedNumber: number | null = null
  let displayedSecondNumber: number | null = null
  let displayedTrend: number | null = null
  let displayedComparison = 'N/A'
  if (
    counts &&
    counts.series &&
    counts.series[0] &&
    counts.series[0].data[0] &&
    counts.series[0].data[0].data &&
    counts.series[0].data[0].data[selectedFormat as keyof Point] !== null &&
    counts.series[0].data[0].data[selectedFormat as keyof Point] !== undefined &&
    counts.series[0].data[0].data['n' as keyof Point]
  ) {
    const newNumber = counts.series[0].data[0].data[selectedFormat as keyof Point]
    const secondary = options?.secondaryNumber || DataTypes.COUNT
    const newSecondNumber = counts.series[0].data[0].data[secondary as keyof Point]
    displayedNumber =
      typeof newNumber === 'number' ? Math.round(newNumber * 10 ** decimals) / 10 ** decimals : null
    displayedSecondNumber =
      typeof newSecondNumber === 'number'
        ? Math.round(newSecondNumber * 10 ** decimals) / 10 ** decimals
        : null
  }
  if (
    trendCounts &&
    trendCounts.series &&
    trendCounts.series[0] &&
    trendCounts.series[0].data[0] &&
    trendCounts.series[0].data[0].data &&
    trendCounts.series[0].data[0].data[selectedFormat as keyof Point] !== null &&
    trendCounts.series[0].data[0].data[selectedFormat as keyof Point] !== undefined
  ) {
    const newTrend = trendCounts.series[0].data[0].data[selectedFormat as keyof Point]

    displayedTrend =
      typeof newTrend === 'number' ? Math.round(newTrend * 10 ** decimals) / 10 ** decimals : null
  }
  if (displayedNumber !== null && displayedTrend !== null) {
    if (selectedFormat === DataTypes.AVERAGE || selectedFormat === DataTypes.SUM) {
      const difference = displayedNumber - displayedTrend
      const newDecimals = selectedFormat !== DataTypes.AVERAGE ? 0 : decimals
      displayedComparison =
        difference > 0 ? `+${difference.toFixed(newDecimals)}` : difference.toFixed(newDecimals)
    } else {
      displayedComparison = `${((displayedNumber / displayedTrend - 1) * 100)
        .toFixed(decimals)
        .toString()}%`
    }
  }

  useEffect(() => {
    setIsLoading(
      (isWaitingForFetching(moduleStatus) && !counts) ||
        isLoadingConfigs ||
        isLoadingCounts ||
        (comparisonmode && isLoadingTrends) ||
        (isReportOrScreen && isLoadingTrends) ||
        (!normalQuery?.start_date &&
          !normalQuery?.end_date &&
          !normalQuery?.kpis?.length &&
          !counts),
    )
  }, [
    moduleStatus,
    isLoadingConfigs,
    isLoadingCounts,
    comparisonmode,
    isLoadingTrends,
    isReportOrScreen,
    isLoadingTrends,
    normalQuery,
    counts,
  ])

  useEffect(() => {
    if (readyTimeoutRef.current) clearTimeout(readyTimeoutRef.current)
    if (!isLoading && isFetchingData(moduleStatus)) {
      readyTimeoutRef.current = setTimeout(() => {
        setReady(CSSCONSTANTS.CLASS_MODULE_DONE_LOADING)
      }, 3000) // To make sure everything has rendered
    }
    return () => readyTimeoutRef.current && clearTimeout(readyTimeoutRef.current)
  }, [isLoading, trendCounts, trendQuery, moduleStatus, counts])

  const handleOpenInspector = useCallback(() => setIsInspectorOpen(true), [])
  const handleCloseInspector = useCallback(() => setIsInspectorOpen(false), [])

  const toastError = (e: string) => {
    if (e) toast.error(e, toastCntr)
  }

  const handleSettingTitleIfEmpty = () => {
    if (module.title) return
    if (!isAllowedToFetch(moduleStatus)) return
    if (selectedKpi) saveModuleProperty({ title: selectedKpi.name })
    if (selectedCalculatedKpi) saveModuleProperty({ title: Object.keys(selectedCalculatedKpi)[0] })
  }

  const saveModuleProperty = (newProperty: Record<string, unknown>) => {
    const newModule = { ...module, ...newProperty }
    saveModule(newModule)
  }

  const isRefetching = useMemo(() => {
    return (
      !!counts &&
      (isWaitingForFetching(moduleStatus) || isRefetchingTrendCounts || isRefetchingCounts)
    )
  }, [isRefetchingCounts, counts, moduleStatus, isRefetchingTrendCounts])

  if (isLoading) {
    return (
      <div className={css.cntr}>
        <div className={css.loading}>
          <CircularProgress thickness={1} size={'5rem'} style={{ opacity: 0.7 }} />
        </div>
      </div>
    )
  }
  if (!isLoading && displayedNumber === null) {
    return (
      <div className={`${css.cntr} ${ready} numbertrend-module-cntr`}>
        <NoData />
      </div>
    )
  }
  return (
    <div className={`${css.cntr} ${ready} numbertrend-module-cntr`}>
      {isInspectorOpen && !!normalQuery && (
        <ValueInspector
          kpis={normalQuery.kpis}
          inspected={title || ''}
          valueType='numeric'
          onClose={handleCloseInspector}
          startDate={normalQuery.start_date}
          endDate={normalQuery.end_date}
          filters={normalQuery.where_meta}
          inspectorId={toastifyId}
        />
      )}
      <FadeIn>
        <NumberTrend
          displayedNumber={
            typeof displayedNumber === 'number' && selectedFormat !== DataTypes.AVERAGE
              ? Math.round(displayedNumber)
              : displayedNumber
          }
          displayedSecondNumber={
            typeof displayedSecondNumber === 'number' &&
            options?.secondaryNumber !== DataTypes.AVERAGE
              ? Math.round(displayedSecondNumber)
              : displayedSecondNumber
          }
          displayedTrend={displayedTrend}
          displayedComparison={displayedComparison}
          imageurl={imageurl}
          options={options}
          comparisonmode={comparisonmode}
          primaryFormat={selectedFormat}
          saveModuleProperty={saveModuleProperty}
          decimals={decimals}
          isReportOrScreen={isReportOrScreen}
          handleOpenInspector={handleOpenInspector}
        />
      </FadeIn>
      {isRefetching && (
        <div style={{ position: 'absolute', top: 0, right: 20 }}>
          <CircularProgress thickness={2} size={'2rem'} style={{ opacity: 0.3 }} />
        </div>
      )}
    </div>
  )
}

const NumberTrendModuleCntr = memo((props: NumberTrendModuleCntrProps) => (
  <ToastProvider id={props.id}>
    <NotificationContainer id={props.id} />
    <ErrorBoundary
      message={errorLoadingModule}
      containerId={props.id}
      fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
    >
      <NumberTrendModuleUnWrappedCntr {...props} />
    </ErrorBoundary>
  </ToastProvider>
))

NumberTrendModuleCntr.displayName = 'NumberTrendModuleCntr'
export default NumberTrendModuleCntr
