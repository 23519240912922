import React from 'react'
import { allChartColors } from '../../../../../../../../styles/variableExport'
import Icon, { IconName } from '../../../../../../../utilities/Icon'
import { ListItemButton, SxProps, Theme, Tooltip } from '@mui/material'
import { pSBC } from '../../../../../../../react-services/colorService'

import css from './CurrentSelectedMetaValue.module.scss'
import { tCommon } from '../../../../../../../../languages/i18n'

const muiButtonStyles: SxProps<Theme> = {
  padding: '0',
  textWrap: 'wrap',
  wordWrap: 'break-word',
  minHeight: '30px',
  backgroundColor: pSBC(0.9, allChartColors.GREY),
  width: 'fit-content',
  display: 'inline-block',
  margin: '2px 0 2px 10px',
}
type CurrentSelectedMetaValueProps = {
  metaValue: string
  metaValues: string[]
  metaKey: string
  onClick?: (metaValues: string[], metaKey: string) => void
  isDisabled?: boolean
}
const CurrentSelectedMetaValue = ({
  metaValue,
  metaValues,
  metaKey,
  onClick = () => {},
  isDisabled = false,
}: CurrentSelectedMetaValueProps) => {
  return (
    <Tooltip title={tCommon('info.clickToRemove')} leaveDelay={0} enterDelay={1000}>
      <ListItemButton
        key={metaValue}
        id={metaValue}
        role='listitem'
        sx={muiButtonStyles}
        data-testid='currentMetaValue'
        disabled={isDisabled}
        onClick={() =>
          onClick(
            metaValues.filter((v) => v !== metaValue),
            metaKey,
          )
        }
      >
        <div className={css.cntr}>
          <div className={css.label}>{metaValue}</div>
          <Icon name={IconName.CLOSE} color={allChartColors.GREY} width={10} height={10} />
        </div>
      </ListItemButton>
    </Tooltip>
  )
}

export default CurrentSelectedMetaValue
