import React from 'react';
import CloseIcon from '../../../../common/Buttons/CloseIcon';
import InProgressIcon from '../../../../common/Buttons/InProgressIcon';
import OpenIcon from '../../../../common/Buttons/OpenIcon';
import { FeedbackAlert } from '../alertTypes';
import { useAlertData } from '../FeedbackAlertsTableCntr';

import css from './AlertCard.module.scss';

type AlertCardProps = {
  alert: FeedbackAlert,
  setNewStateAndAlert: React.Dispatch<React.SetStateAction<{
    state: string;
    alert:FeedbackAlert;
  } | null>>
}

const AlertCard = ({ alert, setNewStateAndAlert }: AlertCardProps) => {
  const [{ currentAlertId }, setAlertData] = useAlertData();

  const handleClickCard = () => {
    setAlertData(prev => ({ ...prev, currentAlertId: alert.id }))
  }

  const getTriggerKpiAnswer = () => {
    if (alert.kpis) {
      return Object.entries(alert.kpis).filter(([key]) => key === alert.question).reduce((acc, curr) => acc + curr[1], '');
    }
    return '';
  }

  const getStateClassName = () => {
    if (alert.state === 'OPEN') return css.open
    if (alert.state === 'IN_PROGRESS') return css.in_progress
    if (alert.state === 'CLOSED') return css.closed
  }

  return (
    <div onClick={handleClickCard} className={`${css.cntr} ${getStateClassName()}`} data-testid="related-alert-card">
      <div className={css.content_left}>
        <span><b>{alert.subject}</b></span>
        <div className={css.info}>
          <span><b>Trigger kpi:</b> {alert.question} &rarr; {getTriggerKpiAnswer()}</span>
          <span><b>State: </b>{alert.state === 'IN_PROGRESS' ? 'IN PROGRESS' : alert.state}</span>
          <span title={alert.recipients?.toString()}>
            <b>Responsible: </b>{alert.recipients?.map((email, i, arr) => arr.length - 1 === i ? <span key={email}>{email}</span> : <span key={email}>{email}, </span>)}
          </span>
          <span>{alert.comment && `Comment: ${alert.comment}`}</span>
          <span>{alert.started_time && `Started: ${new Date(alert.started_time).toLocaleDateString()}`}</span>
          <span>{alert.closed_time && `Closed: ${new Date(alert.closed_time).toLocaleDateString()}`}</span>
        </div>
      </div>
      <div className={css.content_right}>
        <div className={css.active}>
          
          {alert.id === currentAlertId && <div>CURRENT</div>}
        </div>
        <div className={css.state_change}>

          <div>
            {alert.state !== 'OPEN' && <OpenIcon onClick={() => setNewStateAndAlert({ state: 'OPEN', alert })} />}
            {alert.state !== 'IN_PROGRESS' && <InProgressIcon onClick={() => setNewStateAndAlert({ state: 'IN_PROGRESS', alert })} />}
            {alert.state !== 'CLOSED' && <CloseIcon onClick={() => setNewStateAndAlert({ state: 'CLOSED', alert })} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertCard