import React from 'react'
import GenericButton from '../../../../common/Buttons/GenericButton'
import css from './ButtonConversationToggle.module.scss'

type ButtonConversationToggleProps = {
  onClick: () => void
}

const ButtonConversationToggle = ({ onClick }: ButtonConversationToggleProps) => {
  return (
    <GenericButton
      onClick={onClick}
      icon={'chat'}
      text=''
      customClasses={[css.btn]}
      iconPosition={'center'}
      data-testid='conversation-button'
      title='Open conversation dialog'
    />
  )
}

export default ButtonConversationToggle
