import {
  errorFetchingCommonDbSettingsConfig,
  errorGettingDashboardConfig,
  errorGettingDashboardSettingsConfig,
  errorGettingDbTemplateConfig,
  errorGettingReportFilters,
  errorGettingTranslationsConfig,
  errorInvalidCommonDbSettingsObject,
  errorInvalidDashboardConfig,
  errorInvalidDashboardSettingsConfig,
  errorInvalidDbTemplateConfig,
  errorInvalidReportingFilters,
  errorInvalidTranslationsConfig,
  errorPostingDashboardSettingsConfig,
  errorUpdatingCommonDbSettingsConfig,
  errorUpdatingDbTemplateConfig,
  errorUpdatingTranslationsConfig,
} from '../stores/notificationMessages'
import { cloneDeep, isObject } from 'lodash'
import { get, post, put } from './apiService'
import { DbTemplate } from '../stores/useDbTemplateConfig'
import { DbSettings } from '../stores/useDbSettingsConfig'
import { isCommonDbSettingsObject, isReportingFiltersObject } from '../utilities'
import { BLACKLIST } from '../react-constants/metadata'
import { DashboardSettingsConfig } from '../stores/useDashboardSettingsConfig'
import { EMPTY_DASHBOARD } from '../react-constants/dashboards'
import { Translations } from '../stores/useTranslationsConfig'
import { GenericConfig } from '../../types'

function getDbSettingsConfig() {
  try {
    // TODO: real type guard
    return get('GET_DBSETTINGS')
      .then((res: DbSettings) => {
        if (isObject(res) || res === null) {
          return res
        } else {
          throw Error(errorInvalidDashboardConfig)
        }
      })
      .catch((e) =>
        e.message ? Promise.reject(e.message) : Promise.reject(errorGettingDashboardConfig),
      )
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingDashboardConfig)
  }
}

function getDbTemplateConfig() {
  try {
    return get('GET_DBTEMPLATE')
      .then((res: DbTemplate) => {
        // TODO: real type guard
        if (isObject(res) || res === null) {
          return res
        } else {
          throw Error(errorInvalidDbTemplateConfig)
        }
      })
      .catch(() => Promise.reject(errorGettingDbTemplateConfig))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingDbTemplateConfig)
  }
}
function putDbTemplateConfig(payload: { value: Partial<DbTemplate> }): Promise<DbTemplate> {
  try {
    return put('PUT_DBTEMPLATE', payload)
      .then((res: DbTemplate) => {
        // TODO: real type guard
        if (isObject(res) || res === null) {
          return res
        } else {
          throw Error(errorInvalidDbTemplateConfig)
        }
      })
      .catch(() => Promise.reject(errorUpdatingDbTemplateConfig))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorUpdatingDbTemplateConfig)
  }
}

function getDashboardSettingsConfig() {
  try {
    return get('GET_DASHBOARD_SETTINGS')
      .then((res: DashboardSettingsConfig) => {
        // TODO: real type guard
        const config = res || EMPTY_DASHBOARD
        if (isObject(config)) {
          return config
        } else {
          throw Error(errorInvalidDashboardSettingsConfig)
        }
      })
      .catch(() => Promise.reject(errorGettingDashboardSettingsConfig))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingDashboardSettingsConfig)
  }
}

function postDashboardSettingsConfig(payload: DashboardSettingsConfig): Promise<unknown> {
  return post('GET_DASHBOARD_SETTINGS', payload).catch(() =>
    Promise.reject(errorPostingDashboardSettingsConfig),
  )
}

function getCommonDbSettingsConfig() {
  try {
    return get('GET_COMMONDBSETTINGS')
      .then((res: unknown) => {
        if (isCommonDbSettingsObject(res)) {
          return res
        } else {
          throw Error(errorInvalidCommonDbSettingsObject)
        }
      })
      .catch(() => Promise.reject(errorFetchingCommonDbSettingsConfig))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorFetchingCommonDbSettingsConfig)
  }
}

function putCommonDbSettingsConfig(payload: {
  value: Partial<GenericConfig.CommonDbSettings>
}): Promise<GenericConfig.CommonDbSettings> {
  try {
    return put('PUT_COMMONDBSETTINGS', payload)
      .then((res: unknown) => {
        if (isCommonDbSettingsObject(res)) {
          return res
        } else {
          throw Error(errorInvalidCommonDbSettingsObject)
        }
      })
      .catch(() => Promise.reject(errorUpdatingCommonDbSettingsConfig))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorUpdatingCommonDbSettingsConfig)
  }
}

function getReportingFilters() {
  try {
    return get('GET_REPORTINGFILTERS')
      .then((res: unknown) => {
        if (isReportingFiltersObject(res)) {
          const filters = res?.filters || null
          if (!filters) return filters
          const filteredFilters = cloneDeep(filters)
          for (let i = 0; i < BLACKLIST.length; i++) {
            delete filteredFilters[BLACKLIST[i]]
          }
          return filteredFilters
        } else {
          throw new Error(errorInvalidReportingFilters)
        }
      })
      .catch((e) => Promise.reject(typeof e === 'string' ? e : errorGettingReportFilters))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingReportFilters)
  }
}

function getTranslationsConfig() {
  try {
    return get('GET_TRANSLATIONS')
      .then((res: Translations) => {
        // TODO: real type guard
        if (isObject(res) || res === null) {
          return res
        } else {
          throw Error(errorInvalidTranslationsConfig)
        }
      })
      .catch(() => Promise.reject(errorGettingTranslationsConfig))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorGettingTranslationsConfig)
  }
}
function putTranslationsConfig(payload: { value: Partial<Translations> }): Promise<Translations> {
  try {
    return put('PUT_TRANSLATIONS', payload)
      .then((res: Translations) => {
        // TODO: real type guard
        if (isObject(res) || res === null) {
          return res
        } else {
          throw Error(errorInvalidTranslationsConfig)
        }
      })
      .catch(() => Promise.reject(errorUpdatingTranslationsConfig))
  } catch (e: unknown) {
    if (typeof e === 'string') return Promise.reject(e)
    return Promise.reject(errorUpdatingTranslationsConfig)
  }
}
export {
  getDbSettingsConfig,
  getDbTemplateConfig,
  putDbTemplateConfig,
  getCommonDbSettingsConfig,
  putCommonDbSettingsConfig,
  getReportingFilters,
  getDashboardSettingsConfig,
  postDashboardSettingsConfig,
  getTranslationsConfig,
  putTranslationsConfig,
}
