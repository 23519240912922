/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import Rodal from 'rodal'
import {Row, Col, Button} from 'react-materialize';
import { Contacts } from '../../../../types';

type Props = {
  contact: Contacts.Contact
  closeDeleteContact: Function
  handleDelete: (contactId: number) => void
}

const DeleteContact = ({ contact, closeDeleteContact, handleDelete }: Props) => {

  return (
    <div>
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={true}
        onClose={closeDeleteContact}
      >
        <h5>Delete {contact.name}?</h5>
        <Row>
          <Col s={12}><hr/></Col>
          <Col s={12}></Col>
        </Row>

        <br /><br />
        <Row className="center">
          <Col s={5} offset="s1">
            <Button data-cy="delete" onClick={() => handleDelete(contact.id)}>Delete</Button>
          </Col>
          <Col s={5}>
            <Button flat onClick={closeDeleteContact}>Close</Button>
          </Col>
        </Row>
      </Rodal>
    </div>
  )
}

export default DeleteContact
