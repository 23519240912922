import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'
import { PietabularModuleSettings } from './PietabularModuleContext'

export type PietabularModule = {
  type: 'pietabular'
  options: PietabularModuleSettings & ModuleDefaultSettings
} & ModuleBase

export type OpenAnswerGroup = {
  name?: string
  data?: OpenAnswer[]
}

export type OpenAnswerResData = {
  series: OpenAnswerGroup[]
}

export type Category = {
  [category: string]: string[]
}

export type Categories = {
  [key: string]: string[]
}

export type Metadata = {
  [key: string]: string
}

export type QueryMetadata = {
  [key: string]: string[]
}

export type OpenAnswer = {
  id: number
  answer_id: number
  category?: Category
  answer: string
  completed_survey_id: number
  metadata?: Metadata
  date?: string
  topics?: string[]
  sentiments?: string[]
  question?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export type Counts = {
  [key: string]: number
}
export type GroupedDataWithCounts = {
  [groupingValue: string]: Counts
}

export type GroupedDataByDateWithCounts = {
  [date: string]: GroupedDataWithCounts
}

export enum DateGroups {
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export type RegressionTableRow = {
  x: number
  y: number
  x2: number
  y2: number
  xy: number
}

export type Breakpoint = {
  id: number | string
  title: string
  date: string
  description: string
}

export type QueryKpis = {
  id: number
  name: string
}

export type GroupedQuery = {
  kpis: QueryKpis[]
  start_date: string
  end_date: string
  where_meta: { [meta: string]: string[] }
}

export enum AddOrRemove {
  ADD = 'add',
  REMOVE = 'remove',
}

export enum GroupBy {
  META_KEY = 'meta_key',
  DATE = 'date',
  SENTIMENT = 'sentiment',
  TOPIC = 'topic',
  CUSTOM = 'custom_',
}

export type Pagination = {
  pagination?: {
    page_size?: number
    page_number?: number
  }
}

export type CategoryGrouping = {
  category_grouping?: {
    sentiment?: (string | null)[]
    topic?: (string | null)[]
    custom?: {
      [customCategory: string]: (string | null)[]
    }
  }
}

export type QueryBase = {
  kpis?: number[]
  date_grouping: DateGroups
  start_date: string
  end_date: string
  where_meta?: {
    [metaKey: string]: string[]
  }
}

type addPrefixToObject<T, P extends string> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K]
}

export type Grouping =
  | {
      meta_key: string
      group_by: [GroupBy.META_KEY, ...Array<GroupBy | addPrefixToObject<string, GroupBy.CUSTOM>>]
    }
  | {
      meta_key?: undefined
      group_by: Array<
        Exclude<GroupBy, GroupBy.META_KEY> | addPrefixToObject<string, GroupBy.CUSTOM>
      >
    }

export type OpenAnswersCountsQuery = QueryBase & Pagination & CategoryGrouping & Grouping

export type OpenAnswersCountsResponse = {
  results: {
    count: number | null
    date_period: string | null
    meta_value: string | null
    sentiment: string[] | null
    topic: string[] | null
    custom_categories: {
      [key: string]: string[] | null
    }
  }[]
  page_metadata: {
    page_size: number
    total_elements: number
    total_pages: number
    page_number: number
  }
}
