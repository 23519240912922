import React, { useEffect, useRef, useState } from 'react'
import { DataTypes, NumberTrendModule } from './numbertrendTypes'
import useCommonDbSettingsConfig from '../../../../stores/useCommonDbSettingsConfig'
import NumberTrendReport from './NumberTrendReport'

import css from './NumberTrend.module.scss'

type NumberTrendProps = {
  imageurl?: string
  displayedNumber: number | null
  displayedTrend: number | null
  displayedSecondNumber: number | null
  displayedComparison: string
  options?: NumberTrendModule['options']
  comparisonmode?: boolean
  primaryFormat?: DataTypes
  saveModuleProperty: (newValue: { [property: string]: unknown }) => void
  decimals: number
  isReportOrScreen: boolean
  handleOpenInspector: () => void
}
const NumberTrend = ({
  imageurl,
  displayedNumber,
  displayedTrend,
  displayedSecondNumber,
  displayedComparison,
  options,
  comparisonmode,
  primaryFormat,
  saveModuleProperty,
  decimals,
  isReportOrScreen,
  handleOpenInspector,
}: NumberTrendProps) => {
  const { config } = useCommonDbSettingsConfig()
  const showComparisonNumber = !!comparisonmode
  const secondaryFormat = options?.secondaryNumber || DataTypes.COUNT
  const deckElementRef = useRef<HTMLDivElement>()
  const normalNumberElementRef = useRef<HTMLSpanElement>()
  const comparisonNumberElementRef = useRef<HTMLSpanElement>()
  const [isNormalArrowVisible, setIsNormalArrowVisible] = useState<boolean>(true)
  const [isComparisonArrowVisible, setIsComparisonArrowVisible] = useState<boolean>(true)

  useEffect(() => {
    if (deckElementRef.current && normalNumberElementRef.current)
      setIsNormalArrowVisible(
        deckElementRef.current.clientWidth - normalNumberElementRef.current.clientWidth > 51 &&
          !options?.hideTrendArrow,
      )
  }, [
    displayedNumber,
    normalNumberElementRef.current,
    deckElementRef.current?.clientWidth,
    options?.hideTrendArrow,
  ])

  useEffect(() => {
    if (deckElementRef.current && comparisonNumberElementRef.current)
      setIsComparisonArrowVisible(
        deckElementRef.current.clientWidth - comparisonNumberElementRef.current.clientWidth > 51 &&
          !options?.hideTrendArrow,
      )
  }, [
    displayedTrend,
    comparisonNumberElementRef.current,
    deckElementRef.current?.clientWidth,
    options?.hideTrendArrow,
  ])

  function getTrendClass(current: number, previous: number) {
    if (current > previous) return `${css.fadeIn} material-icons text-trafficlight-max`
    if (current < previous)
      return `${css.fadeIn} ${css.arrowDown} material-icons text-trafficlight-min`
    if (current === previous)
      return `${css.fadeIn} ${css.arrowFlat} material-icons text-trafficlight-mid`
    return ''
  }

  const handleClickNumber = () => {
    if (config?.disable_trend_query === true) return
    saveModuleProperty({ comparisonmode: comparisonmode === true ? false : true })
  }

  const handleRightClickNumber = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    handleOpenInspector()
  }

  if (isReportOrScreen) {
    return (
      <NumberTrendReport
        isComparison={!!comparisonmode}
        displayedComparisonNumber={displayedComparison}
        displayedNormalNumber={displayedNumber}
        displayedTrendNumber={displayedTrend}
        displayedSecondNumber={displayedSecondNumber}
        options={options}
        secondaryFormat={secondaryFormat}
        decimals={decimals}
      />
    )
  }
  return (
    <div className='number-trend fullsize' style={{ height: 'inherit', alignContent: 'start' }}>
      <div
        className='flexible-center'
        style={{
          width: '100%',
          ...(imageurl ? { position: 'absolute', top: 0, left: 0 } : {}),
        }}
      >
        <div
          className={`numbertrend-numbers ${css.deck}`}
          ref={(newRef) => {
            if (newRef) deckElementRef.current = newRef
          }}
        >
          <div
            className={`${css.card} ${showComparisonNumber ? css.flipped : ''}`}
            onClick={handleClickNumber}
            onContextMenu={handleRightClickNumber}
            style={{ backgroundImage: imageurl ? `url(${imageurl})` : '' }}
          >
            <div className={`numbertrend-value ${css.front} ${css.face}`}>
              <span
                data-cy='numberTrendValue'
                data-testid='numberTrendValue'
                className={`${css.primary}`}
                ref={(newRef) => {
                  if (newRef) normalNumberElementRef.current = newRef
                }}
              >
                <div>
                  {displayedNumber}
                  {displayedNumber !== null && displayedTrend !== null && isNormalArrowVisible && (
                    <i
                      className={getTrendClass(displayedNumber, displayedTrend)}
                      data-testid='trendArrow'
                    >
                      call_made
                    </i>
                  )}
                </div>
              </span>
              {options?.hideSecondaryNumber !== true && (
                <div
                  className={`numbertrend-subtitle text-shadow ${css.secondary}`}
                  data-testid='numbertrendSubtitle'
                >
                  {typeof displayedSecondNumber === 'number' ? (
                    <>
                      {secondaryFormat === DataTypes.COUNT &&
                        `(n: ${displayedSecondNumber?.toFixed(0)})`}
                      {secondaryFormat === DataTypes.AVERAGE &&
                        `(avg: ${displayedSecondNumber?.toFixed(decimals)})`}
                      {secondaryFormat === DataTypes.SUM &&
                        `(sum: ${displayedSecondNumber?.toFixed(0)})`}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
            <div className={`numbertrend-value text-shadow-2 ${css.back} ${css.face}`}>
              <span
                data-cy='numberTrendValue'
                data-testid='numberComparisonValue'
                className={`blue-grey-text ${css.primary}`}
                ref={(newRef) => {
                  if (newRef) comparisonNumberElementRef.current = newRef
                }}
              >
                <div>
                  {displayedComparison}
                  {displayedNumber !== null &&
                    displayedTrend !== null &&
                    isComparisonArrowVisible && (
                      <i
                        className={getTrendClass(displayedNumber, displayedTrend)}
                        data-testid='trendArrow'
                      >
                        call_made
                      </i>
                    )}
                </div>
              </span>
              {options?.hideSecondaryNumber !== true && (
                <div
                  className={`numbertrend-subtitle text-shadow ${css.secondary}`}
                  data-testid='numbertrendSubtitle'
                >
                  {typeof displayedTrend === 'number' && typeof displayedNumber === 'number' ? (
                    <>
                      {primaryFormat === DataTypes.COUNT &&
                        `(n: ${displayedTrend?.toFixed(0)} to ${displayedNumber?.toFixed(0)})`}
                      {primaryFormat === DataTypes.AVERAGE &&
                        `(avg: ${displayedTrend?.toFixed(decimals)} to ${displayedNumber?.toFixed(
                          decimals,
                        )})`}
                      {primaryFormat === DataTypes.SUM &&
                        `(sum: ${displayedTrend?.toFixed(0)} to ${displayedNumber?.toFixed(0)})`}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NumberTrend
