import { makeNonResettingObservable } from './nonResettingObservable'
import useStoreOwner from './useStoreOwner'
import useStoreNonResetting, { ActionState } from './useStoreNonResetting'
import { getTranslationsConfig, putTranslationsConfig } from '../react-services/configService2'
import { Language } from '../../languages/languages'
import { TranslationNameSpace } from '../../languages/i18n'

type NameSpaceWithoutStatic = Exclude<TranslationNameSpace, TranslationNameSpace.COMMON>

export type Translations = Partial<Record<Language, NameSpaces | undefined>>

export type NameSpaces = Partial<Record<NameSpaceWithoutStatic, Translation | undefined>>

export type Translation = { [translationKey: string]: string } | undefined
// TODO: add nested translations if needed later
// { [translationKey: string]: Translation } |
const translationsStorage = makeNonResettingObservable<Translations>({})

const useTranslationsConfig = () => {
  const key = 'translations'
  const { tenant, user } = useStoreOwner(translationsStorage)
  const {
    dataState: { data, error, state },
    put: putTranslations,
    get: getTranslations,
  } = useStoreNonResetting(
    translationsStorage,
    key,
    user,
    tenant,
    true,
    getTranslationsConfig,
    () => Promise.resolve(),
    putTranslationsConfig,
  )

  const isLoading = state !== ActionState.DONE && state !== ActionState.ERROR
  return { config: data, error, isLoading, putTranslations, getTranslations }
}

export default useTranslationsConfig
