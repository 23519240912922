import React, { useState } from 'react'

import css from './FilterTab.module.scss'
import CustomTooltip from '../../../../_shared/Infos/CustomTooltip'

type FilterTabProps = {
  text: string | JSX.Element
  selected: boolean
  onClick: () => void
  isFilterActive: boolean
}

const FilterTab = ({ text, selected, onClick, isFilterActive }: FilterTabProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  return (
    <div className={css.cntr} data-testid='filterTabCntr'>
      <div
        className={`${css.btn} ${selected ? css.activeBtn : ''} ${
          isFilterActive ? css.activeFilter : ''
        }`}
        onClick={onClick}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div>{text}</div>
      </div>
      {showTooltip && isFilterActive && (
        <CustomTooltip
          content={'Not all fields selected,\nfiltering by given values'}
          direction={'bottom'}
        />
      )}
    </div>
  )
}

export default FilterTab
