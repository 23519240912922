/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Rodal from 'rodal'
import { Row, Col, Icon, Card } from 'react-materialize'
import MetadataBoard from '../../../_shared/MetadataBoard/MetadataBoard'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import MainButton from '../../../_shared/Buttons/MainButton'
import Select, { MultiValue } from 'react-select'
import { dedupe } from '../../../../../utils'
import { UserManagementTool, Metadata, WhereMeta } from '../../../../../types'
import RoleSelect from '../RoleSelect'

type Props = {
  user: UserManagementTool.User
  filters: string[]
  templates: { key: string; name: string }[] | null
  onClose: (a: any) => void
  handleUpdate: (
    id: number,
    modules: string[],
    accessRights: WhereMeta,
    defaultFilters: WhereMeta,
    roles: UserManagementTool.Role[],
  ) => void
}

type State = {
  inaccessibleFilters: string[]
  modules: string[]
  accessRights: { key: string; values: string[] }[]
  defaultFilters: Metadata[]
  errorsOfAccess: any[]
  roles: UserManagementTool.Role[]
}

class EditUser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    let modules: string[] = []
    if (props.user.dbsettings && props.user.dbsettings.templates)
      modules = props.user.dbsettings.templates.map((t) => t)

    this.state = {
      inaccessibleFilters: this.createInaccessibleFilters(),
      modules: modules,
      accessRights: this.prepareMetadata(props.user.filters),
      defaultFilters: this.prepareMetadata(props.user.defaultfilters),
      errorsOfAccess: [],
      roles: this.props.user.roles ?? [],
    }
  }

  componentDidMount() {
    if (this.state.inaccessibleFilters.length > 0) {
      this.setState({
        errorsOfAccess: dedupe(this.state.inaccessibleFilters),
      })
    }

    // PATCH CHROME BUG
    const dropdowns = document.querySelectorAll('.select-wrapper')
    dropdowns.forEach((el) =>
      el.addEventListener('mousedown', (e) => {
        e.preventDefault()
        e.stopPropagation()
      }),
    )
  }

  prepareMetadata = (meta: WhereMeta | undefined): { key: string; values: string[] }[] => {
    const result: { key: string; values: string[] }[] = []
    if (meta && Object.keys(meta).length > 0) {
      Object.entries(meta).forEach(([key, values]) => {
        if (this.props.filters.includes(key)) result.push({ key, values })
      })
    }
    return result
  }

  createInaccessibleFilters = () => {
    const meta = { ...this.props.user.defaultfilters, ...this.props.user.filters }
    const inaccessibleFilters: string[] = []
    Object.keys(meta).forEach((key) => {
      if (!this.props.filters.includes(key)) inaccessibleFilters.push(key)
    })
    return inaccessibleFilters
  }

  handleMetadataChange = (type: 'accessRights' | 'defaultFilters', metas: Metadata[]) => {
    // empty array as access right -> entire filter is restricted
    if (type === 'accessRights') {
      const metasWithoutNull = replaceNullsWithEmptyArrays(metas)
      this.setState({ accessRights: metasWithoutNull })
    } else if (type === 'defaultFilters') {
      this.setState({ defaultFilters: metas })
    }

    function replaceNullsWithEmptyArrays(metas: Metadata[]): { key: string; values: string[] }[] {
      return metas.map((m: Metadata) => ({ key: m.key, values: m.values ? m.values : [] }))
    }
  }

  handleChangeModules = (modules: MultiValue<{ value: string; label: string }>) => {
    const selectedModules = modules as { value: string; label: string }[]
    this.setState({ modules: selectedModules.map((m) => m.value) })
  }

  handleChangeRoles = (roles: UserManagementTool.Role[]) => {
    this.setState({ roles: roles })
  }

  saveUser = () => {
    const ars =
      this.state.accessRights.length > 0
        ? transformFiltersToWhereMetaForm(this.state.accessRights).where_meta
        : {}
    const dfs =
      this.state.defaultFilters.length > 0
        ? transformFiltersToWhereMetaForm(this.state.defaultFilters).where_meta
        : {}

    this.props.handleUpdate(this.props.user.id, this.state.modules, ars, dfs, this.state.roles)
  }

  render() {
    const { user, templates, onClose } = this.props
    const { modules } = this.state

    function getTemplatesOfUsersModules(
      mods: string[],
      temps: { key: string; name: string }[],
    ): { key: string; name: string }[] {
      return mods.map((m) => {
        const template = temps.find((t) => t.key === m)
        if (template && template.name) {
          return template
        } else {
          return { name: m, key: m }
        }
      })
    }

    return (
      <div className='admin-user-tools-groupless-editor' data-testid='userToolsGrouplessEditor'>
        <Rodal closeOnEsc animation='slideUp' visible={true} onClose={onClose}>
          <h5>
            Edit <i>{user.name}</i>
          </h5>
          <hr />
          <br />

          {this.state.errorsOfAccess.length > 0 && (
            <Card className='orange lighten-2'>
              <Row className='marginless-row'>
                <Col s={1}>
                  <Icon className='orange-text' small>
                    warning
                  </Icon>
                </Col>
                <Col s={11}>
                  <span className='user-editor-warning red-text text-darken-4'>
                    Following filters are no longer accessible for this tenant and cannot be set as
                    access rights or default filters:
                    <ul>
                      {this.state.errorsOfAccess.map((err, i) => (
                        <li key={`error-${i}`}>{err}</li>
                      ))}
                    </ul>
                  </span>
                </Col>
              </Row>
            </Card>
          )}

          <div data-cy='selectModules' className='row'>
            <h6 className='label'>
              Modules &nbsp;
              <span className='tool'>
                <span className='tip'>Modules determine the views that are accessible.</span>
                <Icon tiny>help</Icon>
              </span>
            </h6>
            <div
              className='admin-user-management-groupless-modules'
              data-testid='userEditorModuleChanger'
            >
              {templates && Array.isArray(templates) && templates.length > 0 ? (
                <Select
                  isMulti
                  className='react-select-default-styles'
                  value={getTemplatesOfUsersModules(modules, templates).map((t) => ({
                    label: t.name,
                    value: t.key,
                  }))}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options={templates.map((t) => ({ label: t.name, value: t.key }))}
                  onChange={this.handleChangeModules}
                />
              ) : (
                <Select isDisabled placeholder='No modules' />
              )}
            </div>
          </div>

          <div className='margin'>
            <h6 className='label'>
              Access rights &nbsp;
              <span className='tool'>
                <span className='tip'>Select the filters whose values you want to restrict.</span>
                <Icon tiny>help</Icon>
              </span>
            </h6>
            <MetadataBoard
              selections={this.state.accessRights}
              creatables='values'
              isMulti
              valuePlaceholderText='Select, create or leave empty'
              onChange={(vals: Metadata[]) => {
                this.handleMetadataChange('accessRights', vals)
              }}
            />
          </div>

          <div className='margin'>
            <h6 className='label'>
              Default filters{' '}
              <span className='tool'>
                &nbsp;
                <span className='tip'>Modules determine the views that are accessible.</span>
                <Icon tiny>help</Icon>
              </span>
            </h6>

            <MetadataBoard
              selections={this.state.defaultFilters}
              creatables='values'
              isMulti
              valuePlaceholderText='Select or create'
              onChange={(vals: any) => {
                this.handleMetadataChange('defaultFilters', vals)
              }}
            />
          </div>

          <div className='admin-user-management-groupless-modules edit-user-select-role'>
            <h6>
              Roles&nbsp;
              <span className='tool'>
                <span className='tip'>
                  <u>admin</u> users can invite new users, add data
                  <br /> and see detailed information of the data.
                  <br />
                  <br />
                  <u>API</u>, <u>salesforce</u> and <u>dynamics</u> are integrations roles.
                </span>
                <Icon tiny>help</Icon>
              </span>
            </h6>
            <div className='edit-user-select-role' data-testid={'roleSelect'}>
              <RoleSelect
                selectedRoles={this.state.roles}
                handleRoleSelect={this.handleChangeRoles}
                hasStatsOption={this.state.roles.includes('stats')}
              />
            </div>
          </div>

          <div>
            <Row className='center'>
              <Col s={5} offset='s1'>
                <MainButton data-cy='edit' text='Save' onClick={this.saveUser} />
              </Col>
              <Col s={5}>
                <MainButton isFlat text='Close' onClick={onClose} />
              </Col>
            </Row>
          </div>
        </Rodal>
      </div>
    )
  }
}

export default EditUser
