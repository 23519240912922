import { DateGroups, GroupBy, OpenAnswersCountsQuery } from './pietabularTypes'
import { OpenAnswerCategories } from '../../../../stores/useOpenCategories'
import { OpenAnswersQuery, PrunedKpi } from './PietabularModuleContext'

const usePietabularExtensionQuery = (
  allTopicsWithoutUncategorized: OpenAnswerCategories['topic'] | null | undefined,
  query: OpenAnswersQuery | null,
  showNotSelectedTopicsAsOther: boolean,
  kpis: PrunedKpi[] | undefined,
) => {
  const allTopics = allTopicsWithoutUncategorized?.concat('uncategorized')
  const createPieExtensionQuery = (
    openCategories: string[] | undefined,
  ): OpenAnswersCountsQuery | null => {
    let pieQuery: OpenAnswersCountsQuery | null = null
    if (openCategories && query && allTopics) {
      let queryTopics: (null | string)[] = []
      if (showNotSelectedTopicsAsOther) {
        queryTopics = allTopics.map((t) => (t === 'uncategorized' ? null : t))
      }
      if (!showNotSelectedTopicsAsOther) {
        queryTopics = openCategories.map((t) => (t === 'uncategorized' ? null : t))
      }
      pieQuery = {
        kpis: kpis?.map((k) => k.id),
        category_grouping: {
          topic: queryTopics,
        },
        end_date: query.end_date,
        start_date: query.start_date,
        where_meta: query.where_meta,
        // date_grouping: defaultDateGroup || DateGroups.MONTH,
        date_grouping: DateGroups.YEAR,
        group_by: [GroupBy.TOPIC],
      }
    }
    return pieQuery
  }

  const createTabularExtensionQuery = (
    selectedPieTopic: string | null | undefined,
    newFilteredTopics: (string | null)[] | null,
    showTabular: boolean,
    defaultMetaGroup: string | undefined,
    defaultCountedKey: string | undefined,
  ): OpenAnswersCountsQuery | null => {
    let tabularQuery: OpenAnswersCountsQuery | null = null
    if (
      newFilteredTopics &&
      (newFilteredTopics.length || selectedPieTopic) &&
      showTabular &&
      defaultMetaGroup &&
      defaultCountedKey &&
      query &&
      allTopics
    ) {
      let queryTopics: (null | string)[] = []
      if (showNotSelectedTopicsAsOther && selectedPieTopic) {
        queryTopics =
          selectedPieTopic === 'uncategorized'
            ? [null]
            : selectedPieTopic === 'other'
            ? allTopics
                .map((t) => (t === 'uncategorized' ? null : t))
                .filter((t) => !newFilteredTopics.includes(t))
            : [selectedPieTopic]
      }
      if (showNotSelectedTopicsAsOther && !selectedPieTopic) {
        queryTopics = allTopics.map((t) => (t === 'uncategorized' ? null : t))
      }
      if (!showNotSelectedTopicsAsOther && !selectedPieTopic) {
        queryTopics = newFilteredTopics.map((c) => (c === 'uncategorized' ? null : c))
      }
      if (!showNotSelectedTopicsAsOther && selectedPieTopic) {
        queryTopics = [selectedPieTopic === 'uncategorized' ? null : selectedPieTopic]
      }
      const grouping =
        defaultCountedKey === 'sentiment'
          ? GroupBy.SENTIMENT
          : defaultCountedKey === 'topic'
          ? GroupBy.TOPIC
          : GroupBy.CUSTOM + defaultCountedKey
      tabularQuery = {
        kpis: kpis?.map((k) => k.id),
        category_grouping: {
          topic: !queryTopics.length ? undefined : queryTopics,
        },
        end_date: query.end_date,
        start_date: query.start_date,
        where_meta: query.where_meta,
        date_grouping: DateGroups.YEAR,
        meta_key: defaultMetaGroup,
        group_by: [GroupBy.META_KEY, grouping],
      }
    }
    return tabularQuery
  }

  const createTrendsExtensionQuery = (
    selectedPieTopic: string | null | undefined,
    newFilteredTopics: (string | null)[] | null,
    showTrends: boolean,
    defaultCountedKey: string | undefined,
    defaultDateGroup: DateGroups | undefined,
  ): OpenAnswersCountsQuery | null => {
    let trendsQuery: OpenAnswersCountsQuery | null = null
    if (newFilteredTopics && showTrends && defaultCountedKey && query && allTopics) {
      let queryTopics: (null | string)[] = []
      if (showNotSelectedTopicsAsOther && selectedPieTopic) {
        queryTopics =
          selectedPieTopic === 'uncategorized'
            ? [null]
            : selectedPieTopic === 'other'
            ? allTopics
                .map((t) => (t === 'uncategorized' ? null : t))
                .filter((t) => !newFilteredTopics.includes(t))
            : [selectedPieTopic]
      }
      if (showNotSelectedTopicsAsOther && !selectedPieTopic) {
        queryTopics = allTopics.map((t) => (t === 'uncategorized' ? null : t))
      }
      if (!showNotSelectedTopicsAsOther && !selectedPieTopic) {
        queryTopics = newFilteredTopics.map((c) => (c === 'uncategorized' ? null : c))
      }
      if (!showNotSelectedTopicsAsOther && selectedPieTopic) {
        queryTopics = [selectedPieTopic === 'uncategorized' ? null : selectedPieTopic]
      }
      const grouping =
        defaultCountedKey === 'sentiment'
          ? GroupBy.SENTIMENT
          : defaultCountedKey === 'topic'
          ? GroupBy.TOPIC
          : GroupBy.CUSTOM + defaultCountedKey
      trendsQuery = {
        kpis: kpis?.map((k) => k.id),
        category_grouping: {
          topic: queryTopics,
        },
        end_date: query.end_date,
        start_date: query.start_date,
        where_meta: query.where_meta,
        date_grouping: defaultDateGroup || DateGroups.MONTH,
        group_by: [grouping, GroupBy.DATE],
      }
    }
    return trendsQuery
  }
  return { createPieExtensionQuery, createTabularExtensionQuery, createTrendsExtensionQuery }
}

export default usePietabularExtensionQuery
