import { createStateContext } from 'react-use'
import { RadarDataContext } from '../radarTypes'

export const [useRadarData, RadarDataProvider] = createStateContext<RadarDataContext>({
  radarOptions: {},
  radarCountQuery: null,
  countsByTopic: [],
  countsByMeta: [],
  isLoading: false,
})
