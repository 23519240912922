/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { useDeepCompareEffect, useUpdateEffect } from 'react-use'
import './FeedbackAlertModal.scss'
import FeedbackAlertModalTable from './FeedbackAlertModalTable'
import ConfirmationModal from '../../../../common/ConfirmationModal/ConfirmationModal'
import { KPIAnswer, MetadataKpis } from '../alertTypes'
import { useAlertData, useAlertModalControl } from '../FeedbackAlertsTableCntr'
import ButtonSettingsToggle from './ButtonControlToggle'
import ControlModal from '../ControlModal/ControlModal'
import CopyToClipboardIcon from '../../../../common/Buttons/CopyToClipboardIcon'
import DoubleModalMain from '../../../../_shared/Modals/DoubleModalMain'
import ButtonRelatedAlertsToggle from './ButtonRelatedAlertsToggle'
import RelatedAlertsModal from '../RelatedAlertsModal/RelatedAlertsModal'
import ButtonConversationToggle from '../../_shared/ConversationModal/ButtonConversationToggle'
import ConversationModalContext from '../../_shared/ConversationModal/ConversationModalProvider'
import { Conversation } from '../../_shared/ConversationModal/conversationTypes'
import { IconButton } from '@mui/material'
import { Close, Comment, ErrorOutline, FormatListBulleted, People } from '@mui/icons-material'
import { tCommon, tKpi } from '../../../../../../languages/i18n'

import css from './FeedbackAlertModal.module.scss'

type FeedbackAlertModalProps = {
  onCancel: () => void
  isLimitedAlert?: boolean
}

const FeedbackAlertModal = ({ onCancel, isLimitedAlert = false }: FeedbackAlertModalProps) => {
  const [{ alert, alertConfig, isUnsavedChanges, relatedAlerts, conversationsList }, setAlertData] =
    useAlertData()
  const [
    { isConversationModalOpen, isSettingsModalOpen, isRelatedAlertsModalOpen },
    setAlertModalControl,
  ] = useAlertModalControl()

  const setAlertModalsChanged = useState(false)[1]
  const [alertMetadata, setAlertMetadata] = useState<MetadataKpis[]>()
  const [alertKpisNum, setAlertKpisNum] = useState<MetadataKpis[]>()
  const [alertKpisOpen, setAlertKpisOpen] = useState<MetadataKpis[]>()
  const [kpiAnswer, setKpiAnswer] = useState<KPIAnswer>({ kpi: null, answer: null })
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false)

  const isAlertMetaData = alertMetadata && alertMetadata.length > 0
  const isAlertNumKpis = alertKpisNum && alertKpisNum.length > 0
  const isAlertOpenKpis = alertKpisOpen && alertKpisOpen.length > 0
  const isSideModalOpen = isConversationModalOpen || isSettingsModalOpen || isRelatedAlertsModalOpen

  useEffect(() => {
    return () =>
      setAlertData((prev) => ({
        ...prev,
        alert: null,
        currentAlertId: null,
        isUnsavedChanges: false,
      }))
  }, [])

  const handleUnsavedChangesToggle = (bool: boolean) => {
    setAlertData((prev) => ({ ...prev, isUnsavedChanges: bool }))
  }

  useUpdateEffect(() => {
    setAlertModalsChanged(true)
  }, [isSideModalOpen])

  useEffect(() => {
    if (alert) {
      for (const [key, value] of Object.entries(alert.kpis)) {
        if (key === alert.question) setKpiAnswer({ kpi: key, answer: value })
      }
    }
  }, [alert?.question])

  useDeepCompareEffect(() => {
    const metadataArr: MetadataKpis[] = []
    const numberKpiArr: MetadataKpis[] = []
    const openKpiArr: MetadataKpis[] = []
    const blacklistedMeta: string[] = [
      'anonymousid',
      'contactattemptid',
      'surveyid',
      'smsserviceprovider',
      'messentestatus',
      'surveylink',
    ]

    function handleMetadataList() {
      if (!alert) return
      for (const [key, value] of Object.entries(alert.metadata)) {
        metadataArr.push({ key: key, value: value })
      }

      const noBlacklistedMetaArr = metadataArr.filter(function (meta) {
        return blacklistedMeta.indexOf(meta.key) < 0
      })

      if (!alertConfig) return
      if (alertConfig.metakeys && alertConfig.metakeys.length > 0) {
        const filtered_metas = noBlacklistedMetaArr.filter(function (meta) {
          return alertConfig.metakeys!.indexOf(meta.key) >= 0
        })
        setAlertMetadata(filtered_metas)
      } else {
        setAlertMetadata(noBlacklistedMetaArr)
      }
    }

    function handleKpiList() {
      if (!alert) return

      for (const [key, value] of Object.entries(alert.kpis)) {
        const checkNumValue = Number(value)
        if (isNaN(checkNumValue)) {
          openKpiArr.push({ key: key, value: value })
        } else {
          numberKpiArr.push({
            key: key,
            value: checkNumValue % 1 === 0 ? checkNumValue : checkNumValue.toFixed(2),
          })
        }
      }
      const sortedNumberKpiArr = numberKpiArr.sort((a, b) => (a.value < b.value ? -1 : 1))
      setAlertKpisNum(sortedNumberKpiArr)
      setAlertKpisOpen(openKpiArr)
    }
    handleMetadataList()
    handleKpiList()
  }, [alertConfig || {}])

  const handleConversationButtonClick = () => {
    setAlertModalControl((prev) => ({
      ...prev,
      isConversationModalOpen: !prev.isConversationModalOpen,
    }))
  }

  const handleSettingsButtonClick = () => {
    setAlertModalControl((prev) => ({ ...prev, isSettingsModalOpen: !prev.isSettingsModalOpen }))
  }

  const handleRelatedAlertsButtonClick = () => {
    setAlertModalControl((prev) => ({
      ...prev,
      isRelatedAlertsModalOpen: !prev.isRelatedAlertsModalOpen,
    }))
  }

  const handleCloseAlertModal = () => {
    if (isUnsavedChanges) {
      setIsCancelConfirmationModalOpen(true)
    } else {
      onCancel()
    }
  }

  const handleCopyMetadataToClipboard = () => {
    if (isAlertMetaData)
      navigator.clipboard.writeText(
        alertMetadata.reduce((string, meta) => string + meta.key + ': ' + meta.value + '\n', ''),
      )
  }

  const handleCopyNumericKpisToClipboard = () => {
    if (isAlertNumKpis)
      navigator.clipboard.writeText(
        alertKpisNum.reduce(
          (string, numKpi) => string + numKpi.key + ': ' + numKpi.value + '\n',
          '',
        ),
      )
  }

  const handleCopyOpenKpisToClipboard = () => {
    if (isAlertOpenKpis)
      navigator.clipboard.writeText(
        alertKpisOpen.reduce(
          (string, openKpi) => string + openKpi.key + ':\n' + openKpi.value + '\n\n',
          '',
        ),
      )
  }

  const handleCopyRecipientsToClipboard = () => {
    if (alert && alert.recipients && alert.recipients.length > 0)
      navigator.clipboard.writeText(
        alert.recipients.reduce((string, email) => string + email + ',' + '\n', ''),
      )
  }

  const updateMessageCount = (conversations: Conversation[]) => {
    setAlertData((prev) => ({ ...prev, conversationsList: conversations }))
  }

  const updateConversationsList = (conversations: Conversation[]) => {
    setAlertData((prev) => ({ ...prev, conversationsList: conversations }))
  }

  return (
    <>
      {isCancelConfirmationModalOpen && (
        <ConfirmationModal
          handleConfirmation={onCancel}
          handleCancel={() => setIsCancelConfirmationModalOpen(false)}
          title='Unsaved changes'
          info='Please confirm to exit without saving. To confirm, press enter or click confirm button. To cancel, press esc or click cancel.'
          enableEnterConfirmation={true}
        />
      )}
      <div className={`feedback-alert-modal ${css.cntr}`}>
        {!isSideModalOpen && (
          <div className={css.action_buttons}>
            {alertConfig && alertConfig.show_conversation && (
              <ButtonConversationToggle onClick={handleConversationButtonClick} />
            )}
            <ButtonSettingsToggle onClick={handleSettingsButtonClick} />
            {!isLimitedAlert && relatedAlerts && (
              <ButtonRelatedAlertsToggle onClick={handleRelatedAlertsButtonClick} />
            )}
          </div>
        )}
        {alert && (
          <DoubleModalMain
            onClose={handleCloseAlertModal}
            double={isSideModalOpen}
            animation={isSideModalOpen ? 'slideRight' : 'slideLeft'}
            showMask={true}
          >
            <div className={css.header}>
              <h5>
                <div>{alert.subject}</div> <ErrorOutline sx={{ fontSize: '24px' }} />
              </h5>
              <IconButton onClick={handleCloseAlertModal} sx={{ padding: 0 }}>
                <Close sx={{ color: '#fff', fontSize: '28px' }} />
              </IconButton>
            </div>
            <div className={css.content}>
              <p>
                <b>{tCommon('label.triggerKpi')}: </b>
                {tKpi(alert.question)} <b>{tCommon('label.answer')}: </b>
                {kpiAnswer.answer}
              </p>

              <div className='metadata-numeric-answers'>
                <div className='metadata'>
                  <div className='icon-text'>
                    <b>{tCommon('label.backgroundInformation')}</b>
                    <FormatListBulleted />
                    {isAlertMetaData && (
                      <CopyToClipboardIcon onClick={handleCopyMetadataToClipboard} />
                    )}
                  </div>
                  {isAlertMetaData ? (
                    <FeedbackAlertModalTable tableData={alertMetadata} />
                  ) : (
                    <p>{tCommon('info.noData')}</p>
                  )}
                </div>
                <hr />
                <div className='numeric-answers'>
                  <div className='icon-text'>
                    <b>{tCommon('label.numericAnswers')}</b>
                    <FormatListBulleted />
                    {isAlertNumKpis && (
                      <CopyToClipboardIcon onClick={handleCopyNumericKpisToClipboard} />
                    )}
                  </div>
                  {isAlertNumKpis ? (
                    <FeedbackAlertModalTable tableData={alertKpisNum} triggerKPI={kpiAnswer} />
                  ) : (
                    <p>{tCommon('info.noData')}</p>
                  )}
                </div>
              </div>

              <div className='open-answers'>
                <div className='icon-text'>
                  <b>{tCommon('label.openAnswers')}</b>
                  <FormatListBulleted />
                  {isAlertOpenKpis && (
                    <CopyToClipboardIcon onClick={handleCopyOpenKpisToClipboard} />
                  )}
                </div>
                {isAlertOpenKpis ? (
                  <FeedbackAlertModalTable tableData={alertKpisOpen} />
                ) : (
                  <p>{tCommon('info.noData')}</p>
                )}
              </div>

              <hr />

              <div className='responsible-textarea'>
                {alertConfig && !alertConfig.hide_recipients && (
                  <div className='responsible'>
                    <div className='icon-text'>
                      <b>{tCommon('label.responsible')}</b>
                      <People />
                      {alert && alert.recipients && alert.recipients.length > 0 && (
                        <CopyToClipboardIcon onClick={handleCopyRecipientsToClipboard} />
                      )}
                    </div>
                    <ul>
                      {alert.recipients &&
                        alert.recipients.map((recipient) => <li key={recipient}>{recipient}</li>)}
                    </ul>
                  </div>
                )}
                <div className='comment'>
                  <div className='icon-text'>
                    <b>{tCommon('label.comment')}</b>
                    <Comment />
                    {(alert.comment !== null || alert.comment !== undefined) && (
                      <CopyToClipboardIcon
                        onClick={() => navigator.clipboard.writeText(alert.comment || '')}
                      />
                    )}
                  </div>
                  <p>{alert.comment}</p>
                </div>
              </div>
            </div>
          </DoubleModalMain>
        )}
        {isConversationModalOpen && (
          <ConversationModalContext
            completedSurveyId={alert ? alert.completed_survey_id : null}
            closeConversation={handleConversationButtonClick}
            unsavedChangesToggle={handleUnsavedChangesToggle}
            isUnsavedChanges={isUnsavedChanges}
            conversationsList={conversationsList}
            updateConversationsList={updateConversationsList}
            updateMessageCount={updateMessageCount}
          />
        )}
        {isSettingsModalOpen && <ControlModal />}
        {isRelatedAlertsModalOpen && <RelatedAlertsModal />}
      </div>
    </>
  )
}

export default FeedbackAlertModal
