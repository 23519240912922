import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { WheelModule } from './wheelModuleTypes'
import { WheelModuleCntrUnwrappedProps } from './WheelModuleCntr'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import useConfigCustomerPath from '../../../../stores/useConfigCustomerPath'
import useNumericKpis from '../../../../stores/useNumericKpis'
import { Kpi, Query } from '../../../../../types'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import { errorLoadingConfig } from './WheelNotifications'
import { SharedFilter } from '../NumberTrend/numbertrendTypes'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { isAllowedToFetch } from '../Group/contexts/renderListReducer'
import { CircularProgress } from '@mui/material'

import css from './WheelModuleQueryWrapper.module.scss'
import { isEqual } from 'lodash'
import { QueueStatus } from '../Group/groupModuleTypes'

type WheelModuleQueryCntrProps = {
  id: string
  module: WheelModule
  saveModule: (module: WheelModule) => void
  Wrapped: FunctionComponent<WheelModuleCntrUnwrappedProps>
  isReportOrScreen: boolean
  sharedFilter?: SharedFilter
  moduleStatus: QueueStatus | undefined
}
const WheelModuleQueryCntr = ({
  id,
  module,
  saveModule,
  Wrapped,
  isReportOrScreen,
  sharedFilter,
  moduleStatus,
}: WheelModuleQueryCntrProps) => {
  const { requestToFetch } = useRenderActions()
  const doneStatusTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { config: tenantCustomerPath, isLoading: isLoadingTenantCustomerPath } =
    useConfigCustomerPath()

  const [wheelQuery, setWheelQuery] = useState<Query.SingleNumeric | null>(null)

  const reformattedTenantCustomerPath = useMemo(() => {
    if (!tenantCustomerPath) return tenantCustomerPath
    const newConfig = { ...tenantCustomerPath }
    const oldLimits = tenantCustomerPath.limitValues
    if (oldLimits) {
      const newLimits = {} as {
        min?: number
        max?: number
        mid?: number
      }
      newLimits.min = oldLimits.min
      newLimits.max = oldLimits.max
      if (oldLimits.mid) newLimits.min = oldLimits.mid
      newConfig.limitValues = newLimits
    }
    const oldCategories = tenantCustomerPath.conf
    if (oldCategories && Array.isArray(oldCategories)) {
      const newCategories = oldCategories.map((category) => {
        const oldLimits = category.limitValues
        if (oldLimits) {
          const newLimits = {} as {
            min?: number
            max?: number
            mid?: number
          }
          newLimits.min = oldLimits.min
          newLimits.max = oldLimits.max
          if (oldLimits.mid) newLimits.min = oldLimits.mid
          return { ...category, limitValues: newLimits }
        }
        return category
      })
      newConfig.conf = newCategories
    }
    return newConfig
  }, [tenantCustomerPath])

  const { numericKpis, isLoading: isLoadingNumericKpis } = useNumericKpis()
  const moduleCustomerPath = module.options?.moduleWheelConfig
  const currentConfig =
    module.options?.isCustomerPathConfigSelected !== false
      ? reformattedTenantCustomerPath
      : moduleCustomerPath

  const saveModuleOptionsValue = (value: Record<string, unknown>) => {
    const newOptions = { ...(module.options || {}), ...value }
    saveModule({ ...module, options: newOptions })
  }

  useEffect(() => {
    if (!currentConfig || !numericKpis || !currentConfig.conf || !currentConfig.conf.length) return
    const kpis = currentConfig.conf
      .map((category) => category.kpiid)
      .filter((id): id is number => typeof id === 'number')
    const uniqueKpis = Array.from(new Set(kpis))
    const kpisWithNames = uniqueKpis.map<Kpi.Kpi>((id) => ({
      id,
      name: numericKpis.find((k) => k.id === id)?.name || '',
    }))
    if (!kpisWithNames || !kpisWithNames.length) return

    let query: Query.SingleNumeric | null = null

    if (module.query?.start_date && module.query?.end_date) {
      const q = module.query
      query = {
        ...q,
        kpis: kpisWithNames,
        calculated_kpis: {},
        trend_line: false,
        cache: true,
      }
    }

    const filters = sharedFilter?.filters
    if (filters) {
      const q = transformFiltersToWhereMetaForm(filters)
      query = {
        ...q,
        kpis: kpisWithNames,
        calculated_kpis: {},
        trend_line: false,
        cache: true,
      }
    }
    if (isEqual(wheelQuery, query)) return
    if (module.id) handleRequestingToFetch(module.id)
    if (isAllowedToFetch(moduleStatus)) setWheelQuery(query)
  }, [module.query, sharedFilter, currentConfig, numericKpis, moduleStatus])

  const handleRequestingToFetch = (moduleId: string) => {
    requestToFetch(moduleId)
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
  }
  if (!currentConfig || !currentConfig.conf || !currentConfig.conf.length) {
    return (
      <div className={'wheel-module-query-cntr wheel-module-cntr ' + css.noConfig}>
        {errorLoadingConfig}
      </div>
    )
  }

  if (!wheelQuery || isLoadingNumericKpis || isLoadingTenantCustomerPath) {
    return (
      <div className={css.loading}>
        <CircularProgress thickness={1} size={'6rem'} style={{ opacity: 0.7 }} />
      </div>
    )
  }

  return (
    <>
      {!isLoadingTenantCustomerPath ? (
        <Wrapped
          doneStatusTimeoutRef={doneStatusTimeoutRef}
          lineLimits={module.limitValues}
          wheelQuery={wheelQuery}
          lineQuery={{
            ...wheelQuery,
            time_res: module.options?.dateGroup || 'month',
            trend_line: false,
          }}
          customerPathConfig={currentConfig}
          options={module.options}
          saveModuleOptionsValue={saveModuleOptionsValue}
          isReportOrScreen={isReportOrScreen}
          id={id}
          moduleStatus={moduleStatus}
        />
      ) : (
        <LoadingIndicator />
      )}
    </>
  )
}

export default WheelModuleQueryCntr
