/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import {Table, Icon, Pagination} from 'react-materialize';
import { hasRole } from '../../../react-services/authService';
import trackingService from '../../../react-services/trackingService';
import { TrackingEvent } from '../../../react-constants';
import { Contacts } from '../../../../types';
import { isEqual } from 'lodash';
import './ContactManagement.scss';

type Props = {
  contacts: Contacts.Contact[]
  showDeleteContact: Function

  toggleContactList?: Function
  handleCheckboxChange?: Function
  checkedContacts?: Contacts.Contact[]
}

type State = {
  filteredContacts: Contacts.Contact[],
  visibleContacts: Contacts.Contact[],
  items: number
  contactsOnPage: number,
  page: number
}

class ContactList extends React.Component<Props, State> {
  private isAdmin: boolean = hasRole('admin')

  constructor(props: Props) {
    super(props)
    const contactsOnPage = 50
    this.state = {
      filteredContacts: props.contacts,
      visibleContacts: props.contacts.slice(0, contactsOnPage),
      items: Math.ceil(props.contacts.length/contactsOnPage),
      contactsOnPage: contactsOnPage,
      page: 1
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.contacts, this.props.contacts)) {
      this.setState({
        filteredContacts: this.props.contacts,
        visibleContacts: this.props.contacts.slice(0, this.state.contactsOnPage),
        items: Math.ceil(this.props.contacts.length/this.state.contactsOnPage),
        page: 1
      })
    }
  }

  handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trackingService.debounceTrack(TrackingEvent.FilterContactsInContactManagement)
    const filteredContacts = this.props.contacts.filter((contact: any) => contact.name.toLowerCase().includes(event.target.value.toLowerCase()) || contact.email.toLowerCase().includes(event.target.value.toLowerCase()))
    this.setState({
      filteredContacts: filteredContacts,
      visibleContacts: filteredContacts.slice(0, this.state.contactsOnPage),
      items: Math.ceil(filteredContacts.length/this.state.contactsOnPage),
      page: 1
    })
  }

  handlePageChange = (toPage: number) => {
    trackingService.track(TrackingEvent.ChangePageInContactManagement)
    const start = (toPage - 1) * this.state.contactsOnPage
    const end = Math.min(start + this.state.contactsOnPage - 1, this.state.filteredContacts.length - 1)
    this.setState({
      visibleContacts: this.state.filteredContacts.slice(start, end + 1),
      items: Math.ceil(this.state.filteredContacts.length/this.state.contactsOnPage),
      page: toPage
    })
  }

  render() {
    const { showDeleteContact } = this.props;

    return (
      <div>
        <link href="//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet"></link>
        <input data-cy="filter" name="filter" onChange={this.handleFieldChange} placeholder=" &#xf002;   Filter results here"/>

        <Table className='table striped z-depth-1'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              {this.isAdmin &&
                <th className="center">Delete</th>
              }
            </tr>
          </thead>
          <tbody data-cy="contactlist">
            {this.state.visibleContacts.map((contact: any) =>
              <tr key={contact.id}>
                <td>{contact.name}</td>
                <td>{contact.email}</td>
                <td>{contact.phone}</td>
                {this.isAdmin &&
                  <td className="center">
                    <span data-cy="deleteContact" onClick={() => showDeleteContact(contact)}><Icon className="material-icons module-icon-color waves-effect">delete</Icon></span>
                  </td>
                }
              </tr>)}
          </tbody>
        </Table>
        <div className="results">Showing {this.state.visibleContacts.length} / {this.state.filteredContacts.length} results.</div>
        <div data-cy="pagination"><Pagination onSelect={this.handlePageChange} activePage={this.state.page} items={this.state.items} className="center"/></div>
      </div>
    )
  }
}

export default ContactList
