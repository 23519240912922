import {
  subDays,
  subWeeks,
  addDays,
  getDay,
  format,
  startOfWeek,
  endOfWeek,
  startOfQuarter,
  endOfQuarter,
  startOfMonth,
  endOfMonth,
  startOfYear,
  subMonths,
  lastDayOfMonth,
  lastDayOfQuarter,
  subQuarters,
  subYears,
  parse,
  eachDayOfInterval,
  formatISO,
  lastDayOfYear,
} from 'date-fns'
import dayjs from 'dayjs'

/** Date functions
 *  Use the date-fns library whenever you can!
 */

export const DEFAULT_DATE_FORMAT = `yyyy-MM-dd`

const TRUNCATED_DATE_FORMAT = `MM/yyyy`

function daysInBetween(start, end) {
  return Math.abs(Math.ceil((new Date(start) - new Date(end)) / 1000 / 60 / 60 / 24))
}

function getDateNow() {
  return dateToString(new Date(Date.now()))
}

function formatTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

function displayCurrentUTCDate() {
  return (
    [getDateNow()].join('-') +
    ' ' +
    [formatTo2Digits(new Date().getUTCHours()), formatTo2Digits(new Date().getUTCMinutes())].join(
      ':',
    )
  )
}

/** You can also "plus" a negative number, which leads to subtraction
 */
function datePlusDays(d, days) {
  let date = typeof d === 'string' ? new Date(d) : d
  date.setDate(date.getDate() + days)
  return dateToString(date)
}

function dateToString(date) {
  return formatISO(date).slice(0, 10)
}

function getDatesQuickTimeframeButton(button, end_date) {
  let startdate = new Date(end_date)
  startdate.setHours(0, 0, 0, 0)
  let enddate = new Date(end_date)
  enddate.setHours(0, 0, 0, 0)
  switch (button) {
    case 'd':
      startdate = subDays(startdate, 1)
      break
    case 'w':
      startdate = subWeeks(startdate, 1)
      break
    case 'm':
      startdate = subMonths(startdate, 1)
      break
    case 'q':
      startdate = subQuarters(startdate, 1)
      break
    case 'y':
      startdate = subYears(startdate, 1)
      break
    case 'wtd':
      startdate = startOfWeek(startdate, { weekStartsOn: 1 })
      break
    case 'mtd':
      startdate = startOfMonth(startdate)
      break
    case 'qtd':
      startdate = startOfQuarter(startdate)
      break
    case 'ytd':
      startdate = startOfYear(startdate)
      break
    case 'lw':
      startdate = startOfWeek(subWeeks(startdate, 1), { weekStartsOn: 1 })
      enddate = endOfWeek(startdate, { weekStartsOn: 1 })
      break
    case 'lm':
      startdate = startOfMonth(subMonths(startdate, 1))
      enddate = lastDayOfMonth(startdate)
      break
    case 'lq':
      startdate = startOfQuarter(subQuarters(startdate, 1))
      enddate = lastDayOfQuarter(startdate)
      break
    case 'ly':
      startdate = startOfYear(subYears(startdate, 1))
      enddate = lastDayOfYear(startdate)
      break
    default:
      throw new Error('Timeframe set to an illegal value: ' + button)
  }
  let dates = {
    start: startdate,
    end: enddate,
  }
  return dates
}

function startAndEndDatesOfWeekInYear(year, week, weekStartsOn = 1) {
  const DATE_CONFIG = {
    weekStartsOn,
  }
  const THURSDAY = 4
  const DECEMBER = 11

  let weekIndex = figureOutWeekIndex(week.toString())

  let januaryFirst = new Date(year, 0, 1)
  // first week of the year must contain a Thursday; otherwise it's the last week of the previous year. So we need the first Thursday
  let dayOffset = getDay(januaryFirst) - THURSDAY
  let firstThursdayOfJan = subDays(januaryFirst, dayOffset)

  // if we land on Dec when we remove days from Jan 1st, add a week
  if (firstThursdayOfJan.getMonth() === DECEMBER) {
    firstThursdayOfJan = addDays(firstThursdayOfJan, 7)
  }

  let thursdayOfGivenWeek = addDays(firstThursdayOfJan, weekIndex * 7)

  return {
    start_date: format(startOfWeek(thursdayOfGivenWeek, DATE_CONFIG), DEFAULT_DATE_FORMAT),
    end_date: format(endOfWeek(thursdayOfGivenWeek, DATE_CONFIG), DEFAULT_DATE_FORMAT),
  }

  function figureOutWeekIndex(wk) {
    // matches numbers 1-53 with an optional w or W at the beginning
    if (!/^(w?|W?)(5[0-3]|[1-4][0-9]|[1-9])$/.test(wk)) {
      throw new Error('Week number has to be between 1 and 53. It may begin with the letter W.')
    }

    let result = wk
    if (result.toLowerCase().startsWith('w')) {
      result = wk.substr(1)
    }

    return parseInt(result) - 1
  }
}

function startAndEndDatesOfQuarterInYear(year, quarter) {
  let arbitraryDateInGivenQuarter

  switch (quarter.toString()) {
    case 'Q1':
    case '1':
      arbitraryDateInGivenQuarter = new Date(year, 1, 1) // Feb 1
      break
    case 'Q2':
    case '2':
      arbitraryDateInGivenQuarter = new Date(year, 4, 1) // May 1
      break
    case 'Q3':
    case '3':
      arbitraryDateInGivenQuarter = new Date(year, 7, 1) // Aug 1
      break
    case 'Q4':
    case '4':
      arbitraryDateInGivenQuarter = new Date(year, 10, 1) // Nov 1
      break
    default:
      throw new Error('Quarter was not given in the correct format. Use either QX or just X')
  }

  return {
    start_date: format(startOfQuarter(arbitraryDateInGivenQuarter), DEFAULT_DATE_FORMAT),
    end_date: format(endOfQuarter(arbitraryDateInGivenQuarter), DEFAULT_DATE_FORMAT),
  }
}

function startAndEndDatesOfMonthInYear(year, month) {
  if (typeof month === 'string') {
    month = parseInt(month)
  }

  if (month < 1 || month > 12) {
    throw new Error(`Month should be between 1 and 12`)
  }

  let arbitraryDateInGivenMonth = new Date(year, month - 1, 15)

  return {
    start_date: format(startOfMonth(arbitraryDateInGivenMonth), DEFAULT_DATE_FORMAT),
    end_date: format(endOfMonth(arbitraryDateInGivenMonth), DEFAULT_DATE_FORMAT),
  }
}

function startAndEndDatesOfYear(year) {
  return {
    start_date: format(new Date(year, 0, 1), DEFAULT_DATE_FORMAT),
    end_date: format(new Date(year, 11, 31), DEFAULT_DATE_FORMAT),
  }
}

function listOfDaysInBetween(startDate, endDate) {
  let dates = eachDayOfInterval({
    start: parse(startDate, DEFAULT_DATE_FORMAT, new Date()),
    end: parse(endDate, DEFAULT_DATE_FORMAT, new Date()),
  })
  return dates.map((date) => {
    return format(date, DEFAULT_DATE_FORMAT)
  })
}

function toTruncatedFormat(date) {
  return format(date, TRUNCATED_DATE_FORMAT)
}

const extractDateStringFromDayJs = (date) => {
  try {
    if (!date.isValid()) {
      const year = date.year()
      const month = date.month()
      const newDate = dayjs()
        .year(year)
        .month(month - 1)
        .date(1)
      const endOfMonthDate = newDate.set('date', newDate.endOf('month').date())
      return formatISO(endOfMonthDate.toDate()).substring(0, 10)
    }
    return formatISO(date.toDate()).substring(0, 10)
  } catch (error) {
    // DO NOTHING FOR NOW, COULD BE A SMALL RED ERROR AROUND THE INPUT?
  }
}

export {
  daysInBetween,
  getDateNow,
  datePlusDays,
  startAndEndDatesOfWeekInYear,
  startAndEndDatesOfQuarterInYear,
  startAndEndDatesOfMonthInYear,
  startAndEndDatesOfYear,
  dateToString,
  listOfDaysInBetween,
  getDatesQuickTimeframeButton,
  toTruncatedFormat,
  displayCurrentUTCDate,
  extractDateStringFromDayJs,
}
