import { format } from 'date-fns'
import React, { useEffect } from 'react'
import css from './ConversationModal.module.scss'
import { useConversationFunctions, useConversationTools } from './ConversationModalProvider'
import ConversationModalTextarea from './ConversationModalTextarea'
import {
  ConversationType,
  MessageState,
  MessagesUpdate,
  SingleConversationMessage,
} from './conversationTypes'

const SingleConversation = () => {
  const [{ isTextareaExpanded, messages, conversationNav, currentConversationId, initConversations }] =
    useConversationTools()
  const [{ updateMessagesListState, updateMessageCount }] = useConversationFunctions()

  useEffect(() => {
    if (messages && messages.length > 0 && conversationNav.isSingleConversationOpen) {
      const messageIds: number[] = []
      messages.forEach((msg) => {
        messageIds.push(msg.id)
      })
      const messagesToRead: MessagesUpdate = {
        messageIds: messageIds,
        messageState: MessageState.READ,
      }
      updateMessagesListState(messagesToRead)
      if (currentConversationId) {
        const updatedConversationList = initConversations.map(conversation => {
          if (conversation.id === currentConversationId) {
            return {...conversation, unreadMessagesCount: 0}
          }
          return conversation
        })
        updateMessageCount(updatedConversationList)
      }
    }
  }, [messages])

  const getMsgClassName = (msg: SingleConversationMessage) => {
    if (msg.wheelqUserContactInformation) {
      switch (msg.messageType) {
      case ConversationType.EMAIL:
        return css.wheelq_msg_email
      case ConversationType.PHONE:
        return css.wheelq_msg_phone
      case ConversationType.NOTE:
        return css.wheelq_msg_note
      default:
        return css.wheelq_msg_note
      }
    } else {
      return css.client_msg
    }
  }

  const getMsgIcon = (msg: SingleConversationMessage) => {
    switch (msg.messageType) {
    case ConversationType.EMAIL:
      return 'email'
    case ConversationType.PHONE:
      return 'phone_in_talk'
    case ConversationType.NOTE:
      return 'sticky_note_2'
    default:
      return 'sticky_note_2'
    }
  }

  return (
    <>
      <div className={css.conversation_modal_messages}>
        <div
          className={css.msg_messages_list}
          style={{ height: isTextareaExpanded ? '31vh' : '62vh' }}
        >
          {messages && messages.length > 0 ? (
            messages.map((msg: SingleConversationMessage) => (
              <div className={css.msg_container} key={msg.id}>
                <span
                  className={css.sent_at}
                  style={{ textAlign: msg.wheelqUserContactInformation ? 'right' : 'left' }}
                >
                  <b>{msg.wheelqUserContactInformation ? msg.wheelqUserContactInformation : msg.clientContactInformation} </b>
                  {format(new Date(msg.sentAt), 'Pp')}
                </span>
                <div className={`${getMsgClassName(msg)}`}>
                  <i className='material-icons'>{`${getMsgIcon(msg)}`}</i>
                  <p
                    style={{
                      padding: msg.wheelqUserContactInformation
                        ? '1.2em 1.2em 1.2em 3em'
                        : '1.2em 3em 1.2em 1.2em',
                    }}
                  >
                    {msg.commentText}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className={css.conversation_modal_info}>
              <h5>
                No messages yet. Write one below
                <i className='material-icons'>arrow_downward</i>
              </h5>
            </div>
          )}
        </div>
        <ConversationModalTextarea />
      </div>
    </>
  )
}

export default SingleConversation
