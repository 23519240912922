import React, { useEffect, useRef } from 'react'
import Rodal from 'rodal'
import {Row, Col, Button, Input, Icon} from 'react-materialize';
import Select from 'react-select';
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator';
import RoleSelect from "../RoleSelect"

class EditGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.group.name,
      selectedModules: props.group.modules.map(m => ({label: m.name, value: m.key})),
      selectedAccessRights: props.group.accessrights.map(ar => ({label: ar, value: ar})),
      selectedDefaultFilters: props.group.defaultfilters.map(df => ({label: df, value: df})),
      isAdminGroup: props.group.roles.includes('admin'),
      roles: props.group.roles ?? []
    }
  }

  handleUpdateGroup = () => {
    let modules = this.state.selectedModules.map(m => ({key: m.value, name: m.label}))
    let accessRights = this.state.selectedAccessRights.map(ar => ar.value)
    let defaultFilters = this.state.selectedDefaultFilters.map(df => df.value)
    
    this.props.handleUpdate(this.state.name, modules, accessRights, defaultFilters, this.state.roles);
  }

  handleRoleChange = (roles) => {
    this.setState({ roles: roles })
  }

  render() {
    const { templates, filters, closeEditGroup } = this.props

    return (
      <div className="admin-user-tools-group">
        <Rodal
          closeOnEsc
          animation="slideUp"
          visible={true}
          onClose={closeEditGroup}
        >
          <h5>Edit group <i>{this.props.group.name}</i></h5>
          <br/>
          <h6>Name</h6>
          <Input 
            s={12}
            type="text"
            data-cy="name" 
            name="name" 
            defaultValue={this.state.name} 
            onChange={event => {this.setState({name: event.target.value})}} 
            placeholder="This group is missing a name"
            error={!this.state.name ? 'Required' : ''}
          />
          <div data-cy="modulesOfUserGroup" className="margin-top">
            <h6>Modules&nbsp;
              <span className="tool">
                <span className="tip">Modules determine the views that are accessible.</span>
                <Icon tiny>help</Icon>
              </span>
            </h6>
            <Select
              className="react-select-default-styles"
              isMulti
              isClearable
              closeMenuOnSelect={false}
              placeholder="Select modules"
              options={templates.map(template => ({label: template.name, value: template.key}))}
              value={this.state.selectedModules}

              onChange={(values) => {this.setState({selectedModules: values})}}
            />
          </div>
          <br />
          <div data-cy="accessrightsOfUserGroup">
            <h6>Access rights&nbsp;
              <span className="tool">
                <span className="tip">Select the filters whose values you want to restrict.<br/>The values are chosen on user level.</span>
                <Icon tiny>help</Icon>
              </span>
            </h6>
            <Select
              className="react-select-default-styles"
              isMulti
              isClearable
              closeMenuOnSelect={false}
              placeholder="Select access rights"
              options={filters.map(filter => ({label: filter, value: filter}))}
              value={this.state.selectedAccessRights}

              onChange={(values) => {this.setState({selectedAccessRights: values})}}
            />
          </div>          
          <br />
          <div data-cy="defaultfiltersOfUserGroup">
            <h6>Default filters&nbsp;
              <span className="tool">
                <span className="tip">The values of these filters will be preselected on login.<br/>The values are chosen on user level.</span>
                <Icon tiny>help</Icon>
              </span>
            </h6>
            <Select
              className="react-select-default-styles"
              isMulti
              isClearable
              closeMenuOnSelect={false}
              placeholder="Select default filters"
              options={filters.map(filter => ({label: filter, value: filter}))}
              value={this.state.selectedDefaultFilters}

              onChange={(values) => {this.setState({selectedDefaultFilters: values})}}
            />
          </div>
          <br />
          <div data-cy="rolesOfUserGroup">
          <h6>Roles&nbsp;
              <span className="tool">
                <span className="tip">
                  <u>admin</u> users can invite new users, add data<br/> and see detailed information of the data.
                  <br/><br/><u>API</u>, <u>salesforce</u> and <u>dynamics</u> are integrations roles.
                </span>
                <Icon tiny>help</Icon>
              </span>
            </h6>
          </div>
          <div className="edit-group-select-role" data-testid={"roleSelect"}>
            <RoleSelect
              selectedRoles={this.state.roles} 
              handleRoleSelect={this.handleRoleChange} 
              hasStatsOption={this.props.group.roles.includes('stats')}
            />
          </div>
          <br/><br/>
          <Row className="center">
            <Col s={5} offset="s1">
              <Button data-cy="editGroup"
                disabled={this.state.name === '' || this.props.isSaving}
                onClick={this.handleUpdateGroup}
                className='waves-effect waves-light btn orange lighten-1'
              >
                Update
              </Button>
            </Col>
            <Col s={5}>
              <Button disabled={this.props.isSaving} onClick={closeEditGroup}>Close</Button>
            </Col>
          </Row>
          {this.props.isSaving &&
            <BottomLoadingIndicator />
          }
        </Rodal>
      </div>
    )
  }
}

function BottomLoadingIndicator() {
  let ref = useRef()

  useEffect(() => {
    if (ref.current != null) {
      ref.current.scrollIntoView()
    }
  })

  return (
    <>
      <div>
        Saving each affected user. Please wait.
      </div>
      <LoadingIndicator />
      <div ref={ref} className="edit-group-loading-bottom-anchor"></div>
    </>
  ) 
}

export default EditGroup