import React, { useEffect, useState } from 'react'
import { hasRole } from '../../react-services/apiService'
import TabUserManagement from './TabUserManagement'
import ManualSendingOfSurveys from './Surveys/ManualSendingOfSurveys/ManualSendingOfSurveys'
import ResponseRate from './Surveys/ResponseRate/ResponseRate'
import SurveyInfo from './Surveys/SurveyInfo/SurveyInfo'
import InsertDataManually from './Data/InsertDataManually'
import ManageContacts from './ContactManagement/ManageContacts'
import Reports from './Reports/Reports'
import Alerts from './Alerts/Alerts'
import './AdminTools.scss'
import CrmIntegrations from './CrmIntegrations/CrmIntegrations'
import { getGenericConfiguration } from '../../react-services/configService'
import { get } from '../../react-services/apiService'
import { makeApiHook } from '../hook/makeApiHook'
import SurveyCheckToolTable from './Surveys/SurveyCheckTool/SurveyCheckToolTable'
import { ToastProvider } from '../common/Notification/NotificationContext'
import NotificationContainer from '../common/Notification/NotificationContainer'
import OpenInspector from './ViewCustomization/OpenInspector'
import useCommonDbSettingsConfig from '../../stores/useCommonDbSettingsConfig'
import { debounce } from 'lodash'
import { Paper, Tab as OriginalTab, Tabs } from '@mui/material'
import styled from '@emotion/styled'
import TranslationsToolCntr from './ViewCustomization/TranslationsTool/TranslationsToolCntr'

export const ADMIN_TOOLS_TOAST_ID = 'adminToolsToastId'

const Tab = styled(OriginalTab)(() => ({
  '&:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))
const AdminTools = () => {
  const [tab, setTab] = useState(hasRole('admin') ? 'userManagement' : 'surveys')
  const [isAllowedExport, setIsAllowedExport] = useState(false)
  const [hideManualSurveySendingForNonadmins, setHideManualSurveySendingForNonadmins] =
    useState(false)
  const [ready, setReady] = useState(false)
  const { config: cdbs, isLoading: isLoadingCbds } = useCommonDbSettingsConfig()

  useEffect(() => {
    if (isLoadingCbds) return
    const debouncer = debounce(() => {
      setIsAllowedExport(cdbs && cdbs.show_data_export ? cdbs.show_data_export : false)
      setHideManualSurveySendingForNonadmins(
        cdbs && cdbs.hide_manual_survey_sending_for_nonadmins === true ? true : false,
      )
      setReady(true)
    }, 1000)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [cdbs, isLoadingCbds])

  const handleChangeTab = (e, newValue) => setTab(newValue)

  return (
    <Paper sx={{ width: '84vw', margin: '0 auto', height: 'inherit', overflowY: 'auto' }}>
      {ready && (
        <div data-cy='tabs' id='admin-user-tools'>
          <ToastProvider id={ADMIN_TOOLS_TOAST_ID}>
            <NotificationContainer id={ADMIN_TOOLS_TOAST_ID} />
            {hasRole('admin') ? (
              <Tabs value={tab} onChange={handleChangeTab} variant='fullWidth'>
                <Tab value='userManagement' label='User management' />
                <Tab value='surveys' label='Surveys' />
                {/* <Tab value='data' label='Data' /> */}
                <Tab value='contactManagement' label='Contact management' />
                <Tab value='reports' label='Reports' />
                <Tab value='alerts' label='Alerts' />
                <Tab value='crmIntegration' label='CRM integration' />
                <Tab value='viewCustomization' label='View customization' />
              </Tabs>
            ) : (
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab value='surveys' label='Surveys' />
                <Tab value='contactManagement' label='Contact management' />
              </Tabs>
            )}
            <>
              <Paper sx={{ padding: '0 2em 4em 2em' }}>
                {tab === 'userManagement' && <TabUserManagement />}
                {/* {tab === 'data' && <TabData />} */}
                {tab === 'contactManagement' && <TabContactManagement />}
                {tab === 'reports' && <TabReports />}
                {tab === 'alerts' && <TabAlerts />}
                {tab === 'crmIntegration' && <TabCrmIntegrations />}
                {tab === 'viewCustomization' && <TabInspector />}
                {tab === 'surveys' && (
                  <TabSurveys
                    hide_manual_survey={hideManualSurveySendingForNonadmins && !hasRole('admin')}
                    isAllowedExport={hasRole('admin') || isAllowedExport}
                    cdbs={cdbs}
                  />
                )}
              </Paper>
            </>
          </ToastProvider>
        </div>
      )}
    </Paper>
  )
}

const TabSurveys = (props) => {
  const [tab, setTab] = useState(hasRole('admin') ? 'manualSurveySending' : 'responseRate')
  const handleChangeTab = (e, newValue) => setTab(newValue)

  return (
    <div data-cy='surveys' className='admin-tools-surveys'>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='manualSurveySending' label='Manual sending of surveys' />
        <Tab value='responseRate' label='Response rate' />
        <Tab value='surveySendingDetails' label='Survey sending details' />
        <Tab value='surveyInfo' label='Survey info' />
      </Tabs>

      {tab === 'manualSurveySending' && hasRole('admin') && <ManualSendingOfSurveys />}
      {tab === 'surveySendingDetails' && <SurveyCheckToolTable />}
      {tab === 'responseRate' && (
        <ResponseRate isAllowedExport={props.isAllowedExport} cdbs={props.cdbs} />
      )}
      {tab === 'surveyInfo' && (
        <SurveyInfo
          useApiHook={makeApiHook([
            getGenericConfiguration('survey_automation'),
            getGenericConfiguration('triggerconf'),
            get('GET_SURVEY_TEMPLATES'),
            get('GET_SURVEY_STATS'),
          ])}
        />
      )}
    </div>
  )
}

const TabData = () => {
  const [tab, setTab] = useState('insertDataManually')
  const handleChangeTab = (e, newValue) => setTab(newValue)

  return (
    <div data-cy='data' className='admin-tools-data-insertion'>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='insertDataManually' label='Insert data manually' />
      </Tabs>

      {tab === 'insertDataManually' && <InsertDataManually />}
    </div>
  )
}

const TabContactManagement = () => {
  const [tab, setTab] = useState('manageContacts')
  const handleChangeTab = (e, newValue) => setTab(newValue)
  const { config: cdbs } = useCommonDbSettingsConfig()

  return (
    <div data-cy='contactManagement' className='admin-tools-contacts'>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='manageContacts' label='Manage contacts' />
      </Tabs>

      {tab === 'manageContacts' && <ManageContacts cdbs={cdbs} />}
    </div>
  )
}

const TabCrmIntegrations = () => {
  const [tab, setTab] = useState('crmIntegrations')
  const handleChangeTab = (e, newValue) => setTab(newValue)

  return (
    <div data-cy='crmIntegrations' className='admin-tools-crm'>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='crmIntegrations' label='Active integrations' />
      </Tabs>

      {tab === 'crmIntegrations' && <CrmIntegrations />}
    </div>
  )
}

const TabReports = () => {
  const [tab, setTab] = useState('overview')
  const handleChangeTab = (e, newValue) => setTab(newValue)

  return (
    <div data-cy='reports' className='admin-tools-reports'>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='overview' label='Overview' />
      </Tabs>

      {tab === 'overview' && <Reports />}
    </div>
  )
}

const TabAlerts = () => {
  const [tab, setTab] = useState('overview')
  const handleChangeTab = (e, newValue) => setTab(newValue)

  return (
    <div data-cy='alerts' className='admin-tools-alerts'>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab value='overview' label='Overview' />
      </Tabs>

      {tab === 'overview' && <Alerts />}
    </div>
  )
}

const TabInspector = () => {
  const handleChangeTab = (e, newValue) => setTab(newValue)
  const { config: cdbs } = useCommonDbSettingsConfig()
  const [tab, setTab] = useState('openAnswerInspector')
  useEffect(() => {
    const defaultTab = cdbs?.is_multi_language_enabled ? 'translations' : 'openAnswerInspector'
    setTab(defaultTab)
  }, [cdbs])

  return (
    <div data-testid='view-customization-tabs' className='admin-tools-inspector'>
      <Tabs value={tab} onChange={handleChangeTab}>
        {cdbs?.is_multi_language_enabled && <Tab value='translations' label='Translations' />}
        <Tab value='openAnswerInspector' label='Open answers Inspector' />
      </Tabs>

      {tab === 'translations' && <TranslationsToolCntr />}
      {tab === 'openAnswerInspector' && <OpenInspector />}
    </div>
  )
}

export default AdminTools
