import React from 'react'
import SaveText from './SaveText'

import css from './Saving.module.scss'
import { CircularProgress } from '@mui/material'
import { tCommon } from '../../../../../../../../languages/i18n'

const Saving = () => {
  return (
    <div className={css.loadingIcon}>
      <div>
        <CircularProgress size={'18px'} color='inherit' />
      </div>
      <SaveText text={tCommon('label.saving') + '...'} />
    </div>
  )
}

export default Saving
