import { sortObject } from './../../utils'
import { BLACKLIST } from '../react-constants/metadata'
import { getQueryMeta } from '../react-services/queryService'
import { cloneDeep, sortBy, sortedUniqBy } from 'lodash'
import { reportingFilterStorage } from '../stores/useReportingFilters'
import { dashboardTemplateStorage } from '../stores/useDbTemplateConfig'

let logToConsole = false

const BASICFILTERS = {
  start_date: '',
  end_date: '',
  where_meta: {},
}

/** Fetching filters with this function automatically removes blacklisted ones.
 */
function getReportingFilters(returnAsArray = false) {
  let filters = reportingFilterStorage.get('reportingfilters')
  if (filters === null) {
    throw new Error('Reporting filters havent resolved yet, fetch them asynchronously')
  }
  if (typeof filters === 'undefined') {
    throw new Error('Reporting filters havent been fetched at all or werent put to storage.')
  }

  let result = sortObject(removeBlacklistedFilters(filters))
  return returnAsArray ? Object.entries(result).map(([key, values]) => ({ key, values })) : result
}

function getReportingFiltersAsync(returnAsArray = false) {
  const reportingFilters = reportingFilterStorage.get('reportingfilters')
  return Promise.resolve(reportingFilters)
    .then((filters) => {
      let result = sortObject(removeBlacklistedFilters(filters))
      return returnAsArray
        ? Object.entries(result).map(([key, values]) => ({
            key,
            values: values.map((value) => ({ value })),
          }))
        : result
    })
    .catch((e) => {
      console.warn('Reporting filters are not in storage. Returned an empty set.')
      return returnAsArray ? [] : {}
    })
}

function getGroupByMetas() {
  let filters = getReportingFilters()
  let result = [{ name: 'none', value: null }]
  if (!filters || filters.length === 0) {
    console.error('Error fetching group by metas list')
  } else {
    Object.keys(filters).forEach((f) => {
      result.push({ name: f, value: f })
    })
  }
  return result
}

function transformFiltersToWhereMetaForm(filters) {
  if (!filters || filters.length === 0 || Object.keys(filters).length === 0)
    throw new Error('transformFiltersToWhereMetaForm received an invalid input.')

  let result = cloneDeep(BASICFILTERS)
  for (let i = 0; i < filters.length; i++) {
    if (filters[i].name === 'startDate') {
      result.start_date = filters[i].value
    } else if (filters[i].name === 'endDate') {
      result.end_date = filters[i].value
    } else {
      let key = filters[i].name ? filters[i].name : filters[i].key ? filters[i].key : null
      let values = filters[i].value
        ? filters[i].value
        : filters[i].values
        ? filters[i].values
        : null

      if (key !== null && values !== null) {
        result.where_meta[key] = convertValuesToStrings(values)
      }
    }
  }
  return result

  function convertValuesToStrings(values) {
    if (values && values[0]) {
      if (typeof values[0] === 'string') return values
      let result = []
      values.forEach((val) => {
        result.push(val.value)
      })
      return result
    } else return values
  }
}

function removeBlacklistedFilters(filters) {
  for (let i = 0; i < BLACKLIST.length; i++) {
    let key = BLACKLIST[i]
    if (filters && filters.filters) {
      delete filters.filters[key]
    } else if (filters) {
      delete filters[key]
    }
  }

  return filters
}

function extractTimeFiltersFromGroupFilters(filters) {
  if (!Array.isArray(filters))
    throw new Error(
      'moduleFiltersService.extractTimeFiltersFromGroupFilters expected input to be an array',
    )
  var result = []
  filters.forEach(function (filter) {
    if (filter.name === 'startDate' || filter.name === 'endDate') result.push(filter)
  })
  return result
}

function attachQueryMetasToFilters(query, filters) {
  if (logToConsole)
    console.log('moduleFiltersService: attachQueryMetasToFilters. Query/filters', query, filters)
  let newFilters = cloneDeep(filters)
  if (newFilters && typeof newFilters == 'object' && !Array.isArray(newFilters) && query) {
    let whereMeta = getQueryMeta(query)
    for (let key in whereMeta) {
      if (newFilters[key]) {
        let mergedValues = []
        mergedValues = mergedValues.concat(newFilters[key])
        whereMeta[key].forEach(function (val) {
          if (!mergedValues.includes(val)) {
            mergedValues.push(val)
          }
        })
        mergedValues = sortFilterValues(mergedValues)
        if (logToConsole) console.log('Merged values for key:', key, mergedValues)
        newFilters[key] = mergedValues
      } else {
        newFilters[key] = sortFilterValues(whereMeta[key])
        if (logToConsole) console.log('Attached query values for key:', key, newFilters[key])
      }
    }
  }
  return newFilters
}

function sortFilterValues(filterValues) {
  if (!filterValues || !Array.isArray(filterValues) || filterValues.length < 2) {
    return cloneDeep(filterValues)
  } else {
    let sortedValues = sortBy(filterValues, function (filter) {
      let value =
        typeof filter === 'object' && filter.value && filter.value.length
          ? filter.value.toLowerCase()
          : filter && filter.length
          ? filter.toLowerCase()
          : ''
      return value
    })
    return sortedUniqBy(sortedValues, function (v) {
      return typeof v === 'object' && v.value ? v.value : v
    })
  }
}

function sortFilterKeys(filters) {
  if (!filters || !Array.isArray(filters) || filters.length < 2) {
    return cloneDeep(filters)
  } else {
    return sortBy(filters, function (filter) {
      let value =
        typeof filter === 'object' && filter.key && filter.key.length
          ? filter.key.toLowerCase()
          : filter && filter.length
          ? filter.toLowerCase()
          : ''
      return value
    })
  }
}

function sortFilters(filters) {
  if (filters && Array.isArray(filters) && filters.length) {
    for (let filter of filters) {
      if (filter && filter.values) filter.values = sortFilterValues(filter.values)
    }
    filters = sortFilterKeys(filters)
  }
  return filters
}

// If 'preserveEmpty' is TRUE, include keys which hold an empty Array
function mapReportingFiltersToQuery2Format(filters, preserveEmpty) {
  if (!filters) return
  filters = sortFilters(filters)
  var mapFilters = {}
  for (let filter of filters) {
    var values = []
    for (let value of filter.values) {
      values.push(value.value)
    }
    if (values && (preserveEmpty || values.length)) {
      if (mapFilters[filter.key]) {
        if (logToConsole)
          console.log(
            'Entry with key: ' + filter.key + ' already exists. Combine values:',
            mapFilters[filter.key],
            values,
          )
        values = values.concat(mapFilters[filter.key])
        values = sortFilterValues(values)
      }
      mapFilters[filter.key] = values
    }
  }
  return mapFilters
}

// True if requested value is found under key
function filtersContainKeyValuePair(filters, metaKey, metaValue) {
  if (logToConsole)
    console.log(
      'Check if filters contain the requested key/value pair',
      'Filters:',
      filters,
      '\nKey:',
      metaKey,
      '\nValue:',
      metaValue,
    )
  let contains = false
  // If 'filters' is an Array, use old reporting filters format
  if (metaKey && metaValue && filters && Array.isArray(filters)) {
    let matchingFilters = filters.filter(function (element) {
      return element && typeof element === 'object' && element.key === metaKey
    })
    for (let matchingFilter of matchingFilters) {
      if (matchingFilter && matchingFilter.values && matchingFilter.values.length) {
        let value = matchingFilter.values.find(function (element) {
          return element && typeof element === 'object' && element.value === metaValue
        })
        if (value) {
          contains = true
          break
        }
      }
    }
  }
  // If 'filters' is a Map, use new type of reporting filters format
  else if (
    metaKey &&
    metaValue &&
    filters &&
    !Array.isArray(filters) &&
    typeof filters === 'object'
  ) {
    if (filters[metaKey] && Array.isArray(filters[metaKey]) && filters[metaKey].length) {
      if (filters[metaKey].includes(metaValue)) contains = true
    }
  }
  if (logToConsole) console.log('Contains key/value pair:', contains)
  return contains
}

function getAutoTimeScale(rootScope, scope) {
  const hasModuleAutoTimeScale = scope.module?.autoTimeScale
  if (hasModuleAutoTimeScale != null) {
    return hasModuleAutoTimeScale
  } else {
    const autoTimeScaleInCurrentDash = getCurrentDashboardAutoTimeScale(rootScope)
    const autoTimeScale = autoTimeScaleInCurrentDash ?? getAutoTimeScaleFromDBsettings(rootScope)
    return autoTimeScale
  }
}

function getCurrentDashboardAutoTimeScale(rootScope) {
  if (rootScope.sectionsConfig) {
    const dbTemplate = dashboardTemplateStorage.get('dbtemplate')
    const currentTemplate =
      dbTemplate[rootScope.sectionsConfig[rootScope.currentSection]?.templatekey]
    const autoTimeScaleInCurrentDash = currentTemplate
      ? currentTemplate[0]?.dashboards[rootScope.currentDashboard]?.autoTimeScale
      : undefined
    return autoTimeScaleInCurrentDash
  }
  return undefined
}

/**
 * @param {*} rootScope
 * @returns
 */
function getAutoTimeScaleFromDBsettings(rootScope) {
  const DBsettings = rootScope.dashboardConfig
  if (!DBsettings) return
  if (
    !DBsettings[0] ||
    DBsettings[0].autoTimeScale === undefined ||
    DBsettings[0].autoTimeScale === null ||
    DBsettings[0].autoTimeScale === ''
  ) {
    return true
  } else {
    return DBsettings[0].autoTimeScale
  }
}

function getAutoTimeScaleFromDbConfig(dashboardConfig) {
  if (!dashboardConfig) return
  if (
    !dashboardConfig[0] ||
    dashboardConfig[0].autoTimeScale === undefined ||
    dashboardConfig[0].autoTimeScale === null ||
    dashboardConfig[0].autoTimeScale === ''
  ) {
    return true
  } else {
    return dashboardConfig[0].autoTimeScale
  }
}
export {
  getReportingFilters,
  getReportingFiltersAsync,
  removeBlacklistedFilters,
  getGroupByMetas,
  transformFiltersToWhereMetaForm,
  extractTimeFiltersFromGroupFilters,
  attachQueryMetasToFilters,
  sortFilters,
  mapReportingFiltersToQuery2Format,
  filtersContainKeyValuePair,
  getAutoTimeScale,
  getAutoTimeScaleFromDBsettings,
  getAutoTimeScaleFromDbConfig,
}
