import React from 'react'

import css from './BreakpointCardCheckBox.module.scss'

type BreakpointCardCheckBoxProps = {
  selected: boolean
  onClick: () => void
}
const BreakpointCardCheckBox = ({ selected, onClick }: BreakpointCardCheckBoxProps) => {
  const handleCheckBoxClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    onClick()
  }
  return (
    <i
      className={'material-icons material-symbols-outlined ' + css.icon}
      onClick={handleCheckBoxClick}
      data-testid='breakpoints-list-card-checkbox'
    >
      {!selected ? 'check_box_outline_blank' : 'check_box'}
    </i>
  )
}

export default BreakpointCardCheckBox
