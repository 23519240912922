import React from 'react'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'

import modal_css from './../../../DashboardModules/_shared/ModuleSettings/SettingsModal.module.scss'
import css from './SelectExtensions.module.scss'

const SelectExtensions = () => {
  return (
    <div className={`${modal_css.half}`}>
      Select extensions to show
      <div className={css.list}>
        <CheckBoxItem
          text='Show Wheel'
          data_testid='showWheel'
          id='showWheel'
          settingsName='showWheel'
          width='half'
          defaultValue={true}
        />
        <CheckBoxItem
          text='Show Line'
          data_testid='showLine'
          id='showLine'
          settingsName='showLine'
          width='half'
          defaultValue={false}
        />
      </div>
    </div>
  )
}

export default SelectExtensions
