/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer, toast } from 'react-toastify'
import ToastifyStylesContainer from './scripts/components/common/Notification/ToastifyStylesContainer'
import App from './App'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { LicenseInfo } from '@mui/x-license-pro'
import * as Highcharts from 'highcharts8'
import './languages/i18n'
import { ThemeProvider, createTheme } from '@mui/material'
import { allChartColors, primaryModalColor } from './styles/variableExport'
import { pSBC } from './scripts/react-services/colorService'

if (process.env.REACT_APP_MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)
}

const AzureADClientId =
  process.env.REACT_APP_START_PROD || process.env.REACT_APP_STAGING
    ? process.env.REACT_APP_AD_LOGIN_CLIENT_ID
    : process.env.REACT_APP_AD_LOGIN_CLIENT_ID_SMOKE

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
require('highcharts-custom-events')(Highcharts)
window.Highcharts = Highcharts

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: AzureADClientId || '35fc62ad-5a33-429e-8c79-629eaf342fb8',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
  telemetry: {
    application: {
      appName: 'WheelQ Azure AD',
      appVersion: '1.0.0',
    },
  },
}

const theme = createTheme({
  palette: {
    primary: {
      main: primaryModalColor,
    },
    info: {
      main: '#fff',
      contrastText: '#000',
    },
    secondary: {
      dark: allChartColors.GREY,
      light: pSBC(0.4, allChartColors.GREY) || allChartColors.GREY,
      main: pSBC(0.2, allChartColors.GREY) || allChartColors.GREY,
    },
  },
})

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
const publicClientApplication = new PublicClientApplication(msalConfig)

export const googleClientId =
  '744564062594-g90a04v7mrkmeq1rddm4tbs1pvdrmn7u.apps.googleusercontent.com'

const rootElement = document.getElementById('root')
ReactDOM.render(
  <>
    {/* <React.StrictMode> */}
    <MsalProvider instance={publicClientApplication}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <ToastifyStylesContainer>
          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            theme={'colored'}
            autoClose={5000}
            hideProgressBar={true}
            limit={3}
            transition={Slide}
          />
        </ToastifyStylesContainer>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </MsalProvider>
    {/* </React.StrictMode> */}
  </>,
  rootElement,
)
