import React from 'react'
import { get, post } from '../../../../react-services/apiService'
import ModuleFilterBar from '../../../Dashboards/DashboardModules/_shared/Filter/ModuleFilterBar'
import ResponseRateTable from './ResponseRateTable'
import { PropTypes } from 'prop-types'
import { getDateNow, datePlusDays } from '../../../../react-services/datesService'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import { DASHBOARDCONSTANTS } from '../../../../react-constants/dashboards'
import { isEmpty, cloneDeep, isNil } from 'lodash'
import { spawnHelpdeskToast } from '../../../../../utils'

class ResponseRate extends React.Component {
  static propTypes = {
    isAllowedExport: PropTypes.bool,
    cdbs: PropTypes.object,
  }

  static defaultProps = {
    isAllowedExport: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      surveyId: '',
      whereMeta: {},
      startDate: datePlusDays(getDateNow(), -30),
      endDate: getDateNow(),

      surveys: null,
      isExporting: false,
      numberOfElements: 0,
      totalElements: 0,
      doShowNumberOfElements: false,
      hasPendingChanges: false,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.getSurveys()
  }

  getSurveys = () => {
    get('GET_SURVEYS', { filter: 'active' })
      .then((res) => {
        let surveys = getActualSurveys(res)
        this.attachStatisticsDataToSurveys(surveys)
      })
      .catch((error) => {
        console.error(error)
        this.setState({
          isLoading: false,
        })
        spawnHelpdeskToast(
          'Problem while loading your surveys',
          'Unable to load surveys',
          error.toString(),
        )
      })

    /**
     * This "survey" entity is more accurately an "import" entity, e.g. importing data manually creates one entry.
     * They are actually surveys if they have an associated survey ID in the attributes.
     */
    function getActualSurveys(surveys) {
      if (isNil(surveys)) {
        return []
      }

      return surveys
        .filter((s) => s != null && s.attributes != null)
        .filter(
          (s) =>
            'surveygizmoID' in s.attributes ||
            'twowaysmsID' in s.attributes ||
            'surveypalID' in s.attributes,
        )
    }
  }

  attachStatisticsDataToSurveys(surveys) {
    this.setState({
      isLoading: true,
    })

    let promises = surveys.map((survey) => this.getResponseRatesOf(survey))

    Promise.all(promises).then((surveysWithStatistics) => {
      surveysWithStatistics.forEach((s) => {
        cleanUpPagination(s)
      })

      this.setState({
        surveys: surveysWithStatistics,
        hasPendingChanges: false,
        isLoading: false,
      })
    })

    function cleanUpPagination(survey) {
      survey.latestPage = undefined
      survey.wasLastPage = false
    }
  }

  /**
   * Note: the endpoint supports passing multiple survey IDs into a request,
   * but from the end-users perspective of large tenants,
   * it may be faster to run them at smaller batches.
   *
   * For now, we'll do it one at a time.
   */
  getResponseRatesOf(survey) {
    let resultSurvey = cloneDeep(survey)
    resultSurvey.statistics = {
      sent: 0,
      answered: 0,
    }
    resultSurvey.hasError = false

    return post('POST_RESPONSE_RATES', {
      surveyIds: [survey.id],
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      where_meta: this.state.whereMeta,
    })
      .then((res) => {
        if (
          res != null &&
          Array.isArray(res) &&
          !isEmpty(res) &&
          !isNil(res[0]) &&
          !isEmpty(res[0])
        ) {
          resultSurvey.statistics = {
            sent: res[0].sent,
            answered: res[0].answered,
            rate: res[0].rate,
          }
        }

        return resultSurvey
      })
      .catch((e) => {
        console.error(e)
        resultSurvey.hasError = true
        return resultSurvey
      })
  }

  handleToggleFilters = (isExpanded) => {
    if (!isExpanded && this.state.hasPendingChanges && !this.state.isLoading) {
      this.attachStatisticsDataToSurveys(this.state.surveys)
    }
  }

  handleChangeDate = (date, isStartDate) => {
    this.setState({
      startDate: isStartDate ? date : this.state.startDate,
      endDate: !isStartDate ? date : this.state.endDate,
      hasPendingChanges: true,
    })
  }

  handleChangeMetadata = (whereMeta, isExpanded = true) => {
    this.setState(
      {
        whereMeta,
        hasPendingChanges: true,
      },
      () => {
        this.handleToggleFilters(isExpanded)
      },
    )
  }

  handleApplyFilters = () => {
    if (this.state.hasPendingChanges) {
      this.attachStatisticsDataToSurveys(this.state.surveys)
    }
  }

  handleUpdatePageOfRow = (surveyId, response) => {
    if (!surveyId) return

    let copyOfSurveys = cloneDeep(this.state.surveys)
    let surveyInQuestion = copyOfSurveys.find((s) => s.id === surveyId)

    if (!surveyInQuestion) return

    surveyInQuestion.latestPage = response?.page ?? response?.number ?? 0

    // length will be 2 since it will be split to the header row and the empty row which is always included at the end
    surveyInQuestion.wasLastPage = response?.csv?.split('\n').length === 2 ?? true

    this.setState({
      surveys: copyOfSurveys,
    })
  }

  render() {
    return (
      <div data-cy='responseRate'>
        <br />
        <div>
          <ModuleFilterBar
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            selections={this.state.whereMeta}
            hasRestrictedFilters={true}
            doHideIcons={true}
            customDatePickerOptions={{ min: new Date(DASHBOARDCONSTANTS.RESPONSE_RATE_START_DATE) }}
            areChangesImmediate={false}
            canChangeFilters={!this.state.isLoading}
            onChangeDate={this.handleChangeDate}
            onChangeMetadata={this.handleChangeMetadata}
            onToggleExpansion={this.handleToggleFilters}
            onClearMetadataFilter={this.handleChangeMetadata}
            onApplyFilters={this.handleApplyFilters}
          />
        </div>

        {this.state.isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {this.state.hasPendingChanges && (
              <>
                <div>
                  <span className='red-text'>
                    Click <b>Apply</b> to fetch results.
                  </span>
                </div>
                <br />
              </>
            )}

            <ResponseRateTable
              cdbs={this.props.cdbs}
              surveys={this.state.surveys}
              query={{
                start_date: this.state.startDate,
                end_date: this.state.endDate,
                where_meta: this.state.whereMeta || {},
                as_csv: true,
              }}
              isAllowedExport={this.props.isAllowedExport}
              onDownloadFromRow={(surveyId, response) =>
                this.handleUpdatePageOfRow(surveyId, response)
              }
            />
          </>
        )}
      </div>
    )
  }
}

ResponseRate.defaultProps = {
  isAllowedExport: false,
}

export default ResponseRate
