import React from 'react'
import Rodal from 'rodal'
import {Table, Input, Pagination, Row, Col, Button} from 'react-materialize';

class ContactList extends React.Component {
  constructor(props) {
    super(props)
    const contactsOnPage = 50
    this.state = {
      contacts: props.contacts,
      filteredContacts: props.contacts,
      visibleContacts: props.contacts.slice(0, contactsOnPage),
      items: Math.ceil(props.contacts.length/contactsOnPage),
      contactsOnPage: contactsOnPage,
      page: 1
    }
  }

  handleFieldChange = (event) => {
    const filteredContacts = this.state.contacts.filter(contact => contact.name.toLowerCase().includes(event.target.value.toLowerCase()) || contact.email.toLowerCase().includes(event.target.value.toLowerCase()))
    this.setState({ 
      filteredContacts: filteredContacts,
      visibleContacts: filteredContacts.slice(0, this.state.contactsOnPage),
      items: Math.ceil(filteredContacts.length/this.state.contactsOnPage),
      page: 1
    })
  }

  handlePageChange = (event) => {
    var start = (event - 1) * this.state.contactsOnPage
    var end = Math.min(start + this.state.contactsOnPage - 1, this.state.filteredContacts.length - 1)
    this.setState({
      visibleContacts: this.state.filteredContacts.slice(start, end + 1),
      items: Math.ceil(this.state.filteredContacts.length/this.state.contactsOnPage),
      page: event 
    })
  }

  componentDidUpdate(nextProps){
    if (nextProps.contacts !== this.props.contacts){
      this.setState({ 
        contacts: nextProps.contacts,
        filteredContacts: nextProps.contacts,
        visibleContacts: nextProps.contacts.slice(0, this.state.contactsOnPage),
        items: Math.ceil(nextProps.contacts.length/this.state.contactsOnPage),
        page: 1
      })
    }
  }

  render() {
    const contactIsChecked = (id) => {
      for (let i = 0; i < this.props.checkedContacts.length; i++) {
        if (this.props.checkedContacts[i].id === id) return true;
      }
      return false
    }

    return (
      <div id="adminUserToolsSurveyContacts">

      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={true} 
        onClose={this.props.toggleContactList}
      >

      <h5>Add contacts to survey</h5>

      <link href="//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet"></link>
      <input s={12} data-cy="filter" name="filter" value={this.state.filter} onChange={this.handleFieldChange} placeholder=" &#xf002;   Filter results here"/>

        <Table className='table striped z-depth-1'>
          <thead>
            <tr>
              <th>Name</th>  
              <th>Email</th>
              <th>Phone</th>
              <th></th>
            </tr>
          </thead>
          <tbody data-cy="contactlist">
            {this.state.visibleContacts.map(contact =>    
              <tr key={contact.id}>
                <td>{contact.name}</td>
                <td>{contact.email}</td>
                <td>{contact.phone}</td>
                <td><Input name='checkbox' type='checkbox' checked={contactIsChecked(contact.id)} onChange={(e) => this.props.handleCheckboxChange(contact.id, contact.name, contact.email, contact.phone, contact.meta, e.target.checked)} label='to survey' /></td>
              </tr>)}
          </tbody>
        </Table>
        <div className="results">Showing {this.state.visibleContacts.length} / {this.state.filteredContacts.length} results.</div>
        <div data-cy="pagination"><Pagination onSelect={this.handlePageChange} activePage={this.state.page} items={this.state.items} className="center"/></div>
          <Row className="center">
            <Col s={12}>
              <Button onClick={this.props.toggleContactList}>Close</Button>
            </Col>
          </Row>
        </Rodal>
      </div>
    )
  }
}

export default ContactList