// disable any to avoid problems with props handling inside react wrapper
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import ConfirmationModal from '../../../../common/ConfirmationModal/ConfirmationModal'
import SettingsModal from './SettingsModal'
import { SettingsToolsProvider } from './SettingsContext'
import { cloneDeep, isEqual } from 'lodash'
import { Module, ModuleType, QuickTimeframe } from '../../Group/groupModuleTypes'
import FilterBar from '../../Group/filter/FilterBar'
import { FilterArray, getFilterArrayStartDate } from '../../../../../react-services/filterService'
import { IconButton as OriginalIconButton, styled, Tooltip } from '@mui/material'
import { Delete, Settings } from '@mui/icons-material'
import { tCommon, tModule } from '../../../../../../languages/i18n'

import css from './SettingsWrapper.module.scss'

const IconButton = styled(OriginalIconButton)(() => ({
  '&:focus': {
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
  },
  '&:active': {
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
  },
}))

type SettingsWrapperProps = {
  module: Module
  children: JSX.Element
  moduleType: ModuleType
  saveModule: (module: Module) => void
  index: string | number | null
  inGroup: boolean
  isReportMode: boolean
  isScreenMode: boolean
  centerTitle?: boolean
  subtitle?: string
  moveModuleUp: (m: Module) => void
  moveModuleDown: (m: Module) => void
  deleteModule: (m: Module) => void
  isTitleCollapsed?: boolean
}

const SettingsWrapper = memo(
  ({
    index,
    module,
    inGroup,
    children,
    moduleType,
    saveModule,
    isReportMode,
    isScreenMode,
    deleteModule,
    centerTitle = false,
    subtitle,
    moveModuleUp,
    moveModuleDown,
    isTitleCollapsed = false,
  }: SettingsWrapperProps) => {
    const options = module.options
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false)
    const [ModuleComponent, setModuleComponent] = useState<JSX.Element | null>(null)
    const settingsWrapperRef = useRef<HTMLDivElement>()
    const handleSettingsClick = () => setIsSettingsModalOpen(true)
    const handleSettingsClose = () => setIsSettingsModalOpen(false)
    const handleDeleteClick = () => setIsConfirmationModalOpen(true)
    const handleCancelDelete = () => setIsConfirmationModalOpen(false)
    const handleConfirmDelete = () => {
      setIsConfirmationModalOpen(false)
      deleteModule(module)
    }

    const prevProps = useRef<any>()
    useEffect(() => {
      if (!isEqual(prevProps.current, children.props)) {
        prevProps.current = cloneDeep(children.props)
        setModuleComponent(children)
      }
    }, [children.props, settingsWrapperRef.current])

    const Component = useMemo(() => {
      if (ModuleComponent) {
        return React.cloneElement(
          (function () {
            return ModuleComponent
          })(),
          { settingsWrapperRef },
        )
      } else return <div></div>
    }, [ModuleComponent, settingsWrapperRef])

    const handleSaveFilters = (filters: FilterArray) => {
      const newModule = cloneDeep(module)
      newModule.settings = filters
      const oldStartDate = getFilterArrayStartDate(module.settings)
      const newStartDate = getFilterArrayStartDate(filters)
      const oldEndDate = getFilterArrayStartDate(module.settings)
      const newEndDate = getFilterArrayStartDate(filters)
      if (oldStartDate !== newStartDate || oldEndDate !== newEndDate) {
        delete newModule.quicktimeframebutton
        delete newModule.timeframe
      }
      saveModule(newModule)
    }

    const handleSaveQuickTimeFrame = (quickTimeFrame: QuickTimeframe) => {
      const newModule = cloneDeep(module)
      newModule.quicktimeframebutton = quickTimeFrame
      saveModule(newModule)
    }

    const disablePadding = module.type === ModuleType.SUMMARY
    if (isReportMode || isScreenMode) return <>{ModuleComponent}</>
    return (
      <div
        className={`${css.cntr} ${isSettingsModalOpen ? css.moduleFocus : ''} ${
          module.options?.isTittleCollapsed || isTitleCollapsed ? css.cntrCollapsed : ''
        } ${inGroup ? '' : css.cntrNotGrouped}`}
        data-testid='moduleHoverIcons'
        data-cy='moduleHoverIcons'
        ref={(element) => (settingsWrapperRef.current = element ? element : undefined)}
      >
        {isConfirmationModalOpen && (
          <ConfirmationModal
            title='Are you sure you want to delete the module?'
            info='Click Delete or press Enter to confirm delete'
            confirm='Delete'
            enableEnterConfirmation
            handleConfirmation={handleConfirmDelete}
            handleCancel={handleCancelDelete}
          />
        )}
        {isSettingsModalOpen && (
          <SettingsToolsProvider
            initialValue={{
              moveModuleUp,
              moveModuleDown,
              module,
              saveModule,
              moduleType,
              inGroup,
              index,
            }}
          >
            <SettingsModal
              onClose={handleSettingsClose}
              options={options}
              module={module}
              index={index}
              handleDeleteClick={handleDeleteClick}
              moveModuleDown={moveModuleDown}
              moveModuleUp={moveModuleUp}
              saveModule={saveModule}
              settingsWrapperRef={settingsWrapperRef}
            />
          </SettingsToolsProvider>
        )}
        {!inGroup && module.type !== ModuleType.SUMMARY && (
          <FilterBar
            selectedQuickTimeFrame={module.quicktimeframebutton}
            isSmall={false}
            filters={module.settings}
            showQuickTimeButtons
            showFilterToggle
            showTimeDisplay
            saveFilters={handleSaveFilters}
            onSelectQuickTimeFrame={handleSaveQuickTimeFrame}
          />
        )}
        <div
          className={`${css.btns_and_title} ${
            !centerTitle ? '' : css.titleCenter
          } modulegroup-submodule-title ${module.options?.isTittleCollapsed ? css.collapse : ''}`}
          data-testid='moduleTitle'
          style={disablePadding ? { padding: 0 } : {}}
        >
          {!!module.title && (
            <div className='module-title' data-testid='moduleTitleText'>
              {module && module.title ? tModule(module.title) : ''}
            </div>
          )}
          {!!subtitle && (
            <div
              className={`module-title-subtitle ${css.subtitle}`}
              data-testid='moduleSubtitleTitleText'
            >
              {tModule(subtitle)}
            </div>
          )}
        </div>
        <div className={css.btns}>
          <Tooltip title={tCommon('info.openModuleSettings')}>
            <IconButton
              sx={{
                paddingRight: '2px',
                paddingLeft: '2px',
                paddingBottom: '2px',
                paddingTop: '2px',
                opacity: 0,
              }}
              onClick={handleSettingsClick}
              className={css.btn}
              data-testid='groupModuleSettings'
              data-cy='groupModuleSettings'
              color='secondary'
            >
              <Settings />
            </IconButton>
          </Tooltip>
          <Tooltip title={tCommon('info.deleteModule')}>
            <IconButton
              sx={{
                paddingRight: '2px',
                paddingLeft: '2px',
                paddingBottom: '2px',
                paddingTop: '2px',
                opacity: 0,
              }}
              className={css.btn}
              onClick={handleDeleteClick}
              data-testid='moduleDeleteIcon'
              color='secondary'
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </div>

        <div className={css.module} style={disablePadding ? { padding: 0 } : {}}>
          {!!ModuleComponent && settingsWrapperRef.current ? Component : <div></div>}
        </div>
      </div>
    )
  },
)
SettingsWrapper.displayName = 'SettingsWrapper'
export default SettingsWrapper
