import React from 'react'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { ToastPosition, Theme, ToastTransitionProps } from 'react-toastify/dist/types'
import ToastifyStylesContainer from './ToastifyStylesContainer'

type NotificationContainerProps = {
  id: string
  position?: ToastPosition
  theme?: Theme
  autoClose?: number
  hideProgressBar?: boolean
  limit?: number
  slide?: ({ ...props }: ToastTransitionProps) => JSX.Element
  [key: string]: unknown
}

const NotificationContainer = ({
  id,
  position = toast.POSITION.BOTTOM_RIGHT,
  theme = 'colored',
  autoClose = 4000,
  hideProgressBar = true,
  limit = 3,
  slide = Slide,
  ...rest
}: NotificationContainerProps) => (
  <ToastifyStylesContainer>
    <ToastContainer
      enableMultiContainer
      containerId={id}
      position={position}
      theme={theme}
      autoClose={autoClose}
      hideProgressBar={hideProgressBar}
      limit={limit}
      transition={slide}
      {...rest}
    />
  </ToastifyStylesContainer>
)

export default NotificationContainer
