import { PointClickCallbackFunction, PointOptionsObject } from 'highcharts8'
import { cloneDeep } from 'lodash'

// To force redraw
let idIncrement = 0

export enum ActionTypes {
  SET_CHART_TYPE = 'SET_CHART_TYPE',
  SET_CHART_DATA = 'SET_CHART_DATA',
  SET_TITLE = 'SET_TITLE',
  SET_LANGUAGE = 'SET_LANGUAGE',
}

export enum ChartTypes {
  PIE = 'pie',
}

interface SetTypeAction {
  type: ActionTypes.SET_CHART_TYPE
  chartType: ChartTypes.PIE
}

interface SetLanguageAction {
  type: ActionTypes.SET_LANGUAGE
}

export type TopicPlusCount = [string, number]
// export type TopicCountAndSelected = { name: string; y: number; selected: boolean; sliced: boolean }
interface SetDataAction {
  type: ActionTypes.SET_CHART_DATA
  data: {
    pointOptions: PointOptionsObject[]
    replaceTextForOther?: string
    replaceTextForUncategorized?: string
  }
}

interface SetTitleAction {
  type: ActionTypes.SET_TITLE
  data: string
}

export type ClickFunction = {
  handleChartSliceClick: PointClickCallbackFunction | null
}

type ReducerAction = SetTypeAction | SetDataAction | SetTitleAction | SetLanguageAction

export const frequencyChartReducer = (
  state: Highcharts.Options,
  action: ReducerAction,
): Highcharts.Options => {
  const newState = cloneDeep(state)
  switch (action.type) {
    case ActionTypes.SET_CHART_TYPE: {
      if (newState.series) {
        newState.series[0].type = action.chartType
      }
      return newState
    }

    case ActionTypes.SET_CHART_DATA: {
      if (newState.series && newState.series[0].type === ChartTypes.PIE) {
        const newOtherName = action.data.replaceTextForOther
        const newUncategorizedName = action.data.replaceTextForUncategorized
        const newData = action.data.pointOptions.map<PointOptionsObject>((p) => {
          let topicName = p.name
          if (newOtherName && p.name?.toString().toLocaleLowerCase() === 'other') {
            topicName = newOtherName
          }
          if (newUncategorizedName && p.name?.toString().toLocaleLowerCase() === 'uncategorized') {
            topicName = newUncategorizedName
          }
          return {
            ...p,
            name: topicName,
          }
        })
        newState.series[0].data = newData
      }
      return newState
    }

    case ActionTypes.SET_TITLE: {
      if (!newState.title) newState.title = {}
      if (action.data) newState.title.text = action.data
      return newState
    }

    case ActionTypes.SET_LANGUAGE: {
      const newState = cloneDeep(state)
      const pieSeries = newState.series ? newState.series[0] : null
      if (!pieSeries || pieSeries.type !== 'pie') return newState
      pieSeries.id = `pie-${idIncrement++}`
      if (newState.series) newState.series[0] = pieSeries
      return newState
    }

    default:
      return state
  }
}
