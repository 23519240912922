import React from 'react'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoTopicSelection } from '../PietabularNotifications'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import { isStringArray } from '../../../../../utilities'
import { PietabularModuleSettings } from '../PietabularModuleContext'

import modal_css from './../../../DashboardModules/_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/CheckBoxSelectionList.module.scss'
import { PietabularModule } from '../pietabularTypes'

const TopicsForDataFilterSelectionList = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const pietabularModule = module as PietabularModule
  const options = module?.options ? (module.options as PietabularModuleSettings) : null
  const selectedCategories = options?.openCategories || []
  const { configCategories, loading, error } = useOpenCategories()
  const allCategories = configCategories ? configCategories.categories : []
  const hasError = !!error

  const handleClick = (topicName: string) => {
    if (!pietabularModule) return
    let newTopics = [] as string[]
    if (selectedCategories.includes(topicName)) {
      newTopics = selectedCategories.filter((topic) => topic !== topicName)
    }
    if (!selectedCategories.includes(topicName)) {
      newTopics = selectedCategories.concat(topicName)
    }
    const newModule = {
      ...pietabularModule,
      options: { ...pietabularModule.options, openCategories: newTopics },
    }
    saveModule(newModule)
  }

  const hasSelectedCategories =
    selectedCategories && isStringArray(selectedCategories) && selectedCategories.length

  return (
    <div className={modal_css.max}>
      <TextWithInfo
        text='Filter answers by topic'
        info={infoTopicSelection}
        tooltipDirection='top'
      />
      <RequiredStar />
      {loading && <LoadingIndicator />}
      {hasError && <h5>{error}</h5>}
      {!loading && !hasError && (
        <div className={`${css.list} ${hasSelectedCategories ? '' : modal_css.required}`}>
          {allCategories.sort().map((category) => (
            <CheckBoxItem
              settingsName=''
              key={category}
              text={category}
              data_testid={'topic-checkbox-item'}
              id={category}
              classNames={css.item}
              onClickLabel={handleClick}
              checked={selectedCategories.includes(category)}
            />
          ))}
          <CheckBoxItem
            settingsName=''
            key={'uncategorized'}
            text={'uncategorized'}
            data_testid='topic-checkbox-item'
            id={'uncategorized'}
            classNames={css.item}
            onClickLabel={handleClick}
            checked={selectedCategories.includes('uncategorized')}
            isBlackAndBold
          />
        </div>
      )}
    </div>
  )
}

export default TopicsForDataFilterSelectionList
