import React, { useEffect } from 'react'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import MetaKeysSelectionList from '../../_shared/ModuleSettings/components/MetaKeysSelectionList'
import { InfoSettingsMetaKeysSelection } from '../OpenNotifications'
import SelectNonMetaColumns from './SelectNonMetaColumns'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import LoadingIndicator from '../../../../_shared/Infos/LoadingIndicator'
import SelectCategories from './SelectCategories'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import KpiSelectionTreeData from './KpiSelectionTreeData'
import { cloneDeep } from 'lodash'

const OpenSettings = () => {
  const {
    answerCategories,
    // error: categoriesError,
    loading: isLoadingCategories,
  } = useOpenCategories()
  const [{ module, saveModule }] = useSettingsTools()
  // const { toastifyId } = useToastId()
  useEffect(() => {
    if (module && !module.options) {
      const newModule = cloneDeep(module)
      newModule.options = {}
      newModule.options.modulewidth = '6'
      saveModule(newModule)
    }
  }, [])

  return (
    <>
      <Subtitle text='Data' />
      <Linebreak />
      <KpiSelectionTreeData />
      {isLoadingCategories ? (
        <LoadingIndicator />
      ) : answerCategories ? (
        Object.keys(answerCategories).map((category) => (
          <SelectCategories
            key={category}
            allCategories={answerCategories[category]}
            propertyName={`${category}CategoryFilter`}
            title={category.charAt(0).toUpperCase() + category.slice(1)}
          />
        ))
      ) : (
        <div></div>
      )}

      <Subtitle text='Columns' />
      <Linebreak />
      <MetaKeysSelectionList
        isRootProperty
        propertyName='metacolumns'
        text='Meta columns to show'
        info={InfoSettingsMetaKeysSelection}
      />
      <SelectNonMetaColumns />
    </>
  )
}

export default OpenSettings
