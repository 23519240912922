/* eslint-disable @typescript-eslint/no-explicit-any */
// NO CORRECT TYPES FROM LIBRARY
import { tCategory } from '../../../../../../languages/i18n'
import { allChartColors, darkerChartColors } from '../../../../../../styles/variableExport'
import { ExtendedPointOptionsObject } from '../../Wheel/wheelModuleTypes'

const ANIMATION_DURATION = 500
const colors = Object.entries(allChartColors)
  .filter((c) => c[0] !== 'GREY')
  .map((c) => c[1])

interface PointOptionsObjectExtension {
  opacity: number
}
type PointOptionsObjectExtensionEntended = Highcharts.PointOptionsObject &
  PointOptionsObjectExtension

export const createChart = (
  isReportMode: boolean,
  isScreenMode: boolean,
  handleRightClickSlice: (sliceName: string) => void,
  handleChartSliceClick: (e: Highcharts.PointClickEventObject) => void,
): Highcharts.Options => {
  return {
    title: {
      text: '',
    },
    exporting: {
      buttons: {
        contextButton: {
          x: -8,
        },
      },
    },
    accessibility: { enabled: false },
    legend: {
      enabled: true,
      itemMarginBottom: 5,
      symbolHeight: 18,
      symbolWidth: 18,
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'middle',
      floating: true,
      x: 0,
      itemStyle: {
        color: darkerChartColors.GREY,
        fontSize: '13px',
      },
      labelFormatter: function () {
        return tCategory(this.name)
      },
    },
    chart: {
      reflow: true,
      type: 'pie',
      marginLeft: isReportMode || isScreenMode ? 310 : 170,
      spacingLeft: 10,
      animation: {
        duration: ANIMATION_DURATION,
        easing: '',
      },
      events: {
        load: function () {
          // ADD CLICK EVENTS TO CHART LABELS
          const pie = this.series[0]
          if (!pie) return
          pie.points.forEach((p: any) => {
            if (!p || !p.graphic) return
            if (p.dataLabel) {
              p.dataLabel.on('contextmenu', function (e: any) {
                handleRightClickSlice(p.name)
                e.preventDefault()
              })
            }
            p.graphic.on('contextmenu', function (e: any) {
              handleRightClickSlice(p.name)
              e.preventDefault()
            })
          })
        },
        redraw: function () {
          // ADD CLICK EVENTS TO CHART LABELS
          const pie = this.series[0]
          if (!pie) return
          pie.points.forEach((p: any) => {
            if (!p || !p.graphic) return
            if (p.dataLabel) {
              p.dataLabel.on('contextmenu', function (e: any) {
                handleRightClickSlice(p.name)
                e.preventDefault()
              })
            }
            p.graphic.on('contextmenu', function (e: any) {
              handleRightClickSlice(p.name)
              e.preventDefault()
            })
          })
        },
      },
    },
    plotOptions: {
      pie: {
        borderWidth: 2,
        animation: {
          duration: ANIMATION_DURATION,
        },
      },
      series: {
        dataLabels: {
          allowOverlap: true,
          position: 'center',
        },
      },
    },
    tooltip: {
      formatter: function () {
        const options = this.point.options as ExtendedPointOptionsObject
        const n = options.y
        if (!n) return
        const title =
          '<div style="margin-bottom: 1rem;"><strong>' +
          (tCategory(this.point.name) || '') +
          '</strong></div></br>'
        const data =
          "<div style='margin-bottom: 1rem;'><table><tr>" +
          '<td>n: <strong>' +
          (n || '') +
          '</strong></td>' +
          '</tr></table></div>'

        return "<div class='pie-tooltip'>" + title + data + '</div>'
      },
    },
    series: [
      {
        colors: colors,
        type: 'pie',
        data: [],
        name: 'Open answers',
        allowPointSelect: true,
        slicedOffset: 23,
        size: '100%',
        description: 'testing description',
        showCheckbox: true,
        point: {
          events: {
            mouseOver: function () {
              const hoveredPointIndex = this.index
              this.series.data.forEach(function (point) {
                if (point.index !== hoveredPointIndex && point.update)
                  setTimeout(
                    () =>
                      point.update(
                        { opacity: 0.45 } as PointOptionsObjectExtensionEntended,
                        true,
                        true,
                      ),
                    300,
                  )
              })
            },
            mouseOut: function () {
              const hoveredPointIndex = this.index
              this.series.data.forEach(function (point) {
                if (point.index !== hoveredPointIndex && point.update)
                  setTimeout(
                    () =>
                      point.update(
                        { opacity: 1 } as PointOptionsObjectExtensionEntended,
                        true,
                        true,
                      ),
                    300,
                  )
              })
            },
            click: handleChartSliceClick,
          },
        },
        dataLabels: {
          enabled: true,
          distance: -28,
          style: {
            color: 'black',
            textOutline: 'none',
            fontWeight: '500',
            fontSize: '14px',
          },
          alignTo: 'toPlotEdges',
          format: '{point.percentage:.1f}%',
        },
      },
    ],
  }
}
