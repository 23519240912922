import React, { useEffect } from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { useUpdateEffect } from 'react-use'
import { cloneDeep, debounce, isUndefined, set } from 'lodash'
import { Dashboard } from '../../../../../../types'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'

const YaxisLimitsInput = () => {
  const [{ module, saveModule }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  if (module?.type === 'bubble') bubbleModule = module
  const savedMinValue = bubbleModule?.options?.y?.limits?.min
  const savedMaxValue = bubbleModule?.options?.y?.limits?.max
  const [inputMinValue, setInputMinValue] = React.useState<number | undefined>()
  const [inputMaxValue, setInputMaxValue] = React.useState<number | undefined>()

  useEffect(() => {
    if (!bubbleModule) return
    setInputMinValue(savedMinValue)
    setInputMaxValue(savedMaxValue)
  }, [])

  const SAVE_TIMEOUT = 1000
  useUpdateEffect(() => {
    if (!bubbleModule) return
    if (savedMinValue === inputMinValue && savedMaxValue === inputMaxValue) return
    const debouncer = debounce(() => {
      const newModule = cloneDeep(bubbleModule)
      if (!newModule) return
      set(newModule, 'options.y.limits.min', inputMinValue)
      set(newModule, 'options.y.limits.max', inputMaxValue)
      saveModule(newModule)
    }, SAVE_TIMEOUT)
    debouncer()
    return () => {
      debouncer.cancel()
    }
  }, [inputMaxValue, inputMinValue])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, limit: 'min' | 'max') => {
    const input = e.target.value
    const newValue = isUndefined(input) ? input : Number(input)
    if (limit === 'min') setInputMinValue(newValue)
    if (limit === 'max') setInputMaxValue(newValue)
  }

  return (
    <>
      <div className={modal_css.half}>
        Limit min
        <input
          data-testid={'min-input'}
          type='number'
          placeholder={'min limit for axis'}
          value={inputMinValue || ''}
          id={'min-input'}
          onChange={(e) => handleOnChange(e, 'min')}
          className={`${css.input}`}
        />
      </div>
      <div className={modal_css.half}>
        Limit max
        <input
          data-testid={'max-input'}
          type='number'
          placeholder={'max limit for axis'}
          value={inputMaxValue || ''}
          id={'max-input'}
          onChange={(e) => handleOnChange(e, 'max')}
          className={`${css.input}`}
        />
      </div>
    </>
  )
}

export default YaxisLimitsInput
