import React from 'react'
import Rodal from 'rodal'
import { Row, Col, Button } from 'react-materialize';
import { UserManagementTool } from '../../../../../types';

type Props = {
  user: UserManagementTool.User
  closeDeleteUser: () => void
  handleDelete: (id: number) => void
}


const DeleteUser = ({ user, closeDeleteUser, handleDelete }: Props) => {

  return (
    <div>
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={true}
        onClose={closeDeleteUser}
      >
        <h5>Delete {user.username}?</h5>
        <Row>
          <Col s={12}><hr /></Col>
          <Col s={12}></Col>
        </Row>

        <br /><br />
        <Row className="center">
          <Col s={5} offset="s1">
            <Button data-cy="delete" onClick={() => handleDelete(user.id)}>Delete</Button>
          </Col>
          <Col s={5}>
            <Button flat onClick={closeDeleteUser}>Close</Button>
          </Col>
        </Row>
      </Rodal>
    </div>
  )
}

export default DeleteUser