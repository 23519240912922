import React from 'react';
import {Row, Col, Icon} from 'react-materialize';

const LiveDataIndicator = () => {
  return (
    <div className="module-bar-livedata valign-wrapper">
      <Row className="marginless-row">
        <Col s={12}>
          <div className="valign-wrapper live-data-indicator">
            <div data-cy="liveDataIndicator">
              live
            </div>
            <Icon tiny className="icon valign green-text">lens</Icon>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LiveDataIndicator;