import React from 'react'
import { FormMountState } from './BreakPointModal'

import css from './BreakPointModalHeading.module.scss'

type BreakPointModalHeadingProps = {
  formMountState: FormMountState
  hasActiveBp: boolean
}
const BreakPointModalHeading = ({ formMountState, hasActiveBp }: BreakPointModalHeadingProps) => {
  return (
    <div className={css.headings}>
      <div
        className={`${css.leftHeading} ${
          formMountState === FormMountState.OPEN ? css.leftHeading_left : ''
        }`}
      >
        <h4>Module breakpoints</h4>
        <p>Currently selected are highlighted, you can also see all</p>
        <p>other possible breakpoints in the list.</p>
      </div>
      <div
        className={`${css.rightHeading} ${
          formMountState === FormMountState.OPEN ? css.rightHeading_right : ''
        }  `}
      >
        <h4>{hasActiveBp ? 'Edit breakpoint' : 'Add new breakpoint'}</h4>
        <p>You can add new or edit existing breakpoints here. Any</p>
        <p>changes here will be saved for tenant wide.</p>
      </div>
    </div>
  )
}

export default BreakPointModalHeading
