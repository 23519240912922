import React, { useState } from 'react'
import Rodal from 'rodal'
import MainButton from '../../../_shared/Buttons/MainButton'
import '../../../_shared/Modals/Modals.scss'

type Props = {
  onClose: (doExport: boolean) => void
}

const BatchExportModal = ({ onClose }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  return (
    <div className="batch-export-modal">
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={isOpen}
        onClose={() => { setIsOpen(false) }}
        onAnimationEnd={() => {
          if (!isOpen) {
            onClose(false);
          }
        }}>
        <div className="container">
          <h5>Batch Export</h5>
          <div className="divider" />
          <br></br>
          <div className="row">
            <div className="col s9 offset-s1">
              <span>Batch Export enables you to make big exports with only one click. </span>
              <br></br>
              <span>You will get an email when the data export is ready. The data will be available in the WheelQ SFTP server.</span>
            </div>
          </div>

          <div className="row">
            <div className="col s5 offset-s1">
              <MainButton text="Confirm" onClick={() => onClose(true)} />
            </div>

            <div className="col s2 offset-s2">
              <MainButton text="Cancel" isFlat onClick={() => { onClose(false) }} />
            </div>
          </div>
        </div>
      </Rodal>
    </div>
  )
}

export default BatchExportModal;