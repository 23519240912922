import React from 'react'
import LoadingIndicator from '../../../_shared/Infos/LoadingIndicator'
import NoData from '../../../_shared/Infos/NoData'
import { CellClickDetails, CheckedKpiSettings, GroupedData } from './tabularTypes'
import { isEmpty, isNil, upperFirst } from 'lodash'
import { KpiData } from '../../../../../types'
import { useTranslation } from 'react-i18next'

const useTabularTableBuildingTools = (
  grouping: string,
  showN: boolean,
  showTotal: boolean,
  settingsKpis: CheckedKpiSettings[],
  data: KpiData.NumericData | null,
  trendsData: KpiData.NumericData | null,
  decimals: number,
) => {
  let groupedData: null | GroupedData = null
  let groupedTrendsData: null | GroupedData = null
  const { t } = useTranslation()

  const createHeaderWithoutGrouping = (): JSX.Element => {
    const dynamicHeaders: JSX.Element[] = []
    dynamicHeaders.push(
      <th key='tabularHeaderKpiText' colSpan={2}>
        KPI
      </th>,
      <th key='tabularHeaderEmpty' colSpan={2}>
        &nbsp;
      </th>,
    )
    if (showN) {
      dynamicHeaders.push(
        <th
          key='tabularTotalNColumn'
          data-testid='tabularTotalNColumn'
          className='right'
          colSpan={1}
        >
          n
        </th>,
      )
    }
    return <tr>{dynamicHeaders}</tr>
  }

  const createHeaderWithGrouping = (): JSX.Element => {
    let dynamicHeaders: JSX.Element[] = []
    dynamicHeaders = dynamicHeaders.concat([
      <th data-testid='tabularHeaderValue' colSpan={2} key={-1} title={grouping}>
        {upperFirst(t((grouping || '').toLocaleLowerCase(), { ns: 'data' }))}
      </th>,
    ])
    dynamicHeaders = dynamicHeaders.concat(
      settingsKpis.map((kpi) => (
        <th colSpan={2} key={kpi.name} title={kpi.name}>
          {upperFirst(t((kpi.name || '').toLocaleLowerCase(), { ns: 'kpis' }))}
        </th>
      )),
    )
    if (showTotal) {
      dynamicHeaders = dynamicHeaders.concat([
        <th key='totalColumn' className='right-align' colSpan={1}>
          {t('common.label.total')}
        </th>,
      ])
    }
    if (showN) {
      dynamicHeaders = dynamicHeaders.concat([
        <th data-testid='tabularTotalNColumn' key='nColumn' className='right-align' colSpan={1}>
          n
        </th>,
      ])
    }
    return <tr>{dynamicHeaders}</tr>
  }

  const createRowsWithoutGrouping = (
    handleCellClick: (details: CellClickDetails) => void,
  ): JSX.Element[] => {
    if (isDataEmpty()) {
      return [
        <tr key={'nodata'}>
          <td colSpan={getColSpanOfTable()}>
            <NoData />
          </td>
        </tr>,
      ]
    }
    if (!data) {
      return [
        <tr key={'loading'}>
          <td>
            <LoadingIndicator />
          </td>
        </tr>,
      ]
    }

    const newRows = [] as JSX.Element[]
    for (let i = 0; i < settingsKpis.length; i++) {
      const currentKpi = settingsKpis[i]
      const dataMode = currentKpi.dataMode
      const { min, max } = currentKpi.limitValues
      const kpiDataPoint = data.series.find(
        (seriesElement) => seriesElement.name === currentKpi.name,
      )
      let dataValue: string | number = ''
      let nValue: string | number = ''
      let dataTrendValue: number | null = null
      if (
        !kpiDataPoint ||
        !kpiDataPoint.data ||
        !kpiDataPoint.data[0] ||
        !kpiDataPoint.data[0].data ||
        kpiDataPoint.data[0].data[dataMode] === null ||
        kpiDataPoint.data[0].data[dataMode] === undefined
      ) {
        dataValue = 'n/a'
        nValue = 'n/a'
      } else {
        dataValue = kpiDataPoint.data[0].data[dataMode]
        nValue = kpiDataPoint.data[0].data['n'] || 'n/a'
      }
      let kpiTrendDataPoint: KpiData.NumericKpiData | undefined
      if (trendsData && trendsData.series) {
        kpiTrendDataPoint = trendsData.series.find(
          (seriesElement) => seriesElement.name === currentKpi.name,
        )
      }
      if (
        kpiTrendDataPoint &&
        kpiTrendDataPoint.data &&
        kpiTrendDataPoint.data[0] &&
        kpiTrendDataPoint.data[0].data &&
        typeof kpiTrendDataPoint.data[0].data[dataMode] === 'number'
      ) {
        const newTrendValue = Number(kpiTrendDataPoint.data[0].data[dataMode])
        dataTrendValue = !isNaN(newTrendValue) ? newTrendValue : null
      }
      const cells = [] as JSX.Element[]
      const trendArrowClasses = getTrendClass(
        dataValue.toString(),
        (dataTrendValue || 'n/a').toString(),
      )
      const trendIcon = getTrendIcon(dataValue.toString(), (dataTrendValue || 'n/a').toString())
      const cellColorClasses = getTdClasses(dataValue, min, max)
      cells.push(
        <td data-testid={`tabularKpi-${i}`} colSpan={2} key='td1'>
          {currentKpi.name}
        </td>,
      )
      cells.push(
        <td
          onClick={
            dataValue !== 'n/a'
              ? () =>
                  handleCellClick({
                    value: dataValue,
                    rowGroup: currentKpi.name,
                    columnGroup: '',
                    id: currentKpi.id,
                  })
              : undefined
          }
          data-testid={`tabularData-${i}`}
          colSpan={2}
          style={{ cursor: dataValue === 'n/a' ? '' : 'pointer' }}
          key='td2'
          className={`${trendArrowClasses} ${cellColorClasses}`}
        >
          <span className={trendIcon ? 'offset-trend-arrow' : ''}>
            {dataValue === 'n/a'
              ? dataValue
              : Number(dataValue).toFixed(dataMode !== 'avg' ? 0 : decimals)}
          </span>
          {trendIcon && (
            <div className='trendicon'>
              <i data-testid='tabularTrendIcon' className='material-icons tiny'>
                {trendIcon}
              </i>
            </div>
          )}
        </td>,
      )
      if (showN) {
        cells.push(
          <td data-testid={`tabularN-${i}`} key='td3' className='right'>
            {nValue}
          </td>,
        )
      }
      newRows.push(
        <tr key={`tr${i}`} data-testid={`tabularDataRow-${i}`}>
          {cells}
        </tr>,
      )
    }
    return newRows
  }

  const createRowsWithGrouping = (
    handleCellClick: (details: CellClickDetails) => void,
    selectedGroup: string,
  ): JSX.Element[] => {
    if (isDataEmpty()) {
      return [
        <tr key={'nodata'}>
          <td colSpan={getColSpanOfTable()} style={{ textAlign: 'center' }}>
            <NoData />
          </td>
        </tr>,
      ]
    }
    if (!data) {
      return [
        <tr key={'loading'}>
          <td>
            <LoadingIndicator />
          </td>
        </tr>,
      ]
    }

    const newRows = [] as JSX.Element[]
    groupedData = createGroupedData(data)
    groupedTrendsData = trendsData ? createGroupedData(trendsData) : null
    const categories = data.categories

    for (let i = 0; i < categories.length; i++) {
      const newRowCells = [] as JSX.Element[]
      const currentCategory = categories[i]
      if (!groupedData[currentCategory]) continue
      let cellCategory = currentCategory
      if (selectedGroup === 'week' || selectedGroup === 'month' || selectedGroup === 'quarter') {
        cellCategory = currentCategory.split('/').reduce((p, c, i, a) => a[1] + '/' + a[0], '')
      }
      newRowCells.push(
        <td data-testid={`tabularCategory-${i}`} key='groupingColumn' colSpan={2}>
          {cellCategory}
        </td>,
      )
      const kpiCells = settingsKpis.map((kpi, j) => {
        const kpiName = kpi.name
        const dataMode = kpi.dataMode
        const { min, max } = kpi.limitValues
        const dataValue =
          groupedData && groupedData[currentCategory][kpiName] !== undefined
            ? groupedData[currentCategory][kpi.name]
            : 'n/a'
        const dataTrendValue =
          groupedTrendsData &&
          groupedTrendsData[currentCategory] &&
          groupedTrendsData[currentCategory][kpiName] !== undefined
            ? groupedTrendsData[currentCategory][kpiName]
            : 'n/a'

        if (dataValue === 'n/a' || isNaN(Number(dataValue))) {
          return (
            <td colSpan={2} key={`n/a-${j}`} className='center' style={{ cursor: '' }}>
              n/a
            </td>
          )
        } else {
          const trendArrowClasses = getTrendClass(
            dataValue.toString(),
            (dataTrendValue || 'n/a').toString(),
          )
          const trendIcon = getTrendIcon(dataValue.toString(), (dataTrendValue || 'n/a').toString())
          const cellColorClasses = getTdClasses(dataValue, min, max)
          return (
            <td
              onClick={() =>
                handleCellClick({
                  value: dataValue,
                  rowGroup: categories[i],
                  columnGroup: kpiName,
                  id: kpi.id,
                })
              }
              style={{ cursor: 'pointer' }}
              data-testid={`tabularTd-${j}`}
              colSpan={2}
              key={j}
              className={`${cellColorClasses} ${trendArrowClasses}`}
            >
              <span className={trendArrowClasses ? 'offset-trend-arrow' : ''}>
                {isNaN(Number(dataValue))
                  ? 'n/a'
                  : Number(dataValue).toFixed(dataMode !== 'avg' ? 0 : decimals)}
              </span>
              {trendIcon && (
                <div className='trendicon'>
                  <i data-testid='tabularTrendIcon' className='material-icons tiny'>
                    {trendIcon}
                  </i>
                </div>
              )}
            </td>
          )
        }
      })
      newRowCells.push(...kpiCells)
      if (showTotal) {
        const currentTotal = groupedData[currentCategory]['total']
        newRowCells.push(
          <td data-testid='tabularTotalColumn' key='totalColumn' className='right-align'>
            {isNaN(Number(currentTotal)) ? 'n/a' : Number(currentTotal).toFixed(decimals)}
          </td>,
        )
      }
      if (showN) {
        const currentN = groupedData[currentCategory]['n']
        newRowCells.push(
          <td data-testid='tabularTotalNColumn' key='nColumn' className='right-align'>
            {isNaN(Number(currentN)) ? 'n/a' : Number(currentN).toFixed()}
          </td>,
        )
      }
      newRows.push(<tr>{newRowCells}</tr>)
    }
    return newRows
  }

  const createFooterWithoutGrouping = () => {
    if (isDataEmpty() || !data) {
      return null
    }
    const cells = [] as JSX.Element[]
    cells.push(
      <td colSpan={2} key='tabularTotal'>
        Total
      </td>,
    )
    const totals = data.series
      .map((series) => {
        if (!series.data || !series.data[0] || !series.data[0].data) return 'n/a'
        const dataMode = settingsKpis.map((kpi) => kpi.dataMode)[0]
        const currentValue = series.data[0].data[dataMode]
        if (isNaN(Number(currentValue))) return 'n/a'
        else return currentValue
      })
      .reduce<{ sum: number; count: number }>(
        (acc, curr) => {
          if (curr === 'n/a' || isNaN(Number(curr))) return acc
          else
            return {
              sum: acc.sum + Number(curr),
              count: acc.count + 1,
            }
        },
        { sum: 0, count: 0 },
      )
    const total = totals.sum / totals.count
    cells.push(
      <td colSpan={2} key='tabularAvgs' style={{ textAlign: 'center' }}>
        <span>{total.toFixed(decimals)}</span>
      </td>,
    )
    if (showN) {
      cells.push(
        <td colSpan={1} key='tabularFooterN' style={{ textAlign: 'right' }}>
          <span></span>
        </td>,
      )
    }
    return (
      <tr data-testid='tabularTotalsRow' className='totals-row'>
        {cells}
      </tr>
    )
  }

  const createFooterWithGrouping = () => {
    if (!groupedData || typeof groupedData !== 'object' || !Object.values(groupedData).length) {
      return null
    }
    const cells = [] as JSX.Element[]
    cells.push(
      <td colSpan={2} key='tabularTotal'>
        Total
      </td>,
    )
    const kpiNames = settingsKpis.map((kpi) => kpi.name)
    const kpiInitNamesWithCounts = {} as { [kpiName: string]: { sum: number; count: number } }
    kpiNames.forEach((kpiName) => {
      kpiInitNamesWithCounts[kpiName as keyof unknown] = { sum: 0, count: 0 }
    })
    const totals = Object.values(groupedData).reduce((acc, curr) => {
      for (let i = 0; i < kpiNames.length; i++) {
        const kpiName = kpiNames[i]
        const currentValue = curr[kpiName]
        const parsedValue = Number(currentValue)
        if (isNaN(parsedValue)) {
          continue
        } else {
          acc[kpiName] = {
            sum: acc[kpiName].sum + parsedValue,
            count: acc[kpiName].count + 1,
          }
        }
      }
      return acc
    }, kpiInitNamesWithCounts)

    cells.push(
      ...kpiNames.map((kpiName, i) => {
        const total = totals[kpiName].sum / totals[kpiName].count
        return (
          <td colSpan={2} key={`tabularAvgs${i}`} style={{ textAlign: 'center' }}>
            <span>{isNaN(total) ? 'n/a' : total.toFixed(decimals)}</span>
          </td>
        )
      }),
    )
    if (showTotal) {
      cells.push(
        <td colSpan={1} key='tabularFooterTotal' style={{ textAlign: 'right' }}>
          <span></span>
        </td>,
      )
    }
    if (showN) {
      cells.push(
        <td colSpan={1} key='tabularFooterN' style={{ textAlign: 'right' }}>
          <span></span>
        </td>,
      )
    }
    return (
      <tr data-testid='tabularTotalsRow' className='totals-row'>
        {cells}
      </tr>
    )
  }

  const createGroupedData = (newData: KpiData.NumericData) => {
    const groupedData = {} as GroupedData
    for (let i = 0; i < newData.series.length; i++) {
      const currentSeries = newData.series[i]
      const currentKpiName = newData.series[i].name || ''
      const dataMode = settingsKpis.find((kpi) => kpi.name === currentKpiName)?.dataMode
      if (!currentSeries.data || !currentSeries.data.length || !dataMode) continue
      for (let j = 0; j < currentSeries.data.length; j++) {
        const currentCategory = currentSeries.data[j].category || 'no category'
        const currentData = currentSeries.data[j].data
        let currentDataValue: string | number = ''
        let currentN = 0
        if (currentData && (currentData[dataMode] || currentData[dataMode] === 0)) {
          currentDataValue = currentData[dataMode]
          currentN = Number(currentData['n'])
        } else {
          currentDataValue = 'n/a'
        }
        if (!groupedData[currentCategory]) groupedData[currentCategory] = {}
        groupedData[currentCategory][currentKpiName] = Number(currentDataValue)
        groupedData[currentCategory]['n'] = groupedData[currentCategory]['n'] || 0 + currentN
      }
    }
    Object.entries(groupedData).forEach(([categoryKey]) => {
      const currentTotal = Object.entries(groupedData[categoryKey])
        .map(([key, value]) => {
          if (key === 'n') return 'n/a'
          return value
        })
        .filter((value) => value !== 'n/a')
        .reduce((acc, curr, i, arr) => {
          const sum = Number(acc) + Number(curr)
          if (i + 1 === arr.length) {
            return sum / arr.length
          } else {
            return sum
          }
        }, 0)
      groupedData[categoryKey]['total'] = currentTotal
    })
    return groupedData
  }
  const getTdClasses = (value: string | number, min: number | null, max: number | null) => {
    const result = ['center']
    if (value !== 'n/a') {
      if ((min || min === 0) && min > Number(value)) {
        result.push('red-cell')
        result.push('white-text')
      }
      if ((max || max === 0) && max <= Number(value)) {
        result.push('green-cell')
        result.push('white-text')
      }
    }
    return result.join(' ')
  }
  const getTrendClass = (value1: string, value2: string) => {
    if (value1 === 'n/a' || value2 === 'n/a') return ''
    const result = ['cypress-trend-class-locator', 'trend']
    const subtraction = parseFloat(value1) - parseFloat(value2)
    if (subtraction < 0) {
      result.push('down')
    } else if (subtraction === 0) {
      result.push('stable')
    } else {
      result.push('up')
    }
    return result.join(' ')
  }

  const getTrendIcon = (value1: string, value2: string) => {
    if (value1 === 'n/a' || value2 === 'n/a') return ''
    const subtraction = parseFloat(value1) - parseFloat(value2)
    if (subtraction < 0) {
      return 'trending_down'
    } else if (subtraction === 0) {
      return 'trending_flat'
    } else {
      return 'trending_up'
    }
  }

  function isDataEmpty(): boolean {
    return isNil(data) || isEmpty(data.series) || isSeriesEmpty(data.series)
  }

  function isSeriesEmpty(series: KpiData.NumericKpiData[]) {
    return series.reduce((hasNoData, series) => {
      if (series.data && Array.isArray(series.data) && series.data.length) {
        return false
      } else {
        return hasNoData
      }
    }, true)
  }

  const getColSpanOfTable = () => {
    const groupColumnSpan = 1 * 2
    const kpisColumnsSpan = grouping ? settingsKpis.length * 2 : 2
    const nColumnSpan = showN ? 1 : 0
    const totalColumnSpan = showTotal && grouping ? 1 : 0
    return groupColumnSpan + kpisColumnsSpan + nColumnSpan + totalColumnSpan
  }

  return {
    createHeaderWithoutGrouping,
    createHeaderWithGrouping,
    createRowsWithoutGrouping,
    createRowsWithGrouping,
    createFooterWithoutGrouping,
    createFooterWithGrouping,
  }
}

export default useTabularTableBuildingTools
