import React from 'react'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import { Data } from '../../../../../../types'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'

type KpiFormatDropDownProps = {
  title: string
  handleOnChange: (newValue: string) => void
  value: Data.FormatBasic | ''
}

const KpiFormatDropDown = ({ title, value, handleOnChange }: KpiFormatDropDownProps) => {
  const options = [
    { value: 'avg', name: 'Averages' },
    { value: 'n', name: 'Counts' },
    { value: 'sum', name: 'Sums' },
  ]
  return (
    <div className={`${modal_css.third}`}>
      {title}
      <RequiredStar />
      <select
        className={`${css.input} browser-default ${value ? '' : modal_css.required}`}
        onChange={(e) => handleOnChange(e.currentTarget.value)}
        data-testid='kpiFormatDropdownSelect'
        id='kpiFormatDropdownSelect'
        value={value}
      >
        <option key={'emptyOption'} value={''}></option>
        {options.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default KpiFormatDropDown
