import React from 'react'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoEnableShowAllMetaValues } from '../textBoxNotifications'
import KpiSelectionTreeData from './KpiSelectionTreeData'

const TextBoxSettings = () => {
  return (
    <>
      <KpiSelectionTreeData />
      <CheckBoxInput
        settingsName='disableEdit'
        text='Disable editing'
        title={'Hide edit button'}
        data_testid='disableEdit'
        isRootProperty
        width='half'
        id='disableEdit'
      />
      <CheckBoxInput
        settingsName='isAllValuesForMetadata'
        text='Enable show all'
        title={
          <TextWithInfo
            info={infoEnableShowAllMetaValues}
            text='Show all meta values'
            tooltipDirection='left'
          />
        }
        data_testid='isAllValuesForMetadata'
        isRootProperty
        width='half'
        id='isAllValuesForMetadata'
      />
    </>
  )
}

export default TextBoxSettings
