import React, { useRef, useState } from 'react'
import CustomTooltip from '../../../../_shared/Infos/CustomTooltip'
import { useSummaryData } from '../Context/SummaryDataContext'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import useSummaryLanguage from '../useSummaryLanguage'
import { SummaryType } from '../summaryTypes'

import css from './SummaryFilters.module.scss'

export type SummaryViewItemProps = {
  view: SummaryType
}

const SelectViewItem = ({ view }: SummaryViewItemProps) => {
  const [{ saveSettings }] = useSummaryData()
  const [{ summarySettings, filterSummaryTypes, language }, setSummaryFilters] = useSummaryFilters()
  const { t } = useSummaryLanguage(language)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const handleViewAddToList = (e: React.MouseEvent<HTMLElement, MouseEvent>, view: SummaryType) => {
    e.preventDefault()
    e.stopPropagation()
    if (!view) return
    let newViews = [...(filterSummaryTypes || [])]
    if (filterSummaryTypes.map((s) => s.angle).includes(view.angle)) {
      newViews = filterSummaryTypes.filter((filter) => filter.angle !== view.angle)
    } else {
      newViews = [...filterSummaryTypes, view]
    }
    setSummaryFilters((prev) => ({
      ...prev,
      filterSummaryTypes: newViews,
    }))
    if (saveSettings) {
      const newSettings = {
        ...summarySettings,
        filterSummaryTypes: newViews,
      }
      saveSettings(newSettings)
    }
  }

  const handleViewSingleSelect = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    view: SummaryType,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (!view) return
    const newViews = [view] as SummaryType[]
    setSummaryFilters((prev) => ({
      ...prev,
      filterSummaryTypes: newViews,
    }))
    if (saveSettings) {
      const newSettings = {
        ...summarySettings,
        filterSummaryTypes: newViews,
      }
      saveSettings(newSettings)
    }
  }
  const tooltipTimeout = useRef<NodeJS.Timeout | null>(null)
  const handleSetShowTooltip = (value: boolean) => {
    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current)
    tooltipTimeout.current = setTimeout(() => {
      setShowTooltip(value)
    }, 400)
  }

  return (
    <>
      <i
        onMouseEnter={() => handleSetShowTooltip(true)}
        onMouseLeave={() => handleSetShowTooltip(false)}
        data-testid={`view-${view.angle}`}
        className={`material-icons ${
          filterSummaryTypes.map((s) => s.angle).includes(view.angle)
            ? css.view_icon_active
            : css.view_icon_inactive
        }`}
        onContextMenu={(e) => handleViewAddToList(e, view)}
        onClick={(e) => handleViewSingleSelect(e, view)}
      >
        {view.icon}
      </i>
      {showTooltip && <CustomTooltip content={t(view.name)} direction={'right'} />}
    </>
  )
}

export default SelectViewItem
