import React, { useEffect, useMemo, useState } from 'react'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoPastDays } from '../GroupNotifications'
import { GroupModule, ModuleType } from '../groupModuleTypes'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { cloneDeep } from 'lodash'
import useGroupModuleDates from '../useGroupModuleDates'

import css from './../../_shared/ModuleSettings/components/TextInput.module.scss'
import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import {
  setFilterArrayEndDate,
  setFilterArrayStartDate,
} from '../../../../../react-services/filterService'
import { useUpdateEffect } from 'react-use'

const DaysPastInput = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const [value, setValue] = useState<number>()
  const { getPastTimeFrame } = useGroupModuleDates()
  let groupModule: GroupModule | null = null
  if (module?.type === ModuleType.GROUP) groupModule = module

  const timeframedays = useMemo(() => {
    if (!groupModule) return
    const { timeframedays } = groupModule
    return timeframedays
  }, [groupModule])

  useEffect(() => {
    if (!groupModule) return
    setValue(timeframedays)
  }, [!!timeframedays])

  const saveTimeoutRef = React.useRef<NodeJS.Timeout>()
  const SAVE_TIMEOUT = 1000
  useUpdateEffect(() => {
    if (!groupModule) return
    const newModule = cloneDeep(groupModule)
    if (!value) delete newModule.timeframedays
    if (value) {
      delete newModule.timeframedays_future
      delete newModule.quicktimeframebutton
      newModule.timeframedays = value
      if (!newModule.options) newModule.options = {}
      const dates = getPastTimeFrame(value, true)
      let newFilters = newModule.settings || []
      newFilters = setFilterArrayStartDate(newFilters, dates.startDate)
      newFilters = setFilterArrayEndDate(newFilters, dates.endDate)
      newModule.settings = newFilters
    }
    if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    saveTimeoutRef.current = setTimeout(() => {
      saveModule(newModule)
    }, SAVE_TIMEOUT)

    return () => {
      if (saveTimeoutRef.current) clearTimeout(saveTimeoutRef.current)
    }
  }, [value])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const parsedValue = Number(newValue)
    if (isNaN(parsedValue)) return
    if (newValue) {
      setValue(parsedValue)
    } else {
      setValue(undefined)
    }
  }
  return (
    <div className={modal_css.half}>
      <TextWithInfo text='Past days' info={infoPastDays} tooltipDirection='right' />
      <input
        data-testid='timeframedays'
        type='number'
        value={value || ''}
        id='timeframedays'
        onChange={(e) => handleInputChange(e)}
        className={css.input}
      />
    </div>
  )
}

export default DaysPastInput
