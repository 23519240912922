import React, { Component } from 'react';
import { Button, Icon } from 'react-materialize';

export default class ManualSenderButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsOn: false,
    }

    this.handleSend = this.handleSend.bind(this)
  }

  handleSend() {
    this.setState({optionsOn: false})
    this.props.onSend();
  }

  render() {
    return (
      <>
        <div id="manual-survey-bottom">
          <Button data-jt="sendManualSurvey" disabled={this.props.noRecipients || this.props.isSending} className="btn waves-effect waves-light" type="submit" name="action" onClick={this.handleSend}>
            Send
            <Icon className="right">send</Icon>
          </Button>

          <div className="right">
            <Button data-jt="toggleManualSenderOptions" disabled={this.props.isSending || this.props.useContacts} className="waves-effect waves-teal btn-flat" onClick={() => {this.setState({optionsOn: !this.state.optionsOn})}}>
              Options
              <Icon className="right">{!this.state.optionsOn ? 'expand_more' : 'expand_less'}</Icon>
            </Button>
          </div>
        </div>

        <div id="basic-options" style={this.state.optionsOn ? {display: 'block'} : {display: 'none'}}>
          <p>
            <input
              type="checkbox"
              data-jt="toggleAutocomplete"
              id="autocomplete"
              defaultChecked
              onChange={() => {this.props.onToggleOptions({useAutocomplete: !this.props.useAutocomplete})}}
            />
            <label htmlFor="autocomplete">Fill names automatically</label>
          </p>
          <p>
            <input
              type="checkbox"
              data-jt="toggleFlipName"
              id="flip-name"
              disabled={!this.props.useAutocomplete}
              onChange={() => {this.props.onToggleOptions({flipName: !this.props.flipName})}}
            />
            <label htmlFor="flip-name">Flip name order</label>
          </p>
        </div>
      </>
    );
  }
}