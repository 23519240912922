import { tCategory, tKpi } from '../../../../../languages/i18n'
import { darkerChartColors } from '../../../../../styles/variableExport'
import { getTenant } from '../../../../react-services/authService'
import { pSBC } from '../../../../react-services/colorService'

export const createChart = (isReportMode: boolean): Highcharts.Options => {
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      animation: { duration: getTenant() === 'test_frontend' || !!isReportMode ? 0 : 1500 },
      marginRight: 35,
      marginLeft: 55,
      marginTop: isReportMode ? 35 : 60,
      inverted: false,
    },
    navigation: {
      buttonOptions: {
        enabled: isReportMode ? false : true,
      },
    },
    legend: {
      enabled: true,
      padding: 0,
      itemMarginTop: 2,
      itemMarginBottom: 6,
      x: 45,
      width: '95%',
      layout: 'horizontal',
      maxHeight: isReportMode ? 200 : 70,
      align: 'left',
      verticalAlign: 'bottom',
      itemStyle: {
        color: darkerChartColors.GREY,
        fontSize: '13px',
      },
      labelFormatter: function () {
        const currentName = this.name
        return tKpi(currentName, { defaultValue: '' }) || tCategory(currentName)
      },
    },
    lang: {
      noData: 'No data to display',
    },
    exporting: {
      sourceWidth: 1800,
      sourceHeight: 675,
      scale: 1.5,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      buttons: {
        contextButton: {
          y: 0,
          x: -10,
        },
      },
    },
    title: {
      align: 'left',
    },
    subtitle: undefined,
    yAxis: [
      {
        gridLineWidth: 1,
        lineColor: pSBC(0.6, darkerChartColors.GREY) || darkerChartColors.GREY,
        gridLineColor: pSBC(0.6, darkerChartColors.GREY) || darkerChartColors.GREY,
        title: undefined,
        // lineWidth: showAxisLines ? 1 : 0,
        lineWidth: 0,
        labels: {
          style: {
            fontSize: '12px',
            color: darkerChartColors.GREY,
          },
        },
        endOnTick: false,
      },
      {
        gridLineWidth: 0,
        title: undefined,
        // lineWidth: showAxisLines ? 1 : 0,
        lineWidth: 0,
        opposite: true,

        labels: {
          style: {
            fontSize: '12px',
            color: darkerChartColors.GREY,
          },
        },
      },
    ],
    xAxis: {
      title: {
        text: null,
      },
      endOnTick: true,
      startOnTick: true,
      showEmpty: true,
      gridLineWidth: 0,
      showFirstLabel: true,
      labels: {
        style: {
          fontSize: '12px',
          color: darkerChartColors.GREY,
        },
      },
    },

    credits: {
      enabled: false,
    },
    series: [],
    plotOptions: {
      series: {
        animation: { duration: getTenant() === 'test_frontend' || !!isReportMode ? 0 : 1500 },
        animationLimit: 5000,
        pointPlacement: 'on',
        pointStart: 0,
      },
    },
    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
      },
    },
  }
}
