import React from 'react'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'
import { isTimeRes } from '../../../../../utilities'
import { LineChartModule } from '../lineChartTypes'

const options = [
  { value: 'day', name: 'Day' },
  { value: 'week', name: 'Week' },
  { value: 'month', name: 'Month' },
  { value: 'quarter', name: 'Quarter' },
  { value: 'year', name: 'Year' },
]

const DateGroupDowndown = () => {
  const [{ saveModule, module }] = useSettingsTools()
  const lineModule = module as LineChartModule
  const currentQuery = lineModule?.query

  const handleOnChange = (value: string) => {
    if (isTimeRes(value) && currentQuery) {
      const newModule = {
        ...(lineModule || {}),
        query: { ...currentQuery, time_res: value },
        filterGrouping: value,
      }
      saveModule(newModule)
    }
  }

  const currentValue = lineModule?.filterGrouping || lineModule?.query?.time_res || 'month'
  return (
    <div className={`${modal_css.half}`}>
      Date group
      <RequiredStar />
      <select
        className={`${css.input} browser-default ${currentValue ? '' : modal_css.required}`}
        onChange={(e) => handleOnChange(e.currentTarget.value)}
        data-testid='filterGroupingDropdown'
        id='filterGroupingDropdown'
        value={currentValue}
      >
        <option key={'emptyOption'} value={''}></option>
        {options.map(({ value, name }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default DateGroupDowndown
