const FILTERCONSTANTS = {
  SERIES_BY_MAX: 100,
  SERIES_BY_MAX_TOOLTIP: 'Filter too large to group by',
  CONTACT_LIST_LIMIT: 50,
  CONTACT_LIST_HARD_LIMIT: 100,
  SELECT_ALL_NAME: 'All',
  SELECT_ALL_VALUE: '__all'
}

const CUSTOMFILTERSELECTIONS = {
  default: {
    name: 'none',
    id: ''
  },
  custom: {
    name: 'Custom',
    id: 'custom',
    tooltip: {
      root: 'Ignore any predefined filter selections (if they exist)'
    }
  },
  merge: {
    name: 'Merge',
    id: 'merge'
  },
  custom_timeframe: {
    name: 'Custom time frame',
    id: 'custom_timeframe'
  }
};

export {
  FILTERCONSTANTS,
  CUSTOMFILTERSELECTIONS
}