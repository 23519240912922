import React, { memo } from 'react'
import { ToastProvider } from '../../../common/Notification/NotificationContext'
import NotificationContainer from '../../../common/Notification/NotificationContainer'
import { RenderProvider } from './contexts/RenderContext'
import { RootModuleContext, RootProvider } from './contexts/RootContext'
import { GroupModuleProvider } from './contexts/GroupContext'
import GroupModuleCntr, { GroupModuleCntrProps } from './GroupModuleCntr'
import ErrorBoundary from '../../../_shared/Infos/ErrorBoundary'
import { errorLoadingModule } from './GroupNotifications'
import { GroupModule } from './groupModuleTypes'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'
import useNumericKpis from '../../../../stores/useNumericKpis'

type GroupRootModuleProps = {
  saveModule: (module: GroupModule) => void
  moveModuleUp: () => void
  moveModuleDown: () => void
  deleteModule: () => void
} & GroupModuleCntrProps
const GroupRootModule = memo(
  ({
    id,
    index,
    module,
    moveModuleUp,
    moveModuleDown,
    saveModule,
    deleteModule,
    parentFilters,
  }: GroupRootModuleProps) => {
    const { isLoading: isLoadingCalculatedKpis, originalCalculatedKpis } = useCalculatedKpis()
    const { isLoading: isLoadingNumericKpis, numericKpis } = useNumericKpis()

    if (!module || isLoadingCalculatedKpis || isLoadingNumericKpis) return <div></div>
    return (
      <ToastProvider id={module.id || id}>
        <NotificationContainer id={module.id || id} />
        <ErrorBoundary
          message={errorLoadingModule}
          containerId={module.id || id}
          fallback={<div style={{ color: 'red' }}>{errorLoadingModule}</div>}
        >
          <RootProvider
            initRootModule={module}
            toastifyId={module.id || id}
            saveRootModule={saveModule}
            calculatedKpis={originalCalculatedKpis || {}}
            numericKpis={numericKpis || []}
          >
            <RenderProvider>
              <RootModuleContext.Consumer>
                {(rootModule) => {
                  return rootModule ? (
                    <GroupModuleProvider
                      initialValue={{
                        index: 0,
                        isNested: false,
                        module: rootModule,
                      }}
                    >
                      <GroupModuleCntr
                        module={rootModule}
                        id={rootModule.id || id}
                        index={index}
                        parentFilters={parentFilters}
                        isNested={false}
                        moveModuleUp={moveModuleUp}
                        moveModuleDown={moveModuleDown}
                        deleteModule={deleteModule}
                      />
                    </GroupModuleProvider>
                  ) : (
                    <div></div>
                  )
                }}
              </RootModuleContext.Consumer>
            </RenderProvider>
          </RootProvider>
        </ErrorBoundary>
      </ToastProvider>
    )
  },
)
GroupRootModule.displayName = 'GroupRootModule'
export default GroupRootModule
