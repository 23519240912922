import React, { useEffect, useState } from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import useNumericKpis from '../../../../../stores/useNumericKpis'
import useConfigCustomerPath, {
  CustomerPathConfig,
} from '../../../../../stores/useConfigCustomerPath'
import { WheelModule, WheelModuleSettings } from '../wheelModuleTypes'
import SliceKpiSelections from './SliceKpiSelections'
import { isUndefined } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './SliceInputTable.module.scss'

const SliceInputTable = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const wheelModule = module as WheelModule
  const { config: customerPathConfig } = useConfigCustomerPath()
  const { numericKpis } = useNumericKpis()
  const [selectedKpis, setSelectedKpis] = useState<CustomerPathConfig['conf'] | null>(null)
  const [minForAll, setMinForAll] = useState<number | undefined>()
  const [maxForAll, setMaxForAll] = useState<number | undefined>()
  const [editedCategoryAndId, setEditedCategoryAndId] = useState<{
    category: string | null
    kpiId: number | null
  } | null>(null)

  const options = (module?.options || {}) as WheelModuleSettings
  const useConfig = options.isCustomerPathConfigSelected !== false
  const moduleCustomerPathConfig = options.moduleWheelConfig

  useEffect(() => {
    setEditedCategoryAndId(null)
  }, [module?.options])

  useEffect(() => {
    if (customerPathConfig && useConfig) {
      setSelectedKpis(customerPathConfig.conf || [])
      setMinForAll(
        !isUndefined(customerPathConfig.limitValues?.mid)
          ? customerPathConfig.limitValues?.mid
          : customerPathConfig.limitValues?.min,
      )
      setMaxForAll(customerPathConfig.limitValues?.max)
    }
    if (!useConfig && moduleCustomerPathConfig) {
      setSelectedKpis(moduleCustomerPathConfig.conf || [])
      setMinForAll(moduleCustomerPathConfig.limitValues?.min)
      setMaxForAll(moduleCustomerPathConfig.limitValues?.max)
    }
    if (!useConfig && !moduleCustomerPathConfig) setSelectedKpis([])
  }, [customerPathConfig, moduleCustomerPathConfig?.conf, useConfig])

  const getKpiName = (kpiId: number) =>
    numericKpis ? numericKpis.find((kpi) => kpi.id === kpiId)?.name || '' : ''

  const handleCategoryKpiEditClick = (category: string | undefined, kpiId: number | undefined) => {
    if (!category || !kpiId) return
    setEditedCategoryAndId({ category, kpiId })
  }

  const handleAddNewCategoryKpiClick = () => {
    setEditedCategoryAndId({ category: null, kpiId: null })
  }

  const handleCategoryKpiDeleteClick = (
    category: string | undefined,
    kpiId: number | undefined,
  ) => {
    if (!category || !kpiId || !selectedKpis || !wheelModule || !moduleCustomerPathConfig) return
    const newSelectedKpis = selectedKpis.filter(
      (kpi) => !(kpi.category === category && kpi.kpiid === kpiId),
    )
    const newConfig: CustomerPathConfig = { ...moduleCustomerPathConfig, conf: newSelectedKpis }
    saveModule({ ...wheelModule, options: { ...options, moduleWheelConfig: newConfig } })
  }

  const isNewCategoryKpi =
    editedCategoryAndId &&
    editedCategoryAndId.category === null &&
    editedCategoryAndId.kpiId === null
  return (
    <table className={`${modal_css.max} ${css.cntr}`} data-testid='sliceTable'>
      <thead>
        <tr>
          <th className={css.category}>Category</th>
          <th className={css.kpiName}>Kpi Name</th>
          <th className={css.kpiId}>Kpi Id</th>
          <th className={css.minAndMax}>Min</th>
          <th className={css.minAndMax}>Max</th>
          {!useConfig && <th className={css.edit}>Edit</th>}
        </tr>
      </thead>
      <tbody>
        {!!selectedKpis &&
          !!numericKpis &&
          selectedKpis.map((kpi, i) => {
            if (
              editedCategoryAndId &&
              editedCategoryAndId?.category === kpi.category &&
              editedCategoryAndId.kpiId === kpi.kpiid
            ) {
              return (
                <SliceKpiSelections
                  key={kpi.kpiid + kpi.category}
                  category={kpi.category}
                  kpiid={kpi.kpiid}
                  limitValues={kpi.limitValues}
                  handleClearEditerCategory={() => setEditedCategoryAndId(null)}
                />
              )
            } else {
              return (
                <tr
                  key={
                    kpi.kpiid !== undefined && kpi.category !== undefined
                      ? kpi.kpiid + kpi.category
                      : i
                  }
                >
                  <td className={css.category} data-testid='categoryNameCell'>
                    {kpi.category}
                  </td>
                  <td className={css.kpiName} data-testid='categoryKpiNameCell'>
                    {kpi.kpiid !== undefined ? getKpiName(kpi.kpiid) : ''}
                  </td>
                  <td className={css.kpiId} data-testid='categoryKpiIdCell'>
                    {kpi.kpiid}
                  </td>
                  <td className={css.minAndMax} data-testid='categoryMinCell'>
                    {!isUndefined(kpi.limitValues?.mid)
                      ? kpi.limitValues?.mid
                      : !isUndefined(kpi.limitValues?.min)
                      ? kpi.limitValues?.mid
                      : minForAll}
                  </td>
                  <td className={css.minAndMax} data-testid='categoryMaxCell'>
                    {kpi.limitValues?.max !== undefined ? kpi.limitValues.max : maxForAll}
                  </td>
                  {!useConfig && (
                    <td className={css.edit}>
                      <div className={css.editFlex}>
                        <span
                          className={`material-symbols-outlined ${css.icon}`}
                          onClick={() => handleCategoryKpiEditClick(kpi.category, kpi.kpiid)}
                        >
                          edit
                        </span>
                        <span
                          className={`material-symbols-outlined ${css.icon}`}
                          onClick={() => handleCategoryKpiDeleteClick(kpi.category, kpi.kpiid)}
                        >
                          delete
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
              )
            }
          })}
        {!useConfig && (
          <>
            {isNewCategoryKpi ? (
              <SliceKpiSelections
                {...editedCategoryAndId}
                handleClearEditerCategory={() => setEditedCategoryAndId(null)}
              />
            ) : (
              <tr
                className={css.addRow}
                onClick={handleAddNewCategoryKpiClick}
                data-testid='addNewCategoryRow'
              >
                <td className={css.category}>ADD NEW KPI</td>
                <td className={css.add}>
                  <div>
                    <span className='material-symbols-outlined'>add</span>
                  </div>
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  )
}

export default SliceInputTable
