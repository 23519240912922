import React, { useEffect, useMemo, useRef, useState } from 'react'
import SelectionTree, {
  VALIDKPITYPES,
  VALIDSELECTIONTYPES,
} from '../../../../../_shared/TreeView/SelectionTree'
import DoubleModalMain from '../../../../../_shared/Modals/DoubleModalMain'
import useNumericKpis from '../../../../../../stores/useNumericKpis'
import useCalculatedKpis from '../../../../../../stores/useCalculatedKpis'
import { Kpi } from '../../../../../../../types'
import useOpenKpis from '../../../../../../stores/useOpenKpis'
import { isUndefined } from 'lodash'
import ErrorBoundary from '../../../../../_shared/Infos/ErrorBoundary'
import { Button, styled } from '@mui/material'
import { tCommon } from '../../../../../../../languages/i18n'
import { primaryModalColor } from '../../../../../../../styles/variableExport'
import { pSBC } from '../../../../../../react-services/colorService'
import { Cancel, Check } from '@mui/icons-material'

import css from './SelectionTreeInput.module.scss'

const StyledButton = styled(Button)`
  &:focus {
    background: ${pSBC(0.6, primaryModalColor)};
    color: ${pSBC(-0.3, primaryModalColor)};
  }
`

export type kpiIdAndName = { name: string; id?: number }
export type CalculatedKpi = {
  name: string
  kpiIds?: number[] | undefined
  function: string
}
type SelectionTreeInputProps = {
  classNames?: string
  kpiType: VALIDKPITYPES.numeric | VALIDKPITYPES.open | VALIDKPITYPES.all
  excludeCalculatedKpis?: boolean
  selectedKpis: kpiIdAndName[]
  setSelectedKpis: React.Dispatch<React.SetStateAction<kpiIdAndName[] | null>>
  customTitle?: string
}

const SelectionTreeInput = ({
  classNames,
  kpiType,
  excludeCalculatedKpis = false,
  selectedKpis,
  setSelectedKpis,
  customTitle,
}: SelectionTreeInputProps) => {
  const kpisRef = useRef<kpiIdAndName[]>()
  const [isTreeViewOpen, setIsTreeViewOpen] = useState<boolean>(false)
  useEffect(() => {
    if (selectedKpis) {
      kpisRef.current = selectedKpis
    }
  }, [])

  const { calculatedKpis } = useCalculatedKpis()
  const { numericKpis } = useNumericKpis()
  const { openKpis } = useOpenKpis()

  const allKpis: (
    | kpiIdAndName
    | CalculatedKpi
    | {
        id: number
        name: string
        grouping?: string | undefined
      }
  )[] = useMemo(() => {
    if (kpiType === 'all') {
      return [...(numericKpis || []), ...(openKpis || []), ...(calculatedKpis || [])]
    }
    if (kpiType === 'open') {
      return openKpis || []
    }
    if (kpiType === 'numeric' && excludeCalculatedKpis) {
      return numericKpis || []
    }
    if (kpiType === 'numeric' && !excludeCalculatedKpis) {
      return [...(numericKpis || []), ...(calculatedKpis || [])]
    }
    return []
  }, [calculatedKpis, numericKpis, openKpis])

  const selection = useMemo(() => {
    if (!allKpis || !allKpis.length) return []
    const newSelection = allKpis.filter((storedKpi) => {
      let matchingKpiFound = false
      selectedKpis.forEach((selectedKpi) => {
        if ('id' in storedKpi && !isUndefined(selectedKpi.id) && selectedKpi.id === storedKpi.id) {
          matchingKpiFound = true
        }
        if (
          !('id' in storedKpi) &&
          isUndefined(selectedKpi.id) &&
          selectedKpi.name === storedKpi.name
        ) {
          matchingKpiFound = true
        }
      })
      return matchingKpiFound
    })
    return newSelection
  }, [allKpis, selectedKpis])

  const handleCntrClick = () => {
    setIsTreeViewOpen(true)
  }
  const handleOnClose = () => setIsTreeViewOpen(false)

  const handleOnChange = (kpis: (Kpi.Kpi | CalculatedKpi)[], isCancel = false) => {
    let newUniqueKpis = [] as kpiIdAndName[]
    if (isCancel && kpisRef.current) {
      newUniqueKpis = kpisRef.current
    } else {
      newUniqueKpis = handleRemovingDuplicatesFromNewSelection(kpis)
      let kpisClicked: kpiIdAndName[] | null = null
      const isKpiRemovedFromSelection = selection.length > kpis.length
      if (isKpiRemovedFromSelection) {
        kpisClicked = handleExtractingClickedKpisFromNewSelection(selection, kpis)
        newUniqueKpis = handleRemovingClickedKpisFromPreviousKpis(kpisClicked, newUniqueKpis)
      }
    }
    const newSelection = createNewSelectionListAndAddNewSelection(newUniqueKpis)
    setSelectedKpis(newSelection)
  }

  const handleRemovingDuplicatesFromNewSelection = (
    newSelection: (Kpi.Kpi | CalculatedKpi)[],
  ): kpiIdAndName[] => {
    const newUniqueSelection = [] as kpiIdAndName[]
    for (let i = 0; i < newSelection.length; i++) {
      const currentKpi = newSelection[i]
      if (
        !newUniqueSelection.find((kpi) => {
          if (!('id' in currentKpi)) return kpi.name === currentKpi.name
          if ('id' in currentKpi) return kpi.id === currentKpi.id
          return false
        })
      ) {
        newUniqueSelection.push(
          typeof currentKpi === 'string'
            ? { name: currentKpi }
            : { id: 'id' in currentKpi ? currentKpi.id : undefined, name: currentKpi.name },
        )
      }
    }
    return newUniqueSelection
  }

  const handleExtractingClickedKpisFromNewSelection = (
    oldSelection: (
      | kpiIdAndName
      | {
          name: string
          kpiIds?: number[] | undefined
          function: string
        }
      | {
          id: number
          name: string
          grouping?: string | undefined
        }
    )[],
    newSelection: (CalculatedKpi | Kpi.Kpi)[],
  ): kpiIdAndName[] => {
    const handledKpis = oldSelection.filter((kpi) => {
      return !newSelection.find((node) => {
        if (!('id' in kpi) && !('id' in node)) {
          return node.name === kpi.name
        }
        if ('id' in kpi && 'id' in node) {
          if (kpi.id !== node.id || kpi.name !== node.name) return false
          if ('grouping' in kpi && kpi.grouping && kpi.grouping === node.grouping) return true
          if (node.grouping === 'Calculated KPI') return true
          if (!('grouping' in kpi) || ('grouping' in kpi && !kpi.grouping))
            return node.grouping === 'Other'
        }
        return false
      })
    })
    return handledKpis
  }

  const handleRemovingClickedKpisFromPreviousKpis = (
    kpisClicked: kpiIdAndName[] | null,
    uniqueKpis: kpiIdAndName[],
  ): kpiIdAndName[] => {
    if (!kpisClicked) return uniqueKpis
    return uniqueKpis.filter((filteredKpi) => {
      return !kpisClicked.find((clickedKpi) => {
        const { id: filteredId, name: filteredName } = filteredKpi
        const { id: clickedId, name: clickedName } = clickedKpi
        return filteredId === clickedId && filteredName === clickedName
      })
    })
  }

  const createNewSelectionListAndAddNewSelection = (newUniqueKpis: kpiIdAndName[]) => {
    return newUniqueKpis.map((kpi) => {
      const foundMatch = selectedKpis.find((oldKpi) => {
        if (isUndefined(kpi.id) && typeof oldKpi === 'string') return kpi.name === oldKpi
        if (!isUndefined(kpi.id) && typeof oldKpi !== 'string') return kpi.id === oldKpi.id
        return false
      })
      if (!foundMatch) return kpi
      else return foundMatch
    })
  }
  const handleCancelClick = () => {
    if (kpisRef.current && setSelectedKpis) {
      setSelectedKpis(kpisRef.current)
    }
    handleOnClose()
  }

  return (
    <div
      className={`${classNames || ''} ${css.cntr}`}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyUp={(e) => e.stopPropagation()}
      data-testid='dataSelectionCntr'
    >
      {customTitle ? customTitle : 'Data KPIs'}
      <div className={css.btn} onClick={handleCntrClick} data-testid='dataSelectionButton'>
        <div className={css.title}>
          <b>Select questions for data</b>
        </div>
        <i className='material-icons'>insert_chart</i>
      </div>
      {isTreeViewOpen && (
        <DoubleModalMain
          double={false}
          duration={0}
          showMask={true}
          closeOnEsc={true}
          onClose={handleOnClose}
          customMaskStyles={{ opacity: 0 }}
        >
          <>
            <div className={css.tree}>
              <ErrorBoundary
                message={'Error loading the tree selection'}
                containerId={'selectionTreeErrorContainer'}
                fallback={<div style={{ color: 'red' }}>{'Error loading the tree selection'}</div>}
              >
                <SelectionTree
                  selectionType={VALIDSELECTIONTYPES.kpis}
                  selectedKpis={selection}
                  kpiType={kpiType}
                  excludeCalculatedKpis={excludeCalculatedKpis}
                  onSelect={handleOnChange}
                />
              </ErrorBoundary>
            </div>
            <div className={css.btns}>
              <StyledButton
                onClick={handleOnClose}
                id='dataSelectionButtonConfirm'
                variant='contained'
                endIcon={<Check />}
                sx={{ margin: '10px 5px' }}
              >
                {tCommon('button.continue')}
              </StyledButton>
              <StyledButton
                onClick={handleCancelClick}
                id='dataSelectionButtonCancel'
                variant='contained'
                endIcon={<Cancel />}
                sx={{ margin: '10px 5px' }}
              >
                {tCommon('button.cancel')}
              </StyledButton>
            </div>
          </>
        </DoubleModalMain>
      )}
    </div>
  )
}

export default SelectionTreeInput
