/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import Rodal from 'rodal'
import {Input} from 'react-materialize';
import { isEmail, isInternationalPhoneNumber } from '../../../react-services/inputService';
import MetadataBoard from '../../_shared/MetadataBoard/MetadataBoard';
import MainButton from '../../_shared/Buttons/MainButton';
import { WhereMetaSingle, Metadata, WhereMeta } from '../../../../types';

type Props = {
  error: boolean
  toggleAddContact: Function
  handleAddNewContact: Function
  contactMetadata: WhereMeta | null
  onDismissError: (event: React.MouseEvent<HTMLDivElement>) => void
}

type State = {
  name: string
  email: string
  phone: string
  metadata: Metadata[]
  emailErrorText: string | null
  phoneErrorText: string | null
}

class AddContact extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      metadata: [],

      emailErrorText: null,
      phoneErrorText: null,
    }
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
      emailErrorText: event.target.value && !isEmail(event.target.value) ? 'Please provide a valid email address' : null
    })
  }

  handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      phone: event.target.value,
      phoneErrorText: event.target.value && !isInternationalPhoneNumber(event.target.value) ? 'Please provide a phone number with a country code' : null
    })
  }

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value })
  }

  handleMetadataChange = (metadata: Metadata[]) => {
    this.setState({ metadata })
  }

  handleSubmit = () => {
    this.props.handleAddNewContact(this.state.name, this.state.email, this.state.phone, cleanUpMetadata(this.state.metadata));

    function cleanUpMetadata(metas: Metadata[]) {
      const result: WhereMetaSingle = {}
      metas.forEach((m: Metadata) => {
        if (m && m.values && m.values.length > 0)
          result[m.key] = m.values[0]
      })
      return result
    }
  }

  render() {
    const hasErrors = this.state.emailErrorText !== null || this.state.phoneErrorText !== null;
    const hasEmailOrPhone = this.state.email !== '' || this.state.phone !== '';
    const isAbleToSubmit = this.state.name !== '' && hasEmailOrPhone && !hasErrors;

    return (
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={true}
        onClose={this.props.toggleAddContact}
      >
        <h5>Add a new contact</h5>
        <p className="grey-text text-darken-2">
          At least one contact method is required.
        </p>

        <div className="admin-tools-add-contact">
          <Input s={12} m={12} type="text" data-cy="contactName" name="name" label="Name" value={this.state.name} onChange={this.handleNameChange} error={!this.state.name ? 'Required' : ''} />
          <Input s={12} m={6} type="text" data-cy="contactEmail" name="email" label="Email" value={this.state.email} onChange={this.handleEmailChange} error={this.state.emailErrorText} />
          <Input s={12} m={6} type="text" data-cy="contactPhone" name="phone" label="Phone number" value={this.state.phone} onChange={this.handlePhoneChange} error={this.state.phoneErrorText} />

          <div className="row"></div>
          <h6>Metadata</h6>
          <MetadataBoard
            creatables="both"
            onChange={this.handleMetadataChange}
            customFilterSet={this.props.contactMetadata}
            areCustomFiltersMerged={true}
          />

          <div className="row">&nbsp;<br/>&nbsp;</div>
          {this.props.error &&
            <div className="card-panel red white-text add-contact-error-info" onClick={this.props.onDismissError}>
              <i className="material-icons right">close</i>
              Could not add contact. This may be caused by an invalid metadata value. Please change every value to plain text.
              <br/>
              If this problem persists, please refresh the page or contact helpdesk@wheelq.com.
            </div>
          }

          <div className="row center">
            <div className="col s5 offset-s1">
              <MainButton
                text="Submit"
                onClick={() => isAbleToSubmit && this.handleSubmit()}
                isDisabled={!isAbleToSubmit}
                data-testid="addContact"
              />
            </div>
            <div className="col s5">
              <MainButton
                text="Close"
                isFlat
                onClick={this.props.toggleAddContact}
              />
            </div>
          </div>
        </div>
      </Rodal>
    )
  }
}

export default AddContact
