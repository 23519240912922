import React, { useContext, useEffect, useRef, useState } from 'react'
import useCommonDbSettingsConfig from '../../../../../../stores/useCommonDbSettingsConfig'
import DoubleModalMain from '../../../../../_shared/Modals/DoubleModalMain'
import { OpenModuleContext } from '../../../Open/_OpenModuleContext'
import ButtonConversationToggle from '../../ConversationModal/ButtonConversationToggle'
import OpenInspectorMultiTable from './OpenInspectorMultiTable'
import OpenInspectorSingleView from './ValueInspectorSingleView'
import { BLACKLIST } from '../../../../../../react-constants/metadata'
import { Sorting } from '../inspectorTypes'
import OpenInspectorExportView from './OpenInspectorExportView'
import { OpenAnswersRequest, OpenAnswersResponse } from '../../../../../../stores/useNewOpenAnswers'
import useReportingFilters from '../../../../../../stores/useReportingFilters'
import { useUpdateEffect } from 'react-use'
import { TextualKpiData } from '../../../Open/openTypes'
import { OpenAnswer } from '../../../Pietabular/pietabularTypes'
import { tCommon } from '../../../../../../../languages/i18n'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { Close, Download } from '@mui/icons-material'
import { DateRangeIcon } from '@mui/x-date-pickers-pro'

import css from './ValueInspector.module.scss'

type OpenInspectorProps = {
  fetchMore?: () => Promise<boolean>
  isLoadingMore?: boolean
  isConversationModalOpen: boolean
  setIsConversationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  answersData: TextualKpiData[] | null
  singleAnswer: TextualKpiData | null
  setSingleAnswer: React.Dispatch<React.SetStateAction<TextualKpiData | null>>
  onClose: () => void
  inspected: string
  isLoading: boolean
  setSorting: React.Dispatch<React.SetStateAction<Sorting>>
  sorting: Sorting
  query?: OpenAnswersRequest | null
  pagination: OpenAnswersResponse['pageMetadata'] | null
  answersLoaded: number
  customFilterAnswersFunction?: (data: OpenAnswer[]) => OpenAnswer[]
}

const OpenInspector = ({
  isConversationModalOpen,
  setIsConversationModalOpen,
  answersData,
  isLoadingMore,
  singleAnswer,
  setSingleAnswer,
  setSorting,
  sorting,
  fetchMore,
  onClose,
  isLoading,
  inspected,
  query,
  pagination,
  answersLoaded,
  customFilterAnswersFunction,
}: OpenInspectorProps) => {
  const { config } = useCommonDbSettingsConfig()
  const tableCntrRef = useRef<HTMLTableElement>()
  const configOpenMetas = config?.inspector_open_multi?.meta_columns
  const { showInspectorConversation } = useContext(OpenModuleContext)
  const [scrollTop, setScrollTop] = useState<number>(0)
  const scrollTimeoutRef = useRef<NodeJS.Timeout>()

  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false)
  const [metaKeysInData, setMetaKeysInData] = useState<string[] | null>(null)
  const [metaKeysInDataAndConfig, setMetaKeysInDataAndConfig] = useState<string[] | null>(null)
  const { metas: reportingMetas } = useReportingFilters()

  useEffect(() => {
    if (pagination && pagination.page_number !== pagination.total_pages) return
    if (answersData && answersData.length === 1) {
      setSingleAnswer(answersData[0])
    }
  }, [answersData])

  useUpdateEffect(() => {
    setScrollTop(0)
  }, [sorting.column, sorting.order])

  useEffect(() => {
    if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current)
    scrollTimeoutRef.current = setTimeout(() => {
      if (tableCntrRef.current && !singleAnswer) tableCntrRef.current.scrollTo({ top: scrollTop })
    }, 500)
    return () => scrollTimeoutRef.current && clearTimeout(scrollTimeoutRef.current)
  }, [singleAnswer, Boolean(tableCntrRef.current), scrollTop, sorting, isExportModalOpen])

  useEffect(() => {
    if (!answersData) return
    const validMetaKeysInData = getMetaKeysInData(answersData)
    setMetaKeysInData(validMetaKeysInData)
  }, [answersData])

  useEffect(() => {
    if (!metaKeysInData) return
    let newMetas = [] as string[]
    if (configOpenMetas && configOpenMetas.length) {
      const metaKeysInConfig = configOpenMetas.map((metaAndPosition) => metaAndPosition.name)
      const metaKeysInConfigAndData = metaKeysInConfig.filter(
        (key) => metaKeysInData.includes(key) || sorting.column === key,
      )
      newMetas = metaKeysInConfigAndData
    } else if (reportingMetas && Object.keys(reportingMetas).length) {
      const metaKeysInConfigAndData = Object.keys(reportingMetas).filter(
        (key) => metaKeysInData.includes(key) || sorting.column === key,
      )
      newMetas = metaKeysInConfigAndData
    } else {
      newMetas = metaKeysInData
    }

    if (!sorting.column) return setMetaKeysInDataAndConfig(newMetas.sort())
    const sortMeta = sorting.column.substring(5)
    !metaKeysInData.includes(sortMeta) &&
      sorting.column.substring(0, 4) === 'meta' &&
      newMetas.push(sortMeta)
    setMetaKeysInDataAndConfig(newMetas.sort())
  }, [config, metaKeysInData, sorting.column])

  const getMetaKeysInData = (answersData: TextualKpiData[]): string[] => {
    const metaKeys = [] as string[]
    if (answersData && answersData.length > 1) {
      for (let i = 0; i < answersData.length; i++) {
        const answer = answersData[i]
        const metaData = answer.metadata
        if (!metaData) continue
        const metaKeysWithValidValuesFound = Object.entries(metaData)
          .filter((meta) => hasMetaValue(meta[1]))
          .map((meta) => meta[0])
        if (!metaKeysWithValidValuesFound.length) continue
        metaKeysWithValidValuesFound.forEach((key) => !metaKeys.includes(key) && metaKeys.push(key))
      }
    }
    return metaKeys.filter((key) => !BLACKLIST.includes(key))
  }

  const hasMetaValue = (value: unknown): boolean => {
    if (value === null || value === '' || value === undefined) {
      return false
    }
    return true
  }

  const handleAnswerClick = (answer: TextualKpiData) => {
    if (answer) {
      setSingleAnswer(answer)
      setScrollTop(tableCntrRef.current?.scrollTop ? tableCntrRef.current.scrollTop : 0)
    }
  }

  const handleExportClickClick = () => {
    setIsExportModalOpen(true)
    setScrollTop(tableCntrRef.current?.scrollTop ? tableCntrRef.current.scrollTop : 0)
  }

  const handleNavigationBackToTable = () => {
    if (answersData?.length === 1) onClose()
    else if (singleAnswer) setSingleAnswer(null)
    else if (isExportModalOpen) setIsExportModalOpen(false)
    else onClose()
  }
  const handleClosingExportView = () => setIsExportModalOpen(false)

  const getDynamicColumnStyles = () => {
    if (!config) return ''
    if (singleAnswer) return css.singleView
    if (!answersData || answersData.length === 0) return ''
    if (isExportModalOpen) return ''

    if (metaKeysInDataAndConfig && metaKeysInDataAndConfig.length > 0) {
      const numberOfMetas = metaKeysInDataAndConfig.length
      switch (numberOfMetas) {
        case 1:
          return css.one_meta_column
        case 2:
          return css.two_meta_columns
        case 3:
          return css.three_meta_columns
        case 4:
          return css.four_meta_columns
        case 5:
          return css.five_meta_columns
        case 6:
          return css.six_meta_columns
        default:
          return css.many_meta_columns
      }
    }
    return ''
  }

  return (
    <div className={`open-value-inspector-container ${getDynamicColumnStyles()} ${css.cntr}`}>
      {showInspectorConversation && !isConversationModalOpen && (
        <div className={'action_buttons'}>
          {singleAnswer && (
            <ButtonConversationToggle onClick={() => setIsConversationModalOpen(true)} />
          )}
        </div>
      )}
      <DoubleModalMain
        onClose={handleNavigationBackToTable}
        double={isConversationModalOpen}
        animation={isConversationModalOpen ? 'slideRight' : 'slideLeft'}
        showMask={true}
        showCloseButton={false}
      >
        <div className={css.headerBar}>
          <Tooltip title={tCommon('info.inspectorInfo')}>
            <div className={css.headerItem} data-testid='valueInspectorHeader'>
              {tCommon('label.valueInspector').toLocaleUpperCase()}
              &nbsp;&nbsp; &mdash; &nbsp;&nbsp;
              <DateRangeIcon sx={{ verticalAlign: 'middle', marginBottom: '3px' }} />{' '}
              {`${query?.start_date} ${
                query?.start_date !== query?.end_date ? ' - ' + query?.end_date : ''
              }`}
            </div>
          </Tooltip>
          <div className={css.headerItem}>
            {!isExportModalOpen && (
              <Tooltip title={tCommon('button.export')}>
                <IconButton
                  sx={{ padding: '0', fontSize: '28px' }}
                  color='inherit'
                  onClick={handleExportClickClick}
                  data-testid='mainButton-Exportdata'
                >
                  <Download fontSize='inherit' />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={tCommon('button.close')}>
              <IconButton
                sx={{ padding: '0', fontSize: '30px' }}
                onClick={handleNavigationBackToTable}
                className='rodal-close'
                id='rodal-close'
                color='info'
              >
                <Close fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {!isExportModalOpen && !singleAnswer && (
          <div className={css.infoBar}>
            {!!inspected && (
              <p className={css.info}>
                <b>{tCommon('label.inspected')}: </b>
                <span data-testid='openValueInspectorValue'>{inspected}</span>
              </p>
            )}
            {!singleAnswer && !!pagination?.total_elements && (
              <>
                <div data-testid='totalAnswersNumber' className={css.loaded}>
                  <b>{tCommon('label.loaded')}:</b>{' '}
                  {((answersLoaded / pagination.total_elements) * 100).toFixed(0)}
                  {'%, '}
                  <b>{tCommon('label.total')}:</b> {`${answersLoaded}/${pagination.total_elements}`}
                </div>
              </>
            )}
          </div>
        )}
        <>
          {isLoading && (
            <CircularProgress
              size='4rem'
              sx={{ position: 'absolute', left: '50%', top: '150px', opacity: 0.5 }}
              thickness={2}
            />
          )}
          {!singleAnswer && answersData && !isExportModalOpen && metaKeysInDataAndConfig && (
            <div>
              <OpenInspectorMultiTable
                tableCntrRef={tableCntrRef}
                setSorting={setSorting}
                sorting={sorting}
                isLoadingMore={isLoadingMore}
                fetchMore={
                  fetchMore
                    ? () => {
                        const table = tableCntrRef.current
                        if (table) setScrollTop(table.scrollTop)
                        return fetchMore()
                      }
                    : undefined
                }
                answers={answersData}
                handleAnswerClick={handleAnswerClick}
                metaKeysInDataAndConfig={metaKeysInDataAndConfig}
              />
            </div>
          )}
          {!!answersData && !!singleAnswer && !isExportModalOpen && !!metaKeysInDataAndConfig && (
            <div>
              <OpenInspectorSingleView
                inspected={inspected}
                singleAnswer={singleAnswer}
                navToTable={handleNavigationBackToTable}
                showNavButton={answersData.length > 1}
                reportingMetas={reportingMetas ? Object.keys(reportingMetas) : []}
              />
            </div>
          )}
          {answersData && answersData.length === 0 && (
            <div>
              <h4>{tCommon('info.noData')}</h4>
            </div>
          )}
        </>
        {isExportModalOpen && query && (
          <OpenInspectorExportView
            handleClosingExportView={handleClosingExportView}
            customFilterAnswersFunction={customFilterAnswersFunction}
            query={query}
          />
        )}
      </DoubleModalMain>
    </div>
  )
}

export default OpenInspector
