import { useCallback, useEffect, useRef, useState } from 'react'
import { useEffectOnce, useMountedState } from 'react-use'
import { NonResettingStorage } from './nonResettingObservable'

export enum ActionState {
  IDLE,
  LOADING,
  REFETCHING,
  ERROR,
  DONE,
}

type DataState<T> = {
  data: T | null
  state: ActionState
  error: string
}

const initState = {
  data: null,
  state: ActionState.IDLE,
  error: '',
}

export const currentStoresInUse: NonResettingStorage<unknown>[] = []
const useStoreNonResetting = <T,>(
  store: NonResettingStorage<T>,
  key: string,
  user: string,
  tenant: string,
  doFetch = true,
  getFunction?: () => Promise<T>,
  postFunction?: (payload: T) => Promise<unknown>,
  putFunction?: (payload: { value: Partial<T> }) => Promise<T>,
) => {
  const isMounted = useMountedState()
  const [dataState, setDataState] = useState<DataState<T>>(initState)

  const listenerFunc = useCallback((value: T) => {
    setDataState({ data: value, state: ActionState.DONE, error: '' })
  }, [])

  useEffectOnce(() => {
    const isStoreOnList = currentStoresInUse.includes(store as NonResettingStorage<unknown>)
    if (!isStoreOnList) currentStoresInUse.push(store as NonResettingStorage<unknown>)
  })

  const prevKeyRef = useRef<string | null>(null)
  useEffect(() => {
    if (!user || !tenant) return
    const prevKey = prevKeyRef.current
    if (prevKey) store.unsubscribe(listenerFunc, prevKey)
    const unsubscribe = store.subscribe(listenerFunc, key)
    prevKeyRef.current = key
    return () => {
      unsubscribe()
    }
  }, [user, tenant, key])

  useEffect(() => {
    if (!user && !tenant && !doFetch) return
    const storeData = store.get(key)
    if (storeData) return listenerFunc(storeData)
    setDataState((prev) => ({
      ...prev,
      state: prev.data ? ActionState.REFETCHING : ActionState.LOADING,
    }))
    const listeners = store.getRelatedListeners(key)
    const isOnlyListener =
      store.getRelatedListeners(key)?.length === 1 && listeners[0] === listenerFunc
    if (isOnlyListener) get()
  }, [key])

  const get = async () => {
    if (!getFunction) return Promise.resolve()
    return getFunction()
      .then((res) => {
        store.set(res, key)
        return res
      })
      .catch((e) => {
        if (!isMounted()) return
        const genericError = 'Failed to fetch data, data key was: ' + key
        if (typeof e === 'string') setDataState({ data: null, state: ActionState.ERROR, error: e })
        else setDataState({ data: null, state: ActionState.ERROR, error: genericError })
      })
  }

  const post = async (payload: T) => {
    if (!postFunction) return Promise.resolve()
    return postFunction(payload)
      .then(() => {
        store.set(payload, key)
      })
      .catch((e) => {
        if (!isMounted()) return
        const genericError = 'Failed to post data, data key was: ' + key
        if (typeof e === 'string') setDataState({ data: null, state: ActionState.ERROR, error: e })
        else setDataState({ data: null, state: ActionState.ERROR, error: genericError })
      })
  }

  const put = async (payload: { value: Partial<T> }) => {
    if (!putFunction) return Promise.resolve()
    return putFunction(payload)
      .then((res) => {
        store.set(res, key)
        return res
      })
      .catch((e) => {
        if (!isMounted()) return
        const genericError = 'Failed to put data, data key was: ' + key
        if (typeof e === 'string') setDataState({ data: null, state: ActionState.ERROR, error: e })
        else setDataState({ data: null, state: ActionState.ERROR, error: genericError })
      })
  }

  return { dataState, post, get, put }
}

export default useStoreNonResetting
