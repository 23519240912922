import React from 'react'

const InfoIcon = () => (
  <>
    &nbsp;
    <i className='material-icons material-symbols-outlined' style={{ fontSize: '14px' }}>
      info
    </i>
  </>
)

export default InfoIcon
