import React, { Component } from 'react';
import {Row, Col, Icon, Button} from 'react-materialize';
import Rodal from 'rodal';

// type Props = {
//   icon: ?string,
//   onChangeTitle: Function,
// }

// type State = {
//   icon: string,
//   showModal: boolean,
// }

export default class ModuleSettingsButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // default to the gear icon
      icon: this.props.icon ? this.props.icon : 'settings',
      showModal: false,
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((state) => ({
      showModal: !state.showModal,
    }));
  }

  render() {
    return (
      <React.Fragment>
      <div onClick={this.toggleModal}>
        <Icon data-cy="moduleDeleteIcon" className="module-icon-color waves-effect">
          {this.state.icon}
        </Icon>
      </div>

      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={this.state.showModal} 
        onClose={this.toggleModal}
      >
        <h5>Change settings</h5>
        <Row>
          <Col s={6}><hr/></Col>
          <Col s={6}></Col>
        </Row>
        <h6>Nothing to change!</h6>
        {/* Title changing function is passed in props, implement later */}
        <br /><br />
        <Row className="center">
          <Col s={5} offset="s1">
            <Button disabled onClick={this.toggleModal}>Ok</Button>
          </Col>
          <Col s={5}>
            <Button flat onClick={this.toggleModal}>Cancel</Button>
          </Col>
        </Row>
      </Rodal>
      </React.Fragment>
    );
  }
}