import React from 'react'
import GenericButton from '../../../../common/Buttons/GenericButton'

import css from './ButtonControlToggle.module.scss'

type ButtonControlToggleProps = {
  onClick: () => void
}

const ButtonControlToggle = ({ onClick }: ButtonControlToggleProps) => {
  return (
    <GenericButton
      onClick={onClick}
      icon={'tune'}
      text=''
      customClasses={[css.btn]}
      iconPosition={'center'}
      title='Open control dialog'
      data-testid='alert-options-button'
    />
  )
}

export default ButtonControlToggle
