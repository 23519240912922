import React, { useEffect, useMemo, useRef, useState } from 'react'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import {
  TREEROOT,
  treeViewGenerator,
  surveyTreeViewGenerator,
} from '../../../react-services/treeViewService'
import useOpenKpis from '../../../stores/useOpenKpis'
import useNumericKpis from '../../../stores/useNumericKpis'
import useCalculatedKpis from '../../../stores/useCalculatedKpis'
import { isEqual, isUndefined } from 'lodash'
import { Kpi } from '../../../../types'
import { CalculatedKpi } from '../../Dashboards/DashboardModules/_shared/ModuleSettings/components/SelectionTreeInput'

export enum VALIDSELECTIONTYPES {
  kpis = 'kpis',
  surveys = 'surveys',
}

export enum VALIDKPITYPES {
  all = 'all',
  numeric = 'numeric',
  open = 'open',
}

type Node = {
  value: string
  label: string
  children: never[]
}

type SelectionTreeProps =
  | {
      onSelect: (selectedList: (Kpi.Kpi | CalculatedKpi)[]) => void
      selectionType: VALIDSELECTIONTYPES.kpis
      selectedKpis: unknown[]
      selectedSurveys?: unknown[]
      kpiType: VALIDKPITYPES
      excludeCalculatedKpis?: boolean
      surveys?: unknown[]
    }
  | {
      onSelect: (selectedList: unknown[]) => void
      selectionType: VALIDSELECTIONTYPES.surveys
      selectedSurveys: unknown[]
      selectedKpis?: unknown[]
      kpiType?: VALIDKPITYPES
      excludeCalculatedKpis?: boolean
      surveys: unknown[]
    }

const SelectionTree = ({
  onSelect,
  selectionType,
  selectedSurveys,
  selectedKpis,
  kpiType,
  excludeCalculatedKpis = false,
  surveys,
}: SelectionTreeProps) => {
  const treeDisabledRef = useRef<boolean>(false)
  const isTreeDisabled = treeDisabledRef.current
  const [nodes, setNodes] = useState<Node[]>([])
  const [expanded, setExpanded] = useState<string[]>([TREEROOT.value])
  const { openKpis, isLoading: isLoadingOpenKpis } = useOpenKpis()
  const { numericKpis, isLoading: isLoadingNumericKpis } = useNumericKpis()
  const { calculatedKpis, isLoading: isLoadingCalculatedKpis } = useCalculatedKpis()
  const isLoading = useMemo(
    () => isLoadingOpenKpis || isLoadingNumericKpis || isLoadingCalculatedKpis,
    [isLoadingOpenKpis, isLoadingNumericKpis, isLoadingCalculatedKpis],
  )

  useEffect(() => {
    if (isLoading) return
    switch (selectionType) {
      case VALIDSELECTIONTYPES.kpis:
        constructKpiTree()
        break
      case VALIDSELECTIONTYPES.surveys:
        constructSurveyTree()
        break
      default:
        throw new Error('SelectionTree received an invalid selection type: ' + selectionType)
    }
  }, [isLoading, numericKpis, openKpis, calculatedKpis])

  const getCheckedNodes = () => {
    const result = []
    if (selectionType === VALIDSELECTIONTYPES.surveys) {
      for (const index in selectedSurveys) {
        result.push(JSON.stringify(selectedSurveys[index]))
      }
    } else {
      for (const index in selectedKpis) {
        // force any cast to check property
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const kpi: any = selectedKpis[index]
        if (kpi && typeof kpi === 'object' && !kpi.grouping) {
          if (isUndefined(kpi.id))
            result.push(JSON.stringify({ ...kpi, grouping: 'Calculated KPI' }))
          else result.push(JSON.stringify({ ...kpi, grouping: 'Other' }))
        } else {
          result.push(JSON.stringify(kpi))
        }
      }
    }
    treeDisabledRef.current = false
    return result
  }

  const constructKpiTree = async () => {
    if (isLoading) return
    const calculatedKpisWithGrouping = calculatedKpis
      ? calculatedKpis.map((kpi) => ({ ...kpi, grouping: 'Calculated KPI' }))
      : []
    switch (kpiType) {
      case VALIDKPITYPES.all: {
        const numericWithOtherGroup = numericKpis?.map((kpi) =>
          !kpi.grouping ? { ...kpi, grouping: 'Other' } : kpi,
        )
        const openWithOtherGroup = openKpis?.map((kpi) =>
          !kpi.grouping ? { ...kpi, grouping: 'Other' } : kpi,
        )
        const allKpis = {
          kpis: [
            ...(numericWithOtherGroup || []),
            ...(openWithOtherGroup || []),
            ...(!excludeCalculatedKpis ? calculatedKpisWithGrouping : []),
          ],
        }
        setNodes(treeViewGenerator(allKpis))
        break
      }
      case VALIDKPITYPES.numeric: {
        const numericWithOtherGroup = numericKpis?.map((kpi) =>
          !kpi.grouping ? { ...kpi, grouping: 'Other' } : kpi,
        )
        setNodes(
          treeViewGenerator({
            kpis: [
              ...(numericWithOtherGroup || []),
              ...(!excludeCalculatedKpis ? calculatedKpisWithGrouping : []),
            ],
          }),
        )
        break
      }
      case VALIDKPITYPES.open: {
        const openWithOtherGroup = openKpis?.map((kpi) =>
          !kpi.grouping ? { ...kpi, grouping: 'Other' } : kpi,
        )
        setNodes(treeViewGenerator({ kpis: openWithOtherGroup || [] }))
        break
      }
      default:
        throw new Error(`Given KPI type "${kpiType}" is invalid.`)
    }
  }

  const constructSurveyTree = () => {
    setNodes(surveyTreeViewGenerator(surveys))
  }

  const passCheckedValuesDown = (checked: string[]) => {
    if (isEqual(checked, nodeStrings)) return
    if (isTreeDisabled) return
    treeDisabledRef.current = true
    const unStringifiedNodeObjects = []
    for (let index = 0; index < checked.length; index++) {
      try {
        unStringifiedNodeObjects.push(JSON.parse(checked[index]))
      } catch (error) {
        // don't do anything now
      }
    }
    onSelect(unStringifiedNodeObjects)
  }

  const nodeStrings = useMemo(() => getCheckedNodes(), [selectedKpis, selectedSurveys])

  const handleNodeClick = (node: string) => {
    const newNodeString = node.toString()
    const newNodeStrings = [...nodeStrings]
    const foundNode = newNodeStrings.find((nodeString) => nodeString === newNodeString)
    if (foundNode) {
      const index = newNodeStrings.indexOf(foundNode)
      newNodeStrings.splice(index, 1)
    } else {
      if (newNodeString.includes('grouping')) newNodeStrings.push(newNodeString)
      else handleExpandedList(newNodeString)
    }
    passCheckedValuesDown(newNodeStrings)
  }

  const handleExpandedList = (value: string) => {
    const newExpanded = [...expanded]
    const foundNode = newExpanded.find((node) => node === value)
    if (foundNode) {
      const index = newExpanded.indexOf(foundNode)
      newExpanded.splice(index, 1)
    } else {
      newExpanded.push(value)
    }
    setExpanded(newExpanded)
  }

  return (
    <CheckboxTree
      nodes={nodes}
      checked={nodeStrings}
      expanded={expanded}
      onCheck={(checked) => passCheckedValuesDown(checked)}
      onClick={(node) => handleNodeClick(node.value)}
      disabled={isTreeDisabled}
      onExpand={(newExpanded) => setExpanded(newExpanded)}
      icons={{
        check: <i className='material-icons'>check_box</i>,
        uncheck: <i className='material-icons'>check_box_outline_blank</i>,
        halfCheck: <i className='material-icons'>indeterminate_check_box</i>,
        expandClose: <i className='material-icons'>expand_less</i>,
        expandOpen: <i className='material-icons'>expand_more</i>,
        expandAll: null,
        collapseAll: null,
        parentClose: null,
        parentOpen: null,
        leaf: null,
      }}
    />
  )
}

export default SelectionTree
