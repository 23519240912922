import React, { useMemo } from 'react'
import DropdownInput from '../../_shared/ModuleSettings/components/DropdownInput'
import { Languages, SummaryTimePeriod } from '../summaryTypes'
import MetaFilterOptions from './MetaFilterOptions'
import CheckBoxInput from '../../_shared/ModuleSettings/components/CheckBoxInput'
import useConfigCustomerPath from '../../../../../stores/useConfigCustomerPath'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import {
  infoDynamicMetas,
  infoEnableCustomerPath,
  infoShowEmptyMetaValues,
  infoSingleMetaSelect,
} from '../SummaryNotifications'
import TextInput from '../../_shared/ModuleSettings/components/TextInput'
import Linebreak from '../../_shared/ModuleSettings/components/Linebreak'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import KpiSelectionTreeData from './KpiSelectionTreeData'

const SummarySettings = () => {
  const { config } = useConfigCustomerPath()
  const hasConfigForOpenKpiCustomerPath = !!(
    config &&
    Array.isArray(config.open_conf) &&
    config.open_conf.length
  )

  const years = useMemo(() => {
    const firstYear = 2016
    const currentYear = new Date().getFullYear()
    const newYears = [] as string[]
    for (let i = firstYear; i <= currentYear; i++) {
      newYears.push(i.toString())
    }
    return newYears
  }, [])

  return (
    <>
      <TextInput
        settingsName='subtitle'
        title='Subtitle'
        data_testid='subtitle'
        id='subtitle'
        type='text'
      />
      <DropdownInput
        options={Object.values(Languages)}
        settingsName='moduleLanguage'
        title={'Module language'}
        data_testid='moduleLanguage'
        id='moduleLanguage'
      />

      <Subtitle text='Data' />
      <Linebreak />
      <CheckBoxInput
        settingsName='isCustomerPathEnabled'
        text='Enabled'
        defaultValue={false}
        title={
          <TextWithInfo
            text='Customer Path'
            tooltipDirection='right'
            info={infoEnableCustomerPath}
          />
        }
        data_testid='isCustomerPathEnabled'
        width='half'
        id='isCustomerPathEnabled'
        isDisabled={!hasConfigForOpenKpiCustomerPath}
      />

      <KpiSelectionTreeData />
      <TextInput
        settingsName='minAnswersForSummary'
        data_testid='minAnswersForSummary'
        id='minAnswersForSummary'
        width='half'
        title='Number of open answers required'
        type='number'
      />
      <Subtitle text='Filters' />
      <Linebreak />
      <DropdownInput
        options={years}
        settingsName='filterFirstYear'
        title={'First year in filters'}
        data_testid='filterFirstYear'
        id='filterFirstYear'
      />
      <DropdownInput
        options={Object.values(SummaryTimePeriod)}
        settingsName='filterTimePeriod'
        title={'Time period'}
        data_testid='filterTimePeriod'
        id='filterTimePeriod'
      />
      <CheckBoxInput
        settingsName='isSingleMetaSelectEnabled'
        text='Enabled'
        width='third'
        title={
          <TextWithInfo
            info={infoSingleMetaSelect}
            text='Single meta select'
            tooltipDirection='right'
          />
        }
      />
      <CheckBoxInput
        settingsName='isMetaFilterDynamic'
        text='Enabled'
        width='third'
        title={
          <TextWithInfo
            info={infoDynamicMetas}
            text='Dynamic meta filter'
            tooltipDirection='left'
          />
        }
      />
      <CheckBoxInput
        settingsName='isEmptyMetaValuesEnabled'
        text='Enabled'
        width='third'
        title={
          <TextWithInfo
            info={infoShowEmptyMetaValues}
            text='Empty meta values'
            tooltipDirection='left'
          />
        }
      />
      <MetaFilterOptions />
    </>
  )
}

export default SummarySettings
