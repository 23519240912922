import React, { useContext, useEffect, useRef, useState } from 'react'
import { post } from '../../../../react-services/apiService'
import { SENTIMENTS } from '../../../../react-constants/modules'
import { OpenModuleContext } from './_OpenModuleContext'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'
import { TextualKpiData } from './openTypes'
import { cloneDeep, isEqual, upperFirst } from 'lodash'
import { Autocomplete, Chip, FormControl, InputLabel, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useUpdateEffect } from 'react-use'
import { allChartColors } from '../../../../../styles/variableExport'
import { tCategory, tCommon } from '../../../../../languages/i18n'

import css from './OpenCategorySelector.module.scss'

const DELAY_ON_SAVE = 400

const CATEGORY_TYPES = ['sentiment', 'topic', 'custom']

type Props = {
  answer: TextualKpiData
  topics: string[]
  type: string
  customCategory: string
  customCategoryValues: string[]
  onClose: () => void
  onClickOutside: () => void
  onUpdateCategory: (type: string, selections: string[]) => void
}

const OpenCategorySelector = ({
  answer,
  topics,
  type,
  customCategory,
  customCategoryValues,
  onUpdateCategory,
}: Props) => {
  const { t } = useTranslation()
  const { onChangeCategoriesCompleted } = useContext(OpenModuleContext)
  const [search, setSearch] = useState('')
  const delayedSave = useRef<NodeJS.Timeout | null>(null)
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const getSelectOptions = (type: string, topics: string[], customCategoryValues: string[]) => {
    let setOfValues = []

    if (type === CATEGORY_TYPES[0]) {
      setOfValues = SENTIMENTS
    } else if (type === CATEGORY_TYPES[1]) {
      setOfValues = topics
    } else {
      setOfValues = customCategoryValues
    }
    setOfValues = setOfValues.filter((value) => !selectedCategories.includes(value))
    if (search) {
      setOfValues = setOfValues.filter((value) =>
        tCategory(value).toLowerCase().includes(search.toLowerCase()),
      )
    }
    return setOfValues
  }

  const savedCategories = answer.category && answer.category[type] ? answer.category[type] : []
  const categoryKey = customCategory ? customCategory : type
  useEffect(() => {
    setSelectedCategories(savedCategories)
  }, [])

  useUpdateEffect(() => {
    if (isEqual(selectedCategories, savedCategories)) return
    changeCategoriesWithDelay(selectedCategories)
  }, [selectedCategories])

  const changeCategoriesWithDelay = (values: string[]) => {
    trackingService.track(TrackingEvent.ChangeCategories)
    if (delayedSave.current) clearTimeout(delayedSave.current)
    onUpdateCategory(categoryKey, values)
    const payload = {
      [categoryKey]: values,
    }

    delayedSave.current = setTimeout(() => {
      post('POST_OPEN_CATEGORIES', payload, { 'answer-id': answer.answer_id })
        .catch((error: unknown) => {
          console.error(error)
          toast.error(
            'There was a problem while saving the topics & sentiments. Please refresh and try again.',
          )
        })
        .finally(() => {
          const newAnswer = cloneDeep(answer)
          const newCategories = newAnswer.category || {}
          newCategories[type] = values
          newAnswer.category = newCategories
          onChangeCategoriesCompleted(newAnswer)
        })
    }, DELAY_ON_SAVE)
  }

  const onChange = (value: string[]) => {
    const newValues = value
    setSearch('')
    setSelectedCategories(newValues)
  }

  return (
    <div className={css.cntr}>
      <FormControl>
        <InputLabel
          id='category-select-label'
          sx={{
            top: '-13px',
            width: '100%',
            color: allChartColors.GREY,
            fontWeight: 'bold',
          }}
        >
          {upperFirst(t(categoryKey.toLocaleLowerCase(), { ns: 'categories' }))}
        </InputLabel>
        <Autocomplete
          sx={{
            maxWidth: '500px',
            minWidth: '500px',
            paddingTop: '24px',
          }}
          options={getSelectOptions(type, topics, customCategoryValues)}
          multiple
          freeSolo
          getOptionLabel={(option) => tCategory(option)}
          onChange={(_e, v) => onChange(v)}
          renderTags={(value, getTagProps) =>
            value.map((value, i) => (
              <Chip
                variant='outlined'
                label={tCategory(value)}
                {...getTagProps({ index: i })}
                key={value}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='filled'
              fullWidth
              placeholder={tCommon('label.search')}
              onChange={(e) => setSearch(e.target.value)}
            />
          )}
          value={selectedCategories}
        />
      </FormControl>
    </div>
  )
}

export default OpenCategorySelector
