import React, { useMemo } from 'react'
import { useSummaryFilters } from '../Context/SummaryFiltersContext'
import useSummaryLanguage from '../useSummaryLanguage'
import useOpenAnswerCounts from '../../../../../stores/useOpenAnswerCounts'
import Selection from './Selection'
import useSelectionDetails from './useSelectionDetails'
import { GroupBy, OpenAnswersCountsQuery } from '../../Pietabular/pietabularTypes'
import { cloneDeep, isNull, isUndefined } from 'lodash'
import useAnswerCountQuery from '../useAnswerCountQuery'

import css from './SummaryFilters.module.scss'

type DynamicMetaSelectionProps = {
  metaKey: string
  onSelectMeta: (key: string, value: string) => void
}

const DynamicMetaSelection = ({ metaKey, onSelectMeta }: DynamicMetaSelectionProps) => {
  const [{ language, filterMetas, summarySettings }] = useSummaryFilters()
  const { t } = useSummaryLanguage(language)
  const isEmptyMetaValuesEnabled = !!summarySettings.isEmptyMetaValuesEnabled
  const { minAnswersForSummary } = summarySettings

  const { countsQuery } = useAnswerCountQuery()
  const countsQueryForDynamicFilters: OpenAnswersCountsQuery | null = useMemo(() => {
    if (!countsQuery) return null
    const newQuery = cloneDeep(countsQuery) as OpenAnswersCountsQuery
    if (newQuery.where_meta) delete newQuery.where_meta[metaKey]
    newQuery.meta_key = metaKey
    newQuery.group_by = [GroupBy.META_KEY]
    return newQuery
  }, [countsQuery])
  const { counts } = useOpenAnswerCounts(countsQueryForDynamicFilters)

  const { getNumberOfColumns, getSelectionWidth, metaSortPredicate } = useSelectionDetails()

  const metaValues = useMemo(() => {
    if (!counts) return ['']
    return counts.results
      .filter((count) => (count.count || 0) >= (minAnswersForSummary || 0))
      .map((count) => count.meta_value || '')
  }, [counts, minAnswersForSummary])
  const filteredMetaValues = metaValues.filter(
    (metaValue) =>
      !isNull(metaValue) &&
      !isUndefined(metaValue) &&
      (metaValue !== '' || isEmptyMetaValuesEnabled),
  )
  if (!metaValues) return <></>
  return (
    <>
      <Selection
        title={t(metaKey)}
        key={metaKey}
        value={
          filterMetas[metaKey] === '' ? '' : filterMetas[metaKey] ? filterMetas[metaKey] : 'kaikki'
        }
        onSelect={(value: string) => onSelectMeta(metaKey, value)}
        selection={filteredMetaValues.concat(['kaikki']).sort(metaSortPredicate)}
        classNames={css.timeGroupSelect}
        columns={getNumberOfColumns(filteredMetaValues)}
        width={getSelectionWidth(filteredMetaValues)}
        position='bottomRight'
        testid={metaKey + 'Selection'}
      />
    </>
  )
}

export default DynamicMetaSelection
