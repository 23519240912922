export type MessagePayload = {
  messageType: ConversationType
  commentText: string
  clientContactInformation: string | null
  wheelqUserContactInformation: string | null
  conversationId: string
  additionalRecipients: string
  hiddenAdditionalRecipients: string
}

export type Conversation = {
  id: string
  created: number
  completedSurveyId: number
  unreadMessagesCount: number
  lastMessageReceived: number | null
  subject: string | null
}

export type SingleConversationMessage = {
  id: number
  messageType: ConversationType
  messageState: MessageState
  commentText: string
  clientContactInformation: string
  wheelqUserContactInformation: string
  sentAt: string
  conversationId: string
  additionalRecipients: string
  hiddenAdditionalRecipients: string
}

export type NewConversation = {
  completedSurveyId: number
  subject: string
}

export type ConversationsFilter = {
  completedSurveyIds: number[]
}

export type MessagesFilter = {
  conversationIds: string[]
  newerThan: string
  messageStates: MessageState[]
}

export type MessagesUpdate = {
  messageIds: number[]
  messageState: MessageState
}

export type ConversationModalContextProps = {
  completedSurveyId: number | null
  closeConversation: () => void
  unsavedChangesToggle: (bool: boolean) => void
  isUnsavedChanges: boolean
  conversationsList: Conversation[]
  updateConversationsList: (conversations: Conversation[]) => void
  updateMessageCount: (conversations: Conversation[]) => void
  emailForConversation?: string
}

export type ConversationToolsContext = {
  completedSurveyId: number | null
  currentConversationId: string | null
  isTextareaExpanded: boolean
  messageType: ConversationType
  conversationNav: ConversationNavigation
  isUnsavedChanges: boolean
  messageInput: string
  initConversations: Conversation[]
  conversations: Conversation[]
  messages: SingleConversationMessage[]
  emailForConversation?: string
}

type ConversationNavigation = {
  isConversationListOpen: boolean
  isSingleConversationOpen: boolean
}

export type ConversationFunctionsContext = {
  getMessagesById: (conversationId: string) => void
  getConversationsById: (completedSurveyId: number) => void
  updateMessagesListState: (messagesUpdate: MessagesUpdate) => void
  updateConversationsList: (conversations: Conversation[]) => void
  updateMessageCount: (conversations: Conversation[]) => void
  closeConversation: () => void
  unsavedChangesToggle: (bool: boolean) => void
}

export enum ConversationType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  NOTE = 'NOTE',
}

export enum MessageState {
  READ = 'READ',
  UNREAD = 'UNREAD',
}
