import React from 'react'

type SubtitleProps = {
  text: string
  fontSize?:string
}

const Subtitle = ({ text,fontSize='22px' }: SubtitleProps) => {
  return (
    <div
      style={{
        color: '#5a5a5a',
        fontWeight: 'bold',
        fontSize: fontSize,
        marginTop: '10px',
        marginBottom: '-2.2vh',
        opacity: '.8',
        width: '100%',
      }}
    >
      {text}
    </div>
  )
}

export default Subtitle
