import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { RenderList, RenderQueueModule } from '../groupModuleTypes'
import { ActionTypes, initRenderList, renderListReducer } from './renderListReducer'
import { useMountedState } from 'react-use'
import { debounce } from 'lodash'

type ActionFunctionsContext = {
  addModuleToList: (module: RenderQueueModule) => void
  removeModuleFromList: (id: string[]) => void
  updateModuleToIdle: (id: string) => void
  requestToFetch: (id: string) => void
  checkQueueStatuses: () => void
}
export const RenderListContext = createContext<RenderList>({})
export const RenderActionContext = createContext<ActionFunctionsContext>({
  addModuleToList: () => ({}),
  updateModuleToIdle: () => ({}),
  removeModuleFromList: () => ({}),
  requestToFetch: () => ({}),
  checkQueueStatuses: () => ({}),
})

type RenderProviderProps = {
  customInitList?: RenderList
  children: React.ReactNode
}

const DISPATCH_TIMEOUT = 100
export const RenderProvider = ({ customInitList, children }: RenderProviderProps) => {
  const isMounted = useMountedState()
  const [renderList, dispatch] = useReducer(renderListReducer, customInitList || initRenderList)

  const addModuleToList = useCallback((module: RenderQueueModule) => {
    setTimeout(() => {
      if (!isMounted()) return
      dispatch({ type: ActionTypes.ADD, data: { module } })
    }, DISPATCH_TIMEOUT)
  }, [])

  const updateModuleToIdle = useCallback((id: string) => {
    setTimeout(() => {
      if (!isMounted()) return
      dispatch({ type: ActionTypes.UPDATE_TO_DONE, data: { id } })
    }, DISPATCH_TIMEOUT)
  }, [])

  const removeModuleFromList = useCallback((ids: string[]) => {
    setTimeout(() => {
      if (!isMounted()) return
      dispatch({ type: ActionTypes.REMOVE, data: { ids } })
    }, DISPATCH_TIMEOUT)
  }, [])

  const requestToFetch = useCallback((id: string) => {
    setTimeout(() => {
      if (!isMounted()) return
      dispatch({ type: ActionTypes.REQUEST_TO_FETCH, data: { id } })
    }, DISPATCH_TIMEOUT)
  }, [])

  const checkQueueStatuses = useCallback(() => {
    setTimeout(() => {
      if (!isMounted()) return
      dispatch({ type: ActionTypes.CHECK_QUEUE_STATUSES })
    }, DISPATCH_TIMEOUT)
  }, [])

  const actions = useMemo(() => {
    return {
      addModuleToList,
      updateModuleToIdle,
      removeModuleFromList,
      requestToFetch,
      checkQueueStatuses,
    }
  }, [])

  const [list, setList] = useState<RenderList>(renderList)
  useEffect(() => {
    const decouncer = debounce(
      () => {
        setList(renderList)
      },
      DISPATCH_TIMEOUT,
      { maxWait: DISPATCH_TIMEOUT },
    )
    decouncer()
    return () => decouncer.cancel()
  }, [renderList])

  return (
    <RenderActionContext.Provider value={actions}>
      <RenderListContext.Provider value={list}>{children}</RenderListContext.Provider>
    </RenderActionContext.Provider>
  )
}

export const useRenderList = () => {
  const renderList = React.useContext(RenderListContext)
  if (renderList === undefined) {
    throw new Error('useRenderList must be used within a RenderProvider')
  }
  return renderList
}

export const useRenderActions = () => {
  const renderAction = React.useContext(RenderActionContext)
  if (renderAction === undefined) {
    throw new Error('useRenderActions must be used within a RenderProvider')
  }
  const [renderActionState, setRenderActionState] = useState<ActionFunctionsContext>(renderAction)
  useEffect(() => {
    setRenderActionState(renderAction)
  }, [])
  return renderActionState
}
