import { cloneDeep, isEmpty, isNil } from 'lodash'
import { LineChartModule } from './lineChartTypes'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { QUERY_NUMERIC_PAYLOAD } from './../../../../react-constants/emptyPayloads'
import { Query } from '../../../../../types'
import useNumericKpis from '../../../../stores/useNumericKpis'
import useCalculatedKpis from '../../../../stores/useCalculatedKpis'

const useLineChartQueryBuilding = () => {
  const { numericKpis } = useNumericKpis()
  const { originalCalculatedKpis } = useCalculatedKpis()
  function migrateLegacyTableModeConfiguration(module: LineChartModule) {
    const newModule = cloneDeep(module || {})
    if (typeof newModule.linetable === 'boolean') {
      if (isNil(newModule.isInTableMode)) {
        newModule.isInTableMode = newModule.linetable
      }
    }

    if (newModule.limitValues) {
      if (
        !isNil(newModule.limitValues.min) &&
        !isNil(newModule.limitValues.mid) &&
        !isNil(newModule.limitValues.max)
      ) {
        newModule.limitValues = {
          min: newModule.limitValues.mid,
          max: newModule.limitValues.max,
          // no longer used
          mid: undefined,
        }
      }
    }
    if ((!newModule.selections || !Object.keys(newModule.selections).length) && newModule.query) {
      const oldQueryKpisIds = (module.query?.kpis || []).map((kpi) => kpi.id)
      const oldQueryCalcNames = Object.keys(module.query?.calculated_kpis || {})
      newModule.selections = {}
      oldQueryKpisIds.forEach((id) => (newModule.selections[id] = true))
      oldQueryCalcNames.forEach((name) => (newModule.selections[name] = true))
    }
    if (newModule.query?.time_res) {
      newModule.filterGrouping = newModule.query.time_res
    }
    return newModule
  }

  function createQueryObjectToModuleSettings(
    module: LineChartModule,
    sharedFilter?: {
      filters: { name: string; value: string }[]
    },
  ) {
    return {
      ...QUERY_NUMERIC_PAYLOAD,
      ...constructQuery2PayloadOutOfModuleSettings(module, sharedFilter),
      time_res: module.query?.time_res || module.filterGrouping || 'month',
      kpis:
        !!module.selections &&
        !isEmpty(module.selections) &&
        createKpisFromSelectionsObject(module.selections).length
          ? createKpisFromSelectionsObject(module.selections)
          : module?.query?.kpis
          ? module.query.kpis.map((kpi) => ({ id: kpi.id, name: kpi.name }))
          : [],
      calculated_kpis: createCalculatedFromSelectionsObject(module.selections),
    }
  }

  function createKpisFromSelectionsObject(selections: LineChartModule['selections']) {
    const selectedKpis = [] as { id: number; name: string }[]
    Object.keys(selections).forEach((kpi) => {
      const numericKpiId = +kpi
      if (!isNaN(numericKpiId)) {
        const matchingKpi = numericKpis?.find((numericKpi) => +numericKpi.id === numericKpiId)
        if (matchingKpi) selectedKpis.push({ id: matchingKpi.id, name: matchingKpi.name })
      }
    })
    return selectedKpis
  }

  function createCalculatedFromSelectionsObject(selections: LineChartModule['selections']) {
    const selectedKpis = {} as Query.Numeric['calculated_kpis']
    if (!selections) return selectedKpis
    Object.keys(selections).forEach((kpi) => {
      const calculatedKpiName = kpi
      if (isNaN(+calculatedKpiName) && originalCalculatedKpis) {
        const matchingKpi = originalCalculatedKpis[calculatedKpiName]
        if (matchingKpi) selectedKpis[calculatedKpiName] = matchingKpi
      }
    })
    return selectedKpis
  }

  function constructQuery2PayloadOutOfModuleSettings(
    module: LineChartModule,
    sharedFilter?: {
      filters: { name: string; value: string }[]
    },
  ) {
    let result = {
      start_date: '',
      end_date: '',
      where_meta: {},
      calculated_kpis: {},
      kpis: [] as Query.Numeric['kpis'],
      time_res: module.filterGrouping || 'month',
    } as Query.Numeric
    const hasSharedFilter = !!(
      sharedFilter &&
      sharedFilter.filters &&
      !isEmpty(sharedFilter.filters)
    )

    const hasQueryInSettings = !!(
      module.settings &&
      !isEmpty(module.settings) &&
      Array.isArray(module.selections)
    )
    const moduleQuery = module.query
    const hasQuery2Object = !!(moduleQuery && !isEmpty(moduleQuery))
    const legacyFormatQuery = hasSharedFilter
      ? sharedFilter.filters
      : hasQueryInSettings
      ? module.settings
      : undefined
    if (legacyFormatQuery && !isEmpty(legacyFormatQuery)) {
      try {
        result = { ...result, ...(transformFiltersToWhereMetaForm(legacyFormatQuery) || {}) }
      } catch (e) {
        // received an empty filter set; ignore
      }
    } else {
      if (hasQuery2Object) {
        return {
          ...moduleQuery,
          time_res: moduleQuery?.time_res ? moduleQuery : result.time_res,
          where_meta: moduleQuery?.filters || moduleQuery.where_meta || result.where_meta,
        }
      } else {
        return result
      }
    }
    return result
  }

  function getYearBefore(ISODate: string) {
    const timeZoneStr = 'T00:00:00.000Z'
    const date = new Date(ISODate + timeZoneStr)
    date.setFullYear(date.getFullYear() - 1)

    return date.toISOString().split('T')[0]
  }

  return {
    migrateLegacyTableModeConfiguration,
    createQueryObjectToModuleSettings,
    getYearBefore,
  }
}

export default useLineChartQueryBuilding
