import React, { useCallback, useRef, useState } from 'react'
import GroupDashboard from './GroupDashboard'
import { useGroupModule } from './contexts/GroupContext'
import { GroupModule, GroupModuleThemes, ModuleType } from './groupModuleTypes'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import { SettingsToolsProvider } from '../_shared/ModuleSettings/SettingsContext'
import SettingsModal from '../_shared/ModuleSettings/SettingsModal'
import { GroupKpisProvider } from './contexts/GroupKpiContext'
import { useDeepCompareEffect } from 'react-use'
import { useRootActions } from './contexts/RootContext'
import { FilterArray } from '../../../../react-services/filterService'

import css from './GroupModuleCntr.module.scss'

export type GroupModuleCntrProps = {
  module: GroupModule
  id: string
  index: number | null
  isNested?: boolean
  parentFilters?: FilterArray
  moveModuleUp?: () => void
  moveModuleDown?: () => void
  deleteModule?: () => void
}
const GroupModuleCntr = ({
  module,
  index,
  parentFilters,
  moveModuleUp: rootMoveUp = () => ({}),
  moveModuleDown: rootMoveDown = () => ({}),
  deleteModule: rootModuleDelete = () => ({}),
}: GroupModuleCntrProps) => {
  const [{ isNested }, setGroupModule] = useGroupModule()
  const { saveModule, removeModule, moveModuleDown, moveModuleUp } = useRootActions()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false)
  const settingsWrapperRef = useRef<HTMLDivElement>()
  const [isCntrHovered, setIsCntrHovered] = useState(false)

  useDeepCompareEffect(() => {
    setGroupModule((prev) => ({
      ...prev,
      module,
      index,
      parentFilters: isNested ? parentFilters : undefined,
    }))
  }, [module || {}, index, parentFilters || {}])

  const handleOpenDeleteModal = useCallback(() => setIsDeleteModalOpen(true), [])
  const handleCloseDeleteModal = useCallback(() => setIsDeleteModalOpen(false), [])
  const handleCloseSettingsModal = useCallback(() => setIsSettingModalOpen(false), [])
  const handleOpenSettingsModal = useCallback(() => setIsSettingModalOpen(true), [])
  const handleConfirmDeleteModule = useCallback(() => {
    !isNested ? rootModuleDelete() : removeModule(module)
  }, [])

  const getCntrClasses = () => {
    let classes = 'group-module-cntr modulegroup'
    classes = classes.concat(' ', css.cntr)
    if (isDeleteModalOpen) classes = classes.concat(' ', css.delete)
    if (isSettingModalOpen) classes = classes.concat(' ', css.settings)
    const isStyle2 = module.options?.style === GroupModuleThemes.STYLES2
    if (isStyle2) classes = classes.concat(' ', 'group-style2')
    if (isNested) classes = classes.concat(' ', css.nested)
    const hasNoModules = !module?.modules?.length
    if (hasNoModules) classes = classes.concat(' ', css.hasNoModules)
    return classes
  }

  return (
    <div
      className={getCntrClasses()}
      ref={(element) => (settingsWrapperRef.current = element ? element : undefined)}
      style={{
        height: module.options?.moduleheight ? `${module.options?.moduleheight}px` : '',
      }}
      onMouseEnter={() => setIsCntrHovered(true)}
      onMouseLeave={() => setIsCntrHovered(false)}
    >
      <GroupKpisProvider>
        <SettingsToolsProvider
          initialValue={{
            moveModuleUp: !isNested ? rootMoveUp : moveModuleUp,
            moveModuleDown: !isNested ? rootMoveDown : moveModuleDown,
            saveModule: saveModule,
            moduleType: ModuleType.GROUP,
            inGroup: !!isNested,
            module,
            index,
          }}
        >
          {isDeleteModalOpen && (
            <ConfirmationModal
              confirm='Delete'
              title='Delete module?'
              cancel='Cancel'
              info='Module and the content will be deleted. Are you sure?'
              isWarning
              enableEnterConfirmation={false}
              handleCancel={handleCloseDeleteModal}
              handleConfirmation={handleConfirmDeleteModule}
            />
          )}
          {isSettingModalOpen && (
            <SettingsModal
              onClose={handleCloseSettingsModal}
              options={module.options}
              module={module}
              index={index}
              handleDeleteClick={handleOpenDeleteModal}
              settingsWrapperRef={settingsWrapperRef}
              scrollIntoPosition={isNested ? 'center' : 'nearest'}
              saveModule={saveModule}
              moveModuleUp={!isNested ? rootMoveUp : moveModuleUp}
              moveModuleDown={!isNested ? rootMoveDown : moveModuleDown}
            />
          )}
          <GroupDashboard
            isCntrHovered={isCntrHovered}
            handleOpenSettingsModal={handleOpenSettingsModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        </SettingsToolsProvider>
      </GroupKpisProvider>
    </div>
  )
}

export default GroupModuleCntr
