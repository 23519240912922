import { Data, GenericConfig, KpiData, Query } from '../../../../../types'
import { cloneDeep, isEmpty } from 'lodash'
import { AddOrRemove, Breakpoint, DateGroups } from '../Pietabular/pietabularTypes'
import { TargetColor } from './lineChartTypes'
import { errorConvertingBreakpointToDateGroups } from '../Pietabular/PietabularNotifications'
import { toast } from 'react-toastify'
import { ChartBreakpoint } from './lineChartReducer'
import usePietabularFunctions from '../Pietabular/usePietabularFunctions'
import { getTenant } from '../../../../react-services/authService'
import { darkerChartColors } from '../../../../../styles/variableExport'

const colors = Object.values(darkerChartColors)
const useLineChartOptionsBuilding = (
  handleSeriesClick: (kpiId: number | undefined, name: string, dateStamp: string) => void,
  isReportMode: boolean,
) => {
  const handleAddOrRemoveBreakpoint = (
    breakpoint: Breakpoint,
    action: AddOrRemove,
    breakpoints: Breakpoint[],
    handleSaveBreakpoints: (breakpoints: Breakpoint[]) => void,
  ) => {
    if (!module) return
    const prevBreakpoints = breakpoints
    let newBreakpoints = [] as Breakpoint[]
    if (action === AddOrRemove.ADD) {
      const hasBpAlready = prevBreakpoints.map((bp) => bp.id).includes(breakpoint.id)
      newBreakpoints = hasBpAlready ? prevBreakpoints : [...prevBreakpoints, breakpoint]
    }
    if (action === AddOrRemove.REMOVE) {
      newBreakpoints = prevBreakpoints.filter((bp) => bp.id !== breakpoint.id)
    }
    if (action !== AddOrRemove.ADD && action !== AddOrRemove.REMOVE) {
      newBreakpoints = prevBreakpoints
    }
    handleSaveBreakpoints(newBreakpoints)
  }

  const convertToCorrectDateStringFormat = (categories: string[], dateGroup: Query.TimeRes) => {
    let newCategories = [] as string[]
    if (dateGroup === DateGroups.WEEK) {
      newCategories = categories.map((category) =>
        category
          .split('/')
          .map((s) => (s.length === 2 ? s[0] + '0' + s[1] : s))
          .sort()
          .join('/'),
      )
    }
    if (dateGroup === DateGroups.MONTH) {
      newCategories = categories.map((category) =>
        category
          .split('/')
          .map((s) => (s.length === 2 ? 'M'.concat(s) : s))
          .sort()
          .join('/'),
      )
    }
    if (dateGroup === DateGroups.QUARTER) {
      newCategories = categories.map((category) =>
        category
          .split('/')
          .map((s) => (s.length === 2 ? s[0] + '0' + s[1] : s))
          .sort()
          .join('/'),
      )
    }
    if (dateGroup === DateGroups.YEAR) {
      newCategories = categories
    }
    return newCategories
  }

  function constructLineChartSeriesOptions(
    seriesOptions: KpiData.NumericKpiData[],
    dataFormat: Data.Format,
    selectedColor?: TargetColor,
    width?: number,
  ) {
    return seriesOptions.map((series, i) => {
      const seriesColor = colors.filter(
        (c) =>
          !(
            selectedColor === TargetColor.GREEN &&
            (c === darkerChartColors.BLUE_GREEN || c === darkerChartColors.LIGHT_BLUE_GREEN)
          ) &&
          !(
            selectedColor === TargetColor.RED &&
            (c === darkerChartColors.RED || c === darkerChartColors.LIGHT_RED)
          ) &&
          !(selectedColor === TargetColor.GREY && c === darkerChartColors.GREY),
      )[i]

      return {
        ...cloneDeep(series),
        color: width ? '#FFF' : seriesColor,
        dashStyle: series.is_trend ? 'ShortDot' : undefined,
        name: !series.data || isEmpty(series.data) ? 'No data' : series.name,
        type: 'line',
        custom: {
          kpiId: series?.data[0]?.data?.id,
        },
        yAxis: 0,
        zIndex: width ? 1 : 2,
        lineWidth: width ? width : 3,
        data: series.data.map((dataSet) => {
          const newDataSet = { ...(dataSet || {}) }
          if (newDataSet?.data) {
            const format = newDataSet.data[dataFormat]
            if (format !== null && format !== undefined) {
              newDataSet.y = parseFloat(format.toString())
              newDataSet.value = parseFloat(format.toString())
            }
          }

          return newDataSet
        }),
        id: width ? undefined : series.name,
        linkedTo: width ? series.name : undefined,
        animation: {
          duration: getTenant() === 'test_frontend' || !!isReportMode ? 0 : 1500,
          easing: 'easeInOutCose',
        },
        animationLimit: 500,
        gapSize: 20,
        gapUnit: 'value',
        point: {
          events: !series.is_trend && {
            click: function (e) {
              if (e.point.category) {
                handleSeriesClick(
                  series?.data[0]?.data?.id,
                  series.name,
                  e.point.category.toString(),
                )
              }
            },
          },
        },
      } as Highcharts.SeriesLineOptions
    })
  }

  const constructBarChartSeriesOptions = (
    seriesOptions: KpiData.NumericKpiData[],
    selectedColor?: TargetColor,
  ): Highcharts.SeriesColumnOptions[] => {
    return seriesOptions.map((series, i) => {
      const seriesColor = colors.filter(
        (c) =>
          !(selectedColor === TargetColor.GREEN && (c === '#64C2A6' || c === '#AADEA7')) &&
          !(selectedColor === TargetColor.RED && (c === '#F66D44' || c === '#FEAE65')),
      )[i]
      return {
        ...cloneDeep(series),
        color: seriesColor,
        name: !series.data || isEmpty(series.data) ? 'No data' : series.name,
        type: 'column',
        data: series.data.map((dataSet) => {
          if (dataSet?.data) {
            const format = dataSet.data['n']
            if (format !== null && format !== undefined) {
              dataSet.y = parseFloat(format.toString())
              dataSet.value = parseFloat(format.toString())
            }
          }
          return dataSet
        }),
        yAxis: 1,
        linkedTo: series.name,
        animation: {
          duration: getTenant() === 'test_frontend' ? 0 : 1500,
        },
        animationLimit: 500,
        point: {
          events: {
            click: function (e) {
              if (e.point.category) {
                handleSeriesClick(
                  series?.data[0]?.data?.id,
                  series.name,
                  e.point.category.toString(),
                )
              }
            },
          },
        },
      } as Highcharts.SeriesColumnOptions
    })
  }

  const moveStackedDataPointsOnYAxis = (
    seriesOptions: Highcharts.SeriesLineOptions[],
  ): Highcharts.SeriesLineOptions[] => {
    const movedOptions = [] as Highcharts.SeriesLineOptions[]
    seriesOptions.forEach((series) => {
      const newSeries = { ...(series || {}) }
      if (newSeries.data) {
        for (let j = 0; j < newSeries.data.length; j++) {
          const point = newSeries.data[j]
          if (!point || typeof point !== 'object' || Array.isArray(point)) continue
          const currentY = point.y
          const currentX = point.x
          const currentName = series.name
          if (currentY === null || currentY === undefined) continue
          if (currentX === null || currentX === undefined) continue
          if (!currentName) continue
          const foundPointsWithSameY = movedOptions.filter((series) => {
            return (
              series.data &&
              series.data.find((otherPoint) => {
                return (
                  otherPoint &&
                  typeof otherPoint === 'object' &&
                  !Array.isArray(otherPoint) &&
                  series.name !== currentName &&
                  otherPoint.x === currentX &&
                  otherPoint.y?.toFixed(1) === currentY.toFixed(1)
                )
              })
            )
          })

          if (foundPointsWithSameY.length === 0) continue
          if (
            newSeries.data[j] &&
            typeof newSeries.data[j] === 'object' &&
            !Array.isArray(newSeries.data[j])
          )
            point.y = currentY + 0.11
        }
      }
      movedOptions.push(newSeries)
    })
    return movedOptions
  }

  function formatLineChartSeries(
    seriesOptions: KpiData.NumericKpiData[],
    dataFormat: Data.Format,
    isTargetAreaVisible: boolean,
    showTotals: boolean,
    selectedColor?: TargetColor,
  ): Highcharts.SeriesLineOptions[] {
    let result: Highcharts.SeriesLineOptions[] = []

    if (isEmpty(seriesOptions)) {
      result.push({
        name: 'No data to display',
        type: 'line',
      })
    } else {
      result = constructLineChartSeriesOptions(seriesOptions, dataFormat, selectedColor)
      result =
        showTotals || isTargetAreaVisible
          ? result.concat(
              constructLineChartSeriesOptions(seriesOptions, dataFormat, selectedColor, 7),
            )
          : result
      result = moveStackedDataPointsOnYAxis(result)
    }
    return result
  }

  const formatBarChartSeries = (
    seriesOptions: KpiData.NumericKpiData[],
    selectedColor?: TargetColor,
  ): Highcharts.SeriesColumnOptions[] => {
    let result: Highcharts.SeriesColumnOptions[] = []
    if (!isEmpty(seriesOptions)) {
      result = constructBarChartSeriesOptions(seriesOptions, selectedColor)
    }
    return result
  }

  const convertBreakpointDatesToPointsInTheXAxis = (
    breakpoints: Breakpoint[],
    categories: string[],
    dateGroup: Query.TimeRes,
    config: GenericConfig.CommonDbSettings,
  ): ChartBreakpoint[] => {
    const {
      handleMatchingBreakpointsWithYearGroups,
      handleMatchingBreakpointsWithQuarterGroups,
      handleMatchingBreakpointsWithMonthGroups,
      handleMatchingBreakpointsWithWeekGroups,
    } = usePietabularFunctions()
    if (!categories || !categories.length) return []
    if (!config || !config.breakpoints) return []

    const configBpsFilteredBySettingsBpsIds = config.breakpoints.filter((configBp) =>
      breakpoints.map((settingsBp) => settingsBp.id).includes(configBp.id),
    )
    if (!configBpsFilteredBySettingsBpsIds.length) return []
    const convertedCategories = convertToCorrectDateStringFormat(categories, dateGroup)
    try {
      if (dateGroup === DateGroups.YEAR) {
        return handleMatchingBreakpointsWithYearGroups(
          configBpsFilteredBySettingsBpsIds,
          convertedCategories,
        )
      }
      if (dateGroup === DateGroups.QUARTER) {
        return handleMatchingBreakpointsWithQuarterGroups(
          configBpsFilteredBySettingsBpsIds,
          convertedCategories,
        )
      }
      if (dateGroup === DateGroups.MONTH) {
        return handleMatchingBreakpointsWithMonthGroups(
          configBpsFilteredBySettingsBpsIds,
          convertedCategories,
        )
      }
      if (dateGroup === DateGroups.WEEK) {
        return handleMatchingBreakpointsWithWeekGroups(
          configBpsFilteredBySettingsBpsIds,
          convertedCategories,
        )
      }
    } catch (e) {
      toast.error(errorConvertingBreakpointToDateGroups)
    }
    return []
  }

  return {
    handleAddOrRemoveBreakpoint,
    convertToCorrectDateStringFormat,
    formatLineChartSeries,
    formatBarChartSeries,
    convertBreakpointDatesToPointsInTheXAxis,
  }
}

export default useLineChartOptionsBuilding
