import React, { useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import useOpenKpis from '../../../../stores/useOpenKpis'
import { tCategory, tCommon, tData, tKpi } from '../../../../../languages/i18n'

import css from './OpenModuleAnswersTableHeader.module.scss'

type OpenModuleAnswersTableHeaderProps = {
  metadataColumns: string[]
  doShowSentiments: boolean
  doShowTopics: boolean
  doShowCustomCategories: boolean
  showTools: boolean
  customCategories: { [name: string]: string[] } | null
  answerMetas: string[] | null
  kpiId: number
  scrolled: number
  setHeaderPosition: (offset: number) => void
  isHeaderHidden: boolean
  isBlank?: boolean
}

const OpenModuleAnswersTableHeader = ({
  metadataColumns,
  doShowSentiments,
  doShowTopics,
  doShowCustomCategories,
  showTools,
  customCategories,
  answerMetas,
  kpiId,
  scrolled,
  setHeaderPosition,
  isHeaderHidden,
  isBlank,
}: OpenModuleAnswersTableHeaderProps) => {
  // const { isReportMode, isScreenMode, showInspectorConversation, showCategoryTool } =
  //   useContext(OpenModuleContext)
  const headerRef = useRef<HTMLDivElement>()
  const { openKpis } = useOpenKpis()

  let question = ''
  if (openKpis) {
    question = openKpis.find((kpi) => kpi.id === kpiId)?.name || ''
  }

  const headerPositionTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    headerPositionTimeout.current = setTimeout(() => {
      if (headerRef.current) {
        setHeaderPosition(headerRef.current.offsetTop)
      }
    }, 1)
    return () => headerPositionTimeout.current && clearTimeout(headerPositionTimeout.current)
  }, [scrolled])

  const getHiddenClass = () => (isHeaderHidden ? css.hidden : css.visible)
  return (
    <>
      <div
        data-testid={'openModuleAnswerTableCollapsible-0'}
        className={`hr open-module-collapsible-header ${css.headerRow} ${getHiddenClass()}`}
        ref={(ref) => {
          if (ref) headerRef.current = ref
        }}
      >
        {!isBlank && (
          <>
            <div className='tc' style={{ width: '100px' }}>
              {tCommon('label.date')}
            </div>
            <div className='tc'>{tKpi(question)}</div>
            {showTools && <div className='tc'>{tCommon('label.tools')}</div>}
            {metadataColumns.map((metaKey) => (
              <div key={metaKey} className='tc open-module-header-meta-column'>
                {tData(metaKey)}
              </div>
            ))}
            {doShowCustomCategories &&
              customCategories &&
              Object.entries(customCategories).map(([key]) => (
                <div key={key} className='tc open-module-header-meta-column'>
                  {tCategory(key)}
                </div>
              ))}
            {doShowSentiments && <div className='tc'>{tCategory('sentiment')}</div>}
            {doShowTopics && <div className='tc'>{tCategory('topic')}</div>}
            {!isEmpty(answerMetas) && <div className='tc'>&nbsp;</div>}
          </>
        )}
        {!!isBlank && <div className='tc'></div>}
      </div>
    </>
  )
}

export default OpenModuleAnswersTableHeader
