import { infoPerspectivesEN, infoPerspectivesFI } from './SummaryNotifications'
import { Languages } from './summaryTypes'

const useSummaryLanguage = (language: Languages) => {
  const t = (v: string) => {
    if (language !== Languages.EN) return v
    if (v === 'Taustatiedot') return 'Groups'
    if (v === 'Tulosyksikkö') return 'Unit'
    if (v === 'Synnytysosasto') return 'Maternity ward'
    if (v === 'Akuutti') return 'Emergency department'
    if (v === 'Terveysasemat') return 'Health center'
    if (v === 'Ikäryhmä') return 'Age groups'
    if (v === 'Alue') return 'Areas'
    if (v === 'Etelä') return 'South'
    if (v === 'Itä') return 'East'
    if (v === 'Länsi') return 'West'
    if (v === 'Pohjoinen') return 'North'
    if (v === 'Aikaikkuna') return 'Time frame'
    if (v === 'Viikko') return 'Week'
    if (v === 'Kuukausi') return 'Month'
    if (v === 'Kvartaali') return 'Quarter'
    if (v === 'Puolivuosi') return 'Year half'
    if (v === 'Vuosi') return 'Year'
    if (v === 'Näkökulmat') return 'Perspectives'
    if (v === 'Top 5 positiiviset') return 'Top 5 positive'
    if (v === 'Top 5 negatiiviset') return 'Top 5 negative'
    if (v === 'Toimenpidesuositukset') return 'Action recommendations'
    if (v === 'Ongelmakohdat') return 'Problem areas'
    if (v === 'Merkityksellistä') return 'Meaningful'
    if (v === 'Yhteenveto') return 'Summary'
    if (v === 'Hoitoonpääsy') return 'Admission to care'
    if (v === 'Käynnit ja hoito') return 'Visits and treatments'
    if (v === 'Tutkimukset') return 'Research'
    if (v === 'Asiakaspalvelu') return 'Customer service'
    if (v === 'Sähköiset palvelut') return 'E-services'
    if (v === 'Kaikki') return 'All'
    if (v === 'kaikki') return 'all'
    if (v === 'Ei arvoa') return 'No value'
    if (v === 'Ikäihmisten palvelut') return 'Services for senior citizens'
    if (v === 'Pelastuslaitos') return 'Emergency services'
    if (v === 'Perhe- ja sosiaalipalvelut') return 'Family and social services'
    if (v === 'Terveys- ja sairaanhoitopalvelut') return 'Health and medical services'
    if (v === 'Toimialueet') return 'Domain'
    if (v === 'Mitkä asiat ovat asiakkaalle tärkeimpiä?')
      return 'What are the most important things to the customer?'
    if (v === 'Mikä voi mennä pieleen prosessissa ja mitä pitäisi korjata?')
      return 'What can go wrong in the process and what should be fixed?'
    if (v === 'vastausta') return 'answers'
    if (v === infoPerspectivesFI) return infoPerspectivesEN
    return v
  }
  return {
    t,
  }
}

export default useSummaryLanguage
