import React, { memo, useMemo } from 'react'
import { GroupModule, Module, ModuleType } from '../groupModuleTypes'
import { getModuleWidth, isValidModuleWidth } from '../../../../../react-services/moduleService'
import { cloneDeep } from 'lodash'
import SettingsWrapper from '../../_shared/ModuleSettings/SettingsWrapper'
import useNestedModuleActions from '../useNestedModuleActions'

import LineModuleContainer from '../../Line/LineModuleContainer'
import FrequencyModuleConverter from '../../Frequency/FrequencyModuleConverter'
import TabularModuleConverter from '../../Tabular/TabularModuleConverter'
import BubbleModuleContainer from '../../Bubble/BubbleModuleContainer'
import TopBottomModuleContainer from '../../TopBottom/TopBottomModuleContainer'
import NumberTrendModuleCntr from '../../NumberTrend/NumberTrendModuleCntr'
import OpenModuleContainer from '../../Open/OpenModuleContainer'
import PictureModuleCntr from '../../Picture/PictureModuleCntr'
import PietabularModuleContainer from '../../Pietabular/PietabularModuleContainer'
import RadarModuleContainer from '../../Radar/RadarModuleContainer'
import SummaryModuleContainer from '../../Summary/SummaryModuleContainer'
import WheelModuleCntr from '../../Wheel/WheelModuleCntr'
import TextBoxPropsConverter from '../../TextBox/TextBoxPropsConverter'
import { FeedbackAlertsTableConverter } from '../../FeedbackAlerts/FeedbackAlertsTableCntr'
import NoModuleFound from './NoModuleFound'
import { GroupModuleProvider } from '../contexts/GroupContext'
import GroupModuleCntr from '../GroupModuleCntr'
import ReportsOverview from '../../ReportsOverview/ReportsOverview'
import { convertFilterArrayToFilterObject } from '../../../../../react-services/filterService'
import { RenderListContext } from '../contexts/RenderContext'

import css from './ModuleRenderer.module.scss'

type ModuleRendererProps = {
  module: Module
  isNested: boolean
  parentGroupModule: GroupModule
  index: number
  handleSaveNestedModule: (newModule: Module) => void
  handleDeleteNestedModule: (m: Module) => void
  handleMoveNestedModuleUp: (m: Module) => void
  handleMoveNestedModuleDown: (m: Module) => void
}
const ModuleRenderer = memo(
  ({
    index,
    module,
    parentGroupModule,
    handleDeleteNestedModule,
    handleMoveNestedModuleDown,
    handleMoveNestedModuleUp,
    handleSaveNestedModule,
  }: ModuleRendererProps) => {
    const moduleId = module.id
    const { getFiltersAccordingToFilterType } = useNestedModuleActions()

    const moduleWidthClass = useMemo(() => {
      const moduleWidth = module.options?.modulewidth || module.modulewidth
      const checkedModuleWidth = getModuleWidth(moduleWidth)
      const newModeClass = css['width' + checkedModuleWidth] || ''
      if (!parentGroupModule || isValidModuleWidth(moduleWidth)) return newModeClass
      const newParentGroupModule = cloneDeep(parentGroupModule)
      if (!newParentGroupModule.modules) return
      newParentGroupModule.modules[index].options = {
        ...(newParentGroupModule.modules[index].options || {}),
        modulewidth: checkedModuleWidth.toString(),
      }
      return newModeClass
    }, [module.options?.modulewidth])

    const moduleClasses = useMemo(() => {
      let newClasses =
        module.type === ModuleType.GROUP2 || module.type === ModuleType.GROUP
          ? css.groupCntr
          : css.cntr + ' ' + css.notGroupCntr
      newClasses = newClasses.concat(' ', moduleWidthClass)
      newClasses = newClasses.concat(' ', 'modulegroup-submodule')
      return newClasses
    }, [moduleWidthClass])

    if (module.type === ModuleType.GROUP2 || module.type === ModuleType.GROUP) {
      const newParentFilters = parentGroupModule.settings
      const moduleHeight = module.options?.moduleheight
      return (
        <div
          data-testid='group-module-renderer'
          className={moduleClasses}
          style={{ height: moduleHeight ? moduleHeight : 'inherit' }}
        >
          <GroupModuleProvider
            initialValue={{
              index: 0,
              isNested: true,
              module: module,
            }}
          >
            <GroupModuleCntr
              key={moduleId || index}
              module={module}
              id={moduleId || ''}
              index={index}
              isNested
              parentFilters={newParentFilters}
            />
          </GroupModuleProvider>
        </div>
      )
    }

    const getModule = (module: Module) => {
      const newParentFilters = getFiltersAccordingToFilterType(parentGroupModule?.customfilters)
      const filterObject = convertFilterArrayToFilterObject(newParentFilters)
      const queryBase = {
        ...filterObject,
        where_meta: filterObject.where_meta || {},
        filters: filterObject.where_meta || {},
      }
      const newModule = cloneDeep(module)
      newModule.query = { ...module.query, ...queryBase }
      const getProps = <T,>(module: T) => ({
        module,
        key: moduleId || index,
        id: moduleId || '',
        saveModule: handleSaveNestedModule,
        isReportMode: false,
        isScreenMode: false,
        moduleStatus: undefined,
        isNested: true,
      })
      const id = moduleId
      switch (newModule.type) {
        case ModuleType.ALERT:
          return statusConsumer(FeedbackAlertsTableConverter, id, getProps(newModule))
        case ModuleType.BAR: // DEPRECATED
          return statusConsumer(FrequencyModuleConverter, id, getProps(newModule))
        case ModuleType.BUBBLE:
          return statusConsumer(BubbleModuleContainer, id, getProps(newModule))
        case ModuleType.FREQ:
          return statusConsumer(FrequencyModuleConverter, id, getProps(newModule))
        case ModuleType.LISTNUMERIC:
          return statusConsumer(TopBottomModuleContainer, id, getProps(newModule))
        case ModuleType.LINE:
          return statusConsumer(LineModuleContainer, id, getProps(newModule))
        case ModuleType.NUMBERTREND:
          return statusConsumer(NumberTrendModuleCntr, id, getProps(newModule))
        case ModuleType.OPEN:
          return statusConsumer(OpenModuleContainer, id, getProps(newModule))
        case ModuleType.PICTURE:
          return statusConsumer(PictureModuleCntr, id, getProps(newModule))
        case ModuleType.PIETABULAR:
          return statusConsumer(PietabularModuleContainer, id, getProps(newModule))
        case ModuleType.RADAR:
          return statusConsumer(RadarModuleContainer, id, getProps(newModule))
        case ModuleType.REPORTS:
          return statusConsumer(ReportsOverview, id, getProps(newModule))
        case ModuleType.SUMMARY:
          return statusConsumer(SummaryModuleContainer, id, getProps(newModule))
        case ModuleType.TABLE:
          return statusConsumer(TabularModuleConverter, id, getProps(newModule))
        case ModuleType.TABULAR:
          return statusConsumer(TabularModuleConverter, id, getProps(newModule))
        case ModuleType.TEXTBOX:
          return statusConsumer(TextBoxPropsConverter, id, getProps(newModule))
        case ModuleType.WHEEL:
          return statusConsumer(WheelModuleCntr, id, getProps(newModule))
        default:
          return <NoModuleFound moduleType={newModule.type} />
      }
    }

    const getHeight = (module: Module) => {
      const customHeight = Number(module.options?.moduleheight)
      if (customHeight && !isNaN(customHeight)) return customHeight < 190 ? 190 : customHeight
      switch (module.type) {
        case ModuleType.LISTNUMERIC:
          return 'fit-content'
        case ModuleType.NUMBERTREND:
          return 190
        case ModuleType.PIETABULAR: {
          const isAllExtensionsEnabled =
            !!module.options.showPie && !!module.options.showTabular && !!module.options.showTrends
          return isAllExtensionsEnabled ? 2 * 500 : 500
        }
        case ModuleType.TABULAR: {
          const isHeightCollapsed = module.options?.isTableCollapsed
          return isHeightCollapsed ? 'fit-content' : 500
        }
        case ModuleType.TEXTBOX:
          return 'fit-content'
        case ModuleType.ALERT:
          return 'fit-content'
        default:
          return 500
      }
    }

    const getMinHeight = (module: Module) => {
      if (module.options?.moduleheight) return module.options?.moduleheight
      switch (module.type) {
        // case ModuleType.ALERT:
        //   return 600
        // case ModuleType.NUMBERTREND:
        //   return 200
        // case ModuleType.LISTNUMERIC:
        //   return 200
        // case ModuleType.TEXTBOX:
        //   return 'fit-content'
        // case ModuleType.OPEN:
        //   return 'inherit'
        default:
          return '100%'
      }
    }

    return (
      <div
        data-testid='normal-module-renderer'
        className={moduleClasses}
        style={{
          height: getHeight(module),
          minHeight: getMinHeight(module),
        }}
        key={moduleId}
      >
        <SettingsWrapper
          key={moduleId}
          module={module}
          moduleType={module.type as ModuleType}
          saveModule={handleSaveNestedModule}
          deleteModule={handleDeleteNestedModule}
          moveModuleDown={handleMoveNestedModuleDown}
          moveModuleUp={handleMoveNestedModuleUp}
          index={index}
          inGroup={true}
          isReportMode={false}
          isTitleCollapsed={
            module.type === ModuleType.TEXTBOX || module.type === ModuleType.PICTURE
          }
          isScreenMode={false}
        >
          {getModule(module)}
        </SettingsWrapper>
      </div>
    )
  },
)

ModuleRenderer.displayName = 'ModuleRenderer'
export default ModuleRenderer

function statusConsumer<T>(
  Component: React.ComponentType<T>,
  moduleId: string | undefined,
  props: T,
) {
  return (
    <RenderListContext.Consumer>
      {(renderList) => {
        const moduleStatus = moduleId ? renderList[moduleId]?.status : undefined
        const WithQueueStatus = <Component {...(props as T)} moduleStatus={moduleStatus} />
        return WithQueueStatus
      }}
    </RenderListContext.Consumer>
  )
}
