/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Rodal from 'rodal'
import Select from 'react-select'
import { SurveyInfoTool } from '../../../../../types'
import MainButton from '../../../_shared/Buttons/MainButton'
import { displayCurrentUTCDate } from '../../../../react-services/datesService';
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const sanitizeHtml = require('sanitize-html')

type State = {
  languageOptions: { value: string, label: string }[]
  selectedLanguage: { value: string, label: string }
}

type Props = {
  item: SurveyInfoTool.SurveyListItem | undefined
  onClose: () => void
}

class SurveyInfoCoveringLetter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      languageOptions: this.createLanguageOptions(),
      selectedLanguage: this.createLanguageOptions()[0]
    }
  }

  createLanguageOptions(): { value: string, label: string }[] {
    if (this.props.item && this.props.item.messages) {
      return Object.keys(this.props.item.messages).map(lang => ({ value: lang, label: lang }))
    }
    return ([{ value: '', label: '' }])
  }

  handleChangeLanguage = (language: { value: string, label: string }): void => {
    this.setState({ selectedLanguage: language })
  }

  sanitizeHtmlMessage(message: string): string {
    return sanitizeHtml(message, {
      // https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'h1', 'h2',
        'big', 'small',
        'u', 'img'
      ])
    })
  }

  createMessageHtml = (): string => {
    if (
      this.props.item && this.props.item.messages
      && this.props.item.messages[this.state.selectedLanguage.value]
      && this.props.item.messages[this.state.selectedLanguage.value].message_email_html
    ) {
      return this.sanitizeHtmlMessage(this.props.item.messages[this.state.selectedLanguage.value].message_email_html || '')
    }
    return ''
  }


  createTimingGroupTable() {
    const { groupTimings } = this.props.item!
    let timingGroups: any[]
    timingGroups = []

    groupTimings?.forEach(timingGroup => {
      timingGroups.push(
        <tr key={timingGroup}>
          <td className='center' colSpan={6}>{timingGroup}</td>
        </tr>
      )
    })
    return timingGroups;
  }

  render() {
    return (
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible
        onClose={this.props.onClose}
      >
        {this.props.item && this.props.item.messages &&
          <div className='survey-info-rodal'>

            <h5>Preview for <i>{this.props.item.surveyName}</i></h5>
            <hr /><br />
            <div className="row">
              <h6 className="label">Language</h6>
              <Select
                className='react-select-default-styles survey-info-input'
                id='select-language-option'
                options={this.state.languageOptions}
                value={this.state.selectedLanguage}
                onChange={(value: any) => this.handleChangeLanguage(value)}
              />
            </div>
            <div className="row">
              <h6 className="label">Subject</h6>
              <input className="input-field survey-info-input" id="subject" disabled value={this.props.item.messages[this.state.selectedLanguage.value].subject} />
            </div>
            {(this.props.item.deliveryMethod.first === 'email' || this.props.item.deliveryMethod.second === 'email') &&
              <div className="row">
                <h6 className="label">Email message</h6>
                <div className='survey-info-message' id='message-email' dangerouslySetInnerHTML={{ __html: this.createMessageHtml() }} />
              </div>
            }
            {(this.props.item.deliveryMethod.first === 'sms' || this.props.item.deliveryMethod.second === 'sms') &&
              <div className="row">
                <h6 className="label">SMS message</h6>
                <div className='survey-info-message' id='message-sms'>{this.props.item.messages[this.state.selectedLanguage.value].message_sms}</div>
              </div>
            }
            {this.props.item.groupTimings &&
              <div className="row">
                <h6 className='label'>Automation Timing Group</h6>
                <p className='survey-info-UTC-time'>Current UTC time: {displayCurrentUTCDate()}</p>
                <GenericTable
                  headers={<tr>
                    <th className='center' colSpan={6}>Automation Timing group</th>
                  </tr>}
                  rows={this.createTimingGroupTable()}
                  useFixedLayout
                />
              </div>
            }
          </div>
        }

        <div className="row center">
          <MainButton isFlat text="Close" onClick={this.props.onClose} />
        </div>
      </Rodal>
    )
  }
}

export default SurveyInfoCoveringLetter