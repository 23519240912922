/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, memo, useRef } from 'react'
import Rodal from 'rodal'
import ModulePanelList from './ModulePanelList'
import { PICKABLE_DASHBOARD_MODULES } from '../../../../react-constants/moduleTypes'
import MainButton from '../../../_shared/Buttons/MainButton'
import { getAvailableModules } from '../../../../react-services/configService'
import { isNil } from 'lodash'
import { GenericConfig, Dashboard } from '../../../../../types'
import './ModulePicker.scss'

type Props = {
  isGrouped: boolean
  onClose: Function
  onSelect: (m: Dashboard.PickableModule) => void
}

function ModulePicker({ isGrouped, onClose, onSelect }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [enabledModules, setEnabledModules] = useState<Dashboard.PickableModule[] | null>(null)
  const isLoading = useRef<boolean>(true)

  if (enabledModules === null && isLoading.current) {
    isLoading.current = false
    loadEveryConfiguration()
  }

  function loadEveryConfiguration() {
    getAvailableModules().then((conf: GenericConfig.EnabledModules) => {
      if (!conf) {
        setEnabledModules(PICKABLE_DASHBOARD_MODULES.filter((m) => !m.isRestricted))
      } else {
        setEnabledModules(
          PICKABLE_DASHBOARD_MODULES.filter(
            (m) => !m.isRestricted || (m.isRestricted && conf.enabled.includes(m.type)),
          ),
        )
      }
    })
  }

  function toggleModal(isItOpen: boolean | undefined): void {
    if (isNil(isItOpen)) {
      isItOpen = !isOpen
    }

    setIsOpen(isItOpen)
  }

  function handleSelectModule(module: Dashboard.PickableModule) {
    onSelect(module)
    toggleModal(false)
  }

  return (
    <div
      className={'module-picker-modal'}
      onKeyDown={(e) => {
        const el = document.querySelector(`[data-focus-id="${e.keyCode}"]`)
        if (el instanceof HTMLElement) {
          el.focus()
        }
      }}
    >
      <Rodal
        closeOnEsc
        animation='slideUp'
        visible={isOpen}
        onClose={() => {
          toggleModal(false)
        }}
        onAnimationEnd={() => {
          if (!isOpen) {
            onClose()
          }
        }}
      >
        <div className={'row module-picker-header'}>
          <div className={'col s12 valign-wrapper'}>
            <h6>Add a module</h6>
          </div>
        </div>

        {enabledModules !== null && (
          <ModulePanelList
            pickableModules={enabledModules}
            isGrouped={isGrouped}
            onSelect={handleSelectModule}
          />
        )}

        <div className='module-picker-bottom'>
          <MainButton
            isFlat
            text={'Close'}
            onClick={() => {
              toggleModal(false)
            }}
          />
        </div>
      </Rodal>
    </div>
  )
}

export default memo<Props>(ModulePicker)
