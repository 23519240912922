import React, { useEffect, useRef, useState } from 'react'
import { VALIDKPITYPES } from '../../../../_shared/TreeView/SelectionTree'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import SelectionTreeInput, {
  kpiIdAndName,
} from '../../_shared/ModuleSettings/components/SelectionTreeInput'
import useNumericKpis from '../../../../../stores/useNumericKpis'
import { LineChartModule } from '../lineChartTypes'
import { cloneDeep, isEqual, isUndefined } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'

type Selections = { [id: string]: boolean }

const KpiSelectionTreeData = () => {
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [{ saveModule, module }] = useSettingsTools()
  const lineModule = module as LineChartModule
  const { numericKpis } = useNumericKpis()

  useEffect(() => {
    if (!numericKpis) return
    let selectionSettings = [] as (number | string)[]
    if (lineModule && lineModule.selections && typeof lineModule.selections === 'object') {
      selectionSettings = Object.keys(lineModule.selections)
    }
    const kpiSelection = [] as kpiIdAndName[]
    if (numericKpis) {
      selectionSettings.forEach((kpi) => {
        if (isNaN(+kpi)) {
          kpiSelection.push({ id: undefined, name: kpi.toString() })
        } else {
          const matchingKpi = numericKpis.find((numericKpi) => +numericKpi.id === +kpi)
          if (matchingKpi) kpiSelection.push({ id: matchingKpi.id, name: matchingKpi.name })
        }
      })
    }
    if (!kpiSelection.length && lineModule?.query?.kpis) {
      lineModule.query.kpis.forEach((kpi) => kpiSelection.push({ id: kpi.id, name: kpi.name }))
    }
    setSelectedKpis(kpiSelection)
  }, [numericKpis])

  const [selectedKpis, setSelectedKpis] = useState<kpiIdAndName[] | null>(null)
  useEffect(() => {
    if (!selectedKpis) return
    handleSaveNewSelection(selectedKpis)
  }, [selectedKpis])

  const handleSaveNewSelection = (newSelection: kpiIdAndName[]) => {
    try {
      if (!lineModule) return
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        const selectionObject = {} as Selections
        newSelection.forEach((kpi) => {
          if (isUndefined(kpi.id)) selectionObject[kpi.name] = true
          else selectionObject[kpi.id] = true
        })
        const newModule = cloneDeep(lineModule)
        newModule.selections = selectionObject
        if (isEqual(newModule, lineModule)) return
        saveModule(newModule)
      }, 1000)
    } catch (error) {
      // TODO: add toast
    }
  }

  return (
    <>
      {selectedKpis ? (
        <SelectionTreeInput
          selectedKpis={selectedKpis}
          setSelectedKpis={setSelectedKpis}
          classNames={modal_css.half}
          kpiType={VALIDKPITYPES.numeric}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default KpiSelectionTreeData
