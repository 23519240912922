import i18n, { TOptions } from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import commonFI from './common/fi.json'
import commonEN from './common/en.json'
import commonSV from './common/sv.json'
import { upperFirst } from 'lodash'
import { $Dictionary } from 'i18next/typescript/helpers'

export enum TranslationNameSpace {
  CATEGORIES = 'categories',
  DATA = 'data',
  KPIS = 'kpis',
  MODULES = 'modules',
  COMMON = 'common',
  TEMPLATES = 'templates',
}
const languageDetector = new I18nextBrowserLanguageDetector(null, {
  caches: [],
  convertDetectedLanguage: convertISOLangCodeToLanguageCode,
  lookupQuerystring: 'lng',
})

export type DynamicTranslationNameSpace = Exclude<TranslationNameSpace, TranslationNameSpace.COMMON>
export type Options = (TOptions & $Dictionary) | undefined
i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    ns: Object.values(TranslationNameSpace),
    defaultNS: 'static',
    fallbackLng: 'en',
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        static: {
          common: commonEN,
        },
      },
      fi: {
        static: {
          common: commonFI,
        },
      },
      sv: {
        static: {
          common: commonSV,
        },
      },
    },
  })

export const tCategory = (key: string, options?: Options) => {
  const translation = i18n.t(key.toLocaleLowerCase(), {
    ns: TranslationNameSpace.CATEGORIES,
    defaultValue: key,
    ...(options ? { ...options } : {}),
  })
  return upperFirst(translation)
}

export const tData = (key: string, options?: Options) => {
  const translation = i18n.t(key.toLocaleLowerCase(), {
    ns: TranslationNameSpace.DATA,
    defaultValue: key,
    ...(options ? { ...options } : {}),
  })
  return upperFirst(translation)
}

export const tKpi = (key: string, options?: Options) => {
  const translation = i18n.t(key.toLocaleLowerCase(), {
    ns: TranslationNameSpace.KPIS,
    defaultValue: key,
    ...(options ? { ...options } : {}),
  })
  return upperFirst(translation)
}

export const tModule = (key: string, options?: Options, hasUpperFirstLetter = true) => {
  const translation = i18n.t(key.toLocaleLowerCase(), {
    ns: TranslationNameSpace.MODULES,
    defaultValue: key,
    ...(options ? { ...options } : {}),
  })
  if (hasUpperFirstLetter) return upperFirst(translation)
  else return translation
}

export const tTemplate = (key: string, options?: Options) => {
  const translation = i18n.t(key.toLocaleLowerCase(), {
    ns: TranslationNameSpace.TEMPLATES,
    defaultValue: key,
    ...(options ? { ...options } : {}),
  })
  return upperFirst(translation)
}

export const tCommon = (key: string, options?: Options) => {
  const translation = i18n.t('common.' + key, {
    defaultValue: key,
    ...(options ? { ...options } : ({} as Options)),
  })
  return upperFirst(translation)
}

function convertISOLangCodeToLanguageCode(isoLangCode: string) {
  const langCode = isoLangCode.split('-')[0]
  return langCode
}

export default i18n
