import React from 'react'
import GenericTable from '@wheelq/ui-commons/build/tables/GenericTable/GenericTable'
import MainButton from '../../../_shared/Buttons/MainButton'
import { PropTypes } from 'prop-types'
import { downloadCsv } from '../../../../react-services/exportService'
import { post } from '../../../../react-services/apiService'
import ExportModal from '../../../_shared/Modals/ExportModal.tsx'
import { isEmpty } from 'lodash'
import { spawnHelpdeskToast } from '../../../../../utils'
import { DEFAULT_DECIMALS } from '../../../../react-constants/dashboards'
import trackingService from '../../../../react-services/trackingService'
import { TrackingEvent } from '../../../../react-constants'

import './ResponseRate.scss'

export default class ResponseRateTable extends React.Component {
  static propTypes = {
    isAllowedExport: PropTypes.bool,
    query: PropTypes.exact({
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      where_meta: PropTypes.object,
      as_csv: PropTypes.bool,
      page: PropTypes.number,
    }).isRequired,
    surveys: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDownloadFromRow: PropTypes.func.isRequired,
    cdbs: PropTypes.object,
  }

  static defaultProps = {
    isAllowedExport: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      doShowEmptyData: false,
      isExportModalOpen: false,
      isExporting: false,
      waitingForBatchResponse: false,
      isBatchExporting: false,
      isBEResponseBad: false,
      wasLastPageForExportModal: false,
    }
    this.decimals =
      this.props.cdbs != null && this.props.cdbs.decimals
        ? this.props.cdbs.decimals
        : DEFAULT_DECIMALS
  }

  handleEmptyDataChange = () => {
    trackingService.track(TrackingEvent.ToggleEmptyDataVisibility, {
      visible: `${!this.state.doShowEmptyData}`,
    })
    this.setState({ doShowEmptyData: !this.state.doShowEmptyData })
  }

  buildHeaders() {
    return (
      <tr>
        <th colSpan={4}>Survey</th>
        <th colSpan={1}>Sent</th>
        <th colSpan={1}>Answered</th>
        <th colSpan={1}>%</th>
        {this.props.isAllowedExport && (
          <th colSpan={2} onClick={() => {}}>
            Export
          </th>
        )}
      </tr>
    )
  }

  buildRows() {
    let result = []
    this.props.surveys.forEach((survey) => {
      if (survey.statistics) {
        if (survey?.hasError) {
          if (this.state.isBEResponseBad) {
            this.setState({ isBEResponseBad: true })
          }
          result.push(
            <tr key={survey.id} data-testid={`responseRateTableRow-${survey.id}`}>
              <td colSpan={8} className='red-text'>
                {survey.name + ' (Failed to fetch, please try again later)'}
              </td>
            </tr>,
          )
        } else {
          let responseRate = parseFloat(survey.statistics.rate)
          let isDataEmpty = this.areStatisticsEmpty(survey.statistics)

          if (this.state.doShowEmptyData || !isDataEmpty) {
            result.push(
              <tr key={survey.id} data-testid={`responseRateTableRow-${survey.id}`}>
                <td colSpan={4}>{survey.name}</td>
                <td
                  colSpan={1}
                  className='center'
                  data-testid={`responseRateTableRowSent-${survey.id}`}
                >
                  {survey.statistics.sent}
                </td>
                <td
                  colSpan={1}
                  className='center'
                  data-testid={`responseRateTableRowAnswered-${survey.id}`}
                >
                  {survey.statistics.answered}
                </td>
                <td
                  colSpan={1}
                  className='center'
                  data-testid={`responseRateTableRowRate-${survey.id}`}
                >
                  {isNaN(responseRate) ? 'n/a' : responseRate.toFixed(this.decimals)}
                </td>
                {this.props.isAllowedExport && (
                  <PerRowExportButton
                    survey={survey}
                    isLoading={this.state.isExporting}
                    onClick={() => {
                      this.exportOneSurvey(survey)
                    }}
                  />
                )}
              </tr>,
            )
          }
        }
      }
    })

    return result
  }

  buildFooter() {
    let sent = 0,
      answered = 0,
      rate = 0,
      nOfMean = 0

    this.props.surveys.forEach((survey) => {
      if (survey.statistics) {
        sent += parseInt(survey.statistics.sent, 10)
        answered += parseInt(survey.statistics.answered, 10)
        !isNaN(survey.statistics.responseRate) && nOfMean++
      }
    })

    rate = (answered / sent) * 100

    return (
      <tr className='totals-row'>
        <td colSpan={4}>Total</td>
        <td colSpan={1} className='center' data-testid='responseRateTableSent'>
          {sent}
        </td>
        <td colSpan={1} className='center' data-testid='responseRateTableAnswered'>
          {answered}
        </td>
        <td colSpan={1} className='center' data-testid='responseRateTableRate'>
          {isNaN(rate) ? 'n/a' : rate.toFixed(this.decimals)}
        </td>
        {this.props.isAllowedExport && <td colSpan={2}></td>}
      </tr>
    )
  }

  areStatisticsEmpty(stats) {
    return Object.values(stats).every((stat) => stat === 0 || stat === 'NaN' || !stat)
  }

  toggleExportModalOpenness = () => {
    this.setState({
      isExportModalOpen: !this.state.isExportModalOpen,
    })
  }

  exportOneSurvey = (survey) => {
    trackingService.track(TrackingEvent.ExportSingleSurvey)
    const payload = {
      survey_ids: [survey.id],
      page: survey.latestPage >= 0 ? survey.latestPage + 1 : 0,
      ...this.props.query,
    }

    this.doExport(payload, true, survey.name)
  }

  exportMultipleSurveys = (query) => {
    if (isEmpty(query.survey_ids)) {
      query.survey_ids = this.props.surveys.map((survey) => survey.id)
    }

    this.doExport(query)
  }

  doExport = (query, isFromRow = false, surveyName = '') => {
    this.setState({
      isExporting: true,
    })

    post('POST_SURVEY_ROW_DATA', query)
      .then((response) => {
        downloadCsv(response.csv, getFileName(isFromRow, surveyName, query.page + 1))
        if (isFromRow) {
          this.props.onDownloadFromRow(query.survey_ids[0], response)
        } else {
          // length will be 2 since it will be split to the header row and the empty row which is always included at the end
          let wasLastPage = response?.csv?.split('\n').length < 2002 ?? true

          this.setState({
            wasLastPageForExportModal: wasLastPage,
          })
        }
      })
      .catch((error) => {
        this.errorHandling(query, error)
      })
      .finally(() => {
        this.setState({
          isExporting: false,
        })
      })

    function getFileName(isFromRow, surveyName, pageNumber = 1) {
      if (pageNumber < 1) {
        console.error(`Invalid page number. Double check that you are not using indices.`)
        pageNumber = 1
      }

      let beginning = `export`
      let middle = isFromRow && surveyName ? `-${surveyName}` : ``
      middle = middle.concat(`-page${pageNumber}`)
      let extension = `.csv`

      return beginning + middle + extension
    }
  }

  doBatchExport = (query) => {
    if (isEmpty(query.survey_ids)) {
      query.survey_ids = this.props.surveys.map((survey) => survey.id)
    }

    this.setState({ waitingForBatchResponse: true })

    post('POST_SURVEY_BATCH_EXPORT', query)
      .then(() => {
        this.setState({ waitingForBatchResponse: false })
      })
      .catch((error) => {
        this.setState({ waitingForBatchResponse: false })
        if (error.message.includes('423')) {
          this.setState({ isBatchExporting: true })
        } else {
          this.errorHandling(query, error)
        }
      })
  }

  errorHandling = (query, error) => {
    let errorMessage = error + `. Queried with ${JSON.stringify(query)}`
    spawnHelpdeskToast(
      'An error occurred while exporting your survey.',
      `Could not export survey(s)`,
      errorMessage,
    )
  }

  onCloseNotification = () => {
    this.setState({ isBatchExporting: false })
  }

  render() {
    return (
      <>
        <div className='row marginless-row'>
          <input
            id='response-rate-show-empty-data'
            name='emptyData'
            type='checkbox'
            checked={this.state.doShowEmptyData}
            onChange={this.handleEmptyDataChange}
          />
          <label htmlFor='response-rate-show-empty-data' data-testid='showEmptyData'>
            Show empty data
          </label>
        </div>

        <div className='admin-tools-response-rate-table' data-testid='responseRateTable'>
          <div className='center title'>Survey statistics</div>
          <div className='center'>
            {this.props.query.start_date} - {this.props.query.end_date}
          </div>

          <GenericTable
            headers={this.buildHeaders()}
            rows={this.buildRows()}
            footer={this.buildFooter()}
            useFixedLayout
            tableClasses={['response-rate-table']}
          />
        </div>
        <br />

        {this.props.isAllowedExport && (
          <MainButton
            data-testid='responseRateMassExportButton'
            float='right'
            text={this.state.isExporting ? 'Exporting ..' : 'Export ..'}
            isDisabled={this.state.isExporting}
            onClick={this.toggleExportModalOpenness}
          />
        )}

        {this.state.isExportModalOpen && (
          <ExportModal
            dataSelectionType='surveys'
            hasLimitedFilterSet
            surveys={this.props.surveys}
            query={this.props.query}
            isDownloading={this.state.isExporting}
            missingData={this.state.isBEResponseBad}
            onClose={this.toggleExportModalOpenness}
            onExport={(query) => {
              this.exportMultipleSurveys(query)
            }}
            onBatchExport={this.doBatchExport}
            isBatchExporting={this.state.isBatchExporting}
            waitingForBatchResponse={this.state.waitingForBatchResponse}
            onCloseNotification={this.onCloseNotification}
            wasLastPage={this.state.wasLastPageForExportModal}
          />
        )}
      </>
    )
  }
}

function PerRowExportButton({ survey, isLoading = false, onClick }) {
  let pageIndicatorText =
    survey && survey.latestPage >= 0
      ? `${Number(survey.latestPage) + 1} page(s) exported, click again to load next page`
      : null
  if (survey.wasLastPage) pageIndicatorText = 'All pages exported!'
  let isDisabled = survey && (survey.wasLastPage === undefined || survey.wasLastPage) ? true : false

  return (
    <td colSpan={2} className='center per-row-export-button'>
      <button
        onClick={() => {
          !isLoading && !isDisabled && onClick()
        }}
        className={`${isLoading ? 'loading' : ''} ${isDisabled ? 'disabled' : ''}`}
        data-testid='responseRateTableRowExportButton'
      >
        {isDisabled ? (
          <i className='material-icons'>done</i>
        ) : isLoading ? (
          <i className='material-icons'>access_time</i>
        ) : (
          <i className='material-icons'>save_alt</i>
        )}
      </button>
      <small className='per-row-export-pages'>{pageIndicatorText}</small>
    </td>
  )
}
