import React from 'react'
import Icon, { IconName } from '../../../../../utilities/Icon'

import css from './FilterToggle.module.scss'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { tCommon } from '../../../../../../languages/i18n'

type FilterToggleProps = {
  handleOpenFilters: () => void
  showQuickTimeButtons: boolean
  isSmall: boolean
}
const FilterToggle = ({ handleOpenFilters, showQuickTimeButtons, isSmall }: FilterToggleProps) => {
  const { t } = useTranslation()
  const hasNestedStyles = showQuickTimeButtons && isSmall
  const getCntrClasses = () => {
    let classes: string = css.cntr
    if (hasNestedStyles) classes = classes.concat(' ', css.nested)
    return classes
  }

  return (
    <Tooltip title={tCommon('info.filtersButton')}>
      <div
        className={getCntrClasses()}
        onClick={handleOpenFilters}
        aria-label='Filters'
        data-testid='filtersButton'
      >
        <div className={css.icon}>
          <Icon
            name={IconName.TUNE}
            height={hasNestedStyles ? 20 : 26}
            width={hasNestedStyles ? 20 : 26}
            color='#fff'
          />
        </div>
        <div className={css.label}>{t('common.button.filter').toLocaleUpperCase()}</div>
      </div>
    </Tooltip>
  )
}

export default FilterToggle
