import { GenericConfig, Kpi, WhereMeta } from '../../../../../types'
import { OpenAnswersCountsQuery, OpenAnswersCountsResponse } from '../Pietabular/pietabularTypes'
import { ModuleBase, ModuleDefaultSettings } from '../_shared/ModuleSettings/settingsTypes'

export type RadarModule = {
  type: 'radar'
  options: RadarModuleSettings & ModuleDefaultSettings
} & ModuleBase

export type Filters = {
  name: string
  value: string | string[]
}

export type BuildChartDataProps = {
  radarCounts: OpenAnswersCountsResponse | null
  allTopics?: string[]
}

export type RadarModuleQuery = {
  start_date: string
  end_date: string
  where_meta: WhereMeta
  kpis: Kpi.Kpi[]
  calculated_kpis: GenericConfig.CalculatedKpis
}

export type RadarModuleSettings = {
  isAlphabetical?: boolean
  radarDataDisplay?: RadarDataDisplay
  radarDataMode?: RadarDataMode
  radarDataSentiment?: RadarDataSentiment
  radarMetaKey?: string
  radarMetaKeyValues?: string[]
  radarMetaKeyValuesSelected?: string[]
  selectedTopics?: string[]
}

export type RadarDataContext = {
  radarOptions: Highcharts.Options
  radarCountQuery: OpenAnswersCountsQuery | null
  countsByTopic: OpenAnswersCountByTopic[]
  countsByMeta: OpenAnswersCountByMeta[]
  isLoading: boolean
}

export type RadarSettingsContext = {
  radarSettings: RadarModuleSettings
  selectedTopics: string[]
  radarDataSentiment: RadarDataSentiment
  radarDataDisplay: RadarDataDisplay
  radarDataMode: RadarDataMode | null
  radarMetaKey: string
  radarMetaKeyValues: string[]
  radarMetaKeyValuesSelected: string[]
  radarFilters: RadarFilters
  isAlphabetical: boolean
  saveSettings: (settings?: RadarModuleSettings) => void
}

export type RadarFilters = {
  start_date: string
  end_date: string
  where_meta: WhereMeta
}

export type OpenAnswersCountPayload = {
  kpis?: number[]
  pagination?: {
    page_size?: number
    page_number?: number
  }
  category_grouping?: {
    sentiment?: Sentiment[]
    topic?: string[]
    custom?: {
      [customCategory: string]: string[]
    }
  }
  date_grouping: DateGrouping
  start_date?: string
  end_date?: string
  meta_key?: string
  group_by: GroupBy[]
  where_meta?: {
    [customCategory: string]: string[]
  }
}

export type OpenAnswersCountByTopic = {
  topic: string
  positiveCount: number
  negativeCount: number
  neutralCount: number
  uncategorizedCount: number
}

export type OpenAnswersCountByMeta = {
  topic: string
  countByMetaValue: CountByMeta[]
}

type CountByMeta = {
  metaValue: string
  positiveCount: number
  negativeCount: number
  neutralCount: number
  uncategorizedCount: number
}

export enum RadarDataMode {
  AMOUNT = 'Amount',
  COMPARE = 'Compare positive/negative',
  META = 'Meta keys',
}

export enum RadarDataSentiment {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
}

export enum RadarDataDisplay {
  COUNT = 'Count (n)',
  PERCENTAGE = 'Percentage (%)',
}

export enum Sentiment {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
  UNCATEGORIZED = 'Uncategorized',
}

export enum DateGrouping {
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month',
  WEEK = 'week',
}

export enum GroupBy {
  META_KEY = 'meta_key',
  DATE = 'date',
  SENTIMENT = 'sentiment',
  TOPIC = 'topic',
  CUSTOM = 'custom_',
}

export type MetaSelect = {
  label: string
  value: string
}
