import { isEqual } from 'lodash'
import { subMinutes } from 'date-fns'

// Some tips how to implement observables and handle them in React
// https://stackoverflow.com/questions/53451584/is-it-possible-to-share-states-between-components-using-the-usestate-hook-in-r/62002044#62002044?newreg=106c3103b555468b9004c63bdac57f3f

// This observable does not reset value keys but keeps them stored for when new subscribers are added
type Listener<T> = (value: T) => void
export type NonResettingStorage<T> = {
  get: (key: string) => T | undefined
  set: (newValue: T, key: string) => void
  reset: () => void
  getOwner: () => {
    tenant: string
    user: string
  }
  setOwner: (tenant: string, user: string) => void
  subscribe: (listenerFunc: Listener<T>, key: string) => () => void
  unsubscribe: (listenerFunc: Listener<T>, key: string) => void
  getRelatedListeners: (key: string) => Listener<T>[]
  removeOutDatedData: () => void
}

export const makeNonResettingObservable = <T>(
  target: { [key: string]: { data: T; timestamp: Date } },
  numberOfMinutesToOutDateData = 60,
): NonResettingStorage<T> => {
  const listeners = {} as { [key: string]: Listener<T>[] }
  const value = target
  const owner = { tenant: '', user: '' } as { tenant: string; user: string }

  const get = (key: string) => {
    if (!value[key]) return
    else return value[key].data
  }

  const getRelatedListeners = (key: string) => {
    return listeners[key]
  }

  const getOwner = () => {
    return owner
  }

  const setOwner = (tenant: string, user: string) => {
    if (!owner.tenant && !owner.user) {
      owner.tenant = tenant
      owner.user = user
    }
  }

  const set = (newValue: T, key: string) => {
    if (value && typeof value === 'object' && isEqual(value[key as keyof unknown], newValue)) return
    value[key as keyof unknown] = { data: newValue, timestamp: new Date() }
    if (listeners[key]) {
      listeners[key].forEach((l) => l(value[key as keyof unknown].data))
    }
  }

  const subscribe = (listenerFunc: Listener<T>, key: string) => {
    removeOutDatedData()
    if (listeners[key]) {
      listeners[key].push(listenerFunc)
    } else {
      listeners[key] = [listenerFunc]
    }
    return () => unsubscribe(listenerFunc, key)
  }

  const unsubscribe = (listenerFunc: Listener<T>, key: string) => {
    listeners[key] = (listeners[key] || []).filter((l) => l !== listenerFunc)
    if (listeners[key] && listeners[key].length === 0) {
      delete listeners[key]
    }
  }

  const reset = () => {
    if (Object.keys(listeners).length !== 0) {
      Object.keys(listeners).forEach((key) => delete listeners[key])
    }
    if (Object.keys(value).length !== 0) {
      Object.keys(value).forEach((key) => delete value[key])
    }
    owner.user = ''
    owner.tenant = ''
  }

  const removeOutDatedData = () => {
    let storedValueKeys = [] as string[]
    if (value && typeof value === 'object') {
      storedValueKeys = Object.keys(value)
    }
    if (!storedValueKeys.length) return
    let storedListenerKeys = [] as string[]
    if (listeners && typeof listeners === 'object') {
      storedListenerKeys = Object.keys(listeners)
    }
    const notListenedValueKeys = storedValueKeys.filter(
      (valueKey) => !storedListenerKeys.includes(valueKey),
    )
    for (let i = 0; i <= notListenedValueKeys.length; i++) {
      const currentValueKey = notListenedValueKeys[i]
      const currentValueObject: T = (value[currentValueKey] || {}).data
      if (
        currentValueObject &&
        typeof currentValueObject === 'object' &&
        'timestamp' in currentValueObject &&
        currentValueObject['timestamp'] &&
        currentValueObject['timestamp'] instanceof Date
      ) {
        const storeTime = currentValueObject.timestamp
        const currentTime = new Date()
        if (storeTime < subMinutes(currentTime, numberOfMinutesToOutDateData)) {
          delete value[currentValueKey]
        }
      }
    }
  }

  return {
    get,
    set,
    reset,
    getOwner,
    setOwner,
    subscribe,
    unsubscribe,
    getRelatedListeners,
    removeOutDatedData,
  }
}
