import React from 'react'
import useOpenCategories from '../../../../../stores/useOpenCategories'
import { Input } from 'react-materialize'
import { OpenModule } from '../openTypes'
import ClearOrSelectAll from './ClearOrSelectAll'
import { tCategory } from '../../../../../../languages/i18n'

import css from './CategorySelection.module.scss'

type CategorySelectionProps = {
  category: string
  module: OpenModule
  saveModule: (module: OpenModule) => void
  handleClose: () => void
}

const CategorySelection = ({
  category,
  saveModule,
  module,
  handleClose,
}: CategorySelectionProps) => {
  const { answerCategories } = useOpenCategories()
  const allCategories = (answerCategories?.[category] || []).concat('uncategorized')
  const selectedCategoriesInSettings = module.options?.[`${category}CategoryFilter`]

  let selectedSentiments = [] as string[]
  if (selectedCategoriesInSettings) {
    selectedSentiments = selectedCategoriesInSettings
  } else {
    selectedSentiments = allCategories
  }

  const handleClick = (categoryOption: string) => {
    let newSelectedSentiments = [] as string[]
    if (selectedSentiments.includes(categoryOption)) {
      newSelectedSentiments = selectedSentiments.filter((c) => c !== categoryOption)
    } else {
      newSelectedSentiments = selectedSentiments.concat(categoryOption)
    }
    saveModule({
      ...module,
      options: { ...module.options, [`${category}CategoryFilter`]: newSelectedSentiments },
    })
  }

  const handleClearAll = () => {
    saveModule({
      ...module,
      options: { ...module.options, [`${category}CategoryFilter`]: [] },
    })
  }

  const handleSelectAll = () => {
    saveModule({
      ...module,
      options: { ...module.options, [`${category}CategoryFilter`]: allCategories },
    })
  }

  return (
    <div className={css.flexCntr} data-testid='categorySelectionCntr'>
      <div className={css.cntr}>
        {!!allCategories &&
          allCategories.map((categoryName) => (
            <Input
              key={categoryName}
              className={'filled-in alerts-list-checkbox'}
              type='checkbox'
              data-testid='categoryInput'
              label={tCategory(categoryName)}
              value={categoryName}
              checked={selectedSentiments.includes(categoryName)}
              onClick={() => handleClick(categoryName)}
            />
          ))}
      </div>
      <ClearOrSelectAll
        handleClear={handleClearAll}
        handleSelect={handleSelectAll}
        handleClose={handleClose}
      />
    </div>
  )
}

export default CategorySelection
