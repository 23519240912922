import ENDPOINTS, { APIROOT } from '../react-constants/endpoints'
import { logout, isLimitedAccess } from './authService'
import { isUsingIE } from '../../utils'
import requestDuplicator from '../queryDuplicator'
import { decodeURIComponentSafe } from './helperFunctionService'

/*
  This apiservice is used by calling an endpoint with its name.
  Those names can be found in endpoints.js
*/

const DEFAULT_OPTIONS = {
  // use when backend responds with CSV or plain text
  parseAsText: false,
}

var requestQueue = {}
const logtoconsole = false

function getToken() {
  return window.sessionStorage.token
}

function getUserFromToken() {
  return JSON.parse(b64DecodeUnicode(getToken().split('.')[1]))
}

function b64DecodeUnicode(str) {
  return decodeURIComponentSafe(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
}

const roles = function () {
  const user = getUserFromToken()
  return user.role ? user.role : []
}

const hasRole = function (role) {
  return roles().indexOf(role) !== -1
}

function isTestApi() {
  return process.env.NODE_ENV !== 'production'
}

function getEndpoint(name, options = {}) {
  if (!name || name === '') throw new Error('Empty endpoint name was given.')
  else if (name.includes('/')) {
    console.error('name', name, 'options', options)
    throw new Error(
      'Seems like you used an URL on a request. Define an endpoint in constants/endpoints.js and call it by its key value.',
    )
  } else if (!ENDPOINTS[name]) throw new Error('Given endpoint is not defined.')

  let endpoint = ENDPOINTS[name]
  if (endpoint.includes('{')) {
    endpoint = constructEndpoint(endpoint, options)
  }
  return endpoint
}

function getEndpointPath(name, options = {}) {
  let endpoint = getEndpoint(name, options)
  let endpointPath = endpoint.replace(APIROOT, '')
  return endpointPath
}

function constructEndpoint(endpoint, options) {
  let begin = endpoint.indexOf('{')
  let end = endpoint.indexOf('}') - begin + 1

  let replacableToken = endpoint.substr(begin, end)
  let replacedItem = replacableToken.slice(1, -1)

  if (!options)
    throw new Error(
      'Empty endpoint constructor options given for a request that requires ' + replacedItem,
    )
  if (options[replacedItem] === null || typeof options[replacedItem] === 'undefined')
    throw new Error(
      `${endpoint}: Endpoint constructor options do not contain a required attribute ${replacedItem}, or it has an invalid value, or you forgot to pass payload to POST.`,
    )

  return endpoint.replace(replacableToken, options[replacedItem])
}

function get(name = '', options = {}) {
  let endpoint = getEndpoint(name, options)

  return new Promise((resolve, reject) => {
    if (queueForCurrentRequestExists(requestQueue[endpoint])) {
      requestQueue[endpoint].push(resolve)
    } else {
      requestQueue[endpoint] = []
      requestQueue[endpoint].push(resolve)

      return doGet(endpoint, name)
        .then((res) => {
          emptyQueue(requestQueue[endpoint], res)
          return res
        })
        .catch((e) => {
          reject(e)
        })
    }
  })
}

function post(name, body = {}, options = {}) {
  let endpoint = getEndpoint(name, options)
  return doPost(endpoint, body, options, name)
}

function put(name, body = {}, options = {}) {
  let endpoint = getEndpoint(name, options)
  return doPut(endpoint, body, name)
}

function remove(name, body = {}, options = {}) {
  let endpoint = getEndpoint(name, options)
  return doDelete(endpoint, body, name)
}

function queueForCurrentRequestExists(queue) {
  return queue && Array.isArray(queue) && queue.length > 0
}

function emptyQueue(queue, result) {
  while (queue.length > 0) {
    queue.pop()(result)
  }
}

function doGet(url, endpointName) {
  if (logtoconsole) console.log('##get: ' + url)
  /**
   * @type {RequestInit}
   */
  const requestInit = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: getBearer(),
    },
    redirect: 'follow',
    referrer: 'no-referrer',
  }
  if (logtoconsole) console.log('##get: ' + url)

  return fetch(url, requestInit)
    .then((response) => {
      requestDuplicator.scheduleFetchDuplication(endpointName, url, requestInit, response)
      if (response.ok) return response.json()
      if (response.status === 401 || response.status === 403) {
        if (!isLimitedAccess()) logout()
        return
      } else {
        return Promise.reject(response.status)
      }
    })
    .catch((e) => {
      if (e.toString().includes('Unexpected end of JSON input')) {
        console.log('Caught an empty response from server; ignored.')
      } else {
        throw new Error(e)
      }
    })
}

function doPost(url, body = {}, options = DEFAULT_OPTIONS, endpointName) {
  if (logtoconsole) console.log('##post: ' + url)
  /**
   * @type {RequestInit}
   */
  const requestInit = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: getBearer(),
      'Content-Type': 'application/json; charset=utf-8',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(body),
  }
  if (logtoconsole) console.log('##post: ' + url)
  return fetch(url, requestInit)
    .then((response) => {
      requestDuplicator.scheduleFetchDuplication(endpointName, url, requestInit, response)
      if (response.status === 401 || response.status === 403) {
        if (!isLimitedAccess()) logout()
        return
      }

      if (!response.ok)
        throw new Error(
          `Endpoint ${response.url} responded with ${response.status} ${response.statusText}`,
        )

      if (options.parseAsText) return response.text()
      else if (options.responseType === 'blob') {
        return response.blob()
      } else return response.json()
    })
    .then((response) => {
      if (response && response.errors) throw new Error(response)
      else return response
    })
    .catch((e) => {
      if (e.toString().includes('Unexpected end of JSON input')) {
        console.log('Caught an empty response from server; ignored.')
      } else if (isUsingIE()) {
        console.log('Caught an empty response from server with IE; ignored.')
      } else {
        throw new Error(e)
      }
    })
}

function doPut(url, body = {}, endpointName) {
  if (logtoconsole) console.log('##put: ' + url)
  /**
   * @type {RequestInit}
   */
  const requestInit = {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: getBearer(),
      'Content-Type': 'application/json; charset=utf-8',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(body),
  }
  if (logtoconsole) console.log('##put: ' + url)

  return fetch(url, requestInit).then((response) => {
    requestDuplicator.scheduleFetchDuplication(endpointName, url, requestInit, response)
    if (response.status === 401 || response.status === 403) {
      if (!isLimitedAccess()) logout()
      return
    }

    return response.json()
  })
}

function doDelete(url, body = {}, endpointName) {
  if (logtoconsole) console.log('##remove: ' + url)
  /**
   * @type {RequestInit}
   */
  const requestInit = {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: getBearer(),
      'Content-Type': 'application/json; charset=utf-8',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(body),
  }
  if (logtoconsole) console.log('##remove: ' + url)

  return fetch(url, requestInit).then((response) => {
    requestDuplicator.scheduleFetchDuplication(endpointName, url, requestInit, response)
    return response
  })
}

function getBearer() {
  return 'Bearer ' + getToken()
}

export {
  getToken,
  getUserFromToken,
  hasRole,
  isTestApi,
  getEndpoint,
  getEndpointPath,
  get,
  post,
  put,
  remove,
  getBearer as _getBearer,
}
