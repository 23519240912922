import React, { Component } from 'react';
import { Row, Col, Icon } from 'react-materialize';
import { ManualSenderContext } from './_ManualSenderContext';
import DeleteModal from '../_shared/Modals/DeleteModal';
import {isPhoneNumber, looksLikeEmail} from './../../react-services/inputService';
import MetadataRibbon from './MetadataRibbon';
import Select from 'react-select';

export default class ManualSenderRecipientTemplate extends Component {
  static contextType = ManualSenderContext;

  constructor(props, context) {
    super(props, context)

    this.sendMethodFromTemplate = this.context.selectedSurveyTemplate.delivery_method;
    
    this.state = {
      showRemoveModal: false,
      showMetadataRibbon: false,
      name: '',
      contactInfo: '',

      // for "contacts mode"
      contactId: null,
      isEmail: this.sendMethodFromTemplate === 'email' ? true : false,
    }

    this.handleRemove = this.handleRemove.bind(this)
    this.toggleRemoveModal = this.toggleRemoveModal.bind(this)
    this.handleChangeContact = this.handleChangeContact.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleChangeSendingMethod = this.handleChangeSendingMethod.bind(this)
  }

  nameMaker(contactInfo, useAutocomplete, flipName) {
    this.setState({contactInfo: contactInfo})
    if (!isPhoneNumber(contactInfo) && useAutocomplete) {
      let contact = flipName ? nameFlipper(contactInfo) : contactInfo
      this.nameInputHandler(contact);
    } else {
      this.setState({
        contactInfo
      }, () => {
        this.handleUpdate(null)
      })
    }
    
    function nameFlipper(contact) {
      let result = contact;
      if (!looksLikeEmail(contact, true) && contact.includes('.')) {
        var exploded = contact.split('.');
        var last = exploded.pop();
        exploded.unshift(last);
        result = exploded.toString().replace(/,/g, ' ');
      }
      return result;
    }
  }

  nameInputHandler(contact, flipName) {
    if (looksLikeEmail(contact, true))
      this.setState({name: buildName(contact, flipName)}, () => {this.handleUpdate(null)})
    else
      this.setState({name: contact.replace('.', ' ')}, () => {this.handleUpdate(null)})

    function buildName(contact, flip) {
      var name = '';
      for (var char in contact) {
        if (contact[char] !== '@')
          if (contact[char] === '.')
            name += ' ';
          else
            name += contact[char];
        else
          break;
      }
      return name;
    }
  }

  handleChangeContact(contact) {
    let contactDetails = this.state.isEmail ? contact.email : contact.phone;
    this.props.onModify({
      index: this.props.index,
      metadata: contact.meta,
      name: contact.name,
      contact: contactDetails !== "" ? contactDetails : null,
      contactId: contact.id,
      invitationMetadata: this.props.invitationMetadata,
    })

    this.setState({
      contactId: contact.id,
      missingContactInfoError: contactDetails !== "" ? null : `This contact has no ${this.state.isEmail ? 'email address' : 'phone number'}.`
    })
  }

  handleChangeSendingMethod() {
    this.setState({
      isEmail: !this.state.isEmail
    }, () => {
      this.handleChangeContact(this.context.contacts.find(c => c.id === this.state.contactId));
    })
  }

  handleUpdate(metadata = null) {
    this.props.onModify({
      index: this.props.index,
      metadata: this.props.metadata,
      name: this.state.name,
      contact: this.state.contactInfo,
      contactId: this.state.contactId,
      invitationMetadata: metadata ? metadata : this.props.invitationMetadata,
    })
  }

  toggleRemoveModal() {
    this.setState({
      showRemoveModal: !this.state.showRemoveModal
    })
  }

  handleRemove() {
    this.toggleRemoveModal();
    this.props.onRemove(this.props.index)
  }

  render() {
    let {useContacts, contacts, useAutocomplete, flipName} = this.context;
    let thereIsRecipient = useContacts ? !!this.state.contactId : !!this.state.contactInfo;
    let thereIsMetadata = (this.props.metadata && Object.keys(this.props.metadata).length > 0) || (this.props.invitationMetadata && Object.keys(this.props.invitationMetadata).length > 0);

    return (
      <Row style={{marginTop: '2px'}}>
        <div className="valign-wrapper">
          <Col s={1}>
            <div className="manual-survey-recipient-options">
              <Icon id="manual-survey-recipient-options-icon" className="material-icons">more_vert</Icon>
              <div>
                <div className="manual-survey-recipient-tooltip">
                  <div
                    className={`manual-survey-tooltip-option btn-flat ${thereIsRecipient && thereIsMetadata ? '' : 'disabled'}`}
                    onClick={() => this.setState({showMetadataRibbon: !this.state.showMetadataRibbon})}
                  >
                    {!this.state.showMetadataRibbon ? 'Show' : 'Hide'} metadata
                  </div>
                  <hr />
                  <div className="manual-survey-tooltip-option btn-flat" onClick={this.toggleRemoveModal}>Remove contact</div>
                </div>
              </div>
            </div>
          </Col>

          {useContacts ?
            <>
            <Col s={10}>
              <Select
                data-jt="contactSelection" 
                className={`react-select-default-styles ${this.state.missingContactInfoError ? 'manual-sender-contact-error' : ''}`}
                options={contacts.map(c => ({
                  label: c.name,
                  // this contraption makes it possible to search by ID or any meta value or key
                  value: JSON.stringify({id: c.id, ...c.meta}),
                  isDisabled: this.props.selectedContacts.includes(c.id),
                }))}
                styles={{
                  menu: (styles) => ({...styles, 'z-index': '2'})
                }}

                onChange={(selection) => this.handleChangeContact(contacts.find(c => c.id === parseInt(JSON.parse(selection.value).id, 10)))}
              />
            </Col>
            <Col
              className={!this.state.contactId ? 'disabled manual-sender-contact-sending-method-button' : 'manual-sender-contact-sending-method-button'} 
              onClick={() => {this.state.contactId !== null && this.handleChangeSendingMethod()}}
            >
              <button data-jt="contactDeliveryMethodButton" className="blue-grey lighten-4 waves-effect waves-light"><Icon>{this.state.isEmail ? 'alternate_email' : 'sms'}</Icon></button>
            </Col>
            </>
          :
            <>
              <Col s={6}>
                <input data-jt="inputForContactPoint" type="text" value={this.state.contactInfo} onChange={(e) => {this.nameMaker(e.target.value, useAutocomplete, flipName)}} />
              </Col>
              <Col s={5}>
                <input type="text" value={this.state.name} className="capitalize" onChange={(e) => this.setState({name: e.target.value}, () => {this.handleUpdate(null)})}/>
              </Col>
            </>
          }
        </div>
        <div className="center"><label data-jt="missingContactInfoError" className="red-text darken-2">{this.state.missingContactInfoError}</label></div>

        {this.state.showMetadataRibbon &&
          <MetadataRibbon
            metadata={this.props.metadata}
            invitationMetadata={this.props.invitationMetadata}
            contactId={this.state.contactId}

            onModify={this.handleUpdate}
          />
        }

        {this.state.showRemoveModal &&
          <DeleteModal
            customTitle="Remove this contact?"
            onConfirm={this.handleRemove}
            onCancel={this.toggleRemoveModal}
          />
        }
      </Row>
    )
  }
}