import React from 'react'
import CheckBoxItem from '../../_shared/ModuleSettings/components/CheckBoxItem'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import { PietabularModuleSettings } from '../PietabularModuleContext'

import modal_css from './../../../DashboardModules/_shared/ModuleSettings/SettingsModal.module.scss'
import css from './SelectExtensions.module.scss'

const SelectExtensions = () => {
  const [{ module }] = useSettingsTools()
  const options = module?.options ? (module.options as PietabularModuleSettings) : null
  const hasExtensionSelected = !!options?.showPie || !!options?.showTrends || !!options?.showTabular
  return (
    <div className={`${modal_css.max}`}>
      Select extensions to show
      <RequiredStar />
      <div className={`${css.list} ${hasExtensionSelected ? '' : modal_css.required}`}>
        <CheckBoxItem
          text='Show pie'
          data_testid='showPie'
          id='showPie'
          settingsName='showPie'
          width='third'
        />
        <CheckBoxItem
          text='Show tabular'
          data_testid='showTabular'
          id='showTabular'
          settingsName='showTabular'
          width='third'
        />
        <CheckBoxItem
          text='Show trends'
          data_testid='showTrends'
          id='showTrends'
          settingsName='showTrends'
          width='third'
        />
      </div>
    </div>
  )
}

export default SelectExtensions
