import React from 'react'
import SaveText from './SaveText'

import css from './Waiting.module.scss'
import { tCommon } from '../../../../../../../../languages/i18n'

const Waiting = () => {
  return (
    <div className={css.progressCntr}>
      <div className={css.progressBar}>
        <div className={css.progress}></div>
      </div>
      <SaveText text={tCommon('label.autosave')} />
    </div>
  )
}

export default Waiting
