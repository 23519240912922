import { groupBy, isUndefined } from 'lodash'
import { allChartColors } from '../../../../../styles/variableExport'
import { KpiData } from '../../../../../types'
import { PointOptionsObject, SeriesBarOptions, SeriesColumnOptions } from 'highcharts8'
import { FrequencyQueryType, QueryBase } from './frequencyModuleTypes'
import { tCommon, tData } from '../../../../../languages/i18n'

type Options = SeriesBarOptions | SeriesColumnOptions
const useFrequencySeriesBuilder = (
  decimals: number,
  chartUnit: 'n' | '%' | 'avg' | 'sum' | '',
  isMetaComparison = false,
) => {
  const createPointOptionsArrayFromSingleSeries = (
    frequencyCounts: KpiData.NumericData,
    seriesCategories: string[],
    queryBase: QueryBase,
    isComparison?: boolean,
  ): PointOptionsObject[] | null => {
    const { categories, series } = frequencyCounts
    const data = series[0] ? series[0].data : null
    if (!data || !categories) return null
    const totalN = data.reduce((a, c) => (a += c.y), 0)
    const pointOptions = data.map<PointOptionsObject>((dataPoint, i) => {
      const dataPercentValue = +((dataPoint.y / totalN) * 100).toFixed(decimals)
      const dataTotalValue = dataPoint.y
      const displayedValue = chartUnit === '%' ? dataPercentValue : dataTotalValue
      const category = categories[i] === '[empty]' ? 'No category' : categories[i]
      const realIndex = seriesCategories.indexOf(category)
      return {
        x: realIndex,
        y: displayedValue,
        name: category,
        id: category,
        label: category,
        text: category,
        ...(getCustomColor(category, !!isComparison)
          ? { color: getCustomColor(category, !!isComparison) }
          : {}),
        custom: {
          hasMatchingCategory: realIndex > -1,
          total: dataTotalValue,
          percent: dataPercentValue,
          start_date: queryBase.start_date,
          end_date: queryBase.end_date,
        },
      }
    })
    return pointOptions.filter((p) => p.custom?.hasMatchingCategory)
  }

  const createPointOptionsArrayFromMultipleSeries = (
    kpiCounts: KpiData.NumericData,
    queryBase: QueryBase,
  ): PointOptionsObject[] | null => {
    const { categories, series } = kpiCounts
    const data = series ? series : null
    if (!data || !categories) return null
    const pointOptions = data
      .filter((d) => d.data.length > 0)
      .flatMap<PointOptionsObject>((seriesSlice) => {
        const dataName = seriesSlice.name
        const pointBase: Partial<PointOptionsObject> = {
          name: dataName,
          label: dataName,
          text: dataName,
        }
        const totalN = seriesSlice.data.reduce((a, c) => (a += c.data?.n || 0), 0)
        const seriesPoints = seriesSlice.data.map<PointOptionsObject>((dataPoint) => {
          const dataN = dataPoint.data?.n
          const dataSum = dataPoint.data?.sum
          const dataAvg = dataPoint.data?.avg
          const dataPercentValue = +(((dataN || 0) / totalN) * 100).toFixed(decimals)
          const displayedValue: number | undefined =
            chartUnit === '%' && typeof dataPercentValue === 'number'
              ? dataPercentValue
              : chartUnit === 'n' && !isNaN(Number(totalN))
              ? dataN
              : chartUnit === 'sum'
              ? dataSum
              : !isNaN(Number(dataAvg))
              ? Number(dataAvg)
              : undefined
          const metaValue = !isUndefined(dataPoint.x) ? categories[dataPoint.x] : ''
          return {
            ...pointBase,
            y: displayedValue,
            custom: {
              total: dataN,
              avg: dataAvg,
              sum: dataSum,
              percent: chartUnit === '%',
              metaGroup: metaValue || '',
              start_date: queryBase.start_date,
              end_date: queryBase.end_date,
              kpiId: dataPoint.data?.id,
            },
            color: getCustomColor(metaValue, false),
          }
        })
        return seriesPoints
      })
    return pointOptions
  }

  const createBaOrColumnSeries = (
    type: 'bar' | 'column',
    metaFrequencyPoints: PointOptionsObject[] | null,
    metaComparisonFrequencyPoints: PointOptionsObject[] | null,
    numericFrequencyPoints: PointOptionsObject[] | null,
    numericComparisonFrequencyPoints: PointOptionsObject[] | null,
    openFrequencyPoints: PointOptionsObject[] | null,
    openComparisonFrequencyPoints: PointOptionsObject[] | null,
    numericKpiPoints: PointOptionsObject[] | null,
    numericKpiComparisonPoints: PointOptionsObject[] | null,
    queryBase: QueryBase | null,
    comparisonQueryBase: QueryBase | null,
    metaGrouping: string,
  ) => {
    const seriesBase: Options = {
      type,
    }
    const seriesComparisonBase: Options = {
      ...seriesBase,
      showInLegend: true,
      color: allChartColors.LIGHT_BLUE,
    }
    if (metaFrequencyPoints && !metaComparisonFrequencyPoints) {
      return [{ ...seriesBase, data: metaFrequencyPoints, name: metaGrouping, showInLegend: false }]
    }
    if (metaFrequencyPoints && metaComparisonFrequencyPoints) {
      const normalSeriesName = `${tData(metaGrouping)}: ${queryBase?.start_date} - ${
        queryBase?.end_date
      }`
      const comparisonSeriesName = `${tData(metaGrouping)}: ${comparisonQueryBase?.start_date} - ${
        comparisonQueryBase?.end_date
      }`
      const normalSeries: Options = {
        ...seriesBase,
        data: metaFrequencyPoints,
        name: normalSeriesName,
        showInLegend: true,
      }
      const comparisonSeries: Options = {
        ...seriesComparisonBase,
        data: metaComparisonFrequencyPoints,
        name: comparisonSeriesName,
      }
      return [normalSeries, comparisonSeries]
    }
    if (numericFrequencyPoints && !numericComparisonFrequencyPoints) {
      const seriesName = `${queryBase?.start_date} - ${queryBase?.end_date}`
      return [
        { ...seriesBase, data: numericFrequencyPoints, name: seriesName, showInLegend: false },
      ]
    }
    if (numericFrequencyPoints && numericComparisonFrequencyPoints) {
      const normalSeriesName = `${tCommon('label.normal')}: ${queryBase?.start_date} - ${
        queryBase?.end_date
      }`
      const comparisonSeriesName = `${tCommon('label.comparison')}: ${
        comparisonQueryBase?.start_date
      } - ${comparisonQueryBase?.end_date}`
      const normalSeries = {
        ...seriesBase,
        data: numericFrequencyPoints,
        name: normalSeriesName,
        showInLegend: true,
      }
      const comparisonSeries: Options = {
        ...seriesComparisonBase,
        data: numericComparisonFrequencyPoints,
        name: comparisonSeriesName,
      }
      return [normalSeries, comparisonSeries]
    }
    if (openFrequencyPoints && !openComparisonFrequencyPoints) {
      const seriesName = `${queryBase?.start_date} - ${queryBase?.end_date}`
      return [{ ...seriesBase, data: openFrequencyPoints, name: seriesName, showInLegend: false }]
    }
    if (openFrequencyPoints && openComparisonFrequencyPoints) {
      const normalSeriesName = `${tCommon('label.normal')}: ${queryBase?.start_date} - ${
        queryBase?.end_date
      }`
      const comparisonSeriesName = `${tCommon('label.comparison')}: ${
        comparisonQueryBase?.start_date
      } - ${comparisonQueryBase?.end_date}`
      const normalSeries: Options = {
        ...seriesBase,
        data: openFrequencyPoints,
        name: normalSeriesName,
        showInLegend: true,
        index: 0,
      }
      const comparisonSeries: Options = {
        ...seriesComparisonBase,
        data: openComparisonFrequencyPoints,
        name: comparisonSeriesName,
        index: 1,
      }
      return [normalSeries, comparisonSeries]
    }
    if (numericKpiPoints && !numericKpiComparisonPoints) {
      if (!isMetaComparison) {
        const comparisonN = numericKpiPoints?.reduce((a, c) => (a += c.custom?.total || 0), 0)
        const newNumericKpiPointsWithPercentTotal = numericKpiPoints?.map((point) => {
          const n = point.custom?.total || 0
          const percent = +((n / comparisonN) * 100).toFixed(decimals)
          return {
            ...point,
            y: point.custom?.percent === true ? percent : point.y,
            custom: { ...point.custom, percent },
            color: allChartColors.BLUE,
          }
        })
        const seriesName = `${queryBase?.start_date} - ${queryBase?.end_date}`
        return [
          {
            ...seriesBase,
            data: newNumericKpiPointsWithPercentTotal,
            name: seriesName,
            showInLegend: false,
          },
        ]
      } else {
        const seriesGroups = groupBy(
          numericKpiPoints,
          (point) => point.custom?.metaGroup || 'No category',
        )
        const newSeries = Object.entries(seriesGroups)
          .map(([key, points], i) => ({
            ...seriesBase,
            data: points,
            name: key,
            showInLegend: true,
            color: points[0].color,
            colorIndex: i,
          }))
          .map((series) => {
            const newData = series.data.map((point) => {
              const n = point.custom?.total || 0
              const comparisonN = numericKpiPoints
                .filter((p) => p.name === point.name)
                .reduce((a, c) => (a += c.custom?.total || 0), 0)
              const percent = +((n / comparisonN) * 100).toFixed(decimals)
              return {
                ...point,
                y: point.custom?.percent === true ? percent : point.y,
                custom: { ...point.custom, percent },
              }
            })
            return { ...series, data: newData }
          })
        return newSeries
      }
    }
    if (numericKpiPoints && numericKpiComparisonPoints) {
      const normalSeriesName = `${tCommon('label.normal')}: ${queryBase?.start_date} - ${
        queryBase?.end_date
      }`
      const comparisonSeriesName = `${tCommon('label.comparison')}: ${
        comparisonQueryBase?.start_date
      } - ${comparisonQueryBase?.end_date}`
      const newNumericKpiPointsWithPercentTotal = numericKpiPoints?.map((point) => {
        const n = point.custom?.total || 0
        const comparisonN =
          numericKpiComparisonPoints?.find((p) => p.name === point.name)?.custom?.total || 0
        const percent = +((n / (n + comparisonN)) * 100).toFixed(decimals)
        return {
          ...point,
          y: point.custom?.percent === true ? percent : point.y,
          custom: { ...point.custom, percent },
        }
      })
      const newNumericKpiComparisonPointsWithPercentTotal = numericKpiComparisonPoints?.map(
        (point) => {
          const n = point.custom?.total || 0
          const comparisonN =
            numericKpiPoints?.find((p) => p.name === point.name)?.custom?.total || 0
          const percent = +((n / (n + comparisonN)) * 100).toFixed(decimals)
          return {
            ...point,
            y: point.custom?.percent === true ? percent : point.y,
            custom: { ...point.custom, percent },
          }
        },
      )
      const normalSeries: Options = {
        ...seriesBase,
        data: newNumericKpiPointsWithPercentTotal,
        name: normalSeriesName,
        showInLegend: true,
        color: allChartColors.BLUE,
      }
      const comparisonSeries: Options = {
        ...seriesComparisonBase,
        data: newNumericKpiComparisonPointsWithPercentTotal,
        name: comparisonSeriesName,
        color: allChartColors.LIGHT_BLUE,
      }
      return [normalSeries, comparisonSeries]
    }
    return null
  }

  const getCustomColor = (valueName: string, isComparison: boolean) => {
    const v = valueName.toLocaleLowerCase()

    if (v === 'negative' || v === 'detractors' || v === 'arvostelijat' || v === 'no') {
      return isComparison ? allChartColors.LIGHT_RED : allChartColors.RED
    }
    if (v === 'neutral' || v === 'passive' || v === 'neutraalit') {
      return isComparison ? allChartColors.LIGHT_BLUE : allChartColors.BLUE
    }
    if (v === 'positive' || v === 'promoters' || v === 'suosittelijat' || v === 'yes') {
      return isComparison ? allChartColors.LIGHT_BLUE_GREEN : allChartColors.BLUE_GREEN
    }
    return undefined
  }
  const isWithinNumericLimits = (
    point: PointOptionsObject,
    min: string | number | undefined,
    max: string | number | undefined,
  ): boolean => {
    const minNumber = min !== '' ? Number(min) : NaN
    const maxNumber = min !== '' ? Number(max) : NaN
    const pointCategory = point.name !== '' ? Number(point.name) : NaN
    if (isNaN(pointCategory)) return false
    if (isNaN(minNumber) && isNaN(maxNumber)) return true
    if (!isNaN(minNumber) && isNaN(maxNumber)) return pointCategory >= minNumber
    if (isNaN(minNumber) && !isNaN(maxNumber)) return pointCategory <= maxNumber
    return pointCategory >= minNumber && pointCategory <= maxNumber
  }

  const createCategoriesFromSeries = (
    queryType: FrequencyQueryType | undefined,
    counts: KpiData.NumericData | null,
  ) => {
    let newCategories = [] as string[]
    const isMetaCategories = counts && queryType === FrequencyQueryType.META
    if (isMetaCategories) newCategories = counts.categories
    const isNumericFrequencyCategories = counts && queryType === FrequencyQueryType.NUMERIC
    if (isNumericFrequencyCategories) newCategories = counts.categories
    const isOpenCategories = counts && queryType === FrequencyQueryType.OPEN
    if (isOpenCategories) newCategories = counts.categories
    const isNumericKpiCategories = counts && queryType === FrequencyQueryType.NUMERIC_KPI
    if (isNumericKpiCategories) newCategories = counts.categories
    newCategories = newCategories.map((category) =>
      !category || category === '[empty]' ? 'No category' : category,
    )
    return newCategories
  }

  return {
    createPointOptionsArrayFromSingleSeries,
    createPointOptionsArrayFromMultipleSeries,
    isWithinNumericLimits,
    createBaOrColumnSeries,
    createCategoriesFromSeries,
  }
}

export default useFrequencySeriesBuilder
