import { useEffect, useState } from 'react'
import { Categories } from '../components/Dashboards/DashboardModules/Pietabular/pietabularTypes'
import { isCategoriesObject } from '../utilities'
import { getGenericConfiguration } from '../react-services/configService'
import { errorFetchingOpenCategories } from './notificationMessages'
import { useMountedState } from 'react-use'

export type OpenAnswerCategories = {
  sentiment: Sentiment[]
  topic: string[]
  [customCategory: string]: string[]
}

// You need to add sentiments manually, these are not fetched with the other categories but are there in the data
enum Sentiment {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
}

const useOpenCategories = () => {
  const isMounted = useMountedState()
  // these are only configured categories, missing the default
  const [configCategories, setConfigCategories] = useState<Categories | null>(null)
  // all categories that are really used in categorizing answers
  const [answerCategories, setAnswerCategories] = useState<OpenAnswerCategories | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    getCategories()
      .then((categories) => isMounted() && setFetchedCategories(categories))
      .catch((e) => isMounted() && setError(e))
      .finally(() => isMounted() && setLoading(false))
  }, [])

  const getCategories = async () => {
    try {
      const res = await getGenericConfiguration('open_question')
      if (isCategoriesObject(res)) {
        return res
      } else {
        return null
      }
    } catch (e) {
      return Promise.reject(errorFetchingOpenCategories)
    }
  }

  const setFetchedCategories = (configCategories: Categories | null) => {
    setConfigCategories(configCategories)
    if (configCategories === null) return setAnswerCategories(null)
    const { categories, ...customCategories } = configCategories
    const newAnswerCategoriesObject: OpenAnswerCategories = {
      sentiment: Object.values(Sentiment),
      topic: categories,
      ...customCategories,
    }
    setAnswerCategories(newAnswerCategoriesObject)
  }

  return {
    configCategories,
    answerCategories,
    loading,
    error,
  }
}

export default useOpenCategories
