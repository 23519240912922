import React from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { Dashboard } from '../../../../../../types'
import useReportingFilters from '../../../../../stores/useReportingFilters'
import { cloneDeep } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'

const SeriesByDropdown = () => {
  const [{ module, saveModule }] = useSettingsTools()
  let bubbleModule: Dashboard.BubbleModule | null = null
  const { metas } = useReportingFilters()
  if (module?.type === 'bubble') bubbleModule = module
  const savedValue = bubbleModule?.query?.series_by || ''

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!bubbleModule) return
    const newModule = cloneDeep(bubbleModule)
    if (!e.target.value) delete newModule.query.series_by
    else newModule.query.series_by = e.target.value
    saveModule(newModule)
  }

  return (
    <>
      <div className={modal_css.haldf}>
        Series by
        <select
          className={`${css.input} browser-default`}
          onChange={handleOnChange}
          data-testid={'seriesby-input'}
          id={'seriesby-input'}
          value={savedValue || ''}
        >
          <option key={'emptyOption'} value={''}></option>
          {Object.keys(metas || {}).map((metaKey) => (
            <option key={metaKey} value={metaKey}>
              {metaKey}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export default SeriesByDropdown
