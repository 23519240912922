import { useEffect } from 'react'
import { getTenant, getUsername } from '../react-services/authService'
import { NonResettingStorage } from './nonResettingObservable'
import { ResettingStorage } from './resettingObservable'

const useStoreOwner = <T,>(store: NonResettingStorage<T> | ResettingStorage<T>) => {
  const tenant = getTenant() || ''
  const user = getUsername() || ''

  useEffect(() => {
    const owner = store.getOwner()
    if (!owner.tenant || !owner.user || !user || !tenant) store.reset()
    if (owner.tenant !== tenant || owner.user !== user) store.reset()
    store.setOwner(tenant, user)
  }, [tenant, user])

  return { tenant, user }
}
export default useStoreOwner
