import React, { useState } from 'react'
import { useDeepCompareEffect } from 'react-use'

import ConfirmationModal from '../../../../common/ConfirmationModal/ConfirmationModal'
import DoubleModalSide from '../../../../_shared/Modals/DoubleModalSide'
import { AlertChanges, FeedbackAlertModalCategory, FeedbackAlertModalUser } from '../alertTypes'
import { useAlertData, useAlertModalControl, useAlertTools } from '../FeedbackAlertsTableCntr'
import { errorParsingAssignId } from '../FeedBackAlertNotifications'
import { tCategory, tCommon, tData } from '../../../../../../languages/i18n'
import { Button, IconButton } from '@mui/material'
import { Close, Comment, Tune } from '@mui/icons-material'

import css from './ControlModal.module.scss'
import modal_css from './../AlertModal/FeedbackAlertModal.module.scss'

// TODO: create dynamic values
const testMetaValues = [
  'Neurokeskus',
  'Tulehduskeskus',
  'Akuutti',
  'Lasten- ja nuorten sairaudet',
  'Leikkaussalit, teho- ja kivunhoito',
  'Psykiatria',
  'Pää- ja kaulakeskus',
  'Sisätaudit ja kuntoutus',
  'Sydän- ja keuhkokeskus',
  'Syöpäkeskus',
  'Tukielin- ja plastiikkakirurgia',
  'Vatsakeskus',
  'Terveyskylän talot',
  'Omapolku',
  'TerveyskyläPRO',
  'Synnytysosasto',
  '',
]

// TODO: create dynamic values
const selectedMetaKeyToChange = 'Tulosyksikkö'

const ControlModal = () => {
  const [{ alert, alertConfig, isUnsavedChanges }, setAlertData] = useAlertData()
  const setAlertModalControl = useAlertModalControl()[1]
  const [{ handleAlertSave }] = useAlertTools()
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false)
  const [alertCategories, setAlertCategories] = useState<FeedbackAlertModalCategory[]>()
  const [subcategoryFromSelectedCategory, setSubcategoryFromSelectedCategory] = useState<string[]>()
  const [placeholderCategory, setPlaceholderCategory] = useState<boolean>()
  const [alertChanges, setAlertChanges] = useState<AlertChanges>({})

  useDeepCompareEffect(() => {
    if (!alert) return
    setAlertChanges({
      state: alert.state,
      comment: alert.comment,
      assignToUserId: null,
      category: alert.category,
      subcategory: alert.subcategory,
    })
  }, [alert || {}])

  useDeepCompareEffect(() => {
    if (!alertConfig) return

    function handleAlertConfiguration() {
      if (!alertConfig) return
      if (alertConfig.categories && Object.keys(alertConfig.categories).length > 0) {
        const categoryArr: FeedbackAlertModalCategory[] = []
        for (const [key, value] of Object.entries(alertConfig.categories)) {
          if (typeof value === 'string') {
            categoryArr.push({ category: key, subcategory: [value] as string[] })
          } else {
            categoryArr.push({ category: key, subcategory: value as string[] })
          }
        }
        setAlertCategories(categoryArr)
      }
    }
    handleAlertConfiguration()
  }, [alertConfig || {}])

  useDeepCompareEffect(() => {
    if (!alert) return
    if (
      alertChanges.assignToUserId ||
      alert.category !== alertChanges.category ||
      alert.subcategory !== alertChanges.subcategory ||
      alert.comment !== alertChanges.comment ||
      alert.state !== alertChanges.state ||
      (alertChanges.metaValue && alertChanges.metaValue !== alert.metadata[selectedMetaKeyToChange])
    ) {
      setAlertData((prev) => ({ ...prev, isUnsavedChanges: true }))
    } else {
      setAlertData((prev) => ({ ...prev, isUnsavedChanges: false }))
    }
  }, [alertChanges, alert])

  useDeepCompareEffect(() => {
    function checkExistingCategory() {
      alertCategories?.every((category) => {
        if (alertChanges.category === category.category || alertChanges.category === '') {
          setPlaceholderCategory(false)
          setSubcategoryFromSelectedCategory(category.subcategory)
          setAlertChanges((prevState) => ({
            ...prevState,
            subcategory: alertChanges.subcategory,
          }))
          return false
        }
        setPlaceholderCategory(true)
        return true
      })
    }

    checkExistingCategory()
  }, [alertCategories || {}])

  const handleSelectedCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCategory = event.currentTarget.value
    setPlaceholderCategory(false)
    alertCategories?.forEach((category: FeedbackAlertModalCategory) => {
      if (newCategory === category.category) {
        setSubcategoryFromSelectedCategory(category.subcategory)
      }
      if (newCategory === '') {
        setSubcategoryFromSelectedCategory([])
      }
    })
    setAlertChanges((prevState) => ({
      ...prevState,
      category: newCategory,
      subcategory: '',
    }))
  }

  const handleAlertChangesSubcategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSubcategory = event.currentTarget.value
    setAlertChanges((prevState) => {
      return {
        ...prevState,
        subcategory: newSubcategory,
      }
    })
  }

  function handleAlertChangesState(event: React.ChangeEvent<HTMLSelectElement>) {
    const newState = event.currentTarget.value
    setAlertChanges((prevState) => {
      return {
        ...prevState,
        state: newState,
      }
    })
  }

  function handleAlertChangesComment(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const newComment = event.currentTarget.value
    setAlertChanges((prevState) => {
      return {
        ...prevState,
        comment: newComment,
      }
    })
  }

  function handleAlertChangesAssign(event: React.ChangeEvent<HTMLSelectElement>) {
    const newAssign = event.currentTarget.value
    try {
      const id = parseInt(newAssign)
      setAlertChanges((prevState) => {
        return {
          ...prevState,
          assignToUserId: id,
        }
      })
    } catch (e) {
      // TODO: handle error with toast in
      console.error(errorParsingAssignId)
    }
  }

  const handleChangeMetaValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMetaValue = e.currentTarget.value
    if (newMetaValue) {
      setAlertChanges((prevState) => ({
        ...prevState,
        metaKey: selectedMetaKeyToChange,
        metaValue: newMetaValue,
      }))
    }
    if (!newMetaValue) {
      setAlertChanges((prevState) => {
        const newState = { ...prevState }
        delete newState.metaKey
        delete newState.metaValue
        return newState
      })
    }
  }

  const handleChangeReadStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newReadStatus = e.currentTarget.value
    if (newReadStatus === 'read') {
      setAlertChanges((prevState) => ({
        ...prevState,
        response_received: false,
      }))
    }
    if (newReadStatus === 'unread') {
      setAlertChanges((prevState) => ({
        ...prevState,
        response_received: true,
      }))
    }
  }

  const handleCloseSettingsModal = () => {
    if (isUnsavedChanges) {
      setIsCancelConfirmationModalOpen(true)
    } else {
      setAlertModalControl((prev) => ({ ...prev, isSettingsModalOpen: false }))
    }
  }

  const handleCancelConfirmation = () => {
    setAlertData((prev) => ({ ...prev, isUnsavedChanges: false }))
    setIsCancelConfirmationModalOpen(false)
    setAlertModalControl((prev) => ({ ...prev, isSettingsModalOpen: false }))
  }

  return (
    <>
      {isCancelConfirmationModalOpen && (
        <ConfirmationModal
          handleConfirmation={handleCancelConfirmation}
          handleCancel={() => setIsCancelConfirmationModalOpen(false)}
          title='Unsaved changes'
          info='Please confirm to exit without saving. To confirm, press enter or click confirm button. To cancel, press esc or click cancel.'
          enableEnterConfirmation={true}
        />
      )}
      <div className={modal_css.cntr}>
        <DoubleModalSide onClose={handleCloseSettingsModal} animation='slideLeft'>
          {alert && (
            <div className={css.settings_modal}>
              <div className={modal_css.header}>
                <h5>
                  <div>{tCommon('label.control')} </div> <Tune sx={{ fontSize: '24px' }} />
                </h5>
                <IconButton onClick={handleCloseSettingsModal} sx={{ padding: 0 }}>
                  <Close sx={{ color: '#fff', fontSize: '28px' }} />
                </IconButton>
              </div>
              <div className={css.form}>
                {alertCategories && (
                  <>
                    <div className={css.input_cntr}>
                      <span>{tCommon('label.category')}</span>
                      <select
                        className={css.input_field}
                        value={
                          alertChanges.category !== null && alertChanges.category !== undefined
                            ? alertChanges.category
                            : ''
                        }
                        onChange={(event) => handleSelectedCategory(event)}
                        id='alert-modal-category-select'
                      >
                        <option></option>
                        {placeholderCategory && <option disabled>{alert.category}</option>}
                        {alertCategories &&
                          alertCategories.map((category: FeedbackAlertModalCategory, index) => (
                            <option key={index} value={category.category}>
                              {category.category}
                              {tCategory(category?.category?.toLocaleLowerCase())}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className={css.input_cntr}>
                      <span>{tCommon('label.subcategory')}</span>
                      <select
                        className={css.input_field}
                        value={
                          alertChanges.subcategory !== null &&
                          alertChanges.subcategory !== undefined
                            ? alertChanges.subcategory
                            : ''
                        }
                        disabled={!subcategoryFromSelectedCategory}
                        id='alert-modal-subcategory-select'
                        onChange={(event) => handleAlertChangesSubcategory(event)}
                      >
                        <option></option>
                        {placeholderCategory && <option disabled>{alert.subcategory}</option>}
                        {subcategoryFromSelectedCategory &&
                          subcategoryFromSelectedCategory.map((subcategory, index) => (
                            <option key={index}>{subcategory}</option>
                          ))}
                      </select>
                    </div>
                  </>
                )}

                <div className={css.input_cntr}>
                  <span>{tCommon('label.state')}</span>
                  <select
                    className={css.input_field}
                    value={
                      alertChanges.state !== null && alertChanges.state !== undefined
                        ? alertChanges.state
                        : ''
                    }
                    onChange={(event) => handleAlertChangesState(event)}
                    id='alert-modal-state-select'
                  >
                    <option value={'OPEN'}>{tCommon('label.open').toLocaleUpperCase()}</option>
                    <option value={'IN_PROGRESS'}>
                      {tCommon('label.inProgress').toLocaleUpperCase()}
                    </option>
                    <option value={'CLOSED'}>{tCommon('label.closed').toLocaleUpperCase()}</option>
                  </select>
                </div>
                {alertConfig && alertConfig.assign && (
                  <div className={css.input_cntr}>
                    <span>{tCommon('label.shareTo')}</span>
                    <select
                      className={css.input_field}
                      onChange={(event) => handleAlertChangesAssign(event)}
                      id='alert-modal-assign-select'
                    >
                      <option></option>
                      {alertConfig &&
                        alertConfig.users &&
                        alertConfig.users.map((user: FeedbackAlertModalUser) => (
                          <option key={user.id} value={user.id}>
                            {user.username}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {alertConfig && alertConfig.show_conversation && (
                  <>
                    <div className={css.input_cntr}>
                      <span>{tData(selectedMetaKeyToChange)}</span>
                      <select
                        className={css.input_field}
                        value={
                          alertChanges.metaValue !== undefined && alertChanges.metaValue !== null
                            ? alertChanges.metaValue
                            : alert.metadata['office location: business unit']
                        }
                        onChange={(event) => handleChangeMetaValue(event)}
                        id='alert-modal-meta-change'
                      >
                        <option value={undefined}></option>
                        {testMetaValues.map((location) => (
                          <option key={location}>{location}</option>
                        ))}
                      </select>
                    </div>
                    <div className={css.input_cntr}>
                      <span className='alert-modal-read-title'>
                        {tCommon('label.messageStatus')}
                      </span>
                      <select
                        className={css.input_field}
                        value={alertChanges.response_received ? 'unread' : 'read'}
                        onChange={(event) => handleChangeReadStatus(event)}
                        id='alert-modal-read-status-change'
                      >
                        <option value={'unread'}>{tCommon('label.unread')}</option>
                        <option value={'read'}>{tCommon('label.read')}</option>
                      </select>
                    </div>
                  </>
                )}
                <div className={css.textarea_cntr}>
                  <div className=''>
                    <span className='icon-text'>
                      <b>{tCommon('label.writeEditComment')}</b>
                      <Comment />
                    </span>
                    <textarea
                      name='comment'
                      placeholder='Write a comment...'
                      value={
                        alertChanges.comment !== null && alertChanges.comment !== undefined
                          ? alertChanges.comment
                          : ''
                      }
                      onChange={(event) => handleAlertChangesComment(event)}
                    />
                  </div>
                </div>
              </div>
              <div className={css.btns}>
                {/* <button
                  className={`btn blue ${css.btn}`}
                  onClick={() => handleAlertSave(alert, alertChanges)}
                  disabled={!isUnsavedChanges}
                >
                  {tCommon('button.save')}
                </button> */}
                <Button
                  onClick={() => handleAlertSave(alert, alertChanges)}
                  disabled={!isUnsavedChanges}
                  variant='contained'
                >
                  {tCommon('button.save')}
                </Button>
                <Button onClick={handleCloseSettingsModal} variant='contained'>
                  {tCommon('button.cancel')}
                </Button>
              </div>
            </div>
          )}
        </DoubleModalSide>
      </div>
    </>
  )
}

export default ControlModal
