import React from 'react'
import { Dashboard } from '../../../../stores/useDbTemplateConfig'
import DashboardActions from './DashboardActions'
import DashboardModules from './DashboardModules'
import useDashboardSections from '../../../../stores/useDashboardSections'

import css from './DashboardBuilder.module.scss'

type DashboardBuilderProps = {
  currentDashboard: Dashboard | null
}
const DashboardBuilder = ({ currentDashboard }: DashboardBuilderProps) => {
  const { sections } = useDashboardSections()
  return (
    <>
      {currentDashboard && (
        <div className={css.dashboardModules}>
          <DashboardModules currentDashboard={currentDashboard} />
          <DashboardActions currentDashboard={currentDashboard} />
        </div>
      )}
      {!!sections && !currentDashboard && <div>Dashboard not found</div>}
    </>
  )
}

export default DashboardBuilder
