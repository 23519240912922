export type MetaAndPosition = {
  name: string,
  position: number,
}

export enum Position {
  BOTTOM = 'BOTTOM',
  BETWEEN = 'BETWEEN',
  TOP = 'TOP',
}

export enum Direction {
  UP = 'UP',
  UP_TOP = 'UP_TOP',
  DOWN = 'DOWN',
  DOWN_BOTTOM = 'DOWN_BOTTOM',
}

export enum InspectorType {
  OPEN = 'OPEN',
  NUMERIC = 'NUMERIC'
}