import React, { Component } from 'react'
import MetadataBoardItem from './MetadataBoardItem'
import { PropTypes } from 'prop-types'
import { cloneDeep, isEmpty } from 'lodash'
import { tCommon } from '../../../../languages/i18n'
import { AddCircleOutline } from '@mui/icons-material'
import withReportingFilters from '../Hoc/withReportingFilters'

import './MetadataBoard.scss'

class MetadataBoard extends Component {
  static propTypes = {
    selections: PropTypes.arrayOf(PropTypes.object),
    customFilterSet: PropTypes.object,
    areCustomFiltersMerged: PropTypes.bool,
    isMulti: PropTypes.bool,
    isUsedForFiltering: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    creatables: PropTypes.oneOf(['values', 'both', 'neither']),
    reportingFilters: PropTypes.object,
  }

  static defaultProps = {
    customFilterSet: null,
    isMulti: false,
    isUsedForFiltering: false,
    creatables: 'neither',
  }

  constructor(props) {
    super(props)

    if (props.creatables.includes('key')) {
      throw new Error(`Cannot set "${props.creatables}" as creatables, use "both" instead.`)
    }

    this.allFilters = this.getFilterSet(props.reportingFilters)
    this.allFilterKeys = Object.keys(this.allFilters)
    this.state = {
      selectedMetas: props.selections ? cloneDeep(props.selections) : [],
    }
  }

  getFilterSet(reportingFilters) {
    let result = {}
    let doesUseReportingFilters = true
    let doesUseCustomFilters = false

    if (!isEmpty(this.props.customFilterSet)) {
      doesUseCustomFilters = true
      if (!this.props.areCustomFiltersMerged) {
        doesUseReportingFilters = false
      }
    }

    doesUseReportingFilters && Object.assign(result, reportingFilters)
    doesUseCustomFilters && Object.assign(result, this.props.customFilterSet)
    return result
  }

  addMeta = () => {
    this.setState({
      selectedMetas: this.state.selectedMetas.concat({
        key: null,
        values: null,
      }),
    })
  }

  handleChange = (i, key, value) => {
    let copyOfState = cloneDeep(this.state.selectedMetas)
    copyOfState[i] = {
      key,
      values: value ? value : null,
    }

    this.setState({ selectedMetas: copyOfState })
    this.props.onChange(copyOfState)
  }

  handleDelete = (atIndex) => {
    let remainingMetas = this.state.selectedMetas
      .slice(0, atIndex)
      .concat(this.state.selectedMetas.slice(atIndex + 1, this.state.selectedMetas.length))
    this.setState({ selectedMetas: remainingMetas })
    this.props.onChange(remainingMetas)
  }

  render() {
    const selectableKeys = (() => {
      let alreadySelectedKeys = this.state.selectedMetas.map((meta) => meta.key)
      return this.allFilterKeys.filter((f) => !alreadySelectedKeys.includes(f))
    })()

    const selectableValues = (key) => {
      if (key && this.allFilters[key]) return this.allFilters[key]
      else return []
    }

    let isAbleToAddMore =
      this.props.creatables === 'both' ||
      this.state.selectedMetas.length < Object.keys(this.allFilters).length

    return (
      <div className='metadata-board'>
        {this.state.selectedMetas.length > 0 && (
          <div className='row marginless-row'>
            <div className='col s5'>
              <label>{tCommon('label.key')}</label>
            </div>
            <div className='col s6'>
              <label>{tCommon('label.value')}</label>
            </div>
            <div className='col s1 center'>
              <label>{tCommon('label.remove')}</label>
            </div>
          </div>
        )}

        {this.state.selectedMetas.map((meta, i) => (
          <MetadataBoardItem
            key={`meta-${meta.value}-${i}`}
            isMulti={this.props.isMulti}
            index={i}
            metaKey={meta.key}
            metaValues={meta.values}
            possibleKeys={selectableKeys}
            possibleValues={selectableValues(meta.key)}
            creatables={this.props.creatables}
            valuePlaceholderText={this.props.valuePlaceholderText}
            keyPlaceholderText={this.props.keyPlaceholderText}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        ))}

        {this.state.selectedMetas.length === 0 && (
          <div className='row center'>
            <div className='metadata-board-empty'>
              {tCommon('info.metadataBoard1')}
              <AddCircleOutline />
              {tCommon('info.metadataBoard2')}
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col s12'>
            <button
              disabled={!isAbleToAddMore}
              data-testid='addMetadataRow'
              onClick={isAbleToAddMore ? this.addMeta : undefined}
            >
              <div className={`valign-wrapper ${isAbleToAddMore ? '' : 'disabled'}`}>
                <i className='material-icons small'>add_circle_outline</i>
              </div>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withReportingFilters(MetadataBoard)
