import React from 'react'
import { getTenant, getUsername, isLoggedIn } from '../../../../react-services/authService'
import { SURVEYURL } from '../../../../react-constants/urls'
import { tCommon } from '../../../../../languages/i18n'

const FooterBar = () => {
  const tenant = getTenant()
  const username = getUsername()
  const surveyUrl = `${SURVEYURL}?tenant=${tenant}&username=${username}`

  return (
    <div className='footer-fixed'>
      <div className='footer-fixed-content'>
        <div className='container flexible-space-between-margin-nowrap'>
          <div>
            <a
              className='hover-enlarge-text'
              href='https://wheelq.com'
              target='_blank'
              rel='noreferrer'
            >
              WheelQ
            </a>
          </div>
          <div className='margin-left-auto'>
            <a
              className='hover-enlarge-text'
              href='https://wheelq.zendesk.com/hc/en-us'
              target='_blank'
              rel='noreferrer'
            >
              {tCommon('button.userGuide')}
            </a>
          </div>
          <span>|</span>
          <div>
            <a className='hover-enlarge-text' href='mailto:helpdesk@wheelq.com'>
              {tCommon('button.contactUs')}
            </a>
          </div>
          {isLoggedIn() && (
            <>
              <span>|</span>
              <div data-cy='feedbackLauncher' className='brand-color-footer'>
                <a className='hover-enlarge-text' href={surveyUrl} target='_blank' rel='noreferrer'>
                  {tCommon('button.feedback')}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FooterBar
