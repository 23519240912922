import React from 'react'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import Subtitle from '../../_shared/ModuleSettings/components/Subtitle'
import { Data } from '../../../../../../types'
import KpiFormatDropDown from './KpiFormatDropDown'
import KpiMinMaxTextInput from './KpiMinMaxTextInput'
import { isBasicDataFormat } from '../../../../../utilities'
import { TabularModule, TabularSettings } from '../tabularTypes'
import { isUndefined } from 'lodash'

import css from './CellDetailsSelection.module.scss'

const CellDetailsSelection = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const tabularModule = module as TabularModule
  const isKpiSpecificSettings = !!(
    tabularModule &&
    tabularModule.options &&
    tabularModule.options.isKpiSpecificSettings
  )
  const kpis = tabularModule?.options?.kpis || []

  const handleKpiDetailsSave = (
    newProperty:
      | Partial<{
          id: number
          name: string
          dataMode?: Data.FormatBasic
          limitValues?: {
            min?: number
            max?: number
          }
        }>
      | Partial<TabularSettings>,
    kpiId: number | 'all',
  ) => {
    if (!tabularModule) return
    if (kpiId === 'all') {
      const newModule: TabularModule = {
        ...tabularModule,
        options: { ...(tabularModule.options || {}), ...newProperty },
      }
      saveModule(newModule)
    } else {
      if (!kpis || !Array.isArray(kpis)) return
      const newKpis = kpis.map((kpi) => {
        if (kpi.id === kpiId) return { ...(kpi || {}), ...newProperty }
        else return kpi
      })
      const newModule: TabularModule = {
        ...tabularModule,
        options: { ...(tabularModule.options || {}), kpis: newKpis },
      }
      saveModule(newModule)
    }
  }

  return (
    <div>
      {!isKpiSpecificSettings ? (
        <>
          <div style={{ marginBottom: '15px' }}>
            <Subtitle text={'All'} fontSize='17px' />
          </div>
          <div className={css.details} data-testid='kpiDetailsBox'>
            <KpiFormatDropDown
              title='Format'
              value={tabularModule?.options?.cellDataMode || ''}
              handleOnChange={(newValue) =>
                handleKpiDetailsSave(
                  {
                    cellDataMode: isBasicDataFormat(newValue) ? newValue : 'avg',
                  },
                  'all',
                )
              }
            />
            <KpiMinMaxTextInput
              title='Min'
              handleOnChange={(newValue) =>
                handleKpiDetailsSave(
                  {
                    limitValues: {
                      ...(tabularModule?.options?.limitValues || {}),
                      min: newValue === '' ? undefined : Number(newValue),
                    },
                  },
                  'all',
                )
              }
              value={(tabularModule?.options?.limitValues?.min || '').toString()}
            />
            <KpiMinMaxTextInput
              title='Max'
              handleOnChange={(newValue) =>
                handleKpiDetailsSave(
                  {
                    limitValues: {
                      ...(tabularModule?.options?.limitValues || {}),
                      max: newValue === '' ? undefined : Number(newValue),
                    },
                  },
                  'all',
                )
              }
              value={(tabularModule?.options?.limitValues?.max || '').toString()}
            />
          </div>
        </>
      ) : (
        <>
          {Array.isArray(kpis) &&
            kpis.map(({ name, id, dataMode, limitValues }, i) => (
              <React.Fragment key={id}>
                <div style={{ marginBottom: '15px' }}>
                  <Subtitle text={name ? name : 'no name'} key={id || i + name} fontSize='17px' />
                </div>
                <div className={css.details} data-testid='kpiDetailsBox'>
                  <KpiFormatDropDown
                    title='Format'
                    value={
                      dataMode
                        ? dataMode
                        : tabularModule?.options?.cellDataMode
                        ? tabularModule.options.cellDataMode
                        : ''
                    }
                    handleOnChange={(newValue) => {
                      const formats = ['avg', 'n', 'sum']
                      if (formats.includes(newValue) || isUndefined(newValue)) {
                        const checkedValue = newValue as Data.FormatBasic | undefined
                        handleKpiDetailsSave({ dataMode: checkedValue }, id)
                      }
                    }}
                  />
                  <KpiMinMaxTextInput
                    title='Min'
                    handleOnChange={(newValue) =>
                      handleKpiDetailsSave(
                        {
                          limitValues: {
                            ...limitValues,
                            min: newValue === '' ? undefined : Number(newValue),
                          },
                        },
                        id,
                      )
                    }
                    value={
                      limitValues?.min !== undefined
                        ? limitValues.min.toString()
                        : tabularModule?.options?.limitValues?.min
                        ? tabularModule.options.limitValues.min.toString()
                        : ''
                    }
                  />
                  <KpiMinMaxTextInput
                    title='Max'
                    handleOnChange={(newValue) =>
                      handleKpiDetailsSave(
                        {
                          limitValues: {
                            ...limitValues,
                            max: newValue === '' ? undefined : Number(newValue),
                          },
                        },
                        id,
                      )
                    }
                    value={
                      limitValues?.max !== undefined
                        ? limitValues.max.toString()
                        : tabularModule?.options?.limitValues?.max
                        ? tabularModule.options.limitValues.max.toString()
                        : ''
                    }
                  />
                </div>
              </React.Fragment>
            ))}
        </>
      )}
    </div>
  )
}

export default CellDetailsSelection
