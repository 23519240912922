import React, { useState } from 'react'
import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'
import { useDeepCompareEffect } from 'react-use'

import { alertSave, generatePayload } from '../../../../../react-services/alertService'
import ConfirmationModal from '../../../../common/ConfirmationModal/ConfirmationModal'
import { useToastId } from '../../../../common/Notification/NotificationContext'
import DoubleModalSide from '../../../../_shared/Modals/DoubleModalSide'
import { FeedbackAlert } from '../alertTypes'
import {
  successAlertClose,
  successAlertInProgress,
  successAlertOpen,
} from '../FeedBackAlertNotifications'
import { useAlertData, useAlertModalControl, useAlertTools } from '../FeedbackAlertsTableCntr'
import AlertListCard from './AlertCard'

import css from './RelatedAlertsModal.module.scss'

const RelatedAlertsModal = () => {
  const setAlertModalControl = useAlertModalControl()[1]
  const [{ relatedAlerts }] = useAlertData()
  const [newStateAndAlert, setNewStateAndAlert] = useState<{
    state: string
    alert: FeedbackAlert
  } | null>(null)
  const { toastifyId } = useToastId()
  const [sortedRelatedAlerts, setSortedRelatedAlerts] = useState<FeedbackAlert[] | null>(null)
  const [{ updateAlerts, getAlerts }] = useAlertTools()

  useDeepCompareEffect(() => {
    if (relatedAlerts) {
      const copyOfRelatedAlerts = cloneDeep(relatedAlerts)
      const sortedCopyOfRelatedAlerts = copyOfRelatedAlerts.sort(handleSortByState)
      setSortedRelatedAlerts(sortedCopyOfRelatedAlerts)
    }
  }, [relatedAlerts])

  const handleStateChangeConfirmation = () => {
    if (!newStateAndAlert) return
    const { state, alert } = newStateAndAlert
    const changes = { state }
    const payload = generatePayload(alert, changes)
    alertSave(payload, alert.id)
      .then(() => {
        if (state === 'OPEN')
          toastifyId && toast.success(successAlertOpen, { containerId: toastifyId })
        if (state === 'CLOSED')
          toastifyId && toast.success(successAlertClose, { containerId: toastifyId })
        if (state === 'IN_PROGRESS')
          toastifyId && toast.success(successAlertInProgress, { containerId: toastifyId })
      })
      .then(() => updateAlerts && updateAlerts(alert.id, changes))
      .catch((err: unknown) => {
        toastifyId && toast.error(typeof err === 'string' ? err : '', { containerId: toastifyId })
      })
      .finally(() => {
        getAlerts && getAlerts()
        setNewStateAndAlert(null)
      })
  }

  const handleSortByState = (alertA: FeedbackAlert, alertB: FeedbackAlert) => {
    if (alertA.state === 'IN_PROGRESS' && (alertB.state === 'CLOSED' || alertB.state === 'OPEN')) {
      return -1
    }
    if (alertA.state === 'OPEN' && alertB.state === 'CLOSED') {
      return -1
    }
    if (alertA.state === 'OPEN' && alertB.state === 'IN_PROGRESS') {
      return 1
    }
    return 0
  }

  return (
    <>
      {newStateAndAlert && (
        <ConfirmationModal
          info={`Are you sure you want to change state to '${newStateAndAlert.state}'?`}
          confirm={'Confirm'}
          cancel={'Cancel'}
          handleCancel={() => setNewStateAndAlert(null)}
          handleConfirmation={() => handleStateChangeConfirmation()}
          enableEnterConfirmation={true}
        />
      )}
      <DoubleModalSide
        onClose={() =>
          setAlertModalControl((prev) => ({ ...prev, isRelatedAlertsModalOpen: false }))
        }
        animation='slideLeft'
      >
        <br />
        <div className={css.related_modal}>
          <span className={css.related_header}>
            <b>Related Alerts</b>
          </span>
          <div className={css.cards_list}>
            {sortedRelatedAlerts &&
              sortedRelatedAlerts.map((alert) => (
                <AlertListCard
                  key={alert.id}
                  alert={alert}
                  setNewStateAndAlert={setNewStateAndAlert}
                />
              ))}
          </div>
        </div>
      </DoubleModalSide>
    </>
  )
}

export default RelatedAlertsModal
