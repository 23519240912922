import React from 'react';
import GenericButton, { GenericButtonProps } from './GenericButton';
import css from './IconButton.module.scss';

const PreviewIcon = (props:GenericButtonProps) => (
  <GenericButton
    onClick={props.onClick}
    icon={'visibility'}
    text=""
    customClasses={[css.btn,...(props.customClasses ? props.customClasses : [])]}
    iconPosition={'center'}
    title="Preview the changes"
    data_testid="preview-icon-button"
  />
)

export default PreviewIcon;