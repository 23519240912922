import { Keyboard } from './keyboard'

const DEFAULTMODULES = {
  PATH: 'path',
  NPS: 'nps',
  BAR: 'bar',
  LINE: 'line',
  TABULAR: 'tabular',
  OPEN: 'open',
  MAP: 'map',
  FREQUENCY: 'freq',
  GROUP: 'group',
  GROUP2: 'group2',
  WHEEL: 'wheel',
  NUMBERTREND: 'numbertrend',
  PICTURE: 'picture',
  TEXTBOX: 'textbox',
  WORDCLOUD: 'wordcloud',
}

const ADDITIONALMODULES = {
  TABLE: 'table', // TODO deprecate
  FOURFIELDER: 'fourfielder',
  ALERT: 'alert',
  PATHLINE: 'pathline',
  REPORTS: 'reports_overview',
  LISTNUMERIC: 'listnumeric',
  ACTIONS: 'actions',
  SURVEYS: 'surveys',
  EXPORT: 'export',
  RADAR: 'radar',
  BUBBLE: 'bubble',
  ENABLERSBARRIERS: 'ebs',
  PIETABULAR: 'pietabular',
  SUMMARY: 'summary',

  // brand modules
  BRAND: 'brand',
  BS_DIFFERENTIATION: 'bs_differentiation',
  BS_BRANDSTRENGTH: 'bs_brandstrength',
  TASKS: 'tasks',
}

const DEPRECATED = {
  CXOVERVIEW: 'cx_overview',
  CXOVERVIEW2: 'cx_overview2',
  ONECUSTOMER: 'oc_overview',
  SEGMENTSUMMARY: 'segment_overview',
  ONESEGMENT: 'os_overview',
}

const moduleTypes = Object.assign({}, DEFAULTMODULES, ADDITIONALMODULES)

const REPORTMODULES = [
  DEFAULTMODULES.FREQUENCY,
  DEFAULTMODULES.GROUP,
  DEFAULTMODULES.BAR,
  DEFAULTMODULES.LINE,
  DEFAULTMODULES.MAP,
  DEFAULTMODULES.NUMBERTREND,
  DEFAULTMODULES.WHEEL,
  DEFAULTMODULES.OPEN,
  DEFAULTMODULES.PICTURE,
  DEFAULTMODULES.TEXTBOX,
  DEFAULTMODULES.WORDCLOUD,
  DEFAULTMODULES.TABULAR,

  ADDITIONALMODULES.BUBBLE,
  ADDITIONALMODULES.ENABLERSBARRIERS,
  ADDITIONALMODULES.FOURFIELDER,
  ADDITIONALMODULES.LISTNUMERIC,
  ADDITIONALMODULES.PATHLINE,
  ADDITIONALMODULES.PIETABULAR,
  ADDITIONALMODULES.RADAR,
  ADDITIONALMODULES.SUMMARY,
  ADDITIONALMODULES.TABLE,
]

/*  List of modules for the module picker.
 *  Keep in mind that the order does matter!
 */
const PICKABLE_DASHBOARD_MODULES = [
  /*  Group 0
      group module and other modules that have to be at the top level
  */
  {
    type: moduleTypes.GROUP,
    name: 'Module group',
    description: 'Group modules together',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-group.png',
    groupNumber: 0,
    hotKeyCode: Keyboard.g,
  },
  {
    type: moduleTypes.REPORTS,
    name: 'Report overview',
    description: 'An overview of projects',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-performance.png',
    groupNumber: 0,
    isGroupingProhibited: true,
    isRestricted: true,
  },

  /*  Group 1
      "Regular" modules for displaying data
  */
  {
    type: moduleTypes.LINE,
    name: 'Line chart',
    description: 'Data as a function of time',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-line.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.l,
  },
  {
    type: moduleTypes.FREQUENCY,
    name: 'Frequency chart',
    description: 'Frequencies of data points',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-freq.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.f,
  },
  {
    type: moduleTypes.TABULAR,
    name: 'Tabular data',
    description: 'Numeric data in a table',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-tabular.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.t,
  },
  {
    type: moduleTypes.OPEN,
    name: 'Open answers',
    description: 'Open answers for surveys',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-open.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.o,
  },
  {
    type: moduleTypes.NUMBERTREND,
    name: 'Single number',
    description: 'Highlight a single number',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-single.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.n,
  },
  {
    type: moduleTypes.FOURFIELDER,
    name: 'Four fielder chart',
    description: 'Correlation between KPIs',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-4f.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.num4,
    isRestricted: true,
  },
  {
    type: moduleTypes.BUBBLE,
    name: 'Bubble chart',
    description: 'Bubble chart XYZ with bubble size',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-4f.png',
    groupNumber: 1,
    isGroupingForced: true,
    isRestricted: true,
    hotKeyCode: Keyboard.u,
  },
  {
    type: moduleTypes.WHEEL,
    name: 'Wheel',
    description: 'Touchpoints by performance',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-wheel.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.w,
  },
  {
    type: moduleTypes.LISTNUMERIC,
    name: 'Top & bottom',
    description: 'Data ranked by performance',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-topbottom.png',
    groupNumber: 1,
    hotKeyCode: Keyboard.d,
    isRestricted: true,
  },

  /*  Group 2
  Regular modules that have a supportive role
  */
  {
    type: moduleTypes.TEXTBOX,
    name: 'Text box',
    description: 'Add textual descriptions',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-text.png',
    groupNumber: 2,
    hotKeyCode: Keyboard.x,
  },
  {
    type: moduleTypes.PICTURE,
    name: 'Picture',
    description: 'Add a picture',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-pic.png',
    groupNumber: 2,
    hotKeyCode: Keyboard.i,
  },
  {
    type: moduleTypes.WORDCLOUD,
    name: 'Word cloud',
    description: 'Prevalence of words in answers',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-wordcloud.png',
    groupNumber: 2,
    hotKeyCode: Keyboard.num8,
  },

  /*  Group 3
  Specialized modules
  */
  {
    type: moduleTypes.ALERT,
    name: 'Alert list',
    description: 'List of received alerts',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-alerts.png',
    groupNumber: 3,
    hotKeyCode: Keyboard.a,
    isRestricted: true,
  },
  {
    type: moduleTypes.PIETABULAR,
    name: 'Pie tabular',
    description: 'Frequencies visualized with tabular',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-freq.png',
    groupNumber: 3,
    isRestricted: true,
    isGroupingForced: true,
  },
  {
    type: moduleTypes.ACTIONS,
    name: 'Actions',
    description: 'Insight to alert handling',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-actions.png',
    groupNumber: 3,
    hotKeyCode: Keyboard.y,
    isRestricted: true,
  },
  {
    type: moduleTypes.RADAR,
    name: 'Radar chart',
    description: 'Compare data points',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-radar.png',
    groupNumber: 3,
    hotKeyCode: Keyboard.r,
    isGroupingForced: true,
    isRestricted: true,
  },
  {
    type: moduleTypes.SUMMARY,
    name: 'Summary',
    description: 'Summary of open answers',
    iconUrl: 'https://wheelq.s3-eu-west-1.amazonaws.com/ui/icon-module-text.png',
    groupNumber: 3,
    hotKeyCode: Keyboard.j,
    isGroupingForced: false,
    isRestricted: true,
  },

  /*  Group 10
      Modules working only when tenant has the salescompanion in use.
      LEFT HERE FOR REFERENCE AND STYLES
  */
]

export {
  DEFAULTMODULES,
  ADDITIONALMODULES,
  DEPRECATED,
  REPORTMODULES,
  moduleTypes,
  PICKABLE_DASHBOARD_MODULES,
}
