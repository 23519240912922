import React, { useEffect, useReducer, useRef, useState, useMemo, memo } from 'react'
import HighchartsReact from 'highcharts-react-official'
import * as Highcharts from 'highcharts8'
import { AddOrRemove, Breakpoint, DateGroups, GroupedDataWithCounts } from '../pietabularTypes'
import { ActionTypes, ChartBreakpoint, trendsReducer } from './trendsReducer'
import { OpenAnswersQuery, defaultOptions } from '../PietabularModuleContext'
import NoData from '../../../../_shared/Infos/NoData'
import BreakPointModal from './BreakpointModal/BreakPointModal'
import { cloneDeep, isEqual } from 'lodash'
import useCommonDbSettingsConfig from '../../../../../stores/useCommonDbSettingsConfig'
import ErrorBoundary from '../../../../_shared/Infos/ErrorBoundary'
import {
  errorConvertingBreakpointToDateGroups,
  errorCreatingInspectorDateRange,
  errorLoadingBreakpoints,
  errorLoadingInspector,
} from '../PietabularNotifications'
import usePietabularFunctions from '../usePietabularFunctions'
import { toast } from 'react-toastify'
import { GenericConfig } from '../../../../../../types'
import OpenInspectorCntr from '../../_shared/ValueInspector/Open/OpenInspectorCntr'
import { startAndEndDatesOfWeekInYear } from '../../../../../react-services/datesService'
import { createChart } from './trendsChart'
import FadeIn from '../../_shared/FadeIn'
import { CircularProgress } from '@mui/material'
import ChartButton from '../../_shared/ChartComponents/ChartButton'
import { useTranslation } from 'react-i18next'

import css from './Trends.module.scss'
import { useUpdateEffect } from 'react-use'
import { reportSVGWidth } from '../../../../../../styles/variableExport'

Highcharts.AST.allowedAttributes.push('data-content')

type DateRange = {
  start_date: string
  end_date: string
}

type TrendsProps = {
  groupedAndCountedData: GroupedDataWithCounts | null
  filteredTopics: (string | null)[] | undefined
  areas?: string[] | null
  trendsCntrRef?: HTMLDivElement
  isRefetching?: boolean
  modulewidth?: string
  showPie: boolean
  showTabular: boolean
  showTrends: boolean
  breakpoints: Breakpoint[] | null | undefined
  denominator?: string
  numerator?: string
  numberOfTopTopicsToShow: number | undefined
  showRegressionLines: boolean
  showRollingAverage: boolean
  showAreaShareCharts: boolean
  showBreakpoints: boolean
  trendsTitle?: string
  trendsSubTitle?: string
  defaultCountedKey: string | undefined
  defaultMetaGroup: string | undefined
  defaultDateGroup: DateGroups | undefined
  isScreenMode: boolean
  isReportMode: boolean
  query: OpenAnswersQuery | null
  saveSettingsProperty: (newProperty: Record<string, unknown> | undefined) => void
}

const Trends = memo(
  ({
    groupedAndCountedData,
    filteredTopics,
    areas,
    trendsCntrRef,
    isRefetching = false,
    modulewidth,
    showPie,
    showTabular,
    showTrends,
    breakpoints,
    denominator,
    numerator,
    numberOfTopTopicsToShow,
    showRegressionLines,
    showRollingAverage,
    showAreaShareCharts,
    showBreakpoints,
    trendsTitle,
    trendsSubTitle,
    defaultCountedKey,
    defaultMetaGroup,
    defaultDateGroup,
    isScreenMode,
    isReportMode,
    query,
    saveSettingsProperty,
  }: TrendsProps) => {
    const { t, i18n } = useTranslation()
    const {
      handleMatchingBreakpointsWithYearGroups,
      handleMatchingBreakpointsWithQuarterGroups,
      handleMatchingBreakpointsWithMonthGroups,
      handleMatchingBreakpointsWithWeekGroups,
    } = usePietabularFunctions()
    let chartWidth = 0
    if (trendsCntrRef) chartWidth = trendsCntrRef.offsetWidth
    const [clickedDateGroup, setClickedDateGroup] = useState<string | null>(null)
    const isChartWithSmall = chartWidth && chartWidth < 900
    const chartOptionsTimeoutRef = useRef<NodeJS.Timeout>()
    const isdateGroupButtonDisabled = useRef<boolean>(false)
    const initOptionsState = useMemo(
      () => createChart(!!isChartWithSmall, isReportMode, isScreenMode, chartWidth),
      [],
    )

    const setTimeRef = useRef<NodeJS.Timeout>()
    const chartUpdateTimeoutRef = useRef<NodeJS.Timeout>()
    let visibleBreakPoints = [] as Breakpoint[]
    if (showBreakpoints && breakpoints) {
      visibleBreakPoints = breakpoints
    }
    const [chartOptions, setChartOptions] = useReducer(trendsReducer, initOptionsState)
    const [dateRange, setDateRange] = useState<DateRange | null>(null)
    const [isInspectorModalOpen, setIsInspectorModalOpen] = useState(false)
    const [breakpointModalOpen, setBreakpointModalOpen] = useState<boolean>(false)
    const { config } = useCommonDbSettingsConfig()
    const [reRender, setReRender] = useState<boolean>(false)
    const [chartCopy, setChartCopy] = useState<Highcharts.Options | null>(null)

    useMemo(() => {
      if (chartUpdateTimeoutRef.current) clearTimeout(chartUpdateTimeoutRef.current)
      chartUpdateTimeoutRef.current = setTimeout(() => {
        setChartCopy(cloneDeep(chartOptions))
      }, 400)
    }, [chartOptions])

    useUpdateEffect(() => {
      setChartOptions({
        type: ActionTypes.SET_LANGUAGE,
      })
    }, [i18n.language])

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
    const chart = useMemo(() => {
      if (chartCopy && groupedAndCountedData) {
        return (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartCopy}
            ref={chartComponentRef}
            containerProps={{ id: 'trendchart-container', style: { height: 'inherit' } }}
          />
        )
      }
      if (chartComponentRef.current?.chart?.reflow) chartComponentRef.current.chart.reflow()
    }, [chartCopy])

    useEffect(() => {
      // Try to force a proper update
      setReRender(true)
      if (setTimeRef.current) clearTimeout(setTimeRef.current)
      setTimeRef.current = setTimeout(() => {
        setReRender(false)
        if (chartComponentRef.current?.chart?.reflow) chartComponentRef.current.chart.reflow()
      }, 1000)
      return () => {
        setTimeRef.current && clearTimeout(setTimeRef.current)
        chartUpdateTimeoutRef.current && clearTimeout(chartUpdateTimeoutRef.current)
      }
    }, [
      modulewidth,
      showPie,
      showTabular,
      showTrends,
      denominator,
      numerator,
      numberOfTopTopicsToShow,
      showRegressionLines,
      showRollingAverage,
      showAreaShareCharts,
      showBreakpoints,
      trendsTitle,
      trendsSubTitle,
      defaultCountedKey,
    ])

    useEffect(() => {
      if (!groupedAndCountedData || !Object.keys(groupedAndCountedData).length) return
      if (chartOptionsTimeoutRef.current) clearTimeout(chartOptionsTimeoutRef.current)
      chartOptionsTimeoutRef.current = setTimeout(() => {
        let convertedBreakpoints = [] as ChartBreakpoint[]
        if (groupedAndCountedData && Object.keys(groupedAndCountedData).length > 1 && areas) {
          convertedBreakpoints = convertBreakpointDatesToPointsInTheXAxis(
            visibleBreakPoints,
            defaultDateGroup || defaultOptions.defaultDateGroup,
            groupedAndCountedData,
            config,
          )
        }
        if (groupedAndCountedData && Object.keys(groupedAndCountedData).length > 1 && areas) {
          setChartOptions({
            type: ActionTypes.SET_BREAKPOINT,
            data: {
              counts: groupedAndCountedData,
              isReportMode: !!isReportMode,
              areas: [...areas, 'uncategorized'],
              numerator: numerator || '',
              denominator: denominator || '',
              breakpoints: convertedBreakpoints,
              settings: {
                showAreaShareCharts: showAreaShareCharts === false ? false : true,
                showRegressionLine: showRegressionLines === false ? false : true,
                showRollingAvgLine: showRollingAverage === false ? false : true,
              },
            },
          })
        }
        if (groupedAndCountedData && Object.keys(groupedAndCountedData).length > 1 && areas) {
          setChartOptions({
            type: ActionTypes.SET_CHART_DATA,
            data: {
              counts: groupedAndCountedData,
              rollingAvgDelta: 3,
              areas: [...areas, 'uncategorized'],
              numerator: numerator,
              denominator: denominator,
              breakpoints: convertedBreakpoints,
              settings: {
                showAreaShareCharts: showAreaShareCharts === false ? false : true,
                showRegressionLine: showRegressionLines === false ? false : true,
                showRollingAvgLine: showRollingAverage === false ? false : true,
              },
              clickDateColumn: (category) => setClickedDateGroup(category || null),
            },
          })
        } else {
          setChartOptions({
            type: ActionTypes.SET_CHART_DATA,
            data: { counts: null, areas: null, settings: null },
          })
        }
      }, 300)
      return () => chartOptionsTimeoutRef.current && clearTimeout(chartOptionsTimeoutRef.current)
    }, [
      i18n.language,
      breakpoints,
      showRegressionLines,
      showAreaShareCharts,
      showRollingAverage,
      showBreakpoints,
      denominator,
      numerator,
      config?.breakpoints,
      defaultDateGroup,
      areas,
      groupedAndCountedData,
    ])

    const setTitleTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
      setTitleTimeoutRef.current = setTimeout(() => {
        setChartOptions({
          type: ActionTypes.SET_TITLE,
          data: { title: trendsTitle || '', subtitle: trendsSubTitle || '' },
        })
      }, 1)
      return () => setTitleTimeoutRef.current && clearTimeout(setTitleTimeoutRef.current)
    }, [trendsTitle, trendsSubTitle])

    const setDateRangeTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
      setDateRangeTimeoutRef.current = setTimeout(() => {
        if (!isInspectorModalOpen) setDateRange(null)
      }, 1)
      return setDateRangeTimeoutRef.current && clearTimeout(setDateRangeTimeoutRef.current)
    }, [isInspectorModalOpen])

    useEffect(() => {
      isdateGroupButtonDisabled.current = false
    }, [defaultDateGroup])

    const handleInspectorTimeoutRef = useRef<NodeJS.Timeout>()
    useEffect(() => {
      handleInspectorTimeoutRef.current = setTimeout(() => {
        if (clickedDateGroup) {
          handleInspectorOpening()
        }
      }, 1)
      return () =>
        handleInspectorTimeoutRef.current && clearTimeout(handleInspectorTimeoutRef.current)
    }, [clickedDateGroup])

    const handleInspectorOpening = () => {
      if (!defaultDateGroup || !clickedDateGroup) return
      try {
        if (defaultDateGroup === DateGroups.YEAR) {
          setDateRange({
            start_date: clickedDateGroup + '-01-01',
            end_date: clickedDateGroup + '-12-31',
          })
        }
        if (defaultDateGroup === DateGroups.QUARTER) {
          const year = parseInt(clickedDateGroup.substring(0, 4))
          const quarter = parseInt(clickedDateGroup.substring(7, 8))
          const lastDayOfMonth = new Date(year, 3 * quarter, 0).getDate()
          setDateRange({
            start_date: year + `-${3 * quarter - 2}` + '-01',
            end_date: year + `-${3 * quarter}` + `-${lastDayOfMonth}`,
          })
        }
        if (defaultDateGroup === DateGroups.MONTH) {
          const year = parseInt(clickedDateGroup.substring(0, 4))
          const month = parseInt(clickedDateGroup.substring(6, 8))
          const lastDayOfMonth = new Date(year, month, 0).getDate()
          setDateRange({
            start_date: year + `-${month}` + '-01',
            end_date: year + `-${month}` + `-${lastDayOfMonth}`,
          })
        }
        if (defaultDateGroup === DateGroups.WEEK) {
          const year = parseInt(clickedDateGroup.substring(0, 4))
          const week = parseInt(clickedDateGroup.substring(6, 8))
          const dates = startAndEndDatesOfWeekInYear(year, week)
          if (!dates) return
          setDateRange(dates)
        }
      } catch (e) {
        toast.error(errorCreatingInspectorDateRange)
      }
      setIsInspectorModalOpen(true)
    }

    const calculateButtonsLeftPosition = (titleLength: number, subtitleLength: number) => {
      let extraMargin = 80
      if (trendsTitle) {
        const addedMargin = 20 + titleLength * 9
        extraMargin += addedMargin
      }
      if (trendsSubTitle) {
        const addedMargin = 20 + subtitleLength * 6
        extraMargin = addedMargin > extraMargin ? extraMargin + addedMargin : extraMargin
      }
      return extraMargin
    }

    const convertBreakpointDatesToPointsInTheXAxis = (
      breakpoints: Breakpoint[],
      dateGroup: DateGroups,
      groupedAndCountedData: GroupedDataWithCounts | null,
      config: GenericConfig.CommonDbSettings,
    ): ChartBreakpoint[] => {
      if (!groupedAndCountedData) return []
      const possibleDateGroupsInData = Object.keys(groupedAndCountedData)
      if (!possibleDateGroupsInData.length) return []
      if (!config || !config.breakpoints) return []

      const configBpsFilteredBySettingsBpsIds = config.breakpoints.filter((configBp) =>
        breakpoints.map((settingsBp) => settingsBp.id).includes(configBp.id),
      )
      if (!configBpsFilteredBySettingsBpsIds.length || !possibleDateGroupsInData.length) return []

      try {
        if (dateGroup === DateGroups.YEAR) {
          return handleMatchingBreakpointsWithYearGroups(
            configBpsFilteredBySettingsBpsIds,
            possibleDateGroupsInData,
          )
        }
        if (dateGroup === DateGroups.QUARTER) {
          return handleMatchingBreakpointsWithQuarterGroups(
            configBpsFilteredBySettingsBpsIds,
            possibleDateGroupsInData,
          )
        }
        if (dateGroup === DateGroups.MONTH) {
          return handleMatchingBreakpointsWithMonthGroups(
            configBpsFilteredBySettingsBpsIds,
            possibleDateGroupsInData,
          )
        }
        if (dateGroup === DateGroups.WEEK) {
          return handleMatchingBreakpointsWithWeekGroups(
            configBpsFilteredBySettingsBpsIds,
            possibleDateGroupsInData,
          )
        }
      } catch (e) {
        toast.error(errorConvertingBreakpointToDateGroups)
      }

      return []
    }

    const handleAddOrRemoveBreakpoint = (breakpoint: Breakpoint, action: AddOrRemove) => {
      const prevBreakpoints = visibleBreakPoints
      let newBreakpoints = [] as Breakpoint[]
      if (action === AddOrRemove.ADD) {
        const hasBpAlready = prevBreakpoints.map((bp) => bp.id).includes(breakpoint.id)
        newBreakpoints = hasBpAlready ? prevBreakpoints : [...prevBreakpoints, breakpoint]
      }
      if (action === AddOrRemove.REMOVE) {
        newBreakpoints = prevBreakpoints.filter((bp) => bp.id !== breakpoint.id)
      }
      if (action !== AddOrRemove.ADD && action !== AddOrRemove.REMOVE) {
        newBreakpoints = prevBreakpoints
      }
      saveSettingsProperty({ breakpoints: newBreakpoints })
    }

    const handleDateGroupButtonClick = () => {
      if (isdateGroupButtonDisabled.current) return
      isdateGroupButtonDisabled.current = true
      if (defaultDateGroup === DateGroups.WEEK)
        saveSettingsProperty({ defaultDateGroup: DateGroups.YEAR })
      if (defaultDateGroup === DateGroups.MONTH)
        saveSettingsProperty({ defaultDateGroup: DateGroups.WEEK })
      if (defaultDateGroup === DateGroups.QUARTER)
        saveSettingsProperty({ defaultDateGroup: DateGroups.MONTH })
      if (defaultDateGroup === DateGroups.YEAR)
        saveSettingsProperty({ defaultDateGroup: DateGroups.QUARTER })
    }

    const handleInspectorClosing = () => {
      setIsInspectorModalOpen(false)
      setClickedDateGroup(null)
    }

    const inspectorFallBack = useMemo(
      () => <div style={{ color: 'red' }}>{errorLoadingInspector}</div>,
      [],
    )

    const breakpointsFallBack = useMemo(
      () => <div style={{ color: 'red' }}>{errorLoadingBreakpoints}</div>,
      [],
    )
    return (
      <>
        {isInspectorModalOpen && !!dateRange && !!query && (
          <ErrorBoundary message={errorLoadingInspector} fallback={inspectorFallBack}>
            <OpenInspectorCntr
              inspected={`${defaultMetaGroup && 'Metagroup: ' + defaultMetaGroup} ${
                defaultCountedKey && ', Counted category: ' + defaultCountedKey
              }`}
              query={{ ...query, ...dateRange, category_grouping: { topic: filteredTopics } }}
              onClose={handleInspectorClosing}
            />
          </ErrorBoundary>
        )}
        {breakpointModalOpen && (
          <ErrorBoundary message={errorLoadingBreakpoints} fallback={breakpointsFallBack}>
            <BreakPointModal
              breakpoints={breakpoints || []}
              onClose={() => setBreakpointModalOpen(false)}
              handleAddOrRemoveBreakpoint={handleAddOrRemoveBreakpoint}
            />
          </ErrorBoundary>
        )}
        {!reRender &&
        groupedAndCountedData &&
        Object.keys(groupedAndCountedData).length &&
        chartCopy ? (
          <FadeIn>
            <div
              className={`${css.cntr} ${isReportMode ? css.report : ''}`}
              data-testid='pietabular-trends-cntr'
              style={{ width: isReportMode || isScreenMode ? reportSVGWidth : '100%' }}
            >
              {!!chart && !isReportMode && !isScreenMode && (
                <div
                  style={{
                    left: calculateButtonsLeftPosition(
                      trendsTitle?.length || 0,
                      trendsSubTitle?.length || 0,
                    ),
                  }}
                  className={css.buttons}
                >
                  <ChartButton
                    text={t('common.button.breakpoints')}
                    onClick={() => setBreakpointModalOpen(true)}
                  />
                  <ChartButton
                    text={t('common.data.' + (defaultDateGroup || '').toLocaleLowerCase())}
                    onClick={handleDateGroupButtonClick}
                    isLast
                  />
                  {isRefetching && (
                    <CircularProgress
                      thickness={2}
                      size={'1.7rem'}
                      style={{ opacity: 0.7, marginLeft: '10px' }}
                    />
                  )}
                </div>
              )}

              {chart}
            </div>
          </FadeIn>
        ) : (
          <div></div>
        )}
        {(reRender || !groupedAndCountedData) && (
          <div className={`${css.cntr} ${css.loading}`}>
            <CircularProgress thickness={1} size={'5rem'} style={{ opacity: 0.7 }} />
          </div>
        )}

        {!reRender && groupedAndCountedData && !Object.keys(groupedAndCountedData).length && (
          <div className={css.cntr}>
            <NoData />
          </div>
        )}
      </>
    )
  },
  arePropsEqual,
)

function arePropsEqual(prevProps: TrendsProps, newProps: TrendsProps) {
  return isEqual(prevProps, newProps)
}
Trends.displayName = 'Trends'

export default Trends
