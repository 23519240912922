import { Chart } from 'highcharts8'
import { allChartColors, darkerChartColors } from '../../../../../../styles/variableExport'
import { getTenant } from '../../../../../react-services/authService'
import { pSBC } from '../../../../../react-services/colorService'
import { tCommon } from '../../../../../../languages/i18n'

export const createChart = (
  isReportOrScreen: boolean,
  handleClickChartPoint: (e: Highcharts.PointClickEventObject) => void,
  labelRenderTimeout: React.MutableRefObject<NodeJS.Timeout | undefined>,
): Highcharts.Options => {
  const isAnimationDisabled = getTenant() === 'test_frontend' || isReportOrScreen
  const ANIMATION_DURATION = isAnimationDisabled ? 0 : 1200

  function resetLabelPositions(this: Chart) {
    try {
      if (labelRenderTimeout.current) clearTimeout(labelRenderTimeout.current)
      labelRenderTimeout.current = setTimeout(
        () =>
          this.setTitle(
            {
              text: 'Frequency chart',
              style: { opacity: 0, fontSize: '12px' },
            },
            {
              text: 'Frequency chart',
              style: { opacity: 0, fontSize: '12px' },
            },
            true,
          ),
        3000,
      )
    } catch (error) {
      // no need to handle, fails silently
    }
  }

  return {
    accessibility: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: isReportOrScreen ? false : true,
      },
    },
    title: {
      text: '',
      floating: true,
      useHTML: true,
      style: {
        width: 0,
        zIndex: -1,
      },
    },

    chart: {
      animation: { duration: ANIMATION_DURATION },
      marginTop: 50,
      marginLeft: isReportOrScreen ? 80 : 60,
      marginRight: isReportOrScreen ? 30 : 35,
      spacingLeft: 10,
      // force recalculate on datalabels by settings hidden title for the series
      events: {
        load: resetLabelPositions,
        redraw: resetLabelPositions,
      },
      type: 'column',
    },
    subtitle: {
      style: {
        width: 0,
      },
    },
    exporting: {
      sourceWidth: 1800,
      sourceHeight: 675,
      scale: 1.5,
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      buttons: {
        contextButton: {
          y: 0,
          x: -10,
        },
      },
    },
    xAxis: {
      type: 'category',
      labels: {
        style: { color: allChartColors.GREY, fontSize: !isReportOrScreen ? '12px' : '15px' },
      },
      endOnTick: false,
      startOnTick: false,
    },
    yAxis: {
      labels: {
        style: { color: allChartColors.GREY, fontSize: !isReportOrScreen ? '12px' : '15px' },
      },
      title: {
        style: {
          color: allChartColors.GREY,
          fontSize: !isReportOrScreen ? '12px' : '15px',
          fontWeight: 'bold',
        },
        rotation: 0,
        align: 'high',
        offset: 0,
        y: -20,
        x: -14,
      },
      lineColor: pSBC(0.6, darkerChartColors.GREY) || darkerChartColors.GREY,
      gridLineColor: pSBC(0.6, darkerChartColors.GREY) || darkerChartColors.GREY,
    },
    series: [],
    plotOptions: {
      column: {
        pointPadding: 0.06,
        maxPointWidth: 80,
        groupPadding: 0.11,
        dataLabels: {
          enabled: true,
          allowOverlap: false,
          padding: 5,
          y: -5,
          defer: 1300,
          style: {
            color: allChartColors.GREY,
            fontSize: !isReportOrScreen ? '12px' : '15px',
          },
        },
        animation: {
          duration: ANIMATION_DURATION,
        },
        animationLimit: 200,
        colorAxis: true,
        colors: Object.values(allChartColors),
        point: {
          events: {
            click: handleClickChartPoint,
          },
        },
      },
    },
    colors: [...Object.values(allChartColors), ...Object.values(allChartColors)],
    legend: {
      itemStyle: {
        color: darkerChartColors.GREY,
        fontSize: '13px',
      },
      margin: 0,
      maxHeight: isReportOrScreen ? 200 : 80,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{series.name}</span></br>',
      pointFormat:
        '<div style="margin-top:10px">' +
        '<div style="height:10px;width:10px;border-radius:5px;background-color:{point.color};display:inline-block"></div>' +
        '<span style="font-size:12px">{point.name}</span>' +
        '</br>' +
        '<span style="margin-top:8px;font-size:12px;font-weight:bold">' +
        `${tCommon('label.count')}: {point.custom.total}` +
        '</span>' +
        '</br>' +
        '<span style="font-size:12px;font-weight:bold">' +
        `${tCommon('label.share')}: {point.custom.percent}%` +
        '</span>' +
        '</div>',
      style: {
        color: allChartColors.GREY,
      },
    },
  }
}
