export const topBotNotification = 'The following settings are required'
export const selectTopBotColumnNote = 'At least one table column'
export const selectTopBotDataDisplayNote = 'Data display'
export const selectTopBotMaxNumberOfResultsNote = 'Max number of result'
export const topBotMaxResultInfo = 'Select how many rows to display on tables'
export const topBotPathInfo =
  'Use kpis and categories provided by customer path configuration.\nOtherwise use kpi selection tree.'
export const topBotColumnsInfo =
  'Performance = Kpi average\nTrend = Compare current and previous timeframe'
export const topBotInterestInfo =
  'Top table = Display only green values\nBottom table = Display only red values'
export const infoNoDataSource =
  'No data source for topbottom, select data source as config or kpis from the list'
