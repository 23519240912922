/**
 * Production key is for the original account and the fallback key is for the dev account
 */
const MIXPANEL_TOKEN = process.env.NODE_ENV === 'production' ? 'd0f27885f10b937dc992d32d7391de2a' : '94004cc01649a6f86db45a3904893409'

const DEFAULT_METAS = {
  gitHeadSHA: process.env.REACT_APP_GIT_HEAD_SHA,
  userAgent: navigator.userAgent
}

export {
  MIXPANEL_TOKEN,
  DEFAULT_METAS
}
