import React, { useState } from 'react'
import Rodal from 'rodal'
import MainButton from '../Buttons/MainButton'
import './Modals.scss'

type Props = {
  warning?: boolean
  error?: boolean
  title?: string
  notification: string
  onClose: () => void
  onAnimationEnd?: () => void
}

// TODO add coloring for warnings and errors
// also... evaluate if this NotificationModal is necessary
// this is made originally for the BatchExport

const NotificationModal = ({ warning, error, title, notification, onClose, onAnimationEnd }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  return (
    <div className="notification-modal">
      <Rodal
        closeOnEsc
        animation="slideUp"
        visible={isOpen}
        onClose={() => { setIsOpen(false) }}
        onAnimationEnd={() => {
          if (!isOpen && onAnimationEnd) {
            onAnimationEnd();
          }
        }}>
        <div className="container">
          {!warning && !error &&
            <h5>{title ? title : 'Notification!'}</h5>
          }
          {warning &&
            <h5>{title ? title : 'Warning!'}</h5>
          }
          {error &&
            <h5>{title ? title : 'Error!'}</h5>
          }
          <div className="divider" />
          <br></br>
          <div className="row">
            <div className="col s9 offset-s1">
              <span>{notification}</span>
              <br></br>
            </div>
          </div>

          <div className="row">
            <div className="col s3 offset-s5">
              <MainButton text="Ok" onClick={() => { onClose() }} />
            </div>
          </div>
        </div>
      </Rodal>
    </div>
  )
}

export default NotificationModal;