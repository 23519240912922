import { omit } from 'lodash'
import { TrackingEvent } from '../react-constants'
import { post } from './apiService'
import {
  getClaims,
  isLimitedAccess,
  isLoggedIn,
  refreshToken,
  saveTenantToSessionStorage,
  saveUsernameToSessionStorage,
  setToken,
} from './authService'
import trackingService from './trackingService'

export type Credentials = {
  tenant: string
  username: string
  password: string
  loginas?: string
  sysAdminLogin?: boolean
}

export let isLoggedInBoolean = false
export let refreshTokenInterval: null | NodeJS.Timeout = null

export const minutesToMillis = function (minutes: number) {
  return minutes * 60 * 1000
}

const REFRESH_TOKEN_INTERVAL = 30000
export const refreshLogin = () => {
  if (isLoggedIn()) {
    isLoggedInBoolean = true
    if (refreshTokenInterval) clearInterval(refreshTokenInterval)
    refreshTokenInterval = setInterval(() => {
      refreshToken()
    }, REFRESH_TOKEN_INTERVAL)
  }
}

export const login = async (credentials: Credentials) => {
  if (credentials.sysAdminLogin) {
    trackingService.isSysadmin = true
  } else {
    trackingService.isSysadmin = false
  }

  const LOGIN_URL = credentials.sysAdminLogin ? 'POST_SYS_LOGIN' : 'POST_LOGIN'
  return post(LOGIN_URL, omit(credentials, 'tenant', 'sysAdminLogin'), {
    tenant: credentials.tenant,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }).then(function (res: any) {
    if (res) {
      setToken(res) // React
      loginSuccess(credentials)
      return res
    } else {
      throw new Error('Failed to retrieve token')
    }
  })
}

export const loginSuccess = (credentials: Omit<Credentials, 'password'>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const claims: any = getClaims()
  if (isLimitedAccess() && claims.limited_access.type === 'two_step_auth') {
    // not yet fully authenticated
    return
  }
  saveUsernameToSessionStorage(credentials.loginas ? credentials.loginas : credentials.username)
  saveTenantToSessionStorage(credentials.tenant)
  trackingService.track(TrackingEvent.Login)
  refreshLogin()
}

export const loginFederatedUser = (jwToken: string) => {
  setToken(jwToken)
  refreshLogin()
}

export const loginWithToken = (token: string) => {
  setToken(token)
  refreshLogin()
}

// // Get username from session storage
// var getUsername = function () {
//   return getUsernameFromSessionStorage();
// };

// var cancelRefreshLogin = function () {
//   $interval.cancel(refreshTokenPromise);
// }
