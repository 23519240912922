import React from 'react'

import css from './FadeIn.module.scss'

type FadeInProps = {
  children: React.ReactNode
  classes?: string
  durationMs?: number
}

const FadeIn = ({ children, durationMs = 1500, classes = '' }: FadeInProps) => {
  return (
    <div
      style={{
        animationDuration: `${durationMs}ms`,
      }}
      className={`${css.fadeIn} ${classes}`}
    >
      {children}
    </div>
  )
}

export default FadeIn
