import React from 'react';
import css from './SelectableTableCntr.module.scss';

const SelectableTableCntr: React.FC = (props) => {
  return (
    <div className={css.tbl_selectable} data-testid='tools-customization-selectable-cntr'>
      {props.children}
    </div>
  )
}

export default SelectableTableCntr;