import React from 'react';
import css from './TabTablesCntr.module.scss';

const TabTablesCntr: React.FC = (props) => {
  return (
    <div className={css.tbls_cntr}>
      {props.children}
    </div>
  )
}

export default TabTablesCntr;