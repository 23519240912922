import React from 'react'

import css from './ClearOrSelectAll.module.scss'
import { tCommon } from '../../../../../../languages/i18n'

type ClearOrSelectAllProps = {
  handleClear: () => void
  handleSelect: () => void
  handleClose: () => void
}
const ClearOrSelectAll = ({ handleClear, handleSelect, handleClose }: ClearOrSelectAllProps) => {
  return (
    <div className={css.cntr}>
      <div onClick={handleClear}>{tCommon('button.clearAll')}</div>
      <div onClick={handleSelect}>{tCommon('button.selectAll')}</div>
      <div onClick={handleClose}>{tCommon('button.close')}</div>
    </div>
  )
}

export default ClearOrSelectAll
