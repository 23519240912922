import { createStateContext } from 'react-use'
import { Breakpoint, DateGroups } from './pietabularTypes'

export type PietabularSettingsProviderProps = {
  settings: PietabularModuleSettings
  query: OpenAnswersQuery | null
  isScreenMode: boolean
  isReportMode: boolean
  saveSettingsProperty: (newProperty: Record<string, unknown> | undefined) => void
  id?:string
}

export type PietabularModuleSettings = {
  breakpoints?: Breakpoint[] | null
  defaultCountedKey?: string
  defaultDateGroup?: DateGroups
  defaultMetaGroup?: string
  defaultSortColumn?: number
  defaultSortIsAscending?: boolean
  denominator?: string
  hideNoCategory?: string | null // deprecated, kept for legacy settings overwrite
  kpis?: PrunedKpi[]
  metaKeys?: string[]
  modulewidth?: string
  numberOfTopTopicsToShow?: number
  numerator?: string
  openCategories?: string[]
  pieTitle?: string
  selectedPieTopic?: string | null
  showBreakpoints?: boolean
  showPie?: boolean
  showRollingAverage?: boolean
  showRegressionLines?: boolean
  showAreaShareCharts?: boolean
  showNotSelectedTopicsAsOther?: boolean
  showTabular?: boolean
  showTooltip?: boolean
  showTotalCount?: boolean
  showTrends?: boolean
  tabularDataFormat?: string
  tolerance?: number | string
  title?: string
  trendsTitle?: string
  trendsSubTitle?: string
  pieOtherReplaceText?: string
  pieUncategorizedReplaceText?: string
}

export const defaultOptions = {
  defaultCountedKey: 'sentiment',
  defaultDateGroup: DateGroups.MONTH,
  defaultSortColumn: 1,
  defaultSortIsAscending: false,
  denominator: 'Negative',
  metaKeys: [],
  modulewidth: '12',
  numberOfTopTopicsToShow: 6,
  numerator: 'Positive',
  openCategories: [] as string[],
  pieTitle: '',
  tabularDataFormat: 'count',
  showPie: true,
  showAreaShareCharts: true,
  showRegressionLines: true,
  showRollingAverage: true,
  showTabular: true,
  showTooltip: true,
  showTotalCount: true,
  showTrends: false,
  showBreakpoints: true,
  tolerance: 10,
  trendsTitle: 'Trends summary',
  trendsSubTitle: '',
}

export type PrunedKpi = {
  name: string
  id: number
}

export type OpenAnswersQuery = {
  start_date: string
  end_date: string
  where_meta: {
    [meta: string]: string[]
  }
  kpis: PrunedKpi[]
}

export const [usePieTabularSettings, PieTabularSettingsProvider] =
  createStateContext<PietabularSettingsProviderProps>({
    settings: {},
    isReportMode: false,
    isScreenMode: false,
    query: null,
    saveSettingsProperty: () => ({}),
  })
