import React, { useMemo } from 'react'
import RequiredStar from '../../_shared/ModuleSettings/components/RequiredStar'
import TextWithInfo from '../../_shared/ModuleSettings/components/TextWithInfo'
import { infoSelectChartType } from '../FrequencyNotifications'
import { useSettingsTools } from '../../_shared/ModuleSettings/SettingsContext'
import { FrequencyChartType, FrequencyModule, FrequencyPieType } from '../frequencyModuleTypes'
import { cloneDeep, isUndefined } from 'lodash'

import modal_css from './../../_shared/ModuleSettings/SettingsModal.module.scss'
import css from './../../_shared/ModuleSettings/components/DropdownInput.module.scss'

const ChartTypeDropdown = () => {
  const [{ module, saveModule }] = useSettingsTools()
  const freqModule = module as FrequencyModule
  const { chartType, horizontalBars } = freqModule
  const pieType = freqModule.options?.pieType
  const realChartType = useMemo(() => {
    if (horizontalBars === true && chartType === FrequencyChartType.BAR) {
      return FrequencyChartType.BAR
    }
    if (horizontalBars === false && chartType === FrequencyChartType.BAR) {
      return FrequencyChartType.COLUMN
    }
    return chartType
  }, [chartType, horizontalBars])

  const handleOnChangeChartType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const moduleCopy = cloneDeep(freqModule)
    if (!isUndefined(moduleCopy.horizontalBars)) delete moduleCopy.horizontalBars
    const types = Object.values(FrequencyChartType) as string[]
    if (types.includes(e.currentTarget.value)) {
      const newChartType = e.currentTarget.value as FrequencyChartType
      const newModule = { ...moduleCopy, chartType: newChartType }
      saveModule(newModule)
    }
  }

  const handleOnChangePieType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const moduleCopy = cloneDeep(freqModule)
    const newPieType = e.currentTarget.value
    const prevOptions = freqModule.options
    if (newPieType === FrequencyPieType.FULL || newPieType === FrequencyPieType.HOLLOW) {
      saveModule({ ...moduleCopy, options: { ...(prevOptions || {}), pieType: newPieType } })
    }
  }

  return (
    <>
      <div className={`${modal_css.third}`}>
        <TextWithInfo text='Chart type' info={infoSelectChartType} tooltipDirection='right' />
        <RequiredStar />
        <select
          className={`${css.input} browser-default ${realChartType ? '' : modal_css.required}`}
          onChange={handleOnChangeChartType}
          placeholder={'select type'}
          data-testid={'chartTypeSelect'}
          id={'chartTypeSelect'}
          value={realChartType}
        >
          <option key={'emptyOption'} value={''}></option>
          {Object.values(FrequencyChartType).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      {chartType === FrequencyChartType.PIE && (
        <div className={`${modal_css.third}`}>
          Pie type
          <RequiredStar />
          <select
            className={`${css.input} browser-default ${pieType ? '' : modal_css.required}`}
            onChange={handleOnChangePieType}
            placeholder={'select type'}
            data-testid={'pieType'}
            id={'pieType'}
            value={pieType || ''}
          >
            <option key={'emptyOption'} value={''}></option>
            {Object.values(FrequencyPieType).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  )
}

export default ChartTypeDropdown
