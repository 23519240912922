import React, { useMemo } from 'react'
import { isLoggedIn, navigateToLanding } from '../../../../react-services/authService'
import useDashboardSections from '../../../../stores/useDashboardSections'
import DashboardBuilder from './DashboardBuilder'
import trackingService from '../../../../react-services/trackingService'
import { useParams } from 'react-router-dom'
import { useEffectOnce, useUpdateEffect } from 'react-use'
import { useTranslation } from 'react-i18next'
import useTranslationsConfig from '../../../../stores/useTranslationsConfig'

const DashboardContainer = () => {
  const { sectionIdx, dashboardIdx } = useParams()
  const sectionNumber = isNaN(Number(sectionIdx)) ? 0 : Number(sectionIdx)
  const dashboardNumber = isNaN(Number(dashboardIdx)) ? 0 : Number(dashboardIdx)
  const { sections, error, isLoading } = useDashboardSections()
  const { i18n } = useTranslation()
  const { config: translationConfig } = useTranslationsConfig()

  useEffectOnce(() => {
    trackingService.identify()
  })

  useUpdateEffect(() => {
    // if (isLoggedIn() && translationConfig && (isTemplateUser() || isTestApi())) {
    if (isLoggedIn() && translationConfig) {
      window.removeEventListener('keypress', handleChangeLanguage)
      window.addEventListener('keypress', handleChangeLanguage)
    }
  }, [translationConfig])

  const handleChangeLanguage = (event: KeyboardEvent) => {
    let lock = false
    if (!translationConfig) return
    if (event.ctrlKey && event.shiftKey && event.altKey && event.code === 'KeyL') lock = true
    if (lock) {
      const tenantLanguages = Object.keys(translationConfig)
      const currentLanguage = i18n.language
      const currentLanguageIndex = tenantLanguages.indexOf(currentLanguage)
      const nextLanguage = tenantLanguages[currentLanguageIndex + 1]
      const nextLanguageIndex = currentLanguageIndex + 1
      if (nextLanguageIndex === tenantLanguages.length) {
        i18n.changeLanguage(tenantLanguages[0])
      } else {
        i18n.changeLanguage(nextLanguage)
      }
    }
  }

  const currentSection = useMemo(
    function () {
      if (!sections) return null
      try {
        return sections[sectionNumber]
      } catch (error) {
        return null
      }
    },
    [sections, sectionNumber],
  )

  const currentDashboard = useMemo(
    function () {
      if (!currentSection || !currentSection.dashboards) return null
      try {
        return currentSection.dashboards[dashboardNumber]
      } catch (error) {
        return null
      }
    },
    [currentSection, dashboardNumber],
  )

  if (!isLoggedIn()) {
    console.error('Not logged in. Redirect to landing page...')
    navigateToLanding()
    return <></>
  }

  if (isLoading) {
    return <></>
  }

  if (error) {
    return <div>{error}</div>
  }

  return <DashboardBuilder currentDashboard={currentDashboard} />
}

export default DashboardContainer
