/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { memo } from 'react';
import HighchartsGraph from '../_shared/HighchartsGraph';
import { Dashboard, KpiData, KpiNamesXYZ } from '../../../../../types';
import { getBubbleGraphConfiguration } from '../../../../react-services/chartService';

type Props = {
  data: Readonly<KpiData.XYZData>
  kpiNamesXYZ?: Readonly<KpiNamesXYZ>
  module?: Readonly<Dashboard.BubbleModule>
}

const BubbleGraphHighcharts = memo<Props>(({data, kpiNamesXYZ, module}) => {
  let bubbleGraphConfiguration: Highcharts.Options;
  try {
    bubbleGraphConfiguration = getBubbleGraphConfiguration(data, kpiNamesXYZ, module);
  } catch (e) {
    console.error('Error creating Bubble chart configuration.', e);
    return (
      <div>Error rendering Bubble chart</div>
    );
  }
  return (
    <HighchartsGraph
      chartConfiguration={bubbleGraphConfiguration}
    />
  );
});

export default BubbleGraphHighcharts;
