import React from 'react';
import './NoData.css';

type Props = {
  message?: string
}

const NoData = ({ message }: Props) => {
  return (
    <div data-no-data={true} className="valign-wrapper" style={{height: '100%', width: '100%', minHeight: 'inherit'}}>
      <div className="center" style={{width: '100%'}}>
        <span className="no-data-disclaimer-text" data-testid="noDataText">{message ? message : 'No data to display'}</span>
      </div>
    </div>
  );
};

export default NoData;