import React, { useCallback, useState } from 'react'
import { timePickerColor } from '../../../../../../styles/variableExport'
import ModulePicker from '../../ModulePicker/ModulePicker'
import { pSBC } from '../../../../../react-services/colorService'
import { useRootActions } from '../contexts/RootContext'
import { Dashboard } from '../../../../../../types'
import { Add, Delete, DeleteOutlineOutlined, MoreVertOutlined } from '@mui/icons-material'
import { useGroupModule } from '../contexts/GroupContext'
import { Tooltip } from '@mui/material'
import { tCommon } from '../../../../../../languages/i18n'

import css from './ModuleSettings.module.scss'
import cntr_css from './GroupFilters.module.scss'

const darkenedPickerColor = pSBC(-0.4, timePickerColor) || timePickerColor
type ModuleSettingsProps = {
  isCntrHovered?: boolean
  isBarCollapsed: boolean
  isSmall: boolean
  handleOpenSettingsModal?: () => void
  handleOpenDeleteModal?: () => void
}
const ModuleSettings = ({
  isCntrHovered,
  isBarCollapsed,
  isSmall,
  handleOpenSettingsModal,
  handleOpenDeleteModal,
}: ModuleSettingsProps) => {
  const { module } = useGroupModule()[0]
  const [isMoreHovered, setIsMoreHovered] = useState(false)
  const [isDeleteHovered, setIsDeleteHovered] = useState(false)
  const [isAddHovered, setIsAddHovered] = useState(false)
  const [isModulePickerOpen, setIsModulePickerOpen] = useState(false)
  const { addModule } = useRootActions()

  const handleAddModule = useCallback(
    (m: Dashboard.PickableModule) => {
      if (!module?.id) return
      addModule(m, module.id)
    },
    [module?.id],
  )

  const handleOpenPicker = useCallback(() => setIsModulePickerOpen(true), [])
  const handleClosePicker = useCallback(() => setIsModulePickerOpen(false), [])

  return (
    <>
      <div
        className={`${css.cntr} ${cntr_css.icons} ${isBarCollapsed ? cntr_css.collapsed : ''}`}
        style={{ ...(isCntrHovered ? { opacity: 1 } : {}) }}
      >
        {!!handleOpenSettingsModal && (
          <Tooltip title={tCommon('info.openGroupSettingsModal')}>
            <div
              className={css.more}
              onClick={handleOpenSettingsModal}
              onMouseEnter={() => setIsMoreHovered(true)}
              onMouseLeave={() => setIsMoreHovered(false)}
              data-testid='openGroupSettingsModal'
            >
              <MoreVertOutlined
                style={{
                  color: isMoreHovered ? '#fff' : darkenedPickerColor,
                  backgroundColor: isMoreHovered ? darkenedPickerColor : '',
                  borderRadius: '50%',
                }}
              />
            </div>
          </Tooltip>
        )}
        {!isSmall && !!handleOpenDeleteModal && (
          <Tooltip title={tCommon('info.deleteGroupModule')}>
            <div
              className={css.delete}
              onClick={handleOpenDeleteModal}
              onMouseEnter={() => setIsDeleteHovered(true)}
              onMouseLeave={() => setIsDeleteHovered(false)}
              data-testid='deleteGroupModule'
            >
              {isDeleteHovered ? (
                <Delete style={{ color: darkenedPickerColor }} />
              ) : (
                <DeleteOutlineOutlined style={{ color: darkenedPickerColor }} />
              )}
            </div>
          </Tooltip>
        )}
        {isSmall && (
          <Tooltip title={tCommon('info.addModuleToGroup')}>
            <div
              className={css.add}
              onClick={handleOpenPicker}
              onMouseEnter={() => setIsAddHovered(true)}
              onMouseLeave={() => setIsAddHovered(false)}
              data-testid='addModuleInGroup'
            >
              <Add
                style={{
                  color: isAddHovered ? '#fff' : darkenedPickerColor,
                  backgroundColor: isAddHovered ? darkenedPickerColor : '',
                  borderRadius: '50%',
                }}
              />
            </div>
          </Tooltip>
        )}
      </div>
      {isModulePickerOpen && (
        <ModulePicker isGrouped onSelect={handleAddModule} onClose={handleClosePicker} />
      )}
    </>
  )
}

export default ModuleSettings
