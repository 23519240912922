import { useEffect, useState } from 'react'
import { OpenAnswersQuery } from './PietabularModuleContext'
import { transformFiltersToWhereMetaForm } from '../../../../react-services/moduleFiltersService'
import { errorFetchKpis } from './PietabularNotifications'
import { GroupedQuery, QueryKpis } from './pietabularTypes'
import objectHash from 'object-hash'
import { useRenderActions } from '../Group/contexts/RenderContext'
import { isAllowedToFetch } from '../Group/contexts/renderListReducer'
import { isEqual } from 'lodash'
import useOpenKpis from '../../../../stores/useOpenKpis'
import { QueueStatus } from '../Group/groupModuleTypes'

const usePietabularQueryFormat = (
  moduleQuery: OpenAnswersQuery,
  filters: unknown,
  id: string,
  doneStatusTimeoutRef: React.MutableRefObject<NodeJS.Timeout | null>,
  moduleStatus: QueueStatus | undefined,
) => {
  const queryHash = objectHash(moduleQuery)
  const [pietabularQuery, setPietabularQuery] = useState<OpenAnswersQuery | null>(null)
  const [error, setError] = useState<string>('')

  const { requestToFetch } = useRenderActions()
  const { openKpis } = useOpenKpis()

  useEffect(() => {
    let queryForGrouped: GroupedQuery
    if (filters) {
      queryForGrouped = createQueryForGrouped(filters, moduleQuery)
    } else {
      queryForGrouped = moduleQuery
    }
    if (!openKpis) return
    const newQuery = createKpiListToQuery(queryForGrouped)
    if (isEqual(newQuery, pietabularQuery)) return
    if (id) handleRequestingToFetch(id)
    if (isAllowedToFetch(moduleStatus)) {
      setPietabularQuery(newQuery)
    }
  }, [queryHash, filters, moduleStatus, openKpis])

  const handleRequestingToFetch = (moduleId: string) => {
    requestToFetch(moduleId)
    if (doneStatusTimeoutRef.current) clearTimeout(doneStatusTimeoutRef.current)
  }

  const createKpiListToQuery = (query: GroupedQuery) => {
    if (query?.kpis) return query
    let kpis = [] as QueryKpis[]
    try {
      if (!openKpis || openKpis.length === 0) {
        throw ''
      }
      kpis = openKpis.flatMap((kpi) => {
        if (typeof kpi === 'string') {
          return [] as QueryKpis[]
        } else {
          return [{ name: kpi.name, id: kpi.id }]
        }
      })
    } catch (e) {
      setError(errorFetchKpis)
    }
    query.kpis = kpis
    return query
  }

  const createQueryForGrouped = (groupFilters: unknown, moduleFilters: OpenAnswersQuery) => {
    const result = groupFilters ? transformFiltersToWhereMetaForm(groupFilters) : moduleFilters
    const kpis = moduleFilters && moduleFilters.kpis ? moduleFilters.kpis : []
    const resultWithKpis = { ...result, kpis: kpis && Array.isArray(kpis) ? kpis : [] }
    return resultWithKpis
  }

  return {
    pietabularQuery,
    error,
  }
}

export default usePietabularQueryFormat
